import dayjs from "dayjs";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { IP2PUserCreatedOffer } from "@/apis/p2p/types";
import { NftMetadata } from "@/components/P2P/collection/LoanOffers/NftMetadata";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPeerPaymentPlanFromChainId } from "@/constants/contracts";
import { getChainExplorerURL } from "@/utils";

import { OfferDetail } from "./OfferDetail";

export const CancelOfferProgress = ({ offer }: { offer: IP2PUserCreatedOffer }) => {
  const cyanWallet = useCyanWallet();
  const { chainId, account, signer } = useWeb3React();
  const { setModalContent, unsetModal } = useModal();
  const { addTransaction, transactions } = useTransactionContext();
  const [selectedStep, setSelectedStep] = useState<Steps>(Steps.Cancelling);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [activeTx, setActiveTx] = useState<string | null>(null);
  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(Steps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);
  useEffect(() => {
    const onStepChange = async (step: Steps) => {
      if (!chainId || !signer || !account) return;
      try {
        switch (step) {
          case Steps.Cancelling: {
            const peerToPeerContract = getPeerPaymentPlanFromChainId(chainId);
            const writer = f.CyanPeerPlanFactory.connect(peerToPeerContract, signer);
            const tx = await writer.cancelSignature(
              {
                amount: offer.tokenAmount,
                tokenId: offer.tokenId === "" ? "0" : offer.tokenId ?? "0",
                contractAddress: offer.collection.address,
                itemType: offer.collection.tokenType,
                collectionSignature: offer.collection.cyanSignature,
              },
              {
                amount: offer.amount,
                lenderAddress: offer.lenderAddress,
                currencyAddress: offer.currency.address,
                interestRate: offer.interestRate,
                term: offer.term,
                serviceFeeRate: 0,
              },
              {
                signature: offer.signature,
                signedDate: dayjs(offer.signedDate).valueOf(),
                expiryDate: dayjs(offer.signatureExpiry).valueOf(),
                maxUsageCount: offer.signatureUsageLimit,
                extendable: offer.isExtendable,
              },
            );

            addTransaction({
              type: "p2p-offer-cancel",
              hash: tx.hash,
              data: {
                loanId: offer.id,
              },
            });
            setActiveTx(tx.hash);
          }
        }
      } catch (err) {
        setModalContent({
          title: `Cancel loan offer`,
          content: <OfferDetail offer={offer} modalType="cancel" error={err} />,
        });
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);
  return (
    <Flex gap="2rem" direction="column">
      {offer.tokenId && (
        <NftMetadata
          nft={{
            collectionAddress: offer.collection.address,
            collectionName: offer.collection.name,
            tokenId: offer.tokenId,
            currency: offer.currency,
            imageUrl: offer.metadata ? offer.metadata.imageUrl : NoImage,
          }}
        />
      )}
      <Box pb="1.8rem" pt="1rem">
        <Stepper
          marks={StepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === Steps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};
enum Steps {
  Cancelling = 1,
  Success = 2,
  Done = 3,
}
const StepMarks = [
  {
    value: Steps.Cancelling,
    title: `Cancelling loan offer`,
    description: `A small amount of gas is required to approve`,
  },
  {
    value: Steps.Success,
    description: `View on Etherscan`,
    title: `Successfully cancelled`,
  },
];

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
