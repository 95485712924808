import isEqual from "lodash.isequal";
import orderBy from "lodash.orderby";
import { useEffect, useMemo, useState } from "react";
import { useAsyncAbortable } from "react-async-hook";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";

import { Box, Flex, Input, Text } from "@cyanco/components/theme";

import { ICollectionPricer, priceCollectionBasedOnFloorV2 } from "@/apis/pricer/pricer-collection-based";
import { updatePoolSettings } from "@/apis/vault/admin";
import { IPoolSettings } from "@/apis/vault/admin/types";
import { IVault, IVaultSupportedProject } from "@/apis/vault/types";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { isProd } from "@/config";
import { DAY_SECONDS } from "@/hooks/usePlanCreationForm";
import { groupArrayByKey } from "@/utils";

import { SaveSettingsButton } from "../PoolManagement";
import { MobileFriendlyWrapper } from "../VaultLoans";
import { getVaultAdminSignatureExpiry, signPoolSettingsUpdate } from "../utils";
import { CurvePreview } from "./CurvePreview";
import { ExpandableView } from "./ExpandableView";
import { RateCustomizationHeader, RateCustomizationRow } from "./RateCustomizationTable";

const getMaturity = (maturity: number) => {
  const divider = isProd ? DAY_SECONDS : 60 * 60;
  const result = maturity / divider;
  if (result < 1 && !isProd) {
    return `${maturity / 60} minutes`;
  }
  return `${result} ${isProd ? "days" : "hours"}`;
};

export const PoolSettings = ({
  pricerSettings,
  address,
  setPoolSettings,
  baseInterestRate,
  vault,
}: {
  pricerSettings: IPoolSettings[];
  baseInterestRate: number;
  address: string;
  setPoolSettings: (settings: IPoolSettings[], rate: number) => void;
  vault: IVault;
}) => {
  const { signInWallet } = useAuthContext();
  const { account, signer } = useWeb3React();
  const [collection, setSelectedCollection] = useState<IVaultSupportedProject | null>(null);
  const [settingsField, setSettingsFields] = useState<IPoolSettings[]>([]);
  const [baseRate, setBaseRate] = useState<string>((baseInterestRate / 1_00).toString());
  const [openViewIndex, setOpenViewIndex] = useState<number | null>(null);
  const baseRateInNumber = baseRate === "" || isNaN(parseFloat(baseRate)) ? 0 : parseFloat(baseRate);

  const vaultActiveProjects = useMemo(() => {
    return vault.supportedProjects.filter(p => p.isBnplAllowed || p.isPawnAllowed);
  }, [vault.supportedProjects]);

  useEffect(() => {
    setSelectedCollection(vaultActiveProjects[0] ?? null);
  }, [vaultActiveProjects]);

  const {
    result: collectionPricer,
    loading: collectionPricerLoading,
    execute,
  } = useAsyncAbortable<ICollectionPricer | undefined>(
    async abortSignal => {
      if (collection) {
        const result = await priceCollectionBasedOnFloorV2({
          collectionAddress: collection.address,
          chainId: vault.chainId,
          wallet: account,
          abortSignal,
          vaultId: vault.id,
        });
        return result;
      }
      return;
    },
    [vault.id, account, collection],
  );

  useEffect(() => {
    setSettingsFields(
      pricerSettings.map(item => ({
        id: item.id,
        weight: item.weight / 1_000,
        ltvRatio: item.ltvRatio / 1_00,
        isActiveBnpl: item.isActiveBnpl,
        isActivePawn: item.isActivePawn,
        maturity: item.maturity,
      })),
    );
  }, [pricerSettings]);

  useEffect(() => {
    setBaseRate((baseInterestRate / 1_00).toString());
  }, [baseInterestRate]);

  const handleFormChange = (
    id: number,
    value: number,
    target: "weight" | "ltvRatio" | "isActivePawn" | "isActiveBnpl" | "maturity",
  ) => {
    const data = [...settingsField];
    if (target === "isActivePawn" || target === "isActiveBnpl") {
      const index = data.findIndex(item => item.id === id);
      data[index][target] = value === 1 ? true : false;
    } else {
      const index = data.findIndex(item => item.id === id);
      data[index][target] = value;
    }
    setSettingsFields(data);
  };

  const saveSettings = async () => {
    if (!signer || !account) return;
    const { token } = await signInWallet();
    const expiryDate = getVaultAdminSignatureExpiry();
    const signature = await signPoolSettingsUpdate(settingsField, address, baseRateInNumber, expiryDate, signer);
    await updatePoolSettings({
      vaultAddress: address,
      signature,
      token,
      configs: settingsField,
      baseInterestRate: Number(baseRate),
      expiryDate,
    });
    setPoolSettings(
      settingsField.map(item => ({
        id: item.id,
        weight: item.weight * 1_000,
        ltvRatio: item.ltvRatio * 1_00,
        isActiveBnpl: item.isActiveBnpl,
        isActivePawn: item.isActivePawn,
        maturity: item.maturity,
      })),
      baseRateInNumber * 100,
    );
    execute();
  };
  const settingsFieldGrouped = groupArrayByKey(settingsField, "maturity");
  return (
    <Flex direction="column" style={{ overflowX: "hidden" }}>
      <CurvePreview
        pricerSettings={settingsField}
        baseRateInput={baseRateInNumber * 100}
        vault={vault}
        baseRateVault={baseInterestRate}
        setSelectedCollection={setSelectedCollection}
        collectionPricerLoading={collectionPricerLoading}
        collectionPricer={collectionPricer}
        collection={collection}
      />
      <Container direction="column" gap="2.5rem" p="3rem 2rem 1rem 2rem">
        <MobileFriendlyWrapper justifyContent="space-between" w="100%">
          <Flex direction="column">
            <Text color="secondary" style={{ fontSize: "33px" }} weight="600">
              Pool Settings
            </Text>
            <Text color="gray0" size="lg">
              Weight settings are universal and effect <br />
              all collections.{" "}
            </Text>
          </Flex>
          <Flex gap="1rem" alignItems="center">
            <Flex direction="column" gap="5px">
              <Text color="secondary" style={{ fontSize: "22px" }} weight="600">
                Risk-free rate
              </Text>
              <Text color="gray0" size="sm" weight="600" style={{ fontStyle: "italic" }} textWrap={false}>
                LSTs or US Treasuries is a good reference
              </Text>
            </Flex>
            <Box w="115px">
              <NumericFormat
                value={baseRate}
                customInput={Input}
                onValueChange={value => {
                  setBaseRate(value.value);
                }}
                decimalScale={2}
                fontSize="xl"
                suffix="%"
                placeholder="0"
                valueIsNumericString
              />
            </Box>
          </Flex>
        </MobileFriendlyWrapper>
        <Flex direction="column" gap="1rem">
          {Object.keys(settingsFieldGrouped).map((item, index) => (
            <ExpandableView
              title={`${getMaturity(Number(item))}`}
              key={index}
              isOpen={index === openViewIndex}
              onToggle={() => {
                index === openViewIndex ? setOpenViewIndex(null) : setOpenViewIndex(index);
              }}
            >
              <Flex direction="column" gap="0.5rem" style={{ overflowX: "scroll" }}>
                <RateCustomizationHeader />
                {orderBy(settingsFieldGrouped[item], "ltvRatio").map(setting => (
                  <RateCustomizationRow
                    option={setting}
                    key={setting.id}
                    index={setting.id}
                    handleFormChange={handleFormChange}
                  />
                ))}
              </Flex>
            </ExpandableView>
          ))}
        </Flex>
        <SaveSettingsButton
          onClick={saveSettings}
          disabled={
            isEqual(
              settingsField,
              pricerSettings.map(item => ({
                id: item?.id,
                weight: item.weight / 10_00,
                ltvRatio: item.ltvRatio / 1_00,
                isActiveBnpl: item.isActiveBnpl,
                isActivePawn: item.isActivePawn,
                maturity: item.maturity,
              })),
            ) && baseInterestRate / 100 === baseRateInNumber
          }
        />
      </Container>
    </Flex>
  );
};

const Container = styled(Flex)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
  margin-top: 2.5rem;
`;
