import { useMemo } from "react";

import { CsvButton, Flex } from "@cyanco/components/theme";
import { Hidden, SwitchButton, SwitchButtonGroup } from "@cyanco/components/theme/v3";

import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { bigNumToFixedStr, getChainExplorerURL } from "@/utils";
import { Experiments } from "@/utils/experimentList";

import { StickySelectorWrapper } from "../..";
import { useVaultTransactions } from "../../AccountDataContext";
import {
  IPositionsSelectedViews,
  LendTabData,
  VaultTokenTransactionTypes,
  VaultTokensSelectedViews,
  useLendingTabContext,
  useVaultTokensTabContext,
} from "../../AccountPageContext";

export const VaultViewSelectors = () => {
  const { experiment } = useAppContext();
  const { selectedVaultTokensView, setSelectedVaultTokensView } = useVaultTokensTabContext();
  const { selectedLendingTabData, setSelectedLendingTabData } = useLendingTabContext();
  const { vaultTransactions } = useVaultTransactions();
  const { chainId } = useWeb3React();

  const vaultHistory = useMemo(() => {
    return [
      ["Vault", "Wallet", "Transaction Date", "Amount", "Price", "Action", "Transaction Hash"],
      ...vaultTransactions.map(vaultTx => {
        return [
          `${vaultTx.vault.name} (${vaultTx.vault.symbol})`,
          vaultTx.isCyanWallet ? "Cyan Wallet" : "Main Wallet",
          vaultTx.createdAt,
          `${bigNumToFixedStr(vaultTx.amount, 2, vaultTx.vault.decimals)} ${vaultTx.vault.symbol}`,
          `${bigNumToFixedStr(vaultTx.amountEth, 2, vaultTx.vault.decimals)} ${vaultTx.vault.currency}`,
          vaultTx.type == VaultTokenTransactionTypes.Stake ? "Staked" : "Unstaked",
          `${getChainExplorerURL(chainId)}/tx/${vaultTx.txnHash}`,
        ];
      }),
    ].join("\n");
  }, [selectedVaultTokensView, vaultTransactions]);

  return (
    <StickySelectorWrapper alignItems="center" justifyContent="space-between" w="100%" h="28px" pb="1rem">
      <Flex gap="2px" alignItems="center" justifyContent="space-between" w="100%">
        <SwitchButtonGroup<IPositionsSelectedViews>
          value={selectedVaultTokensView}
          onChange={setSelectedVaultTokensView}
          hover
        >
          <SwitchButton height="22px" value={VaultTokensSelectedViews.current}>{`Current`}</SwitchButton>
          <SwitchButton height="22px" value={VaultTokensSelectedViews.historical}>{`Historical`}</SwitchButton>
        </SwitchButtonGroup>{" "}
        {experiment.result && experiment.result[Experiments.P2P] ? (
          <Hidden tabletDown>
            <Flex alignItems="center" gap="1rem">
              {selectedVaultTokensView == "historical" && <CsvButton data={vaultHistory} />}

              <SwitchButtonGroup<LendTabData> value={selectedLendingTabData} onChange={setSelectedLendingTabData} hover>
                <SwitchButton height="22px" value={LendTabData.p2p}>{`Peer-to-Peer`}</SwitchButton>
                <SwitchButton height="22px" value={LendTabData.vault}>{`Peer-to-Vault`}</SwitchButton>
              </SwitchButtonGroup>
            </Flex>
          </Hidden>
        ) : (
          selectedVaultTokensView == "historical" && <CsvButton data={vaultHistory} />
        )}
      </Flex>
    </StickySelectorWrapper>
  );
};
