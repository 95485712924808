import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import { useMemo } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, PriceChart, Text } from "@cyanco/components/theme/v3";

import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { useGetUserTime } from "@/hooks/useGetUserTime";
import { numberWithCommas } from "@/utils";

import { IPriceChartIntervalType, PriceChartInterval } from "../../VaultPageContext";

dayjs.extend(dayOfYear);
dayjs.extend(isoWeek);

export const PriceGraph = ({
  symbol,
  history,
  setSelectedPeriod,
  selectedPeriod,
  formatNumber = 5,
  showYAxis,
  yAxisLabel,
  yAxisTickFormatter,
  yAxisX,
  yAxisFontSize,
}: {
  symbol: string;
  history: Array<{ price: number; createdAt: string }>;
  setSelectedPeriod: (a: IPriceChartIntervalType) => void;
  selectedPeriod: IPriceChartIntervalType;
  formatNumber?: number;
  showYAxis?: boolean;
  yAxisX?: number;
  yAxisLabel?: string;
  yAxisTickFormatter?: (value: number) => string;
  yAxisFontSize?: string;
}) => {
  const { user } = useAuthContext();
  const { getUserPreferredTime } = useGetUserTime();
  const getHistoriesByOneDay = (
    histories: Array<{ price: number; createdAt: string }>,
    group: (a: string) => string,
  ) => {
    const maxValueDates = histories.reduce<{
      [key: string]: { price: number; createdAt: string };
    }>((acc, currVaultHistory) => {
      const vaultHistoryDate = group(currVaultHistory.createdAt);
      const prevVaultHistory = acc[vaultHistoryDate];
      if (!prevVaultHistory || currVaultHistory.price > prevVaultHistory.price) {
        acc[vaultHistoryDate] = currVaultHistory;
      }
      return acc;
    }, {});
    const graphReadyData = Object.values(maxValueDates).map(({ createdAt, price }) => ({
      label: {
        date: dayjs(createdAt).format("DD MMM YYYY "),
        pricePlaceholder: `${numberWithCommas(price, formatNumber)} ${symbol}`,
      },
      price: price,
    }));
    return graphReadyData;
  };

  const oneWeek = useMemo(() => {
    const now = dayjs();
    const date = now.subtract(7, "day");
    return history
      .filter(price => price.price && dayjs(price.createdAt).isBefore(now) && dayjs(price.createdAt).isAfter(date))
      .map(price => ({
        label: {
          date: getUserPreferredTime(price.createdAt).format("HHa, DD MMM YYYY "),
          pricePlaceholder: `${numberWithCommas(price.price, formatNumber)}${symbol}`,
        },
        price: price.price,
      }));
  }, [history, user]);

  const oneMonth = useMemo(() => {
    const now = dayjs();
    const date = now.subtract(30, "day");
    const filteredByMonth = history.filter(
      price => price.price && dayjs(price.createdAt).isBefore(now) && dayjs(price.createdAt).isAfter(date),
    );
    return filteredByMonth
      .filter(price => price.price && dayjs(price.createdAt).isBefore(now) && dayjs(price.createdAt).isAfter(date))
      .map(price => ({
        label: {
          date: getUserPreferredTime(price.createdAt).format("HHa, DD MMM YYYY "),
          pricePlaceholder: `${numberWithCommas(price.price, formatNumber)} ${symbol}`,
        },
        price: price.price,
      }));
  }, [history, user]);

  const oneYear = useMemo(() => {
    return getHistoriesByOneDay(history, createdAt => {
      const date = getUserPreferredTime(createdAt);
      return date.format(history.length <= 20 ? "YYYY-MM-DD-HH-mm" : "YYYY-MM-DD");
    });
  }, [history]);

  const getGraphData = () => {
    switch (selectedPeriod) {
      case "7day":
        return oneWeek;
      case "30day":
        return oneMonth;
      case "365day":
        return oneYear;
      default:
        return oneWeek;
    }
  };
  return (
    <Container w="100%" h="300px" direction="column">
      <PriceChart
        data={getGraphData()}
        dataKey="price"
        labelKey="label"
        showYAxis={showYAxis}
        yAxisLabel={yAxisLabel}
        yAxisX={yAxisX}
        yAxisFontSize={yAxisFontSize}
        yAxisTickFormatter={yAxisTickFormatter}
      />
      <DateContainer>
        <Dates>
          {(Object.keys(PriceChartInterval) as Array<IPriceChartIntervalType>).map(period => (
            <Button key={period} variant="ghost" onClick={() => setSelectedPeriod(period)}>
              <Text color={selectedPeriod === period ? "secondary" : "gray0"} size="lg">
                {PriceChartInterval[period]}
              </Text>
            </Button>
          ))}
        </Dates>
      </DateContainer>
    </Container>
  );
};

const Container = styled(Flex)`
  height: 350px;
  margin-top: 3rem;
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 200 }])}
  ${getStyleWithMediaQuery("margin-top", "rem", [{ [breakpoints.tablet]: 1 }])}
`;

const DateContainer = styled(Flex)`
  border-width: 0;
  border-top-width: 1px;
  ${getStyleWithMediaQuery("border-top-width", "px", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 0 }])};
  border-color: ${({ theme }) => theme.colors.gray20};
  justify-content: flex-end;
  ${getStyleWithMediaQuery("justify-content", "", [{ [breakpoints.tablet]: "flex-start" }])}
  border-style: solid;
  width: 100%;
  padding: 1rem 0;
`;

const Dates = styled(Flex)`
  width: fit-content;
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.desktop]: "fit-content" }, { [breakpoints.tablet]: "100%" }])}
  ${getStyleWithMediaQuery("justify-content", "", [{ [breakpoints.tablet]: "space-between" }])}
  gap: 1rem;
`;
