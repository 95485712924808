import { FC } from "react";
import { useTheme } from "styled-components";

import { Box, Flex } from "@cyanco/components/theme/components";
import { Hidden, SystemMessage, Text } from "@cyanco/components/theme/v3";
import { NewTab } from "@cyanco/components/theme/v3/icons";

import { CHAIN_IDS_TO_EXPLORER_NAME } from "@/constants/chains";
import { jumpToLink } from "@/utils";

const NewTabIconThemed = () => {
  const theme = useTheme();
  return <NewTab color={theme.colors.black} height={11} />;
};

export const PositionCompletedEarly = ({ link, chainId }: { link: string; chainId: number }) => (
  <SystemMessage
    variant="success"
    description={`
    Your plan has successfully completed. The NFT can now be transferred out of your Cyan escrow wallet.
    `}
    title={`Completed Early!`}
    msg={
      <Flex onClick={() => jumpToLink(link)} alignItems="center" gap="5px" style={{ cursor: "pointer" }}>
        <Text size="xs" weight="500" color="black">
          {`Verify on ${CHAIN_IDS_TO_EXPLORER_NAME[chainId]}`}
        </Text>
        <NewTabIconThemed />
      </Flex>
    }
  />
);

export const PositionCompleted = ({ link, chainId }: { link: string; chainId: number }) => (
  <SystemMessage
    variant="success"
    description={`
    Your plan has successfully completed. The NFT can now be transferred out of your Cyan escrow wallet.
    `}
    title={`Completed!`}
    msg={
      <Flex onClick={() => jumpToLink(link)} alignItems="center" gap="5px" style={{ cursor: "pointer" }}>
        <Text size="xs" weight="500" color="black">
          {`Verify on ${CHAIN_IDS_TO_EXPLORER_NAME[chainId]}`}
        </Text>
        <NewTabIconThemed />
      </Flex>
    }
  />
);

export const PositionExtended = ({ link, chainId }: { link: string; chainId: number }) => (
  <SystemMessage
    variant="success"
    description={`
    Your plan has successfully extended.
    `}
    title={`Extended!`}
    msg={
      <Flex onClick={() => jumpToLink(link)} alignItems="center" gap="5px" style={{ cursor: "pointer" }}>
        <Text size="xs" weight="500" color="black">
          {`Verify on ${CHAIN_IDS_TO_EXPLORER_NAME[chainId]}`}
        </Text>
        <NewTabIconThemed />
      </Flex>
    }
  />
);

export const PositionPaymentMade = ({ link, chainId }: { link: string; chainId: number }) => (
  <SystemMessage
    variant="success"
    description={`
    Your plan has successfully completed. The NFT can now be transferred out of your Cyan escrow wallet.
    `}
    title={`Paid!`}
    msg={
      <Flex onClick={() => jumpToLink(link)} alignItems="center" gap="5px" style={{ cursor: "pointer" }}>
        <Text size="xs" weight="500" color="black">
          {`Verify on ${CHAIN_IDS_TO_EXPLORER_NAME[chainId]}`}
        </Text>
        <NewTabIconThemed />
      </Flex>
    }
  />
);

export const PositionPaymentMissed = () => (
  <SystemMessage
    variant="error"
    description={`Your plan has defaulted due to this missed payment. If you feel this defaulted by error, please reach out to our team.`}
    title={`Payment Missed`}
    msg={
      <Text size="xs" weight="500" color="black">
        {`This plan has defaulted`}
      </Text>
    }
  />
);

export const PositionDefaulted = () => (
  <SystemMessage
    variant="error"
    msg={`Your plan has defaulted due to a missed payment. Please contact us if you feel this happened in error.`}
    title={`Defaulted!`}
  />
);

interface ITransactionDetail {
  label: string;
  value: string;
  info?: string;
}
export const TransactionDetail: FC<ITransactionDetail> = ({ label, value, info }) => (
  <Flex justifyContent="space-between" p="0 0 0.5rem 0" w="100%">
    <Text size="xs" color="secondary" weight="500">
      {label}
    </Text>
    <Text size="xs" color="secondary" weight="500">
      {value}

      {info && (
        <Hidden tabletDown>
          <Box as="span" ml="0.25rem">
            <Text color="gray0" size="xs" weight="500">
              {info}
            </Text>
          </Box>
        </Hidden>
      )}
    </Text>
  </Flex>
);
