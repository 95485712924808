import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { notifyApeVaultBalanceError } from "@/apis/notification";
import { ApeCoinBalanceLoading, CloseButton } from "@/components/ApeCoinStaking/CommonComponents";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import {
  BAKCAddress,
  BAYCAddress,
  COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS,
  apeCoinContract,
  apeStakingContract,
  apeVaultContract,
} from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IInitiateOwnedApeStake } from "@/hooks/useApePlanCreation.types";
import { useApproval } from "@/hooks/useApproval";
import { bigNumToFixedStr, getChainExplorerURL } from "@/utils";
import { getVaultBalance } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";

import { useApeStakingUserAssets } from "../../../ApeCoinDataContext";
import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";

export const OwnedApeStaking = (params: IInitiateOwnedApeStake) => {
  const { selectedMainNft, selectedPairingNft, cyanWalletAmount, mainWalletAmount } = params;
  const { setFireConfetti } = useAppContext();
  const { giveErc20Approval } = useApproval();
  const cyanWallet = useCyanWallet();
  const { updateStakingData } = useApeStakingUserAssets();
  const { updateUserBalance } = useApeCoinStatsContext();
  const { addTransaction, transactions } = useTransactionContext();
  const { showPlanModal } = useApePlanCreation();
  const { chainId, account, provider, signer } = useWeb3React();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<StakingSteps>(StakingSteps.TranferApe);

  const stakingAmount = useMemo(() => {
    if (cyanWalletAmount && mainWalletAmount) {
      return ethers.utils.parseEther((parseFloat(cyanWalletAmount) + parseFloat(mainWalletAmount)).toString());
    }
    if (cyanWalletAmount) return ethers.utils.parseEther(cyanWalletAmount);
    if (mainWalletAmount) return ethers.utils.parseEther(mainWalletAmount);
  }, [cyanWalletAmount, mainWalletAmount]);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        updateStakingData();
        updateUserBalance();
        setFireConfetti(true);
        setSelectedStep(StakingSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const transfer = async () => {
    if (!signer || !account) return;
    if (selectedMainNft.isCyanWallet) {
      if (!cyanWallet) return;
      if (mainWalletAmount && parseFloat(mainWalletAmount) > 0) {
        const contractWriter = f.SampleERC20TokenFactory.connect(apeCoinContract, signer);
        const tx = await contractWriter.transfer(cyanWallet.walletAddress, ethers.utils.parseEther(mainWalletAmount));
        await tx.wait();
      }
    } else {
      if (cyanWalletAmount && cyanWallet && parseFloat(cyanWalletAmount) > 0) {
        const contractIFace = f.SampleERC20TokenFactory.createInterface();
        const encodedFnData = contractIFace.encodeFunctionData("transfer", [
          account,
          ethers.utils.parseEther(cyanWalletAmount),
        ]);
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
          apeCoinContract,
          "0",
          encodedFnData,
        ]);
        const tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
        await tx.wait();
      }
    }
  };

  const accept = async () => {
    if (!signer || !account || !stakingAmount) return;
    if (selectedMainNft.isCyanWallet && !cyanWallet) return;
    await giveErc20Approval(
      {
        currencyAddress: apeCoinContract,
        cyanWallet: selectedMainNft.isCyanWallet && cyanWallet ? cyanWallet : undefined,
        amount: stakingAmount,
      },
      apeStakingContract,
    );
  };

  const stakeApeCoin = async () => {
    if (!account || !stakingAmount || !signer) return;
    const apeStakingIFace = f.ApeCoinStakingFactory.createInterface();
    const apeStakingContractWriter = f.ApeCoinStakingFactory.connect(apeStakingContract, signer);
    const isBAYC = selectedMainNft.address.toLowerCase() === BAYCAddress.toLowerCase();
    let tx: ethers.ContractTransaction;
    if (selectedMainNft.address.toLowerCase() === BAKCAddress.toLowerCase()) {
      if (!selectedPairingNft) {
        throw new Error("Must choose NFT to pair stake with BAKC");
      }
      const params = {
        mainTokenId: selectedPairingNft.tokenId,
        bakcTokenId: selectedMainNft.tokenId,
        amount: stakingAmount,
      };
      if (selectedMainNft.isCyanWallet) {
        if (!cyanWallet) return;
        const encodedFnData = apeStakingIFace.encodeFunctionData("depositBAKC", [
          isBAYC ? [params] : [],
          isBAYC ? [] : [params],
        ]);
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
          apeStakingContract,
          "0",
          encodedFnData,
        ]);
        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
      } else {
        tx = await apeStakingContractWriter.depositBAKC(isBAYC ? [params] : [], isBAYC ? [] : [params]);
      }
    } else {
      const params = [{ tokenId: selectedMainNft.tokenId, amount: stakingAmount }];
      if (selectedMainNft.isCyanWallet) {
        if (!cyanWallet) return;
        const encodedFnData = isBAYC
          ? apeStakingIFace.encodeFunctionData("depositBAYC", [params])
          : apeStakingIFace.encodeFunctionData("depositMAYC", [params]);
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
          apeStakingContract,
          "0",
          encodedFnData,
        ]);
        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
      } else {
        tx = isBAYC
          ? await apeStakingContractWriter.depositBAYC(params)
          : await apeStakingContractWriter.depositMAYC(params);
      }
    }
    addTransaction({
      hash: tx.hash,
      type: "ape-stake",
    });
    setActiveTx(tx.hash);
  };

  useEffect(() => {
    const onStepChange = async (step: StakingSteps) => {
      try {
        switch (step) {
          case StakingSteps.TranferApe:
            await transfer();
            setSelectedStep(StakingSteps.Accept);
            break;
          case StakingSteps.Accept:
            await accept();
            setSelectedStep(StakingSteps.Stake);
            break;
          case StakingSteps.Stake:
            await stakeApeCoin();
            break;
        }
      } catch (err: any) {
        if (err?.error?.message?.match(/Not enough \w+ in the Vault/g)) {
          const _sendVaultError = async () => {
            if (!account || !provider) return;
            const vaultBalance = await getVaultBalance({ vaultAddress: apeVaultContract, provider });

            await notifyApeVaultBalanceError({
              vault: apeVaultContract,
              user: account,
              vaultBalance: vaultBalance.toString(),
            });
          };
          _sendVaultError().catch(e => console.error(e));
        }
        openRepaymentModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    showPlanModal({
      ...params,
      err: mappedError,
    });
  };
  const steps = useMemo(() => {
    return StakingStepMarks.map(step => {
      if (step.value === StakingSteps.TranferApe) {
        return !selectedMainNft.isCyanWallet
          ? {
              ...step,
              title: `Move $APE from Cyan Wallet`,
            }
          : {
              ...step,
              title: `Move $APE from Main Wallet`,
              description: `Move $APE from your Main Wallet to your Cyan Wallet.`,
            };
      } else {
        return {
          ...step,
        };
      }
    });
  }, [selectedMainNft.isCyanWallet]);

  return (
    <Flex gap="1rem" direction="column">
      <ApeCoinBalanceLoading
        tokenId={selectedMainNft.tokenId}
        collectionName={COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS[selectedMainNft.address.toLowerCase()]}
        stakingAmount={stakingAmount ? bigNumToFixedStr(stakingAmount, 2) : "0"}
        imageUrl={selectedMainNft.imageUrl}
        isLoading={selectedStep != StakingSteps.Done}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={steps}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep == StakingSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum StakingSteps {
  TranferApe = 1,
  Accept = 2,
  Stake = 3,
  Done = 4,
}
const StakingStepMarks = [
  {
    value: StakingSteps.TranferApe,
    title: `Transfer $APE from Cyan Wallet`,
    description: `A small amount of gas is required to move $APE`,
  },
  {
    value: StakingSteps.Accept,
    title: `Give Permission to Move ApeCoin`,
    description: `Permission to move my ApeCoin to stake into ApeCoin pools.`,
  },
  {
    value: StakingSteps.Stake,
    title: `$APE successfully staked`,
    description: `A small amount of gas is required to stake $APE`,
  },
];
