import orderBy from "lodash.orderby";
import { FC, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { SubTitle } from "@cyanco/components/theme/v3";

import { IVault } from "@/apis/vault/types";
import { isMobile } from "@/utils/userAgent";

import { IPriceChartIntervalType } from "../../VaultPageContext";
import { PriceGraph } from "./PriceGraph";

interface UtilizationRateChartProps {
  history: IVault["history"];
}

export const UtilizationRateChart: FC<UtilizationRateChartProps> = ({ history }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<IPriceChartIntervalType>("365day");
  return (
    <Container direction="column" gap="1.5rem" mt="4.5rem">
      <SubTitle>{`Utilization Rate of Vault`}</SubTitle>
      <Flex direction="column" w="100%">
        <PriceGraph
          history={orderBy(
            history.map(snapshot => ({
              createdAt: snapshot.createdAt,
              price: snapshot.utilizationRate * 100,
            })),
            "createdAt",
            "asc",
          )}
          showYAxis={isMobile ? false : true}
          yAxisLabel={"Utilization Rate"}
          yAxisTickFormatter={value => `${value > 99 ? 100 : value.toFixed(0)}%`}
          yAxisX={-15}
          yAxisFontSize={"16px"}
          formatNumber={2}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          symbol={"%"}
        />
      </Flex>
    </Container>
  );
};

const Container = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0 5px" }])}
`;
