import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { Loader, Text } from "@cyanco/components/theme/v3";

import { NoImage } from "../images";

export const NftMetadataInlineImage = ({ imageUrl, loading }: { imageUrl?: string; loading?: boolean }) => {
  return (
    <div style={{ position: "relative" }}>
      <StyledNftImage src={imageUrl || NoImage} alt="nft" hasImage={!!imageUrl} />
      {loading && (
        <LoadingImage>
          <Loader size="60%" stroke="white" />
        </LoadingImage>
      )}
    </div>
  );
};

export const NftMetadataInline = ({ sub, name, value }: { name: string; value: string; sub?: boolean }) => {
  return (
    <Flex justifyContent="space-between">
      {sub ? (
        <>
          <Text color="gray0" size="xs" weight="500">
            {name}
          </Text>
          <Text color="gray0" size="xs" weight="500">
            {value}
          </Text>
        </>
      ) : (
        <>
          <Text color="secondary" size="md" style={{ overflowWrap: "anywhere" }} weight="600">
            {name}
          </Text>
          <Text color="secondary" size="md" style={{ overflowWrap: "anywhere" }} weight="600">
            {value}
          </Text>
        </>
      )}
    </Flex>
  );
};

export const StyledNftImage = styled.img<{ hasImage?: boolean }>`
  height: 85px;
  width: 85px;
  object-fit: scale-down;
  border: none;
  border-radius: 15px;
  padding: 0;
  margin: 0;
  background-color: black;
  filter: ${({ hasImage, theme }) =>
    !hasImage &&
    theme.theme === "light" &&
    "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;
const LoadingImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 85px;
  background-color: black;
  border-radius: 15px;
  opacity: 0.5;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
