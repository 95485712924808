import styled, { Colors } from "styled-components";

export const TextSizes = {
  xxs: "10px",
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "24px",
  xxl: "36px",
};

export type ITextSizes = keyof typeof TextSizes;
type ITextColors = keyof Colors;

export const Text = styled.span<{
  size?: ITextSizes;
  color?: ITextColors;
  weight?: string;
  textAlign?: "left" | "center" | "right";
  lineHeight?: number;
  textWrap?: boolean;
}>`
  font-family: Inter;
  font-size: ${({ size }) => TextSizes[size ?? "sm"]};
  text-align: ${({ textAlign }) => textAlign};
  font-weight: ${({ weight }) => (weight ? weight : 400)};
  color: ${({ color, theme }) => theme.colors[color ?? "primary"]};
  white-space: ${({ textWrap = true }) => (textWrap ? "normal" : "nowrap")};
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : "normal")};
  margin: 0;
  padding: 0;
`;

export const TextLink = styled.a<{
  size?: ITextSizes;
  color?: ITextColors;
  weight?: string;
  textAlign?: "left" | "center" | "right";
  lineHeight?: number;
  textWrap?: boolean;
}>`
  font-family: Inter;
  font-size: ${({ size }) => TextSizes[size ?? "sm"]};
  text-align: ${({ textAlign }) => textAlign};
  font-weight: ${({ weight }) => (weight ? weight : 400)};
  color: ${({ color, theme }) => theme.colors[color ?? "primary"]};
  white-space: ${({ textWrap = true }) => (textWrap ? "normal" : "nowrap")};
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : "normal")};
  margin: 0;
  padding: 0;
`;
