import dayjs from "dayjs";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IP2PLoanOffer } from "@/apis/p2p/types";
import { IUserNft } from "@/apis/user/types";
import { useAppContext } from "@/components/AppContextProvider";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { bigNumToFloat, displayInUSD, numberWithCommas, shortenAddress, shortenName } from "@/utils";

export const LoanOfferRowLoading = () => {
  return (
    <Row>
      <Flex alignItems="center" gap="8px">
        <StyledImgLoading />
        <RowLoadingText />
      </Flex>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <Flex gap="2px" direction="column">
        <RowLoadingText />
        <RowLoadingText sub />
      </Flex>
      <RowLoadingText />
      <RowLoadingText />
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
    </Row>
  );
};

export const LoanOfferHeader = () => {
  return (
    <HeaderContainer>
      <HeaderText color="secondary">{`NFT`}</HeaderText>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Lender`}</HeaderText>
      </Hidden>
      <HeaderText color="secondary">{`Loan Amount`}</HeaderText>
      <HeaderText color="secondary">{`Duration`}</HeaderText>
      <HeaderText color="secondary">{`Interest`}</HeaderText>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Expiry`}</HeaderText>
      </Hidden>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Borrow`}</HeaderText>
      </Hidden>
    </HeaderContainer>
  );
};

type IProps = {
  blockchainTimestamp: number;
  nft: IUserNft;
  loanBid: Omit<IP2PLoanOffer, "metadata" | "collectionAddress" | "tokenId">;
  onClickBorrow: () => void;
  onClick: () => void;
};
export const LoanOfferRow: React.FC<IProps> = ({ blockchainTimestamp, nft, loanBid, onClickBorrow, onClick }) => {
  const { usdPrice } = useAppContext();
  const [imageLoading, setImageLoading] = useState(true);
  const loanAmount = useMemo(() => {
    return `${numberWithCommas(bigNumToFloat(loanBid.amount, loanBid.currency.decimal), 2)} ${loanBid.currency.symbol}`;
  }, [loanBid]);
  const loanAmountInUsd = useMemo(() => {
    return `${displayInUSD(
      bigNumToFloat(loanBid.amount, loanBid.currency.decimal) * usdPrice[loanBid.currency.symbol],
    )}`;
  }, [loanBid, usdPrice]);
  const expiry = useMemo(() => {
    const dueDate = dayjs(loanBid.signatureExpiry);
    const difference = dueDate.diff(dayjs(blockchainTimestamp), "second");
    return getPaymentInterval(difference);
  }, [loanBid, blockchainTimestamp]);
  const apr = useMemo(() => {
    return (365 / (loanBid.term / 60 / 60 / 24)) * (loanBid.interestRate / 100);
  }, [loanBid.interestRate]);
  return (
    <Row onClick={onClick}>
      <Flex alignItems="center" gap="1rem">
        {imageLoading && (
          <ImageLoader>
            <SkeletonLine w="100%" h="100%" borderRadius="10px" />
          </ImageLoader>
        )}
        <StyledImg
          src={nft.imageUrl || NoImage}
          alt={nft.collectionName}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
          }}
          hasImage={!!nft.imageUrl}
        />
        <Flex direction="column">
          <RowText color={"secondary"} weight="500">
            #{shortenName(nft.tokenId, 10, 6)}
          </RowText>
          <Flex gap="5px">
            <RowText color={"gray0"} sub>
              {shortenName(nft.collectionName)}
            </RowText>
          </Flex>
        </Flex>
      </Flex>
      <Hidden tabletDown>
        <RowText color="secondary" weight="500">
          {shortenAddress(loanBid.lenderAddress)}
        </RowText>
      </Hidden>

      <Flex direction="column">
        <RowText color={"secondary"}>{loanAmount}</RowText>
        <RowText color="gray0" sub>
          {loanAmountInUsd}
        </RowText>
      </Flex>

      <Flex direction="column">
        <RowText color={"secondary"}>{getPaymentInterval(loanBid.term)}</RowText>
        <RowText color="gray0" sub>
          {dayjs().add(loanBid.term, "seconds").format("DD MMMM, YYYY")}
        </RowText>
      </Flex>
      <Flex direction="column">
        <RowText color={"secondary"}>{loanBid.interestRate / 100}%</RowText>
        <RowText color="gray0" sub>
          {apr.toFixed(2)}%
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <RowText color="secondary" weight="500">
          {expiry}
        </RowText>
      </Hidden>
      <Hidden tabletDown>
        <Flex w="fit-content">
          <SmallButton
            onClick={e => {
              e.stopPropagation();
              onClickBorrow();
            }}
          >
            <BorrowBox>
              <SmallButtonText textWrap={false} weight={"700"} color="black">
                {`Borrow`} {nft.currency.symbol}
              </SmallButtonText>
            </BorrowBox>
          </SmallButton>
        </Flex>
      </Hidden>
    </Row>
  );
};

const Row = styled.div<{ selected?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  grid-template-columns: 2fr 1.5fr 1.5fr 1.7fr 1fr 1fr 1fr;
  padding: 13px 15px 13px 15px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.gray10 : "transparent")};
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "1.5fr 1.3fr 1fr 1fr" }])};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 10px 14px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 14 },
    { [breakpoints.laptop]: 12 },
    { [breakpoints.tablet]: 12 },
  ])};
`;

const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

const HeaderContainer = styled(Row)`
  padding: 14px 19px 12px 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "14px 19px 12px 18px" },
    { [breakpoints.laptop]: "14px 10px 12px 40px" },
    { [breakpoints.tablet]: "10px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
`;

const StyledImg = styled.img<{ hasImage?: boolean }>`
width: 36px;
${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
border-radius: ${({ theme }) => theme.borderRadius};
filter: ${({ hasImage, theme }) =>
  !hasImage &&
  theme.theme === "light" &&
  "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
} `;

const RowLoadingText = styled(SkeletonLine)<{ sub?: boolean }>`
  width: 50%;
  height: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("height", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])};
`;
const StyledImgLoading = styled(SkeletonLine)`
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  ${getStyleWithMediaQuery("min-width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("min-height", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("max-height", "px", [{ [breakpoints.mobile]: 24 }])}
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const ImageLoader = styled.div`
  width: 36px;
  height: 36px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 24 }])}
`;

const SmallButton = styled(Button)`
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: ${({ disabled }) => !disabled && "#79FFFF"};
    border-color: ${({ disabled }) => !disabled && "#79FFFF"};
  }
`;
const BorrowBox = styled(Flex)`
  padding: 0.2rem;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0.1rem" }])}
`;
const SmallButtonText = styled(Text)`
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 12 }, { [breakpoints.tablet]: 10 }])};
`;
