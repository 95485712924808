import "@reach/dialog/styles.css";
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { Web3ReactProvider as Web3ReactProviderOrig } from "@web3-react/core";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { CyanWalletContextProvider } from "@usecyan/cyan-wallet";

import { AccountContextWrapperV3 } from "./components/Account/ContextWrapper";
import { ApeCoinDataProvider } from "./components/ApeCoinStaking/new/ApeCoinDataContext";
import { ApeCoinProvider } from "./components/ApeCoinStaking/new/ApeCoinPageContext";
import { ApeCoinStatsProvider } from "./components/ApeCoinStaking/new/ApeCoinStatsContext";
import { AppContextProvider } from "./components/AppContextProvider";
import { AuthContextProvider } from "./components/AuthContext/AuthContextProvider";
import { BargainDataProvider } from "./components/BargainShop/BargainDataContext";
import { BnplContextWrapperV3 } from "./components/Bnpl";
import { PointDataProvider } from "./components/CyanPoints/PointDataContext";
import { HomePageContextProvider } from "./components/HomeTopPage/context";
import { LoanPageProvider } from "./components/Loans/LoanPageProvider";
import { ThemeContextProvider } from "./components/ThemeContextProvider";
import { VaultContextWrapperV3 } from "./components/Vault/ContextWrapper";
import { Web3ReactProvider } from "./components/Web3ReactProvider";
import { connectors } from "./connectors";
import App from "./pages/App";
import store from "./state";

// eslint-disable-next-line
declare let window: any;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  tracesSampleRate: 1.0,
});

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Web3ReactProviderOrig connectors={connectors}>
          <Web3ReactProvider>
            <ThemeContextProvider>
              <AppContextProvider>
                <CyanWalletContextProvider>
                  <AuthContextProvider>
                    <VaultContextWrapperV3>
                      <BnplContextWrapperV3>
                        <AccountContextWrapperV3>
                          <LoanPageProvider>
                            <ApeCoinProvider>
                              <ApeCoinDataProvider>
                                <BargainDataProvider>
                                  <PointDataProvider>
                                    <ApeCoinStatsProvider>
                                      <HomePageContextProvider>
                                        <App />
                                      </HomePageContextProvider>
                                    </ApeCoinStatsProvider>
                                  </PointDataProvider>
                                </BargainDataProvider>
                              </ApeCoinDataProvider>
                            </ApeCoinProvider>
                          </LoanPageProvider>
                        </AccountContextWrapperV3>
                      </BnplContextWrapperV3>
                    </VaultContextWrapperV3>
                  </AuthContextProvider>
                </CyanWalletContextProvider>
              </AppContextProvider>
            </ThemeContextProvider>
          </Web3ReactProvider>
        </Web3ReactProviderOrig>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
