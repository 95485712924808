import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { notifyApeCoinActivity, notifyApeVaultBalanceError } from "@/apis/notification";
import { IResponseData, createApeCoinAcceptance, priceApeCoinStaking } from "@/apis/pricer/pricer-ape-coin";
import { ApeCoinBalance } from "@/components/ApeCoinStaking/CommonComponents";
import { useAppContext } from "@/components/AppContextProvider";
import { useUserTokenContext } from "@/components/Token/TokenContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apeCoinContract, apePaymentPlanContract, apeStakingContract, apeVaultContract } from "@/config";
import { signNewPlanSignature, useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IInitiateApeCoinStake } from "@/hooks/useApePlanCreation.types";
import { useApproval } from "@/hooks/useApproval";
import { getChainExplorerURL } from "@/utils";
import { getVaultBalance } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";
import { IActionType } from "../../../types";

export const StakingApeCoin = (params: IInitiateApeCoinStake) => {
  const { activePlan, mainWalletAmount, cyanWalletAmount, rewardStakeToCyanVault, apeCoinBalance } = params;
  const { unsetModal } = useModal();
  const { setFireConfetti } = useAppContext();
  const { getErc20ApprovalFunctionData } = useApproval();
  const { refreshUserTokens } = useUserTokenContext();
  const { transactions, addTransaction } = useTransactionContext();
  const { cyanWallet, createNewWallet } = useCyanWalletContext();
  const { updateUserBalance } = useApeCoinStatsContext();
  const { showApeCoinPlanModal } = useApePlanCreation();
  const { chainId, account, provider, signer } = useWeb3React();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [pricedResult, setPricedResult] = useState<IResponseData>();
  const [isWalletCreated, setIsWalletCreated] = useState(false);

  const stakingAmount = useMemo(() => {
    return ethers.utils.parseEther((mainWalletAmount + cyanWalletAmount).toString());
  }, [mainWalletAmount, cyanWalletAmount]);

  const isTransferRequired = mainWalletAmount !== null && mainWalletAmount > 0;
  let initialStep = StakingSteps.CreatingCyanWallet;
  if (!cyanWallet) initialStep = StakingSteps.CreatingCyanWallet;
  else if (activePlan) {
    if (isTransferRequired) initialStep = StakingSteps.TransferApe;
    else initialStep = StakingSteps.Staking;
  } else {
    initialStep = StakingSteps.Sign;
  }
  const [selectedStep, setSelectedStep] = useState<StakingSteps>(initialStep);
  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        updateUserBalance();
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setFireConfetti(true);
        setSelectedStep(StakingSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const signPlan = async () => {
    if (!signer) throw new Error("Something went wrong");
    const result = await priceApeCoinStaking({
      items: [
        {
          amount: stakingAmount,
        },
      ],
      wallet: await signer.getAddress(),
      rewardStakeToCyanVault,
    });
    const signature = await signNewPlanSignature(signer, result);
    await createApeCoinAcceptance({
      planIds: result.map(({ planId }) => planId),
      signature,
    });
    setPricedResult(result[0]);
  };

  const stakeApeCoin = async () => {
    if (!account || !signer || !activePlan) return;
    const apeCoinStakingContract = f.ApeCoinStakingFactory.connect(apeStakingContract, signer);
    let tx: ethers.ContractTransaction;
    if (cyanWallet) {
      const apeCoinStakingIFace = f.ApeCoinStakingFactory.createInterface();
      const encodedAllowanceFnDataFormatted = await getErc20ApprovalFunctionData(
        {
          amount: stakingAmount,
          cyanWallet,
          currencyAddress: apeCoinContract,
        },
        apeStakingContract,
      );

      const encodedDepositApeCoinFnData = apeCoinStakingIFace.encodeFunctionData("depositApeCoin", [
        stakingAmount,
        cyanWallet.walletAddress,
      ]);

      const encodedDepositApeCoinFnDataFormatted = [apeStakingContract, 0, encodedDepositApeCoinFnData];
      if (encodedAllowanceFnDataFormatted) {
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
          [encodedAllowanceFnDataFormatted, encodedDepositApeCoinFnDataFormatted],
        ]);

        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
      } else {
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData(
          "execute",
          encodedDepositApeCoinFnDataFormatted,
        );
        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
      }
    } else {
      tx = await apeCoinStakingContract.depositApeCoin(stakingAmount, account);
    }

    addTransaction({ hash: tx.hash, type: "ape-stake" });
    await tx.wait();
    setActiveTx(tx.hash);
    notifyApeCoinActivity({
      amount: stakingAmount.toString(),
      user: account,
      action: "Staked with ERC20",
      token: "erc20",
    });
    refreshUserTokens();
  };

  const createApePlan = async () => {
    if (!signer || !pricedResult) throw new Error("Something went wrong");
    const contract = f.CyanApeCoinPlanV1Factory.connect(apePaymentPlanContract, signer);
    const tx = await contract.createApeCoinPlan(
      pricedResult.planId,
      pricedResult.amount,
      pricedResult.rewardStakeToCyanVault,
      pricedResult.signedBlockNumber,
      pricedResult.signature,
    );
    addTransaction({
      hash: tx.hash,
      type: "ape-coin-plan-create",
      data: {
        planId: pricedResult.planId,
      },
    });
    setActiveTx(tx.hash);
  };

  const transfer = async () => {
    if (!signer || !account || !cyanWallet) return;
    if (mainWalletAmount > 0) {
      const contractWriter = f.SampleERC20TokenFactory.connect(apeCoinContract, signer);
      const tx = await contractWriter.transfer(
        cyanWallet.walletAddress,
        ethers.utils.parseEther(mainWalletAmount.toString()),
      );
      await tx.wait();
    }
  };

  useEffect(() => {
    const onStepChange = async (step: StakingSteps) => {
      if (!chainId || !account || !signer) return;
      try {
        switch (step) {
          case StakingSteps.CreatingCyanWallet: {
            if (!cyanWallet) {
              await createNewWallet(signer);
              setIsWalletCreated(true);
            }
            if (!activePlan) setSelectedStep(StakingSteps.Sign);
            else setSelectedStep(isTransferRequired ? StakingSteps.TransferApe : StakingSteps.Staking);
            return;
          }
          case StakingSteps.Sign: {
            await signPlan();
            setSelectedStep(isTransferRequired ? StakingSteps.TransferApe : StakingSteps.Staking);
            return;
          }
          case StakingSteps.TransferApe: {
            await transfer();
            setSelectedStep(StakingSteps.Staking);
            return;
          }
          case StakingSteps.Staking: {
            if (!activePlan) await createApePlan();
            else await stakeApeCoin();
            return;
          }
        }
      } catch (err: any) {
        if (err?.error?.message?.match(/Not enough \w+ in the Vault/g)) {
          const _sendVaultError = async () => {
            if (!account || !provider) return;
            const vaultBalance = await getVaultBalance({ vaultAddress: apeVaultContract, provider });

            await notifyApeVaultBalanceError({
              vault: apeVaultContract,
              user: account,
              vaultBalance: vaultBalance.toString(),
            });
          };
          _sendVaultError().catch(e => console.error(e));
        }
        openRepaymentModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    showApeCoinPlanModal({
      action: IActionType.stake,
      err: mappedError,
    });
  };
  const stepMarkFiltered = useMemo(() => {
    return StakingStepMarks.filter(item => {
      if (item.value == StakingSteps.Sign && activePlan) {
        return false;
      }
      if (cyanWallet && item.value === StakingSteps.CreatingCyanWallet && !isWalletCreated) {
        return false;
      }
      if (item.value === StakingSteps.TransferApe && !isTransferRequired) {
        return false;
      }
      return true;
    }).map(item => {
      if (item.value === StakingSteps.Staking && txnFinal !== null) {
        return {
          ...item,
          description: `View on Etherscan`,
        };
      }
      return item;
    });
  }, [cyanWallet, isWalletCreated, txnFinal, isTransferRequired, activePlan]);

  return (
    <Flex gap="1rem" direction="column">
      <ApeCoinBalance stakedAmount={apeCoinBalance.stakedAmount} earnedAmount={apeCoinBalance.earnedAmount} />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === StakingSteps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};

enum StakingSteps {
  CreatingCyanWallet = 1,
  Sign = 2,
  TransferApe = 3,
  Staking = 4,
  Done = 5,
}

const StakingStepMarks = [
  {
    value: StakingSteps.CreatingCyanWallet,
    description: `A new wallet is created on the first loan on Cyan`,
    title: `Creating Cyan Wallet`,
  },
  {
    value: StakingSteps.Sign,
    title: `User Accepts Terms & Conditions`,
    description: `Approve this in your wallet, no gas required`,
  },
  {
    value: StakingSteps.TransferApe,
    title: `Transfer $APE to Cyan Wallet`,
    description: `A small amount of gas is required to move $APE`,
  },
  {
    value: StakingSteps.Staking,
    title: `Staking $APE`,
    description: `A small amount of gas is required to stake $APE`,
  },
];

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
