import { Logger } from "ethers/lib/utils";

import { factories as f } from "@cyanco/contract";

import { CONTACT_US, IMappedError, NOT_SUPPORTED_METHOD, REQUEST_MORE_INFO, defaultError } from "./msgs";

const apeCoinStakingErrorsMapped: Record<string, string> = {
  DepositMoreThanOneAPE: "Deposit more than one APE",
  InvalidPoolId: "Invalid pool ID",
  StartMustBeGreaterThanEnd: "Start must be greater than end",
  StartNotWholeHour: "Start not whole hour",
  EndNotWholeHour: "End not whole hour",
  StartMustEqualLastEnd: "Start must equal last end",
  CallerNotOwner: "Caller is not owner",
  MainTokenNotOwnedOrPaired: "Main token not owned or paired",
  BAKCNotOwnedOrPaired: "BAKC not owned or paired",
  BAKCAlreadyPaired: "BAKC already paired",
  ExceededCapAmount: "Exceeded cap amount",
  NotOwnerOfMain: "Not owner of main token",
  NotOwnerOfBAKC: "Not owner of BAKC",
  ProvidedTokensNotPaired: "Provided tokens are not paired",
  ExceededStakedAmount: "Exceeded staked amount",
  NeitherTokenInPairOwnedByCaller: "Neither token in pair owned by caller",
  SplitPairCantPartiallyWithdraw: "Split pair can't partially withdraw",
  UncommitWrongParameters: "Uncommit wrong parameters",
};
const paymentPlanErrorsMapped: Record<string, string> = {
  InvalidSender: "Invalid sender",
  InvalidBlockNumber: "Invalid block number",
  InvalidSignature: "Invalid signature",
  InvalidServiceFeeRate: "Invalid service fee rate",
  InvalidTokenPrice: "Invalid token price",
  InvalidInterestRate: "Invalid interest rate",
  InvalidDownPaymentPercent: "Invalid down payment percent",
  InvalidDownPayment: "Invalid down payment",
  InvalidAmount: "Invalid amount",
  InvalidTerm: "Invalid term",
  InvalidTotalNumberOfPayments: "Invalid total number of payments",
  InvalidPaidCount: "Invalid paid count",
  InvalidStage: "Invalid stage",
  InvalidAddress: "Invalid address",
  InvalidAutoRepaymentDate: "Invalid auto repayment date",
  InvalidAutoRepaymentStatus: "Invalid auto repayment status",
  InvalidReviveDate: "Invalid revive date",
  InvalidPay: "Invalid pay",
  InvalidItem: "Invalid item",
  PaymentPlanAlreadyExists: "Payment plan already exists",
  PaymentPlanNotFound: "Payment plan not found",
  ArraySizesNotEqual: "Array sizes not equal",
};
const apeCoinPlanErrorsMapped: Record<string, string> = {
  InvalidSignature: "Invalid signature",
  InvalidAddress: "Invalid address",
  InvalidRate: "Invalid rate",
  PlanAlreadyExists: "Plan already exists",
  InvalidBlockNumber: "Invalid block number",
  LoanAmountExceedsPoolCap: "Loan amount exceeds pool cap",
};
export const apeCoinActionErrorsMapped: Record<string, string> = {
  TokenIsLocked: "Cannot perform this action on locked token",
  AlreadyInLockState: "Already in locked/unlocked token",
  NotPaired: "Not paired tokens",
  ApeCoinStakingIsLocked: "ApeCoin staking is locked",
  ApeCoinNotLockedByDam: "ApeCoin not locked by DAM",
};

export const revertedErrorsWithCustomMessages = [
  {
    contractMsg: "Withdrawal locked",
    error: {
      title: "Withdrawal Not Possible",
      msg: "Vault token deposit period has not completed yet. Please wait until the deposit period completes.",
      description: REQUEST_MORE_INFO,
    },
  },
  {
    contractMsg: "Not enough active balance in the Vault",
    error: {
      title: "Withdrawable balance of the vault is not enough",
      msg: "The withdrawable balance of the vault is not enough to unstake the requested amount. Please try again later.",
      description: REQUEST_MORE_INFO,
    },
  },
  {
    contractMsg: "Not enough balance in the Vault",
    error: {
      title: "Not enough balance in the Vault",
      msg: "There is not enough liquidity in the vaults to process this loan. Please try again later.",
      description: REQUEST_MORE_INFO,
    },
  },
  {
    contractMsg: "Must deposit more than zero",
    error: {
      title: "Deposit more than zero",
      msg: "You must deposit more than zero tokens to stake.",
      description: REQUEST_MORE_INFO,
    },
  },
  {
    contractMsg: "Not supported method",
    error: {
      title: "Not supported method",
      msg: "The method you are trying to call is not supported by the cyan wallet.",
      description: NOT_SUPPORTED_METHOD,
    },
  },
  {
    contractMsg: "Cannot perform this action on locked token",
    error: {
      title: "Locked token",
      msg: "The token you are trying to perform this action on is locked by cyan wallet.",
      description: REQUEST_MORE_INFO,
    },
  },
  {
    contractMsg: "Cannot perform this action on locked token or balance not enough",
    error: {
      title: "Locked token or balance not enough",
      msg: "This error occurs when the token you are trying to perform this action on is locked by cyan wallet or the balance is not enough.",
      description: REQUEST_MORE_INFO,
    },
  },
  {
    contractMsg: "Token has ape lock",
    error: {
      title: "Locked token",
      msg: "The token you are trying to perform this action on has an APE lock. To continue this action, please complete APE plan first.",
      description: REQUEST_MORE_INFO,
    },
  },
  {
    contractMsg: "CyanApeCoinPlan: plan is not ApeCoin plan",
    error: {
      title: "Plan's pool ID is not matching",
      msg: "The plan you are trying to perform this action on is not an ApeCoin plan.",
      description: REQUEST_MORE_INFO,
    },
  },
  {
    contractMsg: "CyanApeCoinPlan: plan is ApeCoin plan",
    error: {
      title: "Plan's pool ID is not matching",
      msg: "The plan you are trying to perform this action on is an ApeCoin plan.",
      description: REQUEST_MORE_INFO,
    },
  },
  {
    contractMsg: "Token already in given state",
    error: {
      title: `Transaction Reverted`,
      msg: `A new transaction cannot be performed on an NFT with a pending transaction.`,
      description: REQUEST_MORE_INFO,
    },
  },
];

export const errorCodesMapped: Record<string, IMappedError> = {
  [Logger.errors.INSUFFICIENT_FUNDS]: {
    title: "Insufficient balance to start transaction",
    msg: "You don’t have enough balance in your wallet to start transaction.",
  },
  [Logger.errors.ACTION_REJECTED]: {
    title: "Transaction Rejected by User",
    msg: "Please approve the transaction in your wallet to continue.",
  },
  [Logger.errors.NETWORK_ERROR]: {
    title: "Network Error",
    msg: "Please refresh the page and try again.",
    description: REQUEST_MORE_INFO,
  },
  [Logger.errors.SERVER_ERROR]: {
    title: "Server Error",
    msg: "Please refresh the page and try again.",
    description: REQUEST_MORE_INFO,
  },
  [Logger.errors.NONCE_EXPIRED]: {
    title: "Nonce expired",
    msg: "Please refresh the page and try again.",
    description: REQUEST_MORE_INFO,
  },
  [Logger.errors.TIMEOUT]: {
    title: "Timeout",
    msg: "Please refresh the page and try again.",
    description: REQUEST_MORE_INFO,
  },
  [Logger.errors.TRANSACTION_REPLACED]: {
    title: "Transaction Replaced",
    msg: "The transaction was replaced by one with a higher gas price.",
    description: REQUEST_MORE_INFO,
  },
  [4001]: {
    title: "Transaction Rejected by User",
    msg: "Please approve the transaction in your wallet to continue.",
  },
  [4100]: {
    title: "UnauthorizedError",
    msg: "The requested method/account is not authorized by the user.",
    description: CONTACT_US,
  },
  [4200]: {
    title: "UnsupportedMethodError",
    msg: "The requested method is not supported by the provider.",
    description: CONTACT_US,
  },
  [4901]: {
    title: "ChainDisconnectedError",
    msg: "The provider is disconnected from the requested chain. Please refresh the page and try again.",
    description: REQUEST_MORE_INFO,
  },
  [4900]: {
    title: "DisconnectedError",
    msg: "The provider is disconnected. Please refresh the page and try again.",
    description: REQUEST_MORE_INFO,
  },
  [-32005]: {
    title: "LimitExceededError",
    msg: "A limit has been exceeded. Please refresh the page and try again.",
    description: REQUEST_MORE_INFO,
  },
  [-32700]: {
    title: "A request is not valid JSON.",
    msg: CONTACT_US,
  },
};

const parseErrorPaymentPlan = (revertData: any): IMappedError | undefined => {
  const iface = f.PaymentPlanV2Factory.createInterface();
  try {
    const msg = iface.parseError(revertData).name;
    const errorTitle = paymentPlanErrorsMapped[msg];
    if (errorTitle) {
      return {
        title: errorTitle,
        msg: CONTACT_US,
      };
    }
  } catch (e) {}
};

const parseErrorApeCoinPlan = (revertData: any): IMappedError | undefined => {
  const iface = f.CyanApeCoinPlanV1Factory.createInterface();
  try {
    const msg = iface.parseError(revertData).name;
    const errorTitle = apeCoinPlanErrorsMapped[msg];
    if (errorTitle) {
      return {
        title: errorTitle,
        msg: CONTACT_US,
      };
    }
  } catch (e) {}
};

const parseErrorApeCoinStaking = (revertData: any): IMappedError | undefined => {
  const iface = f.ApeCoinStakingFactory.createInterface();
  try {
    const msg = iface.parseError(revertData).name;
    const errorTitle = apeCoinStakingErrorsMapped[msg];
    if (errorTitle) {
      return {
        title: errorTitle,
        msg: CONTACT_US,
      };
    }
  } catch (e) {}
};

export const mapContractError = (error: any): IMappedError | undefined => {
  const errorCode = error?.code;
  const errorCodeFromWallet = error?.error?.code;
  const errorMessage = error?.message;
  const errorMessageFromWallet = error?.error?.message;
  if (errorMessage || errorMessageFromWallet) {
    const errorObject = revertedErrorsWithCustomMessages.find(errorObject =>
      (errorMessage ?? errorMessageFromWallet).includes(errorObject.contractMsg),
    );
    if (errorObject) {
      return errorObject.error;
    }
  }
  if (errorCode || errorCodeFromWallet) {
    const errorObject = errorCodesMapped[errorCode ?? errorCodeFromWallet];
    if (errorObject) {
      return errorObject;
    }
  }
  const revertData = error?.error?.data?.originalError?.data;

  const paymentPlanError = parseErrorPaymentPlan(revertData);
  if (paymentPlanError) {
    return paymentPlanError;
  }
  const apeCoinPlanError = parseErrorApeCoinPlan(revertData);
  if (apeCoinPlanError) {
    return apeCoinPlanError;
  }
  const apeCoinStakingError = parseErrorApeCoinStaking(revertData);
  if (apeCoinStakingError) {
    return apeCoinStakingError;
  }
  const errorReturning: IMappedError = defaultError;
  if (errorCode) {
    switch (errorCode) {
      case Logger.errors.NUMERIC_FAULT: {
        errorReturning.title = "Numeric fault";
        if (error?.fault) {
          errorReturning.msg = error?.fault;
          errorReturning.description = CONTACT_US;
        }
        return errorReturning;
      }
      case Logger.errors.UNSUPPORTED_OPERATION: {
        errorReturning.title = "Unsupported operation";
        if (error?.operation) {
          errorReturning.msg = `Unsupported operation: ${error.operation}`;
          errorReturning.description = CONTACT_US;
        }
        return errorReturning;
      }
      case Logger.errors.MISSING_NEW: {
        errorReturning.title = "Missing new operator to an object";
        if (error?.name) {
          errorReturning.msg = `Missing new operator to an object: ${error.name}`;
          errorReturning.description = CONTACT_US;
        }
        break;
      }
      case Logger.errors.INVALID_ARGUMENT: {
        errorReturning.title = "Invalid argument";
        if (error?.argument && error?.value) {
          errorReturning.msg = `Invalid argument: ${error.argument} with value: ${error.value}`;
          errorReturning.description = CONTACT_US;
        }
        break;
      }
      case Logger.errors.MISSING_ARGUMENT: {
        errorReturning.title = "Missing argument";
        if (error?.count && error?.expectedCount) {
          errorReturning.msg = `Missing argument: ${error.count} of ${error.expectedCount}`;
          errorReturning.description = CONTACT_US;
        }
        break;
      }
      case Logger.errors.UNEXPECTED_ARGUMENT: {
        errorReturning.title = "Unexpected argument";
        if (error?.count && error?.expectedCount) {
          errorReturning.msg = `Unexpected argument: ${error.count} of ${error.expectedCount}`;
          errorReturning.description = CONTACT_US;
        }
        break;
      }
      case Logger.errors.CALL_EXCEPTION: {
        errorReturning.title = "Call exception";
        if (error?.reason) {
          errorReturning.msg = error?.reason;
          errorReturning.description = CONTACT_US;
        }
        break;
      }
      case Logger.errors.UNPREDICTABLE_GAS_LIMIT: {
        errorReturning.title = "Unpredictable gas limit";
        errorReturning.msg = "The gas limit could not be estimated.";
        errorReturning.description = CONTACT_US;
        break;
      }
    }
  }
  if (errorReturning.title !== defaultError.title) {
    return errorReturning;
  }
};
