import { FC, createContext, useCallback, useContext, useMemo } from "react";
import { useAsync, useAsyncAbortable } from "react-async-hook";

import { ICollectionBe } from "@/apis/collection/types";
import { fetchBargainCollectionStat, fetchPrivateSalesWithNft } from "@/apis/private-sale";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { INftBe, INftType } from "@/types";

import BARGAIN_SHOPPING_BANNER from "../../assets/images/bargainshopping-banner.png";
import { useBnplPageContext } from "../Bnpl/BnplPageContext";

interface IBargainContext {
  onSaleAsset?: INftBe[];
  onSaleAssetsLoading: boolean;
  collection?: ICollectionBe;
  collectionsLoading: boolean;
  fetchOnSaleAssets: () => Promise<INftBe[]>;
  updateCollection: (collection: ICollectionBe) => void;
}

const BargainDataContext = createContext<IBargainContext>({
  onSaleAssetsLoading: false,
  onSaleAsset: [],
  collectionsLoading: false,
  fetchOnSaleAssets: async () => [],
  updateCollection: async () => {},
});

export const BargainDataProvider: FC = ({ children }) => {
  const { chainId } = useWeb3React();
  const { selectedTokenId } = useBnplPageContext();
  const {
    result: onSaleAsset = [],
    execute: fetchOnSaleAssets,
    loading: onSaleAssetsLoading,
  } = useAsync<INftBe[]>(async () => {
    const result = await fetchPrivateSalesWithNft({
      chainId,
      tokenId: selectedTokenId ? selectedTokenId : undefined,
    });
    return result;
  }, [chainId, selectedTokenId]);

  const {
    result: collection,
    loading: collectionsLoading,
    merge: updateCollectionsAsync,
  } = useAsyncAbortable<ICollectionBe>(
    async abortSignal => {
      return { ...BargainCollectionInfo, ...(await fetchBargainCollectionStat(chainId, abortSignal)) };
    },
    [chainId],
  );
  const updateCollection = useCallback(
    (collection: ICollectionBe) => {
      updateCollectionsAsync({
        result: collection,
      });
    },
    [updateCollectionsAsync],
  );

  return (
    <BargainDataContext.Provider
      value={{
        onSaleAsset,
        collectionsLoading,
        collection,
        onSaleAssetsLoading,
        fetchOnSaleAssets,
        updateCollection,
      }}
    >
      {children}
    </BargainDataContext.Provider>
  );
};

const useBargainDataContext = () => {
  return useContext(BargainDataContext);
};

export const useBargainAssets = () => {
  const { onSaleAsset, fetchOnSaleAssets, onSaleAssetsLoading } = useBargainDataContext();
  return {
    onSaleAsset,
    fetchOnSaleAssets,
    onSaleAssetsLoading,
  };
};

export const useBargainCollection = () => {
  const { collection, collectionsLoading, updateCollection } = useBargainDataContext();
  return {
    collection,
    collectionsLoading,
    updateCollection,
  };
};

export const useBargainCheapestNfts = () => {
  const { onSaleAsset, onSaleAssetsLoading } = useBargainAssets();
  const cheapestNfts = useMemo(() => {
    const _assets = [...(onSaleAsset ?? [])];
    return _assets.sort(() => 0.5 - Math.random()).slice(0, 3);
  }, [onSaleAsset]);
  return {
    cheapestNfts,
    cheapestNftsLoading: onSaleAssetsLoading,
  };
};

const BargainCollectionInfo: ICollectionBe = {
  address: "0x0000000000000000000000000000000000000000",
  banner: BARGAIN_SHOPPING_BANNER,
  description: `Shop from defaulted NFTs. Items are listed at discounts and bargain prices, exclusive to users of Cyan only. Please contact us in our Discord server for inquiries of specific items, or any questions in general.`,
  symbol: "ETH",
  chainId: 11155111,
  twitterAccount: "",
  traitBoosters: [],
  vaults: [],
  slug: "cyan-marketplace",
  name: "Cyan Marketplace",
  createdAt: "2022-12-12",
  tokenCount: "0",
  onSaleCount: "0",
  ownerCount: "0",
  tokenType: INftType.ERC721,
  floorAsk: { id: "", sourceDomain: "", sourceName: "" },
  topBid: {},
  cyanSignature: "",

  volume: {
    "7day": null,
    "1day": null,
    "30day": null,
    allTime: null,
  },
  volumeChange: {
    "7day": null,
    "1day": null,
    "30day": null,
  },
  floorSale: {
    "7day": null,
    "1day": null,
    "30day": null,
  },
  floorSaleChange: {
    "7day": null,
    "1day": null,
    "30day": null,
  },
  tags: [],
  floorPrice1dBefore: "0",
  floorPrice7dBefore: "0",
  floorPrice30dBefore: "0",
};
