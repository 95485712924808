import { BigNumber } from "ethers";
import { FC, useMemo } from "react";

import { Flex } from "@cyanco/components/theme/Flex";
import { NotFound, PieChart, SkeletonLine, SubTitle } from "@cyanco/components/theme/v3";

import { IVaultAdditionalMetrics } from "@/apis/vault/types";
import { bigNumToFloat, bigNumToFloatFormatted } from "@/utils";
import { isMobile } from "@/utils/userAgent";

import { CHART_HEIGHT, getTopCollections } from ".";

export const MixtureOfActiveLoansByCollection: FC<{
  collections: IVaultAdditionalMetrics["collections"];
  decimals: number;
  currency: string;
  loading: boolean;
}> = ({ collections, decimals = 5, currency, loading }) => {
  const collectionTotal = useMemo(() => {
    return collections.reduce((acc, curr) => {
      return acc.add(BigNumber.from(curr.total));
    }, BigNumber.from(0));
  }, [collections]);
  const topCollectionPublic = getTopCollections(collections, collectionTotal);
  const chartDataPublic = topCollectionPublic.map(collection => ({
    name: collection.name,
    value: bigNumToFloat(BigNumber.from(collection.total), decimals),
    readableValue: `${bigNumToFloatFormatted(BigNumber.from(collection.total), decimals, 5)} ${currency}`,
  }));
  return (
    <Flex direction="column" gap="1.5rem">
      <SubTitle>
        {`Mixture of Active Loans`} {`(in ${currency})`}
      </SubTitle>
      {loading ? (
        <SkeletonLine h={isMobile ? `${CHART_HEIGHT.mobile}px` : `${CHART_HEIGHT.desktop}px`} w="100%" />
      ) : (
        <Flex
          direction="column"
          w="100%"
          h={isMobile ? `${CHART_HEIGHT.mobile}px` : `${CHART_HEIGHT.desktop}px`}
          alignItems="center"
          justifyContent="center"
        >
          {chartDataPublic.length === 0 ? (
            <NotFound msg="No data found" />
          ) : (
            <PieChart
              height="100%"
              innerRadius={0}
              outerRadius={isMobile ? 60 : 200}
              fontSize={isMobile ? 12 : 14}
              labelXDistance={isMobile ? 0 : 40}
              data={chartDataPublic}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};
