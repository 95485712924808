import { FC } from "react";
import { Cell, Pie, PieLabel, PieChart as ReChartPieChart, ResponsiveContainer, Tooltip, TooltipProps } from "recharts";
import styled, { useTheme } from "styled-components";

const COLORS = [
  "#17becf", // cyan
  "#1f77b4", // muted blue
  "#ff7f0e", // bright orange
  "#2ca02c", // muted green
  "#d62728", // bright red
  "#9467bd", // muted purple
  "#8c564b", // dark brown
  "#e377c2", // bright pink
  "#bcbd22", // muted yellow
  "#7f7f7f", // gray
  "#c7c7c7", // light gray
  "#ff69b4", // hot pink
  "#00ced1", // dark turquoise
  "#ff1493", // deep pink
];

const renderCustomizedLabel: PieLabel = params => {
  const { cx, cy, midAngle, outerRadius, percent, name, fontSize, index, labelXDistance, theme } = params;
  const RADIAN = Math.PI / 180;
  const sin = Math.sin(RADIAN * midAngle);
  const cos = Math.cos(RADIAN * midAngle);
  const startX = cx + outerRadius * cos;
  const startY = cy + outerRadius * -sin;
  const middleY = cy + (outerRadius + 50 * Math.abs(sin)) * -sin;

  let endX = startX + (cos >= 0 ? 1 : -1) * labelXDistance;
  let textAnchor = cos >= 0 ? "start" : "end";
  const mirrorNeeded = midAngle > -270 && midAngle < -210 && percent < 0.04 && index % 2 === 1;
  if (mirrorNeeded) {
    endX = startX + outerRadius * -cos * 2 + 100;
    textAnchor = "start";
  }

  return (
    <LabelContainer>
      <path
        d={`M${startX},${startY}L${startX},${middleY}L${endX},${middleY}`}
        fill="none"
        stroke={theme.colors.secondary}
        strokeWidth={2}
      />
      <text
        x={endX + (cos >= 0 || mirrorNeeded ? 1 : -1) * 4}
        y={middleY + fontSize / 2}
        textAnchor={textAnchor}
        fontSize={fontSize}
        fontFamily="Inter"
      >
        <tspan fill={theme.colors.secondary}>{name || ""}: </tspan>
        <tspan fill={theme.colors.gray90}>{(percent * 100).toFixed(2)}%</tspan>
      </text>
    </LabelContainer>
  );
};

const LabelContainer = styled.g`
  & text {
    & *::selection {
      background: ${({ theme }) => theme.theme == "dark" && "#000000"} !important;
      color: ${({ theme }) => theme.theme == "dark" && "#FFFFFF"} !important;
    }
  }
`;

const CustomTooltip: FC<TooltipProps<number, string>> = ({ active, payload }) => {
  return (
    <ToolTipContainer>
      {active && payload && payload.length && (
        <p>{`${payload[0].name}: ${payload[0]?.payload?.payload?.readableValue ?? payload[0].value}`}</p>
      )}
    </ToolTipContainer>
  );
};

type Entry = {
  name: string;
  value: number;
  readableValue?: string;
};

interface PieChartProps {
  data: Entry[];
  width?: number | string;
  height?: number | string;
  outerRadius?: number;
  innerRadius?: number;
  fill?: string;
  fontSize?: number;
  labelXDistance?: number;
}

export const PieChart: FC<PieChartProps> = ({
  data = [],
  width,
  height = "100%",
  outerRadius = 80,
  innerRadius = 30,
  fill = "#8884d8",
  fontSize = 14,
  labelXDistance,
}) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width={width} height={height}>
      <ReChartPieChart>
        <Pie
          data={data.map(entry => ({ ...entry, labelXDistance, theme }))}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          labelLine={false}
          fontSize={fontSize}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          label={renderCustomizedLabel}
          fill={fill}
        >
          {data.map((_entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip active={true} cursor={{ fill: "transparent" }} content={CustomTooltip} />
      </ReChartPieChart>
    </ResponsiveContainer>
  );
};

const ToolTipContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary} !important;
  padding: 0.8rem;
  max-height: 400px;
  font-family: "Inter";
  color: ${({ theme }) => theme.colors.gray90};
`;
