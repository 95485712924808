import { useState } from "react";
import { Minus, Plus } from "react-feather";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { HomeTitleWebkit } from ".";
import { faqs } from "./consts";

export const FAQ = () => {
  return (
    <FaqContainer w="100%">
      <HomeTitleWebkit>Frequenty Asked Questions</HomeTitleWebkit>
      <Flex direction="column">
        {faqs.map(({ title, description }) => (
          <ExpandableView {...{ title, description }} key={title} />
        ))}
      </Flex>
    </FaqContainer>
  );
};

const ExpandableView = ({ title, description }: { title: string; description: string }) => {
  const [height, setHeight] = useState("0");
  const toggleDescription = () => {
    if (height === "0") {
      setHeight("500px");
    } else setHeight("0");
  };
  return (
    <Container>
      <FAQTitle justifyContent="space-between" alignItems="center" onClick={toggleDescription}>
        <FAQTitleText>{title}</FAQTitleText>
        {height === "0" ? <PlusIcon strokeWidth={1.5} /> : <MinusIcon strokeWidth={1.5} />}
      </FAQTitle>

      <FAQDescription
        style={{
          transition: height === "0" ? "0.6s" : "1.5s",
          maxHeight: height,
        }}
      >
        <FAQDescriptionText>{description}</FAQDescriptionText>
      </FAQDescription>
    </Container>
  );
};

const FaqContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  @media only screen and (max-width: 768px) {
    align-items: center;
    gap: 1rem;
  }
`;

const PlusIcon = styled(Plus)`
  color: ${({ theme }) => theme.colors.secondary};
  height: 41px;
  width: 41px;
  ${getStyleWithMediaQuery("width", "px", [
    {
      [breakpoints.laptopM]: 34,
    },
    { [breakpoints.tablet]: 22 },
  ])}
  ${getStyleWithMediaQuery("height", "px", [
    {
      [breakpoints.laptopM]: 34,
    },
    { [breakpoints.tablet]: 22 },
  ])}
`;
const MinusIcon = styled(Minus)`
  color: ${({ theme }) => theme.colors.secondary};
  height: 41px;
  width: 41px;
  ${getStyleWithMediaQuery("width", "px", [
    {
      [breakpoints.laptopM]: 34,
    },
    { [breakpoints.tablet]: 22 },
  ])}
  ${getStyleWithMediaQuery("height", "px", [
    {
      [breakpoints.laptopM]: 34,
    },
    { [breakpoints.tablet]: 22 },
  ])}
`;
const Container = styled(Flex)`
  flex-direction: column;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray80};
  width: 100%;
`;
const FAQTitle = styled(Flex)`
  cursor: pointer;
  opacity: 1;
  padding: 1rem 0rem;
  ${getStyleWithMediaQuery("padding", "", [
    {
      [breakpoints.laptopM]: "0.5rem 0",
    },
    { [breakpoints.tablet]: "0" },
  ])}
  :hover {
    opacity: 0.8;
  }
`;
const FAQTitleText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.secondary};
  white-space: break-word;
  width: 100%;
  ${getStyleWithMediaQuery("font-size", "px", [
    {
      [breakpoints.laptopM]: 20,
    },
    { [breakpoints.tablet]: 16 },
  ])}
`;

const FAQDescription = styled.div`
  overflow: hidden;
`;
const FAQDescriptionText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  ${getStyleWithMediaQuery("font-size", "px", [
    {
      [breakpoints.laptopM]: 16,
    },
    { [breakpoints.tablet]: 14 },
  ])}
  color: ${({ theme }) => theme.colors.gray80};
`;
