import dayjs from "dayjs";
import { useMemo } from "react";
import Markdown from "react-markdown";
import styled, { useTheme } from "styled-components";

import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { CopyButton, Hidden, SubTitle, Text } from "@cyanco/components/theme/v3";
import { Discord, Globe, Twitter } from "@cyanco/components/theme/v3/icons";
import { EtherscanGray, LooksrareGray, NoImage, OpenseaGray, X2Y2Gray } from "@cyanco/components/theme/v3/images";

import { ICollectionBe } from "@/apis/collection/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { isProd } from "@/config";
import { CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS } from "@/constants/chains";
import {
  bigNumToFloat,
  getChainExplorerURL,
  getCryptoSymbolForChain,
  getMarketCollectionUrl,
  getMarketCollectionUrlWithAffiliate,
  jumpToLink,
  numberWithCommas,
  shortenAddress,
} from "@/utils";

import {
  CollectionDetailSubText,
  CollectionLogo,
  DailyCollectionStat,
  FloorChange,
  IconButton,
  IconButtonMarket,
  calculateChangePercent,
} from "../CollectionDetail";

export const CollectionDetailSection: React.FC<{
  collection: ICollectionBe;
}> = ({ collection }) => {
  const { chainId } = useWeb3React();
  const theme = useTheme();
  const oneDayChangePercent = useMemo(() => {
    return calculateChangePercent(collection.floorSale["1day"], collection.floorSaleChange["1day"]);
  }, [collection.floorSale]);
  const getMarketCap = useMemo(() => {
    const floor = collection.floorAsk.price?.amount.decimal ?? collection.floorSale["1day"];
    if (!!!floor) return null;
    return Number(collection.tokenCount) * floor;
  }, [collection.floorAsk.price, collection.tokenCount]);

  const currencySymbol = useMemo(() => {
    return (
      collection.floorAsk.price?.currency.symbol ||
      collection.topBid.price?.currency.symbol ||
      getCryptoSymbolForChain(collection.chainId)
    );
  }, [collection.chainId]);

  const numOfOwners = useMemo(() => {
    return collection.ownerCount ? Number(collection.ownerCount) : null;
  }, [collection]);

  const uniqueOwnersPercent = useMemo(() => {
    if (!collection.tokenCount || !numOfOwners || Number(collection.tokenCount) == 0) return null;
    return ((numOfOwners / Number(collection.tokenCount)) * 100).toFixed();
  }, [collection.tokenCount, numOfOwners]);

  const listedTokensPercent = useMemo(() => {
    if (
      !collection.tokenCount ||
      !collection.onSaleCount ||
      Number(collection.tokenCount) == 0 ||
      Number(collection.onSaleCount) == 0
    )
      return null;
    return ((Number(collection.onSaleCount) / Number(collection.tokenCount)) * 100).toFixed(2);
  }, [collection]);

  const getCollectionStat = (value: number | string | null | undefined, postfix: string) => {
    return value !== null && value !== undefined
      ? `${numberWithCommas(value, Number(value) > 1 ? 0 : 1)} ${postfix}`
      : "-";
  };

  const getCollectionStatFromBigNumber = (value: number | string | null | undefined, postfix: string, fix = 4) => {
    if (value === null || value === undefined) {
      return "-";
    }

    const num = bigNumToFloat(value);
    if (num < 0.001) {
      return `< 0.001 ${postfix}`;
    }

    return `${num.toFixed(fix)} ${postfix}`;
  };

  return (
    <Container>
      <Flex direction="column" gap="2rem">
        <Flex alignItems="center" gap="15px">
          <CollectionLogo src={collection.image ?? NoImage} />
          <Flex direction="column" gap="5px">
            <SubTitle>{collection.name}</SubTitle>
            <Flex gap="5px" wrap="wrap">
              <CollectionDetailSubText color="gray0">
                {collection.tokenCount} {`items`}
              </CollectionDetailSubText>
              <CollectionDetailSubText color="gray0">|</CollectionDetailSubText>
              <CollectionDetailSubText color="gray0">
                {`created`} {dayjs(collection.createdAt).format("MMMM YYYY")}
              </CollectionDetailSubText>
              <CollectionDetailSubText color="gray0">|</CollectionDetailSubText>
              <CollectionDetailSubText color="gray0">
                {CHAIN_INFO[chainId as typeof CYAN_SUPPORTED_CHAIN_IDS[number]].label || ""}
              </CollectionDetailSubText>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          style={{
            columnGap: "3rem",
            rowGap: "1rem",
          }}
          wrap="wrap"
        >
          <DailyCollectionStat statName={`Market Cap`} statValue={getCollectionStat(getMarketCap, currencySymbol)} />
          <DailyCollectionStat
            statName={`24 hour volume`}
            statValue={
              collection.volume["1day"] ? getCollectionStat(collection.volume["1day"].toFixed(2), currencySymbol) : "-"
            }
          />
          <DailyCollectionStat
            statName={`Floor Price`}
            statValue={getCollectionStatFromBigNumber(collection.floorAsk.price?.amount.raw, currencySymbol, 2)}
          />
          {oneDayChangePercent !== null && (
            <Hidden tabletUp>
              <FloorChange statValue={oneDayChangePercent} />
            </Hidden>
          )}
          <DailyCollectionStat
            statName={`Best Bid`}
            statValue={getCollectionStatFromBigNumber(collection.topBid.price?.amount.raw, currencySymbol, 2)}
          />
          <DailyCollectionStat statName={`Listed`} statValue={getCollectionStat(listedTokensPercent, "%")} />
          {numOfOwners !== null && (
            <Hidden laptopDown>
              <DailyCollectionStat statName={`Owners`} statValue={numOfOwners.toString()} />
            </Hidden>
          )}
          {!!numOfOwners && (
            <DailyCollectionStat
              statName={`Unique Wallets`}
              statValue={!uniqueOwnersPercent ? "-" : `${uniqueOwnersPercent}%`}
            />
          )}
        </Flex>
      </Flex>
      <Flex direction="column" gap="2rem">
        {collection.description && (
          <DescriptionBox>
            <Text size="sm" color="secondary">
              <Markdown
                components={{
                  a: ({ children, ...props }) => (
                    <a style={{ color: "#00ffff", textDecoration: "none" }} {...props}>
                      {" "}
                      {children}{" "}
                    </a>
                  ),
                }}
              >
                {collection.description}
              </Markdown>
            </Text>
          </DescriptionBox>
        )}
        <IconBox>
          <CopyButton value={collection.address}>
            <Text size="xs" weight="600" color="secondary">
              {shortenAddress(collection.address)}
            </Text>
          </CopyButton>
          <Flex gap="10px" alignItems="center" w="fit-content">
            <IconButton
              variant="ghost"
              onClick={() => collection.discordUrl && jumpToLink(collection.discordUrl)}
              disabled={!collection.discordUrl}
            >
              <Discord width={16} height={16} color={theme.colors.secondary} />
            </IconButton>
            <IconButton
              variant="ghost"
              disabled={!collection.twitterAccount}
              onClick={() =>
                collection.twitterAccount && jumpToLink(`https://twitter.com/${collection.twitterAccount}`)
              }
            >
              <Twitter width={16} height={16} color={theme.colors.secondary} />
            </IconButton>
            <IconButton
              variant="ghost"
              disabled={!collection.externalUrl}
              onClick={() => collection.externalUrl && jumpToLink(collection.externalUrl)}
            >
              <Globe width={16} height={16} color={theme.colors.secondary} />
            </IconButton>
            <IconButtonMarket
              variant="ghost"
              onClick={() => jumpToLink(`${getChainExplorerURL(collection.chainId)}/address/${collection.address}`)}
            >
              <img src={EtherscanGray} width={16} height={16} />
            </IconButtonMarket>
            <IconButtonMarket
              variant="ghost"
              onClick={() =>
                jumpToLink(
                  `${getMarketCollectionUrl(SupportedMarketPlaces.OPENSEA, collection.slug, collection.chainId)}`,
                )
              }
            >
              <img src={OpenseaGray} width={16} height={16} />
            </IconButtonMarket>
            <IconButtonMarket
              variant="ghost"
              onClick={() =>
                jumpToLink(
                  `${getMarketCollectionUrlWithAffiliate(
                    SupportedMarketPlaces.LOOKSRARE,
                    collection.address,
                    collection.chainId,
                  )}`,
                )
              }
            >
              <img src={LooksrareGray} width={16} height={16} />
            </IconButtonMarket>
            {isProd && (
              <IconButtonMarket
                variant="ghost"
                onClick={() =>
                  jumpToLink(
                    `${getMarketCollectionUrl(SupportedMarketPlaces.X2Y2, collection.slug, collection.chainId)}`,
                  )
                }
              >
                <img src={X2Y2Gray} width={16} height={16} />
              </IconButtonMarket>
            )}
          </Flex>
        </IconBox>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  posiion: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3rem;
`;

const DescriptionBox = styled.div`
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.laptop]: "500px" }, { [breakpoints.tablet]: "100%" }])};
`;

const IconBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;
