import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { SubTitle, TitleContainer } from "@cyanco/components/theme/v3/common";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { ApeCoinPageTabs, useApeCoinContext } from "./ApeCoinPageContext";
import { MainView } from "./components/MainView";
import { Selectors } from "./components/Selector";

export const ApeCoinPage = () => {
  const { panel } = useParams();
  const { setSelectedTab } = useApeCoinContext();
  useEffect(() => {
    switch (panel) {
      case ApeCoinPageTabs.pools:
        setSelectedTab(ApeCoinPageTabs.pools);
        break;
      case ApeCoinPageTabs.rewards:
        setSelectedTab(ApeCoinPageTabs.rewards);
        break;
      case ApeCoinPageTabs.history:
        setSelectedTab(ApeCoinPageTabs.history);
        break;
      default:
        setSelectedTab(ApeCoinPageTabs.pools);
    }
  }, [panel]);
  return (
    <Container direction="column">
      <HorizantallyGap>
        <TitleContainer style={{ alignItems: "flex-start", paddingBottom: "0rem" }}>
          <Flex alignItems="center" gap="10px">
            <ApeCoinImg src={ApeCoin} />
            <SubTitle weight="600">{`ApeCoin Staking`} </SubTitle>
          </Flex>
        </TitleContainer>
      </HorizantallyGap>
      <HorizantallyGap>
        <Selectors />
      </HorizantallyGap>
      <HorizantallyGap>
        <MainView />
      </HorizantallyGap>
    </Container>
  );
};

const Container = styled(Flex)`
  margin: 30px 0;
  gap: 0.5rem;
  padding: 0 0 5rem 0;
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 0" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 0 5rem 0" },
    { [breakpoints.tablet]: "2rem 0.5rem 10rem 0.5rem" },
  ])}
  margin-top: 5rem;
`;
const HorizantallyGap = styled.div`
  margin-left: calc((100% - 1240px) / 2);
  margin-right: calc((100% - 1240px) / 2);
  ${getStyleWithMediaQuery("margin-left", "", [{ [breakpoints.laptopM]: "50px" }, { [breakpoints.tablet]: "0px" }])}
  ${getStyleWithMediaQuery("margin-right", "", [{ [breakpoints.laptopM]: "50px" }, { [breakpoints.tablet]: "0px" }])}
`;

const ApeCoinImg = styled.img`
  width: 36px;
  height: 36px;
  @media only screen and (max-width: ${breakpoints.laptop}px) {
    width: 28px;
    height: 28px;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 25px;
    height: 25px;
  }
`;
