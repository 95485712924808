import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { Box, Flex } from "@cyanco/components/theme";
import {
  Card,
  SwitchButton,
  SwitchButtonGroup,
  SystemMessage,
  Text,
  Toggler,
  Tooltip,
  TooltipText,
  useModal,
} from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";
import { factories as f } from "@cyanco/contract";

import { IPeerPlan, PeerPlanPaymentNumber, PeerPlanStatuses } from "@/apis/p2p/types";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPeerPaymentPlanFromChainId } from "@/constants/contracts";
import { IMappedError } from "@/utils/error/msgs";

import { DefaultedPlanMetadata } from "../PlanMetadata";
import { PositionDefaulted } from "../PlanStatuses";
import { NftMetadata } from "./NftMetadata";
import { PayBox } from "./PayBox";
import { RegularPayment } from "./RegularPayment";
import { PeerPlanRepaymentProgress } from "./RepaymentProgress";
import { RollPayment } from "./RollPayment";

enum PaymentOptions {
  complete = "complete",
  extend = "extend",
}
export interface IPlanData {
  currentPayment: BigNumber;
  nextPaymentDate: Date;
  status: PeerPlanStatuses;
  extendable: boolean;
  fee: BigNumber;
}
export const PeerPlanRepayment = ({
  plan,
  planData: _planData,
  onClose,
  error: _error,
}: {
  plan: IPeerPlan;
  planData?: IPlanData;
  error?: IMappedError;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const { setModalContent } = useModal();
  const { signer, chainId } = useWeb3React();
  const { transactions } = useTransactionContext();

  const [planData, setPlanData] = useState<IPlanData | undefined>(_planData);
  const [paymentOption, setPaymentOption] = useState<PaymentOptions>(PaymentOptions.complete);
  const [defaultedError, setDefaultedError] = useState<string | null>();
  const [error, setError] = useState<IMappedError | null>(_error || null);
  useEffect(() => {
    if (planData?.nextPaymentDate && planData.nextPaymentDate.getTime() < Date.now()) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
    }
  }, [planData]);

  useEffect(() => {
    if (!signer) return;
    const _setNextPayment = async () => {
      const peerToPeerContract = getPeerPaymentPlanFromChainId(chainId);
      const paymentPlanReader = f.CyanPeerPlanFactory.connect(peerToPeerContract, signer);
      const { interestRate, serviceFeeRate, dueDate, amount, status, extendable } =
        await paymentPlanReader.getPaymentPlanMapped(plan.planId);
      const fee = BigNumber.from(amount)
        .mul(interestRate)
        .div(10000)
        .add(BigNumber.from(amount).mul(serviceFeeRate).div(10000));
      const currentPayment = BigNumber.from(amount).add(fee);
      if (status === PeerPlanStatuses.ACTIVE) {
        setPlanData({
          nextPaymentDate: dueDate,
          currentPayment,
          status,
          extendable,
          fee,
        });
      }
    };
    _setNextPayment();
  }, [plan]);

  const completePlan = async () => {
    setError(null);
    if (!planData) return;
    if (planData.nextPaymentDate && planData.nextPaymentDate.getTime() < Date.now()) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
      return;
    }
    setModalContent({
      title: `Confirm Payment`,
      content: (
        <PeerPlanRepaymentProgress
          plan={plan}
          planData={planData}
          paymentType={PeerPlanPaymentNumber.COMPLETED}
          onClose={onClose}
        />
      ),
    });
  };
  const extendPlan = async () => {
    setError(null);
    if (!planData) return;
    if (planData.nextPaymentDate && planData.nextPaymentDate.getTime() < Date.now()) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
      return;
    }
    setModalContent({
      title: `Confirm Payment`,
      content: (
        <PeerPlanRepaymentProgress
          plan={plan}
          planData={planData}
          paymentType={PeerPlanPaymentNumber.EXTENDED}
          onClose={onClose}
        />
      ),
    });
  };

  const hasPendingTxn = transactions.some(txn => txn.data?.planId === plan.planId);

  return (
    <Flex gap="18px" direction="column">
      <NftMetadata plan={plan} />
      {!defaultedError && error && (
        <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />
      )}
      {plan.status === PeerPlanStatuses.ACTIVE && !defaultedError && (
        <>
          <Flex direction="column" gap="8px">
            <Text size="sm" weight="400" color="gray0">
              {`Payment options`}
            </Text>
            <Box h="25px">
              <SwitchButtonGroup<PaymentOptions>
                activeBackground={theme.colors.cyan}
                activeTextColor={theme.colors.black}
                borderColor={theme.colors.gray20}
                value={paymentOption}
                onChange={v => !hasPendingTxn && setPaymentOption(v)}
                hover
              >
                <SwitchButton value={PaymentOptions.complete}>{`Complete Loan`}</SwitchButton>
                <SwitchButton
                  value={PaymentOptions.extend}
                  disabled={planData ? !planData.extendable : !plan.isExtendable}
                >{`Roll Loan`}</SwitchButton>
              </SwitchButtonGroup>
            </Box>
          </Flex>

          {paymentOption === PaymentOptions.complete && planData && (
            <RegularPayment plan={plan} planDataContract={planData} />
          )}
          {paymentOption === PaymentOptions.extend && planData && (
            <RollPayment plan={plan} planDataContract={planData} />
          )}
          <Card p={"10px"} style={{ borderRadius: "10px" }}>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="4px">
                <Text size="md" weight="500" color="gray0">
                  {`Auto-roll Loans`}
                </Text>
                <Tooltip>
                  <HelpCircle height={16} width={16} color={theme.colors.gray0} />
                  <TooltipText showArrow position="top" top="-155px" right="-70px" style={{ width: "150px" }}>
                    <Flex direction="column" gap="7px">
                      <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                        <div>{`Automatically compound your APE on a bi-weekly basis by unstaking, and re-staking all earned APE`}</div>
                      </Text>
                      <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                        <div>{`Your NFT will be moved into your Cyan Wallet for compounding activities. Turning this off will keep your NFT in your main wallet during staking.`}</div>
                      </Text>
                    </Flex>
                  </TooltipText>
                </Tooltip>
              </Flex>
              <Toggler value={planData?.extendable ?? plan.isExtendable} onChange={() => {}} size="sm" disabled />
            </Flex>
          </Card>
          {paymentOption === PaymentOptions.complete && planData && (
            <PayBox
              onPay={completePlan}
              plan={{
                amount: planData.currentPayment,
                currency: plan.loanBid.currency,
                label: `Make payment now:`,
              }}
            />
          )}
          {paymentOption === PaymentOptions.extend && planData && (
            <PayBox
              onPay={extendPlan}
              plan={{
                amount: planData.fee,
                currency: plan.loanBid.currency,
                label: `Roll loan now:`,
              }}
            />
          )}
        </>
      )}
      {(plan.status === PeerPlanStatuses.DEFAULTED || defaultedError) && (
        <>
          <PositionDefaulted />
          <DefaultedPlanMetadata
            plan={{
              totalNumOfPaymentsLeft: 1,
              totalNumOfPayments: 1,
              defaultedAt: plan.defaultedAt || new Date(),
            }}
          />
        </>
      )}
    </Flex>
  );
};
