import { BigNumber } from "ethers";
import { BytesLike, parseUnits } from "ethers/lib/utils";
import { useEffect, useMemo, useState } from "react";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex, Stepper, useModal } from "@cyanco/components/theme";
import { factories as f } from "@cyanco/contract";
import { CyanPrivateSales } from "@cyanco/contract/abis/CyanPrivateSale";
import { PromiseOrValue } from "@cyanco/contract/abis/common";

import { createPrivateSale, fetchPrivateSalesWithNft } from "@/apis/private-sale";
import { usePrivateSales } from "@/components/Account/AccountDataContext";
import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getCyanConduitFromChainId, getPrivateSaleFromChainId } from "@/constants/contracts";
import { IInititatePrivateUpdatePurchase, signPrivateSale } from "@/hooks/usePrivateSaleCreation";
import { INftType } from "@/types";
import { getChainExplorerTextForTxn } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { CloseButton, PrivateSaleLoading } from "../CommonComponents";
import { PrivateSaleModal } from "../PrivateSaleModal";

export const PrivateSaleUpdateStepper = ({
  privateSale,
  price: _price,
  buyerAddress,
  currency,
  currencyAddress,
  nft,
  expiryAt,
}: IInititatePrivateUpdatePurchase) => {
  const { chainId, account, signer } = useWeb3React();
  const [selectedStep, setSelectedStep] = useState<PrivateSaleSteps>(PrivateSaleSteps.Cancel);
  const { setModalContent } = useModal();
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const price = parseUnits(_price, currency.decimal);
  const cyanWallet = useCyanWallet();
  const { fetchPrivateSales } = usePrivateSales();

  const { setFireConfetti } = useAppContext();

  const approveTokenToPlan = async () => {
    if (!account || !signer || !chainId || (nft.isCyanWallet && nft.tokenType !== INftType.CryptoPunks)) return;
    const cyanConduit = getCyanConduitFromChainId(chainId);
    if (nft.tokenType == INftType.ERC721) {
      const contract = f.SampleFactory.connect(nft.address, signer);
      const isApprovedForAll = await contract.isApprovedForAll(account, cyanConduit);
      if (isApprovedForAll) return;
      const approvedTo = await contract.getApproved(nft.tokenId);
      if (approvedTo.toLowerCase() != cyanConduit.toLowerCase()) {
        await contract.setApprovalForAll(cyanConduit, true).then(tx => tx.wait());
      }
    } else if (nft.tokenType === INftType.CryptoPunks) {
      if (nft.isCyanWallet) {
        if (!cyanWallet) {
          throw new Error("Cyan wallet not found");
        }
        const iCryptoPunks = f.SampleCryptoPunksFactory.createInterface();
        const encodedFnData = iCryptoPunks.encodeFunctionData("transferPunk", [account, nft.tokenId]);
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [nft.address, "0", encodedFnData]);
        const tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
        await tx.wait();
      }
      const contract = f.SampleCryptoPunksFactory.connect(nft.address, signer);
      const [ownerAddress, { isForSale, onlySellTo, minValue }] = await Promise.all([
        contract.punkIndexToAddress(nft.tokenId),
        contract.punksOfferedForSale(nft.tokenId),
      ]);
      const privateSaleAddress = getPrivateSaleFromChainId(chainId).toLowerCase();
      if (
        ownerAddress === account &&
        (!isForSale || onlySellTo.toLowerCase() !== privateSaleAddress || !minValue.eq(0))
      ) {
        const tx = await contract.offerPunkForSaleToAddress(nft.tokenId, 0, privateSaleAddress);
        await tx.wait();
      }
    } else {
      const contract = f.SampleERC1155TokenFactory.connect(nft.address, signer);
      const isApprovedForAll = await contract.isApprovedForAll(account, cyanConduit);
      if (!isApprovedForAll) {
        await contract.setApprovalForAll(cyanConduit, true).then(tx => tx.wait());
      }
    }
  };

  const cancel = async () => {
    if (!account || !signer || !chainId) return;
    const privateSaleContract = getPrivateSaleFromChainId(chainId);
    const contract = f.CyanPrivateSaleFactory.connect(privateSaleContract, signer);
    const differentBuyerAndCurrency =
      privateSale.buyerAddress !== buyerAddress || privateSale.currencyAddress !== currencyAddress;
    const priceIncreased = BigNumber.from(privateSale.price).lt(price);
    if (differentBuyerAndCurrency || priceIncreased) {
      // fetch other active
      const result = await fetchPrivateSalesWithNft({
        chainId,
        tokenId: privateSale.tokenId,
        sellerAddresses: [privateSale.sellerAddress],
        noDuplicates: false,
      });
      const transactionData: [CyanPrivateSales.ItemStruct, PromiseOrValue<BytesLike>][] = result
        .map(({ privateSale: sale }) => {
          if (sale) {
            return [
              {
                sellerAddress: sale?.sellerAddress,
                buyerAddress: sale?.buyerAddress,
                signedDate: Math.floor(sale.signedDate.getTime() / 1000),
                expiryDate: Math.floor(sale.expiryDate.getTime() / 1000),
                price: sale.price,
                currencyAddress: sale.currencyAddress,
                tokenAmount: sale.tokenAmount,
                tokenId: sale.tokenId,
                contractAddress: sale.collectionAddress,
                tokenType: sale.tokenType,
                collectionSignature: sale.collectionSignature ?? privateSale.collectionSignature,
              },
              sale.signature,
            ];
          } else {
            return null;
          }
        })
        .filter(e => e !== null) as [CyanPrivateSales.ItemStruct, PromiseOrValue<BytesLike>][];

      if (cyanWallet) {
        const transactions = transactionData.map(data => {
          return {
            to: privateSaleContract,
            value: "0",
            data: contract.interface.encodeFunctionData("cancelSignature", data),
          };
        });
        const data = cyanWallet.interface.encodeFunctionData("executeBatch", [transactions]);
        const tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data,
        });
        await tx.wait();
        setTxnFinal(tx.hash);
      } else {
        for (const [sale, signature] of transactionData) {
          const tx = await contract.cancelSignature(sale, signature);
          await tx.wait();
          setTxnFinal(tx.hash);
        }
      }
      fetchPrivateSales();
    }
  };

  const create = async () => {
    if (!account || !signer || !chainId) return;

    const signedDate = Math.floor(Date.now() / 1000);
    const signature = await signPrivateSale(signer, {
      chainId,
      collectionSignature: privateSale.collectionSignature,
      collectionAddress: privateSale.collectionAddress,
      currencyAddress,
      tokenAmount: nft.tokenType == INftType.ERC1155 ? 1 : 0,
      tokenType: nft.tokenType || 1,
      tokenId: privateSale.tokenId,
      buyerAddress,
      sellerAddress: account,
      signedDate,
      expiryDate: expiryAt,
      price: price.toString(),
    });

    await createPrivateSale({
      wallet: account,
      signature,
      chainId,
      item: {
        collectionAddress: privateSale.collectionAddress,
        tokenId: privateSale.tokenId,
        price: price.toString(),
        signedDate,
        expiryDate: expiryAt,
        buyerAddress,
        currencyAddress,
        tokenAmount: nft.tokenType == INftType.ERC1155 ? 1 : 0,
        tokenType: nft.tokenType || 1,
      },
    });
    setFireConfetti(true);
    fetchPrivateSales();
  };

  useEffect(() => {
    const onStepChange = async (step: PrivateSaleSteps) => {
      if (!chainId || !account || !signer) return;
      try {
        switch (step) {
          case PrivateSaleSteps.Cancel: {
            await cancel();
            setSelectedStep(PrivateSaleSteps.TokenApproval);
            return;
          }
          case PrivateSaleSteps.TokenApproval: {
            await approveTokenToPlan();
            setSelectedStep(PrivateSaleSteps.Create);
            return;
          }
          case PrivateSaleSteps.Create: {
            await create();
            setSelectedStep(PrivateSaleSteps.Done);
            return;
          }
        }
      } catch (err: any) {
        openPrivateSalePurchaseModal(err);
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);

  const openPrivateSalePurchaseModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    setModalContent({
      title: `Private sale`,
      content: <PrivateSaleModal nft={nft} privateSale={privateSale} err={mappedError} />,
    });
  };

  const stepMarkFiltered = useMemo(() => {
    return PrivateSaleStepMarks.map(item => {
      if (item.value === PrivateSaleSteps.Cancel && txnFinal !== null) {
        return {
          ...item,
          description: getChainExplorerTextForTxn(chainId),
        };
      }
      return item;
    });
  }, [chainId]);

  return (
    <Flex gap="1rem" direction="column">
      <PrivateSaleLoading
        tokenId={privateSale.tokenId}
        collectionName={nft.collectionName.toLowerCase()}
        price={_price}
        currencySymbol={currency.symbol}
        isLoading={false}
        buyer={privateSale.buyerAddress}
        imageUrl={nft.imageUrl}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper marks={stepMarkFiltered} selectedStep={selectedStep} txUrl={""}></Stepper>
      </Box>
      {selectedStep == PrivateSaleSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum PrivateSaleSteps {
  Cancel = 0,
  TokenApproval = 1,
  Create = 2,
  Done = 3,
}

const PrivateSaleStepMarks = [
  {
    value: PrivateSaleSteps.Cancel,
    title: `Cancel your old listing`,
    description: `A small amount of gas is required to first cancel the old listing`,
  },
  {
    value: PrivateSaleSteps.TokenApproval,
    title: `NFT Approval`,
    description: `This provides Cyan with permission to move your NFT`,
  },
  {
    value: PrivateSaleSteps.Create,
    title: `Complete update`,
    description: `Please accept the new signature to update the listing`,
  },
];
