import { useEffect, useMemo, useState } from "react";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { CloseButton } from "@/components/ApeCoinStaking/CommonComponents";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { POOL_IDS_MAPPED_BY_ADDRESS, PoolId, apePaymentPlanContract, apeStakingContract } from "@/config";
import { createBatchTxn, useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IInitiateApeBulkUnstake } from "@/hooks/useApePlanCreation.types";
import { ITransaction } from "@/hooks/useTransactions";
import { getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";
import { ISelectedNft } from "../../../types";
import { BulkStakingProgressLoader } from "../../common";

export const BulkUnstaking = (params: IInitiateApeBulkUnstake) => {
  const { selectedMainNfts, withdrawWallet } = params;
  const { setFireConfetti } = useAppContext();
  const { cyanWallet, createNewWallet } = useCyanWalletContext();
  const { transactions, setTransactions } = useTransactionContext();
  const { updateUserBalance } = useApeCoinStatsContext();
  const { showApeBulkPlanModal } = useApePlanCreation();
  const { chainId, signer, account } = useWeb3React();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [isWalletCreated, setIsWalletCreated] = useState(false);
  const [selectedStep, setSelectedStep] = useState<UnstakingSteps>(
    cyanWallet ? UnstakingSteps.Unstaking : UnstakingSteps.CreatingCyanWallet,
  );

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        updateUserBalance();
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setFireConfetti(true);
        setSelectedStep(UnstakingSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const buildCompletePlanTxn = (planId: number) => {
    const iface = f.CyanApeCoinPlanV1Factory.createInterface();
    const data = iface.encodeFunctionData("complete", [planId]);
    return { to: apePaymentPlanContract, data, value: "0" };
  };

  const buildUnstakeTxn = (nft: ISelectedNft) => {
    const iface = f.ApeCoinStakingFactory.createInterface();
    const poolId = POOL_IDS_MAPPED_BY_ADDRESS[nft.address.toLowerCase()];
    const params = [{ tokenId: nft.tokenId, amount: nft.apeStaking.stakedAmount || 0 }];
    let encodedFnData;
    switch (poolId) {
      case PoolId.BAYC: {
        encodedFnData = iface.encodeFunctionData("withdrawBAYC", [params, withdrawWallet]);
        break;
      }
      case PoolId.MAYC: {
        encodedFnData = iface.encodeFunctionData("withdrawMAYC", [params, withdrawWallet]);
        break;
      }
      case PoolId.BAKC: {
        const { pairedTokenId, pairedTokenPool } = nft.apeStaking;
        if (!nft.apeStaking.stakedAmount) throw new Error("Staked amount not found");
        if (!pairedTokenId || !pairedTokenPool) throw new Error("Paired token ID not found");
        const param = {
          bakcTokenId: nft.tokenId,
          mainTokenId: pairedTokenId,
          amount: params[0].amount,
          isUncommit: true,
        };
        encodedFnData = iface.encodeFunctionData("withdrawBAKC", [
          pairedTokenPool === PoolId.BAYC ? [param] : [],
          pairedTokenPool === PoolId.MAYC ? [param] : [],
        ]);
        break;
      }
      default: {
        throw new Error("Invalid Pool ID");
      }
    }
    return { to: apeStakingContract, data: encodedFnData, value: "0" };
  };

  const unstake = async () => {
    if (!signer || !cyanWallet) throw new Error("Something went wrong");

    const stakedNftsWithPlan = selectedMainNfts
      .filter(nft => nft.apeStaking.plan !== null)
      .map(nft => {
        if (!nft.apeStaking.plan) {
          throw new Error("Something went wrong");
        }
        return {
          ...nft,
          planId: nft.apeStaking.plan?.planId,
        };
      });
    const stakedNfts = selectedMainNfts.filter(nft => nft.apeStaking.plan === null);

    const transactionsForCompletePlan = stakedNftsWithPlan.map(plan => buildCompletePlanTxn(plan.planId));
    const transactionsForUnstake = stakedNfts.map(nft => buildUnstakeTxn(nft));

    const txRequest = createBatchTxn(cyanWallet, [...transactionsForUnstake, ...transactionsForCompletePlan]);
    const tx = await signer.sendTransaction(txRequest);

    const newTxns: ITransaction[] =
      stakedNftsWithPlan.length > 0
        ? stakedNftsWithPlan.map(pricedResult => ({
            hash: tx.hash,
            type: "ape-plan-complete",
            expiresAt: Date.now() + 1000 * 60 * 5, // 5 minutes
            data: {
              planId: pricedResult.planId,
              tokenId: pricedResult.tokenId,
              contractAddress: pricedResult.address.toLowerCase(),
            },
          }))
        : [
            {
              hash: tx.hash,
              type: "ape-unstake",
              expiresAt: Date.now() + 1000 * 60 * 5, // 5 minutes
            },
          ];
    setTransactions(oldTxns => [...oldTxns, ...newTxns]);
    setActiveTx(tx.hash);
  };

  useEffect(() => {
    const onStepChange = async (step: UnstakingSteps) => {
      try {
        switch (step) {
          case UnstakingSteps.CreatingCyanWallet: {
            if (!cyanWallet && signer) {
              await createNewWallet(signer);
              setIsWalletCreated(true);
            }
            setSelectedStep(UnstakingSteps.Unstaking);
            return;
          }
          case UnstakingSteps.Unstaking: {
            await unstake();
            return;
          }
        }
      } catch (err: any) {
        openModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);

  const openModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    showApeBulkPlanModal({
      ...params,
      err: mappedError,
    });
  };

  const stepMarkFiltered = useMemo(() => {
    return UnstakingStepMarks.filter(item => {
      if (cyanWallet && item.value === UnstakingSteps.CreatingCyanWallet && !isWalletCreated) {
        return false;
      }
      return true;
    }).map(item => {
      if (item.value === UnstakingSteps.Unstaking && txnFinal !== null) {
        return {
          ...item,
          description: `View on Etherscan`,
        };
      }
      return item;
    });
  }, [cyanWallet, isWalletCreated, txnFinal]);
  return (
    <Flex gap="1rem" direction="column">
      <BulkStakingProgressLoader selectedNfts={selectedMainNfts} isLoading={selectedStep != UnstakingSteps.Done} />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === UnstakingSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum UnstakingSteps {
  CreatingCyanWallet = 1,
  Unstaking = 2,
  Done = 3,
}

const UnstakingStepMarks = [
  {
    value: UnstakingSteps.CreatingCyanWallet,
    description: `A new wallet is created on the first bulk staking on Cyan`,
    title: `Creating Cyan Wallet`,
  },
  {
    value: UnstakingSteps.Unstaking,
    title: `Unstaking APE Coin`,
    description: `A small amount of gas is required to unstake $APE`,
  },
];
