import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "../../config";
import { Text } from "../core/Text";

export const SlashButton = styled.button<{ isBigger?: boolean }>`
  background: ${({ theme }) => (theme.theme === "light" ? "#EEEEEE" : theme.colors.gray20)};
  border: none;
  margin: 0;
  border-radius: 5px;
  min-width: ${({ isBigger }) => (isBigger ? "35px" : "30px")};
  min-height: 30px;
  max-width: ${({ isBigger }) => (isBigger ? "35px" : "30px")};
  max-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getStyleWithMediaQuery("display", "", [{ [breakpoints.tablet]: "none" }])};
`;

export const SlashText = styled(Text)`
  color: ${({ theme }) => (theme.theme === "light" ? theme.colors.black : theme.colors.gray0)};
`;
