import { IPlan } from "../Account/plan.types";

export const BNPLStatuses = {
  Pending: 0,
  Funded: 1,
  Activated: 2,
  Completed: 3,
  Defaulted: 4,
  Liquidated: 5,
  Rejected: 6,
} as const;
export type IBNPLStatus = typeof BNPLStatuses[keyof typeof BNPLStatuses];
export interface IBNPL extends IPlan {
  price: string;
  status: IBNPLStatus;
  downpaymentPercent: number;
  downpaymentAmount: string;
  planType: "BNPL";
}

export const isBnplPlan = (item: any): item is IBNPL => {
  return "planType" in item && item.planType === "BNPL";
};
