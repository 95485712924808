import styled from "styled-components";

import { Flex } from "../../Flex";
import { breakpoints, getStyleWithMediaQuery } from "../../config";
import { Text } from "../core";
import { CyanLogoBlack } from "../images";

export const NotFound = ({ msg, w = 185, h = 185 }: { msg: string; w?: number; h?: number }) => {
  return (
    <Container>
      <Image src={CyanLogoBlack} width={w} height={h} />
      <Text size="xl" weight="600" color="gray20">
        {msg}
      </Text>
    </Container>
  );
};

const Container = styled(Flex)`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "80px 0" },
    { [breakpoints.tablet]: "50px 0" },
    { [breakpoints.mobile]: "25px 0" },
  ])};
`;

const Image = styled.img`
  margin-bottom: 30px;
`;
