import { BigNumber } from "ethers";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Text } from "@cyanco/components/theme/v3";
import { ArrowRight } from "@cyanco/components/theme/v3/icons";

import { bigNumToFloat, numberWithCommas } from "@/utils";

import { useApeStakingUserAssets } from "../../../ApeCoinDataContext";
import { ApeCoinStakingInput } from "../../common";
import { ReleaseWalletSelector } from "./ReleaseWalletSelector";
import { SourceWalletSelector } from "./SourceWalletSelector";

export const ApeCoinUnstaking = ({
  apeCoinBalance,
  unstakingAmount,
  releaseWallet,
  sourceWallet,
  setSourceWallet,
  setReleaseWallet,
  setUnstakingAmount,
}: {
  apeCoinBalance: { stakedAmount: null | BigNumber; earnedAmount: BigNumber | null };
  unstakingAmount?: string;
  releaseWallet: string;
  sourceWallet: string;
  setSourceWallet: (a: string) => void;
  setReleaseWallet: (value: string) => void;
  setUnstakingAmount: (value?: string) => void;
}) => {
  const theme = useTheme();
  const stakedAmount = bigNumToFloat(apeCoinBalance.stakedAmount || 0);
  const { activeApeCoinPlan } = useApeStakingUserAssets();

  return (
    <Flex direction="column" gap="1.2rem">
      <SourceWalletSelector setSourceWallet={setSourceWallet} sourceWallet={sourceWallet} />
      <Flex direction="column" gap="0.3rem">
        <Flex justifyContent="space-between" p="0 2px">
          <Text color="gray0" size="xs" weight="600">
            {`Staked amount`}
          </Text>
          <Text color="gray0" size="xs" weight="500">
            {`${`Max`}: ${numberWithCommas(stakedAmount)} APE`}
          </Text>
        </Flex>
        <ApeCoinStakingInput
          onInputChange={setUnstakingAmount}
          stakingAmount={activeApeCoinPlan ? stakedAmount.toString() : unstakingAmount}
          max={bigNumToFloat(apeCoinBalance.stakedAmount || BigNumber.from("0"), 18)}
          disabled={activeApeCoinPlan !== undefined}
        />
      </Flex>
      <Flex direction="column" gap="0.8rem">
        <StakedBox>
          <Flex direction="column" w="100%" gap="0.3rem">
            <Flex justifyContent="space-between" w="100%">
              <Text color="secondary" size="xs" weight="600">
                {`Total Staked`}
              </Text>
              <Flex alignItems="center" gap="4px">
                <Text color="gray0" size="xs" weight="600">
                  {numberWithCommas(stakedAmount, 2)} APE
                </Text>
                <ArrowRight color={theme.colors.gray0} width={9} height={9} />
                <Text color="secondary" size="xs" weight="600">
                  {numberWithCommas(stakedAmount, 2)}
                  APE
                </Text>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" w="100%">
              <Text color="secondary" size="xs" weight="600">
                {`Rewards`}
              </Text>
              <Text color="secondary" size="xs" weight="600">
                {numberWithCommas(bigNumToFloat(apeCoinBalance.earnedAmount || 0), 2)} APE
              </Text>
            </Flex>
          </Flex>
        </StakedBox>
        {!activeApeCoinPlan && (
          <ReleaseWalletSelector releaseWallet={releaseWallet} setReleaseWallet={setReleaseWallet} />
        )}
      </Flex>
    </Flex>
  );
};

const StakedBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 1rem 0.8rem;
`;
