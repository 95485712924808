import { useWeb3React } from "@web3-react/core";
import dayjs from "dayjs";
import { useContext, useMemo, useState } from "react";
import { useAsync } from "react-async-hook";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Button, Flex, SkeletonLine, Text, useModal } from "@cyanco/components/theme";

import { useAppContext } from "@/components/AppContextProvider";
import { AuthContext } from "@/components/AuthContext/AuthContextProvider";
import { usePointData } from "@/components/CyanPoints/PointDataContext";
import { formatCyanPoint } from "@/components/CyanPoints/utils";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { useUserLog } from "@/hooks/userLog";
import { Experiments } from "@/utils/experimentList";

import { useBNPLPositions, useCreatedLoanBids, usePawnPositions, usePeerPlans } from "../../AccountDataContext";
import { P2PLendingSelectedViews, PositionsSelectedViews, useAccountPageContext } from "../../AccountPageContext";

export const Shortcuts = () => {
  const navigate = useNavigate();
  const { experiment } = useAppContext();
  const cyanWallet = useCyanWallet();

  const { setSelectedLendingView, setSelectedPositionsView } = useAccountPageContext();
  const { userPoint } = usePointData();
  const { account } = useWeb3React();
  const { fetchUserBe } = useContext(AuthContext);
  const { createdLoanBids, createdLoanBidsLoading } = useCreatedLoanBids();
  const { bnplLoading, activeBnplPositions } = useBNPLPositions();
  const { pawnLoading, activePawnPositions } = usePawnPositions();
  const { userActiveTakenPeerPlans, userActiveGivenPeerPlans, peerPlansLoading } = usePeerPlans();
  const { userLogs } = useUserLog();
  const totalLoanNumber = useMemo(() => {
    if (!experiment.result || !experiment.result[Experiments.P2P])
      return activeBnplPositions.length + activePawnPositions.length;
    return activePawnPositions.length + activeBnplPositions.length + userActiveTakenPeerPlans.length;
  }, [activeBnplPositions, activePawnPositions, userActiveTakenPeerPlans, experiment]);
  const totalGivenLoanNumber = useMemo(() => {
    return userActiveGivenPeerPlans.length;
  }, [userActiveGivenPeerPlans]);
  const totalBidsNumber = useMemo(() => {
    const bids = createdLoanBids.filter(bid => bid.isActive && !bid.isExpired);
    return bids.length;
  }, [createdLoanBids]);

  const { result: bannedSeconds = 0 } = useAsync<number>(async () => {
    const { banExpirationDate } = await fetchUserBe();
    return banExpirationDate ? dayjs(banExpirationDate).diff(dayjs(), "seconds") : 0;
  }, [account, userLogs]);

  const { setModalContent } = useModal();

  return (
    <Flex gap="0.55rem">
      {totalLoanNumber !== 0 && (
        <Shortcut
          onClick={() => {
            setSelectedPositionsView(PositionsSelectedViews.current);
            navigate("/account/positions");
          }}
        >
          {peerPlansLoading || bnplLoading || pawnLoading ? (
            <Loader w="98px" />
          ) : (
            <Text weight="700" size="sm" color="secondary">
              {`${totalLoanNumber} loan${totalLoanNumber > 1 ? "s" : ""} taken`}
            </Text>
          )}
        </Shortcut>
      )}
      {experiment.result && experiment.result[Experiments.P2P] && (
        <>
          {totalGivenLoanNumber !== 0 && (
            <Shortcut
              onClick={() => {
                navigate("/account/lending");
                setSelectedLendingView(P2PLendingSelectedViews.current);
              }}
            >
              {peerPlansLoading ? (
                <Loader w="96px" />
              ) : (
                <Text weight="700" size="sm" color="secondary">
                  {`${totalGivenLoanNumber} loan${totalGivenLoanNumber > 1 ? "s" : ""} given`}
                </Text>
              )}
            </Shortcut>
          )}
          {totalBidsNumber !== 0 && (
            <Shortcut
              onClick={() => {
                navigate("/account/lending");
                setSelectedLendingView(P2PLendingSelectedViews.offers);
              }}
            >
              {createdLoanBidsLoading ? (
                <Loader w="48px" />
              ) : (
                <Text weight="700" size="sm" color="secondary">
                  {`${totalBidsNumber} offer${totalBidsNumber > 1 ? "s" : ""}`}
                </Text>
              )}
            </Shortcut>
          )}
        </>
      )}
      <Shortcut
        onClick={() => {
          navigate("/points");
        }}
        style={{ background: "rgba(0, 255, 163, 1)" }}
      >
        <Text weight="700" size="sm" color="black">
          {`Pending pts: `} {userPoint?.totalPoint?.unrealized ? formatCyanPoint(userPoint.totalPoint.unrealized) : "0"}
        </Text>
      </Shortcut>
      {bannedSeconds > 0 && (
        <Shortcut style={{ background: "red" }}>
          <Text weight="700" size="sm" color="black">
            {`Banned from loans for ${getPaymentInterval(bannedSeconds)}`}
          </Text>
        </Shortcut>
      )}

      {cyanWallet &&
        cyanWallet.walletAddress.toLowerCase() === "0xaA79CCF0c5fd32D914daD9aB01943b92ca0EbD4d".toLowerCase() && (
          <Shortcut
            style={{ background: "red" }}
            onClick={() => {
              setModalContent({
                title: "Batch Transactions",
                content: <BatchTemp />,
              });
            }}
          >
            <Text weight="700" size="sm" color="black">
              Batch Transactions
            </Text>
          </Shortcut>
        )}
    </Flex>
  );
};

const BatchTemp = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const { provider } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const batch = async () => {
    setLoading(true);
    try {
      const signer = provider?.getSigner();
      if (!signer || !cyanWallet) return;
      const tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        data,
      });
      await tx.wait();
      setLoading(false);
      setDone(true);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Flex direction="column" gap="10px">
      <Flex direction="column" gap="5px">
        <Text color="secondary">Transaction data</Text>
        <TextArea value={data} onChange={e => setData(e.target.value)} />
      </Flex>

      {done ? (
        <Button
          style={{
            padding: "10px 20px",
          }}
        >
          Done
        </Button>
      ) : loading ? (
        <Button
          style={{
            padding: "10px 20px",
          }}
        >
          Loading
        </Button>
      ) : (
        <Button
          onClick={batch}
          style={{
            padding: "10px 20px",
          }}
        >
          Batch
        </Button>
      )}
    </Flex>
  );
};

const Shortcut = styled.div`
  border-radius: 100px;
  background: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#C8C8C8")};
  padding: 8px 16px;
  height: fit-content;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    background: ${({ theme }) => theme.colors.gray30};
  }
`;

const Loader = styled(SkeletonLine)`
  height: 17px;
`;

const TextArea = styled.textarea`
  color: ${({ theme }) => `${theme.colors.gray0}`};
  background: ${({ theme }) => ` ${theme.colors.transparent}`};
  font-size: 14px;
  outline: none;
  resize: vertical;
  height: 120px;
  padding: 10px;
  transition: 0.2s;
  font-weight: 600;
  font-family: Inter;
  color: ${({ theme }) => `${theme.colors.secondary}`};
  border-radius: 8px;
`;
