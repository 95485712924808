import React, { ReactNode } from "react";
import styled, { useTheme } from "styled-components";

import { Loader, Text } from "..";
import { Flex } from "../../Flex";
import { CheckCircle, NewTab } from "../icons";

export const Stepper: React.FC<{
  marks: { value: number; title: string; description?: string }[];
  selectedStep: number;
  txUrl: string;
  failedMark?: { value: number; title: string; description?: string | ReactNode };
}> = ({ marks, selectedStep, txUrl, failedMark }) => {
  const theme = useTheme();

  return (
    <Flex direction="column" gap="43px" w="100%">
      {marks.map(({ value, title, description }, index) => {
        const titleColor = value === selectedStep ? "secondary" : value > selectedStep ? "gray20" : "cyan";
        const isActive = value === selectedStep;
        const isDone = value < selectedStep;
        const isLast = index + 1 === marks.length;
        if (isActive && failedMark) {
          return (
            <Flex key={value} w="100%" gap="8px">
              <StepperCircleWrapper isActive={isActive} isDone={isDone} isLast={isLast} hasError>
                <StepperCircle isActive={isActive} isDone={isDone} isLast={isLast} hasError />
              </StepperCircleWrapper>
              <Flex direction="column" alignItems="flex-start" w="100%">
                <Flex alignItems="center" gap="5px">
                  <Text size="lg" color={"red"} weight="500" textWrap>
                    {failedMark.title}
                  </Text>
                </Flex>
                {failedMark.description && (
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                      }}
                    >
                      <Text size="xs" weight="400" color={"secondary"} textWrap>
                        {failedMark.description}
                      </Text>
                    </div>
                  </div>
                )}
              </Flex>
            </Flex>
          );
        }
        return (
          <Flex key={value} w="100%" gap="8px">
            <StepperCircleWrapper isActive={isActive} isDone={isDone} isLast={isLast}>
              <StepperCircle isActive={isActive} isDone={isDone} isLast={isLast} />
            </StepperCircleWrapper>
            <Flex direction="column" alignItems="flex-start" w="100%">
              <Flex alignItems="center" gap="5px">
                <Text size="lg" color={titleColor} weight="500" textWrap>
                  {title}
                </Text>
                {isActive ? (
                  <Loader stroke={theme.colors.secondary} size="16px" />
                ) : (
                  isDone && <CheckCircle color={theme.colors.cyan} height={18} width={18} />
                )}
              </Flex>
              {description && (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      cursor: txUrl !== "" ? "pointer" : "default",
                    }}
                  >
                    {isActive && (
                      <Text size="xs" weight="400" color={"secondary"} textWrap>
                        {description}
                      </Text>
                    )}
                    {txUrl !== "" && isLast && selectedStep > value && (
                      <Flex
                        onClick={() => {
                          window.open(txUrl, "_blank");
                        }}
                        gap="5px"
                        mt="2px"
                      >
                        <NewTab color={theme.colors.secondary} height={12} width={12} />
                        <Text size="xs" weight="400" color={"secondary"} textWrap>
                          {description}
                        </Text>
                      </Flex>
                    )}
                  </div>
                </div>
              )}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
const StepperCircle = styled.div<{ isActive: boolean; isDone: boolean; isLast: boolean; hasError?: boolean }>`
  min-width: 9px;
  min-height: 9px;
  max-width: 9px;
  max-height: 9px;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${({ theme, isActive, isDone, hasError }) => {
    if (hasError) {
      return theme.colors.red;
    }
    return isActive || isDone ? theme.colors.cyan : theme.colors.gray20;
  }};
  background-color: ${({ theme, isDone, hasError }) => {
    if (hasError) {
      return theme.colors.red;
    }
    return isDone ? theme.colors.cyan : theme.colors.gray10;
  }};
  margin-top: 4px;
`;

const StepperCircleWrapper = styled.div<{ isActive: boolean; isDone: boolean; isLast: boolean; hasError?: boolean }>`
  min-height: 100%;
  max-height: 100%;
  position: relative;
  :before {
    position: absolute;
    content: "";
    display: ${({ isLast }) => (isLast ? "none" : "block")};
    width: 3px;
    height: calc(100% + 43px);
    background-color: ${({ theme, isDone }) => (isDone ? theme.colors.cyan : theme.colors.gray10)};
    top: calc(100% - calc(100% - 17px));
    left: 5px;
    z-index: 0;
  }
`;
