import { Flex } from "@cyanco/components/theme/Flex";

import { VaultTokensSelectedViews, useVaultTokensTabContext } from "../../AccountPageContext";
import { CurrentVaults } from "./CurrentVaults";
import { HistoricalVaults } from "./HistoricalVaults";
import { VaultViewSelectors } from "./VaultViewSelectors";

export const VaultView = () => {
  const { selectedVaultTokensView } = useVaultTokensTabContext();
  return (
    <Flex direction="column">
      <VaultViewSelectors />
      <div
        style={{
          display: selectedVaultTokensView === VaultTokensSelectedViews.current ? "block" : "none",
        }}
      >
        <CurrentVaults />
      </div>
      <div
        style={{
          display: selectedVaultTokensView === VaultTokensSelectedViews.historical ? "block" : "none",
        }}
      >
        <HistoricalVaults />
      </div>
    </Flex>
  );
};
