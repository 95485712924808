import { BigNumber } from "ethers";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { Box, Flex, Text } from "@cyanco/components/theme";
import {
  Button,
  Card,
  NftMetadataInline,
  NftMetadataInlineImage,
  SwitchButton,
  SwitchButtonGroup,
  SystemMessage,
  useModal,
} from "@cyanco/components/theme/v3";
import { NewTab } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IPeerPlan } from "@/apis/p2p/types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { StepperContainer } from "@/components/PlanPayment/RegularPayment";
import { useWeb3React } from "@/components/Web3ReactProvider";
import {
  bigNumToFixedStr,
  getChainExplorerURL,
  jumpToLink,
  numberWithCommas,
  shortenAddress,
  shortenName,
} from "@/utils";
import { IMappedError } from "@/utils/error/msgs";

import { LiquidatePlanProgress } from "./LiquidateProgress";

const enum DefaultedPlanOptions {
  liquidate,
  revive,
}
export const DefaultedPlan = ({ plan, error }: { plan: IPeerPlan; error?: IMappedError }) => {
  const { setModalContent } = useModal();
  const theme = useTheme();
  const amountWithInterest = useMemo(() => {
    const fee = BigNumber.from(plan.loanBid.amount).mul(plan.loanBid.interestRate).div(10000);
    return fee.add(plan.loanBid.amount);
  }, [plan]);
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(plan.loanBid.currency.decimal) || 4;
  }, [plan.loanBid.currency.decimal]);

  const liquidate = () => {
    setModalContent({
      title: `Liquidating NFT`,
      content: <LiquidatePlanProgress plan={plan} />,
    });
  };

  return (
    <Flex direction="column" gap="18px">
      <LoanMetadata plan={plan} />
      {error && <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />}
      <Flex direction="column" gap="8px">
        <Text size="sm" weight="400" color="gray0">
          {`Peer-to-Peer Loan`}
        </Text>
        <Box h="25px">
          <SwitchButtonGroup<DefaultedPlanOptions>
            activeBackground={theme.colors.cyan}
            activeTextColor={theme.colors.black}
            borderColor={theme.colors.gray20}
            value={DefaultedPlanOptions.liquidate}
            onChange={() => {}}
            hover
          >
            <SwitchButton value={DefaultedPlanOptions.liquidate}>{`Liquidate NFT`}</SwitchButton>
            <SwitchButton value={DefaultedPlanOptions.revive} disabled>{`Revive Loan`}</SwitchButton>
          </SwitchButtonGroup>
        </Box>
      </Flex>
      <StyledCard p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Text size="sm" weight="600" color="secondary">
            {`Defaulted Loan `}{" "}
          </Text>
          <StepperContainer>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="8px">
                <DueCircle />
                <Text size="xs" weight="500" color="gray0">
                  {`missed`}
                </Text>
              </Flex>
              <Text size="xs" weight="500" color="secondary">
                {`${numberWithCommas(
                  bigNumToFixedStr(amountWithInterest, formatNumber, plan.loanBid.currency.decimal),
                  formatNumber,
                )} ${plan.loanBid.currency.symbol}`}
              </Text>
            </Flex>
          </StepperContainer>
          <Flex justifyContent="flex-end">
            <Text size="xs" weight="500" color="gray0">
              {`Borrowed Missed Payment`}
            </Text>
          </Flex>
        </Flex>
      </StyledCard>
      <Button style={{ height: "50px" }} onClick={liquidate}>{`Liquidate NFT`}</Button>
    </Flex>
  );
};
export const LoanMetadata = ({ plan }: { plan: IPeerPlan }) => {
  const { chainId } = useWeb3React();
  const theme = useTheme();
  const currencySymbol = plan.loanBid.currency.symbol;

  return (
    <Flex gap="10px">
      <NftMetadataInlineImage imageUrl={plan.metadata ? plan.metadata.imageUrl : NoImage} />
      <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 15px 0">
        <NftMetadataInline name={plan.collection.name} value={`#${shortenName(plan.tokenId, 10, 5)}`} />
        <NftMetadataInline
          name={`Loaned Amount`}
          value={`${numberWithCommas(
            bigNumToFixedStr(BigNumber.from(plan.loanBid.amount), 2, plan.loanBid.currency.decimal),
            2,
          )} ${currencySymbol}`}
          sub
        />
        <Flex justifyContent="space-between">
          <Text color="gray0" size="xs" weight="500">
            {`Borrower`}
          </Text>
          <BorrowerAddress
            gap="0.3rem"
            alignItems="center"
            onClick={() => jumpToLink(`${getChainExplorerURL(chainId)}/address/${plan.borrowerAddress}`)}
          >
            <Text size="xs" color="gray0">
              {shortenAddress(plan.borrowerAddress)}
            </Text>
            <NewTab color={theme.colors.gray0} height={14} width={14} />
          </BorrowerAddress>
        </Flex>
      </Flex>
    </Flex>
  );
};
const BorrowerAddress = styled(Flex)`
  cursor: pointer;
  transition: 0.2s;
  :hover {
    opacity: 0.8;
  }
`;
const StyledCard = styled(Card)`
  :hover {
    border-color: ${({ theme }) => theme.colors.gray20} !important;
  }
`;
const DueCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
  position: relative;
`;
