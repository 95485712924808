import React, { useState } from "react";

import { useSelectedStakingNftsContext, useSelectedUnstakingNftsContext } from "../../ApeCoinPageContext";
import { IActionType, IApeBulkStakingModal, IApeCoinSource } from "../../types";
import { ApeCoinBulkStakingModal } from "./BulkStakingModal";
import { ApeCoinStakingModal } from "./StakingModal";

export const ApePlanModal: React.FC<IApeBulkStakingModal> = ({ selectedMainNfts: _nfts, ...params }) => {
  const { action } = params;
  const { removeItem: _removeStakingItem } = useSelectedStakingNftsContext();
  const { removeItem: _removeUnstakingItem } = useSelectedUnstakingNftsContext();
  const [nfts, setNfts] = useState(_nfts);
  const removeStakingPlan = (nft: { address: string; tokenId: string }) => {
    setNfts(prev => prev.filter(_nft => !(nft.address === _nft.address && _nft.tokenId === nft.tokenId)));
    _removeStakingItem({
      collectionAddress: nft.address,
      tokenId: nft.tokenId,
    });
  };
  const removeUnstakingPlan = (nft: { address: string; tokenId: string }) => {
    setNfts(prev => prev.filter(_nft => !(nft.address === _nft.address && _nft.tokenId === nft.tokenId)));
    _removeUnstakingItem({
      collectionAddress: nft.address,
      tokenId: nft.tokenId,
    });
  };
  if (nfts.length <= 1) {
    return (
      <ApeCoinStakingModal
        {...params}
        selectedMainNfts={nfts}
        apeCoinSource={nfts.length > 0 && nfts[0].apeStaking.plan ? IApeCoinSource.borrow : IApeCoinSource.owned}
      />
    );
  }
  return (
    <ApeCoinBulkStakingModal
      {...params}
      selectedMainNfts={nfts}
      removePlan={action === IActionType.stake ? removeStakingPlan : removeUnstakingPlan}
    />
  );
};
