import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled, { useTheme } from "styled-components";

import { Button, Flex, Text, TitleContainer } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { ICumulativePoints } from "@/apis/cyan-points";
import { useGetUserTime } from "@/hooks/useGetUserTime";
import { formatCompactNumber, numberWithCommas } from "@/utils";

import { HomeTitleWebkit } from ".";
import { usePointData } from "./PointDataContext";
import { SectionContainer } from "./UserProgress";

enum PointChartInterval {
  "7D" = "7D",
  "1M" = "1M",
  "3M" = "3M",
}
const getHistoriesByOneDay = (histories: ICumulativePoints, group: (a: string) => string) => {
  const maxValueDates = histories.reduce<{
    [key: string]: { realizedDate: Date; realizedPoint: number; totalRealisedPoint?: number };
  }>((acc, curHistory) => {
    const historyDate = group(curHistory.realizedDate.toString());
    const prevVaultHistory = acc[historyDate];
    if (
      !prevVaultHistory ||
      (curHistory.totalRealisedPoint &&
        prevVaultHistory.totalRealisedPoint &&
        curHistory.totalRealisedPoint > prevVaultHistory.totalRealisedPoint)
    ) {
      acc[historyDate] = curHistory;
    }
    return acc;
  }, {});
  const graphReadyData = Object.values(maxValueDates).map(({ realizedDate, totalRealisedPoint }) => ({
    "Total Realised Points": totalRealisedPoint ?? 0,
    "Realised Date": realizedDate,
  }));
  return graphReadyData;
};

const getFilteredHistoryByDate = (days: number, cumulativePointsWithTotalRealised: ICumulativePoints) => {
  const now = dayjs();
  const date = now.subtract(days, "day");
  return cumulativePointsWithTotalRealised.filter(({ realizedDate }) => dayjs(realizedDate).isAfter(date));
};

export const CumulativePoints = () => {
  const theme = useTheme();
  const { cumulativePoints } = usePointData();
  const { getUserPreferredTime } = useGetUserTime();
  const [selectedPeriod, setSelectedPeriod] = useState<PointChartInterval>(PointChartInterval["7D"]);

  // realised points by date since season started
  const cumulativePointsWithTotalRealised = useMemo(() => {
    const items: ICumulativePoints = [];
    cumulativePoints.forEach((item, index) => {
      const prev = items[index - 1];
      items.push({
        ...item,
        totalRealisedPoint: item.realizedPoint + (prev?.totalRealisedPoint ?? 0),
      });
    });
    return items;
  }, [cumulativePoints]);

  const sevenDay = useMemo(
    () =>
      getFilteredHistoryByDate(7, cumulativePointsWithTotalRealised).map(({ realizedDate, totalRealisedPoint }) => ({
        "Total Realised Points": totalRealisedPoint ?? 0,
        "Realised Date": realizedDate,
      })),
    [cumulativePointsWithTotalRealised],
  );

  const oneMonth = useMemo(() => {
    return getHistoriesByOneDay(getFilteredHistoryByDate(30, cumulativePointsWithTotalRealised), createdAt => {
      return dayjs(createdAt).format(
        cumulativePointsWithTotalRealised.length <= 20 ? "YYYY-MM-DD-HH-mm" : "YYYY-MM-DD-HH",
      );
    });
  }, [cumulativePointsWithTotalRealised]);

  const threeMonths = useMemo(() => {
    return getHistoriesByOneDay(cumulativePointsWithTotalRealised, createdAt => {
      return dayjs(createdAt).format(
        cumulativePointsWithTotalRealised.length <= 30 ? "YYYY-MM-DD-HH-mm" : "YYYY-MM-DD",
      );
    });
  }, [cumulativePointsWithTotalRealised]);

  const getGraphData = () => {
    switch (selectedPeriod) {
      case PointChartInterval["7D"]:
        return sevenDay;
      case PointChartInterval["1M"]:
        return oneMonth;
      case PointChartInterval["3M"]:
        return threeMonths;
      default:
        return oneMonth;
    }
  };

  return (
    <SectionContainer direction="column" alignItems="center">
      <TitleContainer alignItems="center" style={{ paddingBottom: 0 }}>
        <HomeTitleWebkit>Cumulative Points</HomeTitleWebkit>
      </TitleContainer>

      <Container w="100%" h="300px" direction="column">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={getGraphData()}>
            <XAxis dataKey={"Realised Date"} tickLine={false} tick={false} height={10} />
            <YAxis
              tickLine={false}
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 600,
              }}
              domain={["dataMin", "dataMax "]}
              tickFormatter={value => `${formatCompactNumber(value)}`}
            />
            <defs>
              <linearGradient id="MyGradient" x1="0%" x2="0%" y1="0%" y2="100%">
                <stop offset="0%" stopColor={theme.colors.green} stopOpacity={1} />
                <stop offset="100%" stopColor={theme.colors.green} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  return (
                    <TooltipBox p="8px 7px" direction="column" gap="5px">
                      <Flex direction="column" gap="0.5rem">
                        {payload.map((item, i) => (
                          <Flex justifyContent="space-between" key={i} alignItems="center" gap="1rem">
                            <Flex gap="10px" alignItems="center">
                              <Text weight="500" size="xs" color="black">
                                {item.name}
                              </Text>
                            </Flex>

                            <Text weight="500" size="xs" color="gray0">
                              <Text weight="600" size="xs" color="black">
                                {`${numberWithCommas(Number(item.value) ?? 0, 2)}  `}
                              </Text>
                              pts.
                            </Text>
                          </Flex>
                        ))}
                      </Flex>
                      <Flex justifyContent="space-between" alignItems="center" gap="1rem">
                        <Text weight="500" size="xs" color="black" textAlign="right">
                          Realised Date:
                        </Text>
                        <Text weight="500" size="xs" color="black" textAlign="right">
                          {getUserPreferredTime(label).format("HHa, DD MMM YYYY ")}
                        </Text>
                      </Flex>
                    </TooltipBox>
                  );
                }
                return null;
              }}
            />
            <Area
              animationDuration={800}
              dot={false}
              dataKey="Total Realised Points"
              stroke="#00FFA3"
              strokeWidth={3}
              fill="url(#MyGradient)"
            />
          </AreaChart>
        </ResponsiveContainer>

        <DateContainer>
          <Dates>
            {(Object.keys(PointChartInterval) as Array<keyof typeof PointChartInterval>).map(period => (
              <Button key={period} variant="ghost" onClick={() => setSelectedPeriod(PointChartInterval[period])}>
                <Text color={selectedPeriod === PointChartInterval[period] ? "secondary" : "gray0"} size="sm">
                  {PointChartInterval[period]}
                </Text>
              </Button>
            ))}
          </Dates>
        </DateContainer>
      </Container>
    </SectionContainer>
  );
};

const Container = styled(Flex)`
  min-height: 300px;
  margin-top: 3rem;
  justify-content: flex-end;
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 200 }])}
  ${getStyleWithMediaQuery("margin-top", "rem", [{ [breakpoints.tablet]: 1 }])}
`;

const DateContainer = styled(Flex)`
  justify-content: flex-end;
  ${getStyleWithMediaQuery("justify-content", "", [{ [breakpoints.tablet]: "flex-start" }])}
  width: 100%;
`;

const Dates = styled(Flex)`
  width: fit-content;
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.desktop]: "fit-content" }, { [breakpoints.tablet]: "100%" }])}
  ${getStyleWithMediaQuery("justify-content", "", [{ [breakpoints.tablet]: "space-between" }])}
  gap: 1rem;
`;

const TooltipBox = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: 10px;
  ${getStyleWithMediaQuery("min-width", "", [{ [breakpoints.mobile]: "auto" }])}
`;
