import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { SupportedCurrencies, SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { Box } from "@cyanco/components/theme/Box";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints } from "@cyanco/components/theme/config";
import {
  Hidden,
  Option,
  Select,
  SwitchButton,
  SwitchButtonGroup,
  Text,
  getCurrencyLogoSrc,
} from "@cyanco/components/theme/v3";
import { Grid, List } from "@cyanco/components/theme/v3/icons";

import { useAppContext } from "@/components/AppContextProvider";
import { HiddenFlex } from "@/components/Bnpl/components/AvailableCollections/CollectionStats";
import { LendView, useLendSelectors } from "@/components/P2P/LendPageContext";
import { Experiments } from "@/utils/experimentList";

import { VaultViewStyle } from ".";
import { VAULT_SORTING_OPTIONS, useVaultSelectors } from "../../VaultPageContext";

export const VaultSelectors: React.FC = () => {
  const location = useLocation();
  const { experiment } = useAppContext();
  const {
    selectedView,
    setSelectedView,
    selectedChainType,
    setSelectedChainType,
    selectedCurrency,
    setSelectedCurrency,
    selectedSort,
    setSelectedSort,
    selectedAutoLiquidationStatus,
    setSelectedAutoLiquidationStatus,
  } = useVaultSelectors();
  const { selectedView: selectedViewPeer, setSelectedView: setSelectedViewPeer } = useLendSelectors();
  return (
    <VaultSelectoWrapper>
      <DropDowns>
        <Hidden tabletDown>
          <Box h="46px" w="170px">
            <Select onChange={setSelectedCurrency} value={selectedCurrency}>
              <Option value={"all"} key={"all"}>
                <Text size="lg" color="secondary" weight="500">
                  All Currencies
                </Text>
              </Option>
              {Object.keys(SupportedCurrencies).map(currency => (
                <Option value={currency} key={currency}>
                  <Flex gap="10px" alignItems="center">
                    <ChainLogo src={getCurrencyLogoSrc(currency as SupportedCurrenciesType)} />
                    <Text size="lg" color="secondary" weight="500">
                      {currency}
                    </Text>
                  </Flex>
                </Option>
              ))}
            </Select>
          </Box>
        </Hidden>
        <Box h="46px" w="150px">
          <Select onChange={setSelectedChainType} value={selectedChainType}>
            <Option value={"all"} key={"all"}>
              <Text size="lg" color="secondary" weight="500">
                All layers
              </Text>
            </Option>
            <Option value={"layer-2"} key={"layer-2"}>
              <Text size="lg" color="secondary" weight="500">
                Layer-2s
              </Text>
            </Option>
          </Select>
        </Box>
        <Box h="46px" w="160px">
          <Select onChange={setSelectedSort} value={selectedSort}>
            <Option value={"none"} key={"none"}>
              <Text size="lg" color="secondary" weight="500">
                Sort by
              </Text>
            </Option>
            {VAULT_SORTING_OPTIONS.map(({ value }) => (
              <Option value={value} key={value}>
                <Text size="lg" color="secondary" weight="500">
                  {value}
                </Text>
              </Option>
            ))}
          </Select>
        </Box>
        {experiment.result && experiment.result[Experiments.AUTO_LIQUIDATION_VAULT] && (
          <Hidden tabletDown>
            <Box h="46px" w="180px">
              <Select onChange={setSelectedAutoLiquidationStatus} value={selectedAutoLiquidationStatus}>
                {["All liquidations", "No liquidation", "Auto-liquidation"].map(value => (
                  <Option value={value} key={value}>
                    <Text size="lg" color="secondary" weight="500">
                      {value}
                    </Text>
                  </Option>
                ))}
              </Select>
            </Box>
          </Hidden>
        )}
      </DropDowns>
      <HiddenFlex></HiddenFlex>
      {experiment.result && experiment.result[Experiments.P2P] && location.pathname === "/lend" && (
        <CartWrapper direction="row" justifyContent="flex-end">
          <ViewSelectorWrapper>
            <ViewType isActive={LendView.p2p === selectedViewPeer} onClick={() => setSelectedViewPeer(LendView.p2p)}>
              <Text size="sm" color={LendView.p2p === selectedViewPeer ? "secondary" : "gray0"} textWrap={false}>
                Peer-to-Peer
              </Text>
            </ViewType>
            <ViewType
              isActive={LendView.vault === selectedViewPeer}
              onClick={() => setSelectedViewPeer(LendView.vault)}
            >
              <Text size="sm" color={LendView.vault === selectedViewPeer ? "secondary" : "gray0"} textWrap={false}>
                Peer-to-Vault
              </Text>
            </ViewType>
          </ViewSelectorWrapper>
        </CartWrapper>
      )}
      <Flex direction="column" justifyContent="flex-end" pb="10px">
        <Flex h="22px">
          <SwitchButtonGroup<VaultViewStyle> value={selectedView} onChange={setSelectedView} hover>
            <SwitchButton value={VaultViewStyle.list} height="22px">
              <List height={10} width={14} />
            </SwitchButton>
            <SwitchButton value={VaultViewStyle.grid} height="22px">
              <Grid height={10} width={10} />
            </SwitchButton>
          </SwitchButtonGroup>
        </Flex>
      </Flex>
    </VaultSelectoWrapper>
  );
};

const VaultSelectoWrapper = styled.div`
  padding-bottom: 20px;
  top: 75px;
  position: sticky;
  background: ${({ theme }) => theme.backgroundColor};
  display: grid;
  grid-template-columns: 2fr 2.5fr 0.5fr;
  gap: 1rem;
  z-index: 6;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 0.5rem;
    top: 60px;
    padding: 5px 0px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    top: 60px;
    padding: 5px 0px;
  }
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    position: absolute;
    transform: translateZ(-1px);
    height: 60px;
    width: 100px;
    left: -70px;
    top: -5px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  :after {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    position: absolute;
    transform: translateZ(-1px);
    height: 70px;
    width: 70px;
    right: -70px;
    top: -5px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
`;

const DropDowns = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  width: 100%;
  gap: 1rem;
  @media only screen and (max-width: ${breakpoints.laptop}px) {
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 0.5rem;
    display: flex:
    width: fit-content;
  }
`;

export const ChainLogo = styled.img`
  width: 20px;
  height: 20px;
`;

export const ViewSelectorWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.gray20};
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 3px;
  flex-direction: row;
`;
export const ViewType = styled.div<{ isActive: boolean }>`
  align-items: center;
  display: flex;
  padding: 0 1rem;
  justify-content: center;
  background-color: ${props => (props.isActive ? props.theme.colors.gray20 : "transparent")};
  border-radius: 7px;
  height: 100%;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    height: 25px;
  }
  :hover {
    color: ${({ theme, isActive }) => !isActive && theme.colors.secondary};
  }
`;
export const CartWrapper = styled(Flex)`
  justify-content: flex-end;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    order: -1;
  }
`;
