import { BigNumber, ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme";
import { Card, SystemMessage, Text, Toggler, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";
import { factories as f } from "@cyanco/contract";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { BAKCAddress, BAYCAddress, CAPS_MAPPED_BY_ADDRESS, MAYCAddress, apeCoinContract } from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IsInTestDrive, bigNumToFloat, isApeCoinStakingPossible, numberWithCommas } from "@/utils";
import { IMappedError } from "@/utils/error/msgs";

import { useApeStakingUserAssets } from "../../ApeCoinDataContext";
import {
  IActionType,
  IApeCoinSource,
  IApeCoinUserBalance,
  IApeStakingModal,
  IApeUserAsset,
  IApeUserPosition,
  ISelectedNft,
} from "../../types";
import { StyledButton } from "../common";
import { ActionSelector } from "./ActionSelector";
import { ApeCoinSourceSelector } from "./ApeCoinSourceSelector";
import { BulkStakingBreakdown } from "./staking/BulkStakingBreakdown";
import { StakingNftSelector } from "./staking/NftSelector";
import { ApeCoinStakingWithPlan } from "./staking/StakingWithPlan";
import { ApeCoinStakingWithoutPlan } from "./staking/StakingWithoutPlan";
import { UnstakingNftSelector } from "./unstaking/NftSelector";
import { ApeCoinUnstakingWithPlan } from "./unstaking/UnstakingWithPlan";
import { ApeCoinUnstakingWithoutPlan } from "./unstaking/UnstakingWithoutPlan";

export const ApeCoinStakingModal: React.FC<IApeStakingModal> = ({
  action,
  apeCoinSource,
  selectedPairingNft: _selectedPairingNft,
  selectedMainNfts: _selectedMainNfts,
  selectedCollection: _selectedCollection,
  err,
}) => {
  const { account, provider, chainId } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const {
    initiateBorrowedApeStake,
    initiateBorrowedApeBulkStake,
    initiateBorrowedApeUnstake,
    initiateOwnedApeUnstake,
  } = useApePlanCreation();
  const { stakableAssets, stakablePositions, stakedPositions, stakedAssets } = useApeStakingUserAssets();

  const [userBalance, setUserBalance] = useState<IApeCoinUserBalance>({
    mainWalletMax: null,
    cyanWalletMax: null,
  });
  const [error, setError] = useState<IMappedError | null>(err || null);
  const [warnings, setWarnings] = useState({
    fullAmount: false, // withdrawing with the full staked amount will trigger an automatic claim
    planComplete: false, // pending reward will be transferred to the ApeVault
  });
  const [selectedAction, setSelectedAction] = useState<IActionType>(action);
  const [selectedSource, setSelectedSource] = useState<IApeCoinSource>(apeCoinSource);

  // BAYC, MAYC and BAKC addresses for staking
  const [selectedCollection, setSelectedCollection] = useState<string>(
    _selectedCollection
      ? _selectedCollection.toLowerCase()
      : _selectedMainNfts && _selectedMainNfts.length > 0
      ? _selectedMainNfts[0].address.toLowerCase()
      : BAYCAddress.toLowerCase(),
  );

  const [selectedNftParam, setSelectedNftParam] = useState<ISelectedNft | null>(
    _selectedMainNfts && _selectedMainNfts.length > 0 ? _selectedMainNfts[0] : null,
  );

  const getDefaultSelectedNft = useCallback(
    (_assets: IApeUserAsset, _positions: IApeUserPosition) => {
      let nft = null;
      let position = null;
      switch (selectedCollection) {
        case BAKCAddress: {
          const assets = _assets.filter(item => item.address.toLowerCase() === BAKCAddress);
          if (assets.length > 0) nft = assets[0];
          const positions = _positions.filter(item => item.metadata.collectionAddress.toLowerCase() === BAKCAddress);
          if (positions.length > 0) position = positions[0];
          break;
        }
        case MAYCAddress: {
          const assets = _assets.filter(item => item.address.toLowerCase() === MAYCAddress);
          if (assets.length > 0) nft = assets[0];
          const positions = _positions.filter(item => item.metadata.collectionAddress.toLowerCase() === MAYCAddress);
          if (positions.length > 0) position = positions[0];
          break;
        }
        case BAYCAddress: {
          const assets = _assets.filter(item => item.address.toLowerCase() === BAYCAddress);
          if (assets.length > 0) nft = assets[0];
          const positions = _positions.filter(item => item.metadata.collectionAddress.toLowerCase() === BAYCAddress);
          if (positions.length > 0) position = positions[0];
          break;
        }
      }
      if (nft) return nft;
      if (position)
        return {
          address: position.metadata.collectionAddress,
          tokenId: position.tokenId,
          isCyanWallet: true,
          imageUrl: position.metadata.imageUrl,
          apeStaking: position.apeStaking,
        };
      return null;
    },
    [selectedCollection],
  );

  const defaultSelectedNft = useMemo(() => {
    if (
      selectedNftParam &&
      selectedNftParam.address.toLowerCase() === selectedCollection &&
      selectedAction === IActionType.stake
    ) {
      setSelectedNftParam(null);
      return selectedNftParam;
    }
    return getDefaultSelectedNft(stakableAssets, stakablePositions);
  }, [stakableAssets, stakablePositions, selectedCollection, selectedAction]);

  const defaultSelectedUnstakingNft = useMemo(() => {
    if (
      selectedNftParam &&
      selectedNftParam.address.toLowerCase() === selectedCollection &&
      selectedAction === IActionType.unstake
    ) {
      setSelectedNftParam(null);
      return selectedNftParam;
    }
    return getDefaultSelectedNft(stakedAssets, stakedPositions);
  }, [stakedPositions, stakedAssets, selectedCollection]);

  // BAYC, MAYC addresses for staking
  const [selectedPairingCollection, setSelectedPairingCollection] = useState<string>(
    _selectedPairingNft ? _selectedPairingNft.address.toLowerCase() : MAYCAddress.toLowerCase(),
  );
  // BAYC, MAYC and BAKC for staking
  const [selectedMainNfts, setSelectedMainNfts] = useState<ISelectedNft[]>(
    _selectedMainNfts && _selectedMainNfts.length > 0 && selectedAction === IActionType.stake ? _selectedMainNfts : [],
  );
  // BAYC, MAYC for pairinf
  const [selectedPairingNft, setSelectedPairingNft] = useState<ISelectedNft | null>(
    _selectedPairingNft && _selectedPairingNft.address.toLowerCase() !== BAKCAddress ? _selectedPairingNft : null,
  );
  // NFT for unstaking
  const [selectedUnstakingNft, setSelectedUnsakingNft] = useState<ISelectedNft | null>(
    _selectedMainNfts && _selectedMainNfts.length > 0 && selectedAction === IActionType.unstake
      ? _selectedMainNfts[0]
      : null,
  );
  // Borrowing amount and  main wallet staking a
  const [stakingAmount, setStakingAmount] = useState<string>();
  // Cyan wallet staking amount
  const [stakingAmountCyan, setStakingAmountCyan] = useState<string>();
  // Unstaking amount
  const [unstakingAmount, setUnstakingAmount] = useState<string>();
  // Wallet address for APE release /owned/
  const [releaseWallet, setReleaseWallet] = useState<string>(account ?? "");
  // Flag for rewrad to cyan vault /owned/
  const [rewardStakeToCyanVault, setRewardStakeToCyanVault] = useState<boolean>(true);

  useEffect(() => {
    const _setApeCoinBalance = async () => {
      if (!provider || !account || !isApeCoinStakingPossible(chainId)) return;
      const apeCoinContractWriter = f.ApeCoinFactory.connect(apeCoinContract, provider);
      let balanceCyan = BigNumber.from(0);
      const balanceMain = await apeCoinContractWriter.balanceOf(account);
      if (cyanWallet) balanceCyan = await apeCoinContractWriter.balanceOf(cyanWallet.walletAddress);
      setUserBalance({
        mainWalletMax: balanceMain,
        cyanWalletMax: balanceCyan,
      });
    };
    _setApeCoinBalance();
  }, [account, cyanWallet]);

  useEffect(() => {
    if (defaultSelectedNft) setSelectedMainNfts([defaultSelectedNft]);
  }, [defaultSelectedNft]);

  useEffect(() => {
    setSelectedUnsakingNft(defaultSelectedUnstakingNft);
  }, [defaultSelectedUnstakingNft]);

  const nftsWithStakingAmount = useMemo(() => {
    if (action === IActionType.unstake) return [];
    if (selectedSource === IApeCoinSource.borrow) {
      return selectedMainNfts.map(nft => {
        const cap = CAPS_MAPPED_BY_ADDRESS[nft.address];
        return {
          ...nft,
          borrowingAmount: ethers.utils.parseEther(cap.toString()).sub(nft.apeStaking.stakedAmount ?? 0),
          userStakingAmount: BigNumber.from(0),
          isDisabled: false,
        };
      });
    } else {
      let totalUserBalance = BigNumber.from(0);
      if (userBalance.cyanWalletMax) totalUserBalance = totalUserBalance.add(userBalance.cyanWalletMax);
      if (userBalance.mainWalletMax) totalUserBalance = totalUserBalance.add(userBalance.mainWalletMax);
      return selectedMainNfts.map(nft => {
        const cap = ethers.utils.parseEther(CAPS_MAPPED_BY_ADDRESS[nft.address].toString());
        const possibleStakingAmount = cap.sub(nft.apeStaking.stakedAmount ?? 0);
        let stakingAmount = BigNumber.from(0);
        let borrowingAmount = BigNumber.from(0);
        let isDisabled = false;
        if (totalUserBalance.eq(0)) {
          borrowingAmount = cap;
          isDisabled = true;
        } else if (possibleStakingAmount.lt(totalUserBalance)) {
          stakingAmount = possibleStakingAmount;
          borrowingAmount = cap.sub(possibleStakingAmount);
          totalUserBalance = totalUserBalance.sub(possibleStakingAmount);
        } else {
          stakingAmount = totalUserBalance;
          borrowingAmount = cap.sub(totalUserBalance);
          totalUserBalance = totalUserBalance.sub(totalUserBalance);
        }
        return {
          ...nft,
          userStakingAmount: stakingAmount,
          borrowingAmount,
          isDisabled,
        };
      });
    }
  }, [selectedMainNfts, selectedSource, userBalance, rewardStakeToCyanVault]);

  const stake = () => {
    setError(null);
    if (selectedMainNfts.length == 0) return;
    if (selectedMainNfts.length == 1) {
      initiateBorrowedApeStake({
        rewardStakeToCyanVault,
        action: selectedAction,
        apeCoinSource: selectedSource,
        selectedMainNft: selectedMainNfts[0],
        selectedMainNfts,
        selectedPairingNft,
        cyanWalletAmount: parseFloat(stakingAmountCyan || "0").toString(),
        mainWalletAmount: parseFloat(stakingAmount || "0").toString(),
      });
    } else {
      if (selectedSource === IApeCoinSource.borrow) {
        initiateBorrowedApeBulkStake({
          isLoan: true,
          action,
          apeCoinSource: selectedSource,
          selectedMainNfts: nftsWithStakingAmount,
          rewardStakeToCyanVault,
          removePlan: (_: { address: string; tokenId: string }) => {},
        });
      }
      if (selectedSource === IApeCoinSource.owned) {
        initiateBorrowedApeBulkStake({
          isLoan: false,
          rewardStakeToCyanVault,
          action,
          apeCoinSource: selectedSource,
          selectedMainNfts: nftsWithStakingAmount,
          removePlan: (_: { address: string; tokenId: string }) => {},
        });
      }
    }
  };

  const unstake = async () => {
    if (!selectedUnstakingNft || !provider) return;

    if (selectedUnstakingNft.apeStaking.plan) {
      if (
        !warnings.planComplete &&
        selectedUnstakingNft.apeStaking.plan.rewardStakeToCyanVault &&
        bigNumToFloat(selectedUnstakingNft.apeStaking.earnedAmount || 0) > 0
      ) {
        setWarnings(prev => ({ ...prev, planComplete: true }));
        return;
      }
      initiateBorrowedApeUnstake({
        action: selectedAction,
        apeCoinSource: unstakingApeSource,
        selectedMainNft: selectedUnstakingNft,
        selectedMainNfts: [selectedUnstakingNft],
      });
    }
    if (unstakingApeSource === IApeCoinSource.owned && unstakingAmount) {
      if (
        !warnings.fullAmount &&
        bigNumToFloat(selectedUnstakingNft.apeStaking.stakedAmount || 0) === parseFloat(unstakingAmount)
      ) {
        setWarnings(prev => ({ ...prev, fullAmount: true }));
        return;
      }
      initiateOwnedApeUnstake({
        action: selectedAction,
        apeCoinSource: unstakingApeSource,
        selectedMainNft: selectedUnstakingNft,
        releaseWallet,
        isClaiming: false,
        unstakingAmount: parseFloat(unstakingAmount || "0").toString(),
        selectedMainNfts: [selectedUnstakingNft],
      });
    }
  };

  const claim = () => {
    if (!selectedUnstakingNft) return;
    initiateOwnedApeUnstake({
      action: selectedAction,
      apeCoinSource: selectedSource,
      selectedMainNft: selectedUnstakingNft,
      releaseWallet,
      isClaiming: true,
      unstakingAmount: parseFloat(unstakingAmount || "0").toString(),
      selectedMainNfts,
    });
  };

  const onChangeCollection = (address: string) => {
    setError(null);
    setStakingAmount(undefined);
    setStakingAmountCyan(undefined);
    setSelectedPairingNft(null);
    setUnstakingAmount(undefined);
    setSelectedCollection(address);
    setSelectedMainNfts([]);
  };

  const onChangePairingCollection = (address: string) => {
    setError(null);
    setSelectedPairingNft(null);
    setSelectedPairingCollection(address);
  };

  const onChangeSelectedPairingNft = (nft: ISelectedNft | null) => {
    setError(null);
    setSelectedPairingNft(nft);
  };

  const mainPoolNfts = useMemo(() => {
    const positions = stakablePositions.map(position => ({
      address: position.metadata.collectionAddress,
      tokenId: position.tokenId,
      isCyanWallet: true,
      imageUrl: position.metadata.imageUrl,
      apeStaking: position.apeStaking,
    }));
    return [...stakableAssets, ...positions];
  }, [stakablePositions, stakableAssets]);

  const onChangeSelectedMainNft = (nft: ISelectedNft | null, selectAll?: boolean) => {
    if (selectedCollection.toLowerCase() == BAKCAddress.toLowerCase()) {
      if (!nft) return;
      setSelectedMainNfts([nft]);
      return;
    }
    if (selectAll) {
      setSelectedMainNfts([
        ...mainPoolNfts.filter(nft => nft.address.toLowerCase() == selectedCollection.toLowerCase()),
      ]);
      return;
    } else if (selectAll == false) {
      setSelectedMainNfts([]);
      return;
    }
    if (!nft) return;
    setError(null);
    if (selectedMainNfts.some(items => items.address == nft.address && items.tokenId == nft.tokenId)) {
      setSelectedMainNfts([
        ...selectedMainNfts.filter(items => items.address !== nft.address || items.tokenId != nft.tokenId),
      ]);
    } else {
      setSelectedMainNfts([...selectedMainNfts, nft]);
    }

    setStakingAmount(undefined);
    setStakingAmountCyan(undefined);
  };

  const onChangeSelectedUnstakingNft = (nft: ISelectedNft | null) => {
    setError(null);
    setUnstakingAmount(undefined);
    setSelectedUnsakingNft(nft);
  };

  const onChangeSelectedSource = (source: IApeCoinSource) => {
    setError(null);
    setStakingAmount(undefined);
    setSelectedSource(source);
  };

  const unstakingApeSource = useMemo(() => {
    if (!selectedUnstakingNft) return selectedSource;
    if (
      selectedUnstakingNft.apeStaking.plan &&
      BigNumber.from(selectedUnstakingNft.apeStaking.plan.borrowedApe).gt(0)
    ) {
      return IApeCoinSource.borrow;
    }
    return IApeCoinSource.owned;
  }, [selectedUnstakingNft]);
  return (
    <Flex direction="column" gap="1rem">
      {selectedAction === IActionType.stake && (
        <StakingNftSelector
          {...{
            selectedCollection,
            selectedMainNfts: selectedMainNfts,
            selectedPairingNft,
            onChangeCollection,
            onChangeSelectedMainNft,
            onChangeSelectedPairingNft,
            selectedPairingCollection,
            onChangePairingCollection,
          }}
        />
      )}
      {selectedAction === IActionType.unstake && (
        <UnstakingNftSelector
          {...{
            selectedCollection,
            selectedMainNft: selectedUnstakingNft,
            onChangeCollection,
            onChangeSelectedMainNft: onChangeSelectedUnstakingNft,
          }}
        />
      )}
      {error && <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />}{" "}
      {warnings.fullAmount && (
        <SystemMessage
          variant="warning"
          title={`Unstaking the full amount`}
          msg={`When unstaking the full amount, the accrued reward amount of APE will also be withdrawn.`}
        />
      )}
      {warnings.planComplete && selectedUnstakingNft?.apeStaking.plan?.rewardStakeToCyanVault && (
        <SystemMessage
          variant="warning"
          title={`APE rewards`}
          msg={`The accrued reward amount of APE will be transferred to the Cyan ApeCoin Staking Vault.`}
        />
      )}
      <Card p={"24px 12px"}>
        <Flex direction="column" gap="26px">
          <Flex justifyContent="center">
            <ActionSelector selectedAction={selectedAction} onChange={setSelectedAction} />
          </Flex>
          <Flex direction="column" gap="0.7rem">
            <ApeCoinSourceSelector
              selectedSource={selectedAction === IActionType.stake ? selectedSource : unstakingApeSource}
              onChange={onChangeSelectedSource}
              disabled={selectedAction === IActionType.unstake}
            />
            {selectedAction === IActionType.stake && selectedMainNfts.length <= 1 && (
              <>
                {selectedSource === IApeCoinSource.borrow && (
                  <ApeCoinStakingWithPlan
                    {...{
                      selectedCollection,
                      selectedMainNfts: selectedMainNfts,
                      selectedPairingNft,
                      rewardStakeToCyanVault,
                      setRewardStakeToCyanVault,
                    }}
                  />
                )}
                {selectedSource === IApeCoinSource.owned && (
                  <ApeCoinStakingWithoutPlan
                    {...{
                      selectedCollection,
                      selectedMainNfts,
                      setRewardStakeToCyanVault,
                      rewardStakeToCyanVault,
                      userBalance,
                      cyanWalletStakingAmount: stakingAmountCyan,
                      stakingAmount,
                      onMainInputChange: setStakingAmount,
                      onCyanInputChange: setStakingAmountCyan,
                    }}
                  />
                )}
              </>
            )}
            {selectedAction === IActionType.stake && selectedMainNfts.length > 1 && (
              <BulkStaking
                rewardStakeToCyanVault={rewardStakeToCyanVault}
                setRewardStakeToCyanVault={setRewardStakeToCyanVault}
                nfts={nftsWithStakingAmount}
                source={selectedSource}
              />
            )}
            {selectedAction === IActionType.unstake && (
              <>
                {selectedUnstakingNft &&
                selectedSource === IApeCoinSource.owned &&
                !selectedUnstakingNft.apeStaking.plan ? (
                  <ApeCoinUnstakingWithoutPlan
                    {...{
                      selectedMainNft: selectedUnstakingNft,
                      setReleaseWallet,
                      releaseWallet,
                      unstakingAmount,
                      setUnstakingAmount,
                    }}
                  />
                ) : (
                  <ApeCoinUnstakingWithPlan
                    selectedCollection={selectedCollection}
                    selectedMainNft={selectedUnstakingNft}
                  />
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Card>
      {selectedAction === IActionType.stake && selectedSource === IApeCoinSource.borrow && (
        <StyledButton disabled={!selectedMainNfts.length} onClick={stake}>{`Stake APE`}</StyledButton>
      )}
      {selectedAction === IActionType.stake && selectedSource === IApeCoinSource.owned && (
        <StyledButton
          disabled={
            IsInTestDrive ||
            !selectedMainNfts.length ||
            (selectedMainNfts.length == 1 && !stakingAmount && !stakingAmountCyan)
          }
          onClick={stake}
        >{`Stake APE`}</StyledButton>
      )}
      {selectedAction === IActionType.unstake &&
        (unstakingApeSource === IApeCoinSource.borrow ||
          (unstakingApeSource === IApeCoinSource.owned && selectedUnstakingNft?.apeStaking.plan)) && (
          <StyledButton disabled={!selectedUnstakingNft} onClick={unstake}>{`Unstake APE`}</StyledButton>
        )}
      {selectedAction === IActionType.unstake &&
        unstakingApeSource === IApeCoinSource.owned &&
        !selectedUnstakingNft?.apeStaking.plan && (
          <>
            <StyledButton
              disabled={IsInTestDrive || !selectedUnstakingNft || !unstakingAmount || parseFloat(unstakingAmount) <= 0}
              onClick={unstake}
            >{`Unstake APE`}</StyledButton>
            {selectedUnstakingNft && (
              <StyledButton
                disabled={
                  !selectedUnstakingNft || bigNumToFloat(selectedUnstakingNft.apeStaking.earnedAmount || 0) <= 0
                }
                onClick={claim}
              >{`Claim ${numberWithCommas(
                bigNumToFloat(selectedUnstakingNft.apeStaking.earnedAmount || 0),
                2,
              )} APE`}</StyledButton>
            )}
          </>
        )}
    </Flex>
  );
};

const BulkStaking = ({
  nfts,
  source,
  rewardStakeToCyanVault,
  setRewardStakeToCyanVault,
}: {
  nfts: Array<ISelectedNft & { userStakingAmount: BigNumber; isDisabled?: boolean; borrowingAmount: BigNumber }>;
  source: IApeCoinSource;
  rewardStakeToCyanVault: boolean;
  setRewardStakeToCyanVault: (value: boolean) => void;
}) => {
  const theme = useTheme();
  return (
    <>
      <BulkStakingBreakdown nfts={nfts} source={source} hasLoan={true} />
      <Flex justifyContent="space-between" p="0 5px">
        <Flex alignItems="center" gap="4px">
          <Text size="md" weight="600" color="secondary">
            {`Reward APE to Cyan Vault`}
          </Text>
          <Tooltip>
            <HelpCircle height={16} width={16} color={theme.colors.secondary} />
            <TooltipText showArrow position="top" top="-155px" right="-70px" style={{ width: "150px" }}>
              <Flex direction="column" gap="7px">
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  <div>{`Rewarded APE will be staked into the Cyan ApeCoin Staking Vault. If you wish to retain the voting rights for the APE you earn, please turn this option off.`}</div>
                </Text>
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  <div>{`By turning this option off, your rewarded APE will be staked into the Horizen Labs contract and you will retain voting rights.`}</div>
                </Text>
              </Flex>
            </TooltipText>
          </Tooltip>
        </Flex>
        <Toggler value={rewardStakeToCyanVault} onChange={setRewardStakeToCyanVault} size="sm" />
      </Flex>
    </>
  );
};
