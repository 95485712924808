import RcSlider, { SliderProps } from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
import styled, { createGlobalStyle, useTheme } from "styled-components";

const StyledSlider = styled(RcSlider)`
  width: 100%;
  .rc-slider-dot-active {
    background-color: ${({ theme }) => theme.colors.cyan} !important;
  }
`;

export const SliderInput: React.FC<Omit<SliderProps, "marks">> = ({ ...props }) => {
  const theme = useTheme();

  const GlobalStyle = createGlobalStyle`
  .rc-slider-disabled {
    background-color: transparent !important;
    background: none !important;
  }
`;
  return (
    <>
      <GlobalStyle />
      <StyledSlider
        trackStyle={{
          background: "#00FFFF80",
          height: "7px",
        }}
        railStyle={{
          background: theme.colors.gray20,
          height: "7px",
        }}
        dotStyle={{
          borderRadius: "6px",
          outline: "none",
          border: "none",
          height: "15px",
          width: "6px",
          bottom: "-7px",
          background: theme.colors.gray30,
          display: "none",
        }}
        handleStyle={{
          outline: "none",
          border: "none",
          opacity: 1,
          cursor: "pointer",
          width: "10px",
          height: "20px",
          borderRadius: "3px",
          background: "#0ff",
          boxShadow: "-4px 4px 4px 1px rgba(0, 0, 0, 0.25)",
          bottom: "-4px",
        }}
        {...props}
      />
    </>
  );
};
