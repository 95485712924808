import { BigNumber, constants, utils } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { useEffect, useState } from "react";

import { Box, Button, Flex, Stepper, Text, useModal } from "@cyanco/components/theme";
import { NoImage } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { returnVaultFunds } from "@/apis/vault/admin";
import { IVaultLiquidatedNft } from "@/apis/vault/admin/types";
import { IVault } from "@/apis/vault/types";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { NftImageWrapper, StyledNftImage } from "@/components/PlanCreation/ItemsMetadata";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedCurrenciesByChain } from "@/config";
import { useApproval } from "@/hooks/useApproval";
import { bigNumToFloat, getChainExplorerURL } from "@/utils";

import { getVaultAdminSignatureExpiry, signRefundVault } from "../utils";
import { VaultRefundBulkModal } from "./VaultRefundBulkModal";
import { VaultRefundModal, VaultRefundNftMetadata } from "./VaultRefundModal";

export const VaultRefundProgressModal = ({
  nfts,
  vault,
}: {
  nfts: Array<IVaultLiquidatedNft & { refundValue: number }>;
  vault: IVault;
}) => {
  const { signer } = useWeb3React();
  const { giveErc20Approval } = useApproval(true);
  const { signInWallet } = useAuthContext();
  const { transactions, addTransaction, setVaultLiquidatedNfts } = useTransactionContext();
  const { setModalContent, unsetModal } = useModal();
  const [selectedStep, setSelectedStep] = useState<PlanLiquidateSteps>(PlanLiquidateSteps.Approve);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [activeTx, setActiveTx] = useState<string | null>(null);

  const totalRefundAmountParsed = nfts.reduce((acc, cur) => {
    return acc.add(parseUnits(cur.refundValue.toString(), vault.decimals));
  }, BigNumber.from(0));
  const vaultSupportedCurrency = SupportedCurrenciesByChain[vault.chainId].find(
    item => item.symbol.toLowerCase() === vault.currency.toLowerCase(),
  );

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setVaultLiquidatedNfts(prev => [
          ...prev,
          ...nfts.map(item => ({
            ...item,
            soldPrice: utils.parseUnits(item.refundValue.toString(), vault.decimals).toString(),
          })),
        ]);
        setSelectedStep(PlanLiquidateSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const liquidate = async () => {
    if (!signer) return;
    const { token } = await signInWallet();
    const expiryDate = getVaultAdminSignatureExpiry();
    const signature = await signRefundVault(
      nfts.map(nft => ({
        id: nft.id,
        refundValue: nft.refundValue,
      })),
      signer,
      expiryDate,
    );
    const totalDefaultedNftAmount = await returnVaultFunds({
      signature,
      liquidatedNfts: nfts.map(nft => ({
        id: nft.id,
        soldPrice: nft.refundValue,
      })),
      token,
      expiryDate,
    });
    const cyanVaultContract = f.CyanVaultV2Factory.connect(vault.contractAddress, signer);
    const tx = await cyanVaultContract.liquidateNFT(totalRefundAmountParsed, totalDefaultedNftAmount, {
      value:
        (vaultSupportedCurrency?.address ?? "").toLowerCase() === constants.AddressZero.toLowerCase()
          ? totalRefundAmountParsed
          : "0",
    });
    await returnVaultFunds({
      signature,
      liquidatedNfts: nfts.map(nft => ({
        id: nft.id,
        soldPrice: nft.refundValue,
        txnHash: tx.hash,
      })),
      token,
      expiryDate,
    });
    addTransaction({
      hash: tx.hash,
      type: "vault-liquidate",
      data: {
        ids: nfts.map(nft => nft.id),
      },
    });
    setActiveTx(tx.hash);
  };

  useEffect(() => {
    const onStepChange = async (step: PlanLiquidateSteps) => {
      if (!signer || !vaultSupportedCurrency) return;
      try {
        switch (step) {
          case PlanLiquidateSteps.Approve: {
            if (vaultSupportedCurrency.address.toLowerCase() !== constants.AddressZero.toLowerCase()) {
              await giveErc20Approval(
                {
                  amount: totalRefundAmountParsed,
                  currencyAddress: vaultSupportedCurrency.address,
                },
                vault.contractAddress,
              );
            }
            setSelectedStep(PlanLiquidateSteps.Liquidate);
            return;
          }
          case PlanLiquidateSteps.Liquidate: {
            await liquidate();
            return;
          }
        }
      } catch (err: any) {
        let errorMessage = err.error?.message ?? `Please try again later`;
        if (err?.response?.data?.message) {
          errorMessage = err.response.data.message;
        }
        if (nfts.length === 1) {
          setModalContent({
            title: "Return Fund",
            content: (
              <VaultRefundModal
                nft={nfts[0]}
                vault={vault}
                error={{
                  title: `Something went wrong!`,
                  msg: errorMessage,
                }}
              />
            ),
          });
        } else {
          setModalContent({
            title: "Return Funds",
            content: (
              <VaultRefundBulkModal
                nfts={nfts}
                vault={vault}
                error={{
                  title: `Something went wrong!`,
                  msg: errorMessage,
                }}
              />
            ),
          });
        }
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);
  return (
    <Flex gap="12px" direction="column">
      {nfts.length === 1 ? (
        <VaultRefundNftMetadata
          nft={nfts[0]}
          vault={vault}
          refundAmount={bigNumToFloat(totalRefundAmountParsed, vault.decimals)}
        />
      ) : (
        <NftImageWrapper style={{ paddingTop: "0px" }}>
          {nfts.map(item => (
            <StyledNftImage src={item.imageUrl || NoImage} hasImage={!!item.imageUrl} key={item.id} />
          ))}
        </NftImageWrapper>
      )}
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={PlanLiquidateStepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(vault.chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === PlanLiquidateSteps.Done && (
        <Button onClick={unsetModal} style={{ height: "50px" }}>
          <Text color="black" size="sm" weight="700">
            Close
          </Text>
        </Button>
      )}
    </Flex>
  );
};

enum PlanLiquidateSteps {
  Approve = 1,
  Liquidate = 2,
  Done = 3,
}

const PlanLiquidateStepMarks = [
  {
    value: PlanLiquidateSteps.Approve,
    title: `Set approval`,
    description: `A small amount of gas is required to approve`,
  },
  {
    value: PlanLiquidateSteps.Liquidate,
    title: `Return vault fund`,
    description: `View on Etherscan`,
  },
];
