import { BigNumber } from "ethers";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { NftMetadataInline, NftMetadataInlineImage } from "@cyanco/components/theme/v3";
import { CloseX } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { DATE_TIME_FORMAT_OPTIONS_DAYJS } from "@/config";
import { useGetUserTime } from "@/hooks";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, numberWithCommas, shortenName } from "@/utils";

import { PlanCreationDecimalFormatMap } from "../PlanCreation/types";
import { TransactionDetail } from "./PlanStatuses";

export const PlanMetadata: React.FC<{
  plans: {
    totalAmount: BigNumber;
    leftAmount: BigNumber;
    collectionName: string;
    tokenId: string;
    currency: ICurrency;
    imageUrl?: string;
    address: string;
    purchasedPrice?: string;
    isBnpl: boolean;
  }[];
  removePlan?: (plan: { address: string; tokenId: string }) => void;
}> = ({ plans, removePlan }) => {
  const theme = useTheme();
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(plans[0].currency.decimal) || 4;
  }, [plans[0].currency.decimal]);
  if (plans.length === 1) {
    const plan = plans[0];
    return (
      <Flex gap="10px" pt="5px">
        <NftMetadataInlineImage imageUrl={plan.imageUrl} />
        <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 15px 0">
          <NftMetadataInline
            name={shortenName(plan.collectionName, 21, 20, 0)}
            value={`#${shortenName(plan.tokenId, 10, 5)}`}
          />
          <Flex gap="5px" direction="column" w="100%">
            {plan.isBnpl && plan.purchasedPrice && (
              <NftMetadataInline
                name={`Purchase price`}
                value={`${numberWithCommas(
                  bigNumToFixedStr(plan.purchasedPrice, formatNumber, plan.currency.decimal),
                  formatNumber,
                )} ${plan.currency.symbol}`}
                sub
              />
            )}
            {!plan.isBnpl && (
              <NftMetadataInline
                name={`Total Loan`}
                value={`${numberWithCommas(
                  bigNumToFixedStr(plan.totalAmount, formatNumber, plan.currency.decimal),
                  formatNumber,
                )} ${plan.currency.symbol}`}
                sub
              />
            )}
            <NftMetadataInline
              name={`Loan Left`}
              value={`${numberWithCommas(
                bigNumToFixedStr(plan.leftAmount, formatNumber, plan.currency.decimal),
                formatNumber,
              )} ${plan.currency.symbol}`}
              sub
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
  return (
    <NftImageWrapper>
      {plans.map(item => (
        <ImageWrapper key={`${item.collectionName}:${item.tokenId}`}>
          <StyledNftImage src={item.imageUrl || NoImage} key={item.tokenId} hasImage={!!item.imageUrl} />
          {removePlan && (
            <RemoveFromCart
              onClick={() => {
                removePlan({
                  address: item.address,
                  tokenId: item.tokenId,
                });
              }}
            >
              <CloseX color={theme.colors.primary} height={8} width={8} />
            </RemoveFromCart>
          )}
        </ImageWrapper>
      ))}
    </NftImageWrapper>
  );
};

export const DefaultedPlanMetadata: React.FC<{
  plan: {
    totalNumOfPaymentsLeft: number;
    totalNumOfPayments: number;
    defaultedAt: Date;
  };
}> = ({ plan }) => {
  const { getUserPreferredTime } = useGetUserTime();
  return (
    <Flex w="100%" direction="column">
      <TransactionDetail
        label={`Number of Payments Left`}
        value={`${plan.totalNumOfPaymentsLeft}`}
        info={`(out of ${plan.totalNumOfPayments})`}
      />
      <TransactionDetail
        label={`Payment defaulted on`}
        value={`${getUserPreferredTime(plan.defaultedAt).format(DATE_TIME_FORMAT_OPTIONS_DAYJS)}`}
      />
    </Flex>
  );
};

const StyledNftImage = styled.img<{ hasImage?: boolean; hasError?: boolean }>`
  height: 64px;
  width: 64px;
  object-fit: scale-down;
  border-radius: 15px;
  padding: 0;
  margin: 0;
  background-color: black;
  filter: ${({ hasImage, theme }) =>
    !hasImage &&
    theme.theme === "light" &&
    "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;

const NftImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(64px, 64px));
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  max-height: 180px;
  overflow: auto;
  padding-top: 5px;
`;
const RemoveFromCart = styled.div`
  cursor: pointer;
  display: none;
  position: absolute;
  top: -5px;
  right: 0px;
  padding: 4px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const ImageWrapper = styled.div`
  position: relative;
  &:hover ${RemoveFromCart} {
    display: flex;
  }
`;
