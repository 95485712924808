import { parseUnits } from "ethers/lib/utils";
import orderBy from "lodash.orderby";
import { useEffect, useMemo, useState } from "react";

import { Flex, Text } from "@cyanco/components/theme";

import { updateCollectionsAppraisalSettings } from "@/apis/vault/admin";
import { IVaultSupportedProjectWithAppraisal } from "@/apis/vault/admin/types";
import { IVault } from "@/apis/vault/types";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { bigNumToFloat } from "@/utils";

import { SaveSettingsButton } from "../PoolManagement";
import { getVaultAdminSignatureExpiry, signCollectionsAppraisalSettings } from "../utils";
import {
  CollectionAppraisalsRow,
  CollectionAppraisalsRowLoader,
  CollectionsAppraisalsHeader,
} from "./CollectionsAppraisalsTable";

export const AppraisalSettings = ({
  vault,
  loading,
  supportedProjects,
  setSupportedProjects,
}: {
  vault: IVault;
  loading: boolean;
  supportedProjects: IVaultSupportedProjectWithAppraisal[];
  setSupportedProjects: (projects: IVaultSupportedProjectWithAppraisal[]) => void;
}) => {
  const { signInWallet } = useAuthContext();
  const { signer, account } = useWeb3React();
  const [collectionWithAppraisal, setCollectionWithAppraisal] = useState<
    {
      name: string;
      imageUrl: string;
      address: string;
      appraisalValue: number;
      customAppraisalValue: number;
    }[]
  >([]);

  useEffect(() => {
    setCollectionWithAppraisal(
      orderBy(supportedProjects, [item => item.name.toLowerCase()]).map(collection => ({
        name: collection.name,
        imageUrl: collection.imageUrl,
        address: collection.address,
        customAppraisalValue: bigNumToFloat(collection?.customAppraisalValue ?? 0, vault.decimals),
        appraisalValue: bigNumToFloat(collection.appraisalValue, vault.decimals),
      })),
    );
  }, [supportedProjects]);

  const updatingCollections = useMemo(() => {
    return collectionWithAppraisal.filter(collection => {
      const supportedProject = supportedProjects.find(
        supportedProject => supportedProject.address === collection.address,
      );
      const customAppraisalValue = parseUnits(collection.customAppraisalValue.toString(), vault.decimals);
      return !customAppraisalValue.eq(supportedProject?.customAppraisalValue ?? 0);
    });
  }, [collectionWithAppraisal, supportedProjects]);

  const onChangeCustomAppraisalValue = (collectionAddress: string, value: number) => {
    const data = [...collectionWithAppraisal];
    const index = data.findIndex(item => item.address === collectionAddress);
    if (index !== -1) {
      data[index].customAppraisalValue = value;
      setCollectionWithAppraisal(data);
    }
  };
  const updateAppraisalSettings = async () => {
    if (!signer || !account) return;
    const { token } = await signInWallet();
    const expiryDate = getVaultAdminSignatureExpiry();
    const signature = await signCollectionsAppraisalSettings(
      updatingCollections.map(({ address, customAppraisalValue }) => ({
        address,
        customAppraisalValue: parseUnits(customAppraisalValue.toString(), vault.decimals),
      })),
      vault.contractAddress,
      expiryDate,
      signer,
    );
    await updateCollectionsAppraisalSettings({
      vaultAddress: vault.contractAddress,
      token,
      projects: updatingCollections.map(({ address, customAppraisalValue }) => ({
        address,
        customAppraisalValue: parseUnits(customAppraisalValue.toString(), vault.decimals).toString(),
      })),
      signature,
      expiryDate,
    });
    const updatedSupportedProjects = supportedProjects.map(supportedProject => {
      const updatedProject = updatingCollections.find(
        updatingCollection => updatingCollection.address === supportedProject.address,
      );
      if (updatedProject) {
        return {
          ...supportedProject,
          customAppraisalValue: parseUnits(updatedProject.customAppraisalValue.toString(), vault.decimals).toString(),
        };
      }
      return supportedProject;
    });
    setSupportedProjects(updatedSupportedProjects);
  };
  return (
    <Flex direction="column" gap="2.5rem" p="3rem 2rem 2rem 2rem">
      <Flex direction="column" gap="5px">
        <Text color="secondary" style={{ fontSize: "33px" }} weight="600">
          Appraisal price override
        </Text>
        <Text color="gray0" size="lg">
          Override appraisals which drives valuations across Cyan
        </Text>
      </Flex>

      <Flex direction="column" gap="0.5rem">
        <CollectionsAppraisalsHeader />
        <Flex
          direction="column"
          gap="0.5rem"
          style={{
            maxHeight: "500px",
            overflowY: "scroll",
          }}
        >
          {loading
            ? Array.from({ length: 5 }).map((_, index) => (
                <CollectionAppraisalsRowLoader key={index} vaultCurrencySymbol={vault.currency} />
              ))
            : collectionWithAppraisal.map((collection, index) => (
                <CollectionAppraisalsRow
                  key={index}
                  collection={collection}
                  vaultCurrencySymbol={vault.currency}
                  onChangeCustomAppraisalValue={onChangeCustomAppraisalValue}
                />
              ))}
        </Flex>
      </Flex>
      <SaveSettingsButton onClick={updateAppraisalSettings} disabled={updatingCollections.length === 0} />
    </Flex>
  );
};
