import { IVault } from "@/apis/vault/types";
import { apeVaultContract } from "@/config";

import { useVaults } from "../../VaultDataProvider";
import { LatestLoans } from "./LatestLoans/LatestLoans";
import { SuppyAPYChart } from "./SuppyAPYChart";
import { UtilizationRateChart } from "./UtilizationRateChart";
import { VaultCollections } from "./VaultCollections";
import { VaultCharts } from "./charts";

export const VaultPublicDetailsSection: React.FC<{
  vault: IVault;
}> = ({ vault }) => {
  const { vaultAdditionalMetrics, fetchingVaultAdditionalMetrics } = useVaults();
  const isApeVault = vault.contractAddress.toLowerCase() === apeVaultContract;

  return (
    <>
      {!isApeVault && (
        <LatestLoans
          loans={vaultAdditionalMetrics.latestLoans}
          vault={vault}
          loading={fetchingVaultAdditionalMetrics}
        />
      )}
      <UtilizationRateChart history={vault.history} />
      <SuppyAPYChart history={vault.history} />
      {!isApeVault && (
        <VaultCharts
          vault={vault}
          vaultAdditionalMetrics={vaultAdditionalMetrics}
          loading={fetchingVaultAdditionalMetrics}
        />
      )}
      {vault.supportedProjects.length > 0 && <VaultCollections vault={vault} />}
    </>
  );
};
