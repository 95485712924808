import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { Clock } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Button, Flex, Hidden, Input, SliderInput, Text, Tooltip, TooltipText } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { ICollectionBe } from "@/apis/collection/types";
import { MAX_BNPL_LIMIT } from "@/config";
import { usePlanCreation } from "@/hooks";
import { INftBe } from "@/types";
import { bigNumToFixedStr, bigNumToFloat } from "@/utils";

import {
  FLOOR_PRICE_THRESHOLD,
  FLOOR_PRICE_THRESHOLD_MAX_COUNT,
  SweepStatus,
  useBnplCart,
} from "../../BnplCartContext";

export const CartItemsMultiSelection = ({
  assets,
  selectedCollection,
}: {
  assets: INftBe[];
  selectedCollection?: ICollectionBe;
}) => {
  const theme = useTheme();
  const { items, removeAll, sweep, setSweep, addItems, getCollectionFloorPrice, itemsCountGtThreshold } = useBnplCart();
  const [showMenu, setShowMenu] = useState(false);
  const [sweepCount, setSweepCount] = useState(0);
  const [sweepAmount, setSweepAmount] = useState(BigNumber.from(0));
  const { showNewPlanModal } = usePlanCreation("bnpl");

  const maxSweepCount = useMemo(() => {
    if (!selectedCollection) return 0;
    const priceInEth = getCollectionFloorPrice(selectedCollection.address);
    if (priceInEth.gt(FLOOR_PRICE_THRESHOLD)) {
      return FLOOR_PRICE_THRESHOLD_MAX_COUNT;
    } else {
      return MAX_BNPL_LIMIT;
    }
  }, [selectedCollection]);

  const toggleSweep = () => {
    setSweep(SweepStatus.EnabledAutomatically);
    addItems(assets.slice(0, maxSweepCount) ?? []);
  };

  useEffect(() => {
    if (sweep == SweepStatus.EnabledManually) {
      toggleSweep();
    }
    if (sweep == SweepStatus.DisabledManually) {
      removeAll();
    }
  }, [sweep]);

  useEffect(() => {
    if (items.length == 0) {
      setSweep(SweepStatus.Disabled);
      return;
    }
    const isCheapest = items
      .sort((a, b) =>
        a.price.lt(b.price) ? -1 : a.price.eq(b.price) ? (BigNumber.from(a.tokenId).lt(b.tokenId) ? -1 : 1) : 1,
      )
      .every((a, index) => {
        return assets.length > index && a.tokenId == assets[index].tokenId;
      });
    if (isCheapest) {
      setSweepCount(items.length);
      setSweepAmount(items.reduce((acc, item) => acc.add(item.price), BigNumber.from(0)));
    }
    setSweep(isCheapest ? SweepStatus.EnabledAutomatically : SweepStatus.Disabled);
  }, [items, assets]);

  useEffect(() => {
    addItems(assets.slice(0, sweepCount) ?? []);
  }, [sweepCount]);

  const openPlanModal = () => {
    showNewPlanModal({ currency: items[0].currency, items });
    return;
  };

  const isBatchPossible =
    items.length > 0 && items.length <= MAX_BNPL_LIMIT && itemsCountGtThreshold <= FLOOR_PRICE_THRESHOLD_MAX_COUNT;

  return (
    <>
      <Hidden laptopSDown>
        <MultiSelectionBox show={items.length !== 0}>
          <Flex alignItems="center" gap="1rem" style={{ flexGrow: 1 }}>
            <SelectBoxTextInsideButton size="lg" color="secondary" style={{ width: "78px" }}>{`${items.length} item${
              items.length > 1 ? "s" : ""
            }`}</SelectBoxTextInsideButton>
            <SelectBoxTextButton size="lg" onClick={toggleSweep}>
              Sweep
            </SelectBoxTextButton>
            {sweep != SweepStatus.Disabled && items.length <= maxSweepCount && (
              <Flex alignItems="center" gap="1rem" style={{ flexGrow: 1, minWidth: "600px" }}>
                <Flex style={{ flexGrow: 1 }}>
                  <SliderInput
                    min={1}
                    max={maxSweepCount}
                    value={sweepCount}
                    onChange={e => setSweepCount(e as number)}
                    defaultValue={maxSweepCount}
                  />
                </Flex>
                <Input style={{ maxWidth: "44px" }} value={sweepCount} disabled />
                <Flex justifyContent="center" style={{ minWidth: "90px" }}>
                  <Text color="secondary" style={{ whiteSpace: "nowrap" }}>
                    {bigNumToFixedStr(sweepAmount, 3, items.length > 0 ? items[0].currency.decimal : 18)}{" "}
                    {items.length > 0 ? items[0].currency.symbol : ""}
                  </Text>
                </Flex>
              </Flex>
            )}

            <Text size="lg" color="gray0">
              |
            </Text>
            <SelectBoxTextButton size="lg" color="cyan" onClick={removeAll}>
              Clear
            </SelectBoxTextButton>
          </Flex>
          <Flex gap="1rem">
            <Tooltip>
              <SelectBoxStyledButton disabled={!isBatchPossible} onClick={openPlanModal}>
                <Flex gap="7px" alignItems="center">
                  <Clock color={theme.colors.black} size={20} />
                  <SelectBoxTextInsideButton color="black" size="lg" weight="600" textWrap={false}>
                    {`Get ${items.length} instant loan${items.length > 1 ? "s" : ""}`}
                  </SelectBoxTextInsideButton>
                </Flex>
              </SelectBoxStyledButton>
              <TooltipText showArrow position="top" top="-82px" right="25px" style={{ minWidth: "160px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="xs" color="primary" weight="500" lineHeight={14}>
                    <div>
                      {`Purchase up to ${MAX_BNPL_LIMIT}  NFTs at once if the floor price is under ${bigNumToFloat(
                        FLOOR_PRICE_THRESHOLD,
                      ).toFixed(1)} ETH, otherwise the limit is ${FLOOR_PRICE_THRESHOLD_MAX_COUNT}. `}
                    </div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
          </Flex>
        </MultiSelectionBox>
      </Hidden>
      <Hidden laptopSUp>
        <MultiSelectionBox show={items.length !== 0}>
          <SelectionBoxMobileMenuWrapper>
            <SelectBoxMoreOptions
              style={{ padding: "6px 12px", background: showMenu ? "#2C2C2C" : "#6C6C6C" }}
              onClick={() => setShowMenu(!showMenu)}
            >
              <Text color="white" size="xxs" weight="600" textWrap={false}>
                {`More options`}
              </Text>
            </SelectBoxMoreOptions>
            <SelectBoxMobileMenu direction="column" show={showMenu}>
              <Flex
                direction="column"
                gap="10px"
                p="14px 22px"
                style={{
                  borderTop: "1px solid #2C2C2C ",
                }}
              >
                <Text size="xs" color="secondary" weight="600" textWrap={false} onClick={removeAll}>{`Clean`}</Text>
                <Text size="xs" color="secondary" weight="600" textWrap={false} onClick={toggleSweep}>{`Sweep`}</Text>
              </Flex>
            </SelectBoxMobileMenu>
          </SelectionBoxMobileMenuWrapper>
          <Tooltip>
            <Button disabled={!isBatchPossible} onClick={openPlanModal} style={{ padding: "6px 12px" }}>
              <Flex gap="7px" alignItems="center">
                <Clock color={theme.colors.black} size={10} />
                <Text color="black" size="xxs" weight="600" textWrap={false}>
                  {`Get ${items.length} instant loan${items.length > 1 ? "s" : ""}`}
                </Text>
              </Flex>
            </Button>
            <TooltipText showArrow position="top" top="-85px" right="25px" style={{ width: "180px" }}>
              <Flex direction="column" gap="7px">
                <Text size="xs" color="primary" weight="500" lineHeight={14}>
                  <div>
                    {`Purchase up to ${MAX_BNPL_LIMIT}  NFTs at once if the floor price is under ${bigNumToFloat(
                      FLOOR_PRICE_THRESHOLD,
                    ).toFixed(1)} ETH, otherwise the limit is ${FLOOR_PRICE_THRESHOLD_MAX_COUNT}. `}
                  </div>
                </Text>
              </Flex>
            </TooltipText>
          </Tooltip>
        </MultiSelectionBox>
      </Hidden>
    </>
  );
};

export const MultiSelectionBox = styled.div<{ show: boolean }>`
  z-index: 80;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
  background: ${({ theme }) => theme.backgroundColor};
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100vw - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  gap: 2rem;
  @media only screen and (max-width: ${breakpoints.laptopS}px) {
    position: fixed;
    width: calc(100% - 1rem);
    padding: 4px 0.5rem;
    height: ${({ show }) => (show ? "47px" : "0px")};
    bottom: ${({ show }) => (show ? "0px" : "-30px")};
  }
  transition: 0.5s;
  height: ${({ show }) => (show ? "100px" : "0px")};
  bottom: ${({ show }) => (show ? "0px" : "-30px")};
`;

export const SelectBoxTextButton = styled(Text)`
  transition: 0.2s;
  cursor: pointer;
  font-size: 18px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptopL]: 14 }])};
  color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.cyan : theme.colors.gray90)};
  :hover {
    color: ${({ theme }) => (theme.theme == "dark" ? "#79ffff" : "black")};
  }
`;

export const SelectBoxTextInsideButton = styled(Text)`
  font-size: 18px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptopL]: 14 }])};
`;

export const SelectBoxStyledButton = styled(Button)`
  width: 230px;
  height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  ${getStyleWithMediaQuery("padding-left", "px", [{ [breakpoints.laptopL]: 10 }])};
  ${getStyleWithMediaQuery("padding-right", "px", [{ [breakpoints.laptopL]: 10 }])};
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.laptopL]: 180 }])};
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.laptopL]: 40 }])};
`;

export const SelectionBoxMobileMenuWrapper = styled.div`
  position: relative;
`;
export const SelectBoxMoreOptions = styled(Button)`
  border-color: ${({ theme }) => theme.colors.gray0};
  background: ${({ theme }) => theme.colors.gray0};
  :hover {
    border-color: ${({ theme }) => theme.colors.gray0};
    background: ${({ theme }) => theme.colors.gray0};
  }
`;
export const SelectBoxMobileMenu = styled(Flex)<{ show: boolean }>`
  z-index: 10 !important;
  position: absolute;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  background: ${({ theme }) => theme.colors.primary};
  bottom: calc(100% + 15px);
  gap: 10px;
  display: ${({ show }) => (show ? "flex" : "none")};
`;
