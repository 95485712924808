import { DefaultTheme, css } from "styled-components";

interface TemplateStringsArray extends ReadonlyArray<string> {
  readonly raw: readonly string[];
}

export const breakpoints = {
  mobile: 425,
  tablet: 768,
  tabletL: 975,
  laptopS: 1050,
  laptop: 1150,
  laptopM: 1240,
  laptopL: 1440,
  desktop: 2560,
};

export const getStyleWithMediaQuery = (
  cssProp: Partial<CSSStyleDeclaration>,
  cssPropUnits: string,
  values: Array<{ [k: number]: string | number }>,
  mediaQueryType: "max-width" | "min-width" | undefined = "max-width",
) => {
  const breakpointProps = values.reduce<string>((mediaQueries, value) => {
    const [screenBreakpoint, cssPropBreakpoint] = [Object.keys(value)[0], Object.values(value)[0]];
    return (mediaQueries += `
    @media screen and (${mediaQueryType}: ${screenBreakpoint}px) {
      ${cssProp}: ${cssPropBreakpoint}${cssPropUnits};
    }
    `);
  }, "");
  return css([breakpointProps] as unknown as TemplateStringsArray);
};

const palette = {
  black: "#000",
  white: "#fff",
  cyan: "cyan",
  primaryDark: "#1c1c1c",
  secondaryDark: "#252525",
};

const mainTheme = {
  borderRadius: "10px",
  borderWidth: "1px",
};

const darkTheme: DefaultTheme = {
  ...mainTheme,
  theme: "dark",
  colors: {
    primary: "#000000",
    secondary: "#FFFFFF",
    gray90: "#9C9C9C",
    gray80: "#808080",
    gray30: "#3c3c3c",
    gray20: "#2c2c2c",
    gray10: "#1c1c1c",
    gray0: "#6c6c6c",
    cyan: "#00FFFF",
    purple: "#5200FF",
    green: "#00FFA3",
    red: "#FF2020",
    blue: "#024AD7",
    transparent: "transparent",
    black: "black",
    white: "white",
    yellow: "#E49B2D",
    logo: "cyan",
  },
  backgroundColor: "#020D15",
  card: {
    colors: {
      primary: palette.primaryDark,
      secondary: palette.secondaryDark,
    },
  },
};

const lightTheme: DefaultTheme = {
  ...mainTheme,
  theme: "light",
  colors: {
    primary: "#FFFFFF",
    secondary: "#000000",
    gray90: "#9C9C9C",
    gray80: "#808080",
    gray30: "#5c5c5c",
    gray20: "#9c9c9c",
    gray10: "#EEEEEE",
    gray0: "#6c6c6c",
    cyan: "#00FFFF",
    purple: "#5200FF",
    green: "#00AC6E",
    red: "#FF2020",
    blue: "#024AD7",
    transparent: "transparent",
    black: "black",
    white: "white",
    yellow: "#E49B2D",
    logo: "black",
  },
  backgroundColor: palette.white,
  card: {
    colors: {
      primary: palette.primaryDark,
      secondary: palette.secondaryDark,
    },
  },
};

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};

interface IColors {
  primary: string;
  secondary: string;
  gray90: string;
  gray80: string;
  gray30: string;
  gray20: string;
  gray10: string;
  gray0: string;
  cyan: string;
  purple: string;
  green: string;
  red: string;
  blue: string;
  transparent: string;
  black: string;
  white: string;
  yellow: string;
  logo: string;
}
export interface ICyanTheme {
  theme: "light" | "dark";
  borderRadius: string;
  borderWidth: string;
  colors: IColors;
  backgroundColor: string;
  card: {
    colors: {
      primary: string;
      secondary: string;
    };
  };
}
