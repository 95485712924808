import dayjs from "dayjs";
import { utils } from "ethers";
import { useEffect, useState } from "react";

import { useCyanWallet } from "@usecyan/cyan-wallet";
import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Box, Flex, Stepper, useModal } from "@cyanco/components/theme";
import { factories as f } from "@cyanco/contract";

import { ICollectionBe } from "@/apis/collection/types";
import { createP2POffer } from "@/apis/p2p";
import { IP2PListedNft } from "@/apis/p2p/types";
import { useCreatedLoanBids } from "@/components/Account/AccountDataContext";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPeerPaymentPlanFromChainId } from "@/constants/contracts";
import { useApproval } from "@/hooks/useApproval";
import { ICurrency, ITEM_AMOUNT_BY_NFT_TYPE } from "@/types";
import { mapAndLogError } from "@/utils/error";

import { useP2PLoanOffers } from "../../LendDataContext";
import { signOfferData } from "../../utils";
import { NftMetadata } from "./NftMetadata";
import { OfferButton, OfferMakerCard } from "./OfferMakerCard";

export type ILoanOfferCreationData = {
  lenderAddress: string;
  term: number;
  currency: ICurrency;
  amount: number;
  isExtendable: boolean;
  interestRate: number;
  numberOfNfts: number;
  offerExpiry: number;
};
export const LoanOfferCreatingProgress = ({
  collection,
  nft,
  loanData,
}: {
  nft?: IP2PListedNft;
  collection: ICollectionBe;
  loanData: ILoanOfferCreationData;
}) => {
  const { giveErc20Approval } = useApproval();
  const { setModalContent } = useModal();
  const cyanWallet = useCyanWallet();
  const { fetchLoanOffers } = useP2PLoanOffers();
  const { fetchUserCreatedLoanBids } = useCreatedLoanBids();
  const { unsetModal } = useModal();
  const { provider, chainId, account, signer } = useWeb3React();
  const [selectedStep, setSelectedStep] = useState<Steps>(Steps.Approve);

  const checkAndApproveERC20 = async () => {
    if (!signer || !account) throw new Error("Provider not found!");
    if (nft) {
      const contractWriter = f.SampleFactory.connect(nft.collectionAddress, signer);
      const owner = await contractWriter.ownerOf(nft.tokenId);
      if (
        owner.toLowerCase() === account.toLowerCase() ||
        (cyanWallet && cyanWallet.walletAddress.toLowerCase() === owner.toLowerCase())
      ) {
        throw new Error("Can't create make loan offer for owned NFT.");
      }
    }
    const amount = utils.parseUnits((loanData.amount * loanData.numberOfNfts).toString(), loanData.currency.decimal);
    const contract = f.SampleERC20TokenFactory.connect(loanData.currency.address, signer);
    const isCyanWalletAddressSelected =
      cyanWallet && cyanWallet.walletAddress.toLowerCase() === loanData.lenderAddress.toLowerCase();
    const userBalance = await contract.balanceOf(isCyanWalletAddressSelected ? cyanWallet.walletAddress : account);
    if (userBalance.lt(amount)) {
      const msg =
        loanData.currency.symbol === SupportedCurrencies.WETH
          ? "You don’t have enough WETH. Please convert your ETH into WETH at any of the trusted DEXs available. We recommend Uniswap and Matcha to trade into WETH, or OpenSea to wrap your ETH"
          : `You don’t have enough ${loanData.currency.symbol}.`;
      setModalContent({
        title: nft ? `NFT Loan Offer` : `Collection Loan Offer`,
        content: (
          <OfferMakerCard
            nft={nft}
            collection={collection}
            error={{
              title: "Insufficient Balance",
              msg,
            }}
            loanData={loanData}
          />
        ),
      });
      return;
    }
    const peerToPeerContract = getPeerPaymentPlanFromChainId(chainId);
    await giveErc20Approval(
      {
        currencyAddress: loanData.currency.address,
        amount,
        cyanWallet: isCyanWalletAddressSelected ? cyanWallet : undefined,
      },
      peerToPeerContract,
    );
  };
  useEffect(() => {
    const createOffer = async () => {
      try {
        switch (selectedStep) {
          case Steps.Approve: {
            await checkAndApproveERC20();
            setSelectedStep(Steps.Sign);
            return;
          }
          case Steps.Sign: {
            if (!provider || !collection.cyanSignature || !signer) return;
            const lastBlockNum = await provider.getBlockNumber();
            const lastBlock = await provider.getBlock(lastBlockNum);
            const lastTimestamp = lastBlock.timestamp * 1000;
            const expiryDate = dayjs(lastTimestamp).add(loanData.offerExpiry, "seconds");
            const signedDate = dayjs().valueOf();
            const signature = await signOfferData(
              {
                contractAddress: collection.address.toLowerCase(),
                tokenId: nft ? nft.tokenId : undefined,
                amount: utils.parseUnits(loanData.amount.toString(), loanData.currency.decimal),
                currencyAddress: loanData.currency.address,
                itemType: collection.tokenType,
                interestRate: loanData.interestRate * 100,
                term: loanData.term,
                maxUsageCount: loanData.numberOfNfts,
                isExtendable: loanData.isExtendable,
                collectionSignature: collection.cyanSignature,
                expiryDate: expiryDate.valueOf(),
                signedDate,
                tokenAmount: ITEM_AMOUNT_BY_NFT_TYPE[collection.tokenType],
                chainId,
              },
              signer,
            );
            setSelectedStep(Steps.CreateOffer);
            await createP2POffer({
              chainId,
              collectionAddress: collection.address,
              tokenId: nft ? nft.tokenId : undefined,
              amount: utils.parseUnits(loanData.amount.toString(), loanData.currency.decimal).toString(),
              lenderAddress: loanData.lenderAddress,
              isExtendable: loanData.isExtendable,
              signature,
              signatureExpiry: expiryDate.valueOf(),
              signedDate,
              signatureUsageLimit: loanData.numberOfNfts,
              currency: loanData.currency.symbol,
              term: loanData.term,
              interestRate: loanData.interestRate * 100,
              tokenAmount: ITEM_AMOUNT_BY_NFT_TYPE[collection.tokenType],
            });
            await fetchLoanOffers();
            await fetchUserCreatedLoanBids();
            setSelectedStep(Steps.Done);
          }
        }
      } catch (error) {
        const mappedError = mapAndLogError(error, account);
        setModalContent({
          title: nft ? `NFT Loan Offer` : `Collection Loan Offer`,
          content: <OfferMakerCard nft={nft} collection={collection} error={mappedError} loanData={loanData} />,
        });
      }
    };
    createOffer();
  }, [selectedStep]);
  return (
    <Flex gap="1rem" direction="column">
      {nft && <NftMetadata nft={nft} />}
      <Box pb="2rem" pt="1rem">
        <Stepper marks={StepMarks} selectedStep={selectedStep} txUrl="" />
      </Box>
      {selectedStep === Steps.Done && <OfferButton onClick={unsetModal}>{`Close`}</OfferButton>}
    </Flex>
  );
};

enum Steps {
  Approve = 0,
  Sign = 1,
  CreateOffer = 2,
  Done = 3,
}
const StepMarks = [
  {
    value: Steps.Approve,
    title: `ERC20 Token Approval`,
    description: `This provides Cyan with permission to move your ERC20`,
  },
  {
    value: Steps.Sign,
    title: `Sign Offer`,
  },
  {
    value: Steps.CreateOffer,
    title: `Making loan offer`,
  },
];
