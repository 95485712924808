import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useModal } from "@cyanco/components/theme/v3";

import { getEmailVerificationStatus } from "@/apis/user";
import { AuthContext } from "@/components/AuthContext/AuthContextProvider";
import SetupAlertsModal from "@/components/SetupAlerts";
import { useLocalStorage } from "@/hooks";
import usePrevious from "@/hooks/usePrevious";
import { StatusBoxTypes } from "@/utils/types";

import { AccountPage as AccountPageV3 } from "../../components/Account";

const AccountHistory = () => {
  const { fetchUserBe } = useContext(AuthContext);
  const { setModalContent, unsetModal, setModalChildContent, onBackModal } = useModal();

  const [searchParams, setSearchParams] = useSearchParams();
  const verificationToken = searchParams.get("token");
  const [verificationStatus, setVerificationStatus] = useLocalStorage<boolean | null>("verificationStatus", null);
  const prevToken = usePrevious(verificationToken);

  useEffect(() => {
    const openSetupAlert = async (status: boolean) => {
      try {
        await fetchUserBe();
        setModalContent({
          title: `Setup Alerts`,
          content: (
            <SetupAlertsModal
              statusBoxType={status ? StatusBoxTypes.Success : StatusBoxTypes.Error}
              onClose={() => {
                unsetModal();
              }}
              onNext={nextNode => {
                setModalChildContent({ childContent: nextNode });
              }}
            />
          ),
          onClose: () => {
            setVerificationStatus(null);
          },
          onBack: onBackModal,
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (verificationStatus === null) return;
    openSetupAlert(verificationStatus);
  }, [verificationStatus]);

  useEffect(() => {
    const checkEmailVerificationStatus = async (token: string) => {
      const isEmailVerified = await getEmailVerificationStatus(token);
      setVerificationStatus(isEmailVerified);
      setSearchParams([]);
    };

    if (verificationToken === null) return;
    checkEmailVerificationStatus(verificationToken);
  }, [prevToken !== verificationToken]);
  return <AccountPageV3 />;
};

export default AccountHistory;
