import styled from "styled-components";

import { breakpoints } from "../../config";

interface IHidden {
  mobileUp?: boolean;
  tabletUp?: boolean;
  laptopSUp?: boolean;
  laptopUp?: boolean;
  laptopMUp?: boolean;
  laptopLUp?: boolean;
  desktopUp?: boolean;

  mobileDown?: boolean;
  tabletDown?: boolean;
  laptopSDown?: boolean;
  laptopDown?: boolean;
  laptopMDown?: boolean;
  laptopLDown?: boolean;
  desktopDown?: boolean;
}

const media = (feature: string, breakPoint: number) => `
  @media only screen and (${feature}: ${breakPoint}px) {
    display: none !important;
  }
`;

export const Hidden = styled.span<IHidden>`
  ${({ mobileUp }) => mobileUp && media("min-width", breakpoints.mobile)}
  ${({ tabletUp }) => tabletUp && media("min-width", breakpoints.tablet)}
  ${({ laptopSUp }) => laptopSUp && media("min-width", breakpoints.laptopS)}
  ${({ laptopUp }) => laptopUp && media("min-width", breakpoints.laptop)}
  ${({ laptopMUp }) => laptopMUp && media("min-width", breakpoints.laptopM)}
  ${({ laptopLUp }) => laptopLUp && media("min-width", breakpoints.laptopL)}
  ${({ desktopUp }) => desktopUp && media("min-width", breakpoints.desktop)}

  ${({ mobileDown }) => mobileDown && media("max-width", breakpoints.mobile)}
  ${({ tabletDown }) => tabletDown && media("max-width", breakpoints.tablet)}
  ${({ laptopSDown }) => laptopSDown && media("max-width", breakpoints.laptopS)}
  ${({ laptopDown }) => laptopDown && media("max-width", breakpoints.laptop)}
  ${({ laptopMDown }) => laptopMDown && media("max-width", breakpoints.laptopM)}
  ${({ laptopLDown }) => laptopLDown && media("max-width", breakpoints.laptopL)}
  ${({ desktopDown }) => desktopDown && media("max-width", breakpoints.desktop)}
  ${({
    mobileUp,
    tabletUp,
    laptopSUp,
    laptopUp,
    laptopMUp,
    laptopLUp,
    desktopUp,
    mobileDown,
    tabletDown,
    laptopSDown,
    laptopDown,
    laptopMDown,
    laptopLDown,
    desktopDown,
  }) =>
    !mobileUp &&
    !tabletUp &&
    !laptopSUp &&
    !laptopUp &&
    !laptopMUp &&
    !laptopLUp &&
    !desktopUp &&
    !mobileDown &&
    !tabletDown &&
    !laptopSDown &&
    !laptopDown &&
    !laptopMDown &&
    !laptopLDown &&
    !desktopDown &&
    "display: none;"}
`;
