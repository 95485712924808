import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { ClickableArea, Flex } from "@cyanco/components/theme/components";
import {
  AssetTag,
  Loader,
  NftCard,
  NftCardActionText,
  NftCardActionWrapper,
  NftCardText,
  NftMetadataImage,
  NftMetadataInfoWrapper,
  Text,
  Tooltip,
  TooltipText,
} from "@cyanco/components/theme/v3";

import { IPeerPlan, isPeerPlan } from "@/apis/p2p/types";
import { ApeCoinStatus } from "@/components/ApeCoinStaking/ApeCoinStatusOnRow";
import { StyledNftCardAddButton } from "@/components/ApeCoinStaking/new/components/Rewards/StakableNftCard";
import { BNPLStatuses, IBNPL } from "@/components/Bnpl/bnpl.types";
import { INFtRarity } from "@/components/NftStatus";
import { APE_COIN_STAKABLE_ADDRESSES } from "@/config";
import { bigNumToFixedStr, bigNumToFloat, isApeCoinStakingPossible, numberWithCommas, shortenName } from "@/utils";

import { IPawn } from "../../pawn.types";
import { NftRarity } from "../common";
import { checkIsPositionActive, checkIsPositionDefaulted, checkIsRevivalPossible } from "./utils";

export const PositionCard = ({
  position,
  onClick,
  chainId,
  toggleItem,
  loading,
  isSelected,
}: {
  position: (IBNPL | IPawn | IPeerPlan) & { rarity: INFtRarity | null };
  onClick: () => void;
  chainId: number;
  toggleItem: () => void;
  loading: boolean;
  isSelected: boolean;
}) => {
  const theme = useTheme();
  const _isPeerPlan = isPeerPlan(position);
  const currency = _isPeerPlan ? position.loanBid.currency : position.currency;

  const enableApeCoinStaking = useMemo(() => {
    return (
      isApeCoinStakingPossible(chainId) &&
      APE_COIN_STAKABLE_ADDRESSES.includes(
        _isPeerPlan ? position.collectionAddress : position.metadata.collectionAddress,
      )
    );
  }, [chainId, position]);
  const ApeCoinStatusMemo = useMemo(() => {
    return (
      <ApeCoinStatus
        nft={{
          tokenId: position.tokenId,
          address: _isPeerPlan ? position.collectionAddress : position.metadata.collectionAddress,
        }}
        isCard={true}
      />
    );
  }, [position.tokenId]);

  const isDefaulted = useMemo(() => {
    return checkIsPositionDefaulted(position);
  }, [position]);
  const isRevivalPossible = useMemo(() => {
    return checkIsRevivalPossible(position);
  }, [position]);
  const isActive = useMemo(() => {
    return checkIsPositionActive(position);
  }, [position]);

  return (
    <NftPositionCardStyled disabled={isDefaulted} selected={isSelected}>
      <ClickableArea onClick={() => !loading && onClick()}>
        <Flex direction="column">
          <NftMetadataImage imageUrl={position.metadata ? position.metadata.imageUrl : null} alt={position.tokenId}>
            <AssetTagsWrapper>
              <AssetTag size="large" variant="black">
                {position.planType === "Pawn" ? `Loan` : position.planType}
              </AssetTag>
            </AssetTagsWrapper>
            {enableApeCoinStaking && <ApeWrapper>{ApeCoinStatusMemo}</ApeWrapper>}
          </NftMetadataImage>
        </Flex>
      </ClickableArea>
      <NftMetadataInfoWrapper style={{ background: !isDefaulted ? theme.colors.white : theme.colors.black }}>
        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <NftCardText weight="800" color={isDefaulted ? theme.colors.white : theme.colors.black}>
              {shortenName(_isPeerPlan ? position.collection.name : position.metadata.collection.name)} #
              {shortenName(position.tokenId, 10, 6)}
            </NftCardText>
          </div>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Tooltip>
            <NftCardText sub weight="500" color={isDefaulted ? theme.colors.white : theme.colors.black}>
              {`${numberWithCommas(
                bigNumToFloat(position.monthlyAmount, currency.decimal) *
                  (_isPeerPlan ? 1 : position.totalNumOfPayments),
                2,
              )} ${currency.symbol}`}
            </NftCardText>
            <TooltipText left="-5px" top="-40px">
              <Text size="xs" color={"primary"} weight="700" textWrap={false}>
                {`Total Loan`}
              </Text>
            </TooltipText>
          </Tooltip>
          {position.rarity && <NftRarity rarity={position.rarity} isPlan={isActive} />}
        </Flex>
      </NftMetadataInfoWrapper>
      <NftCardActionWrapper
        style={{
          minHeight: "25px",
          position: "relative",
        }}
      >
        <Flex justifyContent="space-between" alignItems="center" h="25px">
          <StyledButton
            disabled={loading}
            onClick={e => {
              e.stopPropagation();
              onClick();
            }}
            style={{
              width: "100%",
              padding: 0,
            }}
          >
            {loading ? (
              <Flex alignItems="center" gap="5px">
                <NftCardActionText weight="700" textAlign="left" color="cyan">
                  {`Processing`}
                </NftCardActionText>
                <Loader size="12px" stroke={theme.colors.secondary} />
              </Flex>
            ) : (
              <>
                {isActive && (
                  <Flex alignItems="center" justifyContent="space-between" w="100%">
                    <NftCardActionText weight="500" textAlign="left" color="black">
                      {`Next Payment: `}
                    </NftCardActionText>{" "}
                    <NftCardActionText weight="600" textAlign="left" color="black">
                      {bigNumToFixedStr(position.monthlyAmount, 2)} {currency.symbol}
                    </NftCardActionText>
                  </Flex>
                )}

                {isDefaulted ? (
                  isRevivalPossible ? (
                    <NftCardActionText color="cyan" weight="500" textAlign="left">
                      Revive
                    </NftCardActionText>
                  ) : (
                    <NftCardActionText color="red" weight="500" textAlign="left">
                      {`Defaulted`}
                    </NftCardActionText>
                  )
                ) : (
                  position.planType === "BNPL" &&
                  position.status !== BNPLStatuses.Activated && (
                    <NftCardActionText
                      color={position.status === BNPLStatuses.Rejected ? "red" : "cyan"}
                      weight="500"
                      textAlign="left"
                    >
                      {position.status === BNPLStatuses.Funded && `Purchasing`}
                      {position.status === BNPLStatuses.Pending && `Funding`}
                      {position.status === BNPLStatuses.Rejected && `Rejected`}
                    </NftCardActionText>
                  )
                )}
              </>
            )}
          </StyledButton>
          {isActive && (
            <StyledNftCardAddButton
              isInCart={isSelected}
              onClick={e => {
                e.stopPropagation();
                toggleItem();
              }}
            >
              {!isSelected ? (
                <NftCardText weight="600" color="black">{`Select`}</NftCardText>
              ) : (
                <NftCardText weight="600" color="secondary">
                  {`Unselect`}
                </NftCardText>
              )}
            </StyledNftCardAddButton>
          )}
        </Flex>
      </NftCardActionWrapper>
    </NftPositionCardStyled>
  );
};
const StyledButton = styled.button<{ disabled?: boolean }>`
  border: none;
  outline: none;
  height: 100%;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.2s;
  padding-left: 0;
`;

const NftPositionCardStyled = styled(NftCard)<{ disabled?: boolean }>`
  :hover {
    ${StyledNftCardAddButton} {
      top: 25%;
      opacity: 1 !important;
      height: 25px !important;
    }
  }

  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.black : theme.colors.white)};
`;

const ApeWrapper = styled.div`
  right: 0;
  position: absolute;
  bottom: 0;
`;

const AssetTagsWrapper = styled(Flex)`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
`;
