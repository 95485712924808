import { useMemo, useState } from "react";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled, { useTheme } from "styled-components";

import StyledCheckbox from "@cyanco/components/theme/Checkbox";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { Refresh } from "@/components/Account/components/WalletView/Refresh";
import { IPawn } from "@/components/Account/pawn.types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { INFtRarity } from "@/components/NftStatus";
import { BAKCAddress } from "@/config";
import { bigNumToFixedStr, displayInUSD, numberWithCommas, shortenAddress } from "@/utils";

import { useApeStakingUserAssets } from "../../ApeCoinDataContext";
import { useApeCoinContext, useSelectedStakingNftsContext } from "../../ApeCoinPageContext";
import { IApeCoinStakableNft, IApeCoinStakablePosition, ISelectedNft } from "../../types";
import { WalletSelector } from "../Selector";
import { WalletContainer } from "./StakedNftRow";

export const ApeStakableNftsHeader = ({ totalItems }: { totalItems: number }) => {
  const { selectedUnstakedWalletType, setSelectedUnstakedWalletType } = useApeCoinContext();
  const { items, addItems, removeAll } = useSelectedStakingNftsContext();
  const { stakableAssets, stakablePositions, loading, refreshAssets } = useApeStakingUserAssets();
  const checkAll = () => {
    const positionsFiltered = stakablePositions.filter(
      ({ metadata: { collectionAddress } }) => collectionAddress.toLowerCase() !== BAKCAddress,
    );
    const assetsFiltered = stakableAssets.filter(({ address }) => address.toLowerCase() !== BAKCAddress);
    if (items.length === [...positionsFiltered, ...assetsFiltered].length && !loading) {
      removeAll();
    } else {
      const positions = stakablePositions.map(position => ({
        address: position.metadata.collectionAddress.toLowerCase(),
        apeStaking: position.apeStaking,
        tokenId: position.tokenId,
        isCyanWallet: true,
        imageUrl: position.metadata.imageUrl,
      }));
      const nfts = stakableAssets.map(asset => ({
        address: asset.address.toLowerCase(),
        apeStaking: asset.apeStaking,
        tokenId: asset.tokenId,
        isCyanWallet: asset.isCyanWallet,
        imageUrl: asset.imageUrl,
      }));
      addItems([...nfts, ...positions].filter(({ address }) => address.toLowerCase() !== BAKCAddress));
    }
  };

  const isAllChecked = useMemo(() => {
    const positionsFiltered = stakablePositions.filter(
      ({ metadata: { collectionAddress } }) => collectionAddress.toLowerCase() !== BAKCAddress,
    );
    const assetsFiltered = stakableAssets.filter(({ address }) => address.toLowerCase() !== BAKCAddress);
    return [...assetsFiltered, ...positionsFiltered].length === items.length && items.length > 0 && !loading;
  }, [stakablePositions, stakableAssets, loading, items]);

  return (
    <HeaderContainer>
      <Flex gap="1rem" alignItems="center">
        <div style={{ height: "16px", marginTop: "-2px" }}>
          <StyledCheckbox checked={items.length > 1 || isAllChecked} onChange={checkAll} id={"check-all"} showMinus />
        </div>
        <RowText weight="600" color="secondary">{`${totalItems} item${totalItems > 1 ? "s" : ""}`}</RowText>
        <Hidden tabletDown>
          <Refresh onClick={refreshAssets} />
        </Hidden>
      </Flex>
      <Hidden tabletDown>
        <WalletSelector
          selectedWalletType={selectedUnstakedWalletType}
          setSelectedWalletType={setSelectedUnstakedWalletType}
        />
      </Hidden>
      <RowText weight="700" color="secondary">{`Appraisal`}</RowText>
      <Hidden tabletDown>
        <RowText weight="700" color="secondary">{`Rarity`}</RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText weight="700" color="secondary">{`Stake`}</RowText>
      </Hidden>
    </HeaderContainer>
  );
};

export const StakableNftRow = ({
  nft,
  onClick,
  account,
}: {
  nft: IApeCoinStakableNft & { priceInUSD: number | null; rarity: INFtRarity | null };
  onClick: (nft: ISelectedNft) => void;
  account: string;
}) => {
  const theme = useTheme();
  const { items: cartItems, toggleItem } = useSelectedStakingNftsContext();
  const [imageLoading, setImageLoading] = useState(true);
  const getPrice = useMemo(() => {
    return !nft.appraisalValue ? "-" : bigNumToFixedStr(nft.appraisalValue, 2);
  }, [nft.appraisalValue]);
  const isInCart = useMemo(
    () => !!cartItems.find(({ address, tokenId }) => address === nft.address && tokenId === nft.tokenId),
    [cartItems, nft.address, nft.tokenId],
  );
  const isBakc = useMemo(() => nft.address.toLowerCase() === BAKCAddress, [nft.address]);
  return (
    <Row onClick={() => onClick(nft)}>
      <Flex alignItems="center" gap="1rem">
        <div>
          {/* // wrapped empty div for safari */}
          <RowCartCheckboxWrapper
            onClick={e => {
              e.stopPropagation();
            }}
            alignItems="center"
          >
            <StyledCheckbox
              disabled={isBakc}
              checked={isInCart}
              onChange={e => {
                e.stopPropagation();
                toggleItem(nft);
              }}
              style={{
                visibility: isBakc ? "hidden" : "visible",
              }}
              id={`${nft.tokenId}-${nft.isCyanWallet ? "cyan" : "main"}-nft`}
            />
          </RowCartCheckboxWrapper>
        </div>
        <Flex alignItems="center" gap="20px">
          {imageLoading && (
            <ImageLoader>
              <SkeletonLine w="100%" h="100%" borderRadius="10px" />
            </ImageLoader>
          )}
          <StyledImg
            src={nft.imageUrl || NoImage}
            alt={nft.collectionName}
            onLoad={() => setImageLoading(false)}
            style={{
              display: imageLoading ? "none" : "block",
            }}
            hasImage={!!nft.imageUrl}
          />
          <Flex direction="column">
            <RowText color={"secondary"}>#{nft.tokenId}</RowText>
            <Flex gap="5px">
              <RowText color={"gray0"} sub>
                {nft.collectionName}
              </RowText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Hidden tabletDown>
        {nft.isCyanWallet ? (
          <WalletContainer>
            <div
              style={{
                background: theme.colors.cyan,
                width: "18px",
                height: "18px",
                minWidth: "18px",
                minHeight: "18px",
                borderRadius: "50%",
              }}
            />
            <RowText color="secondary" weight="500">
              Cyan Wallet
            </RowText>
          </WalletContainer>
        ) : (
          <WalletContainer>
            <Jazzicon seed={jsNumberForAddress(account)} diameter={18} />
            <RowText color="secondary" weight="500">
              {shortenAddress(account)}
            </RowText>
          </WalletContainer>
        )}
      </Hidden>
      <Flex direction="column">
        <RowText color={"secondary"}>
          {getPrice} {nft.currency.symbol}
        </RowText>
        <RowText sub color="gray0">
          {nft.priceInUSD ? displayInUSD(nft.priceInUSD) : "-"}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column">
          <RowText color={"secondary"}>{nft.rarity ? numberWithCommas(nft.rarity.rank) : "-"}</RowText>
          <RowText color="gray0" sub>
            {nft.rarity ? nft.rarity.relativeRank : "-"}
          </RowText>
        </Flex>
      </Hidden>
      <Flex gap="0.8rem">
        <StakigButton size="xs">{`Stake APE`}</StakigButton>
      </Flex>
    </Row>
  );
};

export const StakablePositiontRow = ({
  nft,
  onClick,
}: {
  nft: IApeCoinStakablePosition & { priceInUSD: number | null; rarity: INFtRarity | null };
  onClick: (nft: ISelectedNft) => void;
}) => {
  const { items: cartItems, toggleItem } = useSelectedStakingNftsContext();
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(true);
  const getLoanedAmount = useMemo(() => {
    if (nft.planType === "Pawn") {
      return bigNumToFixedStr((nft as IPawn).pawnedAmount, 2);
    } else {
      return bigNumToFixedStr((nft as IBNPL).price, 2);
    }
  }, [nft.currentNumOfPayments]);
  const isInCart = useMemo(
    () =>
      !!cartItems.find(
        ({ address, tokenId }) =>
          address.toLowerCase() === nft.metadata.collectionAddress.toLowerCase() && tokenId === nft.tokenId,
      ),
    [cartItems, nft.metadata.collectionAddress, nft.tokenId],
  );
  const isBakc = useMemo(
    () => nft.metadata.collectionAddress.toLowerCase() === BAKCAddress,
    [nft.metadata.collectionAddress],
  );
  return (
    <Row
      onClick={() =>
        onClick({
          address: nft.metadata.collectionAddress.toLowerCase(),
          apeStaking: nft.apeStaking,
          tokenId: nft.tokenId,
          isCyanWallet: true,
          imageUrl: nft.metadata.imageUrl,
        })
      }
    >
      <Flex alignItems="center" gap="1rem">
        <div>
          {/* // wrapped empty div for safari */}
          <RowCartCheckboxWrapper
            onClick={e => {
              e.stopPropagation();
            }}
            alignItems="center"
          >
            <StyledCheckbox
              disabled={isBakc}
              checked={isInCart}
              onChange={e => {
                e.stopPropagation();
                toggleItem({
                  address: nft.metadata.collectionAddress.toLowerCase(),
                  apeStaking: nft.apeStaking,
                  tokenId: nft.tokenId,
                  isCyanWallet: true,
                  imageUrl: nft.metadata.imageUrl,
                });
              }}
              id={nft.tokenId}
              style={{
                visibility: isBakc ? "hidden" : "visible",
              }}
            />
          </RowCartCheckboxWrapper>
        </div>
        <Flex alignItems="center" gap="20px">
          {imageLoading && (
            <ImageLoader>
              <SkeletonLine w="100%" h="100%" borderRadius="10px" />
            </ImageLoader>
          )}
          <StyledImg
            src={nft.metadata.imageUrl || NoImage}
            alt={nft.metadata.collection.name}
            onLoad={() => setImageLoading(false)}
            style={{
              display: imageLoading ? "none" : "block",
            }}
            hasImage={!!nft.metadata.imageUrl}
          />
          <Flex alignItems="center" gap="5px">
            <Flex direction="column">
              <Flex gap="5px">
                <RowText color={"secondary"}>#{nft.tokenId}</RowText>
                <Hidden laptopDown>
                  <Flex gap="4px" alignItems="flex-end">
                    <Flex
                      style={{
                        background: theme.colors.secondary,
                        borderRadius: "4px",
                      }}
                      p={"2px 4px"}
                      h={"fit-content"}
                    >
                      <Text color="primary" size="xxs" weight="700">
                        {nft.planType === "BNPL" ? "BNPL" : "Loan"}
                      </Text>
                    </Flex>
                  </Flex>
                </Hidden>
              </Flex>
              <RowText color={"gray0"} sub>
                {nft.metadata.collection.name}
              </RowText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Hidden tabletDown>
        <WalletContainer>
          <div
            style={{
              background: theme.colors.cyan,
              width: "18px",
              height: "18px",
              minWidth: "18px",
              minHeight: "18px",
              borderRadius: "50%",
            }}
          />
          <RowText color="secondary" weight="500">
            Cyan Wallet
          </RowText>
        </WalletContainer>
      </Hidden>
      <Flex direction="column">
        <RowText color={"secondary"}>
          {getLoanedAmount} {nft.currency.symbol}
        </RowText>
        <RowText sub color="gray0">
          {nft.priceInUSD ? displayInUSD(nft.priceInUSD) : "-"}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column">
          <RowText color={"secondary"}>{nft.rarity ? numberWithCommas(nft.rarity.rank) : "-"}</RowText>
          <RowText color="gray0" sub>
            {nft.rarity ? nft.rarity.relativeRank : "-"}
          </RowText>
        </Flex>
      </Hidden>
      <Flex gap="0.8rem">
        <StakigButton size="xs">{`Stake APE`}</StakigButton>
      </Flex>
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 1rem;
  align-items: center;
  background-color: transparent;
  grid-template-columns: 2fr 1.3fr 1.3fr 1.3fr 1fr;
  padding: 13px 15px 13px 15px;
  cursor: pointer;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "7px 10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2fr 1.3fr 1.3fr" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const HeaderContainer = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${({ theme }) => theme.colors.primary};
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 7px 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

const ImageLoader = styled.div`
  width: 36px;
  height: 36px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 24 }])}
`;
const StyledImg = styled.img<{ hasImage?: boolean }>`
width: 36px;
${getStyleWithMediaQuery("width", "px", [{ [breakpoints.desktop]: 36 }, { [breakpoints.mobile]: 24 }])};
border-radius: ${({ theme }) => theme.borderRadius};
filter: ${({ hasImage, theme }) =>
  !hasImage &&
  theme.theme === "light" &&
  "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};

} `;

const StakigButton = styled(Button)`
  padding: 0.3rem 0.8rem;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
`;
export const RowCartCheckboxWrapper = styled(Flex)`
  margin: -13px 0px;
  padding: 13px 0px;
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.mobile]: "-7px 0px" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 0px" }])};
  height: 100%;
`;
