import orderBy from "lodash.orderby";
import { useEffect, useMemo, useState } from "react";

import { ICollectionBe } from "@/apis/collection/types";
import { useQueryParams } from "@/hooks/useQueryParams";

import { useAppContext } from "../AppContextProvider";
import { useBnplSelectors } from "./BnplPageContext";

export const useBnplSupportedCollections = () => {
  const { collections, collectionsLoading } = useAppContext();
  const { collectionTagId } = useQueryParams();
  const [filteredCollections, setFilteredCollections] = useState<ICollectionBe[]>(
    collections.filter(c => c.vaults.length > 0),
  );
  const { selectedChain, selectedCollectionSearch } = useBnplSelectors();
  const financeableCollections = useMemo(() => {
    return collections.filter(c => c.vaults.some(v => v.isBnplAllowed));
  }, [collections]);
  useEffect(() => {
    let filteredCollections = financeableCollections;
    if (selectedCollectionSearch !== "") {
      filteredCollections = financeableCollections.filter(
        collection =>
          (collection.name.toLowerCase().includes(selectedCollectionSearch.toLowerCase()) ||
            collection.address.toLowerCase().startsWith(selectedCollectionSearch.toLowerCase())) &&
          (collectionTagId ? collection.tags.includes(Number(collectionTagId)) : true) &&
          selectedChain === collection.chainId,
      );
    } else {
      filteredCollections = financeableCollections.filter(
        collection =>
          (collectionTagId && collectionTagId !== "all" ? collection.tags.includes(Number(collectionTagId)) : true) &&
          selectedChain === collection.chainId,
      );
    }
    const _orderedCollections = orderBy(filteredCollections, [c => c.volume["1day"]], "desc");
    setFilteredCollections(collectionTagId === "all" ? _orderedCollections : _orderedCollections.splice(0, 15));
  }, [financeableCollections, selectedCollectionSearch, , collectionTagId]);
  return {
    collections: financeableCollections,
    collectionsFiltered: filteredCollections,
    collectionsLoading,
  };
};
