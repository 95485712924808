import { useEffect, useRef } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Text, Title } from "@cyanco/components/theme/v3";

import { useAppContext } from "../AppContextProvider";
import { useWeb3React } from "../Web3ReactProvider";
import { CumulativePoints } from "./CumulativePoints";
import { Leaderboard } from "./LeaderBoard";
import { MainTitle } from "./MainTitle";
import { UserProgress } from "./UserProgress";

export const CyanPoints = () => {
  const { account } = useWeb3React();
  const { setFireConfetti } = useAppContext();
  const leaderboardRef = useRef<HTMLDivElement>(null);
  const scrollToLeaderboard = () => {
    leaderboardRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (account) {
      const isCyanPointOpenedLs = localStorage.getItem("isCyanPointOpened");
      const isCyanPointOpened = isCyanPointOpenedLs ? JSON.parse(isCyanPointOpenedLs) : {};
      if (!isCyanPointOpened[account]) {
        setFireConfetti(true);
        localStorage.setItem(
          "isCyanPointOpened",
          JSON.stringify({
            ...isCyanPointOpened,
            [account]: true,
          }),
        );
      }
    }
  }, []);
  return (
    <Flex direction="column">
      <Container direction="column" alignItems="center">
        <Flex direction="column" gap="3rem" w="100%" alignItems="center">
          <MainTitle toLeaderboard={scrollToLeaderboard} />
          <UserProgress />
        </Flex>
        <Flex ref={leaderboardRef} w="100%" justifyContent="center" pt="3rem" pb="3rem">
          <Leaderboard />
        </Flex>
        <CumulativePoints />
      </Container>
    </Flex>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1240px) / 2);
  margin-top: 0px;
  padding: 0 0 3rem 0;
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 50px" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "1rem 0.5rem 0rem 0.5rem" }])}
`;

export const HomeTitleWebkit = styled(Title)`
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: ${({ theme }) =>
    theme.theme === "light"
      ? "linear-gradient(180deg, #0FF 0%, rgba(24, 134, 134, 0.61) 100%)"
      : "linear-gradient(180deg, #FFF 0%, rgba(0, 255, 255, 0.61) 100%)"};
`;

export const ButtonText = styled(Text)`
  font-size: 20px;
  color: black;
  font-weight: 600;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  letter-spacing: -0.6px;
`;
