import { axios } from "@/apis/axios";
import { IPaymentPlanStatus } from "@/constants/plans";

import { IVaultLatestLoan } from "../types";
import { ILiquidatedNftStatus, ILtvController, IPoolSettings, IVaultLiquidatedNft, IVaultLoanSettings } from "./types";

export const getVaultLoanSettings = async (contractAddress: string, token: string): Promise<IVaultLoanSettings> => {
  const { data } = await axios.get(`/v2/vault-admin/${contractAddress}/settings`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getLatestVaultLoansAdmin = async ({
  contractAddress,
  offset,
  token,
  statuses,
  includeAutoLiquidationReadyPlans,
}: {
  contractAddress: string;
  offset: number;
  token: string;
  includeAutoLiquidationReadyPlans?: boolean;
  statuses: IPaymentPlanStatus[];
}): Promise<{ latestLoans: IVaultLatestLoan[]; totalCount: number }> => {
  const { data } = await axios.get(`/v2/vault-admin/${contractAddress}/latest-loans`, {
    params: { offset, statuses, includeAutoLiquidationReadyPlans },
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const updateSupportedProjects = async ({
  vaultAddress,
  projects,
  signature,
  token,
  expiryDate,
}: {
  vaultAddress: string;
  projects: {
    address: string;
    isBnplAllowed: boolean;
    isPawnAllowed: boolean;
  }[];
  signature: string;
  token: string;
  expiryDate: number;
}): Promise<void> => {
  await axios.patch(
    `/v2/vault-admin/${vaultAddress}/supported-projects`,
    {
      projects,
      signature,
      expiryDate,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );
};

export const requestProjectsOfVault = async ({
  vaultAddress,
  projects,
  signature,
  token,
  expiryDate,
}: {
  vaultAddress: string;
  projects: {
    address: string;
    name: string;
    imageUrl: string;
    isBnplAllowed: boolean;
    isPawnAllowed: boolean;
  }[];
  signature: string;
  token: string;
  expiryDate: number;
}): Promise<void> => {
  await axios.post(
    `/v2/vault-admin/${vaultAddress}/requested-projects`,
    {
      projects,
      signature,
      expiryDate,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );
};

export const updateCollectionsAppraisalSettings = async ({
  vaultAddress,
  projects,
  signature,
  token,
  expiryDate,
}: {
  vaultAddress: string;
  projects: {
    customAppraisalValue: string;
    address: string;
  }[];
  signature: string;
  token: string;
  expiryDate: number;
}): Promise<void> => {
  await axios.patch(
    `/v2/vault-admin/${vaultAddress}/appraisal-settings`,
    {
      projects,
      signature,
      expiryDate,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );
};

export const updateVaultDetail = async ({
  vaultAddress,
  name,
  description,
  signature,
  token,
  expiryDate,
}: {
  vaultAddress: string;
  name: string;
  description: string;
  signature: string;
  token: string;
  expiryDate: number;
}): Promise<void> => {
  await axios.patch(
    `/v2/vault-admin/${vaultAddress}/update-vault-detail`,
    {
      name,
      description,
      signature,
      expiryDate,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );
};

export const updateLtvControllers = async ({
  vaultAddress,
  configs,
  signature,
  token,
  expiryDate,
}: {
  vaultAddress: string;
  signature: string;
  token: string;
  configs: ILtvController[];
  expiryDate: number;
}): Promise<void> => {
  await axios.put(
    `/v2/vault-admin/${vaultAddress}/update-ltv-settings`,
    {
      configs,
      signature,
      expiryDate,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );
};

export const updatePoolSettings = async ({
  vaultAddress,
  configs,
  signature,
  token,
  baseInterestRate,
  expiryDate,
}: {
  vaultAddress: string;
  signature: string;
  token: string;
  configs: IPoolSettings[];
  baseInterestRate: number;
  expiryDate: number;
}): Promise<void> => {
  await axios.patch(
    `/v2/vault-admin/${vaultAddress}/update-pricer-settings`,
    {
      configs,
      signature,
      baseInterestRate,
      expiryDate,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );
};

export const liquidateDefaultedPlans = async ({
  plans,
  signature,
  token,
  expiryDate,
}: {
  signature: string;
  token: string;
  plans: { planId: number; estimatedValue: string }[];
  expiryDate: number;
}): Promise<string> => {
  const data = await axios.post(
    `/v2/vault-admin/liquidate-nfts`,
    {
      plans,
      signature,
      expiryDate,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );
  return data.data;
};

export const getVaultLiquidatedNfts = async ({
  contractAddress,
  offset,
  statuses,
  token,
}: {
  contractAddress: string;
  offset: number;
  statuses: ILiquidatedNftStatus[];
  token: string;
}): Promise<{ liquidatedNfts: IVaultLiquidatedNft[]; totalCount: number }> => {
  const data = await axios.get(`/v2/vault-admin/${contractAddress}/liquidated-nfts`, {
    params: { offset, statuses },
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return data.data;
};

export const returnVaultFunds = async ({
  liquidatedNfts,
  signature,
  token,
  expiryDate,
}: {
  signature: string;
  token: string;
  liquidatedNfts: {
    id: number;
    soldPrice: number;
    txnHash?: string;
  }[];
  expiryDate: number;
}): Promise<string> => {
  const data = await axios.post(
    `/v2/vault-admin/return-vault-funds`,
    {
      liquidatedNfts,
      signature,
      expiryDate,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );
  return data.data;
};
