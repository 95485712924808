import { BigNumber } from "ethers";
import orderBy from "lodash.orderby";
import { useMemo, useRef, useState } from "react";
import { useAsyncAbortable } from "react-async-hook";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled, { useTheme } from "styled-components";

import { Box, CopyButton, Flex, Hidden, Option, Select, SkeletonLine, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { ICollectionBe } from "@/apis/collection/types";
import { ICollectionPricer, priceCollectionBasedOnFloorV2 } from "@/apis/pricer/pricer-collection-based";
import { getApr, getInterestRate, getPricerCalculator } from "@/components/PlanCreation/utils";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { _getPossibilityMap } from "@/hooks/usePlanCreationForm";
import { IAutoRepayStatuses } from "@/types";
import { formatCompactNumber, getDurationLabel } from "@/utils";

enum PercentType {
  apr,
  interestRate,
}

const CHART_LINE_COLORS = ["#EF5772", "#7C7CF5", "#FFF", "#FF0", "#00FFA3"];

export const CollectionLoanOptions = ({ collection }: { collection: ICollectionBe }) => {
  const { chainId, account } = useWeb3React();
  const theme = useTheme();
  const [percentType, setPercentType] = useState(PercentType.apr);
  const tableRef = useRef<HTMLTableElement>(null);
  const copyToClipboard = () => {
    if (tableRef.current) {
      const rows = tableRef.current.getElementsByTagName("tr");
      const maxLengths = Array.from(rows[0].children).map(() => 0);
      Array.from(rows, row => {
        Array.from(row.children).forEach((cell, index) => {
          maxLengths[index] = Math.max(maxLengths[index], cell.textContent?.length || 0);
        });
      });
      const spaceBetweenColumns = 8;
      const data =
        `${collection.name}\n\n` +
        Array.from(rows, row =>
          Array.from(row.children, (cell, index) =>
            (cell.textContent || "").padEnd(maxLengths[index] + spaceBetweenColumns, " "),
          ).join(""),
        ).join("\n");
      if ("clipboard" in navigator) {
        navigator.clipboard.writeText(data);
      } else {
        document.execCommand("copy", true, data);
      }
    }
  };

  const { result, loading } = useAsyncAbortable<ICollectionPricer | null>(
    async abortSignal => {
      const result = await priceCollectionBasedOnFloorV2({
        collectionAddress: collection.address,
        chainId,
        wallet: account,
        abortSignal,
      });
      if (!result || !result?.bnplConfig?.length) return null;
      return result;
    },
    [collection.address, chainId],
  );
  const [focusLine, setFocusLine] = useState<string | null>(null);
  const [hoveredCol, setHoveredCol] = useState<number | null>(null);

  const options = useMemo(() => {
    if (result) {
      const getPricingMethod = getPricerCalculator("bnpl", {
        config: result.bnplConfig,
        baseInterestRate: result.baseInterestRate,
        totalPrice: BigNumber.from(result.appraisalPrice),
      });
      const possibilities = _getPossibilityMap("bnpl", result.bnplConfig);
      const durations = Object.keys(possibilities.durations).map(i => Number(i));
      const loanRates = orderBy(
        Object.keys(possibilities.loanRates).map(loanRate => ({
          value: Number(loanRate),
          label: Number(loanRate) === 3400 ? "33%" : `${Number(loanRate) / 100}%`,
        })),
        "label",
      );

      const ltvWithInterestRates = loanRates.map(loanRate => {
        const rates = durations.reduce<{
          [key: number]: {
            apr: number;
            interestRate: number;
          };
        }>((acc, duration) => {
          if (possibilities.durations[duration].has(loanRate.value)) {
            const choice = (result.bnplConfig ?? []).find(([term, totalNumberOfPayments]) => {
              return term * (totalNumberOfPayments - 1) === duration;
            });
            if (!choice) return acc;
            const [term, totalNumberOfPayments] = choice;
            const pricerMethod = getPricingMethod({
              term,
              totalNumberOfPayments,
              loanRate: loanRate.value,
              autoRepayStatus: IAutoRepayStatuses.Disabled,
            });
            if (!pricerMethod) return acc;
            const { loaningAmount, totalInterestFee, totalServiceFee } = pricerMethod;
            const _interestRate = getInterestRate({
              decimals: 18,
              payAmount: loaningAmount.add(totalInterestFee).add(totalServiceFee),
              principleAmount: loaningAmount,
            });
            return {
              ...acc,
              [duration]: {
                interestRate: _interestRate / 100,
                apr: getApr({
                  maturity: term * (totalNumberOfPayments - 1),
                  interestRate: _interestRate,
                }),
              },
            };
          }
          return acc;
        }, {});
        return {
          ...loanRate,
          rates,
        };
      });
      return {
        durations,
        possibilities,
        loanRates,
        ltvWithInterestRates,
      };
    }
  }, [result]);

  const chartData = useMemo(() => {
    if (options) {
      return options.durations.reduce<{ [key: string]: number | string }[]>((acc, duration) => {
        const _rates = options.ltvWithInterestRates.reduce<{ [key: string]: number }>((rates, rate) => {
          const interestRate = rate.rates[duration];
          if (options.possibilities.durations[duration].has(rate.value) && interestRate) {
            return {
              ...rates,
              [rate.label]: percentType === PercentType.apr ? interestRate.apr : interestRate.interestRate,
            };
          }
          return rates;
        }, {});

        return [
          ...acc,
          {
            term: getDurationLabel(duration),
            ..._rates,
          },
        ];
      }, []);
    }
    return [{ term: 0 }];
  }, [options, percentType]);

  return (
    <Flex direction="column" gap="4rem">
      <SelectBox>
        <Text color="secondary" weight="600" size="xl">{`NFT loan interest table (${
          percentType === PercentType.apr ? "APR" : "Interest Rate"
        }%)`}</Text>
        <Box h="46px" w="220px">
          <Select
            onChange={value => {
              setPercentType(value);
            }}
            value={percentType}
          >
            <Option value={PercentType.apr}>APR (Ann. rates)</Option>
            <Option value={PercentType.interestRate}>Interest Rate </Option>
          </Select>
        </Box>
      </SelectBox>

      <Flex direction="column" gap="2rem" w="100%">
        <Flex alignItems="center" gap="10px" justifyContent="center">
          <Text size="lg" weight="600" color="secondary">
            Duration of Loan
          </Text>
          <CopyButton onClick={copyToClipboard} value="table">
            <Text size="xxs" weight="400" color="secondary" onClick={copyToClipboard}>
              Copy table
            </Text>
          </CopyButton>
        </Flex>
        <Flex alignItems="center" w="100%" gap="1.5rem">
          <Flex
            style={{
              transform: "rotate(-90deg)",
            }}
            w="24px"
          >
            <Text color="secondary" size="lg">
              LTV
            </Text>
          </Flex>
          <Flex
            w="100%"
            style={{
              overflowX: "auto",
            }}
          >
            {loading && <SkeletonLine h="200px" w="100%" />}
            {options && (
              <StyledTable onMouseLeave={() => setHoveredCol(null)} ref={tableRef}>
                <colgroup>
                  <col />
                  {options.durations.map((d, i) => (
                    <col
                      key={d}
                      style={{
                        background: hoveredCol === i ? theme.colors.gray10 : "transparent",
                      }}
                    />
                  ))}
                </colgroup>
                <thead>
                  <StyledTr>
                    <StyledTh>
                      <Text style={{ visibility: "hidden" }}>LTV</Text>
                    </StyledTh>
                    {options.durations.map(duration => (
                      <StyledTh key={duration}>{getDurationLabel(Number(duration))}</StyledTh>
                    ))}
                  </StyledTr>
                </thead>
                <tbody>
                  {options.ltvWithInterestRates.map(loanRate => {
                    return (
                      <StyledTr key={loanRate.value}>
                        <StyledTd>{loanRate.label} </StyledTd>
                        {options.durations.map((duration, j) => {
                          return (
                            <StyledTd key={duration} onMouseEnter={() => hoveredCol !== j && setHoveredCol(j)}>
                              {loanRate.rates[duration]
                                ? `${
                                    percentType === PercentType.apr
                                      ? loanRate.rates[duration].apr.toFixed(2)
                                      : loanRate.rates[duration].interestRate.toFixed(2)
                                  }%`
                                : "-"}
                            </StyledTd>
                          );
                        })}
                      </StyledTr>
                    );
                  })}
                </tbody>
              </StyledTable>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="column" gap="2rem" w="100%">
        <Text color="secondary" weight="600" size="xl">{`NFT loan interest curve (${
          percentType === PercentType.apr ? "APR" : "Interest Rate"
        }%)`}</Text>
        <Flex alignItems="center" w="100%" gap="2rem">
          <Hidden laptopSDown>
            <Flex
              style={{
                transform: "rotate(-90deg)",
              }}
              w="24px"
            >
              <Text color="secondary" size="lg" textWrap={false}>
                {percentType === PercentType.apr ? "APR" : "Interest Rate"}
              </Text>
            </Flex>
          </Hidden>
          <Flex direction="column" gap="2rem" w="100%">
            <ChartContainer>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={chartData}
                  margin={{
                    bottom: 20,
                  }}
                  {...{
                    overflow: "visible",
                  }}
                >
                  <Legend
                    align="right"
                    verticalAlign="top"
                    iconType="circle"
                    iconSize={0}
                    formatter={(value, entry) => {
                      const { color } = entry;
                      return (
                        <Flex
                          alignItems="center"
                          gap="7px"
                          ml="2rem"
                          onMouseEnter={() => focusLine !== value && setFocusLine(value)}
                          onMouseLeave={() => setFocusLine(null)}
                          style={{
                            cursor: "pointer",
                            transition: "opacity 0.2s",
                            opacity: focusLine !== null && focusLine !== value ? "0.5" : "1",
                          }}
                        >
                          <LegendCircle bg={color} />
                          <Text size="lg" style={{ color: color ?? theme.colors.white }} weight="600">
                            {`${value} LTV`}
                          </Text>
                        </Flex>
                      );
                    }}
                  />
                  <XAxis
                    style={{
                      fontSize: "18px",
                      fontFamily: "Inter",
                      fontWeight: 600,
                    }}
                    dy={20}
                    dx={20}
                    dataKey={"term"}
                    tickLine={false}
                    allowDataOverflow
                  />
                  <YAxis
                    tickLine={false}
                    strokeWidth={0}
                    dx={-20}
                    dy={-5}
                    style={{
                      fontSize: "18px",
                      fontFamily: "Inter",
                      fontWeight: 600,
                    }}
                    tickFormatter={value => `${formatCompactNumber(value)}%`}
                    interval={0}
                    tickCount={5}
                  />
                  <Tooltip
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        return (
                          <TooltipBox p="8px 7px" direction="column" gap="0.3rem">
                            {payload
                              .sort((a, b) => Number(b?.value ?? 0) - Number(a?.value ?? 0))
                              .map((item, i) => (
                                <Flex gap="10px" key={i} alignItems="center">
                                  <TooltipCircle bg={item.color} />
                                  <Text
                                    weight="500"
                                    size="sm"
                                    style={{
                                      color: item.color,
                                    }}
                                  >
                                    {`${(Number(item.value) ?? 0).toFixed(2)}% ${
                                      percentType === PercentType.apr ? "APR" : "IR"
                                    }`}
                                  </Text>
                                </Flex>
                              ))}
                          </TooltipBox>
                        );
                      }

                      return null;
                    }}
                  />
                  {options?.ltvWithInterestRates.map(({ label, value }, i) => (
                    <Line
                      type="linear"
                      dataKey={label}
                      stroke={CHART_LINE_COLORS[i] ?? "cyan"}
                      key={value}
                      connectNulls
                      dot={false}
                      strokeWidth={5}
                      opacity={focusLine !== null && focusLine !== label ? "0.3" : "1"}
                      style={{ transition: "opacity 0.2s", cursor: "pointer" }}
                      onMouseEnter={() => focusLine !== label && setFocusLine(label)}
                      onMouseLeave={() => setFocusLine(null)}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </ChartContainer>
            <Flex justifyContent="center" w="100%">
              <Hidden tabletDown>
                <Text weight="600" color="secondary" size="lg" textAlign="center">
                  Duration of Loan
                </Text>
              </Hidden>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const SelectBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 1.4rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledTable = styled.table`
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-collapse: collapse;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
  tr:nth-child(1) {
    td {
      padding-top: 1rem;
    }
  }
  thead {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  }
`;

export const StyledTh = styled.th`
  font-weight: 500;
  padding: 0.5rem 0.5rem 0.5rem;
  text-align: center;
  &:first-of-type {
    border-right: 2px solid ${({ theme }) => theme.colors.secondary};
    text-align: start;
  }
`;

export const StyledTr = styled.tr`
  &:hover {
    background: ${({ theme }) => theme.colors.gray10};
  }
`;

export const StyledTd = styled.td`
  position: relative;
  cursor: default;
  text-align: center;
  padding: 0.5rem;
  &:first-of-type {
    border-right: 2px solid ${({ theme }) => theme.colors.secondary};
    text-align: start;
    &:hover {
      background-color: transparent;
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray20};
  }
`;

const ChartContainer = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 500px;
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 300 }])}
`;
const LegendCircle = styled.div<{ bg?: string }>`
  background: ${({ bg, theme }) => bg ?? theme.colors.cyan};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const TooltipCircle = styled.div<{ bg?: string }>`
  background: ${({ bg, theme }) => bg ?? theme.colors.cyan};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const TooltipBox = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-right: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: 10px;
  min-width: 80px;
  ${getStyleWithMediaQuery("min-width", "", [{ [breakpoints.mobile]: "auto" }])}
`;
