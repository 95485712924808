import { BigNumber } from "ethers";

import { SupportedChainId } from "@/constants/chains";
import { INftBe, INftType } from "@/types";

import { axios } from "./axios";
import { ICollectionBe } from "./collection/types";

export const createPrivateSale = async (
  args: FnCreatePrivateSale["params"],
): Promise<FnCreatePrivateSale["result"]> => {
  const { item, wallet, chainId, signature } = args;
  const { data } = await axios.post<FnCreatePrivateSale["result"]>("/v2/private-sales/create", {
    chainId,
    item,
    wallet,
    signature,
  });
  return data;
};

type FnCreatePrivateSale = {
  result: IPrivateSale;
  params: {
    wallet: string;
    signature: string;
    chainId: number;
    item: {
      buyerAddress: string;
      signedDate: number;
      expiryDate: number;
      price: string;
      currencyAddress: string;
      tokenId: string;
      tokenAmount: number;
      tokenType: INftType;
      collectionAddress: string;
    };
  };
};

export type IPrivateSale = {
  id: number;
  status: number;
  signature: string;
  collectionSignature: string;
  sellerAddress: string;
  buyerAddress: string;
  signedDate: Date;
  expiryDate: Date;
  price: string;
  currencyAddress: string;
  tokenId: string;
  tokenAmount: number;
  tokenType: INftType;
  collectionAddress: string;
};

export const fetchPrivateSalesWithNft = async (
  args: FnFetchPrivateSales["params"],
): Promise<FnFetchPrivateSales["result"]> => {
  const { abortSignal, ...reqData } = args;
  const { data } = await axios.post<FnFetchPrivateSales["result"]>(`/v2/private-sales/list`, reqData, {
    signal: abortSignal,
  });
  const result = data.map(asset => {
    const item = {
      ...asset,
      price: BigNumber.from(asset.price),
    };
    if (item.privateSale) {
      item.privateSale.expiryDate = new Date(item.privateSale.expiryDate);
      item.privateSale.signedDate = new Date(item.privateSale.signedDate);
    }
    return item;
  });
  return result;
};

type FnFetchPrivateSales = {
  result: Array<INftBe>;
  params: {
    chainId: number;
    abortSignal?: AbortSignal;
    collectionAddress?: string;
    buyerAddresses?: string[];
    sellerAddresses?: string[];
    tokenId?: string;
    noDuplicates?: boolean;
  };
};

export const fetchBargainCollectionStat: FnFetcBargainhCollections = async (chainId, abortSignal) => {
  const params = chainId ? { chainId } : {};
  return (
    await axios.get(`/v2/private-sales/bargain-stat`, {
      params,
      signal: abortSignal,
    })
  ).data;
};

type FnFetcBargainhCollections = (chain?: SupportedChainId, abortSignal?: AbortSignal) => Promise<ICollectionBe>;
