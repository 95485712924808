import dayjs from "dayjs";
import { useContext, useMemo } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, Progress } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IP2PUserCreatedOffer } from "@/apis/p2p/types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { useSortableHeader } from "@/hooks/useSortableHeader";
import { bigNumToFloat, displayInUSD, numberWithCommas, shortenName } from "@/utils";

import { useAppContext } from "../../../AppContextProvider";
import { AccountDataContext } from "../../AccountDataContext";
import { HEADER_ROW_MIN_HEIGHT } from "../../consts";
import { Refresh } from "../WalletView/Refresh";
import { HeaderContainer, PayButton, RowText, StyledImg } from "./PlanListRow";

export const LoanOfferHeader = ({
  totalItems,
  sortedOffers,
  updateSortedOffers,
}: {
  totalItems: number;
  sortedOffers: IP2PUserCreatedOffer[];
  updateSortedOffers: (offers: IP2PUserCreatedOffer[]) => void;
}) => {
  const { fetchUserCreatedLoanBids } = useContext(AccountDataContext);
  const refresh = async () => {
    await fetchUserCreatedLoanBids();
  };
  const { sort } = useSortableHeader<IP2PUserCreatedOffer>(
    [offer => offer.signatureUsageLimit - offer.signatureAvailableUsage],
    "progress",
  );

  const handleHeaderClick = (value: ((item: IP2PUserCreatedOffer) => any)[]) => {
    const sortedData = sort(value, sortedOffers);
    updateSortedOffers(sortedData);
  };
  return (
    <HeaderContainer>
      <Flex gap="1rem" alignItems="center">
        <RowText weight="600" color="secondary">{`${totalItems} item${totalItems > 1 ? "s" : ""}`}</RowText>
        <Hidden tabletDown>
          <Refresh onClick={refresh} />
        </Hidden>
      </Flex>{" "}
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([offer => offer.signatureUsageLimit - offer.signatureAvailableUsage])}
        >{`Progress`}</RowText>
      </Hidden>
      <RowText
        weight="600"
        color="secondary"
        onClick={() => handleHeaderClick([offer => offer.amount])}
      >{`Loan Amount`}</RowText>
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([offer => offer.term])}
        >{`Duration`}</RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([offer => offer.interestRate])}
        >{`Interest`}</RowText>
      </Hidden>
      <RowText
        weight="600"
        color="secondary"
        onClick={() => handleHeaderClick([offer => dayjs(offer.signatureExpiry).diff(dayjs(), "second")])}
      >{`Expiry`}</RowText>
      <Hidden tabletDown>
        <Flex h={HEADER_ROW_MIN_HEIGHT}>
          <RowText weight="600" color="secondary">{`Edit`}</RowText>
        </Flex>
      </Hidden>
    </HeaderContainer>
  );
};

export const LoanOfferRow = ({
  offer,
  onClick,
  loading,
}: {
  offer: IP2PUserCreatedOffer;
  onClick: () => void;
  loading: boolean;
}) => {
  const { usdPrice } = useAppContext();
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(offer.currency.decimal) || 4;
  }, [offer.currency]);
  const apr = useMemo(() => {
    return (365 / (offer.term / 60 / 60 / 24)) * (offer.interestRate / 100);
  }, [offer]);
  const expiry = useMemo(() => {
    const dueDate = dayjs(offer.signatureExpiry);
    const difference = dueDate.diff(dayjs(), "second");
    return getPaymentInterval(difference);
  }, [offer.signatureExpiry]);
  return (
    <Row onClick={() => !loading && onClick()}>
      {offer.tokenId ? (
        <Flex alignItems="center" gap="8px">
          <StyledImg src={offer.metadata ? offer.metadata.imageUrl : NoImage} />
          <Flex direction="column">
            <RowText color={"secondary"}> #{shortenName(offer.tokenId, 10, 6)}</RowText>
            <RowText color={"gray0"} sub>
              {" "}
              {shortenName(offer.collection.name)}
            </RowText>
          </Flex>
        </Flex>
      ) : (
        <Flex alignItems="center" gap="8px">
          <StyledImg src={NoImage} alt={"no-image"} />
          <Flex direction="column">
            <RowText color={"secondary"}>{`Collection Bid ${`(${offer.signatureUsageLimit} NFT${
              offer.signatureUsageLimit > 1 ? "s" : ""
            })`}`}</RowText>
            <RowText color={"gray0"} sub>
              {" "}
              {shortenName(offer.collection.name)}
            </RowText>
          </Flex>
        </Flex>
      )}
      <Hidden tabletDown>
        <Flex direction="column" gap="5px">
          <div
            style={{
              width: "70%",
            }}
          >
            <Progress
              steps={offer.signatureUsageLimit}
              current={offer.signatureUsageLimit - offer.signatureAvailableUsage}
            />
          </div>
          <RowText weight="500" color="gray0" sub>{`${offer.signatureUsageLimit - offer.signatureAvailableUsage} of ${
            offer.signatureUsageLimit
          } offers taken`}</RowText>
        </Flex>
      </Hidden>
      <Flex direction="column" justifyContent="center" gap="0.2rem">
        <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
          {numberWithCommas(bigNumToFloat(offer.amount, offer.currency.decimal), formatNumber)} {offer.currency.symbol}
        </RowText>
        <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
          {displayInUSD(bigNumToFloat(offer.amount, offer.currency.decimal) * usdPrice[offer.currency.symbol])}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="0.2rem">
          <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
            {getPaymentInterval(offer.term)}
          </RowText>
          <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
            {dayjs().add(offer.term, "seconds").format("DD MMMM, YYYY")}
          </RowText>
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="0.2rem">
          <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
            {offer.interestRate / 100}%
          </RowText>
          <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
            {`APR: ${apr.toFixed(2)}%`}
          </RowText>
        </Flex>
      </Hidden>

      <RowText weight="500" color="secondary">
        {expiry} left
      </RowText>
      <Hidden tabletDown>
        <Flex w="fit-content">
          <PayButton
            onClick={e => {
              e.stopPropagation();
              onClick();
            }}
            size="xs"
          >
            {`Cancel offer`}
          </PayButton>
        </Flex>
      </Hidden>
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  grid-template-columns: 1.7fr 1.4fr 1.3fr 1.3fr 1fr 1fr 0.8fr;
  padding: 13px 15px 13px 15px;
  background-color: transparent;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2.5fr 1.3fr 1.3fr" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
