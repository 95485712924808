import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { notifyApeVaultBalanceError } from "@/apis/notification";
import { IResponseData, createApeCoinAcceptance, priceApeCoin } from "@/apis/pricer/pricer-ape-coin";
import { CloseButton } from "@/components/ApeCoinStaking/CommonComponents";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import {
  ADDRESSES_MAPPED_BY_POOL_ID,
  POOL_IDS_MAPPED_BY_ADDRESS,
  PoolId,
  apeCoinContract,
  apePaymentPlanContract,
  apeVaultContract,
} from "@/config";
import { createBatchTxn, signNewPlanSignature, useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IInitiateApeBulkStake } from "@/hooks/useApePlanCreation.types";
import { useApproval } from "@/hooks/useApproval";
import { ITransaction } from "@/hooks/useTransactions";
import { INftType } from "@/types";
import { getChainExplorerURL } from "@/utils";
import { getVaultBalance } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";
import { BulkStakingProgressLoader } from "../../common";

export const BulkStakingWithApePlan = (params: IInitiateApeBulkStake) => {
  const { selectedMainNfts, rewardStakeToCyanVault } = params;
  const { setFireConfetti } = useAppContext();
  const { giveNftPermission } = useApproval();
  const { transactions, setTransactions } = useTransactionContext();
  const { cyanWallet, createNewWallet } = useCyanWalletContext();
  const { updateUserBalance } = useApeCoinStatsContext();
  const { showApeBulkPlanModal } = useApePlanCreation();
  const { chainId, account, signer, provider } = useWeb3React();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [pricedResults, setPricedResults] = useState<IResponseData[]>();
  const [isWalletCreated, setIsWalletCreated] = useState(false);
  const [selectedStep, setSelectedStep] = useState<StakingSteps>(
    cyanWallet ? StakingSteps.Sign : StakingSteps.CreatingCyanWallet,
  );
  useEffect(() => {
    if (!activeTx) return;

    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        updateUserBalance();
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setFireConfetti(true);
        setSelectedStep(StakingSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const signPlan = async () => {
    if (!signer) return;

    const signerAddress = await signer.getAddress();
    const result = await priceApeCoin({
      items: selectedMainNfts.map(nft => ({
        poolId: POOL_IDS_MAPPED_BY_ADDRESS[nft.address.toLowerCase()],
        tokenId: nft.tokenId,
        amount: nft.borrowingAmount,
      })),
      wallet: signerAddress,
      rewardStakeToCyanVault,
    });
    const signature = await signNewPlanSignature(signer, result);
    await createApeCoinAcceptance({
      planIds: result.map(({ planId }) => planId),
      signature,
    });
    setPricedResults(result);
  };

  const buildCreatePlanTxn = (plan: IResponseData) => {
    const iface = f.CyanApeCoinPlanV1Factory.createInterface();
    if (plan.poolId === PoolId.BAYC) {
      const data = iface.encodeFunctionData("createBaycPlan", [
        plan.planId,
        plan.tokenId,
        plan.amount,
        plan.rewardStakeToCyanVault,
        plan.signedBlockNumber,
        plan.signature,
      ]);
      return { to: apePaymentPlanContract, data, value: "0" };
    }
    const data = iface.encodeFunctionData("createMaycPlan", [
      plan.planId,
      plan.tokenId,
      plan.amount,
      plan.rewardStakeToCyanVault,
      plan.signedBlockNumber,
      plan.signature,
    ]);
    return { to: apePaymentPlanContract, data, value: "0" };
  };

  const createApePlan = async () => {
    if (!signer || !pricedResults || !cyanWallet) return;

    const transactions = pricedResults.map(plan => buildCreatePlanTxn(plan));
    const txRequest = createBatchTxn(cyanWallet, transactions);
    const tx = await signer.sendTransaction(txRequest);
    const newTxns: ITransaction[] = pricedResults.map(pricedResult => ({
      hash: tx.hash,
      type: "ape-plan-create",
      expiresAt: Date.now() + 1000 * 60 * 5, // 5 minutes
      data: {
        planId: pricedResult.planId,
        tokenId: pricedResult.tokenId,
        contractAddress: ADDRESSES_MAPPED_BY_POOL_ID[pricedResult.poolId],
      },
    }));
    setTransactions(oldTxns => [...oldTxns, ...newTxns]);
    setActiveTx(tx.hash);
  };

  const transfer = async () => {
    if (!signer || !account || !cyanWallet) return;

    const sampleErc20 = f.SampleERC20TokenFactory.connect(apeCoinContract, signer);
    const total = selectedMainNfts.reduce((acc, cur) => acc.add(cur.userStakingAmount), BigNumber.from(0));
    const cyanWalletBalance = await sampleErc20.balanceOf(cyanWallet.walletAddress);

    if (cyanWalletBalance.gt(total)) return;

    const mainWalletBalance = await sampleErc20.balanceOf(account);
    const requiredAmount = total.sub(cyanWalletBalance);
    if (requiredAmount.gt(0) && mainWalletBalance.lt(requiredAmount)) {
      openModal({
        title: `Not enough funds`,
        msg: `Your payment didn’t go through due to lack of funds`,
        description: ` Please double check the amount of crypto in your main wallet. In order to facilitate payments for non-native currency plans, it is imperative that the payment amount be drawn from the Cyan wallet.`,
      });
    } else if (requiredAmount.gt(0)) {
      const txn = await sampleErc20.transfer(cyanWallet.walletAddress, requiredAmount);
      await txn.wait();
    }
  };

  useEffect(() => {
    const onStepChange = async (step: StakingSteps) => {
      if (!chainId || !account || !signer || !provider) return;
      try {
        switch (step) {
          case StakingSteps.CreatingCyanWallet: {
            if (!cyanWallet) {
              await createNewWallet(signer);
              setIsWalletCreated(true);
            }
            setSelectedStep(StakingSteps.Sign);
            return;
          }
          case StakingSteps.Sign: {
            await signPlan();
            setSelectedStep(StakingSteps.TranferApe);
            return;
          }
          case StakingSteps.TranferApe: {
            await transfer();
            setSelectedStep(StakingSteps.TokenApproval);
            return;
          }
          case StakingSteps.TokenApproval: {
            if (!cyanWallet) return;
            for (const item of selectedMainNfts) {
              if (!item.isCyanWallet) {
                await giveNftPermission(
                  {
                    collectionAddress: item.address,
                    tokenId: item.tokenId,
                    itemType: INftType.ERC721,
                  },
                  apePaymentPlanContract,
                );
              }
            }
            setSelectedStep(StakingSteps.Staking);
            return;
          }
          case StakingSteps.Staking: {
            await createApePlan();
            return;
          }
        }
      } catch (err: any) {
        if (err?.error?.message?.match(/Not enough \w+ in the Vault/g)) {
          const _sendVaultError = async () => {
            const vaultBalance = await getVaultBalance({ vaultAddress: apeVaultContract, provider });
            const totalBorrow = selectedMainNfts.reduce((acc, cur) => acc.add(cur.borrowingAmount), BigNumber.from(0));
            await notifyApeVaultBalanceError({
              requestedAmount: totalBorrow.toString(),
              vault: apeVaultContract,
              user: account,
              vaultBalance: vaultBalance.toString(),
            });
          };
          _sendVaultError().catch(e => console.error(e));
        }
        openModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    showApeBulkPlanModal({
      ...params,
      err: mappedError,
    });
  };
  const stepMarkFiltered = useMemo(() => {
    return StakingStepMarks.filter(item => {
      if (cyanWallet && item.value === StakingSteps.CreatingCyanWallet && !isWalletCreated) {
        return false;
      }
      return true;
    }).map(item => {
      if (item.value === StakingSteps.Staking && txnFinal !== null) {
        return {
          ...item,
          description: `View on Etherscan`,
        };
      }
      return item;
    });
  }, [cyanWallet, isWalletCreated, txnFinal]);
  return (
    <Flex gap="1rem" direction="column">
      <BulkStakingProgressLoader selectedNfts={selectedMainNfts} isLoading={selectedStep != StakingSteps.Done} />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep == StakingSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum StakingSteps {
  CreatingCyanWallet = 1,
  Sign = 2,
  TranferApe = 3,
  TokenApproval = 4,
  Staking = 5,
  Done = 6,
}

const StakingStepMarks = [
  {
    value: StakingSteps.CreatingCyanWallet,
    description: `A new wallet is created on the first loan on Cyan`,
    title: `Creating Cyan Wallet`,
  },
  {
    value: StakingSteps.Sign,
    title: `User Accepts Terms & Conditions`,
    description: `Approve this in your wallet, no gas required`,
  },
  {
    value: StakingSteps.TranferApe,
    title: `Transfer $APE to Cyan Wallet`,
    description: `A small amount of gas is required to move $APE`,
  },
  {
    value: StakingSteps.TokenApproval,
    title: `NFT Approval`,
    description: `This provides Cyan with permission to move your NFT`,
  },
  {
    value: StakingSteps.Staking,
    title: `Staking $APE`,
    description: `A small amount of gas is required to stake $APE`,
  },
];
