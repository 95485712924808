import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Flex, TitleContainer, TitleDescription } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { DAPP_URL, TESTDRIVE_URL } from "@/config";
import { IsInTestDrive, jumpToLink } from "@/utils";

import { ButtonText, HomeTitleWebkit } from ".";
import BrowserExtension from "../../assets/images/browser-extension.svg";

export const MainTitle = () => {
  const navigate = useNavigate();

  return (
    <TopPageContainer direction="column" alignItems="center">
      <TitleContainer alignItems="center" gap="2px">
        <HomeTitleWebkit>{`Buy NFTs and finance them with loans`}</HomeTitleWebkit>
        <TitleDescription style={{ color: "#9C9C9C" }}>Buy, loan and stake with NFTs</TitleDescription>
      </TitleContainer>

      <ButtonWrapper gap="20px">
        <Flex w="100%">
          <Button
            title={`Get started`}
            style={{ padding: "12px", borderRadius: "30px" }}
            onClick={() => {
              if (IsInTestDrive) {
                window.location.href = `${DAPP_URL}`;
              } else {
                navigate("/");
              }
            }}
          >
            <ButtonText>{`Get started`}</ButtonText>
          </Button>
        </Flex>
        <Flex w="100%">
          <Button
            title={`Test Drive`}
            style={{ padding: "12px", borderRadius: "30px", background: "#00C2FF", border: "none" }}
            onClick={() => {
              if (!IsInTestDrive) {
                window.location.href = `${TESTDRIVE_URL}/testdrive`;
              } else {
                navigate("/testdrive");
              }
            }}
          >
            <ButtonText>{`Test Drive`}</ButtonText>
          </Button>
        </Flex>
      </ButtonWrapper>

      <Flex alignItems="center" mt="30px">
        <img src={BrowserExtension} alt="Browser Extension" width={20} height={20} style={{ marginRight: "7px" }}></img>
        <Button
          variant="ghost"
          style={{ padding: 0 }}
          onClick={() =>
            jumpToLink("https://chrome.google.com/webstore/detail/cyan-extension/knpcgeeofdmafehffknjfedljlacnbdm")
          }
        >
          <TitleDescription
            size="md"
            style={{ color: "#9C9C9C" }}
          >{`Download the Cyan browser extension`}</TitleDescription>
        </Button>
      </Flex>
    </TopPageContainer>
  );
};

const TopPageContainer = styled(Flex)`
  max-width: 882px;
  text-align: center;
`;

const ButtonWrapper = styled(Flex)`
  width: 80%;
  ${getStyleWithMediaQuery("width", "%", [{ [breakpoints.tablet]: 100 }])};
`;
