import styled from "styled-components";

import { Box } from "./Box";

interface IFlexProps {
  display?: "inline-flex" | "flex";
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around";
  alignContent?: "stretch" | "flex-start" | "flex-end" | "center" | "space-between" | "space-around";
  alignItems?: "stretch" | "baseline" | "center" | "flex-start" | "flex-end";
  flex?: number;
  gap?: string;
}
export const Flex = styled(Box)<IFlexProps>`
  display: ${({ display = "flex" }) => display};
  flex-direction: ${({ direction = "row" }) => direction};
  flex-wrap: ${({ wrap = "nowrap" }) => wrap};
  justify-content: ${({ justifyContent = "flex-start" }) => justifyContent};
  align-content: ${({ alignContent = "stretch" }) => alignContent};
  align-items: ${({ alignItems }) => alignItems};
  flex: ${({ flex }) => flex};
  gap: ${({ gap }) => gap};
`;
