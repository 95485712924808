import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";

import { IActionType } from "../../types";

export const ActionSelector = ({
  selectedAction,
  onChange,
  disabled,
}: {
  selectedAction: IActionType;
  onChange: (a: IActionType) => void;
  disabled?: boolean;
}) => {
  return (
    <PillContainer>
      <PillButton
        active={selectedAction === IActionType.stake}
        onClick={() => onChange(IActionType.stake)}
        disabled={disabled}
      >{`Stake`}</PillButton>
      <PillButton
        active={selectedAction === IActionType.unstake}
        onClick={() => onChange(IActionType.unstake)}
        disabled={disabled}
      >{`Unstake`}</PillButton>
    </PillContainer>
  );
};

const PillContainer = styled(Flex)<{ borderColor?: string }>`
  border-radius: 100px;
  height: 100%;
  padding: 3px;
  background: ${({ theme }) => theme.colors.primary};
  width: 80%;
`;

const PillButton = styled.button<{
  active?: boolean;
  disabled?: boolean;
}>`
  border-radius: 100px;
  background: ${({ theme, active }) => (active ? theme.colors.cyan : theme.colors.primary)};
  color: ${({ theme, active }) => (active ? theme.colors.black : theme.colors.secondary)};
  border: none;
  padding: 3px;
  width: 100%;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 0.2s color;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
