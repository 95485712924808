import { BigNumber } from "ethers";
import { useMemo } from "react";

import { NotFound } from "@cyanco/components/theme/v3";

import { IPawn } from "@/components/Account/pawn.types";
import { useAppContext } from "@/components/AppContextProvider";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { bigNumToFloat, getRarityRank } from "@/utils";

import { useApeStakingUserAssets } from "../../ApeCoinDataContext";
import { RewardsViewType, WalletTypes, useApeCoinContext } from "../../ApeCoinPageContext";
import { IActionType, IApeCoinSource, ISelectedNft } from "../../types";
import { Container, ContainerBox, Header, ListContainer, ListViewBox } from "../common";
import { ApeStakableNftsHeader, StakableNftRow, StakablePositiontRow } from "./StakableNftRow";
import { ApeRewardsHeader, StakedNftRow, StakedPositiontRow } from "./StakedNftRow";

export const ListView = () => {
  const { account } = useWeb3React();
  const { collections, usdPrice } = useAppContext();
  const { showPlanModal } = useApePlanCreation();
  const { selectedRewardsViewType, selectedStakedWalletType, selectedUnstakedWalletType } = useApeCoinContext();
  const { stakedAssets, stakedPositions, stakableAssets, stakablePositions } = useApeStakingUserAssets();
  const openUnstakingModal = (nft: ISelectedNft) => {
    showPlanModal({
      action: IActionType.unstake,
      apeCoinSource: nft.apeStaking.plan ? IApeCoinSource.borrow : IApeCoinSource.owned,
      selectedMainNfts: [nft],
    });
  };
  const openStakingModal = (nft: ISelectedNft) => {
    showPlanModal({
      action: IActionType.stake,
      apeCoinSource: IApeCoinSource.borrow,
      selectedMainNfts: [nft],
    });
  };
  const filteredStakedAssets = useMemo(() => {
    if (selectedStakedWalletType === WalletTypes.mainWallet) {
      return stakedAssets.filter(asset => !asset.isCyanWallet);
    }
    if (selectedStakedWalletType === WalletTypes.cyanWallet) {
      return stakedAssets.filter(asset => asset.isCyanWallet);
    }
    return stakedAssets;
  }, [stakedAssets, selectedStakedWalletType]);
  const filteredStakedPositions = useMemo(() => {
    if (selectedStakedWalletType === WalletTypes.mainWallet) {
      return stakedPositions.filter(asset => !asset.isCyanWallet);
    }
    if (selectedStakedWalletType === WalletTypes.cyanWallet) {
      return stakedPositions.filter(asset => asset.isCyanWallet);
    }
    return stakedPositions;
  }, [stakedPositions, selectedStakedWalletType]);
  const filteredStakableAssets = useMemo(() => {
    if (selectedUnstakedWalletType === WalletTypes.mainWallet) {
      return stakableAssets.filter(asset => !asset.isCyanWallet);
    }
    if (selectedUnstakedWalletType === WalletTypes.cyanWallet) {
      return stakableAssets.filter(asset => asset.isCyanWallet);
    }
    return stakableAssets;
  }, [stakableAssets, selectedUnstakedWalletType]);
  const filteredStakablePositions = useMemo(() => {
    if (selectedUnstakedWalletType === WalletTypes.mainWallet) {
      return stakablePositions.filter(asset => !asset.isCyanWallet);
    }
    if (selectedUnstakedWalletType === WalletTypes.cyanWallet) {
      return stakablePositions.filter(asset => asset.isCyanWallet);
    }
    return stakablePositions;
  }, [stakablePositions, selectedUnstakedWalletType]);
  return (
    <ListContainer>
      <ListViewBox>
        {selectedRewardsViewType === RewardsViewType.staked ? (
          <>
            <Header>
              <ApeRewardsHeader totalItems={[...filteredStakedAssets, ...filteredStakedPositions].length} />
            </Header>
            <ContainerBox>
              {[...filteredStakedAssets, ...filteredStakedPositions].length === 0 && (
                <NotFound msg={`No stakes found`} />
              )}
              <Container>
                {stakedPositions.map(asset => (
                  <StakedPositiontRow
                    key={`${asset.metadata.collectionAddress}:${asset.tokenId}:${asset.isCyanWallet}`}
                    nft={asset}
                    onClick={openUnstakingModal}
                  />
                ))}
                {stakedAssets.map(asset => (
                  <StakedNftRow
                    key={`${asset.address}:${asset.tokenId}:${asset.isCyanWallet}`}
                    nft={asset}
                    onClick={openUnstakingModal}
                    account={account || ""}
                    priceInUSD={usdPrice["APE"]}
                  />
                ))}
              </Container>
            </ContainerBox>
          </>
        ) : (
          <>
            <Header>
              <ApeStakableNftsHeader totalItems={[...filteredStakableAssets, ...filteredStakablePositions].length} />
            </Header>
            <ContainerBox>
              {[...filteredStakablePositions, ...filteredStakableAssets].length === 0 && (
                <NotFound msg={`No NFTs found`} />
              )}
              <Container>
                {filteredStakablePositions.map(asset => (
                  <StakablePositiontRow
                    key={`${asset.metadata.collectionAddress}:${asset.tokenId}:${asset.isCyanWallet}`}
                    nft={{
                      ...asset,
                      priceInUSD:
                        bigNumToFloat(
                          BigNumber.from(
                            asset.planType === "Pawn" ? (asset as IPawn).pawnedAmount : (asset as IBNPL).price,
                          ),
                          asset.currency.decimal,
                        ) * usdPrice[asset.currency.symbol],
                      rarity: getRarityRank(asset.metadata.rarityRank, asset.metadata.collectionAddress, collections),
                    }}
                    onClick={openStakingModal}
                  />
                ))}
                {filteredStakableAssets.map(asset => (
                  <StakableNftRow
                    key={`${asset.address}:${asset.tokenId}:${asset.isCyanWallet}`}
                    nft={{
                      ...asset,
                      priceInUSD:
                        asset.appraisalValue && asset.currency
                          ? bigNumToFloat(asset.appraisalValue, asset.currency.decimal) *
                            usdPrice[asset.currency.symbol]
                          : null,
                      rarity: getRarityRank(asset.rarityRank, asset.address, collections),
                    }}
                    onClick={openStakingModal}
                    account={account || ""}
                  />
                ))}
              </Container>
            </ContainerBox>
          </>
        )}
      </ListViewBox>
    </ListContainer>
  );
};
