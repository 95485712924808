import React from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "../../Flex";
import { Text } from "../core";
import { Copy } from "../icons";

export const CopyButton: React.FC<{ value: string | number; onClick?: () => void }> = ({
  children,
  value,
  onClick,
}) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const theme = useTheme();
  const handleCopyClick = () => {
    setIsCopied(true);
    if (onClick) {
      onClick();
    } else {
      navigator.clipboard.writeText(value.toString());
    }
    setTimeout(() => {
      setIsCopied(false);
    }, 800);
  };
  return (
    <div style={{ position: "relative" }}>
      <StyledButton onClick={handleCopyClick}>
        <Flex alignItems="center" gap="0.2rem">
          {children}
          <Copy height={12} width={12} color={theme.colors.secondary} />
        </Flex>
      </StyledButton>
      {isCopied && (
        <CopiedText size="sm" weight="400" color="secondary">
          Copied!
        </CopiedText>
      )}
    </div>
  );
};

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.transparent};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: ${({ theme }) => theme.borderWidth};
  border-color: ${({ theme }) => theme.colors.secondary};
  border-style: solid;
  padding: 0.2rem 0.4rem;
  height: fit-content;
  cursor: pointer;
`;

const CopiedText = styled(Text)`
  position: absolute;
  top: 150%;
  left: 70%;
  z-index: 10;
`;
