import { BigNumber } from "ethers";

import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { INft } from "@/types";

import { axios, isAxiosError } from "../axios";
import { IPricerConfig } from "./types";

interface IPawnPricerStep1Params {
  wallet?: string;
  chainId: SupportedChainId;
  currentCurrencyAddress: string;
  targetCurrencyAddress: string;
  items: Array<
    INft & {
      isBendDao?: boolean;
      price: BigNumber;
      existingPlan?: {
        planId: number;
        amountToComplete: BigNumber;
      };
      isAutoLiquidated: boolean;
    }
  >;
}
interface IRequestData {
  wallet?: string;
  chain: string;
  currencyAddress: string;
  items: Array<
    INft & {
      isAutoLiquidated: boolean;
    }
  >;
}

interface IRequestDataDeprecated {
  wallet?: string;
  chain: string;
  currencyAddress: string;
  items: Array<INft>;
}

const serializeRequestData = (data: IPawnPricerStep1Params): IRequestData => {
  const chain = CHAIN_IDS_TO_NAMES[data.chainId];
  return {
    chain,
    currencyAddress: data.targetCurrencyAddress,
    wallet: data.wallet,
    items: data.items.map(item => ({
      address: item.address,
      tokenId: item.tokenId,
      itemType: item.itemType,
      amount: item.amount,
      existingPlanId: item?.existingPlan?.planId,
      isAutoLiquidated: item.isAutoLiquidated,
    })),
  };
};

const serializeRequestDataDeprecated = (data: IPawnPricerStep1Params): IRequestDataDeprecated => {
  const chain = CHAIN_IDS_TO_NAMES[data.chainId];
  return {
    chain,
    currencyAddress: data.targetCurrencyAddress,
    wallet: data.wallet,
    items: data.items.map(item => ({
      address: item.address,
      tokenId: item.tokenId,
      itemType: item.itemType,
      amount: item.amount,
      existingPlanId: item?.existingPlan?.planId,
    })),
  };
};

export const pricePawnStep1 = async (args: IPawnPricerStep1Params): Promise<IResult> => {
  const data = serializeRequestData(args);
  try {
    const url = args.items[0].isBendDao ? "/v2/pricer/benddao-migration-step1-v2" : "/v2/pricer/pawn-step1-v2";
    const response = (await axios.post<IResponseDataV2>(url, data)).data;
    return {
      ...response,
      items: response.items.map(item => ({
        baseInterestRate: item.interestRate,
        price: BigNumber.from(item.price),
        config: item.config,
        vaultId: item.vaultId,
      })),
    };
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response && e.response.data) throw new Error(e.response.data.message);
    }
    throw e;
  }
};

export const pricePawnStep1Deprecated = async (args: IPawnPricerStep1Params): Promise<IResult> => {
  const data = serializeRequestDataDeprecated(args);
  try {
    const url = args.items[0].isBendDao ? "/v2/pricer/benddao-migration-step1-v2" : "/v2/pricer/pawn-step1-v2";
    const response = (await axios.post<IResponseDataV2>(url, data)).data;
    return {
      ...response,
      items: response.items.map(item => ({
        baseInterestRate: item.interestRate,
        price: BigNumber.from(item.price),
        config: item.config,
        vaultId: item.vaultId,
      })),
    };
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response && e.response.data) throw new Error(e.response.data.message);
    }
    throw e;
  }
};

type IResponseDataV2 = {
  couponDiscountRate: number;
  items: {
    interestRate: number;
    price: string;
    config: IPricerConfig;
    vaultId: number;
  }[];
  baseConfig: {
    [vaultId: number]: IPricerConfig;
  };
};

export type IResult = {
  items: {
    baseInterestRate: number;
    price: BigNumber;
    config: IPricerConfig;
    vaultId: number;
  }[];
  baseConfig: {
    [vaultId: number]: IPricerConfig;
  };
};
