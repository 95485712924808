import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

import { Button, Card, Flex, Input, SystemMessage, Text, useModal } from "@cyanco/components/theme";

import { IVault, IVaultLatestLoan } from "@/apis/vault/types";
import { useSupportedCollections } from "@/components/AppContextProvider";
import { DueCircle, StepperContainer } from "@/components/PlanPayment/RegularPayment";
import { IPlanStatus } from "@/constants/plans";
import { numberWithCommas, shortenName } from "@/utils";

import { DefaultedNftMetadata } from "./DefaultedLoanModal";
import { DefaultedLoanProgressModal } from "./DefaultedLoanProgressModal";

export const DefaultedLoanModalBulk = ({
  loans,
  vault,
  error,
}: {
  loans: Array<IVaultLatestLoan & { estimatedPrice?: number }>;
  error?: { title: string; msg: string };
  vault: IVault;
}) => {
  const { collections } = useSupportedCollections();
  const [estimatedPrices, setEstimatedPrices] = useState<Array<string>>([]);

  useEffect(() => {
    const prices = loans.map(loan => (loan?.estimatedPrice ?? "").toString());
    setEstimatedPrices(prices);
  }, [loans]);

  const setValue = (index: number, value: string) => {
    const prices = [...estimatedPrices];
    prices[index] = value;
    setEstimatedPrices(prices);
  };

  const { setModalContent } = useModal();
  const openModal = () => {
    setModalContent({
      title: "Claim NFTs",
      content: (
        <DefaultedLoanProgressModal
          loans={loans.map((loan, index) => ({ ...loan, estimatedPrice: parseFloat(estimatedPrices[index] ?? 0) }))}
          vault={vault}
        />
      ),
    });
  };
  const total = estimatedPrices.reduce<number>((acc, cur) => {
    const curParsed = parseFloat(cur);
    return acc + (isNaN(curParsed) ? 0 : curParsed);
  }, 0);
  return (
    <Flex gap="12px" direction="column">
      {loans.map((loan, index) => (
        <Flex key={loan.planId} direction="column" gap="12px">
          <DefaultedNftMetadata
            loan={{
              ...loan,
              floorAsk: collections.find(
                collection => collection.address.toLowerCase() === loan.collectionAddress.toLowerCase(),
              )?.floorAsk,
            }}
            vault={vault}
          />
          <NumericFormat
            value={estimatedPrices[index]}
            onValueChange={value => setValue(index, value.value)}
            placeholder="Enter the value of the NFT"
            customInput={Input}
            decimalScale={4}
            valueIsNumericString
          />
        </Flex>
      ))}
      {error && <SystemMessage variant={"error"} title={error.title} msg={error.msg} />}
      <Card p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Text size="sm" weight="600" color="secondary">
            {`Mark-to-Market Values`}
          </Text>
          <StepperContainer>
            {loans.map((loan, index) => (
              <Flex justifyContent="space-between" alignItems="center" key={loan.planId}>
                <Flex alignItems="center" gap="8px">
                  <DueCircle
                    isMissed={loan.status !== IPlanStatus.Activated}
                    isNextPayment={loan.status === IPlanStatus.Activated}
                    isLast
                  />
                  <Text size="xs" weight="500" color="gray0">
                    {`${shortenName(loan.collectionName, 30)} #${loan.tokenId}`}
                  </Text>
                </Flex>
                <Text size="xs" weight="500" color={"gray0"}>
                  {`${estimatedPrices[index] ?? 0} ${vault.currency}`}
                </Text>
              </Flex>
            ))}
          </StepperContainer>
          <Flex gap="5px" justifyContent="flex-end">
            <Text size="xs" weight="500" color="gray0" textAlign="right">
              {`Total Mark-to-Market Values:`}
            </Text>
            <Text size="xs" weight="500" color="secondary" textAlign="right">
              {`${numberWithCommas(total, 6, true)} ${vault.currency}`}
            </Text>
          </Flex>
        </Flex>
      </Card>
      <Button
        style={{ height: "50px" }}
        disabled={
          estimatedPrices.length !== loans.length ||
          !estimatedPrices.every(item => !isNaN(parseFloat(item)) && parseFloat(item) !== 0)
        }
        onClick={openModal}
      >{`Claim NFTs`}</Button>
    </Flex>
  );
};
