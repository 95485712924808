import { BigNumber } from "ethers";
import { useMemo } from "react";

import { Card, Flex, Text } from "@cyanco/components/theme";

import { IEarlyPaymentInfo } from "@/apis/types";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, numberWithCommas } from "@/utils";

import { useAuthContext } from "../AuthContext/AuthContextProvider";
import { PlanCreationDecimalFormatMap } from "../PlanCreation/types";
import { getInterestRate, getPlanApr } from "../PlanCreation/utils";
import { IPaymentsMapped } from "./PlanRepayment";
import { PaymentStep, StepperContainer } from "./RegularPayment";

type IProps = {
  plan: {
    earlyPayment: IEarlyPaymentInfo;
    payments: IPaymentsMapped[];
    originalLoanAmount: BigNumber;
    currency: ICurrency;
    term: number;
    createdAt: Date;
    currentNumOfPayments: number;
    totalNumOfPayments: number;
    planType: "pawn" | "bnpl";
    interestRate: number;
    totalCost: BigNumber;
  };
};

export const EarlyRepayment: React.FC<IProps> = ({ plan }) => {
  const { user } = useAuthContext();
  const {
    totalCost,
    originalLoanAmount,
    currency,
    earlyPayment,
    payments,
    currentNumOfPayments,
    totalNumOfPayments,
    planType,
    term,
  } = plan;
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(currency.decimal) || 4;
  }, [currency.decimal]);
  const totalPaidAmount = useMemo(() => {
    const amount = payments.reduce((acc, payment) => {
      if (!payment.isRevival) acc = acc.add(payment.amount);
      return acc;
    }, BigNumber.from(0));
    return amount;
  }, [payments]);

  const saveAmount = useMemo(() => {
    const saveAmount = bigNumToFloat(
      totalCost.sub(earlyPayment.remainingPayment.add(totalPaidAmount)),
      currency.decimal,
    );
    return numberWithCommas(saveAmount.toFixed(formatNumber), formatNumber);
  }, [earlyPayment]);

  const interestRateForEarlyRepayment = useMemo(() => {
    const _totalCostWithEarlyPayment = earlyPayment.remainingPayment.add(totalPaidAmount);
    return getInterestRate({
      decimals: plan.currency.decimal,
      payAmount: _totalCostWithEarlyPayment,
      principleAmount: originalLoanAmount,
    });
  }, [earlyPayment.payAmountForInterest, currentNumOfPayments, originalLoanAmount]);

  const apr = useMemo(() => {
    const totalNumOfPaymentsByPlanType = planType == "bnpl" ? totalNumOfPayments - 1 : totalNumOfPayments;
    return getPlanApr({
      term,
      interestRate: interestRateForEarlyRepayment,
      totalNumberOfPayments: totalNumOfPaymentsByPlanType,
    });
  }, [interestRateForEarlyRepayment, plan]);

  return (
    <Card p={"10px 8px"}>
      <Flex direction="column" gap="7px">
        <Text size="sm" weight="600" color="secondary">
          {`Pay off loan today `} {`(save ${saveAmount} ${currency.symbol})`}
        </Text>
        <StepperContainer>
          {payments.map(payment => (
            <PaymentStep
              key={`${payment.txnHash}-${payment.isRevival ? "revival" : "payment"}}`}
              isPaid
              text={payment.isRevival ? `Revival Penalty` : `Already Paid`}
              paymentAmount={payment.amount}
              fixNumber={formatNumber}
              currency={currency}
            />
          ))}
          <PaymentStep
            isLast
            isNextPayment
            text={`Pay now`}
            paymentAmount={earlyPayment.remainingPayment}
            fixNumber={formatNumber}
            currency={currency}
            color="secondary"
          />
        </StepperContainer>
        <Flex justifyContent="space-between">
          <Text size="xs" weight="500" color="gray0">
            {user?.config?.showAPR
              ? `${apr.toFixed(2)}% APR`
              : `${(interestRateForEarlyRepayment / 100).toFixed(2)}% interest`}
          </Text>
          <Text size="xs" weight="500" color="gray0">
            {`Total Cost ${numberWithCommas(
              bigNumToFixedStr(totalPaidAmount.add(earlyPayment.remainingPayment), formatNumber, currency.decimal),
              formatNumber,
            )} ${currency.symbol}`}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
