import { BigNumber } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import orderBy from "lodash.orderby";
import { useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Box, Flex, Option, Select, SkeletonLine, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { ICollectionPricer } from "@/apis/pricer/pricer-collection-based";
import { IPricerMethod } from "@/apis/types";
import { IPoolSettings } from "@/apis/vault/admin/types";
import { IVault, IVaultSupportedProject } from "@/apis/vault/types";
import {
  StyledTable,
  StyledTd,
  StyledTh,
  StyledTr,
} from "@/components/Bnpl/components/LoanPricer/CollectionLoanOptions";
import { getApr, getInterestRate, getPricerCalculator } from "@/components/PlanCreation/utils";
import { _getPossibilityMap } from "@/hooks/usePlanCreationForm";
import { IAutoRepayStatuses } from "@/types";
import { formatCompactNumber, getDurationLabel, shortenName } from "@/utils";

import { MobileFriendlyWrapper, TabButton, TabContainer, TabText } from "../VaultLoans";

enum PercentType {
  apr,
  interestRate,
}
enum PercentView {
  withCyanFee,
  withoutCyanFee,
}

const calculateNewInterestRate = (currentInterestRate: number, currentBaseRate: number, newBaseRate: number) => {
  const currentAPR = (currentInterestRate * 365) / 93;
  const ltvInterestRate = currentAPR - currentBaseRate;
  const newAPR = ltvInterestRate + newBaseRate;
  let newInterestRate = (newAPR * 93) / 365;
  if (newInterestRate > 30000) {
    newInterestRate = 30000;
  }
  return newInterestRate;
};

export const CurvePreview = ({
  pricerSettings,
  baseRateInput,
  baseRateVault,
  vault,
  collection,
  collectionPricer,
  collectionPricerLoading,
  setSelectedCollection,
}: {
  pricerSettings: IPoolSettings[];
  baseRateInput: number;
  baseRateVault: number;
  vault: IVault;
  collection: IVaultSupportedProject | null;
  collectionPricer?: ICollectionPricer;
  collectionPricerLoading: boolean;
  setSelectedCollection: React.Dispatch<React.SetStateAction<IVaultSupportedProject | null>>;
}) => {
  const collections = useMemo(() => {
    return vault.supportedProjects.filter(p => p.isBnplAllowed || p.isPawnAllowed);
  }, [vault.supportedProjects]);
  const theme = useTheme();
  const tableRef = useRef<HTMLTableElement>(null);
  const [percentType, setPercentType] = useState(PercentType.apr);
  const [percentView, setPercentView] = useState(PercentView.withoutCyanFee);
  const [hoveredCol, setHoveredCol] = useState<number | null>(null);

  const getOption = (
    planType: "bnpl" | "pawn",
    config: IPricerMethod[],
    interestRate: number,
    appraisalPrice: string,
  ) => {
    const IR = calculateNewInterestRate(interestRate, baseRateVault, baseRateInput);
    // TODO make this numberOfPaymentsDeducter universal for all over dapp
    const numberOfPaymentsDeducter = planType === "bnpl" ? 1 : 0;
    const filteredConfig: IPricerMethod[] = config.map(
      ([_term, _totalNumberOfPayments, serviceFeeRate, _loanRate, multiplier, divider, adder]) => {
        const newWeight = pricerSettings.find(
          p =>
            _loanRate === p.ltvRatio * 100 &&
            _term === p.maturity / (_totalNumberOfPayments - numberOfPaymentsDeducter) &&
            multiplier !== Number(p.weight.toFixed(3)),
        )?.weight;
        return [
          _term,
          _totalNumberOfPayments,
          serviceFeeRate,
          _loanRate,
          newWeight !== undefined ? newWeight : multiplier,
          divider,
          adder,
        ];
      },
    );
    const getPricingMethod = getPricerCalculator(planType, {
      config: filteredConfig,
      baseInterestRate: IR,
      totalPrice: BigNumber.from(appraisalPrice),
    });
    return filteredConfig.reduce<{
      [key: number]: {
        [key: number]: {
          interestRate: number;
          apr: number;
        };
      };
    }>((acc, cur) => {
      const [term, totalNumberOfPayments, , ltvRatio] = cur;
      const pricerMethod = getPricingMethod({
        term,
        totalNumberOfPayments,
        loanRate: ltvRatio,
        autoRepayStatus: IAutoRepayStatuses.Disabled,
      });
      const pricerSetting = pricerSettings.find(
        p => ltvRatio === p.ltvRatio * 100 && term === p.maturity / (totalNumberOfPayments - numberOfPaymentsDeducter),
      );
      const isBnpl = planType === "bnpl";
      if (
        !pricerMethod ||
        (pricerSetting && ((isBnpl && !pricerSetting.isActiveBnpl) || (!isBnpl && !pricerSetting.isActivePawn)))
      ) {
        return acc;
      }
      const { loaningAmount, totalInterestFee, totalServiceFee } = pricerMethod;
      const totalRepayAmount = loaningAmount.add(totalInterestFee).add(totalServiceFee);
      const _interestRate = getInterestRate({
        payAmount: totalRepayAmount,
        principleAmount: loaningAmount,
        decimals: vault.decimals,
      });
      const maturity = term * (totalNumberOfPayments - numberOfPaymentsDeducter);
      const item = acc[maturity] ?? {};
      return {
        ...acc,
        [maturity]: {
          ...item,
          [ltvRatio]: {
            interestRate: _interestRate / 100,
            apr: getApr({ maturity, interestRate: _interestRate }),
          },
        },
      };
    }, {});
  };
  const loanOptions = useMemo(() => {
    if (collectionPricer?.pawnConfig && collectionPricer?.bnplConfig) {
      // if floor price is 0 for collection, set it to 1 to get ideal IRs
      const appraisalPrice = BigNumber.from(collectionPricer.appraisalPrice).eq(0)
        ? parseUnits("1", vault.decimals).toString()
        : collectionPricer.appraisalPrice;
      const pawnOptions = getOption(
        "pawn",
        collectionPricer.pawnConfig,
        collectionPricer.baseInterestRate,
        appraisalPrice,
      );
      const bnplOptions = getOption(
        "bnpl",
        collectionPricer.bnplConfig,
        collectionPricer.baseInterestRate,
        appraisalPrice,
      );
      return { pawnOptions, bnplOptions };
    }
  }, [collectionPricer, baseRateInput, baseRateVault, pricerSettings]);

  const pricerOptions = useMemo(() => {
    if (!collectionPricer?.pawnConfig || !collectionPricer?.bnplConfig) {
      return {
        loanRates: [],
        durations: [],
      };
    }
    const bnplConfig: IPricerMethod[] = collectionPricer.bnplConfig.map(
      ([paymentTerm, paymentNumbers, serviceFeeRate, ltvRatio, weight, divider, adder]) => [
        paymentTerm,
        paymentNumbers - 1,
        serviceFeeRate,
        ltvRatio,
        weight,
        divider,
        adder,
      ],
    );
    // merging configs for getting combined loan rates and maturities
    const possibilities = _getPossibilityMap("pawn", [...collectionPricer.pawnConfig, ...bnplConfig]);
    const durations = Object.keys(possibilities.durations)
      .map(i => Number(i))
      .sort((a, b) => a - b);
    const loanRates = Object.keys(possibilities.loanRates)
      .map(i => Number(i))
      .sort((a, b) => a - b);
    return { durations, loanRates };
  }, [collectionPricer]);

  const ratesWithoutCyanFee = useMemo(() => {
    if (!collectionPricer) return;
    const IR = calculateNewInterestRate(collectionPricer.baseInterestRate, baseRateVault, baseRateInput);
    return pricerSettings.reduce<{
      [key: number]: {
        [key: number]: {
          interestRate: number;
          apr: number;
        };
      };
    }>((acc, cur) => {
      const item = acc[cur.maturity];
      const interestRate = cur.weight * IR;
      const apr = getApr({ maturity: cur.maturity, interestRate });
      if (!cur.isActiveBnpl && !cur.isActivePawn) return acc;
      const rate = {
        [cur.ltvRatio * 100]: {
          interestRate: interestRate / 100,
          apr,
        },
      };
      return item
        ? {
            ...acc,
            [cur.maturity]: {
              ...item,
              ...rate,
            },
          }
        : {
            ...acc,
            [cur.maturity]: {
              ...rate,
            },
          };
    }, {});
  }, [pricerSettings, collectionPricer, baseRateInput, baseRateVault]);

  const formatPercent = (percent?: number) => {
    if (percent === undefined || isNaN(percent)) return { isValidNumber: false, value: "N/A" };
    if (percent < 0) return { isValidNumber: true, value: "0%" };
    return { isValidNumber: true, value: `${formatCompactNumber(percent, 2)}%` };
  };

  return (
    <Flex direction="column" gap="2.5rem" p="3rem 2rem 2rem 2rem">
      <Flex justifyContent="space-between" w="100%">
        <Flex direction="column" gap="5px">
          <Text color="secondary" style={{ fontSize: "33px" }} weight="600">{`Curve Preview`}</Text>
          <Text color="gray0" size="lg">
            Preview the interest rates borrowers will <br /> see based on the settings above.
          </Text>
        </Flex>
        <SelectBox h="46px">
          <Select
            onChange={value => {
              setSelectedCollection(collections.find(c => c.address.toLowerCase() === value.toLowerCase()) ?? null);
            }}
            value={collection?.address ?? ""}
          >
            {orderBy(collections, [_collection => _collection.name.toLowerCase()]).map(_collection => (
              <Option value={_collection.address} key={_collection.address}>
                {shortenName(_collection.name, 20, 17, 0)}
              </Option>
            ))}
          </Select>
        </SelectBox>
      </Flex>
      <Flex direction="column" gap="10px">
        <Flex direction="column" alignItems="flex-end" gap="10px">
          <MobileFriendlyWrapper justifyContent="space-between" w="100%">
            <TabContainer justifyContent="space-between">
              <TabButton
                active={percentView === PercentView.withoutCyanFee}
                variant="ghost"
                onClick={() => {
                  setPercentView(PercentView.withoutCyanFee);
                }}
              >
                <TabText
                  weight="600"
                  size="sm"
                  color={percentView === PercentView.withoutCyanFee ? "secondary" : "gray0"}
                  textWrap={false}
                >{`Nominal`}</TabText>
              </TabButton>
              <TabButton
                active={percentView === PercentView.withCyanFee}
                variant="ghost"
                onClick={() => {
                  setPercentView(PercentView.withCyanFee);
                }}
              >
                <TabText
                  weight="600"
                  size="sm"
                  color={percentView === PercentView.withCyanFee ? "secondary" : "gray0"}
                  textWrap={false}
                >{`With fees`}</TabText>
              </TabButton>
            </TabContainer>
            <TabContainer justifyContent="space-between">
              <TabButton
                active={percentType === PercentType.interestRate}
                variant="ghost"
                onClick={() => {
                  setPercentType(PercentType.interestRate);
                }}
              >
                <TabText
                  weight="600"
                  size="sm"
                  color={percentType === PercentType.interestRate ? "secondary" : "gray0"}
                  textWrap={false}
                >{`Interest rates`}</TabText>
              </TabButton>
              <TabButton
                active={percentType === PercentType.apr}
                variant="ghost"
                onClick={() => {
                  setPercentType(PercentType.apr);
                }}
              >
                <TabText
                  weight="600"
                  size="sm"
                  color={percentType === PercentType.apr ? "secondary" : "gray0"}
                  textWrap={false}
                >{`APRs`}</TabText>
              </TabButton>
            </TabContainer>
          </MobileFriendlyWrapper>
        </Flex>
        <Text
          color="gray0"
          size="xs"
          textAlign="left"
          style={{ visibility: percentView === PercentView.withCyanFee ? "visible" : "hidden" }}
        >
          Rates include fees. BNPL is higher due to purchase price.
        </Text>
      </Flex>

      <Flex direction="column" gap="0.5rem" style={{ overflowX: "scroll" }}>
        {collectionPricerLoading && <SkeletonLine h="210px" w="100%" />}
        {collectionPricer && !collectionPricerLoading && (
          <Table onMouseLeave={() => setHoveredCol(null)} ref={tableRef}>
            <colgroup>
              <col />
              {pricerOptions.durations.map((d, i) => (
                <col
                  key={d}
                  style={{
                    background: hoveredCol === i ? theme.colors.gray10 : "transparent",
                  }}
                />
              ))}
            </colgroup>
            <thead>
              <StyledTr>
                <StyledTh>LTV</StyledTh>
                {pricerOptions.durations.map(duration => (
                  <StyledTh key={duration}>{getDurationLabel(Number(duration))}</StyledTh>
                ))}
              </StyledTr>
            </thead>
            <tbody>
              {percentView === PercentView.withoutCyanFee &&
                pricerOptions.loanRates.map(loanRate => {
                  return (
                    <StyledTr key={loanRate}>
                      <StyledTd>{loanRate / 100}% </StyledTd>
                      {pricerOptions.durations.map((duration, j) => {
                        const percent =
                          percentType === PercentType.apr
                            ? ratesWithoutCyanFee?.[duration]?.[loanRate]?.apr
                            : ratesWithoutCyanFee?.[duration]?.[loanRate]?.interestRate;
                        return (
                          <StyledTd key={duration} onMouseEnter={() => hoveredCol !== j && setHoveredCol(j)}>
                            <Text color={formatPercent(percent).isValidNumber ? "secondary" : "gray0"} size="sm">
                              {formatPercent(percent).value}
                            </Text>
                          </StyledTd>
                        );
                      })}
                    </StyledTr>
                  );
                })}

              {percentView === PercentView.withCyanFee &&
                loanOptions &&
                pricerOptions.loanRates.map(loanRate => {
                  return (
                    <StyledTr key={loanRate}>
                      <StyledTd>{loanRate / 100}% </StyledTd>
                      {pricerOptions.durations.map((duration, j) => {
                        const percentPawn = formatPercent(
                          percentType === PercentType.apr
                            ? loanOptions.pawnOptions?.[duration]?.[loanRate]?.apr
                            : loanOptions.pawnOptions?.[duration]?.[loanRate]?.interestRate,
                        );
                        const percentBnpl = formatPercent(
                          percentType === PercentType.apr
                            ? loanOptions.bnplOptions?.[duration]?.[loanRate]?.apr
                            : loanOptions.bnplOptions?.[duration]?.[loanRate]?.interestRate,
                        );
                        return (
                          <StyledTd
                            key={`${duration}-${loanRate}`}
                            onMouseEnter={() => hoveredCol !== j && setHoveredCol(j)}
                          >
                            <Box
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 0.2fr 1fr",
                              }}
                            >
                              <Text color={percentPawn.isValidNumber ? "secondary" : "gray0"} size="sm">
                                {percentBnpl.value}
                              </Text>
                              <Text color="secondary" size="sm">
                                |
                              </Text>
                              <Text color={percentBnpl.isValidNumber ? "secondary" : "gray0"} size="sm">
                                {percentPawn.value}
                              </Text>
                            </Box>
                          </StyledTd>
                        );
                      })}
                    </StyledTr>
                  );
                })}
            </tbody>
          </Table>
        )}
        {percentView === PercentView.withCyanFee && (
          <Flex gap="10px" justifyContent="flex-end">
            <Text color="gray0" size="xs">
              BNPL
            </Text>
            <Text color="gray0" size="xs">
              |
            </Text>
            <Text color="gray0" size="xs">
              Loan
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const SelectBox = styled(Box)`
  width: 50%;
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.tablet]: "100%" }])}
`;
const Table = styled(StyledTable)`
  font-size: 14px;
`;
