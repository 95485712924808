import { FC, createContext, useContext } from "react";
import { useAsyncAbortable } from "react-async-hook";

import { ICumulativePoints, IUserPoint, getCumulativePoints, getUserPoint } from "@/apis/cyan-points";

import { useWeb3React } from "../Web3ReactProvider";

interface IPointContext {
  cumulativePoints: ICumulativePoints;
  userPoint: IUserPoint | null;
  fetchUserPoint: () => Promise<IUserPoint | null>;
}

const PointDataContext = createContext<IPointContext>({
  cumulativePoints: [],
  userPoint: null,
  fetchUserPoint: async () => null,
});

export const PointDataProvider: FC = ({ children }) => {
  const { account } = useWeb3React();
  const { result: cumulativePoints = [] } = useAsyncAbortable<ICumulativePoints>(async abortSignal => {
    return await getCumulativePoints({ abortSignal });
  }, []);

  const { result: userPoint = null, execute: fetchUserPoint } = useAsyncAbortable<IUserPoint | null>(
    async abortSignal => {
      if (!account) return null;
      return await getUserPoint({ wallet: account, abortSignal });
    },
    [account],
  );

  return (
    <PointDataContext.Provider value={{ cumulativePoints, userPoint, fetchUserPoint }}>
      {children}
    </PointDataContext.Provider>
  );
};

export const usePointData = () => {
  return useContext(PointDataContext);
};
