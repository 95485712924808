import orderBy from "lodash.orderby";
import { useContext, useMemo } from "react";

import { CsvButton, Flex, SwitchButton, SwitchButtonGroup } from "@cyanco/components/theme";

import { ApePlanStatuses } from "@/apis/ape-plans";
import { PoolId } from "@/config";
import { bigNumToFixedStr, bigNumToFloat } from "@/utils";

import { ApeCoinDataContext } from "../../ApeCoinDataContext";
import { HistoryViewType, useApeCoinContext } from "../../ApeCoinPageContext";
import { IApePlanHistory } from "../../types";
import { StickySelectorWrapper } from "../common";

export const HistoryViewSelectors = () => {
  const { selectedHistoryViewType, setSelectedHistoryViewType } = useApeCoinContext();
  const { apePlanHistories } = useContext(ApeCoinDataContext);
  const histories = useMemo(() => {
    return orderBy(apePlanHistories, ["createdAt", "tokenId"], ["desc"]);
  }, [apePlanHistories]);

  const stakeType = (history: IApePlanHistory) => {
    if (history.poolId === PoolId.COIN) return "Vault";
    if (bigNumToFloat(history.amount, 18) == 0) return "Owned";
    return "Borrowed";
  };

  const getStatus = (history: IApePlanHistory) => {
    if (history.status === ApePlanStatuses.Started && !history.rewards) return "Staked";
    if (history.status === ApePlanStatuses.Completed && !history.rewards) return "Unstaked";
    return "Auto-compound";
  };
  const historiesFiltered = useMemo(() => {
    return [
      ["Item", "Destination", "Amount", "Rewards", "Stake Type", "Status", "Date", "Txn Hash"],
      ...histories
        .filter(history => {
          if (selectedHistoryViewType === HistoryViewType.all) return true;
          if (selectedHistoryViewType === HistoryViewType.staked) {
            return history.status === ApePlanStatuses.Started && !history.rewards;
          }
          if (selectedHistoryViewType === HistoryViewType.unstaked) {
            return history.status === ApePlanStatuses.Completed && !history.rewards;
          }
          return false;
        })
        .map(history => [
          history.tokenId ? `${history.metadata?.collectionName} ${history.tokenId}` : "ApeCoin",
          history.rewardStakeToCyanVault ? "Cyan" : "Ape Staking",
          bigNumToFixedStr(history.amount, 0, 18),
          bigNumToFixedStr(history.rewards || 0, 2, 18),
          stakeType(history),
          getStatus(history),
          history.createdAt,
          history.txnHash,
        ]),
    ].join("\n");
  }, [selectedHistoryViewType, histories]);

  return (
    <StickySelectorWrapper alignItems="center" justifyContent="space-between" w="100%" h="28px" pb="1rem">
      <Flex gap="2px" alignItems="center">
        <SwitchButtonGroup<HistoryViewType> value={selectedHistoryViewType} onChange={setSelectedHistoryViewType} hover>
          <SwitchButton height="22px" value={HistoryViewType.all}>{`All`}</SwitchButton>
          <SwitchButton height="22px" value={HistoryViewType.staked}>{`Staked`}</SwitchButton>
          <SwitchButton height="22px" value={HistoryViewType.unstaked}>{`Unstaked`}</SwitchButton>
        </SwitchButtonGroup>
      </Flex>
      <CsvButton data={historiesFiltered} />
    </StickySelectorWrapper>
  );
};
