import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import styled from "styled-components";

import { Flex, Text } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { jumpToLink, shortenName } from "@/utils";

import { IWpArticle, useLatestArticles } from "../HomeTopPage/context";

dayjs.extend(advancedFormat);

dayjs().format("Do");

export const LatestArticles = () => {
  const { articles } = useLatestArticles();
  return (
    <Container direction="column" w="100%">
      <Text color="secondary" size="xl" weight="700">
        LATEST FROM CYAN
      </Text>
      <ArticlesContainer>
        {articles.map(item => (
          <LatestArticle article={item} key={item.id} />
        ))}
      </ArticlesContainer>
    </Container>
  );
};

const LatestArticle = ({ article }: { article: IWpArticle }) => {
  return (
    <ArticleItem
      direction="column"
      p="0.8vw"
      pb="1vw"
      onClick={() => {
        jumpToLink(article.link);
      }}
    >
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="center"
        style={{
          overflow: "hidden",
          borderRadius: "20px",
          willChange: "transform",
        }}
      >
        <ArticleImage src={article?.jetpack_featured_media_url ?? NoImage} />
      </Flex>
      <ArticleTitle>{shortenName(`${article.title.rendered} `, 28, 25, 0)}</ArticleTitle>
      <ArticleDate>{dayjs(article.date).format("MMMM Do, YYYY")}</ArticleDate>
    </ArticleItem>
  );
};

const ArticleImage = styled.div<{ src: string }>`
  border-radius: 20px;
  height: 193px;
  background-image: ${({ src }) => `url(${src})`};
  width: 100%;
  transform: scale(1.2);
  transition: 0.2s;
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    height: 170px;
  }
`;

const ArticleItem = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 35px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  padding: 12px;
  padding-bottom: 16px;
  max-width: 300px;
  width: calc(100% - 24px);
  &:hover ${ArticleImage} {
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
  }
  :after {
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

const ArticleTitle = styled.h1`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const ArticleDate = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.7px;
  color: ${({ theme }) => theme.colors.secondary};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Container = styled(Flex)`
  padding: 0rem 0 3rem;
  gap: 2.5rem;
`;
const ArticlesContainer = styled.div`
  display: grid;
  gap: 3.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media only screen and (max-width: ${breakpoints.laptopS}px) {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    display: flex;
    padding: 0 1rem 1rem;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
`;
