import { BigNumber } from "ethers";
import orderBy from "lodash.orderby";
import { Fragment, useMemo } from "react";
import { HelpCircle } from "react-feather";
import { useTheme } from "styled-components";

import { Card, Flex, Text, Tooltip, TooltipText } from "@cyanco/components/theme";

import { INextPayment } from "@/apis/types";
import { IPayment } from "@/components/Account/payment.types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { getDueForPayment } from "@/components/PlanCreation/utils";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, getDurationLabel, numberWithCommas } from "@/utils";

import { StepperContainer } from "../BulkRepayment/RepaymentBreakdown";
import { IPaymentsMapped } from "../PlanRepayment";
import { PaymentStep } from "../RegularPayment";

export const RevivalDetail = ({
  plan,
}: {
  plan: {
    nextPayment: INextPayment;
    penaltyAmount: BigNumber;
    penaltyRate: number;
    principleValue: BigNumber;
    totalCost: BigNumber;
    term: number;
    totalNumOfPaymentsLeft: number;
    interestRate: number;
    apr: number;
    currency: ICurrency;
    totalNumberOfPayments: number;
    monthlyAmount: BigNumber;
    payments: IPayment[];
    isBnpl: boolean;
    createdAt: Date;
  };
}) => {
  const theme = useTheme();
  const {
    currency,
    nextPayment,
    penaltyAmount,
    monthlyAmount,
    apr,
    penaltyRate,
    principleValue,
    totalNumOfPaymentsLeft,
    totalNumberOfPayments,
    term,
    isBnpl,
    createdAt,
    totalCost,
  } = plan;
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(currency.decimal) || 4;
  }, [currency.decimal]);
  const totalPay = penaltyAmount.add(nextPayment.currentPayment);
  const _totalCost = useMemo(() => {
    return BigNumber.from(totalCost).add(penaltyAmount);
  }, [totalCost, penaltyAmount]);
  const paymentsWithSplittedRevival = useMemo(() => {
    return orderBy(plan.payments, "createdAt", "asc").reduce<IPaymentsMapped[]>((acc, cur, index) => {
      const hasRevivalFee = isBnpl && index === 0 ? false : BigNumber.from(cur.amount).gt(cur.expectedAmount);
      if (hasRevivalFee) {
        return [
          ...acc,
          {
            ...cur,
            amount: BigNumber.from(cur.amount).sub(cur.expectedAmount).toString(),
            isRevival: true,
          },
          {
            ...cur,
            amount: cur.expectedAmount.toString(),
            isRevival: false,
          },
        ];
      }
      return [...acc, { ...cur, isRevival: false }];
    }, []);
  }, [plan.payments, plan.monthlyAmount]);

  return (
    <Flex direction="column" gap="10px">
      <Card p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Text size="sm" weight="600" color="secondary">
            {`Payment of `}{" "}
            {`${numberWithCommas(bigNumToFixedStr(totalPay, formatNumber, currency.decimal), formatNumber)} ${
              currency.symbol
            }`}
          </Text>
          <StepperContainer>
            {paymentsWithSplittedRevival.map(payment => (
              <PaymentStep
                key={`${payment.txnHash}-${payment.isRevival ? "revival" : "payment"}}`}
                text={payment.isRevival ? `Revival Penalty` : `Already Paid`}
                paymentAmount={payment.amount}
                currency={currency}
                fixNumber={formatNumber}
                isPaid
              />
            ))}
            {Array.from(Array(totalNumOfPaymentsLeft).keys()).map(paymentNumber => (
              <Fragment key={paymentNumber}>
                <PaymentStep
                  text={`${
                    paymentNumber === 0
                      ? "missed"
                      : `in ${getDueForPayment({
                          paymentNumber:
                            paymentNumber + paymentsWithSplittedRevival.filter(payment => !payment.isRevival).length,
                          isBnpl,
                          term,
                          createdAt,
                        })}`
                  } `}
                  paymentAmount={monthlyAmount}
                  currency={currency}
                  fixNumber={formatNumber}
                  isLast={paymentNumber === 0 ? false : totalNumOfPaymentsLeft === paymentNumber + 1}
                  isMissed={paymentNumber === 0}
                />

                {paymentNumber === 0 && (
                  <PaymentStep
                    text={`revival`}
                    paymentAmount={monthlyAmount.add(penaltyAmount)}
                    currency={currency}
                    fixNumber={formatNumber}
                    isNextPayment
                    isLast={totalNumOfPaymentsLeft === paymentNumber + 1}
                    color={paymentNumber === 0 ? "secondary" : "gray0"}
                  />
                )}
              </Fragment>
            ))}
          </StepperContainer>
          <Flex justifyContent="space-between">
            <Flex alignItems="center" gap="5px">
              <Text size="xs" weight="500" color="secondary">
                {`${(penaltyRate / 100).toFixed(2)}% penalty`}
              </Text>
              <Tooltip>
                <HelpCircle height={16} width={16} color={theme.colors.secondary} />
                <TooltipText showArrow position="top" top="-75px" right="-50px" style={{ width: "100px" }}>
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`The penalty increases 1% of payment every 24 hours.`}</div>
                  </Text>
                </TooltipText>
              </Tooltip>
            </Flex>
            <Text size="xs" weight="500" color="secondary">
              {`Total Cost ${numberWithCommas(
                bigNumToFixedStr(_totalCost, formatNumber, currency.decimal),
                formatNumber,
              )} ${currency.symbol}`}
            </Text>
          </Flex>
        </Flex>
      </Card>
      <Card p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Text size="sm" weight="600" color="secondary">
            {`Loan Details`}
          </Text>
          <StepperContainer
            style={{
              borderBottomWidth: "0px",
              paddingBottom: "0px",
            }}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text size="xs" weight="500" color="gray0">
                {`Principal amount`}
              </Text>
              <Text size="xs" weight="500" color="gray0">
                {`${numberWithCommas(bigNumToFixedStr(principleValue, formatNumber, currency.decimal), formatNumber)} ${
                  currency.symbol
                }`}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text size="xs" weight="500" color="gray0">
                {`Loan length`}
              </Text>
              <Text size="xs" weight="500" color="gray0">
                {getDurationLabel(term * totalNumberOfPayments)}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text size="xs" weight="500" color="gray0">
                {`Number of payments left`}
              </Text>
              <Text size="xs" weight="500" color="gray0">
                {totalNumOfPaymentsLeft}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text size="xs" weight="500" color="gray0">
                {`APR`}
              </Text>
              <Text size="xs" weight="500" color="gray0">
                {apr.toFixed(2)}%
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text size="xs" weight="500" color="gray0">
                {`Missed amount`}
              </Text>
              <Text size="xs" weight="500" color="gray0">
                {`${numberWithCommas(bigNumToFixedStr(monthlyAmount, formatNumber, currency.decimal), formatNumber)} ${
                  currency.symbol
                }`}
              </Text>
            </Flex>
          </StepperContainer>
        </Flex>
      </Card>
    </Flex>
  );
};
