import { captureException } from "@sentry/react";
import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { createP2POffer } from "@/apis/p2p";
import { IP2PUserCreatedOffer } from "@/apis/p2p/types";
import { useCreatedLoanBids } from "@/components/Account/AccountDataContext";
import { useSupportedCollections } from "@/components/AppContextProvider";
import { useP2PLoanOffers } from "@/components/P2P/LendDataContext";
import { NftMetadata } from "@/components/P2P/collection/LoanOffers/NftMetadata";
import { signOfferData } from "@/components/P2P/utils";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPeerPaymentPlanFromChainId } from "@/constants/contracts";
import { useApproval } from "@/hooks/useApproval";
import { ITEM_AMOUNT_BY_NFT_TYPE } from "@/types";

import { OfferDetail } from "./OfferDetail";

export const RenewOfferProgress = ({
  offer,
}: {
  offer: IP2PUserCreatedOffer & {
    expiry?: number;
  };
}) => {
  const cyanWallet = useCyanWallet();
  const { giveErc20Approval } = useApproval();
  const { chainId, signer, account, provider } = useWeb3React();
  const { setModalContent, unsetModal } = useModal();
  const { fetchLoanOffers } = useP2PLoanOffers();
  const { fetchUserCreatedLoanBids } = useCreatedLoanBids();
  const { collections } = useSupportedCollections();
  const [selectedStep, setSelectedStep] = useState<Steps>(Steps.Approve);

  const checkAndApproveERC20 = async () => {
    if (!signer || !account) throw new Error("Provider not found!");
    const amount = BigNumber.from(offer.amount).mul(offer.signatureUsageLimit);
    const contract = f.SampleERC20TokenFactory.connect(offer.currency.address, signer);
    const isCyanWalletAddressSelected =
      cyanWallet && cyanWallet.walletAddress.toLowerCase() === offer.lenderAddress.toLowerCase();
    const userBalance = await contract.balanceOf(isCyanWalletAddressSelected ? cyanWallet.walletAddress : account);
    if (userBalance.lt(amount)) {
      throw new Error("Insufficient balance to create loan offer");
    }
    const peerToPeerContract = getPeerPaymentPlanFromChainId(chainId);
    await giveErc20Approval(
      {
        amount,
        currencyAddress: offer.currency.address,
        cyanWallet: isCyanWalletAddressSelected ? cyanWallet : undefined,
      },
      peerToPeerContract,
    );
  };
  useEffect(() => {
    const createOffer = async () => {
      try {
        switch (selectedStep) {
          case Steps.Approve: {
            await checkAndApproveERC20();
            setSelectedStep(Steps.Sign);
            return;
          }
          case Steps.Sign: {
            const collection = collections.find(
              col => col.address.toLowerCase() === offer.collection.address.toLowerCase(),
            );
            if (!signer || !collection || !provider) return;
            const lastBlockNum = await provider.getBlockNumber();
            const lastBlock = await provider.getBlock(lastBlockNum);
            const lastTimestamp = lastBlock.timestamp * 1000;
            const expiryDate = dayjs(lastTimestamp).add(offer.expiry || 0, "seconds");
            const signedDate = dayjs().valueOf();
            const signature = await signOfferData(
              {
                contractAddress: offer.collection.address.toLowerCase(),
                tokenId: offer.tokenId ? offer.tokenId : undefined,
                amount: BigNumber.from(offer.amount),
                currencyAddress: offer.currency.address,
                itemType: collection.tokenType,
                interestRate: offer.interestRate,
                term: offer.term,
                maxUsageCount: offer.signatureUsageLimit,
                isExtendable: offer.isExtendable,
                collectionSignature: collection.cyanSignature,
                expiryDate: expiryDate.valueOf(),
                signedDate,
                tokenAmount: ITEM_AMOUNT_BY_NFT_TYPE[collection.tokenType],
                chainId,
              },
              signer,
            );
            setSelectedStep(Steps.Renew);
            await createP2POffer({
              chainId,
              collectionAddress: offer.collection.address.toLowerCase(),
              tokenId: offer.tokenId ? offer.tokenId : undefined,
              amount: offer.amount,
              lenderAddress: offer.lenderAddress,
              isExtendable: offer.isExtendable,
              signature,
              signatureExpiry: expiryDate.valueOf(),
              signedDate,
              signatureUsageLimit: offer.signatureUsageLimit,
              currency: offer.currency.symbol,
              term: offer.term,
              interestRate: offer.interestRate,
              tokenAmount: offer.tokenAmount,
            });
            await Promise.all([fetchUserCreatedLoanBids(), fetchLoanOffers()]);
            setSelectedStep(Steps.Done);
          }
        }
      } catch (e) {
        captureException(e);
        setModalContent({
          title: `Renew loan offer`,
          content: <OfferDetail offer={offer} modalType="renew" error={e} />,
        });
      }
    };
    createOffer();
  }, [selectedStep]);
  return (
    <Flex gap="2rem" direction="column">
      {offer.tokenId && (
        <NftMetadata
          nft={{
            collectionAddress: offer.collection.address,
            collectionName: offer.collection.name,
            tokenId: offer.tokenId,
            currency: offer.currency,
            imageUrl: offer.metadata ? offer.metadata.imageUrl : NoImage,
          }}
        />
      )}
      <Box pb="1.8rem" pt="1rem">
        <Stepper marks={StepMarks} selectedStep={selectedStep} txUrl={""} />
      </Box>
      {selectedStep === Steps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};
enum Steps {
  Approve = 0,
  Sign = 1,
  Renew = 2,
  Success = 3,
  Done = 4,
}
const StepMarks = [
  {
    value: Steps.Approve,
    title: `ERC20 Token Approval`,
    description: `This provides Cyan with permission to move your ERC20`,
  },
  {
    value: Steps.Sign,
    title: `Sign Offer`,
  },
  {
    value: Steps.Renew,
    title: `Renewing loan offer`,
    description: `A small amount of gas is required to approve`,
  },
  {
    value: Steps.Success,
    description: `View on Etherscan`,
    title: `Successfully renewed`,
  },
];

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
