import { useEffect, useState } from "react";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound, useModal } from "@cyanco/components/theme/v3";

import { IP2PUserCreatedOffer } from "@/apis/p2p/types";

import { useCreatedLoanBids } from "../../AccountDataContext";
import { ContainerBox, Header, ListViewBox } from "../../index";
import { LoanOfferHeader, LoanOfferRow } from "./LoanOfferRow";
import { OfferDetail } from "./modals/OfferDetail";

export const Offers: React.FC = () => {
  const { setModalContent } = useModal();
  const { createdLoanBids } = useCreatedLoanBids();
  const [sortedLoanBids, setSortedLoanBids] = useState<IP2PUserCreatedOffer[]>(
    createdLoanBids.filter(bid => bid.isActive && !bid.isExpired),
  );

  const complete = (offer: IP2PUserCreatedOffer) => {
    setModalContent({
      title: `Cancel loan offer`,
      content: <OfferDetail offer={offer} modalType="cancel" />,
    });
  };

  useEffect(() => {
    setSortedLoanBids([...createdLoanBids.filter(bid => bid.isActive && !bid.isExpired)]);
  }, [createdLoanBids]);

  const updateSortedOffers = (offers: IP2PUserCreatedOffer[]) => {
    setSortedLoanBids([...offers]);
  };

  return (
    <ListContainer>
      <ListViewBox>
        <Header>
          <LoanOfferHeader
            totalItems={sortedLoanBids.length}
            sortedOffers={sortedLoanBids}
            updateSortedOffers={updateSortedOffers}
          />
        </Header>
        <ContainerBox>
          {sortedLoanBids.length === 0 && <NotFound msg={`No active offers found`} />}
          <Container role="grid" $showByGrid={false}>
            {sortedLoanBids.map(offer => (
              <LoanOfferRow
                key={offer.id}
                offer={offer}
                loading={false}
                onClick={() => {
                  complete(offer);
                }}
              />
            ))}
          </Container>
        </ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};

const Container = styled.div<{ $showByGrid: boolean }>`
  ${({ $showByGrid }) => {
    if ($showByGrid) {
      return ` display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  ${getStyleWithMediaQuery("grid-column-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-row-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.desktop]: "repeat(auto-fill, minmax(200px, 1fr))" },
    { [breakpoints.tablet]: "repeat(auto-fill, minmax(180px, 1fr))" },
    { [breakpoints.mobile]: "repeat(auto-fill, minmax(140px, 1fr))" },
  ])}; `;
    } else {
      return ` display: flex;
      flex-direction: column;
      width: 100%;
   @media only screen and (max-width: 414px) {
     border: none;
     border-radius: 20px;
     padding: 0;
   }
   > * {
     &:last-child {
       border-bottom-left-radius: 20px;
       border-bottom-right-radius: 20px;
     }
   }`;
    }
  }}
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;
