import { useState } from "react";
import styled from "styled-components";

import { Box, Button, Flex, Input } from "@cyanco/components/theme";
import { Text } from "@cyanco/components/theme/v3";

import { subscribeEmail } from "@/apis/user";
import { validEmail } from "@/utils";

export const SignUp = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSignUp = async () => {
    setIsLoading(true);
    if (!validEmail(email)) {
      setError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    } else {
      setError(null);
    }
    try {
      await subscribeEmail(email);
      setIsSuccess(true);
    } catch (e) {
      setIsSuccess(false);
      setError("Something went wrong. Please try again.");
    }
    setIsLoading(false);
  };

  return (
    <Flex direction="column">
      <Flex gap="10px" justifyContent="center">
        {isSuccess ? (
          <Text size="xl" color="white">
            Success 🎉
          </Text>
        ) : (
          <>
            <StyledInput
              placeholder="Email address"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                setError(null);
              }}
            />
            <Box w="140px" h="50px">
              <Button size="lg" onClick={handleSignUp} loading={isLoading}>
                Sign up
              </Button>
            </Box>
          </>
        )}
      </Flex>
      {error && (
        <Text size="sm" color="red" style={{ marginTop: "10px" }}>
          {error}
        </Text>
      )}
    </Flex>
  );
};

const StyledInput = styled(Input)`
  background: transparent;
  border-width: 2px;
  font-size: 18px;
  flex-grow: 1;
`;
