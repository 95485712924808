import isEqual from "lodash.isequal";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Flex, Text } from "@cyanco/components/theme";

import { updateLtvControllers } from "@/apis/vault/admin";
import { ILtvController } from "@/apis/vault/admin/types";
import { IVault } from "@/apis/vault/types";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";

import { SaveSettingsButton } from "../PoolManagement";
import { getVaultAdminSignatureExpiry, signLtvControllerUpdate } from "../utils";
import { LtvControllerHeader, LtvControllerRow } from "./LtvControllerTable";

export const PoolAdvancedSettings = ({
  vault,
  ltvSettings,
  setLtvController,
}: {
  vault: IVault;
  ltvSettings: ILtvController[];
  setLtvController: (controllers: ILtvController[]) => void;
}) => {
  const { signInWallet } = useAuthContext();
  const { account, signer } = useWeb3React();
  const [ltvFields, setLtvFields] = useState<ILtvController[]>([]);

  useEffect(() => {
    setLtvFields(
      ltvSettings.map(item => ({
        id: item?.id,
        ltvRatio: item.ltvRatio / 1_00,
        priceToFloorRatio: item.priceToFloorRatio / 1_000,
      })),
    );
  }, [ltvSettings]);

  const handleFormChange = (index: number, value: number, target: "priceToFloorRatio" | "ltvRatio") => {
    const data = [...ltvFields];
    data[index][target] = value;
    setLtvFields(data);
  };
  const handleRemoveRow = (index: number) => {
    const data = [...ltvFields];
    data.splice(index, 1);
    setLtvFields(data);
  };

  const updateLtv = async () => {
    if (!account || !signer) return;
    const { token } = await signInWallet();
    const expiryDate = getVaultAdminSignatureExpiry();
    const signature = await signLtvControllerUpdate(ltvFields, vault.contractAddress, expiryDate, signer);
    await updateLtvControllers({
      vaultAddress: vault.contractAddress,
      signature,
      configs: ltvFields,
      token,
      expiryDate,
    });
    setLtvController(
      ltvFields.map(item => ({
        id: item?.id,
        ltvRatio: item.ltvRatio * 1_00,
        priceToFloorRatio: item.priceToFloorRatio * 1_000,
      })),
    );
  };

  return (
    <Flex direction="column" gap="2.5rem" p="3rem 2rem 2rem 2rem">
      <Flex direction="column" gap="5px">
        <Text color="secondary" style={{ fontSize: "33px" }} weight="600">{`Universal LTV controller`}</Text>
        <Text color="gray0" size="lg">
          {`Set universal LTV limitations based on how expensive an NFT is.`}
        </Text>
      </Flex>

      <Flex direction="column" gap="0.5rem">
        <LtvControllerHeader />
        {ltvFields.map((form, index) => (
          <LtvControllerRow
            key={index}
            index={index}
            ltvRatio={form.ltvRatio}
            priceToFloorRatio={form.priceToFloorRatio}
            handleFormChange={handleFormChange}
            handleRemoveRow={handleRemoveRow}
          />
        ))}
      </Flex>
      <Flex gap="1rem">
        <SaveSettingsButton
          onClick={updateLtv}
          disabled={isEqual(
            ltvFields,
            ltvSettings.map(item => ({
              id: item?.id,
              ltvRatio: item.ltvRatio / 1_00,
              priceToFloorRatio: item.priceToFloorRatio / 1_000,
            })),
          )}
        />
        <AddButton
          variant="outlined"
          onClick={() => {
            setLtvFields(prev => [
              ...prev,
              {
                ltvRatio: 75,
                priceToFloorRatio: 1.0,
              },
            ]);
          }}
        >
          <Text color="secondary" weight="700">
            {`+ add another row`}
          </Text>
        </AddButton>
      </Flex>
    </Flex>
  );
};

const AddButton = styled(Button)`
  border-radius: 30px;
  padding: 0.8rem 1.3rem;
  transition: 0.2s ease-in-out;
  border-color: ${({ theme }) => theme.colors.secondary};
  width: fit-content;
  > * {
    transition: 0.2s ease-in-out;
  }
  :hover {
    border-color: ${({ theme }) => theme.colors.cyan};
    > * {
      color: ${({ theme }) => theme.colors.cyan};
    }
  }
`;
