import dayjs from "dayjs";
import { useState } from "react";
import { NumericFormat } from "react-number-format";

import {
  Button,
  Card,
  Flex,
  Input,
  NftMetadataInline,
  NftMetadataInlineImage,
  SystemMessage,
  Text,
  useModal,
} from "@cyanco/components/theme";

import { IVaultLiquidatedNft } from "@/apis/vault/admin/types";
import { IVault } from "@/apis/vault/types";
import { DueCircle, StepperContainer } from "@/components/PlanPayment/RegularPayment";
import { bigNumToFloatFormatted, shortenName } from "@/utils";

import { VaultRefundProgressModal } from "./VaultRefundProgressModal";

export const VaultRefundModal = ({
  nft,
  vault,
  error,
}: {
  nft: IVaultLiquidatedNft & { refundValue?: number };
  error?: { title: string; msg: string };
  vault: IVault;
}) => {
  const [nftValue, setNftValue] = useState(nft?.refundValue ? nft.refundValue.toFixed(4) : "");
  const { setModalContent } = useModal();
  const openModal = () => {
    setModalContent({
      title: "Return Fund",
      content: <VaultRefundProgressModal nfts={[{ ...nft, refundValue: parseFloat(nftValue) }]} vault={vault} />,
    });
  };
  return (
    <Flex gap="12px" direction="column">
      <VaultRefundNftMetadata nft={nft} vault={vault} />
      {error && <SystemMessage variant={"error"} title={error.title} msg={error.msg} />}
      <Card p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Text size="sm" weight="600" color="secondary">
            Defaulted
          </Text>
          <StepperContainer>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="8px">
                <DueCircle isMissed isLast />
                <Text size="xs" weight="500" color="gray0">
                  Marked Value
                </Text>
              </Flex>
              <Text size="xs" weight="500" color={"gray0"}>
                {`${bigNumToFloatFormatted(nft.markedPrice, vault.decimals, 4)} ${vault.currency}`}
              </Text>
            </Flex>
          </StepperContainer>
          <Text size="xs" weight="500" color="gray0" textAlign="right">
            Admin Marked Value
          </Text>
        </Flex>
      </Card>
      <Flex direction="column" gap="10px">
        <Text size="sm" weight="600" color="secondary">
          Funds to return to the pool
        </Text>
        <NumericFormat
          value={nftValue}
          onValueChange={value => setNftValue(value.value)}
          placeholder="Enter the liquidation amount of the NFT"
          customInput={Input}
          valueIsNumericString
          decimalScale={vault.decimals}
        />
        <Button style={{ height: "50px" }} disabled={nftValue === ""} onClick={openModal}>
          Liquidate NFT
        </Button>
      </Flex>
    </Flex>
  );
};

export const VaultRefundNftMetadata = ({
  nft,
  vault,
  refundAmount,
}: {
  nft: IVaultLiquidatedNft;
  vault: IVault;
  refundAmount?: number;
}) => {
  return (
    <Flex gap="10px" pt="5px">
      <NftMetadataInlineImage imageUrl={nft.imageUrl} />
      <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 8px 0">
        <NftMetadataInline
          name={shortenName(nft.collectionName, 21, 20, 0)}
          value={`#${shortenName(nft.tokenId, 10, 5)}`}
        />
        <Flex gap="3px" direction="column" w="100%" justifyContent="flex-end">
          {!refundAmount ? (
            <NftMetadataInline
              name={`Marked value`}
              value={`${bigNumToFloatFormatted(nft.markedPrice, vault.decimals, 3)} ${vault.currency}`}
              sub
            />
          ) : (
            <NftMetadataInline name={`Funds to return`} value={`${refundAmount.toFixed(2)} ${vault.currency}`} sub />
          )}
          <NftMetadataInline name={`Defaulted`} value={`${dayjs(nft.defaultedAt).format("MMM DD, YYYY")}`} sub />
        </Flex>
      </Flex>
    </Flex>
  );
};
