import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Card, NotFound } from "@cyanco/components/theme/v3";

import { useAppContext } from "@/components/AppContextProvider";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { useUserAdmin } from "@/hooks";
import { Experiments } from "@/utils/experimentList";

import { useFilteredVaultsNew } from "../../VaultDataProvider";
import { VAULT_SORTING_OPTIONS, useVaultSelectors } from "../../VaultPageContext";
import { VaultGridCard, VaultGridCardLoading } from "./VaultGridCard";
import { VaultSelectors } from "./VaultSelectors";
import { VaultStatsHeader, VaultStatsRow, VaultStatsRowLoading } from "./VaultStats";

export const AvailableVaults = () => {
  const { vaults, fetchingVaults } = useFilteredVaultsNew();
  const { selectedView, selectedSort, sortDirection } = useVaultSelectors();
  const { experiment } = useAppContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { getUserAdminOfVaults } = useUserAdmin();
  const isAutoLiquidationVaultEnabled = useMemo(() => {
    return experiment.result && experiment.result[Experiments.AUTO_LIQUIDATION_VAULT];
  }, [experiment]);
  const location = useLocation();
  const availableVaults = useMemo(() => {
    const isAdminPage = location.pathname === "/vault-admin";
    if (!isAdminPage) return vaults;
    const adminVaults = getUserAdminOfVaults(vaults);
    if (adminVaults.length === 0) {
      navigate("/404");
    }
    return adminVaults;
  }, [vaults, user, location]);
  const filteredVaults = useMemo(() => {
    const sortedVaults =
      sortDirection === "asc"
        ? availableVaults.sort(VAULT_SORTING_OPTIONS.find(sort => sort.value === selectedSort)?.function)
        : availableVaults.sort(VAULT_SORTING_OPTIONS.find(sort => sort.value === selectedSort)?.functionDesc);
    return sortedVaults.filter(vault => (!isAutoLiquidationVaultEnabled ? !vault.isAutoLiquidated : true));
  }, [availableVaults, selectedSort, sortDirection]);
  return (
    <Container direction="column">
      <Flex direction="column" w="100%">
        <VaultSelectors />
        {selectedView === VaultViewStyle.list && (
          <ListViewBox>
            <Header>
              <VaultStatsHeader />
            </Header>
            <ContainerBox>
              {fetchingVaults ? (
                Array.from(Array(3).keys()).map(loader => <VaultStatsRowLoading key={loader} />)
              ) : filteredVaults.length === 0 ? (
                <NotFound msg="No vaults found" />
              ) : (
                filteredVaults.map((vault, index) => {
                  return <VaultStatsRow vault={{ ...vault, index }} key={vault.id} />;
                })
              )}
            </ContainerBox>
          </ListViewBox>
        )}
        {selectedView === VaultViewStyle.grid && (
          <GridViewBox novault={!fetchingVaults && filteredVaults.length === 0}>
            {fetchingVaults ? (
              Array.from(Array(4).keys()).map(loader => <VaultGridCardLoading key={loader} />)
            ) : filteredVaults.length === 0 ? (
              <NotFound msg="No vaults found" />
            ) : (
              filteredVaults.map(vault => {
                return <VaultGridCard vault={{ ...vault }} key={vault.id} />;
              })
            )}
          </GridViewBox>
        )}
      </Flex>
    </Container>
  );
};
const GridViewBox: React.FC<{ novault: boolean }> = ({ novault, children }) => {
  return (
    <div style={{ position: "relative", margin: "1px" }}>
      <GridBox novault={novault}>{children}</GridBox>
    </div>
  );
};
const GridBox = styled.div<{ novault: boolean }>`
  margin-top:1rem;
  display: grid;
  gap: 1.5rem;
  justify-items: center;
  align-items: center;
  grid-template-columns: ${({ novault }) => (novault ? "1fr" : "1fr 1fr 1fr 1fr")};
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  @media only screen and (max-width: ${breakpoints.tabletL}px) {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  @media only screen and (max-width: 508px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}`;

export enum VaultViewStyle {
  list = "list",
  grid = "grid",
}

const Header = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 170px;
  }
  position: sticky;
  align-items: end;
  top: 150px;
  background: ${({ theme }) => theme.colors.primary};
  transform-style: preserve-3d;
  z-index: 5;
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    position: absolute;
    transform: translateZ(-1px);
    top: -60px;
    height: calc(100% + 40px);
    left: -150px;
    width: calc(100% + 300px);
  }
`;

export const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative", margin: "1px" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

const MainBox = styled(Card)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  border: none;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-radius: 10px;
    box-shadow: none;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &:first-child {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
`;

export const ContainerBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top: none;
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;
export const Container = styled(Flex)`
  gap: 1.5rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])}
`;
