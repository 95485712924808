import { IVaultRequestedProject, IVaultSupportedProject } from "../types";

export type ILtvController = {
  id?: number;
  priceToFloorRatio: number;
  ltvRatio: number;
};

export type IPoolSettings = {
  id: number;
  maturity: number;
  weight: number;
  ltvRatio: number;
  isActivePawn: boolean;
  isActiveBnpl: boolean;
};

export type IVaultSupportedProjectWithAppraisal = IVaultSupportedProject & {
  customAppraisalValue: string | null;
  appraisalValue: string;
};

export type IVaultLoanSettings = {
  ltvSettings: ILtvController[];
  pricerSettings: IPoolSettings[];
  baseInterestRate: number;
  requestedProjects: IVaultRequestedProject[];
  supportedProjects: IVaultSupportedProjectWithAppraisal[];
};

export enum ILiquidatedNftStatus {
  marked,
  sold,
}

export type IVaultLiquidatedNft = {
  id: number;
  tokenId: string;
  collectionAddress: string;
  collectionName: string;
  imageUrl?: string;
  markedPrice: string;
  soldPrice?: string;
  defaultedAt: Date;
};
