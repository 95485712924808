import { AccountPageTabs, LendTabData, useAccountPageContext } from "../AccountPageContext";
import { LendingView } from "./LendingView";
import { PositionView } from "./PositionView";
import { VaultView } from "./VaultView";
import { WalletView } from "./WalletView";

export const MainView = () => {
  const { selectedTab, selectedLendingTabData } = useAccountPageContext();
  return (
    <>
      <div
        style={{
          display: selectedTab === AccountPageTabs.wallets ? "block" : "none",
        }}
      >
        <WalletView />
      </div>
      <div
        style={{
          display: selectedTab === AccountPageTabs.positions ? "block" : "none",
        }}
      >
        <PositionView />
      </div>
      <div
        style={{
          display: selectedTab === AccountPageTabs.vault_tokens ? "block" : "none",
        }}
      >
        <VaultView />
      </div>
      {selectedTab === AccountPageTabs.lending && selectedLendingTabData === LendTabData.p2p && <LendingView />}
      {selectedTab === AccountPageTabs.lending && selectedLendingTabData === LendTabData.vault && <VaultView />}
    </>
  );
};
