import orderBy from "lodash.orderby";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Box, Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { SlashButton, SlashText } from "@cyanco/components/theme/v3";
import { Hidden, Input, Option, Select } from "@cyanco/components/theme/v3/core";
import { Grid, List } from "@cyanco/components/theme/v3/icons";

import { ICollectionBe } from "@/apis/collection/types";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { useDebounce } from "@/hooks/useDebounce";
import { shortenName } from "@/utils";

import { useBnplSupportedCollections } from "../Bnpl/BnplDataContext";
import { OnSaleNftsView, useBnplPageContext } from "../Bnpl/BnplPageContext";
import { PlanCart } from "../Bnpl/components/CollectionDetail/Cart";
import {
  BnplSelectorWrapper,
  BnplSelectors,
  DropDowns,
  ViewSelectorWrapper,
  ViewType,
} from "../Bnpl/components/CollectionDetail/Selectors";
import { useBargainCollection } from "./BargainDataContext";

export const Selectors: React.FC<{ collection: ICollectionBe; border?: boolean }> = ({ collection, border }) => {
  const theme = useTheme();
  const [tokenInputValue, setTokenInputValue] = useState("");
  const { collections } = useBnplSupportedCollections();
  const { collection: bargainCollection } = useBargainCollection();
  const { setSelectedTokenId, setSelectedOnSaleNftsView, selectedOnSaleNftsView } = useBnplPageContext();
  const filteredCollections = useMemo(
    () => collections.filter(_collection => _collection.chainId === collection.chainId),
    [collections, collection.chainId],
  );
  useDebounce(() => setSelectedTokenId(tokenInputValue), [tokenInputValue], 1000);
  const searchInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "k") {
        searchInput.current?.focus();
      }
      if (e.key === "Escape") {
        searchInput.current?.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [tokenInputValue]);

  const navigate = useNavigate();
  return (
    <BnplSelectorWrapper style={{ borderBottom: border ? "1px #2C2C2C solid" : "none" }}>
      <BnplSelectors>
        <DropDowns>
          <Box h="46px">
            <Select
              onChange={value => {
                setTokenInputValue("");
                if (value == bargainCollection?.address) {
                  navigate("/bargain");
                  return;
                }
                const collection = filteredCollections.find(_collection => _collection.address === value);
                navigate(`/bnpl/${CHAIN_IDS_TO_NAMES[collection?.chainId as SupportedChainId]}/${collection?.slug}`);
              }}
              value={collection.address}
            >
              {bargainCollection && (
                <Option value={bargainCollection.address} key={bargainCollection.address}>
                  {shortenName(bargainCollection.name, 20, 17, 0)}
                </Option>
              )}
              {orderBy(filteredCollections, [_collection => _collection.name.toLowerCase()]).map(_collection => (
                <Option value={_collection.address} key={_collection.address}>
                  {shortenName(_collection.name, 20, 17, 0)}
                </Option>
              ))}
            </Select>
          </Box>
          <Hidden laptopDown>
            <Box h="46px">
              <Select onChange={() => {}} value="price">
                <Option value="price">Price</Option>
              </Select>
            </Box>
          </Hidden>
        </DropDowns>
        <Box h="46px">
          <Input
            inputRef={searchInput}
            placeholder={`Search NFTs`}
            fontSize="lg"
            fontWeight="400"
            value={tokenInputValue}
            onChange={e => setTokenInputValue(e.target.value)}
          >
            <SlashButton
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "35px",
                marginRight: "-6px",
              }}
            >
              <SlashText size="xs" weight="500">
                {"⌘+k"}
              </SlashText>
            </SlashButton>
          </Input>
        </Box>
        <CartWrapper direction="row" gap="1rem" w="100%" justifyContent="flex-end">
          <PlanCart planType={"bnpl"} />
          <ViewSelectorWrapper>
            <ViewType
              isActive={OnSaleNftsView.list === selectedOnSaleNftsView}
              onClick={() => setSelectedOnSaleNftsView(OnSaleNftsView.list)}
            >
              <List color={OnSaleNftsView.list === selectedOnSaleNftsView ? theme.colors.white : theme.colors.gray80} />
            </ViewType>
            <ViewType
              isActive={OnSaleNftsView.grid === selectedOnSaleNftsView}
              onClick={() => setSelectedOnSaleNftsView(OnSaleNftsView.grid)}
            >
              <Grid color={OnSaleNftsView.grid === selectedOnSaleNftsView ? theme.colors.white : theme.colors.gray80} />
            </ViewType>
          </ViewSelectorWrapper>
        </CartWrapper>
      </BnplSelectors>
    </BnplSelectorWrapper>
  );
};

const CartWrapper = styled(Flex)`
  justify-content: flex-end;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    order: -1;
  }
`;
