import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useBnplQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchState, setSearchState] = useState<{
    minPrice?: number;
    maxPrice?: number;
    maxRarity?: number;
    minRarity?: number;
    currencies?: Array<string>;
    marketplaces?: Array<string>;
    attributes: { [key: string]: any };
    showOSFlagged: boolean;
    sortBy?: "floorAskPrice";
  }>({ attributes: {}, showOSFlagged: true });

  const [tokenId, setTokenId] = useState<string | undefined>();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const { search: _search, sortBy, tokenId: _tokenId } = parseQueryString(queryParams.toString());
    const minPrice = _search?.price?.min;
    const maxPrice = _search?.price?.max;
    const showOSFlagged = _search?.osFlag;
    const minRarity = _search?.rarity?.min;
    const maxRarity = _search?.rarity?.max;
    const currencies = _search?.currencies ? Object.values(_search.currencies as { [key: string]: any }) : undefined;
    const marketplaces = _search?.marketplaces
      ? Object.values(_search.marketplaces as { [key: string]: any })
      : undefined;
    const attributes = _search?.attributes ?? {};
    const newSearchObj = {
      minPrice: isNaN(minPrice) ? undefined : Number(minPrice),
      maxPrice: isNaN(maxPrice) ? undefined : Number(maxPrice),
      maxRarity: isNaN(maxRarity) ? undefined : Number(maxRarity),
      minRarity: isNaN(minRarity) ? undefined : Number(minRarity),
      currencies,
      marketplaces,
      attributes,
      showOSFlagged: showOSFlagged !== "false",
      sortBy,
    };
    if (JSON.stringify(searchState) !== JSON.stringify(newSearchObj)) {
      setSearchState(newSearchObj);
    }
    if (_tokenId !== tokenId) {
      setTokenId(_tokenId);
    }
  }, [location.search]);

  const setSearchQueryParam = useCallback(
    (obj: any) => {
      const queryParams = new URLSearchParams(location.search);
      const { search: _search } = parseQueryString(queryParams.toString());
      navigate({
        search: objectToQueryParamString({
          ..._search,
          ...obj,
        }),
      });
    },
    [location],
  );
  const setTokenParam = useCallback(
    (tokenId?: string) => {
      const queryParams = new URLSearchParams(location.search);
      const { search: _search } = parseQueryString(queryParams.toString());
      const searchString = objectToQueryParamString(_search);
      navigate({
        search: `${searchString}${
          tokenId ? (searchString.length > 0 ? `&tokenId=${tokenId}` : `?tokenId=${tokenId}`) : ""
        }`,
      });
    },
    [location],
  );

  return {
    ...searchState,
    tokenId,
    setSearchQueryParam,
    setTokenParam,
  };
};

const objectToQueryParamString = (obj: any, prefix = ""): string => {
  const queryStringParams = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value !== undefined && value !== null) {
        const newKey = prefix ? `${prefix}[${key}]` : `search[${key}]`;
        if (typeof value === "object") {
          queryStringParams.push(objectToQueryParamString(value, newKey));
        } else if (Array.isArray(value)) {
          value.forEach((item, index) => {
            const arrayKey = `${newKey}[${index}]`;
            queryStringParams.push(`${encodeURIComponent(arrayKey)}=${encodeURIComponent(item)}`);
          });
        } else {
          queryStringParams.push(`${newKey}=${value}`);
        }
      }
    }
  }
  return queryStringParams.join("&");
};

const parseQueryString = (queryString: string) => {
  const params = new URLSearchParams(queryString);
  const result = {};

  params.forEach((value, key) => {
    const keys = key.split("[").map(item => item.replace("]", ""));
    let currentLevel: Record<string, any> = result; // Type annotation added
    keys.forEach((keyPart, index) => {
      if (index === keys.length - 1) {
        if (!currentLevel[keyPart]) {
          currentLevel[keyPart] = [];
        }
        if (Array.isArray(currentLevel[keyPart])) {
          currentLevel[keyPart].push(value);
        } else {
          currentLevel[keyPart] =
            currentLevel[keyPart] !== undefined
              ? Array.isArray(currentLevel[keyPart])
                ? [...currentLevel[keyPart], value]
                : [currentLevel[keyPart], value]
              : value;
        }
      } else {
        currentLevel[keyPart] = currentLevel[keyPart] || {};
        currentLevel = currentLevel[keyPart];
      }
    });
  });

  return removeSingleElementArrays(result);
};

const removeSingleElementArrays = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length === 1) {
      obj[key] = obj[key][0];
    } else if (typeof obj[key] === "object") {
      removeSingleElementArrays(obj[key]);
    }
  }
  return obj;
};
