import dayjs, { ConfigType } from "dayjs";

import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";

export const useGetUserTime = () => {
  const { user } = useAuthContext();
  const getUserPreferredTime = (date: ConfigType) => {
    return user?.config?.showLocalTime ? dayjs(date).local() : dayjs(date).utc();
  };
  return {
    getUserPreferredTime,
    showLocalTime: user?.config?.showLocalTime ?? false,
  };
};
