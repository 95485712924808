import { AxiosError } from "axios";
import React, { FormEventHandler, useContext, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Input, Text } from "@cyanco/components/theme/v3/core";
import { ArrowLeft, Email } from "@cyanco/components/theme/v3/icons";

import { updateUserProfile } from "@/apis/user";

import { validEmail } from "../../utils";
import { AuthContext } from "../AuthContext/AuthContextProvider";

const UpdateEmailComponent = ({ onBack }: { onBack: () => void }) => {
  const theme = useTheme();
  const { user, setUser, userProfile, setUserProfile } = useContext(AuthContext);
  const [email, setEmail] = useState<string>("");
  const [displayEmail, setDisplayEmail] = useState<string>("");
  const [cyanAlertTypes, setCyanAlertTypes] = useState({
    receivePaymentAlerts: false,
  });

  const updateEmail: FormEventHandler = async e => {
    e.preventDefault();

    if (!email || !user?.token) return;

    try {
      await updateUserProfile({
        token: user.token,
        email,
        ...cyanAlertTypes,
      });
      setDisplayEmail(email);
      setEmail("");
    } catch (err: unknown) {
      const axiosError = err as AxiosError;
      if (axiosError.response && (axiosError.response.status === 403 || axiosError.response.status === 401)) {
        setUser({ token: "", wallet: "", cyanWallet: "" });
        setUserProfile({
          email: "",
          discordUsername: "",
          telegramUsername: "",
          receivePaymentAlerts: false,
        });
      }
      console.error(err);
    }
  };

  useEffect(() => {
    if (!user?.token) return;
    setDisplayEmail(userProfile.email);
    setCyanAlertTypes({
      receivePaymentAlerts: userProfile.receivePaymentAlerts,
    });
  }, [userProfile, user]);

  return (
    <Flex direction="column" gap="0.2rem">
      <form onSubmit={updateEmail}>
        <Flex direction="column">
          <div style={{ width: "80%" }}>
            <Flex direction="row" alignItems="center" mb="0.5rem" gap="0.5rem">
              <Email height={12} width={12} color={theme.colors.secondary} />
              <Text size="sm" weight="700" color="secondary">
                {`Email`}
              </Text>
            </Flex>
            <Flex direction="row" alignItems="center" mb="0.5rem">
              <Text size="sm" color="gray30">
                {`Not connected yet`}
              </Text>
            </Flex>
          </div>
          {displayEmail ? (
            <>
              <Flex direction="column" mt="0.5rem" mb="0.5rem" gap="1rem">
                <Text weight="700" color="secondary" size="sm">
                  {`Confirmation email sent!`}
                </Text>
                <ButtonContainer>
                  <Button onClick={onBack}>
                    <Flex direction="row" gap="0.5rem" alignContent="center" alignItems="center">
                      <ArrowLeft height={12} width={12} color="black" />
                      <Text size="sm" weight="700" color="black">
                        {`Go Back`}
                      </Text>
                    </Flex>
                  </Button>
                </ButtonContainer>
              </Flex>
            </>
          ) : (
            <>
              <Flex direction="row" alignItems="center" mt="0.3rem" mb="0.3rem">
                <Input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  placeholder={`your email address`}
                  type="text"
                  value={email}
                  style={{ width: "100%", margin: 0 }}
                />
              </Flex>
              <Flex direction="row" alignItems="center" mt="0.3rem" mb="0.3rem">
                <ButtonContainer>
                  <Button disabled={!validEmail(email)}>
                    <Text size="sm" weight="700" color="black">
                      {`Send Confirmation Email`}
                    </Text>
                  </Button>
                </ButtonContainer>
              </Flex>
            </>
          )}
        </Flex>
      </form>
    </Flex>
  );
};

const ButtonContainer = styled.div`
  height: 50px;
  width: 100%;
`;

export default UpdateEmailComponent;
