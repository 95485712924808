import orderBy from "lodash.orderby";
import { useEffect, useMemo, useRef, useState } from "react";
import { Filter } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Box, Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { SlashButton, SlashText } from "@cyanco/components/theme/v3";
import { Button, Hidden, Input, Option, Select, Text } from "@cyanco/components/theme/v3/core";
import { Grid, List } from "@cyanco/components/theme/v3/icons";

import { ICollectionBe, NftSortAttributes } from "@/apis/collection/types";
import { useBargainCollection } from "@/components/BargainShop/BargainDataContext";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { useBnplQueryParams } from "@/hooks/useBnplQueryParams";
import { useDebounce } from "@/hooks/useDebounce";
import { shortenName } from "@/utils";

import { SweepStatus, useBnplCart } from "../../BnplCartContext";
import { useBnplSupportedCollections } from "../../BnplDataContext";
import { OnSaleNftsView, useBnplSelectors, useBnplViewType } from "../../BnplPageContext";
import { PlanCart } from "./Cart";

export const Selectors: React.FC<{ collection: ICollectionBe; border?: boolean }> = ({ collection, border }) => {
  const theme = useTheme();
  const [tokenInputValue, setTokenInputValue] = useState("");
  const { sweep, setSweep } = useBnplCart();
  const { sortBy } = useBnplQueryParams();
  const { collections } = useBnplSupportedCollections();
  const { collection: bargainCollection } = useBargainCollection();
  const filteredCollections = useMemo(
    () => collections.filter(_collection => _collection.chainId === collection.chainId),
    [collections, collection.chainId],
  );
  const { setSelectedTokenId, showFilterAttributes, setShowFilterAttributes } = useBnplSelectors();
  const { selectedOnSaleNftsView, setSelectedOnSaleNftsView } = useBnplViewType();
  useDebounce(() => setSelectedTokenId(tokenInputValue), [tokenInputValue], 1000);
  const searchInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "k") {
        searchInput.current?.focus();
      }
      if (e.key === "Escape") {
        searchInput.current?.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [tokenInputValue]);

  const navigate = useNavigate();
  return (
    <BnplSelectorWrapper style={{ borderBottom: border ? "1px #2C2C2C solid" : "none" }}>
      <BnplSelectors>
        <DropDowns>
          <Box h="46px">
            <Select
              onChange={value => {
                setTokenInputValue("");
                setSelectedTokenId("");
                if (value === bargainCollection?.address) {
                  navigate("/bargain");
                  return;
                }
                const collection = filteredCollections.find(_collection => _collection.address === value);
                navigate(`/bnpl/${CHAIN_IDS_TO_NAMES[collection?.chainId as SupportedChainId]}/${collection?.slug}`);
              }}
              value={collection.address}
            >
              {bargainCollection && (
                <Option value={bargainCollection.address} key={bargainCollection.address}>
                  {shortenName(bargainCollection.name, 20, 17, 0)}
                </Option>
              )}
              {orderBy(filteredCollections, [_collection => _collection.name.toLowerCase()]).map(_collection => (
                <Option value={_collection.address} key={_collection.address}>
                  {shortenName(_collection.name, 20, 17, 0)}
                </Option>
              ))}
            </Select>
          </Box>
          <Hidden laptopDown>
            <Box h="46px">
              <Select onChange={() => {}} value={sortBy ?? NftSortAttributes.floorAskPrice}>
                <Option value={NftSortAttributes.floorAskPrice}>{NftSortAttributes.floorAskPrice}</Option>
              </Select>
            </Box>
          </Hidden>

          <FilterButton onClick={() => setShowFilterAttributes(!showFilterAttributes)}>
            <Flex gap="0.2rem" alignItems="center">
              <Filter size={18} color={showFilterAttributes ? theme.colors.gray20 : theme.colors.secondary} />
              <Text size="lg" color={showFilterAttributes ? "gray20" : "secondary"}>{`Filter`}</Text>
            </Flex>
          </FilterButton>
        </DropDowns>
        <Box h="46px">
          <Input
            inputRef={searchInput}
            placeholder={`Search NFTs`}
            fontSize="lg"
            fontWeight="400"
            value={tokenInputValue}
            onChange={e => setTokenInputValue(e.target.value)}
          >
            <SlashButton
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "35px",
                marginRight: "-6px",
              }}
            >
              <SlashText size="xs" weight="500">
                {"⌘+k"}
              </SlashText>
            </SlashButton>
          </Input>
        </Box>
        <CartWrapper direction="row" gap="1rem" w="100%" justifyContent="flex-end">
          <Flex>
            <SweepButton
              active={sweep != SweepStatus.Disabled}
              onClick={() =>
                setSweep(sweep == SweepStatus.Disabled ? SweepStatus.EnabledManually : SweepStatus.DisabledManually)
              }
            >
              <Text size="lg" weight={"600"} color="secondary" style={{ whiteSpace: "nowrap" }}>
                🧹 {`Sweep`}
              </Text>
            </SweepButton>
          </Flex>
          <PlanCart planType={"bnpl"} />
          <ViewSelectorWrapper>
            <ViewType
              isActive={OnSaleNftsView.list === selectedOnSaleNftsView}
              onClick={() => setSelectedOnSaleNftsView(OnSaleNftsView.list)}
            >
              <List color={OnSaleNftsView.list === selectedOnSaleNftsView ? theme.colors.white : theme.colors.gray80} />
            </ViewType>
            <ViewType
              isActive={OnSaleNftsView.grid === selectedOnSaleNftsView}
              onClick={() => setSelectedOnSaleNftsView(OnSaleNftsView.grid)}
            >
              <Grid color={OnSaleNftsView.grid === selectedOnSaleNftsView ? theme.colors.white : theme.colors.gray80} />
            </ViewType>
          </ViewSelectorWrapper>
        </CartWrapper>
      </BnplSelectors>
    </BnplSelectorWrapper>
  );
};

export const BnplSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  top: 75px;
  z-index: 6;
  background: ${({ theme }) => theme.backgroundColor};
  padding: 0 10rem 2rem 10rem;
  @media only screen and (max-width: ${breakpoints.desktop}px) {
    padding: 0 18px 2rem 18px;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    position: relative;
    top: 0px;
    padding: 0 calc(0.5rem + 5px);
    background: transparent;
    gap: 0.5rem;
  }
`;

export const BnplSelectors = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1.2fr 1fr;
  width: 100%;
  gap: 1rem;
  padding-top: 1rem;
  @media only screen and (max-width: ${breakpoints.laptop}px) {
    grid-template-columns: auto 1.2fr 1fr;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    padding-top: 1rem;
  }
`;

export const DropDowns = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr auto;
  width: 100%;
  gap: 1rem;
  @media only screen and (max-width: ${breakpoints.laptop}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 0.5rem;
    grid-template-columns: 2fr 1.3fr;
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    gap: 0.5rem;
    grid-template-columns: 2fr auto;
  }
`;

export const ViewSelectorWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.gray20};
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 3px;
  flex-direction: row;
`;

export const ViewType = styled.div<{ isActive: boolean }>`
  width: 46px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: ${props => (props.isActive ? props.theme.colors.gray20 : "transparent")};
  border-radius: 7px;
  height: 100%;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    height: 25px;
  }
  transition: 0.2s;
  svg,
  path,
  rect {
    transition: 0.2s;
  }
  :hover {
    color: ${({ theme, isActive }) => !isActive && theme.colors.secondary};
    svg,
    path,
    rect {
      transition: 0.2s;
      stroke: ${({ theme, isActive }) => !isActive && theme.colors.secondary};
    }
  }
`;

const CartWrapper = styled(Flex)`
  justify-content: flex-end;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    order: -1;
  }
`;

const FilterButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.gray20};
  height: 46px;
  width: 100px;
  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const SweepButton = styled.button<{ active: boolean }>`
  border: 1px solid ${props => props.theme.colors.gray20};
  background-color: ${({ theme, active }) => (active ? theme.colors.gray10 : theme.colors.transparent)};
  border-color: ${({ theme, active }) => (active ? theme.colors.secondary : theme.colors.gray20)} !important;
  border-radius: 7px;
  cursor: pointer;
  align-items: center;
  height: 100%;
  transition: 0.2s ease-in-out;
  padding: 0 1rem;
  &:hover {
    background-color: ${props => props.theme.colors.gray10};
  }
`;
