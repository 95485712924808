import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { BigNumber } from "ethers";

import { IPeerPlan, isPeerPlan } from "@/apis/p2p/types";
import { IPawn } from "@/components/Account/pawn.types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";

import { DAPP_URL } from "../config";
import { bigNumToFloat } from "./index";

dayjs.extend(utc);

export const getGoogleCalendarDescription = (position: IPawn | IBNPL | IPeerPlan, type: string) => {
  const calendarDateFormat = "YYYY-MM-DD HH:mm:ss";
  let createdDate;
  let interestFee;
  let platformFee;
  let purchasePrice = 0;
  const _isPeerPlan = isPeerPlan(position);
  const isBnpl = type === "bnpl";
  const currency = _isPeerPlan ? position.loanBid.currency : position.currency;
  if (_isPeerPlan) {
    createdDate = dayjs.utc(position.createdAt).format(calendarDateFormat);
    interestFee = bigNumToFloat(
      BigNumber.from(position.loanBid.amount).mul(position.loanBid.interestRate).div(10000),
      currency.decimal,
    );
    platformFee = bigNumToFloat(
      BigNumber.from(position.loanBid.amount).mul(position.serviceFeeRate).div(10000),
      currency.decimal,
    );
    purchasePrice = bigNumToFloat(position.loanBid.amount, currency.decimal);
  } else {
    createdDate = dayjs.utc(position.createdAt).format(calendarDateFormat);
    interestFee = bigNumToFloat(position.interestFee, currency.decimal);
    platformFee = bigNumToFloat(position.serviceFeeAmount, currency.decimal);
    if (isBnpl) {
      const bnplData = position as IBNPL;
      purchasePrice = bigNumToFloat(bnplData.price, currency.decimal);
    } else {
      const pawnData = position as IPawn;
      purchasePrice = bigNumToFloat(pawnData.pawnedAmount, currency.decimal);
    }
  }

  const totalPurchasePrice = interestFee + platformFee + purchasePrice;

  return (
    <>
      <b>Amount due: </b> {bigNumToFloat(position.monthlyAmount, currency.decimal).toFixed(5)} {currency.symbol}
      <br />
      <b>Plan type: </b> {_isPeerPlan ? "P2P Plan" : isBnpl ? "BNPL" : "Loan"}
      <br />
      <b>Underlying NFT:</b> {_isPeerPlan ? position.collection.name : position.metadata.collection.name}
      <br />
      <b>Total purchase price: </b> {totalPurchasePrice.toFixed(5)} {currency.symbol}
      <br />
      <b>Start date: </b> {createdDate} UTC
      <br />
      <br />
      ⚠️ Warning: Any missed payments will result in a default of the plan. Your NFT and all paid amounts will be
      forfeited in the event of a default.
      <br />
      <br />
      <b>Make the payment here: </b> {DAPP_URL}
    </>
  );
};
