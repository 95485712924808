import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";

import { axios } from "../axios";
import { IP2PLoanOfferCreation, IP2PUserCreatedOffer, IPeerPlan } from "./types";

export const createP2POffer = async (args: IP2PLoanOfferCreation) => {
  const { chainId, ...rest } = args;
  const chainSlug = CHAIN_IDS_TO_NAMES[chainId];
  await axios.post(`/v2/p2p/loan-bid/create`, {
    ...rest,
    chain: chainSlug,
  });
};

export const fetchUserP2PPlans = async (args: {
  wallet: string;
  chainId: SupportedChainId;
  abortSignal?: AbortSignal;
}): Promise<IPeerPlan[]> => {
  const { chainId, abortSignal, wallet } = args;
  const chainSlug = CHAIN_IDS_TO_NAMES[chainId];
  const response = await axios.get(`/v2/users/${wallet}/p2p/plans`, {
    params: { chain: chainSlug },
    signal: abortSignal,
  });
  return response.data.map((position: IPeerPlan) => ({
    ...position,
    createdAt: new Date(position.createdAt),
    defaultedAt: position.defaultedAt ? new Date(position.defaultedAt) : null,
    planType: "P2P",
    payments: position.payments
      ? position.payments.map(payment => ({
          ...payment,
          createdAt: payment.createdAt ? new Date(payment.createdAt) : null,
        }))
      : [],
  }));
};

export const fetchUserCreatedP2PLoanOffers = async (args: {
  wallet: string;
  chainId: SupportedChainId;
  abortSignal?: AbortSignal;
}): Promise<IP2PUserCreatedOffer[]> => {
  const { chainId, abortSignal, wallet } = args;
  const chainSlug = CHAIN_IDS_TO_NAMES[chainId];
  const response = await axios.get(`/v2/users/${wallet}/p2p/lending-offers`, {
    params: { chain: chainSlug },
    signal: abortSignal,
  });

  return response.data;
};
