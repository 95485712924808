import { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { Button, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { NewTab } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { shortenName } from "@/utils";

import { StyledNftImage } from "./ItemsMetadata";

type IProp = {
  nft: {
    tokenId: string;
    collectionName: string;
    imageUrl?: string | null;
  };
};
export const ItemsMetadataLoading: React.FC<IProp> = ({ nft }) => {
  const theme = useTheme();

  return (
    <Flex gap="10px">
      <div style={{ position: "relative" }}>
        <StyledNftImage src={nft.imageUrl || NoImage} alt="nft" hasImage={!!nft.imageUrl} />
      </div>
      <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 7px 0">
        <Flex justifyContent="space-between">
          <Text color="secondary" size="md" style={{ overflowWrap: "anywhere" }} weight="600">
            {shortenName(nft.collectionName)}
          </Text>
          <Text color="secondary" size="md" style={{ overflowWrap: "anywhere" }} weight="600">
            #{shortenName(nft.tokenId, 10, 5)}
          </Text>
        </Flex>
        <Flex gap="5px" direction="column" w="100%">
          <Flex justifyContent="space-between">
            <Text color="gray0" size="sm" weight="500">
              <SkeletonLine w="50px" h="13px" />
            </Text>
            <Text color="gray0" size="sm" weight="500">
              <SkeletonLine w="50px" h="13px" />
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text color="gray0" size="sm" weight="500">
              <SkeletonLine w="50px" h="13px" />
            </Text>
            <Flex alignItems="center">
              <Button variant="ghost">
                <Text color="gray0" size="sm" weight="500">
                  <SkeletonLine w="50px" h="13px" />
                </Text>
              </Button>
              <NewTab color={theme.colors.gray0} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
