import { BigNumber, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import {
  Button,
  Card,
  SwitchButton,
  SwitchButtonGroup,
  SystemMessage,
  Text,
  useModal,
} from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { IEarlyPaymentInfo, INextPayment } from "@/apis/types";
import { useApeStakingUserAssets } from "@/components/ApeCoinStaking/new/ApeCoinDataContext";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { usePersistedWalletTypeForRelease } from "@/hooks";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, numberWithCommas } from "@/utils";
import { executeBatchRead, getAccountBalanceOfErc20 } from "@/utils/contract";
import { IMappedError } from "@/utils/error/msgs";
import { IBatchReaderData } from "@/utils/types";

import { IPawn, PawnStatuses } from "../../Account/pawn.types";
import { BNPLStatuses, IBNPL } from "../../Bnpl/bnpl.types";
import { PlanMetadata } from "../PlanMetadata";
import { WalletOptionModal } from "../WalletOptionModal";
import { WalletSelectorForNFTRelease } from "../WalletSelectorForNFTRelease";
import { BulkPaymentLoading } from "./BulkPaymentLoading";
import { PlanBulkRepaymentProgressNew } from "./BulkRepaymentProgressNew";
import { BulkEarlyRepaymentBreakdown } from "./EarlyRepaymentBreakdown";
import { BulkRepaymentBreakdown } from "./RepaymentBreakdown";

export enum PaymentOptions {
  regularPay = "pay",
  earlyPay = "earlyPay",
}

export const PlanBulkRepayment = ({
  plans,
  error: _error,
  removePlan,
  onClose,
  paymentOption: _paymentOption,
}: {
  plans: (IPawn | IBNPL)[];
  error?: IMappedError;
  onClose: () => void;
  removePlan: (plan: { address: string; tokenId: string }) => void;
  paymentOption?: PaymentOptions;
}) => {
  const { setModalContent } = useModal();
  const theme = useTheme();
  const { provider, chainId, account, signer } = useWeb3React();
  const { transactions } = useTransactionContext();
  const [plansWithNextPayment, setPlansWithNextPayment] = useState<
    Array<(IPawn | IBNPL) & { nextPayment?: INextPayment; earlyPayment?: IEarlyPaymentInfo; hasError?: boolean }>
  >([]);

  const [paymentOption, setPaymentOption] = useState<PaymentOptions>(_paymentOption ?? PaymentOptions.regularPay);
  const cyanWallet = useCyanWallet();

  const [defaultedError, setDefaultedError] = useState<string | null>();
  const { walletTypeForRelease } = usePersistedWalletTypeForRelease();
  const [releaseWallet, setReleaseWallet] = useState<string>(
    (walletTypeForRelease === "main" ? account : cyanWallet?.walletAddress) ?? "",
  );

  const [error, setError] = useState<IMappedError | null>(_error || null);
  const [warning, setWarning] = useState<{
    msg: string;
    title: string;
    description?: string;
  } | null>(null);
  const [isNotifiedBulkWarning, setIsNotifiedBulkWarning] = useState<boolean>(false);

  const paymentPlanV2 = getPaymentPlanFromChainId(chainId);

  useEffect(() => {
    const _setNextPayment = async () => {
      if (!signer || !chainId || !provider) return;
      const batchPaymentsInfo: IBatchReaderData[] = [];
      const iPaymentPlanContract = f.PaymentPlanV2Factory.createInterface();
      for (const plan of plans) {
        // 1st transaction -> next scheduled payment amount of plan [i]
        // 2nd transaction -> total payment amount to complete plan [i+1]
        batchPaymentsInfo.push(
          {
            interface: iPaymentPlanContract,
            contractAddress: paymentPlanV2,
            functionName: "getPaymentInfoByPlanId",
            params: [plan.planId, false],
          },
          {
            interface: iPaymentPlanContract,
            contractAddress: paymentPlanV2,
            functionName: "getPaymentInfoByPlanId",
            params: [plan.planId, true],
          },
        );
      }
      const batchPaymentsInfoResult = await executeBatchRead(chainId, provider, batchPaymentsInfo);
      let batchPaymentsInfoIndex = 0;

      const result = plans.map(plan => {
        const isActivePlan =
          plan.planType === "BNPL" ? plan.status === BNPLStatuses.Activated : plan.status === PawnStatuses.Activated;
        let nextPayment: INextPayment | undefined;
        let earlyPayment: IEarlyPaymentInfo | undefined;
        const nextPaymentIndex = batchPaymentsInfoIndex;
        const earlyPaymentIndex = batchPaymentsInfoIndex + 1;
        batchPaymentsInfoIndex = batchPaymentsInfoIndex + 2;
        if (batchPaymentsInfoResult[nextPaymentIndex] && isActivePlan) {
          const [payAmountForCollateral, payAmountForInterest, payAmountForService, currentPayment, dueDate] =
            batchPaymentsInfoResult[nextPaymentIndex];
          nextPayment = {
            nextPaymentDate: new Date(dueDate.toNumber() * 1000),
            currentPayment,
            payAmountForCollateral,
            payAmountForService,
            payAmountForInterest,
          };
        }
        if (batchPaymentsInfoResult[earlyPaymentIndex] && isActivePlan) {
          const [payAmountForCollateral, payAmountForInterest, payAmountForService, currentPayment, dueDate] =
            batchPaymentsInfoResult[earlyPaymentIndex];
          earlyPayment = {
            dueDate: new Date(dueDate.toNumber() * 1000),
            remainingPayment: currentPayment,
            payAmountForCollateral,
            payAmountForService,
            payAmountForInterest,
          };
        }
        return {
          ...plan,
          nextPayment,
          earlyPayment,
        };
      });
      setPlansWithNextPayment(result);
    };
    _setNextPayment();
  }, [plans]);

  const showWalletOptions = useMemo(() => {
    return (
      plans.some(plan => plan.totalNumOfPayments - plan.currentNumOfPayments === 1) ||
      paymentOption === PaymentOptions.earlyPay
    );
  }, [plans, paymentOption]);

  const totalRepaymentAmountByCurrency = useMemo(() => {
    return plansWithNextPayment.reduce<{
      [key: string]: {
        totalCost: BigNumber;
        currency: ICurrency;
        formatNumber: number;
        isNativeCurrency: boolean;
      };
    }>((acc, cur) => {
      if (acc[cur.currency.address.toLowerCase()]) {
        acc[cur.currency.address.toLowerCase()] = {
          totalCost: acc[cur.currency.address.toLowerCase()].totalCost.add(cur.nextPayment?.currentPayment || 0),
          currency: cur.currency,
          formatNumber: PlanCreationDecimalFormatMap.get(cur.currency.decimal) || 4,
          isNativeCurrency: cur.currency.address.toLowerCase() === ethers.constants.AddressZero.toLowerCase(),
        };
      } else {
        acc[cur.currency.address.toLowerCase()] = {
          totalCost: cur.nextPayment?.currentPayment || BigNumber.from(0),
          currency: cur.currency,
          formatNumber: PlanCreationDecimalFormatMap.get(cur.currency.decimal) || 4,
          isNativeCurrency: cur.currency.address.toLowerCase() === ethers.constants.AddressZero.toLowerCase(),
        };
      }
      return acc;
    }, {});
  }, [plansWithNextPayment]);

  const totalEarlyRepaymentAmountByCurrency = useMemo(() => {
    return plansWithNextPayment.reduce<{
      [key: string]: {
        totalCost: BigNumber;
        currency: ICurrency;
        formatNumber: number;
        isNativeCurrency: boolean;
      };
    }>((acc, cur) => {
      if (acc[cur.currency.address.toLowerCase()]) {
        acc[cur.currency.address.toLowerCase()] = {
          totalCost: acc[cur.currency.address.toLowerCase()].totalCost.add(cur.earlyPayment?.remainingPayment || 0),
          currency: cur.currency,
          formatNumber: PlanCreationDecimalFormatMap.get(cur.currency.decimal) || 4,
          isNativeCurrency: cur.currency.address.toLowerCase() === ethers.constants.AddressZero.toLowerCase(),
        };
      } else {
        acc[cur.currency.address.toLowerCase()] = {
          totalCost: cur.earlyPayment?.remainingPayment || BigNumber.from(0),
          currency: cur.currency,
          formatNumber: PlanCreationDecimalFormatMap.get(cur.currency.decimal) || 4,
          isNativeCurrency: cur.currency.address.toLowerCase() === ethers.constants.AddressZero.toLowerCase(),
        };
      }
      return acc;
    }, {});
  }, [plansWithNextPayment]);

  const checkPlans = async () => {
    setPlansWithNextPayment(
      plansWithNextPayment.map(plan => {
        if (!plan.nextPayment) {
          setError({
            title: `Payment Error`,
            msg: `Could not proceed payments for some of the plans, please remove red colored items`,
          });
          return {
            ...plan,
            hasError: true,
          };
        }
        if (plan.nextPayment.nextPaymentDate && plan.nextPayment.nextPaymentDate.getTime() < Date.now()) {
          setDefaultedError(
            `Some of the plans have defaulted, and will not accept payments, please remove red colored items`,
          );
          return {
            ...plan,
            hasError: true,
          };
        }
        return plan;
      }),
    );
  };

  const openProgressModal = ({ selectedWalletAddress }: { selectedWalletAddress: string }) => {
    setModalContent({
      title: `Confirm Payment`,
      content: (
        <PlanBulkRepaymentProgressNew
          plans={plansWithNextPayment}
          onClose={onClose}
          removePlan={removePlan}
          releaseWallet={releaseWallet}
          totalRepaymentAmountByCurrency={
            paymentOption === PaymentOptions.earlyPay
              ? totalEarlyRepaymentAmountByCurrency
              : totalRepaymentAmountByCurrency
          }
          paymentOption={paymentOption}
          selectedWalletAddress={selectedWalletAddress}
        />
      ),
      onClose,
    });
  };

  const checkAccountBalanceAndProceed = async (totalAmount: BigNumber, currency: ICurrency) => {
    if (!provider || !account) return;
    const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
      currencyAddress: currency.address,
      provider: provider,
      mainWallet: account,
      chainId,
    });
    if (totalAmount.gt(mainWalletBalance) && totalAmount.gt(cyanWalletBalance)) {
      setError({
        title: `Not enough funds`,
        msg: `Your payment didn’t go through due to lack of funds`,
        description: ` Please double check the amount of crypto in your main wallet.`,
      });
      return;
    }
    if (cyanWallet) {
      setModalContent({
        content: (
          <WalletOptionModal
            items={plans}
            mainWalletBalance={mainWalletBalance}
            cyanWalletBalance={cyanWalletBalance}
            currency={currency}
            paymentAmount={totalAmount}
            totalAmount={BigNumber.from(0)}
            leftAmount={BigNumber.from(0)}
            onNext={({ selectedWalletAddress }: { selectedWalletAddress: string }) => {
              openProgressModal({
                selectedWalletAddress,
              });
            }}
          />
        ),
        title: "Select wallet to pay",
      });
    }
  };

  const { execute: onPay, loading: onPayLoading } = useAsyncCallback(async () => {
    const _totalRepaymentAmountByCurrency =
      paymentOption === PaymentOptions.earlyPay ? totalEarlyRepaymentAmountByCurrency : totalRepaymentAmountByCurrency;
    const currencyValues = Object.values(_totalRepaymentAmountByCurrency);
    if (currencyValues.length === 0) return;
    checkPlans();
    if (plansWithNextPayment.some(plan => plan.hasError)) return;
    if (currencyValues.length === 1) {
      const item = Object.values(_totalRepaymentAmountByCurrency)[0];
      await checkAccountBalanceAndProceed(item.totalCost, item.currency);
      return;
    } else {
      if (currencyValues.some(item => !item.isNativeCurrency) && !isNotifiedBulkWarning) {
        setWarning({
          title: `Bulk Payment Notice`,
          msg: `For ETH based plans, payment will be taken from your Main Wallet by default. For ERC-20 based plans, payment will be taken from your Cyan Wallet. If you wish to change the wallet you pay with, please make loan payments separately.`,
        });
        setIsNotifiedBulkWarning(true);
        return;
      }
      openProgressModal({
        selectedWalletAddress: account || "",
      });
    }
  });

  const hasPendingTxn = transactions.some(txn => plans.some(plan => plan.planId === txn.data?.planId));
  const isEarlyPaymentPossible = plans.some(plan => plan.totalNumOfPayments - plan.currentNumOfPayments > 1);

  const { loading } = useApeStakingUserAssets();

  if (loading) {
    return <BulkPaymentLoading plans={plans} />;
  }

  return (
    <Flex gap="12px" direction="column">
      <PlanMetadata
        plans={plans.map(plan => ({
          tokenId: plan.tokenId,
          collectionName: plan.metadata.collection.name,
          currency: plan.currency,
          imageUrl: plan.metadata.imageUrl,
          address: plan.metadata.collectionAddress,
          leftAmount: BigNumber.from(0),
          totalAmount: BigNumber.from(0),
          isBnpl: plan.planType === "BNPL",
        }))}
        removePlan={removePlan}
      />
      <Flex direction="column" gap="8px">
        <Text size="sm" weight="400" color="gray0">
          {`Payment options`}
        </Text>
        <SwitchButtonGroup<PaymentOptions>
          activeBackground={theme.colors.cyan}
          activeTextColor={theme.colors.black}
          borderColor={theme.colors.gray20}
          value={paymentOption}
          onChange={v => !hasPendingTxn && setPaymentOption(v)}
          hover
        >
          <SwitchButton height="25px" value={PaymentOptions.regularPay}>{`Make Payment`}</SwitchButton>
          <SwitchButton
            height="25px"
            value={PaymentOptions.earlyPay}
            disabled={!isEarlyPaymentPossible}
          >{`Pay off loan`}</SwitchButton>
        </SwitchButtonGroup>
      </Flex>
      <Flex gap="12px" direction="column">
        {!defaultedError && error && (
          <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />
        )}
        {!defaultedError && warning && (
          <SystemMessage variant="warning" title={warning.title} msg={warning.msg} description={warning.description} />
        )}
        {defaultedError && <SystemMessage variant="error" title={`Defaulted items!`} msg={defaultedError} />}
        {paymentOption === PaymentOptions.earlyPay && (
          <BulkEarlyRepaymentBreakdown
            title={"Pay off all loans"}
            plans={plansWithNextPayment}
            totalRepaymentAmountByCurrency={totalEarlyRepaymentAmountByCurrency}
          />
        )}
        {paymentOption === PaymentOptions.regularPay && (
          <BulkRepaymentBreakdown
            plans={plansWithNextPayment}
            totalRepaymentAmountByCurrency={totalRepaymentAmountByCurrency}
          />
        )}
        {showWalletOptions && (
          <WalletSelectorForNFTRelease
            selectedWallet={releaseWallet}
            onReleaseWalletChange={(wallet: string) => setReleaseWallet(wallet)}
            plans={plans}
            isFullPayment={paymentOption === PaymentOptions.earlyPay}
            onMainWalletStateChange={disabled => {
              if (disabled) {
                setWarning({
                  title: `Release Options Limited`,
                  msg: `One or more items you have selected have an existing staking plan. Releasing to your Main Wallet will become available once the staked items have been removed.`,
                });
              } else {
                setWarning(null);
              }
            }}
          />
        )}
        <Card p={"10px"}>
          <Flex justifyContent="space-between" w="100%">
            <Flex direction="column" gap="4px" w="100%">
              <Text size="sm" weight="500" color="gray0">
                {`Make bulk repayment:`}
              </Text>
              <Flex gap="0px" direction="column">
                {paymentOption === PaymentOptions.regularPay &&
                  Object.values(totalRepaymentAmountByCurrency).map(({ totalCost, currency, formatNumber }) => {
                    return (
                      <Text size="lg" weight="500" color="secondary" key={currency.address}>
                        {`${numberWithCommas(bigNumToFixedStr(totalCost, formatNumber, currency.decimal), formatNumber)}
                    ${currency.symbol}`}
                      </Text>
                    );
                  })}
                {paymentOption === PaymentOptions.earlyPay &&
                  Object.values(totalEarlyRepaymentAmountByCurrency).map(({ totalCost, currency, formatNumber }) => {
                    return (
                      <Text size="lg" weight="500" color="secondary" key={currency.address}>
                        {`${numberWithCommas(bigNumToFixedStr(totalCost, formatNumber, currency.decimal), formatNumber)}
                    ${currency.symbol}`}
                      </Text>
                    );
                  })}
              </Flex>
            </Flex>
            {paymentOption === PaymentOptions.regularPay && (
              <Box w="120px" pt={Object.values(totalRepaymentAmountByCurrency).length > 1 ? "16px" : "0"}>
                <Button onClick={onPay} loading={onPayLoading}>{`Pay`}</Button>
              </Box>
            )}
            {paymentOption === PaymentOptions.earlyPay && (
              <Box w="120px" pt={Object.values(totalEarlyRepaymentAmountByCurrency).length > 1 ? "16px" : "0"}>
                <Button onClick={onPay} loading={onPayLoading}>{`Pay`}</Button>
              </Box>
            )}
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};
