import dayjs from "dayjs";
import { useContext, useMemo, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IPeerPlan, PeerPlanStatuses } from "@/apis/p2p/types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { useSortableHeader } from "@/hooks/useSortableHeader";
import { bigNumToFloat, displayInUSD, numberWithCommas, shortenAddress, shortenName } from "@/utils";

import { useAppContext } from "../../../AppContextProvider";
import { AccountDataContext } from "../../AccountDataContext";
import { HEADER_ROW_MIN_HEIGHT } from "../../consts";
import { Refresh } from "../WalletView/Refresh";

export const PlanListHeader = ({
  totalItem,
  sortedPlans,
  updateSortedPlans,
}: {
  totalItem: number;
  sortedPlans: IPeerPlan[];
  updateSortedPlans: (plans: IPeerPlan[]) => void;
}) => {
  const { fetchPeerPlans } = useContext(AccountDataContext);
  const refresh = async () => {
    await fetchPeerPlans();
  };
  const { sort } = useSortableHeader<IPeerPlan>([plan => plan.borrowerAddress], "borrower");

  const handleHeaderClick = (value: ((item: IPeerPlan) => any)[]) => {
    const sortedData = sort(value, sortedPlans);
    updateSortedPlans(sortedData);
  };
  return (
    <HeaderContainer>
      <Flex gap="1rem" alignItems="center">
        <RowText weight="600" color="secondary">{`${totalItem} item${totalItem > 1 ? "s" : ""}`}</RowText>
        <Hidden tabletDown>
          <Refresh onClick={refresh} />
        </Hidden>
      </Flex>{" "}
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([p => p.borrowerAddress])}
        >{`Borrower`}</RowText>
      </Hidden>
      <RowText
        weight="600"
        color="secondary"
        onClick={() => handleHeaderClick([p => p.loanBid.amount])}
      >{`Loan Amount`}</RowText>
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([p => p.dueDate])}
        >{`Duration`}</RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([p => p.loanBid.interestRate])}
        >{`Interest`}</RowText>
      </Hidden>
      <RowText weight="600" color="secondary" onClick={() => handleHeaderClick([p => p.status])}>{`Status`}</RowText>
      <Hidden tabletDown>
        <Flex h={HEADER_ROW_MIN_HEIGHT} />
      </Hidden>
    </HeaderContainer>
  );
};

export const PeerPlanRow = ({ position, onClick }: { position: IPeerPlan; onClick: () => void }) => {
  const { usdPrice } = useAppContext();
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(position.loanBid.currency.decimal) || 4;
  }, [position.loanBid.currency]);
  const apr = useMemo(() => {
    return (365 / (position.loanBid.term / 60 / 60 / 24)) * (position.loanBid.interestRate / 100);
  }, [position]);
  return (
    <Row onClick={onClick}>
      <Flex alignItems="stretch" gap="20px">
        {imageLoading && !imageError && (
          <ImageLoader>
            <SkeletonLine w="100%" h="100%" borderRadius="10px" />
          </ImageLoader>
        )}
        {!imageError ? (
          <StyledImg
            src={position.metadata ? position.metadata.imageUrl : NoImage}
            alt={position.collection.name}
            onError={() => setImageError(true)}
            onLoad={() => setImageLoading(false)}
            style={{
              display: imageLoading ? "none" : "block",
            }}
          />
        ) : (
          <StyledImg src={NoImage} alt={"no-image"} style={{ objectFit: "contain" }} />
        )}
        <Flex gap="5px" justifyContent="space-between">
          <Flex direction="column" justifyContent="space-between">
            <RowText color={"secondary"} weight="500">
              #{shortenName(position.tokenId, 10, 6)}
            </RowText>
            <RowText sub color={"gray0"}>
              {shortenName(position.collection.name)}
            </RowText>
          </Flex>
        </Flex>
      </Flex>
      <Hidden tabletDown>
        <RowText color={"secondary"} weight="500">
          {shortenAddress(position.borrowerAddress)}
        </RowText>
      </Hidden>

      <Flex direction="column" justifyContent="center" gap="0.2rem">
        <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
          {numberWithCommas(bigNumToFloat(position.loanBid.amount, position.loanBid.currency.decimal), formatNumber)}{" "}
          {position.loanBid.currency.symbol}
        </RowText>
        <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
          {displayInUSD(
            bigNumToFloat(position.loanBid.amount, position.loanBid.currency.decimal) *
              usdPrice[position.loanBid.currency.symbol],
          )}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="0.2rem">
          <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
            {getPaymentInterval(position.loanBid.term)}
          </RowText>
          <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
            {dayjs(position.dueDate).format("DD MMMM, YYYY")}
          </RowText>
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="0.2rem">
          <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
            {position.loanBid.interestRate / 100}%
          </RowText>
          <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
            {`APR: ${apr.toFixed(2)}%`}
          </RowText>
        </Flex>
      </Hidden>
      {position.status === PeerPlanStatuses.ACTIVE && (
        <RowText weight="500" color="green">
          {`Live`}
        </RowText>
      )}

      {position.status === PeerPlanStatuses.DEFAULTED && (
        <RowText weight="500" color="red">
          {`Defaulted`}
        </RowText>
      )}

      {position.status === PeerPlanStatuses.COMPLETED && (
        <RowText weight="500" color="cyan">
          {`Completed`}
        </RowText>
      )}
      <Hidden tabletDown>
        <Flex w="fit-content">
          {position.status === PeerPlanStatuses.DEFAULTED ? (
            <PayButton
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
              size="xs"
            >
              {`Complete`}
            </PayButton>
          ) : (
            <>
              <PayButton
                onClick={e => {
                  e.stopPropagation();
                  onClick();
                }}
                size="xs"
              >
                {`Edit Loan`}
              </PayButton>
            </>
          )}
        </Flex>
      </Hidden>
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  grid-template-columns: 1.7fr 1fr 1.3fr 1.3fr 1fr 1fr 0.8fr;
  padding: 13px 15px 13px 15px;
  background-color: transparent;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2.5fr 1.3fr 1.3fr" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
export const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;
export const HeaderContainer = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    padding: 7px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
export const StyledImg = styled.img`
width: 36px;
height: 36px;
${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 24 }])}
border-radius: ${({ theme }) => theme.borderRadius};
} `;

const ImageLoader = styled.div`
  width: 36px;
  height: 36px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 24 }])}
`;

export const PayButton = styled(Button)`
  border-radius: 5px;
  padding: 0.3rem 0.7rem;
`;
