import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SubTitle, Text } from "@cyanco/components/theme/v3";
import { ArrowLeft } from "@cyanco/components/theme/v3/icons";

import { CHAIN_NAMES_TO_IDS, ISupportedChainNames } from "@/constants/chains";

import { useSupportedCollections } from "../AppContextProvider";
import { useUserAssets } from "./LoanPageProvider";
import { AssetMakeLoanOffer } from "./asset";

export const AssetTakeLoanOffer = () => {
  const { collectionAddress, chain, tokenId } = useParams();
  const { collections } = useSupportedCollections();
  const { userAssets } = useUserAssets();
  const collection = collections.find(
    collection =>
      collection.address === collectionAddress &&
      chain &&
      CHAIN_NAMES_TO_IDS[chain as ISupportedChainNames] === collection.chainId,
  );
  const listedNft = userAssets.assets.find(
    nft => nft.address.toLowerCase() === collectionAddress?.toLowerCase() && chain && nft.tokenId === tokenId,
  );
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [collectionAddress]);

  if (!collection || !listedNft) return null;
  return (
    <Container direction="column">
      <GappedContainer direction="column">
        <div>
          <BackToCollectionsButton variant="ghost" onClick={() => navigate(`/loans`)}>
            <Flex alignItems="center" gap="2px">
              <BackToCollectionsIcon />
              <BackToCollectionsText color="gray0" size="sm">
                {`Your Assets`}
              </BackToCollectionsText>
            </Flex>
          </BackToCollectionsButton>
        </div>
        <Flex justifyContent="space-between" w="100%" alignItems="center">
          <Hidden tabletDown>
            <SubTitle>{`NFT Loan Offers received`}</SubTitle>
          </Hidden>
        </Flex>
      </GappedContainer>
      <AssetMakeLoanOffer collection={collection} nft={listedNft} />
    </Container>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1440px) / 2);
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 2 }, { [breakpoints.tablet]: 1 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopL]: "30px 50px" }, { [breakpoints.tablet]: "0px" }])}
${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 0 5rem 0" },
    { [breakpoints.tablet]: "0rem 0.5rem 6rem 0.5rem" },
  ])}
`;

const GappedContainer = styled(Flex)`
  align-items: start;
  justify-content: start;
  gap: 1rem;
  padding: 0 10rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 0.5 }])}
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0.5rem 5px" },
  ])}
`;

const BackToCollectionsText = styled(Text)`
  transition: 0.2s ease-in-out;
`;
const BackToCollectionsIcon = styled(ArrowLeft)`
  stroke: ${props => props.theme.colors.gray0};
  transition: 0.08s ease-in-out;
`;
const BackToCollectionsButton = styled(Button)`
  &:hover {
    ${BackToCollectionsIcon} {
      stroke: ${props => props.theme.colors.secondary};
    }
    ${BackToCollectionsText} {
      color: ${props => props.theme.colors.secondary};
    }
  }
`;
