import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, CopyButton, SubTitle, SwitchButton, SwitchButtonGroup, Text } from "@cyanco/components/theme/v3";
import { Globe, Twitter } from "@cyanco/components/theme/v3/icons";
import { EtherscanGray } from "@cyanco/components/theme/v3/images";

import { ICollectionBe } from "@/apis/collection/types";
import { IP2PListedNft } from "@/apis/p2p/types";
import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS } from "@/constants/chains";

import {
  bigNumToFixedStr,
  bigNumToFloat,
  getChainExplorerURL,
  jumpToLink,
  numberWithCommas,
  shortenAddress,
} from "../../../utils";
import { useP2PLoanOffers } from "../LendDataContext";
import { LendPageAssetView } from "../LendPageContext";
import { OfferMakerCard } from "../collection/LoanOffers/OfferMakerCard";
import { LoanOffers } from "./LoanOffers";

export const AssetMakeLoanOffer: React.FC<{
  nft: IP2PListedNft;
  collection: ICollectionBe;
}> = ({ nft, collection }) => {
  const { provider } = useWeb3React();
  const { loanOffers, loanOffersLoading } = useP2PLoanOffers();
  const { usdPrice } = useAppContext();
  const [selectedOffersView, setSelectedOffersView] = useState<LendPageAssetView>(LendPageAssetView.current);
  const theme = useTheme();
  const getLendPageAssetViewStat = (value: number | string | null | undefined, postfix?: string, prefix?: string) => {
    return value !== null && value !== undefined ? `${prefix || ""}${numberWithCommas(value)} ${postfix || ""}` : "-";
  };
  const nftOffers = useMemo(() => {
    return loanOffers.filter(
      offer => (offer.tokenId === nft.tokenId && offer.collectionAddress) || offer.tokenId === null,
    );
  }, [loanOffers, nft]);
  const nftOffersActive = useMemo(() => {
    return nftOffers.filter(offer => offer.isActive);
  }, [nftOffers]);
  const nftOffersInActive = useMemo(() => {
    return nftOffers.filter(offer => !offer.isActive);
  }, [nftOffers]);
  const avgLoanAmount = useMemo(() => {
    if (nftOffers.length === 0) return 0;
    return (
      nftOffers.reduce(
        (acc, cur) => acc + bigNumToFloat(cur.amount, cur.currency.decimal) * usdPrice[cur.currency.symbol],
        0,
      ) /
      nftOffers.length /
      usdPrice[nft.currency.symbol]
    );
  }, [nftOffers, usdPrice]);
  const avgApr = useMemo(() => {
    if (nftOffers.length === 0) return 0;
    return (
      nftOffers.reduce((acc, cur) => acc + (365 / (cur.term / 60 / 60 / 24)) * (cur.interestRate / 100), 0) /
      nftOffers.length
    );
  }, [nftOffers]);
  const [blockchainTimestamp, setBlockchainTimestamp] = useState(0);
  useEffect(() => {
    const setTime = async () => {
      if (!provider) return;
      const lastBlockNum = await provider.getBlockNumber();
      const lastBlock = await provider.getBlock(lastBlockNum);
      const lastTimestamp = lastBlock.timestamp * 1000;
      setBlockchainTimestamp(lastTimestamp);
    };
    setTime();
  }, [provider]);
  return (
    <Container>
      <Flex direction="column" w="100%" gap="2rem">
        <LendPageAssetViewTopWrapper>
          <LendPageAssetViewTopWrapperSub direction="column" w="100%">
            <Flex w="100%" gap="1rem" alignItems="center">
              <LendPageAssetViewImage src={nft.imageUrl} />
              <LendPageAssetViewInfoTitleContainer>
                <div>
                  <Flex alignItems="center" gap="15px">
                    <Flex direction="column" gap="5px">
                      <Flex gap="1rem">
                        <SubTitle>{collection.name}</SubTitle>
                        <LendPageAssetViewTitleSymbol>#{nft.tokenId}</LendPageAssetViewTitleSymbol>
                      </Flex>
                      <Flex gap="5px" wrap="wrap">
                        <LendPageAssetViewDetailSubText color="gray0">
                          {collection.tokenCount} {`items`}
                        </LendPageAssetViewDetailSubText>
                        <LendPageAssetViewDetailSubText color="gray0">|</LendPageAssetViewDetailSubText>
                        <LendPageAssetViewDetailSubText color="gray0">
                          {`created`} {dayjs(collection.createdAt).format("MMMM YYYY")}
                        </LendPageAssetViewDetailSubText>
                        <LendPageAssetViewDetailSubText color="gray0">|</LendPageAssetViewDetailSubText>
                        <LendPageAssetViewDetailSubText color="gray0">
                          {CHAIN_INFO[collection.chainId as typeof CYAN_SUPPORTED_CHAIN_IDS[number]].label || ""}
                        </LendPageAssetViewDetailSubText>
                      </Flex>
                    </Flex>
                  </Flex>
                </div>
              </LendPageAssetViewInfoTitleContainer>
            </Flex>
          </LendPageAssetViewTopWrapperSub>
          <LendPageAssetViewStakeMobile>
            <OfferMakerContainer>
              <Text color="secondary" weight="700" size="md">
                {`Make NFT Loan Offer`}
              </Text>
              <OfferMakerCard nft={nft} collection={collection} showNft={false} />
            </OfferMakerContainer>{" "}
          </LendPageAssetViewStakeMobile>
        </LendPageAssetViewTopWrapper>
        <LendPageAssetViewInfoContainer direction="column" gap="2rem">
          <Flex direction="column" gap="1.5rem">
            <LendPageAssetViewStatsGapWrapper gap="2.5rem" alignItems="center" wrap="wrap">
              <DaliyLendPageAssetViewStat
                statName={`Appraisal value`}
                statValue={
                  nft.appraisalValue
                    ? `${numberWithCommas(bigNumToFloat(nft.appraisalValue, nft.currency.decimal), 3)} ${
                        nft.currency.symbol
                      }`
                    : "N/A"
                }
              />

              <DaliyLendPageAssetViewStat
                statName={`Collection Floor`}
                statValue={
                  collection.floorAsk.price
                    ? getLendPageAssetViewStat(
                        bigNumToFixedStr(
                          collection.floorAsk.price.amount.raw,
                          2,
                          collection.floorAsk.price.currency.decimals,
                        ),
                        collection.floorAsk.price.currency.symbol,
                      )
                    : "N/A"
                }
              />
              <DaliyLendPageAssetViewStat
                statName={`Avg. Loan Amt.`}
                statValue={`${avgLoanAmount.toFixed(3)} ${nft.currency.symbol}`}
              />
              <DaliyLendPageAssetViewStat statName={`Avg. APR.`} statValue={getLendPageAssetViewStat(avgApr, "%")} />
            </LendPageAssetViewStatsGapWrapper>
            <Flex gap="3px" alignItems="center" w="fit-content">
              <CopyButton value={collection.address}>
                <Text size="xxs" weight="500" color="secondary">
                  {shortenAddress(collection.address)}
                </Text>
              </CopyButton>
              <IconButton
                variant="ghost"
                disabled={!collection.twitterAccount}
                onClick={() =>
                  collection.twitterAccount && jumpToLink(`https://twitter.com/${collection.twitterAccount}`)
                }
              >
                <Twitter width={16} height={16} color={theme.colors.secondary} />
              </IconButton>
              <IconButton
                variant="ghost"
                disabled={!collection.externalUrl}
                onClick={() => collection.externalUrl && jumpToLink(collection.externalUrl)}
              >
                <Globe width={16} height={16} color={theme.colors.secondary} />
              </IconButton>

              <IconButton
                variant="ghost"
                onClick={() => jumpToLink(`${getChainExplorerURL(collection.chainId)}/address/${collection.address}`)}
              >
                <img src={EtherscanGray} width={16} height={16} />
              </IconButton>
            </Flex>
          </Flex>
        </LendPageAssetViewInfoContainer>
        <ContainerOffers direction="column" gap="1.5rem" mt="2.5rem">
          <SubTitle>{`NFT Loan Offers received`}</SubTitle>
          <Flex direction="column" gap="1rem">
            <Box h="22px" w="fit-content">
              <SwitchButtonGroup<LendPageAssetView> value={selectedOffersView} onChange={setSelectedOffersView} hover>
                <SwitchButton height="22px" value={LendPageAssetView.current}>{`Current`}</SwitchButton>
                <SwitchButton height="22px" value={LendPageAssetView.historical}>{`Historical`}</SwitchButton>
              </SwitchButtonGroup>
            </Box>
            <div
              style={{
                display: selectedOffersView === LendPageAssetView.current ? "block" : "none",
              }}
            >
              <LoanOffers
                collection={collection}
                blockchainTimestamp={blockchainTimestamp}
                offers={nftOffersActive}
                loading={loanOffersLoading}
              />
            </div>
            <div
              style={{
                display: selectedOffersView === LendPageAssetView.historical ? "block" : "none",
              }}
            >
              <LoanOffers
                collection={collection}
                blockchainTimestamp={blockchainTimestamp}
                offers={nftOffersInActive}
                loading={loanOffersLoading}
              />
            </div>
          </Flex>
        </ContainerOffers>
      </Flex>
      <LendPageAssetViewStakeDesktop>
        <OfferMakerContainer>
          <Text color="secondary" weight="700" size="md">
            {`Make NFT Loan Offer`}
          </Text>
          <OfferMakerCard nft={nft} collection={collection} showNft={false} />
        </OfferMakerContainer>
      </LendPageAssetViewStakeDesktop>
    </Container>
  );
};

const DaliyLendPageAssetViewStat = ({ statName, statValue }: { statName: string; statValue: string }) => {
  return (
    <Flex direction="column" gap="3px">
      <Text color="secondary" size="md" weight="700">
        {statValue}
      </Text>
      <Text size="xs" color="secondary" weight="400">
        {statName}
      </Text>
    </Flex>
  );
};

const ContainerOffers = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0 5px" }])}
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  gap: 4rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.laptopL]: 2 }, { [breakpoints.tablet]: 0.5 }])}
`;

const LendPageAssetViewInfoContainer = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "2rem 5px 0rem 5px" }])}
`;

const LendPageAssetViewInfoTitleContainer = styled(Flex)`
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
  }
`;

const LendPageAssetViewDetailSubText = styled(Text)`
  font-size: 12px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 10 }])};
  font-weight: 400;
`;

const LendPageAssetViewStatsGapWrapper = styled(Flex)`
  gap: 2.5rem;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    justify-content: space-between;
    gap: 1.4rem;
  }
`;

const LendPageAssetViewTopWrapper = styled(Flex)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem 0;
    padding: 0 5px;
  }
  padding: 0 18px;
`;
const LendPageAssetViewTopWrapperSub = styled(Flex)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 20px;
  }
`;

const LendPageAssetViewImage = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 12px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 45px;
    height: 45px;
  }
`;

const LendPageAssetViewTitleSymbol = styled(SubTitle)`
  color: ${({ theme }) => theme.colors.gray0};
`;

const LendPageAssetViewStakeMobile = styled.div`
  display: none;
  ${getStyleWithMediaQuery("display", "", [{ [breakpoints.tablet]: "block" }])};
`;

const LendPageAssetViewStakeDesktop = styled.div`
  display: block;
  height: 100%;
  ${getStyleWithMediaQuery("padding-top", "", [
    {
      [breakpoints.tablet]: "0px",
    },
  ])};
  ${getStyleWithMediaQuery("display", "", [{ [breakpoints.tablet]: "none" }])};
`;

const IconButton = styled(Button)`
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;
const OfferMakerContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-style: solid;
  padding: 1rem;
  max-width: 500px;
  position: sticky;
  z-index: 70;
  top: 100px;
  height: fit-content;
  ${getStyleWithMediaQuery("position", "", [
    {
      [breakpoints.tablet]: "relative",
    },
  ])};
`;
