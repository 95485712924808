import { useAppContext } from "@/components/AppContextProvider";
import { P2PCollectionAsset } from "@/components/P2P/LendCollectionAsset";
import { Experiments } from "@/utils/experimentList";

const P2PCollectionAssetPage = () => {
  const { experiment } = useAppContext();
  if (!experiment.result || !experiment.result[Experiments.P2P]) return null;
  return <P2PCollectionAsset />;
};
export default P2PCollectionAssetPage;
