import dayjs from "dayjs";
import { ethers } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { Button, Flex, SystemMessage, Text, useModal } from "@cyanco/components/theme";

import { IPrivateSale } from "@/apis/private-sale";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedCurrenciesByChain } from "@/config";
import { bigNumToFloat, isAddress } from "@/utils";
import { IMappedError } from "@/utils/error/msgs";

import { ISelectedNft } from "../Account/components/WalletView/NftCard";
import { useAppContext, useSupportedCollections } from "../AppContextProvider";
import { useUserAssets } from "../Loans/LoanPageProvider";
import { AddressInput, DateSelector, PrivateSaleInput } from "./CommonComponents";
import { ItemsMetadata } from "./ItemMetadata";
import { PrivateSaleCancelStepper } from "./Stepper/PrivateSaleCancelStepper";
import { PrivateSaleStepper } from "./Stepper/PrivateSaleStepper";
import { PrivateSaleUpdateStepper } from "./Stepper/PrivateSaleUpdateStepper";
import { ExpiryOptions, IExpiryDate } from "./utils";

export const PrivateSaleModal = ({
  nft,
  err,
  privateSale,
}: {
  nft: ISelectedNft;
  privateSale?: IPrivateSale | null;
  err?: IMappedError;
}) => {
  const { chainId } = useWeb3React();
  const { collections } = useSupportedCollections();
  const { setModalContent } = useModal();
  const theme = useTheme();
  const [buyer, setBuyer] = useState<string | undefined>(privateSale?.buyerAddress);
  const [isUpdating, setIsUpdating] = useState(false);

  const [price, setPrice] = useState<string | undefined>(
    privateSale?.price && formatUnits(privateSale.price, nft.currency.decimal),
  );
  const [currency, setCurrency] = useState<{
    address: string;
    decimal: number;
    symbol: SupportedCurrenciesType;
  }>({
    address: privateSale ? nft.currency.address : ethers.constants.AddressZero,
    decimal: privateSale ? nft.currency.decimal : 18,
    symbol: privateSale ? (nft.currency.symbol as SupportedCurrenciesType) : "ETH",
  });
  const { isDemoAsset } = useUserAssets();
  const isDemo = useMemo(() => {
    return isDemoAsset(nft.tokenId, nft.address);
  }, [nft]);
  let initDate = ExpiryOptions[0];
  if (privateSale) {
    const diff = dayjs.utc(privateSale.expiryDate).diff(dayjs.utc(privateSale.signedDate), "second");
    initDate = ExpiryOptions.find(option => option.value >= diff) || ExpiryOptions[0];
  }

  const [date, setDate] = useState<IExpiryDate>(initDate);

  const supportedCurrencies = SupportedCurrenciesByChain[chainId];
  const { usdPrice: usdPrices } = useAppContext();

  const usdPrice = useMemo(() => {
    if (!usdPrices || !usdPrices[currency.symbol] || !price) return 0;
    return Number(usdPrices[currency.symbol]) * Number(price);
  }, [usdPrices, price, currency]);

  const [error, setError] = useState<IMappedError | null>(err || null);

  const chosenCollection = useMemo(() => {
    return collections.find(collection => collection.address.toLowerCase() == nft.address.toLowerCase());
  }, [collections, nft.address]);

  const fromFloor = useMemo(() => {
    if (!usdPrices || !usdPrices["ETH"] || !usdPrice || !chosenCollection?.floorAsk || !chosenCollection.floorAsk.price)
      return;
    const floorUsdPrice = Number(usdPrices["ETH"]) * bigNumToFloat(chosenCollection.floorAsk.price.amount.raw);
    return (usdPrice * 100) / floorUsdPrice - 100;
  }, [chosenCollection, usdPrice]);

  const createPrivateSale = () => {
    setError(null);
    if (!price || !buyer || !chosenCollection || !chosenCollection.cyanSignature) return;
    if (!isAddress(buyer)) {
      setError({
        title: "Invalid buyer address",
        msg: "Invalid buyer address. Please double-check the buyer address.",
      });
      return;
    }
    setModalContent({
      title: `Processing Private Sale`,
      content: (
        <PrivateSaleStepper
          price={price}
          currencyAddress={currency.address}
          buyer={buyer}
          collectionSignature={chosenCollection.cyanSignature}
          expiryAt={Math.floor(Date.now() / 1000) + date.value}
          currency={currency}
          nft={nft}
        />
      ),
    });
  };
  const onChangeDate = (name: string) => {
    const newDate = ExpiryOptions.find(option => option.name == name);
    if (newDate) setDate(newDate);
  };

  const cancel = () => {
    if (!privateSale || !chosenCollection) return;
    privateSale.collectionSignature = chosenCollection.cyanSignature;
    setModalContent({
      title: `Processing Private Sale`,
      content: <PrivateSaleCancelStepper nft={nft} privateSale={privateSale} />,
    });
  };

  const checkDifferent = () => {
    if (!privateSale) return true;
    if (!price || !buyer) return false;
    const oldPrice = formatUnits(privateSale.price, nft.currency.decimal);
    if (
      privateSale.currencyAddress.toLowerCase() === currency.address.toLowerCase() &&
      oldPrice == price &&
      buyer.toLowerCase() == privateSale.buyerAddress.toLowerCase() &&
      initDate.value == date.value
    ) {
      return false;
    }
    return true;
  };

  const update = () => {
    if (!privateSale || !buyer || !price) return;
    if (!isAddress(buyer)) {
      setError({
        title: "Invalid buyer address",
        msg: "Invalid buyer address. Please double-check the buyer address.",
      });
      return;
    }

    if (chosenCollection && chosenCollection.cyanSignature) {
      privateSale.collectionSignature = chosenCollection.cyanSignature;
    }

    setModalContent({
      title: `Processing Private Sale`,
      content: (
        <PrivateSaleUpdateStepper
          nft={nft}
          price={price}
          buyerAddress={buyer}
          currencyAddress={currency.address}
          currency={currency}
          privateSale={privateSale}
          expiryAt={Math.floor(Date.now() / 1000) + date.value}
        />
      ),
    });
  };

  const onChangeCurrency = (newAddress: string) => {
    const c = supportedCurrencies.find(({ address }) => address == newAddress);
    if (c) setCurrency(c);
  };
  const existChange = () => {
    if (privateSale) {
      setPrice(formatUnits(privateSale.price, nft.currency.decimal));
      setBuyer(privateSale.buyerAddress);
      setDate(initDate);
      setCurrency({
        address: nft.currency.address,
        decimal: nft.currency.decimal,
        symbol: nft.currency.symbol as SupportedCurrenciesType,
      });
    }

    setIsUpdating(false);
  };

  return (
    <Flex gap="18px" direction="column">
      <ItemsMetadata item={nft} buyer={buyer} price={price} currency="ETH" isCreating={false} />

      <Flex gap="2px" direction="column">
        {error && (
          <div style={{ marginBottom: "18px" }}>
            <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />
          </div>
        )}
        <Flex justifyContent="space-between" alignItems="center" style={{ marginBottom: "11px" }}>
          <Text color="gray0" size="sm">{`Price`}</Text>
          {privateSale && !isUpdating && (
            <Flex gap="10px">
              <StyledButton color="#6C6C6C" onClick={() => setIsUpdating(true)}>
                <Text size="xs" color="gray0">{`CHANGE`}</Text>
              </StyledButton>
              <StyledButton color="red" onClick={cancel}>
                <Text size="xs" color="red">{`CANCEL SALE`}</Text>
              </StyledButton>
            </Flex>
          )}
          {privateSale && isUpdating && (
            <StyledButton color="#6C6C6C" onClick={existChange}>
              <Text size="xs" color="gray0">{`EXIT CHANGE`}</Text>
            </StyledButton>
          )}
        </Flex>

        <PrivateSaleInput
          price={price}
          onInputChange={setPrice}
          currencies={supportedCurrencies}
          currency={currency}
          onCurrencyChange={onChangeCurrency}
          disabled={!!privateSale && !isUpdating}
          active={!privateSale || isUpdating}
        />
        <ExtraWrapper alignItems="center" justifyContent="space-between">
          <Text color="gray0" weight="400" size="xs">
            Current floor:{" "}
            {chosenCollection &&
              chosenCollection.floorAsk &&
              chosenCollection.floorAsk.price &&
              `${bigNumToFloat(chosenCollection.floorAsk.price.amount.raw)} ETH`}
          </Text>
          <Text color="gray0" weight="400" size="xs">
            {fromFloor ? fromFloor.toFixed(2) : "-"}% from floor
          </Text>
        </ExtraWrapper>
      </Flex>

      <Flex gap="13px" direction="column">
        <Text color="gray0" size="sm">{`Buyer's Address`}</Text>
        <Flex gap="2px" direction="column">
          <AddressInput
            placeholder="0x..."
            value={buyer}
            onChange={e => {
              setBuyer(e.target.value);
            }}
            style={{
              borderRadius: "10px 10px 0px 0px",
              color: !!privateSale && !isUpdating ? theme.colors.gray0 : undefined,
            }}
            disabled={!!privateSale && !isUpdating}
          />
          <ExtraWrapper alignItems="center" justifyContent="space-between">
            <Text color="gray0" weight="400" size="xs">
              Buyer: {buyer}
            </Text>
          </ExtraWrapper>
        </Flex>
      </Flex>

      <Flex gap="2px" direction="column">
        <Text color="gray0" size="sm" style={{ marginBottom: "11px" }}>{`You Receive`}</Text>
        <PrivateSaleInput
          disabled
          price={price}
          currencies={currency ? [currency] : []}
          currency={currency}
          onCurrencyChange={_ => {}}
          onInputChange={_ => {}}
          hideDown={true}
          active={false}
        />
        <ExtraWrapper alignItems="center" justifyContent="space-between">
          <Text color="gray0" weight="400" size="xs">
            ${usdPrice.toFixed(2)} total
          </Text>
          <Text color="gray0" weight="400" size="xs">
            0% listing fees
          </Text>
        </ExtraWrapper>
      </Flex>

      <Flex gap="13px" justifyContent="space-between" alignItems="center">
        <Text color="gray0" size="md">{`Expires after`}</Text>
        <DateSelector
          date={date}
          dates={ExpiryOptions}
          onChangeDate={onChangeDate}
          disabled={!!privateSale && !isUpdating}
        />
      </Flex>
      <StyledConfirmButton
        onClick={!isUpdating ? createPrivateSale : update}
        disabled={!buyer || !price || !checkDifferent() || isDemo}
      >
        <Text color="black" size="sm" weight="700">
          {!isUpdating ? `Submit` : `Update`}
        </Text>
      </StyledConfirmButton>
    </Flex>
  );
};

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;

const StyledButton = styled(Button)<{ color: string }>`
  border: 2px solid;
  border-color: ${({ color }) => color};
  background: transparent;
  border-radius: 6px;
  width: auto;
  max-width: 120px;
  height: 25px;
  :hover {
    background: none;
    border-color: ${({ color }) => color};
  }
`;

const ExtraWrapper = styled(Flex)`
  border-radius: 0 0 10px 10px;
  padding: 11px 15px;
  background-color: ${({ theme }) => theme.colors.gray10};
`;
