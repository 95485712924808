import { useWeb3React } from "@web3-react/core";
import { useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { NewTab } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { ApePlanStatuses } from "@/apis/ape-plans";
import { PoolId } from "@/config";
import { bigNumToFixedStr, bigNumToFloat, getChainExplorerURL, jumpToLink, shortenName } from "@/utils";

import { HistoryDate, RowLoadingText, RowText } from "./components";

type IProps = {
  imageUrl: string;
  poolId: PoolId;
  collectioName: string;
  wallet: string;
  createdAt: Date;
  tokenId: string;
  amount: string;
  rewards?: number;
  status: ApePlanStatuses;
  transactionHash?: string;
  rewardStakeToCyanVault: boolean;
  planType?: "Loan" | "BNPL";
};

export const HistoryRow = ({ history, onClick }: { history: IProps; onClick?: () => void }) => {
  const { chainId } = useWeb3React();
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const txnUrl = `${getChainExplorerURL(chainId)}/tx`;
  const theme = useTheme();
  const stakeType = useMemo(() => {
    if (history.poolId === PoolId.COIN) return "Vault";

    if (bigNumToFloat(history.amount, 18) == 0) return "Owned";
    return "Borrowed";
  }, []);

  const handleClick = () => {
    if (onClick) onClick();
    else if (history.transactionHash) {
      jumpToLink(`${txnUrl}/${history.transactionHash}`);
    }
  };

  return (
    <Row onClick={handleClick} style={{ cursor: history.transactionHash ? "pointer" : "default" }}>
      <Flex alignItems="center" gap="20px">
        {imageLoading && !imageError && (
          <ImageLoader>
            <SkeletonLine w="100%" h="100%" borderRadius="10px" />
          </ImageLoader>
        )}
        {!imageError ? (
          <StyledImg
            src={history.imageUrl}
            alt={history.collectioName}
            onError={() => setImageError(true)}
            onLoad={() => setImageLoading(false)}
            style={{
              display: imageLoading ? "none" : "block",
            }}
          />
        ) : (
          <StyledImg src={NoImage} alt={"no-image"} style={{ objectFit: "contain" }} />
        )}
        <Flex direction="column" justifyContent="space-between">
          {history.poolId != PoolId.COIN ? (
            <>
              <RowText color={"secondary"} weight="500">
                #{shortenName(history.tokenId, 10, 6)}
              </RowText>
              <Flex gap="5px" alignItems="flex-end" justifyContent="space-between">
                <RowText color={"gray0"} sub>
                  {shortenName(history.collectioName)}
                </RowText>
                {history.planType && (
                  <Flex
                    style={{
                      background: theme.colors.secondary,
                      borderRadius: "4px",
                    }}
                    p={"2px 4px"}
                    h={"fit-content"}
                  >
                    <Text color="primary" size="xxs" weight="700">
                      {history.planType === "BNPL" ? `BNPL` : `Loan`}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </>
          ) : (
            <>
              <RowText color="secondary" weight="500">
                ApeCoin
              </RowText>
            </>
          )}
        </Flex>
      </Flex>

      <Hidden tabletDown>
        <RowText weight="500" color="secondary">
          {history.rewardStakeToCyanVault ? `Cyan` : "Ape Staking"}
        </RowText>
      </Hidden>

      <Flex direction="column" justifyContent="center">
        <RowText weight="500" color="secondary" style={{ overflowWrap: "anywhere" }}>
          {bigNumToFixedStr(history.amount, 0, 18)} APE
        </RowText>
      </Flex>
      <Flex direction="column" justifyContent="center">
        <RowText weight="500" color="secondary" style={{ overflowWrap: "anywhere" }}>
          {(history.rewards || 0).toFixed(2)} APE
        </RowText>
      </Flex>

      <Hidden tabletDown>
        <RowText color="secondary" weight="500">
          {stakeType}
        </RowText>
      </Hidden>

      {history.status === ApePlanStatuses.Started && !history.rewards && (
        <RowText weight="600" color="gray0">{`Staked`}</RowText>
      )}
      {history.status === ApePlanStatuses.Completed && !history.rewards && (
        <RowText color="green" weight="600">{`Completed`}</RowText>
      )}
      {history.rewards && <RowText color="green" weight="600">{`Auto-compound`}</RowText>}

      <Hidden tabletDown>
        <HistoryDate date={history.createdAt} />
      </Hidden>
      <Hidden tabletDown>
        <Button
          variant="ghost"
          style={{ opacity: history.transactionHash ? "1" : "0.5", width: "fit-content" }}
          onClick={e => {
            e.stopPropagation();
            history.transactionHash && jumpToLink(`${txnUrl}/${history.transactionHash}`);
          }}
        >
          <NewTab color={theme.colors.secondary} />
        </Button>
      </Hidden>
    </Row>
  );
};

export const HistoryHeader = ({ totalItems }: { totalItems: number }) => {
  return (
    <HeaderContainer>
      <Flex alignItems="center" gap="5px" h="20.5px">
        <RowText weight="600" color="secondary">{`${totalItems} item${totalItems > 1 ? "s" : ""}`}</RowText>
      </Flex>
      <RowText color="secondary" weight="600">{`Destination`}</RowText>
      <Hidden tabletDown>
        <RowText color="secondary" weight="600">{`Amount`}</RowText>
      </Hidden>
      <RowText color="secondary" weight="600">{`Rewards`}</RowText>
      <Hidden tabletDown>
        <RowText color="secondary" weight="600">{`Stake Type`}</RowText>
      </Hidden>
      <RowText color="secondary" weight="600">{`Status`}</RowText>
      <Hidden tabletDown>
        <RowText color="secondary" weight="600">{`Date`}</RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText color="secondary" weight="600">{`Explorer`}</RowText>
      </Hidden>
    </HeaderContainer>
  );
};

export const HistoryRowLoading = () => {
  return (
    <Row>
      <Flex alignItems="center" gap="8px">
        <RowLoadingText />
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" gap="2px">
          <RowLoadingText />
        </Flex>
      </Hidden>
      <Flex direction="column" gap="2px">
        <RowLoadingText />
      </Flex>
      <Flex direction="column" gap="2px">
        <RowLoadingText />
      </Flex>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <RowLoadingText />
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: transparent;
  grid-template-columns: 2fr 1fr 1fr 1.1fr 1fr 1fr 1.2fr 0.6fr;
  padding: 13px 15px 13px 15px;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "3fr 1.5fr 1.5fr 2fr " }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;

const HeaderContainer = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${({ theme }) => theme.colors.primary};
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 7px 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const StyledImg = styled.img`
width: 36px;
height: 36px;
${getStyleWithMediaQuery("width", "px", [{ [breakpoints.desktop]: 36 }, { [breakpoints.mobile]: 24 }])}
border-radius: ${({ theme }) => theme.borderRadius};
} `;

const ImageLoader = styled.div`
  width: 36px;
  height: 36px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 24 }])}
`;
