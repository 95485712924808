import { useEffect, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound } from "@cyanco/components/theme/v3";

import { ICollectionBe } from "@/apis/collection/types";
import { useSupportedCollections } from "@/components/AppContextProvider";
import { MAX_BNPL_LIMIT } from "@/config";

import { useBnplCart } from "../Bnpl/BnplCartContext";
import { OnSaleNftsView, useBnplViewType } from "../Bnpl/BnplPageContext";
import { ContainerBox, Header, ListViewBox } from "../Bnpl/components/CollectionDetail";
import { OnSaleNftCardLoading } from "../Bnpl/components/OnSaleNftCard";
import { OnSaleNftRowLoading, OnSaleNftsListHeader } from "../Bnpl/components/OnSaleNftRow";
import { CartItemsMultiSelection } from "../Bnpl/components/SelectItems/MultiSelectionBox";
import { InfiniteScroller } from "../Pagination/InfiniteScroller";
import { useBargainAssets } from "./BargainDataContext";
import { BargainItems } from "./BargainItems";
import { Selectors } from "./Selectors";

const OnSaleNftWrapper: React.FC<{ showByGrid: boolean; loading: boolean; collection: ICollectionBe }> = ({
  children,
  showByGrid,
  loading,
  collection,
}) => {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 580;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Flex direction="column" style={{ display: showByGrid ? "block" : "none" }}>
        <Selectors collection={collection} border={scrolled} />
        <GridContainer direction="column" gap="10px">
          <Flex direction="row" gap="2rem" mb="80px">
            {loading ? <OnSaleNftCardLoading /> : <BnplNFTWrapper>{children}</BnplNFTWrapper>}
          </Flex>
        </GridContainer>
      </Flex>

      <ListContainer style={{ display: !showByGrid ? "block" : "none" }}>
        <Selectors collection={collection} />
        <StyledListViewBox>
          <Flex direction="row" w="100%" gap="2rem">
            <ListViewBox>
              <Header>
                <OnSaleNftsListHeader />
              </Header>
              <ContainerBox>
                {loading ? (
                  <div>
                    {Array.from(Array(4).keys()).map(loader => (
                      <OnSaleNftRowLoading key={loader} />
                    ))}
                  </div>
                ) : (
                  children
                )}
              </ContainerBox>
            </ListViewBox>
          </Flex>
        </StyledListViewBox>
      </ListContainer>
    </>
  );
};

export const OnSaleNfts: React.FC<{ collection: ICollectionBe }> = ({ collection }) => {
  const { onSaleAsset, onSaleAssetsLoading } = useBargainAssets();
  const { items } = useBnplCart();
  const { collectionsLoading } = useSupportedCollections();
  const { selectedOnSaleNftsView } = useBnplViewType();
  const isSelectToCardPossible = items.length < MAX_BNPL_LIMIT;
  const isGrid = selectedOnSaleNftsView === OnSaleNftsView.grid;

  return (
    <OnSaleNftWrapper showByGrid={isGrid} loading={onSaleAssetsLoading || collectionsLoading} collection={collection}>
      {onSaleAsset && (
        <div
          style={{
            width: isGrid ? "100%" : "calc(100% - 2px)",
          }}
        >
          {onSaleAsset.length === 0 ? (
            <NotFound msg={`No availabe NFT found.`} />
          ) : (
            <InfiniteScroller hasMore={false} isGrid={isGrid}>
              <BargainItems isGrid={isGrid} items={onSaleAsset} isMultiSelectPossible={isSelectToCardPossible} />
            </InfiniteScroller>
          )}
        </div>
      )}
      <CartItemsMultiSelection assets={onSaleAsset ?? []} />
    </OnSaleNftWrapper>
  );
};

const BnplNFTWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 30px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GridContainer = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0.5rem calc(0.5rem + 5px) 1.5rem" },
  ])}
`;

const StyledListViewBox = styled.div`
  gap: 1rem;
  padding: 0rem 18px 0 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0.5rem calc(0.5rem + 5px) 1.5rem" },
  ])}
`;
