import { useNavigate } from "react-router-dom";

import { Flex } from "@cyanco/components/theme/components";
import { Text } from "@cyanco/components/theme/v3";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { ApeCoinStakingButton } from "./CommonComponents";

export const ApeCoinStakingButtonOnNft = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();

  const openApeCoinStakingModal = () => {
    navigate("/ape-coin/rewards");
    onClose();
  };
  return (
    <ApeCoinStakingButton onClick={openApeCoinStakingModal}>
      <Flex alignItems="center" gap="0.5rem">
        <img
          src={ApeCoin}
          alt=""
          height="13px"
          style={{
            borderRadius: "50%",
            border: "1px solid white",
            width: "13px",
          }}
        />
        <Text color="white" size="sm" weight="600">
          {`ApeCoin Staking`}
        </Text>
      </Flex>
    </ApeCoinStakingButton>
  );
};
