import { ApeCoinPageTabs, useApeCoinContext } from "../ApeCoinPageContext";
import { HistoricalView } from "./Historical/HistoryView";
import { PoolsView } from "./Pools";
import { RewardsView } from "./Rewards";

export const MainView = () => {
  const { selectedTab } = useApeCoinContext();
  return (
    <>
      {selectedTab === ApeCoinPageTabs.pools && <PoolsView />}
      {selectedTab === ApeCoinPageTabs.rewards && <RewardsView />}
      {selectedTab === ApeCoinPageTabs.history && <HistoricalView />}
    </>
  );
};
