import { BigNumber, constants } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { useMemo, useState } from "react";
import { useAsync } from "react-async-hook";
import { jsNumberForAddress } from "react-jazzicon";
import Jazzicon from "react-jazzicon/dist/Jazzicon";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Button, Card, Flex, SkeletonLine, Text } from "@cyanco/components/theme";

import { IOffer } from "@/apis/early-unwind/types";
import { IPawn } from "@/components/Account/pawn.types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { ICurrency } from "@/types";
import { bigNumToFloatFormatted, shortenAddress } from "@/utils";
import { getAccountBalanceOfErc20 } from "@/utils/contract";

import { PlanMetadata } from "../PlanMetadata";
import { WalletBox } from "../WalletOptionModal";
import { IPayerWallet } from "./PlanEarlyUnwindProgress";

export const WalletOptionEarlyUnwind: React.FC<{
  plan: IPawn | IBNPL;
  buyOffer: IOffer;
  amountToComplete: BigNumber;
  currency: ICurrency;
  totalAmount: BigNumber;
  purchasedPrice?: string;
  isBnpl: boolean;
  onNext: (a: { payerWallet: IPayerWallet }) => void;
}> = ({ plan, buyOffer, amountToComplete, currency, totalAmount, purchasedPrice, isBnpl, onNext }) => {
  const { account, chainId, provider } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const [selectedWallet, setSelectedWallet] = useState(account || "");

  const isNativeCurrencyPlan = currency.address.toLowerCase() === constants.AddressZero.toLowerCase();
  const offerPrice = buyOffer.price.amount;
  const offerCurrencyAddress = buyOffer.price.currency.address;
  const payAmount = amountToComplete.sub(offerPrice);

  const { result: balance, loading } = useAsync(async () => {
    if (!provider || !account) return;
    const offerCurrencyBalance = await getAccountBalanceOfErc20({
      currencyAddress: offerCurrencyAddress,
      mainWallet: account,
      chainId,
      provider,
    });
    let nativeCurrencyBalance;
    if (isNativeCurrencyPlan) {
      nativeCurrencyBalance = await getAccountBalanceOfErc20({
        currencyAddress: currency.address,
        mainWallet: account,
        chainId,
        provider,
      });
    }
    return {
      offerCurrencyBalance,
      nativeCurrencyBalance,
    };
  }, [account, provider]);

  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(currency.decimal) || 4;
  }, [currency.decimal]);

  const mainWalletBalance = useMemo(() => {
    if (!balance) return BigNumber.from(0);
    return balance.offerCurrencyBalance.mainWalletBalance.add(balance.nativeCurrencyBalance?.mainWalletBalance ?? 0);
  }, [balance]);

  const cyanWalletBalance = useMemo(() => {
    if (!balance) return BigNumber.from(0);
    return balance.offerCurrencyBalance.cyanWalletBalance.add(balance.nativeCurrencyBalance?.cyanWalletBalance ?? 0);
  }, [balance]);

  const selectedWalletBalance = useMemo(() => {
    if (!balance) return;
    const isMainWalletSelected = selectedWallet.toLowerCase() === (account ?? "")?.toLowerCase();
    const offerTokenBalance = isMainWalletSelected
      ? balance.offerCurrencyBalance.mainWalletBalance
      : balance.offerCurrencyBalance.cyanWalletBalance;
    const offerTokenAmount = offerTokenBalance.lt(payAmount)
      ? offerTokenBalance.gt(parseUnits("0.0001", buyOffer.price.currency.decimals))
        ? offerTokenBalance
        : BigNumber.from(0)
      : payAmount;
    const nativeTokenAmount = payAmount.sub(offerTokenAmount);
    return {
      nativeTokenAmount,
      offerTokenAmount,
    };
  }, [balance, selectedWallet, payAmount]);

  return (
    <Flex direction="column" gap="1.3rem">
      <PlanMetadata
        plans={[
          {
            imageUrl: plan.metadata.imageUrl,
            collectionName: plan.metadata.collection.name,
            tokenId: plan.tokenId,
            totalAmount,
            purchasedPrice,
            leftAmount: amountToComplete,
            currency: plan.currency,
            address: plan.metadata.collectionAddress,
            isBnpl,
          },
        ]}
      />
      {balance && !loading && (
        <Flex direction="column" gap="0.3rem">
          <Flex direction="column" gap="0.6rem">
            <Flex direction="column" gap="0.4rem">
              <Text color="gray0" size="sm" weight="600">{`Select wallet to pay with:`}</Text>
              <WalletBox
                isSelected={account === selectedWallet}
                onClick={() => setSelectedWallet(account || "")}
                disabled={mainWalletBalance.lt(payAmount)}
                style={{ padding: "12px" }}
              >
                <Flex justifyContent="space-between" alignItems="center" w="100%">
                  <Flex gap="10px" alignItems="center">
                    <Jazzicon seed={jsNumberForAddress(account || "")} diameter={18} />
                    <Text color={mainWalletBalance.gte(payAmount) ? "secondary" : "gray0"} size="sm" weight="500">
                      {shortenAddress(account || "")}
                    </Text>
                  </Flex>
                  <Flex direction="column" alignItems="flex-end" gap="5px">
                    {balance.nativeCurrencyBalance && (
                      <Text
                        color={balance.offerCurrencyBalance.mainWalletBalance.lt(payAmount) ? "secondary" : "gray0"}
                      >{`${bigNumToFloatFormatted(
                        balance.nativeCurrencyBalance.mainWalletBalance,
                        currency.decimal,
                        formatNumber,
                      )} ${currency.symbol}`}</Text>
                    )}
                    <Text color={mainWalletBalance.gte(payAmount) ? "secondary" : "gray0"}>{`${bigNumToFloatFormatted(
                      balance.offerCurrencyBalance.mainWalletBalance,
                      buyOffer.price.currency.decimals,
                      formatNumber,
                    )} ${buyOffer.price.currency.symbol}`}</Text>
                  </Flex>
                </Flex>
              </WalletBox>
            </Flex>
            {cyanWallet && (
              <WalletBox
                isSelected={cyanWallet.walletAddress === selectedWallet}
                onClick={() => setSelectedWallet(cyanWallet.walletAddress)}
                disabled={cyanWalletBalance.lt(payAmount)}
                style={{ padding: "12px" }}
              >
                <Flex justifyContent="space-between" alignItems="center" w="100%">
                  <Flex gap="10px" alignItems="center">
                    <div style={{ background: "cyan", width: "18px", height: "18px", borderRadius: "50%" }} />
                    <Text color={cyanWalletBalance.gte(payAmount) ? "secondary" : "gray0"} size="sm" weight="500">
                      {shortenAddress(cyanWallet.walletAddress || "")}
                    </Text>
                  </Flex>
                  <Flex direction="column" alignItems="flex-end" gap="5px">
                    {balance.nativeCurrencyBalance && (
                      <Text
                        color={balance.offerCurrencyBalance.cyanWalletBalance.lt(payAmount) ? "secondary" : "gray0"}
                      >{`${bigNumToFloatFormatted(
                        balance.nativeCurrencyBalance.cyanWalletBalance,
                        currency.decimal,
                        formatNumber,
                      )} ${currency.symbol}`}</Text>
                    )}
                    <Text color={cyanWalletBalance.gte(payAmount) ? "secondary" : "gray0"}>{`${bigNumToFloatFormatted(
                      balance.offerCurrencyBalance.cyanWalletBalance,
                      buyOffer.price.currency.decimals,
                      formatNumber,
                    )} ${buyOffer.price.currency.symbol}`}</Text>
                  </Flex>
                </Flex>
              </WalletBox>
            )}
            <Text color="gray0" size="xs" textAlign="right">
              If wrapped token balance is insufficient, native token will be automatically converted.
            </Text>
          </Flex>
        </Flex>
      )}
      {loading && (
        <Flex direction="column" gap="0.6rem">
          <Flex direction="column" gap="0.4rem">
            <Text color="gray0" size="sm" weight="600">{`Select wallet to pay with:`}</Text>
            <SkeletonLine w="100%" h="50px" />
          </Flex>
          <SkeletonLine w="100%" h="50px" />
        </Flex>
      )}
      <Card p={"10px"}>
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4px" w="100%">
            <Text size="sm" weight="500" color="gray0">
              Pay to close loan
            </Text>
            <Text size="lg" weight="500" color="secondary" key={currency.address}>
              {`${bigNumToFloatFormatted(payAmount, buyOffer.price.currency.decimals, formatNumber)} ${
                buyOffer.price.currency.symbol
              }`}
            </Text>
          </Flex>
          <Box w="120px">
            <Button
              disabled={!!!selectedWalletBalance}
              onClick={() => {
                if (selectedWalletBalance) {
                  onNext({
                    payerWallet: {
                      ...selectedWalletBalance,
                      address: selectedWallet,
                    },
                  });
                }
              }}
            >
              Pay
            </Button>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};
