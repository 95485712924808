import { useAppContext } from "@/components/AppContextProvider";
import { P2PCollections } from "@/components/P2P";
import { Experiments } from "@/utils/experimentList";

const P2P = () => {
  const { experiment } = useAppContext();
  if (!experiment.result || !experiment.result[Experiments.P2P]) return null;
  return <P2PCollections />;
};
export default P2P;
