import WidgetBot from "@widgetbot/react-embed";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { Discord } from "@cyanco/components/theme/v3/icons";

const server = "907555867262615562";
const channel = "1091409261575295037";

export const DiscordWidget = () => {
  const [openWidget, setOpenWidget] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!ref || !buttonRef) return;
      if (ref.current?.contains(e.target as Node) || buttonRef.current?.contains(e.target as Node)) {
        return;
      }
      if (ref.current) {
        setOpenWidget(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, buttonRef]);

  return (
    <Container>
      <div ref={ref}>
        {openWidget && (
          <WidgetBot
            server={server}
            channel={channel}
            width={330}
            height={480}
            style={{
              marginLeft: "1rem",
              visibility: openWidget ? "visible" : "hidden",
            }}
          />
        )}
      </div>
      <DiscordCircle
        alignItems="center"
        justifyContent="center"
        onClick={() => setOpenWidget(prev => !prev)}
        ref={buttonRef}
      >
        <DiscordIcon>
          <Discord color="white" height={30} width={30} />
        </DiscordIcon>
      </DiscordCircle>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: calc(1rem + 50px);
  left: 1.25rem;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    bottom: 3rem;
  }
  gap: 0.5rem;
  z-index: 71;
`;

const DiscordIcon = styled.div`
  transition: 0.2s ease-in-out;
  display: flex;
  alignt-items: center;
  justify-content: center;
`;

const DiscordCircle = styled(Flex)`
  background: #5865f2;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  &:hover {
    ${DiscordIcon} {
      transform: scale(1.1);
    }
  }
`;
