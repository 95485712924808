import { useEffect, useState } from "react";

import { Flex } from "@cyanco/components/theme";

import { IVaultLiquidatedNft } from "@/apis/vault/admin/types";
import { IVault, IVaultLatestLoan } from "@/apis/vault/types";
import { useTransactionContext } from "@/components/TransactionContextProvider";

import { VaultLiquidatedNfts } from "./VaultLiquidatedNfts";
import { VaultLoans } from "./VaultLoans";
import { SelectionBox } from "./VaultLoans/MultiSelectionBox";

export const VaultAssetsManagement = ({ vault, loading }: { vault: IVault; loading: boolean }) => {
  const { vaultLiquidatedNfts, paymentLiquidatedNfts } = useTransactionContext();
  const [selectedDefaultedLoans, setSelectedDefaultedLoans] = useState<IVaultLatestLoan[]>([]);
  const [selectedLiquidatedNfts, setSelectedLiquidatedNfts] = useState<IVaultLiquidatedNft[]>([]);

  useEffect(() => {
    if (vaultLiquidatedNfts.length === 0) return;
    setSelectedLiquidatedNfts(prev => prev.filter(item => !vaultLiquidatedNfts.some(nft => nft.id === item.id)));
  }, [vaultLiquidatedNfts]);

  useEffect(() => {
    if (paymentLiquidatedNfts.length === 0) return;
    setSelectedDefaultedLoans(prev => prev.filter(item => !paymentLiquidatedNfts.includes(item.planId)));
  }, [paymentLiquidatedNfts]);

  return (
    <Flex direction="column">
      <VaultLoans
        vault={vault}
        loading={loading}
        selectedLoans={selectedDefaultedLoans}
        setSelectedLoans={setSelectedDefaultedLoans}
      />
      <VaultLiquidatedNfts
        vault={vault}
        loading={loading}
        selectedNfts={selectedLiquidatedNfts}
        setSelectedNfts={setSelectedLiquidatedNfts}
      />
      <SelectionBox
        selectedDefaultedLoans={selectedDefaultedLoans}
        setSelectedDefaultedLoans={setSelectedDefaultedLoans}
        selectedLiquidatedNfts={selectedLiquidatedNfts}
        setSelectedLiquidatedNfts={setSelectedLiquidatedNfts}
        vault={vault}
      />
    </Flex>
  );
};
