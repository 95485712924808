import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { CyanWallet } from "@usecyan/cyan-wallet";
import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { getPlanRevivalSignatureBulk } from "@/apis/revival";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { useApproval } from "@/hooks/useApproval";
import { getChainExplorerURL } from "@/utils";
import { getAccountBalanceOfErc20, getNftTransferFnDataForCyanWallet } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";

import { PlanMetadata } from "../PlanMetadata";
import { IPlanRepaymentProgressProps } from "../PlanRepaymentProgress";
import { getFlagsForPlanRequiredSteps } from "../utils";
import { RevivalModal } from "./RevivalModal";

type IPlanRevivalProgressProps = Omit<IPlanRepaymentProgressProps, "onClose" | "payment"> & {
  payment: {
    nextPaymentAmount: BigNumber;
    penaltyAmount: BigNumber;
    isNativeCurrency: boolean;
    releaseWallet: string;
  };
};

export const PlanRevivalProgress: React.FC<IPlanRevivalProgressProps> = ({
  plan,
  currency,
  payment,
  bnpl,
  pawn,
  selectedWalletAddress,
}) => {
  const { setFireConfetti } = useAppContext();
  const { setModalContent, unsetModal } = useModal();
  const { chainId, account, provider, signer } = useWeb3React();
  const { cyanWallet } = useCyanWalletContext();
  const { transactions, addTransaction } = useTransactionContext();
  const { giveErc20Approval, getErc20ApprovalFunctionData } = useApproval();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<PlanRepaymentSteps>(PlanRepaymentSteps.TokenApproval);
  const paymentPlanV2 = getPaymentPlanFromChainId(chainId);

  const { isErc20ApprovalRequired, isErc20TransferRequired, isPayingFromMainWallet, isNftTransferRequired } =
    getFlagsForPlanRequiredSteps({
      isPlanCompleting: plan.totalNumOfPaymentsLeft === 1,
      isNativeCurrency: payment.isNativeCurrency,
      mainWalletAddress: account || "",
      selectedPayWallet: selectedWalletAddress,
      selectedReceiverWallet: payment.releaseWallet,
    });
  const totalPayAmount = payment.penaltyAmount.add(payment.nextPaymentAmount);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setFireConfetti(true);
        setSelectedStep(PlanRepaymentSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const createBulkTransactionForPayAndTransfer = async (
    signer: ethers.Signer,
    cyanWallet: CyanWallet,
    signature: {
      signature: string;
      expiryDateInSeconds: number;
    },
  ) => {
    const { address: collectionAddress } = plan;
    const { releaseWallet, isNativeCurrency } = payment;
    const paymentContractWriter = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);

    const encodedFnDataTransferFormatted = getNftTransferFnDataForCyanWallet({
      tokenId: plan.tokenId,
      to: releaseWallet,
      from: cyanWallet.walletAddress,
      collectionAddress,
      tokenType: (pawn ?? bnpl)?.metadata.tokenType,
    });

    // revival txn
    const encodedPayFnData = paymentContractWriter.interface.encodeFunctionData("revive", [
      plan.planId,
      payment.penaltyAmount,
      signature.expiryDateInSeconds,
      signature.signature,
    ]);
    const encodedPayFnDataFormatted = [paymentPlanV2, isNativeCurrency ? totalPayAmount : 0, encodedPayFnData];
    let encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
      [encodedPayFnDataFormatted, encodedFnDataTransferFormatted],
    ]);
    if (!isNativeCurrency) {
      const approveFnData = await getApproveNonNativeCurrencyAllowanceCyan(cyanWallet);
      if (approveFnData) {
        encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
          [approveFnData, encodedPayFnDataFormatted, encodedFnDataTransferFormatted],
        ]);
      }
    }
    const tx = await signer.sendTransaction({
      to: cyanWallet.walletAddress,
      value: isNativeCurrency ? totalPayAmount : 0,
      data: encodedCyanExecuteFnData,
    });
    return tx;
  };

  const getApproveNonNativeCurrencyAllowanceCyan = async (cyanWallet: CyanWallet) => {
    const encodedAllowanceFnDataFormatted = await getErc20ApprovalFunctionData(
      {
        amount: totalPayAmount,
        currencyAddress: currency.address,
        cyanWallet,
      },
      paymentPlanV2,
    );
    return encodedAllowanceFnDataFormatted ?? null;
  };

  const checkBalance = async () => {
    if (!chainId || !account || !provider) return;
    const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
      currencyAddress: currency.address,
      provider,
      mainWallet: account,
      chainId,
    });

    if (
      (!isPayingFromMainWallet && cyanWalletBalance.lt(totalPayAmount)) ||
      (isPayingFromMainWallet && mainWalletBalance.lt(totalPayAmount))
    ) {
      openRepaymentModal({
        title: `Not enough funds`,
        msg: `Your payment didn’t go through due to lack of funds`,
        description: ` Please double check the amount of crypto in your main wallet. In order to facilitate payments for non-native currency plans, it is imperative that the payment amount be drawn from the Cyan wallet.`,
      });
    }
  };

  const handlePaymentV2 = async () => {
    if (!signer || !chainId || !account) return;
    if (!cyanWallet) {
      openRepaymentModal({
        title: `Cyan Wallet not found!`,
        msg: `In order to make payment, cyan wallet is required to have.`,
        description: `Please contact Cyan team via discord.`,
      });
      return;
    }
    const { isBnpl, planId } = plan;
    const { isNativeCurrency } = payment;
    const [{ signature, expiryDateInSeconds, penaltyAmount: penaltyAmountBe }] = await getPlanRevivalSignatureBulk(
      [{ planId }],
      chainId,
    );
    if (!payment.penaltyAmount.eq(penaltyAmountBe)) {
      openRepaymentModal({
        title: `Penalty rate changed`,
        msg: `Please check the penalty amount before proceeding with the plan revival.`,
      });
      return;
    }
    let tx: ethers.ContractTransaction;
    if (isNftTransferRequired) {
      tx = await createBulkTransactionForPayAndTransfer(signer, cyanWallet, { signature, expiryDateInSeconds });
    } else {
      const paymentContractWriter = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
      if (!isPayingFromMainWallet) {
        const encodedPayFnData = paymentContractWriter.interface.encodeFunctionData("revive", [
          plan.planId,
          payment.penaltyAmount,
          expiryDateInSeconds,
          signature,
        ]);
        const encodedPayFnDataFormatted = [paymentPlanV2, isNativeCurrency ? totalPayAmount : 0, encodedPayFnData];
        let encodedCyanExecuteFnData = "";
        if (!isNativeCurrency) {
          const approveFnData = await getApproveNonNativeCurrencyAllowanceCyan(cyanWallet);
          if (approveFnData) {
            encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
              [approveFnData, encodedPayFnDataFormatted],
            ]);
          } else {
            encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("execute", encodedPayFnDataFormatted);
          }
        } else {
          encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("execute", encodedPayFnDataFormatted);
        }
        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          value: 0,
          data: encodedCyanExecuteFnData,
        });
      } else {
        tx = await paymentContractWriter.revive(planId, payment.penaltyAmount, expiryDateInSeconds, signature, {
          value: isNativeCurrency ? totalPayAmount : 0,
        });
      }
    }
    addTransaction({
      hash: tx.hash,
      type: isBnpl ? "bnpl-pay" : "pawn-pay",
      data: {
        planId: plan.planId,
        currentNumOfPayments: plan.currentNumOfPayments,
        tokenId: plan.tokenId,
        contractAddress: plan.address,
      },
    });
    setActiveTx(tx.hash);
  };

  const transferErc20 = async () => {
    if (!signer || !account || !cyanWallet || !provider) return;
    const sampleErc20 = f.SampleERC20TokenFactory.connect(currency.address, signer);
    const txn = await sampleErc20.transfer(cyanWallet.walletAddress, totalPayAmount);
    await txn.wait();
  };

  useEffect(() => {
    const onStepChange = async (step: PlanRepaymentSteps) => {
      if (!chainId || !account || !signer || !provider) return;
      try {
        switch (step) {
          case PlanRepaymentSteps.TokenApproval: {
            await checkBalance();
            if (isErc20ApprovalRequired) {
              await giveErc20Approval(
                {
                  currencyAddress: currency.address,
                  amount: totalPayAmount,
                },
                paymentPlanV2,
              );
            }
            setSelectedStep(PlanRepaymentSteps.TokenTransfer);
            break;
          }
          case PlanRepaymentSteps.TokenTransfer: {
            if (isErc20TransferRequired) {
              await transferErc20();
            }
            setSelectedStep(PlanRepaymentSteps.Reviving);
            break;
          }
          case PlanRepaymentSteps.Reviving: {
            await handlePaymentV2();
          }
        }
      } catch (err: any) {
        openRepaymentModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    setModalContent({
      title: `Loan Revival`,
      content: <RevivalModal pawn={pawn} bnpl={bnpl} planType={plan.isBnpl ? "bnpl" : "pawn"} error={mappedError} />,
    });
  };

  return (
    <Flex gap="5x" direction="column">
      <PlanMetadata
        plans={[
          {
            ...plan,
            currency,
          },
        ]}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={PlanRepaymentStepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === PlanRepaymentSteps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};

enum PlanRepaymentSteps {
  TokenApproval = 1,
  TokenTransfer = 2,
  Reviving = 3,
  Done = 4,
}

const PlanRepaymentStepMarks = [
  {
    value: PlanRepaymentSteps.TokenApproval,
    title: `Token Approval`,
  },
  {
    value: PlanRepaymentSteps.TokenTransfer,
    title: `Token Transfer`,
    description: `Transfer required amount of tokens to the Cyan Wallet`,
  },
  {
    value: PlanRepaymentSteps.Reviving,
    title: `Processing Revival`,
    description: `View on Etherscan`,
  },
];

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
