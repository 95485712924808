import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, Text } from "@cyanco/components/theme/v3";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { formatCompactNumber } from "@/utils";

import { IPoolInfo } from "../../ApeCoinStatsContext.types";

export const ApePoolsHeader = () => {
  return (
    <HeaderContainer>
      <RowText color="secondary" weight="600">{`Pool`}</RowText>
      <RowText weight="700" color="secondary"></RowText>
      <Hidden tabletDown>
        <RowText weight="700" color="secondary">{`APY`}</RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText weight="700" color="secondary">{`Total Staked`}</RowText>
      </Hidden>
      <RowText weight="700" color="secondary">{`On Cyan`}</RowText>
      <RowText weight="700" color="secondary">{`Possible Rewards`}</RowText>
      <Hidden laptopDown>
        <RowText weight="700" color="secondary">{`CAP`}</RowText>
      </Hidden>
      <Hidden laptopDown>
        <RowText weight="700" color="secondary">{`Staking`}</RowText>
      </Hidden>
    </HeaderContainer>
  );
};

export const ApePoolsRow = ({
  item,
  onClick,
}: {
  item: {
    hasApeCoin?: boolean;
    name: string;
    cap: string;
    image: string;
  } & IPoolInfo;
  onClick: () => void;
}) => {
  const { name, totalStakedAmount, totalStakedAmountOnCyan, rewardsPerDay, cap, image, hasApeCoin, apy } = item;
  return (
    <Row onClick={onClick}>
      <Flex>
        <StyledImg
          src={image}
          alt={name}
          style={{
            zIndex: "3",
            borderColor: hasApeCoin === undefined ? "cyan" : "white",
          }}
        />
        {hasApeCoin !== undefined && (
          <div
            style={{
              position: "relative",
            }}
          >
            <StyledImg
              src={ApeCoin}
              alt="ApeCoin"
              style={{
                left: "-7px",
                position: "absolute",
                zIndex: "2",
                opacity: hasApeCoin ? 1 : 0.25,
              }}
            />
          </div>
        )}
      </Flex>
      <RowText weight="500" color="secondary">
        {name}
      </RowText>
      <Hidden tabletDown>
        <RowText weight="500" color="secondary">
          {formatCompactNumber(apy)}%
        </RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText weight="500" color="secondary">
          {formatCompactNumber(totalStakedAmount)} APE
        </RowText>
      </Hidden>
      <RowText weight="500" color="secondary">
        {formatCompactNumber(totalStakedAmountOnCyan)} APE
      </RowText>
      <RowText weight="500" color="secondary">
        {formatCompactNumber(rewardsPerDay)} APE/24h
      </RowText>
      <Hidden laptopDown>
        <RowText weight="500" color="secondary">
          {cap} APE
        </RowText>
      </Hidden>
      <Hidden laptopDown>
        <Button
          size="xs"
          style={{
            padding: "6px 0",
          }}
        >{`Start Staking`}</Button>
      </Hidden>
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 1rem;
  align-items: center;
  background-color: transparent;
  grid-template-columns: 0.5fr 1.3fr 0.7fr 1fr 0.8fr 1.2fr 0.7fr 0.7fr;
  padding: 13px 15px 13px 15px;
  cursor: pointer;
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.laptop]: "0.5fr 1.3fr 0.7fr 1fr 0.8fr 1.2fr" },
    { [breakpoints.tablet]: "0.5fr 1.3fr 0.7fr 1fr" },
  ])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const HeaderContainer = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${({ theme }) => theme.colors.primary};
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 7px 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;
const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  border-radius: 50%;
  border: 1px solid white;
`;
