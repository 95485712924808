import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import StyledCheckbox from "@cyanco/components/theme/Checkbox";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, SkeletonLine, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { AlertTriangle, CyanLogo, HelpCircle } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { useAppContext } from "@/components/AppContextProvider";
import { TimeAgo } from "@/components/TimeAgo";
import { INftBe } from "@/types";
import { bigNumToFloat, displayInUSD, numberWithCommas, shortenName } from "@/utils";

import { useBnplCart } from "../BnplCartContext";

export const OnSaleNftRowLoading = () => {
  return (
    <Row>
      <Flex alignItems="center" gap="8px">
        <StyledImgLoading />
        <RowLoadingText />
      </Flex>
      <Flex gap="2px" direction="column">
        <RowLoadingText sub />
        <RowLoadingText />
      </Flex>
      <Flex direction="column" gap="2px">
        <RowLoadingText sub />
        <RowLoadingText />
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" gap="2px">
          <RowLoadingText sub />
          <RowLoadingText />
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
    </Row>
  );
};

export const OnSaleNftsListHeader = () => {
  const theme = useTheme();
  return (
    <HeaderContainer>
      <HeaderText color="secondary">{`Cart`}</HeaderText>
      <HeaderText color="secondary">{`NFT Item`}</HeaderText>
      <Flex gap="5px" alignItems="center">
        <HeaderText color="secondary" textWrap={false}>{`Buy Now`}</HeaderText>
        <Tooltip>
          <HelpCircle height={16} width={16} color={theme.colors.secondary} />
          <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
              <div>{`Buy Now price is an estimate based on a floor price NFT for a 3-month, 25% down payment loan.`}</div>
            </Text>
          </TooltipText>
        </Tooltip>
      </Flex>
      <HeaderText color="secondary">{`Current Price`}</HeaderText>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Best Bid`}</HeaderText>
      </Hidden>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Last Sale`}</HeaderText>
      </Hidden>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Marketplace`}</HeaderText>
      </Hidden>
      <HeaderText color="secondary">{`Time Listed`}</HeaderText>
    </HeaderContainer>
  );
};

type IProps = {
  nft: INftBe;
  loading?: boolean;
  isSelectToCardPossible?: boolean;
  onClick: (a: INftBe) => void;
};
export const OnSaleNftRow: React.FC<IProps> = ({ nft, isSelectToCardPossible, loading = false, onClick }) => {
  const { usdPrice } = useAppContext();
  const formatNumber = useMemo(() => {
    const priceInFloat = bigNumToFloat(nft.price, nft.currency.decimal);
    if (priceInFloat <= 0.01) {
      return 4;
    }
    return 3;
  }, [nft.price]);
  const getPrice = useMemo(
    () => (!nft.price ? "-" : numberWithCommas(bigNumToFloat(nft.price, nft.currency.decimal), formatNumber, true)),
    [nft.price, formatNumber],
  );
  const getPriceInUsd = useMemo(
    () => displayInUSD(bigNumToFloat(nft.price, nft.currency.decimal) * usdPrice[nft.currency.symbol]),
    [nft.price],
  );
  const get25Price = useMemo(
    () =>
      !nft.price ? "-" : numberWithCommas(bigNumToFloat(nft.price, nft.currency.decimal) * 0.25, formatNumber, true),
    [nft.price, formatNumber],
  );
  const get25PriceInUsd = useMemo(
    () => (get25Price === "-" ? "-" : displayInUSD(Number(get25Price) * usdPrice[nft.currency.symbol])),
    [get25Price],
  );

  const { items: cartItems, toggleItem } = useBnplCart();
  const isInCart = !!cartItems.find(({ address, tokenId }) => address === nft.address && tokenId === nft.tokenId);
  const getLastSalePriceInUSD = useMemo(() => {
    return nft.lastSellPrice
      ? displayInUSD(
          Number(bigNumToFloat(nft.lastSellPrice.amount, nft.lastSellPrice.currency.decimal)) *
            usdPrice[nft.lastSellPrice.currency.symbol],
        )
      : "-";
  }, [nft, usdPrice]);
  const getBestBidPriceInUSD = useMemo(() => {
    return nft.topBidPrice
      ? displayInUSD(
          Number(bigNumToFloat(nft.topBidPrice.amount, nft.topBidPrice.currency.decimal)) *
            usdPrice[nft.topBidPrice.currency.symbol],
        )
      : "-";
  }, [nft, usdPrice]);
  return (
    <Row onClick={() => !loading && onClick(nft)} selected={isInCart}>
      <div>
        <RowCartCheckboxWrapper
          onClick={e => {
            e.stopPropagation();
          }}
          alignItems="center"
          justifyContent="flex-start"
        >
          <StyledCheckbox
            checked={isInCart}
            disabled={!isInCart && !isSelectToCardPossible}
            onChange={e => {
              e.stopPropagation();
              nft.planType = "bnpl";
              toggleItem(nft);
            }}
            id={nft.tokenId}
          />
        </RowCartCheckboxWrapper>
      </div>
      <Flex alignItems="center" gap="8px">
        <StyledImg
          src={nft.imageUrl || NoImage}
          hasImage={!!nft.imageUrl}
          alt={`${nft.collectionName} ${nft.tokenId}`}
        />
        <RowText color={"secondary"}> #{shortenName(nft.tokenId, 10, 6)}</RowText>
      </Flex>
      <Flex direction="column">
        <RowText color={"secondary"} style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {get25Price} {nft.currency.symbol} {nft.isFlaggedByOS && <AlertTriangle color="red" width={16} height={16} />}
        </RowText>
        <RowText color="gray0" sub>
          {get25PriceInUsd}
        </RowText>
      </Flex>
      <Flex direction="column">
        <RowText color={"secondary"} style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {getPrice} {nft.currency.symbol} {nft.isFlaggedByOS && <AlertTriangle color="red" width={16} height={16} />}
        </RowText>
        <RowText color="gray0" sub>
          {getPriceInUsd}
        </RowText>
      </Flex>

      <Hidden tabletDown>
        <Flex direction="column">
          <RowText color={"secondary"}>
            {nft.topBidPrice
              ? `${numberWithCommas(bigNumToFloat(nft.topBidPrice.amount, nft.topBidPrice.currency.decimal), 2)} ${
                  nft.topBidPrice.currency.symbol
                }`
              : "-"}
          </RowText>
          <RowText color="gray0" sub>
            {getBestBidPriceInUSD}
          </RowText>
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex direction="column">
          <RowText color={"secondary"}>
            {nft.lastSellPrice
              ? `${numberWithCommas(
                  bigNumToFloat(nft.lastSellPrice.amount, nft.lastSellPrice.currency.decimal),
                  formatNumber,
                  true,
                )} ${nft.lastSellPrice.currency.symbol}`
              : "-"}
          </RowText>
          <RowText color="gray0" sub>
            {getLastSalePriceInUSD}
          </RowText>
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex alignItems="center" gap="8px">
          <MarketImageContainer>
            {nft.isPrivate ? <CyanLogo /> : <img src={nft.marketIcon} alt={nft.marketName} />}
          </MarketImageContainer>
          <RowText color={"secondary"}>{nft.isPrivate ? `cyan` : nft.marketName}</RowText>
        </Flex>
      </Hidden>
      <RowText color={"secondary"}>
        <TimeAgo then={nft.listedAt} live={false} />
      </RowText>
    </Row>
  );
};

const Row = styled.div<{ selected?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  grid-template-columns: 0.3fr 1.3fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.2fr;

  padding: 13px 17px 13px 18px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.gray10 : "transparent")};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "13px 10px 13px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 0.7fr 2fr 2fr 2fr 1fr;
  }
`;
const RowCartCheckboxWrapper = styled(Flex)`
  margin: -20px 0px -20px -18px;
  padding: 20px 0px 20px 18px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptop]: "-14px 0px -14px -40px" },
    { [breakpoints.tablet]: "-7px 0px" },
  ])};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 0px 14px 40px" },
    { [breakpoints.tablet]: "7px 0px 7px 10px" },
  ])};
  height: 100%;
`;
const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 14 },
    { [breakpoints.laptop]: 12 },
    { [breakpoints.tablet]: 12 },
  ])};
`;

const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

const HeaderContainer = styled(Row)`
  padding: 14px 19px 12px 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "14px 19px 12px 18px" },
    { [breakpoints.laptop]: "14px 10px 12px 40px" },
    { [breakpoints.tablet]: "10px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
`;

const StyledImg = styled.img<{ hasImage?: boolean }>`
  width: 36px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
  border-radius: ${({ theme }) => theme.borderRadius};
  filter: ${({ hasImage, theme }) =>
    !hasImage &&
    theme.theme === "light" &&
    "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;

const MarketImageContainer = styled(`span`)`
  img {
    width: 32px;
    ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 22 }])}
    border-radius: ${({ theme }) => theme.borderRadius};
  }
  svg {
    width: 32px;
    height: 32px;
    ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 22 }])}
    ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 22 }])}
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;

const RowLoadingText = styled(SkeletonLine)<{ sub?: boolean }>`
  width: 50%;
  height: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("height", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])};
`;
const StyledImgLoading = styled(SkeletonLine)`
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  ${getStyleWithMediaQuery("min-width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("min-height", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("max-height", "px", [{ [breakpoints.mobile]: 24 }])}
  border-radius: ${({ theme }) => theme.borderRadius};
`;
