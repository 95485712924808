import { BigNumber } from "ethers";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { breakpoints } from "@cyanco/components/theme/config";
import { NotFound, useModal } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { ICollectionBe } from "@/apis/collection/types";
import { IP2PLoanOffer } from "@/apis/p2p/types";
import { IUserNft, IVaultOffer } from "@/apis/user/types";
import { useVaultDataContext } from "@/components/Vault/VaultDataProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedCurrenciesByChain } from "@/config";
import { usePlanCreation } from "@/hooks";
import { ITEM_AMOUNT_BY_NFT_TYPE } from "@/types";

import {
  HeaderWrapper,
  ListContainer,
  ListViewBox,
  NftContainer,
  StyledListViewBox,
} from "../../P2P/collection/Assets/ListedNfts";
import { LoanOfferHeader, LoanOfferRow, LoanOfferRowLoading } from "./LoanOfferRow";
import { OfferTakerCard } from "./OfferTakerCard";
import { VaultOfferRow } from "./VaultOfferRow";

export const LoanOffers = ({
  offers,
  nft,
  collection,
  blockchainTimestamp,
  loading,
  vaultOffers,
}: {
  offers: IP2PLoanOffer[];
  blockchainTimestamp: number;
  collection: ICollectionBe;
  nft: IUserNft;
  loading: boolean;
  vaultOffers: IVaultOffer[];
}) => {
  const { chainId } = useWeb3React();
  const { vaults } = useVaultDataContext();
  const { showNewPlanModal } = usePlanCreation("pawn");
  const { setModalContent } = useModal();
  const showModal = (offer: IP2PLoanOffer) => {
    setModalContent({
      title: `Take NFT Loan Offer`,
      content: <OfferTakerCard nft={nft} loandData={offer} collection={collection} showNft={true} />,
    });
  };

  return (
    <ListContainer>
      <StyledListViewBox style={{ padding: 0 }}>
        <Flex direction="row" w="100%" gap="2rem">
          <ListViewBox>
            <Header>
              <HeaderWrapper>
                <LoanOfferHeader />
              </HeaderWrapper>
            </Header>
            <div
              style={{
                width: "calc(100% - 2px)",
              }}
            >
              {loading ? (
                <>
                  {Array.from(Array(4).keys()).map(loader => (
                    <LoanOfferRowLoading key={loader} />
                  ))}
                </>
              ) : (
                <>
                  {[...offers, ...vaultOffers].length === 0 ? (
                    <NotFound msg={`No loan offers found.`} />
                  ) : (
                    <NftContainer role="grid">
                      <>
                        {vaultOffers.map(offer => {
                          const vault = vaults.find(v => v.id === offer.vaultId);
                          const offerCurrency = SupportedCurrenciesByChain[chainId].find(
                            item => item.symbol === vault?.currency,
                          );
                          if (vault && offer.config.length > 0 && offerCurrency) {
                            return (
                              <VaultOfferRow
                                vault={vault}
                                vaultOffer={offer}
                                key={`${offer.vaultId}:vault`}
                                blockchainTimestamp={blockchainTimestamp}
                                onClick={() => {
                                  showNewPlanModal({
                                    currency: offerCurrency,
                                    items: [
                                      {
                                        ...nft,
                                        planType: "pawn",
                                        price: BigNumber.from(offer.price),
                                        currency: offerCurrency,
                                        imageUrl: nft.imageUrl ?? NoImage,
                                        rarityStat: "N/A",
                                        amount: ITEM_AMOUNT_BY_NFT_TYPE[collection.tokenType],
                                        itemType: collection.tokenType,
                                      },
                                    ],
                                  });
                                }}
                              />
                            );
                          }
                          return <></>;
                        })}
                        {offers.map(offer => (
                          <LoanOfferRow
                            offer={offer}
                            key={`${offer.id}`}
                            blockchainTimestamp={blockchainTimestamp}
                            onClick={showModal}
                          />
                        ))}
                      </>
                    </NftContainer>
                  )}
                </>
              )}
            </div>
          </ListViewBox>
        </Flex>
      </StyledListViewBox>
    </ListContainer>
  );
};
const Header = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 65px;
  }
  align-items: end;
  border-bottom: none;
  background: ${({ theme }) => theme.colors.primary};
  transform-style: preserve-3d;
  z-index: 5;
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    z-index: -1;
    display: block;
    position: absolute;
    transform: translateZ(-1px);
    height: 120px;
    top: -10px;
    height: calc(100% + 10px);
    left: -5px;
    width: calc(100% + 10px);
  }
`;
