import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound, useModal } from "@cyanco/components/theme/v3";

import { IVault } from "@/apis/vault/types";
import { useWeb3React } from "@/components/Web3ReactProvider";

import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "../../../../constants/chains";
import { bigNumToFloat } from "../../../../utils";
import { IPosition } from "../../../Vault/types";
import { useVaultPositionsFiltered } from "../../AccountDataContext";
import { WalletTypes, useVaultTokensTabContext } from "../../AccountPageContext";
import { ContainerBox, Header, ListViewBox } from "../../index";
import { VaultPositionListHeader, VaultPositionRow, VaultPositionRowLoading } from "./VaultPositionRow";
import { VaultTokenTransfer } from "./VaultTokenTransfer";

export const CurrentVaults = () => {
  const { vaultPositions, loading } = useVaultPositionsFiltered();
  const { selectedVaultWalletType } = useVaultTokensTabContext();
  const { setModalContent } = useModal();
  const { account } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const navigate = useNavigate();
  const onTransfer = ({ position, vault }: { position: IPosition; vault: IVault }) => {
    setModalContent({
      title: `Transfer Token`,
      content: <VaultTokenTransfer position={position} vault={vault} />,
    });
  };
  const [sortedVaultPositions, setSortedVaultPositions] =
    useState<{ position: IPosition; vault: IVault }[]>(vaultPositions);
  useEffect(() => {
    setSortedVaultPositions(vaultPositions);
  }, [vaultPositions]);
  const updateSortedVaultPositions = (vaultPositions: { position: IPosition; vault: IVault }[]) => {
    setSortedVaultPositions([...vaultPositions]);
  };
  const filteredVaultPositions = useMemo(() => {
    if (selectedVaultWalletType === WalletTypes.cyanWallet)
      return sortedVaultPositions.filter(position => position.position.isCyanWallet);
    if (selectedVaultWalletType === WalletTypes.mainWallet)
      return sortedVaultPositions.filter(position => !position.position.isCyanWallet);
    return sortedVaultPositions;
  }, [sortedVaultPositions, selectedVaultWalletType]);

  return (
    <ListContainer>
      <ListViewBox>
        <Header>
          <VaultPositionListHeader
            sortedVaultPositions={sortedVaultPositions}
            updateSortedVaultPositions={updateSortedVaultPositions}
          />
        </Header>
        <ContainerBox>
          {loading ? (
            <>
              {Array.from(Array(3).keys()).map(loader => (
                <VaultPositionRowLoading key={loader} />
              ))}
            </>
          ) : (
            <>
              {filteredVaultPositions.length === 0 && <NotFound msg={`No vault positions found`} />}
              <Container>
                {account &&
                  filteredVaultPositions.map(position => (
                    <VaultPositionRow
                      key={position.position.id}
                      position={{
                        ...position.position,
                        balanceInUSD:
                          bigNumToFloat(position.position.balance, position.vault.decimals) * position.vault.priceUsd,
                      }}
                      vault={position.vault}
                      account={account}
                      onClick={() =>
                        navigate(
                          `/vault/${CHAIN_IDS_TO_NAMES[position.vault.chainId as SupportedChainId]}/${
                            position.vault.contractAddress
                          }`,
                        )
                      }
                      onClickSell={() =>
                        navigate(
                          `/vault/${CHAIN_IDS_TO_NAMES[position.vault.chainId as SupportedChainId]}/${
                            position.vault.contractAddress
                          }?type=sell${
                            position.position.isCyanWallet && cyanWallet ? `&wallet=${cyanWallet.walletAddress}` : ""
                          }`,
                        )
                      }
                      onTransfer={() => onTransfer({ position: position.position, vault: position.vault })}
                    />
                  ))}
              </Container>
            </>
          )}
        </ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 414px) {
    border: none;
    border-radius: 20px;
    padding: 0;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
`;
