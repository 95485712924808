import styled from "styled-components";

import { Flex, Loader, StyledNftImage, Text } from "@cyanco/components/theme";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { numberWithCommas, shortenName } from "@/utils";

interface IProps {
  item: {
    collectionName: string;
    tokenId: string;
    imageUrl?: string | null;
  };
  price?: string;
  buyer?: string;
  seller?: string;
  isCreating: boolean;
  currency: string;
}

export const ItemsMetadata: React.FC<IProps> = ({ item, price, buyer, seller, currency, isCreating }) => {
  const address = seller ?? buyer;
  return (
    <>
      <Flex gap="10px">
        <div style={{ position: "relative" }}>
          <StyledNftImage src={item.imageUrl || NoImage} alt="nft" hasImage={!!item.imageUrl} />
          {isCreating && (
            <ImageLoader>
              <Loader stroke="white" size="64px" />
            </ImageLoader>
          )}
        </div>
        <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 7px 0">
          <Flex justifyContent="space-between">
            <Text color="secondary" size="md" style={{ overflowWrap: "anywhere" }} weight="600">
              {shortenName(item.collectionName, 21, 20, 0)}
            </Text>
            <Text color="secondary" size="md" style={{ overflowWrap: "anywhere" }} weight="600">
              #{shortenName(item.tokenId, 10, 5)}
            </Text>
          </Flex>
          <Flex gap="5px" direction="column" w="100%">
            <Flex justifyContent="space-between">
              <Text color="gray0" size="xs" weight="500">
                {`Price`}
              </Text>
              <Text color="gray0" size="xs" weight="500">
                {numberWithCommas(price || "0", 3)} {currency}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text color="gray0" size="xs" weight="500">
                {seller ? `Seller` : `Buyer`}
              </Text>
              <Text color="gray0" size="xs" weight="500">
                {address ? shortenName(address, 5) : "0x"}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const ImageLoader = styled.div`
  height: 85px;
  width: 85px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: none;
`;
