import { FC } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound, SubTitle } from "@cyanco/components/theme/v3";

import { IVault, IVaultAdditionalMetrics } from "@/apis/vault/types";
import { MainBox } from "@/components/ApeCoinStaking/new/components/common";

import { LatestLoanBodyRow, LatestLoanLoadingRow } from "./LatestLoanBodyRow";
import { LatestLoansHeader } from "./LatestLoansHeader";

interface LatestLoansProps {
  loans: IVaultAdditionalMetrics["latestLoans"];
  vault: IVault;
  loading: boolean;
}

export const LatestLoans: FC<LatestLoansProps> = ({ loans, vault, loading }) => {
  return (
    <Container direction="column" gap="1.5rem" mt="4.5rem">
      <SubTitle>{`Latest loans made from Vault`}</SubTitle>
      <Flex direction="column" w="100%">
        <ListViewBox>
          <Header>
            <LatestLoansHeader />
          </Header>
          <ContainerBox>
            {loading ? (
              Array.from(Array(10).keys()).map(loader => <LatestLoanLoadingRow key={loader} />)
            ) : loans.length === 0 ? (
              <NotFound msg="No loans found" />
            ) : (
              loans.map((loan, index) => {
                return <LatestLoanBodyRow vault={vault} loan={loan} index={index} key={loan.planId} />;
              })
            )}
          </ContainerBox>
        </ListViewBox>
      </Flex>
    </Container>
  );
};

const Container = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0 5px" }])}
`;

export const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative", margin: "1px" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

export const Header = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 80px;
  }
  position: sticky;
  align-items: end;
  top: 85px;
  background: ${({ theme }) => theme.colors.primary};
  transform-style: preserve-3d;
  z-index: 5;
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    position: absolute;
    transform: translateZ(-1px);
    top: -15px;
    height: calc(100% + 15px);
    left: -150px;
    width: calc(100% + 300px);
  }
`;

export const ContainerBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top: none;
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;
