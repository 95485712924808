import { ethers } from "ethers";
import { useEffect, useState } from "react";

import { Box, Button, Flex, Stepper, Text, useModal } from "@cyanco/components/theme";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { ICollectionFloorAsk } from "@/apis/collection/types";
import { liquidateDefaultedPlans } from "@/apis/vault/admin";
import { IVault, IVaultLatestLoan } from "@/apis/vault/types";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { NftImageWrapper, StyledNftImage } from "@/components/PlanCreation/ItemsMetadata";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getChainExplorerURL } from "@/utils";

import { getVaultAdminSignatureExpiry, signLiquidatePlans } from "../utils";
import { DefaultedLoanModal, DefaultedNftMetadata } from "./DefaultedLoanModal";
import { DefaultedLoanModalBulk } from "./DefaultedLoanModalBulk";

export const DefaultedLoanProgressModal = ({
  loans,
  vault,
}: {
  loans: Array<IVaultLatestLoan & { estimatedPrice: number; floorAsk?: ICollectionFloorAsk }>;
  vault: IVault;
}) => {
  const { signer } = useWeb3React();
  const { signInWallet } = useAuthContext();
  const { setPaymentLiquidatedNfts } = useTransactionContext();
  const { setModalContent, unsetModal } = useModal();
  const [selectedStep, setSelectedStep] = useState<PlanLiquidateSteps>(PlanLiquidateSteps.Liquidate);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);

  useEffect(() => {
    const onStepChange = async (step: PlanLiquidateSteps) => {
      if (!signer) return;
      try {
        switch (step) {
          case PlanLiquidateSteps.Liquidate: {
            const { token } = await signInWallet();
            const expiryDate = getVaultAdminSignatureExpiry();
            const signature = await signLiquidatePlans(
              loans.map(loan => ({
                planId: loan.planId,
                estimatedValue: ethers.utils.parseUnits(loan.estimatedPrice.toString(), vault.decimals).toString(),
              })),
              expiryDate,
              signer,
            );
            const tx = await liquidateDefaultedPlans({
              signature,
              plans: loans.map(loan => ({
                planId: loan.planId,
                estimatedValue: ethers.utils.parseUnits(loan.estimatedPrice.toString(), vault.decimals).toString(),
              })),
              token,
              expiryDate,
            });
            setTxnFinal(tx);
            setSelectedStep(PlanLiquidateSteps.Done);
            setPaymentLiquidatedNfts(prev => [...prev, ...loans.map(item => item.planId)]);
            return;
          }
        }
      } catch (err: any) {
        let errorMessage = err.error?.message ?? `Please try again later`;
        if (err?.response?.data?.message) {
          errorMessage = err.response.data.message;
        }
        if (loans.length === 1) {
          setModalContent({
            title: "Claim NFT",
            content: (
              <DefaultedLoanModal
                loan={loans[0]}
                vault={vault}
                error={{
                  title: `Something went wrong!`,
                  msg: errorMessage,
                }}
              />
            ),
          });
        } else {
          setModalContent({
            title: "Claim NFTs",
            content: (
              <DefaultedLoanModalBulk
                loans={loans}
                vault={vault}
                error={{
                  title: `Something went wrong!`,
                  msg: errorMessage,
                }}
              />
            ),
          });
        }
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);
  return (
    <Flex gap="12px" direction="column">
      {loans.length === 1 ? (
        <DefaultedNftMetadata loan={loans[0]} vault={vault} />
      ) : (
        <NftImageWrapper style={{ paddingTop: "0px" }}>
          {loans.map(item => (
            <StyledNftImage src={item.nftImageUrl || NoImage} hasImage={!!item.nftImageUrl} key={item.planId} />
          ))}
        </NftImageWrapper>
      )}
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={PlanLiquidateStepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(vault.chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === PlanLiquidateSteps.Done && (
        <Button onClick={unsetModal} style={{ height: "50px" }}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </Button>
      )}
    </Flex>
  );
};

enum PlanLiquidateSteps {
  Liquidate = 1,
  Done = 2,
}

const PlanLiquidateStepMarks = [
  {
    value: PlanLiquidateSteps.Liquidate,
    title: `Liquidate NFT`,
    description: `View on Etherscan`,
  },
];
