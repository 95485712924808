import styled from "styled-components";

import { Flex, TitleContainer } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { HomeTitleWebkit } from ".";
import { partnerFirstRowImgs, partnerSecondRowImgs } from "./consts";

export const Partners = () => {
  return (
    <Container direction="column" w="100%" justifyContent="center" alignItems="center">
      <TitleContainer>
        <HomeTitleWebkit>{`Backed by the Best`}</HomeTitleWebkit>
      </TitleContainer>
      <ImageWrapper w="100%" direction="column">
        <Flex wrap="wrap" justifyContent="space-around" w="100%" style={{ rowGap: "4rem" }}>
          {partnerFirstRowImgs.map(item => (
            <PartnerImg key={item.alt} alt={item.alt} src={item.src} />
          ))}
        </Flex>
        <Flex wrap="wrap" justifyContent="space-around" w="100%" style={{ rowGap: "4rem" }}>
          {partnerSecondRowImgs.map(item => (
            <PartnerImg key={item.alt} alt={item.alt} src={item.src} />
          ))}
        </Flex>
      </ImageWrapper>
    </Container>
  );
};

const Container = styled(Flex)`
  gap: 5rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])};
`;

const ImageWrapper = styled(Flex)`
  gap: 4rem;
`;

const PartnerImg = styled.img`
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.tablet]: "30%" }])};
  filter: ${({ theme }) =>
    theme.theme === "light" && "invert(100%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;
