import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { isMobile } from "../../../../interface/src/utils/userAgent";
import { Flex } from "../../Flex";
import { breakpoints } from "../../config";
import { SkeletonLine, Text } from "../core";
import { NoImage } from "../images";
import { NftImageNone, NftImageStyled } from "./NftCard";

type INftImageTooltip = {
  imageUrl?: string;
  data1: {
    label: string;
    value: string;
  };
  data2: {
    label: string;
    value: string;
  };
  x: number;
  y: number;
};

const NftMetadataImage: React.FC<{
  imageUrl?: string;
}> = ({ imageUrl }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  return (
    <div style={{ position: "relative", flex: 1 }}>
      <NftImageWrapper>
        {imageUrl && !imageError ? (
          <>
            {imageLoading && <ImageLoader />}
            <NftImageStyled
              src={imageUrl}
              alt={"tooltip-image-nft"}
              onLoad={() => setImageLoading(false)}
              onError={() => setImageError(true)}
            />
          </>
        ) : (
          <NftImageNone src={NoImage} alt={"no-image"} />
        )}
      </NftImageWrapper>
    </div>
  );
};

const NftTooltipCard: React.FC<INftImageTooltip & { tooltipRef: React.Ref<HTMLDivElement> }> = ({
  imageUrl,
  data1,
  data2,
  tooltipRef,
  x,
  y,
}) => {
  return createPortal(
    <NftTooltipWrapper
      ref={tooltipRef}
      style={{
        top: y + "px",
        left: x + "px",
      }}
    >
      <Flex direction="column" gap="0.7rem">
        <NftMetadataImage imageUrl={imageUrl} />
        <Flex justifyContent="space-between">
          <Flex direction="column" gap="4px">
            <Text color="gray0" weight="600" size="xs">
              {data1.label}
            </Text>
            <Text color="secondary" weight="600" size="xs">
              {data1.value}
            </Text>
          </Flex>
          <Flex direction="column" gap="4px" alignItems="flex-end">
            <Text color="gray0" weight="600" size="xs">
              {data2.label}
            </Text>
            <Text color="secondary" weight="600" size="xs">
              {data2.value}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </NftTooltipWrapper>,
    document.getElementById("modal-root") as HTMLElement,
    "tooltip",
  );
};

export const NftImageTooltip = ({
  data1,
  data2,
  imageUrl,
  hasMultiSelectionBox,
  children,
}: {
  children: React.ReactNode;
  imageUrl?: string | null;
  hasMultiSelectionBox: boolean;
  data1: {
    label: string;
    value: string;
  };
  data2: {
    label: string;
    value: string;
  };
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const focusTimeout = useRef<NodeJS.Timeout | null>(null);
  const [tooltipData, setTooltipData] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (isMobile) return;
    return () => {
      if (focusTimeout.current) {
        clearTimeout(focusTimeout.current);
      }
    };
  }, []);

  const handleScroll: EventListener = () => {
    if (focusTimeout.current) {
      clearTimeout(focusTimeout.current);
    }
    if (tooltipData.y !== 0 && tooltipData.x !== 0) {
      setTooltipData({
        x: 0,
        y: 0,
      });
    }
  };

  useEffect(() => {
    if (tooltipData.y !== 0 && tooltipData.x !== 0) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tooltipData]);

  const hideNftTooltip = useCallback(() => {
    if (isMobile) return;
    if (focusTimeout.current) {
      clearTimeout(focusTimeout.current);
    }
    setTooltipData({
      x: 0,
      y: 0,
    });
  }, []);
  const showNftTooltip = (args: { x: number; y: number }) => {
    if (isMobile) return;
    const bottomPosition = args.y + 270 + (hasMultiSelectionBox ? 110 : 70);
    const x = args.x + 10;
    const y =
      window.innerHeight <= bottomPosition ? window.innerHeight - 270 - (hasMultiSelectionBox ? 110 : 55) : args.y;
    focusTimeout.current = setTimeout(() => {
      setTooltipData({
        x,
        y,
      });
    }, 300);
  };
  return (
    <NftImageTooltipContainer
      onMouseEnter={e => {
        const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
        showNftTooltip({
          x: rect.left + rect.width,
          y: rect.top,
        });
      }}
      onMouseLeave={() => {
        hideNftTooltip();
      }}
    >
      {tooltipData.x !== 0 && tooltipData.y !== 0 && (
        <NftTooltipCard
          tooltipRef={tooltipRef}
          x={tooltipData.x}
          y={tooltipData.y}
          data1={data1}
          data2={data2}
          imageUrl={imageUrl ?? NoImage}
        />
      )}
      {children}
    </NftImageTooltipContainer>
  );
};

const NftTooltipWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.primary};
  padding: 0.6rem;
  cursor: pointer;
  transition: 0.2s;
  min-width: 200px;
  max-width: 200px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray20};
  z-index: 20;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

const NftImageTooltipContainer = styled.div`
  position: relative;
`;

const ImageLoader = styled(SkeletonLine)`
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 15px;
  width: 100%;
`;

const NftImageWrapper = styled.div`
  display: flex;
  object-fit: scale-down;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  :before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(100%);
  }
`;
