import orderBy from "lodash.orderby";
import { useMemo } from "react";

import { Flex } from "@cyanco/components/theme/Flex";
import { CsvButton, Hidden, SwitchButton, SwitchButtonGroup } from "@cyanco/components/theme/v3";

import { PeerPlanStatuses, isPeerPlan } from "@/apis/p2p/types";
import { bigNumToFixedStr } from "@/utils";

import { StickySelectorWrapper } from "../..";
import { useCreatedLoanBids, usePeerPlans } from "../../AccountDataContext";
import { LendTabData, P2PLendingSelectedViews, useLendingTabContext } from "../../AccountPageContext";

export const LendingViewSelectors = () => {
  const { selectedLendingView, setSelectedLendingView, selectedLendingTabData, setSelectedLendingTabData } =
    useLendingTabContext();

  const { userGivenPeerPlans } = usePeerPlans();
  const { createdLoanBids } = useCreatedLoanBids();

  const filteredPlansAndOffers = useMemo(() => {
    const plans = userGivenPeerPlans.filter(
      plan => plan.status === PeerPlanStatuses.LIQUIDATED || plan.status === PeerPlanStatuses.COMPLETED,
    );
    const offers = createdLoanBids.filter(offer => !offer.isActive || offer.isExpired);
    return orderBy(
      [...offers, ...plans],
      [item => (isPeerPlan(item) ? new Date(item.createdAt) : new Date(item.signedDate))],
      ["desc"],
    );
  }, [userGivenPeerPlans, createdLoanBids]);

  const csvData = useMemo(() => {
    if (selectedLendingView == P2PLendingSelectedViews.current) {
      return [
        ["Collection", "Borrower", "Loan Amount", "Term", "Due Date", "Interest Rate", "Status"],
        ...userGivenPeerPlans.map(plan => {
          return [
            `${plan.collection.name} ${plan.tokenId}`,
            plan.borrowerAddress,
            `${bigNumToFixedStr(plan.loanBid.amount, 5, plan.loanBid.currency.decimal)} ${
              plan.loanBid.currency.symbol
            }`,
            plan.loanBid.term,
            plan.dueDate,
            `${plan.loanBid.interestRate / 100}%`,
            PeerPlanStatuses[plan.status],
          ];
        }),
      ].join("\n");
    } else if (selectedLendingView == P2PLendingSelectedViews.offers) {
      return [
        ["Collection", "Progress", "Loan Amount", "Duration", "Interest", "Expiry"],
        ...createdLoanBids.map(offer => {
          return [
            `${offer.collection.address} ${offer.tokenId}`,
            `${offer.signatureUsageLimit - offer.signatureAvailableUsage} of ${offer.signatureUsageLimit} offers taken`,
            `${bigNumToFixedStr(offer.amount, 4, offer.currency.decimal)} ${offer.currency.symbol}`,
            offer.term,
            `${offer.interestRate / 100}%`,
            offer.signatureExpiry,
          ];
        }),
      ].join("\n");
    } else {
      return [
        ["Collection", "Loan Amount", "Duration", "Interest"],
        ...filteredPlansAndOffers.map(offer => {
          const isPlan = isPeerPlan(offer);
          if (isPlan) {
            return [
              `${offer.collection.name} ${offer.tokenId}`,
              `${bigNumToFixedStr(offer.loanBid.amount, 4, offer.loanBid.currency.decimal)} ${
                offer.loanBid.currency.symbol
              }`,
              offer.loanBid.term,
              `${offer.loanBid.interestRate / 100}%`,
            ];
          }
          return [
            `${offer.collection.name} ${offer.tokenId}`,
            `${bigNumToFixedStr(offer.amount, 4, offer.currency.decimal)} ${offer.currency.symbol}`,
            offer.term,
            `${offer.interestRate / 100}%`,
          ];
        }),
      ].join("\n");
    }
  }, [selectedLendingView, userGivenPeerPlans, createdLoanBids, filteredPlansAndOffers]);

  return (
    <StickySelectorWrapper alignItems="center" justifyContent="space-between" w="100%" h="28px" pb="1rem">
      <Flex gap="2px" alignItems="center" justifyContent="space-between" w="100%">
        <SwitchButtonGroup<P2PLendingSelectedViews> value={selectedLendingView} onChange={setSelectedLendingView} hover>
          <SwitchButton height="22px" value={P2PLendingSelectedViews.current}>{`Current`}</SwitchButton>
          <SwitchButton height="22px" value={P2PLendingSelectedViews.offers}>{`Offers`}</SwitchButton>
          <SwitchButton height="22px" value={P2PLendingSelectedViews.historical}>{`Historical`}</SwitchButton>
        </SwitchButtonGroup>
        <Hidden tabletDown>
          <Flex alignItems="center" gap="1rem">
            <CsvButton data={csvData} />
            <SwitchButtonGroup<LendTabData> value={selectedLendingTabData} onChange={setSelectedLendingTabData} hover>
              <SwitchButton height="22px" value={LendTabData.p2p}>{`Peer-to-Peer`}</SwitchButton>
              <SwitchButton height="22px" value={LendTabData.vault}>{`Peer-to-Vault`}</SwitchButton>
            </SwitchButtonGroup>
          </Flex>
        </Hidden>
      </Flex>
    </StickySelectorWrapper>
  );
};
