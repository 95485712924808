import { BigNumber } from "ethers";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { Button, Flex, SkeletonLine, Text, getCurrencyLogoSrc } from "@cyanco/components/theme";
import { ChevronDown } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { useAppContext } from "@/components/AppContextProvider";
import { ImageWrapper, StyledChevronDown, StyledSelect } from "@/components/PrivateSale/CommonComponents";
import { ICurrency } from "@/types";
import { bigNumToFloat, displayInUSD, numberWithCommas } from "@/utils";

import { IItemsWithPricer } from "./PlanCreationModal";

export const Pricer1Result = ({
  selectedCurrency,
  items,
  loading,
  planType,
  supportedCurrencies,
  onChangeCurrency,
  hasError,
  onClick,
}: {
  selectedCurrency: ICurrency;
  items: IItemsWithPricer;
  loading: boolean;
  planType: "bnpl" | "pawn";
  onChangeCurrency: (newAddress: string) => void;
  supportedCurrencies: ICurrency[];
  onClick: () => void;
  hasError: boolean;
}) => {
  const { usdPrice } = useAppContext();
  const theme = useTheme();
  const getUsdPrice = (price: number, currencySymbol: string) => {
    if (!usdPrice || !usdPrice[currencySymbol]) return 0;
    return usdPrice[currencySymbol] * price;
  };
  const totalPrice = useMemo(() => {
    if (items.length == 0) return { total: 0, usd: 0 };
    return items.reduce<{ total: number; usd: number }>(
      (acc, { price: itemPrice, currency }) => {
        const price = bigNumToFloat(itemPrice ?? BigNumber.from(0), currency.decimal);
        acc.total += price;
        acc.usd += getUsdPrice(price, currency.symbol);
        return acc;
      },
      { total: 0, usd: 0 },
    );
  }, [items, usdPrice]);

  return (
    <Flex direction="column" gap="20px">
      <Seperator />
      {planType === "pawn" && supportedCurrencies.length > 1 && (
        <>
          <Flex justifyContent="space-between" alignItems="center" style={{ position: "relative" }}>
            <Text color="gray0" size="sm">
              Select currency to borrow:
            </Text>
            <PillWrapper
              style={{
                paddingRight: "0.2rem",
              }}
            >
              <ImageWrapper>
                <img
                  height="16px"
                  width="16px"
                  src={getCurrencyLogoSrc(selectedCurrency.symbol as SupportedCurrenciesType)}
                />
              </ImageWrapper>
              <StyledSelect
                value={selectedCurrency.address}
                onChange={e => {
                  onChangeCurrency(e.target.value);
                }}
              >
                {supportedCurrencies.map(currency => (
                  <option value={currency.address} key={currency.address}>
                    {currency.symbol}
                  </option>
                ))}
              </StyledSelect>
              <StyledChevronDown>
                <ChevronDown
                  color={theme.theme == "dark" ? theme.colors.secondary : theme.colors.gray80}
                  width="10px"
                />
              </StyledChevronDown>
            </PillWrapper>
          </Flex>
          <Seperator />
        </>
      )}
      <Flex direction="column" gap="8px">
        <Flex justifyContent="space-between">
          <Text size="sm" weight="600" color="gray0">
            {items.length} item{items.length > 1 ? "s" : ""}
          </Text>
          <Text size="sm" weight="600" color="gray0">
            Total
          </Text>
        </Flex>

        <Flex justifyContent="space-between">
          <Flex>
            {items.map((item, index) => {
              return (
                <ImageWrapperItem
                  src={item.imageUrl || NoImage}
                  key={`${item.address}:${item.tokenId}`}
                  style={{ marginLeft: index > 0 ? "-20px" : 0 }}
                />
              );
            })}
          </Flex>
          <Flex direction="column" alignItems="flex-end">
            {loading ? (
              <SkeletonLine h="34px" w="50px" />
            ) : (
              <>
                <Text size="md" weight="600" color="secondary">
                  {numberWithCommas(totalPrice.total, 2)} {selectedCurrency.symbol}
                </Text>
                <Text size="xs" color="gray0">
                  {displayInUSD(totalPrice.usd)}
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Button disabled={hasError || loading} style={{ padding: "16px 0px" }} onClick={onClick}>
        <Text color="black" size="sm" weight="600">
          Select terms
        </Text>
      </Button>
    </Flex>
  );
};

const ImageWrapperItem = styled.img`
  border-radius: 6px;
  width: 35px;
  height: 35px;
`;

const Seperator = styled.div`
  border: 0.5px solid #2c2c2c;
  width: 100%;
`;

export const PillWrapper = styled(Flex)`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#C8C8C8")};
  border-radius: 30px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
  position: relative;
`;
