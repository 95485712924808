import axios from "axios";

import { FnFetchPriceUSD } from "./types";

export enum currencyList {
  ape = "APE",
  eth = "ETH",
  weth = "WETH",
  pol = "POL",
  usdc = "USDC",
}

export const fetchUSDPrice: FnFetchPriceUSD = async ({ currencies, date, abortSignal }) => {
  const prices = await Promise.all(
    Object.values(currencies).map(currency =>
      axios.get(`https://api.coinbase.com/v2/prices/${currency}-USD/buy${date ? `?date=${date}` : ""}`, {
        signal: abortSignal,
      }),
    ),
  );

  const result = prices
    .map(price => price.data)
    .reduce((acc, curr) => {
      const key = curr.data.base;
      return {
        ...acc,
        [key]: curr.data,
      };
    }, {});

  return result;
};
