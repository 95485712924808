import { BigNumber } from "ethers";

import { Box, Button, Card, Flex, Text } from "@cyanco/components/theme";

import { DATE_TIME_FORMAT_OPTIONS_DAYJS } from "@/config";
import { useGetUserTime } from "@/hooks";
import { IsInTestDrive } from "@/utils";

export const PayBox: React.FC<{
  plan: {
    amount: BigNumber;
    currency: string;
    dueDate: Date;
  };
  onPay: () => Promise<void>;
  isProcessing: boolean;
}> = ({ plan, onPay, isProcessing }) => {
  const { getUserPreferredTime } = useGetUserTime();
  return (
    <Card p={"15px"}>
      <Flex justifyContent="space-between" w="100%">
        <Flex direction="column" gap="4px" w="100%">
          <Text size="sm" weight="500" color="gray0">
            {`Payment due:`}
          </Text>
          <Text size="lg" weight="500" color="secondary">
            {getUserPreferredTime(plan.dueDate).format(DATE_TIME_FORMAT_OPTIONS_DAYJS)}
          </Text>
        </Flex>
        <Box w="120px">
          <Button loading={isProcessing} disabled={IsInTestDrive || isProcessing} onClick={onPay}>{`Pay`}</Button>
        </Box>
      </Flex>
    </Card>
  );
};
