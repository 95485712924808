import { BigNumber, ethers, utils } from "ethers";
import { useMemo, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { NumericFormat, OnValueChange } from "react-number-format";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Input, SystemMessage, Text } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { IUserToken } from "@/apis/user/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { displayInUSD, numberWithCommas, shortenAddress } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { useUserTokenContext } from "../../../../Token/TokenContextProvider";

export const UserTokenTransfer = ({ token }: { token: IUserToken & { tokenInUsd?: number } }) => {
  const [transferAmount, setTransferAmount] = useState<string>("");
  const [transferAddress, setTransferAddress] = useState<string>("");
  const { account, signer } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { refreshUserTokens } = useUserTokenContext();
  const showWalletButton = useMemo(() => {
    return transferAddress.length == 0;
  }, [transferAddress]);

  const {
    execute: transferToken,
    loading: transferLoading,
    error: transferError,
    result: isTransferCompleted,
  } = useAsyncCallback(async () => {
    if (!transferAmount || !signer) return;

    // Transferring token from Cyan Wallet
    if (cyanWallet && token.isCyanWallet) {
      // transfer native token
      if (token.address === ethers.constants.AddressZero) {
        const tx = await cyanWallet.execute(
          transferAddress,
          ethers.utils.parseUnits(transferAmount, token.decimal),
          "0x",
        );
        await tx.wait();
        return true;
      }
      // send erc-20 token
      const contractIFace = f.SampleERC20TokenFactory.createInterface();
      const encodedFnData = contractIFace.encodeFunctionData("transfer", [
        transferAddress,
        ethers.utils.parseUnits(transferAmount, token.decimal),
      ]);
      const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [token.address, "0", encodedFnData]);
      const tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        data: encodedCyanFnData,
      });
      await tx.wait();
      return true;
    }

    // Transferring token from User Wallet
    if (token.address === ethers.constants.AddressZero) {
      const tx = await signer.sendTransaction({
        to: transferAddress,
        value: ethers.utils.parseUnits(transferAmount, token.decimal),
      });
      await tx.wait();
      await refreshUserTokens();
      return true;
    } else {
      const contractWriter = f.SampleERC20TokenFactory.connect(token.address, signer);
      const tx = await contractWriter.transfer(transferAddress, ethers.utils.parseUnits(transferAmount, token.decimal));
      await tx.wait();
      await refreshUserTokens();
      return true;
    }
  });
  const tokenBalanceParsed = useMemo(() => {
    if (!token.tokenBalance) return "0";
    return utils.formatUnits(BigNumber.from(token.tokenBalance), token.decimal);
  }, [token.tokenBalance, token.decimal]);
  const onTransferAmountChange: OnValueChange = values => {
    setTransferAmount(values.value);
  };
  const mappedError = transferError ? mapAndLogError(transferError) : undefined;
  return (
    <Flex direction="column" gap="1rem">
      {mappedError && (
        <SystemMessage
          variant="error"
          title={mappedError.title}
          msg={mappedError.msg}
          description={mappedError.description}
        />
      )}
      <Flex direction="column" gap="0.4rem">
        <Flex gap="5px" justifyContent="flex-end" alignItems="center">
          <Text size="xs" weight="500" color="secondary">
            Balance: {numberWithCommas(tokenBalanceParsed, 4)}
          </Text>
          <MaxButton variant="ghost" onClick={() => setTransferAmount(tokenBalanceParsed)}>
            <Text weight="600" size="xxs" color="secondary">
              Max
            </Text>
          </MaxButton>
        </Flex>

        <Flex gap="0.7rem">
          <Flex gap="0.7rem" alignItems="center">
            <img
              src={token.imageUrl}
              alt=""
              style={{
                width: "42px",
                height: "42px",
                borderRadius: "50%",
              }}
            />
            <Text size="xxl" color="secondary" weight="600">
              {token.symbol}
            </Text>
          </Flex>
          <InputContainer>
            <NumericFormat
              placeholder="0"
              thousandSeparator=","
              allowLeadingZeros={false}
              allowNegative={false}
              onValueChange={onTransferAmountChange}
              value={transferAmount}
              disabled={isTransferCompleted || transferLoading}
              customInput={Input}
              decimalScale={4}
              valueIsNumericString
              fontSize="xl"
              textAlign="right"
              p="0.4rem 0.7rem"
            />
          </InputContainer>
        </Flex>
        <Text size="xs" weight="500" color="gray0" textAlign="right">
          ={" "}
          {token.tokenInUsd
            ? `${transferAmount ? displayInUSD(token.tokenInUsd * parseFloat(transferAmount)) : "$0"}`
            : "N/A"}
        </Text>
      </Flex>

      <Flex direction="column" gap="0.5rem">
        {!isTransferCompleted && (
          <>
            <Text size="sm" color="secondary">
              {`Transfer to address`}:
            </Text>
            <Input
              placeholder="0x1abcd..."
              onChange={e => setTransferAddress(e.target.value)}
              value={transferAddress}
              p={showWalletButton ? "0.2rem 0.2rem 0.2rem 0.4rem" : "0.65rem 0.2rem 0.65rem 0.4rem"}
            >
              <WalletButton
                onClick={() => {
                  if (token.isCyanWallet) {
                    if (account) setTransferAddress(account);
                  } else {
                    if (cyanWallet?.walletAddress) setTransferAddress(cyanWallet?.walletAddress);
                  }
                }}
                disabled={transferLoading}
                style={{
                  display: showWalletButton ? "block" : "none",
                }}
              >
                {!token.isCyanWallet ? (
                  <Text color="gray0" weight="600" size="xs" textWrap={false}>
                    {`Cyan Wallet`}
                  </Text>
                ) : (
                  <Text color="gray0" weight="600" size="xs" textWrap={false}>
                    {shortenAddress(account || "")}
                  </Text>
                )}
              </WalletButton>
            </Input>
          </>
        )}

        {isTransferCompleted && (
          <>
            <Text size="sm" color="secondary">
              🎉 {`Successfully sent to:`}
            </Text>
            <Input
              placeholder="0x1abcd..."
              onChange={e => setTransferAddress(e.target.value)}
              value={transferAddress}
              fontSize="sm"
              disabled
            />
          </>
        )}

        {!isTransferCompleted && (
          <StyledConfirmButton
            disabled={transferAddress === "" || transferAmount === "0"}
            onClick={transferToken}
            loading={transferLoading}
          >
            {`Transfer`}
          </StyledConfirmButton>
        )}
      </Flex>
    </Flex>
  );
};
const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  box-sizing: border-box;
  border: none;
  width: 100%;
  transition: 0.2s;
`;

const MaxButton = styled(Button)`
  max-width: 34px;
  height: 17px;
  background: ${({ theme }) => theme.colors.gray20};
  border-radius: 10px;
  border: none;
`;

const WalletButton = styled.button`
  background: ${({ theme }) => theme.colors.gray20};
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0.6rem;
  transition: background 0.2s ease-in-out;
`;
