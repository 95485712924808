import { Provider } from "@ethersproject/providers";
import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils";

import { factories as f } from "@cyanco/contract";
import { ApeCoinStaking } from "@cyanco/contract/abis";

import { IApePlanData } from "@/apis/ape-plans";
import { PoolId, apePaymentPlanContract, apeStakingContract, apeVaultContract } from "@/config";
import { bigNumToFloat } from "@/utils";
import { executeBatchRead, getCyanWalletAddress } from "@/utils/contract";
import { IBatchReaderData } from "@/utils/types";

import { IApeCoinBalance, IApeCoinPlanType, IApeStakingDataContract, IApeStakingDataCyan } from "./types";

export const getAllStakingData = async (args: { chainId: number; provider: Provider; mainWallet: string }) => {
  const { chainId, provider, mainWallet } = args;
  const cyanWalletAddress = await getCyanWalletAddress({ mainWallet, provider });
  const iApeStakingContract = f.ApeCoinStakingFactory.createInterface();
  const batchReadParam: IBatchReaderData[] = [
    {
      interface: iApeStakingContract,
      functionName: "getAllStakes",
      params: [mainWallet],
      contractAddress: apeStakingContract,
    },
  ];
  if (cyanWalletAddress) {
    batchReadParam.push({
      interface: iApeStakingContract,
      functionName: "getAllStakes",
      params: [cyanWalletAddress],
      contractAddress: apeStakingContract,
    });
  }
  const batchResult = await executeBatchRead(chainId, provider, batchReadParam);
  const allStakesMapped: IApeStakingDataContract[] = [];

  const apeCoinBalance: IApeCoinBalance = {
    stakedAmount: BigNumber.from("0"),
    stakedAmountCyan: BigNumber.from("0"),
    earnedAmount: BigNumber.from("0"),
    earnedAmountCyan: BigNumber.from("0"),
  };

  const mainWalletStakes = batchResult[0][0] as ApeCoinStaking.DashboardStakeStructOutput[];
  const cyanWalletStakes = (batchResult[1]?.[0] ?? []) as ApeCoinStaking.DashboardStakeStructOutput[];
  const allNftStakes = [...mainWalletStakes, ...cyanWalletStakes].filter(
    stake => bigNumToFloat(stake.poolId, 0) !== PoolId.COIN,
  );
  for (const stake of allNftStakes) {
    const pairedTokenId = bigNumToFloat(stake.pair.mainTokenId, 0);
    const pairedTokenPool = bigNumToFloat(stake.pair.mainTypePoolId, 0);
    const isPaired = pairedTokenId !== 0 && pairedTokenPool !== 0;
    allStakesMapped.push({
      tokenId: bigNumToFloat(stake.tokenId, 0).toString(),
      poolId: bigNumToFloat(stake.poolId, 0),
      stakedAmount: stake.deposited,
      earnedAmount: stake.unclaimed,
      pairedTokenId: isPaired ? pairedTokenId.toString() : null,
      pairedTokenPool: isPaired ? pairedTokenPool : null,
      isPaired,
    });
  }
  const apeCoinPoolStakeMain = mainWalletStakes.find(stake => bigNumToFloat(stake.poolId, 0) === PoolId.COIN);
  if (apeCoinPoolStakeMain) {
    apeCoinBalance.stakedAmount = apeCoinPoolStakeMain.deposited;
    apeCoinBalance.earnedAmount = apeCoinPoolStakeMain.unclaimed;
  }
  if (cyanWalletAddress) {
    const apeCoinPoolStakeCyan = cyanWalletStakes.find(stake => bigNumToFloat(stake.poolId, 0) === PoolId.COIN);
    if (apeCoinPoolStakeCyan) {
      apeCoinBalance.stakedAmountCyan = apeCoinPoolStakeCyan.deposited;
      apeCoinBalance.earnedAmountCyan = apeCoinPoolStakeCyan.unclaimed;
    }
  }
  // get bakc pairing info to main tokens
  allStakesMapped.forEach(stake => {
    const stakedBakc = allStakesMapped.find(
      s =>
        s.pairedTokenId === stake.tokenId &&
        s.pairedTokenPool === stake.poolId &&
        s.isPaired &&
        s.poolId === PoolId.BAKC,
    );
    if (stakedBakc) {
      stake.isPaired = true;
      stake.pairedTokenId = stakedBakc.tokenId;
      stake.pairedTokenPool = PoolId.BAKC;
    }
  });
  return { allNftStakes: allStakesMapped, apeCoinBalance };
};

export const applyApeStakingDataToAssets = <T>(args: {
  apeStakeDatasFromContract: IApeStakingDataContract[];
  assets: Array<
    T & {
      poolId: number;
      tokenId: string;
    } & IApePlanData
  >;
  vaultTokenPrice: BigNumber;
  serviceFeeRate: BigNumber;
  pools: {
    [key: number]: {
      rewardsPerHour: BigNumber;
      totalStakedAmount: BigNumber;
      interestRate: number;
    };
  };
}): Array<T & IApeStakingDataCyan> => {
  const { apeStakeDatasFromContract, assets, vaultTokenPrice, serviceFeeRate, pools } = args;
  const newItems = [...assets].map(asset => {
    const apeStakeDataFromContract = apeStakeDatasFromContract.find(
      stake => stake.tokenId === asset.tokenId && asset.poolId === stake.poolId,
    );
    const apeStakingData: IApeStakingDataCyan["apeStaking"] = {
      poolId: asset.poolId,
      stakedAmount: apeStakeDataFromContract?.stakedAmount ?? BigNumber.from(asset?.apeStaking?.borrowedApe ?? 0),
      earnedAmount: apeStakeDataFromContract?.earnedAmount ?? BigNumber.from(0),
      pairedTokenId: apeStakeDataFromContract?.pairedTokenId ?? null,
      pairedTokenPool: apeStakeDataFromContract?.pairedTokenPool ?? null,
      isPaired: apeStakeDataFromContract?.isPaired ?? false,
      plan: null,
    };
    if (asset.apeStaking) {
      const { stakedAmount, earnedAmount, poolId } = apeStakingData;
      const interestRateScaled = BigNumber.from(pools[poolId].interestRate).mul(100);
      const totalServiceFeeRate = serviceFeeRate.add(interestRateScaled);
      const remainingRateForBorrowed = BigNumber.from(10000).sub(totalServiceFeeRate);
      const remainingRateForRewards = BigNumber.from(10000).sub(serviceFeeRate);
      if (apeStakeDataFromContract && !stakedAmount.isZero()) {
        const borrowApeRewards = earnedAmount
          .mul(asset.apeStaking.borrowedApe)
          .div(stakedAmount)
          .mul(remainingRateForBorrowed)
          .div(10000);

        const rewards = earnedAmount
          .mul(stakedAmount.sub(asset.apeStaking.borrowedApe))
          .div(stakedAmount)
          .mul(remainingRateForRewards)
          .div(10000);

        apeStakingData.earnedAmount = rewards.add(borrowApeRewards);
      } else {
        apeStakingData.earnedAmount = BigNumber.from(0);
      }
      apeStakingData.plan = {
        ...asset.apeStaking,
        type: BigNumber.from(asset.apeStaking.borrowedApe).gt(0)
          ? IApeCoinPlanType.Borrow
          : IApeCoinPlanType.AutoCompound,
        totalRewards: vaultTokenPrice.mul(asset.apeStaking.totalRewards),
      };
    }
    return { ...asset, apeStaking: apeStakingData };
  });
  return newItems;
};

export const getPoolAndVaultData = async (
  provider: Provider,
  chainId: number,
): Promise<{
  poolsData: {
    [key: number]: {
      rewardsPerHour: BigNumber;
      totalStakedAmount: BigNumber;
      interestRate: number;
    };
  };
  serviceFeeRate: BigNumber;
  vaultTokenPrice: BigNumber;
}> => {
  const iPoolContract = f.ApeCoinStakingFactory.createInterface();
  const iApeVaultContract = f.CyanApeCoinVaultV1Factory.createInterface();
  const iApePlanContract = f.CyanApeCoinPlanV1Factory.createInterface();
  const oneCyanVaultToken = parseEther("1");

  const batchResult = await executeBatchRead(chainId, provider, [
    {
      interface: iPoolContract,
      functionName: "getPoolsUI",
      params: [],
      contractAddress: apeStakingContract,
    },
    {
      interface: iApeVaultContract,
      functionName: "getPoolInterestRates",
      params: [],
      contractAddress: apeVaultContract,
    },
    {
      interface: iApePlanContract,
      functionName: "serviceFeeRate",
      params: [],
      contractAddress: apePaymentPlanContract,
    },
    {
      interface: iApeVaultContract,
      functionName: "calculateCurrencyByToken",
      params: [oneCyanVaultToken],
      contractAddress: apeVaultContract,
    },
  ]);
  const _poolsUi = batchResult[0];
  const _interestRates = batchResult[1][0];
  const serviceFeeRate = batchResult[2][0];
  const vaultTokenPrice = batchResult[3][0];
  return {
    poolsData: {
      [PoolId.COIN]: {
        totalStakedAmount: _poolsUi[PoolId.COIN].stakedAmount,
        rewardsPerHour: _poolsUi[PoolId.COIN].currentTimeRange.rewardsPerHour,
        interestRate: bigNumToFloat(_interestRates[PoolId.COIN], 0) / 100,
      },
      [PoolId.MAYC]: {
        totalStakedAmount: _poolsUi[PoolId.MAYC].stakedAmount,
        rewardsPerHour: _poolsUi[PoolId.MAYC].currentTimeRange.rewardsPerHour,
        interestRate: bigNumToFloat(_interestRates[PoolId.MAYC], 0) / 100,
      },
      [PoolId.BAYC]: {
        totalStakedAmount: _poolsUi[PoolId.BAYC].stakedAmount,
        rewardsPerHour: _poolsUi[PoolId.BAYC].currentTimeRange.rewardsPerHour,
        interestRate: bigNumToFloat(_interestRates[PoolId.BAYC], 0) / 100,
      },
      [PoolId.BAKC]: {
        totalStakedAmount: _poolsUi[PoolId.BAKC].stakedAmount,
        rewardsPerHour: _poolsUi[PoolId.BAKC].currentTimeRange.rewardsPerHour,
        interestRate: bigNumToFloat(_interestRates[PoolId.BAKC], 0) / 100,
      },
    },
    serviceFeeRate: serviceFeeRate,
    vaultTokenPrice: vaultTokenPrice,
  };
};

export const DAYS_IN_YEAR = 365;
