import dayjs from "dayjs";
import { FC, useMemo, useState } from "react";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import { Box } from "@cyanco/components/theme/Box";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { getDefaultBannerByChain } from "@cyanco/components/theme/utils";
import { Button, CopyButton, Hidden, SubTitle, Text } from "@cyanco/components/theme/v3";
import { Decrease, Discord, Globe, Increase, Twitter } from "@cyanco/components/theme/v3/icons";
import {
  CyanC,
  EtherscanGray,
  LooksrareGray,
  NoImage,
  OpenseaGray,
  X2Y2Gray,
} from "@cyanco/components/theme/v3/images";

import { ICollectionBe } from "@/apis/collection/types";
import { isProd } from "@/config";
import { CHAIN_IDS_TO_NAMES, CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS, SupportedChainId } from "@/constants/chains";
import {
  bigNumToFloat,
  getChainExplorerURL,
  getCryptoSymbolForChain,
  getMarketCollectionUrl,
  getMarketCollectionUrlWithAffiliate,
  jumpToLink,
  numberWithCommas,
  shortenAddress,
} from "@/utils";

import "./CollectionDetailSection.css";

export const calculateChangePercent = (value: number | null, changedBy: number | null): number | null => {
  if (!value || !changedBy) return null;
  const current = value * changedBy;
  const difference = current - value;
  const percent = difference === 0 ? 0 : (difference / (current > value ? current : value)) * 100;
  return percent;
};
export const CollectionDetailSection: React.FC<{
  collection: ICollectionBe;
}> = ({ collection }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const oneDayChangePercent = useMemo(() => {
    return calculateChangePercent(collection.floorSale["1day"], collection.floorSaleChange["1day"]);
  }, [collection.floorSale]);
  const getMarketCap = useMemo(() => {
    const floor = collection.floorAsk.price?.amount.decimal ?? collection.floorSale["1day"];
    if (!!!floor) return null;
    return Number(collection.tokenCount) * floor;
  }, [collection.floorAsk.price, collection.tokenCount]);

  const currencySymbol = useMemo(() => {
    return (
      collection.floorAsk.price?.currency.symbol ||
      collection.topBid.price?.currency.symbol ||
      getCryptoSymbolForChain(collection.chainId)
    );
  }, [collection.chainId]);

  const numOfOwners = useMemo(() => {
    return collection.ownerCount ? Number(collection.ownerCount) : null;
  }, [collection]);

  const uniqueOwnersPercent = useMemo(() => {
    if (!collection.tokenCount || !numOfOwners || Number(collection.tokenCount) == 0) return null;
    return ((numOfOwners / Number(collection.tokenCount)) * 100).toFixed();
  }, [collection.tokenCount, numOfOwners]);

  const listedTokensPercent = useMemo(() => {
    if (
      !collection.tokenCount ||
      !collection.onSaleCount ||
      Number(collection.tokenCount) == 0 ||
      Number(collection.onSaleCount) == 0
    )
      return null;
    return ((Number(collection.onSaleCount) / Number(collection.tokenCount)) * 100).toFixed(2);
  }, [collection]);

  const collectionBanner = useMemo(() => {
    if (!collection.banner) {
      const backgrounds = getDefaultBannerByChain(collection.chainId);
      return theme.theme === "dark" ? backgrounds[0] : backgrounds[1];
    }
    return collection.banner;
  }, [collection, theme.theme]);
  const getCollectionStat = (
    value: number | string | null | undefined,
    postfix: string,
    removePostfixSpace?: boolean,
  ) => {
    return value !== null && value !== undefined
      ? `${numberWithCommas(value, Number(value) > 1 ? 0 : 1)}${removePostfixSpace ? "" : " "}${postfix}`
      : "-";
  };

  const getCollectionStatFromBigNumber = (value: number | string | null | undefined, postfix: string, fix = 4) => {
    if (value === null || value === undefined) {
      return "-";
    }

    const num = bigNumToFloat(value);
    if (num < 0.001) {
      return `< 0.001 ${postfix}`;
    }

    return `${num.toFixed(fix)} ${postfix}`;
  };

  return (
    <Container>
      <CollectionBannerImage src={collectionBanner} />
      <CollectionInfoContainer direction="column">
        <CollectionInfoTitleContainer>
          <CollectionTitleWrapper alignItems="center" gap="15px">
            <CollectionLogo src={collection.image ?? NoImage} />
            <Flex direction="column" gap="5px">
              <SubTitle>{collection.name}</SubTitle>
              <Flex gap="5px" wrap="wrap">
                <CollectionDetailSubText color="gray0">
                  {numberWithCommas(collection.tokenCount)} {`items`}
                </CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">|</CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">
                  {`created`} {dayjs(collection.createdAt).format("MMMM YYYY")}
                </CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">|</CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">
                  {CHAIN_INFO[collection.chainId as typeof CYAN_SUPPORTED_CHAIN_IDS[number]]?.label || ""}
                </CollectionDetailSubText>
              </Flex>
            </Flex>
          </CollectionTitleWrapper>

          {collection.description && <CollectionDescription description={collection.description} />}
        </CollectionInfoTitleContainer>
        {!collection.description ? <CollectionStatsGapWrapper /> : <Gapper />}
        <CollectionInfoTitleContainer>
          <Flex alignItems="center" justifyContent="space-between" style={{ width: "100%", maxWidth: "800px" }}>
            <Hidden tabletDown>
              <DailyCollectionStat
                statName={`Market Cap`}
                statValue={getCollectionStat(getMarketCap, currencySymbol)}
              />
            </Hidden>
            <DailyCollectionStat
              statName={`24 hour volume`}
              statValue={
                collection.volume["1day"]
                  ? getCollectionStat(collection.volume["1day"].toFixed(2), currencySymbol)
                  : "-"
              }
            />
            <DailyCollectionStat
              statName={`Floor Price`}
              statValue={getCollectionStatFromBigNumber(collection.floorAsk.price?.amount.raw, currencySymbol, 2)}
            />
            {oneDayChangePercent !== null && (
              <Hidden tabletUp>
                <FloorChange statValue={oneDayChangePercent} />
              </Hidden>
            )}
            <Hidden tabletDown>
              <DailyCollectionStat
                statName={`Best Bid`}
                statValue={getCollectionStatFromBigNumber(collection.topBid.price?.amount.raw, currencySymbol, 2)}
              />
            </Hidden>
            <Hidden laptopSDown>
              <DailyCollectionStat statName={`Listed`} statValue={getCollectionStat(listedTokensPercent, "%", true)} />
            </Hidden>
            {numOfOwners !== null && (
              <Hidden laptopDown>
                <DailyCollectionStat statName={`Owners`} statValue={numOfOwners.toString()} />
              </Hidden>
            )}
            {!!numOfOwners && (
              <Hidden laptopMDown>
                <DailyCollectionStat
                  statName={`Unique Wallets`}
                  statValue={!uniqueOwnersPercent ? "-" : `${uniqueOwnersPercent}%`}
                />
              </Hidden>
            )}
          </Flex>
          <Hidden tabletDown>
            <CollectionDescriptionBox>
              <Flex gap="10px" alignItems="center" w="fit-content">
                <CopyButton value={collection.address}>
                  <Text size="xs" weight="600" color="secondary">
                    {shortenAddress(collection.address)}
                  </Text>
                </CopyButton>
                <IconButton
                  variant="ghost"
                  onClick={() => collection.discordUrl && jumpToLink(collection.discordUrl)}
                  disabled={!collection.discordUrl}
                >
                  <Discord width={16} height={16} color={theme.colors.secondary} />
                </IconButton>
                <IconButton
                  variant="ghost"
                  disabled={!collection.twitterAccount}
                  onClick={() =>
                    collection.twitterAccount && jumpToLink(`https://twitter.com/${collection.twitterAccount}`)
                  }
                >
                  <Twitter width={16} height={16} color={theme.colors.secondary} />
                </IconButton>

                <IconButton
                  variant="ghost"
                  disabled={!collection.externalUrl}
                  onClick={() => collection.externalUrl && jumpToLink(collection.externalUrl)}
                >
                  <Globe width={16} height={16} color={theme.colors.secondary} />
                </IconButton>
                <IconButtonMarket
                  variant="ghost"
                  onClick={() => jumpToLink(`${getChainExplorerURL(collection.chainId)}/address/${collection.address}`)}
                >
                  <img src={EtherscanGray} width={16} height={16} />
                </IconButtonMarket>
                <IconButtonMarket
                  variant="ghost"
                  onClick={() =>
                    jumpToLink(
                      `${getMarketCollectionUrl(SupportedMarketPlaces.OPENSEA, collection.slug, collection.chainId)}`,
                    )
                  }
                >
                  <img src={OpenseaGray} width={16} height={16} />
                </IconButtonMarket>
                <IconButtonMarket
                  variant="ghost"
                  onClick={() =>
                    jumpToLink(
                      `${getMarketCollectionUrlWithAffiliate(
                        SupportedMarketPlaces.LOOKSRARE,
                        collection.address,
                        collection.chainId,
                      )}`,
                    )
                  }
                >
                  <img src={LooksrareGray} width={16} height={16} />
                </IconButtonMarket>
                {isProd && (
                  <IconButtonMarket
                    variant="ghost"
                    onClick={() =>
                      jumpToLink(
                        `${getMarketCollectionUrl(SupportedMarketPlaces.X2Y2, collection.slug, collection.chainId)}`,
                      )
                    }
                  >
                    <img src={X2Y2Gray} width={16} height={16} />
                  </IconButtonMarket>
                )}
                {collection.vaults &&
                  collection.vaults.length > 0 &&
                  collection.vaults
                    .filter(v => v.isBnplAllowed)
                    .map(vault => (
                      <VaultButton
                        key={vault.id}
                        onClick={() => {
                          navigate(
                            `/vault/${CHAIN_IDS_TO_NAMES[vault.chainId as SupportedChainId]}/${vault.contractAddress}`,
                          );
                        }}
                      >
                        <Flex gap="5px" alignItems="center">
                          <div className="token-image" style={{ background: vault.colorCode }}>
                            <img src={CyanC} alt={vault.name} />
                          </div>
                          <Text size="xs" weight="600" color="black" textWrap={false}>
                            {vault.name}
                          </Text>
                        </Flex>
                      </VaultButton>
                    ))}
              </Flex>
            </CollectionDescriptionBox>
          </Hidden>
        </CollectionInfoTitleContainer>
      </CollectionInfoContainer>
    </Container>
  );
};

export const FloorChange = ({ statValue }: { statValue: number }) => {
  const theme = useTheme();
  return (
    <Flex direction="column" gap="3px">
      <Flex alignItems="center" gap="10px">
        {statValue > 0 ? <Increase color={theme.colors.green} /> : <Decrease />}
        <Text color="green" size="md" weight="700">
          {Math.abs(statValue).toLocaleString("en-us", { maximumFractionDigits: 2 })}%
        </Text>
      </Flex>
      <Text size="xs" color="secondary" weight="400">
        {`24H Floor`}
      </Text>
    </Flex>
  );
};

export const DailyCollectionStat = ({ statName, statValue }: { statName: string; statValue: string }) => {
  return (
    <Flex direction="column" gap="3px">
      <Text color="secondary" size="md" weight="700" style={{ whiteSpace: "nowrap" }}>
        {statValue}
      </Text>
      <Text size="xs" color="secondary" weight="400" style={{ whiteSpace: "nowrap" }}>
        {statName}
      </Text>
    </Flex>
  );
};

const Container = styled.div`
  posiion: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CollectionBannerImage = styled.div<{ src?: string }>`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ src, theme }) =>
    `linear-gradient(to top,${theme.backgroundColor}  1%, transparent 30%),url(${src && src})`};
  background-color: ${({ src, theme }) => !src && theme.colors.gray10};
  height: 200px;
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 150 }, { [breakpoints.mobile]: 100 }])}
`;

export const CollectionInfoContainer = styled(Flex)`
  padding: 0 10rem;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "2rem calc(0.5rem + 5px) 0rem calc(0.5rem + 5px)" },
  ])}
`;

export const CollectionInfoTitleContainer = styled(Flex)`
  position: relative;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: start;
  padding-top: 1rem;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
  }
  ${getStyleWithMediaQuery("margin-top", "px", [{ [breakpoints.mobile]: -20 }])}
  ${getStyleWithMediaQuery("padding-top", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 0.5 }])}
`;

export const CollectionLogo = styled.img`
  border-radius: 50%;
  width: 75px;
  height: 75px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.tablet]: 65 }, { [breakpoints.mobile]: 50 }])};
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 65 }, { [breakpoints.mobile]: 50 }])};
`;

export const CollectionDetailSubText = styled(Text)`
  font-size: 12px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.mobile]: 10 }])};
  font-weight: 400;
`;

const CollectionDescriptionBox = styled.div`
  width: 800px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.laptopL]: "500px" }, { [breakpoints.tablet]: "100%" }])};
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CollectionTitleWrapper = styled(Flex)`
  align-self: start;
  ${getStyleWithMediaQuery("position", "", [{ [breakpoints.laptop]: "relative" }])};
`;

export const CollectionStatsGapWrapper = styled(Flex)`
  margin-top: calc(36px + 2rem);
  ${getStyleWithMediaQuery("margin-top", "", [
    { [breakpoints.laptop]: "calc(24px + 2rem)" },
    { [breakpoints.mobile]: "calc(24px + 1rem)" },
  ])};
`;

export const IconButton = styled(Button)`
  opacity: 0.7;
  filter: ${props =>
    props.theme.theme === "dark"
      ? "invert(0%) sepia(95%) saturate(20%) hue-rotate(39deg) brightness(93%) contrast(107%)"
      : "nvert(100%) sepia(0%) saturate(0%) hue-rotate(160deg) brightness(108%) contrast(101%)"};
  &:hover {
    opacity: 1;
  }
`;

export const IconButtonMarket = styled(Button)`
  opacity: 0.7;
  filter: ${props => (props.theme.theme === "dark" ? " invert(0%)" : " invert(100%)")};
  &:hover {
    opacity: 1;
  }
`;

export const Gapper = styled(Box)`
  margin-top: 1.4rem;
  ${getStyleWithMediaQuery("margin-top", "rem", [{ [breakpoints.laptop]: 2 }, { [breakpoints.tablet]: 2 }])};
`;

const DescriptionToggler: FC = ({ children }) => {
  const theme = useTheme();
  const [isFull, setIsFull] = useState(false);

  const onClickToggle = (e: React.MouseEvent) => {
    e.preventDefault();

    setIsFull(!isFull);
  };

  return (
    <div className={`description-toggler description-toggler__${isFull ? "full" : "small"} ${theme.theme}`}>
      {children}
      <div
        className="toggler"
        style={{
          backgroundColor: theme.backgroundColor,
          boxShadow: `-20px 0 5px 1px ${theme.backgroundColor}`,
        }}
      >
        <a href="#" onClick={onClickToggle}>
          {isFull ? "See less" : "See more"}
        </a>
      </div>
    </div>
  );
};

export const CollectionDescription = ({ description }: { description: string }) => {
  return (
    <DescriptionToggler>
      <CollectionDescriptionBox>
        <Hidden tabletDown>
          <Text size="sm" color="secondary">
            <Markdown
              components={{
                a: ({ children, ...props }) => (
                  <a style={{ color: "#00ffff", textDecoration: "none" }} {...props}>
                    {" "}
                    {children}{" "}
                  </a>
                ),
              }}
            >
              {description}
            </Markdown>
          </Text>
        </Hidden>
        <Hidden tabletUp>
          <Flex justifyContent="space-between" direction={"column"} alignItems="flex-start">
            <Text size="sm" color="secondary">
              <Markdown
                components={{
                  a: ({ children, ...props }) => (
                    <a style={{ color: "#00ffff", textDecoration: "none" }} {...props}>
                      {" "}
                      {children}{" "}
                    </a>
                  ),
                }}
              >
                {description}
              </Markdown>
            </Text>
          </Flex>
        </Hidden>
      </CollectionDescriptionBox>
    </DescriptionToggler>
  );
};

const VaultButton = styled.button`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${props => props.theme.colors.secondary};
  border-style: ${({ theme }) => (theme.theme == "dark" ? "none" : "solid")};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.2rem 0.3rem;
  height: fit-content;
  cursor: pointer;
  & .token-image {
    height: 16px;
    width: 16px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    & img {
      width: 10px;
      height: 10px;
    }
  }
`;
