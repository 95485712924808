import { CHAIN_IDS_TO_NAMES } from "@/constants/chains";

import { axios } from "../axios";
import { FnGetNftAvailableLoans } from "./types";

export const fetchAvailableLoanOffersForNft = async (
  args: FnGetNftAvailableLoans["params"],
): Promise<FnGetNftAvailableLoans["result"]> => {
  const { chainId, collectionAddress, abortSignal, wallet, tokenId } = args;
  const chainSlug = CHAIN_IDS_TO_NAMES[chainId];
  const response = await axios.get(`/v2/users/${wallet}/${collectionAddress}/${tokenId}/available-loans`, {
    params: { chain: chainSlug },
    signal: abortSignal,
  });
  return response.data;
};
