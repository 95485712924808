import { Flex } from "@cyanco/components/theme/Flex";

import { HorizantallyContainerGap } from "../..";
import { WalletSelectedViews, useTabViewStyle, useWalletTabContext } from "../../AccountPageContext";
import { WalletViewSelectors } from "./Selectors";
import { UserNfts } from "./UserNfts";
import { UserTokens } from "./UserTokens";

export const WalletView = () => {
  const { selectedWalletView } = useWalletTabContext();
  const { tabViewStyle } = useTabViewStyle();
  return (
    <Flex direction="column">
      <WalletViewSelectors />
      <HorizantallyContainerGap isGrid={tabViewStyle == "grid" && selectedWalletView === WalletSelectedViews.nfts}>
        <div
          style={{
            display: selectedWalletView === WalletSelectedViews.nfts ? "block" : "none",
          }}
        >
          <UserNfts />
        </div>
        <div
          style={{
            display: selectedWalletView === WalletSelectedViews.tokens ? "block" : "none",
          }}
        >
          <UserTokens />
        </div>
      </HorizantallyContainerGap>
    </Flex>
  );
};
