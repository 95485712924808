import dayjs from "dayjs";
import { useContext, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Card, Loader, Text, Toggler } from "@cyanco/components/theme/v3/core";

import { useGetUserTime } from "@/hooks";
import { getFormattedTimeForUserHistory } from "@/utils";

import { AccountPageContext } from "../Account/AccountPageContext";
import { useAuthContext } from "../AuthContext/AuthContextProvider";
import { ThemeContext } from "../ThemeContextProvider";

export const SettingsModal = () => {
  const [loading, setLoading] = useState(false);
  const { setIsLightTheme } = useContext(ThemeContext);
  const { reset } = useContext(AccountPageContext);
  const { user, setUser } = useAuthContext();
  const { getUserPreferredTime, showLocalTime } = useGetUserTime();
  const refreshDapp = () => {
    localStorage.clear();
    window.location.reload();
  };
  const resetDapp = () => {
    setLoading(true);
    setIsLightTheme(false);
    reset();
    setTimeout(() => setLoading(false), 1000);
  };

  const handleToggler = (value: boolean) => {
    setUser({
      ...user,
      config: { ...user?.config, showLocalTime: user?.config?.showLocalTime ?? false, showAPR: value },
    });
  };
  const handleLocalTimeToggler = (value: boolean) => {
    setUser({ ...user, config: { ...user?.config, showLocalTime: value, showAPR: user?.config?.showAPR ?? false } });
  };
  const formattedDate = getUserPreferredTime(dayjs());
  return (
    <Flex direction="column" gap="20px">
      <StyledCard p="0.75rem">
        <Flex direction="column" gap="2rem">
          <Flex justifyContent="space-between">
            <Text color="secondary" size="md" weight="600">
              {`Show interest rates in APR`}
            </Text>
            <Toggler size="sm" value={user?.config?.showAPR || false} onChange={handleToggler} />
          </Flex>
          <Flex direction="column" gap="1rem">
            <Text weight="500" size="xs" color="secondary">
              {`Interest rates for BNPL, loans and payment menus will show in APR when this is turned on.`}
            </Text>
            <Text weight="500" size="xs" color="secondary">
              {`APR = Annualised Percentage Rate`}
            </Text>
          </Flex>
        </Flex>
      </StyledCard>
      <StyledCard p="0.75rem">
        <Flex direction="column" gap="2rem">
          <Flex justifyContent="space-between">
            <Text color="secondary" size="md" weight="600">
              {`Show local times`}
            </Text>
            <Toggler size="sm" value={user?.config?.showLocalTime || false} onChange={handleLocalTimeToggler} />
          </Flex>
          <Flex direction="column" gap="1rem">
            <Text weight="500" size="xs" color="secondary">
              {`Activating this feature will synchronize the app's displayed times with your computer's system time. By default, times are shown in UTC when this feature is disabled.`}
            </Text>
            <Text weight="500" size="xs" color="secondary">
              {`Display time:`}
              <Text weight="600" size="xs" color="secondary">
                {` ${formattedDate.format("MMMM")} ${formattedDate.format("DD")}, ${formattedDate.format("YYYY")}
                ${getFormattedTimeForUserHistory(formattedDate, showLocalTime)}`}
              </Text>
            </Text>
          </Flex>
        </Flex>
      </StyledCard>
      <StyledCard p="0.75rem">
        <Flex direction="column" gap="2rem">
          <Flex justifyContent="space-between">
            <Text color="secondary" size="md" weight="600">
              {`Reset settings`}
            </Text>
            <RemoveButton onClick={resetDapp}>
              {!loading ? (
                <Text color="white" size="sm" weight="600">{`Reset to Default`}</Text>
              ) : (
                <Loader stroke="white" size="17px" />
              )}
            </RemoveButton>
          </Flex>
          <Text weight="500" size="xs" color="secondary">
            {`Cyan saves your preferences and options throughout the dapp. You can reset them back to factory settings here.`}
          </Text>
        </Flex>
      </StyledCard>
      <StyledCard p="0.75rem">
        <Flex direction="column" gap="2rem">
          <Flex justifyContent="space-between">
            <Text color="secondary" size="md" weight="600">
              {`Hard Refresh`}
            </Text>
            <RemoveButton onClick={refreshDapp}>
              <Text color="white" size="sm" weight="600">{`Refresh Dapp`}</Text>
            </RemoveButton>
          </Flex>
          <Text weight="500" size="xs" color="secondary">
            {`Sometimes the Cyan dapp will need a restart. This button is similar to the ‘Restart’ button found on the Playstation! It will not disrupt on-chain activity.`}
          </Text>
        </Flex>
      </StyledCard>
    </Flex>
  );
};

const StyledCard = styled(Card)`
  :hover {
    border-color: ${({ theme }) => theme.colors.gray20} !important;
  }
`;
const RemoveButton = styled(Button)`
  padding: 9px 0;
  width: 134px;
  background: ${({ theme }) => theme.colors.gray0};
  border-color: ${({ theme }) => theme.colors.gray0};
  :hover {
    background: ${({ theme }) => theme.colors.gray0};
    border-color: ${({ theme }) => theme.colors.gray0};
    opacity: 0.8;
  }
`;
