import styled from "styled-components";

import { Card, Flex, SkeletonLine, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { useGetUserTime } from "@/hooks/useGetUserTime";
import { getFormattedTimeForUserHistory } from "@/utils";
import { isMobile } from "@/utils/userAgent";

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: sticky;
  align-items: end;
  top: 135px;
  transform-style: preserve-3d;
  z-index: 6;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    overflow-anchor: none;
    position: absolute;
    transform: translateZ(-1px);
    height: calc(100% + 15px);
    top: -15px;
    @media only screen and (max-width: ${breakpoints.tablet}px) {
      left: -20px;
    }
    left: -100px;
    z-index: -1;
    width: 100vw;
  }
`;

const MainBox = styled(Card)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  margin: 1px;
  border: none;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-radius: 10px;
    box-shadow: none;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &:first-child {
      border-bottom: none;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
`;

export const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

export const HistoryDate = ({ date }: { date: Date }) => {
  const { getUserPreferredTime, showLocalTime } = useGetUserTime();
  const formattedDate = getUserPreferredTime(date);
  return (
    <Flex direction="column" gap="0.2rem">
      {isMobile ? (
        <RowText color="secondary" weight="500">{`${formattedDate.format("MMM")} ${formattedDate.format(
          "DD",
        )}, ${formattedDate.format("YYYY")}`}</RowText>
      ) : (
        <RowText color="secondary" weight="500">{`${formattedDate.format("MMMM")} ${formattedDate.format(
          "DD",
        )}, ${formattedDate.format("YYYY")}`}</RowText>
      )}

      <RowText color="gray0" sub>
        {getFormattedTimeForUserHistory(formattedDate, showLocalTime)}
      </RowText>
    </Flex>
  );
};

export const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

export const RowLoadingText = styled(SkeletonLine)<{ sub?: boolean }>`
  width: 50%;
  height: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("height", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])};
`;
