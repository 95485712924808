import { Flex } from "@cyanco/components/theme/Flex";
import { SwitchButton, SwitchButtonGroup, Text, Toggler } from "@cyanco/components/theme/v3";
import { Grid, List } from "@cyanco/components/theme/v3/icons";

import { Settings } from "@/components/Account/components/Selectors/Settings";

import { RewardsViewStyle, RewardsViewType, WalletTypes, useApeCoinContext } from "../../ApeCoinPageContext";
import { StickySelectorWrapper } from "../common";
import { GridView } from "./GridView";
import { ListView } from "./ListView";

export const RewardsView = () => {
  const {
    selectedRewardsView,
    setSelectedRewardsView,
    selectedRewardsViewType,
    setSelectedRewardsViewType,
    selectedStakedWalletType,
    selectedUnstakedWalletType,
    setSelectedStakedWalletType,
    setSelectedUnstakedWalletType,
  } = useApeCoinContext();
  const selectedWalletType =
    selectedRewardsViewType === RewardsViewType.staked ? selectedStakedWalletType : selectedUnstakedWalletType;
  const setSelectedWalletType =
    selectedRewardsViewType === RewardsViewType.staked ? setSelectedStakedWalletType : setSelectedUnstakedWalletType;

  return (
    <Flex direction="column">
      <StickySelectorWrapper alignItems="center" justifyContent="space-between" w="100%" pb="1rem" h="28px">
        <Flex alignItems="center">
          <SwitchButtonGroup<RewardsViewType>
            value={selectedRewardsViewType}
            onChange={setSelectedRewardsViewType}
            hover
          >
            <SwitchButton height="22px" value={RewardsViewType.staked}>{`Staked`}</SwitchButton>
            <SwitchButton height="22px" value={RewardsViewType.unstaked}>{`Unstaked`}</SwitchButton>
          </SwitchButtonGroup>
          {selectedRewardsView === RewardsViewStyle.grid && (
            <Settings>
              <>
                <Flex alignItems="center" gap="1rem" p="0.2rem 0.3rem" justifyContent="space-between">
                  <Text size="sm" color="secondary" textWrap={false}>
                    {`Show Main Wallet NFTs`}
                  </Text>
                  <Toggler
                    value={
                      selectedWalletType === WalletTypes.allWallets || selectedWalletType === WalletTypes.mainWallet
                    }
                    onChange={() => {
                      if (selectedWalletType === WalletTypes.allWallets) {
                        setSelectedWalletType(WalletTypes.cyanWallet);
                      } else if (selectedWalletType === WalletTypes.cyanWallet) {
                        setSelectedWalletType(WalletTypes.allWallets);
                      } else if (selectedWalletType === WalletTypes.mainWallet) {
                        setSelectedWalletType(WalletTypes.cyanWallet);
                      }
                    }}
                    size="sm"
                  />
                </Flex>{" "}
                <Flex alignItems="center" gap="1rem" p="0.2rem 0.3rem" justifyContent="space-between">
                  <Text size="sm" color="secondary" textWrap={false}>
                    {`Show Cyan Wallet NFTs`}
                  </Text>
                  <Toggler
                    value={
                      selectedWalletType === WalletTypes.allWallets || selectedWalletType === WalletTypes.cyanWallet
                    }
                    onChange={() => {
                      if (selectedWalletType === WalletTypes.allWallets) {
                        setSelectedWalletType(WalletTypes.mainWallet);
                      } else if (selectedWalletType === WalletTypes.cyanWallet) {
                        setSelectedWalletType(WalletTypes.mainWallet);
                      } else if (selectedWalletType === WalletTypes.mainWallet) {
                        setSelectedWalletType(WalletTypes.cyanWallet);
                      }
                    }}
                    size="sm"
                  />
                </Flex>
              </>
            </Settings>
          )}
        </Flex>
        <Flex gap="1rem" alignItems="center">
          <SwitchButtonGroup<RewardsViewStyle> value={selectedRewardsView} onChange={setSelectedRewardsView} hover>
            <SwitchButton value={RewardsViewStyle.list} height="22px">
              <List height={10} width={14} />
            </SwitchButton>
            <SwitchButton value={RewardsViewStyle.grid} height="22px">
              <Grid height={10} width={10} />
            </SwitchButton>
          </SwitchButtonGroup>
        </Flex>
      </StickySelectorWrapper>
      {selectedRewardsView === RewardsViewStyle.grid && <GridView />}
      {selectedRewardsView === RewardsViewStyle.list && <ListView />}
    </Flex>
  );
};
