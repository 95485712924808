import { useEffect, useState } from "react";

import { Flex } from "@cyanco/components/theme";

import { ICollectionBe } from "@/apis/collection/types";
import { useWeb3React } from "@/components/Web3ReactProvider";

import { LendCollectionView, useLendSelectors } from "../LendPageContext";
import { ListedNfts } from "./Assets/ListedNfts";
import { LoanOffers } from "./LoanOffers/ActiveLoanOffers";
import { Selectors } from "./Selectors";

export const LendCollectionSection: React.FC<{ collection: ICollectionBe }> = ({ collection }) => {
  const { provider } = useWeb3React();
  const [blockchainTimestamp, setBlockchainTimestamp] = useState(0);
  const { selectedLendCollectionView } = useLendSelectors();
  useEffect(() => {
    const setTime = async () => {
      if (!provider) return;
      const lastBlockNum = await provider.getBlockNumber();
      const lastBlock = await provider.getBlock(lastBlockNum);
      const lastTimestamp = lastBlock.timestamp * 1000;
      setBlockchainTimestamp(lastTimestamp);
    };
    setTime();
  }, [provider]);
  return (
    <Flex direction="column">
      <Selectors collection={collection} />
      {selectedLendCollectionView === LendCollectionView.assets && <ListedNfts collection={collection} />}
      {selectedLendCollectionView === LendCollectionView.loanOffers && (
        <LoanOffers collection={collection} blockchainTimestamp={blockchainTimestamp} />
      )}
    </Flex>
  );
};
