import { DialogContent, DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { useWeb3React } from "@web3-react/core";
import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { animated, useTransition } from "react-spring";
import styled, { useTheme } from "styled-components";

import { Flex } from "../../Flex";
import { ArrowBack, CloseX } from "../../components";
import { Text } from "./Text";

interface ModalProps {
  isOpen: boolean;
  unsetModal: () => void;
  title?: React.ReactNode | string;
  initialFocusRef?: React.RefObject<any>; // eslint-disable-line
  children: React.ReactNode;
  backAction?: () => void;
  canBack?: boolean;
  hideHeader?: boolean;
}

type IModalProps = {
  title?: ReactNode | string;
  content: JSX.Element;
  onClose?: () => void;
  onBack?: () => void;
  hideHeader?: boolean;
};

type IBackModalProps = {
  childContent?: JSX.Element | null;
};

export const Modal = ({
  children,
  unsetModal,
  isOpen,
  title,
  canBack,
  hideHeader,
  backAction,
  ...props
}: ModalProps) => {
  const theme = useTheme();
  const transitions = useTransition(isOpen, {
    from: { opacity: 0, y: 0, backgroundColor: "transparent" },
    enter: { opacity: 1, y: 0, backgroundColor: "rgba(0, 0, 0, 0.8)" },
    leave: { opacity: 0, y: 0, backgroundColor: "transparent" },
    config: {
      duration: 200,
    },
  });

  return children
    ? createPortal(
        transitions((_styles, item) => (
          <StyledDialogOverlay isOpen={item} onDismiss={unsetModal} initialFocusRef={props.initialFocusRef}>
            <animated.div
              style={{
                ..._styles,
                position: "absolute",
                width: "100%",
                overflowY: "scroll",
                height: "100vh",
              }}
            >
              <StyledDialogContent
                style={{
                  marginTop: "100px",
                  marginBottom: "100px",
                  backgroundColor: theme.colors.primary,
                  borderRadius: "14px",
                  borderStyle: "solid",
                  borderWidth: theme.borderWidth,
                  borderColor: theme.colors.gray20,
                }}
                aria-label="dialog content"
              >
                {!props.initialFocusRef ? <div tabIndex={1} /> : null}
                {(title || canBack) && !hideHeader && (
                  <Flex alignItems="center" style={{ paddingBottom: "1.5rem", position: "relative" }}>
                    {canBack && <ArrowBack onClick={() => backAction && backAction()} />}
                    {title &&
                      (React.isValidElement(title) ? (
                        title
                      ) : (
                        <Text weight="700" size="md" color="secondary">
                          {title}
                        </Text>
                      ))}

                    <CloseX onClick={unsetModal} />
                  </Flex>
                )}
                {children}
              </StyledDialogContent>
            </animated.div>
          </StyledDialogOverlay>
        )),
        document.getElementById("modal-root") as HTMLElement,
      )
    : null;
};

interface ModalState {
  setModalContent: ({ title, content, onClose, hideHeader }: IModalProps) => void;
  unsetModal: () => void;
  isOpen: boolean;
  setModalChildContent: ({ childContent }: IBackModalProps) => void;
  onBackModal: () => void;
  hideHeader: boolean;
}

const ModalContext = createContext<ModalState>({
  setModalContent: () => {},
  unsetModal: () => {},
  isOpen: false,
  setModalChildContent: () => {},
  onBackModal: () => {},
  hideHeader: false,
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const [hideHeader, setHideHeader] = useState(false);
  const [mainContent, setMainContent] = useState<ReactNode | null>();
  const [childContent, setChildContent] = useState<ReactNode | null>();
  const [modalTitle, setTitle] = useState<ReactNode | string>();
  const [onClose, setOnClose] = useState<() => void>();
  const [onBack, setOnBack] = useState<() => void>();
  const { account, chainId } = useWeb3React();

  const unsetModal = useCallback(() => {
    setShowTitle(true);
    setIsOpen(false);
    setTimeout(() => {
      setMainContent(null);
      setChildContent(null);
      setTitle(undefined);
      setHideHeader(false);
    }, 200);

    onClose && onClose();
  }, [setMainContent, onClose]);

  const setModalContent = ({ title, content, onClose, onBack, hideHeader: hide }: IModalProps) => {
    setShowTitle(true);
    setTitle(title);
    setMainContent(content);
    setIsOpen(true);
    setOnClose(() => onClose);
    setOnBack(() => onBack);
    setHideHeader(!!hide);
  };

  const setModalChildContent = ({ childContent }: IBackModalProps) => {
    setChildContent(childContent);
    setShowTitle(false);
  };

  const onBackModal = useCallback(() => {
    setShowTitle(true);
    setChildContent(null);
    onBack && onBack();
  }, [setChildContent, onBack]);

  useEffect(() => {
    unsetModal();
  }, [chainId, account]);
  return (
    <ModalContext.Provider
      value={{ unsetModal, setModalContent, isOpen, setModalChildContent, onBackModal, hideHeader }}
    >
      <Modal
        unsetModal={unsetModal}
        title={showTitle && modalTitle}
        isOpen={isOpen}
        canBack={childContent !== null && childContent !== undefined}
        backAction={onBack ? onBack : () => {}}
        hideHeader={hideHeader}
      >
        {childContent ?? mainContent}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
const AnimatedDialogOverlay = animated(DialogOverlay);

const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
  &[data-reach-dialog-overlay] {
    background-color: transparent;
    z-index: 81;
    overflow: hidden auto;
    display: flex;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 414px) {
    padding: 0;
  }
`;

const AnimatedDialogContent = animated(DialogContent);

const StyledDialogContent = styled(({ ...rest }) => <AnimatedDialogContent {...rest} />).attrs({
  "aria-label": "dialog",
})`
  overflow-y: auto;
  top: 100px;
  left: 50%;
  ::-webkit-scrollbar {
    display: none;
  }
  &[data-reach-dialog-content] {
    margin: auto;
    padding: 1rem;
    box-sizing: border-box;
    width: 50vw;
    overflow-y: auto;
    overflow-x: hidden;
    align-self: center;
    max-width: 420px;
    min-width: 325px;
    border-radius: 30px;
    @media only screen and (max-width: 525px) {
      min-width: 95%;
      margin-top: auto;
      margin-bottom: auto;
      border: 1px solid grey;
    }
  }
`;
