import { useMemo } from "react";

export interface PaginatonTypes {
  total: number;
  pageSize: number;
  current: number;
  siblingCount?: number;
}

function range(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, idx) => idx + start);
}

export const DOTS = "...";

const usePagination = ({
  total,
  pageSize,
  current,
  siblingCount = 1,
}: PaginatonTypes): (string | number)[] | undefined => {
  const pagination = useMemo(() => {
    const totalPage = Math.ceil(total / pageSize);

    const pageNumbers = siblingCount + 5;

    if (pageNumbers >= totalPage) return range(1, totalPage);

    const leftSiblingIndex = Math.max(current - siblingCount, 1);
    const rightSiblingIndex = Math.min(current + siblingCount, totalPage);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPage - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPage;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPage];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPage - rightItemCount + 1, totalPage);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [total, pageSize, siblingCount, current]);

  return pagination;
};

export default usePagination;
