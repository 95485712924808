import { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Button, Text } from "@cyanco/components/theme/v3";
import { CloseX } from "@cyanco/components/theme/v3/icons";

import { NetworkStatus } from ".";

export const NetworkInfo = ({
  setShowWrongNetwork,
  networkStatus,
}: {
  setShowWrongNetwork: (a: boolean) => void;
  networkStatus: NetworkStatus;
}) => {
  const theme = useTheme();

  return (
    <Flex
      style={{
        backgroundColor: networkStatus === NetworkStatus.NOT_SUPPORTED ? theme.colors.red : theme.colors.yellow,
      }}
      justifyContent="center"
      alignItems="center"
      p="0.5rem"
      gap="2rem"
    >
      <Text size="md" weight="500" color="black">
        {networkStatus === NetworkStatus.NOT_SUPPORTED &&
          `Please connect to the correct network (Sepolia, Mainnet) to use Cyan.`}
        {[NetworkStatus.SWITCH_SEPOLIA, NetworkStatus.SWITCH_BLAST_SEPOLIA, NetworkStatus.SWITCH_CURTIS].includes(
          networkStatus,
        ) ? (
          <>
            {`Your wallet is set to a test network(Blast Sepolia, Sepolia). To use Cyan, please switch to`}{" "}
            <a
              href="https://testnet.usecyan.com"
              style={{
                color: theme.colors.black,
              }}
            >
              testnet.usecyan.com
            </a>
          </>
        ) : (
          [NetworkStatus.SWITCH_MAINNET, NetworkStatus.SWITCH_BLAST].includes(networkStatus) && (
            <>
              {`Your wallet is set to the mainnet(Etheruim, Blast). To use Cyan, please switch to `}{" "}
              <a
                href="https://usecyan.com"
                style={{
                  color: theme.colors.black,
                }}
              >
                usecyan.com
              </a>
            </>
          )
        )}
      </Text>
      <div>
        <Button variant="ghost" onClick={() => setShowWrongNetwork(false)}>
          <CloseX color="black" height={11} width={11} />
        </Button>
      </div>
    </Flex>
  );
};
