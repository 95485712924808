import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Text } from "@cyanco/components/theme/v3";
import { ArrowRight } from "@cyanco/components/theme/v3/icons";

import { bigNumToFloat, numberWithCommas } from "@/utils";

import { ISelectedNft } from "../../../types";
import { ApeCoinStakingInput } from "../../common";
import { ReleaseWalletSelector } from "./ReleaseWalletSelector";

export const ApeCoinUnstakingWithoutPlan = ({
  selectedMainNft,
  setReleaseWallet,
  releaseWallet,
  unstakingAmount,
  setUnstakingAmount,
}: {
  selectedMainNft: ISelectedNft | null;
  releaseWallet: string;
  setReleaseWallet: (a: string) => void;
  unstakingAmount?: string;
  setUnstakingAmount: (a: string) => void;
}) => {
  const theme = useTheme();
  const nft = selectedMainNft;
  const stakedAmount = useMemo(() => {
    if (!nft) return 0;
    if (!nft.apeStaking.stakedAmount) return 0;
    return bigNumToFloat(nft.apeStaking.stakedAmount);
  }, [nft]);
  const pendingRewards = useMemo(() => {
    if (!nft) return 0;
    if (!nft.apeStaking.earnedAmount) return 0;
    return bigNumToFloat(nft.apeStaking.earnedAmount);
  }, [nft]);
  return (
    <Flex direction="column" gap="1.2rem">
      <Flex direction="column" gap="0.3rem">
        <Flex justifyContent="space-between" p="0 2px">
          <Text color="gray0" size="xs" weight="600">
            {`Select ApeCoin`}
          </Text>
          <Text color="gray0" size="xs" weight="500">
            {`${`Max`}: ${numberWithCommas(stakedAmount)} APE`}
          </Text>
        </Flex>
        <ApeCoinStakingInput onInputChange={setUnstakingAmount} stakingAmount={unstakingAmount} max={stakedAmount} />
      </Flex>
      <Flex direction="column" gap="0.8rem">
        <StakedBox>
          <Flex direction="column" w="100%" gap="0.3rem">
            <Flex justifyContent="space-between" w="100%">
              <Text color="secondary" size="xs" weight="600">
                {`Total Staked`}
              </Text>
              <Flex alignItems="center" gap="4px">
                <Text color="gray0" size="xs" weight="600">
                  {numberWithCommas(stakedAmount, 2)} APE
                </Text>
                <ArrowRight color={theme.colors.gray0} width={9} height={9} />
                <Text color="secondary" size="xs" weight="600">
                  {numberWithCommas(stakedAmount, 2)}
                  APE
                </Text>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" w="100%">
              <Text color="secondary" size="xs" weight="600">
                {`Rewards`}
              </Text>
              <Text color="secondary" size="xs" weight="600">
                {numberWithCommas(pendingRewards, 2)} APE
              </Text>
            </Flex>
            <Flex justifyContent="space-between" w="100%">
              <Text color="secondary" size="xs" weight="600">
                {`Rewards APY`}
              </Text>
              <Text color="secondary" size="xs" weight="600">
                -
              </Text>
            </Flex>
          </Flex>
        </StakedBox>
        <ReleaseWalletSelector setReleaseWallet={setReleaseWallet} releaseWallet={releaseWallet} />
      </Flex>
    </Flex>
  );
};

const StakedBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 1rem 0.8rem;
`;
