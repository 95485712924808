import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";

import { Text } from "@cyanco/components/theme/v3";

import { CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS } from "@/constants/chains";
import { switchToNetwork } from "@/utils/switchToNetwork";

import { FlyoutRow } from "./NetworkSelector";

export const NetworkMenuItem = ({
  isActive,
  targetChain,
  onClick,
}: {
  isActive: boolean;
  targetChain: typeof CYAN_SUPPORTED_CHAIN_IDS[number];
  onClick: () => void;
}) => {
  const { connector } = useWeb3React();

  const handleRowClick = () => {
    switchToNetwork({ connector, chainId: targetChain });
    onClick();
  };
  if (isActive) {
    return (
      <ActiveRowWrapper>
        <FlyoutRow>
          <Logo src={CHAIN_INFO[targetChain].logoUrl} alt="chainlogo" />
          <NetworkLabel>
            <Text weight="400" size="md" color="secondary">
              {CHAIN_INFO[targetChain].label}
            </Text>
          </NetworkLabel>
          <FlyoutRowActiveIndicator />
        </FlyoutRow>
      </ActiveRowWrapper>
    );
  }

  return (
    <FlyoutRow onClick={handleRowClick}>
      <Logo src={CHAIN_INFO[targetChain].logoUrl} alt="chainlogo" />
      <NetworkLabel>
        <Text weight="400" size="md" color="secondary">
          {CHAIN_INFO[targetChain].label}
        </Text>
      </NetworkLabel>
    </FlyoutRow>
  );
};

export const Logo = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;

const NetworkLabel = styled.div`
  flex: 1 1 auto;
`;

const ActiveRowWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
    font-weight: 400;
    border-radius: 5px;
  }
`;

const FlyoutRowActiveIndicator = styled.div`
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: 50%;
  height: 9px;
  width: 9px;
`;
