import { BigNumber } from "ethers";
import { useState } from "react";
import { NumericFormat, OnValueChange } from "react-number-format";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Input, Loader, Text, useModal } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { currencyList } from "@/apis/coinbase";

import ApeCoin from "../../assets/images/apecoin.svg";
import {
  bigNumToFixedStr,
  bigNumToFloat,
  displayInUSD,
  numberWithCommas,
  shortenAddress,
  shortenName,
} from "../../utils";
import { useAppContext } from "../AppContextProvider";

export const ApeCoinStakingInput = ({
  isStaked,
  stakingAmount,
  balance,
  onInputChange,
  account,
  apeUsd,
  error,
}: {
  isStaked: boolean;
  stakingAmount?: string;
  balance: {
    mainWallet: BigNumber | null;
    cyanWallet: BigNumber | null;
  };
  onInputChange: (a: string) => void;
  apeUsd: number;
  account: {
    mainWallet: string;
  };
  error?: string | null;
}) => {
  const onStakingAmountChange: OnValueChange = values => {
    onInputChange(values.value);
  };
  return (
    <Flex direction="column" gap="0.5rem">
      {!isStaked && (
        <Flex direction="column" gap="0.3rem">
          <Text size="xs" weight="500" color="gray0" textAlign="right">
            {shortenAddress(account.mainWallet)}:{" "}
            {balance.mainWallet !== null &&
              bigNumToFloat(balance.mainWallet).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Text>

          <Text size="xs" weight="500" color="gray0" textAlign="right">
            Cyan Wallet:{" "}
            {balance.cyanWallet !== null
              ? bigNumToFloat(balance.cyanWallet).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0"}
          </Text>
        </Flex>
      )}
      <Flex gap="2.5rem">
        <Flex gap="0.7rem" alignItems="center">
          <img
            src={ApeCoin}
            alt=""
            style={{
              width: "42px",
              height: "42px",
            }}
          />
          <Text size="xxl" color="secondary" weight="600">
            APE
          </Text>
        </Flex>

        <NumericFormat
          placeholder="0"
          thousandSeparator=","
          allowLeadingZeros={false}
          allowNegative={false}
          onValueChange={onStakingAmountChange}
          value={stakingAmount}
          disabled={isStaked}
          customInput={Input}
          decimalScale={2}
          valueIsNumericString
          textAlign="right"
          style={{
            fontSize: "32px",
          }}
          p="0.1rem 0.5rem 0.1rem 0"
        />
      </Flex>
      <Text size="xs" weight="500" color="gray0" textAlign="right">
        = {stakingAmount ? displayInUSD(apeUsd * parseFloat(stakingAmount)) : "$0"}
      </Text>{" "}
      {error && !isStaked && (
        <Flex mt="-1rem" justifyContent="flex-end">
          <ErrorText text={error} />
        </Flex>
      )}
    </Flex>
  );
};

export const ApeCoinUnstake = ({
  onClick,
  processing,
  earnedAmount,
  stakedAmount,
  disabled = false,
}: {
  onClick: () => Promise<any>;
  processing: boolean;
  earnedAmount: BigNumber | null;
  stakedAmount: BigNumber | null;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      style={{
        padding: "1rem 0",
      }}
    >
      <Flex gap="0.7rem" justifyContent="center" alignItems="center">
        {!processing && (
          <UnstakingText size="sm" weight="700" color="black">
            {`Unstake`} {stakedAmount && earnedAmount && bigNumToFixedStr(earnedAmount.add(stakedAmount), 2)} APE
          </UnstakingText>
        )}
        {processing && <UnstakingLoader size="17px" stroke={theme.colors.black} />}
      </Flex>
    </Button>
  );
};

export const ApeCoinClaim = ({
  onClick,
  processing,
  earnedAmount,
  disabled = false,
}: {
  onClick: () => Promise<any>;
  processing: boolean;
  earnedAmount: BigNumber | null;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      disabled={earnedAmount === null || earnedAmount.isZero() || disabled}
      style={{
        padding: "1rem 0",
      }}
    >
      <Flex gap="0.7rem" justifyContent="center" alignItems="center">
        {!processing && (
          <Text size="sm" weight="700" color="black">
            {`Claim`} {earnedAmount && bigNumToFixedStr(earnedAmount, 2)} APE
          </Text>
        )}
        {processing && <Loader size="17px" stroke={theme.colors.black} />}
      </Flex>
    </Button>
  );
};

export const ApeCoinWithdraw = () => {
  return (
    <Button
      disabled
      style={{
        padding: "1rem 0",
      }}
    >
      Claim or Unstake
    </Button>
  );
};

export const ApeCoinStaking = ({
  onClick,
  processing,
  disabled,
}: {
  onClick: () => void;
  processing?: boolean;
  disabled: boolean;
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  return (
    <ApeCoinStakingButton onClick={onClick} disabled={disabled}>
      <Flex gap="0.7rem" justifyContent="center" alignItems="center">
        {!processing && (
          <>
            {imageLoading && <Loader size="24px" />}
            <img
              src={ApeCoin}
              alt=""
              height="24px"
              style={{
                borderRadius: "50%",
                border: "1px solid white",
                visibility: imageLoading ? "hidden" : "visible",
                width: imageLoading ? "0" : "24px",
              }}
              onLoad={() => setImageLoading(false)}
            />
            <Text size="sm" weight="700" color="white">
              Stake APE
            </Text>
          </>
        )}
        {processing && <Loader size="25px" stroke="white" />}
      </Flex>
    </ApeCoinStakingButton>
  );
};

export const ApeCoinBalance = ({
  earnedAmount,
  stakedAmount,
}: {
  stakedAmount: null | BigNumber;
  earnedAmount: BigNumber | null;
}) => {
  const theme = useTheme();
  const { usdPrice } = useAppContext();
  return (
    <Flex direction="column" w="100%" justifyContent="space-around" gap="0.3rem" mt="1rem">
      <Flex justifyContent="space-between" w="100%" alignItems="center">
        <Text size="xs" weight="500" color="secondary">
          {`Currently staked:`}
        </Text>
        <Flex alignItems="center" gap="2px">
          {stakedAmount !== null ? (
            <Text size="xs" weight="500" color="secondary">
              {`${bigNumToFixedStr(stakedAmount, 3)} APE (${displayInUSD(
                bigNumToFloat(stakedAmount) * usdPrice[currencyList.ape],
              )})`}{" "}
            </Text>
          ) : (
            <Loader size="12px" stroke={theme.colors.gray0} />
          )}
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" w="100%">
        <Text size="xs" weight="500" color="secondary">
          Earned amount
        </Text>
        <Flex alignItems="center" gap="2px">
          {earnedAmount !== null ? (
            <Text size="xs" weight="500" color="secondary">
              {`${bigNumToFixedStr(earnedAmount, 3)} APE (${displayInUSD(
                bigNumToFloat(earnedAmount) * usdPrice[currencyList.ape],
              )})`}{" "}
            </Text>
          ) : (
            <Loader size="12px" stroke={theme.colors.gray0} />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const ApeCoinBalanceLoading = ({
  stakingAmount,
  imageUrl,
  tokenId,
  collectionName,
  isLoading,
}: {
  stakingAmount: string;
  imageUrl?: string | null;
  tokenId: string;
  collectionName: string;
  isLoading: boolean;
}) => {
  return (
    <Flex gap="1rem">
      <div style={{ position: "relative" }}>
        <StyledImage src={imageUrl || NoImage} alt="nft" hasImage={!!imageUrl} />
        {isLoading && (
          <ImageLoader>
            <Loader stroke="white" size="50px" />
          </ImageLoader>
        )}
      </div>
      <Flex direction="column" w="100%" pt="0.2rem" gap="0.3rem">
        <Flex justifyContent="space-between" w="100%">
          <Text size="xs" weight="700" color="secondary">
            {shortenName(collectionName, 25, 6, 4)}
          </Text>
          <Text size="xs" weight="700" color="gray80">
            #{shortenName(tokenId, 20, 6, 4)}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" w="100%">
          <Text size="xs" weight="500" color="secondary">
            {`Staking amount:`}
          </Text>

          <Flex alignItems="center" gap="2px">
            <Text size="xs" weight="500" color="gray80">
              {numberWithCommas(stakingAmount)}
            </Text>
            <Text size="xs" weight="500" color="gray80">
              APE
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
export const SuccessText = ({ text }: { text: string }) => {
  return (
    <Flex justifyContent="center" p="1.5rem">
      <Text weight="700" size="lg" textAlign="center" color="secondary">
        {text} 🎉
      </Text>
    </Flex>
  );
};
export const ErrorText = ({ text }: { text: string }) => {
  return (
    <Text size="xxs" color="red" style={{ textAlign: "right", marginTop: "10px" }}>
      {text}
    </Text>
  );
};

const UnstakingLoader = styled(Loader)`
  transition: 0.2s;
  stroke: ${({ theme }) => theme.colors.primary};
`;

const UnstakingText = styled(Text)`
  transition: 0.2s;
`;

export const ApeCoinStakingButton = styled(Button)`
  background: #024ad7;
  border-radius: 10px;
  border: 1px solid #024ad7;
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  :hover {
    background: #024ad7;
    border: 1px solid #024ad7;
  }
`;

const StyledImage = styled.img<{ hasImage?: boolean; hasError?: boolean }>`
height: 60px;
width: 60px;
object-fit: scale-down;
border: ${({ hasError }) => `${hasError ? "3px solid #f00" : "none"};`}
border-radius: 15px;
padding: 0;
margin: 0;
background-color: black;
filter: ${({ hasImage, theme }) =>
  !hasImage &&
  theme.theme === "light" &&
  "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;
const ImageLoader = styled.div`
  height: 60px;
  width: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: none;
`;

export const CloseButton = () => {
  const { unsetModal } = useModal();
  return (
    <Button onClick={unsetModal} style={{ padding: "1rem 0" }}>
      <Text color="black" size="sm" weight="700">
        {`Close`}
      </Text>
    </Button>
  );
};
