import { UAuthConnector } from "@uauth/web3-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  BarChart2,
  BookOpen,
  DollarSign,
  FileText,
  LogOut,
  Mail,
  Moon,
  Settings,
  Shield,
  Sunrise,
  User,
  Youtube,
} from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Loader, Text, useModal } from "@cyanco/components/theme/v3";
import { Discord, Github, Medium, Twitter } from "@cyanco/components/theme/v3/icons";

import { useWeb3React } from "@/components/Web3ReactProvider";
import {
  CYAN_GITHUB_LINK,
  CYAN_MEDIUM_LINK,
  CYAN_TWITTER_LINK,
  CYAN_YOUTUBE_LINK,
  DISCORD_SERVER_INVITE_LINK,
} from "@/config";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { useToggleModal } from "@/state/application/hooks";
import { ApplicationModal } from "@/state/application/reducer";
import { jumpToLink, shortenName } from "@/utils";

import AccountLogo from "../../assets/images/account-logo.svg";
import AffiliateModal from "../AffiliateModal";
import { AuthContext } from "../AuthContext/AuthContextProvider";
import SecurityModal from "../Security";
import { SettingsModal } from "../Settings";
import SetupAlertsModal from "../SetupAlerts";
import { ThemeContext } from "../ThemeContextProvider";
import { useTransactionContext } from "../TransactionContextProvider";
import { WalletModal } from "../Wallet/WalletModal";
import { useWalletContext } from "../Wallet/WalletProvider";

export const AccountMenu = () => {
  const location = useLocation();
  const node = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { transactions } = useTransactionContext();
  const { setModalContent, unsetModal, setModalChildContent, onBackModal } = useModal();
  const { user, fetchUserBe } = useContext(AuthContext);
  const { isLightTheme, setIsLightTheme } = useContext(ThemeContext);
  const { account, connector, ENSName } = useWeb3React();
  const navigate = useNavigate();
  const toggleWallet = useToggleModal(ApplicationModal.WALLET);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const [addressPlaceHolder, setAddressPlaceHolder] = useState<string>("");
  const [isAffiliateModalOpen, setIsAffiliateModalOpen] = useState<boolean>(false);
  const { setShowAccountMenu, showAccountMenu, setFocusOnCyanWalletByDefault } = useWalletContext();
  useOnClickOutside(node, () => {
    if (showAccountMenu) {
      setShowAccountMenu(false);
      setFocusOnCyanWalletByDefault(false);
    }
  });

  useEffect(() => {
    if (!account || !isEmailModalOpen) return;

    setModalContent({
      title: `Setup Alerts`,
      content: (
        <SetupAlertsModal
          onClose={unsetModal}
          onNext={nextNode => {
            setModalChildContent({ childContent: nextNode });
          }}
        />
      ),
      onClose: () => setIsEmailModalOpen(false),
      onBack: onBackModal,
    });
  }, [account, user, isEmailModalOpen]);

  useEffect(() => {
    const checkAndGetAddressPlaceholder = async () => {
      if (account) {
        if (connector instanceof UAuthConnector) {
          const user = await connector.uauth.user();
          setAddressPlaceHolder(user.sub);
          return;
        }
        ENSName ? setAddressPlaceHolder(ENSName) : setAddressPlaceHolder(account);
      }
    };
    checkAndGetAddressPlaceholder();
  }, [connector, account, ENSName]);

  const onClickConfigureEmail = useCallback(async () => {
    if (!account) return toggleWallet();
    try {
      await fetchUserBe();
      setIsEmailModalOpen(true);
    } catch (e) {
      console.log(e);
    }
  }, [fetchUserBe, account, setIsEmailModalOpen]);

  useEffect(() => {
    if (!account || !isAffiliateModalOpen) return;

    setModalContent({
      title: `Affiliate Program`,
      content: <AffiliateModal onClose={unsetModal} />,
      onClose: () => setIsAffiliateModalOpen(false),
    });
  }, [account, user, isAffiliateModalOpen]);

  const onClickAffiliateProgram = useCallback(async () => {
    if (!account) return toggleWallet();
    try {
      const { token } = await fetchUserBe();
      if (token !== "") setIsAffiliateModalOpen(true);
    } catch (e) {
      console.log(e);
    }
  }, [fetchUserBe, account, setIsAffiliateModalOpen]);

  const disconnectWallet = async () => {
    if (connector?.deactivate) {
      await connector.deactivate();
    } else {
      await connector.resetState();
    }
    unsetModal();
  };

  const jumpToLinkWithClose = (link: string) => {
    jumpToLink(link);
    setShowAccountMenu(false);
  };

  const onClickSecurity = () => {
    setModalContent({
      title: `Security`,
      content: <SecurityModal />,
      onBack: onBackModal,
    });
  };

  const onClickSettings = () => {
    setModalContent({
      title: `Settings`,
      content: <SettingsModal />,
      onBack: onBackModal,
    });
  };

  return (
    <Container ref={node}>
      {transactions.length > 0 ? (
        <PendingCountContainer
          onClick={() => setShowAccountMenu(!showAccountMenu)}
          title="menu"
          showAccountMenu={showAccountMenu}
          isActive={false}
        >
          <Text id="Pending" size="md" weight="600" color="black">
            {transactions.length} Pending
          </Text>
          <Loader size="13px" stroke="black" />
        </PendingCountContainer>
      ) : (
        <Web3StatusConnected
          onClick={() => setShowAccountMenu(!showAccountMenu)}
          title="menu"
          showAccountMenu={showAccountMenu}
          isActive={location.pathname.startsWith("/account")}
        >
          <img src={AccountLogo} height={18} width={18} />
          <AddressText isActive={showAccountMenu} size="md" weight="500" color="gray0" lineHeight={19}>
            {shortenName(addressPlaceHolder, 15, 7, 4)}
          </AddressText>
        </Web3StatusConnected>
      )}
      {showAccountMenu && (
        <MenuFlyout>
          <WalletModal hideDisconnect />
          <div>
            <Flex direction="column" m="0 -0.5rem">
              <MenuItem
                onClick={() => {
                  navigate("/account");
                  setShowAccountMenu(false);
                }}
              >
                {`Account`}
                <User size={16} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onClickAffiliateProgram();
                  setShowAccountMenu(false);
                }}
              >
                {`Affiliates`}
                <DollarSign size={16} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/points");
                  setShowAccountMenu(false);
                }}
              >
                {`Cyan Points`}
                <BarChart2 size={16} />
              </MenuItem>
              <MenuItem onClick={() => jumpToLinkWithClose("https://docs.usecyan.com/docs")}>
                {`Docs`}
                <BookOpen size={16} data-testid="book-icon" />
              </MenuItem>
              <MenuItem onClick={() => jumpToLinkWithClose("https://docs.usecyan.com/docs/terms-of-service")}>
                {`Legal & Privacy`}
                <FileText size={16} data-testid="file-icon" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsLightTheme(!isLightTheme);
                  setShowAccountMenu(false);
                }}
              >
                {isLightTheme ? `Dark Theme` : `Light Theme`}
                {isLightTheme ? <Moon size={16} /> : <Sunrise size={16} />}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onClickSecurity();
                  setShowAccountMenu(false);
                }}
              >
                {`Security`}
                <Shield size={16} data-testid="shield-icon" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onClickSettings();
                  setShowAccountMenu(false);
                }}
              >
                {`Settings`}
                <Settings size={16} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onClickConfigureEmail();
                  setShowAccountMenu(false);
                }}
              >
                {`Setup Alerts`}
                <Mail size={16} data-testid="mail-icon" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  disconnectWallet();
                  setShowAccountMenu(false);
                }}
              >
                {`Sign Out`}
                <LogOut size={16} />
              </MenuItem>
            </Flex>
          </div>
          <Flex justifyContent="space-between" alignItems="center" mb="-0.5rem" w="100%">
            <Flex gap="1rem">
              <IconButton variant="ghost" onClick={() => jumpToLinkWithClose(DISCORD_SERVER_INVITE_LINK)}>
                <Discord color={theme.colors.secondary} width={15} height={15} />
              </IconButton>
              <IconButton variant="ghost" onClick={() => jumpToLinkWithClose(CYAN_TWITTER_LINK)}>
                <Twitter color={theme.colors.secondary} width={15} height={15} />
              </IconButton>
              <IconButton variant="ghost" onClick={() => jumpToLinkWithClose(CYAN_GITHUB_LINK)}>
                <Github color={theme.colors.secondary} width={15} height={15} />
              </IconButton>
              <IconButton variant="ghost" onClick={() => jumpToLinkWithClose(CYAN_YOUTUBE_LINK)}>
                <Youtube color={theme.colors.secondary} width={15} height={15} />
              </IconButton>
              <IconButton variant="ghost" onClick={() => jumpToLinkWithClose(CYAN_MEDIUM_LINK)}>
                <Medium color={theme.colors.secondary} width={17} height={17} />
              </IconButton>
            </Flex>
            <ExtensionButton
              variant="ghost"
              onClick={() =>
                jumpToLinkWithClose(
                  "https://chrome.google.com/webstore/detail/cyan-extension/knpcgeeofdmafehffknjfedljlacnbdm?utm_source=chrome-ntp-icon",
                )
              }
            >
              <Text color="secondary" size="xxs">
                {`Download extension`}
              </Text>
            </ExtensionButton>
          </Flex>
        </MenuFlyout>
      )}
    </Container>
  );
};

export enum FlyoutAlignment {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

const Container = styled.div``;

const Web3StatusConnected = styled.button<{ showAccountMenu: boolean; isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.7rem;
  margin: 0;
  gap: 0.5rem;
  cursor: pointer;
  border: none;
  background: transparent;
  user-select: none;
  transition: 0.2s;
  height: 30px;
  border-radius: 100px;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray10};
  }
`;

const AddressText = styled(Text)<{ isActive: boolean }>`
  transition: 0.2s;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.secondary : theme.colors.gray0)};
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const MenuFlyout = styled.span<{ flyoutAlignment?: FlyoutAlignment }>`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.primary};
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.gray30};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 2.9rem;
  z-index: 100;
  gap: 1.8rem;
  max-width: calc(372px - 2px - 2rem);
  min-width: calc(372px - 2px - 2rem);
  width: calc(372px - 2px - 2rem);

  ${({ flyoutAlignment = FlyoutAlignment.RIGHT }) =>
    flyoutAlignment === FlyoutAlignment.RIGHT
      ? css`
          right: 0.4rem;
        `
      : css`
          left: 0rem;
        `};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    top: 2.5rem;
    min-width: 280px;
    width: 280px;
  }
`;

const MenuItem = styled.a`
  display: flex;
  flex: 1;
  font-size: 16px;
  flex-direction: row;
  align-items: center;
  padding: 0.3rem 0.5rem;
  justify-content: space-between;
  font-weight: 400;
  font-family: Inter;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  transition: 0.2s;
  cursor: pointer;
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    :hover {
      background-color: ${({ theme }) => (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
      font-weight: 400;
      border-radius: 5px;
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    :hover {
      background-color: transparent;
    }
  }
`;

const IconButton = styled(Button)`
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

const ExtensionButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 20px;
  padding: 0.15rem 0.6rem;
  width: fit-content;
`;

const PendingCountContainer = styled.button<{ showAccountMenu: boolean; isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem 1.2rem;
  margin: 0;
  gap: 0.5rem;
  border-radius: 10px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.cyan};
  border: 1px solid
    ${({ theme, showAccountMenu, isActive }) =>
      isActive ? theme.colors.secondary : showAccountMenu ? theme.colors.gray30 : theme.colors.gray20};
  user-select: none;
  transition: 0.2s;
  height: 30px;
  :hover {
    border: 1px solid ${({ theme, isActive }) => (!isActive ? theme.colors.gray30 : theme.colors.secondary)};
  }
`;
