import { Flex } from "@cyanco/components/theme/Flex";

import { ApePlanStatuses } from "@/apis/ape-plans";

import { HistoricalPayments } from ".";
import { HistoryViewType, useApeCoinContext } from "../../ApeCoinPageContext";
import { HistoryViewSelectors } from "./Selectors";

export const HistoricalView = () => {
  const { selectedHistoryViewType } = useApeCoinContext();
  return (
    <Flex direction="column">
      <HistoryViewSelectors />
      {selectedHistoryViewType === HistoryViewType.all && <HistoricalPayments />}
      {selectedHistoryViewType === HistoryViewType.staked && <HistoricalPayments status={ApePlanStatuses.Started} />}
      {selectedHistoryViewType === HistoryViewType.unstaked && (
        <HistoricalPayments status={ApePlanStatuses.Completed} />
      )}
    </Flex>
  );
};
