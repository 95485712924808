import dayjs from "dayjs";
import { memo, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SkeletonLine, SortingArrows, Text } from "@cyanco/components/theme/v3";
import { NewTab } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IPeerPlan } from "@/apis/p2p/types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_IDS_TO_EXPLORER_NAME } from "@/constants/chains";
import { useSortableHeader } from "@/hooks/useSortableHeader";
import { ICurrency } from "@/types";

import { bigNumToFixedStr, bigNumToFloat, displayInUSD, getChainExplorerURL, shortenName } from "../../../../utils";
import { useAppContext } from "../../../AppContextProvider";
import { IPawn } from "../../pawn.types";
import { IPayment } from "../../payment.types";
import { HeaderItemWrapper, PaymentDate, RowText } from "./PositionRow";

export enum PaymentStatusString {
  paid = "paid",
  completed = "completed",
  missed = "missed",
  completedEarly = "completedEarly",
  extended = "extended",
}

export type IProps = {
  imageUrl: string;
  collectioName: string;
  createdAt: Date;
  tokenId: string;
  amount: string;
  currency: ICurrency;
  paymentNumber: number;
  status: keyof typeof PaymentStatusString;
  totalPaymentNumber: number;
  transactionHash?: string;
  positionId: number;
  planType: "BNPL" | "Pawn" | "P2P";
  isAutoLiquidationEnabled: boolean;
};

export const getPositionDefaultedDate = (position: IBNPL | IPawn) => {
  return position.defaultedAt
    ? position.defaultedAt
    : dayjs(position.createdAt)
        .add(position.term * (position.currentNumOfPayments + (position.planType === "Pawn" ? 1 : 0)), "seconds")
        .toDate();
};

export const PaymentRow = memo(({ payment, onClick }: { payment: IProps; onClick?: () => void }) => {
  const { usdPrice } = useAppContext();
  const { chainId } = useWeb3React();
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const txnUrl = `${getChainExplorerURL(chainId)}/tx`;
  const theme = useTheme();
  return (
    <Row onClick={onClick} style={{ cursor: "pointer" }}>
      <Flex alignItems="stretch" gap="20px">
        {imageLoading && !imageError && (
          <ImageLoader>
            <SkeletonLine w="100%" h="100%" borderRadius="10px" />
          </ImageLoader>
        )}
        {!imageError ? (
          <StyledImg
            src={payment.imageUrl}
            alt={payment.collectioName}
            onError={() => setImageError(true)}
            onLoad={() => setImageLoading(false)}
            style={{
              display: imageLoading ? "none" : "block",
            }}
          />
        ) : (
          <StyledImg src={NoImage} alt={"no-image"} style={{ objectFit: "contain" }} />
        )}
        <Flex direction="column" gap="2px">
          <Flex gap="5px">
            <RowText color={"secondary"} weight="500">
              #{shortenName(payment.tokenId, 10, 6)}
            </RowText>
            <Flex gap="4px">
              <Flex
                style={{
                  background: theme.colors.secondary,
                  borderRadius: "4px",
                }}
                p={"2px 4px"}
                h={"fit-content"}
              >
                <Text color="primary" size="xxs" weight="700">
                  {payment.planType === "P2P" ? "P2P" : payment.planType === "BNPL" ? `BNPL` : `Loan`}
                </Text>
              </Flex>
              {payment.isAutoLiquidationEnabled && (
                <Flex
                  style={{
                    background: theme.colors.secondary,
                    borderRadius: "4px",
                  }}
                  p={"2px 4px"}
                  h={"fit-content"}
                >
                  <Text color="primary" size="xxs" weight="700">
                    Auto-Liquidation
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>

          <RowText color={"gray0"} sub>
            {shortenName(payment.collectioName)}
          </RowText>
        </Flex>
      </Flex>

      <RowText weight="500" style={{ overflowWrap: "anywhere" }} color="secondary">
        #{payment.positionId}
      </RowText>

      <Hidden tabletDown>
        <PaymentDate date={payment.createdAt} />
      </Hidden>
      <Hidden tabletDown>
        <RowText weight="500" color="secondary">
          {payment.status === PaymentStatusString.extended
            ? "-"
            : `${payment.paymentNumber} out of ${payment.totalPaymentNumber}`}
        </RowText>
      </Hidden>
      <Flex direction="column" gap="2px">
        <RowText weight="500" color="secondary" style={{ overflowWrap: "anywhere" }}>
          {bigNumToFixedStr(payment.amount, 5, payment.currency.decimal)} {payment.currency.symbol}
        </RowText>
        <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
          {displayInUSD(bigNumToFloat(payment.amount, payment.currency.decimal) * usdPrice[payment.currency.symbol])}
        </RowText>
      </Flex>
      {payment.status === PaymentStatusString.missed && <RowText color="red" weight="600">{`Missed`}</RowText>}
      {payment.status === PaymentStatusString.paid && <RowText color="cyan" weight="600">{`Paid`}</RowText>}
      {payment.status === PaymentStatusString.completed && <RowText color="green" weight="600">{`Completed`}</RowText>}
      {payment.status === PaymentStatusString.completedEarly && (
        <RowText color="green" weight="600">{`Completed early`}</RowText>
      )}
      {payment.status === PaymentStatusString.extended && <RowText color="cyan" weight="600">{`Extended`}</RowText>}
      <Hidden tabletDown>
        <Button
          variant="ghost"
          style={{ opacity: payment.transactionHash ? "1" : "0.5", width: "fit-content" }}
          onClick={e => {
            e.stopPropagation();
            payment.transactionHash && window.open(`${txnUrl}/${payment.transactionHash}`, "_blank");
          }}
        >
          <NewTab color={theme.colors.secondary} />
        </Button>
      </Hidden>
    </Row>
  );
});

PaymentRow.displayName = "PaymentRow";
type IPaymentRow = IProps & {
  position: IPawn | IBNPL | IPeerPlan;
  payment?: IPayment;
};
export const PaymentsHeader = memo(
  ({
    totalItems,
    chainId,
    updateSortedPayment,
    sortedPayments,
  }: {
    totalItems: number;
    chainId: number;
    updateSortedPayment: (payments: IPaymentRow[]) => void;
    sortedPayments: IPaymentRow[];
  }) => {
    const { usdPrice } = useAppContext();
    const { sort, sortName, sortDirectionDesc } = useSortableHeader<IPaymentRow>([c => c.createdAt], "createdAt");
    const handleHeaderClick = (value: ((item: IPaymentRow) => any)[], sortName: string) => {
      const sortedData = sort(value, sortedPayments, sortName);
      updateSortedPayment(sortedData);
    };
    return (
      <HeaderContainer>
        <RowText weight="600" color="secondary">{`${totalItems} item${totalItems > 1 ? "s" : ""}`}</RowText>
        <Flex gap="5px" onClick={() => handleHeaderClick([e => e.positionId], "planid")}>
          <RowText color="secondary" weight="600">{`Plan #ID`}</RowText>
          <SortingArrows sortName={sortName} sortDirectionDesc={sortDirectionDesc} currentSortName={"planid"} />
        </Flex>
        <Hidden tabletDown>
          <HeaderItemWrapper>
            <Flex gap="5px" onClick={() => handleHeaderClick([e => e.createdAt], "createdAt")}>
              <RowText color="secondary" weight="600">{`Payment date`}</RowText>
              <SortingArrows sortName={sortName} sortDirectionDesc={sortDirectionDesc} currentSortName={"createdAt"} />
            </Flex>
          </HeaderItemWrapper>
        </Hidden>
        <Flex gap="5px" onClick={() => handleHeaderClick([e => e.totalPaymentNumber], "paymentNumber")}>
          <RowText color="secondary" weight="600">{`Payment number`}</RowText>
          <SortingArrows sortName={sortName} sortDirectionDesc={sortDirectionDesc} currentSortName={"paymentNumber"} />
        </Flex>
        <Hidden tabletDown>
          <HeaderItemWrapper>
            <Flex
              gap="5px"
              onClick={() =>
                handleHeaderClick(
                  [
                    e =>
                      e.currency && e.amount
                        ? bigNumToFloat(e.amount, e.currency.decimal) * usdPrice[e.currency.symbol]
                        : 0,
                  ],
                  "paymentAmount",
                )
              }
            >
              <RowText color="secondary" weight="600">{`Payment amount`}</RowText>
              <SortingArrows
                sortName={sortName}
                sortDirectionDesc={sortDirectionDesc}
                currentSortName={"paymentAmount"}
              />
            </Flex>
          </HeaderItemWrapper>
        </Hidden>
        <Flex onClick={() => handleHeaderClick([e => e.status], "status")}>
          <RowText color="secondary" weight="600">{`Status`}</RowText>
          <SortingArrows sortName={sortName} sortDirectionDesc={sortDirectionDesc} currentSortName={"status"} />
        </Flex>
        <Hidden tabletDown>
          <HeaderItemWrapper>
            <RowText color="secondary" weight="600">
              {CHAIN_IDS_TO_EXPLORER_NAME[chainId]}
            </RowText>
          </HeaderItemWrapper>
        </Hidden>
      </HeaderContainer>
    );
  },
);

PaymentsHeader.displayName = "PaymentsHeader";

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: transparent;
  grid-template-columns: 2.3fr 0.8fr 1.8fr 1.4fr 1.8fr 0.9fr 0.8fr;
  padding: 13px 15px 13px 15px;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "3fr 1.5fr 1.5fr 2fr " }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;

const HeaderContainer = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${({ theme }) => theme.colors.primary};
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 7px 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const StyledImg = styled.img`
width: 36px;
height: 36px;
${getStyleWithMediaQuery("width", "px", [{ [breakpoints.desktop]: 36 }, { [breakpoints.mobile]: 24 }])}
border-radius: ${({ theme }) => theme.borderRadius};
} `;

const ImageLoader = styled.div`
  width: 36px;
  height: 36px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 24 }])}
`;
