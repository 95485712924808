export const IPlanStatus = {
  Activated: 2,
  Completed: 3,
  Defaulted: 4,
  Liquidated: 5,
  Revived: 7,
  CompletedByRevival: 8,
  AutoLiquidated: 9,
  AutoLiquidatedByOpensea: 10,
  AutoLiquidatedByCyan: 11,
} as const;

const IBnplStatus = {
  Pending: 0,
  Funded: 1,

  ...IPlanStatus,

  Rejected: 6,
} as const;

export type IPaymentPlanStatus = typeof IBnplStatus[keyof typeof IBnplStatus];

export const PlanTypes = {
  BNPL: 0,
  Pawn: 1,
} as const;
export type IPlanType = typeof PlanTypes[keyof typeof PlanTypes];
