import { useState } from "react";
import styled from "styled-components";

import { Button, Flex, Hidden, Text, Tooltip, TooltipText } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { BAKCAddress } from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";

import { useApeStakingUserAssets } from "../ApeCoinDataContext";
import { useSelectedStakingNftsContext, useSelectedUnstakingNftsContext } from "../ApeCoinPageContext";
import { IActionType, IApeCoinSource } from "../types";

export const ApeStakingMultiSelection = () => {
  const { items: stakeItems, addItems: addStakeItems, removeAll: removeAllStakes } = useSelectedStakingNftsContext();
  const {
    items: unstakeItems,
    addItems: addUnstakeItems,
    removeAll: removeAllUnstakes,
  } = useSelectedUnstakingNftsContext();
  const { stakableAssets, stakablePositions, stakedAssets, stakedPositions, loading } = useApeStakingUserAssets();
  const { showApeBulkPlanModal } = useApePlanCreation();

  const [showMenu, setShowMenu] = useState(false);

  const checkAllStakes = () => {
    const positionsFiltered = stakablePositions.filter(
      ({ metadata: { collectionAddress } }) => collectionAddress.toLowerCase() !== BAKCAddress,
    );
    const assetsFiltered = stakableAssets.filter(({ address }) => address.toLowerCase() !== BAKCAddress);
    if (stakeItems.length === [...positionsFiltered, ...assetsFiltered].length && !loading) {
      return;
    } else {
      const positions = stakablePositions.map(position => ({
        address: position.metadata.collectionAddress.toLowerCase(),
        apeStaking: position.apeStaking,
        tokenId: position.tokenId,
        isCyanWallet: true,
        imageUrl: position.metadata.imageUrl,
      }));
      const nfts = stakableAssets.map(asset => ({
        address: asset.address.toLowerCase(),
        apeStaking: asset.apeStaking,
        tokenId: asset.tokenId,
        isCyanWallet: asset.isCyanWallet,
        imageUrl: asset.imageUrl,
      }));
      addStakeItems([...nfts, ...positions].filter(({ address }) => address.toLowerCase() !== BAKCAddress));
    }
  };

  const checkAllUnstakes = () => {
    if (
      unstakeItems.length === [...stakedAssets.filter(asset => asset.isCyanWallet), ...stakedPositions].length &&
      !loading
    ) {
      return;
    } else {
      const positions = stakedPositions.map(position => ({
        address: position.metadata.collectionAddress.toLowerCase(),
        apeStaking: position.apeStaking,
        tokenId: position.tokenId,
        isCyanWallet: true,
        imageUrl: position.metadata.imageUrl,
      }));
      const nfts = stakedAssets
        .filter(asset => asset.isCyanWallet)
        .map(asset => ({
          address: asset.address.toLowerCase(),
          apeStaking: asset.apeStaking,
          tokenId: asset.tokenId,
          isCyanWallet: asset.isCyanWallet,
          imageUrl: asset.imageUrl,
        }));
      addUnstakeItems([...nfts, ...positions]);
    }
  };

  const openStakeModal = () => {
    showApeBulkPlanModal({
      selectedMainNfts: stakeItems,
      action: IActionType.stake,
      apeCoinSource: IApeCoinSource.borrow,
      removePlan: () => {},
    });
  };
  const openUnstakeModal = () => {
    showApeBulkPlanModal({
      selectedMainNfts: unstakeItems,
      action: IActionType.unstake,
      apeCoinSource: IApeCoinSource.all,
      removePlan: () => {},
    });
  };

  return (
    <>
      <Hidden laptopSDown>
        <MultiSelectionBox show={[...stakeItems, ...unstakeItems].length !== 0}>
          <Flex gap="1rem">
            <TextInsideButton size="lg" color="secondary" style={{ width: "78px" }}>{`${
              [...stakeItems, ...unstakeItems].length
            } item${[...stakeItems, ...unstakeItems].length > 1 ? "s" : ""}`}</TextInsideButton>
            <TextButton size="lg" color="cyan" onClick={checkAllStakes}>
              Select all staked NFTs
            </TextButton>
            <Text size="lg" color="gray0">
              |
            </Text>
            <TextButton size="lg" color="cyan" onClick={checkAllUnstakes}>
              Select all unstaked NFTs
            </TextButton>
            <Text size="lg" color="gray0">
              |
            </Text>
            <TextButton
              size="lg"
              color="cyan"
              onClick={() => {
                removeAllStakes();
                removeAllUnstakes();
              }}
            >
              Clear
            </TextButton>
          </Flex>
          <Flex gap="1rem">
            <Tooltip>
              <SecondaryButton disabled={unstakeItems.length === 0} onClick={openUnstakeModal}>
                <TextInsideButton color="secondary" size="lg" weight="600" textWrap={false}>
                  {`Unstake ${unstakeItems.length} NFT${unstakeItems.length > 1 ? "s" : ""}`}
                </TextInsideButton>
              </SecondaryButton>{" "}
              <TooltipText showArrow position="top" top="-70px" right="25px" style={{ width: "180px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="sm" color="primary" weight="500" lineHeight={14}>
                    <div>{`Bulk unstaking is only possible for cyan wallet assets.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
            <Tooltip>
              <StyledButton disabled={stakeItems.length === 0} onClick={openStakeModal}>
                <TextInsideButton color="black" size="lg" weight="600" textWrap={false}>
                  {`Stake  ${stakeItems.length} NFT${stakeItems.length > 1 ? "s" : ""}`}
                </TextInsideButton>
              </StyledButton>
              <TooltipText showArrow position="top" top="-70px" right="25px" style={{ width: "180px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="sm" color="primary" weight="500" lineHeight={14}>
                    <div>{`Bulk staking is only possible for MAYC and BAYC.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
          </Flex>
        </MultiSelectionBox>
      </Hidden>
      <Hidden laptopSUp>
        <MultiSelectionBox show={[...stakeItems, ...unstakeItems].length !== 0}>
          <MobileMenuWrapper>
            <MoreOptions
              style={{ padding: "6px 12px", background: showMenu ? "#2C2C2C" : "#6C6C6C" }}
              onClick={() => setShowMenu(!showMenu)}
            >
              <Text color="white" size="xxs" weight="600" textWrap={false}>
                {`More options`}
              </Text>
            </MoreOptions>
            <MobileMenu direction="column" show={showMenu}>
              <Flex p="14px 26px 7px 22px" direction="column" gap="10px">
                <Flex gap="7px" alignItems="center" onClick={openUnstakeModal}>
                  <Text color="secondary" size="xs" weight="600" textWrap={false}>
                    {`Unstake ${unstakeItems.length} asset${unstakeItems.length > 1 ? "s" : ""}`}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                direction="column"
                gap="10px"
                p="14px 22px"
                style={{
                  borderTop: "1px solid #2C2C2C ",
                }}
              >
                <Text
                  size="xs"
                  color="secondary"
                  weight="600"
                  textWrap={false}
                  onClick={checkAllStakes}
                >{`Select all staked NFTs`}</Text>
                <Text
                  size="xs"
                  color="secondary"
                  textWrap={false}
                  weight="600"
                  onClick={checkAllUnstakes}
                >{`Select all unstaked NFTs`}</Text>
              </Flex>
            </MobileMenu>
          </MobileMenuWrapper>
          <Tooltip>
            <Button disabled={stakeItems.length === 0} onClick={openUnstakeModal} style={{ padding: "6px 12px" }}>
              <Flex gap="7px" alignItems="center">
                <Text color="black" size="xxs" weight="600" textWrap={false}>
                  {`Stake  ${stakeItems.length} asset${stakeItems.length > 1 ? "s" : ""}`}
                </Text>
              </Flex>
            </Button>
          </Tooltip>
        </MultiSelectionBox>
      </Hidden>
    </>
  );
};

const MultiSelectionBox = styled.div<{ show: boolean }>`
  z-index: 80;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
  background: ${({ theme }) => theme.backgroundColor};
  position: fixed;
  bottom: 0;
  width: calc(100vw - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  @media only screen and (max-width: ${breakpoints.laptopS}px) {
    position: fixed;
    width: calc(100% - 1rem);
    padding: 4px 0.5rem;
    height: ${({ show }) => (show ? "47px" : "0px")};
    bottom: ${({ show }) => (show ? "0px" : "-30px")};
  }
  transition: 0.5s;
  height: ${({ show }) => (show ? "100px" : "0px")};
  bottom: ${({ show }) => (show ? "0px" : "-30px")};
`;

const TextButton = styled(Text)`
  transition: 0.2s;
  cursor: pointer;
  font-size: 18px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptopL]: 14 }])};
  :hover {
    color: #79ffff;
  }
`;

const TextInsideButton = styled(Text)`
  font-size: 18px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptopL]: 14 }])};
`;

const StyledButton = styled(Button)`
  height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  ${getStyleWithMediaQuery("padding-left", "px", [{ [breakpoints.laptopL]: 10 }])};
  ${getStyleWithMediaQuery("padding-right", "px", [{ [breakpoints.laptopL]: 10 }])};
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.laptopL]: 40 }])};
`;

const SecondaryButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.gray20};
  border-color: ${({ theme }) => theme.colors.gray20};
  :hover {
    opacity: 0.8;
    background-color: ${({ theme }) => theme.colors.gray20};
    border-color: ${({ theme }) => theme.colors.gray20};
  }
`;

const MobileMenuWrapper = styled.div`
  position: relative;
`;
const MoreOptions = styled(Button)`
  border-color: ${({ theme }) => theme.colors.gray0};
  background: ${({ theme }) => theme.colors.gray0};
  :hover {
    border-color: ${({ theme }) => theme.colors.gray0};
    background: ${({ theme }) => theme.colors.gray0};
  }
`;
const MobileMenu = styled(Flex)<{ show: boolean }>`
  z-index: 10 !important;
  position: absolute;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  background: ${({ theme }) => theme.colors.primary};
  bottom: calc(100% + 15px);
  gap: 10px;
  display: ${({ show }) => (show ? "flex" : "none")};
`;
