import dayjs from "dayjs";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex, Stepper, useModal } from "@cyanco/components/theme";
import { factories as f } from "@cyanco/contract";

import { ICollectionBe } from "@/apis/collection/types";
import { IP2PLoanOffer } from "@/apis/p2p/types";
import { IUserNft } from "@/apis/user/types";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPeerPaymentPlanFromChainId } from "@/constants/contracts";
import { useApproval } from "@/hooks/useApproval";
import { INftType } from "@/types";
import { getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { NftMetadata } from "./NftMetadata";
import { OfferButton, OfferTakerCard } from "./OfferTakerCard";

export const LoanOfferAcceptingProgress = ({
  collection,
  nft,
  loanData,
}: {
  collection: ICollectionBe;
  loanData: IP2PLoanOffer;
  nft: IUserNft;
}) => {
  const navigate = useNavigate();
  const { giveNftPermission } = useApproval();
  const { setModalContent } = useModal();
  const cyanWallet = useCyanWallet();
  const { unsetModal } = useModal();
  const { provider, chainId, account, signer } = useWeb3React();
  const { transactions, addTransaction } = useTransactionContext();
  const [selectedStep, setSelectedStep] = useState<Steps>(Steps.Approve);
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(Steps.Done);
        navigate("/account/positions");
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);
  const approve = async () => {
    if (!provider || !account) throw new Error("Provider not found!");
    const peerPlanContract = getPeerPaymentPlanFromChainId(chainId);
    if (nft.isCyanWallet && !cyanWallet) {
      throw new Error("Cyan wallet not found!");
    }
    if (!nft.isCyanWallet) {
      await giveNftPermission(
        {
          itemType: collection.tokenType,
          collectionAddress: nft.address,
          tokenId: nft.tokenId,
        },
        peerPlanContract,
      );
    }
  };
  const createPlan = async () => {
    if (!signer || !cyanWallet) throw new Error("Provider not found!");
    if (!collection.cyanSignature) throw new Error("Collection signature not found!");
    const peerToPeerContract = getPeerPaymentPlanFromChainId(chainId);
    const writer = f.CyanPeerPlanFactory.connect(peerToPeerContract, signer);
    const serviceFeeRate = await writer.serviceFeeRate();
    let tx: ethers.ContractTransaction;
    const params = {
      item: {
        itemType: collection.tokenType,
        amount: loanData.tokenAmount,
        tokenId: nft.tokenId,
        collectionSignature: collection.cyanSignature,
        contractAddress: loanData.collectionAddress,
      },
      plan: {
        amount: loanData.amount,
        lenderAddress: loanData.lenderAddress,
        currencyAddress: loanData.currency.address,
        interestRate: loanData.interestRate,
        serviceFeeRate,
        term: loanData.term,
      },
      signature: {
        expiryDate: dayjs(loanData.signatureExpiry).valueOf(),
        maxUsageCount: loanData.signatureUsageLimit,
        signature: loanData.signature,
        extendable: loanData.isExtendable,
        signedDate: dayjs(loanData.signedDate).valueOf(),
      },
    };
    if (collection.tokenType === INftType.ERC1155 && nft.isCyanWallet) {
      if (!cyanWallet) {
        throw new Error("Cyan wallet not found!");
      }
      const encodedFn = writer.interface.encodeFunctionData("createP2P", [params.item, params.plan, params.signature]);
      const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
        peerToPeerContract,
        "0",
        encodedFn,
      ]);
      tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        data: encodedCyanFnData,
      });
    }
    tx = await writer.createP2P(params.item, params.plan, params.signature);
    addTransaction({
      hash: tx.hash,
      type: "p2p-create",
      data: {
        signature: loanData.signature,
        tokenId: nft.tokenId,
        contractAddress: nft.address,
      },
    });
    setActiveTx(tx.hash);
  };
  useEffect(() => {
    const creating = async () => {
      if (!account) return;
      try {
        switch (selectedStep) {
          case Steps.Approve: {
            if (
              loanData.lenderAddress === account.toLowerCase() ||
              loanData.lenderAddress === (cyanWallet?.walletAddress ?? "").toLowerCase()
            ) {
              setModalContent({
                title: nft ? `NFT Loan Offer` : `Collection Loan Offer`,
                content: (
                  <OfferTakerCard
                    error={{
                      title: "Can not accept offer",
                      msg: "Sorry, it is not possible to accept own offer.",
                    }}
                    nft={nft}
                    loandData={loanData}
                    collection={collection}
                    showNft={true}
                  />
                ),
              });
            }
            await approve();
            setSelectedStep(Steps.CreateOffer);
            return;
          }
          case Steps.CreateOffer: {
            await createPlan();
            return;
          }
        }
      } catch (e) {
        const mappedError = mapAndLogError(e, account);
        setModalContent({
          title: nft ? `NFT Loan Offer` : `Collection Loan Offer`,
          content: (
            <OfferTakerCard error={mappedError} nft={nft} loandData={loanData} collection={collection} showNft={true} />
          ),
        });
      }
    };
    creating();
  }, [selectedStep]);
  const stepMarks = useMemo(() => {
    return StepMarks.map(item => {
      if (item.value === Steps.CreateOffer) {
        return {
          ...item,
          title: `${loanData.currency.symbol} sent to user`,
        };
      }
      return item;
    });
  }, [StepMarks, loanData.currency]);
  return (
    <Flex gap="1rem" direction="column">
      <NftMetadata nft={nft} lender={loanData.lenderAddress} />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === Steps.Done && <OfferButton onClick={unsetModal}>{`Close`}</OfferButton>}
    </Flex>
  );
};

enum Steps {
  Approve = 1,
  CreateOffer = 2,
  Done = 3,
}
const StepMarks = [
  {
    value: Steps.Approve,
    description: `To move NFT to use as collateral, gas required`,
    title: `Give Permission to Cyan Protocol`,
  },
  {
    value: Steps.CreateOffer,
    title: `ETH sent to user`,
    description: `View on Etherscan`,
  },
];
