import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SkeletonLine, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IP2PListedNft } from "@/apis/p2p/types";
import { useAppContext } from "@/components/AppContextProvider";
import { INFtRarity } from "@/components/NftStatus";
import { bigNumToFloat, displayInUSD, numberWithCommas, shortenName } from "@/utils";

export const ListedNftRowLoading = () => {
  return (
    <Row>
      <Flex alignItems="center" gap="8px">
        <StyledImgLoading />
        <RowLoadingText />
      </Flex>
      <Flex gap="2px" direction="column">
        <RowLoadingText sub />
        <RowLoadingText />
      </Flex>
      <Flex direction="column" gap="2px">
        <RowLoadingText sub />
        <RowLoadingText />
      </Flex>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
    </Row>
  );
};

export const ListedNftHeader = () => {
  const theme = useTheme();
  return (
    <HeaderContainer>
      <HeaderText color="secondary">{`NFT Item`}</HeaderText>
      <Flex gap="5px" alignItems="center">
        <HeaderText color="secondary" textWrap={false}>{`Appraisal Value`}</HeaderText>
        <Tooltip>
          <HelpCircle height={16} width={16} color={theme.colors.secondary} />
          <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
              <div>{`Buy Now price is an estimate based on a floor price NFT for a 3-month, 25% down payment loan.`}</div>
            </Text>
          </TooltipText>
        </Tooltip>
      </Flex>
      <HeaderText color="secondary">{`Rarity`}</HeaderText>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Action`}</HeaderText>
      </Hidden>
    </HeaderContainer>
  );
};

type IProps = {
  nft: IP2PListedNft & { rarity: INFtRarity | null };
  onClick: (a: IP2PListedNft) => void;
  onClickCard: (a: IP2PListedNft) => void;
};
export const ListedNftRow: React.FC<IProps> = ({ nft, onClick, onClickCard }) => {
  const { usdPrice } = useAppContext();
  const getPrice = useMemo(
    () => (!nft.appraisalValue ? "-" : numberWithCommas(bigNumToFloat(nft.appraisalValue, nft.currency.decimal), 2)),
    [nft.appraisalValue],
  );
  const getPriceInUsd = useMemo(
    () => displayInUSD(bigNumToFloat(nft.appraisalValue || 0, nft.currency.decimal) * usdPrice[nft.currency.symbol]),
    [nft.appraisalValue],
  );
  return (
    <Row onClick={() => onClickCard(nft)}>
      <Flex alignItems="center" gap="8px">
        <StyledImg src={nft.imageUrl || NoImage} hasImage={!!nft.imageUrl} alt={nft.collectionName} />
        <RowText color={"secondary"}> #{shortenName(nft.tokenId, 10, 6)}</RowText>
      </Flex>

      <Flex direction="column">
        <RowText color={"secondary"}>
          {getPrice} {nft.currency.symbol}
        </RowText>
        <RowText color="gray0" sub>
          {getPriceInUsd}
        </RowText>
      </Flex>
      <Flex direction="column">
        <RowText color={"secondary"} weight="500">
          {nft.rarity ? numberWithCommas(nft.rarity.rank) : "-"}
        </RowText>
        <RowText color="gray0" sub>
          {nft.rarity ? nft.rarity.relativeRank : "-"}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <Flex w="fit-content">
          <SmallButton
            onClick={e => {
              e.stopPropagation();
              onClick(nft);
            }}
          >
            <BorrowBox>
              <SmallButtonText textWrap={false} weight={"700"} color="black">
                {`Make NFT Loan Offer`}
              </SmallButtonText>
            </BorrowBox>
          </SmallButton>
        </Flex>
      </Hidden>
    </Row>
  );
};

const Row = styled.div<{ selected?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  grid-template-columns: 1.3fr 1.5fr 1.5fr 1.5fr;
  padding: 13px 15px 13px 15px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.gray10 : "transparent")};
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2fr 2.3fr 1.6fr" }])};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 10px 14px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const SmallButton = styled(Button)`
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: ${({ disabled }) => !disabled && "#79FFFF"};
    border-color: ${({ disabled }) => !disabled && "#79FFFF"};
  }
`;
const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 14 },
    { [breakpoints.laptop]: 12 },
    { [breakpoints.tablet]: 12 },
  ])};
`;

const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

const HeaderContainer = styled(Row)`
  padding: 14px 19px 12px 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "14px 19px 12px 18px" },
    { [breakpoints.laptop]: "14px 10px 12px 40px" },
    { [breakpoints.tablet]: "10px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
`;

const StyledImg = styled.img<{ hasImage?: boolean }>`
width: 36px;
${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
border-radius: ${({ theme }) => theme.borderRadius};
filter: ${({ hasImage, theme }) =>
  !hasImage &&
  theme.theme === "light" &&
  "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
} `;
const BorrowBox = styled(Flex)`
  padding: 0.2rem;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0.1rem" }])}
`;

const RowLoadingText = styled(SkeletonLine)<{ sub?: boolean }>`
  width: 50%;
  height: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("height", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])};
`;
const StyledImgLoading = styled(SkeletonLine)`
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  ${getStyleWithMediaQuery("min-width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("min-height", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("max-height", "px", [{ [breakpoints.mobile]: 24 }])}
  border-radius: ${({ theme }) => theme.borderRadius};
`;
const SmallButtonText = styled(Text)`
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 12 }, { [breakpoints.tablet]: 10 }])};
`;
