import orderBy from "lodash.orderby";
import { useEffect, useMemo, useRef, useState } from "react";
import { Filter } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Box, Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { SlashButton, SlashText } from "@cyanco/components/theme/v3";
import { Button, Input, Option, Select, Text, useModal } from "@cyanco/components/theme/v3/core";
import { Grid, List } from "@cyanco/components/theme/v3/icons";

import { ICollectionBe } from "@/apis/collection/types";
import { useSupportedCollections } from "@/components/AppContextProvider";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { useDebounce } from "@/hooks/useDebounce";
import { shortenName } from "@/utils";

import { LendCollectionView, LendPageViewStyle, useLendPageViewType, useLendSelectors } from "../LendPageContext";
import { OfferMakerCard } from "./LoanOffers/OfferMakerCard";

export const Selectors: React.FC<{ collection: ICollectionBe }> = ({ collection }) => {
  const theme = useTheme();
  const { setModalContent } = useModal();
  const [tokenInputValue, setTokenInputValue] = useState("");
  const { collections } = useSupportedCollections();
  const filteredCollections = useMemo(
    () => collections.filter(_collection => _collection.chainId === collection.chainId),
    [collections, collection.chainId],
  );
  const {
    selectedLendCollectionView,
    setSelectedLendCollectionView,
    setSelectedTokenId,
    setShowFilterAttributes,
    showFilterAttributes,
  } = useLendSelectors();
  const { selectedLendViewStyle, setSelectedLendViewStyle } = useLendPageViewType();
  useDebounce(() => setSelectedTokenId(tokenInputValue), [tokenInputValue], 1000);
  const searchInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "k") {
        searchInput.current?.focus();
      }
      if (e.key === "Escape") {
        searchInput.current?.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [tokenInputValue]);

  const navigate = useNavigate();
  return (
    <BnplSelectorWrapper>
      <BnplSelectors>
        <DropDowns>
          <Box h="46px">
            <Select
              onChange={value => {
                setTokenInputValue("");
                setSelectedTokenId("");
                navigate(
                  `/lend/${
                    CHAIN_IDS_TO_NAMES[
                      filteredCollections.find(_collection => _collection.address === value)
                        ?.chainId as SupportedChainId
                    ]
                  }/${value}`,
                );
              }}
              value={collection.address}
            >
              {orderBy(filteredCollections, [_collection => _collection.name.toLowerCase()]).map(_collection => (
                <Option value={_collection.address} key={_collection.address}>
                  {shortenName(_collection.name, 20, 17, 0)}
                </Option>
              ))}
            </Select>
          </Box>
          <LendViewSelectorWrapper>
            <LendViewType
              isActive={LendCollectionView.assets === selectedLendCollectionView}
              onClick={() => setSelectedLendCollectionView(LendCollectionView.assets)}
            >
              <Text
                size="lg"
                color={LendCollectionView.assets === selectedLendCollectionView ? "secondary" : "gray0"}
                textWrap={false}
              >
                {`Assets`}
              </Text>
            </LendViewType>
            <LendViewType
              isActive={LendCollectionView.loanOffers === selectedLendCollectionView}
              onClick={() => setSelectedLendCollectionView(LendCollectionView.loanOffers)}
            >
              <Text
                size="lg"
                color={LendCollectionView.loanOffers === selectedLendCollectionView ? "secondary" : "gray0"}
                textWrap={false}
              >
                {`Loan Offers`}
              </Text>
            </LendViewType>
          </LendViewSelectorWrapper>
          <FilterButton onClick={() => setShowFilterAttributes(!showFilterAttributes)} disabled>
            <Flex gap="0.2rem" alignItems="center">
              <Filter size={18} color={showFilterAttributes ? theme.colors.gray20 : theme.colors.secondary} />
              <Text size="lg" color={showFilterAttributes ? "gray20" : "secondary"}>{`Filter`}</Text>
            </Flex>
          </FilterButton>
        </DropDowns>
        <Box h="46px">
          <Input
            inputRef={searchInput}
            placeholder={`Search NFTs`}
            fontSize="lg"
            fontWeight="400"
            value={tokenInputValue}
            onChange={e => setTokenInputValue(e.target.value)}
          >
            <SlashButton
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "35px",
                marginRight: "-6px",
              }}
            >
              <SlashText size="xs" weight="500">
                {"⌘+k"}
              </SlashText>
            </SlashButton>
          </Input>
        </Box>
        <CartWrapper direction="row" gap="1rem" w="100%" justifyContent="flex-end">
          <Box>
            <Button
              size="xxs"
              style={{
                padding: "0 1rem",
              }}
              onClick={() => {
                setModalContent({
                  content: <OfferMakerCard collection={collection} />,
                  title: `Collection Loan Offer`,
                });
              }}
            >{`Collection Loan Bid`}</Button>
          </Box>
          {selectedLendCollectionView === LendCollectionView.assets && (
            <ViewSelectorWrapper>
              <ViewType
                isActive={LendPageViewStyle.list === selectedLendViewStyle}
                onClick={() => setSelectedLendViewStyle(LendPageViewStyle.list)}
              >
                <List
                  color={LendPageViewStyle.list === selectedLendViewStyle ? theme.colors.white : theme.colors.gray80}
                />
              </ViewType>
              <ViewType
                isActive={LendPageViewStyle.grid === selectedLendViewStyle}
                onClick={() => setSelectedLendViewStyle(LendPageViewStyle.grid)}
              >
                <Grid
                  color={LendPageViewStyle.grid === selectedLendViewStyle ? theme.colors.white : theme.colors.gray80}
                />
              </ViewType>
            </ViewSelectorWrapper>
          )}
        </CartWrapper>
      </BnplSelectors>
    </BnplSelectorWrapper>
  );
};

const BnplSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  top: 75px;
  z-index: 6;
  background: ${({ theme }) => theme.backgroundColor};
  padding: 0 10rem 2rem 10rem;
  @media only screen and (max-width: ${breakpoints.desktop}px) {
    padding: 0 18px 2rem 18px;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    position: relative;
    top: 0px;
    padding: 0 calc(0.5rem + 5px);
    background: transparent;
    gap: 0.5rem;
  }
`;

const BnplSelectors = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1fr 1.6fr;
  width: 100%;
  gap: 1rem;
  padding-top: 1rem;
  @media only screen and (max-width: ${breakpoints.laptop}px) {
    grid-template-columns: auto 1.2fr 1fr;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    padding-top: 1rem;
  }
`;

const DropDowns = styled.div`
  display: grid;
  grid-template-columns: 2.4fr auto auto;
  width: 100%;
  gap: 1rem;
  @media only screen and (max-width: ${breakpoints.laptop}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 0.5rem;
    grid-template-columns: 2fr 1.3fr;
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    gap: 0.5rem;
    grid-template-columns: 2fr auto;
  }
`;

const ViewSelectorWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.gray20};
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 3px;
  flex-direction: row;
`;
const ViewType = styled.div<{ isActive: boolean }>`
  width: 46px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: ${props => (props.isActive ? props.theme.colors.gray20 : "transparent")};
  border-radius: 7px;
  height: 100%;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    height: 25px;
  }
  transition: 0.2s;
  svg,
  path,
  rect {
    transition: 0.2s;
  }
  :hover {
    color: ${({ theme, isActive }) => !isActive && theme.colors.secondary};
    svg,
    path,
    rect {
      transition: 0.2s;
      stroke: ${({ theme, isActive }) => !isActive && theme.colors.secondary};
    }
  }
`;

const CartWrapper = styled(Flex)`
  justify-content: flex-end;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    order: -1;
  }
`;

export const LendViewSelectorWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.gray20};
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 3px;
  align-items: center;
`;
export const LendViewType = styled.div<{ isActive: boolean }>`
  align-items: center;
  display: flex;
  padding: 0 1rem;
  justify-content: center;
  background-color: transparent;
  border-radius: 7px;
  height: 100%;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    height: 25px;
  }
  :hover {
    color: ${({ theme, isActive }) => !isActive && theme.colors.secondary};
  }
`;
const FilterButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.gray20};
  height: 46px;
  width: 100px;
  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
