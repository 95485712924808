import { BigNumber } from "ethers";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { IVault, IVaultAdditionalMetrics } from "@/apis/vault/types";
import { bigNumToFloat } from "@/utils";

import { CashflowBar } from "./CashflowBar";
import { MixtureOfActiveLoansByCollection } from "./MixtureOfActiveLoansByCollection";
import { PoolBreakdown } from "./PoolBreakdown";

export const CHART_HEIGHT = {
  mobile: 250,
  desktop: 574,
};

export const getTopCollections = (collections: IVaultAdditionalMetrics["collections"], total: BigNumber) => {
  const top10Collections = collections
    // filter out if collection total is less than 1%
    .filter(collection => {
      return BigNumber.from(collection.total).gte(total.div(100));
    })
    .sort((a, b) => bigNumToFloat(BigNumber.from(b.total).sub(BigNumber.from(a.total))))
    .slice(0, 10);

  const othersTotal = collections
    .filter(collection => !top10Collections.includes(collection))
    .reduce((acc, curr) => {
      return acc.add(BigNumber.from(curr.total));
    }, BigNumber.from(0));

  if (othersTotal.gt(0)) {
    top10Collections.push({
      name: "Others",
      total: othersTotal.toString(),
    });
  }
  return top10Collections;
};

export const VaultCharts = ({
  vault,
  vaultAdditionalMetrics,
  loading,
}: {
  vault: IVault;
  vaultAdditionalMetrics: IVaultAdditionalMetrics;
  loading: boolean;
}) => {
  return (
    <Container direction="column" gap="1.5rem" mt="4.5rem">
      <PoolBreakdown
        collections={vaultAdditionalMetrics.collections}
        currency={vault.currency}
        decimals={vault.decimals}
        vaultAddress={vault.contractAddress}
        vaultChainId={vault.chainId}
        loading={loading}
      />
      <MixtureOfActiveLoansByCollection
        collections={vaultAdditionalMetrics.collections}
        currency={vault.currency}
        decimals={vault.decimals}
        loading={loading}
      />
      <CashflowBar
        cashflowData={vaultAdditionalMetrics.cashflowData}
        currency={vault.currency}
        decimals={vault.decimals}
        loading={loading}
      />
    </Container>
  );
};

const Container = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0 5px" }])}
`;
