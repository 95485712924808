import { createContext, useContext, useState } from "react";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { apeVaultContract } from "@/config";
import { SupportedChainId } from "@/constants/chains";

import { IVault, IVaultBE } from "../../apis/vault/types";
import { VaultViewStyle } from "./components/AvailableVaults";

export enum ActivityPeriod {
  "1day" = "1D",
  "7day" = "7D",
  "30day" = "30D",
}

export enum PriceChartInterval {
  "7day" = "1W",
  "30day" = "1M",
  "365day" = "1Y",
}

export const VAULT_SORTING_OPTIONS = [
  {
    value: "Vault name",
    function: (a: IVault, b: IVault) => {
      return a.name.localeCompare(b.name);
    },
    functionDesc: (a: IVault, b: IVault) => {
      return b.name.localeCompare(a.name);
    },
    hidden: false,
    align: "start",
  },
  {
    value: "Currency",
    function: (a: IVault, b: IVault) => {
      return a.currency.localeCompare(b.currency);
    },
    functionDesc: (a: IVault, b: IVault) => {
      return b.currency.localeCompare(a.currency);
    },
    hidden: true,
  },
  {
    value: "Supply",
    function: (a: IVault, b: IVault) => {
      return b.totalValueLockedUsd - a.totalValueLockedUsd;
    },
    functionDesc: (a: IVault, b: IVault) => {
      return a.totalValueLockedUsd - b.totalValueLockedUsd;
    },
    hidden: false,
  },
  {
    value: "Utilization",
    function: (a: IVault, b: IVault) => {
      return (b.utilizationRate ?? 0) - (a.utilizationRate ?? 0);
    },
    functionDesc: (a: IVault, b: IVault) => {
      return (a.utilizationRate ?? 0) - (b.utilizationRate ?? 0);
    },
    hidden: true,
  },
  {
    value: "Supply APY",
    function: (a: IVault, b: IVault) => {
      return (getSupplyApy(b) ?? 0) - (getSupplyApy(a) ?? 0);
    },
    functionDesc: (a: IVault, b: IVault) => {
      return (getSupplyApy(a) ?? 0) - (getSupplyApy(b) ?? 0);
    },
    hidden: true,
  },
  {
    value: "Borrow APY",
    function: (a: IVault, b: IVault) => {
      return (getBorrowApy(b) ?? 0) - (getBorrowApy(a) ?? 0);
    },
    functionDesc: (a: IVault, b: IVault) => {
      return (getBorrowApy(a) ?? 0) - (getBorrowApy(b) ?? 0);
    },
    hidden: true,
  },
];
export type ISortValues = "Vault name" | "Currency" | "Supply" | "Utilization" | "Supply APY" | "Borrow APY";
export type IActivityPeriodType = keyof typeof ActivityPeriod;
export type IPriceChartIntervalType = keyof typeof PriceChartInterval;
export type IVaultLiquidationTypes = "No liquidation" | "Auto-liquidation" | "All liquidations";
interface IVaultPageContext {
  selectedChain: SupportedChainId | "all";
  selectedChainType: "layer-2" | "all";
  selectedActivityPeriod: IActivityPeriodType;
  selectedVaultSearch: string;
  selectedView: VaultViewStyle;
  selectedCurrency: SupportedCurrenciesType | "all";
  selectedSort: ISortValues | "none";
  sortDirection: "asc" | "desc";
  selectedAutoLiquidationStatus: IVaultLiquidationTypes;
  setSelectedChain: (chainId: SupportedChainId | "all") => void;
  setSelectedChainType: (chainId: "layer-2" | "all") => void;
  setSelectedActivityPeriod: (activityPeriod: IActivityPeriodType) => void;
  setSelectedVaultSearch: (search: string) => void;
  setSelectedView: (view: VaultViewStyle) => void;
  setSelectedCurrency: (currency: SupportedCurrenciesType | "all") => void;
  setSelectedSort: (value: ISortValues | "none") => void;
  setSortDirection: (value: "asc" | "desc") => void;
  setSelectedAutoLiquidationStatus: (value: IVaultLiquidationTypes) => void;
}

const VaultPageContext = createContext<IVaultPageContext>({
  selectedChain: "all",
  selectedActivityPeriod: "30day",
  selectedVaultSearch: "",
  selectedView: VaultViewStyle.list,
  selectedCurrency: "all",
  selectedSort: "none",
  selectedChainType: "all",
  sortDirection: "asc",
  selectedAutoLiquidationStatus: "All liquidations",
  setSelectedChain: () => {},
  setSelectedActivityPeriod: () => {},
  setSelectedVaultSearch: () => {},
  setSelectedView: () => {},
  setSelectedCurrency: () => {},
  setSelectedSort: () => {},
  setSelectedChainType: () => {},
  setSortDirection: () => {},
  setSelectedAutoLiquidationStatus: () => {},
});

export const VaultPageProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedChain, setSelectedChain] = useState<SupportedChainId | "all">("all");
  const [selectedChainType, setSelectedChainType] = useState<"layer-2" | "all">("all");
  const [selectedActivityPeriod, setSelectedActivityPeriod] = useState<IActivityPeriodType>("30day");
  const [selectedVaultSearch, setSelectedVaultSearch] = useState<string>("");
  const [selectedView, setSelectedView] = useState<VaultViewStyle>(VaultViewStyle.grid);
  const [selectedCurrency, setSelectedCurrency] = useState<SupportedCurrenciesType | "all">("all");
  const [selectedSort, setSelectedSort] = useState<ISortValues | "none">("Supply");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [selectedAutoLiquidationStatus, setSelectedAutoLiquidationStatus] =
    useState<IVaultLiquidationTypes>("All liquidations");
  return (
    <VaultPageContext.Provider
      value={{
        selectedChain,
        selectedActivityPeriod,
        selectedVaultSearch,
        selectedView,
        selectedCurrency,
        selectedSort,
        selectedChainType,
        sortDirection,
        selectedAutoLiquidationStatus,
        setSelectedChain,
        setSelectedActivityPeriod,
        setSelectedVaultSearch,
        setSelectedView,
        setSelectedCurrency,
        setSelectedSort,
        setSelectedChainType,
        setSortDirection,
        setSelectedAutoLiquidationStatus,
      }}
    >
      {children}
    </VaultPageContext.Provider>
  );
};

const useVaultPageContext = () => {
  return useContext(VaultPageContext);
};

export const useVaultSelectors = () => {
  const {
    selectedChain,
    selectedActivityPeriod,
    selectedVaultSearch,
    selectedView,
    selectedCurrency,
    selectedSort,
    sortDirection,
    selectedChainType,
    selectedAutoLiquidationStatus,
    setSelectedSort,
    setSortDirection,
    setSelectedChain,
    setSelectedActivityPeriod,
    setSelectedVaultSearch,
    setSelectedView,
    setSelectedCurrency,
    setSelectedChainType,
    setSelectedAutoLiquidationStatus,
  } = useVaultPageContext();
  return {
    selectedChain,
    selectedActivityPeriod,
    selectedVaultSearch,
    selectedView,
    selectedCurrency,
    selectedSort,
    selectedChainType,
    sortDirection,
    selectedAutoLiquidationStatus,
    setSelectedActivityPeriod,
    setSelectedVaultSearch,
    setSelectedChain,
    setSelectedView,
    setSelectedCurrency,
    setSelectedSort,
    setSelectedChainType,
    setSortDirection,
    setSelectedAutoLiquidationStatus,
  };
};

export const calculateChangePercent = (value: number | null, changedBy: number | null): number | null => {
  if (!value || !changedBy) return null;
  const difference = value - changedBy;
  const percent = difference === 0 ? 0 : (difference / (value > changedBy ? value : changedBy)) * 100;
  return percent;
};

export const calculateSupplyApy = (estYield: number, utilizationRate: number): number => {
  const estYieldConverted = estYield / 100;
  const utilizationRateConverted = utilizationRate * 100;
  const serviceFeeRate = 0; // temporary
  const APR = (estYieldConverted * utilizationRateConverted) / 100 - serviceFeeRate;
  return (Math.pow(1 + APR / 100 / 12, 12) - 1) * 100;
};

export const calculateBorrowApy = (estYield: number): number => {
  const estYieldConverted = estYield / 100; //converting to decimal
  return (Math.pow(1 + estYieldConverted / 100 / 12, 12) - 1) * 100;
};

export const getSupplyApy = (vault: IVaultBE) => {
  if (vault.contractAddress.toLowerCase() === apeVaultContract) {
    if (!vault.estimatedYield) return null;
    return vault.estimatedYield / 100;
  }
  if (!vault.estimatedYield || !vault?.utilizationRate || !vault.transactionFee) return null;
  return calculateSupplyApy(vault.estimatedYield, vault.utilizationRate);
};

export const getBorrowApy = (vault: IVaultBE) => {
  if (vault.contractAddress.toLowerCase() === apeVaultContract) return 0;
  if (!vault.estimatedYield) return null;
  return calculateBorrowApy(vault.estimatedYield);
};

export const formatPercentage = (value: number | null, fix = 2) => {
  switch (value) {
    case 0:
      return "0.00%";
    case null:
      return "--%";
    default:
      return `${value.toFixed(fix)}%`;
  }
};
