import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme";
import { Flex } from "@cyanco/components/theme/Flex";
import { Option, Select, SkeletonLine, Text, Toggler } from "@cyanco/components/theme/v3";
import { CloseX } from "@cyanco/components/theme/v3/icons";
import { CyanLogoBlack, NoImage } from "@cyanco/components/theme/v3/images";

import { mainPoolCollections } from "@/config";

import { ISelectedNft } from "../../types";
import { ImageWrapper, ItemWrapper, NftImageWrapper, RemoveFromCart, StyledNftImage } from "../common";

export const UserNftSelector = ({
  collections,
  nfts,
  selectedCollection,
  selectedNfts,
  onChangeSelectedCollection,
  onChangeSelectedNft,
  isLoading,
  multipleSelect = true,
}: {
  collections: { name: string; address: string }[];
  nfts: ISelectedNft[];
  selectedCollection: string;
  selectedNfts: ISelectedNft[];
  onChangeSelectedCollection: (c: string) => void;
  onChangeSelectedNft: (nft: ISelectedNft | null, selectAll?: boolean) => void;
  isLoading: boolean;
  multipleSelect?: boolean;
}) => {
  const theme = useTheme();
  const [selectAll, setSelectAll] = useState(false);
  const nftsFiltered = nfts.filter(item => item.address.toLowerCase() === selectedCollection.toLowerCase());
  const handleToggler = (selectAll: boolean) => {
    setSelectAll(selectAll);
    onChangeSelectedNft(null, selectAll);
  };
  useEffect(() => {
    if (selectedNfts.length != nftsFiltered.length) setSelectAll(false);
    else setSelectAll(true);
  }, [selectedNfts]);

  return (
    <Flex direction="column" gap="0.6rem">
      <Box w="200px" h="27px">
        <Select
          onChange={onChangeSelectedCollection}
          value={selectedCollection}
          textSize="xs"
          p="4px 7px"
          borderColor={theme.colors.gray0}
        >
          {collections.map((option, index) => (
            <Option
              value={option.address}
              key={option.address}
              style={{
                padding: index === collections.length - 1 ? "1px 7px 5px" : "2px 7px",
              }}
            >
              <Text size="xs" color="secondary">
                {option.name}
              </Text>
            </Option>
          ))}
        </Select>
      </Box>
      {multipleSelect && (
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="xs" color="gray0">
            {selectedNfts.length} selected
          </Text>
          <Flex direction="row" alignItems="center" gap="7px">
            <Text size="xs" color="gray0">
              Select all
            </Text>
            <Toggler value={selectAll} onChange={handleToggler} size="xs" />
          </Flex>
        </Flex>
      )}

      <NftImageWrapper>
        {isLoading ? (
          <>
            {Array.from(Array(4).keys()).map(loader => (
              <Loader key={loader} />
            ))}
          </>
        ) : nftsFiltered.length === 0 ? (
          <Empty>
            <img src={CyanLogoBlack} width={24} height={24} />
            <Text
              style={{
                fontSize: "8px",
              }}
              color="gray30"
              textAlign="center"
            >{`No NFTs found`}</Text>
          </Empty>
        ) : (
          nftsFiltered.map(item => (
            <ImageWrapper
              key={`${item.address}:${item.tokenId}`}
              onClick={() => onChangeSelectedNft(item)}
              isSelected={selectedNfts.some(
                nft => nft.address.toLowerCase() == item.address.toLowerCase() && nft.tokenId == item.tokenId,
              )}
            >
              <StyledNftImage src={item.imageUrl || NoImage} hasImage={!!item.imageUrl} />
            </ImageWrapper>
          ))
        )}
      </NftImageWrapper>
    </Flex>
  );
};

export const UserBulkNfts = ({
  selectedCollection,
  selectedNfts,
  onChangeSelectedCollection,
  onChangeSelectedNft,
  hideSelector,
}: {
  selectedCollection: string;
  selectedNfts: Array<ISelectedNft & { isDisabled?: boolean }>;
  onChangeSelectedCollection: (c: string) => void;
  onChangeSelectedNft: (nft: ISelectedNft) => void;
  hideSelector?: boolean;
}) => {
  const theme = useTheme();
  const collections = mainPoolCollections.filter(c =>
    selectedNfts.some(nft => nft.address.toLowerCase() === c.address),
  );
  return (
    <Flex direction="column" gap="0.4rem">
      {!hideSelector && (
        <Box w="200px" h="27px">
          <Select
            onChange={onChangeSelectedCollection}
            value={selectedCollection}
            textSize="xs"
            p="4px 7px"
            borderColor={theme.colors.gray0}
          >
            {collections.map((option, index) => (
              <Option
                value={option.address}
                key={option.address}
                style={{
                  padding: index === collections.length - 1 ? "0px 7px 2px" : "2px 7px",
                }}
              >
                <Text size="xs" color="secondary">
                  {option.name}
                </Text>
              </Option>
            ))}
          </Select>
        </Box>
      )}
      <NftImageWrapper>
        {selectedNfts.map(item => (
          <ItemWrapper key={`${item.address}:${item.tokenId}`}>
            <ImageWrapper
              isSelected={selectedNfts.some(
                selectedNft =>
                  selectedNft.address.toLowerCase() === item.address.toLowerCase() &&
                  selectedNft.tokenId === item.tokenId,
              )}
              hasError={item.isDisabled}
            >
              <StyledNftImage src={item.imageUrl || NoImage} hasImage={!!item.imageUrl} hasError={item.isDisabled} />
              <RemoveFromCart onClick={() => onChangeSelectedNft(item)}>
                <CloseX color={theme.colors.primary} height={8} width={8} />
              </RemoveFromCart>
            </ImageWrapper>
          </ItemWrapper>
        ))}
      </NftImageWrapper>
    </Flex>
  );
};
const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  gap: 5px;
  border-radius: 15px;
  border: ${({ theme }) => `3px solid ${theme.colors.gray10}`};
  padding: 0;
  margin: 0;
`;

const Loader = styled(SkeletonLine)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  gap: 5px;
  border-radius: 15px;
  border: ${({ theme }) => `3px solid ${theme.colors.gray10}`};
  padding: 0;
  margin: 0;
`;
