import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { POOL_IDS_MAPPED_BY_ADDRESS } from "@/config";

import { useApeCoinDataContext } from "./new/ApeCoinDataContext";

export const ApeCoinStatus = ({
  nft,
  isCard = true,
}: {
  nft: {
    tokenId: string;
    address: string;
  };
  isCard?: boolean;
}) => {
  const navigate = useNavigate();
  const { allNftStakes } = useApeCoinDataContext();
  const apeCoinBalance = useMemo(() => {
    const stake = allNftStakes.find(
      stake => stake.tokenId === nft.tokenId && stake.poolId === POOL_IDS_MAPPED_BY_ADDRESS[nft.address],
    );
    if (!stake) return null;
    return {
      stakedAmount: stake?.stakedAmount,
      earnedAmount: stake?.earnedAmount,
    };
  }, [allNftStakes]);
  if (!apeCoinBalance || apeCoinBalance.stakedAmount?.isZero()) return null;
  if (!isCard) {
    return (
      <StyledImg
        src={ApeCoin}
        alt="ApeCoin"
        onClick={e => {
          e.stopPropagation();
          navigate("/ape-coin/rewards");
        }}
      />
    );
  }
  return (
    <ApeCoinRowBox
      direction="column"
      onClick={e => {
        e.stopPropagation();
        navigate("/ape-coin/rewards");
      }}
    >
      <StyledImg src={ApeCoin} alt="ApeCoin" />
    </ApeCoinRowBox>
  );
};
const ApeCoinRowBox = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  padding: 0.3rem 0.4rem;
`;

const StyledImg = styled.img`
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  max-height: 16px;
  max-width: 16px;
  border-radius: 50%;
`;
