import { JsonRpcSigner } from "@ethersproject/providers";
import { BigNumber, utils } from "ethers";

import { SupportedChainId } from "@/constants/chains";
import { INftType } from "@/types";

type ILoanOfferSignData = {
  contractAddress: string;
  tokenId?: string;
  amount: BigNumber;
  itemType: INftType;
  collectionSignature: string;
  currencyAddress: string;
  interestRate: number;
  term: number;
  expiryDate: number;
  maxUsageCount: number;
  isExtendable: boolean;
  signedDate: number;
  tokenAmount: number;
  chainId: SupportedChainId;
};

export const signOfferData = async (args: ILoanOfferSignData, signer: JsonRpcSigner) => {
  const {
    contractAddress,
    tokenId,
    collectionSignature,
    currencyAddress,
    amount,
    itemType,
    interestRate,
    term,
    expiryDate,
    maxUsageCount,
    isExtendable,
    signedDate,
    tokenAmount,
    chainId,
  } = args;
  let itemHash;
  if (tokenId) {
    itemHash = utils.solidityKeccak256(
      ["address", "uint256", "uint256", "uint8", "bytes"],
      [contractAddress, tokenId, tokenAmount, itemType, collectionSignature],
    );
  }
  itemHash = utils.solidityKeccak256(
    ["address", "uint256", "uint8", "bytes"],
    [contractAddress, tokenAmount, itemType, collectionSignature],
  );
  const planHash = utils.solidityKeccak256(
    ["address", "uint256", "uint32", "uint32"],
    [currencyAddress, amount, interestRate, term],
  );
  const lenderSigHash = utils.solidityKeccak256(
    ["uint256", "uint256", "uint32", "bool"],
    [signedDate, expiryDate, maxUsageCount, isExtendable],
  );
  const msgHash = utils.solidityKeccak256(
    ["bytes", "bytes", "bytes", "uint256"],
    [itemHash, planHash, lenderSigHash, chainId],
  );
  return await signer.signMessage(utils.arrayify(msgHash));
};
