import { SupportedChainId } from "@/constants/chains";
import { INftType } from "@/types";

export type ICollectionFloorAsk = {
  id: string;
  sourceDomain: string;
  sourceName: string;
  price?: {
    currency: {
      contract: string;
      name: string;
      symbol: string;
      decimals: number;
    };
    amount: {
      raw: string;
      decimal: number;
      usd?: string;
      native: number;
    };
  };
};

export interface ICollectionBe {
  traitBoosters: Array<{
    traitName: string;
    floorPriceMultiplier: number;
  }>;
  vaults: Array<{
    id: number;
    name: string;
    colorCode: string;
    contractAddress: string;
    chainId: number;
    isBnplAllowed: boolean;
    isPawnAllowed: boolean;
  }>;
  slug: string;
  name: string;
  createdAt: string;
  image?: string;
  banner?: string;
  discordUrl?: string;
  externalUrl?: string;
  description?: string;
  onSaleCount: string;
  tokenCount: string;
  ownerCount: string;
  tokenType: INftType;
  floorAsk: {
    id: string;
    sourceDomain: string;
    sourceName: string;
    price?: {
      currency: {
        contract: string;
        name: string;
        symbol: string;
        decimals: number;
      };
      amount: {
        raw: string;
        decimal: number;
        usd?: string;
        native: number;
      };
    };
    token?: {
      image: string | null;
      tokenId: string;
    };
  };
  topBid: {
    price?: {
      currency: {
        contract: string;
        name: string;
        symbol: string;
        decimals: number;
      };
      amount: {
        raw: string;
        decimal: number;
        usd?: string;
        native: number;
      };
    };
  };
  cyanSignature: string;
  volume: {
    "1day": number | null;
    "7day": number | null;
    "30day": number | null;
    allTime: number | null;
  };
  volumeChange: {
    "1day": number | null;
    "7day": number | null;
    "30day": number | null;
  };
  floorSale: {
    "1day": number | null;
    "7day": number | null;
    "30day": number | null;
  };
  floorSaleChange: {
    "1day": number | null;
    "7day": number | null;
    "30day": number | null;
  };
  address: string;
  symbol: string;
  chainId: number;
  twitterAccount: string | null;
  tags: number[];
  floorPrice1dBefore: string;
  floorPrice7dBefore: string;
  floorPrice30dBefore: string;
}

export type FnFetchCollections = ({
  chainId,
  abortSignal,
}: {
  chainId?: SupportedChainId;
  abortSignal?: AbortSignal;
}) => Promise<Array<ICollectionBe>>;

export type ICollectionTag = {
  id: number;
  name: string;
};

export type ICollecionAttributes = {
  attributes: Array<{
    key: string;
    values: ICollectionAttributeValue[];
  }>;
  supportedCurrencies: Array<{
    symbol: string;
    address: string;
  }>;
};

export type ICollectionAttribute = {
  key: string;
  values: ICollectionAttributeValue[];
};

export type ICollectionAttributeValue = {
  value: string;
  count: number;
  floorAskPrice?: {
    currency: {
      contract: string;
      symbol: string;
    };
    amount: {
      raw: string;
      decimal: number;
    };
  };
};

export enum NftSortAttributes {
  "floorAskPrice" = "Floor Price",
}

export type ISearchCollectionResult = {
  contract: string;
  image: string;
  name: string;
  slug: string;
}[];
