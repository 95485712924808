import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme/Box";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Card, SkeletonLine, SystemMessage, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";

import { ItemsMetadataLoading } from "@/components/PlanCreation/ItemsMetadataLoading";

export const TokenModalLoading: React.FC<{
  item: {
    tokenId: string;
    collectionName: string;
    imageUrl?: string | null;
  };
  error?: { message: string; title: string; description?: string };
}> = ({ item, error }) => {
  const theme = useTheme();

  return (
    <Flex gap="12px" direction="column">
      <ItemsMetadataLoading nft={item} />
      {error && (
        <SystemMessage variant="error" title={error.title} msg={error.message} description={error.description} />
      )}
      <Card p="8px">
        <Flex direction="column" gap="5px">
          <Text size="sm" weight="600" color="secondary">
            {`Loan Length`}
          </Text>
          <SkeletonLineMobile w="100%" h="25px" />
        </Flex>
      </Card>
      <Card p="8px">
        <Flex direction="column" gap="5px">
          <Text size="sm" weight="600" color="secondary">
            {`Down payment`}
          </Text>
          <SkeletonLineMobile w="100%" h="25px" />
        </Flex>
      </Card>
      <Card p="8px">
        <Flex direction="column" gap="5px">
          <Text size="sm" weight="600" color="secondary">
            {`Currency`}
          </Text>
          <SkeletonLineMobile w="100%" h="25px" />
        </Flex>
      </Card>
      <Flex direction="column">
        <SkeletonLineMobile w="100%" h="125px" borderRadius="10px" />
      </Flex>
      <Card p="8px">
        <Flex direction="column" gap="5px">
          <Flex alignItems="center" gap="4px">
            <Text size="sm" weight="600" color="secondary">
              {`Auto-repayments`}
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.secondary} />
              <TooltipText showArrow position="top" top="-75px" right="-70px" style={{ width: "150px" }}>
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  <div>{`You can provide Cyan permission to automatically withdraw from your wallet every month.`}</div>
                </Text>
              </TooltipText>
            </Tooltip>
          </Flex>
          <SkeletonLineMobile w="100%" h="25px" />
        </Flex>
      </Card>
      <StyledCard p={"15px"}>
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4.3px" w="100%">
            <SkeletonLine w="180px" h="16px" />
            <SkeletonLine w="100px" h="22px" />
          </Flex>
          <Box w="120px">
            <Button disabled>{`Start`}</Button>
          </Box>
        </Flex>
      </StyledCard>
    </Flex>
  );
};

const StyledCard = styled(Card)`
  :hover {
    border-color: ${({ theme }) => theme.colors.gray20} !important;
  }
`;

const SkeletonLineMobile = styled(SkeletonLine)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {\
    width: 100%;
  }
`;
