import { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Option, Select, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { shortenAddress } from "@/utils";

export const ReleaseWalletSelector = ({
  setReleaseWallet,
  releaseWallet,
}: {
  releaseWallet: string;
  setReleaseWallet: (a: string) => void;
}) => {
  const cyanWallet = useCyanWallet();
  const { account } = useWeb3React();
  const theme = useTheme();
  return (
    <Flex justifyContent="space-between" p="0 2px">
      <Flex alignItems="center" gap="4px">
        <Text size="md" weight="600" color="secondary">
          {`Wallet for APE release`}
        </Text>
        <Tooltip>
          <HelpCircle height={16} width={16} color={theme.colors.secondary} />
          <TooltipText showArrow position="top" top="-95px" right="-50px" style={{ width: "100px" }}>
            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
              <div>{`Select which wallet to release your ApeCoin to. Only straight-type staked APEs will go to chosen wallet.`}</div>
            </Text>
          </TooltipText>
        </Tooltip>
      </Flex>
      <Box w="130px">
        <Select onChange={setReleaseWallet} value={releaseWallet} textSize="xxs" p="0.3rem 0.5rem 0.4rem">
          {account && (
            <Option
              value={account}
              style={{
                padding: "0.2rem 0.5rem 0.3rem",
              }}
            >
              <Text size="xxs" color="secondary">
                {`${`MAIN`} (${shortenAddress(account, 2)})`}
              </Text>
            </Option>
          )}
          {cyanWallet && (
            <Option
              value={cyanWallet.walletAddress}
              style={{
                padding: "0.2rem 0.5rem 0.3rem",
              }}
            >
              <Text size="xxs" color="secondary">
                {`${`CYAN`} (${shortenAddress(cyanWallet.walletAddress, 2)})`}
              </Text>
            </Option>
          )}
        </Select>
      </Box>
    </Flex>
  );
};
