import { useCyanWallet } from "@usecyan/cyan-wallet";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { getCyanWalletLockedDate } from "@/utils/contract";

export const useGetCyanWalletLock = () => {
  const cyanWallet = useCyanWallet();
  const { provider } = useWeb3React();

  const getLockedDate = async (vaultAddress: string) => {
    if (provider && cyanWallet) {
      return await getCyanWalletLockedDate({ cyanWallet: cyanWallet.walletAddress, vaultAddress, provider });
    }
    return null;
  };
  return {
    getLockedDate,
  };
};
