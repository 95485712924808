import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { useAsync } from "react-async-hook";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme";
import {
  Button,
  SwitchButton,
  SwitchButtonGroup,
  SystemMessage,
  Text,
  Tooltip,
  TooltipText,
  getCurrencyLogoSrc,
  useModal,
} from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";
import { CyanC } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { usePersistedWalletTypeForRelease } from "@/hooks";
import { useGetCyanWalletLock } from "@/hooks/useGetCyanWalletLock";
import { bigNumToFloat, displayInUSD, numberWithCommas, shortenAddress } from "@/utils";
import { getTokenPriceFromVaultContract } from "@/utils/contract";
import { IMappedError } from "@/utils/error/msgs";

import { useVaultDataContext } from "../../VaultDataProvider";
import { VaultContractAbiNames, VaultDecimalFormatMap } from "../../types";
import { UnstakingProgressModal } from "./UnstakingProgressModal";

export const UnstakingModal = ({
  unstakingAmount,
  vault,
  selectedWallet,
  triggeredError,
  setUnstakingAmount,
  setAmountInToken,
}: {
  unstakingAmount: number;
  vault: IVault;
  selectedWallet: string;
  triggeredError?: IMappedError;
  setUnstakingAmount: (a: string) => void;
  setAmountInToken: (a: string) => void;
}) => {
  const theme = useTheme();
  const cyanWallet = useCyanWallet();
  const { setModalContent } = useModal();
  const { usdPrice } = useAppContext();
  const { provider, account } = useWeb3React();
  const { setVaults, vaults: vaultsNonFiltered } = useVaultDataContext();
  const [isPriceChanged, setIsPriceChanged] = useState(false);
  const { getLockedDate } = useGetCyanWalletLock();
  const { setWalletTypeForRelease, walletTypeForRelease } = usePersistedWalletTypeForRelease();

  const {
    loading: loadingLock,
    result: { isCyanWalletLocked, lockedDate } = { isCyanWalletLocked: false, lockedDate: 0 },
  } = useAsync<{ isCyanWalletLocked: boolean; lockedDate: number }>(async () => {
    if (cyanWallet && vault.symbol.startsWith("CV")) {
      const date = await getLockedDate(vault.contractAddress);
      const lockedDate = date && date.gt(0) ? dayjs.unix(date.toNumber()).diff(dayjs(), "days", true) : 0;
      const isCyanWalletLocked =
        selectedWallet.toLowerCase() === cyanWallet.walletAddress.toLowerCase() &&
        selectedWallet !== "" &&
        lockedDate > 0;
      return {
        lockedDate: lockedDate,
        isCyanWalletLocked,
      };
    }
    return {
      lockedDate: 0,
      isCyanWalletLocked: false,
    };
  }, [cyanWallet, selectedWallet, vault]);

  const unstake = async () => {
    if (!provider || !account) return;
    if (!isPriceChanged) {
      const currentPrice = await getTokenPriceFromVaultContract(
        vault.abiName,
        vault.contractAddress,
        provider,
        vault.decimals,
      );
      if (!BigNumber.from(currentPrice).eq(BigNumber.from(vault.price))) {
        setIsPriceChanged(true);
        setVaults(
          vaultsNonFiltered.map(v => {
            if (v.contractAddress === vault.contractAddress) {
              return {
                ...v,
                price: BigNumber.from(currentPrice),
                priceUsd: bigNumToFloat(currentPrice || 0, vault.decimals) * usdPrice[vault.currency],
              };
            }
            return v;
          }),
        );
        return;
      }
    }
    const selectedReleaseWallet = cyanWallet && walletTypeForRelease === "cyan" ? cyanWallet.walletAddress : account;
    setModalContent({
      title: `Processing Swap`,
      content: (
        <UnstakingProgressModal
          unstakingAmount={unstakingAmount}
          selectedWallet={selectedWallet}
          vault={vault}
          setUnstakingAmount={setUnstakingAmount}
          setAmountInToken={setAmountInToken}
          selectedReleaseWallet={selectedReleaseWallet}
        />
      ),
    });
  };

  const selectedWalletLocked = useMemo(() => {
    return (
      (vault.abiName === VaultContractAbiNames.CyanVaultV2 || vault.abiName === VaultContractAbiNames.CyanVaultV2_1) &&
      isCyanWalletLocked
    );
  }, [isCyanWalletLocked, selectedWallet, cyanWallet]);

  const formatNumber = useMemo(() => {
    return VaultDecimalFormatMap.get(vault.decimals) || 5;
  }, [vault.decimals]);
  const unstakingAmountInToken = useMemo(() => {
    const amount = (unstakingAmount * vault.priceUsd) / usdPrice[vault.currency];
    return Math.trunc(amount * Math.pow(10, formatNumber)) / Math.pow(10, formatNumber);
  }, [unstakingAmount]);

  return (
    <Flex direction="column" gap="1.5rem">
      {cyanWallet && account && (
        <Flex direction="column" gap="10px">
          <Flex alignItems="center" gap="4px">
            <Text weight="600" color="gray0" size="md">
              {`Wallet for token release`}
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.gray0} />
              <TooltipText showArrow position="bottom" top="30px" right="-50px" style={{ width: "100px" }}>
                <Text size="xxs" color="primary" weight="500">
                  <div> Unstaked tokens will be released to the wallet you choose.</div>
                </Text>
              </TooltipText>
            </Tooltip>
          </Flex>
          <SwitchButtonGroup<string>
            activeBackground={theme.colors.cyan}
            activeTextColor={theme.colors.black}
            borderColor={theme.colors.gray10}
            value={walletTypeForRelease === "main" ? account : cyanWallet.walletAddress}
            onChange={v => {
              setWalletTypeForRelease(v === account ? "main" : "cyan");
            }}
            hover
          >
            <SwitchButton height="22px" value={account}>{`Main (${shortenAddress(account)})`}</SwitchButton>
            <SwitchButton height="22px" value={cyanWallet.walletAddress}>{`Cyan (${shortenAddress(
              cyanWallet.walletAddress,
            )})`}</SwitchButton>
          </SwitchButtonGroup>
        </Flex>
      )}
      {selectedWalletLocked && (
        <SystemMessage
          variant="error"
          title={`Withdrawal Not Possible`}
          msg={`Vault token deposit period has not completed yet.`}
          description={`Deposits into vaults are locked for ${
            parseInt(vault.withdrawLockTerm.toString()) / (24 * 60 * 60)
          } days to protect liquidity.
          Please wait for ${lockedDate.toPrecision(1)} days until the deposit period completes.`}
        />
      )}
      {isPriceChanged && (
        <SystemMessage variant="warning" title={`Price change`} msg={`The rate has changed, please confirm above.`} />
      )}
      {triggeredError && (
        <SystemMessage
          variant="error"
          title={triggeredError.title}
          msg={triggeredError.msg}
          description={triggeredError.description}
        />
      )}
      <Flex direction="column" gap="2.5rem">
        <Flex direction="column" gap="0.5rem">
          <Text weight="600" color="gray0" size="md">
            {`You pay`}
          </Text>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Text color="secondary" size="xxl">
              {`${numberWithCommas(unstakingAmount, 2)} ${vault.symbol}`}
            </Text>
            <VaultImageWrapper style={{ background: vault.colorCode }}>
              <VaultImage src={CyanC} alt={vault.name} />
            </VaultImageWrapper>
          </Flex>
          <Text weight="500" color="gray0" size="sm">
            {displayInUSD(Number(unstakingAmount) * vault.priceUsd)}
          </Text>
        </Flex>
        <Flex direction="column" gap="0.5rem">
          <Text weight="600" color="gray0" size="md">
            {`You receive`}
          </Text>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Text color="secondary" size="xxl">
              {`${numberWithCommas(unstakingAmountInToken, 2)} ${vault.currency}`}
            </Text>
            <StyledImg src={getCurrencyLogoSrc(vault.currency)} alt={vault.name} />
          </Flex>
          <Text weight="500" color="gray0" size="sm">
            {displayInUSD(unstakingAmount === 0 ? 0.0 : unstakingAmountInToken * usdPrice[vault.currency])}
          </Text>
        </Flex>
      </Flex>
      <InfoBox pt="1.5rem" direction="column" gap="0.8rem">
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <Text color="gray0" size="sm" weight="500">
            {`Exchange rate`}
          </Text>
          <Text color="secondary" size="sm" weight="500">
            {`1 ${vault.symbol} = ${(vault.priceUsd / usdPrice[vault.currency]).toFixed(formatNumber)} ${
              vault.currency
            }`}
          </Text>
        </Flex>
      </InfoBox>
      <UnstakeButton
        disabled={loadingLock || selectedWalletLocked}
        onClick={unstake}
      >{`Confirm Unstake`}</UnstakeButton>{" "}
    </Flex>
  );
};

const StyledImg = styled.img`
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
`;
const VaultImageWrapper = styled.div`
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const VaultImage = styled.img`
  height: 26px;
  width: 26px;
`;

const InfoBox = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
`;

const UnstakeButton = styled(Button)`
  padding: 1rem;
  background: #4d00ee !important;
  border-color: #4d00ee !important;
  height: 50px;
  transition: all 0.2s ease-in-out;
  :hover {
    background: #5808ff !important;
    border-color: #5808ff !important;
  }
`;
