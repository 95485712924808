import { Flex } from "@cyanco/components/theme/Flex";
import { Hidden, SwitchButton, SwitchButtonGroup, Text } from "@cyanco/components/theme/v3";
import { Grid, List } from "@cyanco/components/theme/v3/icons";

import { jumpToLink } from "@/utils";

import { PoolsViewStyle, useApeCoinContext } from "../../ApeCoinPageContext";
import { StickySelectorWrapper } from "../common";
import { GridView } from "./GridView";
import { ListView } from "./ListView";

export const PoolsView = () => {
  const { selectedPoolsView, setSelectedPoolsView } = useApeCoinContext();
  return (
    <Flex direction="column">
      <StickySelectorWrapper
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        pb="1rem"
        style={{
          maxHeight: "28px",
        }}
      >
        <Hidden tabletDown>
          <Text size="lg" color="secondary" weight="500">
            Automatically compound your $APE, or get FREE $APE.{" "}
            <Text
              size="lg"
              color="secondary"
              weight="500"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => jumpToLink("https://docs.usecyan.com/docs/staking-apecoin")}
            >
              Read more!
            </Text>
          </Text>
        </Hidden>
        <SwitchButtonGroup<PoolsViewStyle> value={selectedPoolsView} onChange={setSelectedPoolsView} hover>
          <SwitchButton value={PoolsViewStyle.list} height="22px">
            <List height={10} width={14} />
          </SwitchButton>
          <SwitchButton value={PoolsViewStyle.grid} height="22px">
            <Grid height={10} width={10} />
          </SwitchButton>
        </SwitchButtonGroup>
      </StickySelectorWrapper>
      {selectedPoolsView === PoolsViewStyle.grid && <GridView />}
      {selectedPoolsView === PoolsViewStyle.list && <ListView />}
    </Flex>
  );
};
