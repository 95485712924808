import { useEffect, useState } from "react";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound, useModal } from "@cyanco/components/theme/v3";

import { IPeerPlan, PeerPlanStatuses } from "@/apis/p2p/types";

import { usePeerPlans } from "../../AccountDataContext";
import { ContainerBox, Header, ListViewBox } from "../../index";
import { PeerPlanRow, PlanListHeader } from "./PlanListRow";
import { DefaultedPlan } from "./modals/DefaultedPlan";
import { LivePlan } from "./modals/LivePlan";

export const Plans: React.FC = () => {
  const { setModalContent } = useModal();
  const { userGivenPeerPlans } = usePeerPlans();
  const [sortedPlans, setSortedPlans] = useState<IPeerPlan[]>(
    userGivenPeerPlans.filter(
      plan => plan.status === PeerPlanStatuses.ACTIVE || plan.status === PeerPlanStatuses.DEFAULTED,
    ),
  );
  const complete = (plan: IPeerPlan) => {
    if (plan.status === PeerPlanStatuses.DEFAULTED) {
      setModalContent({
        title: `Loan liquidation`,
        content: <DefaultedPlan plan={plan} />,
      });
    }
    if (plan.status === PeerPlanStatuses.ACTIVE) {
      setModalContent({
        title: `Edit loan`,
        content: <LivePlan plan={plan} />,
      });
    }
  };

  useEffect(() => {
    setSortedPlans(
      userGivenPeerPlans.filter(
        plan => plan.status === PeerPlanStatuses.ACTIVE || plan.status === PeerPlanStatuses.DEFAULTED,
      ),
    );
  }, [userGivenPeerPlans]);

  const updateSortedPlans = (plans: IPeerPlan[]) => {
    setSortedPlans([...plans]);
  };

  return (
    <ListContainer>
      <ListViewBox>
        <Header>
          <PlanListHeader
            totalItem={sortedPlans.length}
            updateSortedPlans={updateSortedPlans}
            sortedPlans={sortedPlans}
          />
        </Header>
        <ContainerBox>
          {sortedPlans.length === 0 && <NotFound msg={`No plans found`} />}
          <Container role="grid" $showByGrid={false}>
            {sortedPlans.map(plan => (
              <PeerPlanRow
                key={plan.planId}
                position={plan}
                onClick={() => {
                  complete(plan);
                }}
              />
            ))}
          </Container>
        </ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};

const Container = styled.div<{ $showByGrid: boolean }>`
  ${({ $showByGrid }) => {
    if ($showByGrid) {
      return ` display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  ${getStyleWithMediaQuery("grid-column-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-row-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.desktop]: "repeat(auto-fill, minmax(200px, 1fr))" },
    { [breakpoints.tablet]: "repeat(auto-fill, minmax(180px, 1fr))" },
    { [breakpoints.mobile]: "repeat(auto-fill, minmax(140px, 1fr))" },
  ])}; `;
    } else {
      return ` display: flex;
      flex-direction: column;
      width: 100%;
   @media only screen and (max-width: 414px) {
     border: none;
     border-radius: 20px;
     padding: 0;
   }
   > * {
     &:last-child {
       border-bottom-left-radius: 20px;
       border-bottom-right-radius: 20px;
     }
   }`;
    }
  }}
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;
