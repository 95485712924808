import { useState } from "react";

import { SubTitle } from "@cyanco/components/theme";

import { IVaultLiquidatedNft } from "@/apis/vault/admin/types";
import { IVault } from "@/apis/vault/types";

import { VaultDetailSectionContainer } from "../VaultDetailsPrivate";
import { MobileFriendlyWrapper, TabButton, TabContainer, TabText } from "../VaultLoans";
import { CurrentVaultLiquidatedNfts } from "./Current";
import { SoldNftsTable } from "./Historical";

enum Tabs {
  current,
  historical,
}

export interface IVaultLiquidatedNftTable {
  vault: IVault;
  loading: boolean;
  selectedNfts: IVaultLiquidatedNft[];
  setSelectedNfts: React.Dispatch<React.SetStateAction<IVaultLiquidatedNft[]>>;
}

export const VaultLiquidatedNfts = (props: IVaultLiquidatedNftTable) => {
  const [selectedTab, setSelectedTab] = useState(Tabs.current);
  return (
    <VaultDetailSectionContainer>
      <MobileFriendlyWrapper w="100%" justifyContent="space-between" alignItems="center">
        <SubTitle>Return funds to Pool</SubTitle>
        <TabContainer justifyContent="space-between">
          <TabButton
            active={selectedTab == Tabs.current}
            variant="ghost"
            onClick={() => {
              setSelectedTab(Tabs.current);
            }}
          >
            <TabText
              weight="600"
              size="sm"
              color={selectedTab === Tabs.current ? "secondary" : "gray0"}
              textWrap={false}
            >
              Current
            </TabText>
          </TabButton>
          <TabButton
            active={selectedTab == Tabs.historical}
            variant="ghost"
            onClick={() => {
              setSelectedTab(Tabs.historical);
            }}
          >
            <TabText
              weight="600"
              size="sm"
              color={selectedTab === Tabs.historical ? "secondary" : "gray0"}
              textWrap={false}
            >
              Historical
            </TabText>
          </TabButton>
        </TabContainer>
      </MobileFriendlyWrapper>
      <div
        style={{
          display: selectedTab === Tabs.historical ? "block" : "none",
        }}
      >
        <SoldNftsTable vault={props.vault} loading={props.loading} />
      </div>
      <div
        style={{
          display: selectedTab === Tabs.current ? "block" : "none",
        }}
      >
        <CurrentVaultLiquidatedNfts {...props} />
      </div>
    </VaultDetailSectionContainer>
  );
};
