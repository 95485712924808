import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Button, SystemMessage, Text, getCurrencyLogoSrc, useModal } from "@cyanco/components/theme/v3";
import { CyanC } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { bigNumToFloat, displayInUSD, numberWithCommas } from "@/utils";
import { getTokenPriceFromVaultContract } from "@/utils/contract";
import { IMappedError } from "@/utils/error/msgs";

import { useVaultDataContext } from "../../VaultDataProvider";
import { VaultContractAbiNames, VaultDecimalFormatMap } from "../../types";
import { StakingModalProgress } from "./StakingProgressModal";
import { StyledLink } from "./common";

export const StakingModal = ({
  stakingAmount,
  vault,
  selectedWallet,
  triggeredError,
  setStakingAmount,
}: {
  stakingAmount: number;
  vault: IVault;
  selectedWallet: string;
  triggeredError?: IMappedError;
  setStakingAmount: (a: string) => void;
}) => {
  const { setModalContent } = useModal();
  const { usdPrice } = useAppContext();
  const { provider } = useWeb3React();
  const { setVaults, vaults: vaultsNonFiltered } = useVaultDataContext();
  const [isPriceChanged, setIsPriceChanged] = useState(false);

  const stake = async () => {
    if (!provider) return;
    if (!isPriceChanged) {
      const currentPrice = await getTokenPriceFromVaultContract(
        vault.abiName,
        vault.contractAddress,
        provider,
        vault.decimals,
      );
      if (!BigNumber.from(currentPrice).eq(BigNumber.from(vault.price))) {
        setIsPriceChanged(true);
        setVaults(
          vaultsNonFiltered.map(v => {
            if (v.contractAddress === vault.contractAddress) {
              return {
                ...v,
                price: BigNumber.from(currentPrice),
                priceUsd: bigNumToFloat(currentPrice || 0, vault.decimals) * usdPrice[vault.currency],
              };
            }
            return v;
          }),
        );
        return;
      }
    }
    setModalContent({
      title: `Processing Swap`,
      content: (
        <StakingModalProgress
          stakingAmount={stakingAmount}
          selectedWallet={selectedWallet}
          vault={vault}
          setStakingAmount={setStakingAmount}
        />
      ),
    });
  };

  const formatNumber = useMemo(() => {
    return VaultDecimalFormatMap.get(vault.decimals) || 5;
  }, [vault.decimals]);
  const stakingAmountInToken = useMemo(() => {
    const amount = (stakingAmount / vault.priceUsd) * usdPrice[vault.currency] * (1 - vault.transactionFee / 10000);
    return Math.trunc(amount * Math.pow(10, formatNumber)) / Math.pow(10, formatNumber);
  }, [stakingAmount, vault]);

  return (
    <Flex direction="column" gap="1.5rem">
      {isPriceChanged && (
        <SystemMessage variant="warning" title={`Price change`} msg={`The rate has changed, please confirm below.`} />
      )}
      {triggeredError && (
        <SystemMessage
          variant="error"
          title={triggeredError.title}
          msg={triggeredError.msg}
          description={triggeredError.description}
        />
      )}
      <Flex direction="column" gap="2.5rem">
        <Flex direction="column" gap="0.5rem">
          <Text weight="600" color="gray0" size="md">
            {`You pay`}
          </Text>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Text color="secondary" size="xxl">
              {`${numberWithCommas(stakingAmount, 3)} ${vault.currency}`}
            </Text>
            <StyledImg src={getCurrencyLogoSrc(vault.currency)} alt={vault.name} />
          </Flex>
          <Text weight="500" color="gray0" size="sm">
            {displayInUSD(Number(stakingAmount) * usdPrice[vault.currency])}
          </Text>
        </Flex>
        <Flex direction="column" gap="0.5rem">
          <Text weight="600" color="gray0" size="md">
            {`You receive`}
          </Text>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Text color="secondary" size="xxl">
              {`${numberWithCommas(stakingAmountInToken, 3)} ${vault.symbol}`}
            </Text>
            <VaultImageWrapper style={{ background: vault.colorCode }}>
              <VaultImage src={CyanC} alt={vault.name} />
            </VaultImageWrapper>
          </Flex>
          <Text weight="500" color="gray0" size="sm">
            {displayInUSD(stakingAmount === 0 ? 0.0 : stakingAmountInToken * vault.priceUsd)}
          </Text>
        </Flex>
      </Flex>
      <InfoBox pt="1.5rem" direction="column" gap="0.8rem">
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <Text color="gray0" size="sm" weight="500">
            {`Exchange rate`}
          </Text>
          <Text color="secondary" size="sm" weight="500">
            {`1 ${vault.currency} = ${(usdPrice[vault.currency] / vault.priceUsd).toFixed(formatNumber)} ${
              vault.symbol
            }`}
          </Text>
        </Flex>
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <Text color="gray0" size="sm" weight="500">
            {`Deposit fee`}
          </Text>
          <Text color="secondary" size="sm" weight="500">
            {`${(Number(stakingAmount) * (vault.transactionFee / 10000)).toFixed(formatNumber)} ${vault.currency}`}
          </Text>
        </Flex>
      </InfoBox>
      {(vault.abiName === VaultContractAbiNames.CyanVaultV2 || vault.abiName === VaultContractAbiNames.CyanVaultV2_1) &&
        selectedWallet !== "" && (
          <InfoBox pt="1.5rem" direction="column" gap="0.8rem">
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              <Text color="gray0" size="sm" weight="500">
                {`By confirming the deposit below, you acknowledge the deposit will be locked for ${
                  parseInt(vault.withdrawLockTerm.toString()) / (24 * 60 * 60)
                } days. Learn more `}
                <StyledLink href="https://docs.usecyan.com/docs/cyan-vaults" target="_blank">
                  {`here.`}
                </StyledLink>
              </Text>
            </Flex>
          </InfoBox>
        )}
      <StakeButton onClick={stake}>{`Confirm Stake`}</StakeButton>{" "}
    </Flex>
  );
};

const StyledImg = styled.img`
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
`;
const VaultImageWrapper = styled.div`
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const VaultImage = styled.img`
  height: 26px;
  width: 26px;
`;

const InfoBox = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
`;

const StakeButton = styled(Button)`
  padding: 1rem;
  height: 50px;
`;
