export enum SupportedMarketPlaces {
  OPENSEA = "OpenSea",
  LOOKSRARE = "Looksrare",
  X2Y2 = "X2Y2",
  ELEMENT = "Element",
  SUDOSWAP = "Sudoswap",
  NFTX = "NFTX",
  BLUR = "Blur",
  RARIBLE = "Rarible",
  PASS = "Pass.XYZ",
  MAGICEDEN = "Magic Eden",
  MINTIFY = "Mintify",
}
const SupportedMarketPlacesKeys = Object.keys(SupportedMarketPlaces) as Array<keyof typeof SupportedMarketPlaces>;
export const SupportedMarketPlacesList = SupportedMarketPlacesKeys.map<string>(key =>
  SupportedMarketPlaces[key].toString(),
);
export type ISupportedMarketPlace =
  | SupportedMarketPlaces.OPENSEA
  | SupportedMarketPlaces.LOOKSRARE
  | SupportedMarketPlaces.X2Y2
  | SupportedMarketPlaces.ELEMENT
  | SupportedMarketPlaces.SUDOSWAP
  | SupportedMarketPlaces.NFTX
  | SupportedMarketPlaces.BLUR
  | SupportedMarketPlaces.RARIBLE
  | SupportedMarketPlaces.PASS
  | SupportedMarketPlaces.MAGICEDEN
  | SupportedMarketPlaces.MINTIFY;
