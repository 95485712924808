import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { FC } from "react";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, NotFound, SkeletonLine, SubTitle, Text } from "@cyanco/components/theme/v3";

import { IVaultAdditionalMetrics } from "@/apis/vault/types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { bigNumToFloat, formatCompactNumber, numberWithCommas } from "@/utils";
import { isMobile } from "@/utils/userAgent";

import { CHART_HEIGHT } from ".";

export const CashflowBar: FC<{
  cashflowData: IVaultAdditionalMetrics["cashflowData"];
  decimals: number;
  currency: string;
  loading: boolean;
}> = ({ cashflowData, decimals = 5, currency, loading }) => {
  const theme = useTheme();
  const chartData = cashflowData.map(d => ({
    name: dayjs(d.month).format("MMMM"),
    interest: bigNumToFloat(BigNumber.from(d.interestAmount), decimals),
    principal: bigNumToFloat(BigNumber.from(d.principalAmount), decimals),
  }));

  const maxPrincipal = chartData.reduce((acc, curr) => Math.max(acc, curr.principal), 0);

  const minPointSize = maxPrincipal + 3 < (isMobile ? CHART_HEIGHT.mobile : CHART_HEIGHT.desktop) ? 3 : undefined;
  const formatNumber = PlanCreationDecimalFormatMap.get(decimals) || 4;

  return (
    <Flex direction="column" gap="1.8rem">
      <SubTitle>Vault Cashflows</SubTitle>
      {loading ? (
        <SkeletonLine h={isMobile ? `${CHART_HEIGHT.mobile}px` : `${CHART_HEIGHT.desktop}px`} w="100%" />
      ) : (
        <Flex
          direction="column"
          w="100%"
          h={isMobile ? `${CHART_HEIGHT.mobile}px` : `${CHART_HEIGHT.desktop}px`}
          alignItems="center"
          justifyContent="center"
        >
          {chartData.length === 0 ? (
            <NotFound msg="No data found" />
          ) : (
            <ChartContainer alignItems="center" w="100%" gap="1rem">
              <Hidden laptopSDown>
                <Flex
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                  w="24px"
                >
                  <Text color="secondary" size="sm" textWrap={false} weight="600">
                    {`Amount in ${currency}`}
                  </Text>
                </Flex>
              </Hidden>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  maxBarSize={40}
                  barGap={2}
                >
                  <Legend
                    align="right"
                    verticalAlign="top"
                    iconType="circle"
                    iconSize={0}
                    formatter={(value, entry) => {
                      const { color } = entry;
                      return (
                        <Flex alignItems="center" gap="7px" ml="2rem">
                          <LegendCircle bg={color} />
                          <Text
                            size="lg"
                            style={{ color: color ?? theme.colors.white, textTransform: "capitalize" }}
                            weight="600"
                          >
                            {value}
                          </Text>
                        </Flex>
                      );
                    }}
                  />
                  <XAxis
                    dataKey="name"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Inter",
                      fontWeight: 600,
                    }}
                    tickLine={false}
                    dy={10}
                    allowDataOverflow
                    strokeWidth={0}
                  />
                  <YAxis
                    tickLine={false}
                    strokeWidth={0}
                    dy={-5}
                    style={{
                      fontSize: "14px",
                      fontFamily: "Inter",
                      fontWeight: 600,
                    }}
                    tickFormatter={value => `${formatCompactNumber(value, 0)} ${currency}`}
                    interval={0}
                    tickCount={5}
                  />
                  <Tooltip
                    cursor={{ fill: theme.colors.gray10 }}
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        return (
                          <TooltipBox p="8px 7px" direction="column" gap="0.3rem">
                            {payload
                              .sort((a, b) => Number(b?.value ?? 0) - Number(a?.value ?? 0))
                              .map((item, i) => (
                                <Flex gap="10px" key={i} alignItems="center">
                                  <TooltipCircle bg={item.color} />
                                  <Text
                                    weight="500"
                                    size="sm"
                                    style={{
                                      color: item.color,
                                    }}
                                  >
                                    {`${numberWithCommas(Number(item.value) ?? 0, formatNumber)} ${currency}`}
                                  </Text>
                                </Flex>
                              ))}
                          </TooltipBox>
                        );
                      }

                      return null;
                    }}
                  />
                  <Bar
                    dataKey="interest"
                    fill={theme.colors.green}
                    legendType="none"
                    minPointSize={minPointSize ? value => value + minPointSize : undefined}
                  />
                  <Bar
                    dataKey="principal"
                    minPointSize={minPointSize ? value => value + minPointSize : undefined}
                    fill={theme.colors.gray90}
                    legendType="none"
                  />
                </BarChart>
              </ResponsiveContainer>
            </ChartContainer>
          )}
        </Flex>
      )}
    </Flex>
  );
};
const LegendCircle = styled.div<{ bg?: string }>`
  background: ${({ bg, theme }) => bg ?? theme.colors.cyan};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const TooltipCircle = styled.div<{ bg?: string }>`
  background: ${({ bg, theme }) => bg ?? theme.colors.cyan};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const TooltipBox = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-right: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: 10px;
  min-width: 80px;
  ${getStyleWithMediaQuery("min-width", "", [{ [breakpoints.mobile]: "auto" }])}
`;

const ChartContainer = styled(Flex)`
  cursor: pointer;
  width: 100%;
  padding-bottom: 1rem;
  height: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray20};
`;
