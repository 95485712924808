import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme/Box";
import StyledCheckbox from "@cyanco/components/theme/Checkbox";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import {
  Button,
  Hidden,
  Loader,
  NftImageTooltip,
  Progress,
  SkeletonLine,
  SortingArrows,
  Text,
  Tooltip,
  TooltipText,
} from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { IPeerPlan, PeerPlanStatuses } from "@/apis/p2p/types";
import { ApeCoinStatus } from "@/components/ApeCoinStaking/ApeCoinStatusOnRow";
import { BNPLStatuses, IBNPL } from "@/components/Bnpl/bnpl.types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPeerPaymentPlanFromChainId } from "@/constants/contracts";
import { useGetUserTime } from "@/hooks";
import { ICurrency } from "@/types";
import {
  bigNumToFixedStr,
  bigNumToFloat,
  displayInUSD,
  getFormattedTimeForUserHistory,
  isApeCoinStakingPossible,
  numberWithCommas,
  shortenName,
} from "@/utils";

import { APE_COIN_STAKABLE_ADDRESSES } from "../../../../config";
import { isMobile } from "../../../../utils/userAgent";
import { useAppContext } from "../../../AppContextProvider";
import { AccountDataContext } from "../../AccountDataContext";
import { PositionViewSortingAttributes, usePositionsTabContext } from "../../AccountPageContext";
import { HEADER_ROW_MIN_HEIGHT } from "../../consts";
import { IPawn, PawnStatuses } from "../../pawn.types";
import { SelectAllPositionsCheckBox } from "../SelectItems/SelectAllCheckbox";
import { Refresh } from "../WalletView/Refresh";
import { checkIsPositionActive, checkIsPositionDefaulted, checkIsRevivalPossible } from "./utils";

export const PositionListHeader = ({ totalItems }: { totalItems: number }) => {
  const theme = useTheme();
  const { sortingAttribute, setSortingAttribute, setSelectedSortingAttribute, selectedSortingAtttibute } =
    usePositionsTabContext();
  const sort = useCallback(
    (key: PositionViewSortingAttributes) => {
      const order = sortingAttribute[key] === "asc" ? "desc" : "asc";
      setSortingAttribute({ ...sortingAttribute, [key]: order });
      setSelectedSortingAttribute({ key, order });
    },
    [sortingAttribute, setSortingAttribute, setSelectedSortingAttribute],
  );
  const { fetchBNPLPositions, fetchPawnPositions, fetchPeerPlans } = useContext(AccountDataContext);
  const refresh = async () => {
    await Promise.all([fetchBNPLPositions(), fetchPeerPlans(), fetchPawnPositions()]);
  };
  return (
    <HeaderContainer>
      <HeaderItemWrapper gap="1rem">
        <SelectAllPositionsCheckBox />
        <RowText weight="600" color="secondary">{`${totalItems} item${totalItems > 1 ? "s" : ""}`}</RowText>
        <Hidden tabletDown>
          <Refresh onClick={refresh} />
        </Hidden>
      </HeaderItemWrapper>
      <Hidden tabletDown>
        <HeaderItemWrapper
          onClick={() => {
            sort(PositionViewSortingAttributes.floorPrice);
          }}
        >
          <RowText weight="600" color="secondary">{`Floor price`}</RowText>
          <SortingArrows
            sortDirectionDesc={sortingAttribute.floorPrice === "desc"}
            sortName={selectedSortingAtttibute.key}
            currentSortName={PositionViewSortingAttributes.floorPrice}
          />
        </HeaderItemWrapper>
      </Hidden>
      <Hidden tabletDown>
        <HeaderItemWrapper
          gap="2px"
          onClick={() => {
            sort(PositionViewSortingAttributes.totalCost);
          }}
        >
          <RowText weight="600" color="secondary">
            {`Total cost`}
          </RowText>
          <Tooltip>
            <HelpCircle height={16} width={16} color={theme.colors.secondary} />
            <TooltipText showArrow position="bottom" top="25px" right="-70px" style={{ width: "150px" }}>
              <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                <div>{`The total cost includes the purchase price and all interest payments for the length of the loan.`}</div>
              </Text>
            </TooltipText>
          </Tooltip>
          <SortingArrows
            sortDirectionDesc={sortingAttribute.totalCost === "desc"}
            sortName={selectedSortingAtttibute.key}
            currentSortName={PositionViewSortingAttributes.totalCost}
          />
        </HeaderItemWrapper>
      </Hidden>

      <Hidden tabletDown>
        <HeaderItemWrapper
          gap="10px"
          onClick={() => {
            sort(PositionViewSortingAttributes.paymentAmount);
          }}
        >
          <RowText weight="600" color="secondary">{`Payment amt.`}</RowText>
          <SortingArrows
            sortDirectionDesc={sortingAttribute.paymentAmount === "desc"}
            sortName={selectedSortingAtttibute.key}
            currentSortName={PositionViewSortingAttributes.paymentAmount}
          />
        </HeaderItemWrapper>
      </Hidden>
      <Hidden tabletDown>
        <HeaderItemWrapper
          gap="10px"
          onClick={() => {
            sort(PositionViewSortingAttributes.paymentDate);
          }}
        >
          <RowText weight="600" color="secondary">{`Next due`}</RowText>
          <SortingArrows
            sortDirectionDesc={sortingAttribute.paymentDate === "desc"}
            sortName={selectedSortingAtttibute.key}
            currentSortName={PositionViewSortingAttributes.paymentDate}
          />
        </HeaderItemWrapper>
      </Hidden>
      <HeaderItemWrapper
        onClick={() => {
          sort(PositionViewSortingAttributes.loanProgress);
        }}
      >
        <RowText weight="600" color="secondary">{`Loan progress`}</RowText>
        <SortingArrows
          sortDirectionDesc={sortingAttribute.loanProgress === "desc"}
          sortName={selectedSortingAtttibute.key}
          currentSortName={PositionViewSortingAttributes.loanProgress}
        />
      </HeaderItemWrapper>
      <RowText weight="600" color="secondary">{`Pay`}</RowText>
    </HeaderContainer>
  );
};

export const PaymentDate = ({ date }: { date: Date }) => {
  const { getUserPreferredTime, showLocalTime } = useGetUserTime();
  const formattedDate = getUserPreferredTime(date);
  return (
    <Flex direction="column" gap="2px">
      {isMobile ? (
        <RowText color="secondary" weight="500">{`${formattedDate.format("MMM")} ${formattedDate.format(
          "DD",
        )}, ${formattedDate.format("YYYY")}`}</RowText>
      ) : (
        <RowText color="secondary" weight="500" textWrap={false}>{`${formattedDate.format(
          "MMMM",
        )} ${formattedDate.format("DD")}, ${formattedDate.format("YYYY")}`}</RowText>
      )}

      <RowText color="gray0" sub>
        {getFormattedTimeForUserHistory(formattedDate, showLocalTime)}
      </RowText>
    </Flex>
  );
};

export const CurrentPositionRow = ({
  position,
  onClick,
  toggleItem,
  loading,
  isSelected,
  hasMultiSelectionBox,
  isLast,
}: {
  position: (IBNPL | IPawn) & {
    planType: "Pawn" | "BNPL";
    floor?: {
      currency: ICurrency;
      amount: {
        raw: string;
        usd: number;
      };
    } | null;
  };
  isLast?: boolean;
  onClick: () => void;
  toggleItem: () => void;
  loading: boolean;
  isSelected: boolean;
  hasMultiSelectionBox: boolean;
}) => {
  const { chainId } = useWeb3React();
  const { usdPrice } = useAppContext();
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const theme = useTheme();

  const enableApeCoinStaking = useMemo(() => {
    return (
      isApeCoinStakingPossible(chainId) && APE_COIN_STAKABLE_ADDRESSES.includes(position.metadata.collectionAddress)
    );
  }, [chainId, position.metadata.collectionAddress]);
  const ApeCoinStatusMemo = useMemo(() => {
    return (
      <ApeCoinStatus
        nft={{
          tokenId: position.tokenId,
          address: position.metadata.collectionAddress,
        }}
        isCard={false}
      />
    );
  }, [position.tokenId, position.metadata.collectionAddress]);
  const isDefaulted = useMemo(() => {
    return checkIsPositionDefaulted(position);
  }, [position]);
  const isRevivalPossible = useMemo(() => {
    return checkIsRevivalPossible(position);
  }, [position]);
  const isActivePlan = useMemo(() => {
    return checkIsPositionActive(position);
  }, [position]);
  const totalNumOfPaymentsLeft = useMemo(() => {
    return position.totalNumOfPayments - position.currentNumOfPayments;
  }, [position.totalNumOfPayments, position.currentNumOfPayments]);
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(position.currency.decimal) || 4;
  }, [position.currency.decimal]);

  const formatTooltipDate = (date?: Date | null) => {
    if (!date) return "-";
    return dayjs(date).format("DD MMM, YYYY");
  };

  const totalCost = useMemo(() => {
    if (position.planType === "BNPL") {
      return BigNumber.from(position.monthlyAmount)
        .mul(position.totalNumOfPayments - 1)
        .add(position.downpaymentAmount);
    }
    return BigNumber.from(position.monthlyAmount).mul(position.totalNumOfPayments);
  }, [position.interestFee]);
  return (
    <Row onClick={() => !loading && onClick()} selected={isSelected} isLast={isLast}>
      <Flex alignItems="center" gap="1rem">
        <div
          style={{
            visibility: isActivePlan || isRevivalPossible ? "visible" : "hidden",
          }}
        >
          {/* // wrapped empty div for safari */}
          <RowCartCheckboxWrapper
            onClick={e => {
              e.stopPropagation();
            }}
            alignItems="center"
          >
            <StyledCheckbox
              checked={isSelected}
              onChange={toggleItem}
              disabled={!isActivePlan && !isRevivalPossible}
              id={`${position.tokenId}-${position.planType}-${position.planId}`}
            />
          </RowCartCheckboxWrapper>
        </div>
        <Flex alignItems="center" gap="20px">
          {imageLoading && !imageError && (
            <ImageLoader>
              <SkeletonLine w="100%" h="100%" borderRadius="10px" />
            </ImageLoader>
          )}
          {!imageError ? (
            <div
              style={{
                display: imageLoading ? "none" : "block",
              }}
            >
              <NftImageTooltip
                data1={{
                  label: "Payment amt.",
                  value: `${bigNumToFixedStr(position.monthlyAmount, 2, position.currency.decimal)} ${
                    position.currency.symbol
                  }`,
                }}
                data2={{
                  label: isDefaulted ? "Missed Date" : "Payment Date",
                  value: isDefaulted
                    ? formatTooltipDate(position.defaultedAt)
                    : isActivePlan
                    ? formatTooltipDate(position.nextPaymentDate)
                    : "-",
                }}
                imageUrl={position.metadata.imageUrl}
                hasMultiSelectionBox={hasMultiSelectionBox}
              >
                <StyledImg
                  src={position.metadata.imageUrl}
                  alt={position.metadata.collection.name}
                  onError={() => setImageError(true)}
                  onLoad={() => setImageLoading(false)}
                />
              </NftImageTooltip>
            </div>
          ) : (
            <StyledImg src={NoImage} alt={"no-image"} style={{ objectFit: "contain" }} />
          )}
          <Flex direction="column" gap="2px">
            <Flex gap="5px">
              <RowText color={"secondary"} weight="500">
                #{shortenName(position.tokenId, 10, 6)}
              </RowText>
              <Flex gap="4px">
                <Flex direction="row" gap="5px">
                  <Flex
                    style={{
                      background: theme.colors.secondary,
                      borderRadius: "4px",
                    }}
                    p={"2px 4px"}
                    h={"fit-content"}
                    w="fit-content"
                  >
                    <StyledTextPlanType color="primary" weight="700">
                      {position.planType === "BNPL" ? "BNPL" : "Loan"}
                    </StyledTextPlanType>
                  </Flex>
                  {enableApeCoinStaking && ApeCoinStatusMemo}
                </Flex>
                <Flex gap="4px">
                  {position.isAutoLiquidationEnabled && (
                    <Flex gap="4px" alignItems="flex-end">
                      <Flex
                        style={{
                          background: theme.colors.secondary,
                          borderRadius: "4px",
                        }}
                        p={"2px 4px"}
                        h={"fit-content"}
                        w="fit-content"
                      >
                        <StyledTextPlanType color="primary" weight="700">
                          {"Auto-Liquidation"}
                        </StyledTextPlanType>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <RowText sub color={"gray0"}>
              {shortenName(position.metadata.collection.name)}
            </RowText>
          </Flex>
        </Flex>
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="2px">
          {position.floor ? (
            <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
              {numberWithCommas(
                bigNumToFloat(position.floor.amount.raw, position.floor.currency.decimal),
                formatNumber,
              )}{" "}
              {position.floor.currency.symbol}
            </RowText>
          ) : (
            <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
              -
            </RowText>
          )}
          {position.floor ? (
            <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
              {displayInUSD(position.floor.amount.usd)}
            </RowText>
          ) : (
            <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
              $-
            </RowText>
          )}
        </Flex>
      </Hidden>
      {/* TODO find purchase price */}
      <Hidden tabletDown>
        {position.planType === "BNPL" ? (
          <Flex direction="column" justifyContent="center" gap="2px">
            <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
              {numberWithCommas(bigNumToFloat(totalCost, position.currency.decimal), formatNumber)}{" "}
              {position.currency.symbol}
            </RowText>
            <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
              {displayInUSD(bigNumToFloat(totalCost, position.currency.decimal) * usdPrice[position.currency.symbol])}
            </RowText>
          </Flex>
        ) : (
          <Flex direction="column" justifyContent="center" gap="2px">
            <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
              -
            </RowText>
            <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
              $-
            </RowText>
          </Flex>
        )}
      </Hidden>

      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="2px">
          <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
            {numberWithCommas(bigNumToFloat(position.monthlyAmount, position.currency.decimal), formatNumber)}{" "}
            {position.currency.symbol}
          </RowText>
          <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
            {displayInUSD(
              bigNumToFloat(position.monthlyAmount, position.currency.decimal) * usdPrice[position.currency.symbol],
            )}
          </RowText>
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        {position?.nextPaymentDate ? (
          <PaymentDate date={position.nextPaymentDate} />
        ) : isDefaulted ? (
          <RowText color="red" weight="500">
            {`Defaulted`}
          </RowText>
        ) : position.planType === "BNPL" && position.status !== BNPLStatuses.Activated ? (
          <>
            {position.status === BNPLStatuses.Rejected && <RowText color="red" weight="500">{`Rejected`}</RowText>}
            {position.status === BNPLStatuses.Funded && (
              <Flex alignItems="center" gap="5px">
                <RowText color="cyan" weight="500">
                  {`Purchasing`}
                </RowText>
                <Loader stroke={theme.colors.secondary} size={"12px"} />
              </Flex>
            )}
            {position.status === BNPLStatuses.Pending && (
              <Flex alignItems="center" gap="5px">
                <RowText color="cyan" weight="500">
                  {`Funding`}
                </RowText>
                <Loader stroke={theme.colors.secondary} size={"12px"} />
              </Flex>
            )}
          </>
        ) : (
          "-"
        )}
      </Hidden>
      <Flex direction="column" gap="2px">
        <Flex>
          <div style={{ width: "70%" }}>
            <Progress
              steps={position.totalNumOfPayments}
              current={position.currentNumOfPayments}
              color={isDefaulted ? "red" : undefined}
            />
          </div>
          <RowText weight="500" style={{ visibility: "hidden" }}>
            .
          </RowText>
        </Flex>
        <RowText weight="500" color="gray0" sub>{`${totalNumOfPaymentsLeft} payment${
          totalNumOfPaymentsLeft > 1 ? "s" : ""
        } left`}</RowText>
      </Flex>
      {loading ? (
        <Flex w="fit-content" alignItems="center" gap="5px">
          <SmallButtonText textWrap={false} weight={"700"} color="cyan">{`Processing`}</SmallButtonText>
          <Loader size="12px" stroke={theme.colors.secondary} />
        </Flex>
      ) : (
        <Flex w="fit-content">
          {((position.planType === "BNPL" && position.status === BNPLStatuses.Activated) ||
            (position.planType === "Pawn" && position.status === PawnStatuses.Activated)) && (
            <PayButton
              variant={theme.theme == "dark" ? "outlined" : "filled"}
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            >
              <Box p={"0.2rem 0.5rem"}>
                <PayText textWrap={false} weight={"700"}>{`Pay`}</PayText>
              </Box>
            </PayButton>
          )}
          {isRevivalPossible && (
            <PayButton
              variant={theme.theme == "dark" ? "outlined" : "filled"}
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            >
              <Box p={"0.2rem 0.5rem"}>
                <PayText textWrap={false} weight={"700"}>{`Revive`}</PayText>
              </Box>
            </PayButton>
          )}
        </Flex>
      )}
    </Row>
  );
};

export const CurrentPeerPlanRow = ({
  position,
  onClick,
  toggleItem,
  loading,
  isSelected,
}: {
  position: IPeerPlan & {
    floor?: {
      currency: ICurrency;
      amount: {
        raw: string;
        usd: number;
      };
    } | null;
  };
  onClick: () => void;
  toggleItem: () => void;
  loading: boolean;
  isSelected: boolean;
}) => {
  const { signer, chainId } = useWeb3React();
  const { usdPrice } = useAppContext();
  const [nextPayment, setNextPayment] = useState<{ currentPayment: BigNumber; nextPaymentDate?: Date }>();
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    if (!signer) return;
    const _setNextPayment = async () => {
      const peerToPeerContract = getPeerPaymentPlanFromChainId(chainId);
      const paymentPlanReader = f.CyanPeerPlanFactory.connect(peerToPeerContract, signer);
      const { interestRate, serviceFeeRate, dueDate, amount, status } = await paymentPlanReader.getPaymentPlanMapped(
        position.planId,
      );
      const fee = BigNumber.from(amount)
        .mul(interestRate)
        .div(10000)
        .add(BigNumber.from(amount).mul(serviceFeeRate).div(10000));
      const currentPayment = BigNumber.from(amount).add(fee);
      if (status === PeerPlanStatuses.ACTIVE) {
        setNextPayment({
          nextPaymentDate: dueDate,
          currentPayment,
        });
      }
    };
    _setNextPayment();
  }, [position]);

  const theme = useTheme();

  const enableApeCoinStaking = useMemo(() => {
    return isApeCoinStakingPossible(chainId) && APE_COIN_STAKABLE_ADDRESSES.includes(position.collectionAddress);
  }, [chainId, position.collectionAddress]);
  const ApeCoinStatusMemo = useMemo(() => {
    return (
      <ApeCoinStatus
        nft={{
          tokenId: position.tokenId,
          address: position.collectionAddress,
        }}
        isCard={false}
      />
    );
  }, [position.tokenId, position.collectionAddress]);
  const isDefaulted = useMemo(() => {
    return (
      !nextPayment || position.status === PeerPlanStatuses.DEFAULTED || position.status === PeerPlanStatuses.LIQUIDATED
    );
  }, [position.status, nextPayment]);
  const isActivePlan = useMemo(() => {
    return position.status === PeerPlanStatuses.ACTIVE;
  }, [position.status]);
  const totalNumOfPaymentsLeft = useMemo(() => {
    // peer plan has only one payment
    return isActivePlan || isDefaulted || position.status === PeerPlanStatuses.LIQUIDATED ? 1 : 0;
  }, [isActivePlan, isDefaulted]);
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(position.loanBid.currency.decimal) || 4;
  }, [position.loanBid.currency]);

  return (
    <Row onClick={() => !loading && onClick()} selected={isSelected}>
      <Flex alignItems="center" gap="1rem">
        <div>
          {/* // wrapped empty div for safari */}
          <RowCartCheckboxWrapper
            onClick={e => {
              e.stopPropagation();
            }}
            alignItems="center"
          >
            <StyledCheckbox
              checked={isSelected}
              onChange={toggleItem}
              disabled={!isActivePlan}
              id={`${position.tokenId}-${position.planType}-${position.planId}`}
            />
          </RowCartCheckboxWrapper>
        </div>
        <Flex alignItems="stretch" gap="20px">
          {imageLoading && !imageError && (
            <ImageLoader>
              <SkeletonLine w="100%" h="100%" borderRadius="10px" />
            </ImageLoader>
          )}
          {!imageError ? (
            <StyledImg
              src={position.metadata ? position.metadata.imageUrl : NoImage}
              alt={position.collection.name}
              onError={() => setImageError(true)}
              onLoad={() => setImageLoading(false)}
              style={{
                display: imageLoading ? "none" : "block",
              }}
            />
          ) : (
            <StyledImg src={NoImage} alt={"no-image"} style={{ objectFit: "contain" }} />
          )}
          <Flex gap="2px" direction="column">
            <Flex gap="5px">
              <RowText color={"secondary"} weight="500">
                #{shortenName(position.tokenId, 10, 6)}
              </RowText>
              <MobileFriendlyWrapper
                gap="4px"
                style={{
                  alignSelf: "flex-end",
                }}
              >
                <Flex
                  style={{
                    background: theme.colors.secondary,
                    borderRadius: "4px",
                  }}
                  p={"2px 4px"}
                  h={"fit-content"}
                  w="fit-content"
                >
                  <StyledTextPlanType color="primary" weight="700">
                    P2P
                  </StyledTextPlanType>
                </Flex>
                {enableApeCoinStaking && ApeCoinStatusMemo}
              </MobileFriendlyWrapper>
            </Flex>
            <RowText sub color={"gray0"}>
              {shortenName(position.collection.name)}
            </RowText>
          </Flex>
        </Flex>
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="2px">
          {position.floor ? (
            <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
              {numberWithCommas(
                bigNumToFloat(position.floor.amount.raw, position.floor.currency.decimal),
                formatNumber,
              )}{" "}
              {position.floor.currency.symbol}
            </RowText>
          ) : (
            <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
              -
            </RowText>
          )}
          {position.floor ? (
            <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
              {displayInUSD(position.floor.amount.usd)}
            </RowText>
          ) : (
            <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
              $-
            </RowText>
          )}
        </Flex>
      </Hidden>
      {/* TODO find purchase price */}
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="2px">
          <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
            -
          </RowText>
          <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
            $-
          </RowText>
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="2px">
          <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
            {numberWithCommas(bigNumToFloat(position.monthlyAmount, position.loanBid.currency.decimal), formatNumber)}{" "}
            {position.loanBid.currency.symbol}
          </RowText>
          <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
            {displayInUSD(
              bigNumToFloat(position.monthlyAmount, position.loanBid.currency.decimal) *
                usdPrice[position.loanBid.currency.symbol],
            )}
          </RowText>
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        {nextPayment?.nextPaymentDate && position.dueDate ? (
          <PaymentDate date={nextPayment?.nextPaymentDate ?? position.dueDate} />
        ) : isDefaulted ? (
          <RowText color="red" weight="500">
            {`Defaulted`}
          </RowText>
        ) : (
          "-"
        )}
      </Hidden>
      <Flex direction="column" gap="2px">
        <Flex>
          <div style={{ width: "70%" }}>
            <Progress steps={1} current={isActivePlan || isDefaulted ? 0 : 1} />
          </div>
          <RowText weight="500" style={{ visibility: "hidden" }}>
            .
          </RowText>
        </Flex>
        <RowText weight="500" color="gray0" sub>{`${totalNumOfPaymentsLeft} payment${
          totalNumOfPaymentsLeft > 1 ? "s" : ""
        } left`}</RowText>
      </Flex>
      {loading ? (
        <Flex w="fit-content" alignItems="center" gap="5px">
          <SmallButtonText textWrap={false} weight={"700"} color="cyan">{`Processing`}</SmallButtonText>
          <Loader size="12px" stroke={theme.colors.secondary} />
        </Flex>
      ) : (
        <Flex w="fit-content">
          {isActivePlan && (
            <PayButton
              variant="outlined"
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            >
              <Box p={"0.3rem 0.7rem"}>
                <PayText textWrap={false} weight={"700"}>{`Pay`}</PayText>
              </Box>
            </PayButton>
          )}
        </Flex>
      )}
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean; selected?: boolean; isLast?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  grid-template-columns: 2.3fr 1.2fr 1.2fr 1.3fr 1.4fr 1.3fr 0.6fr;
  padding: 13px 15px 13px 15px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.gray10 : "transparent")};
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "3fr 1.3fr 1.3fr" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  ${({ isLast }) =>
    isLast &&
    ` border-bottom-left-radius: 20px;
       border-bottom-right-radius: 20px;`}
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;

export const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;
const SmallButtonText = styled(Text)`
  font-size: 14px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 12 }, { [breakpoints.mobile]: 10 }])};
`;

const HeaderContainer = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    padding: 7px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const StyledImg = styled.img`min-width: 36px;
min-height: 36px;
min-width: 36px;
max-width: 36px;
max-height: 36px;
${getStyleWithMediaQuery("min-width", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("min-height", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("max-height", "px", [{ [breakpoints.mobile]: 24 }])}
border-radius: ${({ theme }) => theme.borderRadius};
} `;

const ImageLoader = styled.div`
  width: 36px;
  height: 36px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 24 }])}
`;

const PayText = styled(SmallButtonText)`
  color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.cyan : "black")};
  transition: 0.2s ease-in-out;
`;
const PayButton = styled(Button)`
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  :hover {
    background-color: ${({ theme }) => theme.colors.cyan};
  }
  &:hover ${PayText} {
    color: ${({ theme }) => theme.colors.black};
  }
`;
const MobileFriendlyWrapper = styled(Flex)`
  flex-direction: row;
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])};
`;

const StyledTextPlanType = styled(Text)`
  font-size: 10px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 10 }, { [breakpoints.mobile]: 8 }])}
`;

const RowCartCheckboxWrapper = styled(Flex)`
  margin: -13px 0px;
  padding: 13px 0px;
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.mobile]: "-7px 0px" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 0px" }])};
  height: 100%;
`;

export const HeaderItemWrapper = styled(Flex)`
  height: ${HEADER_ROW_MIN_HEIGHT};
  align-items: center;
`;
