import { useEffect, useState } from "react";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { IPeerPlan } from "@/apis/p2p/types";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPeerPaymentPlanFromChainId } from "@/constants/contracts";
import { getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { DefaultedPlan, LoanMetadata } from "./DefaultedPlan";

export const LiquidatePlanProgress = ({ plan }: { plan: IPeerPlan }) => {
  const cyanWallet = useCyanWallet();
  const { chainId, signer, account } = useWeb3React();
  const { setModalContent, unsetModal } = useModal();
  const { addTransaction, transactions } = useTransactionContext();
  const [selectedStep, setSelectedStep] = useState<Steps>(Steps.Liquidate);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [activeTx, setActiveTx] = useState<string | null>(null);
  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(Steps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);
  useEffect(() => {
    const onStepChange = async (step: Steps) => {
      if (!chainId || !signer || !account) return;
      try {
        switch (step) {
          case Steps.Liquidate: {
            const peerToPeerContract = getPeerPaymentPlanFromChainId(chainId);
            const writer = f.CyanPeerPlanFactory.connect(peerToPeerContract, signer);
            const tx = await writer.liquidate([plan.planId, 0, 0]);
            addTransaction({
              type: "p2p-liquidate",
              hash: tx.hash,
              data: {
                planId: plan.planId,
              },
            });
            setActiveTx(tx.hash);
          }
        }
      } catch (err) {
        const mappedError = mapAndLogError(err, account);
        setModalContent({
          title: `Cancel loan offer`,
          content: <DefaultedPlan plan={plan} error={mappedError} />,
        });
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);
  return (
    <Flex gap="2rem" direction="column">
      <LoanMetadata plan={plan} />
      <Box pb="1.8rem" pt="1rem">
        <Stepper
          marks={StepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === Steps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};
enum Steps {
  Liquidate = 1,
  Success = 2,
  Done = 3,
}
const StepMarks = [
  {
    value: Steps.Liquidate,
    title: `Liquidating NFT`,
    description: `A small amount of gas is required to approve`,
  },
  {
    value: Steps.Success,
    description: `View on Etherscan`,
    title: `Successfully cancelled`,
  },
];

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
