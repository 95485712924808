import { IPlan } from "./plan.types";

export const PawnStatuses = {
  Activated: 2,
  Completed: 3,
  Defaulted: 4,
  Liquidated: 5,
};
export type IPawnStatus = typeof PawnStatuses[keyof typeof PawnStatuses];

export interface IPawn extends IPlan {
  appraisalValue: string;

  pawnedAmount: string;
  status: IPawnStatus;
  planType: "Pawn";
}

export const isPawnPlan = (item: any): item is IPawn => {
  return "planType" in item && item.planType === "Pawn";
};
