import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useAsyncAbortable } from "react-async-hook";
import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, CopyButton, SubTitle, SwitchButton, SwitchButtonGroup, Text } from "@cyanco/components/theme/v3";
import { Globe, Twitter } from "@cyanco/components/theme/v3/icons";
import { EtherscanGray } from "@cyanco/components/theme/v3/images";

import { ICollectionBe } from "@/apis/collection/types";
import { IP2PLoanOffer } from "@/apis/p2p/types";
import { fetchAvailableLoanOffersForNft } from "@/apis/user";
import { FnGetNftAvailableLoans, IUserNft } from "@/apis/user/types";
import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS } from "@/constants/chains";

import {
  bigNumToFixedStr,
  bigNumToFloat,
  getChainExplorerURL,
  jumpToLink,
  numberWithCommas,
  shortenAddress,
} from "../../../utils";
import { LoanOfferViewStyle } from "../LoanPageProvider";
import { LoanOffers } from "./LoanOffers";
import { OfferTakerCard } from "./OfferTakerCard";

export const AssetMakeLoanOffer: React.FC<{
  nft: IUserNft;
  collection: ICollectionBe;
}> = ({ nft, collection }) => {
  const { provider, account, chainId } = useWeb3React();
  const { usdPrice } = useAppContext();
  const [selectedOffer, _setSelectedOffer] = useState<IP2PLoanOffer>();
  const [selectedOffersView, setSelectedOffersView] = useState<LoanOfferViewStyle>(LoanOfferViewStyle.current);
  const theme = useTheme();

  const { result: availableLoan = { loanBids: [], vaultOffers: [] }, loading: loanOffersLoading } = useAsyncAbortable<
    FnGetNftAvailableLoans["result"]
  >(
    async abortSignal => {
      if (account)
        return fetchAvailableLoanOffersForNft({
          chainId,
          collectionAddress: nft.address,
          wallet: account,
          tokenId: nft.tokenId,
          abortSignal,
        });
      return {
        loanBids: [],
        vaultOffers: [],
      };
    },
    [account],
  );

  const getStat = (value: number | string | null | undefined, postfix?: string, prefix?: string) => {
    return value !== null && value !== undefined ? `${prefix || ""}${numberWithCommas(value)} ${postfix || ""}` : "-";
  };
  const nftOffers = useMemo(() => {
    return availableLoan.loanBids.filter(
      offer => (offer.tokenId === nft.tokenId && offer.collectionAddress) || offer.tokenId === null,
    );
  }, [availableLoan.loanBids, nft]);
  const nftOffersActive = useMemo(() => {
    return nftOffers.filter(offer => offer.isActive);
  }, [nftOffers]);
  const nftOffersInActive = useMemo(() => {
    return nftOffers.filter(offer => !offer.isActive);
  }, [nftOffers]);
  const avgLoanAmount = useMemo(() => {
    return (
      nftOffers.reduce(
        (acc, cur) => acc + bigNumToFloat(cur.amount, cur.currency.decimal) * usdPrice[cur.currency.symbol],
        0,
      ) /
      nftOffers.length /
      usdPrice[nft.currency.symbol]
    );
  }, [nftOffers, usdPrice]);
  const avgApr = useMemo(() => {
    return (
      nftOffers.reduce((acc, cur) => acc + (365 / (cur.term / 60 / 60 / 24)) * (cur.interestRate / 100), 0) /
      nftOffers.length
    );
  }, [nftOffers]);
  const [blockchainTimestamp, setBlockchainTimestamp] = useState(0);
  useEffect(() => {
    const setTime = async () => {
      if (!provider) return;
      const lastBlockNum = await provider.getBlockNumber();
      const lastBlock = await provider.getBlock(lastBlockNum);
      const lastTimestamp = lastBlock.timestamp * 1000;
      setBlockchainTimestamp(lastTimestamp);
    };
    setTime();
  }, [provider]);

  return (
    <Container>
      <Flex direction="column" w="100%" gap="2rem">
        <ContainerOffers direction="column" gap="1.5rem">
          <Flex direction="column" gap="1rem">
            <Box h="22px" w="fit-content">
              <SwitchButtonGroup<LoanOfferViewStyle> value={selectedOffersView} onChange={setSelectedOffersView} hover>
                <SwitchButton height="22px" value={LoanOfferViewStyle.current}>{`Current`}</SwitchButton>
                <SwitchButton height="22px" value={LoanOfferViewStyle.historical}>{`Historical`}</SwitchButton>
              </SwitchButtonGroup>
            </Box>

            <div
              style={{
                display: selectedOffersView === LoanOfferViewStyle.current ? "block" : "none",
              }}
            >
              <LoanOffers
                blockchainTimestamp={blockchainTimestamp}
                offers={nftOffersActive}
                loading={loanOffersLoading}
                vaultOffers={availableLoan.vaultOffers}
                collection={collection}
                nft={nft}
              />{" "}
            </div>
            <div
              style={{
                display: selectedOffersView === LoanOfferViewStyle.historical ? "block" : "none",
              }}
            >
              <LoanOffers
                blockchainTimestamp={blockchainTimestamp}
                offers={nftOffersInActive}
                loading={loanOffersLoading}
                vaultOffers={[]}
                collection={collection}
                nft={nft}
              />
            </div>
          </Flex>
          <StakeMobile>
            <OfferMakerContainer>
              <Text color="secondary" weight="700" size="md">
                {`Take NFT Loan Offer`}
              </Text>
              <OfferTakerCard nft={nft} collection={collection} showNft={false} />
            </OfferMakerContainer>{" "}
          </StakeMobile>
        </ContainerOffers>
        <TopWrapper>
          <TopWrapperSub direction="column" w="100%">
            <Flex w="100%" gap="1rem" alignItems="center">
              <Image src={nft.imageUrl} />
              <InfoTitleContainer>
                <div>
                  <Flex alignItems="center" gap="15px">
                    <Flex direction="column" gap="5px">
                      <Flex gap="1rem">
                        <SubTitle>{collection.name}</SubTitle>
                        <TitleSymbol>#{nft.tokenId}</TitleSymbol>
                      </Flex>
                      <Flex gap="5px" wrap="wrap">
                        <DetailSubText color="gray0">
                          {collection.tokenCount} {`items`}
                        </DetailSubText>
                        <DetailSubText color="gray0">|</DetailSubText>
                        <DetailSubText color="gray0">
                          {`created`} {dayjs(collection.createdAt).format("MMMM YYYY")}
                        </DetailSubText>
                        <DetailSubText color="gray0">|</DetailSubText>
                        <DetailSubText color="gray0">
                          {CHAIN_INFO[collection.chainId as typeof CYAN_SUPPORTED_CHAIN_IDS[number]].label || ""}
                        </DetailSubText>
                      </Flex>
                    </Flex>
                  </Flex>
                </div>
              </InfoTitleContainer>
            </Flex>
          </TopWrapperSub>
        </TopWrapper>
        <InfoContainer direction="column" gap="2rem">
          <Flex direction="column" gap="1.5rem">
            <StatsGapWrapper gap="2.5rem" alignItems="center" wrap="wrap">
              <DaliyStat
                statName={`Appraisal value`}
                statValue={
                  nft.appraisalValue
                    ? `${numberWithCommas(bigNumToFloat(nft.appraisalValue, nft.currency.decimal), 3)} ${
                        nft.currency.symbol
                      }`
                    : "N/A"
                }
              />

              <DaliyStat
                statName={`Collection Floor`}
                statValue={
                  collection.floorAsk.price
                    ? getStat(
                        bigNumToFixedStr(
                          collection.floorAsk.price.amount.raw,
                          2,
                          collection.floorAsk.price.currency.decimals,
                        ),
                        collection.floorAsk.price.currency.symbol,
                      )
                    : "N/A"
                }
              />
              <DaliyStat statName={`Avg. Loan Amt.`} statValue={`${avgLoanAmount.toFixed(3)} ${nft.currency.symbol}`} />
              <DaliyStat statName={`Avg. APR.`} statValue={getStat(avgApr, "%")} />
            </StatsGapWrapper>
            <Flex gap="3px" alignItems="center" w="fit-content">
              <CopyButton value={collection.address}>
                <Text size="xxs" weight="500" color="secondary">
                  {shortenAddress(collection.address)}
                </Text>
              </CopyButton>
              <IconButton
                variant="ghost"
                disabled={!collection.twitterAccount}
                onClick={() =>
                  collection.twitterAccount && jumpToLink(`https://twitter.com/${collection.twitterAccount}`)
                }
              >
                <Twitter width={16} height={16} color={theme.colors.secondary} />
              </IconButton>
              <IconButton
                variant="ghost"
                disabled={!collection.externalUrl}
                onClick={() => collection.externalUrl && jumpToLink(collection.externalUrl)}
              >
                <Globe width={16} height={16} color={theme.colors.secondary} />
              </IconButton>

              <IconButton
                variant="ghost"
                onClick={() => jumpToLink(`${getChainExplorerURL(collection.chainId)}/address/${collection.address}`)}
              >
                <img src={EtherscanGray} width={16} height={16} />
              </IconButton>
            </Flex>
          </Flex>
        </InfoContainer>
      </Flex>
      <StakeDesktop>
        <OfferMakerContainer>
          <Text color="secondary" weight="700" size="md">
            {`Take NFT Loan Offer`}
          </Text>
          <OfferTakerCard nft={nft} collection={collection} showNft={false} loandData={selectedOffer} />
        </OfferMakerContainer>
      </StakeDesktop>
    </Container>
  );
};

const DaliyStat = ({ statName, statValue }: { statName: string; statValue: string }) => {
  return (
    <Flex direction="column" gap="3px">
      <Text color="secondary" size="md" weight="700">
        {statValue}
      </Text>
      <Text size="xs" color="secondary" weight="400">
        {statName}
      </Text>
    </Flex>
  );
};

const ContainerOffers = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0 5px" }])}
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 4rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.laptopL]: 2 }, { [breakpoints.tablet]: 0.5 }])}
`;

const InfoContainer = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "2rem 5px 0rem 5px" }])}
`;

const InfoTitleContainer = styled(Flex)`
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
  }
`;

const DetailSubText = styled(Text)`
  font-size: 12px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 10 }])};
  font-weight: 400;
`;

const StatsGapWrapper = styled(Flex)`
  gap: 2.5rem;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    justify-content: space-between;
    gap: 1.4rem;
  }
`;

const TopWrapper = styled(Flex)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem 0;
    padding: 0 5px;
  }
  padding: 0 18px;
`;
const TopWrapperSub = styled(Flex)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 20px;
  }
`;

const Image = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 12px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 45px;
    height: 45px;
  }
`;

const TitleSymbol = styled(SubTitle)`
  color: ${({ theme }) => theme.colors.gray0};
`;

const StakeMobile = styled.div`
  visibility: hidden;
  display: none;
  ${getStyleWithMediaQuery("display", "", [{ [breakpoints.tablet]: "block" }])};
`;

const StakeDesktop = styled.div`
  visibility: hidden;
  display: block;
  position: sticky;
  z-index: 70;
  padding-top: calc(22px + 1rem);
  ${getStyleWithMediaQuery("padding-top", "", [
    {
      [breakpoints.tablet]: "0px",
    },
  ])};
  ${getStyleWithMediaQuery("display", "", [{ [breakpoints.tablet]: "none" }])};
`;

const IconButton = styled(Button)`
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;
const OfferMakerContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-style: solid;
  padding: 1rem;
  max-width: 500px;
  ${getStyleWithMediaQuery("position", "", [
    {
      [breakpoints.tablet]: "relative",
    },
  ])};
`;
