export enum SupportedMarketPlaces {
  OPENSEA = "opensea",
  LOOKSRARE = "looksrare",
  PUDGYPENGUINS = "pudgypenguins",
  X2Y2 = "x2y2",
  SUDOSWAP = "sudoswap",
  APECOINMARKETPLACE = "apecoinmarketplace",
  NFTX = "nftx",
  BLUR = "blur",
  GEM = "gem.xyz",
  CAVIAR = "caviar.sh",
  RESERVOIR = "reservoir nft explorer",
  RARIBLE = "rarible",
  PASS = "pass.xyz",
  CYANPRIVATESALE = "cyanprivatesale",
  MAGICEDEN = "magic eden",
  CRYPTOPUNKS = "cryptopunks",
}
