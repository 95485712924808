import { Flex } from "@cyanco/components/theme/Flex";

import { HorizantallyContainerGap } from "../..";
import { PositionsSelectedViews, usePositionsTabContext, useTabViewStyle } from "../../AccountPageContext";
import { CurrentPositions } from "./CurrentPositions";
import { HistoricalPayments } from "./HistoricalPayments";
import { PositionViewSelectors } from "./Selectors";

export const PositionView = () => {
  const { selectedPositionsView } = usePositionsTabContext();
  const { tabViewStyle } = useTabViewStyle();
  return (
    <Flex direction="column">
      <PositionViewSelectors />
      <HorizantallyContainerGap
        isGrid={tabViewStyle == "grid" && selectedPositionsView === PositionsSelectedViews.current}
      >
        <div
          style={{
            display: selectedPositionsView === PositionsSelectedViews.current ? "block" : "none",
          }}
        >
          <CurrentPositions />
        </div>
        <div
          style={{
            display: selectedPositionsView === PositionsSelectedViews.historical ? "block" : "none",
          }}
        >
          <HistoricalPayments />
        </div>
      </HorizantallyContainerGap>
    </Flex>
  );
};
