import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SkeletonLine, SortingArrows, Text, getCurrencyLogoSrc } from "@cyanco/components/theme/v3";
import { CyanC } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { bigNumToFloat, formatCompactNumber } from "@/utils";

import {
  ISortValues,
  VAULT_SORTING_OPTIONS,
  formatPercentage,
  getBorrowApy,
  getSupplyApy,
  useVaultSelectors,
} from "../../VaultPageContext";

export const VaultStatsHeader = () => {
  const { setSelectedSort, selectedSort, setSortDirection, sortDirection } = useVaultSelectors();
  return (
    <VaultStatsHeaderContainer>
      <Hidden tabletDown></Hidden>
      {VAULT_SORTING_OPTIONS.map(({ value: name, hidden, align }) => {
        if (hidden) {
          return (
            <Hidden tabletDown key={name}>
              <Flex
                justifyContent={align === "start" ? "flex-start" : "flex-end"}
                gap="5px"
                onClick={() => {
                  if (name === selectedSort) {
                    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
                  } else {
                    setSortDirection("desc");
                  }
                  setSelectedSort(name as ISortValues);
                }}
              >
                <VaultHeaderText color="secondary">{name}</VaultHeaderText>
                <SortingArrows
                  sortDirectionDesc={sortDirection === "desc"}
                  sortName={selectedSort}
                  currentSortName={name}
                />
              </Flex>
            </Hidden>
          );
        } else {
          return (
            <Flex key={name} justifyContent={align === "start" ? "flex-start" : "flex-end"} gap="5px">
              <VaultHeaderText
                onClick={() => {
                  if (name === selectedSort) {
                    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
                  } else {
                    setSortDirection("desc");
                  }
                  setSelectedSort(name as ISortValues);
                }}
                color="secondary"
              >
                {name}
              </VaultHeaderText>
              <SortingArrows
                sortDirectionDesc={sortDirection === "desc"}
                sortName={selectedSort}
                currentSortName={name}
              />
            </Flex>
          );
        }
      })}
      <VaultHeaderText color="secondary">{`Actions`}</VaultHeaderText>
    </VaultStatsHeaderContainer>
  );
};

export const VaultStatsRow: React.FC<{
  vault: IVault & { index: number };
}> = ({ vault }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const localUrl = `/${location.pathname.split("/")[1]}/${CHAIN_IDS_TO_NAMES[vault.chainId as SupportedChainId]}/${
    vault.contractAddress
  }`;

  const supplyApy = useMemo(() => {
    return formatPercentage(getSupplyApy(vault));
  }, [vault.estimatedYield, vault.utilizationRate]);

  const borrowApy = useMemo(() => {
    // TODO change calculation logic
    return formatPercentage(getBorrowApy(vault));
  }, [vault.estimatedYield]);

  const currency = useMemo(() => {
    return getCurrencyLogoSrc(vault.currency);
  }, [vault.currency]);
  const formattedUsdTvl = useMemo(() => {
    return formatCompactNumber(Math.round(vault.totalValueLockedUsd));
  }, [vault.currency, vault.totalValueLockedUsd]);
  const formattedTvl = useMemo(() => {
    if (vault.totalValueLocked) {
      return formatCompactNumber(bigNumToFloat(vault.totalValueLocked, vault.decimals));
    }
    return "N/A";
  }, [vault]);

  return (
    <Row onClick={() => navigate(localUrl)}>
      <Hidden tabletDown>
        <VaultRowText color="gray0" align="center">
          {vault.index + 1}
        </VaultRowText>
      </Hidden>
      <Flex alignItems="center" gap="8px">
        <StyledImg style={{ backgroundColor: vault.colorCode }}>
          <VaultImage src={CyanC} alt={vault.name} />
        </StyledImg>
        <VaultRowText color="secondary" textAlign="left">
          {vault.name}
        </VaultRowText>
      </Flex>
      <Hidden tabletDown>
        <VaultRowText textAlign="left">
          <Flex gap="5px" alignItems="center">
            <img src={currency} height="20px" width="20px"></img>
            <Text color="secondary">{vault.currency}</Text>
          </Flex>
        </VaultRowText>
      </Hidden>
      <Hidden tabletDown>
        <VaultRowText textAlign="right">
          <Flex direction="column">
            <Flex gap="5px" alignItems="center">
              <img src={currency} height="20px" width="20px"></img>
              <Text color="secondary">{formattedTvl}</Text>
            </Flex>
            <Text color="gray0" size="xs">
              ${formattedUsdTvl}
            </Text>
          </Flex>
        </VaultRowText>
      </Hidden>
      <VaultRowText color="secondary">
        {vault.utilizationRate ? `${(vault.utilizationRate * 100).toFixed(2)}%` : "N/A"}
      </VaultRowText>
      <Hidden tabletDown>
        <VaultRowText color="secondary">{supplyApy}</VaultRowText>
      </Hidden>
      <Hidden tabletDown>
        <VaultRowText color="secondary">{borrowApy}</VaultRowText>
      </Hidden>
      <VaultRowText>
        <Hidden tabletDown>
          <VaultActionButton onClick={() => navigate(localUrl)}>
            <Text color="black" size="xs" weight="600">
              {`Deposit ${vault.currency}`}
            </Text>
          </VaultActionButton>
        </Hidden>
        <Hidden tabletUp>
          <VaultActionButton onClick={() => navigate(localUrl)}>
            <Text color="black" size="xs" weight="600">
              {`Deposit`}
            </Text>
          </VaultActionButton>
        </Hidden>
      </VaultRowText>
    </Row>
  );
};

export const VaultStatsRowLoading = () => {
  return (
    <Row>
      <Hidden tabletDown>
        <VaultRowLoadingText />
      </Hidden>
      <VaultRowLoadingText />
      <HiddenFlex tabletDown>
        <VaultRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <VaultRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <VaultRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <VaultRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <VaultRowLoadingText />
      </HiddenFlex>
      <VaultRowLoadingText />
    </Row>
  );
};
const VaultActionButton = styled(Button)`
  border-radius: 5px;
  height: 24px;
  max-width: 116px;
  min-width: 95px;
  border: 1px solid ${({ theme }) => (theme.theme === "dark" ? theme.colors.cyan : theme.colors.black)};
  background: ${({ theme }) => (theme.theme === "dark" ? theme.colors.cyan : theme.backgroundColor)};
`;
const Row = styled.div`
  cursor: pointer;
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: end;
  grid-template-columns: 0.2fr 2fr 1.3fr 1.4fr 1.2fr 1fr 1.1fr 1.4fr;
  padding: 18px 20px 18px 14px;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2.5fr 1fr 1fr" }])};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 10px 14px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;

const HiddenFlex = styled(Hidden)`
  display: flex;
  justify-content: end;
`;

const VaultStatsHeaderContainer = styled(Row)`
  padding: 14px 20px 12px 14px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 20px 12px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  position: sticky;
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
`;

const VaultHeaderText = styled(Text)<{ align?: string }>`
  display: flex;
  justify-content: ${({ align }) => (align ? align : "end")};
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 14 },
    { [breakpoints.laptop]: 12 },
    { [breakpoints.tablet]: 12 },
  ])};
`;

export const VaultRowText = styled(Text)<{ align?: string }>`
  display: flex;
  justify-content: ${({ align }) => (align ? align : "end")};
  font-weight: 500;
  font-size: 14px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 14 }, { [breakpoints.tablet]: 12 }])};
`;

export const VaultRowLoadingText = styled(SkeletonLine)`
  width: 70%;
  height: 20px;
  justify-self: end;
  ${getStyleWithMediaQuery("height", "px", [
    { [breakpoints.desktop]: 17 },
    { [breakpoints.laptop]: 15 },
    { [breakpoints.tablet]: 13 },
  ])};
`;

export const StyledImg = styled.div`
width: 24px;
height: 24px;
min-width: 24px;
min-height: 24px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
} `;
export const VaultImage = styled.img`
  width: 16px;
  height: 16px;
`;
