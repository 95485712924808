import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { ClickableArea, Flex } from "@cyanco/components/theme/components";
import {
  NftCard,
  NftCardActionWrapper,
  NftCardAddButton,
  NftCardText,
  NftMetadataImage,
  NftMetadataInfoWrapper,
  Text,
  Tooltip,
  TooltipText,
} from "@cyanco/components/theme/v3";
import { BarChart } from "@cyanco/components/theme/v3/icons";

import { IP2PListedNft } from "@/apis/p2p/types";

import { bigNumToFixedStr, numberWithCommas, shortenName } from "../../../../utils";
import { INFtRarity } from "../../../NftStatus";

export const ListedNftCard = ({
  nft,
  onClick,
  onClickCard,
}: {
  nft: IP2PListedNft & { rarity: INFtRarity | null };
  onClick: (a: IP2PListedNft) => void;
  onClickCard: (a: IP2PListedNft) => void;
}) => {
  const getPrice = useMemo(() => {
    return !nft.appraisalValue ? "-" : bigNumToFixedStr(nft.appraisalValue, 2);
  }, [nft.appraisalValue]);
  const theme = useTheme();
  return (
    <NftCardStyled key={`${nft.collectionName}:${nft.tokenId}`}>
      <ClickableArea onClick={() => onClickCard(nft)}>
        <NftMetadataImage imageUrl={nft.imageUrl ?? null} alt={nft.tokenId} />
      </ClickableArea>
      <NftMetadataInfoWrapper>
        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <NftCardText weight="800" color="secondary">
              {shortenName(nft.collectionName)} #{shortenName(nft.tokenId, 10, 6)}
            </NftCardText>
          </div>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Tooltip>
            <NftCardText weight="500" color="secondary" sub>
              {`${getPrice} ${nft.currency.symbol}`}
            </NftCardText>
            <TooltipText right="1" top="-40px">
              <Text size="xs" color="primary" weight="700" textWrap={false}>
                {`Appraisal value`}
              </Text>
            </TooltipText>
          </Tooltip>
          {nft.rarity && (
            <Tooltip>
              <WhiteBox>
                <Flex gap="3px" alignItems="center">
                  <BarChart color={theme.colors.secondary} height={"10px"} />
                  <NftCardText weight="500" color="secondary" sub>
                    {numberWithCommas(nft.rarity.rank)}
                  </NftCardText>
                </Flex>
              </WhiteBox>
              <TooltipText position="top" top="-50px" left="-3px">
                <Flex direction="column">
                  <Text color="gray0" size="xs" weight="700" textWrap={false}>{`Rarity`}</Text>
                  <Text size="xs" color="primary" weight="700" textWrap={false}>
                    {nft.rarity.relativeRank}
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
          )}
        </Flex>
      </NftMetadataInfoWrapper>
      <NftCardActionWrapper
        style={{
          minHeight: "25px",
          position: "relative",
        }}
      >
        <NftPawnPriceWrapper justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <StyledButton>
              <NftCardText weight="500" color="gray0">
                {`Buy Now, Pay Later`}
              </NftCardText>
            </StyledButton>
          </Flex>
        </NftPawnPriceWrapper>
        <StyledNftCardAddButton
          onClick={e => {
            e.stopPropagation();
            onClick(nft);
          }}
          id={nft.tokenId}
        >
          <NftCardText weight="600" color="black">{`Make NFT Loan Offer`}</NftCardText>
        </StyledNftCardAddButton>
      </NftCardActionWrapper>
    </NftCardStyled>
  );
};

const WhiteBox = styled.div`
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  border: none;
  outline: none;
  height: 100%;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.2s;
  padding-left: 0;
`;

const NftPawnPriceWrapper = styled(Flex)`
  height: 25px;
`;
const StyledNftCardAddButton = styled(NftCardAddButton)`
  transition: all 0.2s ease-out;
  position: absolute;
  opacity: 0;
  top: 100%;
`;
const NftCardStyled = styled(NftCard)`
  overflow: hidden;
  :hover {
    ${StyledNftCardAddButton} {
      top: 25%;
      opacity: 1 !important;
      height: 25px !important;
    }
    ${NftPawnPriceWrapper} {
      display: none;
    }
  }
`;
