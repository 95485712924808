import { useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import styled, { useTheme } from "styled-components";

import { Button, Flex, Loader, SubTitle, Text } from "@cyanco/components/theme";

import { ILtvController, IPoolSettings, IVaultSupportedProjectWithAppraisal } from "@/apis/vault/admin/types";
import { IVault } from "@/apis/vault/types";

import { AppraisalSettings } from "./AppraisalSettings";
import { PoolAdvancedSettings } from "./PoolAdvancedSettings";
import { PoolDetails } from "./PoolDetails";
import { PoolSettings } from "./PoolSettings";
import { VaultDetailSectionContainer } from "./VaultDetailsPrivate";

enum PoolManagementTabs {
  details,
  settings,
  appraisal,
  advanced,
}

export const PoolManagement = ({
  vault,
  pricerSettings,
  ltvSettings,
  baseInterestRate,
  supportedProjects,
  setLtvController,
  setPoolSettings,
  setSupportedProjects,
  loading,
}: {
  vault: IVault;
  pricerSettings: IPoolSettings[];
  ltvSettings: ILtvController[];
  supportedProjects: IVaultSupportedProjectWithAppraisal[];
  baseInterestRate: number;
  setLtvController: (controllers: ILtvController[]) => void;
  setPoolSettings: (settings: IPoolSettings[], rate: number) => void;
  setSupportedProjects: (projects: IVaultSupportedProjectWithAppraisal[]) => void;
  loading: boolean;
}) => {
  const [poolManageTab, setPoolManageTab] = useState(PoolManagementTabs.details);
  return (
    <VaultDetailSectionContainer>
      <SubTitle>{`Pool Management`}</SubTitle>
      <PoolManagementContainer>
        <TabsContainer gap="1.5rem">
          <TabButton
            onClick={() => setPoolManageTab(PoolManagementTabs.details)}
            isActive={poolManageTab === PoolManagementTabs.details}
          >
            <Text color={poolManageTab === PoolManagementTabs.details ? "secondary" : "gray0"} weight="600" size="lg">
              Details
            </Text>
          </TabButton>
          <TabButton
            onClick={() => setPoolManageTab(PoolManagementTabs.settings)}
            isActive={poolManageTab === PoolManagementTabs.settings}
          >
            <Text color={poolManageTab === PoolManagementTabs.settings ? "secondary" : "gray0"} weight="600" size="lg">
              Settings
            </Text>
          </TabButton>
          <TabButton
            onClick={() => setPoolManageTab(PoolManagementTabs.appraisal)}
            isActive={poolManageTab === PoolManagementTabs.appraisal}
          >
            <Text color={poolManageTab === PoolManagementTabs.appraisal ? "secondary" : "gray0"} weight="600" size="lg">
              Appraisals
            </Text>
          </TabButton>
          <TabButton
            onClick={() => setPoolManageTab(PoolManagementTabs.advanced)}
            isActive={poolManageTab === PoolManagementTabs.advanced}
          >
            <Text color={poolManageTab === PoolManagementTabs.advanced ? "secondary" : "gray0"} weight="600" size="lg">
              Advanced
            </Text>
          </TabButton>
        </TabsContainer>
        <Flex direction="column">
          <div
            style={{
              display: poolManageTab === PoolManagementTabs.details ? "block" : "none",
            }}
          >
            <PoolDetails name={vault.name} description={vault.description} address={vault.contractAddress} />
          </div>
          <div
            style={{
              display: poolManageTab === PoolManagementTabs.settings ? "block" : "none",
            }}
          >
            <PoolSettings
              pricerSettings={pricerSettings}
              address={vault.contractAddress}
              setPoolSettings={setPoolSettings}
              baseInterestRate={baseInterestRate}
              vault={vault}
            />
          </div>
          <div
            style={{
              display: poolManageTab === PoolManagementTabs.appraisal ? "block" : "none",
            }}
          >
            <AppraisalSettings
              vault={vault}
              loading={loading}
              supportedProjects={supportedProjects}
              setSupportedProjects={setSupportedProjects}
            />
          </div>
          <div
            style={{
              display: poolManageTab === PoolManagementTabs.advanced ? "block" : "none",
            }}
          >
            <PoolAdvancedSettings vault={vault} ltvSettings={ltvSettings} setLtvController={setLtvController} />
          </div>
        </Flex>
      </PoolManagementContainer>
    </VaultDetailSectionContainer>
  );
};

export const SaveSettingsButton = ({
  onClick,
  disabled,
}: {
  onClick: () => Promise<void> | void;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const { loading, execute } = useAsyncCallback(async () => {
    await onClick();
  });
  return (
    <SaveSettings size="md" onClick={execute} disabled={loading || disabled}>
      <Flex alignItems="center" gap="5px">
        <Text color="primary" weight="700">{`Save settings`}</Text>
        {loading && <Loader stroke={theme.colors.black} size="16px" />}
      </Flex>
    </SaveSettings>
  );
};
const PoolManagementContainer = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.primary};
`;

const TabsContainer = styled(Flex)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
  padding: 0 2rem;
`;
const TabButton = styled.div<{ isActive: boolean }>`
  border-bottom: ${({ theme, isActive }) => `2px solid ${isActive ? theme.colors.cyan : theme.colors.transparent}`};
  padding: 1rem 0;
  cursor: pointer;
  transition: 0.2s;
`;
export const SaveSettings = styled(Button)`
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: 30px;
  padding: 0.8rem 1.3rem;
  width: fit-content;
  border: 0;
`;

export const CancelSettings = styled(Button)`
  background: ${({ theme }) => theme.colors.transparent};
  border: ${({ theme }) => `1px solid ${theme.colors.secondary}`};
  border-radius: 30px;
  padding: 0.8rem 1.3rem;
  width: fit-content;
  &:hover {
    background: ${({ theme }) => theme.colors.transparent};
    border: ${({ theme }) => `1px solid ${theme.colors.cyan}`};
  }
`;
