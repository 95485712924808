import { createContext, useContext } from "react";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { currencyList } from "@/apis/coinbase";
import { INftSortAttribute } from "@/apis/collection";
import { SupportedChainId } from "@/constants/chains";

export enum ActivityPeriod {
  "1day" = "1D",
  "7day" = "7D",
  "30day" = "30D",
}

export enum LendPageViewStyle {
  list = "list",
  grid = "grid",
}
export enum LendPageAssetView {
  historical = "historical",
  current = "current",
}
export enum LendView {
  vault = "vault",
  p2p = "p2p",
}
export enum LendCollectionView {
  assets = "assets",
  loanOffers = "loanOffers",
}

export type IActivityPeriodType = keyof typeof ActivityPeriod;
interface ILendPageContext {
  selectedChain: SupportedChainId;
  setSelectedChain: (chainId: SupportedChainId) => void;
  selectedVault: number;
  setSelectedVault: (vaultId: number) => void;
  selectedActivityPeriod: IActivityPeriodType;
  setSelectedActivityPeriod: (activityPeriod: IActivityPeriodType) => void;
  selectedSortAttribute: INftSortAttribute;
  setSelectedSortAttribute: (sortAttribute: INftSortAttribute) => void;
  selectedLendViewStyle: LendPageViewStyle;
  setSelectedLendViewStyle: (view: LendPageViewStyle) => void;
  selectedTokenId: string;
  setSelectedTokenId: (tokenId: string) => void;
  selectedCollectionSearch: string;
  setSelectedCollectionSearch: (search: string) => void;
  selectedAttributes: {
    [key: string]: string[];
  };
  setSelectedAttributes: (attributes: { [key: string]: string[] }) => void;
  selectedPriceRange: {
    min?: number;
    max?: number;
  };
  setSelectedPriceRange: (range: { min?: number; max?: number }) => void;
  selectedRarityRange: {
    min?: number;
    max?: number;
  };
  setSelectedRarityRange: (range: { min?: number; max?: number }) => void;
  selectedPriceCurrency?: SupportedCurrenciesType;
  setSelectedPriceCurrency: (priceCurrency: SupportedCurrenciesType) => void;
  selectedCurrenciesFilter: string[];
  setSelectedCurrenciesFilter: (currencies: string[]) => void;
  selectedView: LendView;
  setSelectedView: (view: LendView) => void;
  selectedCurrency: currencyList;
  setSelectedCurrency: (currency: currencyList) => void;
  showFilterAttributes: boolean;
  setShowFilterAttributes: (show: boolean) => void;
  selectedLendCollectionView: LendCollectionView;
  setSelectedLendCollectionView: (view: LendCollectionView) => void;
}

const LendPageContext = createContext<ILendPageContext>({
  selectedChain: SupportedChainId.MAINNET,
  selectedTokenId: "",
  selectedActivityPeriod: "1day",
  selectedSortAttribute: "floorAskPrice",
  selectedLendViewStyle: LendPageViewStyle.grid,
  selectedVault: 0, //All vaults
  selectedCollectionSearch: "",
  showFilterAttributes: false,
  selectedAttributes: {},
  selectedPriceRange: {
    min: undefined,
    max: undefined,
  },
  selectedRarityRange: {
    min: undefined,
    max: undefined,
  },

  selectedLendCollectionView: LendCollectionView.assets,
  setSelectedLendCollectionView: () => {},
  selectedCurrenciesFilter: [],
  setSelectedCurrenciesFilter: () => {},
  selectedCurrency: currencyList.eth,
  setSelectedCurrency: () => {},
  selectedView: LendView.p2p,
  setSelectedView: () => {},
  setSelectedPriceRange: () => {},
  setSelectedRarityRange: () => {},
  setSelectedAttributes: () => {},
  setShowFilterAttributes: () => {},
  setSelectedCollectionSearch: () => {},
  setSelectedSortAttribute: () => {},
  setSelectedChain: () => {},
  setSelectedActivityPeriod: () => {},
  setSelectedTokenId: () => {},
  setSelectedVault: () => {},
  setSelectedLendViewStyle: () => {},
  setSelectedPriceCurrency: () => {},
});

const useLendPageContext = () => {
  return useContext(LendPageContext);
};

export const useLendSelectors = () => {
  const {
    selectedLendViewStyle,
    setSelectedLendViewStyle,
    selectedSortAttribute,
    setSelectedSortAttribute,
    selectedChain,
    setSelectedChain,
    selectedActivityPeriod,
    setSelectedActivityPeriod,
    selectedVault,
    setSelectedVault,
    selectedTokenId,
    setSelectedTokenId,
    selectedCollectionSearch,
    setSelectedCollectionSearch,
    showFilterAttributes,
    setShowFilterAttributes,
    selectedAttributes,
    setSelectedAttributes,
    selectedPriceRange,
    setSelectedPriceRange,
    selectedRarityRange,
    setSelectedRarityRange,
    selectedCurrency,
    setSelectedCurrency,
    selectedCurrenciesFilter,
    setSelectedCurrenciesFilter,
    selectedPriceCurrency,
    setSelectedPriceCurrency,
    selectedView,
    setSelectedView,
    selectedLendCollectionView,
    setSelectedLendCollectionView,
  } = useLendPageContext();
  return {
    selectedLendViewStyle,
    setSelectedLendViewStyle,
    selectedSortAttribute,
    setSelectedSortAttribute,
    selectedChain,
    setSelectedChain,
    selectedActivityPeriod,
    setSelectedActivityPeriod,
    selectedVault,
    setSelectedVault,
    selectedTokenId,
    setSelectedTokenId,
    selectedCollectionSearch,
    setSelectedCollectionSearch,
    showFilterAttributes,
    setShowFilterAttributes,
    selectedAttributes,
    setSelectedAttributes,
    selectedPriceRange,
    setSelectedPriceRange,
    selectedRarityRange,
    setSelectedRarityRange,
    selectedCurrency,
    setSelectedCurrency,
    selectedCurrenciesFilter,
    setSelectedCurrenciesFilter,
    selectedPriceCurrency,
    setSelectedPriceCurrency,
    selectedView,
    setSelectedView,
    selectedLendCollectionView,
    setSelectedLendCollectionView,
  };
};

export const useLendPageViewType = () => {
  const { selectedLendViewStyle, setSelectedLendViewStyle } = useLendPageContext();
  return {
    selectedLendViewStyle,
    setSelectedLendViewStyle,
  };
};
