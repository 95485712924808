import { useMemo } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Text } from "@cyanco/components/theme/v3";

import { CAPS_MAPPED_BY_ADDRESS } from "@/config";
import { bigNumToFloat, numberWithCommas } from "@/utils";

import { ISelectedNft } from "../../../types";
import { ApeCoinStakingInput } from "../../common";

export const ApeCoinUnstakingWithPlan = ({
  selectedMainNft,
  selectedCollection,
}: {
  selectedMainNft: ISelectedNft | null;
  selectedCollection: string;
}) => {
  const nft = selectedMainNft;
  const cap = useMemo(() => {
    return nft ? CAPS_MAPPED_BY_ADDRESS[nft.address] : CAPS_MAPPED_BY_ADDRESS[selectedCollection];
  }, [nft, selectedCollection]);
  const stakedAmount = useMemo(() => {
    if (!nft) return 0;
    if (!nft.apeStaking.stakedAmount) return 0;
    return bigNumToFloat(nft.apeStaking.stakedAmount);
  }, [nft, cap]);
  const borrowedAmount = useMemo(() => {
    if (!nft) return 0;
    if (!nft.apeStaking.plan) return 0;
    return bigNumToFloat(nft.apeStaking.plan.borrowedApe);
  }, [nft]);
  const pendingRewards = useMemo(() => {
    if (!nft) return 0;
    if (!nft.apeStaking.earnedAmount) return 0;
    return bigNumToFloat(nft.apeStaking.earnedAmount);
  }, [nft]);
  return (
    <Flex direction="column" gap="1.2rem">
      <Flex direction="column" gap="0.3rem">
        <Flex justifyContent="space-between" p="0 2px">
          <Text color="gray0" size="xs" weight="600">
            {nft?.apeStaking.plan ? `Borrowed ApeCoin from Vault` : `Select ApeCoin`}
          </Text>
          <Text color="gray0" size="xs" weight="500">
            {`${`Max`}: ${numberWithCommas(stakedAmount)} APE`}
          </Text>
        </Flex>
        <ApeCoinStakingInput disabled={true} onInputChange={() => {}} stakingAmount={stakedAmount.toString()} />
      </Flex>
      <StakedBox>
        <Flex direction="column" w="100%" gap="0.3rem">
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Total Staked`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {numberWithCommas(stakedAmount, 2)} APE
            </Text>
          </Flex>
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Borrowed Ape`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {numberWithCommas(borrowedAmount, 2)} APE
            </Text>
          </Flex>
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Rewards`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {numberWithCommas(pendingRewards, 2)} APE
            </Text>
          </Flex>
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Rewards APY`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              -
            </Text>
          </Flex>
        </Flex>
      </StakedBox>
    </Flex>
  );
};

const StakedBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 1rem 0.8rem;
`;
