import { captureException, withScope } from "@sentry/react";

import { mapBeError } from "./api";
import { mapContractError } from "./contract";
import { CONTACT_US, IMappedError, defaultError } from "./msgs";

export const mapAndLogError = (error: any, user?: string): IMappedError => {
  if (error.title && error.msg) {
    return error;
  }
  const beError = mapBeError(error);
  if (beError) {
    return beError;
  }
  const contractError = mapContractError(error);
  if (contractError) {
    return contractError;
  }
  // send unknown error to sentry
  const errorMsg = error?.response?.data?.message ?? error?.error?.message ?? error?.message;
  if (user) {
    withScope(scope => {
      const errorCode = error?.code;
      const errorCodeFromWallet = error?.error?.code;
      scope.setLevel("error");
      scope.setTag("unknown-error", "contract");
      scope.setExtra("user", user);
      if (errorMsg) {
        scope.setExtra("errorMessage", errorMsg);
      }
      if (errorCode || errorCodeFromWallet) {
        scope.setExtra("errorCode", errorCode || errorCodeFromWallet);
      }
      captureException(error);
    });
    return {
      ...defaultError,
      msg: errorMsg ? errorMsg : defaultError.msg,
      description: errorMsg ? CONTACT_US : undefined,
    };
  }
  return {
    ...defaultError,
    msg: errorMsg ? errorMsg : CONTACT_US,
    description: errorMsg ? CONTACT_US : undefined,
  };
};
