import dayjs from "dayjs";
import { BigNumber } from "ethers";
import orderBy from "lodash.orderby";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { PriceChart, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { Decrease, Increase, MeatballMenu } from "@cyanco/components/theme/v3/icons";
import { CyanC } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { DAPP_URL } from "@/config";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { bigNumToFixedStr, bigNumToFloat } from "@/utils";

import { calculateChangePercent } from "../../VaultPageContext";
import { VaultMenu } from "./Menu";

const getHistoriesByOneDay = (
  histories: Array<{ price: BigNumber; createdAt: string }>,
  decimals: number,
  group: (a: string) => string,
) => {
  const maxValueDates = histories.reduce<{
    [key: string]: { price: BigNumber; createdAt: string };
  }>((acc, currVaultHistory) => {
    const vaultHistoryDate = group(currVaultHistory.createdAt);
    const prevVaultHistory = acc[vaultHistoryDate];
    if (!prevVaultHistory || BigNumber.from(currVaultHistory.price).gt(BigNumber.from(prevVaultHistory.price))) {
      acc[vaultHistoryDate] = currVaultHistory;
    }
    return acc;
  }, {});
  const graphReadyData = Object.values(maxValueDates).map(({ createdAt, price }) => ({
    name: dayjs(createdAt).format("DD MMM YYYY"),
    price: bigNumToFloat(price, decimals).toPrecision(5),
  }));
  return graphReadyData;
};

export const VaultCard = ({ vault }: { vault: IVault }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const priceChange = useMemo(() => {
    if (!vault.price || !vault.history) return null;
    const now = dayjs();
    const date = now.subtract(365, "day");
    const filteredVaultHistory = vault.history.filter(
      price => price.price && dayjs(price.createdAt).isBefore(now) && dayjs(price.createdAt).isAfter(date),
    );
    const prevPrice =
      filteredVaultHistory.length > 0
        ? bigNumToFloat(orderBy(filteredVaultHistory, "createdAt", "asc")[0].price, vault.decimals)
        : null;
    return calculateChangePercent(bigNumToFloat(vault.price, vault.decimals), prevPrice);
  }, [vault]);

  const oneYear = useMemo(() => {
    return getHistoriesByOneDay(orderBy(vault.history, "createdAt"), vault.decimals, createdAt => {
      return `${dayjs(createdAt).format("DD MMM YYYY")}}`;
    });
  }, [vault.history]);

  const urlPath = useMemo(() => {
    return `vault/${CHAIN_IDS_TO_NAMES[vault.chainId as SupportedChainId]}/${vault.contractAddress}`;
  }, [vault.chainId, vault.contractAddress]);
  return (
    <Container
      onClick={() => navigate(`/${urlPath}`)}
      style={{
        cursor: "pointer",
      }}
    >
      <Flex alignItems="center" gap="10px">
        <VaultImageWrapper style={{ background: vault.colorCode }}>
          <VaultImage src={CyanC} alt={vault.name} />
        </VaultImageWrapper>
        <Flex direction="column" gap="3px">
          <Flex gap="8px">
            <Text color="secondary" size="md" weight="600">
              {vault.name}
            </Text>
            <Text color="gray0" size="md" weight="600">
              {vault.symbol}
            </Text>
          </Flex>
          <Flex gap="5px" alignItems="flex-end">
            <Text color="secondary" size="sm">
              {vault.price ? `${bigNumToFixedStr(vault.price, 2, vault.decimals)} ${vault.currency}` : "N/A"}
            </Text>
            {priceChange !== null && (
              <Flex alignItems="center" gap="1px" mb="2px">
                {priceChange >= 0 ? (
                  <Increase color={theme.colors.green} height={8} width={8} />
                ) : (
                  <Decrease height={10} width={10} />
                )}
                <Text color={priceChange >= 0 ? "green" : "red"} size="xxs">
                  {Math.abs(priceChange).toFixed(2)}%
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <GraphContainer>
        <PriceChart data={oneYear} dataKey="price" labelKey="name" showTooltip={false} />
        <Pill>
          <Text color="secondary" size="xs">
            {`Annual Chart`}
          </Text>
        </Pill>
      </GraphContainer>
      <Flex justifyContent="space-between">
        <Flex direction="column">
          <Text color="secondary" weight="800" size="xs">
            {vault.symbol}
          </Text>
          <Text color="gray0" weight="500" size="xxs">
            {vault.name}
          </Text>
        </Flex>
        <Flex direction="column">
          <Text color="secondary" weight="500" size="xs" textAlign="right">
            {vault.price ? `${bigNumToFixedStr(vault.price, 2, vault.decimals)} ${vault.currency}` : "-"}
          </Text>
        </Flex>
      </Flex>
      <BottomContainer>
        <Text size="xs" color="gray0">
          {vault.estimatedYield ? `${(vault.estimatedYield / 100).toFixed(2)}%` : "-"} ann.yield
        </Text>
        <VaultMenu localUrl={`${DAPP_URL}/${urlPath}`} />
      </BottomContainer>
    </Container>
  );
};

export const VaultCardLoading = () => {
  const theme = useTheme();
  return (
    <Container>
      <Flex alignItems="center" gap="10px">
        <VaultImageWrapper>
          <SkeletonLine w="100%" h="100%" borderRadius="50%" />
        </VaultImageWrapper>
        <Flex direction="column" gap="3px">
          <Flex gap="8px">
            <SkeletonLine w="150px" h="19.5px" />
          </Flex>
          <Flex gap="5px" alignItems="flex-end">
            <SkeletonLine w="100px" h="17px" />
          </Flex>
        </Flex>
      </Flex>
      <GraphContainer>
        <SkeletonLine h="100px" w="100%" />
      </GraphContainer>
      <Flex justifyContent="space-between">
        <Flex direction="column" gap="1px">
          <SkeletonLine w="80px" h="13.5px" />
          <SkeletonLine w="80px" h="12px" />
        </Flex>
        <Flex direction="column" gap="1px">
          <SkeletonLine w="80px" h="13.5px" />
          <SkeletonLine w="80px" h="12px" />
        </Flex>
      </Flex>
      <BottomContainer>
        <SkeletonLine w="100px" h="17px" />
        <MeatballMenu color={theme.colors.gray0} width="15px" />
      </BottomContainer>
    </Container>
  );
};

const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-style: solid;
  padding: 0.6rem;
  padding-bottom: 0.8rem;
  width: 100%;
  width: 310px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.laptop]: 290 }, { [breakpoints.mobile]: 230 }])}
  transition: border-color 0.2s ease-in-out;
  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
  }
`;

const GraphContainer = styled.div`
  height: 120px;
  position: relative;
`;

const VaultImage = styled.img`
  width: 25px;
  height: 25px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.desktop]: 25 }, { [breakpoints.tablet]: 15 }])};
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.desktop]: 25 }, { [breakpoints.tablet]: 15 }])};
`;
const VaultImageWrapper = styled.div`
  width: 37px;
  height: 37px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.desktop]: 37 }, { [breakpoints.tablet]: 25 }])};
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.desktop]: 37 }, { [breakpoints.tablet]: 25 }])};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomContainer = styled(Flex)`
  border-top-width: ${({ theme }) => theme.borderWidth};
  border-top-color: ${({ theme }) => theme.colors.gray20};
  border-top-style: solid;
  padding-top: 10px;
  justify-content: space-between;
  align-items: center;
`;

const Pill = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 30px;
  padding: 0.2rem 0.5rem;
  right: 0;
  bottom: 0;
`;
