import { AuthEngineTypes } from "@walletconnect/auth-client";
import { ProposalTypes, SessionTypes } from "@walletconnect/types";
import { Web3WalletTypes } from "@walletconnect/web3wallet";
import { Logger } from "ethers/lib/utils";
import { useContext, useEffect } from "react";

import { CyanWalletContext } from "./contexts";
import { NotInitialized } from "./errors";

export const useCyanWalletContext = () => {
  const context = useContext(CyanWalletContext);
  if (!context) {
    throw new Error('Only use this hook inside the "CyanWalletProvider"');
  }

  return context;
};

export const useCyanWallet = () => {
  const { cyanWallet } = useCyanWalletContext();
  return cyanWallet;
};

export const useCyanWalletSessions = () => {
  const { cyanWallet, sessionProposals, setSessionProposals, setSessions, sessions } = useCyanWalletContext();
  console.log("[useCyanwalletSessions] sessionProposals", sessionProposals);

  useEffect(() => {
    console.log("cyanWallet.sesionProposals", cyanWallet?.sessionProposals);
    setSessions(Object.values(cyanWallet?.sessions ?? []));
    setSessionProposals(Object.values(cyanWallet?.sessionProposals ?? []));

    const onSessionProposal = (request: Web3WalletTypes.SessionProposal) => {
      setSessionProposals([...sessionProposals, request.params]);
    };

    const onSessionDeleted = ({ topic }: Web3WalletTypes.EventArguments["session_delete"]) => {
      setSessions(sessions.filter(({ topic: _topic }) => topic !== _topic));
    };

    cyanWallet?.addEventHandler("session_proposal", onSessionProposal);
    cyanWallet?.addEventHandler("session_delete", onSessionDeleted);
    return () => {
      cyanWallet?.removeEventHandler("session_delete", onSessionDeleted);
      cyanWallet?.removeEventHandler("session_proposal", onSessionProposal);
    };
  }, [cyanWallet]);

  const disconnectSession = async (topic: string): Promise<void> => {
    if (!cyanWallet) throw new NotInitialized();

    await cyanWallet.disconnectSession(topic);
    setSessions(sessions.filter(({ topic: _topic }) => topic !== _topic));
  };

  const rejectSessionProposal = async (proposal: ProposalTypes.Struct): Promise<void> => {
    if (!cyanWallet) throw new NotInitialized();
    await cyanWallet.rejectSessionProposal(proposal.id);
    setSessionProposals(sessionProposals.filter(({ id }) => id !== proposal.id));
  };

  const approveSessionProposal = async (proposal: ProposalTypes.Struct): Promise<SessionTypes.Struct> => {
    if (!cyanWallet) throw new NotInitialized();

    const session = await cyanWallet.approveSessionProposal(proposal);
    setSessions([...sessions, session]);
    setSessionProposals(sessionProposals.filter(({ id }) => id !== proposal.id));
    return session;
  };

  return { sessions, disconnectSession, sessionProposals, rejectSessionProposal, approveSessionProposal };
};

export const useCyanWalletAuthRequests = (chainId: number) => {
  const { cyanWallet, authRequests, setAuthRequests } = useCyanWalletContext();

  useEffect(() => {
    setAuthRequests([]);

    const onAuthRequest = async (request: Web3WalletTypes.AuthRequest) => {
      setAuthRequests([...authRequests, { ...request.params, id: request.id, pairingTopic: request.topic }]);
    };

    cyanWallet?.addEventHandler("auth_request", onAuthRequest);
    return () => {
      cyanWallet?.removeEventHandler("auth_request", onAuthRequest);
    };
  }, [cyanWallet]);

  const rejectAuthRequest = async (request: AuthEngineTypes.PendingRequest): Promise<void> => {
    if (!cyanWallet) throw new NotInitialized();
    await cyanWallet.rejectAuthRequest(request);
    setAuthRequests(authRequests.filter(({ requester }) => requester.publicKey !== request.requester.publicKey));
  };

  const approveAuthRequest = async (request: AuthEngineTypes.PendingRequest): Promise<void> => {
    if (!cyanWallet) throw new NotInitialized();
    try {
      await cyanWallet.approveAuthRequest(chainId, request);
      setAuthRequests(authRequests.filter(({ requester }) => requester.publicKey !== request.requester.publicKey));
    } catch (e: any) {
      if (e?.code && e?.code === Logger.errors.ACTION_REJECTED) {
        setAuthRequests(authRequests.filter(({ requester }) => requester.publicKey !== request.requester.publicKey));
      }
      throw e;
    }
  };
  return { authRequests, rejectAuthRequest, approveAuthRequest };
};
