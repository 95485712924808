import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { Card, Text } from "@cyanco/components/theme/v3/core";
import { Delegatecash, Revoke, WalletGuard } from "@cyanco/components/theme/v3/images";

const InfoCard = ({
  title,
  url,
  description,
  logoUrl,
  logoText,
  logoBackground,
}: {
  title: string;
  url: string;
  description: string;
  logoUrl: string;
  logoText?: string;
  logoBackground?: string;
}) => {
  return (
    <StyledCard p="0.75rem">
      <a
        href={url}
        style={{ border: "none", textDecoration: "none", color: "inherit" }}
        target="_blank"
        rel="noreferrer"
      >
        <MobileFriendlyContainer>
          <Flex direction="column" alignItems="flex-start" gap="0.5rem">
            <Text color="secondary" size="md" weight="600">
              {title}
            </Text>
            <Flex style={{ height: "33px" }} alignItems="center" gap="8px">
              <img
                src={logoUrl}
                height="100%"
                style={{
                  backgroundColor: logoBackground,
                  padding: logoBackground && "4px",
                  borderRadius: logoBackground && "8px",
                }}
              />

              <Text color="secondary" style={{ fontSize: "30px" }}>
                {logoText}
              </Text>
            </Flex>
            <Text color="gray0" size="xs" weight="500">
              {description}
            </Text>
          </Flex>
        </MobileFriendlyContainer>
      </a>
    </StyledCard>
  );
};

const SecurityModal = () => {
  return (
    <Flex direction="column" gap="20px">
      <InfoCard
        url="https://revoke.cash/"
        title="Keep track of collection permissions"
        logoUrl={Revoke}
        logoBackground="black"
        description="Revoke is a great tool to keep track of which collections, protocols and contracts you are giving wallet permission to."
      />
      <InfoCard
        url="https://delegate.xyz/"
        title="Add a layer of protection when connecting"
        logoUrl={Delegatecash}
        logoText="Delegate"
        description="Using Delegate.xyz will provide a layer of protection so no contract has direct control over your assets."
      />
      <InfoCard
        url="https://www.walletguard.app/"
        title="Double check functions before exeuction"
        logoUrl={WalletGuard}
        description="A fantastic browser extension which checks contract functions before you execute, so you don’t fat finger."
      />
    </Flex>
  );
};

const MobileFriendlyContainer = styled(Flex)`
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    align-items: unset;
  }
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray10} !important;
  :hover {
    border-color: ${({ theme }) => theme.colors.cyan} !important;
  }
`;

export default SecurityModal;
