import { BigNumber } from "ethers";
import { useContext, useEffect, useMemo, useState } from "react";
import { Bell } from "react-feather";
import styled from "styled-components";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { ArrowRight } from "@cyanco/components/theme/v3/icons";
import { UpdatedBNPLEvent } from "@cyanco/contract/abis/CyanPaymentPlanV2";

import { AuthContext } from "@/components/AuthContext/AuthContextProvider";
import { BAKCAddress, BAYCAddress, DISCORD_SERVER_INVITE_LINK, MAYCAddress } from "@/config";
import { usePaymentPlanContract } from "@/hooks/usePaymentPlanContract";
import { ICurrency } from "@/types";
import { getChainExplorerTextForTxn } from "@/utils";

import { useApeCoinStatsContext } from "../ApeCoinStaking/new/ApeCoinStatsContext";
import { ApeCoinStakingApyButton } from "../ApeCoinStaking/new/components/common";
import { BNPLStatuses, IBNPLStatus } from "../Bnpl/bnpl.types";
import { useWeb3React } from "../Web3ReactProvider";
import { ItemsMetadata } from "./ItemsMetadata";
import { PlanNotificationSettings } from "./PlanNotificationSettings";

type IProps = {
  plan: {
    address: string;
    tokenId: string;
    planId: number;
    status: IBNPLStatus;
    planType?: "bnpl" | "pawn";
    price: BigNumber;
    collectionName: string;
    imageUrl: string | null;
    currency: ICurrency;
    marketName?: string;
    rarityStat?: string;
    error?: string;
    autoRepayStatus?: boolean;
    owner?: string;
  };
};
export const BnplDetailProgress = (args: IProps) => {
  const { plan } = args;
  const { chainId } = useWeb3React();
  const { fetchUserBe } = useContext(AuthContext);
  const { setModalContent, onBackModal } = useModal();
  const { cyanWallet } = useCyanWalletContext();
  const { contract: paymentPlanContract, filters: paymentPlanEventFilters } = usePaymentPlanContract();
  const [selectedStep, setSelectedStep] = useState<BnplCreateSteps>(() => {
    if (plan.status === BNPLStatuses.Pending) {
      return BnplCreateSteps.VaultFunding;
    }
    if (plan.status === BNPLStatuses.Funded) {
      return BnplCreateSteps.PurchasingNft;
    }
    if (plan.status === BNPLStatuses.Rejected) {
      return BnplCreateSteps.PurchasingNft - 1;
    }
    return BnplCreateSteps.Done;
  });

  useEffect(() => {
    const onUpdateBnplEvent = (planId: BigNumber, status: number) => {
      if (planId.toNumber() !== plan.planId) return;
      if (status === 1) {
        // status for "funded"
        setSelectedStep(BnplCreateSteps.PurchasingNft);
      }

      if (status === 2) {
        // status for "activated"
        setSelectedStep(BnplCreateSteps.Done);
      }
    };

    const onStepChange = async (step: BnplCreateSteps) => {
      switch (step) {
        case BnplCreateSteps.VaultFunding:
        case BnplCreateSteps.PurchasingNft: {
          paymentPlanContract?.on<UpdatedBNPLEvent>(paymentPlanEventFilters.UpdatedBNPL(), onUpdateBnplEvent);
          return;
        }
      }
    };

    onStepChange(selectedStep);
    return () => {
      paymentPlanContract?.off<UpdatedBNPLEvent>(paymentPlanEventFilters.UpdatedBNPL(), onUpdateBnplEvent);
    };
  }, [selectedStep, cyanWallet]);

  const stepMarkFiltered = useMemo(() => {
    const steps = getBnplCreateStepMarks(chainId, selectedStep);
    return steps.filter(
      item =>
        !(
          (!plan?.autoRepayStatus && item.value === BnplCreateSteps.SettingUpAutoRepayments) ||
          (cyanWallet && item.value === BnplCreateSteps.CreatingCyanWallet)
        ),
    );
  }, [plan.autoRepayStatus, cyanWallet, chainId, selectedStep]);

  const openSetupAlertsModal = async () => {
    try {
      await fetchUserBe();
      setModalContent({
        title: `BNPL Started`,
        content: <PlanNotificationSettings items={[plan]} planType="bnpl" />,
        onBack: onBackModal,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const { poolsWithBorrow } = useApeCoinStatsContext();

  const apy = useMemo(() => {
    if (plan.address.toLowerCase() == BAYCAddress.toLowerCase()) {
      return poolsWithBorrow.BAYC.apy;
    }
    if (plan.address.toLowerCase() == MAYCAddress.toLowerCase()) {
      return poolsWithBorrow.MAYC.apy;
    }
    if (plan.address.toLowerCase() == BAKCAddress.toLowerCase()) {
      return poolsWithBorrow.BAKC.apy;
    }
    return null;
  }, [poolsWithBorrow, plan]);

  const handleApyClick = () => {
    window.open("/ape-coin", "_blank");
  };

  return (
    <Flex gap="5x" direction="column">
      <ItemsMetadata
        items={[plan]}
        planType="bnpl"
        isCreating={selectedStep !== BnplCreateSteps.Done && plan.status !== BNPLStatuses.Rejected}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl=""
          failedMark={
            plan.status === BNPLStatuses.Rejected
              ? {
                  value: selectedStep,
                  title: `Protocol Rejection`,
                  description: (
                    <span
                      style={{
                        width: "max-content",
                      }}
                    >
                      {`The loan was rejected. Contact us on`}{" "}
                      <TextLink href={DISCORD_SERVER_INVITE_LINK} target="_blank">
                        Discord
                      </TextLink>{" "}
                      for details.
                    </span>
                  ),
                }
              : undefined
          }
        />
      </Box>

      {selectedStep === BnplCreateSteps.Done && (
        <Flex direction="column" gap="10px">
          <StyledConfirmButton onClick={openSetupAlertsModal}>
            <Flex gap="4px" justifyContent="center" alignItems="center">
              <Bell height={16} width={16} strokeWidth="3px" />
              <Text color="black" size="sm" weight="700">
                {`Setup Notifications`}
              </Text>
              <ArrowRight />
            </Flex>
          </StyledConfirmButton>
          {apy && <ApeCoinStakingApyButton onClick={handleApyClick} apy={apy} />}
        </Flex>
      )}
    </Flex>
  );
};
enum BnplCreateSteps {
  TokenApproval = 1,
  CreatingCyanWallet = 2,
  SettingUpAutoRepayments = 3,
  SettlingDownPayment = 4,
  VaultFunding = 5,
  PurchasingNft = 6,
  Done = 7,
}

const getBnplCreateStepMarks = (chainId: number, step: BnplCreateSteps) => {
  return [
    {
      value: BnplCreateSteps.TokenApproval,
      title: `Approve Token`,
    },
    {
      value: BnplCreateSteps.CreatingCyanWallet,
      description: `A new wallet is created on the first purchase on Cyan`,
      title: `Creating Cyan Wallet`,
    },
    {
      value: BnplCreateSteps.SettingUpAutoRepayments,
      title: `Setting up auto-repayments`,
    },
    {
      value: BnplCreateSteps.SettlingDownPayment,
      title: `Down payment settled`,
    },
    {
      value: BnplCreateSteps.VaultFunding,
      title: `Funding from Vault`,
      description: `This step requires an automated execution. This may take up to 15 minutes to process.`,
    },
    {
      value: BnplCreateSteps.PurchasingNft,
      title: `Purchasing NFT`,
      description:
        step === BnplCreateSteps.PurchasingNft
          ? `This step requires an automated execution. This may take up to 15 minutes to process.`
          : getChainExplorerTextForTxn(chainId),
    },
  ];
};

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;

const TextLink = styled.a`
  color: ${p => p.theme.colors.secondary};
  font-family: Inter;
  font-size: 12px;
`;
