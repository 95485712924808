import dayjs from "dayjs";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, SkeletonLine, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { ICollectionBe } from "@/apis/collection/types";
import { IP2PLoanOffer } from "@/apis/p2p/types";
import { useAppContext } from "@/components/AppContextProvider";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { bigNumToFloat, displayInUSD, numberWithCommas, shortenAddress, shortenName } from "@/utils";

export const LoanOfferRowLoading = () => {
  return (
    <Row>
      <Flex alignItems="center" gap="8px">
        <StyledImgLoading />
        <RowLoadingText />
      </Flex>
      <Hidden tabletDown>
        <Flex gap="2px" direction="column">
          <RowLoadingText sub />
          <RowLoadingText />
        </Flex>
      </Hidden>
      <RowLoadingText />
      <RowLoadingText />
      <RowLoadingText />
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <RowLoadingText />
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
    </Row>
  );
};

export const LoanOfferHeader = () => {
  const theme = useTheme();
  return (
    <HeaderContainer>
      <HeaderText color="secondary">{`Item`}</HeaderText>
      <Hidden tabletDown>
        <Flex gap="5px" alignItems="center">
          <HeaderText color="secondary" textWrap={false}>{`Appraisal Value`}</HeaderText>
          <Tooltip>
            <HelpCircle height={16} width={16} color={theme.colors.secondary} />
            <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
              <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                <div>{`Buy Now price is an estimate based on a floor price NFT for a 3-month, 25% down payment loan.`}</div>
              </Text>
            </TooltipText>
          </Tooltip>
        </Flex>
      </Hidden>
      <HeaderText color="secondary">{`Lender`}</HeaderText>
      <HeaderText color="secondary">{`Loan Amount`}</HeaderText>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Interest`}</HeaderText>
      </Hidden>
      <HeaderText color="secondary">{`APR`}</HeaderText>
      <HeaderText color="secondary">{`Duration`}</HeaderText>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Expiry`}</HeaderText>
      </Hidden>
    </HeaderContainer>
  );
};

type IProps = {
  offer: IP2PLoanOffer;
  collection: ICollectionBe;
  blockchainTimestamp: number;
};
export const LoanOfferRow: React.FC<IProps> = ({ offer, collection, blockchainTimestamp }) => {
  const { usdPrice } = useAppContext();
  const theme = useTheme();
  const getPrice = useMemo(() => {
    if (offer.tokenId) {
      return "-";
    }
    return !collection.floorAsk.price
      ? "-"
      : `${numberWithCommas(
          bigNumToFloat(collection.floorAsk.price.amount.raw, collection.floorAsk.price.currency.decimals),
          2,
        )} ${collection.floorAsk.price?.currency.symbol}`;
  }, [collection, offer]);
  const getPriceInUsd = useMemo(() => {
    if (offer.tokenId) {
      return "-";
    }
    return !collection.floorAsk.price
      ? "-"
      : `${displayInUSD(
          bigNumToFloat(collection.floorAsk.price.amount.raw, collection.floorAsk.price.currency.decimals) *
            usdPrice[collection.floorAsk.price.currency.symbol],
        )}`;
  }, [collection, offer, usdPrice]);
  const loanAmount = useMemo(() => {
    return `${numberWithCommas(bigNumToFloat(offer.amount, offer.currency.decimal), 2)} ${offer.currency.symbol}`;
  }, [offer]);
  const loanAmountInUsd = useMemo(() => {
    return `${displayInUSD(bigNumToFloat(offer.amount, offer.currency.decimal) * usdPrice[offer.currency.symbol])}`;
  }, [offer, usdPrice]);
  const expiry = useMemo(() => {
    const dueDate = dayjs(offer.signatureExpiry);
    const difference = dueDate.diff(dayjs(blockchainTimestamp), "second");
    return getPaymentInterval(difference);
  }, [offer.signatureExpiry, blockchainTimestamp]);
  const apr = useMemo(() => {
    return (365 / (offer.term / 60 / 60 / 24)) * (offer.interestRate / 100);
  }, [offer.interestRate]);
  return (
    <Row
      style={{
        background: offer.tokenId ? theme.colors.primary : "#00FFFF26",
      }}
    >
      {offer.tokenId ? (
        <Flex alignItems="center" gap="8px">
          <StyledImg src={offer.metadata ? offer.metadata.imageUrl : NoImage} hasImage={!!offer.metadata} />
          <RowText color={"secondary"}> #{shortenName(offer.tokenId, 10, 6)}</RowText>
        </Flex>
      ) : (
        <Flex alignItems="center" gap="8px">
          <StyledImg src={collection.image ?? NoImage} hasImage={!!collection.image} />
          <Flex direction="column">
            <RowText color={"secondary"}> {shortenName(collection.name, 10, 6)}</RowText>
            <RowText color={"gray0"} sub>
              {`${offer.signatureUsageLimit} NFTs`}
            </RowText>
          </Flex>
        </Flex>
      )}
      <Hidden tabletDown>
        <Flex direction="column">
          <RowText color={"secondary"}>{getPrice}</RowText>
          <RowText color="gray0" sub>
            {getPriceInUsd}
          </RowText>
        </Flex>
      </Hidden>
      <RowText color="secondary" weight="500">
        {shortenAddress(offer.lenderAddress)}
      </RowText>
      <Flex direction="column">
        <RowText color={"secondary"}>{loanAmount}</RowText>
        <RowText color="gray0" sub>
          {loanAmountInUsd}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <RowText color="secondary" weight="500">
          {offer.interestRate / 100}%
        </RowText>
      </Hidden>
      <RowText color="secondary" weight="500">
        {" "}
        {apr.toFixed(2)}%
      </RowText>
      <RowText color="secondary" weight="500">
        {getPaymentInterval(offer.term)}
      </RowText>
      <Hidden tabletDown>
        <RowText color="secondary" weight="500">
          {expiry}
        </RowText>
      </Hidden>
    </Row>
  );
};

const Row = styled.div<{ selected?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  grid-template-columns: 2fr 1.5fr 1.5fr 1.7fr 1fr 0.7fr 0.7fr 1fr;
  padding: 13px 15px 13px 15px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.gray10 : "transparent")};
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2fr 1fr 1fr 1fr 1fr" }])};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 10px 14px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 14 },
    { [breakpoints.laptop]: 12 },
    { [breakpoints.tablet]: 12 },
  ])};
`;

const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

const HeaderContainer = styled(Row)`
  padding: 14px 19px 12px 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "14px 19px 12px 18px" },
    { [breakpoints.laptop]: "14px 10px 12px 40px" },
    { [breakpoints.tablet]: "10px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
`;

const StyledImg = styled.img<{ hasImage?: boolean }>`
width: 36px;
${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
border-radius: ${({ theme }) => theme.borderRadius};
filter: ${({ hasImage, theme }) =>
  !hasImage &&
  theme.theme === "light" &&
  "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
} `;

const RowLoadingText = styled(SkeletonLine)<{ sub?: boolean }>`
  width: 50%;
  height: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("height", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])};
`;
const StyledImgLoading = styled(SkeletonLine)`
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  ${getStyleWithMediaQuery("min-width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("min-height", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("max-height", "px", [{ [breakpoints.mobile]: 24 }])}
  border-radius: ${({ theme }) => theme.borderRadius};
`;
