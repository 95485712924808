import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { SystemMessage } from "@cyanco/components/theme/v3";
import { Button, Card, Hidden, Loader, Text, useModal } from "@cyanco/components/theme/v3/core";
import { Calendar, CheckCircle, Discord, Email, Send } from "@cyanco/components/theme/v3/icons";

import { updateUserProfile } from "@/apis/user";

import { getGoogleCalendarLink, shortenName } from "../../utils";
import { AlertTypes, IAlertTypes, IStatusBoxTypes, StatusBoxTypes } from "../../utils/types";
import { IPawn } from "../Account/pawn.types";
import { AuthContext } from "../AuthContext/AuthContextProvider";
import { IBNPL } from "../Bnpl/bnpl.types";
import UpdateDiscordComponent from "./DiscordComponent";
import UpdateEmailComponent from "./EmailComponent";
import UpdateTelegramComponent from "./TelegramComponent";

const SetupAlertsModal = ({
  planData,
  planType,
  selectedAlert,
  statusBoxType,
  onClose,
  autoClose,
  onNext,
}: {
  planData?: IPawn | IBNPL | null;
  planType?: "pawn" | "bnpl";
  selectedAlert?: IAlertTypes;
  statusBoxType?: IStatusBoxTypes;
  onClose: () => void;
  autoClose?: boolean;
  onNext: (x: JSX.Element) => void;
}) => {
  const { user, setUser, userProfile, setUserProfile } = useContext(AuthContext);
  const theme = useTheme();
  const [displayEmail, setDisplayEmail] = useState<string>("");
  const [displayDiscordUserName, setDisplayDiscordUserName] = useState<string>("");
  const [displayTelegramUsername, setDisplayTelegramStatus] = useState<string>("");

  const [isEmailDisconnectLoading, setIsEmailDisconnectLoading] = useState<boolean>(false);
  const [isDiscordDisconnectLoading, setIsDiscordDisconnectLoading] = useState<boolean>(false);
  const [isTelegramDisconnectLoading, setIsTelegramDisconnectLoading] = useState<boolean>(false);

  const { onBackModal } = useModal();

  const [cyanAlertTypes, setCyanAlertTypes] = useState({
    receivePaymentAlerts: false,
  });

  const disconnectEmail = async () => {
    setIsEmailDisconnectLoading(true);
    if (!user?.token) return;
    try {
      await updateUserProfile({
        token: user.token,
        email: null,
        ...cyanAlertTypes,
      });
      setDisplayEmail("");
      setUserProfile({ ...userProfile, email: "", ...cyanAlertTypes });
      autoClose && onClose();
    } catch (err: unknown) {
      const axiosError = err as AxiosError;
      if (axiosError.response && (axiosError.response.status === 403 || axiosError.response.status === 401)) {
        setUser({ token: "", wallet: "", cyanWallet: "" });
        setUserProfile({
          email: "",
          discordUsername: "",
          telegramUsername: "",
          receivePaymentAlerts: false,
        });
      }
      console.error(err);
    }
    setIsEmailDisconnectLoading(false);
  };

  const disconnectDiscord = async () => {
    if (!user?.token) return;
    setIsDiscordDisconnectLoading(true);
    try {
      await updateUserProfile({
        token: user.token,
        discordUsername: null,
        ...cyanAlertTypes,
      });
      setDisplayEmail("");
      setUserProfile({ ...userProfile, discordUsername: "", ...cyanAlertTypes });
      autoClose && onClose();
    } catch (err: unknown) {
      const axiosError = err as AxiosError;
      if (axiosError.response && (axiosError.response.status === 403 || axiosError.response.status === 401)) {
        setUser({ token: "", wallet: "", cyanWallet: "" });
        setUserProfile({
          email: "",
          discordUsername: "",
          telegramUsername: "",
          receivePaymentAlerts: false,
        });
      }
      console.error(err);
    }
    setIsDiscordDisconnectLoading(false);
  };

  const disconnectTelegram = async () => {
    if (!user?.token) return;
    setIsTelegramDisconnectLoading(true);
    try {
      await updateUserProfile({
        token: user.token,
        telegramUsername: null,
        ...cyanAlertTypes,
      });
      setDisplayTelegramStatus("");
      setUserProfile({ ...userProfile, telegramUsername: "", ...cyanAlertTypes });
      autoClose && onClose();
    } catch (err: unknown) {
      const axiosError = err as AxiosError;
      if (axiosError.response && (axiosError.response.status === 403 || axiosError.response.status === 401)) {
        setUser({ token: "", wallet: "", cyanWallet: "" });
        setUserProfile({
          email: "",
          discordUsername: "",
          telegramUsername: "",
          receivePaymentAlerts: false,
        });
      }
      console.error(err);
    }
    setIsTelegramDisconnectLoading(false);
  };

  useEffect(() => {
    if (!user?.token) return;
    setDisplayEmail(userProfile.email);
    setDisplayDiscordUserName(userProfile.discordUsername);
    setDisplayTelegramStatus(userProfile.telegramUsername);
    setCyanAlertTypes({
      receivePaymentAlerts: userProfile.receivePaymentAlerts,
    });

    if (selectedAlert === AlertTypes.Email && userProfile.email === "") {
      onNext(<UpdateEmailComponent onBack={onBackModal} />);
    } else if (selectedAlert === AlertTypes.Discord && userProfile.discordUsername === "") {
      onNext(<UpdateDiscordComponent onBack={onBackModal} />);
    }
  }, [userProfile, user]);

  return (
    <Flex direction="column" gap="0.5rem">
      {statusBoxType !== undefined && (
        <SystemMessage
          title={statusBoxType === StatusBoxTypes.Success ? `Success!` : `Link Expired`}
          msg={
            statusBoxType === StatusBoxTypes.Success
              ? `Your email address has successfully been added!`
              : `Failed to process!`
          }
          description={
            statusBoxType === StatusBoxTypes.Success
              ? `If you would like to change your email again at any time, please disconnect and input your new address.`
              : `This email address has already been linked. If you would like to change your address, please disconnect and start again.`
          }
          variant={statusBoxType === StatusBoxTypes.Success ? "success" : "error"}
        />
      )}
      <Card p="0.75rem">
        <Flex direction="column" gap="1.5rem">
          <MobileFriendlyContainer>
            <div style={{ width: "80%" }}>
              <Flex direction="row" alignItems="center" gap="0.5rem" mb="0.3rem">
                <Email color={theme.colors.secondary} width={12} height={12} />
                <Text size="sm" weight="700" color="secondary">
                  {`Email`}
                </Text>
              </Flex>
              {displayEmail ? (
                <Flex direction="row" alignItems="center" gap="0.2rem">
                  <Hidden tabletDown>
                    <Text size="sm" weight="700" color="secondary">
                      {shortenName(displayEmail, 26, 15)}
                    </Text>
                  </Hidden>
                  <Hidden tabletUp>
                    <Text size="sm" weight="700" color="secondary">
                      {shortenName(displayEmail, 30, 15, 10)}
                    </Text>
                  </Hidden>
                  <CheckCircle color="cyan" />
                </Flex>
              ) : (
                <Flex direction="row" alignItems="center" gap="0.2rem">
                  <Text size="sm" weight="500" color="gray80">
                    {`Not connected yet`}
                  </Text>
                </Flex>
              )}
            </div>
            <ButtonContainer>
              {displayEmail ? (
                <RemoveButton variant="outlined" onClick={disconnectEmail} disabled={isEmailDisconnectLoading}>
                  {isEmailDisconnectLoading ? (
                    <Loader stroke="red" size="12px" />
                  ) : (
                    <Text size="xs" weight="600" color="red">
                      {`Remove`}
                    </Text>
                  )}
                </RemoveButton>
              ) : (
                <ConnectButton variant="outlined" onClick={() => onNext(<UpdateEmailComponent onBack={onBackModal} />)}>
                  <Text size="xs" weight="600" color="cyan">
                    {`Connect`}
                  </Text>
                </ConnectButton>
              )}
            </ButtonContainer>
          </MobileFriendlyContainer>
        </Flex>
      </Card>
      <Card p="0.75rem">
        <Flex direction="column" gap="0.7rem">
          <MobileFriendlyContainer>
            <div style={{ width: "80%" }}>
              <Flex direction="row" alignItems="center" gap="0.5rem" mb="0.3rem">
                <Discord color={theme.colors.secondary} width={12} height={12} />
                <Text size="sm" weight="700" color="secondary">
                  {`Discord`}
                </Text>
              </Flex>
              {!displayDiscordUserName && (
                <Flex direction="row" alignItems="center" gap="0.3rem">
                  <Text size="sm" color="gray80">
                    {`Not connected yet`}
                  </Text>
                </Flex>
              )}
              {displayDiscordUserName && (
                <Flex direction="row" alignItems="center" gap="0.3rem">
                  <Text size="sm" weight="700" color="secondary">
                    {displayDiscordUserName}
                  </Text>
                  <CheckCircle color="cyan" />
                </Flex>
              )}
            </div>
            <ButtonContainer>
              {displayDiscordUserName ? (
                <RemoveButton variant="outlined" onClick={disconnectDiscord} disabled={isDiscordDisconnectLoading}>
                  {isDiscordDisconnectLoading ? (
                    <Loader stroke="red" size="12px" />
                  ) : (
                    <Text size="xs" weight="600" color="red">
                      {`Remove`}
                    </Text>
                  )}
                </RemoveButton>
              ) : (
                <ConnectButton
                  variant="outlined"
                  onClick={() => onNext(<UpdateDiscordComponent onBack={onBackModal} />)}
                >
                  <Text size="xs" weight="600" color="cyan">
                    {`Connect`}
                  </Text>
                </ConnectButton>
              )}
            </ButtonContainer>
          </MobileFriendlyContainer>
        </Flex>
      </Card>
      <Card p="0.75rem">
        <Flex direction="column" gap="0.7rem">
          <MobileFriendlyContainer>
            <div style={{ width: "80%" }}>
              <Flex direction="row" alignItems="center" gap="0.5rem" mb="0.3rem">
                <Send color={theme.colors.secondary} width={12} height={12} />
                <Text size="sm" weight="700" color="secondary">
                  {`Telegram`}
                </Text>
              </Flex>
              {!displayTelegramUsername ? (
                <Flex direction="row" alignItems="center" gap="0.3rem">
                  <Text size="sm" color="gray80">
                    {`Not connected yet`}
                  </Text>
                </Flex>
              ) : (
                <Flex direction="row" alignItems="center" gap="0.3rem">
                  <Text size="sm" color="cyan">
                    {`Connected`}
                  </Text>
                  <CheckCircle color="cyan" />
                </Flex>
              )}
            </div>
            <ButtonContainer>
              {displayTelegramUsername ? (
                <RemoveButton variant="outlined" onClick={disconnectTelegram} disabled={isTelegramDisconnectLoading}>
                  {isTelegramDisconnectLoading ? (
                    <Loader stroke="red" size="12px" />
                  ) : (
                    <Text size="xs" weight="600" color="red">
                      {`Remove`}
                    </Text>
                  )}
                </RemoveButton>
              ) : (
                <ConnectButton
                  variant="outlined"
                  onClick={() => onNext(<UpdateTelegramComponent onBack={onBackModal} />)}
                >
                  <Text size="xs" weight="600" color="cyan">
                    {`Connect`}
                  </Text>
                </ConnectButton>
              )}
            </ButtonContainer>
          </MobileFriendlyContainer>
        </Flex>
      </Card>
      {planData && planType && (
        <Card p="0.75rem">
          <Flex direction="column" gap="0.7rem">
            <MobileFriendlyContainer>
              <div style={{ width: "80%" }}>
                <Flex direction="row" alignItems="center" gap="0.5rem" mb="0.5rem">
                  <Calendar color={theme.colors.secondary} width={12} height={12} />
                  <Text size="sm" weight="700" color="secondary">
                    {`Add Reminder to Calendar`}
                  </Text>
                </Flex>
                <Flex direction="row" alignItems="center" gap="0.3rem">
                  <Text size="sm" color="gray80">
                    {`Add invites to your calendar`}
                  </Text>
                </Flex>
              </div>
              <ButtonContainer>
                <ConnectButton variant="outlined" onClick={() => getGoogleCalendarLink(planData, planType)}>
                  <Text size="xs" weight="600" color="cyan">
                    {`Add`}
                  </Text>
                </ConnectButton>
              </ButtonContainer>
            </MobileFriendlyContainer>
          </Flex>
        </Card>
      )}
    </Flex>
  );
};

const ButtonContainer = styled.div`
  height: 35px;
  min-width: 85px;
`;

const RemoveButton = styled(Button)`
  transition: 0.2s ease-in-out;
  border-color: red;
  :hover {
    background: red;
    span {
      color: white;
    }
    path {
      stroke: white;
    }
  }
`;

const ConnectButton = styled(Button)`
  transition: 0.2s ease-in-out;
  :hover {
    background: ${({ theme }) => theme.colors.cyan};
    span {
      color: black;
    }
  }
`;

const MobileFriendlyContainer = styled(Flex)`
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    align-items: unset;
  }
`;

export default SetupAlertsModal;
