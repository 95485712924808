import { FC, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IVault, IVaultAdditionalMetrics } from "@/apis/vault/types";
import { getPaymentInterval, getPlanApr } from "@/components/PlanCreation/utils";
import { bigNumToFixedStr, shortenName } from "@/utils";

import { VaultTableRowBase } from "../common";

export interface LatestLoanBodyRowProps {
  index: number;
  loan: IVaultAdditionalMetrics["latestLoans"][0];
  vault: IVault;
}

export const LatestLoanBodyRow: FC<LatestLoanBodyRowProps> = ({ index, loan, vault }) => {
  const [imageLoading, setImageLoading] = useState(true);
  return (
    <Row style={{ cursor: "pointer" }} data-plan-id={loan.planId} data-created-at={loan.createdAt}>
      <Hidden tabletDown>
        <VaultRowText color="gray0" align="center">
          {index + 1}
        </VaultRowText>
      </Hidden>
      <Flex alignItems="center" gap="8px">
        {imageLoading && <SkeletonLine borderRadius="50%" w="24px" h="24px" />}
        <StyledImg
          src={loan.nftImageUrl ?? NoImage}
          alt={`${loan.collectionName}-${loan.tokenId}`}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
          }}
        />
        <VaultRowText color="secondary" textAlign="left">
          {shortenName(loan.collectionName, 40)} #{loan.tokenId}
        </VaultRowText>
      </Flex>
      <Hidden tabletDown>
        <VaultRowText color="secondary">
          {bigNumToFixedStr(loan.appraisalValue, 2, vault.decimals)} {vault.currency}
        </VaultRowText>
      </Hidden>

      <VaultRowText color="secondary">
        {bigNumToFixedStr(loan.loanAmount, 2, vault.decimals)} {vault.currency}
      </VaultRowText>
      <Hidden tabletDown>
        <VaultRowText color="secondary">{getPaymentInterval(loan.term * loan.totalNumOfPayments)}</VaultRowText>
      </Hidden>
      <VaultRowText color="secondary">
        {getPlanApr({
          term: loan.term,
          interestRate: loan.interestRate,
          totalNumberOfPayments: loan.totalNumOfPayments,
        }).toFixed(2)}
        %
      </VaultRowText>
    </Row>
  );
};

const Row = styled(VaultTableRowBase)`
  grid-template-columns: 0.2fr minmax(0, 2.2fr) 1.3fr 1.3fr 1.2fr 1.2fr 0.1fr;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2.5fr 1fr 1fr" }])};
`;

export const VaultRowText = styled(Text)<{ align?: string; sub?: boolean }>`
  display: flex;
  justify-content: ${({ align }) => (align ? align : "end")};
  font-weight: 500;
  font-size: 14px;
  font-size: ${({ sub }) => (sub ? "12px" : "14px")};
  ${({ sub }) => getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: sub ? 8 : 10 }])}
`;

// TODO: make general component for all round pics
// assignee: [@Munkhzulne]
export const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const LatestLoanLoadingRow = () => {
  return (
    <Row>
      <Hidden tabletDown>
        <LatestLoanLoadingText />
      </Hidden>
      <Flex alignItems="center" gap="8px">
        <SkeletonLine borderRadius="50%" h="24px" w="24px" />
        <LatestLoanLoadingText />
      </Flex>
      <Hidden tabletDown>
        <VaultRowText color="secondary">
          <LatestLoanLoadingText />
        </VaultRowText>
      </Hidden>
      <VaultRowText color="secondary">
        <LatestLoanLoadingText />
      </VaultRowText>
      <Hidden tabletDown>
        <VaultRowText color="secondary">
          <LatestLoanLoadingText />
        </VaultRowText>
      </Hidden>
      <LatestLoanLoadingText />
    </Row>
  );
};

const LatestLoanLoadingText = styled(SkeletonLine)`
  justify-self: end;
  width: 70%;
  height: 20px;
  ${getStyleWithMediaQuery("height", "px", [
    { [breakpoints.desktop]: 17 },
    { [breakpoints.laptop]: 15 },
    { [breakpoints.tablet]: 13 },
  ])};
`;
