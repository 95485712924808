import { BigNumber } from "ethers";
import { useMemo } from "react";

import { Flex } from "@cyanco/components/theme/Flex";
import { NftMetadataInline, NftMetadataInlineImage } from "@cyanco/components/theme/v3";

import { PeerPlanPaymentNumber } from "@/apis/p2p/types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import {
  PositionCompleted,
  PositionExtended,
  PositionPaymentMissed,
  TransactionDetail,
} from "@/components/PlanPayment/PlanStatuses";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { useGetUserTime } from "@/hooks";
import { ICurrency } from "@/types";

import { DATE_TIME_FORMAT_OPTIONS_DAYJS } from "../../../../config";
import {
  bigNumToFixedStr,
  getChainExplorerURL,
  numberWithCommas,
  shortenAddress,
  shortenName,
} from "../../../../utils";
import { PaymentStatusString } from "./PaymentRow";

interface IProps {
  imageUrl: string;
  collectionName: string;
  tokenId: string;
  amount: string;
  currency: ICurrency;
  paidDate: Date;
  paymentNumber: PeerPlanPaymentNumber;
  txnHash?: string;
  interestRate: number;
  serviceFeeRate: number;
  lender: string;
  status: string;
}

export const HistoricalPeerPlanPaymentModal = ({ payment }: { payment: IProps }) => {
  const { chainId } = useWeb3React();
  const { getUserPreferredTime } = useGetUserTime();
  const txnUrl = `${getChainExplorerURL(chainId)}/tx`;
  const paidDate = getUserPreferredTime(payment.paidDate).format(DATE_TIME_FORMAT_OPTIONS_DAYJS);
  const currencySymbol = payment.currency.symbol;
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(payment.currency.decimal) || 4;
  }, [payment.currency.decimal]);
  const serviceFee = BigNumber.from(payment.amount).mul(payment.serviceFeeRate).div(10000);
  const interestFee = BigNumber.from(payment.amount).mul(payment.interestRate).div(10000);

  return (
    <Flex direction="column" gap="1rem">
      <Flex gap="10px">
        <NftMetadataInlineImage imageUrl={payment.imageUrl} />
        <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 15px 0">
          <NftMetadataInline name={payment.collectionName} value={`#${shortenName(payment.tokenId, 10, 5)}`} />
          <NftMetadataInline
            name={`Loaned Amount`}
            value={`${numberWithCommas(
              bigNumToFixedStr(BigNumber.from(payment.amount), 2, payment.currency.decimal),
              2,
            )} ${currencySymbol}`}
            sub
          />
          <NftMetadataInline name={`Lender`} value={shortenAddress(payment.lender)} sub />
        </Flex>
      </Flex>
      {payment.status === PaymentStatusString.completed && (
        <PositionCompleted link={`${txnUrl}/${payment.txnHash}`} chainId={chainId} />
      )}
      {payment.status === PaymentStatusString.extended && (
        <PositionExtended link={`${txnUrl}/${payment.txnHash}`} chainId={chainId} />
      )}
      {payment.status === PaymentStatusString.missed && <PositionPaymentMissed />}

      <Flex direction="column" w="100%">
        <TransactionDetail
          label={`Payment Amount`}
          value={`${numberWithCommas(
            bigNumToFixedStr(BigNumber.from(payment.amount), formatNumber, payment.currency.decimal),
            formatNumber,
          )} ${currencySymbol}`}
        />
        <TransactionDetail
          label={`Purchase Provider Fee`}
          value={`${numberWithCommas(
            bigNumToFixedStr(serviceFee, formatNumber, payment.currency.decimal),
            formatNumber,
          )} ${currencySymbol}`}
          info={`(${(payment.serviceFeeRate / 100).toFixed(1)}%)`}
        />
        <TransactionDetail
          label={`Interest Amount`}
          value={`${numberWithCommas(
            bigNumToFixedStr(interestFee, formatNumber, payment.currency.decimal),
            formatNumber,
          )} ${currencySymbol}`}
          info={`(${(payment.interestRate / 100).toFixed(1)}%)`}
        />
        <TransactionDetail label={`Payment Date`} value={paidDate} />
      </Flex>
    </Flex>
  );
};
