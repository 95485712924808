import { Flex, Text, TextLink } from "@cyanco/components/theme";

import { DISCORD_ALERT_CHANNEL_URL, DISCORD_CONTACT_CHANNEL_URL } from "@/config";

export interface IMappedError {
  title: string;
  msg: string | React.ReactNode;
  description?: string | React.ReactNode;
}

export const CONTACT_US = (
  <Flex direction="column">
    <Text size="xs" weight="500" color="black">
      {`We apologize for the inconvenience, for further assistance, please open a ticket in our `}
      <TextLink href={DISCORD_ALERT_CHANNEL_URL} target="_blank" size="xs">
        Discord server.
      </TextLink>
    </Text>
  </Flex>
);

export const CONTACT_US_UNKNOWN = (
  <Flex direction="column" gap="1rem">
    <Text size="xs" weight="500" color="black">
      We apologize for the inconvenience.
    </Text>
    <Text size="xs" weight="500" color="black">
      {`Something went wrong due to error. For further assistance, please open a ticket in our `}
      <TextLink href={DISCORD_ALERT_CHANNEL_URL} target="_blank" size="xs">
        Discord server.
      </TextLink>
    </Text>
  </Flex>
);

export const REQUEST_MORE_INFO = (
  <Text size="xs" weight="500" color="black">
    {`If you need further assistance or more information, please open a ticket in our `}
    <TextLink href={DISCORD_CONTACT_CHANNEL_URL} target="_blank" size="xs">
      Discord server.
    </TextLink>
  </Text>
);

export const NOT_SUPPORTED_METHOD = (
  <Text size="xs" color="black">
    {`The desired function cannot be executed, as it has not been whitelisted for Cyan Wallet. Please contact us on `}
    <TextLink href={DISCORD_CONTACT_CHANNEL_URL} target="_blank" rel="noopener noreferrer">
      Discord
    </TextLink>
    {` if you would like this method added.`}
  </Text>
);

export const defaultError: IMappedError = { title: "Error Occured", msg: CONTACT_US_UNKNOWN };
