import { BigNumber, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { CyanWallet } from "@usecyan/cyan-wallet";
import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { IEarlyPaymentInfo, INextPayment } from "@/apis/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { useApproval } from "@/hooks/useApproval";
import { ITransaction } from "@/hooks/useTransactions";
import { ICurrency } from "@/types";
import { getChainExplorerTextForTxn, getChainExplorerURL } from "@/utils";
import { getAccountBalanceOfErc20, getNftTransferFnDataForCyanWallet } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";

import { IPawn } from "../../Account/pawn.types";
import { IBNPL } from "../../Bnpl/bnpl.types";
import { useTransactionContext } from "../../TransactionContextProvider";
import { PlanMetadata } from "../PlanMetadata";
import { PaymentOptions, PlanBulkRepayment } from "./PlanBulkRepayment";

type IRepayablePlan = (IPawn | IBNPL) & {
  nextPayment?: INextPayment;
  earlyPayment?: IEarlyPaymentInfo;
  hasError?: boolean;
};

type IBulkRepaymentProgress = {
  plans: Array<IRepayablePlan>;
  totalRepaymentAmountByCurrency: {
    [key: string]: {
      totalCost: BigNumber;
      currency: ICurrency;
      formatNumber: number;
      isNativeCurrency: boolean;
    };
  };
  releaseWallet: string;
  removePlan: (plan: { address: string; tokenId: string }) => void;
  onClose: () => void;
  paymentOption: PaymentOptions;
  selectedWalletAddress: string;
};

export const PlanBulkRepaymentProgressNew = ({
  plans,
  totalRepaymentAmountByCurrency,
  releaseWallet,
  removePlan,
  onClose,
  paymentOption,
  selectedWalletAddress,
}: IBulkRepaymentProgress) => {
  const isEarlyPayment = paymentOption === PaymentOptions.earlyPay;
  const { setModalContent, unsetModal } = useModal();
  const { chainId, account, provider, signer } = useWeb3React();
  const { getErc20ApprovalFunctionData } = useApproval();
  const { cyanWallet } = useCyanWalletContext();
  const { transactions, setTransactions } = useTransactionContext();
  const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
  const [selectedStep, setSelectedStep] = useState<PlanBulkRepaymentSteps>(PlanBulkRepaymentSteps.TokenTransfer);
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const currencyValues = Object.values(totalRepaymentAmountByCurrency);
  const withdrawFromCyanWallet =
    selectedWalletAddress.toLowerCase() === (cyanWallet?.walletAddress ?? "").toLowerCase();
  const isTokenTransferRequired = !withdrawFromCyanWallet && currencyValues.some(item => !item.isNativeCurrency);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(PlanBulkRepaymentSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const createPayTxn = (plan: IRepayablePlan) => {
    if (!plan.nextPayment) return;
    const isNativeCurrency = plan.currency.address.toLowerCase() === ethers.constants.AddressZero.toLowerCase();
    const paymentContractWriter = f.PaymentPlanV2Factory.createInterface();
    const encodedPayFnData = paymentContractWriter.encodeFunctionData("pay", [plan.planId, isEarlyPayment]);
    const encodedPayFnDataFormatted = [
      paymentPlanV2,
      isNativeCurrency ? (isEarlyPayment ? plan.earlyPayment?.remainingPayment : plan.nextPayment.currentPayment) : 0,
      encodedPayFnData,
    ];
    return encodedPayFnDataFormatted;
  };

  const createApproveTxn = async (
    signer: ethers.Signer,
    cyanWallet: CyanWallet,
    item: {
      currency: ICurrency;
      requiredAmount: BigNumber;
    },
  ) => {
    return await getErc20ApprovalFunctionData(
      {
        amount: item.requiredAmount,
        currencyAddress: item.currency.address,
        cyanWallet,
      },
      paymentPlanV2,
    );
  };

  const createBulkTransactions = async (signer: ethers.Signer, cyanWallet: CyanWallet) => {
    const bulkTransactions = [];
    const plansWithTxns = [];
    for (const { currency, totalCost, isNativeCurrency } of currencyValues) {
      if (!isNativeCurrency) {
        const txn = await createApproveTxn(signer, cyanWallet, {
          currency,
          requiredAmount: totalCost,
        });
        if (txn) bulkTransactions.push(txn);
      }
    }
    for (const plan of plans) {
      const payTxn = createPayTxn(plan);
      if (payTxn) {
        bulkTransactions.push(payTxn);
        plansWithTxns.push(plan);
      }
      const totalNumOfPaymentsLeft = plan.totalNumOfPayments - plan.currentNumOfPayments;
      if (
        (totalNumOfPaymentsLeft === 1 || isEarlyPayment) &&
        cyanWallet.walletAddress.toLowerCase() !== releaseWallet.toLowerCase()
      ) {
        const transferTxn = getNftTransferFnDataForCyanWallet({
          tokenType: plan.metadata.tokenType,
          to: releaseWallet,
          from: cyanWallet.walletAddress,
          tokenId: plan.tokenId,
          collectionAddress: plan.metadata.collectionAddress,
        });
        bulkTransactions.push(transferTxn);
      }
    }
    return { bulkTransactions, plansWithTxns };
  };

  const handlePaymentV2 = async () => {
    if (!chainId || !account || !signer || !cyanWallet) return;
    const { bulkTransactions, plansWithTxns } = await createBulkTransactions(signer, cyanWallet);
    if (!bulkTransactions || bulkTransactions.length === 0) return;
    const encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [bulkTransactions]);
    let tx: ethers.ContractTransaction;
    if (currencyValues.length === 1) {
      if (withdrawFromCyanWallet) {
        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          value: 0,
          data: encodedCyanExecuteFnData,
        });
      } else {
        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          value: currencyValues[0].isNativeCurrency ? currencyValues[0].totalCost : "0",
          data: encodedCyanExecuteFnData,
        });
      }
    } else {
      tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        value: totalRepaymentAmountByCurrency[ethers.constants.AddressZero.toLowerCase()]?.totalCost || 0,
        data: encodedCyanExecuteFnData,
      });
    }
    const newTxns: ITransaction[] = plansWithTxns.map(paymentPlan => ({
      hash: tx.hash,
      type: paymentPlan.planType === "BNPL" ? "bnpl-pay" : "pawn-pay",
      expiresAt: Date.now() + 1000 * 60 * 5, // 5 minutes
      data: {
        planId: paymentPlan.planId,
        tokenId: paymentPlan.tokenId,
        currentNumOfPayments: paymentPlan.currentNumOfPayments,
        contractAddress: paymentPlan.metadata.collectionAddress,
      },
    }));
    setTransactions(oldTxns => [...oldTxns, ...newTxns]);
    setActiveTx(tx.hash);
  };

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    setModalContent({
      title: `Loan Details`,
      content: (
        <PlanBulkRepayment
          plans={plans}
          onClose={onClose}
          removePlan={removePlan}
          error={mappedError}
          paymentOption={paymentOption}
        />
      ),
    });
  };

  const checkAndTransferErc20 = async () => {
    if (!signer || !cyanWallet) return;
    for (const { currency, totalCost, isNativeCurrency } of currencyValues) {
      if (!isNativeCurrency) {
        const sampleErc20 = f.SampleERC20TokenFactory.connect(currency.address, signer);
        const txn = await sampleErc20.transfer(cyanWallet.walletAddress, totalCost);
        await txn.wait();
      }
    }
  };

  const checkBalance = async () => {
    if (!chainId || !account || !provider) return;

    for (const item of currencyValues) {
      const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
        currencyAddress: item.currency.address,
        mainWallet: account,
        provider,
        chainId,
      });
      // checking mainWalletBalance, if it is enough, will be transfered to cyan wallet in next step
      let hasEnoughBalance = mainWalletBalance.gte(item.totalCost);
      if (currencyValues.length === 1) {
        hasEnoughBalance = withdrawFromCyanWallet
          ? cyanWalletBalance.gte(item.totalCost)
          : mainWalletBalance.gte(item.totalCost);
      }
      if (!hasEnoughBalance) {
        openRepaymentModal({
          title: `Not enough funds`,
          msg: `Your payment didn’t go through due to lack of funds`,
          description: ` Please double check the amount of crypto in your main wallet. In order to facilitate payments for non-native currency plans, it is imperative that the payment amount be drawn from the Cyan wallet.`,
        });
      }
    }
    return true;
  };

  useEffect(() => {
    const onStepChange = async (step: PlanBulkRepaymentSteps) => {
      if (!chainId || !account || !signer) return;
      try {
        switch (step) {
          case PlanBulkRepaymentSteps.TokenTransfer: {
            const hasEnoughBalance = await checkBalance();

            if (isTokenTransferRequired && hasEnoughBalance) {
              await checkAndTransferErc20();
            }

            setSelectedStep(PlanBulkRepaymentSteps.Paying);
            return;
          }
          case PlanBulkRepaymentSteps.Paying: {
            await handlePaymentV2();
          }
        }
      } catch (err: any) {
        openRepaymentModal(err);
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const stepMarks = useMemo(() => {
    const steps = getBulkRepaymentStepMarks(chainId);
    return steps;
  }, [chainId]);

  return (
    <Flex gap="18px" direction="column">
      <PlanMetadata
        plans={plans.map(plan => ({
          tokenId: plan.tokenId,
          collectionName: plan.metadata.collection.name,
          currency: plan.currency,
          imageUrl: plan.metadata.imageUrl,
          address: plan.metadata.collectionAddress,
          leftAmount: BigNumber.from(0),
          totalAmount: BigNumber.from(0),
          isBnpl: plan.planType === "BNPL",
        }))}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === PlanBulkRepaymentSteps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};

enum PlanBulkRepaymentSteps {
  TokenTransfer = 1,
  Paying = 2,
  Done = 3,
}

const getBulkRepaymentStepMarks = (chainId: number) => {
  return [
    {
      value: PlanBulkRepaymentSteps.TokenTransfer,
      title: `Token Transfer`,
      description: `Transfer required amount of tokens to the Cyan Wallet`,
    },
    {
      value: PlanBulkRepaymentSteps.Paying,
      title: `Processing Payment`,
      description: getChainExplorerTextForTxn(chainId),
    },
  ];
};

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
