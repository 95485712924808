import Animoca from "../../assets/images/homepage/animoca.svg";
import Bastion from "../../assets/images/homepage/bastion.svg";
import FabricVentures from "../../assets/images/homepage/fabricVentures.svg";
import Gumi from "../../assets/images/homepage/gumi.svg";
import Opensea from "../../assets/images/homepage/opensea.svg";
import Sandbox from "../../assets/images/homepage/sandbox.svg";
import YGG from "../../assets/images/homepage/ygg.svg";

export const partnerFirstRowImgs = [
  {
    alt: "Anomica Logo",
    src: Animoca,
  },
  {
    alt: "Opensea Logo",
    src: Opensea,
  },
  {
    alt: "Gumi Crypto Logo",
    src: Gumi,
  },
];
export const partnerSecondRowImgs = [
  {
    alt: "Fabric Ventures Logo",
    src: FabricVentures,
  },
  {
    alt: "The Sandbox Logo",
    src: Sandbox,
  },
  {
    alt: "YGG Logo",
    src: YGG,
  },
  {
    alt: "Bastion Logo",
    src: Bastion,
  },
];

export const companyLinks = [
  {
    value: "Team",
    link: "https://docs.usecyan.com/docs/meet-the-team",
  },
  {
    value: "Audits",
    link: "https://docs.usecyan.com/docs/security-audit",
  },
  {
    value: "Affiliates",
    link: "https://docs.usecyan.com/docs/ambassador-program",
  },
  {
    value: "Contact us",
    link: "mailto:hi@usecyan.com",
  },
];

const articlesBaseUrl = "https://usecyan.com/articles";
export const learnLinks = [
  {
    value: "What is an NFT Marketplace?",
    link: `${articlesBaseUrl}/what-is-an-nft-marketplace/`,
  },
  {
    value: "How to buy NFTs in Cyan's NFT Marketplace?",
    link: `${articlesBaseUrl}/how-to-buy-nfts-in-cyans-nft-marketplace/`,
  },
  {
    value: "How to identify a safe NFT Marketplace?",
    link: `${articlesBaseUrl}/what-is-an-nft-marketplace/`,
  },
  {
    value: "What Makes Cyan the Best NFT Marketplace?",
    link: `${articlesBaseUrl}/what-makes-cyan-the-best-nft-marketplace/`,
  },
];

export const resourceLinks = [
  {
    value: "Articles",
    link: articlesBaseUrl,
  },
  {
    value: "Customer Support",
    link: "https://discord.gg/akJxmGUJmb",
  },
  {
    value: "Partners",
    link: "https://docs.usecyan.com/docs/partnership-bd",
  },
  {
    value: "Developers",
    link: "https://docs.usecyan.com/reference",
  },
];

export const marketLinks = [
  {
    value: "Art",
    link: "/?tag=2",
  },
  {
    value: "Blue Chip",
    link: "/?tag=1",
  },
  {
    value: "Gaming",
    link: "/?tag=3",
  },
  {
    value: "Memberships",
    link: "/?tag=5",
  },
  {
    value: "PFPs",
    link: "/?tag=4",
  },
];

export const faqs = [
  {
    title: "What is Buy Now, Pay Later for NFTs?",
    description:
      "Buy Now Pay Later (BNPL) is a way of financing an item. Klarna, Affirm, and Afterpay provide BNPL to existing e-commerce websites. BNPL works by breaking up the price into many installment payments. So instead of paying for an NFT all at once on OpenSea, only an initial down payment is necessary. Make four payments in three months on Cyan instead of one large payment on OpenSea.",
  },
  {
    title: "How does Cyan help finance the purchase of NFTs?",
    description:
      "Cyan is a DeFi protocol that matches users with NFT leverage and those looking for yield by staking Ethereum into Cyan Vaults. When users start a new BNPL plan, they make a down payment of 25% and borrow the rest to buy the NFT. This is a 75% LTV, with which the underlying NFT purchased becomes the collateral for the loan. Cyan Vaults have claims against the purchased NFT in the event of a default.",
  },
  {
    title: "What is an NFT Loan, and how does it work?",
    description:
      "An NFT Loan is when you use an NFT as collateral to borrow ETH, WETH, USDC, POL Token, or other cryptocurrencies. On Cyan, we offer up to six-month loans against your NFT, with installments paid back every 31 days. You are now able to borrow up to 66% of the appraised value of your NFT. We also offer shorter durations, such as 24-hour flash loans or 7-day weekly loans.",
  },
  {
    title: "What happens if I don’t make a payment?",
    description:
      "In a missed payment, the underlying NFT moves to the respective Cyan Vault. For example, in a BNPL default, the purchased NFT moves into a Cyan Vault. The NFT liquidation process begins by listing the NFT in open markets. We are currently building a grace period into the protocol to allow for late payments.",
  },
  {
    title: "How do you determine the interest rate for BNPL or NFT Loans?",
    description:
      "Cyan’s risk engine will appraise every NFT requested and provide an interest rate. We’ve identified over 150 characteristics to measure. The major criteria are the creditworthiness of the user and the quality of the NFT. Trailing volume, volatility, depth of markets, number of transactions, stability of the community, and dispersion of ownership are some of the characteristics which determine the quality of an NFT.",
  },
  {
    title: "Is there a token, and if one doesn’t exist, wen token?",
    description:
      "Currently, a token does not exist. Similar protocols have issued tokens, and we've seen them become distractions or harm the community. A token must enhance the protocol instead of a means for capital or value extraction. There is no definitive date for issuing tokens, but it is always under review.",
  },
];

export const tweets = [
  "1700708002343334179",
  "1681348115079610369",
  "1724507112003219906",
  "1700177738966188108",
  "1701067248339972362",
  "1705250752179196098",
  "1662203993613893634",
];
