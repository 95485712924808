import { useRef, useState } from "react";
import { Filter } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { Option, SwitchButton, SwitchButtonGroup, Text, Toggler } from "@cyanco/components/theme/v3";
import { useOnClickOutside } from "@cyanco/components/theme/v3/hooks/useOnClickOutside";
import { Grid, List } from "@cyanco/components/theme/v3/icons";

import { StickySelectorWrapper } from "../..";
import {
  IWalletSelectedViews,
  IWalletTypes,
  IWalletViewStyle,
  WalletSelectedViews,
  WalletTypes,
  WalletViewStyle,
  useTabViewStyle,
  useWalletTabContext,
} from "../../AccountPageContext";
import { Settings } from "../Selectors/Settings";

export const WalletViewSelectors = () => {
  const {
    selectedWalletType,
    setSelectedWalletType,
    selectedWalletView,
    setSelectedWalletView,
    showCyanSupportedProjects,
    setShowCyanSupportedProjects,
  } = useWalletTabContext();
  const { tabViewStyle, setTabViewStyle } = useTabViewStyle();

  return (
    <StickySelectorWrapper
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      h="28px"
      pb="1rem"
      style={{
        maxHeight: "28px",
      }}
    >
      <Flex gap="2px" alignItems="center">
        <SwitchButtonGroup<IWalletSelectedViews> value={selectedWalletView} onChange={setSelectedWalletView} hover>
          <SwitchButton value={WalletSelectedViews.nfts} height="22px">{`NFTs`}</SwitchButton>
          <SwitchButton value={WalletSelectedViews.tokens} height="22px">{`Tokens`}</SwitchButton>
        </SwitchButtonGroup>
        {selectedWalletView === WalletSelectedViews.nfts && (
          <Settings>
            <Flex alignItems="center" gap="1rem" p="0.2rem 0.3rem" justifyContent="space-between">
              <Text size="sm" color="secondary" textWrap={false}>
                {`Cyan supported projects`}
              </Text>
              <Toggler value={showCyanSupportedProjects} onChange={setShowCyanSupportedProjects} size="sm" />
            </Flex>
            {tabViewStyle === WalletViewStyle.grid && (
              <>
                <Flex alignItems="center" gap="1rem" p="0.2rem 0.3rem" justifyContent="space-between">
                  <Text size="sm" color="secondary" textWrap={false}>
                    {`Show Main Wallet NFTs`}
                  </Text>
                  <Toggler
                    value={
                      selectedWalletType === WalletTypes.allWallets || selectedWalletType === WalletTypes.mainWallet
                    }
                    onChange={() => {
                      if (selectedWalletType === WalletTypes.allWallets) {
                        setSelectedWalletType(WalletTypes.cyanWallet);
                      } else if (selectedWalletType === WalletTypes.cyanWallet) {
                        setSelectedWalletType(WalletTypes.allWallets);
                      } else if (selectedWalletType === WalletTypes.mainWallet) {
                        setSelectedWalletType(WalletTypes.cyanWallet);
                      }
                    }}
                    size="sm"
                  />
                </Flex>{" "}
                <Flex alignItems="center" gap="1rem" p="0.2rem 0.3rem" justifyContent="space-between">
                  <Text size="sm" color="secondary" textWrap={false}>
                    {`Show Cyan Wallet NFTs`}
                  </Text>
                  <Toggler
                    value={
                      selectedWalletType === WalletTypes.allWallets || selectedWalletType === WalletTypes.cyanWallet
                    }
                    onChange={() => {
                      if (selectedWalletType === WalletTypes.allWallets) {
                        setSelectedWalletType(WalletTypes.mainWallet);
                      } else if (selectedWalletType === WalletTypes.cyanWallet) {
                        setSelectedWalletType(WalletTypes.mainWallet);
                      } else if (selectedWalletType === WalletTypes.mainWallet) {
                        setSelectedWalletType(WalletTypes.cyanWallet);
                      }
                    }}
                    size="sm"
                  />
                </Flex>
              </>
            )}
          </Settings>
        )}
      </Flex>
      {selectedWalletView === WalletSelectedViews.nfts && (
        <Flex gap="1rem" alignItems="center">
          <SwitchButtonGroup<IWalletViewStyle> value={tabViewStyle} onChange={setTabViewStyle} hover>
            <SwitchButton value={WalletViewStyle.list} height="22px">
              <List height={10} width={14} />
            </SwitchButton>
            <SwitchButton value={WalletViewStyle.grid} height="22px">
              <Grid height={10} width={10} />
            </SwitchButton>
          </SwitchButtonGroup>
        </Flex>
      )}
    </StickySelectorWrapper>
  );
};

export const WalletSelector = ({
  selectedWalletType,
  setSelectedWalletType,
}: {
  selectedWalletType: IWalletTypes;
  setSelectedWalletType: (w: IWalletTypes) => void;
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setOpen(false));
  return (
    <WalletSelectorBox
      ref={node}
      onClick={() => {
        setOpen(false);
      }}
    >
      <WalletSelectedValue
        alignItems="center"
        gap="5px"
        onClick={e => {
          e.stopPropagation();
          setOpen(prev => !prev);
        }}
      >
        <Filter color={theme.colors.secondary} size={12} />
        <Text
          size="xs"
          color="secondary"
          style={{
            userSelect: "none",
          }}
        >
          {WalletTypes.allWallets === selectedWalletType && `All Wallets`}
          {WalletTypes.cyanWallet === selectedWalletType && `Cyan Wallet`}
          {WalletTypes.mainWallet === selectedWalletType && `Main Wallet`}
        </Text>
      </WalletSelectedValue>
      <OptionsWrapper open={open}>
        <Option
          value={WalletTypes.allWallets}
          isActive={WalletTypes.allWallets === selectedWalletType}
          onSelect={() => {
            setSelectedWalletType(WalletTypes.allWallets);
            setOpen(false);
          }}
        >{`All Wallets`}</Option>
        <Option
          value={WalletTypes.mainWallet}
          isActive={WalletTypes.mainWallet === selectedWalletType}
          onSelect={() => {
            setSelectedWalletType(WalletTypes.mainWallet);
            setOpen(false);
          }}
        >{`Main Wallet`}</Option>
        <Option
          value={WalletTypes.cyanWallet}
          isActive={WalletTypes.cyanWallet === selectedWalletType}
          onSelect={() => {
            setSelectedWalletType(WalletTypes.cyanWallet);
            setOpen(false);
          }}
        >{`Cyan Wallet`}</Option>
      </OptionsWrapper>
    </WalletSelectorBox>
  );
};

const WalletSelectorBox = styled(Flex)`
  position: relative;
`;
const WalletSelectedValue = styled(Flex)`
  padding: 3px 10px 3px 8px;
  border-radius: 100px;
  background: ${({ theme }) => (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
  transition: 0.2s;
  :hover {
    background: ${({ theme }) => theme.colors.gray20};
  }
`;
const OptionsWrapper = styled.div<{ open: boolean }>`
  position: absolute;
  max-height: 300px;
  overflow: hidden;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  background: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  border-radius: ${({ theme }) => theme.borderRadius};
  z-index: 10;
  left: 0px;
  outline: none;
  max-width: 188px;
  top: calc(100% + 0.5rem);
  width: calc(100% - 2px);
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;
