import { FC, createContext } from "react";

import { themes } from "@cyanco/components/theme/config";
import ThemeProvider from "@cyanco/components/theme/v3/ThemeProvider";

import { useLocalStorage } from "../hooks";

const useTheme = () => {
  const [theme, setTheme] = useLocalStorage<{
    theme: "light" | "dark";
  }>("theme", { theme: "dark" });
  const setThemeByString = (theme: "light" | "dark") => {
    setTheme({ theme });
  };
  return [theme.theme, setThemeByString] as const;
};
interface IThemeContext {
  isLightTheme: boolean;
  setIsLightTheme: (arg: boolean) => void;
}
export const ThemeContext = createContext<IThemeContext>({
  isLightTheme: false,
  setIsLightTheme: () => {},
});

export const ThemeContextProvider: FC = ({ children }) => {
  const [theme, setTheme] = useTheme();
  const isLightTheme = theme === "light";
  const setIsLightTheme = (isLightTheme: boolean) => {
    setTheme(isLightTheme ? "light" : "dark");
  };
  return (
    <ThemeContext.Provider
      value={{
        isLightTheme,
        setIsLightTheme,
      }}
    >
      <ThemeProvider theme={isLightTheme ? themes.light : themes.dark}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
