import { captureMessage } from "@sentry/react";
import { ethers } from "ethers";

import { SupportedCurrencies, SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { SupportedChainId } from "./constants/chains";

export const getEnvOrThrow = (variableName: string): string => {
  const variable = process.env[`REACT_APP_${variableName}`];
  if (variable) return variable;

  captureMessage(`Variable named ${variableName} is not found!`);
  throw new Error(`Variable named ${variableName} is not found!`);
};

export const isProd = process.env.NODE_ENV === "production" && process.env.REACT_APP_ENVIRONMENT !== "staging";
export const isDev = process.env.NODE_ENV === "development";

export const LOCAL_STORAGE_TRANSACTIONS_KEY = "transactions";
export const LOCAL_STORAGE_AFFILIATE_DATA_KEY = "affiliate_data";

export const DATE_TIME_FORMAT_OPTIONS_DAYJS = "MMMM DD, YYYY, HH:MMA";

export const LOCAL_STORAGE_AFFILIATE_CODE_EXPIRING_DAYS = 14;

export const DAPP_URL = isProd ? "https://usecyan.com" : "https://testnet.usecyan.com";

export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
if (typeof INFURA_KEY === "undefined") {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`);
}

export const UNSTOPPABLE_CLIENT_ID = process.env.REACT_APP_UNSTOPPABLE_CLIENT_ID;
if (typeof UNSTOPPABLE_CLIENT_ID === "undefined") {
  throw new Error(`REACT_APP_UNSTOPPABLE_CLIENT_ID must be a defined environment variable`);
}

export const DISCORD_ALERT_CHANNEL_URL = isProd
  ? "https://discord.com/channels/907555867262615562/1035411473968672799"
  : "https://discord.com/channels/907555867262615562/1065205430982291496";

export const DISCORD_SERVER_INVITE_LINK = "https://discord.gg/usecyan";
export const DISCORD_CONTACT_CHANNEL_URL = "https://discord.com/channels/907555867262615562/1091411104544071790";
export const CYAN_TWITTER_LINK = "https://x.com/usecyan";
export const CYAN_GITHUB_LINK = "https://github.com/usecyan";
export const CYAN_MEDIUM_LINK = "https://medium.com/@usecyan";
export const CYAN_YOUTUBE_LINK = "https://www.youtube.com/@usecyan";
export const TELEGRAM_BOT_INVITE_LINK = isProd ? "https://t.me/CyanAlertsBot" : "https://t.me/CyanTestnetBot";

// ApeCoinStaking
export const BAYCAddress = isProd
  ? "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d".toLowerCase()
  : "0x300b105942d6d181cdfe8199fd48eb09d26efd24".toLowerCase();
export const MAYCAddress = isProd
  ? "0x60e4d786628fea6478f785a6d7e704777c86a7c6".toLowerCase()
  : "0xfede5e85f747177770819f25b09edf81f2ba4493".toLowerCase();
export const BAKCAddress = isProd
  ? "0xba30e5f9bb24caa003e9f2f0497ad287fdf95623".toLowerCase()
  : "0x7f5fb797533204872a0fd58d53edfae233b3d74e".toLowerCase();

export const apePaymentPlanContract = getEnvOrThrow("APE_PAYMENT_PLAN_CONTRACT_ADDRESS").toLowerCase();
export const apeStakingContract = getEnvOrThrow("APE_STAKING_CONTRACT_ADDRESS").toLowerCase();
export const apeVaultContract = getEnvOrThrow("APE_VAULT_CONTRACT_ADDRESS").toLowerCase();
export const apeVaultTokenContract = getEnvOrThrow("APE_VAULT_TOKEN_CONTRACT_ADDRESS").toLowerCase();
export const apeCoinContract = getEnvOrThrow("APE_COIN_CONTRACT_ADDRESS").toLowerCase();
export const CYAN_TESTDRIVE_WALLET = getEnvOrThrow("CYAN_TEST_DRIVE_ADDRESS").toLowerCase();
export enum PoolId {
  COIN = 0,
  BAYC = 1,
  MAYC = 2,
  BAKC = 3,
}

export const POOL_IDS_MAPPED_BY_ADDRESS = {
  [BAYCAddress]: PoolId.BAYC,
  [MAYCAddress]: PoolId.MAYC,
  [BAKCAddress]: PoolId.BAKC,
};

export const ADDRESSES_MAPPED_BY_POOL_ID: {
  [key: number]: string;
} = {
  [PoolId.COIN]: apeCoinContract,
  [PoolId.BAYC]: BAYCAddress,
  [PoolId.MAYC]: MAYCAddress,
  [PoolId.BAKC]: BAKCAddress,
};

export const CAPS_MAPPED_BY_ADDRESS = {
  [BAYCAddress]: 10094,
  [MAYCAddress]: 2042,
  [BAKCAddress]: 856,
};

export const CAPS_MAPPED_BY_POOL_ID = {
  [PoolId.COIN]: 0,
  [PoolId.BAYC]: 10094,
  [PoolId.MAYC]: 2042,
  [PoolId.BAKC]: 856,
};

export const COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS = {
  [BAYCAddress]: "BAYC",
  [MAYCAddress]: "MAYC",
  [BAKCAddress]: "BAKC",
};

export const mainPoolCollections = [
  { name: `Bored Ape Yacht Club`, address: BAYCAddress },
  { name: `Mutant Ape Yacht Club`, address: MAYCAddress },
  { name: `Bored Ape Kennel Club`, address: BAKCAddress },
];

export const APE_COIN_STAKABLE_ADDRESSES = [BAYCAddress, MAYCAddress, BAKCAddress];

export const nonNativeSupportedCurrenciesData: {
  [a: number]: {
    [b: string]: {
      address: string;
      decimals: number;
    };
  };
} = {
  [SupportedChainId.POLYGON]: {
    [SupportedCurrencies.USDC]: {
      address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      decimals: 6,
    },

    [SupportedCurrencies.WETH]: {
      address: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
      decimals: 18,
    },
  },
  [SupportedChainId.MAINNET]: {
    [SupportedCurrencies.USDC]: {
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      decimals: 6,
    },
    [SupportedCurrencies.WETH]: {
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      decimals: 18,
    },
  },
  [SupportedChainId.SEPOLIA]: {
    [SupportedCurrencies.USDC]: {
      address: "0xc6ed33406A4d19976c75a5cC80F1c3920b1e84A4",
      decimals: 6,
    },
    [SupportedCurrencies.WETH]: {
      address: "0x7b79995e5f793a07bc00c21412e50ecae098e7f9",
      decimals: 18,
    },
  },
  [SupportedChainId.BLAST]: {
    [SupportedCurrencies.WETH]: {
      address: "0x4300000000000000000000000000000000000004",
      decimals: 18,
    },
  },
  [SupportedChainId.BLAST_SEPOLIA]: {
    [SupportedCurrencies.WETH]: {
      address: "0x4200000000000000000000000000000000000023",
      decimals: 18,
    },
  },
};

export const SupportedCurrenciesByChain: {
  [a: number]: {
    address: string;
    decimal: number;
    symbol: SupportedCurrenciesType;
  }[];
} = {
  [SupportedChainId.POLYGON]: [
    {
      address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174".toLowerCase(),
      decimal: 6,
      symbol: SupportedCurrencies.USDC,
    },
    {
      address: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619".toLowerCase(),
      decimal: 18,
      symbol: SupportedCurrencies.WETH,
    },
    { address: ethers.constants.AddressZero.toLowerCase(), decimal: 18, symbol: SupportedCurrencies.POL },
  ],
  [SupportedChainId.MAINNET]: [
    {
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48".toLowerCase(),
      decimal: 6,
      symbol: SupportedCurrencies.USDC,
    },
    {
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2".toLowerCase(),
      decimal: 18,
      symbol: SupportedCurrencies.WETH,
    },
    { address: ethers.constants.AddressZero.toLowerCase(), decimal: 18, symbol: SupportedCurrencies.ETH },
    {
      address: "0x4d224452801aced8b2f0aebe155379bb5d594381".toLowerCase(),
      decimal: 18,
      symbol: SupportedCurrencies.APE,
    },
  ],
  [SupportedChainId.SEPOLIA]: [
    {
      address: "0xc6ed33406A4d19976c75a5cC80F1c3920b1e84A4".toLowerCase(),
      decimal: 6,
      symbol: SupportedCurrencies.USDC,
    },
    {
      address: "0x7b79995e5f793a07bc00c21412e50ecae098e7f9".toLowerCase(),
      decimal: 18,
      symbol: SupportedCurrencies.WETH,
    },
    { address: ethers.constants.AddressZero.toLowerCase(), decimal: 18, symbol: SupportedCurrencies.ETH },
    {
      address: "0xbe759b054a80c78e684870bb45738b3240dd8af3".toLowerCase(),
      decimal: 18,
      symbol: SupportedCurrencies.APE,
    },
  ],
  [SupportedChainId.BLAST]: [
    {
      address: "0x4300000000000000000000000000000000000004".toLowerCase(),
      decimal: 18,
      symbol: SupportedCurrencies.WETH,
    },
    { address: ethers.constants.AddressZero.toLowerCase(), decimal: 18, symbol: SupportedCurrencies.ETH },
  ],
  [SupportedChainId.BLAST_SEPOLIA]: [
    {
      address: "0x4200000000000000000000000000000000000023".toLowerCase(),
      decimal: 18,
      symbol: SupportedCurrencies.WETH,
    },
    { address: ethers.constants.AddressZero.toLowerCase(), decimal: 18, symbol: SupportedCurrencies.ETH },
  ],
};
export const MAX_APPROVAL_VALUE = ethers.constants.MaxUint256;
export const MAX_BNPL_LIMIT = 10;
export const MAX_PAWN_LIMIT = 10;
export const MAX_REVIVAL_LIMIT = 10;
export const TESTDRIVE_URL = isProd ? "https://demo.usecyan.com" : "https://testdrive-testnet.usecyan.com";

export const CYAN_POINTS_DOC_LINK = "https://docs.usecyan.com/docs/cyan-points";
