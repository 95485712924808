import { BigNumber } from "ethers";
import { useMemo } from "react";

import { Box, Button, Card, Flex, Text } from "@cyanco/components/theme";

import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, numberWithCommas } from "@/utils";

export const PayBox: React.FC<{
  plan: {
    amount: BigNumber;
    currency: ICurrency;
    label: string;
  };
  onPay: () => Promise<void>;
}> = ({ plan, onPay }) => {
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 4;
  }, [plan.currency.decimal]);
  return (
    <Card p={"15px"}>
      <Flex justifyContent="space-between" w="100%">
        <Flex direction="column" gap="4px" w="100%">
          <Text size="sm" weight="500" color="secondary">
            {plan.label}
          </Text>
          <Text size="lg" weight="500" color="secondary">
            {`${numberWithCommas(bigNumToFixedStr(plan.amount, formatNumber, plan.currency.decimal), formatNumber)} ${
              plan.currency.symbol
            }`}{" "}
          </Text>
        </Flex>
        <Box w="120px">
          <Button onClick={onPay}>{`Pay`}</Button>
        </Box>
      </Flex>
    </Card>
  );
};
