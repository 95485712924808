import { useEffect, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import styled from "styled-components";

import { Flex, Hidden, NotFound, SkeletonLine } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { getVaultLiquidatedNfts } from "@/apis/vault/admin";
import { ILiquidatedNftStatus, IVaultLiquidatedNft } from "@/apis/vault/admin/types";
import { IVault } from "@/apis/vault/types";
import { PaymentDate } from "@/components/Account/components/PositionView/PositionRow";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import Pagination from "@/components/Pagination";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { bigNumToFloatFormatted, shortenName } from "@/utils";

import { LatestLoanLoadingRow, StyledImg, VaultRowText } from "../../LatestLoans/LatestLoanBodyRow";
import { ContainerBox, Header, ListViewBox } from "../../LatestLoans/LatestLoans";
import { VaultLoanHeaderText } from "../../LatestLoans/LatestLoansHeader";
import { VaultTableHeaderBase, VaultTableRowBase } from "../../common";
import { PAGINATION_SIZE } from "../VaultLoans";

export const SoldNftsTable = ({ vault, loading }: { vault: IVault; loading: boolean }) => {
  const { user } = useAuthContext();
  const { vaultLiquidatedNfts } = useTransactionContext();

  const [totalLoans, setTotalLoans] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedDatas, setPaginatedDatas] = useState<IVaultLiquidatedNft[][]>([]);
  const { loading: dataLoading, execute } = useAsyncCallback(
    async (pageNumber: number, currentTotal: number, existingDatas: IVaultLiquidatedNft[][]) => {
      if (!user?.token) return;
      if (!existingDatas[pageNumber - 1]?.length) {
        const data = await getVaultLiquidatedNfts({
          offset: (pageNumber - 1) * PAGINATION_SIZE,
          contractAddress: vault.contractAddress,
          token: user.token,
          statuses: [ILiquidatedNftStatus.sold],
        });
        if (data.totalCount !== currentTotal) {
          setTotalLoans(data.totalCount);
        }
        const newPaginatedDatas = [...existingDatas];
        newPaginatedDatas[pageNumber - 1] = data.liquidatedNfts;
        setPaginatedDatas(newPaginatedDatas);
      }
    },
  );

  useEffect(() => {
    setTotalLoans(0);
    setPaginatedDatas([]);
    setCurrentPage(1);
    execute(1, 0, []);
  }, [vault.contractAddress]);

  const paginatedData =
    currentPage === 1
      ? [...vaultLiquidatedNfts, ...(paginatedDatas[currentPage - 1] ?? [])]
      : paginatedDatas[currentPage - 1] ?? [];

  return (
    <Flex direction="column" w="100%">
      <ListViewBox>
        <Header>
          <HistoryHeader />
        </Header>
        <ContainerBox>
          {dataLoading || loading ? (
            Array.from(Array(10).keys()).map(loader => <LatestLoanLoadingRow key={loader} />)
          ) : !paginatedData.length ? (
            <NotFound msg="No loans found" />
          ) : (
            paginatedData.map((nft, index) => {
              return (
                <HistoryRow
                  decimals={vault.decimals}
                  symbol={vault.currency}
                  nft={nft}
                  key={`${nft.id}:index`}
                  index={(currentPage - 1) * PAGINATION_SIZE + index}
                />
              );
            })
          )}
          <Pagination
            total={totalLoans}
            pageSize={PAGINATION_SIZE}
            current={currentPage}
            onPageChange={n => {
              setCurrentPage(n);
              execute(n, totalLoans, paginatedDatas);
            }}
          />
        </ContainerBox>
      </ListViewBox>
    </Flex>
  );
};

const HistoryHeader = () => {
  return (
    <HeaderContainer>
      <Hidden tabletDown></Hidden>
      <VaultLoanHeaderText color="secondary" align="start">
        Liquidated assets
      </VaultLoanHeaderText>
      <Hidden tabletDown>
        <VaultLoanHeaderText color="secondary">Marked value</VaultLoanHeaderText>
      </Hidden>
      <VaultLoanHeaderText color="secondary">Sold price</VaultLoanHeaderText>
      <Hidden tabletDown>
        <VaultLoanHeaderText color="secondary">Defaulted date</VaultLoanHeaderText>
      </Hidden>
    </HeaderContainer>
  );
};

const HistoryRow = ({
  nft,
  decimals,
  symbol,
  index,
}: {
  nft: IVaultLiquidatedNft;
  decimals: number;
  symbol: string;
  index: number;
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  return (
    <StyledRow>
      <Hidden tabletDown>
        <VaultRowText color="gray0" align="center">
          {index + 1}
        </VaultRowText>
      </Hidden>
      <Flex alignItems="center" gap="8px">
        {imageLoading && <SkeletonLine borderRadius="50%" w="24px" h="24px" />}
        <StyledImg
          src={nft.imageUrl ?? NoImage}
          alt={`${nft.collectionAddress}-${nft.tokenId}`}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
          }}
        />
        <VaultRowText color="secondary" textAlign="left">
          {shortenName(nft.collectionName, 40)} #{nft.tokenId}
        </VaultRowText>
      </Flex>
      <Hidden tabletDown>
        <VaultRowText color="secondary">
          {bigNumToFloatFormatted(nft.markedPrice, decimals, 3)} {symbol}
        </VaultRowText>
      </Hidden>
      <VaultRowText color="secondary">
        {nft.soldPrice ? `${bigNumToFloatFormatted(nft.soldPrice, decimals, 3)} ${symbol}` : "-"}
      </VaultRowText>
      <Hidden tabletDown>
        <VaultLoanHeaderText color="secondary">
          {nft.defaultedAt && <PaymentDate date={nft.defaultedAt} />}
        </VaultLoanHeaderText>
      </Hidden>
    </StyledRow>
  );
};

const StyledRow = styled(VaultTableRowBase)`
  grid-template-columns: 0.2fr 1.5fr 1fr 1fr 1fr;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "1.5fr 1fr" }])};
`;

const HeaderContainer = styled(VaultTableHeaderBase)`
  grid-template-columns: 0.2fr 1.5fr 1fr 1fr 1fr;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "1.5fr 1fr" }])};
`;
