import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { Card, Flex, Text } from "@cyanco/components/theme";
import { Button, SystemMessage, Toggler, Tooltip, TooltipText, useModal } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";
import { factories as f } from "@cyanco/contract";

import { IPeerPlan, PeerPlanStatuses } from "@/apis/p2p/types";
import { IPlanData } from "@/components/PlanPayment/PeerPlan";
import { RegularPayment } from "@/components/PlanPayment/PeerPlan/RegularPayment";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPeerPaymentPlanFromChainId } from "@/constants/contracts";
import { IMappedError } from "@/utils/error/msgs";

import { LoanMetadata } from "./DefaultedPlan";
import { UpdateExtendableProgress } from "./UpdateExtendableProgress";

export const LivePlan = ({
  plan,
  error,
  planData: _planData,
}: {
  plan: IPeerPlan;
  error?: IMappedError;
  planData?: IPlanData;
}) => {
  const theme = useTheme();
  const { signer, chainId } = useWeb3React();
  const { setModalContent } = useModal();
  const [planData, setPlanData] = useState<IPlanData | undefined>(_planData);
  const [enableAutoRoll, setEnableAutoRoll] = useState(plan.isExtendable);
  useEffect(() => {
    if (!signer) return;
    const _setNextPayment = async () => {
      const peerToPeerContract = getPeerPaymentPlanFromChainId(chainId);
      const paymentPlanReader = f.CyanPeerPlanFactory.connect(peerToPeerContract, signer);
      const { interestRate, dueDate, amount, status, extendable } = await paymentPlanReader.getPaymentPlanMapped(
        plan.planId,
      );
      const fee = BigNumber.from(amount).mul(interestRate).div(10000);
      const currentPayment = BigNumber.from(amount).add(fee);
      if (status === PeerPlanStatuses.ACTIVE) {
        setPlanData({
          nextPaymentDate: dueDate,
          currentPayment,
          status,
          extendable,
          fee,
        });
      }
    };
    _setNextPayment();
  }, [plan]);

  const edit = () => {
    setModalContent({
      title: `Editing Loan`,
      content: <UpdateExtendableProgress planData={planData} plan={{ ...plan, enableAutoRoll }} />,
    });
  };

  return (
    <Flex direction="column" gap="18px">
      <LoanMetadata plan={plan} />
      {error && <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />}
      <Flex direction="column" gap="8px">
        <Text size="sm" weight="400" color="gray0">
          {`Peer-to-Peer Loan`}
        </Text>

        {planData && <RegularPayment plan={plan} planDataContract={planData} isLender />}
        <Card p={"10px"} style={{ borderRadius: "10px", borderColor: theme.colors.cyan }}>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" gap="4px">
              <Text size="md" weight="500" color="secondary">
                {`Enable auto-roll`}
              </Text>
              <Tooltip>
                <HelpCircle height={16} width={16} color={theme.colors.secondary} />
                <TooltipText showArrow position="top" top="-155px" right="-70px" style={{ width: "150px" }}>
                  <Flex direction="column" gap="7px">
                    <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                      <div>{`Automatically compound your APE on a bi-weekly basis by unstaking, and re-staking all earned APE`}</div>
                    </Text>
                    <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                      <div>{`Your NFT will be moved into your Cyan Wallet for compounding activities. Turning this off will keep your NFT in your main wallet during staking.`}</div>
                    </Text>
                  </Flex>
                </TooltipText>
              </Tooltip>
            </Flex>
            <Toggler value={enableAutoRoll} onChange={setEnableAutoRoll} size="sm" />
          </Flex>
        </Card>
      </Flex>
      <Button style={{ height: "50px" }} onClick={edit}>{`Save Auto-roll`}</Button>
    </Flex>
  );
};
