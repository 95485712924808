import { Provider } from "@ethersproject/providers";
import { BigNumber } from "ethers";
import { FC, useMemo } from "react";
import { useAsync } from "react-async-hook";

import { Flex } from "@cyanco/components/theme/Flex";
import { NotFound, PieChart, SkeletonLine, SubTitle } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { IVaultAdditionalMetrics } from "@/apis/vault/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { bigNumToFloat, bigNumToFloatFormatted, getJsonRpcProvider } from "@/utils";
import { isMobile } from "@/utils/userAgent";

import { CHART_HEIGHT, getTopCollections } from ".";

export const PoolBreakdown: FC<{
  collections: IVaultAdditionalMetrics["collections"];
  decimals: number;
  currency: string;
  vaultAddress: string;
  vaultChainId: number;
  loading: boolean;
}> = ({ collections, decimals = 5, currency, vaultAddress, vaultChainId, loading }) => {
  const { provider, chainId } = useWeb3React();
  const { result: vaultAssetAmount } = useAsync(async () => {
    if (!provider) return;
    let vaultProvider: Provider | undefined = provider;
    if (chainId !== vaultChainId) {
      vaultProvider = getJsonRpcProvider(vaultChainId);
    }
    if (!vaultProvider) return;
    const vaultContractWriter = f.CyanVaultV2Factory.connect(vaultAddress, vaultProvider);
    return await vaultContractWriter.getCurrentAssetAmountsMapped();
  }, [provider, chainId, vaultChainId, vaultAddress]);

  const collectionTotal = useMemo(() => {
    return collections.reduce((acc, curr) => {
      return acc.add(BigNumber.from(curr.total));
    }, BigNumber.from(0));
  }, [collections]);

  const collectionChartData = useMemo(() => {
    const topCollectionAdmin = getTopCollections(
      collections,
      collectionTotal.add(vaultAssetAmount?.remainingAmount ?? 0).add(vaultAssetAmount?.defaultedNftAssetAmount ?? 0),
    );
    return topCollectionAdmin.map(collection => ({
      name: collection.name,
      value: bigNumToFloat(BigNumber.from(collection.total), decimals),
      readableValue: `${bigNumToFloatFormatted(BigNumber.from(collection.total), decimals, 5)} ${currency}`,
    }));
  }, [
    collections,
    collectionTotal,
    currency,
    decimals,
    vaultAssetAmount?.defaultedNftAssetAmount,
    vaultAssetAmount?.remainingAmount,
  ]);

  return (
    <Flex direction="column" gap="1.5rem">
      <SubTitle>Pool breakdown</SubTitle>
      {loading ? (
        <SkeletonLine h={isMobile ? `${CHART_HEIGHT.mobile}px` : `${CHART_HEIGHT.desktop}px`} w="100%" />
      ) : (
        <Flex
          direction="column"
          w="100%"
          h={isMobile ? `${CHART_HEIGHT.mobile}px` : `${CHART_HEIGHT.desktop}px`}
          alignItems="center"
          justifyContent="center"
        >
          {vaultAssetAmount ? (
            <PieChart
              height="100%"
              innerRadius={0}
              outerRadius={isMobile ? 60 : 200}
              fontSize={isMobile ? 12 : 14}
              labelXDistance={isMobile ? 0 : 40}
              data={[
                ...collectionChartData,
                {
                  name: `Unutilized amount`,
                  value: bigNumToFloat(vaultAssetAmount.remainingAmount, decimals),
                  readableValue: `${bigNumToFloatFormatted(vaultAssetAmount.remainingAmount, decimals, 5)} ${currency}`,
                },
                {
                  name: `Defaulted NFT amount`,
                  value: bigNumToFloat(vaultAssetAmount.defaultedNftAssetAmount, decimals),
                  readableValue: `${bigNumToFloatFormatted(
                    vaultAssetAmount.defaultedNftAssetAmount,
                    decimals,
                    5,
                  )} ${currency}`,
                },
              ]}
            />
          ) : (
            <NotFound msg="No data found" />
          )}
        </Flex>
      )}
    </Flex>
  );
};
