import { NftMetadata } from "@/apis/types";
import { SupportedChainId } from "@/constants/chains";
import { ICurrency, INftType } from "@/types";

export enum PeerPlanStatuses {
  NONE,
  ACTIVE,
  DEFAULTED,
  COMPLETED,
  LIQUIDATED,
}

export enum PeerPlanPaymentNumber {
  EXTENDED,
  COMPLETED,
}

type IPeerPlanPayment = {
  id: number;
  txnHash: string;
  createdAt: Date;
  paymentNumber: PeerPlanPaymentNumber;
  amount: string;
};

type ILoanBidData = {
  lenderAddress: string;

  amount: string;
  currency: string;
  term: number;
  isExtendable: boolean;
  interestRate: number;
  tokenAmount: number;

  signature: string;
  signatureExpiry: number;
  signatureUsageLimit: number;
  signedDate: number;

  collectionAddress: string;
  tokenId?: string;
};

export type IP2PListedNft = {
  id: string;
  collectionAddress: string;
  tokenId: string;
  imageUrl: string;
  rarityRank: number;
  collectionName: string;
  appraisalValue?: string;
  currency: ICurrency;
};

export type IP2PLoanOfferCreation = {
  chainId: SupportedChainId;
} & ILoanBidData;

export type IP2PLoanOffer = ILoanBidData & {
  id: number;
  isActive: boolean;
  metadata?: {
    imageUrl: string;
  };
  currency: ICurrency;
};

export interface IPeerPlan {
  planId: number;
  tokenId: string;
  collectionAddress: string;

  lenderAddress: string;
  borrowerAddress: string;

  status: PeerPlanStatuses;
  isExtendable: boolean;
  serviceFeeRate: number;
  dueDate: Date;

  createdAt: Date;
  defaultedAt?: Date | null;

  metadata?: Pick<NftMetadata, "imageUrl" | "rarityRank">;
  collection: {
    name: string;
  };
  payments: IPeerPlanPayment[];
  loanBid: Pick<ILoanBidData, "amount" | "currency" | "interestRate" | "term" | "signature"> & {
    id: number;
    currency: ICurrency;
  };

  // will assign in FE
  nextPaymentDate: Date;
  monthlyAmount: string;
  planType: "P2P";
}

export type IP2PUserCreatedOffer = {
  id: number;
  lenderAddress: string;

  metadata?: {
    imageUrl: string;
  };
  currency: ICurrency;
  collection: {
    name: string;
    address: string;
    cyanSignature: string;
    tokenType: INftType;
  };
  isActive: boolean;
  isExpired: boolean;

  signature: string;
  signatureExpiry: Date;
  signatureUsageLimit: number;
  signatureAvailableUsage: number;
  signedDate: Date;

  tokenId: string | null;
  tokenAmount: number;
  term: number;

  amount: string;
  isExtendable: boolean;

  interestRate: number;
};

export const isPeerPlan = (item: any): item is IPeerPlan => {
  return "loanBid" in item && "planType" in item && item.planType === "P2P";
};

export type IPeerPlanAmountsByWallet = Array<{
  wallet: string;
  amount: string;
  currency: ICurrency;
}>;
