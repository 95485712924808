import { NumericFormat, OnValueChange } from "react-number-format";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Card, Input, Loader, Text } from "@cyanco/components/theme/v3";
import { NewTab } from "@cyanco/components/theme/v3/icons";
import { ApeCoin, NoImage } from "@cyanco/components/theme/v3/images";

import { formatCompactNumber, roundDown } from "@/utils";

export const ApeCoinStakingInput = ({
  disabled,
  stakingAmount,
  onInputChange,
  max,
}: {
  disabled?: boolean;
  stakingAmount?: string;
  onInputChange: (a: string) => void;
  max?: number;
}) => {
  const onStakingAmountChange: OnValueChange = values => {
    onInputChange(values.value);
  };
  const setMax = () => {
    if (!max) return;
    if (disabled || max < 1) return;
    onInputChange(roundDown(max, 2).toString());
  };
  return (
    <NumericFormat
      placeholder="0"
      thousandSeparator=","
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={onStakingAmountChange}
      value={stakingAmount || ""}
      disabled={disabled}
      customInput={Input}
      decimalScale={2}
      valueIsNumericString
      style={{
        fontSize: "20px",
        height: "38px",
        width: "100%",
      }}
      height={"35px"}
      icon={
        <img
          src={ApeCoin}
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      }
      p="0.3rem 0.3rem 0.3rem 0.3rem"
    >
      {max !== undefined ? (
        <MaxButton onClick={setMax}>
          <Text size="xs" weight="500" color="gray0">
            Max
          </Text>
        </MaxButton>
      ) : (
        <></>
      )}
    </NumericFormat>
  );
};

export const BulkStakingProgressLoader = ({
  selectedNfts,
  isLoading,
}: {
  selectedNfts: Array<{ address: string; tokenId: string; imageUrl?: null | string }>;
  isLoading: boolean;
}) => {
  return (
    <NftImageWrapper>
      {selectedNfts.map(item => (
        <ItemWrapper key={`${item.address}:${item.tokenId}`}>
          <ImageWrapper isSelected={true}>
            <StyledNftImage src={item.imageUrl || NoImage} hasImage={!!item.imageUrl} />
            {isLoading && (
              <ImageLoader>
                <Loader stroke="white" size="50px" />
              </ImageLoader>
            )}
          </ImageWrapper>
        </ItemWrapper>
      ))}
    </NftImageWrapper>
  );
};

export const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

export const StickySelectorWrapper = styled(Flex)`
  top: 64px;
  position: sticky;
  z-index: 7;
  background: ${({ theme }) => theme.backgroundColor};
  padding-left: calc((100% - 1240px) / 2);
  padding-right: calc((100% - 1240px) / 2);
  padding-top: 1.5rem;
  margin-top: -1.5rem;
  width: calc(100% - calc((100% - 1240px)));
  ${getStyleWithMediaQuery("padding-top", "", [{ [breakpoints.desktop]: "1.5rem" }, { [breakpoints.tablet]: "1rem" }])}
  ${getStyleWithMediaQuery("margin-top", "", [{ [breakpoints.desktop]: "-1.5rem" }, { [breakpoints.tablet]: "-1rem" }])}
  ${getStyleWithMediaQuery("padding-left", "", [{ [breakpoints.laptopM]: "50px" }, { [breakpoints.tablet]: "0px" }])}
  ${getStyleWithMediaQuery("padding-right", "", [{ [breakpoints.laptopM]: "50px" }, { [breakpoints.tablet]: "0px" }])}
${getStyleWithMediaQuery("width", "", [
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
`;

const MaxButton = styled.button`
  border-radius: 7px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
  background: ${({ theme }) => theme.colors.gray20};
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  padding: 1rem;
  height: 50px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;

export const MainBox = styled(Card)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  margin: 1px;
  border: none;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-radius: 10px;
    box-shadow: none;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &:first-child {
      border-bottom: none;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: sticky;
  align-items: end;
  top: 135px;
  transform-style: preserve-3d;
  z-index: 6;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    overflow-anchor: none;
    position: absolute;
    transform: translateZ(-1px);
    height: calc(100% + 15px);
    top: -15px;
    left: -100px;
    @media only screen and (max-width: ${breakpoints.tablet}px) {
      left: -20px;
    }
    z-index: -1;
    width: 100vw;
  }
`;

export const ContainerBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top: none;
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 414px) {
    border: none;
    border-radius: 20px;
    padding: 0;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
`;

export const NftImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  max-height: 100px;
  overflow: auto;
  padding-top: 5px;
`;

export const RemoveFromCart = styled.div`
  cursor: pointer;
  display: none;
  position: absolute;
  top: -6px;
  right: -3px;
  padding: 4px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary}};
`;

export const ItemWrapper = styled.div`
  width: 68px;
  height: 68px;
  padding-right: 3px;
`;

export const ImageWrapper = styled.div<{ hasImage?: boolean; hasError?: boolean; isSelected?: boolean }>`
  position: relative;
  border-radius: 15px;
  width: 61px;
  height: 61px;
  &:hover ${RemoveFromCart} {
    display: flex;
  }
  border: 3px solid;
  border-color: ${({ isSelected, theme, hasError }) =>
    hasError ? "red" : isSelected ? `${theme.colors.cyan}` : `${theme.colors.primary}`};
`;

export const StyledNftImage = styled.img<{ hasImage?: boolean; hasError?: boolean }>`
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  border-radius: 12px;
  padding: 0;
  margin: 0;
  background-color: black;
  filter: ${({ hasImage, theme, hasError }) =>
    hasError
      ? "grayscale(100%)"
      : !hasImage &&
        theme.theme === "light" &&
        "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;

const ImageLoader = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: none;
`;

export const ApeCoinStakingApyButton = ({ apy, onClick }: { apy: number; onClick?: () => void }) => {
  return (
    <ApeBox direction="column" p="21px 0" justifyContent="center" onClick={onClick}>
      <Flex alignItems="center">
        <img
          width={16}
          height={16}
          src={ApeCoin}
          style={{
            border: "1px solid #FFFFFF",
            borderRadius: "50%",
          }}
        />
        <Flex>
          <Text
            color="secondary"
            size="sm"
            weight="700"
            textAlign="center"
            style={{ marginLeft: "6px", marginRight: "3px" }}
          >
            Start ApeCoin staking
          </Text>
          <NewTab color="white" height={11} width={11} strokeWidth={1.36} />
        </Flex>
      </Flex>

      <Text color="secondary" weight="700" textAlign="center" style={{ marginTop: "6px", fontSize: "48px" }}>
        {apy ? (apy > 100 ? formatCompactNumber(apy) : apy.toFixed(2)) : "- "}%
      </Text>

      <Text color="secondary" size="xxs" weight="700" textAlign="center">
        FREE{" "}
        <Text size="xxs" weight="400" color="secondary">
          yield with staked NFT
        </Text>
      </Text>
    </ApeBox>
  );
};

const ApeBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 14px;
  cursor: pointer;
  align-items: center;
  width: 100%;
`;
