import { useEffect, useMemo, useState } from "react";
import { ChevronDown, ChevronUp, HelpCircle, Search, X } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Box, Flex, StyledCheckbox } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Input, Label, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";

import { ICollectionAttribute, ICollectionAttributeValue, ICollectionBe } from "@/apis/collection/types";
import { useAppContext } from "@/components/AppContextProvider";
import { SupportedMarketPlacesList } from "@/constants/marketplaces";
import { useBnplQueryParams } from "@/hooks/useBnplQueryParams";
import { useDebounce } from "@/hooks/useDebounce";
import { bigNumToFixedStr, numberWithCommas } from "@/utils";

import { useBnplSelectors } from "../../../BnplPageContext";
import { useFilterContext } from "./FilterContext";

export const AttributeFilters: React.FC<{
  collection: ICollectionBe;
  attributes: ICollectionAttribute[];
  supportedCurrencies: { symbol: string; address: string }[];
}> = ({ collection, attributes, supportedCurrencies }) => {
  const theme = useTheme();
  const {
    showOSFlagged,
    setSearchQueryParam,
    maxRarity: _maxRarity,
    minRarity: _minRarity,
    minPrice: _minPrice,
    maxPrice: _maxPrice,
    currencies,
    marketplaces,
  } = useBnplQueryParams();
  const { currencySymbol } = useAppContext();

  const [searchAttribute, setSearchAttribute] = useState<string>("");
  const [searchCurrency, setSearchCurrency] = useState<string>("");
  const [searchMarketplace, setSearchMarketplace] = useState<string>("");
  const [minPrice, setMinPrice] = useState(_minPrice);
  const [maxPrice, setMaxPrice] = useState(_maxPrice);
  const [minRarity, setMinRarity] = useState(_minRarity);
  const [maxRarity, setMaxRarity] = useState(_maxRarity);
  const [attributesFiltered, setAttributesFiltered] = useState<ICollectionAttribute[]>([]);
  const [currenciesFiltered, setCurrenciesFiltered] = useState<
    {
      symbol: string;
      address: string;
    }[]
  >([]);
  const [marketplaceFiltered, setMarketplaceFiltered] = useState<string[]>([]);
  const selectMarketplace = (_marketplace: string) => {
    if ((marketplaces ?? []).includes(_marketplace)) {
      setSearchQueryParam({
        marketplaces: (marketplaces ?? []).filter(marketplace => marketplace !== _marketplace),
      });
    } else {
      setSearchQueryParam({
        marketplaces: [...(marketplaces ?? []), _marketplace],
      });
    }
  };
  const selectCurrency = (_symbol: string) => {
    if ((currencies ?? []).includes(_symbol)) {
      setSearchQueryParam({
        currencies: (currencies ?? []).filter((symbol: string) => symbol !== _symbol),
      });
    } else {
      setSearchQueryParam({
        currencies: [...(currencies ?? []), _symbol],
      });
    }
  };

  useDebounce(
    () => {
      if (searchMarketplace !== "") {
        setMarketplaceFiltered(
          SupportedMarketPlacesList.filter(
            _marketplace => _marketplace.toLowerCase() === searchMarketplace.toLowerCase(),
          ),
        );
      } else {
        setMarketplaceFiltered(SupportedMarketPlacesList);
      }
    },
    [searchMarketplace, SupportedMarketPlacesList],
    700,
  );

  useDebounce(
    () => {
      if (searchAttribute !== "") {
        setAttributesFiltered(
          attributes.filter(_attribute => _attribute.key.toLowerCase().includes(searchAttribute.toLowerCase())),
        );
      } else {
        setAttributesFiltered(attributes);
      }
    },
    [searchAttribute, attributes],
    700,
  );

  useDebounce(
    () => {
      if (searchCurrency !== "") {
        setCurrenciesFiltered(
          supportedCurrencies.filter(_attribute =>
            _attribute.symbol.toLowerCase().startsWith(searchCurrency.toLowerCase()),
          ),
        );
      } else {
        setCurrenciesFiltered(supportedCurrencies);
      }
    },
    [searchCurrency, supportedCurrencies],
    700,
  );

  const { setShowFilterAttributes, showFilterAttributes, setSelectedPriceCurrency } = useBnplSelectors();

  useEffect(() => {
    setSelectedPriceCurrency(currencySymbol);
  }, [currencySymbol]);

  const {
    openAttributes,
    setOpenAttributes,
    showRarityFilter,
    setShowRarityFilter,
    showCurrencyFilter,
    setShowCurrencyFilter,
    showMarketplaceFilter,
    setShowMarketplaceFilter,
  } = useFilterContext();
  const toggleOpenAttribute = (attributeKey: string) => {
    if (openAttributes.includes(attributeKey)) {
      setOpenAttributes(openAttributes.filter(_attributeKey => _attributeKey !== attributeKey));
    } else {
      setOpenAttributes([...openAttributes, attributeKey]);
    }
  };
  return (
    <AttributeFiltersWrapper>
      <Flex justifyContent="flex-end">
        <Button
          variant="ghost"
          style={{
            width: "fit-content",
            padding: 0,
          }}
          onClick={() => setShowFilterAttributes(!showFilterAttributes)}
        >
          <CloseIcon height={18} width={18} />
        </Button>
      </Flex>
      <TopAttributeBox alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" gap="2px">
          <Text color="secondary" size="md" weight="600">
            {`Show OS flagged`}
          </Text>
          <Tooltip>
            <HelpCircle height={15} width={15} color={theme.colors.secondary} />
            <TooltipText position="bottom" top="15px" left="5px" style={{ width: "115px" }}>
              <Flex direction="column" gap="7px">
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  {`OpenSea flags items when previous owners report the NFT as stolen.`}
                </Text>
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  {`Items with a warning symbol on the top right are flagged and may drop in price in the future.`}
                </Text>
              </Flex>
            </TooltipText>
          </Tooltip>
        </Flex>

        <AttributeToggler
          value={showOSFlagged}
          onChange={() => {
            setSearchQueryParam({
              osFlag: showOSFlagged ? false : undefined,
            });
          }}
        ></AttributeToggler>
      </TopAttributeBox>
      <AttributeBox direction="column" gap="1rem">
        <AttributeOptionBox
          alignItems="center"
          justifyContent="space-between"
          style={{
            cursor: "pointer",
          }}
          onClick={() => setShowMarketplaceFilter(!showMarketplaceFilter)}
        >
          <Text color="secondary" size="md" weight="600">
            {`Marketplace`}
          </Text>
          {showMarketplaceFilter ? <UpIcon size={21} /> : <DownIcon size={21} />}
        </AttributeOptionBox>
        {showMarketplaceFilter && (
          <Flex gap="1rem" direction="column">
            <StyledInput
              placeholder="Search marketplace"
              icon={<Search size={16} color={theme.colors.secondary} />}
              fontSize="md"
              value={searchMarketplace}
              onChange={e => setSearchMarketplace(e.target.value)}
            />
            <Flex direction="column" gap="1rem">
              {marketplaceFiltered.map(marketplace => (
                <Flex
                  alignItems="center"
                  key={`${marketplace}`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <StyledCheckbox
                    id={`${marketplace}`}
                    checked={!(marketplaces ?? []).includes(marketplace)}
                    onChange={() => {
                      selectMarketplace(marketplace);
                    }}
                    style={{
                      borderWidth: "2px",
                      borderRadius: "2px",
                    }}
                  />
                  <Box
                    pl="15px"
                    onClick={() => {
                      selectMarketplace(marketplace);
                    }}
                  >
                    <Text color="secondary" size="sm" weight="600">
                      {marketplace}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </Flex>
          </Flex>
        )}
      </AttributeBox>
      <AttributeBox direction="column" gap="1rem">
        <AttributeOptionBox
          alignItems="center"
          justifyContent="space-between"
          style={{
            cursor: "pointer",
          }}
          onClick={() => setShowCurrencyFilter(!showCurrencyFilter)}
        >
          <Text color="secondary" size="md" weight="600">
            {`Currency`}
          </Text>
          {showCurrencyFilter ? <UpIcon size={21} /> : <DownIcon size={21} />}
        </AttributeOptionBox>
        {showCurrencyFilter && (
          <Flex gap="1rem" direction="column">
            <StyledInput
              placeholder="Search currencies"
              icon={<Search size={16} color={theme.colors.secondary} />}
              fontSize="md"
              value={searchCurrency}
              onChange={e => setSearchCurrency(e.target.value)}
            />
            <Flex direction="column" gap="1rem">
              {currenciesFiltered.map(currency => (
                <Flex
                  alignItems="center"
                  key={`${currency.symbol}-${currency.address}`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <StyledCheckbox
                    id={`${currency.symbol}-${currency.address}`}
                    checked={(currencies ?? []).includes(currency.symbol)}
                    onChange={() => {
                      selectCurrency(currency.symbol);
                    }}
                    style={{
                      borderWidth: "2px",
                      borderRadius: "2px",
                    }}
                  />
                  <Box
                    pl="15px"
                    onClick={() => {
                      selectCurrency(currency.symbol);
                    }}
                  >
                    <Text color="secondary" size="sm" weight="600">
                      {currency.symbol}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </Flex>
          </Flex>
        )}
      </AttributeBox>
      <AttributeBox direction="column" gap="0.5rem">
        <Text color="secondary" size="md" weight="600">
          {`Price Range`} ({currencySymbol})
        </Text>
        <Flex direction="column" gap="10px">
          <Flex gap="10px">
            <StyledInput
              placeholder="Min"
              fontSize="md"
              value={minPrice}
              onChange={e => {
                setMinPrice(e.target.value === "" ? undefined : e.target.value);
              }}
            />
            <StyledInput
              placeholder="Max"
              fontSize="md"
              value={maxPrice}
              onChange={e => {
                setMaxPrice(e.target.value === "" ? undefined : e.target.value);
              }}
            />
          </Flex>
          <Button
            style={{ padding: "10px 0" }}
            onClick={() => {
              setSearchQueryParam({ price: { min: minPrice, max: maxPrice } });
            }}
          >
            Apply
          </Button>
        </Flex>
      </AttributeBox>
      <AttributeBox direction="column" gap="1.5rem">
        <AttributeOptionBox
          alignItems="center"
          justifyContent="space-between"
          style={{
            cursor: "pointer",
          }}
          onClick={() => setShowRarityFilter(!showRarityFilter)}
        >
          <Text color="secondary" size="md" weight="600">
            {`Rarity Rank`}
          </Text>
          {showRarityFilter ? <UpIcon size={21} /> : <DownIcon size={21} />}
        </AttributeOptionBox>
        {showRarityFilter && (
          <Flex direction="column" gap="10px">
            <Flex gap="10px">
              <Flex direction="column" gap="5px">
                <Text color="gray0" size="xs" weight="600">
                  {`From (Rare)`}
                </Text>
                <StyledInput
                  placeholder="1"
                  fontSize="md"
                  value={minRarity}
                  onChange={e => {
                    setMinRarity(e.target.value === "" ? undefined : e.target.value);
                  }}
                />
              </Flex>
              <Flex direction="column" gap="5px">
                <Text color="gray0" size="xs" weight="600">
                  {`To (Common)`}
                </Text>{" "}
                <StyledInput
                  placeholder={collection.tokenCount}
                  fontSize="md"
                  value={maxRarity}
                  onChange={e => {
                    setMaxRarity(e.target.value === "" ? undefined : e.target.value);
                  }}
                />
              </Flex>
            </Flex>
            <Button
              style={{ padding: "10px 0" }}
              onClick={() => {
                setSearchQueryParam({ rarity: { min: minRarity, max: maxRarity } });
              }}
            >
              Apply
            </Button>
          </Flex>
        )}
      </AttributeBox>
      <AttributeBox direction="column" gap="1.5rem">
        <StyledInput
          placeholder="Filter properties"
          icon={<Search size={16} color={theme.colors.secondary} />}
          fontSize="md"
          value={searchAttribute}
          onChange={e => setSearchAttribute(e.target.value)}
        />
      </AttributeBox>
      <Flex direction="column" gap="2rem">
        {attributesFiltered.map(attribute => (
          <AttributeOptionBox key={attribute.key} direction="column">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              style={{
                cursor: "pointer",
              }}
              onClick={() => toggleOpenAttribute(attribute.key)}
            >
              <Text color="secondary" size="md" weight="600">
                {attribute.key}
              </Text>
              {openAttributes.includes(attribute.key) ? <UpIcon size={21} /> : <DownIcon size={21} />}
            </Flex>
            {openAttributes.includes(attribute.key) && (
              <CollectionAttributes collection={collection} attribute={attribute} />
            )}
          </AttributeOptionBox>
        ))}
      </Flex>
    </AttributeFiltersWrapper>
  );
};

const CollectionAttributes: React.FC<{ collection: ICollectionBe; attribute: ICollectionAttribute }> = ({
  collection,
  attribute,
}) => {
  const theme = useTheme();
  const { attributes, setSearchQueryParam } = useBnplQueryParams();
  const [searchInput, setSearchInput] = useState("");
  const [attributeValues, setAttributeValues] = useState<ICollectionAttributeValue[]>(attribute.values);
  useDebounce(
    () => {
      if (searchInput) {
        setAttributeValues(
          attribute.values.filter(value => {
            return value.value.toLowerCase().includes(searchInput.toLowerCase());
          }),
        );
      } else {
        setAttributeValues(attribute.values);
      }
    },
    [searchInput, attribute],
    700,
  );

  return (
    <AttributeBox pt="0.8rem" gap="1rem">
      <StyledInput
        placeholder="Search"
        icon={<Search size={16} color={theme.colors.secondary} />}
        fontSize="md"
        onChange={e => setSearchInput(e.target.value)}
      />
      <Flex direction="column" gap="1rem">
        {(attributeValues ?? []).map(value => (
          <CollectionAttribute
            key={`${attribute.key}-${value.value}`}
            attribute={attribute}
            value={value}
            isChecked={attributes[attribute.key] && Object.values(attributes[attribute.key]).includes(value.value)}
            onSelect={() => {
              attributes[attribute.key] && Object.values(attributes[attribute.key]).includes(value.value)
                ? setSearchQueryParam({
                    attributes: {
                      ...attributes,
                      [attribute.key]: Object.values(attributes[attribute.key] as string[]).filter(
                        (val: string) => val !== value.value,
                      ),
                    },
                  })
                : setSearchQueryParam({
                    attributes: {
                      ...attributes,
                      [attribute.key]: attributes[attribute.key]
                        ? [...Object.values(attributes[attribute.key]), value.value]
                        : [value.value],
                    },
                  });
            }}
            collection={{
              tokenCount: collection.tokenCount,
            }}
          />
        ))}
      </Flex>
    </AttributeBox>
  );
};

const CollectionAttribute: React.FC<{
  value: ICollectionAttributeValue;
  attribute: ICollectionAttribute;
  isChecked: boolean;
  onSelect: () => void;
  collection: {
    tokenCount: string;
  };
}> = ({ value, collection, attribute, onSelect, isChecked }) => {
  const countPercentage = useMemo(() => {
    return (value.count / Number(collection.tokenCount)) * 100;
  }, [value.count, collection.tokenCount]);
  return (
    <Flex
      alignItems="center"
      w="100%"
      style={{
        cursor: "pointer",
      }}
    >
      <StyledCheckbox
        id={`${attribute.key}-${value.value}`}
        checked={isChecked}
        onChange={onSelect}
        style={{
          borderWidth: "2px",
          borderRadius: "2px",
        }}
      />
      <Flex direction="column" gap="0.2rem" w="100%" onClick={onSelect} pl="15px">
        <Flex justifyContent="space-between" gap="10px">
          <Text color="secondary" size="sm" weight="600">
            {value.value}
          </Text>
          <Text color="secondary" size="sm" weight="600">
            {value.count}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text color="gray0" size="xs" weight="400">
            {value.floorAskPrice
              ? `${bigNumToFixedStr(value.floorAskPrice.amount.raw, 2)} ${value.floorAskPrice.currency.symbol} floor`
              : "-"}
          </Text>
          <Text color="gray0" size="xs" weight="400">
            ({numberWithCommas(countPercentage.toFixed(2))}%)
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
const UpIcon = styled(ChevronUp)`
  color: ${props => props.theme.colors.secondary};
  transition: color 0.2s ease-in-out;
`;
const DownIcon = styled(ChevronDown)`
  color: ${props => props.theme.colors.secondary};
  transition: color 0.2s ease-in-out;
`;
const CloseIcon = styled(X)`
  color: ${props => props.theme.colors.secondary};
  transition: color 0.2s ease-in-out;
  :hover {
    color: ${props => props.theme.colors.gray0};
  }
`;
const AttributeOptionBox = styled(Flex)`
  user-select: none;
  &:hover {
    ${UpIcon} {
      color: ${props => props.theme.colors.gray0};
    }
    ${DownIcon} {
      color: ${props => props.theme.colors.gray0};
    }
  }
`;
const AttributeFiltersWrapper = styled.div`
  display: flex;
  position: sticky;
  top: 170px;
  gap: 1.2rem;
  flex-direction: column;
  background-color: ${props => props.theme.colors.primary};
  ${props =>
    getStyleWithMediaQuery("border-radius", "", [
      { [breakpoints.desktop]: props.theme.borderRadius },
      { [breakpoints.mobile]: 0 },
    ])}
  padding: 1rem;
  padding-bottom: 2rem;
  border: 1px solid ${props => props.theme.colors.gray20};
  min-width: 320px;
  max-width: 320px;
  ${getStyleWithMediaQuery("min-width", "", [{ [breakpoints.desktop]: "260px" }])}
  ${getStyleWithMediaQuery("max-width", "", [{ [breakpoints.desktop]: "260px" }, { [breakpoints.mobile]: "100vw" }])}
  ${getStyleWithMediaQuery("min-height", "", [{ [breakpoints.mobile]: "100%" }])}
  ${getStyleWithMediaQuery("max-height", "", [{ [breakpoints.desktop]: "calc(100vh - 220px - 18px)" }])}
  overflow-y: auto;
  ${getStyleWithMediaQuery("box-sizing", "", [{ [breakpoints.mobile]: "border-box" }])}
`;

const AttributeBox = styled(Flex)`
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.colors.gray20};
  padding-bottom: 1.2rem;
`;

const TopAttributeBox = styled(Flex)`
  user-select: none;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.colors.gray20};
  border-top: 1px solid ${props => props.theme.colors.gray20};
  padding: 1.2rem 0;
  cursor: pointer;
`;

const StyledInput = styled(Input)`
  width: 100%;
  background-color: ${props => props.theme.colors.gray10};
  border-color: ${props => props.theme.colors.gray10};
  border-radius: 6px;
  padding: 0.3rem;
  :hover {
    border-color: ${props => props.theme.colors.gray10};
  }
`;

const AttributeToggler = ({
  value,
  disabled = false,
  onChange,
}: {
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };
  return (
    <Label>
      <TogglerInput checked={value} type="checkbox" disabled={disabled} onChange={handleChange} />
      <StyledToggler isChecked={value} />
    </Label>
  );
};

const StyledToggler = styled.div<{ isChecked: boolean }>`
  position: relative;
  height: 9px;
  width: 29px;
  background: ${({ theme }) => (theme.theme === "light" ? "white" : "#6C6C6C")};
  border-radius: 25px;
  padding: 4px 5px;
  transition: 300ms background;
  border: 1px solid;
  border-color: ${({ theme, isChecked }) => {
    if (isChecked) {
      return theme.theme === "light" ? "#00DFDF" : "#006060";
    }
    return theme.theme === "light" ? "black" : "#6C6C6C";
  }};

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: ${({ theme }) => theme.colors.secondary};
    transform: translate(0, -50%);
  }
`;

const TogglerInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${StyledToggler} {
    background: ${({ theme }) => (theme.theme === "light" ? "#00DFDF" : "#006060")};

    &:before {
      transform: translate(18px, -50%);
      background: #00ffff;
    }
  }
`;
