import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AsyncState, useAsync, useAsyncAbortable } from "react-async-hook";

import { currencyList, fetchUSDPrice } from "@/apis/coinbase";
import { fetchCollectionTags, fetchCollections as fetchCollectionsBe } from "@/apis/collection";
import { ICollectionBe, ICollectionTag } from "@/apis/collection/types";
import { IExperiment, fetchExperiments } from "@/apis/experiment-flag";
import { ICyanStats, getCyanStats } from "@/apis/fetch-cyan-stats";
import { INft } from "@/types";
import { bigNumToFloat, getPreferredChainIdByDapp } from "@/utils";

import { SupportedChainId, isSupportedChain } from "../constants/chains";
import { useWeb3React } from "./Web3ReactProvider";

dayjs.extend(utc);

interface IUsdPrice {
  [key: string]: number;
}

interface IAppContext {
  experiment: AsyncState<IExperiment>;

  collectionTags: ICollectionTag[];

  collections: ICollectionBe[];
  collectionsLoading: boolean;
  updateCollections: (collection: ICollectionBe[]) => void;

  usdPrice: IUsdPrice;
  usdPrice24hr: IUsdPrice;
  currencySymbol: currencyList.eth | currencyList.pol | currencyList.weth | currencyList.usdc | currencyList.ape;

  fireConfetti: boolean;
  setFireConfetti: (arg: boolean) => void;

  cyanStats: {
    userCount: number;
    numOfBorrowersWithActiveLoan: number;
    loanedAmountBnplsUsd: number;
    loanedAmountPawnsUsd: number;
    totalStakedNftAmount: number;
    loanCount24h: number;
    loanAmount24h: number;
  };

  settledItems: INft[];
  setSettledItems: (arg: INft[]) => void;
}

export const AppContext = createContext<IAppContext>({
  experiment: { status: "not-requested", loading: true, result: undefined, error: undefined },

  collections: [],
  collectionsLoading: false,

  collectionTags: [],

  updateCollections: async () => {},

  usdPrice: {
    POL: 0,
    ETH: 0,
    WETH: 0,
    USDC: 0,
    APE: 0,
  },
  usdPrice24hr: {
    POL: 0,
    ETH: 0,
    WETH: 0,
    USDC: 0,
    APE: 0,
  },
  currencySymbol: currencyList.eth,

  fireConfetti: false,
  setFireConfetti() {},

  cyanStats: {
    userCount: 0,
    numOfBorrowersWithActiveLoan: 0,
    loanedAmountBnplsUsd: 0,
    loanedAmountPawnsUsd: 0,
    totalStakedNftAmount: 0,
    loanCount24h: 0,
    loanAmount24h: 0,
  },

  settledItems: [],
  setSettledItems: () => {},
});

export const useAppContext = () => {
  return useContext(AppContext);
};

export const useSettledItemContext = () => {
  const { settledItems, setSettledItems } = useAppContext();
  return { settledItems, setSettledItems };
};

export const useSupportedCollections = () => {
  const { collections, collectionsLoading, updateCollections } = useAppContext();
  return { collections, collectionsLoading, updateCollections };
};

export const useCollectionTags = () => {
  const { collectionTags } = useAppContext();
  return { collectionTags };
};

export const AppContextProvider: FC = ({ children }) => {
  const { account, chainId } = useWeb3React();
  const [isFireConfetti, setIsFireConfetti] = useState<boolean>(false);
  const [currencySymbol, setCurrencySymbol] = useState<currencyList.eth | currencyList.pol | currencyList.weth>(
    currencyList.eth,
  );
  const [usdPrice, setUsdPrice] = useState({
    POL: 0,
    ETH: 0,
    WETH: 0,
    USDC: 0,
    APE: 0,
  });
  const [usdPrice24hr, setUsdPrice24hr] = useState({
    POL: 0,
    ETH: 0,
    WETH: 0,
    USDC: 0,
    APE: 0,
  });
  const experiment = useAsyncAbortable<IExperiment>(
    async abortSignal => {
      return fetchExperiments(account, abortSignal);
    },
    [account],
  );

  const { result: cyanStatsBe } = useAsync<ICyanStats>(async () => await getCyanStats(), []);
  const cyanStats = useMemo(() => {
    const dataChainId = getPreferredChainIdByDapp(chainId);
    if (
      cyanStatsBe &&
      dataChainId !== SupportedChainId.SEPOLIA &&
      chainId !== SupportedChainId.BLAST_SEPOLIA &&
      chainId !== SupportedChainId.CURTIS
    ) {
      return {
        userCount: cyanStatsBe.userCount,
        loanCount24h: cyanStatsBe.loanCount24h,
        loanAmount24h: bigNumToFloat(cyanStatsBe.loanAmount24h) * usdPrice["ETH"],
        numOfBorrowersWithActiveLoan: cyanStatsBe.numOfBorrowersWithActiveLoan,
        loanedAmountBnplsUsd: bigNumToFloat(cyanStatsBe.loanedAmountBnplsUsd),
        loanedAmountPawnsUsd: bigNumToFloat(cyanStatsBe.loanedAmountPawnsUsd),
        totalStakedNftAmount: bigNumToFloat(cyanStatsBe.totalStakedNftAmount) * usdPrice["ETH"],
      };
    }
    return {
      userCount: 0,
      numOfBorrowersWithActiveLoan: 0,
      loanedAmountBnplsUsd: 0,
      loanedAmountPawnsUsd: 0,
      totalStakedNftAmount: 0,
      loanCount24h: 0,
      loanAmount24h: 0,
    };
  }, [cyanStatsBe, usdPrice, currencySymbol, chainId]);

  const {
    result: collections = [],
    loading: collectionsLoading,
    merge: updateCollectionsAsync,
  } = useAsyncAbortable<ICollectionBe[]>(
    async abortSignal => {
      if (!isSupportedChain(chainId)) return [];
      return await fetchCollectionsBe({ chainId, abortSignal });
    },
    [chainId],
  );

  const { result: collectionTags = [] } = useAsync<ICollectionTag[]>(async () => fetchCollectionTags(), []);

  const updateCollections = useCallback(
    (collections: ICollectionBe[]) => {
      updateCollectionsAsync({
        result: collections,
      });
    },
    [updateCollectionsAsync],
  );

  useEffect(() => {
    let ignorePreviousState = false;
    const setCurrency = async () => {
      if (!ignorePreviousState) {
        const currencyListFilter = Object.keys(currencyList).filter(data => {
          return data !== currencyList.weth.toLowerCase();
        });
        const usdPrices = await fetchUSDPrice({ currencies: currencyListFilter });
        setUsdPrice({
          POL: parseFloat(usdPrices[currencyList.pol].amount),
          ETH: parseFloat(usdPrices[currencyList.eth].amount),
          WETH: parseFloat(usdPrices[currencyList.eth].amount),
          USDC: parseFloat(usdPrices[currencyList.usdc].amount),
          APE: parseFloat(usdPrices[currencyList.ape].amount),
        });
        const lastUpdatedTime24hAgo = dayjs().subtract(1, "day").utc().format("YYYY-MM-DD");
        const usdPrices24hAgo = await fetchUSDPrice({ currencies: currencyListFilter, date: lastUpdatedTime24hAgo });
        setUsdPrice24hr({
          POL: parseFloat(usdPrices24hAgo[currencyList.pol].amount),
          ETH: parseFloat(usdPrices24hAgo[currencyList.eth].amount),
          WETH: parseFloat(usdPrices24hAgo[currencyList.eth].amount),
          USDC: parseFloat(usdPrices24hAgo[currencyList.usdc].amount),
          APE: parseFloat(usdPrices24hAgo[currencyList.ape].amount),
        });
      }

      if (chainId === SupportedChainId.POLYGON) {
        if (!ignorePreviousState) {
          setCurrencySymbol(currencyList.weth);
        }
      } else {
        if (!ignorePreviousState) {
          setCurrencySymbol(currencyList.eth);
        }
      }
    };
    setCurrency();
    return () => {
      ignorePreviousState = true;
    };
  }, [chainId]);

  const setFireConfetti = (arg: boolean) => {
    setIsFireConfetti(arg);
  };
  const fireConfetti = isFireConfetti;

  const [settledItems, setSettledItems] = useState<INft[]>([]);

  return (
    <AppContext.Provider
      value={{
        collections,
        collectionTags,
        collectionsLoading,
        experiment,
        usdPrice,
        currencySymbol,
        fireConfetti,
        setFireConfetti,
        cyanStats,
        updateCollections,
        usdPrice24hr,
        settledItems,
        setSettledItems,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
