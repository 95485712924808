import { useMemo } from "react";

import { Flex } from "@cyanco/components/theme";
import { Text } from "@cyanco/components/theme/v3";

import { BAKCAddress, mainPoolCollections } from "@/config";

import { useApeStakingUserAssets } from "../../../ApeCoinDataContext";
import { ISelectedNft } from "../../../types";
import { UserNftSelector } from "../UserNftSelector";

type IType = {
  selectedMainNfts: ISelectedNft[];
  onChangeSelectedMainNft: (nft: ISelectedNft | null) => void;
  selectedPairingNft: ISelectedNft | null;
  onChangeSelectedPairingNft: (nft: ISelectedNft | null) => void;
  selectedCollection: string;
  onChangeCollection: (c: string) => void;
  selectedPairingCollection: string;
  onChangePairingCollection: (c: string) => void;
};
export const StakingNftSelector: React.FC<IType> = ({
  selectedCollection,
  selectedMainNfts,
  selectedPairingNft,
  selectedPairingCollection,
  onChangePairingCollection,
  onChangeSelectedPairingNft,
  onChangeSelectedMainNft,
  onChangeCollection,
}) => {
  const { stakableAssets, stakablePositions, loading, stakedPositions, stakedAssets } = useApeStakingUserAssets();

  const pairableNfts = useMemo(() => {
    const positions = [...stakablePositions, ...stakedPositions].map(position => ({
      address: position.metadata.collectionAddress,
      tokenId: position.tokenId,
      isCyanWallet: true,
      imageUrl: position.metadata.imageUrl,
      apeStaking: position.apeStaking,
    }));
    const assets = [...stakableAssets, ...stakedAssets];
    return [...assets, ...positions].filter(
      asset => asset.address.toLowerCase() !== BAKCAddress && !asset.apeStaking.isPaired,
    );
  }, [stakableAssets, stakablePositions]);

  const mainPoolNfts = useMemo(() => {
    const positions = stakablePositions.map(position => ({
      address: position.metadata.collectionAddress,
      tokenId: position.tokenId,
      isCyanWallet: true,
      imageUrl: position.metadata.imageUrl,
      apeStaking: position.apeStaking,
    }));
    return [...stakableAssets, ...positions];
  }, [stakablePositions, stakableAssets]);

  return (
    <Flex direction="column" gap="13px">
      <Flex direction="column" gap="12px">
        <Text size="xs" color="secondary" weight="600">
          {`Choose NFT to stake with:`}
        </Text>
        <UserNftSelector
          collections={mainPoolCollections}
          nfts={mainPoolNfts}
          selectedCollection={selectedCollection}
          selectedNfts={selectedMainNfts}
          onChangeSelectedCollection={onChangeCollection}
          onChangeSelectedNft={onChangeSelectedMainNft}
          multipleSelect={selectedCollection.toLowerCase() != BAKCAddress.toLowerCase()}
          isLoading={loading}
        />
      </Flex>
      {selectedMainNfts.length == 1 && selectedMainNfts[0].address.toLowerCase() === BAKCAddress && (
        <Flex direction="column" gap="12px">
          <Text size="xs" color="secondary" weight="600">
            {`Choose NFT to pair stake with BAKC:`}
          </Text>
          <UserNftSelector
            multipleSelect={false}
            collections={mainPoolCollections.filter(item => item.address !== BAKCAddress)}
            nfts={pairableNfts}
            selectedCollection={selectedPairingCollection}
            selectedNfts={selectedPairingNft ? [selectedPairingNft] : []}
            onChangeSelectedCollection={onChangePairingCollection}
            onChangeSelectedNft={onChangeSelectedPairingNft}
            isLoading={false}
          />
        </Flex>
      )}
    </Flex>
  );
};
