import { useMemo } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Button, SystemMessage, Text } from "@cyanco/components/theme/v3";

import { DISCORD_SERVER_INVITE_LINK } from "@/config";

export enum ErrorType {
  NOT_SUPPORTED = "not-supported",
  LOCKED = "locked",
}

export const BlockedFunction = ({ errorType, onClose }: { onClose: () => void; errorType: ErrorType }) => {
  const errorBody: {
    variant: "warning" | "error";
    title: string;
    msg: string;
    description: string | React.ReactNode;
  } = useMemo(() => {
    switch (errorType) {
      case ErrorType.NOT_SUPPORTED:
        return {
          variant: "warning",
          title: `Blocked Function!`,
          msg: `A recently called function is unavailable.`,
          description: (
            <Text size="xs" color="black">
              {`The desired function cannot be executed, as it has not been whitelisted for Cyan Wallet. Please contact us on`}
              {` `}
              <Link href={DISCORD_SERVER_INVITE_LINK} target="_blank" rel="noopener noreferrer">
                Discord
              </Link>
              {` `} or at {` `}
              <Link href="mailto:hi@usecyan.com" target="_blank" rel="noopener noreferrer">
                hi@usecyan.com
              </Link>
              {` `}
              {`if you would like this method added.`}
            </Text>
          ),
        };
      case ErrorType.LOCKED:
        return {
          variant: "error",
          title: `Blocked Function!`,
          msg: `A recently called function is unavailable for your NFT.`,
          description: `The desired function cannot be executed, as the loan on the NFT has not been paid off. Once the loan has been completed, it will be fully unlocked and available for any function.`,
        };
    }
  }, [errorType]);
  return (
    <Flex direction="column" gap="1rem">
      <SystemMessage
        variant={errorBody.variant}
        title={errorBody.title}
        msg={errorBody.msg}
        description={errorBody.description}
      />
      <Wrapper>
        <Text color="red" weight="700" size="sm">
          {`Execution Error`}
        </Text>
        <div>
          <CloseButton onClick={onClose}>
            <Text color="white" weight="600" size="sm">{`Close`}</Text>
          </CloseButton>
        </div>
      </Wrapper>
    </Flex>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #ff0000;
  border-radius: 10px;
  padding: 10px;
`;
const CloseButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.gray20};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  padding: 0.6rem 1rem;
  border-radius: 6px;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray20};
    border: 1px solid ${({ theme }) => theme.colors.gray20};
  }
`;

const Link = styled.a`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: underline;
`;
