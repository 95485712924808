import { useEffect, useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound } from "@cyanco/components/theme/v3";

import { IVaultTokenTransaction } from "@/components/Vault/types";
import { useWeb3React } from "@/components/Web3ReactProvider";

import { getChainExplorerURL } from "../../../../utils";
import { useVaultTransactions } from "../../AccountDataContext";
import { WalletTypes, useVaultTokensTabContext } from "../../AccountPageContext";
import { ContainerBox, Header, ListViewBox } from "../../index";
import { VaultTransactionListHeader, VaultTransactionRow } from "./VaultTransactionRow";

export const HistoricalVaults = () => {
  const { vaultTransactions } = useVaultTransactions();
  const { selectedVaultWalletType } = useVaultTokensTabContext();
  const { account, chainId } = useWeb3React();
  const [sortedVaultTransactions, setSortedVaultTransactions] = useState<IVaultTokenTransaction[]>();

  const updateSortedVaultTransactions = (updatedVaultTransactions: IVaultTokenTransaction[]) => {
    setSortedVaultTransactions([...updatedVaultTransactions]);
  };

  useEffect(() => {
    setSortedVaultTransactions(vaultTransactions);
  }, [vaultTransactions]);
  const filteredVaultTransactions = useMemo(() => {
    if (sortedVaultTransactions) {
      if (selectedVaultWalletType === WalletTypes.cyanWallet)
        return sortedVaultTransactions.filter(position => position.isCyanWallet);
      if (selectedVaultWalletType === WalletTypes.mainWallet)
        return sortedVaultTransactions.filter(position => !position.isCyanWallet);
      return sortedVaultTransactions;
    }
    return vaultTransactions;
  }, [sortedVaultTransactions, selectedVaultWalletType]);
  return (
    <ListContainer>
      <ListViewBox>
        <Header>
          <VaultTransactionListHeader
            sortedVaultTransactions={vaultTransactions}
            updateSortedVaultTransactions={updateSortedVaultTransactions}
          />
        </Header>
        <ContainerBox>
          {filteredVaultTransactions.length === 0 && <NotFound msg={`No vault transactions found`} />}
          <Container>
            {account && (
              <Virtuoso
                customScrollParent={window.document.getElementById("custom-scroll-parent") ?? undefined}
                totalCount={filteredVaultTransactions.length}
                data={filteredVaultTransactions}
                itemContent={(index, transaction) => (
                  <VaultTransactionRow
                    key={transaction.id}
                    isLast={index === filteredVaultTransactions.length - 1}
                    transaction={transaction}
                    txnUrl={`${getChainExplorerURL(chainId)}/tx/${transaction.txnHash}`}
                    account={account}
                  />
                )}
              />
            )}
          </Container>
        </ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 414px) {
    border: none;
    border-radius: 20px;
    padding: 0;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
`;
