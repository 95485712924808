import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { notifyApeVaultBalanceError } from "@/apis/notification";
import { CloseButton } from "@/components/ApeCoinStaking/CommonComponents";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { BAYCAddress, apeCoinContract, apeStakingContract, apeVaultContract } from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IInitiateApeBulkStake } from "@/hooks/useApePlanCreation.types";
import { useApproval } from "@/hooks/useApproval";
import { getChainExplorerURL, isApeCoinStakingPossible } from "@/utils";
import { getAccountBalanceOfErc20, getVaultBalance } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";

import { useApeStakingUserAssets } from "../../../ApeCoinDataContext";
import { useSelectedStakingNftsContext } from "../../../ApeCoinPageContext";
import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";
import { BulkStakingProgressLoader } from "../../common";

export const OwnedApeBulkStaking = (params: IInitiateApeBulkStake) => {
  const { selectedMainNfts } = params;
  const { removeAll } = useSelectedStakingNftsContext();
  const { setFireConfetti } = useAppContext();
  const { giveErc20Approval } = useApproval();
  const cyanWallet = useCyanWallet();
  const { updateStakingData } = useApeStakingUserAssets();
  const { updateUserBalance } = useApeCoinStatsContext();
  const { addTransaction, transactions } = useTransactionContext();
  const { showApeBulkPlanModal } = useApePlanCreation();
  const { chainId, account, provider, signer } = useWeb3React();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<StakingSteps>(StakingSteps.TranferApe);

  const totalStakingAmount = useMemo(() => {
    return selectedMainNfts.reduce((acc, cur) => acc.add(cur.userStakingAmount), BigNumber.from(0));
  }, [selectedMainNfts]);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        updateStakingData();
        updateUserBalance();
        removeAll();
        setFireConfetti(true);
        setSelectedStep(StakingSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const transfer = async () => {
    if (!signer || !account || !cyanWallet || !isApeCoinStakingPossible(chainId) || !provider) return;
    const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
      provider,
      mainWallet: account,
      currencyAddress: apeCoinContract,
      chainId,
    });
    const requiredAmount = totalStakingAmount.sub(cyanWalletBalance);
    if (requiredAmount.gt(0) && mainWalletBalance.lt(requiredAmount)) {
      openRepaymentModal({
        title: `Not enough funds`,
        msg: `Your payment didn’t go through due to lack of funds`,
        description: ` Please double check the amount of crypto in your main wallet. In order to facilitate payments for non-native currency plans, it is imperative that the payment amount be drawn from the Cyan wallet.`,
      });
    } else if (requiredAmount.gt(0)) {
      const sampleErc20 = f.SampleERC20TokenFactory.connect(apeCoinContract, signer);
      const txn = await sampleErc20.transfer(cyanWallet.walletAddress, requiredAmount);
      await txn.wait();
    }
  };

  const accept = async () => {
    if (!signer || !account || !cyanWallet) return;
    await giveErc20Approval(
      {
        currencyAddress: apeCoinContract,
        cyanWallet,
        amount: totalStakingAmount,
      },
      apeStakingContract,
    );
  };

  const stakeApeCoin = async () => {
    if (!account || !cyanWallet || !signer) return;
    const apeStakingIFace = f.ApeCoinStakingFactory.createInterface();
    const transactions = selectedMainNfts.map(nft => {
      const isBAYC = nft.address.toLowerCase() === BAYCAddress.toLowerCase();
      const params = [{ tokenId: nft.tokenId, amount: nft.userStakingAmount }];
      const encodedFnData = isBAYC
        ? apeStakingIFace.encodeFunctionData("depositBAYC", [params])
        : apeStakingIFace.encodeFunctionData("depositMAYC", [params]);
      return {
        to: apeStakingContract,
        data: encodedFnData,
        value: "0",
      };
    });
    const data = cyanWallet.interface.encodeFunctionData("executeBatch", [transactions]);
    const tx = await signer.sendTransaction({
      to: cyanWallet.walletAddress,
      data,
    });
    addTransaction({
      hash: tx.hash,
      type: "ape-stake",
    });
    setActiveTx(tx.hash);
  };

  useEffect(() => {
    const onStepChange = async (step: StakingSteps) => {
      try {
        switch (step) {
          case StakingSteps.TranferApe:
            await transfer();
            setSelectedStep(StakingSteps.Accept);
            break;
          case StakingSteps.Accept:
            await accept();
            setSelectedStep(StakingSteps.Stake);
            break;
          case StakingSteps.Stake:
            await stakeApeCoin();
            break;
        }
      } catch (err: any) {
        if (err?.error?.message?.match(/Not enough \w+ in the Vault/g)) {
          const _sendVaultError = async () => {
            if (!account || !provider) return;
            const vaultBalance = await getVaultBalance({ vaultAddress: apeVaultContract, provider });

            await notifyApeVaultBalanceError({
              vault: apeVaultContract,
              user: account,
              vaultBalance: vaultBalance.toString(),
            });
          };
          _sendVaultError().catch(e => console.error(e));
        }
        openRepaymentModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet, account]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    showApeBulkPlanModal({
      ...params,
      err: mappedError,
    });
  };
  const stepMarkFiltered = useMemo(() => {
    return StakingStepMarks.map(item => {
      if (item.value === StakingSteps.Stake && txnFinal !== null) {
        return {
          ...item,
          description: `View on Etherscan`,
        };
      }
      return item;
    });
  }, [txnFinal]);
  return (
    <Flex gap="1rem" direction="column">
      <BulkStakingProgressLoader selectedNfts={selectedMainNfts} isLoading={selectedStep != StakingSteps.Done} />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep == StakingSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum StakingSteps {
  TranferApe = 1,
  Accept = 2,
  Stake = 3,
  Done = 4,
}
const StakingStepMarks = [
  {
    value: StakingSteps.TranferApe,
    title: `Transfer $APE to Cyan Wallet`,
    description: `A small amount of gas is required to move $APE`,
  },
  {
    value: StakingSteps.Accept,
    title: `Give Permission to Move ApeCoin`,
    description: `Permission to move my ApeCoin to stake into ApeCoin pools.`,
  },
  {
    value: StakingSteps.Stake,
    title: `$APE successfully staked`,
    description: `A small amount of gas is required to stake $APE`,
  },
];
