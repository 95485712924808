import { BarChart } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Flex, NftCardText, Text, Tooltip, TooltipText } from "@cyanco/components/theme";

import { INFtRarity } from "@/components/NftStatus";
import { numberWithCommas } from "@/utils";

export const NftRarity = ({ rarity, isPlan }: { rarity: INFtRarity; isPlan?: boolean }) => {
  const theme = useTheme();
  return (
    <Tooltip>
      <TooltipBox
        style={{
          backgroundColor: isPlan ? theme.colors.primary : theme.colors.gray20,
        }}
      >
        <Flex gap="3px" alignItems="center">
          <BarChart color={theme.colors.secondary} height={"10px"} />
          <NftCardText weight="500" color="secondary" sub>
            {numberWithCommas(rarity.rank)}
          </NftCardText>
        </Flex>
      </TooltipBox>
      <TooltipText position="top" top="-50px" left="-3px">
        <Flex direction="column">
          <Text color="gray0" size="xs" weight="700" textWrap={false}>{`Rarity`}</Text>
          <Text size="xs" color="primary" weight="700" textWrap={false}>
            {rarity.relativeRank}
          </Text>
        </Flex>
      </TooltipText>
    </Tooltip>
  );
};

const TooltipBox = styled.div`
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
`;
