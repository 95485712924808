import { useMemo, useState } from "react";
import { AlertCircle, AlertTriangle, Tag } from "react-feather";
import styled, { useTheme } from "styled-components";

import { ClickableArea, Flex } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import {
  Button,
  NftCard,
  NftCardActionText,
  NftCardActionWrapper,
  NftCardAddButton,
  NftCardLoading,
  NftCardText,
  NftLastSaleText,
  NftMetadataImage,
  NftMetadataInfoWrapper,
  Text,
  Tooltip,
  TooltipText,
} from "@cyanco/components/theme/v3";
import { BarChart, CyanLogo } from "@cyanco/components/theme/v3/icons";

import { ICollectionBe } from "@/apis/collection/types";
import { INFtRarity } from "@/components/NftStatus";
import { InfiniteScroller } from "@/components/Pagination/InfiniteScroller";
import { INftBe } from "@/types";
import { bigNumToFloat, numberWithCommas, shortenName } from "@/utils";
import { isMobile } from "@/utils/userAgent";

import { useBnplCart } from "../BnplCartContext";

export const OnSaleNftCard = ({
  nft,
  onClick,
  hideCart,
  bargainListing,
  isSelectToCardPossible,
  collectionFloorPrice,
}: {
  nft: INftBe & { rarity?: INFtRarity };
  onClick?: (n: INftBe) => void;
  hideCart?: boolean;
  bargainListing?: boolean;
  isSelectToCardPossible?: boolean;
  collectionFloorPrice?: ICollectionBe["floorAsk"];
}) => {
  const [marketImgErr, setMarketImgErr] = useState(false);
  const theme = useTheme();

  const formatNumber = useMemo(() => {
    const priceInFloat = bigNumToFloat(nft.price, nft.currency.decimal);
    if (priceInFloat <= 0.01) {
      return 4;
    }
    return 3;
  }, [nft.price]);

  const getPrice = useMemo(
    () => (!nft.price ? "-" : numberWithCommas(bigNumToFloat(nft.price, nft.currency.decimal), formatNumber, true)),
    [nft.price, formatNumber],
  );
  const getBnplPrice = useMemo(() => {
    return !nft.price
      ? "-"
      : numberWithCommas(bigNumToFloat(nft.price, nft.currency.decimal) * 0.25, formatNumber, true);
  }, [nft.price, formatNumber]);
  const { items: cartItems, toggleItem } = useBnplCart();
  const isInCart = !!cartItems.find(
    ({ address, tokenId, privateSaleId }) =>
      address === nft.address && tokenId === nft.tokenId && nft.privateSale?.id == privateSaleId,
  );

  return (
    <NftCardStyled selected={isInCart && !hideCart} privateSale={!bargainListing && !!nft.privateSale}>
      <ClickableArea onClick={() => onClick && onClick(nft)}>
        <Flex direction="column">
          <NftMetadataImage imageUrl={nft.imageUrl} alt={`${nft.collectionName} ${nft.tokenId}`}>
            {nft.isPrivate ? (
              <CyanLogoWrapper>
                <CyanLogo />
              </CyanLogoWrapper>
            ) : marketImgErr || !nft.marketIcon ? (
              <MarketLogoAlert />
            ) : (
              <MarketLogo src={nft.marketIcon} alt={nft.marketName} onError={() => setMarketImgErr(true)} />
            )}
            {nft.isFlaggedByOS && <FlaggedByOS />}
            <InfoButtonWrapper>
              {nft.isPrivate && (
                <InfoButton
                  bgColor={bargainListing ? theme.colors.green : "#E49B2D"}
                  selected={isInCart && !hideCart}
                  borderRadius={nft.isFlaggedByOS ? "0px" : undefined}
                >
                  <FlaggedByOSText size="sm" weight="700" color="black">
                    <Tag color="black" width={18} height={18} strokeWidth={3} />{" "}
                    {bargainListing ? "Bargain Sale" : "Private Listing"}
                  </FlaggedByOSText>
                </InfoButton>
              )}
              {nft.isFlaggedByOS && (
                <InfoButton selected={isInCart && !hideCart}>
                  <FlaggedByOSText size="sm" weight="700" color="black">
                    <AlertTriangle color="black" width={18} height={18} strokeWidth={3} /> Flagged on OpenSea
                  </FlaggedByOSText>
                </InfoButton>
              )}
            </InfoButtonWrapper>
          </NftMetadataImage>
        </Flex>
      </ClickableArea>
      <NftMetadataInfoWrapper>
        <div>
          <NftCardText weight="800" color="secondary">
            {shortenName(nft.collectionName, 21, 20, 0)} #{shortenName(nft.tokenId, 10, 6)}
          </NftCardText>
        </div>
        <Flex justifyContent="space-between" alignItems="center">
          <Tooltip>
            {nft.privateSale && collectionFloorPrice?.price && (
              <NftLastSaleText weight="500" color="secondary" sub style={{ marginRight: "4px" }}>
                {`${numberWithCommas(
                  bigNumToFloat(collectionFloorPrice.price.amount.raw, collectionFloorPrice.price.currency.decimals),
                  formatNumber,
                )} ${collectionFloorPrice.price.currency.symbol}`}
              </NftLastSaleText>
            )}

            <NftCardText weight="500" color="secondary" sub>
              {`${getPrice} ${nft.currency.symbol}`}
            </NftCardText>
            <TooltipText left="0" top="-40px">
              <Text size="xs" color="primary" weight="700" textWrap={false}>
                {`Buy Now Price`}
              </Text>
            </TooltipText>
          </Tooltip>
          {nft.rarity && (
            <Tooltip>
              <WhiteBox>
                <Flex gap="3px" alignItems="center">
                  <BarChart color={theme.colors.secondary} height={"10px"} />
                  <NftCardText weight="500" color="secondary" sub>
                    {numberWithCommas(nft.rarity.rank)}
                  </NftCardText>
                </Flex>
                <TooltipText left="0" top="-50px">
                  <Flex direction="column">
                    <Text color="gray0" size="xs" weight="700" textWrap={false}>{`Rarity`}</Text>
                    <Text size="xs" color="primary" weight="700" textWrap={false}>
                      {nft.rarity.relativeRank}
                    </Text>
                  </Flex>
                </TooltipText>
              </WhiteBox>
            </Tooltip>
          )}
        </Flex>
      </NftMetadataInfoWrapper>
      <NftCardActionWrapper style={{ position: "relative" }}>
        <NftBnplPriceWrapper justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <StyledButton
              onClick={e => {
                e.stopPropagation();
                onClick && onClick(nft);
              }}
              disabled={!onClick}
            >
              <NftCardActionText weight="500" textAlign="left" color="gray0">
                {`Buy Now with:`}
              </NftCardActionText>
            </StyledButton>
          </Flex>
          <NftCardText weight="600" color="secondary">
            {getBnplPrice} {nft.currency.symbol}
          </NftCardText>
        </NftBnplPriceWrapper>
        {!hideCart && (
          <StyledNftCardAddButton
            isInCart={isInCart}
            onClick={e => {
              e.stopPropagation();
              toggleItem(nft);
            }}
            id={nft.tokenId}
            disabled={!isInCart && !isSelectToCardPossible}
          >
            {!isInCart ? (
              <NftCardText weight="600" color="black">{`Select`}</NftCardText>
            ) : (
              <NftCardText weight="600" color="secondary">
                {`Unselect`}
              </NftCardText>
            )}
          </StyledNftCardAddButton>
        )}
      </NftCardActionWrapper>
    </NftCardStyled>
  );
};

export const OnSaleNftCardLoading: React.FC = () => {
  return (
    <BnplNFTWrapper>
      <InfiniteScroller isGrid hasMore={false}>
        {Array.from(Array(isMobile ? 4 : 8).keys()).map(loader => (
          <NftCardLoading key={loader} actionText={`Buy Now, Pay Later `} />
        ))}
      </InfiniteScroller>
    </BnplNFTWrapper>
  );
};

const BnplNFTWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 30px;
`;

const MarketLogo = styled.img`
  position: absolute;
  top: 5px;
  left: 5px;
  height: 25px;
  width: 25px;
  z-index: 1;
`;
const MarketLogoAlert = styled(AlertCircle)`
  position: absolute;
  top: 5px;
  left: 5px;
  height: 25px;
  width: 25px;
  z-index: 1;
  color: #e49b2d;
`;
const StyledButton = styled.button<{ disabled?: boolean }>`
  border: none;
  outline: none;
  height: 100%;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.2s;
  padding-left: 0;
`;
const AddCardWrapper = styled.div`
  position: absolute;
  min-width: 50%;
  height: 35px;
  right: 5px;
  bottom: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
`;

const NftBnplPriceWrapper = styled(Flex)`
  height: 25px;
`;

const StyledNftCardAddButton = styled(NftCardAddButton)`
  transition: all 0.2s ease-out;
  position: absolute;
  opacity: 0;
  top: 90%;
  :hover {
    border: none;
  }
`;

const NftCardStyled = styled(NftCard)<{ privateSale?: boolean }>`
  border-color: ${({ theme, selected, privateSale }) =>
    selected
      ? privateSale
        ? theme.colors.yellow
        : theme.theme == "dark"
        ? theme.colors.cyan
        : "#00E7E7"
      : theme.colors.gray20};
  :hover {
    ${AddCardWrapper} {
      visibility: visible;
      opacity: 1;
    }
    ${StyledNftCardAddButton} {
      top: 25%;
      opacity: 1 !important;
      height: 25px !important;
    }
    ${NftCardActionText} {
      color: ${({ theme }) => theme.colors.cyan} !important;
    }
  }
`;

const WhiteBox = styled.div`
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
`;

const FlaggedByOS = styled(AlertTriangle)`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  z-index: 1;
  color: red;
`;

const CyanLogoWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  z-index: 1;
`;

const InfoButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 1;
`;

const InfoButton = styled(Button)<{ selected?: boolean; bgColor?: string; borderRadius?: string }>`
  transition: border-radius 0s;
  border-radius: ${({ selected, borderRadius }) =>
    borderRadius ? borderRadius : selected ? "0 0 8px 8px" : "0 0 14px 14px"} !important;
  background: ${({ bgColor }) => (bgColor ? bgColor : "red")};
  padding: 8px 0;
  border: none;
  :hover {
    background: ${({ bgColor }) => (bgColor ? bgColor : "red")};
  }
`;

const FlaggedByOSText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 5px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 12 }])};
`;
