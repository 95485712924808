import { useMemo } from "react";

import { factories as f } from "@cyanco/contract";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";

export const usePaymentPlanContract = () => {
  const { chainId, provider } = useWeb3React();

  const contract = useMemo(() => {
    if (!chainId || !provider) return;

    try {
      const paymentPlanContractAddress = getPaymentPlanFromChainId(chainId);
      return f.PaymentPlanV2Factory.connect(paymentPlanContractAddress, provider);
    } catch (e) {}
  }, [chainId, provider]);

  if (!contract) return {};

  return {
    contract,
    filters: contract.filters,
  };
};
