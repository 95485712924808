import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import { useEffect } from "react";

import { LOCAL_STORAGE_TRANSACTIONS_KEY } from "../config";
import { useLocalStorage } from "./useLocalStorage";

const TXN_TYPES_TO_WAIT = [
  "bnpl-create",
  "bnpl-pay",
  "pawn-create",
  "plan-refinance",
  "pawn-pay",
  "vault-stake",
  "vault-unstake",
  "ape-plan-create",
  "ape-coin-plan-create",
  "ape-plan-complete",
  "ape-coin-plan-complete",
  "p2p-create",
  "p2p-pay",
  "p2p-liquidate",
  "p2p-update",
  "p2p-offer-cancel",
];
export type ITransaction = {
  hash: string;
  type:
    | "bnpl-create"
    | "bnpl-pay"
    | "pawn-accept"
    | "pawn-create"
    | "plan-refinance"
    | "pawn-pay"
    | "vault-stake"
    | "vault-unstake"
    | "ape-stake"
    | "ape-unstake"
    | "ape-claim"
    | "ape-plan-create"
    | "ape-coin-plan-create"
    | "ape-coin-plan-complete"
    | "ape-plan-complete"
    | "p2p-create"
    | "p2p-pay"
    | "p2p-liquidate"
    | "p2p-update"
    | "p2p-offer-cancel"
    | "vault-liquidate";

  expiresAt: number;
  data?:
    | Record<string, string | number | boolean | BigNumber>
    | Record<string, Array<string | number | boolean | BigNumber>>;
};

export const useTransactions: FnUseTransactions = () => {
  const { provider } = useWeb3React();
  const [transactions, setTransactions] = useLocalStorage<ITransaction[]>(LOCAL_STORAGE_TRANSACTIONS_KEY, []);

  useEffect(() => {
    if (!provider) return;

    for (const txn of transactions) {
      if (TXN_TYPES_TO_WAIT.includes(txn.type)) continue;

      provider.waitForTransaction(txn.hash).then(() => {
        setTransactions(oldTransactions => oldTransactions.filter(oldTx => oldTx !== txn));
      });
    }
  }, [transactions, setTransactions, provider]);

  const addTransaction = (transaction: Omit<ITransaction, "expiresAt"> & { expiresAt?: number }) => {
    let defaultExpiresAt = Date.now() + 1000 * 60 * 5; // 5 minutes
    if (transaction.type === "bnpl-create") {
      defaultExpiresAt = Date.now() + 1000 * 60 * 60 * 2; // 2 hours
    }
    const newTxn: ITransaction = {
      ...transaction,
      expiresAt: transaction.expiresAt ?? defaultExpiresAt,
    };

    setTransactions(txns => [...txns, newTxn]);
  };

  return { transactions, setTransactions, addTransaction } as const;
};

type FnUseTransactions = () => {
  transactions: ITransaction[];
  addTransaction: (v: Omit<ITransaction, "expiresAt"> & { expiresAt?: number }) => void;
  setTransactions: React.Dispatch<React.SetStateAction<ITransaction[]>>;
};
