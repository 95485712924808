import Axios, { AxiosError } from "axios";

const baseURL = `${process.env.REACT_APP_BE_ENDPOINT}`;
export const axios = Axios.create({
  baseURL,
  headers: { "X-API-KEY": process.env.REACT_APP_API_KEY ?? "" },
});
export const isAxiosError = (e: any, status?: number): e is AxiosError => {
  return !!e?.isAxiosError && (!status || e.response?.status === status);
};
