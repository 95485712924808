import type { Connector } from "@web3-react/types";

import { SupportedChainId } from "../constants/chains";

interface SwitchNetworkArguments {
  connector: Connector;
  chainId?: SupportedChainId;
}

export async function switchToNetwork({ connector, chainId }: SwitchNetworkArguments): Promise<null | void> {
  if (!connector?.provider?.request) {
    return;
  }

  try {
    await connector.activate(chainId);
  } catch (error) {
    throw error;
  }
}
