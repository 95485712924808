import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { CyanWallet, useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { ApeCoinBalanceLoading, CloseButton } from "@/components/ApeCoinStaking/CommonComponents";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import {
  COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS,
  POOL_IDS_MAPPED_BY_ADDRESS,
  PoolId,
  apeStakingContract,
} from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IInitiateOwnedApeUnstake } from "@/hooks/useApePlanCreation.types";
import { bigNumToFixedStr, bigNumToFloat, getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { useApeStakingUserAssets } from "../../../ApeCoinDataContext";
import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";

const buildCyanWalletExecuteFnData = (cyanWallet: CyanWallet, encodedFnData: string) => {
  const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
    apeStakingContract,
    "0",
    encodedFnData,
  ]);
  return encodedCyanFnData;
};

export const OwnedApeUnstaking = (params: IInitiateOwnedApeUnstake) => {
  const { selectedMainNft, unstakingAmount, releaseWallet, isClaiming } = params;
  const { setFireConfetti } = useAppContext();
  const { account } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { updateUserBalance } = useApeCoinStatsContext();
  const { transactions, addTransaction } = useTransactionContext();
  const { updateStakingData } = useApeStakingUserAssets();
  const { showPlanModal } = useApePlanCreation();
  const { chainId, provider, signer } = useWeb3React();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<UnstakingSteps>(
    isClaiming ? UnstakingSteps.CliamingRewards : UnstakingSteps.Unstaking,
  );
  const apeCoinStakingIFace = f.ApeCoinStakingFactory.createInterface();

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        updateUserBalance();
        updateStakingData();
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setFireConfetti(true);
        setSelectedStep(UnstakingSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const unstake = async () => {
    if (!signer) throw new Error("Something went wrong");
    const apeCoinStakingContractWriter = f.ApeCoinStakingFactory.connect(apeStakingContract, signer);
    let tx: ethers.ContractTransaction;
    const poolId = POOL_IDS_MAPPED_BY_ADDRESS[selectedMainNft.address.toLowerCase()];
    const params = [{ tokenId: selectedMainNft.tokenId, amount: ethers.utils.parseEther(unstakingAmount) }];
    if (selectedMainNft.isCyanWallet && cyanWallet) {
      let encodedFnData;
      switch (poolId) {
        case PoolId.BAYC: {
          encodedFnData = apeCoinStakingIFace.encodeFunctionData("withdrawBAYC", [params, releaseWallet]);
          break;
        }
        case PoolId.MAYC: {
          encodedFnData = apeCoinStakingIFace.encodeFunctionData("withdrawMAYC", [params, releaseWallet]);
          break;
        }
        case PoolId.BAKC: {
          const { pairedTokenId, pairedTokenPool } = selectedMainNft.apeStaking;
          if (!selectedMainNft.apeStaking.stakedAmount) throw new Error("Staked amount not found");
          if (!pairedTokenId || !pairedTokenPool) throw new Error("Paired token ID not found");
          const param = {
            bakcTokenId: selectedMainNft.tokenId,
            mainTokenId: pairedTokenId,
            amount: params[0].amount,
            isUncommit: bigNumToFloat(selectedMainNft.apeStaking.stakedAmount) === parseFloat(unstakingAmount),
          };
          encodedFnData = apeCoinStakingIFace.encodeFunctionData("withdrawBAKC", [
            pairedTokenPool === PoolId.BAYC ? [param] : [],
            pairedTokenPool === PoolId.MAYC ? [param] : [],
          ]);
          break;
        }
        default: {
          throw new Error("Invalid Pool ID");
        }
      }
      tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        data: buildCyanWalletExecuteFnData(cyanWallet, encodedFnData),
      });
    } else {
      switch (poolId) {
        case PoolId.BAYC: {
          tx = await apeCoinStakingContractWriter.withdrawBAYC(params, releaseWallet);
          break;
        }
        case PoolId.MAYC: {
          tx = await apeCoinStakingContractWriter.withdrawMAYC(params, releaseWallet);
          break;
        }
        case PoolId.BAKC: {
          const { pairedTokenId, pairedTokenPool } = selectedMainNft.apeStaking;
          if (!pairedTokenId || !pairedTokenPool) throw new Error("Paired token ID not found");
          if (!selectedMainNft.apeStaking.stakedAmount) throw new Error("Staked amount not found");

          const param = {
            bakcTokenId: selectedMainNft.tokenId,
            mainTokenId: pairedTokenId,
            amount: params[0].amount,
            isUncommit: bigNumToFloat(selectedMainNft.apeStaking.stakedAmount) === parseFloat(unstakingAmount),
          };
          tx = await apeCoinStakingContractWriter.withdrawBAKC(
            pairedTokenPool === PoolId.BAYC ? [param] : [],
            pairedTokenPool === PoolId.MAYC ? [param] : [],
          );

          break;
        }
        default: {
          throw new Error("Invalid Pool ID");
        }
      }
    }
    addTransaction({
      hash: tx.hash,
      type: "ape-unstake",
      data: {
        tokenId: selectedMainNft.tokenId,
        address: selectedMainNft.address,
      },
    });
    setActiveTx(tx.hash);
  };

  const claim = async () => {
    if (!provider || !account || !signer) {
      throw new Error(`No provider found!`);
    }
    const apeCoinStakingContractWriter = f.ApeCoinStakingFactory.connect(apeStakingContract, signer);
    let tx: ethers.ContractTransaction;
    const poolId = POOL_IDS_MAPPED_BY_ADDRESS[selectedMainNft.address.toLowerCase()];
    if (selectedMainNft.isCyanWallet && cyanWallet) {
      let encodedFnData;
      switch (poolId) {
        case PoolId.BAYC: {
          encodedFnData = apeCoinStakingIFace.encodeFunctionData("claimBAYC", [
            [selectedMainNft.tokenId],
            releaseWallet,
          ]);
          break;
        }
        case PoolId.MAYC: {
          encodedFnData = apeCoinStakingIFace.encodeFunctionData("claimMAYC", [
            [selectedMainNft.tokenId],
            releaseWallet,
          ]);
          break;
        }
        case PoolId.BAKC: {
          const { pairedTokenId, pairedTokenPool } = selectedMainNft.apeStaking;
          if (!pairedTokenId || !pairedTokenPool) throw new Error("Paired token ID not found");
          const param = { bakcTokenId: selectedMainNft.tokenId, mainTokenId: pairedTokenId };

          encodedFnData = apeCoinStakingIFace.encodeFunctionData("claimBAKC", [
            pairedTokenPool === PoolId.BAYC ? [param] : [],
            pairedTokenPool === PoolId.MAYC ? [param] : [],
            releaseWallet,
          ]);
          break;
        }
        default: {
          throw new Error("Invalid Pool ID");
        }
      }
      tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        data: buildCyanWalletExecuteFnData(cyanWallet, encodedFnData),
      });
    } else {
      switch (poolId) {
        case PoolId.BAYC: {
          tx = await apeCoinStakingContractWriter.claimBAYC([selectedMainNft.tokenId], releaseWallet);
          break;
        }
        case PoolId.MAYC: {
          tx = await apeCoinStakingContractWriter.claimMAYC([selectedMainNft.tokenId], releaseWallet);

          break;
        }
        case PoolId.BAKC: {
          const { pairedTokenId, pairedTokenPool } = selectedMainNft.apeStaking;
          if (!pairedTokenId || !pairedTokenPool) throw new Error("Paired token ID not found");
          const param = { bakcTokenId: selectedMainNft.tokenId, mainTokenId: pairedTokenId };
          tx = await apeCoinStakingContractWriter.claimBAKC(
            pairedTokenPool === PoolId.BAYC ? [param] : [],
            pairedTokenPool === PoolId.MAYC ? [param] : [],
            releaseWallet,
          );

          break;
        }
        default: {
          throw new Error("Invalid Pool ID");
        }
      }
    }
    addTransaction({
      hash: tx.hash,
      type: "ape-claim",
      data: {
        tokenId: selectedMainNft.tokenId,
        address: selectedMainNft.address,
      },
    });
    setActiveTx(tx.hash);
  };
  useEffect(() => {
    const onStepChange = async (step: UnstakingSteps) => {
      try {
        switch (step) {
          case UnstakingSteps.CliamingRewards: {
            await claim();
            return;
          }
          case UnstakingSteps.Unstaking: {
            await unstake();
            return;
          }
        }
      } catch (err: any) {
        openRepaymentModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    showPlanModal({
      ...params,
      err: mappedError,
    });
  };
  const stepMarkFiltered = useMemo(() => {
    let steps = [];
    if (isClaiming) steps = UnstakingStepMarks.filter(item => item.value !== UnstakingSteps.Unstaking);
    else steps = UnstakingStepMarks.filter(item => item.value !== UnstakingSteps.CliamingRewards);
    return steps.map(item => {
      if (item.value === UnstakingSteps.Unstaking && txnFinal !== null) {
        return {
          ...item,
          description: `View on Etherscan`,
        };
      }
      return item;
    });
  }, [isClaiming, txnFinal]);

  return (
    <Flex gap="1rem" direction="column">
      <ApeCoinBalanceLoading
        tokenId={selectedMainNft.tokenId}
        collectionName={COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS[selectedMainNft.address.toLowerCase()]}
        stakingAmount={
          selectedMainNft.apeStaking.stakedAmount ? bigNumToFixedStr(selectedMainNft.apeStaking.stakedAmount) : "0"
        }
        imageUrl={selectedMainNft.imageUrl}
        isLoading={selectedStep != UnstakingSteps.Done}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep == UnstakingSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum UnstakingSteps {
  CliamingRewards = 1,
  Unstaking = 2,
  Done = 3,
}

const UnstakingStepMarks = [
  {
    value: UnstakingSteps.CliamingRewards,
    title: `Claiming APE Coin rewards`,
    description: `View on Etherscan`,
  },
  {
    value: UnstakingSteps.Unstaking,
    title: `Unstaking APE Coin`,
    description: `A small amount of gas is required to unstake $APE`,
  },
];
