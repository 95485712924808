import dayjs from "dayjs";
import { BigNumber, utils } from "ethers";
import { useEffect, useState } from "react";
import { useAsync } from "react-async-hook";
import { Info, Lock } from "react-feather";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import {
  Button,
  Hidden,
  Loader,
  NotFound,
  SkeletonLine,
  SortingArrows,
  Text,
  Tooltip,
  TooltipText,
  useModal,
} from "@cyanco/components/theme/v3";
import { ArrowRight, Send } from "@cyanco/components/theme/v3/icons";
import { Etherscan } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { IUserToken } from "@/apis/user/types";
import { ApeCoinOnlyStakingModal } from "@/components/ApeCoinStaking/new/components/PlanModal/ApeCoinStakingModal";
import { IActionType } from "@/components/ApeCoinStaking/new/types";
import { useFilteredVaults } from "@/components/Vault/VaultDataProvider";
import { VaultContractAbiNames } from "@/components/Vault/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apeCoinContract, apeStakingContract } from "@/config";
import { useGetCyanWalletLock } from "@/hooks";
import { useSortableHeader } from "@/hooks/useSortableHeader";

import ApeCoin from "../../../../assets/images/apecoin.svg";
import {
  bigNumToFixedStr,
  bigNumToFloat,
  displayInUSD,
  getChainExplorerURL,
  isApeCoinStakingPossible,
  numberWithCommas,
  shortenAddress,
  shortenName,
} from "../../../../utils";
import { useUserTokens } from "../../../Token/TokenContextProvider";
import { useWalletTabContext } from "../../AccountPageContext";
import { ContainerBox, Header as HeaderContainer, ListViewBox } from "../../index";
import { HeaderItemWrapper } from "../PositionView/PositionRow";
import { RowText, StyledImg, WalletContainer } from "./NftRow";
import { Refresh } from "./Refresh";
import { WalletSelector } from "./Selectors";
import { UserTokenTransfer } from "./UserTokenModals/UserTokenTransfer";

const UserTokenRow = ({
  token,
  account,
  cyanWalletAddress,
  chainId,
}: {
  token: IUserToken & { tokenInUsd?: number };
  account: string;
  cyanWalletAddress?: string;
  chainId: number;
}) => {
  const { provider, signer } = useWeb3React();
  const theme = useTheme();
  const cyanWallet = useCyanWallet();
  const { vaults } = useFilteredVaults();
  const tokenBalanceInNumber = parseFloat(utils.formatUnits(token.tokenBalance, token.decimal));
  const { setModalContent } = useModal();
  const { getLockedDate } = useGetCyanWalletLock();
  const [apeCoinBalance, setApeCoinBalance] = useState<{
    stakedAmount: null | BigNumber;
    earnedAmount: BigNumber | null;
  }>({
    stakedAmount: null,
    earnedAmount: null,
  });
  const enableApeCoinStaking = token.address.toLowerCase() === apeCoinContract && isApeCoinStakingPossible(chainId);

  const {
    loading: depositLockLoading,
    result: { isCyanWalletLocked, lockedDate } = { isCyanWalletLocked: false, lockedDate: dayjs() },
  } = useAsync<{ isCyanWalletLocked: boolean; lockedDate: dayjs.Dayjs | null }>(async () => {
    if (cyanWallet && token.symbol.startsWith("CV")) {
      try {
        const vaultAddress = vaults.find(
          vault =>
            vault.contractTokenAddress.toLowerCase() === token.address.toLowerCase() &&
            (vault.abiName === VaultContractAbiNames.CyanVaultV2 ||
              vault.abiName === VaultContractAbiNames.CyanVaultV2_1),
        )?.contractAddress;
        if (vaultAddress) {
          const date = await getLockedDate(vaultAddress);
          const lockedDate = date && date.gt(0) ? dayjs.unix(date.toNumber()) : null;
          const isCyanWalletLocked = lockedDate !== null;
          return {
            lockedDate: lockedDate,
            isCyanWalletLocked,
          };
        }
      } catch (e) {
        console.error(e);
      }
    }
    return {
      lockedDate: null,
      isCyanWalletLocked: false,
    };
  }, [cyanWallet, token]);

  useEffect(() => {
    if (!enableApeCoinStaking || !provider) return;
    const _setApeCoinBalance = async () => {
      if (!provider || !account) return;
      const apeCoinStakingContract = f.ApeCoinStakingFactory.connect(apeStakingContract, provider);
      if (cyanWalletAddress && token.isCyanWallet) {
        const addressPosition = await apeCoinStakingContract.addressPosition(cyanWalletAddress);
        const pendingRewards = await apeCoinStakingContract.pendingRewards(0, cyanWalletAddress, 0);
        setApeCoinBalance({
          stakedAmount: addressPosition.stakedAmount,
          earnedAmount: pendingRewards,
        });
      }
      if (!token.isCyanWallet) {
        const addressPosition = await apeCoinStakingContract.addressPosition(account);
        const pendingRewards = await apeCoinStakingContract.pendingRewards(0, account, 0);
        setApeCoinBalance({
          stakedAmount: addressPosition.stakedAmount,
          earnedAmount: pendingRewards,
        });
      }
    };
    _setApeCoinBalance();
  }, [account, token]);
  const openApeCoinStakingModal = () => {
    const isStaked = apeCoinBalance.stakedAmount !== null && bigNumToFloat(apeCoinBalance.stakedAmount) > 0;
    if (provider && signer) {
      if (isStaked) {
        setModalContent({
          title: `ApeCoin Staking`,
          content: <ApeCoinOnlyStakingModal action={IActionType.unstake} />,
        });
      }
    } else {
      setModalContent({
        title: `Connect Wallet`,
        content: (
          <Flex alignItems="center" p="1.5rem" justifyContent="center">
            <Text size="lg" weight="700">{`Please connect wallet.`}</Text>
          </Flex>
        ),
      });
    }
  };
  const onClick = () => {
    if (enableApeCoinStaking) {
      setModalContent({
        title: `APE Coin`,
        content: (
          <ApeCoinOptions
            apeCoinBalance={apeCoinBalance}
            onClickStake={openApeCoinStakingModal}
            onClickTransfer={() =>
              setModalContent({ title: `Token Transfer`, content: <UserTokenTransfer token={token} /> })
            }
          />
        ),
      });
    } else {
      setModalContent({ title: `Token Transfer`, content: <UserTokenTransfer token={token} /> });
    }
  };
  return (
    <Row onClick={onClick}>
      <Flex gap="1rem" alignItems="center">
        <Flex alignItems="center" gap="20px">
          <StyledImg src={token.imageUrl} hasImage alt={token.name} />
          <Flex gap="5px">
            <Flex direction="column" gap="2px">
              <RowText weight="500" color="secondary">
                {token.symbol.toUpperCase()}
              </RowText>
              <RowText color="gray0" sub>
                {shortenName(token.name)}
              </RowText>
            </Flex>
            <MobileFriendlyWrapper>
              {enableApeCoinStaking &&
                apeCoinBalance.earnedAmount &&
                apeCoinBalance.stakedAmount &&
                !apeCoinBalance.stakedAmount?.isZero() && (
                  <>
                    <Hidden laptopMDown>
                      <RowBox direction="column">
                        <StyledText color="primary" weight="600" size="xxs">
                          Staked: {bigNumToFixedStr(apeCoinBalance.stakedAmount, 2)} APE
                        </StyledText>
                        <StyledText color="primary" weight="600" size="xxs">
                          Earned: {bigNumToFixedStr(apeCoinBalance.earnedAmount, 2)} APE
                        </StyledText>
                      </RowBox>
                    </Hidden>

                    <Hidden laptopMUp onClick={e => e.stopPropagation()}>
                      <Flex
                        style={{
                          background: theme.colors.secondary,
                          borderRadius: "4px",
                        }}
                        p={"2px 3px"}
                        h={"fit-content"}
                      >
                        <Tooltip>
                          <Flex gap="2px" alignItems="center">
                            <StyledText color="primary" weight="600">
                              {`Staked`}
                            </StyledText>
                            <Info height={"10px"} width={"10px"} color={theme.colors.primary} />
                          </Flex>
                          <TooltipText
                            showArrow
                            position="top"
                            bottom="calc(100% + 15px)"
                            left="-35px"
                            style={{ minWidth: "fit-content" }}
                          >
                            <Flex direction="column">
                              <StyledText color="primary" weight="600" size="xxs" textWrap={false}>
                                {`Staked`}: {bigNumToFixedStr(apeCoinBalance.stakedAmount, 2)} APE
                              </StyledText>
                              {apeCoinBalance.earnedAmount && (
                                <StyledText color="primary" weight="600" size="xxs" textWrap={false}>
                                  {`Earned`}: {bigNumToFixedStr(apeCoinBalance.earnedAmount, 2)} APE
                                </StyledText>
                              )}
                            </Flex>
                          </TooltipText>
                        </Tooltip>
                      </Flex>
                    </Hidden>
                  </>
                )}
            </MobileFriendlyWrapper>
            {!depositLockLoading && isCyanWalletLocked && token.isCyanWallet && token.symbol.startsWith("CV") && (
              <MobileFriendlyWrapper>
                <>
                  <Hidden laptopMDown>
                    <Tooltip>
                      <RowBox direction="column">
                        <Flex gap="2px" alignItems="center">
                          <StyledText color="primary" weight="600" size="xxs">
                            Locked until: {lockedDate?.format("MMMM Do, YYYY")}
                          </StyledText>
                        </Flex>
                        <TooltipText
                          showArrow
                          position="top"
                          bottom="calc(100% + 15px)"
                          left="-35px"
                          style={{ minWidth: "fit-content" }}
                        >
                          <Flex direction="column">
                            <StyledText color="primary" weight="600" size="xxs">
                              Due to the recent vault deposit, The cyan wallet is locked.
                            </StyledText>
                          </Flex>
                        </TooltipText>
                      </RowBox>
                    </Tooltip>
                  </Hidden>

                  <Hidden laptopMUp onClick={e => e.stopPropagation()}>
                    <Flex
                      style={{
                        background: theme.colors.secondary,
                        borderRadius: "4px",
                      }}
                      p={"2px 3px"}
                      h={"fit-content"}
                    >
                      <Tooltip>
                        <Flex gap="2px" alignItems="center">
                          <StyledText color="primary" weight="600">
                            {`Locked`}
                          </StyledText>
                          <Info height={"10px"} width={"10px"} color={theme.colors.primary} />
                        </Flex>
                        <TooltipText
                          showArrow
                          position="top"
                          bottom="calc(100% + 15px)"
                          left="-35px"
                          style={{ minWidth: "fit-content" }}
                        >
                          <Flex direction="column">
                            <StyledText color="primary" weight="600" size="xxs">
                              Due to the recent vault deposit, The cyan wallet is locked until{" "}
                              {lockedDate?.format("MMMM Do, YYYY")}
                            </StyledText>
                          </Flex>
                        </TooltipText>
                      </Tooltip>
                    </Flex>
                  </Hidden>
                </>
              </MobileFriendlyWrapper>
            )}
          </Flex>
        </Flex>
      </Flex>
      {token.isCyanWallet ? (
        <WalletContainer>
          <div
            style={{
              background: theme.colors.cyan,
              width: "18px",
              height: "18px",
              minWidth: "18px",
              minHeight: "18px",
              borderRadius: "50%",
            }}
          />
          <RowText color="secondary" weight="500">
            Cyan Wallet
          </RowText>
          {!depositLockLoading && isCyanWalletLocked && token.symbol.startsWith("CV") && (
            <Lock height={"15x"} width={"15px"} color={theme.colors.secondary}></Lock>
          )}
        </WalletContainer>
      ) : (
        <WalletContainer>
          <Jazzicon seed={jsNumberForAddress(account)} diameter={18} />
          <RowText color="secondary" weight="500">
            {shortenAddress(account)}
          </RowText>
        </WalletContainer>
      )}

      <Flex direction="column" justifyContent="center" gap="2px">
        <RowText weight="500" style={{ overflowWrap: "anywhere" }} color="secondary">
          {numberWithCommas(tokenBalanceInNumber, 2)} {token.symbol.toUpperCase()}
        </RowText>
        {!!token.tokenInUsd && (
          <RowText sub color="gray0" style={{ overflowWrap: "anywhere" }}>
            {displayInUSD(token.tokenInUsd * tokenBalanceInNumber)}
          </RowText>
        )}
      </Flex>
      <Hidden tabletDown>
        <Box w="min-content">
          <TransferButton
            variant="ghost"
            onClick={e => {
              e.stopPropagation();
              setModalContent({ title: `Token Transfer`, content: <UserTokenTransfer token={token} /> });
            }}
          >
            <Send color={theme.colors.secondary} height={20} width={20} />
          </TransferButton>
        </Box>
      </Hidden>
      <Hidden tabletDown>
        <ImgButton
          src={Etherscan}
          onClick={e => {
            e.stopPropagation();
            window.open(`${getChainExplorerURL(chainId)}/token/${token.address}`, "_blank");
          }}
        />
      </Hidden>
    </Row>
  );
};

const UserTokenListHeader = ({
  refreshToken,
  sortedTokens,
  updateSortedTokens,
}: {
  refreshToken: () => Promise<void>;
  sortedTokens: (IUserToken & { tokenInUsd?: number })[];
  updateSortedTokens: (values: (IUserToken & { tokenInUsd?: number })[]) => void;
}) => {
  const { selectedWalletType, setSelectedWalletType } = useWalletTabContext();
  const getBalance = (c: IUserToken & { tokenInUsd?: number }) => {
    return c.tokenInUsd && c.tokenBalance && c.decimal
      ? (c.tokenInUsd ?? 1) * parseFloat(utils.formatUnits(c.tokenBalance, c.decimal))
      : 0;
  };
  const { sort, sortDirectionDesc, sortName } = useSortableHeader<IUserToken & { tokenInUsd?: number }>(
    [c => getBalance(c)],
    "balance",
  );

  const handleHeaderClick = (value: ((item: IUserToken & { tokenInUsd?: number }) => any)[], sortName: string) => {
    const sortedData = sort(value, sortedTokens, sortName);
    updateSortedTokens(sortedData);
  };

  const onRefresh = async () => {
    await refreshToken();
  };
  return (
    <Header>
      <Flex gap="1rem" alignItems="center">
        <RowText weight="600" color="secondary">{`${sortedTokens.length} item${
          sortedTokens.length > 1 ? "s" : ""
        }`}</RowText>
        <Refresh onClick={onRefresh} />
      </Flex>{" "}
      <WalletSelector selectedWalletType={selectedWalletType} setSelectedWalletType={setSelectedWalletType} />
      <Flex alignItems="center" gap="0.4rem">
        <HeaderItemWrapper>
          <Flex gap="5px" onClick={() => handleHeaderClick([c => getBalance(c)], "balance")}>
            <RowText color="secondary" weight="600">{`Balance`}</RowText>
            <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="balance" />
          </Flex>
        </HeaderItemWrapper>
      </Flex>
      <Hidden tabletDown>
        <RowText color="secondary" weight="600">{`Transfer`}</RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText color="secondary" weight="600">{`Explorer`}</RowText>
      </Hidden>
    </Header>
  );
};

export const UserTokens = () => {
  const { account, chainId } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { userTokensFiltered, refreshUserTokens } = useUserTokens();
  const [sortedTokens, setSortedTokens] = useState<(IUserToken & { tokenInUsd?: number })[]>(userTokensFiltered);

  useEffect(() => {
    setSortedTokens(userTokensFiltered);
  }, [userTokensFiltered]);
  const updateSortedTokens = (values: (IUserToken & { tokenInUsd?: number })[]) => {
    setSortedTokens(values);
  };

  return (
    <ListContainer>
      <ListViewBox>
        <HeaderContainer>
          <UserTokenListHeader
            refreshToken={refreshUserTokens}
            updateSortedTokens={updateSortedTokens}
            sortedTokens={sortedTokens}
          />
        </HeaderContainer>
        <ContainerBox>
          {sortedTokens.length === 0 && <NotFound msg={`No tokens found`} />}
          {account &&
            sortedTokens.map(token => (
              <UserTokenRow
                key={`${token.address}-${token.isCyanWallet ? "cyan" : "main"}`}
                token={token}
                account={account}
                chainId={chainId}
                cyanWalletAddress={cyanWallet?.walletAddress}
              />
            ))}
        </ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};

const ApeCoinOptions = ({
  onClickStake,
  onClickTransfer,
  apeCoinBalance,
}: {
  onClickStake: () => void;
  onClickTransfer: () => void;
  apeCoinBalance: { stakedAmount: null | BigNumber; earnedAmount: BigNumber | null };
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  const { stakedAmount, earnedAmount } = apeCoinBalance;
  return (
    <Flex direction="column">
      <Flex gap="1rem" alignItems="center">
        <Flex gap="0.7rem" mb="1rem" mt="1rem" alignItems="center">
          {imageLoading && (
            <ImageLoader>
              <SkeletonLine w="100%" h="100%" borderRadius="50%" />
            </ImageLoader>
          )}
          <img
            src={ApeCoin}
            alt=""
            height="42px"
            style={{
              borderRadius: "50%",
              border: "1px solid white",
              display: imageLoading ? "none" : "block",
              width: imageLoading ? "0" : "42px",
            }}
            onLoad={() => setImageLoading(false)}
          />
          <Text size="xxl" color="secondary">
            APE
          </Text>
        </Flex>
        <Flex direction="column" w="100%" justifyContent="space-around" gap="0.5rem">
          <Flex justifyContent="space-between" w="100%" alignItems="center">
            <Text size="sm" weight="500" color="secondary">
              {`Currently staked:`}
            </Text>
            <Text size="sm" weight="500" color="secondary">
              {stakedAmount !== null ? bigNumToFixedStr(stakedAmount, 3) : <Loader size="14px" stroke="#808080" />} APE
            </Text>
          </Flex>
          <Flex justifyContent="space-between" w="100%" alignItems="center">
            <Text size="sm" weight="500" color="secondary">
              {`Earned amount:`}
            </Text>
            <Text size="sm" weight="500" color="secondary">
              {earnedAmount !== null ? bigNumToFixedStr(earnedAmount, 3) : <Loader size="14px" stroke="#808080" />} APE
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="column" gap="0.5rem">
        <StakingButton onClick={onClickStake}>
          <Flex gap="4px" justifyContent="center" alignItems="center">
            <Text color="white" size="sm" weight="600">
              {`ApeCoin Staking Menu`}
            </Text>
            <ArrowRight color="white" />
          </Flex>
        </StakingButton>
        <StyledConfirmButton onClick={onClickTransfer}>{`Transfer`}</StyledConfirmButton>
      </Flex>
    </Flex>
  );
};

const Row = styled.div`
  cursor: pointer;
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  background-color: transparent;
  grid-template-columns: 2.5fr 2fr 2fr 0.8fr 0.5fr;
  padding: 13px 15px 13px 15px;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2.5fr 2fr 1.3fr" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const Header = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 65x;
    padding: 7px 10px;
  }
`;

const ImgButton = styled.img`
  cursor: pointer;
  height: max(20px, 0.8vw);
  width: max(20px, 0.8vw);
  cursor: pointer;
  transition: 0.2s;
  :hover {
    opacity: 0.5;
  }
`;
const StakingButton = styled(Button)`
  background: #024ad7;
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-top: 1rem;
  transition: 0.3s;
  border: 3px solid #024ad7;
  :hover {
    background: #024ad7;
    border: 3px solid #024ad7;
  }
`;
const ImageLoader = styled.div`
  min-height: 42px;
  min-width: 42px;
`;
const RowBox = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;
  padding: 0.3rem 0.5rem;
`;
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  width: calc(100% - calc((100% - 1240px)));
  gap: 1rem;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;

const TransferButton = styled(Button)`
  width: fit-content;
  :hover {
    svg,
    path {
      transition: 0.2s ease-in-out;
      stroke: ${({ theme }) => theme.colors.gray0};
    }
  }
`;

const MobileFriendlyWrapper = styled(Flex)`
  flex-direction: row;
  gap: 4px;
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])};
  align-items: flex-end;
`;

const StyledText = styled(Text)`
  font-size: 10px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 10 }, { [breakpoints.mobile]: 8 }])}
`;
