import { BigNumber } from "ethers";

import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { Errored, INft, isNonErrored } from "@/types";
import { Override } from "@/utils/types";

import { axios, isAxiosError } from "../axios";

export const priceBnplStep2 = async (args: IParams): Promise<IResult> => {
  try {
    const data = serializeRequestData(args);
    const response = await axios.post<IResponse>("/v2/pricer/bnpl-step2-v2", data);
    return serializeResponseData(response.data);
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response && e.response.data) throw new Error(e.response.data.message);
    }
    throw e;
  }
};

export const priceBnplStep2Deprecated = async (args: IParams): Promise<IResult> => {
  try {
    const data = serializeRequestDataDeprecated(args);
    const response = await axios.post<IResponse>("/v2/pricer/bnpl-step2-v2", data);
    return serializeResponseData(response.data);
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response && e.response.data) throw new Error(e.response.data.message);
    }
    throw e;
  }
};

const serializeRequestData = (data: IParams): IRequestData => {
  const chain = CHAIN_IDS_TO_NAMES[data.chainId];
  return {
    chain,
    items: data.items.map(item => ({
      address: item.address,
      tokenId: item.tokenId,
      itemType: item.itemType,
      amount: item.amount,
      privateSaleId: item.privateSaleId,
      option: [item.term, item.totalNumberOfPayments, item.loanRate],
      isAutoLiquidated: item.isAutoLiquidated,
    })),
    currencyAddress: data.currencyAddress,
    autoRepayStatus: data.autoRepayStatus,
    wallet: data.wallet,
  };
};

const serializeRequestDataDeprecated = (data: IParams): IRequestDataDeprecated => {
  const chain = CHAIN_IDS_TO_NAMES[data.chainId];
  return {
    chain,
    items: data.items.map(item => ({
      address: item.address,
      tokenId: item.tokenId,
      itemType: item.itemType,
      amount: item.amount,
      privateSaleId: item.privateSaleId,
      option: [item.term, item.totalNumberOfPayments, item.loanRate],
    })),
    currencyAddress: data.currencyAddress,
    autoRepayStatus: data.autoRepayStatus,
    wallet: data.wallet,
  };
};

const serializeResponseData = (data: IResponse): IResult => {
  return {
    blockNumber: data.blockNumber,
    plans: data.plans.map(plan => {
      if (isNonErrored(plan)) {
        return { ...plan, price: BigNumber.from(plan.price) };
      }
      return plan;
    }),
  };
};

type IParams = {
  items: Array<
    INft & {
      term: number;
      loanRate: number;
      totalNumberOfPayments: number;
      isAutoLiquidated: boolean;
    }
  >;
  chainId: SupportedChainId;
  currencyAddress: string;
  autoRepayStatus: number;
  wallet: string;
};

type IRequestData = Omit<IParams, "chainId" | "term" | "totalNumberOfPayments" | "loanRate" | "items"> & {
  chain: string;
  items: Array<
    INft & {
      option: [number, number, number];
      isAutoLiquidated: boolean;
    }
  >;
};

type IRequestDataDeprecated = Omit<IParams, "chainId" | "term" | "totalNumberOfPayments" | "loanRate" | "items"> & {
  chain: string;
  items: Array<
    INft & {
      option: [number, number, number];
    }
  >;
};

type IPlan = {
  planId: number;
  signature: string;
  price: string;
  interestRate: number;
  vaultAddress: string;
  marketName?: string;
};
interface IResponse {
  plans: Errored<IPlan>[];
  blockNumber: number;
}
interface IResult {
  plans: Errored<Override<IPlan, { price: BigNumber }>>[];
  blockNumber: number;
}
