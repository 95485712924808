import * as Sentry from "@sentry/react";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Modal as ModalV3, Text as TextV3 } from "@cyanco/components/theme/v3";

import MetamaskIcon from "../../assets/images/metamask.png";
import { metaMask, uauthConnect } from "../../connectors";
import { IWalletConnectors, SUPPORTED_WALLETS } from "../../constants/wallet";
import usePrevious from "../../hooks/usePrevious";
import { useModalOpen, useWalletModalToggle } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import { useAuthContext } from "../AuthContext/AuthContextProvider";
import Option from "./Option";
import PendingView from "./PendingView";

// eslint-disable-next-line
declare let window: any;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;

  :hover {
    color: #a4a4a4;
  }
`;

const HoverText = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const WALLET_VIEWS = {
  OPTIONS: "options",
  OPTIONS_SECONDARY: "options_secondary",
  ACCOUNT: "account",
  PENDING: "pending",
  LEGAL: "legal",
};

const userClosedModalErrors = [
  "User denied account authorization",
  "The popup was closed.",
  "User closed modal",
  "User rejected the request.",
];

const WalletModal = () => {
  const { account, isActive, connector } = useWeb3React();
  const { setUser } = useAuthContext();
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);
  const [pendingWallet, setPendingWallet] = useState<IWalletConnectors | undefined>();
  const [pendingError, setPendingError] = useState<boolean>();
  const walletModalOpen = useModalOpen(ApplicationModal.WALLET);
  const toggleWalletModal = useWalletModalToggle();
  const previousAccount = usePrevious(account);

  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal();
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen]);

  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  const activePrevious = usePrevious(isActive);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (walletModalOpen && ((isActive && !activePrevious) || (connector && connector !== connectorPrevious))) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [setWalletView, isActive, connector, walletModalOpen, activePrevious, connectorPrevious]);

  const tryActivation = async (targetConnector: IWalletConnectors | undefined) => {
    setPendingWallet(targetConnector);
    setWalletView(WALLET_VIEWS.PENDING);
    if (connector?.deactivate) {
      void connector.deactivate();
    } else {
      void connector.resetState();
    }
    try {
      targetConnector && (await targetConnector.activate());
      if (targetConnector === uauthConnect) {
        metaMask.resetState();
      }
      toggleWalletModal();
      setUser({ wallet: "", token: "", cyanWallet: "" });
    } catch (e: any) {
      setPendingError(true);
      console.error(e);
      if (e?.message && !userClosedModalErrors.includes(e.message)) {
        Sentry.captureException(e);
      }
    }
  };

  const getOptions = () => {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;
    return Object.keys(SUPPORTED_WALLETS).map(key => {
      const option = SUPPORTED_WALLETS[key];
      if (option.connector === metaMask) {
        if (!(window.web3 || window.ethereum)) {
          if (option.name === "MetaMask") {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={"blue"}
                header={`Install MetaMask`}
                subheader={null}
                link={"https://metamask.io/"}
                icon={MetamaskIcon}
                hooks={option.hooks}
              />
            );
          } else {
            return null;
          }
        } else if (option.name === "Metamask" && !isMetamask) {
          return null;
        }
      }

      return (
        <Option
          id={`connect-${key}`}
          onClick={() => {
            isActive && option.connector === connector
              ? setWalletView(WALLET_VIEWS.ACCOUNT)
              : !option.href && tryActivation(option.connector);
          }}
          key={key}
          color={option.color}
          link={option.href}
          header={option.name}
          subheader={null}
          icon={option.iconURL}
          hooks={option.hooks}
        />
      );
    });
  };

  if (!walletModalOpen) return null;

  return (
    <ModalV3
      isOpen={walletModalOpen}
      unsetModal={toggleWalletModal}
      title={
        walletView !== WALLET_VIEWS.ACCOUNT ? (
          <HeaderRow>
            <HoverText
              onClick={() => {
                setPendingError(false);
                setWalletView(WALLET_VIEWS.ACCOUNT);
              }}
            >
              <ArrowLeft />
            </HoverText>
          </HeaderRow>
        ) : (
          <TextV3 weight="700" color="secondary" size="md">{`Connect Wallet`}</TextV3>
        )
      }
      canBack={false}
      backAction={() => {}}
    >
      <Flex direction="column">
        {walletView === WALLET_VIEWS.PENDING ? (
          <PendingView
            connector={pendingWallet}
            error={pendingError}
            setPendingError={setPendingError}
            tryActivation={tryActivation}
          />
        ) : (
          <OptionGrid>{getOptions()}</OptionGrid>
        )}
      </Flex>
    </ModalV3>
  );
};

export default WalletModal;
