import React from "react";

import { BnplCartContextProvider } from "./BnplCartContext";
import { BnplPageProvider } from "./BnplPageContext";

export const BnplContextWrapperV3: React.FC = ({ children }) => {
  return (
    <BnplPageProvider>
      <BnplCartContextProvider>{children}</BnplCartContextProvider>
    </BnplPageProvider>
  );
};
