import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Text, useModal } from "@cyanco/components/theme/v3";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { useVaults } from "@/components/Vault/VaultDataProvider";
import { apeVaultContract, isProd } from "@/config";
import { formatCompactNumber } from "@/utils";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";
import { IActionType } from "../../../types";
import { ApeCoinOnlyStakingModal } from "../../PlanModal/ApeCoinStakingModal";

export const ApeCoinStakingSelector = () => {
  const { unsetModal, setModalContent } = useModal();
  const { vaults } = useVaults();
  const { apeVault } = useApeCoinStatsContext();
  const navigate = useNavigate();

  const apy = useMemo(() => {
    const apeVault = vaults.find(
      ({ contractAddress }) => apeVaultContract.toLowerCase() === contractAddress.toLowerCase(),
    );
    if (!apeVault || !apeVault.estimatedYield) return null;
    return apeVault.estimatedYield / 100;
  }, [vaults]);
  const autoCompoundApy = useMemo(() => {
    return (Math.pow(1 + apeVault.apr.COIN / 100 / 52, 52 - 1) - 1) * 100;
  }, [apeVault.apr.COIN]);
  return (
    <Flex direction="column" gap="1.2rem">
      <Box
        direction="column"
        onClick={() => {
          navigate(`/vault/${isProd ? "mainnet" : "sepolia"}/${apeVaultContract}`);
          unsetModal();
        }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Flex direction="column" gap="0.2rem">
            <Text color="secondary" weight="600" size="md">
              {`Cyan ApeCoin Staking Vault`}
            </Text>
            <Text color="secondary" size="xxl" weight="500">
              🔥 {apy ? (apy > 100 ? formatCompactNumber(apy) : apy.toFixed(2)) : "- "}%
            </Text>
          </Flex>
          <StyledImg src={ApeCoin} alt="ApeCoin" />
        </Flex>
        <Text color="gray0" weight="500" size="xs">
          {`For the highest yield, select this option. Your APE will be staked into the vault, and you will not have voting rights.`}
        </Text>
      </Box>
      <Text textAlign="center" size="md" color="gray0">
        or
      </Text>
      <Box
        direction="column"
        onClick={() => {
          setModalContent({
            title: `ApeCoin Auto-Compounding`,
            content: <ApeCoinOnlyStakingModal action={IActionType.stake} />,
          });
        }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Flex direction="column" gap="0.2rem">
            <Text color="secondary" weight="600" size="md">
              {`ApeCoin Auto-Compounding`}
            </Text>
            <Text color="secondary" size="xxl" weight="500">
              🔥{" "}
              {autoCompoundApy
                ? autoCompoundApy > 100
                  ? formatCompactNumber(autoCompoundApy)
                  : autoCompoundApy.toFixed(2)
                : "- "}
              %
            </Text>
          </Flex>
          <StyledImg src={ApeCoin} alt="ApeCoin" />
        </Flex>
        <Text color="gray0" weight="500" size="xs">
          {`To keep your voting rights, select this option. Your APE will be auto-compounded as it is being staked.`}
        </Text>
      </Box>
    </Flex>
  );
};
const Box = styled(Flex)`
  background: ${({ theme }) => (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
  border: 1px solid ${({ theme }) => (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
  border-radius: 10px;
  cursor: pointer;
  padding: 1rem;
  transition: 0.2s ease-in-out;
  :hover {
    border-color: ${({ theme }) => (theme.theme === "dark" ? "cyan" : theme.colors.black)};
  }
`;
const StyledImg = styled.img`
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
`;
