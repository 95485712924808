import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button } from "@cyanco/components/theme/v3";

export const VaultTableRowBase = styled.div`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: end;
  padding: 18px 20px 18px 14px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 10px 14px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
  & > div > span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const VaultTableHeaderBase = styled(VaultTableRowBase)`
  padding: 14px 20px 12px 14px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 20px 12px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  position: sticky;
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.gray0};
`;

export const StyledImg = styled.img`
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  border-radius: 50%;
`;

export const VaultImageWrapper = styled.div`
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const VaultImage = styled.img`
  height: 15px;
  width: 15px;
`;
export const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
