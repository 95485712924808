import { BigNumber, BigNumberish } from "ethers";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { StyledProjectLink } from "@cyanco/components/theme/components";
import { Card, Text } from "@cyanco/components/theme/v3";
import { NewTab } from "@cyanco/components/theme/v3/icons";

import { INextPayment } from "@/apis/types";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, getGoogleCalendarLink, numberWithCommas } from "@/utils";

import { IPawn } from "../Account/pawn.types";
import { useAuthContext } from "../AuthContext/AuthContextProvider";
import { IBNPL } from "../Bnpl/bnpl.types";
import { PlanCreationDecimalFormatMap } from "../PlanCreation/types";
import { getDueForPayment, getPlanApr } from "../PlanCreation/utils";
import { IPaymentsMapped } from "./PlanRepayment";

type IProps = {
  plan: {
    nextPayment: INextPayment;
    payments: IPaymentsMapped[];
    interestRate: number;
    monthlyAmount: string;
    currency: ICurrency;
    totalNumOfPaymentsLeft: number;
    term: number;
    createdAt: Date;
    planType: "pawn" | "bnpl";
    planData: IBNPL | IPawn;
    totalCost: BigNumber;
    totalNumberOfPayments: number;
  };
};

export const RegularPayment: React.FC<IProps> = ({ plan }) => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const {
    totalCost,
    monthlyAmount,
    currency,
    nextPayment,
    payments,
    totalNumOfPaymentsLeft,
    planData,
    planType,
    interestRate,
    term,
  } = plan;
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 4;
  }, [plan.currency.decimal]);

  const apr = useMemo(() => {
    const totalNumOfPaymentsByPlanType =
      planType == "bnpl" ? plan.totalNumberOfPayments - 1 : plan.totalNumberOfPayments;
    return getPlanApr({
      term,
      interestRate,
      totalNumberOfPayments: totalNumOfPaymentsByPlanType,
    });
  }, [interestRate, plan]);

  return (
    <Card p={"10px 8px"}>
      <Flex direction="column" gap="7px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="sm" weight="600" color="secondary">
            {`Payment of `}{" "}
            {`${numberWithCommas(
              bigNumToFixedStr(nextPayment.currentPayment, formatNumber, currency.decimal),
              formatNumber,
            )} ${currency.symbol}`}
          </Text>
          <StyledProjectLink
            onClick={() => getGoogleCalendarLink(planData, planType)}
            target="_blank"
            key="calendar-link"
            style={{ gap: "5px", margin: 0, alignItems: "center" }}
          >
            <StyledText size="xs" color="gray0">
              {`Add to Calendar`}
            </StyledText>
            <NewTab height="12px" width="12px" color={theme.colors.gray0} />
          </StyledProjectLink>
        </Flex>
        <StepperContainer>
          {payments.map(payment => (
            <PaymentStep
              key={`${payment.txnHash}-${payment.isRevival ? "revival" : "payment"}}`}
              text={payment.isRevival ? `Revival Penalty` : `Already Paid`}
              paymentAmount={payment.amount}
              currency={currency}
              fixNumber={formatNumber}
              isPaid
            />
          ))}
          {Array.from(Array(totalNumOfPaymentsLeft).keys()).map(paymentNumber => (
            <PaymentStep
              key={paymentNumber}
              text={`${paymentNumber === 0 ? "Due" : ""} in ${getDueForPayment({
                paymentNumber: paymentNumber + payments.filter(payment => !payment.isRevival).length,
                createdAt: plan.createdAt,
                isBnpl: planType === "bnpl",
                term,
              })}`}
              paymentAmount={monthlyAmount}
              currency={currency}
              fixNumber={formatNumber}
              isLast={totalNumOfPaymentsLeft === paymentNumber + 1}
              isNextPayment={paymentNumber === 0}
              color={paymentNumber === 0 ? "secondary" : "gray0"}
            />
          ))}
        </StepperContainer>
        <Flex justifyContent="space-between">
          <Text size="xs" weight="500" color="gray0">
            {user?.config?.showAPR ? `${apr.toFixed(2)}% APR` : `${(interestRate / 100).toFixed(2)}% interest`}
          </Text>
          <Text size="xs" weight="500" color="gray0">
            {`Total Cost ${numberWithCommas(
              bigNumToFixedStr(totalCost, formatNumber, currency.decimal),
              formatNumber,
            )} ${currency.symbol}`}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export const PaymentStep = ({
  text,
  paymentAmount,
  currency,
  fixNumber,
  isLast,
  isNextPayment,
  isMissed,
  isPaid,
  isDeducting,
  color,
  valueColor,
}: {
  text: string;
  paymentAmount: BigNumberish;
  currency: ICurrency;
  fixNumber: number;
  isLast?: boolean;
  isNextPayment?: boolean;
  isPaid?: boolean;
  isMissed?: boolean;
  isDeducting?: boolean;
  color?: string;
  valueColor?: string;
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex alignItems="center" gap="8px">
        <DueCircle
          isPaid={isPaid}
          isNextPayment={isNextPayment}
          isLast={isLast}
          isMissed={isMissed}
          isDeducting={isDeducting}
        />
        <Text size="xs" weight="500" color={color ?? "gray0"}>
          {text}
        </Text>
      </Flex>
      <Text size="xs" weight="500" color={valueColor ?? color ?? "gray0"}>
        {`${isDeducting ? "- " : ""}${numberWithCommas(bigNumToFloat(paymentAmount, currency.decimal), fixNumber)} ${
          currency.symbol
        }`}
      </Text>
    </Flex>
  );
};

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-left: none;
  border-right: none;
  padding: 9px 0;
  margin-top: 3px;
  gap: 8px;
`;

export const DueCircle = styled.div<{
  isLast?: boolean;
  isNextPayment?: boolean;
  isPaid?: boolean;
  isMissed?: boolean;
  isDeducting?: boolean;
}>`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray0};
  background-color: ${({ theme, isNextPayment, isPaid, isMissed, isDeducting }) =>
    isDeducting
      ? theme.colors.yellow
      : isPaid
      ? theme.colors.gray0
      : isNextPayment
      ? theme.colors.cyan
      : isMissed
      ? theme.colors.red
      : theme.colors.transparent};
  position: relative;
  :before {
    position: absolute;
    content: "";
    display: ${({ isLast }) => (isLast ? "none" : "block")};
    width: 1px;
    height: 10px;
    border-left: 1px solid;
    border-color: ${({ theme }) => theme.colors.gray0};
    bottom: -100%;
    left: 5px;
  }
`;

const StyledText = styled(Text)`
  text-align: right;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    color: cyan;
  }
`;
