import { BigNumber } from "ethers";
import qs from "qs";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { ICurrency, INftBe, INftType } from "@/types";

import { axios } from "../axios";

export const fetchListedNfts = async (args: IParams): Promise<IResult> => {
  const { slug, abortSignal, ...params } = args;
  const response = await axios.get(`/v2/collections/${slug}/listed`, {
    params: { ...params, source: params.marketPlace, excludeSources: params.excludeSources },
    signal: abortSignal,
    paramsSerializer: p => qs.stringify(p, { arrayFormat: "repeat" }),
  });
  return serializeResponse(response.data);
};

const serializeResponse = (data: IResponse): IResult => {
  const { assets, continuation } = data;
  const items = assets.map(asset => ({
    tokenId: asset.tokenId,
    address: asset.contractAddress,
    collectionName: asset.collectionName,
    amount: (asset.tokenType ?? INftType.ERC721) === INftType.ERC1155 ? 1 : 0,
    itemType: asset.tokenType ?? INftType.ERC721,
    supply: Number(asset.supply),
    remainingSupply: Number(asset.remainingSupply),
    price: BigNumber.from(asset.price),
    imageUrl: asset.imageUrl,
    isFlaggedByOS: asset.isFlaggedByOS,
    isPrivate: asset.isPrivate,
    isPossibleToBuy: asset.isPossibleToBuy,
    topBidPrice: asset.topBidPrice
      ? {
          ...asset.topBidPrice,
          amount: BigNumber.from(asset.topBidPrice.amount),
        }
      : null,
    lastSellPrice: asset.lastSellPrice
      ? {
          ...asset.lastSellPrice,
          amount: BigNumber.from(asset.lastSellPrice.amount),
        }
      : null,
    currency: {
      symbol: asset.currency.symbol,
      address: asset.currency.address,
      decimal: asset.currency.decimal,
    },
    listedAt: new Date(asset.listedAt * 1000), // TODO We should handle this logic in the BE
    marketIcon: asset.marketIcon,
    marketName: asset.marketName,
    rarityRank: asset.rarityRank,
    owner: asset.owner,
  }));

  return {
    items,
    fetchedTimestamp: new Date(),
    continuation,
    collectionAddress: data.collectionAddress,
  };
};
const NftSortAttributes = {
  floorAskPrice: "Floor Price",
} as const;
export type INftSortAttribute = keyof typeof NftSortAttributes;
export enum FlagStatuses {
  default = -1,
  nonFlagged = 0,
  flagged = 1,
}
type IFlagStatuses = typeof FlagStatuses[keyof typeof FlagStatuses];

interface IParams {
  slug: string;
  continuation?: string;
  tokenId?: string;
  marketPlace?: string;
  sortBy?: INftSortAttribute;
  abortSignal?: AbortSignal;
  minRarityRank?: number;
  maxRarityRank?: number;
  minFloorAskPrice?: number;
  maxFloorAskPrice?: number;
  attributes?: {
    [key: string]: string[];
  };
  currencies?: string[];
  priceCurrency?: SupportedCurrenciesType;
  flagStatus?: IFlagStatuses;
  excludeSources?: string[];
}
export interface IResult {
  items: Array<INftBe>;
  fetchedTimestamp: Date;
  continuation?: string;
  collectionAddress: string;
}

type IResponse = {
  assets: Array<{
    collectionName: string;
    contractAddress: string;
    currency: ICurrency;
    id: string;
    imageUrl: string;
    isFlaggedByOS: boolean;
    isPrivate: boolean;
    tokenType?: number;
    supply: string;
    remainingSupply: string;
    topBidPrice: {
      amount: BigNumber;
      currency: {
        symbol: string;
        decimal: number;
      };
    } | null;
    lastSellPrice: {
      amount: BigNumber;
      currency: {
        symbol: string;
        decimal: number;
      };
    } | null;
    listedAt: number;
    marketIcon: string;
    marketName: string;
    price: string;
    rarityRank: number;
    tokenId: string;
    isPossibleToBuy: boolean;
    owner: string;
  }>;
  continuation?: string;
  collectionAddress: string;
};
