import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Option, Select, Text } from "@cyanco/components/theme/v3";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { shortenAddress } from "@/utils";

export const SourceWalletSelector = ({
  setSourceWallet,
  sourceWallet,
}: {
  sourceWallet: string;
  setSourceWallet: (a: string) => void;
}) => {
  const cyanWallet = useCyanWallet();
  const { account } = useWeb3React();
  return (
    <Flex justifyContent="space-between" p="0 2px">
      <Flex alignItems="center" gap="4px">
        <Text size="md" weight="600" color="secondary">
          {`Wallet`}:
        </Text>
      </Flex>
      <Box w="130px">
        <Select onChange={setSourceWallet} value={sourceWallet} textSize="xxs" p="0.3rem 0.5rem 0.4rem">
          {account && (
            <Option
              value={account}
              style={{
                padding: "0.2rem 0.5rem 0.3rem",
              }}
            >
              <Text size="xxs" color="secondary">
                {`${`MAIN`} (${shortenAddress(account, 2)})`}
              </Text>
            </Option>
          )}
          {cyanWallet && (
            <Option
              value={cyanWallet.walletAddress}
              style={{
                padding: "0.2rem 0.5rem 0.3rem",
              }}
            >
              <Text size="xxs" color="secondary">
                {`${`CYAN`} (${shortenAddress(cyanWallet.walletAddress, 2)})`}
              </Text>
            </Option>
          )}
        </Select>
      </Box>
    </Flex>
  );
};
