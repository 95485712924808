import { axios } from "../axios";

export const login = async (data: {
  wallet: string;
  signature: string;
  chainId: number;
}): Promise<{ token: string }> => {
  return (await axios.post("/users/login", data)).data;
};

export const getSignedUser = async (data: { wallet: string }): Promise<{ isSigned: boolean }> => {
  return (await axios.get(`/v2/users/check-signature/${data.wallet}`)).data;
};
