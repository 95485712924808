import { CSSProperties, FC } from "react";
import { ArrowUpRight, Hexagon } from "react-feather";
import styled from "styled-components";

import { Flex, Hidden, Text, useModal } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { useWalletModalToggle } from "@/state/application/hooks";

import APE_URL from "../../assets/images/testdrive/ape_background.png";
import WL_URL from "../../assets/images/testdrive/box.png";
import CAPTAINZ_URL from "../../assets/images/testdrive/captains.png";
import COOLCAT_URL from "../../assets/images/testdrive/cool_cats.png";
import DOODLES_URL from "../../assets/images/testdrive/doodles.png";
import ENS_URL from "../../assets/images/testdrive/ens.png";
import FLOWER_URL from "../../assets/images/testdrive/flower.png";
import FROG_URL from "../../assets/images/testdrive/frog.png";
import WL2_URL from "../../assets/images/testdrive/hv_mtl2.png";
import HVMTL_URL from "../../assets/images/testdrive/hv_mtl.png";
import MOONBIRD_URL from "../../assets/images/testdrive/moonbirds.png";
import POKEMON_URL from "../../assets/images/testdrive/pokemon_card.png";
import SANDBOX_URL from "../../assets/images/testdrive/sandbox.png";
import UNSTOPPABLE_URL from "../../assets/images/testdrive/unstopable.png";
import { CYAN_TWITTER_LINK, DAPP_URL } from "../../config";
import { jumpToLink } from "../../utils";
import SecurityModal from "../Security";

export const TestDriveHome = () => {
  const { setModalContent, onBackModal } = useModal();
  const toggleWalletModal = useWalletModalToggle();

  const onClickSecurity = () => {
    setModalContent({
      title: `Security`,
      content: <SecurityModal />,
      onBack: onBackModal,
    });
  };

  return (
    <HomeContainer>
      <Hidden tabletDown style={{ height: "100%" }}>
        <GridContainer gridColumns="1fr" gridRows="0.8fr 0.6fr 1.6fr">
          <ItemCard background={CAPTAINZ_URL} link="https://www.memeland.com/captainz" style={{ borderRadius: "25px" }}>
            <TextBox direction="column">
              <Text size="xxl" color="white" weight="700">
                Stake with
              </Text>
              <Text size="xxl" color="white" weight="700">
                CAPTAINZ
              </Text>
            </TextBox>
          </ItemCard>

          <ItemCard background={APE_URL} link={`${DAPP_URL}/ape-coin`} style={{ borderRadius: "25px" }}>
            <Text size="xxl" color="white" weight="700">
              $APE
            </Text>
          </ItemCard>

          <GridContainer gridColumns="1fr 1fr">
            <MainCardWrapper onClick={() => jumpToLink("https://courtyard.io/")} style={{ borderRadius: "25px" }}>
              <PokemonBox>
                <Flex direction="column">
                  <Text size="lg" weight="700" color="black">
                    Pokemon Cards
                  </Text>
                  <Text size="xxs" weight="700" color="black">
                    provided by Courtyard.io
                  </Text>
                </Flex>
                <img src={POKEMON_URL} width="100%" height="auto" alt="pokemon" />
              </PokemonBox>
            </MainCardWrapper>

            <GridContainer gridColumns="1fr" gridRows="0.4fr 1.2fr">
              <MainCardWrapper
                style={{ border: "2px solid #FFF", borderRadius: "25px" }}
                onClick={() => jumpToLink("https://wreckleague.xyz/journey")}
              >
                <ImageCard background={WL_URL} />
              </MainCardWrapper>

              <ItemCard
                background={SANDBOX_URL}
                style={{ borderRadius: "25px" }}
                link="https://www.sandbox.game/en/sign/?redirectTo=%2Fen%2F&createAccount=true"
              >
                <Flex direction="column">
                  <Text color="white" size="xl" weight="700">
                    Build on
                  </Text>
                  <Text color="white" size="xxl" weight="700">
                    Sandbox
                  </Text>
                </Flex>
              </ItemCard>
            </GridContainer>
          </GridContainer>
        </GridContainer>
      </Hidden>
      <GridContainer gridColumns="1fr" gridRows="0.6fr 5fr 1.5fr">
        <GridContainer gridColumns="1fr 1fr">
          <MainCardWrapper onClick={() => toggleWalletModal()} style={{ borderRadius: "20px" }}>
            <TextCardBox style={{ backgroundColor: "#0D67FD" }}>
              <img src={UNSTOPPABLE_URL} width={55} height={55} style={{ borderRadius: "50%" }}></img>
              <CardText size="xl" weight="600" color="white">
                Unstoppable
              </CardText>
            </TextCardBox>
          </MainCardWrapper>

          <MainCardWrapper onClick={() => jumpToLink("https://app.ens.domains/")} style={{ borderRadius: "20px" }}>
            <TextCardBox style={{ backgroundColor: "white" }}>
              <CardText size="xl" weight="500" color="black">
                use your
              </CardText>
              <img src={ENS_URL} width={54} height={54}></img>
              <CardText size="xl" weight="500" color="black">
                ENS
              </CardText>
            </TextCardBox>
          </MainCardWrapper>
        </GridContainer>
        <ItemCard background={COOLCAT_URL} link="https://journeys.coolcatsnft.com/">
          <TextBox direction="column">
            <Text size="xxl" color="white" weight="700">
              Stake with
            </Text>
            <Text size="xxl" color="white" weight="700" style={{ fontSize: "48px" }}>
              COOL CATS
            </Text>
          </TextBox>
        </ItemCard>

        <ItemCard background={HVMTL_URL} link="https://hv-mtl.com/" style={{ borderRadius: "27px" }}>
          <TextBox direction="column">
            <Text size="xxl" color="white" weight="400">
              BUILD&nbsp;
            </Text>
            <Text size="xxl" color="white" weight="700">
              HV-MTL
            </Text>
          </TextBox>
        </ItemCard>
      </GridContainer>

      <Hidden laptopSDown style={{ height: "100%" }}>
        <GridContainer gridColumns="1fr" gridRows="1.4fr 1.6fr 2fr 1.4fr">
          <GridContainer gridColumns="1fr 1fr">
            <MainCardWrapper onClick={() => jumpToLink("https://stoodio.doodles.app/")}>
              <ImageCard background={DOODLES_URL} />
            </MainCardWrapper>
            <MainCardWrapper onClick={() => jumpToLink("https://wreckleague.xyz/journey")}>
              <ImageCard background={WL2_URL} />
            </MainCardWrapper>
          </GridContainer>

          <GridContainer gridColumns="1fr 1fr 1fr">
            <ColorCard link="https://delegate.xyz/" color="#ECE5A9" text="Delegate" circleColor="#FF0" shape="arrow" />
            <ColorCard color="#F6E7F8" text="Protect" circleColor="#E897E0" onClick={onClickSecurity} />
            <ColorCard
              link={CYAN_TWITTER_LINK}
              color="#80FFF0"
              text="Twitter Hexagon"
              circleColor="#1d9bf0"
              shape="hexagon"
            />
          </GridContainer>

          <ItemCard background={FROG_URL} link="https://www.plaguebrands.io/staking" style={{ borderRadius: "27px" }}>
            <Text size="xxl" color="black" weight="700">
              Earn $FRG
            </Text>
          </ItemCard>

          <GridContainer gridColumns="1fr 1fr">
            <MainCardWrapper
              onClick={() => jumpToLink("https://www.lens.xyz/")}
              style={{ border: "2px solid #FFF", borderRadius: "28px" }}
            >
              <ImageCard background={FLOWER_URL} />
            </MainCardWrapper>
            <MainCardWrapper
              onClick={() => jumpToLink("https://www.proof.xyz/rewards")}
              style={{ border: "2px solid #FFF", borderRadius: "28px" }}
            >
              <ImageCard background={MOONBIRD_URL} />
            </MainCardWrapper>
          </GridContainer>
        </GridContainer>
      </Hidden>
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  width: auto;
  display: grid;
  grid-template-columns: 1fr 1.4fr 1fr;
  grid-gap: 20px;
  align-items: center;
  height: 750px;
  box-sizing: border-box;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.laptopS]: "1fr 1.4fr" }])};
  ${getStyleWithMediaQuery("display", "", [{ [breakpoints.tablet]: "flex" }])};
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.tablet]: "calc(100% - 20px)" }])};
`;

const ItemCard: FC<{ background: string; style?: CSSProperties; link?: string }> = ({
  background,
  children,
  link,
  style,
}) => {
  return (
    <MainCardWrapper
      style={style}
      onClick={() => {
        if (link) {
          jumpToLink(link);
        }
      }}
    >
      <ItemCardContainer background={background} style={style}>
        {children}
      </ItemCardContainer>
    </MainCardWrapper>
  );
};

const ColorCard = ({
  color,
  circleColor,
  text,
  link,
  shape = "lock",
  onClick,
}: {
  color: string;
  circleColor: string;
  text: string;
  shape?: "lock" | "hexagon" | "arrow";
  link?: string;
  onClick?: () => void;
}) => {
  return (
    <MainCardWrapper
      style={{ borderRadius: "24px" }}
      onClick={() => {
        if (link) jumpToLink(link);
        if (onClick) onClick();
      }}
    >
      <ColoredCardBox backgroundColor={color}>
        {shape === "hexagon" ? (
          <Hexagon
            color={circleColor}
            fill={circleColor}
            width="90%"
            height="auto"
            scale={1.2}
            style={{ marginTop: "-22px" }}
          />
        ) : (
          <Flex
            style={{ width: "90%", borderRadius: "100%", backgroundColor: circleColor, aspectRatio: "1/1" }}
            alignItems="center"
            justifyContent="center"
          >
            {shape === "arrow" && <ArrowUpRight color="black" size={40} />}
            {shape === "lock" && <Text size="xxl">🔒</Text>}
          </Flex>
        )}
        <Text size="sm" color="black" weight="700" textAlign="center">
          {text}
        </Text>
      </ColoredCardBox>
    </MainCardWrapper>
  );
};

const ItemCardContainer = styled.div<{ background: string; flex?: number }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${({ background }) => background}) lightgray 50% / cover no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
`;

const ImageCard = styled.div<{ background: string }>`
  width: 100%;
  height: 100%;
  background: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
`;

const GridContainer = styled.div<{ gridColumns: string; gridRows?: string }>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${({ gridColumns }) => gridColumns};
  grid-template-rows: ${({ gridRows }) => gridRows};
  grid-gap: 20px;
  align-items: start;
  box-sizing: border-box;
`;

const CardText = styled(Text)`
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptopS]: 18 }])};
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 16 }])};
`;

const PokemonBox = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 40px);
  padding: 20px;
  background-color: #d7d7d7;
  transition: 0.2s ease-in-out;
`;

const ColoredCardBox = styled(Flex)<{ backgroundColor?: string }>`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 50px);
  padding: 25px 15px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: 0.2s ease-in-out;
`;

const TextCardBox = styled(Flex)`
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
`;

const MainCardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  cursor: pointer;
  height: 100%;
  &:hover {
    ${ItemCardContainer}, ${PokemonBox}, ${ImageCard},${ColoredCardBox} {
      transform: scale(1.07) !important;
    }
    ${TextCardBox} {
      transform: scale(1.05) !important;
    }
  }
`;

const TextBox = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
