import { UAuthConnector } from "@uauth/web3-react";
import type { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import type { Web3ReactHooks } from "@web3-react/core";
import type { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect-v2";

import COINBASE_ICON_URL from "../assets/images/coinbaseWalletIcon.svg";
import METAMASK_ICON_URL from "../assets/images/metamask.png";
import UNSTOPPABLE_LOGO from "../assets/images/ud-logo.svg";
import WALLETCONNECT_ICON_URL from "../assets/images/walletConnectIcon.svg";
import {
  coinbaseWallet,
  coinbaseWalletHooks,
  metaMask,
  metaMaskHooks,
  uauthConnect,
  uauthHook,
  walletConnect,
  walletConnectHooks,
} from "../connectors";

export type IWalletConnectors = MetaMask | WalletConnect | CoinbaseWallet | UAuthConnector;
export interface WalletInfo {
  connector?: IWalletConnectors;
  hooks: Web3ReactHooks;
  name: string;
  iconURL: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: metaMask,
    hooks: metaMaskHooks,
    name: "MetaMask",
    iconURL: METAMASK_ICON_URL,
    description: "Easy-to-use browser extension.",
    href: null,
    color: "red",
  },
  WALLET_CONNECT: {
    connector: walletConnect,
    hooks: walletConnectHooks,
    name: "WalletConnect",
    iconURL: WALLETCONNECT_ICON_URL,
    description: "Connect to Rainbow, Gnosis, Trust and more...",
    href: null,
    color: "white",
    mobile: true,
  },
  WALLET_LINK: {
    connector: coinbaseWallet,
    hooks: coinbaseWalletHooks,
    name: "Coinbase Wallet",
    iconURL: COINBASE_ICON_URL,
    description: "Use Coinbase Wallet app on mobile device",
    href: null,
    color: "blue",
  },
  UNSTOPPABLE_LOGIN: {
    connector: uauthConnect,
    hooks: uauthHook,
    name: "Login with Unstoppable",
    iconURL: UNSTOPPABLE_LOGO,
    description: "",
    href: null,
    color: "blue",
  },
};
