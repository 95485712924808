import { captureMessage } from "@sentry/react";
import { BigNumber } from "ethers";
import { useMemo } from "react";

import { CyanWallet } from "@usecyan/cyan-wallet";

import { factories as f } from "@cyanco/contract";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { MAX_APPROVAL_VALUE, apeCoinContract } from "@/config";
import { getCyanConduitFromChainId } from "@/constants/contracts";
import { INftType } from "@/types";

export const useApproval = (isVaultStake?: boolean) => {
  const { chainId, account, signer } = useWeb3React();

  const isConduitEnabled = useMemo(() => {
    if (isVaultStake) {
      return false;
    }
    return true;
  }, [isVaultStake, chainId]);

  const _getOperator = (operator: string) => {
    let _operator = operator;
    if (isConduitEnabled) {
      try {
        _operator = getCyanConduitFromChainId(chainId);
      } catch (e) {
        captureMessage("Cyan Conduit flag is enabled but conduit address is not defined in ENV");
        return operator;
      }
    }
    return _operator;
  };

  const isErc20ApprovalRequired = async (
    args: { amount: BigNumber; currencyAddress: string; cyanWallet?: CyanWallet },
    operator: string,
  ): Promise<boolean> => {
    if (!account || !signer) return false;
    const { currencyAddress, amount, cyanWallet } = args;
    const _operator = _getOperator(operator);
    const contract = f.SampleERC20TokenFactory.connect(currencyAddress, signer);
    const allowance = await contract.allowance(cyanWallet ? cyanWallet.walletAddress : account, _operator);
    return allowance.lt(amount);
  };

  const giveErc20Approval = async (
    args: { amount: BigNumber; currencyAddress: string; cyanWallet?: CyanWallet },
    operator: string,
  ) => {
    if (!account || !signer) return;
    const { currencyAddress, amount, cyanWallet } = args;
    const isApprovalRequired = await isErc20ApprovalRequired(args, operator);
    if (isApprovalRequired) {
      const _operator = _getOperator(operator);
      let _amount = amount;
      if (isConduitEnabled) _amount = MAX_APPROVAL_VALUE;
      const contract = f.SampleERC20TokenFactory.connect(currencyAddress, signer);
      if (cyanWallet) {
        const encodedFn = contract.interface.encodeFunctionData("approve", [_operator, _amount]);
        const encodedFnCyan = cyanWallet.interface.encodeFunctionData("execute", [currencyAddress, 0, encodedFn]);
        const tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedFnCyan,
        });
        await tx.wait();
      } else {
        const tx = await contract.approve(_operator, _amount);
        await tx.wait();
      }
    }
  };

  const giveNftPermission = async (
    args: { itemType: INftType; collectionAddress: string; tokenId: string; ignoreOwnerCheck?: boolean },
    operator: string,
  ) => {
    const { collectionAddress, tokenId, itemType, ignoreOwnerCheck } = args;
    if (!account || !signer) return;
    const _operator = _getOperator(operator);

    if (itemType === INftType.ERC1155) {
      const contract = f.SampleERC1155TokenFactory.connect(collectionAddress, signer);
      const [tokenBalance, isApproved] = await Promise.all([
        contract.balanceOf(account, tokenId),
        contract.isApprovedForAll(account, _operator),
      ]);
      if (tokenBalance.gt(0) && !isApproved) {
        const tx = await contract.setApprovalForAll(_operator, true);
        await tx.wait();
      }
    } else if (itemType === INftType.CryptoPunks) {
      const contract = f.SampleCryptoPunksFactory.connect(collectionAddress, signer);
      const [ownerAddress, { isForSale, onlySellTo, minValue }] = await Promise.all([
        contract.punkIndexToAddress(tokenId),
        contract.punksOfferedForSale(tokenId),
      ]);
      if (
        ownerAddress === account &&
        (!isForSale || onlySellTo.toLowerCase() !== operator.toLowerCase() || !minValue.eq(0))
      ) {
        const tx = await contract.offerPunkForSaleToAddress(tokenId, 0, operator);
        await tx.wait();
      }
    } else {
      const contract = f.SampleFactory.connect(collectionAddress, signer);
      const [owner, approvedTo, isApprovedForAll] = await Promise.all([
        contract.ownerOf(tokenId),
        contract.getApproved(tokenId),
        contract.isApprovedForAll(account, _operator),
      ]);
      const isOwner = owner.toLowerCase() === account.toLowerCase();
      const isApprovedToOperator = approvedTo.toLowerCase() === _operator.toLowerCase();
      if ((isOwner || ignoreOwnerCheck) && !isApprovedToOperator && !isApprovedForAll) {
        if (isConduitEnabled) {
          const tx = await contract.setApprovalForAll(_operator, true);
          await tx.wait();
        } else {
          const tx = await contract.approve(_operator, tokenId);
          await tx.wait();
        }
      }
    }
  };

  const getErc20ApprovalFunctionData = async (
    args: {
      amount: BigNumber;
      currencyAddress: string;
      cyanWallet: CyanWallet;
    },
    operator: string,
  ) => {
    if (!signer) return;
    const { amount, currencyAddress } = args;
    const isApeCoinAddress = currencyAddress.toLowerCase() === apeCoinContract;
    const isApprovalRequired = await isErc20ApprovalRequired(args, operator);
    if (isApprovalRequired) {
      const contract = f.SampleERC20TokenFactory.connect(currencyAddress, signer);
      const _operator = isApeCoinAddress ? operator : _getOperator(operator);
      let _amount = amount;
      if (isConduitEnabled && !isApeCoinAddress) _amount = MAX_APPROVAL_VALUE;
      const encodedAllowanceFnData = contract.interface.encodeFunctionData("approve", [_operator, _amount]);
      const encodedAllowanceFnDataFormatted = [currencyAddress, 0, encodedAllowanceFnData];
      return encodedAllowanceFnDataFormatted;
    }
    return;
  };

  return { giveErc20Approval, giveNftPermission, getErc20ApprovalFunctionData, isErc20ApprovalRequired };
};
