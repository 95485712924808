import { JsonRpcSigner } from "@ethersproject/providers";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { IPeerPlan, PeerPlanPaymentNumber } from "@/apis/p2p/types";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPeerPaymentPlanFromChainId } from "@/constants/contracts";
import { useApproval } from "@/hooks/useApproval";
import { getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { IPlanData, PeerPlanRepayment } from ".";
import { NftMetadata } from "./NftMetadata";

type IPlanRepaymentProgressProps = {
  paymentType: PeerPlanPaymentNumber;
  plan: IPeerPlan;
  planData: IPlanData;
  error?: {
    msg: string;
    title: string;
    description?: string;
  };
  onClose: () => void;
};

export const PeerPlanRepaymentProgress: React.FC<IPlanRepaymentProgressProps> = ({
  plan,
  planData,
  paymentType,
  onClose,
}) => {
  const { giveErc20Approval } = useApproval();
  const { setModalContent, unsetModal } = useModal();
  const { chainId, account, signer } = useWeb3React();
  const { cyanWallet } = useCyanWalletContext();
  const { transactions, addTransaction } = useTransactionContext();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<PlanRepaymentSteps>(PlanRepaymentSteps.TokenApproval);
  const peerPlanContract = getPeerPaymentPlanFromChainId(chainId);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(PlanRepaymentSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const checkAndApproveNonNativeCurrencyAllowance = async (signer: JsonRpcSigner, account: string) => {
    const contract = f.SampleERC20TokenFactory.connect(plan.loanBid.currency.address, signer);
    const requiredAmount = paymentType === PeerPlanPaymentNumber.COMPLETED ? planData.currentPayment : planData.fee;
    // checking user main wallet balance
    const userBalance = await contract.balanceOf(account);
    if (userBalance.lt(requiredAmount)) {
      openRepaymentModal({
        title: `Not enough funds`,
        msg: `Your payment didn’t go through due to lack of funds`,
        description: ` Please double check the amount of crypto in your wallet. There are not enough funds in your wallet to make the payment.`,
      });
      return;
    }
    await giveErc20Approval(
      {
        currencyAddress: plan.loanBid.currency.address,
        amount: requiredAmount,
      },
      peerPlanContract,
    );
  };

  const handlePaymentV2 = async () => {
    if (!signer || !chainId || !account) return;
    let tx: ethers.ContractTransaction;
    const paymentContractWriter = f.CyanPeerPlanFactory.connect(peerPlanContract, signer);
    if (paymentType === PeerPlanPaymentNumber.COMPLETED) {
      tx = await paymentContractWriter.complete(plan.planId);
    } else {
      tx = await paymentContractWriter.extend(plan.planId);
    }
    addTransaction({
      hash: tx.hash,
      type: "p2p-pay",
      data: {
        planId: plan.planId,
        tokenId: plan.tokenId,
        contractAddress: plan.collectionAddress,
      },
    });
    setActiveTx(tx.hash);
  };

  useEffect(() => {
    const onStepChange = async (step: PlanRepaymentSteps) => {
      if (!chainId || !account || !signer) return;
      try {
        switch (step) {
          case PlanRepaymentSteps.TokenApproval: {
            await checkAndApproveNonNativeCurrencyAllowance(signer, account);
            setSelectedStep(PlanRepaymentSteps.Paying);
            break;
          }
          case PlanRepaymentSteps.Paying: {
            await handlePaymentV2();
          }
        }
      } catch (err: any) {
        openRepaymentModal(err);
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    setModalContent({
      title: `Loan Details`,
      content: <PeerPlanRepayment plan={plan} onClose={onClose} error={mappedError} planData={planData} />,
    });
  };

  return (
    <Flex gap="5x" direction="column">
      <NftMetadata plan={plan} />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={PlanRepaymentStepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === PlanRepaymentSteps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};

enum PlanRepaymentSteps {
  TokenApproval = 1,
  Paying = 2,
  Done = 3,
}

const PlanRepaymentStepMarks = [
  {
    value: PlanRepaymentSteps.TokenApproval,
    title: `Token Approval`,
  },
  {
    value: PlanRepaymentSteps.Paying,
    title: `Processing Payment`,
    description: `View on Etherscan`,
  },
];

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
