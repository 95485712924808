import { AxiosError } from "axios";
import { BigNumber } from "ethers";
import { FormEventHandler, useContext, useEffect, useState } from "react";
import { Copy, Edit3 } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Box, Flex, StyledProjectLink } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Card, Input, Loader, Text } from "@cyanco/components/theme/v3/core";
import { NewTab } from "@cyanco/components/theme/v3/icons";

import {
  checkAffiliateCode,
  claimUserAffiliateRevenue,
  fetchAffiliateRevenueDetail,
  getUser,
  updateUserProfile,
} from "@/apis/user";
import { IAffiliateRevenue } from "@/apis/user/types";
import { useGetUserTime } from "@/hooks";

import { DATE_TIME_FORMAT_OPTIONS_DAYJS } from "../../config";
import { bigNumToFixedStr } from "../../utils/index";
import { AuthContext } from "../AuthContext/AuthContextProvider";

// eslint-disable-next-line
declare let window: any;

const AffiliateModal = ({ onClose }: { onClose: () => void }) => {
  const { user, setUser, setUserProfile } = useContext(AuthContext);
  const { getUserPreferredTime } = useGetUserTime();
  const theme = useTheme();
  const [affiliateDetail, setAffiliateDetail] = useState<IAffiliateRevenue | null>(null);
  const [affiliateLink, setAffiliateLink] = useState<string>("https://usecyan.com?a=");
  const [affiliateSharePercentage, setAffiliateSharePercentage] = useState<number | null>(null);
  const [affiliateCode, setAffiliateCode] = useState<string>("");
  const [affiliateCodeList, setAffiliateCodeList] = useState<Array<string>>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [claimError, setClaimError] = useState<string | null>(null);
  const [showUpdateWarning, setShowUpdateWarning] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isAffiliateUniqueOrValid, setIsAffiliateUniqueOrValid] = useState(false);
  const [isUpdateable, setIsUpdateable] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitable, setIsSubmitable] = useState(false);

  const handleCopyClick = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(affiliateLink);
    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  const handleAttemptToEdit = () => {
    if (!isUpdateable) {
      setShowUpdateWarning(true);
      setTimeout(() => {
        setShowUpdateWarning(false);
      }, 7000);
    }
  };

  const handleUserServiceError = (err: unknown) => {
    const axiosError = err as AxiosError;
    if (axiosError.response && (axiosError.response.status === 403 || axiosError.response.status === 401)) {
      setUser({ token: "", wallet: "", cyanWallet: "" });
      setUserProfile({
        email: "",
        telegramUsername: "",
        discordUsername: "",
        receivePaymentAlerts: false,
      });
    }
    console.error(err);
  };

  const onSubmitAffiliateCode = async () => {
    if (!user?.token || !isUpdateable || !isSubmitable) return;
    try {
      await updateUserProfile({
        token: user.token,
        affiliateCode: affiliateCode,
        receivePaymentAlerts: false,
      });
    } catch (err: unknown) {
      handleUserServiceError(err);
    }
    await getUserDetail();
    setIsEditing(false);
  };

  const onKeyDownAffiliateCode = async (e: any) => {
    if (e.key === "Enter") {
      await onSubmitAffiliateCode();
    }
  };

  const onChangeAffiliateCode = async (e: any) => {
    if (!user?.token || !isUpdateable || !isEditing) return;
    if (!e.includes("https://usecyan.com?a=")) {
      setAffiliateLink("https://usecyan.com?a=");
    } else {
      setAffiliateLink(e);
      const code = e.split("=")[1];
      try {
        setIsAffiliateUniqueOrValid(await checkAffiliateCode(user.token, code));
      } catch (err: unknown) {
        const axiosError = err as AxiosError;
        if (axiosError.response && axiosError.response.status === 400) {
          setIsAffiliateUniqueOrValid(false);
        }
        handleUserServiceError(err);
      }
      setAffiliateCode(code ?? "");
    }
  };
  const claimAffiliateRevenue: FormEventHandler = async e => {
    e.preventDefault();
    if (isProcessing || !user?.token) return;
    setIsProcessing(true);

    try {
      const data = await claimUserAffiliateRevenue(user.token);
      setClaimError(data.status === "Success" ? "" : data.status);
    } catch (e) {
      const { response } = e as AxiosError;
      setClaimError(response?.data?.message || `Something went wrong, please try again later.`);
    }
    setIsProcessing(false);
  };

  const getUserAffiliateDetail = async () => {
    if (!user?.token) return;
    try {
      const data = await fetchAffiliateRevenueDetail(user.token);
      setAffiliateDetail(data);
    } catch (e) {
      const { response } = e as AxiosError;
      console.log(response?.data?.message);
    }
  };

  const getUserDetail = async () => {
    if (!user?.token) return;
    try {
      const { affiliateCodes, affiliateCodeNextUpdateDate, affiliateRevenueSharePercentage } = await getUser(
        user.token,
      );
      if (affiliateRevenueSharePercentage) setAffiliateSharePercentage(affiliateRevenueSharePercentage);
      if (affiliateCodes) {
        setAffiliateLink(affiliateLink);
        if (affiliateCodes.length > 0) {
          setAffiliateCode(affiliateCodes.slice(-1)[0]);
          setAffiliateLink(`https://usecyan.com?a=${affiliateCodes.slice(-1)[0]}`);
        }
        setAffiliateCodeList(affiliateCodes);
      }
      if (affiliateCodeNextUpdateDate) {
        setIsUpdateable(new Date(affiliateCodeNextUpdateDate).getTime() < Date.now());
      }
    } catch (err: unknown) {
      handleUserServiceError(err);
      onClose();
    }
  };

  useEffect(() => {
    if (!user?.token) return;

    setAffiliateDetail(null);
    setClaimError(null);
    getUserDetail();
    getUserAffiliateDetail();
  }, [user]);

  useEffect(() => {
    if (affiliateCodeList.includes(affiliateCode)) {
      setIsSubmitable(true);
    } else if (!isAffiliateUniqueOrValid) {
      setIsSubmitable(false);
    } else {
      setIsSubmitable(true);
    }
  }, [affiliateCode]);

  return (
    <Flex direction="column" gap="0.7rem">
      <Box>
        <Text size="sm" color="gray0">{`Your affiliate link:`}</Text>
        <LinkWrapper direction="row" justify-content="space-between" alignItems="center" w="100%">
          <Flex direction="column" style={{ gap: "5px" }} justifyContent="space-between" w="100%">
            <Input
              type="text"
              title="ambassadorLink"
              value={affiliateLink}
              onClick={handleAttemptToEdit}
              onChange={e => onChangeAffiliateCode(e.target.value)}
              onKeyDown={e => onKeyDownAffiliateCode(e)}
              p="0.3rem 0.3rem 0.3rem 0.8rem"
              fontSize="xs"
              bgColor={theme.colors.gray10}
              style={
                !isEditing
                  ? {
                      borderColor: theme.colors.gray10,
                      caretColor: "transparent",
                    }
                  : {
                      borderColor: theme.colors.gray30,
                    }
              }
            >
              <Flex gap="2px" justifyContent="space-between">
                {isEditing && (
                  <Button
                    type="submit"
                    title="copy"
                    onClick={onSubmitAffiliateCode}
                    disabled={!isSubmitable}
                    size="xs"
                    style={{
                      width: "75px",
                      borderRadius: "5px",
                      height: "25px",
                    }}
                  >
                    {`Submit`}
                  </Button>
                )}
                {isUpdateable && !isEditing && (
                  <Button
                    variant="ghost"
                    type="button"
                    title="Edit"
                    onClick={() => setIsEditing(true)}
                    disabled={isProcessing}
                    style={{
                      width: "30px",
                      borderRadius: "5px",
                    }}
                  >
                    <Box p="0.2rem 0 0.1rem">
                      <Edit3 strokeWidth="2.5px" height="0.8rem" width="0.8rem" color={theme.colors.secondary}></Edit3>
                    </Box>
                  </Button>
                )}
                {!isEditing && (
                  <Button
                    type="submit"
                    title="copy"
                    onClick={handleCopyClick}
                    disabled={!affiliateLink || isCopied}
                    style={{
                      width: "30px",
                      borderRadius: "5px",
                    }}
                  >
                    <Box p="0.2rem 0 0.1rem">
                      <Copy strokeWidth="2.5px" height="0.7rem" width="0.7rem" color="black"></Copy>
                    </Box>
                  </Button>
                )}
              </Flex>
            </Input>
            {!isAffiliateUniqueOrValid && isEditing && isUpdateable && !affiliateCodeList.includes(affiliateCode) && (
              <Text style={{ paddingLeft: "0.7rem" }} color="yellow" size="xs">
                {`The code is claimed by another user or not in accepted pattern; please try a different one.`}
              </Text>
            )}
            {showUpdateWarning && (
              <Text style={{ paddingLeft: "0.7rem" }} color="yellow" size="xs">
                {`It has not yet been six months since your last update to the affiliate code.`}
              </Text>
            )}
          </Flex>
        </LinkWrapper>
      </Box>

      <Card hover>
        <RevenueWrapper>
          <RevenueDetailWrapper
            style={{ marginBottom: "0.5rem", borderBottom: "1px solid", borderBottomColor: theme.colors.gray20 }}
          >
            <Flex alignItems="center" style={{ gap: "5px" }} justifyContent="space-between" w="100%">
              <Text size="sm" color="secondary" weight="600">{`Revenue Details`}</Text>
              <StyledProjectLink
                href="https://docs.usecyan.com/docs/ambassador-program"
                target="_blank"
                key="toc-link"
                style={{ gap: "5px" }}
              >
                <StyledText size="xs" color="gray0">
                  {`Terms of Service`}
                </StyledText>
                <NewTab height="12px" width="12px" color={theme.colors.gray0} />
              </StyledProjectLink>
            </Flex>
          </RevenueDetailWrapper>

          <RevenueDetailWrapper>
            <Text size="sm" color="secondary">{`Revenue Share`}</Text>
            <Text size="sm" color="secondary">
              {affiliateSharePercentage ? `${(affiliateSharePercentage / 100).toFixed(2)}%` : "-"}
            </Text>
          </RevenueDetailWrapper>

          <RevenueDetailWrapper>
            <Text size="sm" color="secondary">{`Earned Revenue`}</Text>
            <Text size="sm" color="secondary">
              {affiliateDetail ? `${bigNumToFixedStr(BigNumber.from(affiliateDetail?.earnedRevenue))} ETH` : "-"}
            </Text>
          </RevenueDetailWrapper>

          <RevenueDetailWrapper>
            <Text size="sm" color="secondary">{`Expected Revenue`}</Text>
            <Text size="sm" color="secondary">
              {affiliateDetail ? `${bigNumToFixedStr(BigNumber.from(affiliateDetail?.unrealisedRevenue))} ETH` : "-"}
            </Text>
          </RevenueDetailWrapper>

          <RevenueDetailWrapper>
            <Text size="sm" color="secondary">{`Claimed Revenue`}</Text>
            <Text size="sm" color="secondary">
              {affiliateDetail ? `${bigNumToFixedStr(BigNumber.from(affiliateDetail?.claimedRevenue))} ETH` : "-"}
            </Text>
          </RevenueDetailWrapper>

          <RevenueDetailWrapper>
            <Text size="sm" color="secondary">{`Total # of Transactions`}</Text>
            <Text size="sm" color="secondary">
              {" "}
              {affiliateDetail ? affiliateDetail?.totalNumberTransaction : "-"}{" "}
            </Text>
          </RevenueDetailWrapper>

          <RevenueDetailWrapper>
            <Text size="sm" color="secondary">{`Last Transaction`}</Text>
            <Text size="sm" color="secondary">
              {affiliateDetail && affiliateDetail?.lastTransactionDate
                ? getUserPreferredTime(affiliateDetail.lastTransactionDate).format(DATE_TIME_FORMAT_OPTIONS_DAYJS)
                : "-"}{" "}
            </Text>
          </RevenueDetailWrapper>
        </RevenueWrapper>
      </Card>
      <Card hover>
        <ClaimWrapper>
          <Flex direction="column" gap="3px">
            <Text style={{ paddingRight: "0.5rem" }} size="sm" color="gray0">
              {`Total earned affiliate revenue:`}
            </Text>
            <Text style={{ paddingRight: "0.5rem" }} size="lg" color="secondary">
              {affiliateDetail ? `${bigNumToFixedStr(BigNumber.from(affiliateDetail?.earnedRevenue))} ETH` : "-"}
            </Text>
          </Flex>

          <ConfirmButtonWrapper>
            <Button
              onClick={claimAffiliateRevenue}
              disabled={isProcessing || !affiliateDetail || affiliateDetail.earnedRevenue <= 0}
            >
              {!isProcessing && (
                <Text weight="700" color="black">
                  {`Claim`}
                </Text>
              )}
              {isProcessing && <Loader size="14px" stroke="black" />}
            </Button>
          </ConfirmButtonWrapper>
        </ClaimWrapper>
      </Card>

      {!isProcessing && claimError === "" && (
        <AlertTextContainer>
          <Text size="xs" color="cyan">
            {`Submit Claim Success`} 🎉
          </Text>
        </AlertTextContainer>
      )}

      {!isProcessing && claimError && (
        <AlertTextContainer>
          <Text size="xs" color="cyan" style={{ marginTop: "4px" }}>
            {claimError}
          </Text>
        </AlertTextContainer>
      )}
    </Flex>
  );
};

export default AffiliateModal;

const RevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0.8rem 0.1rem;
`;

const RevenueDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 0.5rem 0;
`;

const ClaimWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.8rem;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.8rem 0.8rem 0rem 0.8rem;
    outline: none;
    align-items: flex-start;
  }
`;

const ConfirmButtonWrapper = styled.div`
  height: 45px;
  width: 100px;

  @media only screen and (max-width: 1080px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const AlertTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-top: 0.5rem;
`;

const LinkWrapper = styled(Flex)`
  padding-top: 0.3rem;
  flex-direction: row;
  width: 100%;
  gap: 0.3rem;
  ${getStyleWithMediaQuery("flex-direction", "", [
    {
      [breakpoints.tablet]: "column",
    },
  ])}
`;

const StyledText = styled(Text)`
  text-align: right;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    color: cyan;
  }
`;
