import { AxiosError } from "axios";
import { FormEventHandler, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Button, Loader, Text } from "@cyanco/components/theme/v3";

import { useWeb3React } from "@/components/Web3ReactProvider";

import { CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS } from "../../constants/chains";
import { switchToNetwork } from "../../utils/switchToNetwork";

const ChangeNetworkModal = ({ chainId }: { chainId: typeof CYAN_SUPPORTED_CHAIN_IDS[number] }) => {
  const { connector } = useWeb3React();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [claimError, setClaimError] = useState<string | null>(null);

  const changeNetwork: FormEventHandler = async e => {
    e.preventDefault();
    if (isProcessing && !connector) return;
    setIsProcessing(true);

    try {
      await switchToNetwork({ connector, chainId });
    } catch (e) {
      const { response } = e as AxiosError;
      setClaimError(response?.data?.message || `Something went wrong, please try again.`);
    }
    setIsProcessing(false);
  };

  return (
    <Flex direction="column" p="1rem">
      <Text textAlign="center" size="md">
        To interact with the Cyan Dapp, please switch to <Text color="cyan">{CHAIN_INFO[chainId].label}</Text> Network
        in your wallet.
      </Text>
      <ConfirmButtonWrapper>
        <StyledConfirmButton onClick={changeNetwork} disabled={isProcessing}>
          {!isProcessing && (
            <Text weight="700" color="black">
              {`Switch Network`}
            </Text>
          )}
          {isProcessing && <Loader size="14px" stroke="black" />}
        </StyledConfirmButton>
      </ConfirmButtonWrapper>
      {!isProcessing && claimError && (
        <AlertTextContainer>
          <Text size="sm" color="red" style={{ marginTop: "4px" }}>
            {claimError}
          </Text>
        </AlertTextContainer>
      )}
    </Flex>
  );
};

export default ChangeNetworkModal;

const StyledConfirmButton = styled(Button)`
  border-radius: 20px;
  text-wrap: none;
  white-space: nowrap;

  @media only screen and (max-width: 1080px) {
    margin: 1rem 0 1rem 0;
    border-radius: 20px;
    width: 100%;
  }
`;

const ConfirmButtonWrapper = styled.div`
  height: 45px;
  width: 100%;
  margin-top: 2rem;

  @media only screen and (max-width: 1080px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const AlertTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-top: 0.5rem;
`;
