import React from "react";

import { AccountDataProvider } from "./AccountDataContext";
import { AccountPageProvider } from "./AccountPageContext";

export const AccountContextWrapperV3: React.FC = ({ children }) => {
  return (
    <AccountPageProvider>
      <AccountDataProvider>{children}</AccountDataProvider>
    </AccountPageProvider>
  );
};
