import { BigNumber } from "ethers";

import { IPlanBe } from "@/components/Account/plan.types";

import { axios } from "../axios";
import { FnFetchPositionsByUser } from "./types";

export const fetchPositionsByUser: FnFetchPositionsByUser<IPlanBe> = async (address, type, abortSignal) => {
  const { data } = await axios.get(`/v2/plans/${address}`, {
    params: { type },
    signal: abortSignal,
  });
  return data.map((position: IPlanBe) => ({
    ...position,
    createdAt: new Date(position.createdAt),
    defaultedAt: position.defaultedAt ? new Date(position.defaultedAt) : null,
    completedAt: position.completedAt ? new Date(position.completedAt) : null,
    payments: position.payments
      ? position.payments.map(payment => ({
          ...payment,
          createdAt: payment.createdAt ? new Date(payment.createdAt) : null,
        }))
      : [],
    revivalInfo: position.revivalInfo
      ? {
          ...position.revivalInfo,
          penaltyAmount: BigNumber.from(position.revivalInfo.penaltyAmount),
        }
      : undefined,
  }));
};
