import { BigNumber } from "ethers";
import { useEffect, useState } from "react";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme";
import { Card, SystemMessage } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { PoolId, apeCoinContract as apeCoinContractAddress, apeStakingContract } from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IApeCoinBalance } from "@/hooks/useApePlanCreation.types";
import { IsInTestDrive } from "@/utils";
import { executeBatchRead } from "@/utils/contract";
import { IMappedError } from "@/utils/error/msgs";
import { IBatchReaderData } from "@/utils/types";

import { useApeStakingUserAssets } from "../../ApeCoinDataContext";
import { IActionType, IApeCoinStakingModal } from "../../types";
import { StyledButton } from "../common";
import { ActionSelector } from "./ActionSelector";
import { ApeCoinStaking } from "./staking/ApeCoinStaking";
import { ApeCoinUnstaking } from "./unstaking/UnstakingApeCoin";

export const ApeCoinOnlyStakingModal: React.FC<IApeCoinStakingModal> = ({ action, err }) => {
  const { account, provider, chainId, signer } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { initiateApeCoinStake, initiateApeCoinUnstake } = useApePlanCreation();

  const [error, setError] = useState<IMappedError | null>(err || null);
  const [selectedAction, setSelectedAction] = useState<IActionType>(action);
  const [stakingAmount, setStakingAmount] = useState<string>();
  const [stakingAmountCyan, setStakingAmountCyan] = useState<string>();
  const [releaseWallet, setReleaseWallet] = useState<string>(account ?? "");
  const [sourceWallet, setSourceWallet] = useState<string>(account ?? "");
  const [unstakingAmount, setUnstakingAmount] = useState<string>();
  const { activeApeCoinPlan } = useApeStakingUserAssets();
  const [apeCoinBalance, setApeCoinBalance] = useState<IApeCoinBalance>({
    stakedAmountMain: null,
    stakedAmountCyan: null,
    earnedAmountMain: null,
    earnedAmountCyan: null,
    mainWalletMax: null,
    cyanWalletMax: null,
    allowance: null,
  });

  const [rewardStakeToCyanVault, setRewardStakeToCyanWallet] = useState<boolean>(false);

  useEffect(() => {
    if (!provider || !signer) return;
    const _setApeCoinBalance = async () => {
      if (!provider || !account) return;

      const iApeCoinContract = f.ApeCoinFactory.createInterface();
      const iApeCoinStakingContract = f.ApeCoinStakingFactory.createInterface();

      const batchDatas: IBatchReaderData[] = [
        {
          interface: iApeCoinContract,
          functionName: "balanceOf",
          contractAddress: apeCoinContractAddress,
          params: [account],
        },
        {
          interface: iApeCoinStakingContract,
          functionName: "addressPosition",
          contractAddress: apeStakingContract,
          params: [account],
        },
        {
          interface: iApeCoinStakingContract,
          functionName: "pendingRewards",
          contractAddress: apeStakingContract,
          params: [0, account, 0],
        },
        {
          interface: iApeCoinContract,
          functionName: "allowance",
          contractAddress: apeCoinContractAddress,
          params: [account, apeStakingContract],
        },
      ];

      if (cyanWallet) {
        batchDatas.push(
          {
            interface: iApeCoinContract,
            functionName: "balanceOf",
            contractAddress: apeCoinContractAddress,
            params: [cyanWallet.walletAddress],
          },
          {
            interface: iApeCoinStakingContract,
            functionName: "addressPosition",
            contractAddress: apeStakingContract,
            params: [cyanWallet.walletAddress],
          },
          {
            interface: iApeCoinStakingContract,
            functionName: "pendingRewards",
            contractAddress: apeStakingContract,
            params: [0, cyanWallet.walletAddress, 0],
          },
        );
      }
      const batchResults = await executeBatchRead(chainId, provider, batchDatas);

      let cyanWalletMax = BigNumber.from(0);
      let stakedAmountCyan = BigNumber.from(0);
      let earnedAmountCyan = BigNumber.from(0);
      if (cyanWallet) {
        cyanWalletMax = batchResults[4][0];
        stakedAmountCyan = batchResults[5].stakedAmount;
        earnedAmountCyan = batchResults[6][0];
      }
      setApeCoinBalance({
        mainWalletMax: batchResults[0][0],
        stakedAmountMain: batchResults[1].stakedAmount,
        earnedAmountMain: batchResults[2][0],
        allowance: batchResults[3][0],
        cyanWalletMax,
        stakedAmountCyan,
        earnedAmountCyan,
      });
    };
    _setApeCoinBalance();
  }, [account, cyanWallet]);

  const isMainWalletUnstaking = sourceWallet.toLowerCase() === (account ?? "").toLowerCase();

  const stake = () => {
    if (!provider) return;
    setError(null);
    initiateApeCoinStake({
      action: selectedAction,
      rewardStakeToCyanVault,
      activePlan: activeApeCoinPlan,
      poolId: PoolId.COIN,
      cyanWalletAmount: parseFloat(stakingAmountCyan || "0"),
      mainWalletAmount: parseFloat(stakingAmount || "0"),
      apeCoinBalance: {
        earnedAmount: (apeCoinBalance.earnedAmountMain ?? BigNumber.from(0)).add(apeCoinBalance.earnedAmountCyan ?? 0),
        stakedAmount: (apeCoinBalance.stakedAmountMain ?? BigNumber.from(0)).add(apeCoinBalance.stakedAmountCyan ?? 0),
        mainWalletMax: apeCoinBalance.mainWalletMax,
        cyanWalletMax: apeCoinBalance.cyanWalletMax,
        allowance: apeCoinBalance.allowance,
      },
    });
    return;
  };

  const unstake = async () => {
    if (!activeApeCoinPlan && !unstakingAmount) return;
    initiateApeCoinUnstake({
      apeCoinBalance: {
        earnedAmount: isMainWalletUnstaking ? apeCoinBalance.earnedAmountMain : apeCoinBalance.earnedAmountCyan,
        stakedAmount: isMainWalletUnstaking ? apeCoinBalance.stakedAmountMain : apeCoinBalance.stakedAmountCyan,
        mainWalletMax: apeCoinBalance.mainWalletMax,
        cyanWalletMax: apeCoinBalance.cyanWalletMax,
        allowance: apeCoinBalance.allowance,
      },
      action: selectedAction,
      planId: activeApeCoinPlan?.id,
      unstakingAmount: unstakingAmount ? Number(unstakingAmount) : undefined,
      releaseWallet,
      sourceWallet,
    });
    return;
  };

  return (
    <Flex direction="column" gap="1rem">
      {error && <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />}{" "}
      <Card p={"24px 12px"}>
        <Flex direction="column" gap="26px">
          <Flex justifyContent="center">
            <ActionSelector selectedAction={selectedAction} onChange={setSelectedAction} />
          </Flex>
          <Flex direction="column" gap="0.7rem">
            {selectedAction == IActionType.stake && (
              <ApeCoinStaking
                {...{
                  apeCoinBalance: {
                    ...apeCoinBalance,
                    earnedAmount: (apeCoinBalance.earnedAmountMain ?? BigNumber.from(0)).add(
                      apeCoinBalance.earnedAmountCyan ?? 0,
                    ),
                    stakedAmount: (apeCoinBalance.stakedAmountMain ?? BigNumber.from(0)).add(
                      apeCoinBalance.stakedAmountCyan ?? 0,
                    ),
                  },
                  cyanWalletStakingAmount: stakingAmountCyan,
                  stakingAmount,
                  rewardStakeToCyanVault,
                  onMainInputChange: setStakingAmount,
                  onCyanInputChange: setStakingAmountCyan,
                  setRewardStakeToCyanWallet,
                }}
              />
            )}
            {selectedAction == IActionType.unstake && (
              <ApeCoinUnstaking
                {...{
                  releaseWallet,
                  setReleaseWallet,
                  apeCoinBalance: {
                    earnedAmount: isMainWalletUnstaking
                      ? apeCoinBalance.earnedAmountMain
                      : apeCoinBalance.earnedAmountCyan,
                    stakedAmount: isMainWalletUnstaking
                      ? apeCoinBalance.stakedAmountMain
                      : apeCoinBalance.stakedAmountCyan,
                  },
                  unstakingAmount,
                  setUnstakingAmount,
                  setSourceWallet,
                  sourceWallet,
                }}
              />
            )}
          </Flex>
        </Flex>
      </Card>
      {selectedAction === IActionType.stake && (
        <StyledButton
          disabled={IsInTestDrive || (!stakingAmount && !stakingAmountCyan)}
          onClick={stake}
        >{`Stake APE`}</StyledButton>
      )}
      {selectedAction === IActionType.unstake && (
        <StyledButton
          disabled={IsInTestDrive || (!activeApeCoinPlan && !unstakingAmount)}
          onClick={unstake}
        >{`Unstake APE`}</StyledButton>
      )}
    </Flex>
  );
};
