import { useEffect, useState } from "react";
import { HelpCircle, Trash } from "react-feather";
import { NumericFormat } from "react-number-format";
import styled, { useTheme } from "styled-components";

import { Box, Flex, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Input, Tooltip, TooltipText } from "@cyanco/components/theme/v3";

import { LtvSliderInput } from "../LtvSlider";

export const LtvControllerHeader = () => {
  const theme = useTheme();
  return (
    <HeaderContainer>
      <Flex gap="5px" alignItems="center">
        <Text weight="600" size="lg" color="secondary">
          {`Price-to-Floor Ratio`}
        </Text>
        <Tooltip>
          <HelpCircle height={16} width={16} color={theme.colors.gray0} />
          <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
              <div>{`Price-to-floor ratio measure the expensiveness of an NFT.`}</div>
            </Text>
          </TooltipText>
        </Tooltip>
      </Flex>
      <Flex gap="5px" alignItems="center">
        <Text weight="600" size="lg" color="secondary">
          {`Loan-to-Value Cap`}
        </Text>
        <Tooltip>
          <HelpCircle height={16} width={16} color={theme.colors.gray0} />
          <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
              <div>{`The Loan-to-Value (LTV) cap  refers to the maximum percentage of an NFT's appraised value that the pool is willing to lend to a borrower.`}</div>
            </Text>
          </TooltipText>
        </Tooltip>
      </Flex>{" "}
      <div />
    </HeaderContainer>
  );
};

export const LtvControllerRow = ({
  priceToFloorRatio,
  ltvRatio,
  index,
  handleFormChange,
  handleRemoveRow,
}: {
  priceToFloorRatio: number;
  ltvRatio: number;
  index: number;
  handleRemoveRow: (index: number) => void;
  handleFormChange: (index: number, value: number, target: "priceToFloorRatio" | "ltvRatio") => void;
}) => {
  const theme = useTheme();
  const [ratio, setRatio] = useState<string>(priceToFloorRatio.toString());
  useEffect(() => {
    const ratioParsed = ratio === "" || isNaN(parseFloat(ratio)) ? 0 : parseFloat(ratio);
    handleFormChange(index, ratioParsed, "priceToFloorRatio");
  }, [ratio]);
  return (
    <Row>
      <Box w="100px">
        <NumericFormat
          value={ratio}
          onValueChange={values => setRatio(values.value)}
          fontSize="lg"
          decimalScale={3}
          placeholder="0"
          customInput={Input}
          valueIsNumericString
        />
      </Box>
      <LtvSliderInput
        value={ltvRatio}
        min={0}
        max={100}
        step={null}
        onChange={e => {
          handleFormChange(index, e as number, "ltvRatio");
        }}
      />
      <Flex justifyContent="flex-end">
        <Box w="100px">
          <NumericFormat
            customInput={Input}
            value={ltvRatio.toFixed(2)}
            fontSize="lg"
            decimalScale={2}
            placeholder="0"
            disabled
            min={0}
            max={100}
            suffix="%"
          />
        </Box>
      </Flex>
      <Flex justifyContent="flex-end">
        <Remove color={theme.colors.secondary} onClick={() => handleRemoveRow(index)} />
      </Flex>
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  grid-template-columns: 1.4fr 2fr 1fr 0.2fr;
  padding: 0.8rem 1.2rem;
  background-color: transparent;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  border: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
  border-radius: 20px;
`;

const HeaderContainer = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  grid-template-columns: 1.4fr 2fr 1fr 0.2fr;
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
`;

const Remove = styled(Trash)`
  cursor: pointer;
  transition: 0.2s;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
`;
