import dayjs from "dayjs";

import { IPeerPlan, PeerPlanStatuses } from "@/apis/p2p/types";
import { BNPLStatuses, IBNPL } from "@/components/Bnpl/bnpl.types";

import { IPawn, PawnStatuses } from "../../pawn.types";

type IPositionParams = (IBNPL | IPawn | IPeerPlan) & {
  planType: "Pawn" | "BNPL" | "P2P";
};

export const checkIsRevivalPossible = (position: IPositionParams) => {
  if (position.planType === "P2P") return false;
  return (
    position.revivalInfo &&
    position.revivalInfo.isRevivalPossible &&
    dayjs(position.defaultedAt).add(position.term, "seconds").isAfter(Date.now()) &&
    ((position.planType === "BNPL" && position.status === BNPLStatuses.Defaulted) ||
      (position.planType === "Pawn" && position.status === PawnStatuses.Defaulted))
  );
};

export const checkIsPositionDefaulted = (position: IPositionParams) => {
  if (position.planType === "P2P")
    return position.status === PeerPlanStatuses.DEFAULTED || position.status === PeerPlanStatuses.LIQUIDATED;
  if (position.planType === "BNPL")
    return position.status === BNPLStatuses.Defaulted || position.status === BNPLStatuses.Liquidated;
  if (position.planType === "Pawn")
    return position.status === PawnStatuses.Defaulted || position.status === PawnStatuses.Liquidated;
  return false;
};

export const checkIsPositionActive = (position: IPositionParams) => {
  if (position.planType === "P2P") return position.status === PeerPlanStatuses.ACTIVE;
  if (position.planType === "BNPL") return position.status === BNPLStatuses.Activated;
  if (position.planType === "Pawn") return position.status === PawnStatuses.Activated;
  return false;
};
