import { InputHTMLAttributes, useState } from "react";
import { NumericFormat, OnValueChange } from "react-number-format";
import styled, { useTheme } from "styled-components";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { Button, Flex, Input, Loader, Text, getCurrencyLogoSrc, useModal } from "@cyanco/components/theme";
import { ChevronDown } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { numberWithCommas, shortenAddress, shortenName } from "@/utils";

import { IExpiryDate } from "./utils";

export const PrivateSaleInput = ({
  disabled,
  price,
  currency,
  currencies,
  onInputChange,
  onCurrencyChange,
  hideDown,
  active,
}: {
  disabled?: boolean;
  price?: string;
  currency: {
    address: string;
    decimal: number;
    symbol: SupportedCurrenciesType;
  };
  currencies: {
    address: string;
    decimal: number;
    symbol: SupportedCurrenciesType;
  }[];
  hideDown?: boolean;
  active?: boolean;
  onInputChange: (a: string) => void;
  onCurrencyChange: (a: string) => void;
}) => {
  const theme = useTheme();
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const onStakingAmountChange: OnValueChange = values => {
    onInputChange(values.value);
  };
  return (
    <Flex style={{ position: "relative" }}>
      <NumericFormat
        placeholder={showPlaceholder ? "0" : ""}
        thousandSeparator=","
        allowLeadingZeros={false}
        allowNegative={false}
        onValueChange={onStakingAmountChange}
        value={price || ""}
        disabled={disabled}
        customInput={CustomInput}
        decimalScale={4}
        valueIsNumericString
        style={{
          fontSize: "36px",
          height: "60px",
          width: "100%",
          padding: "0px 7.5px",
          color: active ? undefined : theme.colors.gray0,
        }}
        onFocus={() => setShowPlaceholder(false)}
        onBlur={() => setShowPlaceholder(true)}
        height={"35px"}
        p="0.3rem 0.3rem 0.3rem 0.3rem"
      />

      <PillWrapper
        style={{
          paddingRight: "0.2rem",
        }}
      >
        <ImageWrapper>
          <img height="16px" width="16px" src={getCurrencyLogoSrc(currency.symbol)} />
        </ImageWrapper>
        <StyledSelect
          value={currency.address}
          onChange={e => {
            onCurrencyChange(e.target.value);
          }}
          active={active}
          disabled={disabled}
        >
          {currencies.map(currency => (
            <option value={currency.address} key={currency.address}>
              {currency.symbol}
            </option>
          ))}
        </StyledSelect>
        {!hideDown && (
          <StyledChevronDown>
            <ChevronDown color={theme.theme == "dark" ? theme.colors.secondary : theme.colors.gray80} width="10px" />
          </StyledChevronDown>
        )}
      </PillWrapper>
    </Flex>
  );
};

export const AddressInput = ({ placeholder, value, onChange, disabled, style }: InputHTMLAttributes<any>) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  return (
    <CustomInput
      placeholder={showPlaceholder ? placeholder : ""}
      onFocus={() => setShowPlaceholder(false)}
      onBlur={() => setShowPlaceholder(true)}
      value={value}
      onChange={onChange}
      style={style}
      disabled={disabled}
    />
  );
};

const CustomInput = styled(Input)`
  font-size: 36px;
  padding: 0px 7.5px;
  height: 60px;
  background: ${({ theme }) => (theme.theme == "dark" ? "#1c1c1c" : "#EEEEEE")};
  border: none;
  border-radius: 10px 10px 0 0;
  :hover {
    border: none;
  }
  :disabled {
    cursor: default;
  }
`;

export const StyledSelect = styled.select<{ padding?: string; active?: boolean }>`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: Inter;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  padding: 0 6px 0 calc(0.5rem + 15px);
  padding: ${({ padding }) => padding};
  border-radius: 30px;
  max-width: 85px;
  outline: none;
  appearance: none;
  cursor: pointer;
  background-color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#C8C8C8")};
  border: none;
  :disabled {
    opacity: ${({ active }) => (active ? 1 : 0.7)};
  }
`;

const PillWrapper = styled(Flex)`
  height: 30px;
  position: absolute !important;
  top: 15px;
  right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#C8C8C8")};
  border-radius: 30px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
  position: relative;
`;

export const ImageWrapper = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0.5rem;
`;

export const StyledChevronDown = styled.div`
  z-index: 4;
  pointer-events: none;
  margin-right: 4px;
`;

export const PrivateSaleLoading = ({
  price,
  buyer,
  seller,
  imageUrl,
  tokenId,
  collectionName,
  isLoading,
  currencySymbol,
}: {
  price: string;
  buyer?: string;
  seller?: string;
  imageUrl?: string | null;
  tokenId: string;
  collectionName: string;
  isLoading: boolean;
  currencySymbol: string;
}) => {
  return (
    <Flex gap="1rem">
      <div style={{ position: "relative" }}>
        <StyledImage src={imageUrl || NoImage} alt="nft" hasImage={!!imageUrl} />
        {isLoading && (
          <ImageLoader>
            <Loader stroke="white" size="50px" />
          </ImageLoader>
        )}
      </div>
      <Flex direction="column" w="100%" justifyContent="space-around" pt="0.2rem" gap="0.3rem">
        <Flex justifyContent="space-between" w="100%">
          <Text size="md" weight="600" color="secondary">
            {shortenName(collectionName, 25, 6, 4)}
          </Text>
          <Text size="md" weight="600" color="secondary">
            #{shortenName(tokenId, 20, 6, 4)}
          </Text>
        </Flex>
        <Flex direction="column" gap="7px">
          <Flex justifyContent="space-between" w="100%">
            <Text size="xs" weight="500" color="gray80">
              {`Price`}
            </Text>

            <Flex alignItems="center" gap="2px">
              <Text size="xs" weight="500" color="gray80">
                {numberWithCommas(price, 2)}
              </Text>
              <Text size="xs" weight="500" color="gray80">
                {currencySymbol}
              </Text>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" w="100%">
            <Text size="xs" weight="500" color="gray80">
              {buyer ? `Buyer` : `Seller`}
            </Text>

            <Flex alignItems="center" gap="2px">
              <Text size="xs" weight="500" color="gray80">
                {buyer && shortenAddress(buyer)}
                {seller && shortenAddress(seller)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const StyledImage = styled.img<{ hasImage?: boolean; hasError?: boolean }>`
height: 85px;
width: 85px;
object-fit: scale-down;
border: ${({ hasError }) => `${hasError ? "3px solid #f00" : "none"};`}
border-radius: 15px;
padding: 0;
margin: 0;
background-color: black;
filter: ${({ hasImage, theme }) =>
  !hasImage &&
  theme.theme === "light" &&
  "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;

const ImageLoader = styled.div`
  height: 85px;
  width: 85px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: none;
`;

export const DateSelector = ({
  date,
  dates,
  onChangeDate,
  disabled,
}: {
  date: IExpiryDate;
  dates: IExpiryDate[];
  disabled?: boolean;
  onChangeDate: (a: string) => void;
}) => {
  const theme = useTheme();
  return (
    <PillDateWrapper
      style={{
        paddingRight: "0.2rem",
      }}
    >
      <StyledSelect
        padding="5px 0 5px 11px"
        value={date.name}
        onChange={e => {
          onChangeDate(e.target.value);
        }}
        disabled={disabled}
      >
        {dates.map(d => (
          <option value={d.name} key={d.name}>
            {d.name}
          </option>
        ))}
      </StyledSelect>
      <Flex style={{ marginRight: "5px" }}>
        <ChevronDown color={theme.theme == "dark" ? theme.colors.secondary : theme.colors.gray80} width="16px" />
      </Flex>
    </PillDateWrapper>
  );
};

const PillDateWrapper = styled(Flex)`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#C8C8C8")};
  border-radius: 10px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
  position: relative;
`;

export const CloseButton = () => {
  const { unsetModal } = useModal();
  return (
    <Button onClick={unsetModal} style={{ padding: "1rem 0" }}>
      <Text color="black" size="sm" weight="700">
        {`Close`}
      </Text>
    </Button>
  );
};
