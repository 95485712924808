import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Flex, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

export const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Flex direction="column" alignItems="center" gap="5rem" p="3rem 0 6rem">
      <Flex direction="column" alignItems="center" gap="2.5rem">
        <Flex direction="column" alignItems="center">
          <NotFound>404</NotFound>
          <Text color="secondary" weight="600" size="xxl" textAlign="center">
            Page could not be found.
          </Text>
        </Flex>
        <Text weight="600" size="xl" color="gray0" textAlign="center">
          We scoured the spectrum, hit a blue wall. <br />
          Your page is lost in the Cyan abyss.
        </Text>
      </Flex>
      <HomeButton size="sm" onClick={() => navigate("/")}>
        Head back Home
      </HomeButton>
    </Flex>
  );
};

const NotFound = styled(Text)`
  color: rgba(156, 156, 156, 1);
  font-size: 150px;
  font-weight: 600;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptopS]: 100 }])}
`;

const HomeButton = styled(Button)`
  width: 260px;
  padding: 15px;
`;
