import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, SkeletonLine, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { Decrease, HelpCircle, Increase } from "@cyanco/components/theme/v3/icons";
import { CollectioNoImage } from "@cyanco/components/theme/v3/images";

import { currencyList } from "@/apis/coinbase";
import { ICollectionBe } from "@/apis/collection/types";
import { calculateChangePercent } from "@/components/Vault/VaultPageContext";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { useSortableHeader } from "@/hooks/useSortableHeader";
import { bigNumToFloat } from "@/utils";

export const CollectionStatsHeader = ({
  updateCollectionSorted,
  collectionsSorted,
  selectedCurrency,
  defaultCurrencySymbol,
  usdPrice,
  selectedActivityPeriod,
}: {
  updateCollectionSorted: (collections: ICollectionBe[]) => void;
  collectionsSorted: ICollectionBe[];
  selectedCurrency: currencyList;
  defaultCurrencySymbol: string;
  usdPrice: {
    [key: string]: number;
  };
  selectedActivityPeriod: "1day" | "7day" | "30day";
}) => {
  const theme = useTheme();
  const { sort } = useSortableHeader<ICollectionBe>([c => c.name], "name");

  const handleHeaderClick = (value: ((item: ICollectionBe) => any)[]) => {
    const sortedData = sort(value, collectionsSorted);
    updateCollectionSorted(sortedData);
  };
  const calculateFloorPrice = (collection: ICollectionBe) => {
    const currencySymbol = collection.floorAsk.price
      ? collection.floorAsk.price.currency.symbol
      : defaultCurrencySymbol;
    if (!collection?.floorAsk?.price) return null;
    const _floorPrice = bigNumToFloat(collection.floorAsk.price.amount.raw);
    if (selectedCurrency.toLowerCase() === currencySymbol.toLowerCase()) {
      return _floorPrice;
    } else {
      const source = usdPrice[currencySymbol.toUpperCase()];
      const target = usdPrice[selectedCurrency.toUpperCase()];
      if (!source || !target) return null;
      return (_floorPrice * source) / target;
    }
  };

  return (
    <CollectionStatsHeaderContainer>
      <Hidden tabletDown></Hidden>
      <CollectionHeaderText
        color="secondary"
        align="start"
        onClick={() => handleHeaderClick([c => c.name])}
      >{`NFT Collections`}</CollectionHeaderText>

      <CollectionHeaderText
        color="secondary"
        onClick={() => handleHeaderClick([c => (c.floorAsk && c.floorAsk.price ? calculateFloorPrice(c) : 0)])}
      >{`Floor Price`}</CollectionHeaderText>

      <CollectionHeaderText
        color="secondary"
        onClick={() =>
          handleHeaderClick([
            c =>
              c.floorSale && c.floorSaleChange
                ? calculateChangePercent(c.floorSale[selectedActivityPeriod], c.floorSaleChange[selectedActivityPeriod])
                : 0,
          ])
        }
      >{`Floor Change`}</CollectionHeaderText>

      <Hidden tabletDown>
        <CollectionHeaderText color="secondary">{`Num. of Loans`}</CollectionHeaderText>
      </Hidden>
      <Hidden tabletDown>
        <CollectionHeaderText color="secondary">{`Loan Amount`}</CollectionHeaderText>
      </Hidden>
      <Hidden tabletDown>
        <Flex gap="5px" alignItems="center" justifyContent="flex-end">
          <CollectionHeaderText color="secondary">{`Avg. APR`}</CollectionHeaderText>
          <Tooltip>
            <HelpCircle height={16} width={16} color={theme.colors.secondary} />
            <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
              <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                <div>{`Buy Now price is an estimate based on a floor price NFT for a 3-month, 25% down payment loan.`}</div>
              </Text>
            </TooltipText>
          </Tooltip>
        </Flex>
      </Hidden>
    </CollectionStatsHeaderContainer>
  );
};

export const CollectionStatsRow: React.FC<{
  collection: {
    currencySymbol: string;
    index: number;
    address: string;
    name: string;
    floorPrice: number | null;
    floorChange: number | null;
    volume: number | null;
    volumeChange: number | null;
    items: string | null;
    imageUrl?: string;
    chainId: number;
  };
  selectedCurrency: currencyList;
  usdPrice: {
    [key: string]: number;
  };
}> = ({ collection, selectedCurrency, usdPrice }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(true);
  const floorPrice = useMemo(() => {
    if (collection.floorPrice === null) return null;
    if (selectedCurrency.toLowerCase() === collection.currencySymbol.toLowerCase()) {
      return collection.floorPrice;
    } else {
      const source = usdPrice[collection.currencySymbol.toUpperCase()];
      const target = usdPrice[selectedCurrency.toUpperCase()];
      if (!source || !target) return null;
      return (collection.floorPrice * source) / target;
    }
  }, [collection.floorPrice, collection.currencySymbol, selectedCurrency, usdPrice]);

  const localUrl = `/lend/${CHAIN_IDS_TO_NAMES[collection.chainId as SupportedChainId]}/${collection.address}`;
  return (
    <Row
      onClick={() => navigate(localUrl)}
      style={{
        cursor: "pointer",
      }}
    >
      <Hidden tabletDown>
        <CollectionRowText color="gray0" align="center">
          {collection.index + 1}
        </CollectionRowText>
      </Hidden>
      <Flex alignItems="center" gap="8px">
        {imageLoading && <SkeletonLine borderRadius="50%" w="24px" h="24px" />}
        <StyledImg
          src={collection.imageUrl ?? CollectioNoImage}
          alt={collection.name}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
          }}
        />
        <CollectionRowText color="secondary" textAlign="left">
          {collection.name}
        </CollectionRowText>
      </Flex>
      <CollectionRowText color="secondary">
        {floorPrice !== null ? `${floorPrice.toFixed(2)} ${selectedCurrency}` : "N/A"}
      </CollectionRowText>
      {collection.floorChange !== null ? (
        <Flex alignItems="center" justifyContent="flex-end" gap="3px">
          {collection.floorChange >= 0 ? <Increase color={theme.colors.green} /> : <Decrease />}
          <CollectionRowText color={collection.floorChange >= 0 ? "green" : "red"}>
            {Math.abs(collection.floorChange).toLocaleString("en-us", { maximumFractionDigits: 2 })}%
          </CollectionRowText>
        </Flex>
      ) : (
        <CollectionRowText color="secondary">N/A</CollectionRowText>
      )}
      <Hidden tabletDown>
        <CollectionRowText color="secondary">-</CollectionRowText>
      </Hidden>
      <Hidden tabletDown>
        <CollectionRowText color="secondary">-</CollectionRowText>
      </Hidden>
      <Hidden tabletDown>
        <CollectionRowText color="secondary">-</CollectionRowText>
      </Hidden>
    </Row>
  );
};

export const CollectionStatsRowLoading = () => {
  return (
    <Row>
      <Hidden tabletDown>
        <CollectionRowLoadingText />
      </Hidden>
      <Flex alignItems="center" gap="8px">
        <SkeletonLine borderRadius="50%" h="24px" w="24px" />
        <CollectionRowLoadingText />
      </Flex>

      <CollectionRowLoadingText />

      <CollectionRowLoadingText />

      <HiddenFlex tabletDown>
        <CollectionRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <CollectionRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <CollectionRowLoadingText />
      </HiddenFlex>
    </Row>
  );
};

const Row = styled.div`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: end;
  grid-template-columns: 0.2fr 1.8fr 0.4fr 0.8fr 0.8fr 0.8fr 0.8fr 0.2fr;
  padding: 18px 20px 18px 14px;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "3.5fr 2fr 2fr" }])};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 10px 14px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;

const HiddenFlex = styled(Hidden)`
  display: flex;
  justify-content: end;
`;

const CollectionStatsHeaderContainer = styled(Row)`
  padding: 14px 20px 12px 14px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 10px 12px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  position: sticky;
`;

const CollectionHeaderText = styled(Text)<{ align?: string }>`
  display: flex;
  justify-content: ${({ align }) => (align ? align : "end")};
  font-weight: 600;
  font-size: 16px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 14 },
    { [breakpoints.laptop]: 12 },
    { [breakpoints.mobile]: 12 },
  ])};
`;

const CollectionRowText = styled(Text)<{ align?: string }>`
  display: flex;
  justify-content: ${({ align }) => (align ? align : "end")};
  font-weight: 500;
  font-size: 14px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 14 }, { [breakpoints.tablet]: 12 }])};
`;

const CollectionRowLoadingText = styled(SkeletonLine)`
  justify-self: end;
  width: 70%;
  height: 20px;
  ${getStyleWithMediaQuery("height", "px", [
    { [breakpoints.desktop]: 17 },
    { [breakpoints.laptop]: 15 },
    { [breakpoints.tablet]: 13 },
  ])};
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
