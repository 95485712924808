import { useMemo } from "react";

import { Flex } from "@cyanco/components/theme";
import { Text } from "@cyanco/components/theme/v3";

import { mainPoolCollections } from "@/config";

import { useApeStakingUserAssets } from "../../../ApeCoinDataContext";
import { ISelectedNft } from "../../../types";
import { UserNftSelector } from "../UserNftSelector";

type IType = {
  selectedMainNft: ISelectedNft | null;
  onChangeSelectedMainNft: (nft: ISelectedNft | null) => void;
  selectedCollection: string;
  onChangeCollection: (c: string) => void;
};
export const UnstakingNftSelector: React.FC<IType> = ({
  selectedCollection,
  selectedMainNft,
  onChangeSelectedMainNft,
  onChangeCollection,
}) => {
  const { stakedAssets, stakedPositions, loading } = useApeStakingUserAssets();
  const mainPoolNfts = useMemo(() => {
    const positions = stakedPositions.map(position => ({
      address: position.metadata.collectionAddress,
      tokenId: position.tokenId,
      isCyanWallet: true,
      imageUrl: position.metadata.imageUrl,
      apeStaking: position.apeStaking,
    }));
    return [...stakedAssets, ...positions];
  }, [stakedPositions, stakedAssets]);

  const onChangeSelectedNft = (nft: ISelectedNft | null) => {
    if (!nft) return;
    if (selectedMainNft && selectedMainNft.address === nft.address && selectedMainNft.tokenId === nft.tokenId) {
      onChangeSelectedMainNft(null);
    } else onChangeSelectedMainNft(nft);
  };

  return (
    <Flex direction="column" gap="13px">
      <Flex direction="column" gap="12px">
        <Text size="xs" color="secondary" weight="600">
          {`Choose NFT to unstake with:`}
        </Text>
        <UserNftSelector
          collections={mainPoolCollections}
          nfts={mainPoolNfts}
          selectedCollection={selectedCollection}
          selectedNfts={selectedMainNft ? [selectedMainNft] : []}
          onChangeSelectedCollection={onChangeCollection}
          onChangeSelectedNft={onChangeSelectedNft}
          isLoading={loading}
          multipleSelect={false}
        />
      </Flex>
    </Flex>
  );
};
