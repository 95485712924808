import { getEnvOrThrow } from "../utils";
import { SupportedChainId } from "./chains";

export const getPaymentPlanFromChainId = (chainId: SupportedChainId): string => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return getEnvOrThrow("CONTRACT_PAYMENT_ADDRESS_MAINNET_V2");

    case SupportedChainId.SEPOLIA:
      return getEnvOrThrow("CONTRACT_PAYMENT_ADDRESS_SEPOLIA_V2");

    case SupportedChainId.POLYGON:
      return getEnvOrThrow("CONTRACT_PAYMENT_ADDRESS_POLYGON_V2");

    case SupportedChainId.BLAST:
      return getEnvOrThrow("CONTRACT_PAYMENT_ADDRESS_BLAST_V2");

    case SupportedChainId.BLAST_SEPOLIA:
      return getEnvOrThrow("CONTRACT_PAYMENT_ADDRESS_BLAST_SEPOLIA_V2");

    case SupportedChainId.CURTIS:
      return getEnvOrThrow("CONTRACT_PAYMENT_ADDRESS_CURTIS_V2");

    default:
      throw new Error("Not supported chain id.");
  }
};

export const getPeerPaymentPlanFromChainId = (chainId: SupportedChainId): string => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return getEnvOrThrow("PEER_TO_PEER_CONTRACT_ADDRESS_MAINNET");

    case SupportedChainId.SEPOLIA:
      return getEnvOrThrow("PEER_TO_PEER_CONTRACT_ADDRESS_SEPOLIA");

    case SupportedChainId.POLYGON:
      return getEnvOrThrow("PEER_TO_PEER_CONTRACT_ADDRESS_POLYGON");

    case SupportedChainId.BLAST:
      return getEnvOrThrow("PEER_TO_PEER_CONTRACT_ADDRESS_BLAST");

    case SupportedChainId.BLAST_SEPOLIA:
      return getEnvOrThrow("PEER_TO_PEER_CONTRACT_ADDRESS_BLAST_SEPOLIA");

    case SupportedChainId.CURTIS:
      return getEnvOrThrow("PEER_TO_PEER_CONTRACT_ADDRESS_CURTIS");

    default:
      throw new Error("Not supported chain id.");
  }
};

export const getPrivateSaleFromChainId = (chainId: SupportedChainId): string => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return getEnvOrThrow("PRIVATE_SALE_CONTRACT_ADDRESS_MAINNET");

    case SupportedChainId.SEPOLIA:
      return getEnvOrThrow("PRIVATE_SALE_CONTRACT_ADDRESS_SEPOLIA");

    case SupportedChainId.POLYGON:
      return getEnvOrThrow("PRIVATE_SALE_CONTRACT_ADDRESS_POLYGON");

    case SupportedChainId.BLAST:
      return getEnvOrThrow("PRIVATE_SALE_CONTRACT_ADDRESS_BLAST");

    case SupportedChainId.BLAST_SEPOLIA:
      return getEnvOrThrow("PRIVATE_SALE_CONTRACT_ADDRESS_BLAST_SEPOLIA");

    case SupportedChainId.CURTIS:
      return getEnvOrThrow("PRIVATE_SALE_CONTRACT_ADDRESS_CURTIS");

    default:
      throw new Error("Not supported chain id.");
  }
};

export const getCyanConduitFromChainId = (chainId: SupportedChainId): string => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return getEnvOrThrow("CYAN_CONDUIT_ADDRESS_MAINNET");

    case SupportedChainId.POLYGON:
      return getEnvOrThrow("CYAN_CONDUIT_ADDRESS_POLYGON");

    case SupportedChainId.SEPOLIA:
      return getEnvOrThrow("CYAN_CONDUIT_ADDRESS_SEPOLIA");

    case SupportedChainId.BLAST:
      return getEnvOrThrow("CYAN_CONDUIT_ADDRESS_BLAST");

    case SupportedChainId.BLAST_SEPOLIA:
      return getEnvOrThrow("CYAN_CONDUIT_ADDRESS_BLAST_SEPOLIA");

    case SupportedChainId.CURTIS:
      return getEnvOrThrow("CYAN_CONDUIT_ADDRESS_CURTIS");

    default:
      throw new Error("Not supported chain id.");
  }
};

export const getBatchReaderAddressFromChainId = (chainId: SupportedChainId): string => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return getEnvOrThrow("CYAN_BATCH_READER_ADDRESS_MAINNET");

    case SupportedChainId.POLYGON:
      return getEnvOrThrow("CYAN_BATCH_READER_ADDRESS_POLYGON");

    case SupportedChainId.SEPOLIA:
      return getEnvOrThrow("CYAN_BATCH_READER_ADDRESS_SEPOLIA");

    case SupportedChainId.BLAST:
      return getEnvOrThrow("CYAN_BATCH_READER_ADDRESS_BLAST");

    case SupportedChainId.BLAST_SEPOLIA:
      return getEnvOrThrow("CYAN_BATCH_READER_ADDRESS_BLAST_SEPOLIA");

    case SupportedChainId.CURTIS:
      return getEnvOrThrow("CYAN_BATCH_READER_ADDRESS_CURTIS");

    default:
      throw new Error("Not supported chain id.");
  }
};
