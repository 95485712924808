import { useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { breakpoints } from "@cyanco/components/theme/config";
import { Button } from "@cyanco/components/theme/v3";
import { useOnClickOutside } from "@cyanco/components/theme/v3/hooks/useOnClickOutside";
import { SettingsGear } from "@cyanco/components/theme/v3/icons";

export const Settings: React.FC = ({ children }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setIsOpen(false));
  return (
    <div style={{ position: "relative", height: "26px" }} ref={node}>
      <Button variant="ghost" onClick={() => setIsOpen(!isOpen)}>
        <SettingsGear color={isOpen ? theme.colors.secondary : theme.colors.gray0} height={24} width={24} />
      </Button>
      {isOpen && (
        <SettingsOptionWrapper>
          <SettingsOption>{children}</SettingsOption>
        </SettingsOptionWrapper>
      )}
    </div>
  );
};

const SettingsOptionWrapper = styled.div`
  position: absolute;
  z-index: 10;
  left: 8px;
  top: calc(100% + 5px);
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    left: -60px;
  }
`;

const SettingsOption = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  padding: 0.5rem;
`;
