import { Flex } from "@cyanco/components/theme/Flex";

import { P2PLendingSelectedViews, useLendingTabContext } from "../../AccountPageContext";
import { History } from "./History";
import { Offers } from "./Offers";
import { Plans } from "./Plans";
import { LendingViewSelectors } from "./Selectors";

export const LendingView = () => {
  const { selectedLendingView } = useLendingTabContext();
  return (
    <Flex direction="column">
      <LendingViewSelectors />
      <div
        style={{
          display: selectedLendingView === P2PLendingSelectedViews.current ? "block" : "none",
        }}
      >
        <Plans />
      </div>
      <div
        style={{
          display: selectedLendingView === P2PLendingSelectedViews.offers ? "block" : "none",
        }}
      >
        <Offers />
      </div>
      <div
        style={{
          display: selectedLendingView === P2PLendingSelectedViews.historical ? "block" : "none",
        }}
      >
        <History />
      </div>
    </Flex>
  );
};
