import { useMemo } from "react";
import { HelpCircle } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Text, Toggler, Tooltip, TooltipText } from "@cyanco/components/theme/v3";

import { BAKCAddress, BAYCAddress, CAPS_MAPPED_BY_ADDRESS, MAYCAddress } from "@/config";
import { bigNumToFloat, formatCompactNumber, numberWithCommas } from "@/utils";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";
import { ISelectedNft } from "../../../types";
import { ApeCoinStakingInput } from "../../common";

export const ApeCoinStakingWithPlan = ({
  selectedMainNfts: nfts,
  selectedCollection,
  rewardStakeToCyanVault,
  setRewardStakeToCyanVault,
}: {
  selectedMainNfts: ISelectedNft[];
  selectedCollection: string;
  rewardStakeToCyanVault: boolean;
  setRewardStakeToCyanVault: (a: boolean) => void;
}) => {
  const theme = useTheme();
  const { poolsWithBorrow } = useApeCoinStatsContext();
  const cap = useMemo(() => {
    if (nfts.length == 0) return CAPS_MAPPED_BY_ADDRESS[selectedCollection];
    return nfts ? CAPS_MAPPED_BY_ADDRESS[nfts[0].address] : CAPS_MAPPED_BY_ADDRESS[selectedCollection];
  }, [nfts]);
  const maxStakingAmount = useMemo(() => {
    if (nfts.length == 0) return 0;
    if (nfts[0].apeStaking?.stakedAmount) return cap * nfts.length;
    return cap * nfts.length - bigNumToFloat(nfts[0].apeStaking.stakedAmount || 0);
  }, [nfts, cap]);
  const estimatedApy = useMemo(() => {
    switch (selectedCollection.toLowerCase()) {
      case BAYCAddress:
        return formatCompactNumber(poolsWithBorrow.BAYC.apy);
      case MAYCAddress:
        return formatCompactNumber(poolsWithBorrow.MAYC.apy);
      case BAKCAddress:
        return formatCompactNumber(poolsWithBorrow.BAKC.apy);
    }
  }, [poolsWithBorrow, selectedCollection]);

  const alreadyStaked = useMemo(() => {
    if (nfts.length == 0) return 0;
    return nfts.reduce((acc, nft) => {
      return acc + (nft.apeStaking.stakedAmount ? bigNumToFloat(nft.apeStaking.stakedAmount) : 0);
    }, 0);
  }, [nfts]);

  return (
    <Flex direction="column" gap="1.2rem">
      <Flex direction="column" gap="0.3rem">
        <Flex justifyContent="space-between" p="0 2px">
          <Text color="gray0" size="xs" weight="600">
            {`Borrow ApeCoin from Vault`}
          </Text>
          <Text color="gray0" size="xs" weight="500">
            {`${`Max`}: ${numberWithCommas(maxStakingAmount)} APE`}
          </Text>
        </Flex>
        <ApeCoinStakingInput disabled={true} stakingAmount={maxStakingAmount.toString()} onInputChange={() => {}} />
      </Flex>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap="4px">
          <Text size="md" weight="600" color="secondary">
            {`Reward APE to Cyan Vault`}
          </Text>
          <Tooltip>
            <HelpCircle height={16} width={16} color={theme.colors.secondary} />
            <TooltipText showArrow position="top" top="-155px" right="-70px" style={{ width: "150px" }}>
              <Flex direction="column" gap="7px">
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  <div>{`Rewarded APE will be staked into the Cyan ApeCoin Staking Vault. If you wish to retain the voting rights for the APE you earn, please turn this option off.`}</div>
                </Text>
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  <div>{`By turning this option off, your rewarded APE will be staked into the Horizen Labs contract and you will retain voting rights.`}</div>
                </Text>
              </Flex>
            </TooltipText>
          </Tooltip>
        </Flex>
        <Toggler value={rewardStakeToCyanVault} onChange={setRewardStakeToCyanVault} size="sm" />
      </Flex>

      <StakedBox>
        <Flex direction="column" w="100%" gap="0.3rem">
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Already Staked`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {numberWithCommas(alreadyStaked)} APE
            </Text>
          </Flex>
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Estimated APY`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {estimatedApy}%
            </Text>
          </Flex>
        </Flex>
      </StakedBox>
    </Flex>
  );
};

const StakedBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 1rem 0.8rem;
`;
