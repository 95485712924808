import { useContext, useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Box, Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { Input, SlashButton, SlashText, Text } from "@cyanco/components/theme/v3";
import { Grid, List } from "@cyanco/components/theme/v3/icons";

import { WalletViewStyle } from "@/components/Account/AccountPageContext";
import { AppContext } from "@/components/AppContextProvider";
import { LoanView, useLoanPageSelectors } from "@/components/Loans/LoanPageProvider";
import { LendViewSelectorWrapper, LendViewType } from "@/components/P2P/collection/Selectors";
import { useDebounce } from "@/hooks/useDebounce";
import { Experiments } from "@/utils/experimentList";

export const Selectors: React.FC = () => {
  const theme = useTheme();
  const searchInput = useRef<HTMLInputElement>(null);
  const [tokenInputValue, setTokenInputValue] = useState("");
  const { experiment } = useContext(AppContext);
  const { walletViewStyle, setSelectedNftSearch, setWalletViewStyle, selectedLoanView, setSelectedLoanView } =
    useLoanPageSelectors();
  useDebounce(() => setSelectedNftSearch(tokenInputValue), [tokenInputValue], 1000);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "/") {
        searchInput.current?.focus();
      }
    };
    document.addEventListener("keyup", handleKeyDown);
    return () => document.removeEventListener("keyup", handleKeyDown);
  }, [searchInput]);
  return (
    <BnplSelectorWrapper>
      <BnplSelectors isP2PEnabled={!!(experiment.result && experiment.result[Experiments.P2P])}>
        {experiment.result && experiment.result[Experiments.P2P] && (
          <LendViewSelectorWrapper>
            <LendViewType
              isActive={LoanView.assets === selectedLoanView}
              onClick={() => setSelectedLoanView(LoanView.assets)}
            >
              <TabText
                size="lg"
                weight="500"
                color={LoanView.assets === selectedLoanView ? "secondary" : "gray0"}
                textWrap={false}
              >
                {`Assets`}
              </TabText>
            </LendViewType>
            <LendViewType
              isActive={LoanView.loanOffers === selectedLoanView}
              onClick={() => setSelectedLoanView(LoanView.loanOffers)}
            >
              <TabText
                size="lg"
                weight="500"
                color={LoanView.loanOffers === selectedLoanView ? "secondary" : "gray0"}
                textWrap={false}
              >
                {`Loan Offers`}
              </TabText>
            </LendViewType>
          </LendViewSelectorWrapper>
        )}

        <Box h="46px">
          <Input
            placeholder={`Search NFTs`}
            fontSize="lg"
            fontWeight="400"
            value={tokenInputValue}
            onChange={e => setTokenInputValue(e.target.value)}
            p="0.45rem 0.45rem 0.45rem 1rem"
            inputRef={searchInput}
          >
            <SlashButton>
              <SlashText size="lg" weight="500">
                /
              </SlashText>
            </SlashButton>
          </Input>
        </Box>
        <CartWrapper direction="row" gap="1rem" w="100%" justifyContent="flex-end">
          {selectedLoanView !== LoanView.loanOffers && (
            <ViewSelectorWrapper>
              <ViewType
                isActive={WalletViewStyle.list === walletViewStyle}
                onClick={() => setWalletViewStyle(WalletViewStyle.list)}
              >
                <List color={WalletViewStyle.list === walletViewStyle ? theme.colors.white : theme.colors.gray80} />
              </ViewType>
              <ViewType
                isActive={WalletViewStyle.grid === walletViewStyle}
                onClick={() => setWalletViewStyle(WalletViewStyle.grid)}
              >
                <Grid color={WalletViewStyle.grid === walletViewStyle ? theme.colors.white : theme.colors.gray80} />
              </ViewType>
            </ViewSelectorWrapper>
          )}
        </CartWrapper>
      </BnplSelectors>
    </BnplSelectorWrapper>
  );
};

const BnplSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  top: 75px;
  z-index: 7;
  background: ${({ theme }) => theme.backgroundColor};
  padding-bottom: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    top: 65px;
    padding: 0;
    background: transparent;
    gap: 0.5rem;
  }
`;

const BnplSelectors = styled.div<{ isP2PEnabled: boolean }>`
  display: grid;
  grid-template-columns: ${({ isP2PEnabled }) => (isP2PEnabled ? "0.7fr 1.4fr 2fr" : "1.4fr 2fr")};
  width: 100%;
  gap: 1rem;
  background: ${({ theme }) => theme.backgroundColor};
  padding-top: 1.5rem;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    grid-template-columns: ${({ isP2PEnabled }) => (isP2PEnabled ? "1.2fr 1fr" : "1.2fr 1fr")};
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    padding-bottom: 5px;
  }
`;

const ViewSelectorWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.gray20};
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 3px;
  flex-direction: row;
`;
const ViewType = styled.div<{ isActive: boolean }>`
  width: 46px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: ${props => (props.isActive ? props.theme.colors.gray20 : "transparent")};
  border-radius: 7px;
  height: 100%;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    height: 25px;
  }
  transition: 0.2s;
  svg,
  path,
  rect {
    transition: 0.2s;
  }
  :hover {
    color: ${({ theme, isActive }) => !isActive && theme.colors.secondary};
    svg,
    path,
    rect {
      transition: 0.2s;
      stroke: ${({ theme, isActive }) => !isActive && theme.colors.secondary};
    }
  }
`;

const CartWrapper = styled(Flex)`
  justify-content: flex-end;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    order: -1;
  }
`;

const TabText = styled(Text)`
  transition: color 0.2s ease-in-out;
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
