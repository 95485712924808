import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NftCardLoading } from "@cyanco/components/theme/v3";

import { useAppContext } from "@/components/AppContextProvider";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { getRarityRank } from "@/utils";

import { useApeStakingUserAssets } from "../../ApeCoinDataContext";
import { RewardsViewType, useApeCoinContext } from "../../ApeCoinPageContext";
import { IActionType, IApeCoinSource, ISelectedNft } from "../../types";
import { StakableNftCard, StakablePositionCard } from "./StakableNftCard";
import { StakedNftCard, StakedPositionCard } from "./StakedNftCard";
import { UserRewards } from "./UserRewards";

export const GridView = () => {
  const { selectedRewardsViewType } = useApeCoinContext();
  const { showPlanModal } = useApePlanCreation();
  const { stakedAssets, stakedPositions, loading, stakableAssets, stakablePositions } = useApeStakingUserAssets();
  const { collections } = useAppContext();
  const openUnstakingModal = (nft: ISelectedNft) => {
    showPlanModal({
      action: IActionType.unstake,
      apeCoinSource: nft.apeStaking.plan ? IApeCoinSource.borrow : IApeCoinSource.owned,
      selectedMainNfts: [nft],
    });
  };
  const openStakingModal = (nft: ISelectedNft) => {
    showPlanModal({
      action: IActionType.stake,
      apeCoinSource: IApeCoinSource.borrow,
      selectedMainNfts: [nft],
    });
  };
  return (
    <Wrapper>
      <Container>
        <UserRewards />
        {loading ? (
          <>
            {Array.from(Array(4).keys()).map(loader => (
              <NftCardLoading key={loader} actionText={`Loan`} />
            ))}
          </>
        ) : selectedRewardsViewType === RewardsViewType.staked ? (
          <>
            {[...stakedAssets, ...stakedPositions].length === 0 && (
              <div style={{ visibility: "hidden" }}>
                <NftCardLoading actionText={`Loan`} />
              </div>
            )}
            {stakedPositions.map(position => (
              <StakedPositionCard
                key={`${position.metadata.collectionAddress}:${position.tokenId}:${position.isCyanWallet}`}
                position={{
                  ...position,
                  rarity: getRarityRank(position.metadata.rarityRank, position.metadata.collectionAddress, collections),
                }}
                onClick={openUnstakingModal}
              />
            ))}
            {stakedAssets.map(asset => (
              <StakedNftCard
                key={`${asset.address}:${asset.tokenId}:${asset.isCyanWallet}`}
                nft={{
                  ...asset,
                  rarity: getRarityRank(asset.rarityRank, asset.address, collections),
                }}
                onClick={openUnstakingModal}
              />
            ))}
          </>
        ) : (
          <>
            {[...stakablePositions, ...stakableAssets].length === 0 && (
              <div style={{ visibility: "hidden" }}>
                <NftCardLoading actionText={`Loan`} />
              </div>
            )}
            {stakablePositions.map(position => (
              <StakablePositionCard
                key={`${position.metadata.collectionAddress}:${position.tokenId}:${position.isCyanWallet}`}
                position={{
                  ...position,
                  rarity: getRarityRank(position.metadata.rarityRank, position.metadata.collectionAddress, collections),
                }}
                onClick={openStakingModal}
              />
            ))}
            {stakableAssets.map(asset => (
              <StakableNftCard
                key={`${asset.address}:${asset.tokenId}:${asset.isCyanWallet}`}
                nft={{
                  ...asset,
                  rarity: getRarityRank(asset.rarityRank, asset.address, collections),
                }}
                onClick={openStakingModal}
              />
            ))}
          </>
        )}
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  ${getStyleWithMediaQuery("grid-column-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-row-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.desktop]: "repeat(auto-fill, minmax(220px, 1fr))" },
    { [breakpoints.tablet]: "repeat(auto-fill, minmax(200px, 1fr))" },
    { [breakpoints.mobile]: "repeat(auto-fill, minmax(160px, 1fr))" },
  ])};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 30px;
`;
