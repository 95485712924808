import { memo, useMemo } from "react";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, SortingArrows } from "@cyanco/components/theme/v3";
import { Etherscan } from "@cyanco/components/theme/v3/images";

import { useGetUserTime } from "@/hooks/useGetUserTime";
import { useSortableHeader } from "@/hooks/useSortableHeader";
import {
  bigNumToFixedStr,
  bigNumToFloat,
  displayInUSD,
  getFormattedTimeForUserHistory,
  jumpToLink,
  numberWithCommas,
  shortenAddress,
  shortenName,
} from "@/utils";

import { isMobile } from "../../../../utils/userAgent";
import { IVaultTokenTransaction } from "../../../Vault/types";
import { VaultTokenTransactionTypes, useVaultTokensTabContext } from "../../AccountPageContext";
import { HeaderItemWrapper } from "../PositionView/PositionRow";
import { WalletContainer } from "../WalletView/NftRow";
import { WalletSelector } from "../WalletView/Selectors";
import { RowText } from "./VaultPositionRow";

export const VaultTransactionListHeader = ({
  updateSortedVaultTransactions,
  sortedVaultTransactions,
}: {
  updateSortedVaultTransactions: (transactions: IVaultTokenTransaction[]) => void;
  sortedVaultTransactions: IVaultTokenTransaction[];
}) => {
  const { setSelectedVaultWalletType, selectedVaultWalletType } = useVaultTokensTabContext();
  const { sort, sortDirectionDesc, sortName } = useSortableHeader<IVaultTokenTransaction>(
    [c => (c.vault ? bigNumToFixedStr(c.amount, 2, c.vault.decimals) : 0)],
    "amount",
  );

  const handleHeaderClick = (value: ((item: IVaultTokenTransaction) => any)[], sortName: string) => {
    const sortedData = sort(value, sortedVaultTransactions, sortName);
    updateSortedVaultTransactions(sortedData);
  };
  return (
    <HeaderContainer>
      <HeaderItemWrapper>
        <Flex gap="5px" onClick={() => handleHeaderClick([c => (c.vault ? c.vault.symbol : "")], "token")}>
          <RowText color="secondary" weight="600">{`Token`}</RowText>
          <SortingArrows
            sortDirectionDesc={sortDirectionDesc}
            sortName={sortName}
            currentSortName="token"
          ></SortingArrows>
        </Flex>
      </HeaderItemWrapper>
      <Hidden tabletDown>
        <WalletSelector
          selectedWalletType={selectedVaultWalletType}
          setSelectedWalletType={setSelectedVaultWalletType}
        />
      </Hidden>
      <Flex gap="5px" onClick={() => handleHeaderClick([c => c.createdAt], "createdAt")}>
        <RowText color="secondary" weight="600">{`Transaction Date`}</RowText>
        <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="createdAt" />
      </Flex>
      <Hidden tabletDown>
        <HeaderItemWrapper>
          <Flex
            gap="5px"
            onClick={() =>
              handleHeaderClick(
                [
                  c =>
                    c.vault
                      ? bigNumToFloat(c.amountEth, c.vault.decimals) / bigNumToFloat(c.amount, c.vault.decimals)
                      : 0,
                ],
                "price",
              )
            }
          >
            <RowText color="secondary" weight="600">{`Price`}</RowText>
            <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="price" />
          </Flex>
        </HeaderItemWrapper>
      </Hidden>
      <Flex
        gap="5px"
        onClick={() =>
          handleHeaderClick([c => (c.vault ? bigNumToFixedStr(c.amount, 2, c.vault.decimals) : 0)], "amount")
        }
      >
        <RowText color="secondary" weight="600">{`Amount`}</RowText>
        <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="amount" />
      </Flex>
      <Hidden tabletDown>
        <HeaderItemWrapper>
          <Flex
            gap="5px"
            onClick={() =>
              handleHeaderClick(
                [c => (c.vault ? c.ethUsdPrice * bigNumToFloat(c.amountEth, c.vault.decimals) : 0)],
                "deposited amount",
              )
            }
          >
            <RowText color="secondary" weight="600">{`Deposited Amount`}</RowText>
            <SortingArrows
              sortDirectionDesc={sortDirectionDesc}
              sortName={sortName}
              currentSortName="deposited amount"
            />
          </Flex>
        </HeaderItemWrapper>
      </Hidden>
      <Hidden tabletDown>
        <HeaderItemWrapper>
          <RowText color="secondary" weight="600">{`Action`}</RowText>
        </HeaderItemWrapper>
      </Hidden>
      <RowText color="secondary" weight="600">{`Explorer`}</RowText>
    </HeaderContainer>
  );
};

const TransactionDate = ({ date }: { date: Date }) => {
  const { getUserPreferredTime, showLocalTime } = useGetUserTime();
  const formattedDate = getUserPreferredTime(date);
  return (
    <Flex direction="column" gap="0.2rem">
      {isMobile ? (
        <RowText color="secondary" weight="500">{`${formattedDate.format("MMM")} ${formattedDate.format(
          "DD",
        )}, ${formattedDate.format("YYYY")}`}</RowText>
      ) : (
        <RowText color="secondary" weight="500">{`${formattedDate.format("MMMM")} ${formattedDate.format(
          "DD",
        )}, ${formattedDate.format("YYYY")}`}</RowText>
      )}

      <RowText color="gray0" sub>
        {getFormattedTimeForUserHistory(formattedDate, showLocalTime)}
      </RowText>
    </Flex>
  );
};

export const VaultTransactionRow = memo(
  ({
    transaction,
    txnUrl,
    account,
    isLast,
  }: {
    transaction: IVaultTokenTransaction;
    txnUrl: string;
    isLast?: boolean;
    account: string;
  }) => {
    const theme = useTheme();
    const tokenPrice = useMemo(() => {
      return (
        bigNumToFloat(transaction.amountEth, transaction.vault.decimals) /
        bigNumToFloat(transaction.amount, transaction.vault.decimals)
      );
    }, [transaction]);
    return (
      <Row isLast={isLast}>
        <Flex alignItems="center" gap="20px">
          <StyledImg style={{ background: transaction.vault.colorCode }} />
          <Flex direction="column">
            <RowText color={"secondary"} weight="500">
              {transaction.vault.symbol}
            </RowText>
            <RowText sub color={"gray0"}>
              {shortenName(transaction.vault.name)}
            </RowText>
          </Flex>
        </Flex>
        <Hidden tabletDown>
          {transaction.isCyanWallet ? (
            <WalletContainer>
              <div
                style={{
                  background: theme.colors.cyan,
                  width: "18px",
                  height: "18px",
                  minWidth: "18px",
                  minHeight: "18px",
                  borderRadius: "50%",
                }}
              />
              <RowText color="secondary" weight="500">
                Cyan Wallet
              </RowText>
            </WalletContainer>
          ) : (
            <WalletContainer>
              <Jazzicon seed={jsNumberForAddress(account)} diameter={18} />
              <RowText color="secondary" weight="500">
                {shortenAddress(account)}
              </RowText>
            </WalletContainer>
          )}
        </Hidden>
        <TransactionDate date={transaction.createdAt} />
        <Hidden tabletDown>
          <Flex direction="column">
            <RowText color={"secondary"} weight="500">
              {numberWithCommas(tokenPrice, 2)} {transaction.vault.currency}
            </RowText>
            <RowText sub color={"gray0"} weight="500">
              {displayInUSD(transaction.ethUsdPrice * tokenPrice)}
            </RowText>
          </Flex>
        </Hidden>
        <RowText color={"secondary"} weight="500">
          {numberWithCommas(bigNumToFixedStr(transaction.amount, 2, transaction.vault.decimals), 2)}{" "}
          {transaction.vault.symbol}
        </RowText>
        <Hidden tabletDown>
          <Flex direction="column">
            <RowText color={"secondary"} weight="500">
              {numberWithCommas(bigNumToFixedStr(transaction.amountEth, 2, transaction.vault.decimals), 2)}{" "}
              {transaction.vault.currency}
            </RowText>
            <RowText sub color={"gray0"} weight="500">
              {" "}
              {displayInUSD(transaction.ethUsdPrice * bigNumToFloat(transaction.amountEth, transaction.vault.decimals))}
            </RowText>
          </Flex>
        </Hidden>
        <Hidden tabletDown>
          <RowText color={"cyan"} weight="500">
            {" "}
            {transaction.type === VaultTokenTransactionTypes.Stake && `Staked`}
            {transaction.type === VaultTokenTransactionTypes.Unstake && `Unstaked`}
          </RowText>
        </Hidden>
        <ImgButton
          onClick={e => {
            e.stopPropagation();
            jumpToLink(txnUrl);
          }}
        >
          <img src={Etherscan} height={18} width={18} />
        </ImgButton>
      </Row>
    );
  },
);

VaultTransactionRow.displayName = "VaultTransactionRow";

const Row = styled.div<{ disabled?: boolean; isLast?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  background-color: transparent;
  grid-template-columns: 2.5fr 1.5fr 1.7fr 1.3fr 1.3fr 1.5fr 0.8fr 0.7fr;
  padding: 13px 15px 13px 15px;
  cursor: pointer;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2.5fr 1.5fr 1fr 0.8fr" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
  ${({ isLast }) =>
    isLast &&
    ` border-bottom-left-radius: 20px;
     border-bottom-right-radius: 20px;`}
`;
const HeaderContainer = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${({ theme }) => theme.colors.primary};
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    padding: 7px 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const StyledImg = styled.div`
width: 36px;
height: 36px;
${getStyleWithMediaQuery("min-width", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("min-height", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("max-height", "px", [{ [breakpoints.mobile]: 24 }])}
border-radius: 50%;
} `;

const ImgButton = styled.div`
  cursor: pointer;
`;
