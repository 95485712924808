import { ContractTransaction } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { useEffect, useMemo, useState } from "react";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { notifyApeCoinActivity } from "@/apis/notification";
import { ApeCoinBalance, CloseButton } from "@/components/ApeCoinStaking/CommonComponents";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apePaymentPlanContract, apeStakingContract } from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IInitiateApeCoinUnstake } from "@/hooks/useApePlanCreation.types";
import { bigNumToFixedStr, getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";
import { IActionType } from "../../../types";

export const UnstakingApeCoin = (params: IInitiateApeCoinUnstake) => {
  const { planId, apeCoinBalance, unstakingAmount, releaseWallet, sourceWallet } = params;
  const { setFireConfetti } = useAppContext();
  const cyanWallet = useCyanWallet();
  const { transactions, addTransaction } = useTransactionContext();
  const { updateUserBalance } = useApeCoinStatsContext();
  const { showApeCoinPlanModal } = useApePlanCreation();
  const { chainId, signer, account } = useWeb3React();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<UnstakingSteps>(UnstakingSteps.Unstaking);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        updateUserBalance();
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setFireConfetti(true);
        setSelectedStep(UnstakingSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const completePlan = async () => {
    if (!planId) return;
    if (!signer) throw new Error("Something went wrong");
    const contract = f.CyanApeCoinPlanV1Factory.connect(apePaymentPlanContract, signer);
    const tx = await contract.completeApeCoinPlan(planId);
    addTransaction({
      hash: tx.hash,
      type: "ape-coin-plan-complete",
      data: {
        planId: planId,
      },
    });
    setActiveTx(tx.hash);
  };
  const unstake = async () => {
    if (!signer) {
      throw new Error(`No provider found!`);
    }
    if (!unstakingAmount) return;
    let tx: ContractTransaction;
    const apeCoinStakingContract = f.ApeCoinStakingFactory.connect(apeStakingContract, signer);
    if (cyanWallet && sourceWallet.toLowerCase() === cyanWallet.walletAddress.toLowerCase()) {
      const encodedFn = apeCoinStakingContract.interface.encodeFunctionData("withdrawApeCoin", [
        parseEther(unstakingAmount.toString()),
        releaseWallet,
      ]);
      const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [apeStakingContract, 0, encodedFn]);
      tx = await signer.sendTransaction({ to: cyanWallet.walletAddress, data: encodedCyanFnData });
    } else {
      tx = await apeCoinStakingContract.withdrawApeCoin(parseEther(unstakingAmount.toString()), releaseWallet);
    }
    addTransaction({ hash: tx.hash, type: "ape-unstake" });
    await tx.wait();
    setActiveTx(tx.hash);
    notifyApeCoinActivity({
      amount: `${unstakingAmount} + ${bigNumToFixedStr(apeCoinBalance.earnedAmount || 0)}`,
      user: releaseWallet,
      action: "Unstaked with ERC20",
      token: "erc20",
    });
  };

  useEffect(() => {
    const onStepChange = async (step: UnstakingSteps) => {
      try {
        switch (step) {
          case UnstakingSteps.Unstaking: {
            if (planId && sourceWallet.toLowerCase() === (cyanWallet?.walletAddress?.toLowerCase() ?? "")) {
              await completePlan();
            } else {
              await unstake();
            }
            return;
          }
        }
      } catch (err: any) {
        openRepaymentModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    showApeCoinPlanModal({
      action: IActionType.stake,
      err: mappedError,
    });
  };
  const stepMarkFiltered = useMemo(() => {
    return UnstakingStepMarks.map(item => {
      if (item.value === UnstakingSteps.Unstaking && txnFinal !== null) {
        return {
          ...item,
          description: `View on Etherscan`,
        };
      }
      return item;
    });
  }, [txnFinal]);
  return (
    <Flex gap="1rem" direction="column">
      <ApeCoinBalance stakedAmount={apeCoinBalance.stakedAmount} earnedAmount={apeCoinBalance.earnedAmount} />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === UnstakingSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum UnstakingSteps {
  Unstaking = 1,
  Done = 2,
}

const UnstakingStepMarks = [
  {
    value: UnstakingSteps.Unstaking,
    title: `Unstaking APE Coin`,
    description: `A small amount of gas is required to unstake $APE`,
  },
];
