import { BigNumber } from "ethers";
import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme/Box";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Card, SkeletonLine, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";

import { IPawn } from "../Account/pawn.types";
import { IBNPL } from "../Bnpl/bnpl.types";
import { PlanMetadata } from "./PlanMetadata";

export const PlanPaymentLoading: React.FC<{
  planType: "pawn" | "bnpl";
  plan: IBNPL | IPawn;
  totalLoanAmount: BigNumber;
  totalLeftAmount: BigNumber;
  bnplOriginalPrice?: string;
}> = ({ planType, plan, bnplOriginalPrice, totalLeftAmount, totalLoanAmount }) => {
  const theme = useTheme();

  return (
    <Flex gap="12px" direction="column">
      <PlanMetadata
        plans={[
          {
            imageUrl: plan.metadata.imageUrl,
            collectionName: plan.metadata.collection.name,
            tokenId: plan.tokenId,
            totalAmount: totalLoanAmount,
            leftAmount: totalLeftAmount,
            currency: plan.currency,
            address: plan.metadata.collectionAddress,
            purchasedPrice: bnplOriginalPrice,
            isBnpl: planType === "bnpl",
          },
        ]}
      />
      <Card p="8px">
        <Flex direction="column" gap="5px">
          <Text size="sm" weight="600" color="secondary">
            {`Payment options`}
          </Text>
          <SkeletonLineMobile w="100%" h="31px" />
        </Flex>
      </Card>
      <Flex direction="column">
        <SkeletonLineMobile w="100%" h={planType === "bnpl" ? "125px" : "102.75px"} borderRadius="10px" />
      </Flex>
      <Card p="8px">
        <Flex direction="column" gap="5px">
          <Flex alignItems="center" gap="4px">
            <Text size="sm" weight="600" color="secondary">
              {`Auto-repayments`}
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.secondary} />
              <TooltipText showArrow position="top" top="-75px" right="-70px" style={{ width: "150px" }}>
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  <div>{`You can provide Cyan permission to automatically withdraw from your wallet every month.`}</div>
                </Text>
              </TooltipText>
            </Tooltip>
          </Flex>
          <SkeletonLineMobile w="100%" h="25px" />
        </Flex>
      </Card>
      <StyledCard p={"15px"}>
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4.3px" w="100%">
            <SkeletonLine w="180px" h="16px" />
            <SkeletonLine w="100px" h="22px" />
          </Flex>
          <Box w="120px">
            <Button disabled>{`Start`}</Button>
          </Box>
        </Flex>
      </StyledCard>
    </Flex>
  );
};

const StyledCard = styled(Card)`
  :hover {
    border-color: ${({ theme }) => theme.colors.gray20} !important;
  }
`;

const SkeletonLineMobile = styled(SkeletonLine)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`;
