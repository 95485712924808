import orderBy from "lodash/orderBy";
import { useState } from "react";

export const useSortableHeader = <T>(
  initialSortKey: ((item: T) => any)[],
  initialSortName: string,
  initialSortOrder: "asc" | "desc" = "asc",
) => {
  const [selectedSort, setSelectedSort] = useState<((item: T) => any)[]>(initialSortKey);
  const [sortName, setSortName] = useState<string>(initialSortName);
  const [sortDirectionDesc, setSortDirectionDesc] = useState<boolean>(initialSortOrder === "asc");

  const isSameSortKey = (values: ((item: T) => any)[]) => {
    if (values.length !== selectedSort.length) return false;
    return values.every((fn, index) => {
      return fn({} as T) === selectedSort[index]({} as T);
    });
  };

  const sort = (values: ((item: T) => any)[], data: T[], sortName?: string) => {
    const newSortDirectionDesc = isSameSortKey(values) ? !sortDirectionDesc : false;
    const sortedData = orderBy(data, values, newSortDirectionDesc ? "desc" : "asc");
    setSortName(sortName ?? "");
    setSortDirectionDesc(newSortDirectionDesc);
    setSelectedSort(values);

    return sortedData;
  };

  return { selectedSort, sortDirectionDesc, sort, sortName, setSortName };
};
