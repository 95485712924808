import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Text, Title } from "@cyanco/components/theme/v3";

import { Collections } from "../Bnpl";
import { CardsSection } from "./CardsSection";
import { FAQ } from "./FAQ";
import { MainTitle } from "./MainTitle";
import { Partners } from "./Partners";
import { TestDriveSection } from "./TestdriveSection";

export const HomeTopPage = () => {
  return (
    <Flex direction="column">
      <Collections />
      <Container direction="column" alignItems="center">
        <MainTitle />
        <Flex direction="column">
          <CardsSection />
          <TestDriveSection />
        </Flex>
        <Partners />
        <FAQ />
        {/* TEMP HIDING
        <Testimonials /> */}
      </Container>
    </Flex>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1240px) / 2);
  margin-top: 0px;
  padding: 0 0 3rem 0;
  gap: 100px;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.laptopM]: 5 }, { [breakpoints.tablet]: 3 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 50px" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "1rem 0.5rem 0rem 0.5rem" }])}
`;

export const HomeTitleWebkit = styled(Title)`
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: ${({ theme }) =>
    theme.theme === "light"
      ? "linear-gradient(180deg, #0FF 0%, rgba(24, 134, 134, 0.61) 100%)"
      : "linear-gradient(180deg, #FFF 0%, rgba(0, 255, 255, 0.61) 100%)"};
`;

export const TestDriveTitle = styled(Title)`
  background: linear-gradient(180deg, #fff 0%, rgba(0, 255, 255, 0.61) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ButtonText = styled(Text)`
  font-size: 20px;
  color: black;
  font-weight: 600;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  letter-spacing: -0.6px;
`;
