import { parseUnits } from "ethers/lib/utils";
import { useEffect, useMemo, useState } from "react";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex, Stepper, useModal } from "@cyanco/components/theme";
import { factories as f } from "@cyanco/contract";

import { createPrivateSale } from "@/apis/private-sale";
import { usePrivateSales } from "@/components/Account/AccountDataContext";
import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getCyanConduitFromChainId, getPrivateSaleFromChainId } from "@/constants/contracts";
import { IInititatePrivateSale, signPrivateSale } from "@/hooks/usePrivateSaleCreation";
import { INftType } from "@/types";
import { mapAndLogError } from "@/utils/error";

import { CloseButton, PrivateSaleLoading } from "../CommonComponents";
import { PrivateSaleModal } from "../PrivateSaleModal";

export const PrivateSaleStepper = ({
  price: _price,
  nft,
  currencyAddress,
  collectionSignature,
  currency,
  buyer,
  expiryAt,
}: IInititatePrivateSale) => {
  const { chainId, account, signer } = useWeb3React();
  const { setModalContent } = useModal();
  const { cyanWallet } = useCyanWalletContext();
  const [selectedStep, setSelectedStep] = useState<PrivateSaleSteps>(PrivateSaleSteps.TokenApproval);
  const { setFireConfetti } = useAppContext();
  const { fetchPrivateSales } = usePrivateSales();
  const price = parseUnits(_price, currency.decimal);

  const approveTokenToPlan = async () => {
    if (!account || !signer || !chainId || (nft.isCyanWallet && nft.tokenType !== INftType.CryptoPunks)) return;
    const cyanConduit = getCyanConduitFromChainId(chainId);
    if (nft.tokenType == INftType.ERC721) {
      const contract = f.SampleFactory.connect(nft.address, signer);
      const isApprovedForAll = await contract.isApprovedForAll(account, cyanConduit);
      if (isApprovedForAll) return;
      const approvedTo = await contract.getApproved(nft.tokenId);
      if (approvedTo.toLowerCase() != cyanConduit.toLowerCase()) {
        await contract.setApprovalForAll(cyanConduit, true).then(tx => tx.wait());
      }
    } else if (nft.tokenType === INftType.CryptoPunks) {
      if (nft.isCyanWallet) {
        if (!cyanWallet) {
          throw new Error("Cyan wallet not found");
        }
        const iCryptoPunks = f.SampleCryptoPunksFactory.createInterface();
        const encodedFnData = iCryptoPunks.encodeFunctionData("transferPunk", [account, nft.tokenId]);
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [nft.address, "0", encodedFnData]);
        const tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
        await tx.wait();
      }
      const contract = f.SampleCryptoPunksFactory.connect(nft.address, signer);
      const [ownerAddress, { isForSale, onlySellTo, minValue }] = await Promise.all([
        contract.punkIndexToAddress(nft.tokenId),
        contract.punksOfferedForSale(nft.tokenId),
      ]);
      const privateSaleAddress = getPrivateSaleFromChainId(chainId).toLowerCase();
      if (
        ownerAddress === account &&
        (!isForSale || onlySellTo.toLowerCase() !== privateSaleAddress || !minValue.eq(0))
      ) {
        const tx = await contract.offerPunkForSaleToAddress(nft.tokenId, 0, privateSaleAddress);
        await tx.wait();
      }
    } else {
      const contract = f.SampleERC1155TokenFactory.connect(nft.address, signer);
      const isApprovedForAll = await contract.isApprovedForAll(account, cyanConduit);
      if (!isApprovedForAll) {
        await contract.setApprovalForAll(cyanConduit, true).then(tx => tx.wait());
      }
    }
  };

  const create = async () => {
    if (!account || !signer || !chainId) return;

    const signedDate = Math.floor(Date.now() / 1000);
    const signature = await signPrivateSale(signer, {
      chainId,
      collectionSignature: collectionSignature,
      collectionAddress: nft.address,
      currencyAddress,
      tokenAmount: nft.tokenType == INftType.ERC1155 ? 1 : 0,
      tokenId: nft.tokenId,
      buyerAddress: buyer,
      sellerAddress: account,
      signedDate,
      expiryDate: expiryAt,
      price: price.toString(),
      tokenType: nft.tokenType || 1,
    });

    await createPrivateSale({
      wallet: account,
      signature,
      chainId,
      item: {
        collectionAddress: nft.address,
        tokenId: nft.tokenId,
        price: price.toString(),
        signedDate,
        expiryDate: expiryAt,
        buyerAddress: buyer,
        currencyAddress: currencyAddress,
        tokenAmount: nft.tokenType == INftType.ERC1155 ? 1 : 0,
        tokenType: nft.tokenType || 1,
      },
    });
    setFireConfetti(true);
    fetchPrivateSales();
  };
  useEffect(() => {
    const onStepChange = async (step: PrivateSaleSteps) => {
      if (!chainId || !account || !signer) return;
      try {
        switch (step) {
          case PrivateSaleSteps.TokenApproval: {
            await approveTokenToPlan();
            setSelectedStep(PrivateSaleSteps.Create);
            return;
          }
          case PrivateSaleSteps.Create: {
            await create();
            setSelectedStep(PrivateSaleSteps.Done);
            return;
          }
        }
      } catch (err: any) {
        openPrivateSaleModal(err);
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);

  const stepMarkFiltered = useMemo(() => {
    return PrivateSaleStepMarks.map(item => {
      if (item.value === PrivateSaleSteps.Create) {
        return {
          ...item,
        };
      }
      return item;
    });
  }, []);

  const openPrivateSaleModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    setModalContent({
      title: `Private Sale`,
      content: <PrivateSaleModal nft={nft} err={mappedError} />,
    });
  };

  return (
    <Flex gap="1rem" direction="column">
      <PrivateSaleLoading
        tokenId={nft.tokenId}
        collectionName={nft.collectionName}
        price={_price}
        currencySymbol={currency.symbol}
        isLoading={false}
        buyer={buyer}
        imageUrl={nft.imageUrl}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper marks={stepMarkFiltered} selectedStep={selectedStep} txUrl={""}></Stepper>
      </Box>
      {selectedStep == PrivateSaleSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum PrivateSaleSteps {
  TokenApproval = 1,
  Create = 2,
  Done = 3,
}

const PrivateSaleStepMarks = [
  {
    value: PrivateSaleSteps.TokenApproval,
    title: `NFT Approval`,
    description: `This provides Cyan with permission to move your NFT`,
  },
  {
    value: PrivateSaleSteps.Create,
    title: `Create private sale listing`,
    description: `Please sign the message in your wallet`,
  },
];
