import { useMemo } from "react";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Carousel } from "@cyanco/components/theme/v3";

import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { divideArrayByN } from "@/utils";

import { LoanRange, LoanRangeLoading } from "./LoanRange";

export const LoanRanges = () => {
  const { chainId } = useWeb3React();
  const { collectionsLoading, collections: _collections } = useAppContext();
  const collections = useMemo(() => {
    return _collections.filter(_collection => _collection.chainId === chainId);
  }, [_collections, chainId]);
  const colllectionDivided = useMemo(() => {
    return divideArrayByN(collections, 5);
  }, [collections]);
  return (
    <Container>
      {collectionsLoading ? (
        <Carousel>
          <LoanRangeLoading />
        </Carousel>
      ) : (
        <Carousel autoScroll>
          {colllectionDivided.map((_collections, index) => (
            <div
              key={index}
              style={{
                width: "95%",
              }}
            >
              <LoanRange collections={_collections} />
            </div>
          ))}
        </Carousel>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 460px;
  ${getStyleWithMediaQuery("width", "px", [
    { [breakpoints.desktop]: 480 },
    { [breakpoints.laptop]: 460 },
    { [breakpoints.mobile]: 400 },
  ])}
`;
