import { axios } from "../axios";
import { FnFetchAffiliateRevenueByUser } from "./types";

export const claimUserAffiliateRevenue = async (token: string) => {
  return (
    await axios.post(
      `/users/claim-affiliate-revenue`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    )
  ).data;
};

export const fetchAffiliateRevenueDetail: FnFetchAffiliateRevenueByUser = async (token: string) => {
  const { data } = await axios.get(`/users/affiliate-revenue`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return {
    ...data,
    lastTransactionDate: data.lastTransactionDate ? new Date(data.lastTransactionDate) : null,
  };
};

export const checkAffiliateCode = async (token: string, affiliateCode: string): Promise<boolean> => {
  return (
    await axios.post(
      "/users/check-affiliate",
      { affiliateCode },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    )
  ).data;
};

export const applyAffiliateCode = async (data: { wallet: string; affiliateCode: string }): Promise<void> => {
  return await axios.post(`/v2/users/${data.wallet}/apply-affiliate-code`, {
    affiliateCode: data.affiliateCode,
  });
};
