import { BigNumber } from "ethers";

import { ApePlanStatuses } from "@/apis/ape-plans";
import { IUserNft } from "@/apis/user/types";
import { IPawn } from "@/components/Account/pawn.types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { PoolId } from "@/config";
import { IMappedError } from "@/utils/error/msgs";

export type IApeStakingDataContract = {
  poolId: PoolId;
  stakedAmount: BigNumber;
  earnedAmount: BigNumber;
  pairedTokenId: string | null;
  pairedTokenPool: number | null;
  isPaired: boolean;
  tokenId: string;
};

export type IApeStakingData = {
  poolId: PoolId;
  stakedAmount: BigNumber | null;
  earnedAmount: BigNumber | null;
  pairedTokenId: string | null;
  pairedTokenPool: number | null;
  isPaired: boolean;
};

export type IApeStakingDataCyan = {
  apeStaking: Omit<IApeStakingDataContract, "tokenId"> & {
    plan: {
      borrowedApe: BigNumber;
      planId: number;
      status: ApePlanStatuses;
      totalRewards: BigNumber;
      type: IApeCoinPlanType;
      rewardStakeToCyanVault: boolean;
    } | null;
  };
};

export type IApeCoinStakableNft = IUserNft & IApeStakingDataCyan;
export type IApeUserAsset = Array<IApeCoinStakableNft>;

export type IApePlanHistory = {
  id: number;
  mainPoolId?: string;
  poolId: PoolId;
  tokenId?: string;
  wallet: string;
  amount: string;
  rewards?: string;
  rewardStakeToCyanVault: boolean;
  status: ApePlanStatuses;
  createdAt: Date;
  txnHash: string;
  vaultTokenAmount?: string;
  autoCompound?: {
    rewards: string;
    vaultTokenAmount: string;
    txnHash: string;
    createdAt: Date;
  };
  metadata?: {
    collection: string;
    collectionName: string;
    imageUrl: string;
  };
};
export type IApeCoinStakablePosition = (IPawn | IBNPL) & IApeStakingDataCyan;
export type IApeUserPosition = Array<IApeCoinStakablePosition>;

export enum IActionType {
  stake = "stake",
  unstake = "unstake",
}

export enum IApeCoinSource {
  owned = "owned",
  borrow = "borrow",
  all = "all",
}

export enum IApeCoinPlanType {
  AutoCompound = "Auto-Compound",
  Borrow = "Borrow",
}

export type IApeStakingModal = {
  action: IActionType;
  apeCoinSource: IApeCoinSource;
  selectedCollection?: string;
  selectedPairingNft?: ISelectedNft | null;
  selectedMainNfts?: ISelectedNft[];
  err?: IMappedError;
};

export type IApeCoinStakingModal = {
  action: IActionType;
  err?: IMappedError;
};

export type IApeBulkStakingModal = {
  action: IActionType;
  apeCoinSource: IApeCoinSource;
  selectedCollection?: string;
  selectedMainNfts: ISelectedNft[];
  err?: IMappedError;
  removePlan: (a: { address: string; tokenId: string }) => void;
};

export type ISelectedNft = {
  address: string;
  tokenId: string;
  isCyanWallet: boolean;
  imageUrl?: string | null;
} & IApeStakingDataCyan;

export type IApeCoinUserBalance = {
  mainWalletMax: BigNumber | null;
  cyanWalletMax: BigNumber | null;
};

export type IApeCoinBalance = {
  stakedAmount: BigNumber;
  earnedAmount: BigNumber;
  stakedAmountCyan: BigNumber;
  earnedAmountCyan: BigNumber;
};
