import React from "react";
import styled from "styled-components";

export const Tooltip: React.FC = ({ children }) => {
  return <TooltipContainer>{children}</TooltipContainer>;
};

export const TooltipText = styled.div<{
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  position?: "top" | "bottom" | "left" | "right";
  showArrow?: boolean;
}>`
  position: absolute;
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 0.5rem;
  display: none;
  z-index: 11;
  max-width: 140px;
  top: ${({ top }) => top && top};
  bottom: ${({ bottom }) => bottom && bottom};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  :after {
    content: "";
    position: absolute;
    width: 10%;
    height: 100%;
    border-radius: 50%;
    z-index: -1;
    background: ${({ theme }) => theme.colors.secondary};
    display: ${({ showArrow }) => (showArrow ? "block" : "none")};
    border-color: ${({ theme, position }) => {
      switch (position) {
        case "bottom":
          return `transparent transparent ${theme.colors.secondary} transparent`;
        case "left":
          return `transparent ${theme.colors.secondary} transparent transparent`;
        case "right":
          return `transparent transparent transparent ${theme.colors.secondary}`;
        case "top":
        default:
          return `${theme.colors.secondary} transparent transparent transparent`;
      }
    }};
    ${({ position }) => {
      switch (position) {
        case "bottom":
          return `top: -8px; left: 50%; transform: translateX(-50%);`;
        case "left":
          return `top: 50%; right: -14px; transform: translateY(-50%) rotate(360deg) scaleX(-1);`;
        case "right":
          return `top: 50%; left: -14px; transform:translateY(-50%) rotate(360deg) scaleX(-1);
          `;
        case "top":
        default:
          return `bottom: -6px; left: 50%; transform: translateX(-50%); `;
      }
    }};
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  &:hover ${TooltipText} {
    display: block;
  }
`;
