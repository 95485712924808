import { useEffect, useState } from "react";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound, useModal } from "@cyanco/components/theme/v3";

import { IP2PUserCreatedOffer, IPeerPlan, PeerPlanStatuses, isPeerPlan } from "@/apis/p2p/types";

import { useCreatedLoanBids, usePeerPlans } from "../../AccountDataContext";
import { ContainerBox, Header, ListViewBox } from "../../index";
import { HistoryHeader, HistoryRow } from "./HistoryRow";
import { OfferDetail } from "./modals/OfferDetail";

export const History: React.FC = () => {
  const { setModalContent } = useModal();
  const { userGivenPeerPlans } = usePeerPlans();
  const { createdLoanBids } = useCreatedLoanBids();
  const renew = (offer: IP2PUserCreatedOffer) => {
    setModalContent({
      title: `Renew loan offer`,
      content: <OfferDetail offer={offer} modalType="renew" />,
    });
  };

  const [sortedPlans, setSortedPlans] = useState<(IP2PUserCreatedOffer | IPeerPlan)[]>([
    ...userGivenPeerPlans.filter(
      plan => plan.status === PeerPlanStatuses.LIQUIDATED || plan.status === PeerPlanStatuses.COMPLETED,
    ),
    ...createdLoanBids.filter(offer => !offer.isActive || offer.isExpired),
  ]);
  useEffect(() => {
    setSortedPlans([
      ...userGivenPeerPlans.filter(
        plan => plan.status === PeerPlanStatuses.LIQUIDATED || plan.status === PeerPlanStatuses.COMPLETED,
      ),
      ...createdLoanBids.filter(offer => !offer.isActive || offer.isExpired),
    ]);
  }, [userGivenPeerPlans, createdLoanBids]);

  const updateSortedPlans = (plans: (IP2PUserCreatedOffer | IPeerPlan)[]) => {
    setSortedPlans([...plans]);
  };
  return (
    <ListContainer>
      <ListViewBox>
        <Header>
          <HistoryHeader
            totalItems={sortedPlans.length}
            sortedPlans={sortedPlans}
            updateSortedPlans={updateSortedPlans}
          />
        </Header>
        <ContainerBox>
          {sortedPlans.length === 0 && <NotFound msg={`No plans or offers found`} />}
          <Container role="grid" $showByGrid={false}>
            {sortedPlans.map(plan => (
              <HistoryRow
                key={isPeerPlan(plan) ? `${plan.planId}-loan` : `${plan.id}-offer`}
                position={plan}
                onClick={() => {
                  !isPeerPlan(plan) && renew(plan);
                }}
              />
            ))}
          </Container>
        </ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};

const Container = styled.div<{ $showByGrid: boolean }>`
  ${({ $showByGrid }) => {
    if ($showByGrid) {
      return ` display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  ${getStyleWithMediaQuery("grid-column-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-row-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.desktop]: "repeat(auto-fill, minmax(200px, 1fr))" },
    { [breakpoints.tablet]: "repeat(auto-fill, minmax(180px, 1fr))" },
    { [breakpoints.mobile]: "repeat(auto-fill, minmax(140px, 1fr))" },
  ])}; `;
    } else {
      return ` display: flex;
      flex-direction: column;
      width: 100%;
   @media only screen and (max-width: 414px) {
     border: none;
     border-radius: 20px;
     padding: 0;
   }
   > * {
     &:last-child {
       border-bottom-left-radius: 20px;
       border-bottom-right-radius: 20px;
     }
   }`;
    }
  }}
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;
