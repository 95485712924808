import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "../../Flex";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { ChevronDown } from "../icons";
import { ITextSizes, Text } from "./Text";

export const Select = <T,>(props: {
  onChange: (v: T) => void;
  value: T;
  children?: React.ReactNode;
  textSize?: ITextSizes;
  p?: string;
  bg?: string;
  borderColor?: string;
  borderRadius?: string;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setOpen(false));
  const { children, onChange, value, textSize, p, bg, borderColor, disabled, borderRadius } = props;
  const [open, setOpen] = useState(false);
  const [selectedValueLabel, setSelectedValueLabel] = useState<React.ReactNode>(null);
  const [selectedValue, setSelectedValue] = useState<T | null>(value);
  useEffect(() => {
    const selectedChild = React.Children.toArray(children).find(
      child => React.isValidElement(child) && child.props.value === value,
    );
    setSelectedValueLabel(selectedChild && React.isValidElement(selectedChild) ? selectedChild.props.children : null);
  }, [selectedValue]);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <SelectWrapper
        open={open}
        ref={node}
        bg={bg}
        borderColor={borderColor}
        disabled={disabled}
        borderRadius={borderRadius}
      >
        <SelectedValue onClick={() => !disabled && setOpen(!open)} p={p} opened={open} borderRadius={borderRadius}>
          <Flex>
            {React.isValidElement(selectedValueLabel) ? (
              selectedValueLabel
            ) : (
              <Text size={textSize || "lg"} color="secondary" weight="500">
                {selectedValueLabel || "-"}
              </Text>
            )}
          </Flex>
          <ChevronDown color={theme.colors.secondary} strokeWidth={textSize === "sm" || textSize === "xs" ? 1 : 2} />
        </SelectedValue>
        <OptionsWrapper open={open} bg={props.bg}>
          {React.Children.map(children, child => {
            if (!React.isValidElement(child)) {
              return null;
            }
            return React.cloneElement(child as React.ReactElement<any>, {
              value: child.props.value == value,
              isActive: child.props.value == value,
              p,
              onSelect: () => {
                onChange(child.props.value);
                setSelectedValueLabel(child.props.children || child.props.value);
                setSelectedValue(child.props.value);
                setOpen(false);
              },
            });
          })}
        </OptionsWrapper>
      </SelectWrapper>
    </div>
  );
};

export const Option: React.FC<{
  value?: string | number;
  isActive?: boolean;
  onSelect?: () => void;
  style?: CSSProperties;
}> = ({ children, value, isActive, onSelect, ...props }) => {
  return (
    <OptionWrapper onClick={onSelect} {...props}>
      {React.Children.count(children) !== 0 ? (
        React.isValidElement(children) ? (
          children
        ) : (
          <Text size="lg" color="secondary" weight="500">
            {children}
          </Text>
        )
      ) : (
        <Text size="lg" color="secondary" weight="500">
          {value === undefined ? "-" : value}
        </Text>
      )}
      {isActive && <ActiveIndicator />}
    </OptionWrapper>
  );
};

const SelectWrapper = styled.div<{
  open: boolean;
  bg?: string;
  borderColor?: string;
  disabled?: boolean;
  borderRadius?: string;
}>`
  background: ${({ theme, bg }) => bg || theme.colors.primary};
  border-radius: ${({ theme, borderRadius }) => borderRadius ?? theme.borderRadius};
  border: 1px solid
    ${({ theme, open, borderColor }) => (open ? theme.colors.gray30 : borderColor || theme.colors.gray20)};
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  width: calc(100% - 2px);
  outline: none;
  display: flex;
  height: calc(100% - 2px);
  transition: border-color 0.2s ease-in, border-bottom-color 0.2s ease-in, border-bottom-width 0s,
    border-bottom-left-radius 0s, border-bottom-right-radius 0s;
  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
    background: ${({ theme }) => (theme.theme == "dark" ? "#1c1c1c" : "#EEEEEE")};
  }
`;

const SelectedValue = styled.div<{ p?: string; opened: boolean; borderRadius?: string }>`
  border-radius: ${({ theme, borderRadius }) => borderRadius ?? theme.borderRadius};
  background: ${({ theme, opened }) => opened && (theme.theme == "dark" ? "#1c1c1c" : "#EEEEEE")};
  display: flex;
  flex-direction: row;
  padding: ${({ p }) => p || "0.7rem 0.8rem"};
  align-items: center;
  justify-content: space-between;
  outline: none;
  width: 100%;
  user-select: none;
`;

const OptionsWrapper = styled.div<{ open: boolean; bg?: string }>`
  position: absolute;
  max-height: 300px;
  overflow-y: scroll;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  background: ${({ theme, bg }) => bg || theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  border-radius: ${({ theme }) => theme.borderRadius};
  z-index: 10;
  left: 0px;
  outline: none;
  top: calc(100% + 0.5rem);
  width: calc(100% - 2px);
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.45rem 0.8rem;
  position: relative;
  &:last-child {
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    border-bottom: 1px solid;
    margin-bottom: -1px;
    padding-bottom: 0.7rem;
    border-bottom-color: transparent;
  }
  :hover {
    background: ${({ theme }) => theme.colors.gray10};
    transition: all 0.1s;
  }
`;

const ActiveIndicator = styled.div`
  position: absolute;
  top: 37%;
  right: 8px;
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: 50%;
  min-height: 5px;
  min-width: 5px;
  height: 26%;
  aspect-ratio: 1/1;
`;
