import React, { useState } from "react";

import { IPawn } from "../Account/pawn.types";
import { IBNPL } from "../Bnpl/bnpl.types";
import { PlanBulkRepayment } from "./BulkRepayment/PlanBulkRepayment";
import { PlanRepayment } from "./PlanRepayment";

type IPlanRepaymentModalProps = {
  plans: Array<IPawn | IBNPL>;
  onClose: () => void;
};

export const PlanRepaymentModal: React.FC<IPlanRepaymentModalProps> = ({ plans: _plans, onClose }) => {
  const [plans, setPlans] = useState(_plans);
  const removePlan = (plan: { address: string; tokenId: string }) => {
    setPlans(prevValues =>
      prevValues.filter(
        ({ metadata, tokenId }) => !(plan.address === metadata.collectionAddress && plan.tokenId === tokenId),
      ),
    );
  };
  if (plans.length === 1) {
    const plan = plans[0];
    return (
      <PlanRepayment
        bnpl={plan.planType === "BNPL" ? plan : undefined}
        pawn={plan.planType === "Pawn" ? plan : undefined}
        planType={plan.planType === "BNPL" ? "bnpl" : "pawn"}
        onClose={onClose}
      />
    );
  }
  return <PlanBulkRepayment plans={plans} removePlan={removePlan} onClose={onClose} />;
};
