import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SubTitle, Text } from "@cyanco/components/theme/v3";
import { ArrowLeft } from "@cyanco/components/theme/v3/icons";

import { CHAIN_NAMES_TO_IDS, ISupportedChainNames } from "@/constants/chains";

import { useSupportedCollections } from "../AppContextProvider";
import { CollectionDetailSection } from "../Bnpl/components/CollectionDetail";
import { LendCollectionSection } from "./collection";
import { CollectionSearch } from "./collections/CollectionSelectors";

export const P2PCollection = () => {
  const { collectionAddress, chain } = useParams();
  const { collections } = useSupportedCollections();
  const collection = collections.find(
    collection =>
      collection.address === collectionAddress &&
      chain &&
      CHAIN_NAMES_TO_IDS[chain as ISupportedChainNames] === collection.chainId,
  );
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [collectionAddress]);

  if (!collection) return null;
  return (
    <Container direction="column">
      <GappedContainer direction="column">
        <div>
          <BackToCollectionsButton variant="ghost" onClick={() => navigate("/lend")}>
            <Flex alignItems="center" gap="2px">
              <BackToCollectionsIcon />
              <BackToCollectionsText color="gray0" size="sm">
                {`Collections`}
              </BackToCollectionsText>
            </Flex>
          </BackToCollectionsButton>
        </div>
        <Flex justifyContent="space-between" w="100%" alignItems="center">
          <Hidden tabletDown>
            <SubTitle>{`Lend to Specific NFTs`}</SubTitle>
          </Hidden>

          <Hidden tabletDown>
            <CollectionSearch minWidth="300px" />
          </Hidden>
        </Flex>
      </GappedContainer>
      <CollectionDetailSection collection={collection} />
      <LendCollectionSection collection={collection} />
    </Container>
  );
};

const Container = styled(Flex)`
  gap: 2rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 2 }, { [breakpoints.tablet]: 0.5 }])}
  padding-bottom: 18px;
  ${getStyleWithMediaQuery("padding-bottom", "", [
    { [breakpoints.desktop]: "18px" },
    { [breakpoints.laptop]: "3.5rem" },
    { [breakpoints.tablet]: "2rem" },
  ])}
`;

const GappedContainer = styled(Flex)`
  align-items: start;
  justify-content: start;
  gap: 1rem;
  padding: 0 10rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 0.5 }])}
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0.5rem 5px" },
  ])}
`;

const BackToCollectionsText = styled(Text)`
  transition: 0.2s ease-in-out;
`;
const BackToCollectionsIcon = styled(ArrowLeft)`
  stroke: ${props => props.theme.colors.gray0};
  transition: 0.08s ease-in-out;
`;
const BackToCollectionsButton = styled(Button)`
  &:hover {
    ${BackToCollectionsIcon} {
      stroke: ${props => props.theme.colors.secondary};
    }
    ${BackToCollectionsText} {
      color: ${props => props.theme.colors.secondary};
    }
  }
`;
