import { useEffect, useMemo, useState } from "react";
import { NumericFormat, OnValueChange } from "react-number-format";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";
import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import {
  Box,
  Button,
  Card,
  CurrencyLogo,
  Flex,
  Hidden,
  Input,
  SliderInput,
  SystemMessage,
  Text,
  Toggler,
  Tooltip,
  TooltipText,
  useModal,
} from "@cyanco/components/theme";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";

import { ICollectionBe } from "@/apis/collection/types";
import { IP2PListedNft } from "@/apis/p2p/types";
import { useAppContext } from "@/components/AppContextProvider";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { isProd, nonNativeSupportedCurrenciesData } from "@/config";
import { bigNumToFloat, displayInUSD, roundDown, shortenAddress } from "@/utils";
import { IMappedError } from "@/utils/error/msgs";

import { ILoanOfferCreationData, LoanOfferCreatingProgress } from "./LoanOfferCreatingProgress";
import { NftMetadata } from "./NftMetadata";

const LOAN_EXPIRY_TERMS: { [key: string]: number } = isProd
  ? {
      "1 hour": 3600,
      "6 hours": 21600,
      "1 day": 86400,
      "3 days": 259200,
      "7 days": 604800,
      "1 month": 2678400,
    }
  : {
      "10 mins": 600,
      "30 mins": 1800,
      "45 mins": 2700,
      "1 hour": 3600,
      "3 hours": 10800,
    };

export const OfferMakerCard = ({
  collection,
  nft,
  showNft = true,
  error: _error,
  loanData,
}: {
  nft?: IP2PListedNft;
  collection: ICollectionBe;
  showNft?: boolean;
  error?: IMappedError;
  loanData?: ILoanOfferCreationData;
}) => {
  const theme = useTheme();
  const { setModalContent } = useModal();
  const { account, chainId } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { usdPrice } = useAppContext();
  const [loanAmount, setLoanAmount] = useState<string>("");
  const [loanLength, setLoanLength] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState<SupportedCurrencies>(SupportedCurrencies.WETH);
  const [interestRate, setInterestRate] = useState<string>("");
  const [autoRoll, setAutoRoll] = useState<boolean>(true);
  const [numberOfNfts, setNumberOfNfts] = useState<number>(1);
  const [offerExpiry, setOfferExpiry] = useState<number>(
    isProd ? LOAN_EXPIRY_TERMS["1 month"] : LOAN_EXPIRY_TERMS["3 hours"],
  );
  const [selectedWallet, setSelectedWallet] = useState<string>(account || "");
  const [error, setError] = useState(_error);

  useEffect(() => {
    if (!loanData) return;
    setAutoRoll(loanData.isExtendable);
    setSelectedWallet(loanData.lenderAddress);
    setInterestRate(loanData.interestRate.toString());
    setLoanAmount(loanData.amount.toString());
    setNumberOfNfts(loanData.numberOfNfts);
    setOfferExpiry(loanData.offerExpiry);
    setLoanLength(loanData.term);
    setSelectedCurrency(loanData.currency.symbol as SupportedCurrencies);
  }, [loanData]);

  const onLoanAmountChange: OnValueChange = values => {
    setLoanAmount(values.value);
  };
  const onLoanLengthChange: OnValueChange = values => {
    const value = values.floatValue ?? 0;
    setLoanLength(isProd ? value * 24 * 60 * 60 : value * 60);
  };
  const onLoanAprChange: OnValueChange = values => {
    setInterestRate(values.value);
  };
  const onNftNumberChange: OnValueChange = values => {
    const value = values.floatValue ?? 0;
    setNumberOfNfts(value);
  };
  const makeOffer = () => {
    setError(undefined);
    setModalContent({
      content: (
        <LoanOfferCreatingProgress
          collection={collection}
          nft={nft}
          loanData={{
            lenderAddress: selectedWallet,
            currency: {
              address: nonNativeSupportedCurrenciesData[chainId][selectedCurrency].address,
              symbol: selectedCurrency,
              decimal: nonNativeSupportedCurrenciesData[chainId][selectedCurrency].decimals,
            },
            isExtendable: autoRoll,
            interestRate: parseFloat(interestRate),
            numberOfNfts,
            offerExpiry,
            amount: parseFloat(loanAmount),
            term: loanLength,
          }}
        />
      ),
      title: `Creating Loan Offer`,
    });
  };

  const repaymentAmount = useMemo(() => {
    return roundDown(
      parseFloat(loanAmount === "" ? "0" : loanAmount) +
        parseFloat(loanAmount === "" ? "0" : loanAmount) * (parseFloat(interestRate === "" ? "0" : interestRate) / 100),
      6,
    );
  }, [loanAmount, interestRate]);
  const isMakingOfferDisabled = !(
    numberOfNfts > 0 &&
    parseFloat(loanAmount === "" ? "0" : loanAmount) > 0 &&
    loanLength > 0
  );
  const floorOrAppraisalValue = useMemo(() => {
    if (nft?.appraisalValue) {
      return `${bigNumToFloat(nft.appraisalValue, nft.currency.decimal).toFixed(2)} ${nft.currency.symbol}`;
    }
    if (!collection.floorAsk.price) return "-";
    return `${bigNumToFloat(collection.floorAsk.price.amount.raw, collection.floorAsk.price.currency.decimals).toFixed(
      2,
    )} ${collection.floorAsk.price.currency.symbol}`;
  }, [collection.floorAsk, nft?.appraisalValue]);
  const percentOfRepaymentAmount = useMemo(() => {
    if (nft?.appraisalValue) {
      const usdFloorAsk = bigNumToFloat(nft.appraisalValue) * usdPrice[nft.currency.symbol];
      const usdRepayment = repaymentAmount * usdPrice[selectedCurrency];
      return ((usdRepayment * 100) / usdFloorAsk).toFixed(0);
    }
    if (!collection.floorAsk.price) return "-";

    const usdFloorAsk =
      bigNumToFloat(collection.floorAsk.price.amount.raw) * usdPrice[collection.floorAsk.price.currency.symbol];
    const usdRepayment = repaymentAmount * usdPrice[selectedCurrency];
    return ((usdRepayment * 100) / usdFloorAsk).toFixed(0);
  }, [collection.floorAsk, repaymentAmount, selectedCurrency, usdPrice]);
  const apr = useMemo(() => {
    if (loanLength === 0 || interestRate === "" || interestRate === "0") return "-";
    return ((365 / (loanLength / 60 / 60 / 24)) * parseFloat(interestRate)).toFixed(2);
  }, [loanLength, interestRate]);
  return (
    <Flex direction="column" gap="1.2rem">
      {nft && showNft && <NftMetadata nft={nft} />}
      {error && <SystemMessage variant="error" title={error.title} msg={error.description} />}
      {/* Loan Amount */}
      <Flex direction="column" gap="0.5rem">
        <Text color="gray0" size="sm" weight="500">
          {`Principal Amount`}
        </Text>
        <Flex direction="column" gap="2px">
          <Card
            style={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
              <NumericFormat
                placeholder="0"
                thousandSeparator=","
                allowLeadingZeros={false}
                allowNegative={false}
                onValueChange={onLoanAmountChange}
                value={loanAmount}
                customInput={StakeInput}
                decimalScale={3}
                valueIsNumericString
              />
              <PillWrapper showArrow>
                <CurrencyLogo symbol={selectedCurrency} />
                <StyledSelect
                  value={selectedCurrency}
                  onChange={e => {
                    setSelectedCurrency(e.target.value as SupportedCurrencies);
                  }}
                >
                  <option value={SupportedCurrencies.USDC} key={SupportedCurrencies.USDC}>
                    {SupportedCurrencies.USDC}
                  </option>
                  <option value={SupportedCurrencies.WETH} key={SupportedCurrencies.WETH}>
                    {SupportedCurrencies.WETH}
                  </option>
                </StyledSelect>
              </PillWrapper>
            </Flex>
          </Card>
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
              <Text color="gray0" size="xs" weight="400">
                {`Floor price: ${floorOrAppraisalValue}`}
              </Text>
              <Text color="gray0" size="xs" weight="400">
                {displayInUSD(parseFloat(loanAmount === "" ? "0" : loanAmount) * usdPrice[selectedCurrency])}
              </Text>
            </Flex>
          </Card>
        </Flex>
      </Flex>
      <Flex gap="1.2rem" direction="row">
        {/* Loan Length */}
        <Flex direction="column" gap="0.5rem">
          <Text color="gray0" size="sm" weight="500">
            {`Loan Length`}
          </Text>
          <Flex direction="column" gap="2px">
            <Card
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
                <NumericFormat
                  placeholder="0"
                  thousandSeparator=","
                  allowLeadingZeros={false}
                  allowNegative={false}
                  onValueChange={onLoanLengthChange}
                  value={loanLength === 0 ? "" : isProd ? loanLength / 24 / 60 / 60 : loanLength / 60}
                  customInput={StakeInput}
                  decimalScale={0}
                  valueIsNumericString
                />
                <Text color="secondary" size="xl" weight="400">
                  {isProd ? `days` : `mins`}
                </Text>
              </Flex>
            </Card>
            <Card
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
                <Text color="gray0" size="xs" weight="400">
                  {loanLength === 0 ? "-" : getPaymentInterval(loanLength)}
                </Text>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        {/* Loan Interest */}
        <Flex direction="column" gap="0.5rem">
          <Text color="gray0" size="sm" weight="500">
            {`Interest Rate`}
          </Text>
          <Flex direction="column" gap="2px">
            <Card
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
                <NumericFormat
                  placeholder="0"
                  thousandSeparator=","
                  allowLeadingZeros={false}
                  allowNegative={false}
                  onValueChange={onLoanAprChange}
                  value={interestRate}
                  customInput={StakeInput}
                  decimalScale={5}
                  valueIsNumericString
                />
                <Text color="secondary" size="xl" weight="400">
                  %
                </Text>
              </Flex>
            </Card>
            <Card
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
                <Text color="gray0" size="xs" weight="400">
                  APR: {apr}%
                </Text>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Flex>

      {/* Loan Repayment */}
      <Flex direction="column" gap="0.5rem" mt="0.5rem">
        <Text color="gray0" size="sm" weight="500">
          {`Repayment Amount`}
        </Text>
        <Flex direction="column" gap="2px">
          <Card
            style={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
              <NumericFormat
                placeholder="0"
                thousandSeparator=","
                allowLeadingZeros={false}
                allowNegative={false}
                onValueChange={() => {}}
                value={repaymentAmount}
                customInput={StakeInput}
                disabled
                valueIsNumericString
              />
              <PillWrapper gap="5px" pt="0.4rem">
                <CurrencyLogo symbol={selectedCurrency} />
                <Text color="secondary" weight="400" size="md">
                  {selectedCurrency}
                </Text>
              </PillWrapper>
            </Flex>
          </Card>
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
              <Text color="gray0" size="xs" weight="400">
                {percentOfRepaymentAmount}% LTV
              </Text>
              <Text color="gray0" size="xs" weight="400">
                {displayInUSD(repaymentAmount * usdPrice[selectedCurrency])}
              </Text>
            </Flex>
          </Card>
        </Flex>
      </Flex>
      {/* Togglers */}
      <Flex direction="column" gap="0.7rem">
        {" "}
        {!nft && (
          <Flex justifyContent="space-between" alignItems="center" gap="1.4rem">
            <Flex alignItems="center" gap="4px">
              <Text size="md" weight="500" color="gray0">
                {`Items`}
              </Text>
              <Tooltip>
                <HelpCircle height={16} width={16} color={theme.colors.gray0} />
                <TooltipText showArrow position="top" top="-100px" right="-62px" style={{ width: "125px" }}>
                  <Flex direction="column" gap="7px">
                    <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                      <div>{`Your NFT will be moved into your Cyan Wallet for compounding activities. Turning this off will keep your NFT in your main wallet during staking.`}</div>
                    </Text>
                  </Flex>
                </TooltipText>
              </Tooltip>
            </Flex>
            <Hidden tabletDown style={{ width: "100%" }}>
              <SliderInput
                min={0}
                max={25}
                value={numberOfNfts}
                onChange={e => setNumberOfNfts(e as number)}
                defaultValue={1}
              />
            </Hidden>
            <Box w="70px">
              <NumericFormat
                value={numberOfNfts}
                customInput={NumberNftInput}
                onValueChange={onNftNumberChange}
                allowLeadingZeros={false}
                allowNegative={false}
                valueIsNumericString
                isAllowed={values => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || (floatValue !== undefined && floatValue <= 25);
                }}
              />
            </Box>
          </Flex>
        )}
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap="4px">
            <Text size="md" weight="500" color="gray0">
              {`Auto-roll Loans`}
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.gray0} />
              <TooltipText showArrow position="top" top="-178px" right="-71px" style={{ width: "150px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`Automatically rolled loans are loans where the maturity date is renewed everytime the borrower pays back the interest by the maturity date.`}</div>
                  </Text>
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`The borrower can keep rolling the loan so long as they continue to pay the interest by the due date at every period. When the loan is fully repaid, the principal is returned.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
          </Flex>
          <Toggler value={autoRoll} onChange={setAutoRoll} size="sm" />
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="md" weight="500" color="gray0">
            {`Offer Expiry`}
          </Text>
          <LoanOptionPillWrapper>
            <LoanOptionSelector
              value={offerExpiry}
              onChange={e => {
                setOfferExpiry(Number(e.target.value));
              }}
            >
              {Object.keys(LOAN_EXPIRY_TERMS).map(term => (
                <option value={LOAN_EXPIRY_TERMS[term]} key={term}>
                  {term}
                </option>
              ))}
            </LoanOptionSelector>
          </LoanOptionPillWrapper>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap="4px">
            <Text size="md" weight="500" color="gray0">
              {`Wallet`}
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.gray0} />
              <TooltipText showArrow position="top" top="-98px" right="-67px" style={{ width: "132px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`Select the wallet you would like the loans to be made out from. The wallet you select will be from where crypto will be withdrawn on every loan made.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
          </Flex>
          {account && (
            <LoanOptionPillWrapper>
              <LoanOptionSelector value={selectedWallet} onChange={e => setSelectedWallet(e.target.value)}>
                <option value={account} key={account}>
                  {`${`MAIN`} (${shortenAddress(account, 2)})`}
                </option>
                {cyanWallet && (
                  <option value={cyanWallet.walletAddress} key={cyanWallet.walletAddress}>
                    {`${`CYAN`} (${shortenAddress(cyanWallet.walletAddress, 2)})`}
                  </option>
                )}
              </LoanOptionSelector>
            </LoanOptionPillWrapper>
          )}
        </Flex>
      </Flex>
      <OfferButton onClick={makeOffer} disabled={isMakingOfferDisabled}>{`Make Offer`}</OfferButton>
    </Flex>
  );
};

const PillWrapper = styled(Flex)<{ showArrow?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 30px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
  ${({ showArrow, theme }) =>
    showArrow &&
    `:after {
    content: "";
    appearance: none;
    background-image: ${
      theme.theme === "light"
        ? `url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1 1.86 4 4 4-4" /></svg>')`
        : `url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1 1.86 4 4 4-4" /></svg>')`
    };
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
  }`}
`;

const StakeInput = styled.input`
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;
  outline: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
`;
const StyledSelect = styled.select`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: Inter;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  padding: 0 0.2rem 0 0.2rem;
  border-radius: 30px;
  outline: none;
  appearance: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray20};
  border: none;
`;
const LoanOptionPillWrapper = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 30px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
`;
const LoanOptionSelector = styled(StyledSelect)`
  color: ${({ theme }) => theme.colors.gray0};
  font-size: 14px;
  padding-right: 0.85rem;

  appearance: none;
  background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="gray" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1 1.86 4 4 4-4" /></svg>');
  background-repeat: no-repeat;
  background-position: right 0rem top 50%;
  background-size: 0.65rem auto;
`;
export const OfferButton = styled(Button)`
  padding: 1rem;
  height: 50px;
`;

const NumberNftInput = styled(Input)`
  border-radius: 8px;
  padding: 0.2rem 0.4rem;
`;
