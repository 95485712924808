import { JsonRpcSigner } from "@ethersproject/providers";
import { utils } from "ethers";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { IPrivateSale } from "@/apis/private-sale";
import { ISelectedNft } from "@/components/Account/components/WalletView/NftCard";
import { SupportedChainId } from "@/constants/chains";
import { INftBe } from "@/types";

export type IInititatePrivateSale = {
  price: string;
  currencyAddress: string;
  buyer: string;
  collectionSignature: string;
  expiryAt: number;
  currency: {
    symbol: SupportedCurrenciesType;
    decimal: number;
  };
  nft: ISelectedNft;
};

type PrivateSaleSignItem = {
  chainId: SupportedChainId;
  collectionSignature: string;
  collectionAddress: string;
  currencyAddress: string;
  tokenAmount: number;
  tokenId: string;
  buyerAddress: string;
  sellerAddress: string;
  signedDate: number;
  expiryDate: number;
  price: string;
  tokenType: number;
};

export const signPrivateSale = async (signer: JsonRpcSigner, data: PrivateSaleSignItem): Promise<string> => {
  const messageHash = utils.solidityKeccak256(
    [
      "uint256",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
      "uint256",
      "address",
      "uint8",
      "bytes",
    ],
    [
      data.chainId,
      data.buyerAddress,
      data.signedDate,
      data.expiryDate,
      data.price,
      data.currencyAddress,
      data.tokenAmount,
      data.tokenId,
      data.collectionAddress,
      data.tokenType,
      data.collectionSignature,
    ],
  );
  const signature = await signer.signMessage(utils.arrayify(messageHash));
  return signature;
};

export type IInititatePrivateSalePurchase = {
  nft: INftBe;
  privateSale: IPrivateSale;
};

export type IInititatePrivateCancelPurchase = {
  nft: ISelectedNft;
  privateSale: IPrivateSale;
};

export type IInititatePrivateUpdatePurchase = {
  nft: ISelectedNft;
  privateSale: IPrivateSale;
  currencyAddress: string;
  price: string;
  buyerAddress: string;
  expiryAt: number;
  currency: {
    symbol: SupportedCurrenciesType;
    decimal: number;
  };
};
