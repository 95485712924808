import dayjs from "dayjs";
import { useMemo } from "react";

import { StyledCheckbox } from "@cyanco/components/theme";

import { PeerPlanStatuses } from "@/apis/p2p/types";

import { useBNPLPositions, usePawnPositions, usePeerPlans, useUserAssets } from "../../AccountDataContext";
import { useSelectedItems } from "../../SelectedItemsContext";

export const SelectAllItemsCheckBox = () => {
  const { addItems, items, removeAll } = useSelectedItems();
  const { userAssetsLoading, userAssets } = useUserAssets();
  const { activePawnPositions, pawnLoading } = usePawnPositions();
  const { activeBnplPositions, bnplLoading } = useBNPLPositions();
  const { userTakenPeerPlans, peerPlansLoading } = usePeerPlans();
  const activePeerPlans = userTakenPeerPlans.filter(
    plan => plan.status === PeerPlanStatuses.ACTIVE && plan.nextPaymentDate && dayjs().isBefore(plan.nextPaymentDate),
  );
  const isChecked = useMemo(() => {
    return items.length > 0;
  }, [items]);
  const isLoading = useMemo(() => {
    return pawnLoading || userAssetsLoading || bnplLoading || peerPlansLoading;
  }, [pawnLoading, userAssetsLoading, bnplLoading, peerPlansLoading]);
  const checkAll = () => {
    if (!isChecked) {
      const _userAssets = userAssets.assets.filter(item => !item.isBendDao);
      addItems([...activeBnplPositions, ...activePawnPositions, ...activePeerPlans, ..._userAssets]);
      return;
    }
    removeAll();
  };
  return (
    <div style={{ marginTop: "-2px" }}>
      <StyledCheckbox
        checked={!isLoading && isChecked}
        onChange={checkAll}
        id={"check-all"}
        disabled={isLoading}
        showMinus
      />
    </div>
  );
};

export const SelectAllPositionsCheckBox = () => {
  const { addItems, items, removeAll } = useSelectedItems();
  const { activePawnPositions, revivablePawnPostions, pawnLoading } = usePawnPositions();
  const { activeBnplPositions, revivableBnplPositions, bnplLoading } = useBNPLPositions();
  const { userTakenPeerPlans, peerPlansLoading } = usePeerPlans();

  const activePeerPlans = userTakenPeerPlans.filter(
    plan => plan.status === PeerPlanStatuses.ACTIVE && plan.nextPaymentDate && dayjs().isBefore(plan.nextPaymentDate),
  );
  const isChecked = useMemo(() => {
    return items.length > 1;
  }, [items]);
  const isLoading = useMemo(() => {
    return pawnLoading || bnplLoading || peerPlansLoading;
  }, [pawnLoading, bnplLoading, peerPlansLoading]);
  const checkAll = () => {
    if (!isChecked) {
      addItems([
        ...activeBnplPositions,
        ...activePawnPositions,
        ...activePeerPlans,
        ...revivableBnplPositions,
        ...revivablePawnPostions,
      ]);
      return;
    }
    removeAll();
  };
  return (
    <div style={{ marginTop: "-2px" }}>
      <StyledCheckbox
        checked={!isLoading && isChecked}
        onChange={checkAll}
        id={"check-all"}
        disabled={isLoading}
        showMinus
      />
    </div>
  );
};
