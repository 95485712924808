import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IPeerPlan } from "@/apis/p2p/types";
import { IPawn, PawnStatuses } from "@/components/Account/pawn.types";
import { BNPLStatuses, IBNPL } from "@/components/Bnpl/bnpl.types";
import { useWeb3React } from "@/components/Web3ReactProvider";

import { useBNPLPositions, usePawnPositions, usePeerPlans } from "../../AccountDataContext";
import { ContainerBox, Header, ListViewBox } from "../../index";
import { IPayment } from "../../payment.types";
import { BnplPaymentRow } from "./BnplPaymentRow";
import { PawnPaymentRow } from "./PawnPaymentRow";
import { IProps, PaymentsHeader } from "./PaymentRow";
import { PeerPlanPaymentRow } from "./PeerPlanPaymentRow";
import { checkIsRevivalPossible } from "./utils";

const UserNftWrapper: React.FC<{
  totalItems: number;
  chainId: number;
  updateSortedPayments: (payments: (IProps & { position: IPawn | IBNPL | IPeerPlan })[]) => void;
  sortedPayments: (IProps & { position: IPawn | IBNPL | IPeerPlan })[];
}> = ({ children, totalItems, chainId, sortedPayments, updateSortedPayments }) => {
  return (
    <ListContainer>
      <ListViewBox>
        <Header>
          <PaymentsHeader
            totalItems={totalItems}
            chainId={chainId}
            sortedPayments={sortedPayments}
            updateSortedPayment={updateSortedPayments}
          />
        </Header>
        <ContainerBox>{children}</ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};

export const HistoricalPayments: React.FC = () => {
  const { chainId } = useWeb3React();
  const { pawnPositions } = usePawnPositions();
  const { bnplPositions } = useBNPLPositions();
  const { userTakenPeerPlans } = usePeerPlans();
  const [sortedPayments, setSortedPayments] =
    useState<(IProps & { position: IPawn | IBNPL | IPeerPlan; payment?: IPayment })[]>();
  const positionsMerged = useMemo(
    () => [
      ...pawnPositions.filter(
        position =>
          position.paymentPlan.chainId === chainId &&
          !checkIsRevivalPossible(position) &&
          (position.payments.length || [PawnStatuses.Defaulted, PawnStatuses.Liquidated].includes(position.status)),
      ),
      ...bnplPositions.filter(
        position =>
          position.paymentPlan.chainId === chainId &&
          position.status !== BNPLStatuses.Pending &&
          position.status !== BNPLStatuses.Rejected &&
          position.status !== BNPLStatuses.Funded,
      ),
      ...userTakenPeerPlans,
    ],
    [pawnPositions, bnplPositions],
  );

  useEffect(() => {
    const payments: (IProps & { position: IPawn | IBNPL | IPeerPlan; payment?: IPayment })[] = [];
    positionsMerged.forEach(position => {
      if (position.planType === "Pawn") {
        position.payments.forEach(payment => {
          const status =
            (position.numOfEarlyPaidPayments ?? 0) > 1 && payment.paymentNumber === position.totalNumOfPayments
              ? "completedEarly"
              : payment.paymentNumber === position.totalNumOfPayments && position.status === PawnStatuses.Completed
              ? "completed"
              : "paid";
          payments.push({
            position,
            payment,
            imageUrl: position.metadata.imageUrl,
            collectioName: position.metadata.collection.name,
            createdAt: payment.createdAt,
            paymentNumber: payment.paymentNumber,
            amount: payment.amount,
            currency: position.currency,
            status,
            tokenId: position.tokenId,
            totalPaymentNumber: position.totalNumOfPayments,
            transactionHash: payment.txnHash,
            positionId: position.planId,
            planType: "Pawn",
            isAutoLiquidationEnabled: position.isAutoLiquidationEnabled,
          });
        });
      } else if (position.planType === "BNPL") {
        position.payments.forEach(payment => {
          const status =
            (position.numOfEarlyPaidPayments ?? 0) > 1 && payment.paymentNumber === position.totalNumOfPayments
              ? "completedEarly"
              : payment.paymentNumber === position.totalNumOfPayments && position.status === BNPLStatuses.Completed
              ? "completed"
              : "paid";
          payments.push({
            position,
            payment,
            imageUrl: position.metadata.imageUrl,
            collectioName: position.metadata.collection.name,
            createdAt: payment.createdAt,
            paymentNumber: payment.paymentNumber,
            amount: payment.amount,
            currency: position.currency,
            status,
            tokenId: position.tokenId,
            totalPaymentNumber: position.totalNumOfPayments,
            transactionHash: payment.txnHash,
            positionId: position.planId,
            planType: "BNPL",
            isAutoLiquidationEnabled: position.isAutoLiquidationEnabled,
          });
        });
      } else {
        [position].forEach(p =>
          payments.push({
            position,
            imageUrl: position.metadata ? position.metadata.imageUrl : NoImage,
            collectioName: p.collection.name,
            createdAt: p.defaultedAt ? p.defaultedAt : dayjs(p.createdAt).add(p.loanBid.term, "seconds").toDate(),
            paymentNumber: 1,
            amount: p.monthlyAmount,
            currency: p.loanBid.currency,
            status: "missed",
            tokenId: p.tokenId,
            totalPaymentNumber: 1,
            positionId: p.planId,
            planType: "P2P",
            isAutoLiquidationEnabled: false,
          }),
        );
      }
    });
    setSortedPayments(payments);
  }, [positionsMerged]);

  const updateSortedPayments = (payments: (IProps & { position: IPawn | IBNPL | IPeerPlan; payment?: IPayment })[]) => {
    setSortedPayments([...payments]);
  };
  return (
    <UserNftWrapper
      totalItems={positionsMerged.length}
      chainId={chainId}
      updateSortedPayments={updateSortedPayments}
      sortedPayments={sortedPayments ?? []}
    >
      {positionsMerged.length === 0 && <NotFound msg={`No positions found`} />}
      <Virtuoso
        customScrollParent={window.document.getElementById("custom-scroll-parent") ?? undefined}
        totalCount={positionsMerged.length}
        data={sortedPayments}
        itemContent={(index, payment) => {
          const updatedPosition: IPawn | IBNPL | IPeerPlan = {
            ...payment.position,
            payments: payment.payment ? [payment.payment] : [],
          };
          return updatedPosition.planType === "Pawn" ? (
            <PawnPaymentRow position={updatedPosition} key={`${index}:${updatedPosition.planId}:PAWN`} />
          ) : updatedPosition.planType === "BNPL" ? (
            <BnplPaymentRow position={updatedPosition} key={`${index}:${updatedPosition.planId}:BNPL`} />
          ) : (
            <PeerPlanPaymentRow position={updatedPosition} key={`${index}:${updatedPosition.planId}:P2P`} />
          );
        }}
      />
    </UserNftWrapper>
  );
};

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;
