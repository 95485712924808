import { decode } from "jsonwebtoken";

import { IUserTokenData } from "@/apis/user/types";
import { IVault } from "@/apis/vault/types";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";

export const useUserAdmin = () => {
  const { user } = useAuthContext();
  const getUserAdminOfVaults = (vaults: IVault[]) => {
    const token = user?.token;
    if (token) {
      const data = decode(token) as IUserTokenData;
      const adminVaults = data?.vaultAddresses ?? [];
      return vaults.filter(vault => adminVaults.includes(vault.contractAddress));
    }
    return [];
  };
  return {
    getUserAdminOfVaults,
  };
};
