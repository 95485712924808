import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";
import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";
import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import { Button, Flex, SystemMessage, Text, useModal } from "@cyanco/components/theme";
import { CyanLogo } from "@cyanco/components/theme/v3/icons";

import { IPrivateSale } from "@/apis/private-sale";
import { usePlanCreation } from "@/hooks";
import { INftBe } from "@/types";
import { bigNumToFloat, displayInUSD } from "@/utils";
import { IMappedError } from "@/utils/error/msgs";

import { useAppContext, useSupportedCollections } from "../AppContextProvider";
import { useWeb3React } from "../Web3ReactProvider";
import { PrivateSaleInput } from "./CommonComponents";
import { ItemsMetadata } from "./ItemMetadata";
import { PrivateSalePurchaseStepper } from "./Stepper/PrivateSalePurchaseStepper";

export const PrivateSalePurchaseModal = ({
  nft,
  privateSale,
  err,
}: {
  nft: INftBe;
  privateSale: IPrivateSale;
  err?: IMappedError;
}) => {
  const { account } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { collections } = useSupportedCollections();
  const { setModalContent } = useModal();
  const date = dayjs(privateSale.expiryDate).format("MMMM D, YYYY");

  const currency = {
    address: nft.currency.address,
    decimal: nft.currency.decimal,
    symbol: nft.currency.symbol as SupportedCurrenciesType,
  };
  const price = formatUnits(privateSale.price, nft.currency.decimal);
  const { usdPrice: usdPrices } = useAppContext();

  const chosenCollection = useMemo(() => {
    return collections.find(
      collection => collection.address.toLowerCase() == privateSale.collectionAddress.toLowerCase(),
    );
  }, [collections, privateSale.collectionAddress]);

  const usdPrice = useMemo(() => {
    if (!usdPrices || !usdPrices[currency.symbol] || !price) return 0;
    return Number(usdPrices[currency.symbol]) * Number(price);
  }, [usdPrices, price, currency]);

  const [error, setError] = useState<IMappedError | null>(err || null);

  const fromFloor = useMemo(() => {
    if (
      !chosenCollection ||
      !usdPrices ||
      !usdPrices["ETH"] ||
      !usdPrice ||
      !chosenCollection?.floorAsk ||
      !chosenCollection.floorAsk.price
    )
      return;
    const floorUsdPrice = Number(usdPrices["ETH"]) * bigNumToFloat(chosenCollection.floorAsk.price.amount.raw);
    return (usdPrice * 100) / floorUsdPrice - 100;
  }, [chosenCollection, usdPrice]);

  const confirmPurchase = () => {
    setError(null);
    if (!privateSale.collectionSignature && chosenCollection?.cyanSignature)
      privateSale.collectionSignature = chosenCollection?.cyanSignature;
    setModalContent({
      title: `Proccessing Purchase`,
      content: <PrivateSalePurchaseStepper nft={nft} privateSale={privateSale} />,
    });
  };

  const { showNewPlanModal } = usePlanCreation("bnpl");

  const cyanPay = () => {
    showNewPlanModal({
      currency: nft.currency,
      items: [
        {
          ...nft,
          address: privateSale.collectionAddress,
          price: BigNumber.from(privateSale.price),
          itemType: privateSale.tokenType,
          amount: privateSale.tokenAmount,
          marketName: SupportedMarketPlaces.CYANPRIVATESALE,
          privateSaleId: privateSale.id,
        },
      ],
    });
  };

  const isUserBuyer = [(account ?? "").toLowerCase(), (cyanWallet?.walletAddress ?? "").toLowerCase()].includes(
    privateSale.buyerAddress.toLowerCase(),
  );

  return (
    <Flex gap="18px" direction="column">
      <ItemsMetadata item={nft} seller={privateSale.sellerAddress} price={price} currency="ETH" isCreating={false} />
      <Flex gap="2px" direction="column">
        {error && (
          <div style={{ marginBottom: "18px" }}>
            <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />
          </div>
        )}
        {/* HIDING TEMPORARY
        {chosenCollection && (
          <Flex w="100%" justifyContent="space-between" alignItems="center" mb="11px">
            <Text color="gray0" size="sm">{`Price`}</Text>
            <CopyText
              value={`${DAPP_URL}/bnpl/${
                CHAIN_IDS_TO_NAMES[chosenCollection.chainId as SupportedChainId] ?? "mainnet"
              }/${chosenCollection.slug}?tokenId=${nft.tokenId}`}
              color="gray0"
            >
              Copy Link
            </CopyText>
          </Flex>
        )} */}
        <PrivateSaleInput
          price={price}
          onInputChange={() => {}}
          currencies={[currency]}
          currency={currency}
          onCurrencyChange={() => {}}
          active
          disabled
          hideDown
        />
        <ExtraWrapper alignItems="center" justifyContent="space-between">
          <Text color="gray0" weight="400" size="xs">
            {fromFloor ? fromFloor.toFixed(2) : "-"}% from floor
          </Text>
          <Text color="gray0" weight="400" size="xs">
            {displayInUSD(usdPrice)}
          </Text>
        </ExtraWrapper>
      </Flex>
      <Flex gap="13px" justifyContent="space-between" alignItems="center">
        <Text color="gray0" size="md">{`Expires on`}</Text>
        <PillDateWrapper>
          <Text size="md" color="secondary">
            {date}
          </Text>
        </PillDateWrapper>
      </Flex>

      <Flex gap="10px">
        <StyledConfirmButton onClick={confirmPurchase} disabled={!isUserBuyer}>
          <Text color="black" size="sm" weight="700">
            {`Buy`}
          </Text>
        </StyledConfirmButton>
        <StyledConfirmButton onClick={cyanPay} disabled={!isUserBuyer}>
          <Text color="black" size="sm" weight="700" style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <CyanLogo width={15} height={15} color="black" pathColor="#0ff" /> {`Pay Later`}
          </Text>
        </StyledConfirmButton>
      </Flex>
    </Flex>
  );
};

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;

const ExtraWrapper = styled(Flex)`
  border-radius: 0 0 10px 10px;
  padding: 11px 15px;
  background-color: ${({ theme }) => theme.colors.gray10};
`;

const PillDateWrapper = styled(Flex)`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 10px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
  position: relative;
`;
