import dayjs from "dayjs";
import { BigNumber, constants } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Button, Card, Flex, SystemMessage, Text, useModal } from "@cyanco/components/theme";
import { factories as f } from "@cyanco/contract";

import { INextPayment } from "@/apis/types";
import { IPawn } from "@/components/Account/pawn.types";
import { useApeStakingUserAssets } from "@/components/ApeCoinStaking/new/ApeCoinDataContext";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { getApr, getInterestRate, getPlanPrincipleAmount } from "@/components/PlanCreation/utils";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { usePersistedWalletTypeForRelease } from "@/hooks";
import { bigNumToFloat, numberWithCommas } from "@/utils";
import { getAccountBalanceOfErc20 } from "@/utils/contract";
import { IMappedError } from "@/utils/error/msgs";

import { DefaultedPlanMetadata, PlanMetadata } from "../PlanMetadata";
import { PositionDefaulted } from "../PlanStatuses";
import { WalletOptionModal } from "../WalletOptionModal";
import { WalletSelectorForNFTRelease } from "../WalletSelectorForNFTRelease";
import { RevivalDetail } from "./RevivalDetail";
import { RevivalPaymentLoading } from "./RevivalPaymentLoading";
import { PlanRevivalProgress } from "./RevivalProgress";

export const RevivalModal = ({
  pawn,
  bnpl,
  planType,
  error: _error,
}: {
  pawn?: IPawn;
  bnpl?: IBNPL;
  planType: "pawn" | "bnpl";
  error?: IMappedError;
}) => {
  const plan = pawn ?? bnpl;
  if (!plan) {
    throw new Error("Plan is not defined");
  }

  const { provider, chainId, account, signer } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { setModalContent } = useModal();

  const [nextPayment, setNextPayment] = useState<INextPayment>();
  const [defaultedError, setDefaultedError] = useState<string | null>();
  const [error, setError] = useState<IMappedError | null>(_error || null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [warning, setWarning] = useState<{
    msg: string;
    title: string;
    description?: string;
  } | null>(null);
  const [isNotifiedTransferWarning, setIsNotifiedTransferWarning] = useState<boolean>(false);
  const { walletTypeForRelease } = usePersistedWalletTypeForRelease();
  const [releaseWallet, setReleaseWallet] = useState<string>(
    (walletTypeForRelease === "main" ? account : cyanWallet?.walletAddress) ?? "",
  );

  const isBnpl = planType === "bnpl" && bnpl !== undefined;
  const bnplOriginalPrice = bnpl?.price;
  const totalNumOfPaymentsByPlanType = isBnpl ? plan.totalNumOfPayments - 1 : plan.totalNumOfPayments;
  const totalNumOfPaymentsLeft = plan.totalNumOfPayments - plan.currentNumOfPayments;
  const totalLoanAmount = BigNumber.from(plan.monthlyAmount).mul(totalNumOfPaymentsByPlanType);
  const totalLeftAmount = BigNumber.from(plan.monthlyAmount).mul(totalNumOfPaymentsLeft);
  const totalCost = isBnpl ? totalLoanAmount.add(BigNumber.from(bnpl.downpaymentAmount)) : totalLoanAmount;
  const principleAmount = getPlanPrincipleAmount(plan);
  const totalRepayAmount = principleAmount.add(plan.interestFee).add(plan.serviceFeeAmount);
  const interestRate = getInterestRate({
    principleAmount,
    payAmount: totalRepayAmount,
    decimals: plan.currency.decimal,
  });
  const apr = getApr({
    maturity: plan.term * totalNumOfPaymentsByPlanType,
    interestRate,
  });

  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 4;
  }, [plan.currency.decimal]);

  const isRevivalPossible = !!plan.revivalInfo?.isRevivalPossible;
  const penaltyRate = plan.revivalInfo?.penaltyRate ?? 0;
  const penaltyAmount = plan.revivalInfo?.penaltyAmount ?? BigNumber.from(0);
  const totalPayNow = BigNumber.from(plan.monthlyAmount).add(penaltyAmount);

  useEffect(() => {
    const _setNextPayment = async () => {
      if (!signer || !chainId) return;

      const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
      const paymentPlanReader = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
      const { payAmountForCollateral, payAmountForInterest, payAmountForService, dueDate, currentPayment } =
        await paymentPlanReader.getPaymentInfoByPlanIdMapped(plan.planId, false);
      setNextPayment({
        payAmountForCollateral,
        payAmountForInterest,
        payAmountForService,
        nextPaymentDate: dueDate,
        currentPayment,
      });
    };
    _setNextPayment();
    return () => {
      setNextPayment(undefined);
    };
  }, [plan.currentNumOfPayments, plan.status]);

  const checkAccountBalanceAndProceed = async ({
    isNativeCurrency,
  }: {
    isNativeCurrency: boolean;
    isTokenApprovalRequired: boolean;
  }) => {
    if (!provider || !account) return;
    const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
      currencyAddress: plan.currency.address,
      provider: provider,
      mainWallet: account,
      chainId,
    });
    if (totalPayNow.gt(mainWalletBalance) && totalPayNow.gt(cyanWalletBalance)) {
      setError({
        title: `Not enough funds`,
        msg: `Your payment didn’t go through due to lack of funds`,
        description: ` Please double check the amount of crypto in your main wallet.`,
      });
      return;
    }
    if (cyanWallet) {
      setModalContent({
        content: (
          <WalletOptionModal
            items={[plan]}
            mainWalletBalance={mainWalletBalance}
            cyanWalletBalance={cyanWalletBalance}
            currency={plan.currency}
            paymentAmount={totalPayNow}
            totalAmount={totalLoanAmount}
            leftAmount={totalLeftAmount}
            purchasedPrice={bnplOriginalPrice}
            onNext={({ selectedWalletAddress }: { selectedWalletAddress: string }) => {
              openProgressModal({
                selectedWalletAddress,
                isNativeCurrency,
              });
            }}
          />
        ),
        title: "Select wallet to pay",
      });
    } else {
      openProgressModal({
        selectedWalletAddress: account,
        isNativeCurrency,
      });
    }
    return;
  };

  const openProgressModal = ({
    selectedWalletAddress,
    isNativeCurrency,
  }: {
    selectedWalletAddress: string;
    isNativeCurrency: boolean;
  }) => {
    if (!nextPayment) return;

    setModalContent({
      title: `Confirm Payment`,
      content: (
        <PlanRevivalProgress
          plan={{
            ...plan,
            isBnpl,
            totalNumOfPaymentsLeft,
            address: plan.metadata.collectionAddress,
            collectionName: plan.metadata.collection.name,
            totalAmount: totalLoanAmount,
            leftAmount: totalLeftAmount,
            imageUrl: plan.metadata.imageUrl,
            purchasedPrice: bnplOriginalPrice,
          }}
          selectedWalletAddress={selectedWalletAddress}
          currency={plan.currency}
          payment={{
            nextPaymentAmount: nextPayment.currentPayment,
            penaltyAmount: BigNumber.from(plan.revivalInfo?.penaltyAmount ?? 0),
            isNativeCurrency,
            releaseWallet,
          }}
          bnpl={bnpl}
          pawn={pawn}
        />
      ),
    });
  };

  const handleRevival = async () => {
    if (!nextPayment) return;
    if (!plan?.defaultedAt || dayjs(nextPayment?.nextPaymentDate).add(plan.term, "seconds").isBefore(Date.now())) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
      return;
    }
    setError(null);
    if (isProcessing || !signer || defaultedError || !chainId || !account || !cyanWallet) return;
    setIsProcessing(true);

    const totalPay = nextPayment.currentPayment.add(penaltyAmount);
    let isNativeCurrency = true;
    const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
    isNativeCurrency = plan.currency.address.toLowerCase() === constants.AddressZero.toLowerCase();
    let isTokenApprovalRequired = false;
    if (!isNativeCurrency) {
      const contract = f.SampleERC20TokenFactory.connect(plan.currency.address, signer);
      const userAllowance = await contract.allowance(account, paymentPlanV2);
      isTokenApprovalRequired = userAllowance.lt(totalPay);
      if (!isNotifiedTransferWarning && releaseWallet.toLowerCase() !== cyanWallet.walletAddress.toLowerCase()) {
        const userBalance = await contract.balanceOf(cyanWallet.walletAddress);
        if (userBalance.lt(totalPay)) {
          setWarning({
            title: `Main Wallet Release`,
            msg: `You have selected to release the NFT to your Main wallet.`,
            description: `When releasing to your Main wallet, you will need to have your Cyan Wallet funded with the payment, in the loan currency.`,
          });
          setIsNotifiedTransferWarning(true);
          setIsProcessing(false);
          return;
        }
      }
    }
    await checkAccountBalanceAndProceed({
      isNativeCurrency,
      isTokenApprovalRequired,
    });
    setIsProcessing(false);
  };

  const { loading } = useApeStakingUserAssets();

  if (loading) {
    return (
      <RevivalPaymentLoading
        planType={planType}
        plan={plan}
        totalLoanAmount={totalLoanAmount}
        totalLeftAmount={totalLeftAmount}
        bnplOriginalPrice={bnplOriginalPrice}
        totalNumOfPaymentsLeft={totalNumOfPaymentsLeft}
      />
    );
  }

  return (
    <Flex gap="18px" direction="column">
      <PlanMetadata
        plans={[
          {
            imageUrl: plan.metadata.imageUrl,
            collectionName: plan.metadata.collection.name,
            tokenId: plan.tokenId,
            totalAmount: totalLoanAmount,
            leftAmount: totalLeftAmount,
            currency: plan.currency,
            address: plan.metadata.collectionAddress,
            purchasedPrice: bnplOriginalPrice,
            isBnpl,
          },
        ]}
      />
      {!defaultedError && error && (
        <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />
      )}
      {!defaultedError && warning && (
        <SystemMessage variant="warning" title={warning.title} msg={warning.msg} description={warning.description} />
      )}
      {nextPayment && isRevivalPossible && (
        <Flex direction="column" gap="10px">
          <RevivalDetail
            plan={{
              currency: plan.currency,
              penaltyAmount: BigNumber.from(plan.revivalInfo?.penaltyAmount ?? 0),
              penaltyRate,
              principleValue: principleAmount,
              totalNumberOfPayments: totalNumOfPaymentsByPlanType,
              payments: plan.payments,
              monthlyAmount: BigNumber.from(plan.monthlyAmount),
              term: plan.term,
              createdAt: plan.createdAt,
              totalNumOfPaymentsLeft,
              isBnpl,
              totalCost,
              interestRate,
              nextPayment,
              apr,
            }}
          />
          {totalNumOfPaymentsLeft === 1 && (
            <WalletSelectorForNFTRelease
              selectedWallet={releaseWallet}
              onReleaseWalletChange={(wallet: string) => setReleaseWallet(wallet)}
              plans={[plan]}
              isFullPayment={true}
              onMainWalletStateChange={disabled => {
                if (disabled) {
                  setWarning({
                    title: `Release Options Limited`,
                    msg: `One or more items you have selected have an existing staking plan. Releasing to your Main Wallet will become available once the staked items have been removed.`,
                  });
                } else {
                  setWarning(null);
                }
              }}
            />
          )}

          <Card p={"15px"}>
            <Flex justifyContent="space-between" w="100%">
              <Flex direction="column" gap="4px" w="100%">
                <Text size="sm" weight="500" color="gray0">
                  {`Pay to revive loan:`}
                </Text>
                <Text size="lg" weight="500" color="secondary">
                  {`${numberWithCommas(bigNumToFloat(totalPayNow, plan.currency.decimal), formatNumber)} ${
                    plan.currency.symbol
                  }`}
                </Text>
              </Flex>
              <Box w="120px">
                <Button loading={isProcessing} onClick={handleRevival}>{`Pay`}</Button>
              </Box>
            </Flex>
          </Card>
        </Flex>
      )}
      {!isRevivalPossible && (
        <>
          <PositionDefaulted />
          <DefaultedPlanMetadata
            plan={{
              totalNumOfPaymentsLeft,
              totalNumOfPayments: plan.totalNumOfPayments,
              defaultedAt: plan.defaultedAt || new Date(),
            }}
          />
        </>
      )}
    </Flex>
  );
};
