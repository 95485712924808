import { BigNumber, ethers, utils } from "ethers";
import orderBy from "lodash.orderby";
import { FC, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAsyncAbortable } from "react-async-hook";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { factories as f } from "@cyanco/contract";

import { fetchUSDPrice } from "@/apis/coinbase";
import { fetchUserTokens as fetchUserTokensBe } from "@/apis/user";
import { IUserToken } from "@/apis/user/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apeCoinContract, apeStakingContract } from "@/config";
import { CYAN_SUPPORTED_CHAIN_IDS, SupportedChainId } from "@/constants/chains";
import { IsInTestDrive, getCryptoSymbolForChain, isPromiseFulfilled } from "@/utils";

import ethereumLogoUrl from "../../assets/images/ethereum-logo.png";
import polygonLogoUrl from "../../assets/images/polygon-logo.svg";
import { WalletTypes, useWalletTabContext } from "../Account/AccountPageContext";
import { useAppContext } from "../AppContextProvider";
import { useVaults } from "../Vault/VaultDataProvider";

interface ITokenContext {
  tokens: Array<IUserToken & { tokenInUsd?: number }>;
  fetchUserTokens: (a: string) => void;
  refreshUserTokens: () => Promise<void>;
}
const TokenContext = createContext<ITokenContext>({
  tokens: [],
  fetchUserTokens: () => {},
  refreshUserTokens: async () => {},
});

export const TokenContextProvider: FC = ({ children }) => {
  const { usdPrice } = useAppContext();
  const cyanWallet = useCyanWallet();
  const { chainId, account, provider } = useWeb3React();
  const [tokens, setTokens] = useState<Array<IUserToken & { tokenInUsd?: number }>>([]);
  const [tokensBe, setTokensBe] = useState<Array<IUserToken & { tokenInUsd?: number }>>([]);
  const [cyanWalletTokensBe, setCyanWalletTokensBe] = useState<Array<IUserToken & { tokenInUsd?: number }>>([]);
  const { vaults } = useVaults();

  useAsyncAbortable(
    async abortSignal => {
      const allTokens = [...cyanWalletTokensBe, ...tokensBe];

      const isApeCoinStakingSupported = chainId === SupportedChainId.MAINNET || chainId === SupportedChainId.SEPOLIA;
      if (provider && account && isApeCoinStakingSupported) {
        const apeCoinStakingContract = f.ApeCoinStakingFactory.connect(apeStakingContract, provider);
        const zeroBalanceApe = {
          name: "ApeCoin",
          symbol: "APE",
          decimal: 18,
          imageUrl: "https://cryptologos.cc/logos/apecoin-ape-ape-logo.png?v=023",
          address: apeCoinContract,
          tokenBalance: BigNumber.from(0),
        };
        if (
          cyanWallet &&
          !allTokens.some(({ isCyanWallet, address }) => isCyanWallet && address.toLowerCase() === apeCoinContract)
        ) {
          const { stakedAmount } = await apeCoinStakingContract.addressPosition(cyanWallet.walletAddress);
          if (stakedAmount.gt(0))
            allTokens.push({
              ...zeroBalanceApe,
              isCyanWallet: true,
            });
        }
        if (
          !allTokens.some(({ isCyanWallet, address }) => !isCyanWallet && address.toLowerCase() === apeCoinContract)
        ) {
          const { stakedAmount } = await apeCoinStakingContract.addressPosition(account);
          if (stakedAmount.gt(0)) {
            allTokens.push({
              ...zeroBalanceApe,
              isCyanWallet: false,
            });
          }
        }
      }
      const promises = allTokens.map(async token => {
        if (!vaults) return token;
        const vault = vaults.find(
          ({ contractTokenAddress }) =>
            contractTokenAddress && contractTokenAddress.toLowerCase() === token.address.toLowerCase(),
        );
        if (vault && token.symbol.startsWith("CV")) {
          return {
            ...token,
            tokenInUsd: parseFloat(utils.formatUnits(vault.price || 0, vault.decimals)) * usdPrice[vault.currency],
          };
        } else {
          try {
            const usdPrice = await fetchUSDPrice({ currencies: [token.symbol.toUpperCase()], abortSignal });
            return usdPrice
              ? {
                  ...token,
                  tokenInUsd: parseFloat(usdPrice[token.symbol.toUpperCase()].amount),
                }
              : token;
          } catch (e) {
            console.error(e);
            return token;
          }
        }
      });
      const promiseSettled = await Promise.allSettled(promises);
      const tokensWithPrice = promiseSettled.filter(isPromiseFulfilled).map(({ value }) => value);
      setTokens(tokensWithPrice);
    },
    [vaults, tokensBe, cyanWalletTokensBe],
  );

  const fetchNativeCurrencyFromWallet = async (account: string, chainId: SupportedChainId) => {
    let balance = BigNumber.from(0);
    if (provider) {
      balance = await provider.getBalance(account);
    }
    const walletData: IUserToken = {
      name: chainId === SupportedChainId.POLYGON ? "POL" : "Ethereum",
      symbol: getCryptoSymbolForChain(chainId),
      decimal: 18,
      imageUrl: chainId === SupportedChainId.POLYGON ? polygonLogoUrl : ethereumLogoUrl,
      address: ethers.constants.AddressZero,
      tokenBalance: balance,
      isCyanWallet: false,
    };
    return walletData;
  };

  const fetchUserTokens = async (wallet: string, isCyanWallet?: boolean) => {
    if (!CYAN_SUPPORTED_CHAIN_IDS.includes(chainId)) return;

    const tokensBe = await fetchUserTokensBe({ chainId, wallet });
    const userBalance = await fetchNativeCurrencyFromWallet(wallet, chainId);
    tokensBe.push(userBalance);

    if (isCyanWallet) {
      setCyanWalletTokensBe(tokensBe.map(token => ({ ...token, isCyanWallet })));
    } else {
      setTokensBe(tokensBe.map(token => ({ ...token, isCyanWallet: isCyanWallet ?? false })));
    }
  };

  const refreshUserTokens = async () => {
    if (account) await fetchUserTokens(account);
    if (cyanWallet) await fetchUserTokens(cyanWallet.walletAddress, true);
  };

  useEffect(() => {
    if (account && !IsInTestDrive) {
      fetchUserTokens(account);
    } else {
      setTokensBe([]);
    }
  }, [chainId, account, IsInTestDrive]);

  useEffect(() => {
    if (cyanWallet) {
      fetchUserTokens(cyanWallet.walletAddress, true);
    } else {
      setCyanWalletTokensBe([]);
    }
  }, [chainId, cyanWallet]);

  return (
    <TokenContext.Provider
      value={{
        tokens,
        fetchUserTokens,
        refreshUserTokens,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export const useUserTokenContext = () => {
  return useContext(TokenContext);
};

export const useUserTokens = () => {
  const { tokens, fetchUserTokens, refreshUserTokens } = useContext(TokenContext);
  const { selectedTokenSearch, selectedWalletType } = useWalletTabContext();
  const userTokensFiltered = useMemo(() => {
    if (!tokens) return [];
    if (selectedTokenSearch !== "") {
      return tokens.filter(
        token =>
          (token.symbol.toLowerCase().startsWith(selectedTokenSearch.toLowerCase()) ||
            token.name.toLowerCase().startsWith(selectedTokenSearch.toLowerCase()) ||
            token.address.toLowerCase().startsWith(selectedTokenSearch.toLowerCase())) &&
          ((selectedWalletType === WalletTypes.mainWallet && !token.isCyanWallet) ||
            (selectedWalletType === WalletTypes.cyanWallet && token.isCyanWallet) ||
            selectedWalletType === WalletTypes.allWallets),
      );
    } else {
      return tokens.filter(
        token =>
          (selectedWalletType === WalletTypes.mainWallet && !token.isCyanWallet) ||
          (selectedWalletType === WalletTypes.cyanWallet && token.isCyanWallet) ||
          selectedWalletType === WalletTypes.allWallets,
      );
    }
  }, [tokens, selectedTokenSearch, selectedWalletType]);

  return {
    tokens: [
      ...userTokensFiltered.filter(token => token.symbol === "ETH"),
      ...orderBy(
        userTokensFiltered.filter(token => token.symbol !== "ETH"),
        "name",
        "asc",
      ),
    ],
    userTokensFiltered,
    fetchUserTokens,
    refreshUserTokens,
  };
};
