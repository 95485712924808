import { createContext, useContext, useState } from "react";

const FilterContext = createContext<{
  openAttributes: string[];
  setOpenAttributes: (attributes: string[]) => void;
  showRarityFilter: boolean;
  setShowRarityFilter: (show: boolean) => void;
  showCurrencyFilter: boolean;
  setShowCurrencyFilter: (show: boolean) => void;
  showMarketplaceFilter: boolean;
  setShowMarketplaceFilter: (show: boolean) => void;
}>({
  openAttributes: [],
  setOpenAttributes: (_attributes: string[]) => {},
  showRarityFilter: false,
  setShowRarityFilter: (_show: boolean) => {},
  showCurrencyFilter: false,
  setShowCurrencyFilter: (_show: boolean) => {},
  showMarketplaceFilter: true,
  setShowMarketplaceFilter: (_show: boolean) => {},
});

export const FilterContextProvider: React.FC = ({ children }) => {
  const [openAttributes, setOpenAttributes] = useState<string[]>([]);
  const [showRarityFilter, setShowRarityFilter] = useState<boolean>(false);
  const [showCurrencyFilter, setShowCurrencyFilter] = useState<boolean>(false);
  const [showMarketplaceFilter, setShowMarketplaceFilter] = useState<boolean>(false);
  return (
    <FilterContext.Provider
      value={{
        openAttributes,
        setOpenAttributes,
        showRarityFilter,
        setShowRarityFilter,
        showCurrencyFilter,
        setShowCurrencyFilter,
        showMarketplaceFilter,
        setShowMarketplaceFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};
