import { JsonRpcSigner } from "@ethersproject/providers";
import * as Sentry from "@sentry/react";
import { AuthEngineTypes } from "@walletconnect/auth-client";
import { ProposalTypes, SessionTypes } from "@walletconnect/types";
import { useWeb3React } from "@web3-react/core";
import { createContext, useEffect, useState } from "react";

import { CyanWallet } from "@usecyan/cyan-wallet";
import { NoWalletError } from "@usecyan/cyan-wallet/errors";

import { ICyanWalletContext } from "./types";

const IsInTestDrive = window.location.hostname.includes("testdrive");

export const CyanWalletContext = createContext<ICyanWalletContext | undefined>(undefined);
export const CyanWalletContextProvider = ({ children }: { children: React.ReactChild }) => {
  const { provider, account } = useWeb3React();
  const [cyanWallet, setCyanWallet] = useState<CyanWallet | null>(null);
  const [sessions, setSessions] = useState<SessionTypes.Struct[]>([]);
  const [sessionProposals, setSessionProposals] = useState<ProposalTypes.Struct[]>([]);
  const [authRequests, setAuthRequests] = useState<AuthEngineTypes.PendingRequest[]>([]);
  const [initializing, setInitializing] = useState(false);

  const signer = provider?.getSigner();

  const createNewWallet = async (signer: JsonRpcSigner) => {
    await CyanWallet.createNewWallet(signer);
    const cyanWallet = await CyanWallet.initialize(signer, false);
    setCyanWallet(cyanWallet ?? null);
  };

  const initCyanWallet = async (isTestDrive?: boolean) => {
    if (!signer) return;
    try {
      setInitializing(true);
      const cyanWallet = await CyanWallet.initialize(signer, isTestDrive);
      setCyanWallet(cyanWallet ?? null);
    } catch (e) {
      setCyanWallet(null);
      if (e instanceof NoWalletError) {
        return;
      }
      Sentry.captureException(e);
      console.error(e);
    } finally {
      setInitializing(false);
    }
  };

  useEffect(() => {
    if (!provider) {
      if (!cyanWallet) return;
      return setCyanWallet(null);
    }
    initCyanWallet(IsInTestDrive);
  }, [provider, account, IsInTestDrive]);

  return (
    <CyanWalletContext.Provider
      value={{
        cyanWallet,
        createNewWallet,
        initCyanWallet,
        sessions,
        setSessions,
        sessionProposals,
        setSessionProposals,
        authRequests,
        setAuthRequests,
        initializing,
      }}
    >
      {children}
    </CyanWalletContext.Provider>
  );
};
