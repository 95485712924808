import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";

import { Text } from "@cyanco/components/theme/v3";

import { useWalletModalToggle as useWalletModalToggleConnect } from "@/state/application/hooks";

import WalletModal from "../WalletModal";
import { AccountMenu } from "./AccountMenu";

const Web3StatusConnect = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors.cyan};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: 0.2s;
  cursor: pointer;
  padding: 0 0.7rem;
  transition: 0.2s;
  height: 30px;
  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: 414px) {
    padding: 0.5rem;
  }

  @media only screen and (max-width: 1080px) {
    border: none;

    :hover {
      border: none;
    }
  }
`;

const Web3StatusInner = () => {
  const { account } = useWeb3React();
  const toggleWalletModal = useWalletModalToggleConnect();
  if (account) {
    return <AccountMenu />;
  }

  return (
    <Web3StatusConnect onClick={toggleWalletModal}>
      <Text color="black" weight="600" size="sm">{`Connect Wallet`}</Text>
    </Web3StatusConnect>
  );
};

const Web3Status = () => {
  return (
    <>
      <Web3StatusInner />
      <WalletModal />
    </>
  );
};

export default Web3Status;
