import { memo } from "react";
import styled from "styled-components";

import { SupportedCurrencies, SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { ApeCoin, EthLogo, EthLogoGray, MaticLogo, USDC } from "../images";

export const getCurrencyLogoSrc = (symbol: SupportedCurrenciesType) => {
  switch (symbol) {
    case SupportedCurrencies.MATIC:
      return MaticLogo;
    case SupportedCurrencies.APE:
      return ApeCoin;
    case SupportedCurrencies.USDC:
      return USDC;
    case SupportedCurrencies.WETH:
      return EthLogoGray;
    default:
      return EthLogo;
  }
};

export const CurrencyLogo = memo(({ symbol }: { symbol: SupportedCurrenciesType }) => {
  return <CurrencyImg src={getCurrencyLogoSrc(symbol)} />;
});

CurrencyLogo.displayName = "CurrencyLogo";

const CurrencyImg = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 3px;
`;
