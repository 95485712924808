import { useWeb3React } from "@web3-react/core";
import { FC, createContext, useContext, useEffect, useState } from "react";

import { useModal } from "@cyanco/components/theme/v3";

import { ApePlanStatuses, fetchUserApePlans } from "@/apis/ape-plans";
import { PeerPlanPaymentNumber, PeerPlanStatuses } from "@/apis/p2p/types";
import { isUserNft } from "@/apis/user";
import { IVaultLiquidatedNft } from "@/apis/vault/admin/types";

import { ITransaction, useTransactions } from "../hooks/useTransactions";
import {
  useBNPLPositions,
  useCreatedLoanBids,
  usePawnPositions,
  usePeerPlans,
  useUserAssets,
} from "./Account/AccountDataContext";
import { useSelectedItems } from "./Account/SelectedItemsContext";
import { isPawnPlan } from "./Account/pawn.types";
import { useApeStakingUserAssets } from "./ApeCoinStaking/new/ApeCoinDataContext";
import {
  useSelectedStakingNftsContext,
  useSelectedUnstakingNftsContext,
} from "./ApeCoinStaking/new/ApeCoinPageContext";
import { useAppContext } from "./AppContextProvider";
import { BNPLStatuses, isBnplPlan } from "./Bnpl/bnpl.types";
import { usePointData } from "./CyanPoints/PointDataContext";
import SetupAlertsModal from "./SetupAlerts";
import { useVaultDataContext } from "./Vault/VaultDataProvider";

interface ITransactionContext {
  transactions: Array<ITransaction>;
  addTransaction: (v: Omit<ITransaction, "expiresAt"> & { expiresAt?: number }) => void;
  setTransactions: (v: ITransaction[] | ((v: ITransaction[]) => ITransaction[])) => void;
  vaultLiquidatedNfts: IVaultLiquidatedNft[];
  paymentLiquidatedNfts: number[];
  setVaultLiquidatedNfts: React.Dispatch<React.SetStateAction<IVaultLiquidatedNft[]>>;
  setPaymentLiquidatedNfts: React.Dispatch<React.SetStateAction<number[]>>;
}
const TransactionContext = createContext<ITransactionContext>({
  transactions: [],
  async addTransaction() {},
  async setTransactions() {},
  vaultLiquidatedNfts: [],
  paymentLiquidatedNfts: [],
  setPaymentLiquidatedNfts() {},
  setVaultLiquidatedNfts() {},
});

export const TransactionContextProvider: FC = ({ children }) => {
  const [vaultLiquidatedNfts, setVaultLiquidatedNfts] = useState<IVaultLiquidatedNft[]>([]);
  const [paymentLiquidatedNfts, setPaymentLiquidatedNfts] = useState<number[]>([]);

  const { account } = useWeb3React();
  const { fetchUserPoint } = usePointData();
  const { fetchPositions: fetchVaultPositions } = useVaultDataContext();
  const { fetchUserAssets } = useUserAssets();
  const { fetchBNPLPositions: fetchBnplPositionsV2 } = useBNPLPositions();
  const { fetchPawnPositions: fetchPawnPositionsV2 } = usePawnPositions();
  const { fetchPeerPlans } = usePeerPlans();
  const { fetchUserCreatedLoanBids } = useCreatedLoanBids();
  const { transactions, addTransaction, setTransactions } = useTransactions();
  const { setFireConfetti } = useAppContext();
  const [openSetupModal, setOpenSetupModal] = useState(false);
  const { setModalContent, unsetModal, setModalChildContent, onBackModal } = useModal();
  const { setItems: setUsersSelectedItems } = useSelectedItems();
  const { refreshAssets } = useApeStakingUserAssets();
  const { setItems: setStakingItems } = useSelectedStakingNftsContext();
  const { setItems: setUnstakingItems } = useSelectedUnstakingNftsContext();

  useEffect(() => {
    if (!transactions.length || !account) return;
    const _refetchUntilBackendSettled = (): NodeJS.Timer => {
      return setInterval(async () => {
        // Fetches data from backend and removes from transactions if exist.
        const pawnCreateTransactions = transactions.filter(
          txn => txn.type === "pawn-create" || txn.type === "plan-refinance",
        );
        if (pawnCreateTransactions.length > 0) {
          const newPositions = await fetchPawnPositionsV2();
          const groupedByTxnHash = pawnCreateTransactions.reduce<{
            [key: string]: ITransaction[];
          }>((acc, cur) => {
            const item = acc[cur.hash];
            return {
              ...acc,
              [cur.hash]: item ? [...item, cur] : [cur],
            };
          }, {});
          for (const hash of Object.keys(groupedByTxnHash)) {
            let isTransactionDone = true;
            const txns = groupedByTxnHash[hash];
            for (const txn of txns) {
              if (
                !newPositions.some(
                  position =>
                    position.planId === txn.data?.planId &&
                    txn.data?.tokenId === position.tokenId &&
                    position.metadata.collectionAddress === txn.data?.contractAddress,
                )
              ) {
                isTransactionDone = false;
              }
            }
            if (isTransactionDone) {
              setUsersSelectedItems(oldItems => {
                return oldItems.filter(item => {
                  if (
                    isUserNft(item) &&
                    txns.some(txn => txn.data?.tokenId === item.tokenId && item.address === txn.data?.contractAddress)
                  ) {
                    return false;
                  }
                  return true;
                });
              });
              setTransactions(oldTxns => {
                return oldTxns.filter(({ hash: _hash }) => _hash !== hash);
              });
              setTimeout(() => {
                if (txns[0].type === "plan-refinance") {
                  fetchPawnPositionsV2();
                  fetchBnplPositionsV2();
                }
                fetchUserPoint();
              }, 5000);
              setFireConfetti(true);
            }
          }
        }

        const pawnPayTransactions = transactions.filter(txn => txn.type === "pawn-pay");
        if (pawnPayTransactions.length > 0) {
          const newPositions = await fetchPawnPositionsV2();
          const groupedByTxnHash = pawnPayTransactions.reduce<{
            [key: string]: ITransaction[];
          }>((acc, cur) => {
            const item = acc[cur.hash];
            return {
              ...acc,
              [cur.hash]: item ? [...item, cur] : [cur],
            };
          }, {});
          for (const hash of Object.keys(groupedByTxnHash)) {
            let isTransactionDone = true;
            const txns = groupedByTxnHash[hash];
            for (const txn of txns) {
              if (
                !newPositions.some(
                  position =>
                    position.planId === txn.data?.planId &&
                    txn.data?.currentNumOfPayments !== position.currentNumOfPayments,
                )
              ) {
                isTransactionDone = false;
              }
            }
            if (isTransactionDone) {
              setUsersSelectedItems(oldItems => {
                return oldItems.filter(item => {
                  if (isPawnPlan(item) && txns.some(txn => txn.data?.planId === item.planId)) {
                    return false;
                  }
                  return true;
                });
              });
              setTransactions(oldTxns => {
                return oldTxns.filter(({ hash: _hash }) => _hash !== hash);
              });
              if (
                newPositions.some(
                  position =>
                    position.totalNumOfPayments === position.currentNumOfPayments &&
                    txns.some(txn => txn.data?.planId === position.planId),
                )
              ) {
                fetchUserAssets();
                setTimeout(() => {
                  fetchUserAssets();
                  fetchUserPoint();
                }, 5000);
              }
              setFireConfetti(true);
            }
          }
        }

        const p2pCreateTransactions = transactions.filter(txn => txn.type === "p2p-create");
        const p2pPayTransactions = transactions.filter(txn => txn.type === "p2p-pay");
        const p2pUpdateTransactions = transactions.filter(txn => txn.type === "p2p-update");
        const p2pLiquidateTransactions = transactions.filter(txn => txn.type === "p2p-liquidate");
        const p2pOfferCancelTransactions = transactions.filter(txn => txn.type === "p2p-offer-cancel");

        if (
          p2pCreateTransactions.length > 0 ||
          p2pPayTransactions.length > 0 ||
          p2pUpdateTransactions.length > 0 ||
          p2pLiquidateTransactions.length > 0
        ) {
          const peerPlans = await fetchPeerPlans();
          peerPlans.forEach(position => {
            const p2pCreateTxn = p2pCreateTransactions.find(
              txn =>
                txn.data?.signature.toString().toLowerCase() === position.loanBid.signature.toLowerCase() &&
                txn.data?.tokenId === position.tokenId &&
                txn.data?.contractAddress === position.collectionAddress,
            );
            const p2pDoneTxn = p2pPayTransactions.find(
              txn =>
                txn.data?.planId === position.planId &&
                txn.data?.tokenId === position.tokenId &&
                txn.data?.contractAddress === position.collectionAddress &&
                position.payments.some(
                  payment =>
                    payment.txnHash.toLowerCase() === txn.hash.toLowerCase() &&
                    payment.paymentNumber === PeerPlanPaymentNumber.COMPLETED,
                ),
            );
            const p2pPayTxn = p2pPayTransactions.find(
              txn =>
                !p2pDoneTxn &&
                txn.data?.planId === position.planId &&
                txn.data?.tokenId === position.tokenId &&
                txn.data?.contractAddress === position.collectionAddress &&
                position.payments.some(payment => payment.txnHash.toLowerCase() === txn.hash.toLowerCase()),
            );
            const p2pUpdateTxn = p2pUpdateTransactions.find(
              txn => txn.data?.planId === position.planId && txn.data?.isExtendable === position.isExtendable,
            );
            const p2pLiquidateTxn = p2pLiquidateTransactions.find(
              txn => txn.data?.planId === position.planId && position.status === PeerPlanStatuses.LIQUIDATED,
            );

            if (p2pCreateTxn) {
              setTransactions(oldTxns => {
                return oldTxns.filter(({ hash }) => p2pCreateTxn.hash !== hash && p2pCreateTxn.expiresAt > Date.now());
              });
            }
            if (p2pPayTxn) {
              setTransactions(oldTxns => {
                return oldTxns.filter(({ hash }) => p2pPayTxn.hash !== hash && p2pPayTxn.expiresAt > Date.now());
              });
            }
            if (p2pDoneTxn) {
              setTransactions(oldTxns => {
                return oldTxns.filter(({ hash }) => p2pDoneTxn.hash !== hash && p2pDoneTxn.expiresAt > Date.now());
              });
            }
            if (p2pUpdateTxn) {
              setTransactions(oldTxns => {
                return oldTxns.filter(({ hash }) => p2pUpdateTxn.hash !== hash && p2pUpdateTxn.expiresAt > Date.now());
              });
            }
            if (p2pLiquidateTxn) {
              setTransactions(oldTxns => {
                return oldTxns.filter(
                  ({ hash }) => p2pLiquidateTxn.hash !== hash && p2pLiquidateTxn.expiresAt > Date.now(),
                );
              });
            }

            if (p2pDoneTxn || p2pPayTxn || p2pCreateTxn || p2pLiquidateTxn || p2pUpdateTxn) {
              setFireConfetti(true);
            }
            if (p2pDoneTxn || p2pCreateTxn) {
              setTimeout(() => {
                fetchUserAssets();
                fetchUserCreatedLoanBids();
              }, 5000);
            }
          });
        }

        if (p2pOfferCancelTransactions.length > 0) {
          const loanBids = await fetchUserCreatedLoanBids();
          loanBids.forEach(offer => {
            const p2pOfferCancelTxn = p2pOfferCancelTransactions.find(
              txn => txn.data?.loanId === offer.id && !offer.isActive,
            );
            if (p2pOfferCancelTxn) {
              setTransactions(oldTxns => {
                return oldTxns.filter(
                  ({ hash }) => p2pOfferCancelTxn.hash !== hash && p2pOfferCancelTxn.expiresAt > Date.now(),
                );
              });
            }
            if (p2pOfferCancelTxn) {
              setFireConfetti(true);
            }
          });
        }

        if (transactions.find(txn => txn.type === "bnpl-create" || txn.type === "bnpl-pay")) {
          const newPositions = await fetchBnplPositionsV2();
          newPositions.forEach(position => {
            transactions.forEach(async txn => {
              const isActivated =
                txn.type === "bnpl-create" &&
                txn.data?.planId === position.planId &&
                position.status === BNPLStatuses.Activated;

              const isRejected =
                txn.type === "bnpl-create" &&
                txn.data?.planId === position.planId &&
                position.status === BNPLStatuses.Rejected;

              const isPayDone =
                txn.type === "bnpl-pay" &&
                txn.data?.planId === position.planId &&
                txn.data.currentNumOfPayments !== position.currentNumOfPayments;

              const isCompleted =
                txn.type === "bnpl-pay" &&
                txn.data?.planId === position.planId &&
                position.currentNumOfPayments === position.totalNumOfPayments;

              if (isActivated || isPayDone || isCompleted || isRejected) {
                setTransactions(oldTxns =>
                  oldTxns.filter(({ hash }) => txn.hash !== hash && txn.expiresAt > Date.now()),
                );
                if (isActivated || isCompleted) {
                  setFireConfetti(true);
                }
                fetchBnplPositionsV2();
              }
              if (isCompleted || isPayDone) {
                setUsersSelectedItems(oldItems =>
                  oldItems.filter(item => {
                    return (
                      isBnplPlan(item) &&
                      newPositions.some(
                        position =>
                          txn.data?.planId === position.planId &&
                          position.metadata.collectionAddress.toLowerCase() ===
                            item.metadata.collectionAddress.toLowerCase() &&
                          position.tokenId === item.tokenId,
                      )
                    );
                  }),
                );
              }
              if (isCompleted) {
                fetchUserAssets();
              }
              if (isCompleted || isActivated) {
                setTimeout(() => {
                  fetchUserPoint();
                }, 5000);
              }
            });
          });
        }
        if (transactions.find(txn => txn.type === "vault-unstake" || txn.type === "vault-stake")) {
          const newPositions = await fetchVaultPositions();
          transactions.forEach(txn => {
            if (txn.type === "vault-unstake") {
              const unstakedPosition = newPositions.find(({ vaultId }) => vaultId === txn.data?.vaultId);
              const settledPosition = newPositions.find(({ lastTxn }) => lastTxn === txn.hash);
              if (!unstakedPosition || settledPosition) {
                setTransactions(oldTxns =>
                  oldTxns.filter(({ hash }) => txn.hash !== hash && txn.expiresAt > Date.now()),
                );
                return;
              }
            }
            newPositions.forEach(position => {
              if (txn.type === "vault-stake" && txn.hash === position.lastTxn) {
                setTransactions(oldTxns =>
                  oldTxns.filter(({ hash }) => txn.hash !== hash && txn.expiresAt > Date.now()),
                );
              }
            });
          });
        }
        if (
          transactions.find(
            txn =>
              txn.type === "ape-plan-complete" ||
              txn.type === "ape-plan-create" ||
              txn.type == "ape-coin-plan-complete" ||
              txn.type === "ape-coin-plan-create",
          )
        ) {
          const apePlans = await fetchUserApePlans({ wallet: account });
          const apePlanCreateTransactions = transactions.filter(txn => txn.type === "ape-plan-create");
          if (apePlanCreateTransactions.length > 0) {
            const groupedByTxnHash = apePlanCreateTransactions.reduce<{
              [key: string]: ITransaction[];
            }>((acc, cur) => {
              const item = acc[cur.hash];
              return {
                ...acc,
                [cur.hash]: item ? [...item, cur] : [cur],
              };
            }, {});

            for (const hash of Object.keys(groupedByTxnHash)) {
              let isTransactionDone = true;
              const txns = groupedByTxnHash[hash];
              for (const txn of txns) {
                if (!apePlans.some(plan => plan.id === txn.data?.planId && plan.status === ApePlanStatuses.Started)) {
                  isTransactionDone = false;
                }
              }
              if (isTransactionDone) {
                setStakingItems(oldItems => {
                  return oldItems.filter(item => {
                    if (
                      txns.some(txn => txn.data?.tokenId === item.tokenId && item.address === txn.data?.contractAddress)
                    ) {
                      return false;
                    }
                    return true;
                  });
                });
                setTimeout(async () => {
                  await refreshAssets();
                }, 2000);
                setTransactions(oldTxns => {
                  return oldTxns.filter(({ hash: _hash }) => _hash !== hash);
                });
                setFireConfetti(true);
              }
            }
          }
          const apePlanCompleteTransactions = transactions.filter(txn => txn.type === "ape-plan-complete");
          if (apePlanCompleteTransactions.length > 0) {
            const groupedByTxnHash = apePlanCompleteTransactions.reduce<{
              [key: string]: ITransaction[];
            }>((acc, cur) => {
              const item = acc[cur.hash];
              return {
                ...acc,
                [cur.hash]: item ? [...item, cur] : [cur],
              };
            }, {});

            for (const hash of Object.keys(groupedByTxnHash)) {
              let isTransactionDone = true;
              const txns = groupedByTxnHash[hash];
              for (const txn of txns) {
                if (!apePlans.some(plan => plan.id === txn.data?.planId && plan.status === ApePlanStatuses.Completed)) {
                  isTransactionDone = false;
                }
              }
              if (isTransactionDone) {
                setUnstakingItems(oldItems => {
                  return oldItems.filter(item => {
                    if (
                      txns.some(txn => txn.data?.tokenId === item.tokenId && item.address === txn.data?.contractAddress)
                    ) {
                      return false;
                    }
                    return true;
                  });
                });
                setTimeout(async () => {
                  await refreshAssets();
                }, 2000);
                setTransactions(oldTxns => {
                  return oldTxns.filter(({ hash: _hash }) => _hash !== hash);
                });
                setFireConfetti(true);
              }
            }
          }
          apePlans.forEach(plan => {
            transactions.forEach(async txn => {
              const isApeCoinCreated =
                txn.type === "ape-coin-plan-create" &&
                txn.data?.planId === plan.id &&
                plan.status === ApePlanStatuses.Started;

              const isApeCoinCompleted =
                txn.type === "ape-coin-plan-complete" &&
                txn.data?.planId === plan.id &&
                plan.status === ApePlanStatuses.Completed;

              if (isApeCoinCreated || isApeCoinCompleted) {
                setTransactions(oldTxns => {
                  return oldTxns.filter(({ hash }) => txn.hash !== hash && txn.expiresAt > Date.now());
                });
              }
            });
          });
        }
      }, 5000);
    };

    const intervalId = _refetchUntilBackendSettled();
    return () => clearInterval(intervalId);
  }, [transactions, setTransactions]);

  return (
    <TransactionContext.Provider
      value={{
        transactions,
        addTransaction,
        setTransactions,
        setPaymentLiquidatedNfts,
        setVaultLiquidatedNfts,
        vaultLiquidatedNfts,
        paymentLiquidatedNfts,
      }}
    >
      {children}
      {openSetupModal &&
        setModalContent({
          title: `Setup Alerts`,
          content: (
            <SetupAlertsModal
              autoClose
              onClose={unsetModal}
              onNext={nextNode => {
                setModalChildContent({ childContent: nextNode });
              }}
            />
          ),
          onClose: () => setOpenSetupModal(false),
          onBack: onBackModal,
        })}
    </TransactionContext.Provider>
  );
};

export const useTransactionContext = () => {
  const context = useContext(TransactionContext);

  return context;
};
