import { BigNumber } from "ethers";
import { useMemo } from "react";
import { AlertTriangle } from "react-feather";
import styled, { useTheme } from "styled-components";

import { getMarketPlaceName } from "@usecyan/shared/utils/marketplaces";

import { Flex } from "@cyanco/components/theme/Flex";
import { Button, Loader, SystemMessage, Text } from "@cyanco/components/theme/v3";
import { CloseX, NewTab } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, getMarketItemUrl, jumpToLink, numberWithCommas, shortenName } from "@/utils";
import { REQUEST_MORE_INFO } from "@/utils/error/msgs";

import { usePrivateSales } from "../Account/AccountDataContext";
import { PlanCreationDecimalFormatMap } from "./types";
import { isExpensiveItem } from "./utils";

type IItemType = {
  address: string;
  tokenId: string;
  planType?: "bnpl" | "pawn";
  price: BigNumber;
  collectionName: string;
  imageUrl: string | null;
  currency: ICurrency;
  marketName?: string;
  rarityStat?: string;
  error?: string;
  isFlaggedByOS?: boolean;
  isCyanWalletAsset?: boolean;
  isPossibleToBuy?: boolean;
  owner?: string;
};
type IProps = {
  items: (IItemType & { interestRate?: number })[];
  planType: "bnpl" | "pawn";
  planAmount?: BigNumber;
  onRemoveItem?: (i: { address: string; tokenId: string }) => void;
  isCreating?: boolean;
  triggeredError?: any;
};

export const ItemsMetadata: React.FC<IProps> = ({
  items,
  onRemoveItem,
  planType,
  isCreating,
  planAmount,
  triggeredError,
}) => {
  const theme = useTheme();
  const { chainId } = useWeb3React();
  const hasError = items.some(({ error }) => error);
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(items[0].currency.decimal) || 4;
  }, [items[0].currency]);

  const { privateSales } = usePrivateSales();

  const checkPrivateSale = (item: IItemType) => {
    if (planType === "bnpl") return false;
    return privateSales.some(
      p => p.privateSale?.collectionAddress === item.address.toLowerCase() && p.tokenId === item.tokenId,
    );
  };

  if (items.length === 1) {
    const item = items[0];

    return (
      <>
        <Flex gap="10px">
          <div style={{ position: "relative" }}>
            <StyledNftImage
              src={item.imageUrl || NoImage}
              alt="nft"
              hasImage={!!item.imageUrl}
              hasError={((!!item.error || checkPrivateSale(item)) && planType !== "pawn") || isExpensiveItem(item)}
            />
            {isCreating && (
              <ImageLoader>
                <Loader stroke="white" size="64px" />
              </ImageLoader>
            )}
            {item.isFlaggedByOS && <FlaggedByOS />}
          </div>
          <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 7px 0">
            <Flex justifyContent="space-between">
              <Text color="secondary" size="md" style={{ overflowWrap: "anywhere" }} weight="600">
                {shortenName(item.collectionName, 21, 20, 0)}
              </Text>
              <Text color="secondary" size="md" style={{ overflowWrap: "anywhere" }} weight="600">
                #{shortenName(item.tokenId, 10, 5)}
              </Text>
            </Flex>
            <Flex gap="5px" direction="column" w="100%">
              <Flex justifyContent="space-between">
                <Text color="gray0" size="xs" weight="500">
                  {planType === "bnpl" ? `Purchase Price` : `Appraisal Value`}
                </Text>
                <Text color="gray0" size="xs" weight="500">
                  {numberWithCommas(
                    bigNumToFixedStr(items[0].price, formatNumber, item.currency.decimal),
                    formatNumber,
                  )}{" "}
                  {item.currency.symbol}
                </Text>
              </Flex>
              {planType === "bnpl" && (
                <Flex justifyContent="space-between">
                  <Text color="gray0" size="xs" weight="500">
                    {`Marketplace`}
                  </Text>{" "}
                  {item.marketName && (
                    <Flex alignItems="center">
                      <Button
                        variant="ghost"
                        onClick={() =>
                          item.marketName &&
                          jumpToLink(getMarketItemUrl(item.marketName, item.address, item.tokenId, chainId, item.owner))
                        }
                      >
                        <Text color="gray0" size="xs" weight="500">
                          {getMarketPlaceName(item.marketName)}
                        </Text>
                      </Button>
                      <NewTab color={theme.colors.gray0} />
                    </Flex>
                  )}
                </Flex>
              )}
              {planType === "pawn" && !isCreating && (
                <Flex justifyContent="space-between">
                  <Text color="gray0" size="xs" weight="500">
                    {`NFT Rarity`}
                  </Text>
                  <Text color="gray0" size="xs" weight="500">
                    {item.rarityStat}
                  </Text>
                </Flex>
              )}

              {planType === "pawn" && isCreating && (
                <Flex justifyContent="space-between">
                  <Text color="gray0" size="xs" weight="500">
                    {`Loan Amount`}
                  </Text>
                  <Text color="gray0" size="xs" weight="500">
                    {planAmount
                      ? numberWithCommas(
                          bigNumToFixedStr(planAmount, formatNumber, item.currency.decimal),
                          formatNumber,
                        )
                      : "-"}{" "}
                    {item.currency.symbol}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        {!triggeredError && !isCreating && hasError && item.error && (
          <SystemMessage variant="warning" title={`Warning`} msg={item.error} />
        )}
        {!isCreating && planType === "bnpl" && !item?.isPossibleToBuy && (
          <SystemMessage
            variant="error"
            title="This item is likely to be rejected"
            msg={
              <Text size="xs" weight="500" color="black">
                {`Higher priced items may not be approved by the protocol. Please note gas fees to initialize the loan are nonrefundable.`}
              </Text>
            }
            description={REQUEST_MORE_INFO}
          />
        )}
      </>
    );
  }

  return (
    <>
      <NftImageWrapper>
        {items.map(item => (
          <ImageWrapper key={`${item.address}:${item.tokenId}:${item.isCyanWalletAsset ? "cyan" : "main"}`}>
            <StyledNftImage
              src={item.imageUrl || NoImage}
              key={item.tokenId}
              hasImage={!!item.imageUrl}
              hasError={!!item.error || checkPrivateSale(item) || isExpensiveItem(item)}
            />
            {onRemoveItem && (
              <RemoveFromCart
                onClick={() => {
                  onRemoveItem(item);
                }}
              >
                <CloseX color={theme.colors.primary} />
              </RemoveFromCart>
            )}
            {isCreating && (
              <ImageLoader>
                <Loader stroke="white" size="64px" />
              </ImageLoader>
            )}
          </ImageWrapper>
        ))}
      </NftImageWrapper>
      {!triggeredError && !isCreating && hasError && (
        <SystemMessage
          variant="warning"
          title={`Warning`}
          msg={
            items.find(item => !!item.error)?.error ||
            `Could not price some of the items, please remove red bordered items`
          }
        />
      )}
    </>
  );
};

export const StyledNftImage = styled.img<{ hasImage?: boolean; hasError?: boolean; isSelected?: boolean }>`
  height: ${({ isSelected, hasError }) => (isSelected || hasError ? "79px" : "85px")};
  width: ${({ isSelected, hasError }) => (isSelected || hasError ? "79px" : "85px")};
  object-fit: scale-down;
  border: ${({ hasError, isSelected }) => `${isSelected ? "3px solid cyan" : hasError ? "3px solid #f00" : "none"}`};
  border-radius: 15px;
  padding: 0;
  margin: 0;
  background-color: black;
  filter: ${({ hasImage, theme }) =>
    !hasImage &&
    theme.theme === "light" &&
    "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;

export const NftImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  max-height: 180px;
  overflow: auto;
  padding-top: 12px;
`;
const RemoveFromCart = styled.div`
  cursor: pointer;
  display: none;
  position: absolute;
  top: -5px;
  right: 0px;
  padding: 5px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary};
`;
const ImageWrapper = styled.div`
  position: relative;
  &:hover ${RemoveFromCart} {
    display: flex;
  }
`;
const ImageLoader = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: none;
`;

const FlaggedByOS = styled(AlertTriangle)`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 15px;
  width: 15px;
  z-index: 1;
  color: red;
`;
