import { useEffect, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Card, NotFound } from "@cyanco/components/theme/v3";
import { Text } from "@cyanco/components/theme/v3/core";

import { ICollectionBe } from "@/apis/collection/types";
import { useVaults } from "@/components/Vault/VaultDataProvider";
import { AvailableVaults } from "@/components/Vault/components/AvailableVaults";

import { bigNumToFloat, numberWithCommas } from "../../../utils";
import { useAppContext } from "../../AppContextProvider";
import { useLendSupportedCollectionsFiltered } from "../LendDataContext";
import { LendView, useLendSelectors } from "../LendPageContext";
import { CollectionSelectors } from "./CollectionSelectors";
import { CollectionStatsHeader, CollectionStatsRow, CollectionStatsRowLoading } from "./CollectionStats";

export const AvailableCollections = () => {
  const { selectedActivityPeriod, selectedChain, selectedCurrency, selectedView } = useLendSelectors();
  const { collections, collectionsLoading } = useLendSupportedCollectionsFiltered();
  const { vaults } = useVaults();
  const { currencySymbol, usdPrice } = useAppContext();
  const [collectionsSorted, setCollectionsSorted] = useState<ICollectionBe[]>(collections);
  useEffect(() => {
    setCollectionsSorted(collections);
  }, [collections]);

  const updateCollectionSorted = (collections: ICollectionBe[]) => {
    setCollectionsSorted([...collections]);
  };
  const calculateChangePercent = (value: number | null, changedBy: number | null): number | null => {
    if (value === null || changedBy === null) return null;
    const current = value * changedBy;
    const difference = current - value;
    const percent = difference === 0 ? 0 : (difference / (current > value ? current : value)) * 100;
    return percent;
  };
  if (selectedView === LendView.vault) {
    return <AvailableVaults />;
  }
  return (
    <Container direction="column">
      <CollectionText color="secondary">{`Collections available on Cyan`}</CollectionText>
      <ListViewContainer>
        <CollectionSelectors vaults={vaults.filter(v => v.chainId === selectedChain)} />
        <ListViewBox>
          <Header>
            <CollectionStatsHeader
              updateCollectionSorted={updateCollectionSorted}
              collectionsSorted={collectionsSorted}
              defaultCurrencySymbol={currencySymbol}
              selectedCurrency={selectedCurrency}
              usdPrice={usdPrice}
              selectedActivityPeriod={selectedActivityPeriod}
            />
          </Header>
          <ContainerBox>
            {collectionsLoading ? (
              Array.from(Array(3).keys()).map(loader => <CollectionStatsRowLoading key={loader} />)
            ) : collectionsSorted.length === 0 ? (
              <NotFound msg="No collections found" />
            ) : (
              collectionsSorted.map((collection, index) => {
                return (
                  <CollectionStatsRow
                    collection={{
                      chainId: collection.chainId,
                      currencySymbol: collection.floorAsk.price
                        ? collection.floorAsk.price.currency.symbol
                        : currencySymbol,
                      name: collection.name,
                      address: collection.address,
                      items: numberWithCommas(collection.tokenCount),
                      floorChange: calculateChangePercent(
                        collection.floorSale[selectedActivityPeriod],
                        collection.floorSaleChange[selectedActivityPeriod],
                      ),
                      floorPrice: collection.floorAsk.price
                        ? bigNumToFloat(collection.floorAsk.price.amount.raw)
                        : null,
                      volume: collection.volume[selectedActivityPeriod],
                      volumeChange: calculateChangePercent(
                        collection.volume[selectedActivityPeriod],
                        collection.volumeChange[selectedActivityPeriod],
                      ),
                      index,
                      imageUrl: collection.image,
                    }}
                    selectedCurrency={selectedCurrency}
                    usdPrice={usdPrice}
                    key={collection.address}
                  />
                );
              })
            )}
          </ContainerBox>
        </ListViewBox>
      </ListViewContainer>
    </Container>
  );
};

const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

const MainBox = styled(Card)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  margin: 1px;
  border: none;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-radius: 10px;
    box-shadow: none;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &:first-child {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
`;

const ContainerBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top: none;
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;

const CollectionText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 32 },
    { [breakpoints.tablet]: 24 },
    { [breakpoints.mobile]: 18 },
  ])};
`;

const Container = styled(Flex)`
  gap: 1.5rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])}
`;

const ListViewContainer = styled(Flex)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const Header = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 170px;
  }
  position: sticky;
  ${getStyleWithMediaQuery("position", "", [{ [breakpoints.mobile]: "static" }])};
  align-items: end;
  top: 150px;
  background: ${({ theme }) => theme.colors.primary};
  transform-style: preserve-3d;
  z-index: 5;
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    position: absolute;
    transform: translateZ(-1px);
    top: -15px;
    height: calc(100% + 15px);
    left: -150px;
    width: calc(100% + 300px);
  }
`;
