import { useMemo } from "react";
import styled from "styled-components";

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray20};
  height: 8px;
  border-radius: 8px;
  padding: 2px;
`;

const ProgressBar = styled.div<{ percent: number; color?: string }>`
  background-color: ${({ theme, color }) => color ?? theme.colors.cyan};
  border-radius: 8px;
  height: 90%;
  width: ${({ percent }) => percent}%;
`;

interface IProgress {
  steps: number;
  current: number;
  color?: string;
}
export const Progress = ({ steps, current, color }: IProgress) => {
  const percent = useMemo(() => {
    return (current / steps) * 100;
  }, [current, steps]);
  return (
    <ProgressBarWrapper>
      <ProgressBar percent={percent} color={color} />
    </ProgressBarWrapper>
  );
};
