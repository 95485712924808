import { useMemo } from "react";

import { Card, Flex, Text } from "@cyanco/components/theme";

import { IPeerPlan } from "@/apis/p2p/types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { bigNumToFixedStr, numberWithCommas } from "@/utils";

import { IPlanData } from ".";
import { StepperContainer } from "../RegularPayment";

export const RollPayment: React.FC<{
  plan: IPeerPlan;
  planDataContract: IPlanData;
}> = ({ plan, planDataContract }) => {
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(plan.loanBid.currency.decimal) || 4;
  }, [plan.loanBid.currency.decimal]);

  const apr = useMemo(() => {
    return (365 / (plan.loanBid.term / 60 / 60 / 24)) * (plan.loanBid.interestRate / 100);
  }, [plan.loanBid]);

  return (
    <Card p={"10px 8px"}>
      <Flex direction="column" gap="7px">
        <Text size="sm" weight="600" color="secondary">
          {`Interest to roll loan:`}{" "}
          {`${numberWithCommas(
            bigNumToFixedStr(planDataContract.fee, formatNumber, plan.loanBid.currency.decimal),
            formatNumber,
          )} ${plan.loanBid.currency.symbol}`}
        </Text>
        <StepperContainer>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" gap="8px">
              <Text size="xs" weight="500" color="gray0">
                {`Principal amount`}
              </Text>
            </Flex>
            <Text size="xs" weight="500" color="gray0">
              {`${numberWithCommas(
                bigNumToFixedStr(plan.loanBid.amount, formatNumber, plan.loanBid.currency.decimal),
                formatNumber,
              )} ${plan.loanBid.currency.symbol}`}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" gap="8px">
              <Text size="xs" weight="500" color="gray0">
                {`Loan length`}
              </Text>
            </Flex>
            <Text size="xs" weight="500" color="gray0">
              {`${getPaymentInterval(plan.loanBid.term)}`}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" gap="8px">
              <Text size="xs" weight="500" color="gray0">
                {`APR`}
              </Text>
            </Flex>
            <Text size="xs" weight="500" color="gray0">
              {`${apr.toFixed(2)}%`}
            </Text>
          </Flex>
        </StepperContainer>
        <Flex justifyContent="space-between">
          <Text size="xs" weight="500" color="gray0">
            {`Interest to roll`}
          </Text>
          <Text size="xs" weight="500" color="gray0">
            {`${numberWithCommas(
              bigNumToFixedStr(planDataContract.fee, formatNumber, plan.loanBid.currency.decimal),
              formatNumber,
            )} ${plan.loanBid.currency.symbol}`}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
