import { createContext, useCallback, useContext, useMemo, useState } from "react";

import { useWeb3React } from "@/components/Web3ReactProvider";

import { AccountDataContext, filterAssets } from "../Account/AccountDataContext";
import { IWalletTypes, IWalletViewStyle, WalletTypes, WalletViewStyle } from "../Account/AccountPageContext";

export enum LoanView {
  assets = "assets",
  loanOffers = "loanOffers",
}
export enum LoanOfferViewStyle {
  historical = "historical",
  current = "current",
}
type LoanPageContextType = {
  selectedWalletType: IWalletTypes;
  walletViewStyle: IWalletViewStyle;
  selectedNftSearch: string;
  setWalletViewStyle: (style: IWalletViewStyle) => void;
  setSelectedWalletType: (type: IWalletTypes) => void;
  setSelectedNftSearch: (search: string) => void;
  selectedLoanView: LoanView;
  setSelectedLoanView: (view: LoanView) => void;
};

const LoanPageContext = createContext<LoanPageContextType>({
  selectedWalletType: WalletTypes.mainWallet,
  setSelectedWalletType: () => {},
  walletViewStyle: WalletViewStyle.list,
  setWalletViewStyle: () => {},
  selectedNftSearch: "",
  setSelectedNftSearch: () => {},
  selectedLoanView: LoanView.assets,
  setSelectedLoanView: () => {},
});

export const LoanPageProvider: React.FC = ({ children }) => {
  const [selectedWalletType, setSelectedWalletType] = useState<IWalletTypes>(WalletTypes.allWallets);
  const [walletViewStyle, setWalletViewStyle] = useState<IWalletViewStyle>(WalletViewStyle.grid);
  const [selectedNftSearch, setSelectedNftSearch] = useState<string>("");
  const [selectedLoanView, setSelectedLoanView] = useState<LoanView>(LoanView.assets);
  return (
    <LoanPageContext.Provider
      value={{
        selectedWalletType,
        walletViewStyle,
        selectedNftSearch,
        setSelectedNftSearch,
        setWalletViewStyle,
        setSelectedWalletType,
        selectedLoanView,
        setSelectedLoanView,
      }}
    >
      {children}
    </LoanPageContext.Provider>
  );
};

export const useUserAssets = () => {
  const { account } = useWeb3React();
  const {
    userAssets,
    userAssetsLoading,
    fetchUserAssets,
    loadMoreUserAssets,
    cyanAssetsLoading,
    cyanAssets,
    fetchCyanAssets,
    loadMoreCyanAssets,
    pawnPositions,
    bnplPositions,
    peerPlans,
    demoAssets,
  } = useContext(AccountDataContext);
  const { selectedNftSearch, selectedWalletType } = useContext(LoanPageContext);

  const userAssetsFiltered = useMemo(() => {
    if (!userAssets || !cyanAssets) return [];
    const assets = [...cyanAssets.assets, ...userAssets.assets];
    if (selectedNftSearch !== "") {
      return assets.filter(
        asset =>
          (asset.tokenId.toLowerCase().startsWith(selectedNftSearch.toLowerCase()) ||
            asset.address.toLowerCase().startsWith(selectedNftSearch.toLowerCase()) ||
            asset.collectionName.toLowerCase().includes(selectedNftSearch.toLowerCase())) &&
          ((selectedWalletType === WalletTypes.mainWallet && !asset.isCyanWallet) ||
            (selectedWalletType === WalletTypes.cyanWallet && asset.isCyanWallet) ||
            selectedWalletType === WalletTypes.allWallets),
      );
    } else {
      return assets.filter(
        asset =>
          (selectedWalletType === WalletTypes.mainWallet && !asset.isCyanWallet) ||
          (selectedWalletType === WalletTypes.cyanWallet && asset.isCyanWallet) ||
          selectedWalletType === WalletTypes.allWallets,
      );
    }
  }, [userAssets?.assets, cyanAssets?.assets, selectedNftSearch, selectedWalletType]);

  const fetchAsset = useCallback(async () => {
    if (selectedWalletType === WalletTypes.mainWallet) {
      await fetchUserAssets();
    } else if (selectedWalletType === WalletTypes.cyanWallet) {
      await fetchCyanAssets();
    } else {
      await Promise.all([fetchUserAssets(), fetchCyanAssets()]);
    }
  }, [selectedWalletType, fetchUserAssets, fetchCyanAssets]);
  const hasMoreAssets = useMemo(() => {
    if (selectedWalletType === WalletTypes.mainWallet) {
      return !!userAssets?.continuation;
    } else if (selectedWalletType === WalletTypes.cyanWallet) {
      return !!cyanAssets?.continuation;
    } else {
      return !!userAssets?.continuation || !!cyanAssets?.continuation;
    }
  }, [selectedWalletType, userAssets, cyanAssets]);
  const loadMoreAssets = useCallback(async () => {
    if (!hasMoreAssets) return;
    if (selectedWalletType === WalletTypes.mainWallet && !!userAssets?.continuation) {
      await loadMoreUserAssets();
    } else if (selectedWalletType === WalletTypes.cyanWallet && !!cyanAssets?.continuation) {
      await loadMoreCyanAssets();
    } else {
      const promises = [];
      if (!!userAssets?.continuation) {
        promises.push(loadMoreUserAssets());
      }
      if (!!cyanAssets?.continuation) {
        promises.push(loadMoreCyanAssets());
      }
      await Promise.all(promises);
    }
  }, [selectedWalletType, loadMoreUserAssets, loadMoreCyanAssets]);
  const assetsLoading = useMemo(() => {
    if (selectedWalletType === WalletTypes.mainWallet) {
      return userAssetsLoading;
    } else if (selectedWalletType === WalletTypes.cyanWallet) {
      return cyanAssetsLoading;
    } else {
      return userAssetsLoading || cyanAssetsLoading;
    }
  }, [selectedWalletType, userAssetsLoading, cyanAssetsLoading]);
  const isDemoAsset = (tokenId: string, collectionAddress: string) => {
    return !!demoAssets.some(item => item.tokenId === tokenId && item.address === collectionAddress);
  };
  return {
    userAssets: {
      assets: filterAssets(userAssetsFiltered, [
        ...pawnPositions,
        ...bnplPositions,
        ...peerPlans.filter(plan => plan.borrowerAddress.toLowerCase() === account?.toLowerCase()),
      ]),
      hasMore: hasMoreAssets,
    },
    userAssetsFiltered,
    userAssetsLoading: assetsLoading,
    fetchUserAssets: fetchAsset,
    loadMoreUserAssets: loadMoreAssets,
    hasMore: hasMoreAssets,
    demoAssets,
    isDemoAsset,
  };
};

export const useLoanPageSelectors = () => {
  const {
    selectedWalletType,
    walletViewStyle,
    selectedNftSearch,
    setSelectedNftSearch,
    setWalletViewStyle,
    setSelectedWalletType,
    selectedLoanView,
    setSelectedLoanView,
  } = useContext(LoanPageContext);
  return {
    selectedWalletType,
    walletViewStyle,
    selectedNftSearch,
    setSelectedNftSearch,
    setWalletViewStyle,
    setSelectedWalletType,
    selectedLoanView,
    setSelectedLoanView,
  };
};
