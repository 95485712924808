import { captureException } from "@sentry/react";
import dayjs from "dayjs";
import jwt, { JwtPayload } from "jsonwebtoken";
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { getUser, login } from "@/apis/user";
import { IUser } from "@/apis/user/types";
import { IUserLog, useUserLog } from "@/hooks/userLog";

import { getSignInSignature } from "../../utils/contract";
import { useWeb3React } from "../Web3ReactProvider";

type ILocalStorage = {
  token: string;
  wallet: string;
  cyanWallet: string;
};
type ISetUserProfile = (args: {
  email?: string;
  discordUsername?: string;
  telegramUsername?: string;
  receivePaymentAlerts?: boolean;
  banExpirationDate?: Date;
}) => void;
interface IAuthContext {
  user: IUserLog | null;
  userProfile: Omit<IUser, "cyanWallet">;
  setUser: (a: IUserLog) => void;
  setUserProfile: ISetUserProfile;
  signInWallet: () => Promise<
    Pick<
      ILocalStorage & IUser,
      "token" | "wallet" | "email" | "discordUsername" | "telegramUsername" | "banExpirationDate"
    >
  >;
  fetchUserBe: () => Promise<
    Pick<ILocalStorage & IUser, "token" | "email" | "discordUsername" | "telegramUsername" | "banExpirationDate">
  >;
  refreshUserDataFromBe: (a: string) => Promise<IUser>;
}

const userProfileInit: Omit<IUser, "cyanWallet"> = {
  email: "",
  discordUsername: "",
  telegramUsername: "",
  receivePaymentAlerts: false,
  banExpirationDate: undefined,
};

export const AuthContext = createContext<IAuthContext>({
  user: null,
  userProfile: userProfileInit,
  setUser() {},
  setUserProfile() {},
  signInWallet: async () => ({
    token: "",
    wallet: "",
    cyanWallet: "",
    email: "",
    discordUsername: "",
    telegramUsername: "",
    banExpirationDate: undefined,
  }),
  fetchUserBe: async () => ({
    email: "",
    discordUsername: "",
    telegramUsername: "",
    token: "",
    banExpirationDate: undefined,
  }),
  refreshUserDataFromBe: async () => ({
    ...userProfileInit,
    cyanWallet: "",
  }),
});

export const AuthContextProvider: FC = ({ children }) => {
  const { userLogs, setUserLogs } = useUserLog();
  const [userProfile, _setUserProfile] = useState<Omit<IUser, "cyanWallet">>(userProfileInit);
  const { account, chainId, signer } = useWeb3React();
  const [fetchingProfile, setFetchingProfile] = useState(false);
  useEffect(() => {
    setUserProfile({});
  }, [account]);
  const user = useMemo(() => {
    if (account) {
      return userLogs[account] ?? null;
    }
    return null;
  }, [account, userLogs]);
  const setUser = (data: IUserLog) => {
    if (account) {
      const userLog = userLogs[account];
      setUserLogs({
        ...userLogs,
        [account]: {
          ...userLog,
          ...data,
        },
      });
    }
  };
  const setUserProfile: ISetUserProfile = data => {
    _setUserProfile({
      email: data?.email ?? "",
      discordUsername: data?.discordUsername ?? "",
      telegramUsername: data?.telegramUsername ?? "",
      receivePaymentAlerts: data?.receivePaymentAlerts ?? false,
      banExpirationDate: data?.banExpirationDate,
    });
  };
  const refreshUserDataFromBe = async (userToken: string) => {
    const { email, discordUsername, telegramUsername, receivePaymentAlerts, cyanWallet, banExpirationDate } =
      await getUser(userToken);
    setUserProfile({
      email,
      discordUsername,
      telegramUsername,
      receivePaymentAlerts,
      banExpirationDate,
    });
    return {
      email,
      discordUsername,
      telegramUsername,
      receivePaymentAlerts,
      cyanWallet,
      banExpirationDate,
    };
  };
  const signInWallet = useCallback(async () => {
    if (account && signer && chainId) {
      if (!user || !user.token || ((jwt.decode(user.token) as JwtPayload).exp || 0) <= new Date().getTime() / 1000) {
        try {
          const signature = await getSignInSignature(account, signer);
          const { token } = await login({ wallet: account, signature, chainId });
          const { email, discordUsername, telegramUsername, cyanWallet, banExpirationDate } =
            await refreshUserDataFromBe(token);
          setUser({ token, wallet: account, cyanWallet, isSigned: true, lastVisited: dayjs(), banExpirationDate });
          return { token, wallet: account, email, discordUsername, telegramUsername, cyanWallet, banExpirationDate };
        } catch (e) {
          console.error(e);
        }
      } else
        return {
          token: user?.token ?? "",
          wallet: user?.wallet ?? "",
          cyanWallet: user?.cyanWallet ?? "",
          email: userProfile.email,
          discordUsername: userProfile.discordUsername,
          telegramUsername: userProfile.telegramUsername,
          banExpirationDate: userProfile.banExpirationDate,
        };
    }
    return { token: "", wallet: "", email: "", discordUsername: "", telegramUsername: "" };
  }, [account, user]);

  const fetchUserBe = useCallback(async () => {
    let email = "";
    let discordUsername = "";
    let telegramUsername = "";
    if (account && !fetchingProfile) {
      setFetchingProfile(true);
      try {
        if (!user || !user.token || ((jwt.decode(user.token) as JwtPayload).exp || 0) <= new Date().getTime() / 1000) {
          const response = await signInWallet();
          email = response.email;
          discordUsername = response.discordUsername;
          telegramUsername = response.telegramUsername;
          setFetchingProfile(false);
          return {
            email,
            discordUsername,
            telegramUsername,
            token: response.token,
            banExpirationDate: response.banExpirationDate,
          };
        } else {
          const response = await refreshUserDataFromBe(user.token);
          setUser(response);
          email = response.email;
          discordUsername = response.discordUsername;
          telegramUsername = response.telegramUsername;
          setFetchingProfile(false);
          return {
            email,
            discordUsername,
            telegramUsername,
            token: user.token,
            banExpirationDate: response.banExpirationDate,
          };
        }
      } catch (e) {
        console.error(e);
        captureException(e);
      }
      setFetchingProfile(false);
    }
    return { email, telegramUsername, discordUsername, token: user?.token ?? "" };
  }, [account, user]);
  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userProfile,
        setUserProfile,
        signInWallet,
        fetchUserBe,
        refreshUserDataFromBe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
