import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, Text, Title, TitleContainer, TitleDescription } from "@cyanco/components/theme/v3";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { useToggleModal } from "@/state/application/hooks";
import { ApplicationModal } from "@/state/application/reducer";
import { formatCompactNumber } from "@/utils";

import { useAppContext } from "../AppContextProvider";
import { ThemeContext } from "../ThemeContextProvider";
import { AvailableCollections, CheapestNfts } from "./components";
import { PlanCart } from "./components/CollectionDetail/Cart";

export const Collections = () => {
  const { account } = useWeb3React();
  const toggleWallet = useToggleModal(ApplicationModal.WALLET);
  const navigate = useNavigate();
  const { isLightTheme } = useContext(ThemeContext);
  const { cyanStats } = useAppContext();
  const totalLoanedAmountFormatted = formatCompactNumber(
    cyanStats.loanedAmountBnplsUsd + cyanStats.loanedAmountPawnsUsd,
  );
  const formattedUsers = useMemo(() => {
    return formatCompactNumber(Math.round(cyanStats.userCount / 10) * 10);
  }, [cyanStats.userCount]);

  return (
    <>
      <Container direction="column">
        <TopContainer w="100%">
          <Hidden
            tabletDown
            style={{
              height: "100%",
            }}
          >
            <TitleContainer justifyContent="space-between">
              <Title>{`Buy NFTs Now, Pay Later`} </Title>
              <Flex pb="2rem">
                <div
                  style={{
                    flexGrow: 1,
                    width: 0,
                  }}
                >
                  <TitleDescription color="gray0">
                    Cyan is the Buy Now, Pay Later service for the metaverse, and over {formattedUsers}+ users have done{" "}
                    <TitleDescription style={{ color: !isLightTheme ? "cyan" : "#00D5D5" }}>
                      ${totalLoanedAmountFormatted} in loan volume.
                    </TitleDescription>
                  </TitleDescription>
                </div>
              </Flex>
              <Flex gap="0.7rem">
                <FilledButton onClick={() => navigate("/loans")} title="NFT Loans">
                  <Text color="primary" size="sm" weight="600">{`NFT Loans`}</Text>
                </FilledButton>
                <GhostButton variant="ghost" onClick={() => navigate("/bnpl/loan-pricer")} title="NFT Loan Pricer">
                  <HoverText color="secondary" size="sm" weight="600">{`NFT Loan Pricer`}</HoverText>
                </GhostButton>
                <BargainButton onClick={() => navigate("/bargain")} title="Shop Bargains">
                  <Text color="primary" size="sm" weight="600">{`Shop Bargains`}</Text>
                </BargainButton>
                {!account && (
                  <ConnectButton onClick={toggleWallet} title="Connect Wallet">{`Connect Wallet`}</ConnectButton>
                )}
              </Flex>
            </TitleContainer>
          </Hidden>
          <CheapestNfts />
        </TopContainer>
        <AvailableCollections />
      </Container>
      <PlanCart planType={"bnpl"} />
    </>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1240px) / 2);
  padding: 0 0 5rem 0;
  gap: 4rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 2 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 50px" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "1rem 0.5rem 0 0.5rem" }])}
`;

const TopContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 340 }], "min-width")}
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])}
`;

const HoverText = styled(Text)`
  transition: all 0.2s;
`;
const GhostButton = styled(Button)`
  border: 1px solid ${props => props.theme.colors.secondary};
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  transition: all 0.2s;
  :hover {
    border: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.secondary};
  }
  :hover ${HoverText} {
    color: ${props => props.theme.colors.primary};
  }
`;
const FilledButton = styled(Button)`
  border: 1px solid ${props => props.theme.colors.secondary};
  background-color: ${props => props.theme.colors.secondary};
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  :hover {
    background-color: ${props => props.theme.colors.secondary};
    border-color: ${props => props.theme.colors.secondary};
    opacity: 0.8;
  }
`;

const ConnectButton = styled(Button)`
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.5rem;
`;

const BargainButton = styled(Button)`
  border: 1px solid ${props => props.theme.colors.green};
  background-color: ${props => props.theme.colors.green};
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  :hover {
    background-color: ${props => props.theme.colors.green};
    border-color: ${props => props.theme.colors.green};
    opacity: 0.8;
  }
`;
