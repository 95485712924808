import { createSlice } from "@reduxjs/toolkit";

export enum ApplicationModal {
  WALLET,
  MENU,
  VAULT,
  BNPL,
  PAWN,
  EMAIL,
  BNPL_POSITION,
  PAWN_POSITION,
  VAULT_POSITION,
  NETWORK_SELECTOR,
}

interface ApplicationState {
  readonly openModal: ApplicationModal | null;
}

const initialState: ApplicationState = {
  openModal: null,
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
  },
});

export const { setOpenModal } = applicationSlice.actions;

export default applicationSlice.reducer;
