import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { ArrowDown } from "react-feather";
import { NumberFormatValues, NumericFormat, SourceInfo } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Card, CurrencyLogo, Loader, Text, useModal } from "@cyanco/components/theme/v3";
import { ChevronDown } from "@cyanco/components/theme/v3/icons";
import { CyanC } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { VaultContractAbiNames, VaultDecimalFormatMap } from "@/components/Vault/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { useGetCyanWalletLock } from "@/hooks";
import { SourceType } from "@/utils/types";

import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "../../../../constants/chains";
import {
  IsInTestDrive,
  bigNumToFloat,
  displayInUSD,
  numberWithCommas,
  roundDown,
  shortenName,
  watchBelowStakeAmount,
} from "../../../../utils";
import { useAppContext } from "../../../AppContextProvider";
import ChangeNetworkModal from "../../../ChangeNetworkModal/ChangeNetworkModal";
import { useFilteredVaults } from "../../VaultDataProvider";
import { UnstakingModal } from "./UnstakingModal";
import {
  ChevronDownWrapper,
  PillWrapper,
  StakeInput,
  StyledChevronDown,
  StyledSelect,
  VaultImage,
  VaultImageWrapper,
} from "./VaultStake";
import { StyledLink } from "./common";

export const UnstakingCard = ({
  vault,
  toggle,
  availableTokenBalance,
  selectedWallet,
}: {
  vault: IVault;
  toggle: () => void;
  availableTokenBalance?: BigNumber;
  selectedWallet: string;
}) => {
  const theme = useTheme();
  const { signer, chainId } = useWeb3React();
  const { usdPrice } = useAppContext();
  const cyanWallet = useCyanWallet();
  const { setModalContent } = useModal();
  const { vaults } = useFilteredVaults();
  const navigate = useNavigate();
  // cyan vault token
  const [unstakingAmount, setUnstakingAmount] = useState<string>("");
  // vault currency token
  const [amountInToken, setAmountInToken] = useState<string>("");
  const { getLockedDate } = useGetCyanWalletLock();
  const location = useLocation();

  const { result: { isCyanWalletLocked, lockedDate } = { isCyanWalletLocked: false, lockedDate: 0 } } = useAsync<{
    isCyanWalletLocked: boolean;
    lockedDate: number;
  }>(async () => {
    if (cyanWallet && vault.symbol.startsWith("CV")) {
      const date = await getLockedDate(vault.contractAddress);
      const lockedDate = date && date.gt(0) ? dayjs.unix(date.toNumber()).diff(dayjs(), "seconds", true) : 0;
      const isCyanWalletLocked =
        selectedWallet.toLowerCase() === cyanWallet.walletAddress.toLowerCase() &&
        selectedWallet !== "" &&
        lockedDate > 0;
      return {
        lockedDate: lockedDate,
        isCyanWalletLocked,
      };
    }
    return {
      lockedDate: 0,
      isCyanWalletLocked: false,
    };
  }, [cyanWallet, selectedWallet, vault]);

  useEffect(() => {
    setUnstakingAmount("");
    setAmountInToken("");
  }, [selectedWallet]);

  const formatNumber = useMemo(() => {
    if (vault.abiName === VaultContractAbiNames.ApeCoinVaultV1) {
      return 2;
    }
    return VaultDecimalFormatMap.get(vault.decimals) || 5;
  }, [vault.decimals, vault.abiName]);
  const onUnstakingAmountChange = (values: NumberFormatValues, source: SourceInfo) => {
    if (source.source === SourceType.props) return;
    if (values.value === "") {
      setUnstakingAmount("");
      setAmountInToken("");
      return;
    }
    const amount = (parseFloat(values.value) * vault.priceUsd) / usdPrice[vault.currency];
    setAmountInToken(roundDown(amount, formatNumber).toString());
    setUnstakingAmount(values.value);
  };
  const onAmountInTokenChange = (values: NumberFormatValues, source: SourceInfo) => {
    if (source.source === SourceType.props) return;
    if (values.value === "") {
      setAmountInToken("");
      setUnstakingAmount("");
      return;
    }
    const amount = (parseFloat(values.value) * usdPrice[vault.currency]) / vault.priceUsd;
    setUnstakingAmount(roundDown(amount, formatNumber).toString());
    setAmountInToken(values.value);
  };
  const availableBalanceInFloat = useMemo(() => {
    return availableTokenBalance ? bigNumToFloat(availableTokenBalance, vault.decimals) : null;
  }, [availableTokenBalance, vault.decimals]);
  const availableToken = useMemo(() => {
    return !availableBalanceInFloat ? 0 : roundDown(availableBalanceInFloat, formatNumber);
  }, [availableBalanceInFloat, formatNumber]);
  const onSetMaxAmount = () => {
    if (availableToken === 0) return;
    const amount = (availableToken * vault.priceUsd) / usdPrice[vault.currency];
    setAmountInToken(roundDown(amount, formatNumber).toString());
    setUnstakingAmount(roundDown(availableToken, formatNumber).toString());
  };

  const availableTokenInVaultCurrency = (availableToken * vault.priceUsd) / usdPrice[vault.currency];
  const { execute: handleUnstake, loading: unstaking } = useAsyncCallback(async () => {
    if (!signer) return;
    if (vault.chainId !== chainId) {
      setModalContent({
        title: `Change Network`,
        content: <ChangeNetworkModal chainId={vault.chainId} />,
      });
      return;
    }
    setModalContent({
      title: `Confirm Unstake`,
      content: (
        <UnstakingModal
          unstakingAmount={parseFloat(unstakingAmount === "" ? "0" : unstakingAmount)}
          vault={vault}
          selectedWallet={selectedWallet}
          setUnstakingAmount={setUnstakingAmount}
          setAmountInToken={setAmountInToken}
        />
      ),
    });
  });
  return (
    <>
      <Flex gap="0.2rem" direction="column">
        <Card mb="-0.2rem">
          <Flex direction="column" gap="2.5rem" p="1.3rem 0.8rem">
            <Flex direction="column" gap="0.3rem">
              {(vault.abiName === VaultContractAbiNames.CyanVaultV2 ||
                vault.abiName === VaultContractAbiNames.CyanVaultV2_1) &&
              selectedWallet !== "" ? (
                <Text weight="500" color="gray0" size="xs">
                  {`Minimum deposit duration is ${parseInt(vault.withdrawLockTerm.toString()) / (24 * 60 * 60)} days. `}
                  <StyledLink href="https://docs.usecyan.com/docs/cyan-vaults" target="_blank">
                    {`Learn more.`}
                  </StyledLink>
                </Text>
              ) : (
                <Text weight="600" color="gray0" size="sm">
                  {`You pay`}
                </Text>
              )}
              <Flex w="100%" justifyContent="space-between" alignItems="center" gap="15px">
                <Text color="secondary" size="xxl">
                  <NumericFormat
                    placeholder="0"
                    thousandSeparator=","
                    allowLeadingZeros={false}
                    allowNegative={false}
                    onValueChange={onUnstakingAmountChange}
                    value={unstakingAmount}
                    customInput={StakeInput}
                    decimalScale={5}
                    valueIsNumericString
                    disabled={unstaking || !availableBalanceInFloat || availableBalanceInFloat === 0}
                  />
                </Text>
                <PillWrapper>
                  <VaultImageWrapper style={{ background: vault.colorCode }}>
                    <VaultImage src={CyanC} alt={vault.name} />
                  </VaultImageWrapper>
                  <StyledSelect
                    value={vault.contractAddress}
                    onChange={e => {
                      navigate(
                        `/${location.pathname.split("/")[1]}/${
                          CHAIN_IDS_TO_NAMES[
                            vaults.find(v => v.contractAddress === e.target.value)?.chainId as SupportedChainId
                          ]
                        }/${e.target.value}`,
                      );
                    }}
                  >
                    {vaults.map(v => (
                      <option value={v.contractAddress} key={v.contractAddress}>
                        {vault.name === v.name ? v.symbol : `${v.name} (${v.symbol})`}
                      </option>
                    ))}
                  </StyledSelect>
                  <StyledChevronDown>
                    <ChevronDown color={theme.colors.secondary} width="10px" />
                  </StyledChevronDown>
                </PillWrapper>
              </Flex>
              <Flex
                w="100%"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  overflowX: "auto",
                }}
              >
                <Text weight="500" color="gray0" size="sm">
                  {shortenName(displayInUSD(Number(unstakingAmount) * vault.priceUsd), 18, 16, 2)}{" "}
                </Text>
                <Flex alignItems="center">
                  <Text color="gray0" weight="400" size="sm" textWrap={false}>
                    {`Balance`}: {numberWithCommas(availableToken, formatNumber)}
                  </Text>
                  <div>
                    <Button variant="ghost" onClick={onSetMaxAmount}>
                      <Text color="cyan" weight="400" size="sm">
                        {`Max`}
                      </Text>
                    </Button>
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <div style={{ position: "relative" }}>
          <ChevronDownWrapper onClick={() => !unstaking && toggle()}>
            <ArrowDown color={theme.colors.secondary} size={22} />
          </ChevronDownWrapper>
        </div>
        <Card
          style={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Flex direction="column" gap="2.5rem" p="1.3rem 0.8rem">
            <Flex direction="column" gap="0.3rem">
              <Text weight="600" color="gray0" size="sm">
                {`You receive`}
              </Text>
              <Flex w="100%" justifyContent="space-between" alignItems="center" gap="15px">
                <Text color="secondary" size="xxl">
                  <NumericFormat
                    placeholder="0"
                    thousandSeparator=","
                    allowLeadingZeros={false}
                    allowNegative={false}
                    onValueChange={onAmountInTokenChange}
                    value={amountInToken}
                    customInput={StakeInput}
                    decimalScale={5}
                    valueIsNumericString
                    disabled={unstaking || !availableBalanceInFloat || availableBalanceInFloat === 0}
                  />
                </Text>
                <PillWrapper>
                  <CurrencyLogo symbol={vault.currency} />
                  <Text color="secondary" weight="400" size="md">
                    {vault.currency}
                  </Text>
                </PillWrapper>
              </Flex>
              <Flex
                w="100%"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  overflowX: "auto",
                }}
              >
                <Text weight="500" color="gray0" size="sm">
                  {shortenName(
                    displayInUSD(amountInToken === "" ? 0.0 : Number(amountInToken) * usdPrice[vault.currency]),
                    18,
                    16,
                    2,
                  )}
                </Text>
                <Flex alignItems="center">
                  <Text color="gray0" weight="400" size="sm" textWrap={false}>
                    {`Balance`}:{" "}
                    {numberWithCommas(roundDown(availableTokenInVaultCurrency, formatNumber), formatNumber)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
        {vault.price && (
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Flex p="0.8rem">
              <Text color="secondary" size="xs">
                1 {vault.symbol} = {`${(vault.priceUsd / usdPrice[vault.currency]).toFixed(4)} ${vault.currency}`}{" "}
                <Text color="gray0" size="xs">
                  ({displayInUSD(vault.priceUsd)})
                </Text>
              </Text>
            </Flex>
          </Card>
        )}
      </Flex>
      <UnstakeButton
        onClick={handleUnstake}
        loading={unstaking}
        disabled={
          IsInTestDrive ||
          unstaking ||
          unstakingAmount === "" ||
          parseFloat(unstakingAmount) == 0 ||
          availableBalanceInFloat == 0 ||
          availableBalanceInFloat == null ||
          !watchBelowStakeAmount(parseFloat(unstakingAmount), availableBalanceInFloat) ||
          isCyanWalletLocked
        }
      >
        {unstaking ? (
          <Box>
            <Loader stroke={theme.colors.primary} size="14px" />
          </Box>
        ) : (
          <Text size={"sm"} weight={"700"} color={"primary"}>
            {`Unstake`}
          </Text>
        )}
      </UnstakeButton>
      {isCyanWalletLocked && (
        <Text color="red" size="xs" textAlign="right">
          {`Vault tokens cannot be unstaked for ${getPaymentInterval(lockedDate).split(" ")[0]} more ${
            getPaymentInterval(lockedDate).split(" ")[1]
          }`}
        </Text>
      )}
    </>
  );
};

const UnstakeButton = styled(Button)`
  padding: 1rem;
  background: #4d00ee !important;
  border-color: #4d00ee !important;
  height: 50px;
  transition: all 0.2s ease-in-out;
  :hover {
    background: #5808ff !important;
    border-color: #5808ff !important;
  }
`;
