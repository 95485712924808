import styled, { css } from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

interface AssetTagProps {
  variant?: "white" | "black";
  size?: "small" | "large";
  icon?: string;
}

export const AssetTag = styled.div<AssetTagProps>`
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  font-weight: 700;
  font-family: "Inter";
  letter-spacing: -0.03em;
  padding: 1px 5.5px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ size = "small" }) => {
    if (size === "small") {
      return getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 10 }, { [breakpoints.mobile]: 8 }]);
    } else {
      // large
      return css`
        font-size: 12px;
        padding: 0.2rem 0.4rem;
        font-weight: 600;
      `;
    }
  }}
  ${({ variant = "white" }) => {
    if (variant === "black") {
      return css`
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.secondary};
      `;
    } else {
      return css`
        background: ${({ theme }) => theme.colors.secondary};
        color: ${({ theme }) => theme.colors.primary};
      `;
    }
  }}
`;
