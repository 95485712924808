import { JsonRpcSigner } from "@ethersproject/providers";
import { BigNumber, ethers } from "ethers";

import { CyanWallet, useCyanWallet } from "@usecyan/cyan-wallet";

import { useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import {
  priceBnplStep1Deprecated,
  priceBnplStep1 as priceBnplStep1V2,
  priceBnplStep2Deprecated,
  priceBnplStep2 as priceBnplStep2V2,
  pricePawnStep1Deprecated,
  pricePawnStep1 as pricePawnStep1V2,
  pricePawnStep2Deprecated,
  pricePawnStep2 as pricePawnStep2V2,
} from "@/apis/pricer/index-v2";
import { useAppContext } from "@/components/AppContextProvider";
import { WalletOptionModal } from "@/components/PlanCreation/WalletOptionModal";
import { autoLiquidationExperimentFlag } from "@/components/PlanCreation/utils";
import { PlanCreationModal, PlanCreationSteps } from "@/components/PlanCreation/v2/PlanCreationModal";
import { BnplCreationProgressV2 } from "@/components/PlanCreation/v2/progress/BnplCreationProgress";
import { PawnCreationProgressV2 } from "@/components/PlanCreation/v2/progress/PawnCreationProgress";
import { RefinanceProgressV2 } from "@/components/PlanPayment/Refinance/RefinanceProgress-V2";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { ICreatePlanParams, ICurrency, IPlanCreatableNft } from "@/types";
import { getAccountBalanceOfErc20 } from "@/utils/contract";
import { IMappedError } from "@/utils/error/msgs";
import { Experiments } from "@/utils/experimentList";

import { IInitiatePlanCreationParamsV2 } from "./usePlanCreation.types";
import { ITransaction } from "./useTransactions";

const buildCreateBnplTxn = async (chainId: number, signer: JsonRpcSigner, paymentPlan: ICreatePlanParams) => {
  const { item, plan, planId, blockNum, signature, currency } = paymentPlan;
  const paymentPlanContract = f.PaymentPlanV2Factory.connect(getPaymentPlanFromChainId(chainId), signer);
  const { downpaymentAmount } = f.PaymentPlanV2Contract.getExpectedPlanSync({
    amount: plan.amount,
    downpaymentRate: plan.downPaymentPercent,
    interestRate: plan.interestRate,
    serviceFeeRate: plan.serviceFeeRate,
    totalNumberOfPayments: plan.totalNumberOfPayments,
  });
  const isNativeCurrency = currency.address === ethers.constants.AddressZero;
  const value = isNativeCurrency ? downpaymentAmount : 0;

  const data = paymentPlanContract.interface.encodeFunctionData("createBNPL", [
    item,
    plan,
    planId,
    blockNum,
    signature,
  ]);
  return { to: paymentPlanContract.address, data, value: value };
};

const buildCreatePawnTxn = async (
  chainId: number,
  _signer: JsonRpcSigner,
  paymentPlan: ICreatePlanParams,
  isBendDao?: boolean,
) => {
  const { item, plan, planId, blockNum, signature } = paymentPlan;
  const paymentPlanContractAddress = getPaymentPlanFromChainId(chainId);

  const iface = f.PaymentPlanV2Factory.createInterface();
  if (isBendDao) {
    const data = iface.encodeFunctionData("createPawnFromBendDao", [item, plan, planId, blockNum, signature]);
    return { to: paymentPlanContractAddress, data, value: "0" };
  }
  const data = iface.encodeFunctionData("createPawn", [item, plan, planId, blockNum, signature]);
  return { to: paymentPlanContractAddress, data, value: "0" };
};

const buildTransaction = async (
  planType: "pawn" | "bnpl",
  chainId: number,
  signer: JsonRpcSigner,
  cyanWallet: CyanWallet | null,
  paymentPlans: Array<ICreatePlanParams & { isCyanWalletAsset?: boolean }>,
  options: { withdrawFromCyanWallet?: boolean; isBendDao?: boolean },
) => {
  const txnBuilder = planType === "bnpl" ? buildCreateBnplTxn : buildCreatePawnTxn;
  if (paymentPlans.length === 1) {
    const { item, plan, planId, currency, blockNum, signature, isCyanWalletAsset } = paymentPlans[0];
    if (isCyanWalletAsset || options?.withdrawFromCyanWallet) {
      if (!cyanWallet) {
        throw new Error("User don't have a cyan wallet");
      }
      const transaction = await txnBuilder(
        chainId,
        signer,
        { item, plan, planId, currency, blockNum, signature },
        options?.isBendDao,
      );
      const data = cyanWallet.interface.encodeFunctionData("execute", [
        transaction.to,
        transaction.value,
        transaction.data,
      ]);
      return { to: cyanWallet.walletAddress, data, value: options?.withdrawFromCyanWallet ? "0" : transaction.value };
    }
    return await txnBuilder(chainId, signer, { item, plan, planId, currency, blockNum, signature }, options?.isBendDao);
  }

  if (!cyanWallet) {
    throw new Error("User don't have a cyan wallet");
  }

  const transactions = await Promise.all(
    paymentPlans.map(({ item, plan, planId, currency, blockNum, signature }) =>
      txnBuilder(chainId, signer, { item, plan, planId, currency, blockNum, signature }, options?.isBendDao),
    ),
  );
  const downpaymentAmount = transactions.reduce((acc, { value }) => acc.add(value), BigNumber.from(0));

  const data = cyanWallet.interface.encodeFunctionData("executeBatch", [transactions]);
  return { to: cyanWallet.walletAddress, data, value: options?.withdrawFromCyanWallet ? "0" : downpaymentAmount };
};

export const usePlanCreation = (planType: "pawn" | "bnpl") => {
  const { setModalContent } = useModal();
  const { setTransactions } = useTransactionContext();
  const { chainId, signer, account, provider } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { experiment } = useAppContext();
  const createPlans = async ({
    paymentPlans,
    isBendDao,
    withdrawFromCyanWallet,
  }: {
    paymentPlans: Array<
      ICreatePlanParams & {
        isCyanWalletAsset?: boolean;
      }
    >;
    isBendDao?: boolean;
    withdrawFromCyanWallet?: boolean;
  }) => {
    if (!chainId || !signer) return;

    const txRequest = await buildTransaction(planType, chainId, signer, cyanWallet, paymentPlans, {
      withdrawFromCyanWallet,
      isBendDao,
    });
    const tx = await signer.sendTransaction(txRequest);
    const newTxns: ITransaction[] = paymentPlans.map(paymentPlan => ({
      hash: tx.hash,
      type: planType === "bnpl" ? "bnpl-create" : "pawn-create",
      expiresAt: planType === "bnpl" ? Date.now() + 1000 * 60 * 60 * 2 : Date.now() + 1000 * 60 * 5,
      data: {
        planId: paymentPlan.planId,
        tokenId: paymentPlan.item.tokenId,
        contractAddress: paymentPlan.item.contractAddress,
      },
    }));
    setTransactions(oldTxns => [...oldTxns, ...newTxns]);
    await tx.wait();
    return tx;
  };

  const showNewPlanModal = ({
    currency,
    items,
    onClose,
  }: {
    currency: ICurrency;
    items: IPlanCreatableNft[];
    onClose?: () => void;
  }) => {
    let pricerStep1, pricerStep2;
    if (
      experiment.result &&
      experiment.result[autoLiquidationExperimentFlag(chainId)] &&
      experiment.result[Experiments.AUTO_LIQUIDATION_VAULT]
    ) {
      pricerStep1 = planType === "bnpl" ? priceBnplStep1V2 : pricePawnStep1V2;
      pricerStep2 = planType === "bnpl" ? priceBnplStep2V2 : pricePawnStep2V2;
    } else {
      pricerStep1 = planType === "bnpl" ? priceBnplStep1Deprecated : pricePawnStep1Deprecated;
      pricerStep2 = planType === "bnpl" ? priceBnplStep2Deprecated : pricePawnStep2Deprecated;
    }
    setModalContent({
      title: planType === "bnpl" ? "Pay Later" : `Borrow`,
      content: (
        <PlanCreationModal
          currency={currency}
          items={items}
          planType={planType}
          pricePlanStep1={pricerStep1}
          pricePlanStep2={pricerStep2}
          currentStep={items.length > 1 ? PlanCreationSteps.Pricer1Result : PlanCreationSteps.SelectTerm}
          onClose={onClose}
        />
      ),
      hideHeader: true,
      onClose,
    });
    return;
  };

  const checkAccountBalanceAndProceed = async ({
    currency,
    items,
    payAmount,
    setTriggeredError,
    onNext,
    planType,
  }: {
    currency: ICurrency;
    items: IPlanCreatableNft[];
    payAmount: BigNumber;
    setTriggeredError: (error: IMappedError) => void;
    onNext: (a: { selectedWalletAddress: string }) => void;
    planType: "bnpl" | "pawn";
  }) => {
    if (cyanWallet && account && provider) {
      const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
        currencyAddress: currency.address,
        mainWallet: account,
        provider,
        chainId,
      });
      if (mainWalletBalance.lt(payAmount) && cyanWalletBalance.lt(payAmount)) {
        setTriggeredError({
          title: `Not enough funds`,
          msg: `Your payment didn’t go through due to lack of funds`,
          description: `Please double check the amount of crypto in your main wallet. In order to facilitate payments for non-native currency plans, it is imperative that the payment amount be drawn from the Cyan wallet.`,
        });
        return;
      }
      setModalContent({
        content: (
          <WalletOptionModal
            items={items}
            mainWalletBalance={mainWalletBalance}
            cyanWalletBalance={cyanWalletBalance}
            currency={currency}
            payAmount={payAmount}
            onNext={onNext}
            planType={planType}
          />
        ),
        title: "Select wallet to pay",
      });
    }
  };

  const initiatePlanCreationV2 = (args: IInitiatePlanCreationParamsV2) => {
    if (args.items.some(item => item.existingPlan)) {
      setModalContent({
        title: "Processing Refinance",
        content: <RefinanceProgressV2 {...args} />,
        onClose: args.onClose,
      });
      return;
    }
    setModalContent({
      title: "Processing Purchase",
      content: planType === "bnpl" ? <BnplCreationProgressV2 {...args} /> : <PawnCreationProgressV2 {...args} />,
      onClose: args.onClose,
    });
  };

  return {
    initiatePlanCreationV2,
    checkAccountBalanceAndProceed,
    showNewPlanModal,
    createPlans,
  };
};
