import dayjs from "dayjs";
import { Logger } from "ethers/lib/utils";
import { useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";

import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import {
  Button,
  Card,
  CurrencyLogo,
  Flex,
  SystemMessage,
  Text,
  Toggler,
  Tooltip,
  TooltipText,
  useModal,
} from "@cyanco/components/theme";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { currencyList } from "@/apis/coinbase";
import { IP2PUserCreatedOffer } from "@/apis/p2p/types";
import { useAppContext } from "@/components/AppContextProvider";
import { NftMetadata } from "@/components/P2P/collection/LoanOffers/NftMetadata";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { isProd } from "@/config";
import { bigNumToFloat, displayInUSD, roundDown, shortenAddress } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { CancelOfferProgress } from "./CancelOfferProgress";
import { RenewOfferProgress } from "./RenewOfferProfress";

const LOAN_EXPIRY_TERMS: { [key: string]: number } = isProd
  ? {
      "1 hour": 3600,
      "6 hours": 21600,
      "1 day": 86400,
      "3 days": 259200,
      "7 days": 604800,
      "1 month": 2678400,
    }
  : {
      "10 mins": 600,
      "30 mins": 1800,
      "45 mins": 2700,
      "1 hour": 3600,
      "3 hours": 10800,
    };
export const OfferDetail = ({
  offer,
  error,
  modalType,
}: {
  offer: IP2PUserCreatedOffer;
  error?: any;
  modalType: "cancel" | "renew";
}) => {
  const { setModalContent } = useModal();
  const theme = useTheme();
  const { usdPrice } = useAppContext();

  const [offerExpiry, setOfferExpiry] = useState<number>(0);

  const repaymentAmount = useMemo(() => {
    const loanAmount = bigNumToFloat(offer.amount, offer.currency.decimal);
    return roundDown(loanAmount + loanAmount * (offer.interestRate / 10000), 6);
  }, [offer.amount]);
  const expiry = useMemo(() => {
    return dayjs(offer.signatureExpiry).format("DD / MM / YYYY");
  }, [offer.signatureExpiry]);
  const apr = useMemo(() => {
    return ((365 / (offer.term / 60 / 60 / 24)) * (offer.interestRate / 100)).toFixed(2);
  }, [offer.term]);
  const ErrorMemod = useMemo(() => {
    if (!error) return null;
    if (typeof error === "string") return <SystemMessage variant="error" title={error} msg={error} />;
    switch (error.code) {
      case Logger.errors.ACTION_REJECTED: {
        return (
          <SystemMessage
            variant="error"
            title={`Transaction Rejected by User`}
            msg={`Please approve the transaction in your wallet to continue.`}
          />
        );
      }
      case Logger.errors.INSUFFICIENT_FUNDS: {
        return (
          <SystemMessage
            variant="error"
            title={`Insufficient balance to start transaction`}
            msg={`You don’t have enough balance in your wallet to start transaction.`}
          />
        );
      }
      default:
        const _error = mapAndLogError(error);
        return <SystemMessage variant="error" title={_error.title} msg={_error.msg} />;
    }
  }, [error]);

  const openProgressModal = () => {
    if (modalType === "cancel") {
      setModalContent({
        title: `Cancelling loan offer `,
        content: <CancelOfferProgress offer={offer} />,
      });
    }
    if (modalType === "renew") {
      setModalContent({
        title: `Renewing Loan Offer `,
        content: <RenewOfferProgress offer={{ ...offer, expiry: offerExpiry }} />,
      });
    }
  };
  return (
    <Flex direction="column" gap="1.2rem">
      {offer.tokenId && (
        <NftMetadata
          nft={{
            collectionAddress: offer.collection.address,
            collectionName: offer.collection.name,
            tokenId: offer.tokenId,
            currency: offer.currency,
            imageUrl: offer.metadata ? offer.metadata.imageUrl : NoImage,
          }}
        />
      )}
      {ErrorMemod && ErrorMemod}
      {/* Loan Amount */}
      <Flex direction="column" gap="0.5rem">
        <Text color="gray0" size="sm" weight="500">
          {`Principal Amount`}
        </Text>
        <Flex direction="column" gap="2px">
          <Card
            style={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
              <Text weight="400" color="gray0" size="xxl">
                {bigNumToFloat(offer.amount, offer.currency.decimal)}
              </Text>
              <PillWrapper gap="5px" pt="0.4rem">
                <CurrencyLogo symbol={(offer.currency.symbol as SupportedCurrencies) ?? currencyList.weth} />
                <Text color="secondary" weight="400" size="md">
                  {offer.currency.symbol ?? currencyList.weth}
                </Text>
              </PillWrapper>
            </Flex>
          </Card>
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            p="0.5rem 0.6rem"
          >
            <Text color="gray0" size="xs" weight="400">
              {displayInUSD(
                bigNumToFloat(offer.amount, offer.currency.decimal) *
                  usdPrice[offer.currency.symbol ?? currencyList.weth],
              )}
            </Text>
          </Card>
        </Flex>
      </Flex>
      <Flex gap="1.2rem" direction={"row"}>
        {/* Loan Length */}
        <Flex direction="column" gap="0.5rem" w="100%">
          <Text color="gray0" size="sm" weight="500">
            {`Loan Length`}
          </Text>
          <Flex direction="column" gap="2px">
            <Card
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
                <Text weight="400" color="gray0" size="xxl">
                  {isProd ? offer.term / 24 / 60 / 60 : offer.term / 60}
                </Text>
                <Text color="secondary" size="xl" weight="400">
                  {isProd ? `days` : `mins`}
                </Text>
              </Flex>
            </Card>
            <Card
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
                <Text color="gray0" size="xs" weight="400">
                  {getPaymentInterval(offer.term)}
                </Text>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        {/* Loan interest */}
        <Flex direction="column" gap="0.5rem" w="100%">
          <Text color="gray0" size="sm" weight="500">
            {`Interest Rate`}
          </Text>
          <Flex direction="column" gap="2px">
            <Card
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
                <Text weight="400" color="gray0" size="xxl">
                  {offer.interestRate / 100}
                </Text>
                <Text color="secondary" size="xl" weight="400">
                  %
                </Text>
              </Flex>
            </Card>
            <Card
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
                <Text color="gray0" size="xs" weight="400">
                  APR: {apr}%
                </Text>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Flex>

      {/* Loan Repayment */}
      <Flex direction="column" gap="0.5rem" mt="0.5rem">
        <Text color="gray0" size="sm" weight="500">
          {`Repayment Amount`}
        </Text>
        <Flex direction="column" gap="2px">
          <Card
            style={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
              <Text weight="400" color="gray0" size="xxl">
                {repaymentAmount}
              </Text>
              <PillWrapper gap="5px" pt="0.4rem">
                <CurrencyLogo symbol={(offer.currency.symbol as SupportedCurrencies) ?? currencyList.weth} />
                <Text color="secondary" weight="400" size="md">
                  {offer.currency.symbol ?? currencyList.weth}
                </Text>
              </PillWrapper>
            </Flex>
          </Card>
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            p="0.5rem 0.6rem"
          >
            <Text color="gray0" size="xs" weight="400">
              {displayInUSD(repaymentAmount * usdPrice[offer.currency.symbol ?? currencyList.weth])}
            </Text>
          </Card>
        </Flex>
      </Flex>
      {/* Togglers */}
      <Flex direction="column" gap="0.7rem">
        {!offer.tokenId && (
          <Flex justifyContent="space-between" alignItems="center" gap="1.4rem">
            <Flex alignItems="center" gap="4px">
              <Text size="md" weight="500" color="gray0">
                {`Items`}
              </Text>
              <Tooltip>
                <HelpCircle height={16} width={16} color={theme.colors.gray0} />
                <TooltipText showArrow position="top" top="-100px" right="-62px" style={{ width: "125px" }}>
                  <Flex direction="column" gap="7px">
                    <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                      <div>{`Your NFT will be moved into your Cyan Wallet for compounding activities. Turning this off will keep your NFT in your main wallet during staking.`}</div>
                    </Text>
                  </Flex>
                </TooltipText>
              </Tooltip>
            </Flex>
            <Text size="sm" color="secondary">
              {modalType === "cancel" ? offer.signatureAvailableUsage : offer.signatureUsageLimit}{" "}
            </Text>
          </Flex>
        )}
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap="4px">
            <Text size="md" weight="500" color="gray0">
              {`Auto-roll Loans`}
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.gray0} />
              <TooltipText showArrow position="top" top="-178px" right="-71px" style={{ width: "150px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`Automatically rolled loans are loans where the maturity date is renewed everytime the borrower pays back the interest by the maturity date.`}</div>
                  </Text>
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`The borrower can keep rolling the loan so long as they continue to pay the interest by the due date at every period. When the loan is fully repaid, the principal is returned.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
          </Flex>
          <Toggler value={offer.isExtendable} onChange={() => {}} size="sm" disabled />
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="md" weight="500" color="gray0">
            {`Offer Expiry`}
          </Text>
          {modalType === "cancel" ? (
            <LoanOptionPillWrapper>
              <Text color="gray0" size="sm">
                {expiry}
              </Text>
            </LoanOptionPillWrapper>
          ) : (
            <LoanOptionPillWrapper style={{ background: "cyan" }}>
              <LoanOptionSelector
                style={{ backgroundColor: "cyan", color: "black" }}
                value={offerExpiry}
                onChange={e => {
                  setOfferExpiry(Number(e.target.value));
                }}
                isCyan
              >
                <option value={0} key={0}>
                  Select
                </option>
                {Object.keys(LOAN_EXPIRY_TERMS).map(term => (
                  <option value={LOAN_EXPIRY_TERMS[term]} key={term}>
                    {term}
                  </option>
                ))}
              </LoanOptionSelector>
            </LoanOptionPillWrapper>
          )}
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap="4px">
            <Text size="md" weight="500" color="gray0">
              {`Wallet`}
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.gray0} />
              <TooltipText showArrow position="top" top="-98px" right="-67px" style={{ width: "132px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`Select the wallet you woul
                    d like the loans to be made out from. The wallet you select will be from where crypto will be withdrawn on every loan made.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
          </Flex>
          <LoanOptionPillWrapper>
            <Text color="gray0" size="sm">
              {shortenAddress(offer.lenderAddress, 4)}{" "}
            </Text>
          </LoanOptionPillWrapper>
        </Flex>
      </Flex>
      {modalType === "renew" && (
        <OfferButton onClick={openProgressModal} disabled={offerExpiry === 0}>{`Renew Offer`}</OfferButton>
      )}
      {modalType === "cancel" && (
        <CancelButton onClick={openProgressModal} variant="outlined">
          <Text weight="700" size="sm" color="red">
            {`Cancel Offer`}
          </Text>
        </CancelButton>
      )}
    </Flex>
  );
};

const PillWrapper = styled(Flex)<{ showArrow?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 30px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
  ${({ showArrow, theme }) =>
    showArrow &&
    `:after {
    content: "";
    appearance: none;
    background-image: ${
      theme.theme === "light"
        ? `url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1 1.86 4 4 4-4" /></svg>')`
        : `url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1 1.86 4 4 4-4" /></svg>')`
    };
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
  }`}
`;

const StyledSelect = styled.select`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: Inter;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  padding: 0 0.2rem 0 0.2rem;
  border-radius: 30px;
  outline: none;
  appearance: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray20};
  border: none;
`;
const LoanOptionPillWrapper = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 30px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
`;
const LoanOptionSelector = styled(StyledSelect)<{ isCyan?: boolean }>`
  color: ${({ theme, isCyan }) => (isCyan ? theme.colors.black : theme.colors.gray0)};
  font-size: 14px;
  padding-right: 0.85rem;
  font-weight: 500;

  appearance: none;
  background-image: ${({ isCyan }) =>
    isCyan
      ? `url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1 1.86 4 4 4-4" /></svg>')`
      : ` url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="gray" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1 1.86 4 4 4-4" /></svg>')`};

  background-repeat: no-repeat;
  background-position: right 0rem top 50%;
  background-size: 0.65rem auto;
`;
const OfferButton = styled(Button)`
  padding: 1rem;
  height: 50px;
`;

const CancelButton = styled(Button)`
  padding: 1rem;
  height: 50px;
  border-color: ${({ theme }) => theme.colors.red};
`;
