import { ethers } from "ethers";
import { useMemo } from "react";
import { jsNumberForAddress } from "react-jazzicon";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import styled from "styled-components";

import { Box, Flex } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Carousel, SkeletonLine, Text } from "@cyanco/components/theme/v3";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedCurrenciesByChain } from "@/config";
import { ICurrency } from "@/types";
import { bigNumToFloat, divideArrayByN, shortenAddress } from "@/utils";

import { useAppContext } from "../AppContextProvider";
import { useLendDataContext } from "./LendDataContext";

const LeaderBoardLoading = () => {
  return (
    <Container>
      <Flex direction="column">
        <StyledBox>
          <Text weight="600" size="xs" color="secondary">{`Peer-to-Peer Leader Board`}</Text>
        </StyledBox>
      </Flex>
      <Flex direction="column" gap="0.7rem" p="0 0.8rem">
        {Array.from(Array(7).keys()).map(loader => (
          <Flex direction="row" key={loader} gap="10px" alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap="15px">
              <SkeletonLine
                style={{
                  minHeight: "16px",
                  minWidth: "16px",
                }}
              />
              <SkeletonLine w="50px" h="12.5px" />
            </Flex>
            <SkeletonLine w="50px" h="12.5px" />
          </Flex>
        ))}
      </Flex>
    </Container>
  );
};

const LeaderBoardCard = ({
  amounts,
}: {
  amounts: Array<{
    wallet: string;
    amount: number;
    currency: ICurrency;
  }>;
}) => {
  return (
    <Container w="100%">
      <Flex direction="column">
        <StyledBox>
          <Text weight="600" size="xs" color="secondary">{`Peer-to-Peer Leader Board`}</Text>
        </StyledBox>
      </Flex>
      <Flex direction="column" gap="0.7rem" p="0 0.8rem">
        {amounts.map((item, index) => {
          return (
            <Flex direction="row" key={item.wallet} gap="10px" alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" gap="15px">
                <Text color="gray0" size="xs" weight="600">
                  {index + 1}
                </Text>
                <Flex gap="10px">
                  <Jazzicon seed={jsNumberForAddress(item.wallet)} diameter={16} />
                  <Text color="secondary" size="xs" weight="800">
                    {shortenAddress(item.wallet)}
                  </Text>
                </Flex>
              </Flex>
              <Text color="secondary" size="xs" weight="500">
                {`${item.amount.toFixed(2)} ${item.currency.symbol}`}
              </Text>
            </Flex>
          );
        })}
        {Array.from(Array(8 - amounts.length).keys()).map(loader => (
          <Flex
            direction="row"
            key={loader}
            gap="10px"
            alignItems="center"
            justifyContent="space-between"
            style={{ visibility: "hidden" }}
          >
            <Flex alignItems="center" gap="15px">
              <Text color="gray0" size="xs" weight="600">
                space
              </Text>
              <Flex gap="10px">
                <Jazzicon seed={jsNumberForAddress(ethers.constants.AddressZero)} diameter={16} />
                <Text color="secondary" size="xs" weight="800"></Text>
              </Flex>
            </Flex>
            <Text color="secondary" size="xs" weight="500"></Text>
          </Flex>
        ))}
      </Flex>
    </Container>
  );
};

export const LeaderBoard = () => {
  const { chainId } = useWeb3React();
  const { usdPrice } = useAppContext();
  const { leaderBoardDataLoading, leaderBoardData } = useLendDataContext();

  const amounts = useMemo(() => {
    const uniqueResults = leaderBoardData.reduce<{
      [key: string]: {
        wallet: string;
        amount: number;
        currency: ICurrency;
      };
    }>((acc, cur) => {
      const data = acc[cur.wallet];
      return {
        ...acc,
        [cur.wallet]: data
          ? {
              ...data,
              currency: SupportedCurrenciesByChain[chainId][2],
              amount:
                data.amount +
                (bigNumToFloat(cur.amount, cur.currency.decimal) * usdPrice[cur.currency.symbol]) / usdPrice["ETH"],
            }
          : {
              ...cur,
              currency: SupportedCurrenciesByChain[chainId][2],
              amount:
                (bigNumToFloat(cur.amount, cur.currency.decimal) * usdPrice[cur.currency.symbol]) / usdPrice["ETH"],
            },
      };
    }, {});
    return divideArrayByN(Object.values(uniqueResults), 8);
  }, [leaderBoardData, usdPrice]);
  return (
    <ContainerMain>
      {leaderBoardDataLoading ? (
        <Carousel>
          <LeaderBoardLoading />
        </Carousel>
      ) : (
        <Carousel autoScroll>
          {amounts.map((_amounts, index) => (
            <div
              key={index}
              style={{
                width: "95%",
              }}
            >
              <LeaderBoardCard amounts={_amounts} />
            </div>
          ))}
        </Carousel>
      )}
    </ContainerMain>
  );
};

const ContainerMain = styled.div`
  width: 460px;
  ${getStyleWithMediaQuery("width", "px", [
    { [breakpoints.desktop]: 480 },
    { [breakpoints.laptop]: 460 },
    { [breakpoints.mobile]: 400 },
  ])}
`;

const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-style: solid;
  padding-bottom: 0.8rem;
  width: 100%;
  transition: border-color 0.2s ease-in-out;
  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
  }
`;
const StyledBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray20};
  padding: 0.5rem 0.8rem;
`;
