import { useWeb3React } from "@web3-react/core";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { IPeerPlan, isPeerPlan } from "@/apis/p2p/types";
import { isUserNft } from "@/apis/user";
import { IUserNft } from "@/apis/user/types";
import usePrevious from "@/hooks/usePrevious";

import { IBNPL, isBnplPlan } from "../Bnpl/bnpl.types";
import { useAccountPageContext } from "./AccountPageContext";
import { IPawn, isPawnPlan } from "./pawn.types";

type IItem = IBNPL | IPawn | IPeerPlan | IUserNft;
type IItems = Array<IItem>;

type ISelectedItemsContext = {
  currency: string | null;
  setCurrency: React.Dispatch<React.SetStateAction<string | null>>;

  items: IItems;
  setItems: React.Dispatch<React.SetStateAction<IItems>>;
};

const SelectedItemsContext = createContext<ISelectedItemsContext>({
  currency: null,
  setCurrency: () => {},

  items: [],
  setItems: () => {},
});

export const SelectedItemsProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const { selectedTab } = useAccountPageContext();
  const [currency, setCurrency] = useState<string | null>(null);
  const [items, setItems] = useState<IItems>([]);
  const { account } = useWeb3React();
  const previousAccount = usePrevious(account);

  useEffect(() => {
    if (account === previousAccount) return;
    setCurrency(null);
    setItems([]);
  }, [account]);

  useEffect(() => {
    setCurrency(null);
    setItems([]);
  }, [selectedTab, location.pathname]);

  return (
    <SelectedItemsContext.Provider value={{ items, setItems, currency, setCurrency }}>
      {children}
    </SelectedItemsContext.Provider>
  );
};

export const useSelectedItems = () => {
  const { setItems, items } = useContext(SelectedItemsContext);

  const addItem = (item: IItem) => {
    setItems(prev => [...prev, item]);
  };

  const addItems = (_items: IItem[]) => {
    if (!_items.length) return;
    if (items.length === 0) {
      setItems(_items);
      return;
    }
    const filteredItems = _items.filter(_item => {
      let searchedItem;
      if (isPeerPlan(_item)) {
        searchedItem = items.find(
          item =>
            isPeerPlan(item) &&
            item.collectionAddress === _item.collectionAddress &&
            item.tokenId === _item.tokenId &&
            item.planId === _item.planId,
        );
      }
      if (isBnplPlan(_item)) {
        searchedItem = items.find(
          item =>
            isBnplPlan(item) &&
            item.metadata.collectionAddress === _item.metadata.collectionAddress &&
            item.tokenId === _item.tokenId &&
            item.planId === _item.planId,
        );
      }
      if (isPawnPlan(_item)) {
        searchedItem = items.find(
          item =>
            isPawnPlan(item) &&
            item.metadata.collectionAddress === _item.metadata.collectionAddress &&
            item.tokenId === _item.tokenId &&
            item.planId === _item.planId,
        );
      }
      if (isUserNft(_item)) {
        searchedItem = items.find(
          item =>
            isUserNft(item) &&
            item.address === _item.address &&
            item.tokenId === _item.tokenId &&
            item.isCyanWallet === _item.isCyanWallet,
        );
      }
      if (searchedItem) return false;
      return true;
    });
    setItems(prev => [...prev, ...filteredItems]);
  };

  const removeAll = () => {
    setItems([]);
  };

  const removeItem = (_item: IItem) => {
    let remainingItems = items;
    if (isPeerPlan(_item)) {
      remainingItems = items.filter(
        item =>
          !(
            isPeerPlan(item) &&
            item.collectionAddress === _item.collectionAddress &&
            item.tokenId === _item.tokenId &&
            item.planId === _item.planId
          ),
      );
    }
    if (isBnplPlan(_item)) {
      remainingItems = items.filter(
        item =>
          !(
            isBnplPlan(item) &&
            item.metadata.collectionAddress === _item.metadata.collectionAddress &&
            item.tokenId === _item.tokenId &&
            item.planId === _item.planId
          ),
      );
    }
    if (isPawnPlan(_item)) {
      remainingItems = items.filter(
        item =>
          !(
            isPawnPlan(item) &&
            item.metadata.collectionAddress === _item.metadata.collectionAddress &&
            item.tokenId === _item.tokenId &&
            item.planId === _item.planId
          ),
      );
    }
    if (isUserNft(_item)) {
      remainingItems = items.filter(
        item =>
          !(
            isUserNft(item) &&
            item.address === _item.address &&
            item.tokenId === _item.tokenId &&
            item.isCyanWallet === _item.isCyanWallet
          ),
      );
    }
    setItems(remainingItems);
  };

  const toggleItem = (_item: IItem) => {
    let searchedItem;
    if (isPeerPlan(_item)) {
      searchedItem = items.find(
        item =>
          isPeerPlan(item) &&
          item.collectionAddress === _item.collectionAddress &&
          item.tokenId === _item.tokenId &&
          item.planId === _item.planId,
      );
    }
    if (isBnplPlan(_item)) {
      searchedItem = items.find(
        item =>
          isBnplPlan(item) &&
          item.metadata.collectionAddress === _item.metadata.collectionAddress &&
          item.tokenId === _item.tokenId &&
          item.planId === _item.planId,
      );
    }
    if (isPawnPlan(_item)) {
      searchedItem = items.find(
        item =>
          isPawnPlan(item) &&
          item.metadata.collectionAddress === _item.metadata.collectionAddress &&
          item.tokenId === _item.tokenId &&
          item.planId === _item.planId,
      );
    }
    if (isUserNft(_item)) {
      searchedItem = items.find(
        item =>
          isUserNft(item) &&
          item.address === _item.address &&
          item.tokenId === _item.tokenId &&
          _item.isCyanWallet === item.isCyanWallet,
      );
    }
    if (!searchedItem) {
      addItem(_item);
    } else {
      removeItem(searchedItem);
    }
  };

  return {
    items,
    setItems,
    addItems,
    addItem,
    removeAll,
    toggleItem,
    removeItem,
  };
};
