import "rc-slider/assets/index.css";
import React, { InputHTMLAttributes, ReactElement } from "react";
import styled from "styled-components";

import { Flex } from "../../Flex";
import { ITextSizes, TextSizes } from "./Text";

const StyledInput = styled.input<{
  fontSize?: ITextSizes;
  textAlign?: "left" | "right";
  disabled: boolean;
  fontWeight?: string;
}>`
  padding: 0;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  font-size: ${({ fontSize }) => TextSizes[fontSize ?? "sm"]};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  height: 100%;
  font-family: Inter;
  outline: none;
  transition: 0.2s;
  width: 100%;
  cursor: ${({ disabled }) => disabled && "not-allowed"};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const InputContainer = styled.div<{ bgColor?: string; p?: string; focused: boolean; hasError?: boolean }>`
  background-color: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.colors.primary)};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: ${({ theme }) => theme.borderWidth};
  border-color: ${({ theme, focused, hasError }) =>
    hasError ? "red" : focused ? (theme.theme === "dark" ? "#00FFFF" : "black") : theme.colors.gray20};
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: ${({ p }) => (p ? p : "0.7rem 0.8rem")};
  height: auto;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  transition: border-color 0.2s;
  border-style: solid;
  gap: 8px;
  height: 100%;
  :hover {
    border-color: ${({ theme, focused, hasError }) => !hasError && !focused && theme.colors.gray30};
  }
`;

export const Input: React.FC<
  {
    bgColor?: string;
    fontSize?: ITextSizes;
    textAlign?: "left" | "right";
    icon?: ReactElement;
    disabled?: boolean;
    fontWeight?: string;
    p?: string;
    children?: ReactElement | ReactElement[];
    inputRef?: any;
    hasError?: boolean;
  } & InputHTMLAttributes<any>
> = ({
  bgColor,
  fontSize,
  textAlign,
  icon,
  disabled = false,
  fontWeight,
  p,
  children,
  inputRef,
  hasError,
  ...rest
}) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const [focused, setFocused] = React.useState(false);
  return (
    <InputContainer
      bgColor={bgColor}
      p={p}
      focused={focused}
      hasError={hasError}
      {...rest}
      onClick={() => {
        if (ref && ref.current) {
          ref.current.focus();
          return;
        }
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      }}
    >
      {icon && (
        <Flex pl="3px" alignItems="center" justifyContent="center">
          {icon}
        </Flex>
      )}
      <StyledInput
        fontSize={fontSize}
        textAlign={textAlign}
        fontWeight={fontWeight}
        {...rest}
        disabled={disabled}
        ref={inputRef || ref}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {children && children}
    </InputContainer>
  );
};
