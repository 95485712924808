import RcSlider, { SliderProps } from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import React from "react";
import styled, { createGlobalStyle, useTheme } from "styled-components";

const StyledSlider = styled(RcSlider)`
  width: 100%;
  .rc-slider-dot-active {
    background-color: ${({ theme }) => theme.colors.cyan} !important;
  }
  .rc-slider-mark-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
  }
  .rc-slider-dot {
    &:first-child {
      margin-left: 3.5px;
    }
    &:last-child {
      margin-left: -3.5px;
    }
  }
`;

export const LtvSliderInput: React.FC<SliderProps> = ({ ...props }) => {
  const theme = useTheme();

  const GlobalStyle = createGlobalStyle`
  .rc-slider-disabled {
    background-color: transparent !important;
    background: none !important;
  }
`;
  return (
    <>
      <GlobalStyle />
      <StyledSlider
        trackStyle={{
          background: "#00FFFF80",
          height: "7px",
        }}
        railStyle={{
          background: theme.colors.gray20,
          height: "7px",
        }}
        dotStyle={{
          outline: "none",
          border: "none",
          height: "7px",
          width: "7px",
          bottom: "-3px",
          background: theme.colors.gray30,
          fontFamily: "Inter",
        }}
        marks={{
          0: {
            label: "0%",
            style: {
              marginLeft: "3.5px",
            },
          },
          25: " ",
          33: " ",
          50: " ",
          67: " ",
          75: " ",
          100: {
            label: "100%",
            style: {
              marginLeft: "-3.5px",
            },
          },
        }}
        handleStyle={{
          outline: "none",
          border: "3.5px solid cyan",
          opacity: 1,
          cursor: "pointer",
          width: "14px",
          height: "14px",
          background: "#26D4D1",
          boxShadow: "-4px 4px 4px 1px rgba(0, 0, 0, 0.25)",
          bottom: "-1px",
        }}
        handleRender={(node, handleProps) => {
          return (
            <Tooltip
              overlayInnerStyle={{
                background: "cyan",
                color: "black",
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "12px",
                borderRadius: "36px",
                padding: "3px 8px",
                minHeight: "fit-content",
              }}
              overlay={`${handleProps.value}%`}
              placement="top"
              showArrow={false}
            >
              {node}
            </Tooltip>
          );
        }}
        {...props}
      />
    </>
  );
};
