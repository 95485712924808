import orderBy from "lodash.orderby";
import { useMemo, useState } from "react";

import { IPricerConfig } from "@/apis/types";
import { IAutoRepayStatuses } from "@/types";
import { getDurationLabel } from "@/utils";

import { IPlanCreationForm, ISliderMarks } from "./usePlanCreationForm.types";

export const MINUTE_SECONDS = 60;
export const HOUR_SECONDS = MINUTE_SECONDS * 60;
export const DAY_SECONDS = HOUR_SECONDS * 24;
export const MONTH_SECONDS = DAY_SECONDS * 31; // 31 days per month is our default term
const _getDurationLabel = (duration: number) => {
  if (duration >= MONTH_SECONDS) {
    const months = duration / MONTH_SECONDS;
    return `${months} mth${months > 1 ? "s" : ""}`;
  }
  return getDurationLabel(duration);
};

const getFormChoices = (planType: "bnpl" | "pawn", config: IPricerConfig): ISliderMarks => {
  return {
    durations: Array.from(
      new Set(
        config.map(([term, totalNumberOfPayments]) => {
          return term * (planType === "bnpl" ? totalNumberOfPayments - 1 : totalNumberOfPayments);
        }),
      ),
    ).map(duration => ({
      value: duration,
      label: _getDurationLabel(duration),
    })),
    loanRates: orderBy(
      Array.from(new Set(config.map(([_term, _totalNumberOfPayments, _serviceFeeRate, loanRate]) => loanRate))).map(
        loanRate => ({
          value: loanRate,
          label: `${(planType === "bnpl" ? 100_00 - loanRate : loanRate) / 100}%`,
        }),
      ),
      "label",
    ),
  };
};

type IPossibilities = {
  durations: Record<number, Set<number>>;
  loanRates: Record<number, Set<number>>;
};
export const _getPossibilityMap = (planType: "bnpl" | "pawn", config: IPricerConfig): IPossibilities => {
  return config.reduce<IPossibilities>(
    ({ durations, loanRates }, [term, totalNumberOfPayments, _serviceFeeRate, loanRate]) => {
      const duration = term * (planType === "bnpl" ? totalNumberOfPayments - 1 : totalNumberOfPayments);
      durations[duration] = (durations[duration] ?? new Set()).add(loanRate);
      loanRates[loanRate] = (loanRates[loanRate] ?? new Set()).add(duration);

      return { durations, loanRates };
    },
    { durations: {}, loanRates: {} },
  );
};

export const usePlanCreationForm = (
  planType: "bnpl" | "pawn",
  baseConfig: IPricerConfig,
  config: IPricerConfig,
  defaultConfig:
    | {
        duration: number;
        loanRate: number;
      }
    | undefined,
  selectedItemIsAutoLiquidated: boolean,
): IPlanCreationForm => {
  const choices = getFormChoices(planType, baseConfig);
  const possibilities = useMemo(() => _getPossibilityMap(planType, config), [config]);

  const [autoRepayStatus, setAutoRepayStatus] = useState<IAutoRepayStatuses>(IAutoRepayStatuses.Disabled);
  const [duration, setDuration] = useState<number>(
    defaultConfig?.duration && choices.durations.some(({ value }) => value === defaultConfig.duration)
      ? defaultConfig.duration
      : choices.durations[0].value,
  );
  const [isAutoLiquidated, setAutoLiquidated] = useState<boolean>(selectedItemIsAutoLiquidated);
  const [loanRate, setLoanRate] = useState<number>(
    defaultConfig?.loanRate && possibilities.durations[duration].has(defaultConfig.loanRate)
      ? defaultConfig.loanRate
      : Math.max(...possibilities.durations[duration].values()),
  );

  const choice = config.find(([term, totalNumberOfPayments]) => {
    return term * (planType === "bnpl" ? totalNumberOfPayments - 1 : totalNumberOfPayments) === duration;
  });
  if (!choice) {
    throw new Error("Invalid choice");
  }

  const [term, totalNumberOfPayments] = choice;

  const setMethod: IPlanCreationForm["setMethod"] = arg => {
    if (arg.autoRepayStatus !== undefined) {
      setAutoRepayStatus(arg.autoRepayStatus);
    }
    if (arg.duration !== undefined && possibilities.durations[arg.duration]) {
      setDuration(arg.duration);

      const possibleLoanRates = possibilities.durations[arg.duration];
      if (!possibleLoanRates.has(loanRate)) {
        setLoanRate(possibleLoanRates.values().next().value);
      }
    }
    if (arg.loanRate !== undefined && possibilities.loanRates[arg.loanRate]) {
      setLoanRate(arg.loanRate);

      const possibleDurations = possibilities.loanRates[arg.loanRate];
      if (!possibleDurations.has(duration)) {
        setDuration(possibleDurations.values().next().value);
      }
    }
    if (arg.isAutoLiquidated !== undefined) {
      setAutoLiquidated(arg.isAutoLiquidated);
    }
  };

  return { autoRepayStatus, duration, term, totalNumberOfPayments, loanRate, setMethod, choices, isAutoLiquidated };
};
