import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, SubTitle } from "@cyanco/components/theme/v3";

import { BackToCollectionsButton, BackToCollectionsIcon, BackToCollectionsText } from "../Bnpl";
import { FilterContextProvider } from "../Bnpl/components/CollectionDetail/Filter/FilterContext";
import { BargainCollectionDetailSection } from "./BargainCollectionDetail";
import { useBargainCollection } from "./BargainDataContext";
import { OnSaleNfts } from "./OnSaleNfts";

export const BargainCollection = () => {
  const navigate = useNavigate();
  const { collection } = useBargainCollection();

  if (!collection) return <></>;
  return (
    <FilterContextProvider>
      <Container direction="column">
        <GappedContainer direction="column">
          <div>
            <BackToCollectionsButton variant="ghost" onClick={() => navigate("/")}>
              <Flex alignItems="center" gap="2px">
                <BackToCollectionsIcon />
                <BackToCollectionsText color="gray0" size="sm">
                  {`Collections`}
                </BackToCollectionsText>
              </Flex>
            </BackToCollectionsButton>
          </div>
          <Flex justifyContent="space-between" w="100%" alignItems="center">
            <Hidden tabletDown>
              <SubTitle>{`Buy NFTs Now, Pay Later`}</SubTitle>
            </Hidden>
          </Flex>
        </GappedContainer>
        <BargainCollectionDetailSection collection={collection} />
        <OnSaleNfts collection={collection} />
      </Container>
    </FilterContextProvider>
  );
};

const Container = styled(Flex)`
  gap: 2rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 2 }, { [breakpoints.tablet]: 0.5 }])}
  padding-bottom: 18px;
  ${getStyleWithMediaQuery("padding-bottom", "", [
    { [breakpoints.desktop]: "18px" },
    { [breakpoints.laptop]: "3.5rem" },
    { [breakpoints.tablet]: "2rem" },
  ])}
`;

const GappedContainer = styled(Flex)`
  align-items: start;
  justify-content: start;
  gap: 1rem;
  padding: 0 10rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 0.5 }])}
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0.5rem 5px" },
  ])}
`;
