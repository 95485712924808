import { JsonRpcSigner } from "@ethersproject/providers";
import { BigNumber, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { CyanWallet } from "@usecyan/cyan-wallet";
import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { useApproval } from "@/hooks/useApproval";
import { ICurrency } from "@/types";
import { getChainExplorerTextForTxn, getChainExplorerURL } from "@/utils";
import { getAccountBalanceOfErc20, getNftTransferFnDataForCyanWallet } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";

import { IPawn } from "../Account/pawn.types";
import { useAppContext } from "../AppContextProvider";
import { IBNPL } from "../Bnpl/bnpl.types";
import { useTransactionContext } from "../TransactionContextProvider";
import { PlanMetadata } from "./PlanMetadata";
import { PlanRepayment } from "./PlanRepayment";
import { getFlagsForPlanRequiredSteps } from "./utils";

type IPlan = {
  totalAmount: BigNumber;
  leftAmount: BigNumber;
  collectionName: string;
  address: string;
  tokenId: string;
  imageUrl?: string;
  paymentPlan: {
    address: string;
    abiName: string;
    chainId: number;
  };
  isBnpl: boolean;
  planId: number;
  currentNumOfPayments: number;
  totalNumOfPaymentsLeft: number;
  purchasedPrice?: string;
};
export type IPlanRepaymentProgressProps = {
  plan: IPlan;
  pawn?: IPawn;
  bnpl?: IBNPL;
  currency: ICurrency;
  payment: {
    amount: BigNumber;
    isEarlyRepayment: boolean;
    isNativeCurrency: boolean;
    releaseWallet: string;
  };
  selectedWalletAddress: string;
  onClose: () => void;
};

export const PlanRepaymentProgress: React.FC<IPlanRepaymentProgressProps> = ({
  plan,
  currency,
  payment,
  bnpl,
  pawn,
  selectedWalletAddress,
  onClose,
}) => {
  const { setModalContent, unsetModal } = useModal();
  const { chainId, account, provider, signer } = useWeb3React();
  const { setFireConfetti } = useAppContext();
  const { cyanWallet } = useCyanWalletContext();
  const { transactions, addTransaction } = useTransactionContext();
  const { giveErc20Approval, getErc20ApprovalFunctionData } = useApproval();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<PlanRepaymentSteps>(PlanRepaymentSteps.TokenApproval);

  const paymentPlanV2 = getPaymentPlanFromChainId(chainId);

  const { isErc20ApprovalRequired, isErc20TransferRequired, isPayingFromMainWallet, isNftTransferRequired } =
    getFlagsForPlanRequiredSteps({
      isPlanCompleting: plan.totalNumOfPaymentsLeft === 1 || payment.isEarlyRepayment,
      isNativeCurrency: payment.isNativeCurrency,
      mainWalletAddress: account || "",
      selectedPayWallet: selectedWalletAddress,
      selectedReceiverWallet: payment.releaseWallet,
    });

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setFireConfetti(true);
        setSelectedStep(PlanRepaymentSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const createBulkTransactionForPayAndTransfer = async (signer: ethers.Signer, cyanWallet: CyanWallet) => {
    const { address: collectionAddress } = plan;
    const { amount: paymentAmount, isEarlyRepayment, releaseWallet, isNativeCurrency } = payment;
    const paymentContractWriter = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);

    const encodedFnDataTransferFormatted = getNftTransferFnDataForCyanWallet({
      to: releaseWallet,
      from: cyanWallet.walletAddress,
      tokenId: plan.tokenId,
      collectionAddress,
      tokenType: (pawn ?? bnpl)?.metadata.tokenType,
    });

    // Pay txn
    const encodedPayFnData = paymentContractWriter.interface.encodeFunctionData("pay", [plan.planId, isEarlyRepayment]);
    const encodedPayFnDataFormatted = [paymentPlanV2, isNativeCurrency ? paymentAmount : 0, encodedPayFnData];
    let encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
      [encodedPayFnDataFormatted, encodedFnDataTransferFormatted],
    ]);
    if (!isNativeCurrency) {
      const approveFnData = await getApproveNonNativeCurrencyAllowanceCyan(signer, cyanWallet);
      if (approveFnData) {
        encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
          [approveFnData, encodedPayFnDataFormatted, encodedFnDataTransferFormatted],
        ]);
      }
    }
    const tx = await signer.sendTransaction({
      to: cyanWallet.walletAddress,
      value: isNativeCurrency ? paymentAmount : 0,
      data: encodedCyanExecuteFnData,
    });
    return tx;
  };

  const checkAndApproveNonNativeCurrencyAllowanceMain = async (signer: JsonRpcSigner) => {
    const { amount: paymentAmount } = payment;
    const paymentContractWriter = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    const currencyAddress = await paymentContractWriter.getCurrencyAddressByPlanId(plan.planId);
    await giveErc20Approval(
      {
        currencyAddress,
        amount: paymentAmount,
      },
      paymentPlanV2,
    );
  };

  const getApproveNonNativeCurrencyAllowanceCyan = async (signer: ethers.Signer, cyanWallet: CyanWallet) => {
    const paymentContractWriter = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    const currencyAddress = await paymentContractWriter.getCurrencyAddressByPlanId(plan.planId);
    const encodedAllowanceFnDataFormatted = await getErc20ApprovalFunctionData(
      {
        amount: payment.amount,
        currencyAddress,
        cyanWallet,
      },
      paymentPlanV2,
    );
    return encodedAllowanceFnDataFormatted ?? null;
  };

  const checkBalance = async () => {
    if (!chainId || !account || !provider) return;
    const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
      currencyAddress: currency.address,
      mainWallet: account,
      provider,
      chainId,
    });
    if (
      (!isPayingFromMainWallet && cyanWalletBalance.lt(payment.amount)) ||
      (isPayingFromMainWallet && mainWalletBalance.lt(payment.amount))
    ) {
      openRepaymentModal({
        title: `Not enough funds`,
        msg: `Your payment didn’t go through due to lack of funds`,
        description: ` Please double check the amount of crypto in your main wallet. In order to facilitate payments for non-native currency plans, it is imperative that the payment amount be drawn from the Cyan wallet.`,
      });
    }
  };

  const handlePaymentV2 = async () => {
    if (!signer || !chainId || !account) return;
    const { isBnpl } = plan;
    const { amount: paymentAmount, isEarlyRepayment, isNativeCurrency } = payment;
    let tx: ethers.ContractTransaction;
    if (cyanWallet && isNftTransferRequired) {
      tx = await createBulkTransactionForPayAndTransfer(signer, cyanWallet);
    } else {
      const paymentContractWriter = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
      if (!isPayingFromMainWallet) {
        if (!cyanWallet) {
          openRepaymentModal({
            title: `Cyan Wallet not found!`,
            msg: `In order to make payment, cyan wallet is required to have.`,
            description: `Please contact Cyan team via discord.`,
          });
          return;
        }
        const encodedPayFnData = paymentContractWriter.interface.encodeFunctionData("pay", [
          plan.planId,
          isEarlyRepayment,
        ]);
        const encodedPayFnDataFormatted = [paymentPlanV2, isNativeCurrency ? paymentAmount : 0, encodedPayFnData];
        let encodedCyanExecuteFnData = "";
        if (!isNativeCurrency) {
          const approveFnData = await getApproveNonNativeCurrencyAllowanceCyan(signer, cyanWallet);
          if (approveFnData) {
            encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
              [approveFnData, encodedPayFnDataFormatted],
            ]);
          } else {
            encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("execute", encodedPayFnDataFormatted);
          }
        } else {
          encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("execute", encodedPayFnDataFormatted);
        }

        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          value: 0,
          data: encodedCyanExecuteFnData,
        });
      } else {
        tx = await paymentContractWriter.pay(plan.planId, isEarlyRepayment, {
          value: isNativeCurrency ? paymentAmount : 0,
        });
      }
    }
    addTransaction({
      hash: tx.hash,
      type: isBnpl ? "bnpl-pay" : "pawn-pay",
      data: {
        planId: plan.planId,
        currentNumOfPayments: plan.currentNumOfPayments,
        tokenId: plan.tokenId,
        contractAddress: plan.address,
      },
    });
    setActiveTx(tx.hash);
  };

  const transferErc20 = async () => {
    if (!signer || !account || !cyanWallet || !provider) return;
    const sampleErc20 = f.SampleERC20TokenFactory.connect(currency.address, signer);
    const txn = await sampleErc20.transfer(cyanWallet.walletAddress, payment.amount);
    await txn.wait();
  };

  useEffect(() => {
    const onStepChange = async (step: PlanRepaymentSteps) => {
      if (!chainId || !account || !signer || !provider) return;
      try {
        switch (step) {
          case PlanRepaymentSteps.TokenApproval: {
            await checkBalance();
            if (isErc20ApprovalRequired) {
              await checkAndApproveNonNativeCurrencyAllowanceMain(signer);
            }
            setSelectedStep(PlanRepaymentSteps.TokenTransfer);
            break;
          }
          case PlanRepaymentSteps.TokenTransfer: {
            if (isErc20TransferRequired) {
              await transferErc20();
            }
            setSelectedStep(PlanRepaymentSteps.Paying);
            break;
          }
          case PlanRepaymentSteps.Paying: {
            await handlePaymentV2();
          }
        }
      } catch (err: any) {
        openRepaymentModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    setModalContent({
      title: `Loan Details`,
      content: (
        <PlanRepayment
          pawn={pawn}
          bnpl={bnpl}
          planType={plan.isBnpl ? "bnpl" : "pawn"}
          onClose={onClose}
          error={mappedError}
        />
      ),
    });
  };

  const stepMarks = useMemo(() => {
    return PlanRepaymentStepMarks.map(item => {
      if (item.value === PlanRepaymentSteps.Paying) {
        return { ...item, description: getChainExplorerTextForTxn(chainId) };
      }
      return item;
    });
  }, [chainId]);

  return (
    <Flex gap="5x" direction="column">
      <PlanMetadata
        plans={[
          {
            ...plan,
            currency,
          },
        ]}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === PlanRepaymentSteps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};

enum PlanRepaymentSteps {
  TokenApproval = 1,
  TokenTransfer = 2,
  Paying = 3,
  Done = 4,
}

const PlanRepaymentStepMarks = [
  {
    value: PlanRepaymentSteps.TokenApproval,
    title: `Token Approval`,
  },
  {
    value: PlanRepaymentSteps.TokenTransfer,
    title: `Token Transfer`,
    description: `Transfer required amount of tokens to the Cyan Wallet`,
  },
  {
    value: PlanRepaymentSteps.Paying,
    title: `Processing Payment`,
    description: `View on Etherscan`,
  },
];

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
