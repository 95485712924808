import { formatCompactNumber, numberWithCommas } from "@/utils";

export const formatCyanPoint = (point: number | null) => {
  if (point === 0 || point === null) {
    return "0";
  } else if (point < 1) {
    return point.toFixed(3);
  } else if (point >= 10000) {
    return formatCompactNumber(point);
  }
  return numberWithCommas(point);
};
