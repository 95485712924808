import React from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "../../Flex";
import { AlertTriangle, Confetti } from "../icons";
import { Text } from "./Text";

const MsgBox = styled(Flex)<{
  variant: "error" | "warning" | "success";
}>`
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 1rem;
  background-color: ${({ theme, variant }) => {
    switch (variant) {
      case "error":
        return theme.colors.red;
      case "success":
        return theme.colors.green;
      case "warning":
      default:
        return theme.colors.yellow;
    }
  }};
  flex-direction: column;
`;

export const SystemMessage: React.FC<{
  title: string;
  msg: string | React.ReactNode;
  description?: string | React.ReactNode;
  variant?: "error" | "warning" | "success";
}> = ({ title, msg, description, variant = "warning" }) => {
  const theme = useTheme();
  return (
    <MsgBox variant={variant} gap="1rem">
      <Flex direction="column" gap="0.2rem">
        <Flex alignItems="center" gap="0.2rem">
          {variant === "success" ? (
            <Confetti width={16} height={16} />
          ) : (
            <AlertTriangle color={theme.colors.black} width={16} height={16} />
          )}
          <Text size="md" weight="700" color="black">
            {title}
          </Text>
        </Flex>
        {React.isValidElement(msg) ? (
          msg
        ) : (
          <Text size="xs" weight="500" color="black">
            {msg}
          </Text>
        )}
      </Flex>
      {description &&
        (React.isValidElement(description) ? (
          description
        ) : (
          <Text size="xs" weight="500" color="black">
            {description}
          </Text>
        ))}
    </MsgBox>
  );
};
