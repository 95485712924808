import { useMemo } from "react";
import { HelpCircle } from "react-feather";
import { useTheme } from "styled-components";

import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Flex } from "@cyanco/components/theme/Flex";
import {
  CsvButton,
  Hidden,
  SwitchButton,
  SwitchButtonGroup,
  Text,
  Toggler,
  Tooltip,
  TooltipText,
} from "@cyanco/components/theme/v3";
import { Grid, List } from "@cyanco/components/theme/v3/icons";

import { IPeerPlan, isPeerPlan } from "@/apis/p2p/types";
import { useAppContext } from "@/components/AppContextProvider";
import { BNPLStatuses, IBNPL, isBnplPlan } from "@/components/Bnpl/bnpl.types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedChainId } from "@/constants/chains";
import { bigNumToFixedStr, bigNumToFloat } from "@/utils";

import { StickySelectorWrapper } from "../..";
import { useBNPLPositions, usePawnPositions, usePeerPlans, usePositionsSorted } from "../../AccountDataContext";
import {
  IPositionsSelectedViews,
  IWalletViewStyle,
  PositionsSelectedViews,
  WalletViewStyle,
  usePositionsTabContext,
  useTabViewStyle,
} from "../../AccountPageContext";
import { IPawn, PawnStatuses, isPawnPlan } from "../../pawn.types";
import { Settings } from "../Selectors/Settings";
import { checkIsPositionDefaulted } from "./utils";

export const PositionViewSelectors = () => {
  const { chainId } = useWeb3React();
  const theme = useTheme();
  const {
    selectedPositionsView,
    setSelectedPositionsView,
    showDefaultedPlans,
    showRejectedPlans,
    setShowDefaultedPlans,
    setShowRejectedPlans,
    showRecentRejectedLoans,
    setShowRecentRejectedLoans,
  } = usePositionsTabContext();
  const { usdPrice } = useAppContext();
  const { positionsSorted } = usePositionsSorted();
  const { pawnPositions, activePawnPositions } = usePawnPositions();
  const { bnplPositions, activeBnplPositions } = useBNPLPositions();
  const { userTakenPeerPlans } = usePeerPlans();

  const totalNextPayment = useMemo(() => {
    const totalInUsd = [...activeBnplPositions, ...activePawnPositions]
      .filter(plan => plan.paymentPlan.chainId === chainId)
      .reduce((acc, cur) => {
        return acc + bigNumToFloat(cur.monthlyAmount, cur.currency.decimal) * usdPrice[cur.currency.symbol];
      }, 0);
    const eth =
      totalInUsd /
      (chainId === SupportedChainId.POLYGON ? usdPrice[SupportedCurrencies.WETH] : usdPrice[SupportedCurrencies.ETH]);
    return {
      value: eth,
      currencySymbol: chainId === SupportedChainId.POLYGON ? SupportedCurrencies.WETH : SupportedCurrencies.ETH,
    };
  }, [activeBnplPositions, activePawnPositions, usdPrice, chainId]);

  const totalPayment = useMemo(() => {
    const totalInUsd = [...activeBnplPositions, ...activePawnPositions]
      .filter(plan => plan.paymentPlan.chainId === chainId)
      .reduce((acc, cur) => {
        return (
          acc +
          bigNumToFloat(cur.monthlyAmount, cur.currency.decimal) *
            (cur.totalNumOfPayments - cur.currentNumOfPayments) *
            usdPrice[cur.currency.symbol]
        );
      }, 0);
    const eth =
      totalInUsd /
      (chainId === SupportedChainId.POLYGON ? usdPrice[SupportedCurrencies.WETH] : usdPrice[SupportedCurrencies.ETH]);
    return {
      value: eth,
      currencySymbol: chainId === SupportedChainId.POLYGON ? SupportedCurrencies.WETH : SupportedCurrencies.ETH,
    };
  }, [activeBnplPositions, activePawnPositions, usdPrice, chainId]);

  const getPawns = () => {
    const pawns: any[] = [];
    [...pawnPositions, ...bnplPositions].forEach(position => {
      if (
        (isPawnPlan(position) && position.status === PawnStatuses.Defaulted) ||
        position.status === PawnStatuses.Liquidated ||
        (isBnplPlan(position) &&
          (position.status === BNPLStatuses.Defaulted || position.status === BNPLStatuses.Liquidated))
      ) {
        pawns.push([
          isPawnPlan(position) ? "Pawn" : "BNPL",
          `${position.metadata.collection.name} ${position.tokenId}`,
          position.planId,
          position.createdAt,
          `${bigNumToFixedStr(position.monthlyAmount, 5, position.currency.decimal)} ${position.currency.symbol}`,
          "Missed",
          `${position.currentNumOfPayments}/${position.totalNumOfPayments}`,
          "-",
        ]);
        return;
      }
      pawns.push(
        ...position.payments.map(payment => {
          const status =
            (position.numOfEarlyPaidPayments ?? 0) > 1 && payment.paymentNumber === position.totalNumOfPayments
              ? "Completed Early"
              : payment.paymentNumber === position.totalNumOfPayments && position.status === PawnStatuses.Completed
              ? "Completed"
              : "Paid";

          return [
            isPawnPlan(position) ? "Pawn" : "BNPL",
            `${position.metadata.collection.name} ${position.tokenId}`,
            position.planId,
            position.createdAt,
            `${bigNumToFixedStr(position.monthlyAmount, 5, position.currency.decimal)} ${position.currency.symbol}`,
            status,
            `${payment.paymentNumber}/${position.totalNumOfPayments}`,
            payment.txnHash,
          ];
        }),
      );
    });
    return pawns;
  };
  const csvData = useMemo(() => {
    if (selectedPositionsView === PositionsSelectedViews.historical) {
      return [
        ["Plan Type", "Token ID", "Plan ID", "Payment Date", "Payment Amount", "Status", "Payment Number", "Txn Hash"],
        ...getPawns(),
        ...userTakenPeerPlans.map(position => [
          "P2P",
          `${position.collection.name} ${position.tokenId}`,
          position.planId,
          position.createdAt,
          `${bigNumToFixedStr(position.monthlyAmount, 5, position.loanBid.currency.decimal)} ${
            position.loanBid.currency.symbol
          }`,
          "-",
          "-",
          "-",
        ]),
      ].join("\n");
    }

    const getNextDue = (position: IPeerPlan | IPawn | IBNPL) => {
      if (position.nextPaymentDate) return position.nextPaymentDate;
      if (checkIsPositionDefaulted(position)) {
        return "Defaulted";
      }
      if (position.planType === "BNPL" && position.status !== BNPLStatuses.Activated) {
        if (position.status == BNPLStatuses.Rejected) return "Rejected";
        else if (position.status == BNPLStatuses.Funded) return "Purchasing";
        else return "Funding";
      }
      return "-";
    };
    return [
      ["Plan Type", "Token ID", "Plan ID", "Payment Amount", "Next due", "Loan Progress"],
      ...positionsSorted.map(position => {
        const currency = isPeerPlan(position) ? position.loanBid.currency : position.currency;

        return [
          position.planType,
          `${isPeerPlan(position) ? position.collection.name : position.metadata.collection.name} ${position.tokenId}`,
          position.planId,
          `${bigNumToFixedStr(position.monthlyAmount, 4, currency.decimal)} ${currency.symbol}`,
          getNextDue(position),
          !isPeerPlan(position) ? `${position.currentNumOfPayments}/${position.totalNumOfPayments}` : "-",
        ];
      }),
    ].join("\n");
  }, [selectedPositionsView, pawnPositions, bnplPositions, userTakenPeerPlans, positionsSorted]);

  const { tabViewStyle, setTabViewStyle } = useTabViewStyle();
  return (
    <StickySelectorWrapper alignItems="center" justifyContent="space-between" w="100%" h="28px" pb="1rem">
      <Flex gap="2px" alignItems="center">
        <SwitchButtonGroup<IPositionsSelectedViews>
          value={selectedPositionsView}
          onChange={setSelectedPositionsView}
          hover
        >
          <SwitchButton height="22px" value={PositionsSelectedViews.current}>{`Current`}</SwitchButton>
          <SwitchButton height="22px" value={PositionsSelectedViews.historical}>{`Historical`}</SwitchButton>
        </SwitchButtonGroup>
        {selectedPositionsView === PositionsSelectedViews.current && (
          <Settings>
            <Flex alignItems="center" gap="1rem" p="0.2rem 0.3rem" justifyContent="space-between">
              <Text size="sm" color="secondary" textWrap={false}>
                {`Show defaulted loans`}
              </Text>
              <Toggler value={showDefaultedPlans} onChange={setShowDefaultedPlans} size="sm" />
            </Flex>
            <Flex alignItems="center" gap="1rem" p="0.2rem 0.3rem" justifyContent="space-between" mt="5px">
              <Text size="sm" color="secondary" textWrap={false}>
                {`Show rejected loans`}
              </Text>
              <Toggler value={showRejectedPlans} onChange={setShowRejectedPlans} size="sm" />
            </Flex>

            <Flex alignItems="center" gap="1rem" p="0.2rem 0.3rem" justifyContent="space-between" mt="5px">
              <Flex alignItems="center" gap="5px">
                <Text size="sm" color="secondary" textWrap={false}>
                  {`Show recently rejected loans`}
                </Text>
                <Tooltip>
                  <HelpCircle height={16} width={16} color={theme.colors.secondary} />
                  <TooltipText showArrow position="top" top="-76px" right="-51px" style={{ width: "100px" }}>
                    <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                      <div>{`This option will filter loans to only those rejected in the past three days.`}</div>
                    </Text>
                  </TooltipText>
                </Tooltip>
              </Flex>
              <Toggler value={showRecentRejectedLoans} onChange={setShowRecentRejectedLoans} size="sm" />
            </Flex>
          </Settings>
        )}
      </Flex>

      <Flex alignItems="center" gap="1rem">
        {[...activeBnplPositions, ...activePawnPositions].length > 0 && (
          <Hidden tabletDown>
            <Flex gap="20px" alignItems="center">
              <Flex alignItems="center" gap="5px">
                <Text color="gray90" size="xs" textWrap={false}>
                  {`Total loans outstanding: ${totalPayment.value.toFixed(4)} ${totalPayment.currencySymbol}`}
                </Text>
                <Tooltip>
                  <HelpCircle size={13} color={theme.colors.gray90} />
                  <TooltipText showArrow position="top" top="-87px" right="-72px" style={{ width: "150px" }}>
                    <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                      <div>
                        {`Total loans outstanding is the sum of all the loans still owed. It does not include the amounts already paid towards the loan.`}
                      </div>
                    </Text>
                  </TooltipText>
                </Tooltip>
              </Flex>
              <Flex alignItems="center" gap="5px">
                <Text color="gray90" size="xs" textWrap={false}>
                  {`All next payments: ${totalNextPayment.value.toFixed(4)} ${totalNextPayment.currencySymbol}`}
                </Text>
                <Tooltip>
                  <HelpCircle size={13} color={theme.colors.gray90} />
                  <TooltipText showArrow position="top" top="-99px" right="-72.5px" style={{ width: "150px" }}>
                    <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                      <div>
                        {`All next payments are the sum of all the payments owed for each loan, to the nearest due date. It does not include amounts owed at later payment dates.`}
                      </div>
                    </Text>
                  </TooltipText>
                </Tooltip>
              </Flex>
            </Flex>
          </Hidden>
        )}
        <CsvButton data={csvData} />
        {selectedPositionsView === PositionsSelectedViews.current && (
          <Flex gap="1rem" alignItems="center">
            <SwitchButtonGroup<IWalletViewStyle> value={tabViewStyle} onChange={setTabViewStyle} hover>
              <SwitchButton height="22px" value={WalletViewStyle.list}>
                <List height={10} width={14} />
              </SwitchButton>
              <SwitchButton height="22px" value={WalletViewStyle.grid}>
                <Grid height={10} width={10} />
              </SwitchButton>
            </SwitchButtonGroup>
          </Flex>
        )}
      </Flex>
    </StickySelectorWrapper>
  );
};
