export enum SupportedChainId {
  MAINNET = 1,
  MUMBAI = 80001,
  BLAST = 81457,
  RINKEBY = 4,
  ROPSTEN = 3,
  KOVAN = 42,
  POLYGON = 137,
  ARBITRUM = 42161,
  OPTIMISM = 10,
  AVALANCHE = 43114,
  SEPOLIA = 11155111,
  BLAST_SEPOLIA = 168587773,
  CURTIS = 33111,
  APECHAIN = 33139,
}
