import orderBy from "lodash.orderby";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { SkeletonLine, StyledCheckbox, SubTitle, Text } from "@cyanco/components/theme";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { CollectioNoImage } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";

import { divideArrayByN, shortenName } from "../../../../utils";

export const VaultCollections = ({ vault }: { vault: IVault }) => {
  const collectionLength = vault.supportedProjects.length;
  const collectionCountPerColumn = collectionLength > 10 ? Math.ceil(collectionLength / 3) : 10;
  const collectionDivided = divideArrayByN(
    orderBy(vault.supportedProjects, [item => item.name.toLowerCase()]),
    collectionCountPerColumn,
  );
  return (
    <Container direction="column" gap="1.5rem" mt="4.5rem">
      <SubTitle>{`Collections`}</SubTitle>
      <VaultContainer>
        {collectionDivided.map((collections, index) => {
          return (
            <Flex direction="column" gap="1rem" key={index}>
              {collections.map(collection => (
                <Collection
                  collection={{
                    name: collection.name,
                    imageUrl: collection.imageUrl,
                    chainId: vault.chainId,
                    slug: collection.slug,
                    address: collection.address,
                  }}
                  key={collection.slug}
                />
              ))}
            </Flex>
          );
        })}
      </VaultContainer>
    </Container>
  );
};

const Collection = ({
  collection,
  isEditing,
  isChecked,
  onSelectCollection,
}: {
  collection: { name: string; imageUrl?: string; chainId: number; slug: string; address: string; isNew?: boolean };
  isEditing?: boolean;
  isChecked?: boolean;
  onSelectCollection?: (a: string) => void;
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  const navigate = useNavigate();
  const localUrl = `/bnpl/${CHAIN_IDS_TO_NAMES[collection.chainId as SupportedChainId]}/${collection.slug}`;
  return (
    <Flex alignItems="center" gap="8px">
      {isEditing && (
        <StyledCheckbox
          checked={isChecked ?? false}
          id={collection.slug}
          onChange={() => onSelectCollection && onSelectCollection(collection.address)}
        />
      )}
      <CollectionWrapper
        gap="8px"
        onClick={() => (isEditing && onSelectCollection ? onSelectCollection(collection.address) : navigate(localUrl))}
        style={{ cursor: "pointer" }}
      >
        {imageLoading && <SkeletonLine borderRadius="50%" w="24px" h="24px" />}
        <StyledImg
          src={collection.imageUrl ?? CollectioNoImage}
          alt={collection.name}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
            opacity: isChecked || !isEditing ? 1 : 0.7,
          }}
        />
        <CollectionText color={isChecked || !isEditing ? "secondary" : "gray0"} size="lg">
          {shortenName(collection.name, 30)}
        </CollectionText>
      </CollectionWrapper>
    </Flex>
  );
};

const Container = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0 5px" }])}
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  border-radius: 50%;
`;

const VaultContainer = styled(Flex)`
  flex-direction: row;
  gap: 5rem;
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])};
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])};
`;

const CollectionText = styled(Text)`
  transition: opacity 0.2s;
`;

const CollectionWrapper = styled(Flex)`
  width: fit-content;
  &:hover ${CollectionText} {
    opacity: 0.6;
  }
`;
