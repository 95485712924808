import { useEffect, useState } from "react";
import { HelpCircle } from "react-feather";
import { NumericFormat } from "react-number-format";
import styled, { useTheme } from "styled-components";

import { Box, Flex, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Input, Toggler, Tooltip, TooltipText } from "@cyanco/components/theme/v3";

import { IPoolSettings } from "@/apis/vault/admin/types";

export const RateCustomizationHeader = () => {
  const theme = useTheme();
  return (
    <HeaderContainer>
      <Flex gap="5px" alignItems="center">
        <Text weight="600" size="lg" color="secondary">
          {`LTV Ratio`}
        </Text>
        <Tooltip>
          <HelpCircle height={16} width={16} color={theme.colors.gray0} />
          <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
              <div>{`Loan-to-Value (LTV) represents the ratio of the loan amount offered against the appraised value of the NFT collateral.`}</div>
            </Text>
          </TooltipText>
        </Tooltip>
      </Flex>{" "}
      <Flex gap="5px" alignItems="center">
        <Text weight="600" size="lg" color="secondary">
          {`Weight Adjustment`}
        </Text>
        <Tooltip>
          <HelpCircle height={16} width={16} color={theme.colors.gray0} />
          <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
              <div>{`Override time multiplier for loan maturity`}</div>
            </Text>
          </TooltipText>
        </Tooltip>
      </Flex>{" "}
      <Text weight="600" size="lg" color="secondary">
        {`Active (BNPL)`}
      </Text>
      <Text weight="600" size="lg" color="secondary">
        {`Active (Loan)`}
      </Text>
    </HeaderContainer>
  );
};

export const RateCustomizationRow = ({
  option,
  index,
  handleFormChange,
}: {
  option: IPoolSettings;
  index: number;
  handleFormChange: (
    index: number,
    value: number,
    target: "weight" | "ltvRatio" | "isActivePawn" | "isActiveBnpl" | "maturity",
  ) => void;
}) => {
  const theme = useTheme();
  const [weight, setWeight] = useState<string>(option.weight.toString());
  useEffect(() => {
    const weightInNumber = weight === "" || isNaN(parseFloat(weight)) ? 0 : parseFloat(weight);
    handleFormChange(index, weightInNumber, "weight");
  }, [weight]);
  return (
    <Row>
      <RowText color={"secondary"}>{option.ltvRatio.toFixed(2)}%</RowText>
      <Box w="100px">
        <NumericFormat
          value={weight}
          onValueChange={values => setWeight(values.value)}
          fontSize="lg"
          decimalScale={3}
          placeholder="0"
          customInput={Input}
          style={{
            color: theme.colors.secondary,
          }}
          valueIsNumericString
          isAllowed={values => {
            return values.value === "" || (values.floatValue !== undefined && values.floatValue >= 0);
          }}
        />
      </Box>
      <Toggler
        value={option.isActiveBnpl}
        onChange={value => handleFormChange(index, value ? 1 : 0, "isActiveBnpl")}
        size="sm"
      />
      <Toggler
        value={option.isActivePawn}
        onChange={value => handleFormChange(index, value ? 1 : 0, "isActivePawn")}
        size="sm"
      />
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  grid-template-columns: 1.2fr 1.4fr 1fr 0.8fr;
  padding: 0.8rem 1.2rem;
  background-color: transparent;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  border: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
  border-radius: 20px;
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.tablet]: "fit-content" }])};
`;

const HeaderContainer = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  grid-template-columns: 1.2fr 1.4fr 1fr 0.8fr;
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
`;

const RowText = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`;
