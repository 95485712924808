import { ArrowUpRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";

import { TESTDRIVE_URL } from "@/config";
import { IsInTestDrive } from "@/utils";

import { TestDriveTitle } from ".";
import TESTDRIVE_IMAGE from "../../assets/images/testdrive/testdrive.png";

export const TestDriveSection = () => {
  const navigate = useNavigate();

  return (
    <TestDriveWrapper
      onClick={() => {
        if (!IsInTestDrive) {
          window.location.href = `${TESTDRIVE_URL}/testdrive`;
        } else {
          navigate("/testdrive");
        }
      }}
    >
      <TestDriveBgWrapper>
        <TestDriveImage src={TESTDRIVE_IMAGE} alt="Test Drive" />
      </TestDriveBgWrapper>
      <TestDriveTop>
        <TestDriveTitle>Test drive the Cyan Wallet</TestDriveTitle>
        <StyledArrow style={{ marginTop: "20px" }} color="#00FFFF" strokeWidth={0.8} />
      </TestDriveTop>
    </TestDriveWrapper>
  );
};

const StyledArrow = styled(ArrowUpRight)`
  width: 300px;
  height: 300px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 50px;
    height: 50px;
  }
`;
const TestDriveBgWrapper = styled.div`
  transition: 0.2s ease-in-out;
`;

const TestDriveWrapper = styled(Flex)`
  overflow: hidden;
  border-radius: 30px;
  margin-top: 30px;
  border: 2px solid #6c6c6c;
  position: relative;
  &:hover {
    ${TestDriveBgWrapper} {
      transform: scale(1.1) !important;
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    height: 240px !important;
  }
`;

const TestDriveTop = styled.div`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  }
  &::before {
    left: -100%;
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    &::before {
      left: 100%;
    }
  }
`;

const TestDriveImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
