import { useMemo } from "react";
import { ArrowDownRight } from "react-feather";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled, { useTheme } from "styled-components";

import { Flex, SkeletonLine, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { ILeaderboardPoint } from "@/apis/cyan-points";
import { StyledIncrease } from "@/components/Bnpl/components/AvailableCollections/CollectionStats";
import { numberWithCommas, shortenAddress } from "@/utils";

import { formatNumberWithSign, getBooster } from "../UserProgress";
import { formatCyanPoint } from "../utils";

export const LeaderBoardHeader = () => {
  return (
    <HeaderContainer>
      <HeaderText color="secondary"></HeaderText>
      <HeaderText color="secondary" textAlign="left">{`Name`}</HeaderText>
      <HeaderText color="secondary" textAlign="right">{`Boost`}</HeaderText>
      <HeaderText color="secondary" textAlign="right">{`Weekly Points`}</HeaderText>
      <HeaderText color="secondary" textAlign="right">{`Unrealized Points`}</HeaderText>
      <HeaderText color="secondary" textAlign="right">{`Ranking change`}</HeaderText>
      <HeaderText color="secondary" textAlign="right">{`Total points`}</HeaderText>
    </HeaderContainer>
  );
};

export const LeaderBoardLoading = () => {
  return (
    <Row>
      <div />
      <Flex alignItems="center" gap="8px">
        <SkeletonLine borderRadius="50%" h="24px" w="24px" />
        <RowLoadingText />
      </Flex>
      <RowLoadingText />
      <RowLoadingText />
      <RowLoadingText />
      <RowLoadingText />
    </Row>
  );
};

export const LeaderBoardRow = ({
  point,
  userWallet,
}: {
  point: ILeaderboardPoint & { index: number };
  userWallet: string;
}) => {
  const theme = useTheme();
  const rankChange = useMemo(() => {
    if (!point.rank) return null;
    const rank = point.lastWeekRank ? point.lastWeekRank - point.rank : point.rank;
    return rank;
  }, [point]);
  const isUserStats = point.wallet.toLowerCase() === userWallet.toLowerCase();
  return (
    <Row selected={isUserStats}>
      <RowText color="gray0" align="center">
        {point.index >= 0 ? point.index + 1 : "-"}
      </RowText>
      <Flex alignItems="center" gap="8px">
        <Jazzicon seed={jsNumberForAddress(point.wallet)} diameter={22} />
        <RowText isSelected={isUserStats} weight={isUserStats ? "700" : "400"}>
          {isUserStats ? "YOU" : shortenAddress(point.wallet)}
        </RowText>
      </Flex>
      <RowText isSelected={isUserStats}>{getBooster(point.rank).toFixed(2)}x</RowText>
      <RowText isSelected={isUserStats}>{formatCyanPoint(point.realized)}</RowText>
      <RowText isSelected={isUserStats}>{formatCyanPoint(point.unrealized)}</RowText>
      {rankChange !== null ? (
        <Flex alignItems="center" justifyContent="flex-end" gap="2px">
          {rankChange >= 0 ? (
            <StyledIncrease size={18} color={theme.colors.green} />
          ) : (
            <ArrowDownRight size={18} color="red" />
          )}
          <RowText color={rankChange >= 0 ? theme.colors.green : "red"}>{formatNumberWithSign(rankChange)}</RowText>
        </Flex>
      ) : (
        <RowText isSelected={isUserStats}>-</RowText>
      )}
      <RowText isSelected={isUserStats}>{numberWithCommas(point.totalRealized)}</RowText>
    </Row>
  );
};

const Row = styled.div<{ selected?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: end;
  grid-template-columns: 0.2fr 1.4fr 0.8fr 1.2fr 1.3fr 1.2fr 1fr;
  padding: 18px 20px 18px 14px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.gray10 : "transparent")};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 10px 12px 10px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;

const HeaderContainer = styled(Row)`
  padding: 14px 19px 12px 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "14px 19px 12px 18px" },
    { [breakpoints.laptop]: "14px 10px 12px 10px" },
    { [breakpoints.tablet]: "10px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
`;

const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptop]: 12 }])};
`;

const RowLoadingText = styled(SkeletonLine)`
  justify-self: flex-end;
  width: 70%;
  height: 20px;
  ${getStyleWithMediaQuery("height", "px", [
    { [breakpoints.desktop]: 17 },
    { [breakpoints.laptop]: 15 },
    { [breakpoints.tablet]: 13 },
  ])};
`;

const RowText = styled(Text)<{ align?: string; isSelected?: boolean }>`
  display: flex;
  justify-content: ${({ align }) => (align ? align : "end")};
  font-size: 16px;
  color: ${({ theme, isSelected, color }) =>
    color ? color : isSelected ? (theme.theme === "dark" ? theme.colors.cyan : "black") : theme.colors.secondary};

  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptop]: 14 }, { [breakpoints.tablet]: 12 }])};
`;
