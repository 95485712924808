export class NoWalletError extends Error {
  constructor() {
    super("User don't have a an CyanWallet");
    Object.setPrototypeOf(this, NoWalletError.prototype);
  }
}

export class NotInitialized extends Error {
  constructor() {
    super("Cyan wallet not initialized");
    Object.setPrototypeOf(this, NotInitialized.prototype);
  }
}
