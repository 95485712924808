import { FC, useEffect, useMemo, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import styled, { useTheme } from "styled-components";

import { Flex, StyledCheckbox, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, NotFound, Progress, SkeletonLine, useModal } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { ICollectionFloorAsk } from "@/apis/collection/types";
import { getLatestVaultLoansAdmin } from "@/apis/vault/admin";
import { IVaultLatestLoan } from "@/apis/vault/types";
import { useSupportedCollections } from "@/components/AppContextProvider";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import Pagination from "@/components/Pagination";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { IPlanStatus } from "@/constants/plans";
import { bigNumToFixedStr, bigNumToFloatFormatted, shortenName } from "@/utils";

import { IVaultDefaultedNftTable, PAGINATION_SIZE } from ".";
import {
  LatestLoanBodyRowProps,
  LatestLoanLoadingRow,
  StyledImg,
  VaultRowText,
} from "../../LatestLoans/LatestLoanBodyRow";
import { ContainerBox, Header, ListViewBox } from "../../LatestLoans/LatestLoans";
import { VaultLoanHeaderText } from "../../LatestLoans/LatestLoansHeader";
import { VaultTableHeaderBase, VaultTableRowBase } from "../../common";
import { DefaultedLoanModal } from "../ClaimNft/DefaultedLoanModal";

const LiquidateReadyLoansHeader = ({
  totalCount,
  selectAll,
  isSelectedAll,
}: {
  totalCount: number;
  selectAll: () => void;
  isSelectedAll: boolean;
}) => {
  return (
    <StyledHeader>
      <div style={{ marginTop: "-2px" }}>
        <StyledCheckbox checked={isSelectedAll} onChange={selectAll} id={"check-all"} showMinus />
      </div>
      <VaultLoanHeaderText color="secondary" align="start">
        {`${totalCount} items`}
      </VaultLoanHeaderText>
      <Hidden tabletDown>
        <VaultLoanHeaderText align="start" color="secondary">
          Owed Amount
        </VaultLoanHeaderText>
      </Hidden>
      <Hidden tabletDown>
        <VaultLoanHeaderText align="start" color="secondary">
          Floor Price
        </VaultLoanHeaderText>
      </Hidden>
      <Hidden tabletDown>
        <VaultLoanHeaderText align="start" color="secondary">
          Payments Left
        </VaultLoanHeaderText>
      </Hidden>
      <VaultLoanHeaderText color="secondary">Liquidate</VaultLoanHeaderText>
    </StyledHeader>
  );
};

const LiquidateReadyLoansRow: FC<
  LatestLoanBodyRowProps & {
    openModal: (loan: IVaultLatestLoan, floorAsk?: ICollectionFloorAsk) => void;
    isSelected: boolean;
    selectLoan: (a: IVaultLatestLoan) => void;
    floorAsk?: ICollectionFloorAsk;
  }
> = ({ isSelected, loan, vault, floorAsk, openModal, selectLoan }) => {
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(true);
  const totalNumOfPaymentsLeft = loan.totalNumOfPayments - loan.currentNumOfPayments;
  return (
    <StyledRow style={{ cursor: "pointer" }} data-plan-id={loan.planId} data-created-at={loan.createdAt}>
      <div style={{ marginTop: "-2px" }}>
        <StyledCheckbox checked={isSelected} onChange={() => selectLoan(loan)} id={`${loan.planId}`} />
      </div>
      <Flex alignItems="center" gap="8px">
        {imageLoading && <SkeletonLine borderRadius="50%" w="24px" h="24px" />}
        <StyledImg
          src={loan.nftImageUrl ?? NoImage}
          alt={`${loan.collectionName}-${loan.tokenId}`}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
          }}
        />
        <Flex gap="5px">
          <VaultRowText color="secondary" textAlign="left">
            {vault.isAutoLiquidated
              ? shortenName(loan.collectionName, 27, 17, 7)
              : shortenName(loan.collectionName, 40)}{" "}
            #{shortenName(loan.tokenId, 5, 2, 1)}
          </VaultRowText>
          {vault.isAutoLiquidated && (
            <Hidden tabletDown>
              <Flex
                style={{
                  background: loan.status === IPlanStatus.Activated ? theme.colors.green : theme.colors.secondary,
                  borderRadius: "4px",
                }}
                p={"2px 4px"}
                h={"fit-content"}
                w="fit-content"
                mr="8px"
              >
                <Text color="primary" size="xxs" weight="700" textWrap={false}>
                  {loan.status === IPlanStatus.Activated ? "Auto Liquidate" : "Defaulted"}
                </Text>
              </Flex>
            </Hidden>
          )}
        </Flex>
      </Flex>
      <Hidden tabletDown>
        <VaultRowText color="secondary" align="start">
          {bigNumToFixedStr(loan.amountToCompleteLoan, 2, vault.decimals)} {vault.currency}
        </VaultRowText>
      </Hidden>
      <Hidden tabletDown>
        <VaultRowText color="secondary" align="start">
          {floorAsk?.price
            ? `${bigNumToFloatFormatted(floorAsk.price.amount.raw, floorAsk.price.currency.decimals, 3)} ${
                floorAsk.price.currency.symbol
              }`
            : "N/A"}
        </VaultRowText>
      </Hidden>
      <Hidden tabletDown>
        <Flex direction="column" gap="2px">
          <Flex>
            <div style={{ width: "80%" }}>
              <Progress steps={loan.totalNumOfPayments} current={loan.currentNumOfPayments} />
            </div>
            <VaultRowText weight="500" style={{ visibility: "hidden" }}>
              .
            </VaultRowText>
          </Flex>
          <div style={{ width: "80%" }}>
            <VaultRowText weight="500" color="gray0" size="xs" sub align="start">
              {`${totalNumOfPaymentsLeft} payment${totalNumOfPaymentsLeft > 1 ? "s" : ""} left`}
            </VaultRowText>
          </div>
        </Flex>
      </Hidden>
      <Flex justifyContent="flex-end">
        <Button
          size="sm"
          style={{
            width: "fit-content",
            padding: "0.3rem 0.6rem",
          }}
          onClick={() => {
            openModal(loan, floorAsk);
          }}
        >
          <VaultRowText color="black" style={{ fontWeight: 700 }}>
            Claim NFT
          </VaultRowText>
        </Button>
      </Flex>
    </StyledRow>
  );
};

export const LiquidateReadyLoansTable = ({
  vault,
  loading,
  selectedLoans,
  setSelectedLoans,
}: IVaultDefaultedNftTable) => {
  const { user } = useAuthContext();
  const { paymentLiquidatedNfts } = useTransactionContext();
  const { setModalContent } = useModal();
  const [totalLoans, setTotalLoans] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedDatas, setPaginatedDatas] = useState<IVaultLatestLoan[][]>([]);

  const { collections, collectionsLoading } = useSupportedCollections();
  const { result: autoLiquidateReadyPlans = [], loading: autoLiquidatePlansLoading } = useAsync<
    IVaultLatestLoan[]
  >(async () => {
    if (!vault.isAutoLiquidated) return [];
    if (!user?.token) return [];
    const data = await getLatestVaultLoansAdmin({
      contractAddress: vault.contractAddress,
      token: user.token,
      offset: 0,
      includeAutoLiquidationReadyPlans: true,
      statuses: [IPlanStatus.Activated],
    });
    return data.latestLoans;
  }, [vault]);
  const { loading: dataLoading, execute } = useAsyncCallback(
    async (pageNumber: number, currentTotal: number, existingDatas: IVaultLatestLoan[][]) => {
      if (!user?.token) return;
      if (!existingDatas[pageNumber - 1]?.length) {
        const data = await getLatestVaultLoansAdmin({
          contractAddress: vault.contractAddress,
          offset: (pageNumber - 1) * PAGINATION_SIZE,
          token: user.token,
          statuses: [IPlanStatus.Defaulted],
        });
        if (data.totalCount !== currentTotal) {
          setTotalLoans(data.totalCount);
        }
        const newPaginatedDatas = [...existingDatas];
        newPaginatedDatas[pageNumber - 1] = data.latestLoans;
        setPaginatedDatas(newPaginatedDatas);
      }
    },
  );

  useEffect(() => {
    setTotalLoans(0);
    setPaginatedDatas([]);
    setCurrentPage(1);
    execute(1, 0, []);
  }, [vault.contractAddress]);

  const paginatedDatasWithAutoLiquidate = useMemo(() => {
    const data = paginatedDatas.map((loans, index) => {
      if (index === 0) {
        return [...autoLiquidateReadyPlans, ...loans];
      }
      return loans;
    });
    return data;
  }, [paginatedDatas, autoLiquidateReadyPlans]);

  const openModal = (loan: IVaultLatestLoan, floorAsk?: ICollectionFloorAsk) => {
    setModalContent({
      title: "Claim NFT",
      content: <DefaultedLoanModal loan={{ ...loan, floorAsk }} vault={vault} />,
    });
  };

  const selectAll = () => {
    const datas = paginatedDatasWithAutoLiquidate[currentPage - 1] ?? [];
    if (!datas.every(item => selectedLoans.some(loan => loan.planId === item.planId))) {
      setSelectedLoans(prev => [...prev, ...datas]);
    } else {
      setSelectedLoans(prev => prev.filter(loan => !datas.some(item => item.planId === loan.planId)));
    }
  };

  const selectLoan = (loan: IVaultLatestLoan) => {
    if (!selectedLoans.some(item => item.planId === loan.planId)) {
      setSelectedLoans(prev => [...prev, loan]);
    } else {
      setSelectedLoans(prev => prev.filter(item => item.planId !== loan.planId));
    }
  };

  const paginatedData = (paginatedDatasWithAutoLiquidate[currentPage - 1] ?? []).filter(
    item => !paymentLiquidatedNfts.includes(item.planId),
  );

  return (
    <Flex direction="column" w="100%">
      <ListViewBox>
        <Header>
          <LiquidateReadyLoansHeader
            isSelectedAll={paginatedData.some(item => selectedLoans.some(loan => loan.planId === item.planId))}
            selectAll={selectAll}
            totalCount={paginatedData.length}
          />
        </Header>
        <ContainerBox>
          {dataLoading || loading || collectionsLoading || autoLiquidatePlansLoading ? (
            Array.from(Array(10).keys()).map(loader => <LatestLoanLoadingRow key={loader} />)
          ) : !paginatedData.length ? (
            <NotFound msg="No loans found" />
          ) : (
            paginatedData.map((loan, index) => {
              return (
                <LiquidateReadyLoansRow
                  vault={vault}
                  loan={loan}
                  index={(currentPage - 1) * PAGINATION_SIZE + index}
                  key={`${loan.planId}:${index}`}
                  openModal={openModal}
                  selectLoan={selectLoan}
                  isSelected={selectedLoans.some(item => item.planId === loan.planId)}
                  floorAsk={
                    collections.find(
                      collection => collection.address.toLowerCase() === loan.collectionAddress.toLowerCase(),
                    )?.floorAsk
                  }
                />
              );
            })
          )}
          <Pagination
            total={totalLoans}
            pageSize={PAGINATION_SIZE}
            current={currentPage}
            onPageChange={n => {
              setCurrentPage(n);
              execute(n, totalLoans, paginatedDatas);
            }}
          />
        </ContainerBox>
      </ListViewBox>
    </Flex>
  );
};

const StyledRow = styled(VaultTableRowBase)`
  grid-template-columns: 0.2fr minmax(0, 2.4fr) 1.3fr 1.3fr 1.2fr 1fr 0.1fr;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "0.2fr 1fr 1fr" }])};
`;

const StyledHeader = styled(VaultTableHeaderBase)`
  grid-template-columns: 0.2fr minmax(0, 2.4fr) 1.3fr 1.3fr 1.2fr 1fr 0.1fr;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "0.2fr 1fr 1fr" }])};
`;
