import dayjs from "dayjs";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useTheme } from "styled-components";

import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import {
  Button,
  Card,
  Flex,
  Input,
  NftMetadataInline,
  NftMetadataInlineImage,
  SystemMessage,
  Text,
  Tooltip,
  TooltipText,
  useModal,
} from "@cyanco/components/theme";
import { HelpCircle, NewTab } from "@cyanco/components/theme/v3/icons";

import { ICollectionFloorAsk } from "@/apis/collection/types";
import { IVault, IVaultLatestLoan } from "@/apis/vault/types";
import { OwnerAddress } from "@/components/P2P/collection/LoanOffers/NftMetadata";
import { DueCircle, StepperContainer } from "@/components/PlanPayment/RegularPayment";
import { IPlanStatus } from "@/constants/plans";
import {
  bigNumToFloatFormatted,
  getChainExplorerURL,
  getMarketItemUrl,
  jumpToLink,
  shortenAddress,
  shortenName,
} from "@/utils";

import { DefaultedLoanProgressModal } from "./DefaultedLoanProgressModal";

export const DefaultedLoanModal = ({
  loan,
  vault,
  error,
}: {
  loan: IVaultLatestLoan & { estimatedPrice?: number; floorAsk?: ICollectionFloorAsk };
  error?: { title: string; msg: string };
  vault: IVault;
}) => {
  const theme = useTheme();
  const [nftValue, setNftValue] = useState(loan.estimatedPrice ? loan.estimatedPrice.toFixed(4) : "");
  const { setModalContent } = useModal();
  const nextPaymentDate = dayjs(loan.createdAt)
    .add(loan.term * loan.currentNumOfPayments + 1, "seconds")
    .format("MMM DD, YYYY");
  const openModal = () => {
    setModalContent({
      title: "Claim NFT",
      content: <DefaultedLoanProgressModal loans={[{ ...loan, estimatedPrice: parseFloat(nftValue) }]} vault={vault} />,
    });
  };
  const isActive = loan.status === IPlanStatus.Activated;
  return (
    <Flex gap="12px" direction="column">
      <DefaultedNftMetadata loan={loan} vault={vault} />
      {error && <SystemMessage variant={"error"} title={error.title} msg={error.msg} />}
      <Card p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Text size="sm" weight="600" color="secondary">
            {isActive ? `Auto-liquidation` : `Defaulted Loan`}
          </Text>
          <StepperContainer>
            {isActive && (
              <Flex justifyContent="space-between" alignItems="center">
                <Flex gap="5px" alignItems="center">
                  <Flex alignItems="center" gap="8px">
                    <DueCircle isNextPayment />
                    <Text size="xs" weight="500" color="gray0">
                      Appraisal value
                    </Text>
                  </Flex>

                  <Tooltip>
                    <HelpCircle height={16} width={16} color={theme.colors.gray0} />
                    <TooltipText showArrow position="top" top="-75px" right="-70px" style={{ width: "150px" }}>
                      <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                        <div>
                          Cyan automatically appraises each collection by observing the floor price and bid depth across
                          marketplaces.
                        </div>
                      </Text>
                    </TooltipText>
                  </Tooltip>
                </Flex>
                <Text size="xs" weight="500" color={"gray0"}>
                  {`${bigNumToFloatFormatted(loan.appraisalValue, vault.decimals, 4)} ${vault.currency}`}
                </Text>
              </Flex>
            )}
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="8px">
                <DueCircle isDeducting />
                <Text size="xs" weight="500" color="gray0">
                  Loan principal value
                </Text>
              </Flex>
              <Text size="xs" weight="500" color={"gray0"}>
                {`${bigNumToFloatFormatted(loan.loanAmount, vault.decimals, 4)} ${vault.currency}`}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="8px">
                <DueCircle isMissed isLast={isActive ? false : true} />
                <Text size="xs" weight="500" color="gray0">
                  Amount owed
                </Text>
              </Flex>
              <Text size="xs" weight="500" color={"gray0"}>
                {`${bigNumToFloatFormatted(loan.amountToCompleteLoan, vault.decimals, 4)} ${vault.currency}`}
              </Text>
            </Flex>
            {isActive && (
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" gap="8px">
                  <DueCircle isPaid isLast />
                  <Text size="xs" weight="500" color="gray0">
                    Next payment date
                  </Text>
                </Flex>
                <Text size="xs" weight="500" color={"gray0"}>
                  {nextPaymentDate}
                </Text>
              </Flex>
            )}
          </StepperContainer>
          <Text size="xs" weight="500" color="gray0" textAlign="right">
            {isActive ? `NFT's price changed` : `Borrower Missed Payment`}
          </Text>
        </Flex>
      </Card>
      <Flex direction="column" gap="10px">
        <Text size="sm" weight="600" color="secondary">
          {`Mark-to-Market Value`}
        </Text>
        <NumericFormat
          value={nftValue}
          onValueChange={value => setNftValue(value.value)}
          placeholder="Enter the value of the NFT"
          customInput={Input}
          decimalScale={4}
          valueIsNumericString
        />
        <Button style={{ height: "50px" }} disabled={nftValue === ""} onClick={openModal}>{`Liquidate NFT`}</Button>
      </Flex>
    </Flex>
  );
};

export const DefaultedNftMetadata = ({
  loan,
  vault,
}: {
  loan: IVaultLatestLoan & { floorAsk?: ICollectionFloorAsk };
  vault: IVault;
}) => {
  const theme = useTheme();
  return (
    <Flex gap="10px" pt="5px">
      <NftMetadataInlineImage imageUrl={loan.nftImageUrl} />
      <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 8px 0">
        <NftMetadataInline
          name={shortenName(loan.collectionName, 21, 20, 0)}
          value={`#${shortenName(loan.tokenId, 10, 5)}`}
        />
        <Flex gap="3px" direction="column" w="100%" justifyContent="flex-end">
          <NftMetadataInline
            name={`Owed Amount`}
            value={`${bigNumToFloatFormatted(loan.amountToCompleteLoan, vault.decimals, 3)} ${vault.currency}`}
            sub
          />
          <Flex justifyContent="space-between">
            <Text color="gray0" size="xs" weight="500">
              {`Floor price`}
            </Text>
            <OwnerAddress
              gap="0.3rem"
              alignItems="center"
              onClick={() =>
                loan?.floorAsk?.price &&
                jumpToLink(
                  getMarketItemUrl(
                    loan.floorAsk.sourceName as SupportedMarketPlaces,
                    loan.collectionAddress,
                    loan.tokenId,
                    vault.chainId,
                  ),
                )
              }
            >
              <Text size="xs" color="gray0">
                {loan?.floorAsk?.price
                  ? `${bigNumToFloatFormatted(
                      loan.floorAsk.price.amount.raw,
                      loan.floorAsk.price.currency.decimals,
                      3,
                    )} ${loan.floorAsk.price.currency.symbol}`
                  : "N/A"}{" "}
              </Text>
              {loan?.floorAsk?.price && <NewTab color={theme.colors.gray0} height={12} width={12} />}{" "}
            </OwnerAddress>
          </Flex>
          <Flex justifyContent="space-between">
            <Text color="gray0" size="xs" weight="500">
              {`Borrower`}
            </Text>
            <OwnerAddress
              gap="0.3rem"
              alignItems="center"
              onClick={() => jumpToLink(`${getChainExplorerURL(vault.chainId)}/address/${loan.creatorWallet}`)}
            >
              <Text size="xs" color="gray0">
                {shortenAddress(loan.creatorWallet)}
              </Text>
              <NewTab color={theme.colors.gray0} height={12} width={12} />
            </OwnerAddress>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
