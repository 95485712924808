import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";

import { axios } from "../axios";
import { FnFetchCollections, ICollecionAttributes, ICollectionTag, ISearchCollectionResult } from "./types";

export const fetchCollectionTags = async (): Promise<ICollectionTag[]> => {
  return (await axios.get(`/v2/collections/tags`)).data;
};

export const fetchCollectionAttributes = async ({
  address,
  abortSignal,
  chainId,
}: {
  address: string;
  abortSignal?: AbortSignal;
  chainId: SupportedChainId;
}): Promise<ICollecionAttributes> => {
  const chainSlug = CHAIN_IDS_TO_NAMES[chainId];

  return (
    await axios.get(`/v2/collections/${address}/attributes`, { params: { chain: chainSlug }, signal: abortSignal })
  ).data;
};

export const fetchCollections: FnFetchCollections = async ({ chainId, abortSignal }) => {
  const params = chainId ? { chain: CHAIN_IDS_TO_NAMES[chainId] } : {};
  return (
    await axios.get(`/v2/collections`, {
      params,
      signal: abortSignal,
    })
  ).data;
};

export const searchCollections = async (chainId: SupportedChainId, name: string, abortSignal?: AbortSignal) => {
  return (
    await axios.get<ISearchCollectionResult>(`/v2/collections/search`, {
      params: {
        name,
        chain: CHAIN_IDS_TO_NAMES[chainId],
      },
      signal: abortSignal,
    })
  ).data;
};
