import { useMemo } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { Text } from "@cyanco/components/theme/v3";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { BAKCAddress, BAYCAddress, MAYCAddress } from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { formatCompactNumber, isApeCoinStakingPossible } from "@/utils";

import Bakc from "../../../../../assets/images/bakc.svg";
import Bayc from "../../../../../assets/images/bayc.svg";
import Mayc from "../../../../../assets/images/mayc.svg";
import { useApeCoinStatsContext } from "../../ApeCoinStatsContext";
import { IActionType, IApeCoinSource } from "../../types";
import { ApyBox, StakingButton, StakingText } from "./GridView";

export const PoolsApy = () => {
  const { showPlanModal } = useApePlanCreation();
  const { poolsWithBorrow, poolsWithoutBorrow, apeVault } = useApeCoinStatsContext();
  const pools = useMemo(() => {
    return [
      {
        name: `BAYC`,
        image: Bayc,
        borrowApy: poolsWithBorrow.BAYC.apy,
        apy: poolsWithoutBorrow.BAYC.apy,
        apr: apeVault.apr.BAYC,
        onClick: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.owned,
            selectedCollection: BAYCAddress.toLowerCase(),
          }),
        onClickBorrow: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.borrow,
            selectedCollection: BAYCAddress.toLowerCase(),
          }),
      },
      {
        name: `MAYC`,
        image: Mayc,
        borrowApy: poolsWithBorrow.MAYC.apy,
        apy: poolsWithoutBorrow.MAYC.apy,
        apr: apeVault.apr.MAYC,
        onClick: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.owned,
            selectedCollection: MAYCAddress.toLowerCase(),
          }),
        onClickBorrow: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.borrow,
            selectedCollection: MAYCAddress.toLowerCase(),
          }),
      },
      {
        name: `BAKC`,
        image: Bakc,
        borrowApy: poolsWithBorrow.BAKC.apy,
        apy: poolsWithoutBorrow.BAKC.apy,
        apr: apeVault.apr.BAKC,
        onClick: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.owned,
            selectedCollection: BAKCAddress.toLowerCase(),
          }),
        onClickBorrow: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.borrow,
            selectedCollection: BAKCAddress.toLowerCase(),
          }),
      },
    ];
  }, [poolsWithBorrow, poolsWithoutBorrow, apeVault]);
  return (
    <Container>
      {pools.map(pool => (
        <PoolApy key={pool.name} {...pool} />
      ))}
    </Container>
  );
};

const PoolApy = (args: {
  apy: number;
  borrowApy: number;
  name: string;
  image: string;
  onClick: () => void;
  onClickBorrow: () => void;
  apr: number;
}) => {
  const { chainId } = useWeb3React();
  const { apr, apy, borrowApy, name, image, onClick, onClickBorrow } = args;
  const differencePercent = useMemo(() => {
    const diff = apy - apr;
    if (diff < 0) return 0;
    return diff.toFixed(0);
  }, [apr, apy]);
  return (
    <Card>
      <Flex direction="column" alignItems="center" gap="16px">
        <StyledImg
          src={image}
          alt={name}
          style={{
            borderRadius: "50%",
          }}
        />
        <Flex direction="column" gap="5px" alignItems="center">
          <Text color="secondary" size="xl" weight="600">
            {name}
          </Text>
          <Text color="secondary" size="xs" weight="400">
            {`Auto-Compound APY`}
          </Text>
        </Flex>

        <Flex direction="column" gap="4rem" alignItems="center" w="100%">
          <Flex direction="column" w="100%">
            <ApyBox direction="column" alignItems="center" gap="2px" p="14px 0 14px 0" justifyContent="center">
              <Text color="secondary" size="xxl" weight="600" textAlign="center">
                🔥 {apy ? (apy > 100 ? formatCompactNumber(apy) : apy.toFixed(2)) : "- "}%
              </Text>
              <Text weight="500" color="secondary" size="xs">
                <Text weight="700" color="green" size="xs">
                  {" "}
                  +{differencePercent}%{" "}
                </Text>{" "}
                over native APY
              </Text>
            </ApyBox>
            <StakingButton onClick={onClick} disabled={!isApeCoinStakingPossible(chainId)}>
              <StakingText size="sm" weight={"700"} color={"cyan"}>
                {`Start Earning`}
              </StakingText>
            </StakingButton>
          </Flex>
          <Flex direction="column" w="100%">
            <ApyBoxGreen direction="column" alignItems="center" gap="2px" p="14px 0 14px 0" justifyContent="center">
              <Text color="secondary" size="xxl" weight="600" textAlign="center">
                {borrowApy ? (borrowApy > 100 ? formatCompactNumber(borrowApy) : borrowApy.toFixed(2)) : "- "}%
              </Text>
              <Text weight="500" color="secondary" size="xs">
                <Text weight="700" color="secondary" size="xs">
                  {" "}
                  FREE{" "}
                </Text>{" "}
                yield with staked NFT
              </Text>
            </ApyBoxGreen>

            <StakingButton onClick={onClickBorrow} disabled={!isApeCoinStakingPossible(chainId)}>
              <StakingText size="sm" weight={"700"} color={"cyan"}>
                {`Start Earning`}
              </StakingText>
            </StakingButton>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3.6rem;
  border-radius: 14px;
  transition: 0.2s;
  padding: 0 1.8rem;
  border: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
  background: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0;
  }
`;

const Card = styled.div`
  padding: 1.8rem 0;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  justify-content: space-between;
  align-item: center;
`;

const StyledImg = styled.img`
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  max-height: 36px;
  max-width: 36px;
  border-radius: 50%;
  background-color: black;
  border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
`;

export const ApyBoxGreen = styled(Flex)`
  border-radius: 14px;
  align-items: center;
  width: 100%;
`;
