import React, { FC } from "react";
import { ArrowLeft } from "react-feather";
import styled from "styled-components";

import { ReactComponent as Close } from "../assets/images/x.svg";
import { Box } from "./Box";
import StyledCheckbox from "./Checkbox";
import { Flex } from "./Flex";

export const StyledProjectLink = styled.a`
  display: flex;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 0 0 0.25rem 0;
  word-wrap: break-word;
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 0;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const CloseColor = styled(Close)`
  stroke: ${({ theme }) => theme.colors.secondary};
`;

export const CloseX = (prop: { onClick: () => void }) => {
  const { onClick } = prop;

  return (
    <CloseIcon onClick={onClick}>
      <CloseColor />
    </CloseIcon>
  );
};

const ArrowIcon = styled.div`
  transition: 0.2s;
  height: 20px;
  width: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const ArrowColor = styled(ArrowLeft)`
  stroke: ${({ theme }) => theme.colors.secondary};
`;

export const ArrowBack = (prop: { onClick: () => void }) => {
  const { onClick } = prop;

  return (
    <ArrowIcon onClick={onClick}>
      <ArrowColor height={"20px"} width="20px" />
    </ArrowIcon>
  );
};

export const ClickableArea: FC<{ onClick: () => void }> = ({ onClick, children }) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      {children}
    </div>
  );
};

export { Flex };
export { Box };
export { StyledCheckbox };
