import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { Text } from "@cyanco/components/theme/v3";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { currencyList } from "@/apis/coinbase";
import { useAppContext } from "@/components/AppContextProvider";
import { bigNumToFloat, displayInUSD, numberWithCommas } from "@/utils";

import { useApeCoinStatsContext } from "../ApeCoinStatsContext";

export const UserBalance = () => {
  const { usdPrice } = useAppContext();
  const { userBalance } = useApeCoinStatsContext();

  return (
    <Flex alignItems="center" gap="18px">
      <ApeCoinImg src={ApeCoin} />
      <Flex direction="column">
        <Flex justifyContent="space-between" gap="2rem">
          <Text color="gray0" size="sm" weight="600">
            APEUSD
          </Text>
          <Text color="secondary" size="sm" weight="600">
            {displayInUSD(usdPrice[currencyList.ape])}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" gap="2rem">
          <Text color="gray0" size="sm" weight="600">
            My Balance
          </Text>
          <Text color="secondary" size="sm" weight="600">
            {numberWithCommas(bigNumToFloat(userBalance.apeBalance), 2)} APE
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ApeCoinImg = styled.img`
  width: 33px;
  height: 33px;
`;
