import dayjs from "dayjs";
import orderBy from "lodash.orderby";
import { useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { AddTokenToWalletButton, Button, CopyButton, SubTitle, Text } from "@cyanco/components/theme/v3";
import { Decrease, Github, Globe, Increase, Medium, Twitter } from "@cyanco/components/theme/v3/icons";
import { CyanC, EtherscanGray } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { CYAN_GITHUB_LINK, CYAN_MEDIUM_LINK, CYAN_TWITTER_LINK, apeVaultContract } from "@/config";
import {
  bigNumToFixedStr,
  bigNumToFloat,
  getChainExplorerURL,
  jumpToLink,
  numberWithCommas,
  shortenAddress,
} from "@/utils";

import { CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS } from "../../../../constants/chains";
import { useVaults } from "../../VaultDataProvider";
import {
  IPriceChartIntervalType,
  calculateBorrowApy,
  calculateChangePercent,
  calculateSupplyApy,
} from "../../VaultPageContext";
import { VaultDetailsPrivate } from "./Admin/VaultDetailsPrivate";
import { PriceGraph } from "./PriceGraph";
import { VaultPublicDetailsSection } from "./VaultDetailsPublic";
import { VaultStake } from "./VaultStake";

const CYAN_SITE = "https://www.usecyan.com/";

export const VaultDetailsSection: React.FC<{
  vault: IVault;
  isPrivate?: boolean;
}> = ({ vault, isPrivate = false }) => {
  const { vaultAdditionalMetrics } = useVaults();
  const theme = useTheme();
  const [selectedPeriod, setSelectedPeriod] = useState<IPriceChartIntervalType>("365day");
  const getVaultStat = (value: number | string | null | undefined, postfix?: string, prefix?: string) => {
    return value !== null && value !== undefined ? `${prefix || ""}${numberWithCommas(value)} ${postfix || ""}` : "-";
  };
  const priceChange = useMemo(() => {
    if (!vault.price || !vault.history) return null;
    const now = dayjs();
    let date = now.subtract(7, "days");
    if (selectedPeriod === "30day") date = now.subtract(30, "days");
    if (selectedPeriod === "365day") date = now.subtract(1, "year");
    const filteredVaultHistory = vault.history.filter(
      price => price.price && dayjs(price.createdAt).isBefore(now) && dayjs(price.createdAt).isAfter(date),
    );
    const prevPrice =
      filteredVaultHistory.length > 0
        ? bigNumToFloat(orderBy(filteredVaultHistory, "createdAt", "asc")[0].price, vault.decimals)
        : null;
    const priceChange = calculateChangePercent(bigNumToFloat(vault.price, vault.decimals), prevPrice);
    let priceChangeFormatted;
    if (priceChange !== null) {
      if (priceChange > 0) {
        priceChangeFormatted = priceChange.toFixed(2);
      } else {
        const priceChangeWithFirstDecimal = priceChange.toFixed(20).match(/^-?\d*\.?0*\d{0,2}/);
        priceChangeFormatted =
          priceChangeWithFirstDecimal !== null
            ? priceChangeWithFirstDecimal[0].length > 7
              ? "0.00"
              : priceChangeWithFirstDecimal[0]
            : null;
      }
    }
    return priceChangeFormatted;
  }, [vault, selectedPeriod]);

  const supplyApy = useMemo(() => {
    if (vault.contractAddress.toLowerCase() === apeVaultContract) {
      if (!vault.estimatedYield) return "N/A";
      return `${(vault.estimatedYield / 100).toFixed(2)}%`;
    }
    if (!vault.estimatedYield || !vault.utilizationRate || !vault.transactionFee) return "N/A";
    return `${calculateSupplyApy(vault.estimatedYield, vault.utilizationRate).toFixed(2)}%`;
  }, [vault.estimatedYield, vault.utilizationRate]);

  const borrowApy = useMemo(() => {
    if (vault.contractAddress.toLowerCase() === apeVaultContract) return "- %";
    if (!vault.estimatedYield) return "N/A";
    return `${calculateBorrowApy(vault.estimatedYield).toFixed(2)}%`;
  }, [vault.estimatedYield]);

  return (
    <Container>
      <Flex direction="column" w="100%">
        <VaultTopWrapper>
          <VaultTopWrapperSub direction="column" w="100%">
            <Flex w="100%" gap="0.5rem" alignItems="center">
              <VaultImageWrapper style={{ background: vault.colorCode }}>
                <VaultImage src={CyanC} alt={vault.name} />
              </VaultImageWrapper>
              <Flex gap="5px" direction="column">
                <Flex gap="1rem">
                  <SubTitle>{vault.name}</SubTitle>
                  <VaultTitleSymbol>{vault.symbol}</VaultTitleSymbol>
                </Flex>
                <Flex alignItems="flex-end" gap="0.6rem">
                  <VaultTitlePrice color="secondary">
                    {vault.price
                      ? `${numberWithCommas(bigNumToFloat(vault.price, vault.decimals), 5)} ${vault.currency}`
                      : "N/A"}
                  </VaultTitlePrice>
                  {!!priceChange && (
                    <Flex alignItems="center" gap="5px" mb="2px">
                      {parseFloat(priceChange) >= 0 ? <Increase color={theme.colors.green} /> : <Decrease />}
                      <Text color={parseFloat(priceChange) >= 0 ? "green" : "red"} size="lg">
                        {Math.abs(parseFloat(priceChange))}%
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <div>
              <PriceGraph
                history={orderBy(
                  vault.history.map(snapshot => ({
                    createdAt: snapshot.createdAt,
                    price: bigNumToFloat(snapshot.price, vault.decimals),
                  })),
                  "createdAt",
                  "asc",
                )}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                symbol={vault.currency}
              />
            </div>
          </VaultTopWrapperSub>
          <VaultStakeMobile>
            <VaultStake vault={vault} />
          </VaultStakeMobile>
        </VaultTopWrapper>
        <VaultInfoContainer direction="column" gap="2rem">
          <VaultInfoTitleContainer>
            <div>
              <Flex alignItems="center" gap="15px">
                <Flex direction="column" gap="5px">
                  <SubTitle>{vault.name}</SubTitle>
                  <Flex gap="5px" wrap="wrap">
                    <VaultDetailSubText color="gray0">
                      {vault.supportedProjects.length} {`collections`}
                    </VaultDetailSubText>
                    <VaultDetailSubText color="gray0">|</VaultDetailSubText>
                    <VaultDetailSubText color="gray0">
                      {`created`} {dayjs(vault.createdAt).format("MMMM YYYY")}
                    </VaultDetailSubText>
                    <VaultDetailSubText color="gray0">|</VaultDetailSubText>
                    <VaultDetailSubText color="gray0">
                      {`vault fee`} {(vault.transactionFee / 100).toFixed(2)}%
                    </VaultDetailSubText>
                    <VaultDetailSubText color="gray0">|</VaultDetailSubText>
                    <VaultDetailSubText color="gray0">
                      {CHAIN_INFO[vault.chainId as typeof CYAN_SUPPORTED_CHAIN_IDS[number]].label || ""}
                    </VaultDetailSubText>
                  </Flex>
                </Flex>
              </Flex>
            </div>
          </VaultInfoTitleContainer>
          <Flex direction="column" gap="1.5rem">
            <VaultStatsGapWrapper gap="2.5rem" alignItems="center" wrap="wrap">
              <DailyVaultStat
                statName={`Total Value Locked`}
                statValue={
                  vault.totalValueLocked
                    ? getVaultStat(bigNumToFixedStr(vault.totalValueLocked, 2, vault.decimals), vault.currency)
                    : "N/A"
                }
              />
              <DailyVaultStat statName={`Borrowers`} statValue={getVaultStat(vault.numOfBorrowers, "")} />
              <DailyVaultStat statName={`Stakers`} statValue={getVaultStat(vault.numOfStakers, "")} />
              <DailyVaultStat
                statName={`Current Loans`}
                statValue={
                  vault.totalLoanedAmount
                    ? getVaultStat(bigNumToFixedStr(vault.totalLoanedAmount, 2, vault.decimals), vault.currency)
                    : "-"
                }
              />
              <DailyVaultStat
                statName={`Utilization`}
                statValue={vault.utilizationRate ? `${(vault.utilizationRate * 100).toFixed(2)}%` : "-"}
              />
              <DailyVaultStat statName={`Supply APY`} statValue={supplyApy} />
              <DailyVaultStat statName={`Borrow APY`} statValue={borrowApy} />
              <DailyVaultStat
                statName={`Default Rate`}
                statValue={`${parseFloat((vaultAdditionalMetrics.totalDefaultRate * 100).toFixed(2))}%`}
              />
            </VaultStatsGapWrapper>
            <VaultDetailActionsContainer>
              <CopyButton value={vault.contractTokenAddress}>
                <Text size="xxs" weight="500" color="secondary">
                  {shortenAddress(vault.contractTokenAddress)}
                </Text>
              </CopyButton>
              <IconButton variant="ghost" onClick={() => jumpToLink(CYAN_TWITTER_LINK)} style={{ marginLeft: "5px" }}>
                <Twitter width={16} height={16} color={theme.colors.secondary} />
              </IconButton>
              <IconButton variant="ghost" onClick={() => jumpToLink(CYAN_GITHUB_LINK)}>
                <Github width={16} height={16} color={theme.colors.secondary} />
              </IconButton>

              <IconButton variant="ghost" onClick={() => jumpToLink(CYAN_SITE)}>
                <Globe width={16} height={16} color={theme.colors.secondary} />
              </IconButton>
              <IconButton variant="ghost" onClick={() => jumpToLink(CYAN_MEDIUM_LINK)}>
                <Medium width={16} height={16} color={theme.colors.secondary} />
              </IconButton>
              <IconButton
                variant="ghost"
                onClick={() => jumpToLink(`${getChainExplorerURL(vault.chainId)}/token/${vault.contractTokenAddress}`)}
              >
                <img src={EtherscanGray} width={16} height={16} />
              </IconButton>
              <AddTokenToWalletButton
                tokenContractAddress={vault.contractTokenAddress}
                tokenType="ERC20"
                image={CyanC}
                decimals={vault.decimals}
                symbol={vault.symbol}
              >
                <Text size="xxs" weight="600" color="black" textWrap={false}>
                  + {`Import Token to Wallet`}
                </Text>
              </AddTokenToWalletButton>
            </VaultDetailActionsContainer>
          </Flex>
          <VaultDescriptionBox>
            <Text size="sm" color="secondary">
              {vault.description}
            </Text>
          </VaultDescriptionBox>
        </VaultInfoContainer>
        {isPrivate ? <VaultDetailsPrivate vault={vault} /> : <VaultPublicDetailsSection vault={vault} />}
      </Flex>
      <VaultStakeDesktop>
        <VaultStake vault={vault} />
      </VaultStakeDesktop>
    </Container>
  );
};

const DailyVaultStat = ({ statName, statValue }: { statName: string; statValue: string }) => {
  return (
    <Flex direction="column" gap="3px">
      <Text color="secondary" size="md" weight="700">
        {statValue}
      </Text>
      <Text size="xs" color="secondary" weight="400">
        {statName}
      </Text>
    </Flex>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 4rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.laptopL]: 2 }, { [breakpoints.tablet]: 0.5 }])}
`;

const VaultInfoContainer = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "2rem 5px 0rem 5px" }])}
`;

const VaultInfoTitleContainer = styled(Flex)`
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
  }
`;

const VaultDetailSubText = styled(Text)`
  font-size: 12px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 10 }])};
  font-weight: 400;
`;

const VaultDescriptionBox = styled.div`
  width: 850px;
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.laptop]: "500px" }, { [breakpoints.tablet]: "100%" }])};
`;

const VaultStatsGapWrapper = styled(Flex)`
  gap: 2.5rem;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    justify-content: space-between;
    gap: 1.4rem;
  }
`;

const VaultTopWrapper = styled(Flex)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem 0;
    padding: 0 5px;
  }
  padding: 0 18px;
`;
const VaultTopWrapperSub = styled(Flex)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 20px;
  }
`;
const VaultImageWrapper = styled.div`
  width: 75px;
  height: 75px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 45px;
    height: 45px;
  }
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VaultImage = styled.img`
  width: 45px;
  height: 45px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 25px;
    height: 25px;
  }
`;
const VaultTitlePrice = styled(Text)`
  font-size: 32px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptop]: 22 }])};
  font-weight: 400;
`;

const VaultTitleSymbol = styled(SubTitle)`
  color: ${({ theme }) => theme.colors.gray0};
`;

const VaultStakeMobile = styled.div`
  display: none;
  ${getStyleWithMediaQuery("display", "", [{ [breakpoints.tablet]: "block" }])};
`;

const VaultStakeDesktop = styled.div`
  position: sticky;
  top: 150px;
  height: fit-content;
  z-index: 70;
  display: block;
  overflow-x: hidden;
  ${getStyleWithMediaQuery("padding-top", "", [
    {
      [breakpoints.tablet]: "0px",
    },
  ])};
  ${getStyleWithMediaQuery("display", "", [{ [breakpoints.tablet]: "none" }])};
`;

const IconButton = styled(Button)`
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  height: unset;
  width: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  opacity: 0.7;
  :hover {
    opacity: 1;
  }
`;

const VaultDetailActionsContainer = styled(Flex)`
  row-gap: 16px;
  column-gap: 3px;
  flex-wrap: wrap;
  align-items: center;
  ${getStyleWithMediaQuery("justify-content", "", [{ [breakpoints.tablet]: "space-between" }])};
`;
