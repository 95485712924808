import { useEffect, useState } from "react";

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const formatTimeDifference = (then: number, now: number) => {
  if (then > now) return "";
  const seconds = Math.round(Math.abs(now - then) / 1000);
  const [value, unit] =
    seconds < MINUTE
      ? [Math.round(seconds), "s"]
      : seconds < HOUR
      ? [Math.round(seconds / MINUTE), "m"]
      : seconds < DAY
      ? [Math.round(seconds / HOUR), "h"]
      : [Math.round(seconds / DAY), "d"];
  return `${value}${unit} ago`;
};

export const TimeAgo = ({ then, live = false }: { then?: number | string | Date; live?: boolean }) => {
  const convertedThenTime = then && new Date(then).getTime();
  const [timeNow, setTimeNow] = useState(Date.now());

  useEffect(() => {
    if (!live || !then) return;
    const intervalId = setInterval(() => {
      setTimeNow(Date.now());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [live, then]);

  return <span>{convertedThenTime && formatTimeDifference(convertedThenTime, timeNow)}</span>;
};
