import dayjs from "dayjs";
import { useContext, useMemo } from "react";
import { Moon, Sun } from "react-feather";
import styled from "styled-components";

import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Flex, Text } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { QuantStamp } from "@cyanco/components/theme/v3/images";

import { displayInUSD, formatCompactNumber, numberWithCommas } from "@/utils";

import { useAppContext } from "../AppContextProvider";
import { GasTracker } from "../Header/GasTracker";
import { ThemeContext } from "../ThemeContextProvider";
import { useVaultStats } from "../Vault/VaultDataProvider";

const CYAN_START_DATE = "2022-05-01";

export const Stats = () => {
  const { isLightTheme, setIsLightTheme } = useContext(ThemeContext);
  const { usdPrice, cyanStats } = useAppContext();
  const { totalTvl } = useVaultStats();

  const formattedTvl = useMemo(() => {
    return formatCompactNumber((Math.round(totalTvl + cyanStats.totalStakedNftAmount) / 5) * 5);
  }, [totalTvl, cyanStats.totalStakedNftAmount]);
  const totalLoanedAmountFormatted = formatCompactNumber(
    cyanStats.loanedAmountBnplsUsd + cyanStats.loanedAmountPawnsUsd,
  );
  const runningDays = dayjs().diff(CYAN_START_DATE, "days");

  return (
    <Container alignItems="center">
      <QuantStampLink href="https://docs.usecyan.com/docs/security-audit" target="_blank">
        <Flex alignItems="center" gap="0.5rem">
          <Text color={isLightTheme ? "black" : "cyan"} size="xs" weight="700">
            Audited by
          </Text>
          <OuantStampImg src={QuantStamp}></OuantStampImg>
        </Flex>
      </QuantStampLink>
      <Flex gap="2rem" alignItems="center" p="0 1.2rem">
        <Text color="gray0" size="xs" weight="500">
          Days Running safe: {runningDays}
        </Text>
        <Text color="gray0" size="xs" weight="500">
          24h/Loans: {cyanStats.loanCount24h}
        </Text>
        <Text color="gray0" size="xs" weight="500">
          24h/Volume: $
          {cyanStats.loanAmount24h > 99999
            ? formatCompactNumber(cyanStats.loanAmount24h)
            : numberWithCommas(cyanStats.loanAmount24h, 2)}
        </Text>
        <Text color="gray0" size="xs" weight="500">
          Total Loans: ${totalLoanedAmountFormatted}
        </Text>
        <Text color="gray0" size="xs" weight="500">
          TVL: ${formattedTvl}
        </Text>
        <Text color="gray0" size="xs" weight="500">
          ETH/USD: {displayInUSD(usdPrice[SupportedCurrencies.ETH])}
        </Text>
      </Flex>
      <GasTrackerContainer alignItems="center" justifyContent="flex-end">
        {isLightTheme ? (
          <MoonIcon
            size={18}
            onClick={() => {
              setIsLightTheme(false);
            }}
          />
        ) : (
          <SunIcon
            size={18}
            onClick={() => {
              setIsLightTheme(true);
            }}
          />
        )}
        <GasTracker />
      </GasTrackerContainer>
    </Container>
  );
};

const Container = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
  background: ${({ theme }) => theme.backgroundColor};
  height: 50px;
  width: 100%;
  z-index: 71;
  justify-content: center;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
  @media only screen and (max-width: ${breakpoints.laptopS}px) {
    justify-content: start;
  }
`;
const OuantStampImg = styled.img`
  filter: ${({ theme }) => (theme.theme === "dark" ? "none" : "brightness(0%)")};
`;
const QuantStampLink = styled.a`
  position: absolute;
  margin-left: 1.2rem;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  font-size: 12px;
  border: 1px solid ${({ theme }) => (theme.theme === "dark" ? theme.colors.cyan : theme.colors.black)};
  background: ${({ theme }) => theme.backgroundColor};
  font-family: Inter;
  font-weight: 700;
  padding: 8px 15px;
`;
const GasTrackerContainer = styled(Flex)`
  margin-right: 1.2rem;
  gap: 1.5rem;
  position: absolute;
  right: 0;
  background: ${({ theme }) => theme.backgroundColor};
`;
const SunIcon = styled(Sun)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray0};
  transition: 0.2s ease-in-out;
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
const MoonIcon = styled(Moon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray0};
  transition: 0.2s ease-in-out;
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
