import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";

import { axios } from "../axios";
import { IPricerMethod } from "./types";

export const priceCollectionBasedOnFloorV2 = async ({
  chainId,
  collectionAddress,
  abortSignal,
  wallet,
  vaultId,
}: {
  chainId: SupportedChainId;
  collectionAddress: string;
  wallet?: string;
  vaultId?: number;
  abortSignal?: AbortSignal;
}): Promise<ICollectionPricer> => {
  const chainSlug = CHAIN_IDS_TO_NAMES[chainId];
  const response = (
    await axios.get<ICollectionPricer>(`/v2/pricer/price-collections`, {
      params: { chain: chainSlug, collectionAddress, wallet, vaultId },
      signal: abortSignal,
    })
  ).data;
  return response;
};

export type ICollectionPricer = {
  collectionAddress: string;
  baseInterestRate: number;
  appraisalPrice: string;
  pawnConfig: IPricerMethod[];
  bnplConfig: IPricerMethod[];
};
