import { BigNumber } from "ethers";

import { SupportedChainId } from "@/constants/chains";

import { axios } from "./axios";

export const getPlanRevivalSignatureBulk = async (
  plans: { planId: number }[],
  chainId: SupportedChainId,
): Promise<IRevivalSignatureBulk[]> => {
  const { data } = await axios.post<IRevivalSignatureBulk[]>(`/v2/plans/revive`, {
    plans,
    chainId,
  });
  return data;
};
type IRevivalSignatureBulk = {
  expiryDateInSeconds: number;
  signature: string;
  penaltyAmount: BigNumber;
  planId: number;
};
