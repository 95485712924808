import { BigNumber } from "ethers";

import { CHAIN_IDS_TO_NAMES } from "@/constants/chains";

import { axios } from "../axios";
import { FnFetchTokensByUser, IUserToken } from "./types";

export const fetchUserTokens: FnFetchTokensByUser = async ({ chainId, wallet }) => {
  const chainSlug = CHAIN_IDS_TO_NAMES[chainId];
  const { data } = await axios.get(`/users/${chainSlug}/${wallet}/tokens`);
  return data.map((token: IUserToken) => ({
    ...token,
    tokenBalance: BigNumber.from(token.tokenBalance),
  }));
};
