import { throttle } from "lodash";
import React, { FC, useCallback } from "react";
import { Area, AreaChart, AreaProps, Label, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { useTheme } from "styled-components";

import { Flex } from "../../Flex";
import { Text } from "./Text";

const CustomTooltip: FC<TooltipProps<number, string>> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Flex direction="column" alignItems="center" ml="-100%">
        <Text color="gray0" size="xs">
          {payload[0].payload.label.date}
        </Text>
        <Text color="gray0" size="xs">
          {payload[0].payload.label.pricePlaceholder}
        </Text>
      </Flex>
    );
  }

  return null;
};

export const PriceChart = ({
  dataKey,
  labelKey,
  data,
  showTooltip = true,
  strokeColor,
  chartColor,
  yAxisX = -20,
  showYAxis,
  yAxisLabel,
  yAxisFontSize = "100%",
  yAxisTickFormatter = value => value.toString(),
}: {
  dataKey?: string;
  labelKey?: string;
  data?: any[];
  showTooltip?: boolean;
  strokeColor?: string;
  chartColor?: string;
  showYAxis?: boolean;
  yAxisX?: number;
  yAxisLabel?: string;
  yAxisFontSize?: string;
  yAxisTickFormatter?: (value: number) => string;
}) => {
  const theme = useTheme();
  const [x, setX] = React.useState<number>();
  const handleMouseMove = useCallback(
    throttle(_x => {
      setX(_x);
    }, 30),
    [],
  );
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        margin={{ top: 0, left: showYAxis ? 24 : 0, right: 0, bottom: 0 }}
        data={data}
        onMouseMove={data => {
          showTooltip && handleMouseMove(data.activeCoordinate?.x);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <XAxis
          dataKey={labelKey}
          style={{
            visibility: "hidden",
          }}
        />
        {showYAxis ? (
          <YAxis
            tickLine={false}
            strokeWidth={0}
            dx={yAxisX + (yAxisLabel ? 4 : 0)}
            dy={0}
            domain={["dataMin", "dataMax"]}
            tickFormatter={yAxisTickFormatter}
            style={{
              fontSize: "14px",
              fontFamily: "Inter",
              fontWeight: 600,
            }}
          >
            {yAxisLabel && (
              <Label
                dx={-48}
                style={{
                  textAnchor: "middle",
                  fontSize: yAxisFontSize,
                  fill: theme.colors.secondary,
                  fontFamily: "Inter",
                }}
                angle={270}
                value={yAxisLabel}
              />
            )}
          </YAxis>
        ) : (
          <YAxis hide domain={["dataMin", "dataMax"]} />
        )}

        {showTooltip && (
          <Tooltip
            contentStyle={{
              background: theme.colors.transparent,
              border: "none",
              borderRadius: "8px",
              padding: "0.5rem",
            }}
            wrapperStyle={{
              border: "none",
              outline: "none",
              userSelect: "none",
            }}
            itemStyle={{
              color: theme.colors.gray0,
              fontFamily: "Inter",
              fontSize: "12px",
            }}
            active={true}
            position={{ y: -30, x: x ? x : undefined }}
            isAnimationActive={false}
            content={CustomTooltip}
          />
        )}
        <defs>
          <linearGradient id="MyGradient" x1="0%" x2="0%" y1="0%" y2="100%">
            <stop
              offset="0%"
              stopColor={chartColor ?? theme.colors.cyan}
              stopOpacity={theme.theme === "dark" ? 0.2 : 0.6}
            />
            <stop offset="100%" stopColor={chartColor ?? theme.colors.cyan} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dot={false}
          dataKey={dataKey as AreaProps["dataKey"]}
          stroke={strokeColor ?? "#00E7E7"}
          strokeWidth={2}
          fill="url(#MyGradient)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
