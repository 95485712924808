import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { save } from "redux-localstorage-simple";

import application from "./application/reducer";

const store = configureStore({
  reducer: {
    application,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ thunk: true }).concat(save({ debounce: 1000 })),
});

setupListeners(store.dispatch);

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
