import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Text, useModal } from "@cyanco/components/theme/v3";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { useVaults } from "@/components/Vault/VaultDataProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apeVaultContract, isProd } from "@/config";
import { formatCompactNumber, isApeCoinStakingPossible } from "@/utils";

import { useApeCoinStatsContext } from "../../ApeCoinStatsContext";
import { IActionType } from "../../types";
import { ApeCoinOnlyStakingModal } from "../PlanModal/ApeCoinStakingModal";
import { ApeCoinStakingSelector } from "../PlanModal/staking/ApeCoinStakinSelector";
import { ApyBoxGreen, PoolsApy } from "./PoolsApy";

export const GridView = () => {
  return (
    <Container>
      <VaultCard />
      <PoolsApy />
    </Container>
  );
};

const VaultCard = () => {
  const { chainId } = useWeb3React();
  const { setModalContent } = useModal();
  const navigate = useNavigate();
  const { vaults } = useVaults();
  const { apeVault } = useApeCoinStatsContext();
  const apy = useMemo(() => {
    const apeVault = vaults.find(
      ({ contractAddress }) => apeVaultContract.toLowerCase() === contractAddress.toLowerCase(),
    );
    if (!apeVault || !apeVault.estimatedYield) return null;
    return apeVault.estimatedYield / 100;
  }, [vaults]);
  const autoCompoundApy = useMemo(() => {
    return (Math.pow(1 + apeVault.apr.COIN / 100 / 52, 52 - 1) - 1) * 100;
  }, [apeVault.apr.COIN]);
  const differencePercent = useMemo(() => {
    if (!apy) return 0;
    const diff = apy - apeVault.apr.COIN;
    if (diff < 0) return 0;
    return diff.toFixed(0);
  }, [apeVault.apr.COIN, apy]);

  return (
    <Card>
      <Flex direction="column" alignItems="center" gap="16px">
        <StyledImg
          src={ApeCoin}
          alt="ApeCoin"
          style={{
            border: "2px solid #0FF",
            borderRadius: "50%",
          }}
        />
        <Flex direction="column" gap="5px" alignItems="center">
          <Text color="secondary" size="xl" weight="600">
            {`ApeCoin Staking`}
          </Text>
          <Text color="secondary" size="xs" weight="400">
            {`Auto-Compound APY`}
          </Text>
        </Flex>
        <Flex direction="column" gap="4rem" alignItems="center" w="100%">
          <Flex direction="column" w="100%">
            <ApyBox direction="column" alignItems="center" gap="2px" p="14px 0 14px 0" justifyContent="center">
              <Text color="secondary" size="xxl" weight="600" textAlign="center">
                🔥 {apy ? (apy > 100 ? formatCompactNumber(apy) : apy.toFixed(2)) : "- "}%
              </Text>
              <Text weight="500" color="secondary" size="xs">
                <Text weight="700" color="green" size="xs">
                  {" "}
                  +{differencePercent}%{" "}
                </Text>{" "}
                over native APY
              </Text>
            </ApyBox>
            <StakingButton onClick={() => navigate(`/vault/${isProd ? "mainnet" : "sepolia"}/${apeVaultContract}`)}>
              <StakingText size="sm" weight={"700"} color={"cyan"}>
                {`Start Earning`}
              </StakingText>
            </StakingButton>
          </Flex>
          <Flex direction="column" w="100%">
            <ApyBoxGreen
              onClick={() => {
                setModalContent({
                  title: `ApeCoin Auto-Compounding`,
                  content: <ApeCoinOnlyStakingModal action={IActionType.stake} />,
                });
              }}
              direction="column"
              alignItems="center"
              gap="2px"
              p="14px 0 14px 0"
              justifyContent="center"
            >
              <Text color="secondary" size="xxl" weight="600" textAlign="center">
                {autoCompoundApy
                  ? autoCompoundApy > 100
                    ? formatCompactNumber(autoCompoundApy)
                    : autoCompoundApy.toFixed(2)
                  : "- "}
                %
              </Text>
              <Text weight="500" color="secondary" size="xs">
                <Text weight="700" color="secondary" size="xs">
                  {" "}
                  KEEP{" "}
                </Text>{" "}
                voting and auto-compound{" "}
              </Text>
            </ApyBoxGreen>

            <StakingButton
              onClick={() => {
                setModalContent({
                  title: `Select ApeCoin Staking`,
                  content: <ApeCoinStakingSelector />,
                });
              }}
              disabled={!isApeCoinStakingPossible(chainId)}
            >
              <StakingText size="sm" weight={"700"} color={"cyan"}>
                {`Start Earning`}
              </StakingText>
            </StakingButton>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 3rem;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0;
  }
`;

export const ApyBox = styled(Flex)`
  border-radius: 14px;
  align-items: center;
  width: 100%;
  transition: opacity 0.2s ease;
`;

const StyledImg = styled.img`
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  max-height: 36px;
  max-width: 36px;
  border-radius: 50%;
`;

export const StakingText = styled(Text)`
  color: ${({ theme }) => (theme.theme === "dark" ? theme.colors.black : theme.colors.white)};
  transition: 0.2s ease-in-out;
`;

export const StakingButton = styled(Button)`
  padding: 12px;
  border-color: ${({ theme }) => (theme.theme === "dark" ? theme.colors.white : theme.colors.black)};
  background: ${({ theme }) => (theme.theme === "dark" ? theme.colors.white : theme.colors.black)};
  :hover {
    background-color: #79FFF;
    border-color: #79FFF;
    ${StakingText} {
      color: black;
    }
  }
`;
const Card = styled.div`
  padding: 1.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  transition: 0.2s;
  border-radius: 14px;
  justify-content: space-between;
  align-item: center;
  border: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
`;
