import { useEffect, useState } from "react";
import { Check, HelpCircle } from "react-feather";
import { NumericFormat } from "react-number-format";
import styled, { useTheme } from "styled-components";

import { Flex, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Input, SkeletonLine, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { CollectioNoImage } from "@cyanco/components/theme/v3/images";

import { shortenName } from "@/utils";
import { isMobile } from "@/utils/userAgent";

export const CollectionsAppraisalsHeader = () => {
  const theme = useTheme();
  return (
    <HeaderContainer>
      <Flex gap="5px" alignItems="center">
        <Text weight="600" size="lg" color="secondary">
          Collections
        </Text>
      </Flex>
      <Flex gap="5px" alignItems="center">
        <Text weight="600" size="lg" color="secondary">
          Appraisal
        </Text>
        <Tooltip>
          <HelpCircle height={16} width={16} color={theme.colors.gray0} />
          <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
              <div>
                Cyan automatically appraises each collection by observing the floor price and bid depth across
                marketplaces.
              </div>
            </Text>
          </TooltipText>
        </Tooltip>
      </Flex>
      <Flex gap="5px" alignItems="center">
        <Text weight="600" size="lg" color="secondary">
          Override
        </Text>
        <Tooltip>
          <HelpCircle height={16} width={16} color={theme.colors.gray0} />
          <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
              <div>
                The price you input here will override the appraisal value. Delete this value if you would like to use
                Cyan’s appraisal value.
              </div>
            </Text>
          </TooltipText>
        </Tooltip>
      </Flex>
    </HeaderContainer>
  );
};

export const CollectionAppraisalsRow = ({
  collection,
  vaultCurrencySymbol,
  onChangeCustomAppraisalValue,
}: {
  collection: {
    address: string;
    name: string;
    imageUrl: string;
    appraisalValue: number;
    customAppraisalValue: number;
  };
  vaultCurrencySymbol: string;
  onChangeCustomAppraisalValue: (collectionAddress: string, value: number) => void;
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [customAppraisalValue, setCustomAppraisalValue] = useState<string>(collection.customAppraisalValue.toString());
  useEffect(() => {
    const parsed =
      customAppraisalValue === "" || isNaN(parseFloat(customAppraisalValue)) ? 0 : parseFloat(customAppraisalValue);
    onChangeCustomAppraisalValue(collection.address, parsed);
  }, [customAppraisalValue]);
  const isCyanAppraisalEnabled =
    customAppraisalValue === "" || isNaN(parseFloat(customAppraisalValue)) || parseFloat(customAppraisalValue) === 0;
  return (
    <Row>
      <Flex gap="8px">
        {imageLoading && <SkeletonLine borderRadius="50%" w="24px" h="24px" />}
        <StyledImg
          src={collection.imageUrl ?? CollectioNoImage}
          alt={collection.name}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
          }}
        />
        <Text size="lg" color="secondary" weight="600">
          {isMobile ? shortenName(collection.name) : shortenName(collection.name, 30, 12, 10)}
        </Text>
      </Flex>

      <NumericFormat
        value={collection.appraisalValue}
        fontSize="lg"
        decimalScale={2}
        customInput={Input}
        valueIsNumericString
        placeholder={`0 ${vaultCurrencySymbol}`}
        disabled={true}
        suffix={` ${vaultCurrencySymbol}`}
      >
        <CheckCircleWrapper isChecked={isCyanAppraisalEnabled}>
          <Check color="black" size={12} strokeWidth={3} />
        </CheckCircleWrapper>
      </NumericFormat>

      <NumericFormat
        value={customAppraisalValue}
        onValueChange={values => setCustomAppraisalValue(values.value)}
        fontSize="lg"
        decimalScale={2}
        placeholder={`0 ${vaultCurrencySymbol}`}
        customInput={Input}
        valueIsNumericString
        suffix={` ${vaultCurrencySymbol}`}
      >
        <CheckCircleWrapper isChecked={!isCyanAppraisalEnabled}>
          <Check color="black" size={12} strokeWidth={3} />
        </CheckCircleWrapper>
      </NumericFormat>
    </Row>
  );
};

export const CollectionAppraisalsRowLoader = ({ vaultCurrencySymbol }: { vaultCurrencySymbol: string }) => {
  return (
    <Row>
      <Flex gap="8px">
        <SkeletonLine borderRadius="50%" w="24px" h="24px" />
        <SkeletonLine w="100px" h="24px" />
      </Flex>
      <NumericFormat
        fontSize="lg"
        decimalScale={2}
        customInput={Input}
        valueIsNumericString
        placeholder={`0 ${vaultCurrencySymbol}`}
        disabled={true}
        suffix={` ${vaultCurrencySymbol}`}
      >
        <CheckCircleWrapper>
          <Check color="black" size={12} strokeWidth={3} />
        </CheckCircleWrapper>
      </NumericFormat>

      <NumericFormat
        fontSize="lg"
        decimalScale={2}
        customInput={Input}
        valueIsNumericString
        placeholder={`0 ${vaultCurrencySymbol}`}
        disabled={true}
        suffix={` ${vaultCurrencySymbol}`}
      >
        <CheckCircleWrapper>
          <Check color="black" size={12} strokeWidth={3} />
        </CheckCircleWrapper>
      </NumericFormat>
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 2rem;
  align-items: center;
  grid-template-columns: 1fr 0.4fr 0.4fr;
  padding: 0.8rem 1.2rem;
  background-color: transparent;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "1fr 0.5fr 0.5fr" }])};

  border: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
  border-radius: 20px;
`;

const HeaderContainer = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 2rem;
  align-items: center;
  grid-template-columns: 1fr 0.4fr 0.4fr;
  padding: 0.8rem 1.2rem;
  background-color: transparent;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "1fr 0.5fr 0.5fr" }])};
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  border-radius: 50%;
`;

const CheckCircleWrapper = styled.div<{ isChecked?: boolean }>`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${({ theme, isChecked }) => (!isChecked ? theme.colors.gray20 : theme.colors.cyan)};
`;
