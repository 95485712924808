import React from "react";
import styled from "styled-components";

import { breakpoints } from "./config";

const CheckboxContainer = styled.div`
  display: inline-block;
  height: 18px;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: #000000;
  stroke-width: 3px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const VisibleCheckbox = styled.div<{
  checked: boolean;
  borderColor?: string;
  disabled?: boolean;
}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({ checked, theme }) => (checked ? "cyan" : theme.colors.primary)};
  border: 1px solid;
  border-color: ${({ checked, borderColor, disabled, theme }) =>
    disabled ? theme.colors.gray0 : checked ? "#006060" : borderColor};
  border-radius: 5px;
  transition: all 150ms;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")};
  }
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    width: 14px;
    height: 14px;
  }
`;

const StyledCheckbox = ({
  checked,
  onChange,
  id,
  style,
  borderColor = "#6c6c6c",
  disabled = false,
  showMinus,
  ...props
}: {
  id: string;
  checked: boolean;
  borderColor?: string;
  style?: React.CSSProperties;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  showMinus?: boolean;
}) => (
  <label>
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} onChange={onChange} {...props} id={id} disabled={disabled} />
      <VisibleCheckbox checked={checked} borderColor={borderColor} style={style} disabled={disabled}>
        {showMinus ? (
          <Icon viewBox="0 0 24 24">
            <polyline points="7,13 17,13" />
          </Icon>
        ) : (
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        )}
      </VisibleCheckbox>
    </CheckboxContainer>
  </label>
);

export default StyledCheckbox;
