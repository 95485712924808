import { useEffect, useState } from "react";
import { useAsyncCallback } from "react-async-hook";

import { Flex, NotFound } from "@cyanco/components/theme";

import { IVault, IVaultLatestLoan } from "@/apis/vault/types";
import Pagination from "@/components/Pagination";

import { PAGINATION_SIZE } from ".";
import { LatestLoanBodyRow, LatestLoanLoadingRow } from "../../LatestLoans/LatestLoanBodyRow";
import { ContainerBox, Header, ListViewBox } from "../../LatestLoans/LatestLoans";
import { LatestLoansHeader } from "../../LatestLoans/LatestLoansHeader";

export const LoansPaginatedTable = ({
  vault,
  loading,
  fetchLoans,
}: {
  vault: IVault;
  loading: boolean;
  fetchLoans: (address: string, pageKey: number) => Promise<{ latestLoans: IVaultLatestLoan[]; totalCount: number }>;
}) => {
  const [totalLoans, setTotalLoans] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedDatas, setPaginatedDatas] = useState<IVaultLatestLoan[][]>([]);
  const { loading: dataLoading, execute } = useAsyncCallback(
    async (pageNumber: number, currentTotal: number, existingDatas: IVaultLatestLoan[][]) => {
      if (!existingDatas[pageNumber - 1]?.length) {
        const data = await fetchLoans(vault.contractAddress, (pageNumber - 1) * PAGINATION_SIZE);
        if (data.totalCount !== currentTotal) {
          setTotalLoans(data.totalCount);
        }
        const newPaginatedDatas = [...existingDatas];
        newPaginatedDatas[pageNumber - 1] = data.latestLoans;
        setPaginatedDatas(newPaginatedDatas);
      }
    },
  );

  useEffect(() => {
    setTotalLoans(0);
    setPaginatedDatas([]);
    setCurrentPage(1);
    execute(1, 0, []);
  }, [vault.contractAddress]);

  return (
    <Flex direction="column" w="100%">
      <ListViewBox>
        <Header>
          <LatestLoansHeader />
        </Header>
        <ContainerBox>
          {dataLoading || loading ? (
            Array.from(Array(10).keys()).map(loader => <LatestLoanLoadingRow key={loader} />)
          ) : !paginatedDatas[currentPage - 1]?.length ? (
            <NotFound msg="No loans found" />
          ) : (
            (paginatedDatas[currentPage - 1] ?? []).map((loan, index) => {
              return (
                <LatestLoanBodyRow
                  vault={vault}
                  loan={loan}
                  index={(currentPage - 1) * PAGINATION_SIZE + index}
                  key={loan.planId}
                />
              );
            })
          )}
          <Pagination
            total={totalLoans}
            pageSize={PAGINATION_SIZE}
            current={currentPage}
            onPageChange={n => {
              setCurrentPage(n);
              execute(n, totalLoans, paginatedDatas);
            }}
          />
        </ContainerBox>
      </ListViewBox>
    </Flex>
  );
};
