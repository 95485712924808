import { BigNumber, constants } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { isProd } from "@/config";
import { SupportedChainId } from "@/constants/chains";
import usePrevious from "@/hooks/usePrevious";
import { ICurrency, IPlanCreatableNft } from "@/types";
import { convertPriceToGivenCurrency } from "@/utils";

import { AppContext, useSupportedCollections } from "../AppContextProvider";

export enum SweepStatus {
  Disabled,
  EnabledAutomatically,
  EnabledManually,
  DisabledManually,
}

export const FLOOR_PRICE_THRESHOLD = isProd ? parseEther("2") : parseEther("0.5");
export const FLOOR_PRICE_THRESHOLD_MAX_COUNT = 5;

type IBnplCartContext = {
  currency: string | null;
  setCurrency: React.Dispatch<React.SetStateAction<string | null>>;

  items: IPlanCreatableNft[];
  setItems: React.Dispatch<React.SetStateAction<IPlanCreatableNft[]>>;

  sweep: SweepStatus;
  setSweep: React.Dispatch<React.SetStateAction<SweepStatus>>;
};

const BnplCartContext = createContext<IBnplCartContext | null>(null);

export const BnplCartContextProvider: React.FC = ({ children }) => {
  // TODO this must be changed to currency id!
  const [currency, setCurrency] = useState<string | null>(null);
  const [items, setItems] = useState<IPlanCreatableNft[]>([]);
  const { account } = useWeb3React();
  const previousAccount = usePrevious(account);
  const [sweep, setSweep] = useState(SweepStatus.Disabled);

  useEffect(() => {
    if (account === previousAccount) return;
    setCurrency(null);
    setItems([]);
  }, [account]);

  return (
    <BnplCartContext.Provider value={{ items, setItems, currency, setCurrency, sweep, setSweep }}>
      {children}
    </BnplCartContext.Provider>
  );
};

const useBnplCartContext = () => {
  const context = useContext(BnplCartContext);
  if (!context) {
    throw new Error("useCartContext must be within CartContextProvider");
  }

  return context;
};

export const useBnplCart = () => {
  // TODO all currency logic must be changed to currencyId!
  const { chainId } = useWeb3React();
  const { items, setItems, currency, setCurrency, sweep, setSweep } = useBnplCartContext();
  const { usdPrice } = useContext(AppContext);
  const { collections } = useSupportedCollections();

  const convertPriceToEthForCheck = (price: BigNumber, _currency: ICurrency) => {
    if (
      [
        SupportedChainId.MAINNET,
        SupportedChainId.SEPOLIA,
        SupportedChainId.BLAST,
        SupportedChainId.BLAST_SEPOLIA,
      ].includes(chainId)
    ) {
      if (_currency.address.toLowerCase() === constants.AddressZero.toLowerCase()) {
        return price;
      }
    }
    return convertPriceToGivenCurrency({
      source: { decimal: 18, usdPrice: usdPrice[SupportedCurrencies.ETH] },
      target: {
        decimal: _currency.decimal,
        usdPrice: usdPrice[_currency.symbol],
      },
      convertAmount: price,
    });
  };

  const getCollectionFloorPrice = (collectionAddress: string) => {
    const collection = collections.find(
      collection =>
        collection.address.toLowerCase() === collectionAddress.toLowerCase() && chainId === collection.chainId,
    );
    if (!collection || !collection.floorAsk.price) return BigNumber.from(0);
    const { price: floorPrice } = collection.floorAsk;

    return convertPriceToEthForCheck(BigNumber.from(floorPrice.amount.raw), {
      decimal: floorPrice.currency.decimals,
      address: floorPrice.currency.contract,
      symbol: floorPrice.currency.symbol.toUpperCase(),
    });
  };

  const addItem = (item: IPlanCreatableNft) => {
    if (!items.length) {
      setCurrency(item.currency.symbol);
      setItems([item]);
      return;
    }

    if (item.currency.symbol !== currency) {
      throw new Error("The cart can only contain items with the same currency.");
    }
    setItems([...items, item]);
  };

  const addItems = (_items: IPlanCreatableNft[]) => {
    if (!_items.length) return;

    if (!items.length) {
      setCurrency(_items[0].currency.symbol);
    } else {
      if (_items.some(item => item.currency.symbol !== currency)) {
        throw new Error("The cart can only contain items with the same currency.");
      }
    }
    setItems(_items);
  };

  const removeItem = (_address: string, _tokenId: string, _privateSaleId?: number) => {
    const remainingItems = items.filter(
      ({ address, tokenId, privateSaleId }) =>
        !(address === _address && tokenId == _tokenId && privateSaleId == _privateSaleId),
    );
    setItems(remainingItems);

    if (!remainingItems.length) {
      setCurrency(null);
    }
  };

  const toggleItem = (item: IPlanCreatableNft) => {
    const _item = items.find(
      ({ address, tokenId, privateSaleId }) =>
        address === item.address && tokenId == item.tokenId && privateSaleId == item.privateSaleId,
    );

    if (!_item) {
      return addItem(item);
    }

    return removeItem(item.address, item.tokenId, item.privateSaleId);
  };

  const removeAll = () => {
    setCurrency(null);
    setItems([]);
  };

  const itemsCountGtThreshold = useMemo(() => {
    return items.reduce((acc, cur) => {
      const collectionFloorPrice = getCollectionFloorPrice(cur.address);
      if (collectionFloorPrice.gt(FLOOR_PRICE_THRESHOLD)) {
        acc++;
        return acc;
      }
      return acc;
    }, 0);
  }, [items]);

  return {
    currency,
    sweep,
    setSweep,

    itemsCountGtThreshold,
    getCollectionFloorPrice,

    items,
    addItem,
    addItems,
    removeItem,
    removeAll,
    toggleItem,
    setItems,
  };
};
