import { createContext, useContext, useEffect, useState } from "react";

import { ICollectionBe } from "@/apis/collection/types";
import { IP2PListedNft, IP2PLoanOffer, IPeerPlanAmountsByWallet } from "@/apis/p2p/types";

import { useAppContext } from "../AppContextProvider";
import { useLendSelectors } from "./LendPageContext";

interface ILendDataContext {
  listedNfts: IP2PListedNft[];
  listedNftsLoading: boolean;
  fetchListedNfts: () => Promise<IP2PListedNft[]>;
  loanOffers: IP2PLoanOffer[];
  loanOffersLoading: boolean;
  fetchLoanOffers: () => Promise<IP2PLoanOffer[]>;
  leaderBoardData: IPeerPlanAmountsByWallet;
  leaderBoardDataLoading: boolean;
}

const LendDataContext = createContext<ILendDataContext>({
  listedNfts: [],
  listedNftsLoading: false,
  fetchListedNfts: async () => [],
  loanOffers: [],
  loanOffersLoading: false,
  fetchLoanOffers: async () => [],
  leaderBoardData: [],
  leaderBoardDataLoading: false,
});

export const useLendDataContext = () => {
  return useContext(LendDataContext);
};

export const useP2PListedNfts = () => {
  const { listedNfts, listedNftsLoading, fetchListedNfts } = useLendDataContext();
  return {
    listedNfts,
    listedNftsLoading,
    fetchListedNfts,
  };
};

export const useP2PLoanOffers = () => {
  const { loanOffers, loanOffersLoading, fetchLoanOffers } = useLendDataContext();
  return {
    loanOffers,
    loanOffersLoading,
    fetchLoanOffers,
  };
};

export const useLendSupportedCollectionsFiltered = () => {
  const { collections, collectionsLoading, updateCollections } = useAppContext();
  const [filteredCollections, setFilteredCollections] = useState<ICollectionBe[]>(collections);
  const { selectedVault, selectedCollectionSearch, selectedChain } = useLendSelectors();
  useEffect(() => {
    if (selectedCollectionSearch !== "") {
      setFilteredCollections(
        collections.filter(
          collection =>
            (collection.name.toLowerCase().includes(selectedCollectionSearch.toLowerCase()) ||
              collection.address.toLowerCase().startsWith(selectedCollectionSearch.toLowerCase())) &&
            (collection.vaults.some(v => v.id === selectedVault) || selectedVault === 0) &&
            selectedChain === collection.chainId,
        ),
      );
    } else {
      setFilteredCollections(
        collections.filter(
          collection =>
            collection.vaults.some(v => v.id === selectedVault || selectedVault === 0) &&
            selectedChain === collection.chainId,
        ),
      );
    }
  }, [collections, selectedVault, selectedCollectionSearch, selectedChain]);
  return {
    collections: filteredCollections,
    collectionsLoading,
    updateCollections,
  };
};
