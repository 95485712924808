import { CHAIN_IDS_TO_NAMES } from "@/constants/chains";

import { axios } from "../axios";
import { FnFetchUserNFTs, IUserNFTsResult, IUserNft } from "./types";

export const fetchUserNFTs: FnFetchUserNFTs = async (wallet, query) => {
  const { chainId, onlySupported, collectionAddress, continuation, abortSignal } = query;
  const chain = CHAIN_IDS_TO_NAMES[chainId];

  const { data } = await axios.get<IUserNFTsResult>(`/v2/users/${wallet}/nfts`, {
    params: { collectionAddress, continuation, onlySupported, chain },
    signal: abortSignal,
  });

  return {
    continuation: data.continuation,
    assets: data.assets.map(asset => ({
      ...asset,
      ownership: {
        tokenCount: Number(asset.ownership.tokenCount),
      },
    })),
  };
};

export const isUserNft = (item: any): item is IUserNft => "ownership" in item;
