import { useTheme } from "styled-components";

import { Flex } from "../../Flex";
import { ChevronDown, ChevronUp } from "../icons";

export const SortingArrows = ({
  sortName,
  currentSortName,
  sortDirectionDesc,
}: {
  sortName: string;
  currentSortName: string;
  sortDirectionDesc: boolean;
}) => {
  const theme = useTheme();
  return (
    <Flex direction="column" style={{ cursor: "pointer" }}>
      <ChevronUp
        width={10}
        height={8}
        color={sortName === currentSortName && sortDirectionDesc ? theme.colors.secondary : theme.colors.gray10}
      />
      <ChevronDown
        width={10}
        height={8}
        color={sortName === currentSortName && !sortDirectionDesc ? theme.colors.secondary : theme.colors.gray10}
      />
    </Flex>
  );
};
