import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import { Box, Flex } from "@cyanco/components/theme/components";
import { Button, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { Send, Twitter } from "@cyanco/components/theme/v3/icons";
import { Etherscan, Looksrare, NoImage, Opensea, X2Y2 } from "@cyanco/components/theme/v3/images";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { ICurrency, INftType } from "@/types";

import { isProd } from "../../../../../config";
import {
  TOKEN_TYPE_MAPPED,
  bigNumToFixedStr,
  getChainExplorerURL,
  getMarketItemUrl,
  jumpToLink,
  shortenAddress,
  shortenName,
} from "../../../../../utils";
import { useAppContext } from "../../../../AppContextProvider";

export const UserNftMetadata = ({
  nft,
  onNftTransfer,
}: {
  nft: {
    address: string;
    tokenId: string;
    imageUrl?: string;
    collectionName: string;
    tokenType?: number;
    appraisalValue?: string;
    currency: ICurrency;
    isCyanWalletAsset: boolean;
    ownership: {
      tokenCount: number;
    };
    hasPrivateSale?: boolean;
  };
  onNftTransfer?: () => void;
}) => {
  const { chainId, account } = useWeb3React();
  const { collections } = useAppContext();
  const collection = collections.find(({ address }) => address === nft.address);
  const twitterUrl =
    collection?.twitterAccount && collection.twitterAccount !== ""
      ? `https://twitter.com/${collection?.twitterAccount}`
      : null;
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <Flex direction="column" gap="0.5rem">
      <Box>
        <NftImageWrapper>
          {nft.imageUrl ? (
            <>
              {imageLoading && <ImageLoader />}
              <NftImage src={nft.imageUrl} alt={nft.tokenId} onLoad={() => setImageLoading(false)} hasImage />
            </>
          ) : (
            <NftImage src={NoImage} alt={"no-image"} style={{ objectFit: "contain" }} />
          )}
        </NftImageWrapper>
      </Box>
      <Flex direction="column" p="1rem 0rem" gap="0.5rem">
        <Flex justifyContent="space-between">
          <Text size="sm" color="secondary">{`Collection`}</Text>
          <Text size="sm" color="gray0">
            {shortenName(nft.collectionName || "", 30)}
          </Text>
        </Flex>
        {nft.tokenType && nft.tokenType === INftType.ERC1155 && (
          <Flex justifyContent="space-between">
            <Text size="sm" color="secondary">{`Token Amount`}</Text>
            <Text size="sm" color="gray0">
              {`${nft.ownership.tokenCount}`}
            </Text>
          </Flex>
        )}
        <Flex justifyContent="space-between">
          <Text size="sm" color="secondary">{`Token ID`}</Text>
          <Text size="sm" color="gray0">
            #{shortenName(nft.tokenId, 10, 6)}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text size="sm" color="secondary">{`Token Type`}</Text>
          <Text size="sm" color="gray0">
            {TOKEN_TYPE_MAPPED[nft.tokenType ?? INftType.ERC721].toUpperCase()}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text size="sm" color="secondary">{`Appraisal Value`}</Text>
          <Text size="sm" color="gray0">
            {nft.appraisalValue
              ? nft.appraisalValue && bigNumToFixedStr(nft.appraisalValue, 2, nft.currency.decimal)
              : 0.0}{" "}
            {nft.currency.symbol}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text size="sm" color="secondary">{`Wallet`}</Text>
          {nft.isCyanWalletAsset ? (
            <Text size="sm" color="cyan">
              {`Cyan Wallet`}
            </Text>
          ) : (
            <Text size="sm" color="gray0">
              {shortenAddress(account || "")}
            </Text>
          )}
        </Flex>
        {/* HIDING TEMPORARY
        {collection?.slug && nft.hasPrivateSale && (
          <Flex justifyContent="space-between">
            <Text size="sm" color="secondary">{`Private Sale`}</Text>
            <CopyText
              value={`${DAPP_URL}/bnpl/${CHAIN_IDS_TO_NAMES[collection.chainId as SupportedChainId] ?? "mainnet"}/${
                collection.slug
              }?tokenId=${nft.tokenId}`}
              color="gray0"
            >
              Copy Link
            </CopyText>
          </Flex>
        )} */}
        <Line />
        <Flex justifyContent="space-between">
          {onNftTransfer && (
            <div>
              <TransferButton variant="ghost" onClick={onNftTransfer}>
                <Send color={theme.colors.secondary} height={22} width={22} />
              </TransferButton>
            </div>
          )}
          <div>
            <Button variant="ghost" disabled={twitterUrl === null} onClick={() => twitterUrl && jumpToLink(twitterUrl)}>
              <Twitter color={theme.colors.secondary} height={22} width={22} />
            </Button>
          </div>
          <div>
            <Button
              variant="ghost"
              onClick={() => jumpToLink(`${getChainExplorerURL(chainId)}/address/${nft.address}`)}
            >
              <ImgButton src={Etherscan} />
            </Button>
          </div>
          <div>
            <Button
              variant="ghost"
              onClick={() =>
                jumpToLink(getMarketItemUrl(SupportedMarketPlaces.OPENSEA, nft.address, nft.tokenId, chainId))
              }
            >
              <ImgButton src={Opensea} />
            </Button>
          </div>
          <div>
            <Button
              variant="ghost"
              onClick={() =>
                jumpToLink(getMarketItemUrl(SupportedMarketPlaces.LOOKSRARE, nft.address, nft.tokenId, chainId))
              }
            >
              <ImgButton src={Looksrare} />
            </Button>
          </div>

          <div>
            <Button
              variant="ghost"
              onClick={() =>
                isProd && jumpToLink(getMarketItemUrl(SupportedMarketPlaces.X2Y2, nft.address, nft.tokenId, chainId))
              }
              disabled={!isProd}
            >
              <ImgButton src={X2Y2} />
            </Button>
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};
const NftImageWrapper = styled.div<{
  loaded?: string | undefined;
}>`
  display: flex;
  object-fit: scale-down;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.primary}};
  position: relative;
  :before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(100%);
  }
`;
const NftImage = styled.img<{ hasImage?: boolean }>`
  width: 100%;
  border: none;
  border-radius: 25px;
  padding: 0;
  margin: 0;
  object-fit: scale-down;
  background-color: ${({ theme }) => theme.colors.primary}};
  position: absolute;
  top: 0;
  height: 100%;
  filter: ${({ hasImage, theme }) =>
    !hasImage &&
    theme.theme === "light" &&
    "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};

  @media only screen and (max-width: 414px) {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const ImageLoader = styled(SkeletonLine)`
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 15px;
  width: 100%;
`;

const Line = styled.div`
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20}};
  margin-top: 0.5rem;
`;

const ImgButton = styled.img<{ disabled?: boolean }>`
  width: 22px;
  height: 22px;
`;
const TransferButton = styled(Button)`
  width: fit-content;
  :hover {
    svg,
    path {
      transition: 0.2s ease-in-out;
      stroke: ${({ theme }) => theme.colors.gray0};
    }
  }
`;
