import { useContext, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Text } from "@cyanco/components/theme/v3/core";
import { ArrowLeft, CheckCircle, Copy, Send } from "@cyanco/components/theme/v3/icons";

import { TELEGRAM_BOT_INVITE_LINK } from "../../config";
import { AuthContext } from "../AuthContext/AuthContextProvider";

const UpdateTelegramComponent = ({ onBack }: { onBack: () => void }) => {
  const theme = useTheme();
  const { user, userProfile, fetchUserBe } = useContext(AuthContext);
  const [uniqueCode, setUniqueCode] = useState<string>("");
  const [isAlreadySetup, setIsAlreadySetup] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!user?.wallet) return;
    const wallet = user.wallet.substring(user.wallet.length - 10);
    setUniqueCode(wallet);
  }, [userProfile, user]);

  const handleCopyClick = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(uniqueCode);
    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  const handleBackToSetupAlert = async () => {
    await fetchUserBe();
    onBack();
  };

  return (
    <Flex direction="column" gap="1rem">
      <Flex direction="column" gap="0.8rem">
        <Flex direction="row" alignItems="center" gap="0.3rem">
          <Send height={14} width={14} color={theme.colors.secondary} />
          <Text size="sm" weight="700" color="secondary">
            {`Telegram`}
          </Text>
        </Flex>
        {isAlreadySetup ? (
          <Flex direction="row" alignItems="center">
            <Text size="sm" color="cyan">
              {`Connected`}
            </Text>
          </Flex>
        ) : (
          <Flex direction="row" alignItems="center">
            <Text size="sm" color="gray30">
              {`Not connected yet`}
            </Text>
          </Flex>
        )}

        <Flex direction="row" alignItems="center" gap="0.3rem">
          <Text size="md" color="secondary" weight="500" style={{ width: "95%" }}>
            1. {`Add Cyan Alerts bot in Telegram`}
          </Text>
          <CheckCircle width={14} height={14} color={isAlreadySetup ? "cyan" : "#808080"} />
        </Flex>
        {!isAlreadySetup && (
          <ButtonContainer>
            <Button
              onClick={() => {
                setIsAlreadySetup(true);
                const newLink = TELEGRAM_BOT_INVITE_LINK + `?start=${uniqueCode}`;
                window.open(newLink, "_blank");
              }}
            >
              <Text size="sm" weight="700" color="black">
                {`Connect on Telegram`}
              </Text>
            </Button>
          </ButtonContainer>
        )}
        <Flex direction="row" alignItems="center" mt="0.5rem" gap="0.3rem">
          <Text size="md" color="secondary" weight="500" style={{ width: "95%" }}>
            2. {`Tap START to setup in telegram`}
          </Text>
          <CheckCircle height={14} width={14} color={isAlreadySetup ? "cyan" : "#808080"} />
        </Flex>
        {isAlreadySetup && (
          <>
            <Flex direction="row" alignItems="center" gap="1rem">
              <Text size="sm" color="secondary">
                {`If the bot doesn’t automatically start, please paste in the code below into the bot chat.`}
              </Text>
            </Flex>
            <Flex direction="row" alignItems="center" justifyContent="center" gap="1rem">
              <Text size="md" weight="700" color={isCopied ? "cyan" : "secondary"}>
                {uniqueCode}
              </Text>
              <div onClick={() => handleCopyClick()}>
                <Copy height={16} width={16} color={isCopied ? "cyan" : "white"} />
              </div>
            </Flex>
            <Flex direction="row" alignItems="center" justifyContent="center" mt="1rem">
              <ButtonContainer>
                <Button onClick={handleBackToSetupAlert}>
                  <Flex direction="row" gap="0.5rem" alignContent="center" alignItems="center">
                    <ArrowLeft height={12} width={12} color="black" />
                    <Text size="sm" weight="700" color="black">
                      {`Go Back`}
                    </Text>
                  </Flex>
                </Button>
              </ButtonContainer>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};

const ButtonContainer = styled.div`
  height: 50px;
  width: 100%;
`;

export default UpdateTelegramComponent;
