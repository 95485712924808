import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { ClickableArea, Flex } from "@cyanco/components/theme/components";
import {
  AssetTag,
  NftCardActionWrapper,
  NftCardText,
  NftMetadataImage,
  NftMetadataInfoWrapper,
  Text,
  Tooltip,
  TooltipText,
} from "@cyanco/components/theme/v3";

import { NftRarity } from "@/components/Account/components/common";
import { IPawn } from "@/components/Account/pawn.types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { INFtRarity } from "@/components/NftStatus";
import { bigNumToFixedStr, bigNumToFloat, shortenName } from "@/utils";

import { useSelectedUnstakingNftsContext } from "../../ApeCoinPageContext";
import { IApeCoinStakableNft, IApeCoinStakablePosition, ISelectedNft } from "../../types";
import { NftCardStyled, NftPositionCardStyled, NftPriceWrapper, StyledNftCardAddButton } from "./StakableNftCard";

export const StakedNftCard = ({
  nft,
  onClick,
}: {
  nft: IApeCoinStakableNft & { rarity: INFtRarity | null };
  onClick: (nft: ISelectedNft) => void;
}) => {
  const { items: cartItems } = useSelectedUnstakingNftsContext();
  const isInCart = useMemo(
    () => !!cartItems.find(({ address, tokenId }) => address === nft.address && tokenId === nft.tokenId),
    [cartItems, nft.address, nft.tokenId],
  );
  const getPrice = useMemo(() => {
    return !nft.appraisalValue ? "-" : bigNumToFixedStr(nft.appraisalValue, 2);
  }, [nft.appraisalValue]);
  const getPawnPrice = useMemo(() => {
    return !nft.appraisalValue ? "-" : (bigNumToFloat(nft.appraisalValue) * 0.66).toFixed(2);
  }, [nft.appraisalValue]);
  return (
    <NftCardStyled selected={isInCart} isBakc={!nft.isCyanWallet}>
      <ClickableArea onClick={() => onClick(nft)}>
        <Flex direction="column">
          <NftMetadataImage imageUrl={nft.imageUrl ?? null} alt={nft.tokenId}>
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}
            >
              <ApeCoinStakedBox>
                <Text color="primary" weight="400" size="xxs">
                  {`Staked:`}{" "}
                  <Text color="primary" weight="600" size="xxs">
                    {bigNumToFixedStr(nft.apeStaking.stakedAmount || 0, 2)} APE
                  </Text>
                </Text>
                <Text color="primary" weight="400" size="xxs">
                  {`Type:`}{" "}
                  <Text color="primary" weight="600" size="xxs">
                    {nft.apeStaking.plan ? nft.apeStaking.plan.type : "Direct"}
                  </Text>
                </Text>
              </ApeCoinStakedBox>
            </div>
          </NftMetadataImage>
        </Flex>
      </ClickableArea>
      <NftMetadataInfoWrapper>
        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <NftCardText weight="800" color="secondary">
              {shortenName(nft.collectionName)} #{shortenName(nft.tokenId, 10, 6)}
            </NftCardText>
          </div>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" h="18px">
          <Tooltip>
            <NftCardText weight="500" color="secondary" sub>
              {`${getPrice} ${nft.currency.symbol}`}
            </NftCardText>
            <TooltipText right="0" top="-40px">
              <Text size="xs" color="primary" weight="700" textWrap={false}>
                {`Appraisal value`}
              </Text>
            </TooltipText>
          </Tooltip>
          {nft.rarity && <NftRarity rarity={nft.rarity} />}
        </Flex>
      </NftMetadataInfoWrapper>
      <UnstakingAction
        isSelected={isInCart}
        amount={getPawnPrice}
        symbol={nft.currency.symbol}
        nft={nft}
        actionText={`Instant Loan:`}
      />
    </NftCardStyled>
  );
};

export const StakedPositionCard = ({
  position,
  onClick,
}: {
  position: IApeCoinStakablePosition & { rarity: INFtRarity | null };
  onClick: (nft: ISelectedNft) => void;
}) => {
  const { items: cartItems } = useSelectedUnstakingNftsContext();
  const theme = useTheme();
  const getLoanedAmount = useMemo(() => {
    if (position.planType === "Pawn") {
      return bigNumToFixedStr((position as IPawn).pawnedAmount, 2);
    } else {
      return bigNumToFixedStr((position as IBNPL).price, 2);
    }
  }, [position.currentNumOfPayments]);
  const getMonthlyAmount = useMemo(() => {
    return bigNumToFixedStr(position.monthlyAmount, 2);
  }, [position.currentNumOfPayments]);
  const isInCart = useMemo(
    () =>
      !!cartItems.find(
        ({ address, tokenId }) => address === position.metadata.collectionAddress && tokenId === position.tokenId,
      ),
    [cartItems, position.metadata.collectionAddress, position.tokenId],
  );
  return (
    <NftPositionCardStyled selected={isInCart}>
      <ClickableArea
        onClick={() =>
          onClick({
            address: position.metadata.collectionAddress.toLowerCase(),
            apeStaking: position.apeStaking,
            tokenId: position.tokenId,
            isCyanWallet: true,
            imageUrl: position.metadata.imageUrl,
          })
        }
      >
        <Flex direction="column">
          <NftMetadataImage imageUrl={position.metadata.imageUrl ?? null} alt={position.tokenId}>
            <AssetTagsWrapper>
              <AssetTag size="large" variant="black">
                {position.planType === "Pawn" ? `Loan` : position.planType}
              </AssetTag>
            </AssetTagsWrapper>
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}
            >
              <ApeCoinStakedBox>
                <Text color="primary" weight="400" size="xxs">
                  {`Staked:`}{" "}
                  <Text color="primary" weight="600" size="xxs">
                    {bigNumToFixedStr(position.apeStaking.stakedAmount || 0, 2)} APE
                  </Text>
                </Text>
                <Text color="primary" weight="400" size="xxs">
                  {`Type:`}{" "}
                  <Text color="primary" weight="600" size="xxs">
                    {position.apeStaking.plan ? position.apeStaking.plan.type : "Direct"}
                  </Text>
                </Text>
              </ApeCoinStakedBox>
            </div>
          </NftMetadataImage>
        </Flex>
      </ClickableArea>
      <NftMetadataInfoWrapper style={{ background: theme.colors.secondary }}>
        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <NftCardText weight="800" color="primary">
              {shortenName(position.metadata.collection.name)} #{shortenName(position.tokenId, 10, 6)}
            </NftCardText>
          </div>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" h="18px">
          <Tooltip>
            <NftCardText weight="500" color="primary" sub>
              {`${getLoanedAmount} ${position.currency.symbol}`}
            </NftCardText>
            <TooltipText right="0" top="-40px">
              <Text size="xs" color="primary" weight="700" textWrap={false}>
                {`Loaned amount`}
              </Text>
            </TooltipText>
          </Tooltip>
          {position.rarity && <NftRarity rarity={position.rarity} isPlan />}
        </Flex>
      </NftMetadataInfoWrapper>
      <UnstakingAction
        isSelected={isInCart}
        amount={getMonthlyAmount}
        symbol={position.currency.symbol}
        nft={{
          address: position.metadata.collectionAddress.toLowerCase(),
          apeStaking: position.apeStaking,
          tokenId: position.tokenId,
          isCyanWallet: true,
          imageUrl: position.metadata.imageUrl,
        }}
        isPosition={true}
        actionText={`Next Payment:`}
      />
    </NftPositionCardStyled>
  );
};

const UnstakingAction = ({
  isSelected,
  symbol,
  amount,
  actionText,
  nft,
  isPosition,
}: {
  isSelected: boolean;
  symbol: string;
  amount: string;
  actionText: string;
  nft: ISelectedNft;
  isPosition?: boolean;
}) => {
  const { toggleItem } = useSelectedUnstakingNftsContext();
  return (
    <NftCardActionWrapper
      style={{
        minHeight: "25px",
        position: "relative",
      }}
    >
      <NftPriceWrapper justifyContent="space-between" alignItems="center" w="100%">
        <Flex justifyContent="space-between" alignItems="center" h="25px" w="100%">
          <Flex alignItems="center">
            <StyledButton>
              <NftCardText weight="500" color="gray0">
                {actionText}
              </NftCardText>
            </StyledButton>
          </Flex>
          <NftCardText weight="600" color={isPosition ? `primary` : "secondary"}>
            {amount} {symbol}
          </NftCardText>
        </Flex>
      </NftPriceWrapper>
      <StyledNftCardAddButton
        isInCart={isSelected}
        onClick={e => {
          e.stopPropagation();
          toggleItem(nft);
        }}
        disabled={!nft.isCyanWallet}
        id={nft.tokenId}
      >
        {!isSelected ? (
          <NftCardText weight="600" color="black">{`Unstake`}</NftCardText>
        ) : (
          <NftCardText weight="600" color="secondary">
            {`Remove from unstake`}
          </NftCardText>
        )}
      </StyledNftCardAddButton>
    </NftCardActionWrapper>
  );
};

const ApeCoinStakedBox = styled(Flex)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  padding: 0.3rem 0.4rem;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  border: none;
  outline: none;
  height: 100%;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.2s;
  padding-left: 0;
`;

const AssetTagsWrapper = styled(Flex)`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
`;
