import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Flex, NftMetadataInline, NftMetadataInlineImage, Text } from "@cyanco/components/theme";
import { NewTab } from "@cyanco/components/theme/v3/icons";
import { factories as f } from "@cyanco/contract";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { ICurrency } from "@/types";
import {
  bigNumToFixedStr,
  getChainExplorerURL,
  jumpToLink,
  numberWithCommas,
  shortenAddress,
  shortenName,
} from "@/utils";

export const NftMetadata = ({
  nft,
}: {
  nft: {
    collectionAddress: string;
    tokenId: string;
    imageUrl: string;
    collectionName: string;
    appraisalValue?: string;
    currency: ICurrency;
  };
}) => {
  const theme = useTheme();
  const { provider, chainId } = useWeb3React();
  const [owner, setOwner] = useState<string | null>(null);
  useEffect(() => {
    const _setOwner = async () => {
      if (!provider) return;
      const contractWriter = f.SampleFactory.connect(nft.collectionAddress, provider);
      const _owner = await contractWriter.ownerOf(nft.tokenId);
      setOwner(_owner);
    };
    _setOwner();
  }, [nft]);
  return (
    <Flex gap="10px" pt="5px">
      <NftMetadataInlineImage imageUrl={nft.imageUrl} />
      <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 15px 0">
        <NftMetadataInline
          name={shortenName(nft.collectionName, 21, 20, 0)}
          value={`#${shortenName(nft.tokenId, 10, 5)}`}
        />
        <Flex gap="5px" direction="column" w="100%">
          <NftMetadataInline
            name={`Appraisal Value`}
            value={`${numberWithCommas(bigNumToFixedStr(nft.appraisalValue || 0), 2)} ${nft.currency.symbol}`}
            sub
          />
          <Flex justifyContent="space-between">
            <Text color="gray0" size="xs" weight="500">
              {`Owner`}
            </Text>
            <OwnerAddress
              gap="0.3rem"
              alignItems="center"
              onClick={() => jumpToLink(`${getChainExplorerURL(chainId)}/address/${owner}`)}
            >
              <Text size="xs" color="gray0">
                {`${owner ? shortenAddress(owner) : owner}`}
              </Text>
              <NewTab color={theme.colors.gray0} height={12} width={12} />
            </OwnerAddress>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const OwnerAddress = styled(Flex)`
  cursor: pointer;
  transition: 0.2s;
  :hover {
    opacity: 0.8;
  }
`;
