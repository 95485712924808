import { useState } from "react";
import styled from "styled-components";

import { Button, Flex, SubTitle, Text } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";

import { getVaultLatestLoans } from "@/apis/vault";
import { IVault, IVaultLatestLoan } from "@/apis/vault/types";
import { IPlanStatus } from "@/constants/plans";

import { VaultDetailSectionContainer } from "../VaultDetailsPrivate";
import { LiquidateReadyLoansTable } from "./LiquidateReadyLoansTable";
import { LoansPaginatedTable } from "./LoansPaginatedTable";

enum VaultLoanTabs {
  current,
  completed,
  manage,
}

export interface IVaultDefaultedNftTable {
  vault: IVault;
  loading: boolean;
  selectedLoans: IVaultLatestLoan[];
  setSelectedLoans: React.Dispatch<React.SetStateAction<IVaultLatestLoan[]>>;
}

export const PAGINATION_SIZE = 10;

export const VaultLoans = (props: IVaultDefaultedNftTable) => {
  const { vault, loading } = props;
  const fetchCompletedLoans = async (contractAddress: string, offset: number) => {
    return await getVaultLatestLoans({
      contractAddress,
      offset,
      statuses: [IPlanStatus.Completed, IPlanStatus.CompletedByRevival],
    });
  };
  const fetchActiveLoans = async (contractAddress: string, offset: number) => {
    return await getVaultLatestLoans({
      contractAddress,
      offset,
      statuses: [IPlanStatus.Activated],
    });
  };
  const [selectedTab, setSelectedTab] = useState(VaultLoanTabs.current);
  return (
    <VaultDetailSectionContainer>
      <MobileFriendlyWrapper w="100%" justifyContent="space-between" alignItems="center">
        <SubTitle>{`Loans Made from Pool`}</SubTitle>
        <TabContainer justifyContent="space-between">
          <TabButton
            active={selectedTab == VaultLoanTabs.current}
            variant="ghost"
            onClick={() => {
              setSelectedTab(VaultLoanTabs.current);
            }}
          >
            <TabText
              weight="600"
              size="sm"
              color={selectedTab === VaultLoanTabs.current ? "secondary" : "gray0"}
              textWrap={false}
            >{`Current`}</TabText>
          </TabButton>
          <TabButton
            active={selectedTab == VaultLoanTabs.completed}
            variant="ghost"
            onClick={() => {
              setSelectedTab(VaultLoanTabs.completed);
            }}
          >
            <TabText
              weight="600"
              size="sm"
              color={selectedTab === VaultLoanTabs.completed ? "secondary" : "gray0"}
              textWrap={false}
            >{`Completed`}</TabText>
          </TabButton>
          <TabButton
            active={selectedTab == VaultLoanTabs.manage}
            variant="ghost"
            onClick={() => {
              setSelectedTab(VaultLoanTabs.manage);
            }}
          >
            <TabText
              weight="600"
              size="sm"
              color={selectedTab === VaultLoanTabs.manage ? "secondary" : "gray0"}
              textWrap={false}
            >{`Manage`}</TabText>
          </TabButton>
        </TabContainer>
      </MobileFriendlyWrapper>
      <div
        style={{
          display: selectedTab === VaultLoanTabs.current ? "block" : "none",
        }}
      >
        <LoansPaginatedTable vault={vault} loading={loading} fetchLoans={fetchActiveLoans} />
      </div>
      <div
        style={{
          display: selectedTab === VaultLoanTabs.completed ? "block" : "none",
        }}
      >
        <LoansPaginatedTable vault={vault} loading={loading} fetchLoans={fetchCompletedLoans} />
      </div>
      <div
        style={{
          display: selectedTab === VaultLoanTabs.manage ? "block" : "none",
        }}
      >
        <LiquidateReadyLoansTable {...props} />
      </div>
    </VaultDetailSectionContainer>
  );
};

export const TabContainer = styled(Flex)`
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: ${({ theme }) => theme.borderWidth};
  border-color: ${({ theme }) => theme.colors.gray20};
  border-style: solid;
  background-color: ${({ theme }) => theme.colors.primary};
  height: 36px;
  padding: 5px;
  gap: 0px;
  transition: border-color 0.2s ease-in-out;
  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
  }
`;

export const TabText = styled(Text)`
  transition: color 0.2s ease-in-out;
  padding: 9px 12px;
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const TabButton = styled(Button)<{ active?: boolean }>`
  border-radius: 5px;
  background-color: ${({ theme, active }) => active && (theme.theme == "light" ? "#c8c8c8" : "#2c2c2c")};
`;

export const MobileFriendlyWrapper = styled(Flex)`
  flex-direction: row;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    align-items: unset;
  }
`;
