import { FC, isValidElement } from "react";
import styled from "styled-components";

import { Box } from "../../Box";
import { Loader } from "./Loader";
import { ITextSizes, Text, TextSizes } from "./Text";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "filled" | "outlined" | "ghost";
  loading?: boolean;
  disabled?: boolean;
  size?: ITextSizes;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const StyledButton = styled.button<IButtonProps>`
  background-color: ${({ theme, variant }) => (variant === "filled" ? "#00ffff" : theme.colors.transparent)};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: ${({ theme, variant }) => (variant === "ghost" ? 0 : theme.borderWidth)};
  border-color: #00ffff;
  border-style: solid;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  pointer-events: ${({ disabled }) => !disabled && "auto"};
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ variant, disabled }) => {
    if (variant === "filled") {
      return `
      transition: 0.2s ease-in-out;
      :hover {
        background-color: ${!disabled && "#79FFFF"};
        border-color: ${!disabled && "#79FFFF"};
      }
      `;
    }
  }}
`;

export const Button: FC<IButtonProps> = ({
  children,
  variant = "filled",
  loading = false,
  disabled = false,
  size = "sm",
  onClick = () => {},
  ...props
}) => {
  return (
    <StyledButton variant={variant} disabled={disabled} onClick={!disabled ? onClick : () => {}} {...props}>
      {isValidElement(children) ? (
        children
      ) : loading ? (
        <Box>
          <Loader stroke={variant === "filled" ? "black" : "#00FFFF"} size={TextSizes[size]} />
        </Box>
      ) : (
        <Text size={size} weight={"700"} color={variant === "filled" ? "black" : "cyan"}>
          {children}
        </Text>
      )}
    </StyledButton>
  );
};
