import { Web3ReactHooks } from "@web3-react/core";
import React, { MouseEventHandler } from "react";
import styled from "styled-components";

import { Text } from "@cyanco/components/theme/v3";

const Option = ({
  link = null,
  clickable = true,
  onClick,
  header,
  subheader = null,
  icon,
  id,
  hooks,
}: {
  link?: string | null;
  clickable?: boolean;
  size?: number | null;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  color: string;
  header: React.ReactNode;
  subheader: React.ReactNode | null;
  icon: string;
  id: string;
  hooks: Web3ReactHooks;
}) => {
  const { useIsActive } = hooks;
  const isActive = useIsActive();
  const content = (
    <OptionCardClickable id={id} onClick={onClick} clickable={clickable && !isActive} active={isActive}>
      <OptionCardLeft>
        <Text style={{ display: "flex", justifyContent: "left" }} size="lg" weight="500" color="secondary">
          {isActive ? (
            <CircleWrapper>
              <GreenCircle>
                <div />
              </GreenCircle>
            </CircleWrapper>
          ) : (
            ""
          )}
          {header}
        </Text>
        {subheader && (
          <SubHeader size="sm" style={{ marginTop: "5px" }} color="secondary">
            {subheader}
          </SubHeader>
        )}
      </OptionCardLeft>
      <IconWrapper>
        <img src={icon} alt={"icon"} />
      </IconWrapper>
    </OptionCardClickable>
  );
  if (link) {
    return (
      <ExternalLink href={link} target="_blank">
        {content}
      </ExternalLink>
    );
  }

  return content;
};

export default Option;

const InfoCard = styled.button<{ active?: boolean }>`
  background-color: ${({ theme }) => theme.colors.gray10};
  padding: 1rem;
  border: ${({ theme }) => "1px solid " + theme.colors.gray10};
  border-radius: 20px;
  width: 100%;
  transition: border 0.2s ease-in-out;
  &:hover {
    border-color: ${({ theme }) => theme.colors.gray20};
  }


  @media only screen and (max-width: 414px) {
    outline: none;
    &:hover {
      outline: none;
      }
    }
  }
`;

const OptionCard = styled(InfoCard)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1.5rem;
`;

const OptionCardLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionCardClickable = styled(OptionCard)<{ clickable?: boolean }>`
  margin-top: 0;
  &:hover {
    cursor: ${({ clickable }) => (clickable ? "pointer" : "")};
  }
`;
const GreenCircle = styled.div`
  display: flex;
  flex-direction: column nowrap;
  justify-content: center;
  align-items: center;

  &:first-child {
    height: 8px;
    width: 8px;
    margin-right: 8px;
    background-color: green;
    border-radius: 50%;
  }
`;

const CircleWrapper = styled.div`
  color: green;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div<{ size?: number | null }>`
  display: flex;
  flex-direction: column nowrap;
  justify-content: center;
  align-items: center;
  & > img,
  span {
    height: ${({ size }) => (size ? size + "px" : "24px")};
    width: ${({ size }) => (size ? size + "px" : "24px")};
  }
`;

const SubHeader = styled(Text)`
  text-align: left;
  padding-right: 0.5rem;
`;

const ExternalLink = styled.a`
  text-decoration: none;
`;
