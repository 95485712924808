import { Download } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Text } from "..";
import { Box } from "../../Box";
import { Flex } from "../../components";

export const CsvButton = ({ data }: { data: string }) => {
  const theme = useTheme();
  const download = () => {
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "positions");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };
  return (
    <Box>
      <StyledButton onClick={download}>
        <Flex gap="6px" alignItems="center">
          <Text color="secondary" size="xs" weight="600">
            CSV
          </Text>
          <Download size={13} color={theme.colors.secondary} />
        </Flex>
      </StyledButton>
    </Box>
  );
};

const StyledButton = styled.button`
  background: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#EEEEEE")};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  padding: 6px 15px 5px 15px;
  border-radius: 100px;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#EEEEEE")};
  }
`;
