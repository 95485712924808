import { ethers } from "ethers";
import { useMemo, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { NumericFormat, OnValueChange } from "react-number-format";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Input, SystemMessage, Text } from "@cyanco/components/theme/v3";
import { CyanC } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { IVault } from "@/apis/vault/types";
import { useVaultPositions } from "@/components/Vault/VaultDataProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { bigNumToFloat, displayInUSD } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { IPosition } from "../../../Vault/types";

export const VaultTokenTransfer = ({ position, vault }: { vault: IVault; position: IPosition }) => {
  const { account, signer } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const [transferAmount, setTransferAmount] = useState<string>("");
  const [transferAddress, setTransferAddress] = useState<string>("");
  const { fetchPositions } = useVaultPositions();

  const {
    execute: transferToken,
    loading: transferLoading,
    error: transferError,
    result: isTransferCompleted,
  } = useAsyncCallback(async () => {
    if (!signer || !account) {
      throw new Error(`No provider found!`);
    }
    if (position.isCyanWallet) {
      if (!cyanWallet) return true;
      const contractIFace = f.SampleERC20TokenFactory.createInterface();
      const transferFnData = contractIFace.encodeFunctionData("transfer", [
        transferAddress,
        ethers.utils.parseUnits(transferAmount, vault.decimals),
      ]);
      const walletExecuteFnData = cyanWallet.interface.encodeFunctionData("execute", [
        vault.contractTokenAddress,
        "0",
        transferFnData,
      ]);
      const tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        data: walletExecuteFnData,
      });
      await tx.wait();
    } else {
      const contractWriter = f.SampleERC20TokenFactory.connect(vault.contractTokenAddress, signer);
      const tx = await contractWriter.transfer(
        transferAddress,
        ethers.utils.parseUnits(transferAmount, vault.decimals),
      );
      await tx.wait();
    }
    await fetchPositions();
    return true;
  });
  const tokenBalanceInNumber = useMemo(() => {
    return Math.trunc(bigNumToFloat(position.balance, vault.decimals) * 100) / 100;
  }, [position.balance]);
  const onTransferAmountChange: OnValueChange = values => {
    setTransferAmount(values.value);
  };
  const mappedError = transferError ? mapAndLogError(transferError) : undefined;
  return (
    <Flex direction="column" gap="1rem">
      {mappedError && (
        <SystemMessage
          variant="error"
          title={mappedError.title}
          msg={mappedError.msg}
          description={mappedError.description}
        />
      )}
      <Flex direction="column" gap="0.4rem">
        <Text size="sm" weight="500" color="gray0" textAlign="right">
          Max: {tokenBalanceInNumber.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </Text>
        <Flex gap="0.7rem">
          <Flex gap="0.7rem" alignItems="center">
            <VaultImageWrapper style={{ background: vault.colorCode }}>
              <VaultImage src={CyanC} alt={vault.name} />
            </VaultImageWrapper>
            <Text size="xxl" color="secondary" weight="600">
              {vault.symbol}
            </Text>
          </Flex>
          <InputContainer>
            <NumericFormat
              placeholder="0"
              thousandSeparator=","
              allowLeadingZeros={false}
              allowNegative={false}
              onValueChange={onTransferAmountChange}
              value={transferAmount}
              disabled={isTransferCompleted || transferLoading}
              customInput={Input}
              decimalScale={2}
              valueIsNumericString
              fontSize="xl"
              textAlign="right"
              p="0.4rem 0.7rem"
            />
          </InputContainer>
        </Flex>
        <Text size="sm" weight="500" color="gray0" textAlign="right">
          ={" "}
          {vault.priceUsd
            ? `${transferAmount ? displayInUSD(vault.priceUsd * parseFloat(transferAmount)) : "$0"}`
            : "N/A"}
        </Text>
      </Flex>

      <Flex direction="column" gap="0.5rem">
        {!isTransferCompleted && (
          <>
            <Text size="sm" color="secondary">
              {`Transfer to address`}:
            </Text>
            <Input
              placeholder="0x1abcd..."
              onChange={e => setTransferAddress(e.target.value)}
              value={transferAddress}
            />
          </>
        )}

        {isTransferCompleted && (
          <>
            <Text size="sm" color="secondary">
              🎉 {`Successfully transferred to:`}
            </Text>
            <Text size="sm" color="secondary">
              {transferAddress}
            </Text>
          </>
        )}

        {!isTransferCompleted && (
          <StyledConfirmButton
            disabled={transferAddress === "" || transferAmount === "0"}
            onClick={transferToken}
            loading={transferLoading}
          >
            {`Transfer`}
          </StyledConfirmButton>
        )}
      </Flex>
    </Flex>
  );
};
const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  box-sizing: border-box;
  border: none;
  width: 100%;
  transition: 0.2s;
`;
const VaultImageWrapper = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const VaultImage = styled.img`
  width: 23px;
  height: 23px;
`;
