import { createContext, useContext, useState } from "react";

export enum AccountPageTabs {
  wallets = "wallets",
  positions = "positions",
  vault_tokens = "vault_tokens",
  lending = "lending",
}
export enum WalletSelectedViews {
  nfts = "nfts",
  tokens = "tokens",
}
export enum PositionsSelectedViews {
  historical = "historical",
  current = "current",
}
export enum P2PLendingSelectedViews {
  historical = "historical",
  current = "current",
  offers = "offers",
}
export enum VaultTokensSelectedViews {
  historical = "historical",
  current = "current",
}
enum PositionTypes {
  allPlans = "allPlans",
  bnplPlans = "bnplPlans",
  pawnPlans = "pawnPlans",
}
export enum WalletTypes {
  allWallets = "allWallets",
  mainWallet = "mainWallet",
  cyanWallet = "cyanWallet",
}
export enum WalletViewStyle {
  list = "list",
  grid = "grid",
}
export enum VaultTokenTransactionTypes {
  Stake = 1,
  Unstake = 2,
}
export enum PositionViewSortingAttributes {
  tokenId = "tokenId",
  paymentDate = "paymentDate",
  paymentAmount = "paymentAmount",
  floorPrice = "floorPrice",
  totalCost = "totalCost",
  loanProgress = "loanProgress",
}
export enum LendTabData {
  vault = "vault",
  p2p = "p2p",
}
type IAccountPageTabs = keyof typeof AccountPageTabs;
export type IWalletSelectedViews = keyof typeof WalletSelectedViews;
export type IPositionsSelectedViews = keyof typeof PositionsSelectedViews;
type IVaultTokensSelectedViews = keyof typeof VaultTokensSelectedViews;
type IPositionTypes = keyof typeof PositionTypes;
export type IWalletTypes = keyof typeof WalletTypes;
export type IWalletViewStyle = keyof typeof WalletViewStyle;

type AccountPageContextType = {
  selectedTab: IAccountPageTabs;
  selectedWalletView: IWalletSelectedViews;
  selectedPositionsView: IPositionsSelectedViews;
  selectedLendingView: P2PLendingSelectedViews;
  selectedLendingSearch: string;
  selectedLendingTabData: LendTabData;
  selectedVaultTokensView: IVaultTokensSelectedViews;
  selectedPositionType: IPositionTypes;
  selectedWalletType: IWalletTypes;
  selectedVaultWalletType: IWalletTypes;
  showCyanSupportedProjects: boolean;
  showDefaultedPlans: boolean;
  showRejectedPlans: boolean;
  tabViewStyle: IWalletViewStyle;
  selectedVault: number;
  selectedNftSearch: string;
  selectedPositionSearch: string;
  selectedTokenSearch: string;
  selectedVaultSearch: string;
  sortingAttribute: {
    [key in PositionViewSortingAttributes]: "asc" | "desc";
  };
  setSortingAttribute: (a: {
    [key in PositionViewSortingAttributes]: "asc" | "desc";
  }) => void;
  selectedSortingAtttibute: {
    key: PositionViewSortingAttributes;
    order: "asc" | "desc";
  };
  showRecentRejectedLoans: boolean;
  setShowRecentRejectedLoans: (search: boolean) => void;
  setSelectedSortingAttribute: (a: { key: PositionViewSortingAttributes; order: "asc" | "desc" }) => void;
  setSelectedVaultSearch: (search: string) => void;
  setSelectedNftSearch: (search: string) => void;
  setSelectedPositionSearch: (search: string) => void;
  setSelectedTokenSearch: (search: string) => void;
  setSelectedVault: (vault: number) => void;
  setTabViewStyle: (style: IWalletViewStyle) => void;
  setShowDefaultedPlans: (show: boolean) => void;
  setShowRejectedPlans: (show: boolean) => void;
  setShowCyanSupportedProjects: (show: boolean) => void;
  setSelectedTab: (tab: IAccountPageTabs) => void;
  setSelectedWalletView: (view: IWalletSelectedViews) => void;
  setSelectedPositionsView: (view: IPositionsSelectedViews) => void;
  setSelectedLendingView: (view: P2PLendingSelectedViews) => void;
  setSelectedLendingSearch: (search: string) => void;
  setSelectedVaultTokensView: (view: IVaultTokensSelectedViews) => void;
  setSelectedPositionType: (type: IPositionTypes) => void;
  setSelectedWalletType: (type: IWalletTypes) => void;
  setSelectedVaultWalletType: (type: IWalletTypes) => void;
  setSelectedLendingTabData: (view: LendTabData) => void;
  reset: () => void;
};

export const AccountPageContext = createContext<AccountPageContextType>({
  selectedTab: AccountPageTabs.wallets,
  setSelectedTab: () => {},
  selectedWalletView: WalletSelectedViews.tokens,
  setSelectedWalletView: () => {},
  selectedPositionsView: PositionsSelectedViews.current,
  setSelectedPositionsView: () => {},
  selectedLendingView: P2PLendingSelectedViews.current,
  setSelectedLendingView: () => {},
  selectedLendingSearch: "",
  setSelectedLendingSearch: () => {},
  selectedVaultTokensView: VaultTokensSelectedViews.current,
  setSelectedVaultTokensView: () => {},
  selectedPositionType: PositionTypes.bnplPlans,
  setSelectedPositionType: () => {},
  selectedWalletType: WalletTypes.mainWallet,
  setSelectedVaultWalletType: () => {},
  selectedVaultWalletType: WalletTypes.mainWallet,
  setSelectedWalletType: () => {},
  showCyanSupportedProjects: false,
  showDefaultedPlans: false,
  showRejectedPlans: false,
  setShowDefaultedPlans: () => {},
  setShowRejectedPlans: () => {},
  setShowCyanSupportedProjects: () => {},
  tabViewStyle: WalletViewStyle.list,
  setTabViewStyle: () => {},
  selectedVault: 0, // All vaults
  setSelectedVault: () => {},
  selectedNftSearch: "",
  selectedPositionSearch: "",
  selectedTokenSearch: "",
  selectedVaultSearch: "",
  sortingAttribute: {
    [PositionViewSortingAttributes.paymentAmount]: "asc",
    [PositionViewSortingAttributes.paymentDate]: "asc",
    [PositionViewSortingAttributes.tokenId]: "asc",
    [PositionViewSortingAttributes.floorPrice]: "asc",
    [PositionViewSortingAttributes.totalCost]: "asc",
    [PositionViewSortingAttributes.loanProgress]: "asc",
  },
  selectedSortingAtttibute: {
    key: PositionViewSortingAttributes.paymentDate,
    order: "asc",
  },
  selectedLendingTabData: LendTabData.p2p,
  setSelectedLendingTabData: () => {},
  setSelectedSortingAttribute: () => {},
  setSortingAttribute: () => {},
  setSelectedVaultSearch: () => {},
  setSelectedNftSearch: () => {},
  setSelectedPositionSearch: () => {},
  setSelectedTokenSearch: () => {},
  reset: () => {},
  showRecentRejectedLoans: false,
  setShowRecentRejectedLoans: () => {},
});

export const AccountPageProvider: React.FC = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState<IAccountPageTabs>(AccountPageTabs.wallets);
  const [selectedWalletView, setSelectedWalletView] = useState<IWalletSelectedViews>(WalletSelectedViews.nfts);
  const [selectedPositionsView, setSelectedPositionsView] = useState<IPositionsSelectedViews>(
    PositionsSelectedViews.current,
  );
  const [selectedVaultTokensView, setSelectedVaultTokensView] = useState<IVaultTokensSelectedViews>(
    VaultTokensSelectedViews.current,
  );
  const [selectedLendingView, setSelectedLendingView] = useState<P2PLendingSelectedViews>(
    P2PLendingSelectedViews.current,
  );
  const [selectedLendingTabData, setSelectedLendingTabData] = useState<LendTabData>(LendTabData.p2p);
  const [selectedPositionType, setSelectedPositionType] = useState<IPositionTypes>(PositionTypes.allPlans);
  const [selectedWalletType, setSelectedWalletType] = useState<IWalletTypes>(WalletTypes.allWallets);
  const [selectedVaultWalletType, setSelectedVaultWalletType] = useState<IWalletTypes>(WalletTypes.allWallets);
  const [showCyanSupportedProjects, setShowCyanSupportedProjects] = useState<boolean>(true);
  const [showDefaultedPlans, setShowDefaultedPlans] = useState<boolean>(true);
  const [showRejectedPlans, setShowRejectedPlans] = useState<boolean>(true);
  const [tabViewStyle, setTabViewStyle] = useState<IWalletViewStyle>(WalletViewStyle.list);
  const [selectedVault, setSelectedVault] = useState<number>(0);
  const [selectedNftSearch, setSelectedNftSearch] = useState<string>("");
  const [selectedPositionSearch, setSelectedPositionSearch] = useState<string>("");
  const [selectedLendingSearch, setSelectedLendingSearch] = useState<string>("");
  const [selectedTokenSearch, setSelectedTokenSearch] = useState<string>("");
  const [selectedVaultSearch, setSelectedVaultSearch] = useState<string>("");
  const [sortingAttribute, setSortingAttribute] = useState<{
    [PositionViewSortingAttributes.paymentAmount]: "asc" | "desc";
    [PositionViewSortingAttributes.paymentDate]: "asc" | "desc";
    [PositionViewSortingAttributes.tokenId]: "asc" | "desc";
    [PositionViewSortingAttributes.floorPrice]: "asc" | "desc";
    [PositionViewSortingAttributes.loanProgress]: "asc" | "desc";
    [PositionViewSortingAttributes.totalCost]: "asc" | "desc";
  }>({
    [PositionViewSortingAttributes.paymentAmount]: "asc",
    [PositionViewSortingAttributes.paymentDate]: "asc",
    [PositionViewSortingAttributes.tokenId]: "asc",
    [PositionViewSortingAttributes.floorPrice]: "asc",
    [PositionViewSortingAttributes.loanProgress]: "asc",
    [PositionViewSortingAttributes.totalCost]: "asc",
  });
  const [selectedSortingAtttibute, setSelectedSortingAttribute] = useState<{
    key: PositionViewSortingAttributes;
    order: "asc" | "desc";
  }>({
    key: PositionViewSortingAttributes.paymentDate,
    order: "asc",
  });
  const [showRecentRejectedLoans, setShowRecentRejectedLoans] = useState(true);
  const reset = () => {
    setSelectedSortingAttribute({
      key: PositionViewSortingAttributes.paymentDate,
      order: "asc",
    });
    setSortingAttribute({
      [PositionViewSortingAttributes.paymentAmount]: "asc",
      [PositionViewSortingAttributes.paymentDate]: "asc",
      [PositionViewSortingAttributes.tokenId]: "asc",
      [PositionViewSortingAttributes.floorPrice]: "asc",
      [PositionViewSortingAttributes.totalCost]: "asc",
      [PositionViewSortingAttributes.loanProgress]: "asc",
    });
    setSelectedVaultSearch("");
    setSelectedTokenSearch("");
    setSelectedLendingSearch("");
    setSelectedPositionSearch("");
    setSelectedNftSearch("");
    setSelectedVault(0);
    setTabViewStyle(WalletViewStyle.list);
    setShowRejectedPlans(true);
    setShowDefaultedPlans(true);
    setShowCyanSupportedProjects(true);
    setSelectedVaultWalletType(WalletTypes.allWallets);
    setSelectedWalletType(WalletTypes.allWallets);
    setSelectedPositionType(PositionTypes.allPlans);
    setSelectedLendingTabData(LendTabData.p2p);
    setSelectedLendingView(P2PLendingSelectedViews.current);
    setSelectedVaultTokensView(VaultTokensSelectedViews.current);
    setSelectedPositionsView(PositionsSelectedViews.current);
    setSelectedWalletView(WalletSelectedViews.nfts);
    setSelectedTab(AccountPageTabs.wallets);
    setShowRecentRejectedLoans(false);
  };
  return (
    <AccountPageContext.Provider
      value={{
        selectedTab,
        selectedVault,
        selectedWalletView,
        selectedPositionsView,
        selectedVaultTokensView,
        selectedPositionType,
        selectedWalletType,
        showCyanSupportedProjects,
        showDefaultedPlans,
        showRejectedPlans,
        tabViewStyle,
        selectedNftSearch,
        selectedPositionSearch,
        selectedTokenSearch,
        selectedVaultSearch,
        sortingAttribute,
        selectedSortingAtttibute,
        setSelectedSortingAttribute,
        setSortingAttribute,
        setSelectedVaultSearch,
        setSelectedNftSearch,
        setSelectedPositionSearch,
        setSelectedTokenSearch,
        setTabViewStyle,
        setShowDefaultedPlans,
        setShowRejectedPlans,
        setShowCyanSupportedProjects,
        setSelectedWalletType,
        setSelectedPositionType,
        setSelectedVaultTokensView,
        setSelectedPositionsView,
        setSelectedVault,
        setSelectedTab,
        setSelectedWalletView,
        selectedLendingSearch,
        setSelectedLendingSearch,
        selectedLendingView,
        setSelectedLendingView,
        selectedLendingTabData,
        setSelectedLendingTabData,
        selectedVaultWalletType,
        setSelectedVaultWalletType,
        showRecentRejectedLoans,
        setShowRecentRejectedLoans,
        reset,
      }}
    >
      {children}
    </AccountPageContext.Provider>
  );
};

export const useWalletTabContext = () => {
  const {
    selectedWalletView,
    setSelectedWalletView,
    showCyanSupportedProjects,
    setShowCyanSupportedProjects,
    selectedWalletType,
    setSelectedWalletType,
    selectedTokenSearch,
    setSelectedTokenSearch,
    selectedNftSearch,
    setSelectedNftSearch,
  } = useContext(AccountPageContext);
  return {
    selectedWalletView,
    setSelectedWalletView,
    showCyanSupportedProjects,
    setShowCyanSupportedProjects,
    selectedWalletType,
    setSelectedWalletType,
    selectedTokenSearch,
    setSelectedTokenSearch,
    selectedNftSearch,
    setSelectedNftSearch,
  };
};

export const usePositionsTabContext = () => {
  const {
    selectedPositionsView,
    setSelectedPositionsView,
    selectedPositionType,
    setSelectedPositionType,
    showDefaultedPlans,
    setShowDefaultedPlans,
    showRejectedPlans,
    setShowRejectedPlans,
    setSelectedPositionSearch,
    selectedPositionSearch,
    sortingAttribute,
    setSortingAttribute,
    selectedSortingAtttibute,
    setSelectedSortingAttribute,
    showRecentRejectedLoans,
    setShowRecentRejectedLoans,
  } = useContext(AccountPageContext);
  return {
    selectedPositionsView,
    setSelectedPositionsView,
    selectedPositionType,
    setSelectedPositionType,
    showDefaultedPlans,
    setShowDefaultedPlans,
    showRejectedPlans,
    setShowRejectedPlans,
    setSelectedPositionSearch,
    selectedPositionSearch,
    sortingAttribute,
    setSortingAttribute,
    selectedSortingAtttibute,
    setSelectedSortingAttribute,
    showRecentRejectedLoans,
    setShowRecentRejectedLoans,
  };
};

export const useLendingTabContext = () => {
  const {
    selectedLendingSearch,
    selectedLendingView,
    setSelectedLendingSearch,
    setSelectedLendingView,
    selectedLendingTabData,
    setSelectedLendingTabData,
  } = useContext(AccountPageContext);
  return {
    selectedLendingSearch,
    selectedLendingView,
    setSelectedLendingSearch,
    setSelectedLendingView,
    selectedLendingTabData,
    setSelectedLendingTabData,
  };
};

export const useVaultTokensTabContext = () => {
  const {
    selectedVaultTokensView,
    setSelectedVaultTokensView,
    selectedVault,
    setSelectedVault,
    selectedVaultSearch,
    setSelectedVaultSearch,
    selectedVaultWalletType,
    setSelectedVaultWalletType,
  } = useContext(AccountPageContext);
  return {
    selectedVaultTokensView,
    setSelectedVaultTokensView,
    selectedVault,
    setSelectedVault,
    selectedVaultSearch,
    setSelectedVaultSearch,
    selectedVaultWalletType,
    setSelectedVaultWalletType,
  };
};

export const useAccountPageContext = () => {
  return useContext(AccountPageContext);
};

export const useTabViewStyle = () => {
  const { tabViewStyle, setTabViewStyle } = useContext(AccountPageContext);
  return { tabViewStyle, setTabViewStyle };
};
