import { BigNumber } from "ethers";

export interface IPosition {
  id: number;
  vaultId: number;
  balance: BigNumber;
  availableBalance: BigNumber;
  lastTxn: string;
  chainId: number;
  isCyanWallet?: boolean;
}

export type IVaultPositionBe = Exclude<IPosition, "availableBalance">;

export interface IVaultTokenTransaction {
  id: number;
  amount: string;
  amountEth: string;
  ethUsdPrice: number;
  txnHash: string;
  type: number;
  vaultId: number;
  createdAt: Date;
  isCyanWallet?: boolean;
  vault: {
    name: string;
    symbol: string;
    colorCode: string;
    currency: "POL" | "ETH";
    decimals: number;
    contractAddress?: string;
  };
}

export enum VaultContractAbiNames {
  CyanVaultV2 = "CyanVaultV2",
  CyanVaultV2_1 = "CyanVaultV2_1",
  ApeCoinVaultV1 = "ApeCoinVaultV1",
}

export const VaultDecimalFormatMap = new Map<number, number>([
  [6, 2],
  [18, 5],
]);
