import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NftCardLoading, NotFound, useModal } from "@cyanco/components/theme/v3";

import { IPeerPlan, PeerPlanStatuses, isPeerPlan } from "@/apis/p2p/types";
import { isUserNft } from "@/apis/user";
import { IUserNft } from "@/apis/user/types";
import { BNPLStatuses, IBNPL, isBnplPlan } from "@/components/Bnpl/bnpl.types";
import { INFtRarity } from "@/components/NftStatus";
import { InfiniteScroller } from "@/components/Pagination/InfiniteScroller";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { usePlanCreation } from "@/hooks";
import { ITEM_AMOUNT_BY_NFT_TYPE } from "@/types";
import { bigNumToFloat, getRarityRank, numberWithCommas } from "@/utils";

import { useAppContext } from "../../../AppContextProvider";
import {
  filterAssets,
  useBNPLPositions,
  usePawnPositions,
  usePeerPlans,
  useUserAssets,
} from "../../AccountDataContext";
import { WalletTypes, WalletViewStyle, useTabViewStyle, useWalletTabContext } from "../../AccountPageContext";
import { useSelectedItems } from "../../SelectedItemsContext";
import { ContainerBox, Header, ListViewBox } from "../../index";
import { IPawn, PawnStatuses, isPawnPlan } from "../../pawn.types";
import { ISelectedNft, UserNftCard } from "./NftCard";
import { UserNftRow, UserNftRowLoading, UserNftsListHeader } from "./NftRow";
import { UserNftDetails } from "./UserNftModals/UserNftDetails";
import { UserNftTransfer } from "./UserNftModals/UserNftTransfer";
import { UserPosition } from "./UserPositions";

const UserNftWrapper: React.FC<{
  showByGrid: boolean;
  loading: boolean;
  totalItem: number;
  sortedAssets: (IUserNft | IPawn | IBNPL | IPeerPlan)[];
  updateSortedAssets: (values: (IUserNft | IPawn | IBNPL | IPeerPlan)[]) => void;
}> = ({ children, showByGrid, loading, totalItem, sortedAssets, updateSortedAssets }) => {
  if (showByGrid) {
    return (
      <Flex direction="column" gap="10px">
        {loading ? (
          <PawnNFTWrapper>
            <InfiniteScroller hasMore={false} isGrid>
              {Array.from(Array(8).keys()).map(loader => (
                <NftCardLoading key={loader} actionText={`Loan`} />
              ))}
            </InfiniteScroller>
          </PawnNFTWrapper>
        ) : (
          <PawnNFTWrapper>{children}</PawnNFTWrapper>
        )}
      </Flex>
    );
  } else {
    return (
      <ListContainer>
        <ListViewBox>
          <Header>
            <UserNftsListHeader
              totalItem={totalItem}
              sortedAssets={sortedAssets}
              updateSortedAssets={updateSortedAssets}
            />
          </Header>
          <ContainerBox>
            {loading ? Array.from(Array(6).keys()).map(loader => <UserNftRowLoading key={loader} />) : children}
          </ContainerBox>
        </ListViewBox>
      </ListContainer>
    );
  }
};

export const UserNfts: React.FC = () => {
  const { chainId, account } = useWeb3React();
  const { userAssetsLoading, userAssets, hasMore, loadMoreUserAssets, userAssetsFiltered } = useUserAssets();
  const { activePawnPositions, pawnPositions, pawnLoading } = usePawnPositions();
  const { activeBnplPositions, bnplPositions, bnplLoading } = useBNPLPositions();
  const { userTakenPeerPlans, peerPlansLoading } = usePeerPlans();
  const { tabViewStyle } = useTabViewStyle();
  const { showNewPlanModal } = usePlanCreation("pawn");
  const { setModalContent, unsetModal } = useModal();
  const { selectedNftSearch, selectedWalletType } = useWalletTabContext();
  const showByGrid = useMemo(() => tabViewStyle === WalletViewStyle.grid, [tabViewStyle]);
  const isLoading = useMemo(() => {
    return pawnLoading || userAssetsLoading || bnplLoading || peerPlansLoading;
  }, [pawnLoading, userAssetsLoading, bnplLoading, peerPlansLoading]);
  const openUserNftTransferModal = (asset: IUserNft & { rarity: INFtRarity | null }) => {
    setModalContent({
      title: `Transfer NFT`,
      content: <UserNftTransfer nfts={[asset]} onClose={unsetModal} />,
    });
  };

  const createPawn = (asset: ISelectedNft) => {
    if (!asset.appraisalValue || BigNumber.from(asset.appraisalValue).isZero() || !asset.currency || !asset.tokenType)
      return;

    showNewPlanModal({
      currency: asset.currency,
      items: [
        {
          ...asset,
          imageUrl: asset.imageUrl ?? null,
          price: BigNumber.from(asset.appraisalValue),
          currency: asset.currency,
          amount: ITEM_AMOUNT_BY_NFT_TYPE[asset.tokenType],
          itemType: asset.tokenType,
          supportedCurrencies: asset.supportedCurrencies,
          rarityStat: asset.rarity
            ? `${numberWithCommas(asset.rarity.rank)}/${numberWithCommas(asset.rarity.total)}`
            : "N/A",
          isCyanWalletAsset: asset.isCyanWallet,
        },
      ],
    }); // TODO - fix this
  };

  const openUserNftDetailsModal = (asset: ISelectedNft) => {
    setModalContent({
      title: `NFT Details`,
      content: (
        <UserNftDetails
          nft={asset}
          onNftTransfer={() => {
            openUserNftTransferModal(asset);
          }}
          onPawnNft={() => createPawn(asset)}
          onClose={unsetModal}
        />
      ),
    });
  };
  const totalItem = useMemo(() => {
    return (
      userAssets.assets.length + activeBnplPositions.length + activePawnPositions.length + userTakenPeerPlans.length
    );
  }, [userAssets, activeBnplPositions, activePawnPositions, userTakenPeerPlans]);
  const [sortedAssets, setSortedAssets] = useState<(IUserNft | IPawn | IBNPL | IPeerPlan)[]>([
    ...pawnPositions,
    ...bnplPositions,
    ...userTakenPeerPlans,
    ...userAssetsFiltered,
  ]);

  useEffect(() => {
    setSortedAssets([
      ...bnplPositions.filter(
        bnpl =>
          bnpl.status === BNPLStatuses.Activated &&
          bnpl.nextPaymentDate &&
          dayjs().isBefore(bnpl.nextPaymentDate) &&
          bnpl.paymentPlan.chainId === chainId,
      ),
      ...pawnPositions.filter(
        pawn =>
          pawn.status === PawnStatuses.Activated &&
          pawn.nextPaymentDate &&
          dayjs().isBefore(pawn.nextPaymentDate) &&
          pawn.paymentPlan.chainId === chainId,
      ),
      ...userTakenPeerPlans.filter(plan => plan.status === PeerPlanStatuses.ACTIVE),
      ...filterAssets(userAssetsFiltered, [
        ...pawnPositions,
        ...bnplPositions,
        ...userTakenPeerPlans.filter(plan => plan.borrowerAddress.toLowerCase() === account?.toLowerCase()),
      ]),
    ]);
  }, [bnplPositions, pawnPositions, userTakenPeerPlans, userAssetsFiltered]);

  const updateSortedAssets = (values: (IUserNft | IPawn | IBNPL | IPeerPlan)[]) => {
    setSortedAssets([...values]);
  };

  const assets = useMemo(() => {
    return sortedAssets.filter(position => {
      let showPosition = true;
      if (isPawnPlan(position) || isBnplPlan(position)) {
        if (selectedNftSearch !== "") {
          showPosition =
            (position.tokenId.toLowerCase().startsWith(selectedNftSearch.toLowerCase()) ||
              position.metadata.collectionAddress.toLowerCase().startsWith(selectedNftSearch.toLowerCase()) ||
              position.metadata.collection.name.toLowerCase().startsWith(selectedNftSearch.toLowerCase())) &&
            ((selectedWalletType === WalletTypes.mainWallet && !position.isCyanWallet) ||
              (selectedWalletType === WalletTypes.cyanWallet && position.isCyanWallet) ||
              selectedWalletType === WalletTypes.allWallets);
        } else {
          showPosition =
            (selectedWalletType === WalletTypes.mainWallet && !position.isCyanWallet) ||
            (selectedWalletType === WalletTypes.cyanWallet && position.isCyanWallet) ||
            selectedWalletType === WalletTypes.allWallets;
        }
      } else if (isPeerPlan(position)) {
        if (selectedNftSearch !== "") {
          showPosition =
            (position.tokenId.toLowerCase().startsWith(selectedNftSearch.toLowerCase()) ||
              position.collectionAddress.toLowerCase().startsWith(selectedNftSearch.toLowerCase()) ||
              position.collection.name.toLowerCase().startsWith(selectedNftSearch.toLowerCase())) &&
            (selectedWalletType === WalletTypes.cyanWallet || selectedWalletType === WalletTypes.allWallets);
        } else {
          showPosition = selectedWalletType === WalletTypes.cyanWallet || selectedWalletType === WalletTypes.allWallets;
        }
      }
      return showPosition;
    });
  }, [sortedAssets, selectedNftSearch, selectedWalletType]);

  return (
    <UserNftWrapper
      loading={isLoading}
      showByGrid={showByGrid}
      totalItem={totalItem}
      sortedAssets={sortedAssets}
      updateSortedAssets={updateSortedAssets}
    >
      {userAssets && (
        <>
          {totalItem === 0 || !account ? (
            <NotFound msg={`No NFTs found`} />
          ) : (
            <InfiniteScroller
              hasMore={!!hasMore}
              loadMore={loadMoreUserAssets}
              loader={
                showByGrid ? (
                  <>
                    {Array.from(Array(8).keys()).map(loader => (
                      <NftCardLoading key={`${loader}-grid`} actionText={`Loan`} />
                    ))}
                  </>
                ) : (
                  <>
                    {Array.from(Array(6).keys()).map(loader => (
                      <UserNftRowLoading key={`${loader}-list`} />
                    ))}
                  </>
                )
              }
              isGrid={showByGrid}
            >
              {assets.map(element => {
                if (isUserNft(element)) {
                  return (
                    <UserAssets
                      key={`nft-${element.id}`}
                      assets={[element]}
                      showByGrid={showByGrid}
                      chainId={chainId}
                      account={account}
                      onClick={openUserNftDetailsModal}
                      onClickPawn={createPawn}
                      onClickTransfer={openUserNftTransferModal}
                    />
                  );
                } else if (isPawnPlan(element)) {
                  return (
                    <UserPosition
                      key={`pawn-${element.planId}`}
                      position={element}
                      chainId={chainId}
                      account={account}
                      showByGrid={showByGrid}
                    />
                  );
                } else if (isBnplPlan(element)) {
                  return (
                    <UserPosition
                      key={`bnpl-${element.planId}`}
                      position={element}
                      chainId={chainId}
                      account={account}
                      showByGrid={showByGrid}
                    />
                  );
                } else {
                  return (
                    <UserPosition
                      key={`peer-${element.loanBid.id}`}
                      position={element}
                      chainId={chainId}
                      account={account}
                      showByGrid={showByGrid}
                    />
                  );
                }
              })}
            </InfiniteScroller>
          )}
        </>
      )}
    </UserNftWrapper>
  );
};

export const UserAssets = ({
  assets,
  showByGrid,
  chainId,
  account,
  onClick,
  onClickPawn,
  onClickTransfer,
}: {
  assets: IUserNft[];
  showByGrid: boolean;
  chainId: number;
  account: string;
  onClick: (asset: ISelectedNft) => void;
  onClickPawn: (asset: ISelectedNft) => void;
  onClickTransfer: (asset: ISelectedNft) => void;
}) => {
  const { collections, usdPrice } = useAppContext();
  const { items, toggleItem } = useSelectedItems();
  const { transactions } = useTransactionContext();

  return (
    <>
      {assets.map(asset =>
        showByGrid ? (
          <UserNftCard
            nft={{
              ...asset,
              rarity: getRarityRank(asset.rarityRank, asset.address, collections),
            }}
            isSelected={items.some(
              item =>
                isUserNft(item) &&
                item.address === asset.address &&
                item.tokenId === asset.tokenId &&
                item.isCyanWallet === asset.isCyanWallet,
            )}
            toggleItem={() => toggleItem(asset)}
            key={`${asset.address}:${asset.tokenId}:${asset.isCyanWallet}`}
            chainId={chainId}
            onClick={onClick}
            onPawn={onClickPawn}
            loading={transactions.some(
              t =>
                t.data &&
                t.data.tokenId === asset.tokenId &&
                t.data.contractAddress === asset.address &&
                t.type === "pawn-create",
            )}
          />
        ) : (
          <UserNftRow
            nft={{
              ...asset,
              priceInUSD:
                asset.appraisalValue && asset.currency
                  ? bigNumToFloat(asset.appraisalValue, asset.currency.decimal) * usdPrice[asset.currency.symbol]
                  : null,
              rarity: getRarityRank(asset.rarityRank, asset.address, collections),
            }}
            chainId={chainId}
            key={`${asset.address}:${asset.tokenId}:nft:${asset.isCyanWallet ? "cyan" : "main"}`}
            account={account}
            onClick={onClick}
            onClickPawn={onClickPawn}
            onClickTransfer={onClickTransfer}
            isSelected={items.some(
              item =>
                isUserNft(item) &&
                item.address === asset.address &&
                item.tokenId === asset.tokenId &&
                item.isCyanWallet === asset.isCyanWallet,
            )}
            toggleItem={() => toggleItem(asset)}
            loading={transactions.some(
              t =>
                t.data &&
                t.data.tokenId === asset.tokenId &&
                t.data.contractAddress === asset.address &&
                t.type === "pawn-create",
            )}
            hasMultiSelectionBox={items.length > 0}
          />
        ),
      )}
    </>
  );
};

export const PawnNFTWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 30px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  width: calc(100% - calc((100% - 1240px)));
  gap: 1rem;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;
