import { formatUnits } from "ethers/lib/utils";
import { useEffect, useMemo, useState } from "react";

import { Box, Flex, Stepper, useModal } from "@cyanco/components/theme";
import { factories as f } from "@cyanco/contract";

import { usePrivateSales } from "@/components/Account/AccountDataContext";
import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPrivateSaleFromChainId } from "@/constants/contracts";
import { IInititatePrivateCancelPurchase } from "@/hooks/usePrivateSaleCreation";
import { getChainExplorerTextForTxn } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { CloseButton, PrivateSaleLoading } from "../CommonComponents";
import { PrivateSaleModal } from "../PrivateSaleModal";

export const PrivateSaleCancelStepper = ({ privateSale, nft }: IInititatePrivateCancelPurchase) => {
  const { chainId, account, signer } = useWeb3React();
  const { setFireConfetti } = useAppContext();
  const [selectedStep, setSelectedStep] = useState<PrivateSaleSteps>(PrivateSaleSteps.Cancel);
  const { setModalContent } = useModal();
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const { fetchPrivateSales } = usePrivateSales();

  const cancel = async () => {
    if (!account || !signer || !chainId) return;
    const contract = f.CyanPrivateSaleFactory.connect(getPrivateSaleFromChainId(chainId), signer);

    const tx = await contract.cancelSignature(
      {
        sellerAddress: privateSale.sellerAddress,
        buyerAddress: privateSale.buyerAddress,
        signedDate: Math.floor(privateSale.signedDate.getTime() / 1000),
        expiryDate: Math.floor(privateSale.expiryDate.getTime() / 1000),
        price: privateSale.price,
        currencyAddress: privateSale.currencyAddress,
        tokenAmount: privateSale.tokenAmount,
        tokenId: privateSale.tokenId,
        contractAddress: privateSale.collectionAddress,
        tokenType: privateSale.tokenType,
        collectionSignature: privateSale.collectionSignature,
      },
      privateSale.signature,
    );
    await tx.wait();
    setTxnFinal(tx.hash);
    setFireConfetti(true);
    fetchPrivateSales();
  };

  useEffect(() => {
    const onStepChange = async (step: PrivateSaleSteps) => {
      if (!chainId || !account || !signer) return;
      try {
        switch (step) {
          case PrivateSaleSteps.Cancel: {
            await cancel();
            setSelectedStep(PrivateSaleSteps.Done);
            return;
          }
        }
      } catch (err: any) {
        openPrivateSalePurchaseModal(err);
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);

  const openPrivateSalePurchaseModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    setModalContent({
      title: `Private Sale`,
      content: <PrivateSaleModal nft={nft} privateSale={privateSale} err={mappedError} />,
    });
  };

  const stepMarkFiltered = useMemo(() => {
    return PrivateSaleStepMarks.map(item => {
      if (item.value === PrivateSaleSteps.Cancel && txnFinal !== null) {
        return {
          ...item,
          description: getChainExplorerTextForTxn(chainId),
        };
      }
      return item;
    });
  }, [chainId]);

  return (
    <Flex gap="1rem" direction="column">
      <PrivateSaleLoading
        tokenId={privateSale.tokenId}
        collectionName={nft.collectionName.toLowerCase()}
        price={formatUnits(privateSale.price, nft.currency.decimal)}
        currencySymbol={nft.currency.symbol}
        isLoading={false}
        buyer={privateSale.buyerAddress}
        imageUrl={nft.imageUrl}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper marks={stepMarkFiltered} selectedStep={selectedStep} txUrl={""}></Stepper>
      </Box>
      {selectedStep == PrivateSaleSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum PrivateSaleSteps {
  Cancel = 0,
  Done = 1,
}

const PrivateSaleStepMarks = [
  {
    value: PrivateSaleSteps.Cancel,
    description: `Approve this to cancel your listing, little amount of gas required`,
    title: `Cancel your listing`,
  },
];
