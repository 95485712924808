import { ethers } from "ethers";
import { useMemo, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { NumericFormat } from "react-number-format";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme/components";
import {
  Button,
  Input,
  Loader,
  NftMetadataInline,
  NftMetadataInlineImage,
  SystemMessage,
  Text,
} from "@cyanco/components/theme/v3";
import { CloseX, NewTab } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { isUserNft } from "@/apis/user";
import { IUserNft } from "@/apis/user/types";
import { useSelectedItems } from "@/components/Account/SelectedItemsContext";
import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { INftType } from "@/types";
import { bigNumToFixedStr, getChainExplorerURL, getRarityRank, jumpToLink, shortenAddress, shortenName } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { useUserAssets } from "../../../AccountDataContext";

export const UserNftTransfer = ({ nfts, onClose }: { nfts: Array<IUserNft>; onClose: () => void }) => {
  const [items, setItems] = useState<IUserNft[]>(nfts);
  const { collections } = useAppContext();
  const { account, chainId, signer } = useWeb3React();
  const { removeItem: removeFromTransfer, setItems: _setItems } = useSelectedItems();
  const theme = useTheme();
  const cyanWallet = useCyanWallet();
  const { fetchUserAssets } = useUserAssets();
  const [transferAddress, setTransferAddress] = useState("");
  const [txnHash, setTxnHash] = useState("");
  const [quantity, setQuantity] = useState("1");
  const removeAllTransferItem = () => {
    _setItems(prev => prev.filter(item => !(isUserNft(item) && item.isCyanWallet)));
  };
  const {
    execute: transferNFT,
    loading: transferLoading,
    error: transferError,
    result: isTransferCompleted,
  } = useAsyncCallback(async () => {
    if (!transferAddress || !signer || !account) return;
    if (items.length === 1) {
      if (items[0].isCyanWallet && cyanWallet) {
        let encodedFnData;
        if (items[0].tokenType === INftType.ERC1155) {
          const contractIFace = f.SampleERC1155TokenFactory.createInterface();
          encodedFnData = contractIFace.encodeFunctionData("safeTransferFrom", [
            cyanWallet.walletAddress,
            transferAddress,
            items[0].tokenId,
            quantity,
            [],
          ]);
        } else if (items[0].tokenType === INftType.CryptoPunks) {
          const iCryptoPunks = f.SampleCryptoPunksFactory.createInterface();
          encodedFnData = iCryptoPunks.encodeFunctionData("transferPunk", [transferAddress, items[0].tokenId]);
        } else {
          const contractIFace = f.SampleFactory.createInterface();
          encodedFnData = contractIFace.encodeFunctionData("safeTransferFrom(address,address,uint256)", [
            cyanWallet.walletAddress,
            transferAddress,
            items[0].tokenId,
          ]);
        }
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
          items[0].address,
          "0",
          encodedFnData,
        ]);
        const tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
        await tx.wait();
        setTxnHash(tx.hash);
        removeFromTransfer(items[0]);
      } else {
        let tx: ethers.ContractTransaction;
        if (items[0].tokenType === INftType.ERC1155) {
          const contractWriter = f.SampleERC1155TokenFactory.connect(items[0].address, signer);
          tx = await contractWriter.safeTransferFrom(account, transferAddress, items[0].tokenId, quantity, []);
        } else if (items[0].tokenType === INftType.CryptoPunks) {
          const contractWriter = f.SampleCryptoPunksFactory.connect(items[0].address, signer);
          tx = await contractWriter.transferPunk(transferAddress, items[0].tokenId);
        } else {
          const contractWriter = f.SampleFactory.connect(items[0].address, signer);
          tx = await contractWriter["safeTransferFrom(address,address,uint256)"](
            account,
            transferAddress,
            items[0].tokenId,
          );
        }
        await tx.wait();
        setTxnHash(tx.hash);
        removeFromTransfer(items[0]);
      }
    } else if (cyanWallet) {
      const encodedFnDatas = items.map(item => {
        let encodedFnData;
        if (item.tokenType === INftType.ERC1155) {
          const contractIFace = f.SampleERC1155TokenFactory.createInterface();
          encodedFnData = contractIFace.encodeFunctionData("safeTransferFrom", [
            cyanWallet.walletAddress,
            transferAddress,
            item.tokenId,
            quantity,
            [],
          ]);
        } else if (item.tokenType === INftType.CryptoPunks) {
          const iCryptoPunks = f.SampleCryptoPunksFactory.createInterface();
          encodedFnData = iCryptoPunks.encodeFunctionData("transferPunk", [transferAddress, item.tokenId]);
        } else {
          const contractIFace = f.SampleFactory.createInterface();
          encodedFnData = contractIFace.encodeFunctionData("safeTransferFrom(address,address,uint256)", [
            cyanWallet.walletAddress,
            transferAddress,
            item.tokenId,
          ]);
        }
        return [item.address, "0", encodedFnData];
      });
      const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [encodedFnDatas]);
      const tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        data: encodedCyanFnData,
      });
      await tx.wait();
      setTxnHash(tx.hash);
      removeAllTransferItem();
    }
    await fetchUserAssets();
    return true;
  });

  const removeItem = (nft: IUserNft) => {
    setItems(items.filter(({ address, tokenId }) => !(nft.address === address && nft.tokenId === tokenId)));
    removeFromTransfer(nft);
  };

  const isCyanWallet = useMemo(() => {
    if (items.length === 1) return items[0].isCyanWallet;
    return true;
  }, [items]);

  const showWalletButton = useMemo(() => {
    return transferAddress.length == 0;
  }, [transferAddress]);

  const rarity = useMemo(() => {
    if (items.length === 1) return getRarityRank(items[0].rarityRank, items[0].address, collections);
    return null;
  }, [items]);
  const mappedError = transferError ? mapAndLogError(transferError) : undefined;

  return (
    <>
      <Flex direction="column" gap="1.5rem">
        {mappedError && (
          <SystemMessage
            variant="error"
            title={mappedError.title}
            msg={mappedError.msg}
            description={mappedError.description}
          />
        )}
        <SystemMessage
          variant="warning"
          title={"Important Notice"}
          msg={
            "Only NFTs in your Cyan Wallet can be transferred in batches. The items in your Main Wallet will not be transferred."
          }
        />
        {items.length === 1 ? (
          <Flex gap="10px">
            <NftMetadataInlineImage imageUrl={items[0].imageUrl} />
            <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 15px 0">
              <NftMetadataInline
                name={shortenName(items[0].collectionName)}
                value={`#${shortenName(items[0].tokenId, 10, 5)}`}
              />
              <Flex gap="5px" direction="column" w="100%">
                <NftMetadataInline
                  name={`Appraisal Value`}
                  value={
                    items[0].appraisalValue
                      ? `${bigNumToFixedStr(items[0].appraisalValue, 4)} ${items[0].currency.symbol}`
                      : "N/A"
                  }
                  sub
                />
                <NftMetadataInline name={`NFT Rarity`} value={rarity ? `${rarity.rank}/${rarity.total}` : "N/A"} sub />
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <NftImageWrapper>
            {items.map(item => (
              <ImageWrapper key={`${item.address}:${item.tokenId}`}>
                <StyledNftImage src={item.imageUrl || NoImage} key={item.tokenId} hasImage={!!item.imageUrl} />
                <RemoveFromCart
                  onClick={() => {
                    removeItem(item);
                  }}
                >
                  <CloseX color={theme.colors.primary} height={8} width={8} />
                </RemoveFromCart>
                {transferLoading && (
                  <ImageLoader>
                    <Loader stroke="white" size="64px" />
                  </ImageLoader>
                )}
              </ImageWrapper>
            ))}
          </NftImageWrapper>
        )}
        <Flex direction="column" gap="0.5rem">
          {!isTransferCompleted && account && (
            <Flex direction="column" gap="0.8rem">
              {items.length === 1 && items[0].tokenType === INftType.ERC1155 && (
                <Flex direction="column" gap="0.3rem">
                  <Flex justifyContent="space-between">
                    <Text size="sm" color="secondary">
                      {`Quantity`}:
                    </Text>
                    <Text size="sm" color="gray0">
                      {`Max`}: {items[0].ownership.tokenCount}
                    </Text>
                  </Flex>
                  <NumericFormat
                    placeholder="0"
                    onChange={e => setQuantity(e.target.value)}
                    disabled={transferLoading}
                    value={quantity}
                    style={{
                      fontSize: "14px",
                    }}
                    p={"0.65rem 0.2rem 0.65rem 0.4rem"}
                    customInput={Input}
                    max={items[0].ownership.tokenCount}
                  />
                </Flex>
              )}
              <Flex direction="column" gap="0.3rem">
                <Text size="sm" color="secondary">
                  {`Transfer to NFT address`}:
                </Text>
                <Input
                  placeholder="0x1abcd..."
                  onChange={e => setTransferAddress(e.target.value)}
                  disabled={transferLoading}
                  value={transferAddress}
                  fontSize="sm"
                  p={showWalletButton ? "0.2rem 0.2rem 0.2rem 0.4rem" : "0.65rem 0.2rem 0.65rem 0.4rem"}
                >
                  <WalletButton
                    onClick={() => {
                      if (isCyanWallet) {
                        setTransferAddress(account);
                      } else {
                        setTransferAddress(cyanWallet?.walletAddress || "");
                      }
                    }}
                    disabled={transferLoading}
                    style={{
                      display: showWalletButton ? "block" : "none",
                    }}
                  >
                    {!isCyanWallet ? (
                      <Text color="gray0" weight="600" size="xs" textWrap={false}>
                        {`Cyan Wallet`}
                      </Text>
                    ) : (
                      <Text color="gray0" weight="600" size="xs" textWrap={false}>
                        {shortenAddress(account)}
                      </Text>
                    )}
                  </WalletButton>
                </Input>
              </Flex>
            </Flex>
          )}

          {isTransferCompleted && txnHash !== "" && (
            <>
              <Text size="sm" color="secondary">
                🎉 {`Successfully sent to:`}
              </Text>
              <Input
                placeholder="0x1abcd..."
                onChange={e => setTransferAddress(e.target.value)}
                value={transferAddress}
                fontSize="sm"
                disabled
              />
              <Flex justifyContent="flex-end">
                <Flex
                  alignItems="center"
                  onClick={() => jumpToLink(`${getChainExplorerURL(chainId)}/tx/${txnHash}`)}
                  gap="3px"
                  style={{
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                >
                  <Text color="gray0" size="xxs">
                    {`View on Block Explorer`}
                  </Text>
                  <NewTab color={theme.colors.gray0} height={10} />
                </Flex>
              </Flex>
            </>
          )}

          {!isTransferCompleted ? (
            <StyledConfirmButton
              disabled={transferAddress === "" || transferLoading}
              onClick={transferNFT}
              loading={transferLoading}
            >
              {`Transfer`}
            </StyledConfirmButton>
          ) : (
            <StyledConfirmButton onClick={onClose}>{`Close`}</StyledConfirmButton>
          )}
        </Flex>
      </Flex>
    </>
  );
};

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;

const WalletButton = styled.button`
  background: ${({ theme }) => theme.colors.gray20};
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0.6rem;
  transition: background 0.2s ease-in-out;
`;

const StyledNftImage = styled.img<{ hasImage?: boolean; hasError?: boolean }>`
  height: 85px;
  width: 85px;
  object-fit: scale-down;
  border-radius: 15px;
  padding: 0;
  margin: 0;
  background-color: black;
  filter: ${({ hasImage, theme }) =>
    !hasImage &&
    theme.theme === "light" &&
    "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;

const NftImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  max-height: 180px;
  overflow: auto;
  padding-top: 12px;
`;
const RemoveFromCart = styled.div`
  cursor: pointer;
  display: none;
  position: absolute;
  top: -5px;
  right: 0px;
  padding: 5px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary}};
`;

const ImageWrapper = styled.div`
  position: relative;
  &:hover ${RemoveFromCart} {
    display: flex;
  }
`;
const ImageLoader = styled.div`
  height: 85px;
  width: 85px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: none;
`;
