import dayjs from "dayjs";
import { BigNumber } from "ethers";
import orderBy from "lodash.orderby";
import { useMemo } from "react";
import { HelpCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Box, Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Text, Tooltip, TooltipText, useModal } from "@cyanco/components/theme/v3";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { useVaultTransactions } from "@/components/Account/AccountDataContext";
import { VaultTokenTransactionTypes } from "@/components/Account/AccountPageContext";
import { useVaults } from "@/components/Vault/VaultDataProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apeVaultContract, isProd } from "@/config";
import { bigNumToFloat, isApeCoinStakingPossible, jumpToLink, numberWithCommas } from "@/utils";

import Bakc from "../../../../../assets/images/bakc.svg";
import Bayc from "../../../../../assets/images/bayc.svg";
import Mayc from "../../../../../assets/images/mayc.svg";
import { useApeStakingUserAssets } from "../../ApeCoinDataContext";
import { useApeCoinStatsContext } from "../../ApeCoinStatsContext";
import { IActionType } from "../../types";
import { ApeCoinOnlyStakingModal } from "../PlanModal/ApeCoinStakingModal";

export const UserRewards = () => {
  const { chainId } = useWeb3React();
  const theme = useTheme();
  const navigate = useNavigate();
  const { vaultTransactions } = useVaultTransactions();
  const { userBalance } = useApeCoinStatsContext();
  const { vaults } = useVaults();
  const { setModalContent } = useModal();
  const { stakedAssets, stakedPositions, apeCoinBalance, loading, activeApeCoinPlan } = useApeStakingUserAssets();
  const withdrawals24hr = useMemo(() => {
    const now = dayjs();
    return vaultTransactions
      .filter(
        tx =>
          tx.vault.contractAddress?.toLowerCase() === apeVaultContract &&
          tx.type === VaultTokenTransactionTypes.Unstake &&
          dayjs(tx.createdAt).isAfter(now.subtract(1, "day")),
      )
      .reduce((acc, cur) => {
        return acc.add(cur.amountEth);
      }, BigNumber.from(0));
  }, [vaultTransactions]);
  const changes24hr = useMemo(() => {
    const now = dayjs();
    let change = 0;
    const apeVault = vaults.find(vault => vault.contractAddress.toLowerCase() === apeVaultContract);
    const deposited = vaultTransactions
      .filter(
        tx =>
          tx.vault.contractAddress?.toLowerCase() === apeVaultContract &&
          tx.type === VaultTokenTransactionTypes.Stake &&
          dayjs(tx.createdAt).isAfter(now.subtract(1, "day")),
      )
      .reduce((acc, cur) => {
        return acc.add(cur.amountEth);
      }, BigNumber.from(0));
    if (apeVault) {
      const price = orderBy(
        apeVault.history.filter(history => dayjs(history.createdAt).isAfter(now.subtract(1, "day"))),
        "createdAt",
        "asc",
      )[0];
      if (price) {
        const priceChange = bigNumToFloat(userBalance.helpers.tokenPrice) - bigNumToFloat(price.price);
        change = change + priceChange * bigNumToFloat(userBalance.vaultBalance);
      }
    }
    return change + (bigNumToFloat(deposited) - bigNumToFloat(withdrawals24hr));
  }, [withdrawals24hr, vaults, userBalance.vaultBalance, userBalance.helpers.tokenPrice]);

  const accruedApe = useMemo(() => {
    const accruedApe = [...stakedAssets, ...stakedPositions].reduce((acc, cur) => {
      if (cur.apeStaking.stakedAmount) {
        return acc + bigNumToFloat(cur.apeStaking.earnedAmount || 0);
      }
      return acc;
    }, 0);

    return accruedApe + bigNumToFloat(apeCoinBalance.earnedAmount) + bigNumToFloat(apeCoinBalance.earnedAmountCyan);
  }, [stakedAssets, stakedPositions]);

  const borrowedApe = useMemo(() => {
    return [...stakedAssets, ...stakedPositions].reduce((acc, cur) => {
      if (cur.apeStaking.plan) {
        return acc.add(cur.apeStaking.plan.borrowedApe);
      }
      return acc;
    }, BigNumber.from(0));
  }, [stakedAssets, stakedPositions]);

  const userOwnedStakeAmount = useMemo(() => {
    if (loading) return 0;
    return userBalance.totalStaked.sub(borrowedApe);
  }, [borrowedApe, userBalance.totalStaked]);
  return (
    <Card>
      <Flex direction="column" gap="1.5rem" h="100%">
        <Flex alignItems="center" gap="7px">
          <StyledImg src={ApeCoin} alt="ApeCoin" />
          <Text size="lg" color="secondary" weight="600">
            {`Staking Rewards`}
          </Text>
        </Flex>
        <Container>
          <VaultSection direction="column" alignItems="center" gap="1rem" justifyContent="space-between">
            <Flex direction="column" alignItems="center" gap="1rem" w="100%">
              <StyledImgLogo
                src={ApeCoin}
                alt="ApeCoin"
                style={{
                  border: "2px solid #0FF",
                  borderRadius: "50%",
                }}
              />
              <Text color="secondary" size="xl" weight="600">
                {`ApeCoin Vault`}
              </Text>
              <Flex direction="column" justifyContent="space-between" w="100%" gap="8px" h="100%">
                <Flex justifyContent="space-between" w="100%">
                  <Flex alignItems="center" gap="4px">
                    <Text size="xs" color="gray0" weight="400">{`Staked`}</Text>
                    <Tooltip>
                      <HelpCircle height={12} width={12} color={theme.colors.gray0} />
                      <TooltipText showArrow position="top" top="-75px" right="-72.5px" style={{ width: "150px" }}>
                        <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                          <div>{`This is the total staked APE, including deposits into the vault and earned APE from NFT Staking.`}</div>
                        </Text>
                      </TooltipText>
                    </Tooltip>
                  </Flex>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(bigNumToFloat(userBalance.vaultBalance), 2)} APE
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`24hr withdraws`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(bigNumToFloat(withdrawals24hr), 2)} APE
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" w="100%">
                  {" "}
                  <Flex alignItems="center" gap="4px">
                    <Text size="xs" color="gray0" weight="400">{`24hr change`}</Text>
                    <Tooltip>
                      <HelpCircle height={12} width={12} color={theme.colors.gray0} />
                      <TooltipText showArrow position="top" top="-62.5px" right="-72.5px" style={{ width: "150px" }}>
                        <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                          <div>{`This includes all deposits, withdrawals, and APE deposited from NFT Staking.`}</div>
                        </Text>
                      </TooltipText>
                    </Tooltip>
                  </Flex>
                  <Text size="xs" color="secondary" weight="600">
                    {changes24hr > 0 && "+"}
                    {numberWithCommas(changes24hr, 2)} APE
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`Daily earn`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(bigNumToFloat(userBalance.dailyEarnApeCoin), 2)} APE
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Box w="100%" h="25px">
              <Button
                size="xxs"
                style={{
                  borderRadius: "6px",
                }}
                onClick={() => navigate(`/vault/${isProd ? "mainnet" : "sepolia"}/${apeVaultContract}`)}
              >{`Withdraw`}</Button>
            </Box>
          </VaultSection>
          <ApeCoinSection direction="column" alignItems="center" gap="1rem" pl="1rem" justifyContent="space-between">
            <Flex direction="column" alignItems="center" gap="1rem" w="100%">
              <Flex w="80px" justifyContent="center">
                <StyledImgLogo
                  src={ApeCoin}
                  alt="ApeCoin"
                  style={{
                    border: "2px solid #0FF",
                    borderRadius: "50%",
                  }}
                />
              </Flex>
              <Text color="secondary" size="xl" weight="600">
                {`ApeCoin Staking`}
              </Text>
              <Flex direction="column" justifyContent="space-between" w="100%" gap="8px">
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`Staked`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(
                      bigNumToFloat(apeCoinBalance.stakedAmount.add(apeCoinBalance.stakedAmountCyan)),
                      2,
                    )}{" "}
                    APE
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`Voting Power`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(bigNumToFloat(apeCoinBalance.stakedAmount), 2)} APE
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`24hr change`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {!activeApeCoinPlan ||
                    !activeApeCoinPlan.amount ||
                    dayjs(activeApeCoinPlan.createdAt).isBefore(dayjs().subtract(24, "hour"))
                      ? "0.00"
                      : numberWithCommas(bigNumToFloat(BigNumber.from(activeApeCoinPlan.amount)), 2)}{" "}
                    APE
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`Daily reward`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(
                      bigNumToFloat(apeCoinBalance.earnedAmount.add(apeCoinBalance.earnedAmountCyan)),
                      2,
                    )}{" "}
                    APE
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Box w="100%" h="25px">
              <Button
                size="xxs"
                style={{
                  borderRadius: "6px",
                }}
                onClick={() => {
                  setModalContent({
                    title: `ApeCoin Auto-Compounding`,
                    content: <ApeCoinOnlyStakingModal action={IActionType.unstake} />,
                  });
                }}
                disabled={!isApeCoinStakingPossible(chainId)}
              >{`Unstake`}</Button>
            </Box>
          </ApeCoinSection>
          <NftSection direction="column" alignItems="center" gap="1rem" pl="1rem" justifyContent="space-between">
            <Flex direction="column" alignItems="center" gap="1rem" w="100%">
              <Flex w="80px">
                <StyledImgLogo
                  src={Bayc}
                  alt="BAYC"
                  style={{
                    zIndex: "3",
                  }}
                />
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <StyledImgLogo
                    src={Mayc}
                    alt="MAYC"
                    style={{
                      left: "-13px",
                      position: "absolute",
                      zIndex: "2",
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <StyledImgLogo
                    src={Bakc}
                    alt="BAKC"
                    style={{
                      left: "8.5px",
                      position: "absolute",
                      zIndex: "1",
                    }}
                  />
                </div>
              </Flex>
              <Text color="secondary" size="xl" weight="600">
                {`NFT Staking`}
              </Text>
              <Flex direction="column" justifyContent="space-between" w="100%" gap="8px">
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`Deposited`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(bigNumToFloat(userOwnedStakeAmount), 2)} APE
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`FREE borrow`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(bigNumToFloat(borrowedApe), 2)} APE
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`Staked Total`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(bigNumToFloat(userBalance.totalStaked), 2)} APE
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" w="100%">
                  <Text size="xs" color="gray0" weight="400">{`Rewarded*`}</Text>
                  <Text size="xs" color="secondary" weight="600">
                    {numberWithCommas(accruedApe, 2)} APE
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Text size="xxs" color="gray0" weight="400" style={{ marginBottom: "-2px" }}>
              *Rewarded APE is automatically staked into the ApeCoin Vault, and will show on the left as{" "}
              <Text size="xxs" color="gray0" weight="700">
                Staked
              </Text>
              .{" "}
              <Text
                size="xxs"
                color="gray0"
                weight="400"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => jumpToLink("https://docs.usecyan.com/docs/staking-apecoin")}
              >
                Read more.
              </Text>
            </Text>
          </NftSection>
        </Container>
      </Flex>
    </Card>
  );
};

const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    display: flex;
    flex-direction: column;
  }
`;

const VaultSection = styled(Flex)`
  border-right: 1px solid ${({ theme }) => theme.colors.gray20};
  padding-right: 1rem;
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    border: none;
    padding: 0;
  }
`;

const ApeCoinSection = styled(Flex)`
  border-right: 1px solid ${({ theme }) => theme.colors.gray20};
  padding-right: 1rem;
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    border: none;
    padding: 0;
  }
`;

const NftSection = styled(Flex)`
  padding-left: 1rem;
  @media only screen and (max-width: ${breakpoints.mobile}px) {
    padding: 0;
  }
`;

const Card = styled(Box)`
  display: flex;
  gap: 1.7rem;
  flex-direction: column;
  justify-content: space-between;
  border-style: solid;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -khtml-border-radius: 20px;
  -moz-border-radius: 20px;
  background: ${({ theme }) => theme.colors.gray10};
  padding: 0.6rem 0.8em 0.8rem;
  transition: 0.2s;
  border-width: ${({ theme }) => theme.borderWidth};
  border-color: ${({ theme }) => theme.colors.gray20};
  grid-column: 1 / span 3;
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  border-radius: 50%;
`;
const StyledImgLogo = styled.img`
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  max-height: 36px;
  max-width: 36px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  background-color: black;
`;
