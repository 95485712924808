import React, { useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Box } from "../../Box";
import { Flex } from "../../Flex";
import { Text } from "../core";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { CloseX, MeatballMenu } from "../icons";

export const NftLinkHelperMenu = ({ link, market = "OpenSea" }: { link: string; market?: string }) => {
  const [open, setOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const theme = useTheme();
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setOpen(false));
  const copyLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
      setOpen(false);
    }, 300);
  };
  return (
    <div style={{ position: "relative" }}>
      <StyledButton
        onClick={e => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        {open ? (
          <CloseX color={theme.colors.gray0} height={15} width={15} />
        ) : (
          <MeatballMenu color={theme.colors.gray0} height={15} width={15} />
        )}
      </StyledButton>
      {open && (
        <Menu ref={node}>
          <Flex direction="column">
            <StyledButton
              role="link"
              onClick={e => {
                e.stopPropagation();
                window.open(link, "_blank");
              }}
            >
              <Text size="md" weight="600" textWrap={false} color="secondary">
                {`Jump to ${market}`}
              </Text>
            </StyledButton>
            <Box p="0.5rem" />
            <StyledButton onClick={copyLink}>
              {!isCopied ? (
                <Text size="md" weight="600" textWrap={false} color="secondary">
                  {`Copy link`}
                </Text>
              ) : (
                <Text size="md" weight="600" textWrap={false} color="secondary">
                  {`Copied!`}
                </Text>
              )}
            </StyledButton>
          </Flex>
        </Menu>
      )}
    </div>
  );
};

const StyledButton = styled.button`
  border: none;
  outline: none;
  height: 100%;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
const Menu = styled.div`
  position: absolute;
  right: 0.1rem;
  top: -90px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: ${({ theme }) => theme.borderWidth};
  border-color: ${({ theme }) => theme.colors.gray0};
  border-style: solid;
  padding: 0.8rem 0.5rem;
`;
