import * as Sentry from "@sentry/react";
import { Web3WalletTypes } from "@walletconnect/web3wallet";
import { FC, createContext, useContext, useEffect, useState } from "react";

import { EIP155_SIGNING_METHODS, useCyanWallet } from "@usecyan/cyan-wallet";

import { Modal } from "@cyanco/components/theme/v3";

import { useWeb3React } from "@/components/Web3ReactProvider";

import { useToggleModal } from "../../state/application/hooks";
import { ApplicationModal } from "../../state/application/reducer";
import { BlockedFunction, ErrorType } from "./BlockedFunction";

interface IWalletContext {
  showAccountMenu: boolean;
  setShowAccountMenu: (isOpen: boolean) => void;
  focusOnCyanWalletByDefault: boolean;
  setFocusOnCyanWalletByDefault: (isOpen: boolean) => void;
  toggleAccountMenu: () => void;
}

const WalletContext = createContext<IWalletContext>({
  showAccountMenu: false,
  setShowAccountMenu() {},
  focusOnCyanWalletByDefault: false,
  setFocusOnCyanWalletByDefault() {},
  toggleAccountMenu() {},
});

export const WalletContextProvider: FC = ({ children }) => {
  const toggleWalletConnectModal = useToggleModal(ApplicationModal.WALLET);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [focusOnCyanWalletByDefault, setFocusOnCyanWalletByDefault] = useState(false);
  const [blockedFunctionError, setBlockedFunctionError] = useState<null | ErrorType>(null);
  const cyanWallet = useCyanWallet();
  const { account, signer } = useWeb3React();

  const toggleAccountMenu = () => {
    if (!showAccountMenu) {
      if (!account) {
        toggleWalletConnectModal();
        return;
      }
      setShowAccountMenu(true);
    }
  };
  useEffect(() => {
    if (!cyanWallet) return;
    const onSessionProposal = async (_request: Web3WalletTypes.SessionProposal) => {
      console.log("onSessionProposal", _request);
      if (!showAccountMenu) toggleAccountMenu();
    };

    const onSessionRequest = async (requestEvent: Web3WalletTypes.SessionRequest) => {
      console.log("requestEvent", requestEvent);
      const {
        params: { request },
      } = requestEvent;

      switch (request.method) {
        case EIP155_SIGNING_METHODS.ETH_SIGN:
        case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
          return await cyanWallet.approveSignRequest(requestEvent);

        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
          return cyanWallet.approveSignTypedDataRequest(requestEvent);

        case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
          return await cyanWallet.sendTransaction(requestEvent);

        case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
          return await cyanWallet.signTransaction(requestEvent);

        case "wallet_switchEthereumChain": // OpenSea switch chain method
          return await signer?.provider.send("wallet_switchEthereumChain", request.params);

        default:
          console.error("Unsupported session request:", requestEvent);
          Sentry.captureException(`Unsupported session request: ${request.method}`);
      }
    };

    (async () => {
      cyanWallet.addEventHandler("session_proposal", onSessionProposal);
      cyanWallet.addEventHandler("session_request", onSessionRequest);
    })();

    return () => {
      cyanWallet.removeEventHandler("session_proposal", onSessionProposal);
      cyanWallet.removeEventHandler("session_request", onSessionRequest);
    };
  }, [cyanWallet]);

  return (
    <WalletContext.Provider
      value={{
        showAccountMenu,
        setShowAccountMenu,
        focusOnCyanWalletByDefault,
        setFocusOnCyanWalletByDefault,
        toggleAccountMenu,
      }}
    >
      {children}
      {blockedFunctionError && (
        <Modal
          isOpen={blockedFunctionError !== null}
          unsetModal={() => {
            setBlockedFunctionError(null);
          }}
          canBack={false}
          backAction={() => {}}
          title={`Wallets`}
        >
          <BlockedFunction
            onClose={() => {
              setBlockedFunctionError(null);
            }}
            errorType={blockedFunctionError}
          />
        </Modal>
      )}
    </WalletContext.Provider>
  );
};

export const useWalletContext = () => {
  return useContext(WalletContext);
};
