import orderBy from "lodash.orderby";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Box, Flex, Option, Select } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { ICollectionBe } from "@/apis/collection/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { shortenName } from "@/utils";

import { useBnplSupportedCollections } from "../../BnplDataContext";
import { CollectionDetailSection } from "./CollectionInfoSection";
import { CollectionLoanOptions } from "./CollectionLoanOptions";

export const LoanPricer = () => {
  const { chainId } = useWeb3React();
  const [collection, setSelectedCollection] = useState<ICollectionBe | null>(null);
  const { collections } = useBnplSupportedCollections();
  const filteredCollections = useMemo(
    () => collections.filter(_collection => _collection.chainId === chainId),
    [collections, chainId],
  );
  useEffect(() => {
    if (filteredCollections.length > 0) setSelectedCollection(filteredCollections[0]);
  }, [filteredCollections]);

  return (
    <Container>
      <Flex direction="column" w="100%" gap="3rem">
        <SelectBox h="46px">
          <Select
            onChange={value => {
              setSelectedCollection(filteredCollections.find(_collection => _collection.address === value) ?? null);
            }}
            value={collection?.address ?? ""}
          >
            {orderBy(filteredCollections, [_collection => _collection.name.toLowerCase()]).map(_collection => (
              <Option value={_collection.address} key={_collection.address}>
                {shortenName(_collection.name, 20, 17, 0)}
              </Option>
            ))}
          </Select>
        </SelectBox>
        {collection && <CollectionDetailSection collection={collection} />}
        {collection && <CollectionLoanOptions collection={collection} />}
      </Flex>
    </Container>
  );
};

const Container = styled(Flex)`
  gap: 1.5rem;
  flex-direction: column;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])}
`;

const SelectBox = styled(Box)`
  width: 50%;
  ${getStyleWithMediaQuery("width", "", [{ [breakpoints.tablet]: "100%" }])}
`;
