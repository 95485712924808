import React from "react";
import styled from "styled-components";

// eslint-disable-next-line
declare let window: any;

interface AddTokenToWalletButtonProps {
  tokenContractAddress: string;
  tokenType: "ERC20" | "ERC721";
  image: string;
  decimals: number;
  symbol: string;
}

export const AddTokenToWalletButton: React.FC<AddTokenToWalletButtonProps> = ({
  children,
  tokenContractAddress,
  tokenType,
  image,
  decimals,
  symbol,
}) => {
  const handleAddTokenToWallet = () => {
    if (!!window.ethereum) {
      window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: tokenType,
          options: {
            address: tokenContractAddress,
            symbol: symbol,
            decimals: decimals,
            image: image,
          },
        },
      });
    }
  };
  return <StyledButton onClick={handleAddTokenToWallet}>{children}</StyledButton>;
};

const StyledButton = styled.button`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${props => props.theme.colors.secondary};
  border-style: ${({ theme }) => (theme.theme == "dark" ? "none" : "solid")};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.2rem 0.3rem;
  height: fit-content;
  cursor: pointer;
`;
