import { UAuthConnector } from "@uauth/web3-react";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { Web3ReactHooks, initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect-v2";

import CYAN_LOGO_URL from "../assets/images/logo.png";
import { DAPP_URL, INFURA_KEY, UNSTOPPABLE_CLIENT_ID } from "../config";
import { CYAN_SUPPORTED_CHAIN_IDS, SupportedChainId } from "../constants/chains";

const NETWORK_URLS: { [key in typeof CYAN_SUPPORTED_CHAIN_IDS[number]]: string } = {
  [SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.SEPOLIA]: `https://sepolia.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.CURTIS]: `https://curtis.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.POLYGON]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.BLAST]: `https://blast-mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.BLAST_SEPOLIA]: `https://blast-sepolia.infura.io/v3/${INFURA_KEY}`,
};

export const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>(actions => new MetaMask({ actions }));
export const [walletConnect, walletConnectHooks] = initializeConnector<WalletConnect>(
  actions =>
    new WalletConnect({
      actions,
      options: {
        projectId: process.env.REACT_APP_WC_PROJECT_ID || "",
        chains: [SupportedChainId.MAINNET],
        optionalChains: [
          SupportedChainId.MAINNET,
          SupportedChainId.POLYGON,
          SupportedChainId.SEPOLIA,
          SupportedChainId.KOVAN,
          SupportedChainId.BLAST,
          SupportedChainId.BLAST_SEPOLIA,
          SupportedChainId.CURTIS,
          SupportedChainId.APECHAIN,
        ],
        showQrModal: true,
      },
    }),
);
export const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  actions =>
    new CoinbaseWallet({
      actions,
      options: {
        url: NETWORK_URLS[SupportedChainId.MAINNET],
        appName: "Cyan",
        appLogoUrl: CYAN_LOGO_URL,
      },
    }),
);

export const [uauthConnect, uauthHook] = initializeConnector(
  actions =>
    new UAuthConnector({
      actions,
      options: {
        clientID: UNSTOPPABLE_CLIENT_ID || "",
        redirectUri: DAPP_URL,
        scope: "openid wallet",
        shouldLoginWithRedirect: false,
        connectors: { injected: metaMask, walletconnect: walletConnect },
      },
    }),
);

export const connectors: [MetaMask | WalletConnect | CoinbaseWallet | UAuthConnector, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks],
  [uauthConnect, uauthHook],
];
