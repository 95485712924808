import { BigNumber } from "ethers";
import { useMemo } from "react";

import { Flex } from "@cyanco/components/theme/Flex";
import { NftMetadataInline, NftMetadataInlineImage } from "@cyanco/components/theme/v3";

import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { getInterestRate } from "@/components/PlanCreation/utils";
import {
  PositionCompleted,
  PositionCompletedEarly,
  PositionPaymentMade,
  PositionPaymentMissed,
  TransactionDetail,
} from "@/components/PlanPayment/PlanStatuses";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { useGetUserTime } from "@/hooks";
import { ICurrency } from "@/types";

import { DATE_TIME_FORMAT_OPTIONS_DAYJS } from "../../../../config";
import { bigNumToFixedStr, bigNumToFloat, getChainExplorerURL, numberWithCommas, shortenName } from "../../../../utils";
import { PaymentStatusString } from "./PaymentRow";

interface IProps {
  imageUrl: string;
  collectionName: string;
  tokenId: string;
  monthlyAmount: string;
  downpaymentAmount: string;
  loanedAmount: string;
  serviceFeeAmount: string;
  interestFeeAmount: string;
  amount: string;
  currency: ICurrency;
  paidDate: Date;
  totalNumOfPayments: number;
  currentNumOfPayments: number;
  paymentNumber: number;
  txnHash?: string;
  status: string;
  payments: { amount: string }[];
  planType: "Pawn" | "BNPL";
}

export const HistoricalPaymentModal = ({ payment }: { payment: IProps }) => {
  const {
    planType,
    monthlyAmount,
    totalNumOfPayments,
    downpaymentAmount,
    currency,
    loanedAmount,
    serviceFeeAmount,
    interestFeeAmount,
    currentNumOfPayments,
  } = payment;
  const { getUserPreferredTime } = useGetUserTime();
  const { chainId } = useWeb3React();
  const txnUrl = `${getChainExplorerURL(chainId)}/tx`;
  const isBnpl = planType === "BNPL";

  const paidDate = getUserPreferredTime(payment.paidDate).format(DATE_TIME_FORMAT_OPTIONS_DAYJS);
  const currencySymbol = currency.symbol;

  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(payment.currency.decimal) || 4;
  }, [payment.currency.decimal]);

  const totalCost = useMemo(() => {
    if (isBnpl)
      return BigNumber.from(monthlyAmount)
        .mul(totalNumOfPayments - 1)
        .add(downpaymentAmount);
    return BigNumber.from(monthlyAmount).mul(totalNumOfPayments);
  }, [isBnpl]);

  const totalPaidAmount = useMemo(() => {
    return payment.payments.reduce((acc, cur) => {
      return acc.add(cur.amount);
    }, BigNumber.from(0));
  }, [payment.payments]);

  const totalPaidAmountByPaymentNumber = useMemo(() => {
    if (payment.status === PaymentStatusString.completed || payment.status === PaymentStatusString.completedEarly) {
      return bigNumToFloat(totalPaidAmount, currency.decimal);
    }
    const total = BigNumber.from(monthlyAmount)
      .mul(
        (payment.status === PaymentStatusString.missed ? currentNumOfPayments : payment.paymentNumber) -
          (isBnpl ? 1 : 0),
      )
      .add(downpaymentAmount);
    return bigNumToFloat(total, currency.decimal);
  }, [payment, totalPaidAmount]);

  const _interestRate = useMemo(() => {
    return getInterestRate({
      payAmount: BigNumber.from(loanedAmount).add(serviceFeeAmount).add(interestFeeAmount),
      principleAmount: BigNumber.from(loanedAmount),
      decimals: currency.decimal,
    });
  }, [totalCost, currency, loanedAmount]);

  const interestFee = useMemo(() => {
    return (bigNumToFloat(payment.amount) * _interestRate) / 10_000;
  }, [payment.amount, _interestRate]);
  return (
    <Flex direction="column" gap="1rem">
      <Flex gap="10px">
        <NftMetadataInlineImage imageUrl={payment.imageUrl} />
        <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 15px 0">
          <NftMetadataInline name={payment.collectionName} value={`#${shortenName(payment.tokenId, 10, 5)}`} />
          <Flex gap="5px" direction="column" w="100%">
            <NftMetadataInline
              name={isBnpl ? `Total Purchase Amount` : `Total Cost`}
              value={`${numberWithCommas(bigNumToFloat(totalCost, currency.decimal), 4)} ${currencySymbol}`}
              sub
            />
            <NftMetadataInline
              name={`Total Paid`}
              value={`${numberWithCommas(totalPaidAmountByPaymentNumber, 4)} ${currencySymbol}`}
              sub
            />
          </Flex>
        </Flex>
      </Flex>
      {payment.status === PaymentStatusString.missed && <PositionPaymentMissed />}
      {payment.status === PaymentStatusString.paid && (
        <PositionPaymentMade link={`${txnUrl}/${payment.txnHash}`} chainId={chainId} />
      )}
      {payment.status === PaymentStatusString.completed && (
        <PositionCompleted link={`${txnUrl}/${payment.txnHash}`} chainId={chainId} />
      )}
      {payment.status === PaymentStatusString.completedEarly && (
        <PositionCompletedEarly link={`${txnUrl}/${payment.txnHash}`} chainId={chainId} />
      )}

      <Flex direction="column" w="100%">
        <TransactionDetail
          label={`Payment Amount`}
          value={`${numberWithCommas(
            bigNumToFixedStr(BigNumber.from(payment.amount), formatNumber, payment.currency.decimal),
            formatNumber,
          )} ${currencySymbol}`}
        />
        {payment.status === PaymentStatusString.completedEarly && (
          <TransactionDetail
            label={`Discounted amount`}
            value={`${numberWithCommas(
              bigNumToFloat(totalCost.sub(totalPaidAmount), currency.decimal),
              formatNumber,
            )} ${currencySymbol}`}
          />
        )}
        {(payment.status === PaymentStatusString.completedEarly ||
          payment.status === PaymentStatusString.completed) && (
          <TransactionDetail
            label={`Interest Amount`}
            value={`${numberWithCommas(interestFee, formatNumber)} ${currencySymbol}`}
            info={`(${(_interestRate / 100).toFixed(2)}%)`}
          />
        )}
        <TransactionDetail
          label={`Payment Number`}
          value={`${payment.paymentNumber} (out of ${payment.totalNumOfPayments})`}
        />
        <TransactionDetail label={`Payment Date`} value={paidDate} />
      </Flex>
    </Flex>
  );
};
