import { useMemo } from "react";

import { Flex } from "@cyanco/components/theme/components";
import { NotFound } from "@cyanco/components/theme/v3";

import { ICollectionBe } from "@/apis/collection/types";

import { useP2PLoanOffers } from "../../LendDataContext";
import { useLendSelectors } from "../../LendPageContext";
import {
  Header,
  HeaderWrapper,
  ListContainer,
  ListViewBox,
  NftContainer,
  StyledListViewBox,
} from "../Assets/ListedNfts";
import { LoanOfferHeader, LoanOfferRow, LoanOfferRowLoading } from "./LoanOfferRow";

export const LoanOffers = ({
  collection,
  blockchainTimestamp,
}: {
  collection: ICollectionBe;
  blockchainTimestamp: number;
}) => {
  const { selectedTokenId } = useLendSelectors();
  const { loanOffersLoading, loanOffers } = useP2PLoanOffers();
  const listedNftFiltered = useMemo(() => {
    return loanOffers.filter(offer => {
      if (selectedTokenId) return offer.tokenId != null && offer.tokenId.startsWith(selectedTokenId) && offer.isActive;
      return offer.isActive;
    });
  }, [loanOffers, selectedTokenId]);

  return (
    <ListContainer>
      <StyledListViewBox>
        <Flex direction="row" w="100%" gap="2rem">
          <ListViewBox>
            <Header>
              <HeaderWrapper>
                <LoanOfferHeader />
              </HeaderWrapper>
            </Header>

            {loanOffersLoading ? (
              <div>
                {Array.from(Array(4).keys()).map(loader => (
                  <LoanOfferRowLoading key={loader} />
                ))}
              </div>
            ) : (
              <div
                style={{
                  width: "calc(100% - 2px)",
                }}
              >
                {listedNftFiltered.length === 0 ? (
                  <NotFound msg={`No availabe NFT found.`} />
                ) : (
                  <NftContainer role="grid">
                    {listedNftFiltered.map(offer => (
                      <LoanOfferRow
                        offer={offer}
                        key={`${offer.id}`}
                        collection={collection}
                        blockchainTimestamp={blockchainTimestamp}
                      />
                    ))}
                  </NftContainer>
                )}
              </div>
            )}
          </ListViewBox>
        </Flex>
      </StyledListViewBox>
    </ListContainer>
  );
};
