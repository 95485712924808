import {
  FallbackProvider,
  FallbackProviderConfig,
  JsonRpcProvider,
  JsonRpcSigner,
  Web3Provider,
} from "@ethersproject/providers";
import { Web3ContextType, useWeb3React as useWeb3ReactOrig } from "@web3-react/core";
import { createContext, useContext, useMemo } from "react";

import { isProd } from "@/config";
import { SupportedChainId } from "@/constants/chains";
import { getJsonRpcProvider } from "@/utils";

const Web3Context = createContext<
  | (Web3ContextType<FallbackProvider | JsonRpcProvider | Web3Provider> & {
      signer?: JsonRpcSigner;
      chainId: number;
    })
  | undefined
>(undefined);

export const Web3ReactProvider = ({ children }: { children: JSX.Element }) => {
  const { chainId: chainIdOrig, provider: providerOrig, account, ...rest } = useWeb3ReactOrig();

  const chainId = useMemo(() => {
    return chainIdOrig ?? (isProd ? SupportedChainId.MAINNET : SupportedChainId.SEPOLIA);
  }, [chainIdOrig, isProd]);

  const rpcProvider = useMemo(() => getJsonRpcProvider(chainId), [chainId]);

  const provider = useMemo(() => {
    const providerConfigs: FallbackProviderConfig[] = [];
    if (providerOrig) {
      providerConfigs.push({
        provider: providerOrig,
        priority: 2,
      });
    }
    if (!providerOrig && rpcProvider) {
      providerConfigs.push({
        provider: rpcProvider,
        priority: 1,
        stallTimeout: 1000,
      });
    }
    return new FallbackProvider(providerConfigs);
  }, [providerOrig, rpcProvider]);

  const signer = providerOrig?.getSigner();
  return (
    <Web3Context.Provider
      value={{
        account,
        ...rest,
        chainId,
        signer,
        provider,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3React = () => {
  const context = useContext(Web3Context);
  if (!context) throw Error("useWeb3React can only be used within the Web3ReactProvider component");
  return context;
};
