import { useEffect, useMemo, useState } from "react";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { ApeCoinBalanceLoading, CloseButton } from "@/components/ApeCoinStaking/CommonComponents";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS, apePaymentPlanContract } from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IInitiateBorrowedApeUnstake } from "@/hooks/useApePlanCreation.types";
import { bigNumToFixedStr, getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";

export const UnstakingWithApePlan = (params: IInitiateBorrowedApeUnstake) => {
  const { selectedMainNft } = params;
  const { setFireConfetti } = useAppContext();
  const { transactions, addTransaction } = useTransactionContext();
  const { updateUserBalance } = useApeCoinStatsContext();
  const { showPlanModal } = useApePlanCreation();
  const { chainId, signer, account } = useWeb3React();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<UnstakingSteps>(UnstakingSteps.Unstaking);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        updateUserBalance();
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setFireConfetti(true);
        setSelectedStep(UnstakingSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const completePlan = async () => {
    if (!signer || !selectedMainNft.apeStaking.plan) throw new Error("Something went wrong");
    const contract = f.CyanApeCoinPlanV1Factory.connect(apePaymentPlanContract, signer);
    const tx = await contract.complete(selectedMainNft.apeStaking.plan.planId);
    addTransaction({
      hash: tx.hash,
      type: "ape-plan-complete",
      data: {
        planId: selectedMainNft.apeStaking.plan.planId,
        tokenId: selectedMainNft.tokenId,
        contractAddress: selectedMainNft.address,
      },
    });
    setActiveTx(tx.hash);
  };
  useEffect(() => {
    const onStepChange = async (step: UnstakingSteps) => {
      try {
        switch (step) {
          case UnstakingSteps.Unstaking: {
            await completePlan();
            return;
          }
        }
      } catch (err: any) {
        openRepaymentModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    showPlanModal({
      ...params,
      err: mappedError,
    });
  };
  const stepMarkFiltered = useMemo(() => {
    return UnstakingStepMarks.map(item => {
      if (item.value === UnstakingSteps.Unstaking && txnFinal !== null) {
        return {
          ...item,
          description: `View on Etherscan`,
        };
      }
      return item;
    });
  }, [txnFinal]);
  return (
    <Flex gap="1rem" direction="column">
      <ApeCoinBalanceLoading
        tokenId={selectedMainNft.tokenId}
        collectionName={COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS[selectedMainNft.address.toLowerCase()]}
        stakingAmount={
          selectedMainNft.apeStaking.stakedAmount ? bigNumToFixedStr(selectedMainNft.apeStaking.stakedAmount) : "0"
        }
        imageUrl={selectedMainNft.imageUrl}
        isLoading={selectedStep != UnstakingSteps.Done}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep == UnstakingSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum UnstakingSteps {
  Unstaking = 1,
  Done = 2,
}

const UnstakingStepMarks = [
  {
    value: UnstakingSteps.Unstaking,
    title: `Unstaking APE Coin`,
    description: `A small amount of gas is required to unstake $APE`,
  },
];
