import { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme";
import { Input, SlashButton, SlashText } from "@cyanco/components/theme/v3";
import { Search } from "@cyanco/components/theme/v3/icons";

import { useDebounce } from "../../../../hooks/useDebounce";
import {
  AccountPageTabs,
  LendTabData,
  WalletSelectedViews,
  useAccountPageContext,
  useLendingTabContext,
  usePositionsTabContext,
  useVaultTokensTabContext,
  useWalletTabContext,
} from "../../AccountPageContext";

export const SearchInput = () => {
  const theme = useTheme();
  const { selectedTab } = useAccountPageContext();
  const [tokenInput, setTokenInput] = useState<string>("");
  const [nftInput, setNftInput] = useState<string>("");
  const [positionInput, setPositionInput] = useState<string>("");
  const [vaultInput, setVaultInput] = useState<string>("");
  const [lendingInput, setLendingInput] = useState<string>("");
  const { setSelectedPositionSearch } = usePositionsTabContext();
  const { setSelectedNftSearch, setSelectedTokenSearch, selectedWalletView } = useWalletTabContext();
  const { setSelectedVaultSearch } = useVaultTokensTabContext();
  const { selectedLendingTabData, setSelectedLendingSearch } = useLendingTabContext();
  const searchInput = useRef<HTMLInputElement>(null);
  useDebounce(() => setSelectedNftSearch(nftInput), [nftInput], 600);
  useDebounce(() => setSelectedTokenSearch(tokenInput), [tokenInput], 600);
  useDebounce(() => setSelectedPositionSearch(positionInput), [positionInput], 600);
  useDebounce(() => setSelectedLendingSearch(lendingInput), [lendingInput], 600);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "k") {
        searchInput.current?.focus();
      }
      if (e.key === "Escape") {
        searchInput.current?.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [searchInput]);
  useDebounce(() => setSelectedVaultSearch(vaultInput), [vaultInput], 600);
  return (
    <>
      {selectedTab === AccountPageTabs.wallets && selectedWalletView === WalletSelectedViews.nfts && (
        <Box h="48px">
          <Input
            placeholder={`Search assets`}
            fontSize="lg"
            value={nftInput}
            onChange={e => setNftInput(e.target.value)}
            fontWeight="400"
            icon={<Search color={theme.colors.gray0} />}
            p="0.45rem"
            inputRef={searchInput}
          >
            <SlashButton isBigger>
              <SlashText size="xs" weight="500">
                {"⌘+k"}
              </SlashText>
            </SlashButton>
          </Input>
        </Box>
      )}
      {selectedTab === AccountPageTabs.wallets && selectedWalletView === WalletSelectedViews.tokens && (
        <Box h="48px">
          <Input
            placeholder={`Search assets`}
            fontSize="lg"
            fontWeight="400"
            value={tokenInput}
            onChange={e => setTokenInput(e.target.value)}
            icon={<Search color={theme.colors.gray0} />}
            p="0.45rem"
            inputRef={searchInput}
          >
            <SlashButton isBigger>
              <SlashText size="xs" weight="500">
                {"⌘+k"}
              </SlashText>
            </SlashButton>
          </Input>
        </Box>
      )}
      {selectedTab === AccountPageTabs.positions && (
        <Box h="48px">
          <Input
            placeholder={`Search assets`}
            fontSize="lg"
            fontWeight="400"
            value={positionInput}
            onChange={e => setPositionInput(e.target.value)}
            icon={<Search color={theme.colors.gray0} />}
            p="0.45rem"
            inputRef={searchInput}
          >
            <SlashButton isBigger>
              <SlashText size="xs" weight="500">
                {"⌘+k"}
              </SlashText>
            </SlashButton>
          </Input>
        </Box>
      )}
      {selectedTab === AccountPageTabs.lending && selectedLendingTabData === LendTabData.p2p && (
        <Box h="48px">
          <Input
            placeholder={`Search assets`}
            fontSize="lg"
            fontWeight="400"
            value={lendingInput}
            onChange={e => setLendingInput(e.target.value)}
            icon={<Search color={theme.colors.gray0} />}
            p="0.45rem"
            inputRef={searchInput}
          >
            <SlashButton isBigger>
              <SlashText size="xs" weight="500">
                {"⌘+k"}
              </SlashText>
            </SlashButton>
          </Input>
        </Box>
      )}
      {(selectedTab === AccountPageTabs.vault_tokens ||
        (selectedTab === AccountPageTabs.lending && selectedLendingTabData === LendTabData.vault)) && (
        <Box h="48px">
          <Input
            placeholder={`Search assets`}
            fontSize="lg"
            fontWeight="400"
            value={vaultInput}
            onChange={e => setVaultInput(e.target.value)}
            icon={<Search color={theme.colors.gray0} />}
            p="0.45rem"
            inputRef={searchInput}
          >
            <SlashButton isBigger>
              <SlashText size="xs" weight="500">
                {"⌘+k"}
              </SlashText>
            </SlashButton>
          </Input>
        </Box>
      )}
    </>
  );
};
