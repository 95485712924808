import styled from "styled-components";

interface IBoxProps {
  p?: string | Array<string>;
  pl?: string;
  pt?: string;
  pr?: string;
  pb?: string;

  m?: string | Array<string>;
  ml?: string;
  mt?: string;
  mr?: string;
  mb?: string;

  b?: string;
  bl?: string;
  bt?: string;
  br?: string;
  bb?: string;

  h?: string;
  w?: string;
}
export const Box = styled.div<IBoxProps>`
  padding: ${({ p }) => (typeof p === "string" ? p : p?.join(" "))};
  padding-left: ${({ pl }) => pl};
  padding-top: ${({ pt }) => pt};
  padding-right: ${({ pr }) => pr};
  padding-bottom: ${({ pb }) => pb};

  margin: ${({ m }) => (typeof m === "string" ? m : m?.join(" "))};
  margin-left: ${({ ml }) => ml};
  margin-top: ${({ mt }) => mt};
  margin-right: ${({ mr }) => mr};
  margin-bottom: ${({ mb }) => mb};

  border: ${({ b }) => b};
  border-left: ${({ bl }) => bl};
  border-top: ${({ bt }) => bt};
  border-right: ${({ br }) => br};
  border-bottom: ${({ bb }) => bb};

  height: ${({ h }) => h};
  width: ${({ w }) => w};
`;
