import { Box, Flex } from "@cyanco/components/theme/components";
import { Button, Text } from "@cyanco/components/theme/v3";

export const MarketNotice = ({ marketName, onClick }: { marketName: string; onClick: () => void }) => {
  return (
    <Flex direction="column">
      <Box mb="0.5rem">
        <Text size="sm" weight="500" color="secondary">
          {`We are currently working hard to implement ${marketName}! Please bare with us as we work on the final touches to fully integrate the marketplace.`}
        </Text>
      </Box>
      <Box mb="1rem">
        <Text size="sm" weight="500" color="secondary">
          {`We thank you for your understanding`}.
        </Text>
      </Box>
      <Box h="50px">
        <Button title={`Close`} onClick={onClick}>
          <Text size="md" weight="700" color="black">
            {`Close`}
          </Text>
        </Button>
      </Box>
    </Flex>
  );
};
