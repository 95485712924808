import dayjs from "dayjs";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, SkeletonLine } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IP2PUserCreatedOffer, IPeerPlan, PeerPlanStatuses, isPeerPlan } from "@/apis/p2p/types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { useSortableHeader } from "@/hooks/useSortableHeader";
import { bigNumToFloat, displayInUSD, numberWithCommas, shortenAddress, shortenName } from "@/utils";

import { useAppContext } from "../../../AppContextProvider";
import { HEADER_ROW_MIN_HEIGHT } from "../../consts";
import { PayButton, RowText, StyledImg } from "./PlanListRow";

export const HistoryHeader = ({
  totalItems,
  sortedPlans,
  updateSortedPlans,
}: {
  totalItems: number;
  sortedPlans: (IP2PUserCreatedOffer | IPeerPlan)[];
  updateSortedPlans: (plans: (IP2PUserCreatedOffer | IPeerPlan)[]) => void;
}) => {
  const { sort } = useSortableHeader<IP2PUserCreatedOffer | IPeerPlan>([c => isPeerPlan(c.tokenId)], "type");

  const handleHeaderClick = (value: ((item: IP2PUserCreatedOffer | IPeerPlan) => any)[]) => {
    const sortedData = sort(value, sortedPlans);
    updateSortedPlans(sortedData);
  };
  return (
    <HeaderContainer>
      <RowText weight="600" color="secondary">{`${totalItems} item${totalItems > 1 ? "s" : ""}`}</RowText>
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([p => isPeerPlan(p)])}
        >{`Type`}</RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([p => (isPeerPlan(p) ? shortenAddress(p.borrowerAddress) : "-")])}
        >{`Borrower`}</RowText>
      </Hidden>
      <RowText
        weight="600"
        color="secondary"
        onClick={() => handleHeaderClick([p => (isPeerPlan(p) ? p.loanBid.amount : p.amount)])}
      >{`Loan Amount`}</RowText>
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([p => (isPeerPlan(p) ? p.loanBid.term : p.term)])}
        >{`Duration`}</RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText
          weight="600"
          color="secondary"
          onClick={() => handleHeaderClick([p => (isPeerPlan(p) ? p.loanBid.interestRate : p.interestRate)])}
        >{`Interest`}</RowText>
      </Hidden>
      <RowText
        weight="600"
        color="secondary"
        onClick={() => handleHeaderClick([p => (isPeerPlan(p) ? p.loanBid.interestRate : p.interestRate)])}
      >{`Status`}</RowText>
      <Hidden tabletDown>
        <Flex h={HEADER_ROW_MIN_HEIGHT} />
      </Hidden>
    </HeaderContainer>
  );
};

export const HistoryRow = ({
  position,
  onClick,
}: {
  position: IPeerPlan | IP2PUserCreatedOffer;
  onClick: () => void;
}) => {
  const { usdPrice } = useAppContext();
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const isPlan = isPeerPlan(position);
  const formatNumber = useMemo(() => {
    return (
      PlanCreationDecimalFormatMap.get(isPlan ? position.loanBid.currency.decimal : position.currency.decimal) || 4
    );
  }, [isPlan]);
  const apr = useMemo(() => {
    const term = isPlan ? position.loanBid.term : position.term;
    return (365 / (term / 60 / 60 / 24)) * (term / 100);
  }, [isPlan]);
  const amount = useMemo(() => {
    return isPlan ? position.loanBid.amount : position.amount;
  }, [isPlan]);
  const currency = useMemo(() => {
    return isPlan ? position.loanBid.currency : position.currency;
  }, [isPlan]);
  return (
    <Row onClick={onClick}>
      <Flex alignItems="stretch" gap="20px">
        {imageLoading && !imageError && (
          <ImageLoader>
            <SkeletonLine w="100%" h="100%" borderRadius="10px" />
          </ImageLoader>
        )}
        {!imageError ? (
          <StyledImg
            src={position.metadata ? position.metadata.imageUrl : NoImage}
            alt={position.collection.name}
            onError={() => setImageError(true)}
            onLoad={() => setImageLoading(false)}
            style={{
              display: imageLoading ? "none" : "block",
            }}
          />
        ) : (
          <StyledImg src={NoImage} alt={"no-image"} style={{ objectFit: "contain" }} />
        )}
        {position.tokenId ? (
          <Flex gap="5px" justifyContent="space-between">
            <Flex direction="column" justifyContent="space-between">
              <RowText color={"secondary"} weight="500">
                #{shortenName(position.tokenId, 10, 6)}
              </RowText>
              <RowText sub color={"gray0"}>
                {shortenName(position.collection.name)}
              </RowText>
            </Flex>
          </Flex>
        ) : (
          <Flex gap="5px" justifyContent="space-between">
            <Flex direction="column" justifyContent="space-between">
              <RowText color={"secondary"} weight="500">
                {`Collection Offer`}
              </RowText>
              <RowText sub color={"gray0"}>
                {shortenName(position.collection.name)}
              </RowText>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Hidden tabletDown>
        <RowText color={"secondary"} weight="500">
          {isPlan ? `Loan` : `Offer`}
        </RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText color={"secondary"} weight="500">
          {isPlan ? shortenAddress(position.borrowerAddress) : "-"}
        </RowText>
      </Hidden>

      <Flex direction="column" justifyContent="center" gap="0.2rem">
        <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
          {numberWithCommas(bigNumToFloat(amount, currency.decimal), formatNumber)} {currency.symbol}
        </RowText>
        <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
          {displayInUSD(bigNumToFloat(amount, currency.decimal) * usdPrice[currency.symbol])}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="0.2rem">
          <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
            {getPaymentInterval(isPlan ? position.loanBid.term : position.term)}
          </RowText>
          <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
            {dayjs(isPlan ? position.dueDate : position.signatureExpiry).format("DD MMMM, YYYY")}
          </RowText>
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex direction="column" justifyContent="center" gap="0.2rem">
          <RowText weight="500" color={"secondary"} style={{ overflowWrap: "anywhere" }}>
            {(isPlan ? position.loanBid.interestRate : position.interestRate) / 100}%
          </RowText>
          <RowText sub style={{ overflowWrap: "anywhere" }} color="gray0">
            {`APR: ${apr.toFixed(2)}%`}
          </RowText>
        </Flex>
      </Hidden>
      {!isPlan &&
        (position.isExpired || position.signatureAvailableUsage === 0 || dayjs().isAfter(position.signatureExpiry)) && (
          <RowText weight="500" color="gray0">
            {`Offer Expired`}
          </RowText>
        )}

      {!isPlan &&
        position.signatureAvailableUsage > 0 &&
        !position.isActive &&
        dayjs().isBefore(position.signatureExpiry) && (
          <RowText weight="500" color="gray0">
            {`Offer Cancelled`}
          </RowText>
        )}

      {isPlan && position.status === PeerPlanStatuses.COMPLETED && (
        <RowText weight="500" color="cyan">
          {`Completed`}
        </RowText>
      )}
      {isPlan && position.status === PeerPlanStatuses.LIQUIDATED && (
        <RowText weight="500" color="cyan">
          {`Liquidated`}
        </RowText>
      )}
      <Hidden tabletDown>
        <Flex w="fit-content">
          {!isPlan ? (
            <PayButton
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
              size="xs"
            >
              {`Renew`}
            </PayButton>
          ) : (
            <></>
          )}
        </Flex>
      </Hidden>
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  grid-template-columns: 1.7fr 1fr 1fr 1.3fr 1.3fr 1fr 1fr 0.8fr;
  padding: 13px 15px 13px 15px;
  background-color: transparent;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2.5fr 1.3fr 1.3fr" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const ImageLoader = styled.div`
  width: 36px;
  height: 36px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 24 }])}
`;

const HeaderContainer = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    padding: 7px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
