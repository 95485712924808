import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import styled, { useTheme } from "styled-components";

import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import {
  Button,
  Card,
  CurrencyLogo,
  Flex,
  SystemMessage,
  Text,
  Toggler,
  Tooltip,
  TooltipText,
  useModal,
} from "@cyanco/components/theme";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";

import { currencyList } from "@/apis/coinbase";
import { ICollectionBe } from "@/apis/collection/types";
import { IP2PLoanOffer } from "@/apis/p2p/types";
import { IUserNft } from "@/apis/user/types";
import { useAppContext } from "@/components/AppContextProvider";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { isProd } from "@/config";
import { bigNumToFloat, displayInUSD, roundDown, shortenAddress } from "@/utils";
import { IMappedError } from "@/utils/error/msgs";

import { LoanOfferAcceptingProgress } from "./LoanOfferAcceptingProgress";
import { NftMetadata } from "./NftMetadata";

export const OfferTakerCard = ({
  collection,
  nft,
  showNft = true,
  loandData,
  error: triggeredError,
}: {
  nft?: IUserNft;
  collection: ICollectionBe;
  loandData?: IP2PLoanOffer;
  showNft?: boolean;
  error?: IMappedError;
}) => {
  const theme = useTheme();
  const { setModalContent } = useModal();
  const { usdPrice } = useAppContext();
  const [error, setError] = useState(triggeredError);

  const takeOffer = async () => {
    if (!loandData || !nft) return;
    setError(undefined);
    setModalContent({
      title: `Taking NFT Loan Offer`,
      content: <LoanOfferAcceptingProgress loanData={loandData} nft={nft} collection={collection} />,
    });
  };
  const loanAmount = useMemo(() => {
    if (!loandData) return 0;
    return bigNumToFloat(loandData.amount, loandData.currency.decimal);
  }, [loandData?.amount]);
  const repaymentAmount = useMemo(() => {
    if (!loandData) return 0;
    return roundDown(loanAmount + loanAmount * (loandData.interestRate / 10000), 6);
  }, [loandData, loanAmount]);
  const percentOfLoanAmount = useMemo(() => {
    if (!loandData) return "-";
    if (nft?.appraisalValue) {
      const loanedAmountUsd = loanAmount * usdPrice[loandData.currency.symbol];
      const usdFloorAsk = bigNumToFloat(nft.appraisalValue, nft.currency.decimal) * usdPrice[nft.currency.symbol];
      return ((loanedAmountUsd * 100) / usdFloorAsk).toFixed(0);
    }
    if (!collection.floorAsk.price) return "-";
    const loanedAmountUsd = loanAmount * usdPrice[loandData.currency.symbol];
    const usdFloorAsk =
      bigNumToFloat(collection.floorAsk.price.amount.raw) * usdPrice[collection.floorAsk.price.currency.symbol];
    return ((loanedAmountUsd * 100) / usdFloorAsk).toFixed(0);
  }, [collection.floorAsk, loanAmount, usdPrice, loandData, nft]);
  const percentOfRepaymentAmount = useMemo(() => {
    if (!loandData) return "-";
    if (nft?.appraisalValue) {
      const usdFloorAsk = bigNumToFloat(nft.appraisalValue) * usdPrice[nft.currency.symbol];
      const usdRepayment = repaymentAmount * usdPrice[loandData.currency.symbol];
      return ((usdRepayment * 100) / usdFloorAsk).toFixed(0);
    }
    if (!collection.floorAsk.price) return "-";
    const usdFloorAsk =
      bigNumToFloat(collection.floorAsk.price.amount.raw) * usdPrice[collection.floorAsk.price.currency.symbol];
    const usdRepayment = repaymentAmount * usdPrice[loandData.currency.symbol];
    return ((usdRepayment * 100) / usdFloorAsk).toFixed(0);
  }, [collection.floorAsk, repaymentAmount, usdPrice, loandData, nft]);
  const expiry = useMemo(() => {
    if (!loandData) return "-";
    const dueDate = dayjs(loandData.signatureExpiry);
    const difference = dueDate.diff(dayjs(), "second");
    return getPaymentInterval(difference);
  }, [loandData?.signatureExpiry]);
  const apr = useMemo(() => {
    if (!loandData) return "-";
    return ((365 / (loandData.term / 60 / 60 / 24)) * (loandData.interestRate / 100)).toFixed(2);
  }, [loandData?.term]);
  return (
    <Flex direction="column" gap="1.2rem">
      {nft && showNft && loandData && <NftMetadata nft={nft} lender={loandData?.lenderAddress} />}
      {error && <SystemMessage variant="error" title={error.title} msg={error.description} />}
      {/* Loan Amount */}
      <Flex direction="column" gap="0.5rem">
        <Text color="gray0" size="sm" weight="500">
          {`Principal Amount`}
        </Text>
        <Flex direction="column" gap="2px">
          <Card
            style={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
              <NumericFormat
                placeholder="0"
                thousandSeparator=","
                allowLeadingZeros={false}
                allowNegative={false}
                onValueChange={() => {}}
                value={loanAmount === 0 ? "" : loanAmount}
                customInput={StakeInput}
                decimalScale={3}
                valueIsNumericString
                disabled
              />
              <PillWrapper gap="5px" pt="0.4rem">
                <CurrencyLogo symbol={(loandData?.currency.symbol as SupportedCurrencies) ?? currencyList.weth} />
                <Text color="secondary" weight="400" size="md">
                  {loandData?.currency.symbol ?? currencyList.weth}
                </Text>
              </PillWrapper>
            </Flex>
          </Card>
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
              <Text color="gray0" size="xs" weight="400">
                {`${percentOfLoanAmount}% LTV`}
              </Text>
              <Text color="gray0" size="xs" weight="400">
                {displayInUSD(loanAmount * usdPrice[loandData?.currency.symbol ?? currencyList.weth])}
              </Text>
            </Flex>
          </Card>
        </Flex>
      </Flex>
      <Flex gap="1.2rem" direction={showNft ? "row" : "column"}>
        {/* Loan Length */}
        <Flex direction="column" gap="0.5rem">
          <Text color="gray0" size="sm" weight="500">
            {`Loan Length`}
          </Text>
          <Flex direction="column" gap="2px">
            <Card
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
                <NumericFormat
                  placeholder="0"
                  thousandSeparator=","
                  allowLeadingZeros={false}
                  allowNegative={false}
                  onValueChange={() => {}}
                  value={loandData ? (isProd ? loandData.term / 24 / 60 / 60 : loandData.term / 60) : ""}
                  customInput={StakeInput}
                  decimalScale={0}
                  valueIsNumericString
                  disabled
                />
                <Text color="secondary" size="xl" weight="400">
                  {isProd ? `days` : `mins`}
                </Text>
              </Flex>
            </Card>
            <Card
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
                <Text color="gray0" size="xs" weight="400">
                  {loandData ? getPaymentInterval(loandData.term) : "-"}
                </Text>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        {/* Loan APR */}
        <Flex direction="column" gap="0.5rem">
          <Text color="gray0" size="sm" weight="500">
            {`Interest Rate`}
          </Text>
          <Flex direction="column" gap="2px">
            <Card
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
                <NumericFormat
                  placeholder="0"
                  thousandSeparator=","
                  allowLeadingZeros={false}
                  allowNegative={false}
                  onValueChange={() => {}}
                  value={loandData ? loandData.interestRate / 100 : ""}
                  customInput={StakeInput}
                  decimalScale={2}
                  valueIsNumericString
                  disabled
                />
                <Text color="secondary" size="xl" weight="400">
                  %
                </Text>
              </Flex>
            </Card>
            <Card
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
                <Text color="gray0" size="xs" weight="400">
                  APR: {apr}%
                </Text>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Flex>

      {/* Loan Repayment */}
      <Flex direction="column" gap="0.5rem" mt="0.5rem">
        <Text color="gray0" size="sm" weight="500">
          {`Repayment Amount`}
        </Text>
        <Flex direction="column" gap="2px">
          <Card
            style={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" gap="15px" p="0.4rem 0.6rem">
              <NumericFormat
                placeholder="0"
                thousandSeparator=","
                allowLeadingZeros={false}
                allowNegative={false}
                onValueChange={() => {}}
                value={repaymentAmount}
                customInput={StakeInput}
                disabled
                valueIsNumericString
              />
              <PillWrapper gap="5px" pt="0.4rem">
                <CurrencyLogo symbol={(loandData?.currency.symbol as SupportedCurrencies) ?? currencyList.weth} />
                <Text color="secondary" weight="400" size="md">
                  {loandData?.currency.symbol ?? currencyList.weth}
                </Text>
              </PillWrapper>
            </Flex>
          </Card>
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" p="0.5rem 0.6rem">
              <Text color="gray0" size="xs" weight="400">
                {percentOfRepaymentAmount}%
              </Text>
              <Text color="gray0" size="xs" weight="400">
                {displayInUSD(repaymentAmount * usdPrice[loandData?.currency.symbol ?? currencyList.weth])}
              </Text>
            </Flex>
          </Card>
        </Flex>
      </Flex>
      {/* Togglers */}
      <Flex direction="column" gap="0.7rem">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap="4px">
            <Text size="md" weight="500" color="gray0">
              {`Auto-roll Loans`}
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.gray0} />
              <TooltipText showArrow position="top" top="-178px" right="-71px" style={{ width: "150px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`Automatically rolled loans are loans where the maturity date is renewed everytime the borrower pays back the interest by the maturity date.`}</div>
                  </Text>
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`The borrower can keep rolling the loan so long as they continue to pay the interest by the due date at every period. When the loan is fully repaid, the principal is returned.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
          </Flex>
          <Toggler value={loandData?.isExtendable ?? true} onChange={() => {}} size="sm" />
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="md" weight="500" color="gray0">
            {`Offer Expiry`}
          </Text>
          <LoanOptionPillWrapper>
            <Text color="gray0" size="sm">
              {expiry}
            </Text>
          </LoanOptionPillWrapper>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap="4px">
            <Text size="md" weight="500" color="gray0">
              {`Wallet`}
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.gray0} />
              <TooltipText showArrow position="top" top="-98px" right="-67px" style={{ width: "132px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`Select the wallet you would like the loans to be made out from. The wallet you select will be from where crypto will be withdrawn on every loan made.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>
          </Flex>
          <LoanOptionPillWrapper>
            <Text color="gray0" size="sm">
              {loandData?.lenderAddress ? `${shortenAddress(loandData?.lenderAddress, 4)}` : ""}
            </Text>
          </LoanOptionPillWrapper>
        </Flex>
      </Flex>
      <OfferButton onClick={takeOffer} disabled={!loandData && !nft}>{`Take Offer`}</OfferButton>
    </Flex>
  );
};

const PillWrapper = styled(Flex)<{ showArrow?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 30px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
  ${({ showArrow, theme }) =>
    showArrow &&
    `:after {
    content: "";
    appearance: none;
    background-image: ${
      theme.theme === "light"
        ? `url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1 1.86 4 4 4-4" /></svg>')`
        : `url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1 1.86 4 4 4-4" /></svg>')`
    };
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
  }`}
`;

const StakeInput = styled.input`
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;
  outline: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
`;
const LoanOptionPillWrapper = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray20};
  border-radius: 30px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
`;
export const OfferButton = styled(Button)`
  padding: 1rem;
  height: 50px;
`;
