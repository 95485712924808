import { BigNumber } from "ethers";

import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import { ICollectionBe } from "@/apis/collection/types";
import { useBnplQueryParams } from "@/hooks/useBnplQueryParams";
import { INftBe } from "@/types";
import { getRarityRankV3 } from "@/utils";

import { OnSaleNftCard } from "./OnSaleNftCard";
import { OnSaleNftRow } from "./OnSaleNftRow";

export const PrivateSaleItems = ({
  isGrid,
  items,
  isMultiSelectPossible,
  collection,
}: {
  isGrid: boolean;
  items: INftBe[];
  isMultiSelectPossible: boolean;
  collection: ICollectionBe;
}) => {
  const { setTokenParam } = useBnplQueryParams();

  const openPrivateSale = (nft: INftBe) => {
    setTokenParam(nft.tokenId);
  };
  return (
    <>
      {items.map(nft => {
        let item = nft;
        if (item.privateSale) {
          item = {
            ...item,
            address: item.privateSale.collectionAddress,
            price: BigNumber.from(item.privateSale.price),
            itemType: item.privateSale.tokenType,
            amount: item.privateSale.tokenAmount,
            privateSaleId: item.privateSale.id,
            marketName: SupportedMarketPlaces.CYANPRIVATESALE,
          };
        }
        return isGrid ? (
          <OnSaleNftCard
            nft={{
              ...item,
              rarity: getRarityRankV3(item.rarityRank, Number(collection.tokenCount)) ?? undefined,
            }}
            key={`${item.address}:${item.tokenId}`}
            onClick={openPrivateSale}
            isSelectToCardPossible={isMultiSelectPossible}
          />
        ) : (
          <OnSaleNftRow
            nft={item}
            key={`${item.address}:${item.tokenId}`}
            isSelectToCardPossible={isMultiSelectPossible}
            onClick={() => openPrivateSale(item)}
          />
        );
      })}
    </>
  );
};
