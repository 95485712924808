import { ethers } from "ethers";
import { useMemo } from "react";
import { useAsync } from "react-async-hook";
import styled, { useTheme } from "styled-components";

import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Flex, Text } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { NewTab } from "@cyanco/components/theme/v3/icons";

import { CHAIN_IDS_TO_EXPLORER_NAME, SupportedChainId } from "@/constants/chains";
import { displayInUSD, getChainExplorerURL, jumpToLink } from "@/utils";

import { useAppContext } from "../AppContextProvider";
import { useWeb3React } from "../Web3ReactProvider";

const GAS_UNITS = {
  bnpl_create: 248700,
  pay: 108000,
  stake: 120000,
};

export const GasTracker = () => {
  const { provider, chainId } = useWeb3React();
  const { usdPrice } = useAppContext();
  const theme = useTheme();
  const currencySymbol = chainId === SupportedChainId.POLYGON ? SupportedCurrencies.POL : SupportedCurrencies.ETH;

  const { result: gasPriceInWei = null, loading } = useAsync(async () => {
    if (!provider) return null;
    const gasPrice = await provider.getGasPrice();
    return gasPrice;
  }, [chainId, provider]);
  const gasInNative = useMemo(() => {
    const value = ethers.utils.formatEther(gasPriceInWei ?? 0);
    return Number(value);
  }, [gasPriceInWei, usdPrice]);
  if (gasPriceInWei == null && loading) return <Container />;
  if (gasPriceInWei == null) return <></>;
  return (
    <Container alignItems="center">
      <GasText size="xs" textWrap={false}>{`Gas: ${Number(ethers.utils.formatUnits(gasPriceInWei, "gwei")).toFixed(
        0,
      )} gwei`}</GasText>
      <FlyoutMenu>
        <Text color="secondary" weight="600" size="xs">{`Ethereum Gas Tracker`}</Text>
        <Flex direction="column" gap="0.5rem" mt="1.5rem" w="100%">
          <Flex justifyContent="space-between" w="100%">
            <Text color="gray0" weight="500" size="xs">{`Action`}</Text>
            <Text color="gray0" weight="500" size="xs">{`Average`}</Text>
          </Flex>
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="500">{`Cyan Pay NFT`}</Text>
            <Text color="secondary" size="xs" weight="500">
              {displayInUSD(gasInNative * GAS_UNITS.bnpl_create * usdPrice[currencySymbol])}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="500">{`Make loan payment`}</Text>
            <Text color="secondary" size="xs" weight="500">
              {displayInUSD(gasInNative * GAS_UNITS.pay * usdPrice[currencySymbol])}
            </Text>{" "}
          </Flex>
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="500">{`Stake into vault`}</Text>
            <Text color="secondary" size="xs" weight="500">
              {displayInUSD(gasInNative * GAS_UNITS.stake * usdPrice[currencySymbol])}
            </Text>{" "}
          </Flex>
        </Flex>
        <EtherscanLink
          onClick={() => {
            jumpToLink(`${getChainExplorerURL(chainId)}/gastracker#chart_gasprice`);
          }}
        >
          <Flex mt="1.5rem" justifyContent="space-between" w="100%" alignItems="center">
            <Text color="secondary" weight="500" size="xs">
              {`Official ${CHAIN_IDS_TO_EXPLORER_NAME[chainId]} Gas Tracker`}
            </Text>
            <NewTab color={theme.colors.secondary} width={12} height={12} />
          </Flex>
        </EtherscanLink>
      </FlyoutMenu>
    </Container>
  );
};

const GasText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray0};
  transition: 0.2s ease-in-out;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

const FlyoutMenu = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.primary};
  border: 0px solid ${({ theme }) => theme.colors.gray30};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 0;
  position: absolute;
  opacity: 0;
  bottom: 1.9rem;
  display: flex;
  right: 0px;
  min-width: 200px;
  max-height: 0px;
  z-index: 99;
  transition: opacity 0.5s ease-in-out, max-height 0s 0.2s ease-in-out, padding 0s 0.2s, border-width 0s 0.2s;
  :hover ${GasText} {
    color: ${({ theme }) => theme.colors.secondary};
  }
  :hover {
    opacity: 1;
    max-height: 200px;
    opacity: 1;
    padding: 13px 10px;
    border-width: 1px;
  }
`;
const Container = styled(Flex)`
  cursor: pointer;
  min-width: 70px;
  position: relative;
  :hover ${GasText} {
    color: ${({ theme }) => (theme.theme === "light" ? theme.colors.black : theme.colors.cyan)};
  }
  :hover ${FlyoutMenu} {
    max-height: 200px;
    opacity: 1;
    padding: 13px 10px;
    border-width: 1px;
  }
`;

const EtherscanLink = styled.div`
  cursor: pointer;
  width: 100%;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.8;
  }
`;
