import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { Clock, CreditCard, Send } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Button, Flex, Hidden, Text, Tooltip, TooltipText, useModal } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { PeerPlanStatuses } from "@/apis/p2p/types";
import { isUserNft } from "@/apis/user";
import { IUserNft } from "@/apis/user/types";
import { useAppContext } from "@/components/AppContextProvider";
import { IBNPL, isBnplPlan } from "@/components/Bnpl/bnpl.types";
import { PlanRepaymentModal } from "@/components/PlanPayment/PlanRepaymentModal";
import { PlanRevivalModal } from "@/components/PlanPayment/PlanRevivalModal";
import { MAX_PAWN_LIMIT, MAX_REVIVAL_LIMIT } from "@/config";
import { IPlanStatus } from "@/constants/plans";
import { usePlanCreation } from "@/hooks";
import { INftType, IPlanCreatableNft, ITEM_AMOUNT_BY_NFT_TYPE } from "@/types";
import { getRarityRank, numberWithCommas } from "@/utils";

import { useBNPLPositions, usePawnPositions, usePeerPlans, useUserAssets } from "../../AccountDataContext";
import { useSelectedItems } from "../../SelectedItemsContext";
import { IPawn, isPawnPlan } from "../../pawn.types";
import { checkIsRevivalPossible } from "../PositionView/utils";
import { UserNftTransfer } from "../WalletView/UserNftModals/UserNftTransfer";

export const UserItemsMultiSelection = () => {
  const theme = useTheme();
  const { showNewPlanModal } = usePlanCreation("pawn");
  const { collections } = useAppContext();
  const { setModalContent, unsetModal } = useModal();
  const { items, removeAll, setItems } = useSelectedItems();
  const { userAssets } = useUserAssets();
  const { activePawnPositions } = usePawnPositions();
  const { activeBnplPositions } = useBNPLPositions();
  const { userTakenPeerPlans } = usePeerPlans();
  const [showMenu, setShowMenu] = useState(false);

  const activePeerPlans = userTakenPeerPlans.filter(
    plan => plan.status === PeerPlanStatuses.ACTIVE && plan.nextPaymentDate && dayjs().isBefore(plan.nextPaymentDate),
  );
  const _userAssets = userAssets.assets.filter(item => !item.isBendDao);
  const checkAllPositions = () => {
    setItems([...activeBnplPositions, ...activePawnPositions, ...activePeerPlans]);
  };
  const checkAllUserNfts = () => {
    setItems(_userAssets.filter(asset => asset.isCyanWallet));
  };
  const checkAllItems = () => {
    setItems([...activeBnplPositions, ...activePawnPositions, ...activePeerPlans, ..._userAssets]);
  };

  const cyanWalletAssets: IUserNft[] = useMemo(() => {
    return items.filter(item => isUserNft(item) && item.isCyanWallet) as IUserNft[];
  }, [items]);

  const mainWalletAssets: IUserNft[] = useMemo(() => {
    return items.filter(item => isUserNft(item) && !item.isCyanWallet) as IUserNft[];
  }, [items]);

  // TODO bulk repayment for p2p plan
  const payablePositions: Array<IBNPL | IPawn> = useMemo(() => {
    return items.filter(
      item => (isPawnPlan(item) || isBnplPlan(item)) && item.status === IPlanStatus.Activated,
    ) as Array<IBNPL | IPawn>;
  }, [items]);

  const revivablePositions: Array<IBNPL | IPawn> = useMemo(() => {
    return items.filter(
      item =>
        (isPawnPlan(item) || isBnplPlan(item)) && item.status === IPlanStatus.Defaulted && checkIsRevivalPossible(item),
    ) as Array<IBNPL | IPawn>;
  }, [items]);

  const userNfts: IUserNft[] = useMemo(() => {
    return items.filter(
      item => isUserNft(item) && item.appraisalValue && item.currency && item.tokenType,
    ) as IUserNft[];
  }, [items]);

  const openTransferModal = () => {
    setModalContent({
      title: `Bulk Transfer NFT`,
      content: <UserNftTransfer onClose={unsetModal} nfts={cyanWalletAssets} />,
    });
  };

  const openRevivalModal = () => {
    setModalContent({
      title: `Bulk Revival`,
      content: <PlanRevivalModal onClose={unsetModal} plans={revivablePositions} />,
    });
  };
  const openPlanModal = () => {
    const planCreatableNfts: IPlanCreatableNft[] = userNfts.map(nft => {
      const rarity = getRarityRank(nft.rarityRank, nft.address, collections);
      return {
        planType: "pawn",
        address: nft.address,
        tokenId: nft.tokenId,
        collectionName: nft.collectionName,
        imageUrl: nft.imageUrl ?? null,
        price: BigNumber.from(nft.appraisalValue),
        currency: nft.currency,
        amount: ITEM_AMOUNT_BY_NFT_TYPE[nft.tokenType ?? INftType.ERC721],
        itemType: nft.tokenType ?? INftType.ERC721,
        supportedCurrencies: nft.supportedCurrencies,
        rarityStat: rarity ? `${numberWithCommas(rarity.rank)}/${numberWithCommas(rarity.total)}` : "N/A",
        isCyanWalletAsset: nft.isCyanWallet,
      };
    });
    showNewPlanModal({
      currency: {
        symbol: planCreatableNfts[0].currency.symbol,
        address: planCreatableNfts[0].currency.address,
        decimal: planCreatableNfts[0].currency.decimal,
      },
      items: planCreatableNfts,
    });
  };

  const openPaymentModal = () => {
    setModalContent({
      title: `Bulk Repayment`,
      content: <PlanRepaymentModal onClose={unsetModal} plans={payablePositions} />,
    });
  };

  return (
    <>
      <Hidden laptopSDown>
        <MultiSelectionBox show={items.length !== 0}>
          <Flex gap="1rem">
            <TextInsideButton size="lg" color="secondary" style={{ width: "78px" }}>{`${items.length} item${
              items.length > 1 ? "s" : ""
            }`}</TextInsideButton>
            <TextButton size="lg" onClick={checkAllItems}>
              Select all
            </TextButton>
            <Text size="lg" color="gray0">
              |
            </Text>
            <TextButton size="lg" onClick={checkAllUserNfts}>
              Select all transfers
            </TextButton>
            <Text size="lg" color="gray0">
              |
            </Text>
            <TextButton size="lg" onClick={checkAllPositions}>
              Select all payments
            </TextButton>
            <Text size="lg" color="gray0">
              |
            </Text>
            <TextButton size="lg" onClick={removeAll}>
              Clear
            </TextButton>
          </Flex>
          <Flex gap="1rem">
            <Tooltip>
              <SecondaryButton disabled={cyanWalletAssets.length === 0} onClick={openTransferModal}>
                <Flex gap="7px" alignItems="center">
                  <Send color={theme.colors.secondary} size={20} />
                  {cyanWalletAssets.length > 0 ? (
                    <TextInsideButton color="secondary" size="lg" weight="600" textWrap={false}>
                      {`Transfer ${cyanWalletAssets.length} asset${cyanWalletAssets.length > 1 ? "s" : ""}`}
                    </TextInsideButton>
                  ) : (
                    mainWalletAssets.length > 0 && (
                      <TextInsideButton color="secondary" size="lg" weight="600" textWrap={false}>
                        {`Transfer ${mainWalletAssets.length} asset${mainWalletAssets.length > 1 ? "s" : ""}`}
                      </TextInsideButton>
                    )
                  )}
                </Flex>
              </SecondaryButton>{" "}
              <TooltipText showArrow position="top" top="-70px" right="25px" style={{ width: "180px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="sm" color="primary" weight="500" lineHeight={14}>
                    <div>{`For transferring multiple NFTs, make sure they are from the Cyan Wallet.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            </Tooltip>

            <SecondaryButton disabled={payablePositions.length === 0} onClick={openPaymentModal}>
              <Flex gap="7px" alignItems="center">
                <CreditCard color={theme.colors.secondary} size={20} />
                <TextInsideButton color="secondary" size="lg" weight="600" textWrap={false}>
                  {`Make ${payablePositions.length} payment${payablePositions.length > 1 ? "s" : ""}`}
                </TextInsideButton>
              </Flex>
            </SecondaryButton>
            <Tooltip>
              <StyledButton
                disabled={userNfts.length > MAX_PAWN_LIMIT || userNfts.length === 0}
                onClick={openPlanModal}
              >
                <Flex gap="7px" alignItems="center">
                  <Clock color={theme.colors.black} size={20} />
                  <TextInsideButton color="black" size="lg" weight="600" textWrap={false}>
                    {`Get ${userNfts.length} instant loan${userNfts.length > 1 ? "s" : ""}`}
                  </TextInsideButton>
                </Flex>
              </StyledButton>
              {(userNfts.length > MAX_PAWN_LIMIT || userNfts.length === 0) && (
                <TooltipText showArrow position="top" top="-70px" right="25px" style={{ width: "180px" }}>
                  <Flex direction="column" gap="7px">
                    <Text size="sm" color="primary" weight="500" lineHeight={14}>
                      <div>{`A maximum of ${MAX_PAWN_LIMIT} NFTs can be bulk loaned at a time.`}</div>
                    </Text>
                  </Flex>
                </TooltipText>
              )}
            </Tooltip>
            <Tooltip>
              <StyledButton
                disabled={revivablePositions.length > MAX_REVIVAL_LIMIT || revivablePositions.length === 0}
                onClick={openRevivalModal}
              >
                <Flex gap="7px" alignItems="center">
                  <Clock color={theme.colors.black} size={20} />
                  <TextInsideButton color="black" size="lg" weight="600" textWrap={false}>
                    {`Revive ${revivablePositions.length} loan${revivablePositions.length > 1 ? "s" : ""}`}
                  </TextInsideButton>
                </Flex>
              </StyledButton>
              {(revivablePositions.length > MAX_REVIVAL_LIMIT || revivablePositions.length === 0) && (
                <TooltipText showArrow position="top" top="-70px" right="25px" style={{ width: "180px" }}>
                  <Flex direction="column" gap="7px">
                    <Text size="sm" color="primary" weight="500" lineHeight={14}>
                      <div>{`A maximum of ${MAX_REVIVAL_LIMIT} loans can be bulk revived at a time.`}</div>
                    </Text>
                  </Flex>
                </TooltipText>
              )}
            </Tooltip>
          </Flex>
        </MultiSelectionBox>
      </Hidden>
      <Hidden laptopSUp>
        <MultiSelectionBox show={items.length !== 0}>
          <MobileMenuWrapper>
            <MoreOptions
              style={{ padding: "6px 12px", background: showMenu ? "#2C2C2C" : "#6C6C6C" }}
              onClick={() => setShowMenu(!showMenu)}
            >
              <Text color="white" size="xxs" weight="600" textWrap={false}>
                {`More options`}
              </Text>
            </MoreOptions>
            <MobileMenu direction="column" show={showMenu}>
              <Flex p="14px 26px 7px 22px" direction="column" gap="10px">
                <Flex gap="7px" alignItems="center" onClick={openTransferModal}>
                  <Send color={theme.colors.secondary} size={12} />
                  <Text color="secondary" size="xs" weight="600" textWrap={false}>
                    {`Transfer ${cyanWalletAssets.length} asset${cyanWalletAssets.length > 1 ? "s" : ""}`}
                  </Text>
                </Flex>
                <Flex gap="7px" alignItems="center" onClick={openPaymentModal}>
                  <CreditCard color={theme.colors.secondary} size={12} />
                  <Text color="secondary" size="xs" weight="600" textWrap={false}>
                    {`Make ${payablePositions.length} payment${payablePositions.length > 1 ? "s" : ""}`}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                direction="column"
                gap="10px"
                p="14px 22px"
                style={{
                  borderTop: "1px solid #2C2C2C ",
                }}
              >
                <Text
                  size="xs"
                  color="secondary"
                  weight="600"
                  textWrap={false}
                  onClick={checkAllItems}
                >{`Select all`}</Text>
                <Text
                  size="xs"
                  color="secondary"
                  textWrap={false}
                  weight="600"
                  onClick={checkAllUserNfts}
                >{`Select all transfers`}</Text>
                <Text
                  size="xs"
                  weight="600"
                  color="secondary"
                  textWrap={false}
                  onClick={checkAllPositions}
                >{`Select all payments`}</Text>
              </Flex>
            </MobileMenu>
          </MobileMenuWrapper>
          <Tooltip>
            <Button
              disabled={userNfts.length > MAX_PAWN_LIMIT || userNfts.length === 0}
              onClick={openPlanModal}
              style={{ padding: "6px 12px" }}
            >
              <Flex gap="7px" alignItems="center">
                <Clock color={theme.colors.black} size={10} />
                <Text color="black" size="xxs" weight="600" textWrap={false}>
                  {`Get ${userNfts.length} instant loan${userNfts.length > 1 ? "s" : ""}`}
                </Text>
              </Flex>
            </Button>
            {(userNfts.length > MAX_PAWN_LIMIT || userNfts.length === 0) && (
              <TooltipText showArrow position="top" top="-70px" right="25px" style={{ width: "180px" }}>
                <Flex direction="column" gap="7px">
                  <Text size="sm" color="primary" weight="500" lineHeight={14}>
                    <div>{`A maximum of ${MAX_PAWN_LIMIT} NFTs can be bulk loaned at a time.`}</div>
                  </Text>
                </Flex>
              </TooltipText>
            )}
          </Tooltip>
        </MultiSelectionBox>
      </Hidden>
    </>
  );
};

const MultiSelectionBox = styled.div<{ show: boolean }>`
  z-index: 70;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
  background: ${({ theme }) => theme.backgroundColor};
  position: fixed;
  bottom: 0;
  width: calc(100vw - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  @media only screen and (max-width: ${breakpoints.laptopS}px) {
    position: fixed;
    width: calc(100% - 1rem);
    padding: 4px 0.5rem;
    height: ${({ show }) => (show ? "47px" : "0px")};
    bottom: ${({ show }) => (show ? "0px" : "-30px")};
  }
  transition: 0.5s;
  height: ${({ show }) => (show ? "100px" : "0px")};
  bottom: ${({ show }) => (show ? "0px" : "-30px")};
`;

const TextButton = styled(Text)`
  transition: 0.2s;
  cursor: pointer;
  font-size: 18px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptopL]: 14 }])};
  color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.cyan : theme.colors.gray90)};
  :hover {
    color: ${({ theme }) => (theme.theme == "dark" ? "#79ffff" : "black")};
  }
`;

const TextInsideButton = styled(Text)`
  font-size: 18px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.laptopL]: 14 }])};
`;

const StyledButton = styled(Button)`
  height: 50px;
  padding-right: 20px;
  padding-left: 20px;
  ${getStyleWithMediaQuery("padding-left", "px", [{ [breakpoints.laptopL]: 10 }])};
  ${getStyleWithMediaQuery("padding-right", "px", [{ [breakpoints.laptopL]: 10 }])};
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.laptopL]: 40 }])};
`;

const SecondaryButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.gray20};
  border-color: ${({ theme }) => theme.colors.gray20};
  :hover {
    opacity: 0.8;
    background-color: ${({ theme }) => theme.colors.gray20};
    border-color: ${({ theme }) => theme.colors.gray20};
  }
`;

const MobileMenuWrapper = styled.div`
  position: relative;
`;
const MoreOptions = styled(Button)`
  border-color: ${({ theme }) => theme.colors.gray0};
  background: ${({ theme }) => theme.colors.gray0};
  :hover {
    border-color: ${({ theme }) => theme.colors.gray0};
    background: ${({ theme }) => theme.colors.gray0};
  }
`;
const MobileMenu = styled(Flex)<{ show: boolean }>`
  z-index: 10 !important;
  position: absolute;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  background: ${({ theme }) => theme.colors.primary};
  bottom: calc(100% + 15px);
  gap: 10px;
  display: ${({ show }) => (show ? "flex" : "none")};
`;
