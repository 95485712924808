import styled from "styled-components";

import { Flex } from "../../Flex";
import { breakpoints, getStyleWithMediaQuery } from "../../config";
import { Text } from "../core";

export const TitleContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem);
  ${getStyleWithMediaQuery("align-items", "", [{ [breakpoints.tablet]: "center" }])}
  padding: 2rem 0;
`;

export const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 65px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 65 },
    { [breakpoints.laptopM]: 45 },
    { [breakpoints.tablet]: 24 },
  ])};
  font-weight: 600;
`;

export const SubTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 36px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 36 }, { [breakpoints.laptop]: 24 }])};
  font-weight: 500;
`;

export const TitleDescription = styled(Text)`
  font-weight: 400;
  font-size: 18px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 18 },
    { [breakpoints.laptopM]: 16 },
    { [breakpoints.tablet]: 14 },
  ])};
`;
