import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { Card, Flex, Text } from "@cyanco/components/theme";
import { StyledProjectLink } from "@cyanco/components/theme/components";
import { NewTab } from "@cyanco/components/theme/v3/icons";

import { IPeerPlan, PeerPlanPaymentNumber } from "@/apis/p2p/types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { bigNumToFixedStr, getGoogleCalendarLinkForP2P, numberWithCommas } from "@/utils";

import { IPlanData } from ".";
import { DueCircle, StepperContainer } from "../RegularPayment";

export const RegularPayment: React.FC<{
  plan: IPeerPlan;
  planDataContract: IPlanData;
  isLender?: boolean;
}> = ({ plan, planDataContract, isLender }) => {
  const theme = useTheme();
  const dueForPayment = useMemo(() => {
    const difference = dayjs(planDataContract.nextPaymentDate).diff(dayjs(new Date()), "second");
    return getPaymentInterval(difference);
  }, [planDataContract]);

  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(plan.loanBid.currency.decimal) || 4;
  }, [plan.loanBid.currency.decimal]);

  const totalCost = useMemo(() => {
    const totalPayments = plan.payments.reduce((acc, cur) => acc.add(cur.amount), BigNumber.from(0));
    return totalPayments.add(planDataContract.currentPayment);
  }, [plan, planDataContract]);

  return (
    <Card p={"10px 8px"}>
      <Flex direction="column" gap="7px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="sm" weight="600" color="secondary">
            {`Payment of `}{" "}
            {`${numberWithCommas(
              bigNumToFixedStr(planDataContract.currentPayment, formatNumber, plan.loanBid.currency.decimal),
              formatNumber,
            )} ${plan.loanBid.currency.symbol}`}
          </Text>
          {!isLender && (
            <StyledProjectLink
              onClick={() => getGoogleCalendarLinkForP2P(plan)}
              target="_blank"
              key="calendar-link"
              style={{ gap: "5px", margin: 0, alignItems: "center" }}
            >
              <StyledText size="xs" color="gray0">
                {`Add to Calendar`}
              </StyledText>
              <NewTab height="12px" width="12px" color={theme.colors.gray0} />
            </StyledProjectLink>
          )}
        </Flex>
        <StepperContainer>
          {plan.payments
            .filter(payment => payment.paymentNumber === PeerPlanPaymentNumber.EXTENDED)
            .map(payment => (
              <Flex justifyContent="space-between" key={`${payment.txnHash}`} alignItems="center">
                <Flex alignItems="center" gap="8px">
                  <DueCircle isPaid />
                  <Text size="xs" weight="500" color="gray0">
                    {`extended`}
                  </Text>
                </Flex>
                <Text size="xs" weight="500" color="gray0">
                  {`${numberWithCommas(
                    bigNumToFixedStr(payment.amount, formatNumber, plan.loanBid.currency.decimal),
                    formatNumber,
                  )} ${plan.loanBid.currency.symbol}`}
                </Text>
              </Flex>
            ))}
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" gap="8px">
              <DueCircle isLast={true} isNextPayment={true} />
              <Text size="xs" weight="500" color="secondary">
                {`Due in ${dueForPayment}`}
              </Text>
            </Flex>
            <Text size="xs" weight="500" color="secondary">
              {`${numberWithCommas(
                bigNumToFixedStr(planDataContract.currentPayment, formatNumber, plan.loanBid.currency.decimal),
                formatNumber,
              )} ${plan.loanBid.currency.symbol}`}
            </Text>
          </Flex>
        </StepperContainer>
        <Flex justifyContent="space-between">
          <Text size="xs" weight="500" color="gray0">
            {`${plan.loanBid.interestRate / 100}% interest`}
          </Text>
          <Text size="xs" weight="500" color="gray0">
            {`Total Cost ${numberWithCommas(
              bigNumToFixedStr(totalCost, formatNumber, plan.loanBid.currency.decimal),
              formatNumber,
            )} ${plan.loanBid.currency.symbol}`}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

const StyledText = styled(Text)`
  text-align: right;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    color: cyan;
  }
`;
