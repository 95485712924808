import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { SubTitle } from "@cyanco/components/theme/v3";
import { CyanLogo } from "@cyanco/components/theme/v3/icons";

import { ICollectionBe } from "@/apis/collection/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS } from "@/constants/chains";

import {
  CollectionBannerImage,
  CollectionDescription,
  CollectionDetailSubText,
  CollectionInfoContainer,
  CollectionInfoTitleContainer,
  CollectionStatsGapWrapper,
  CollectionTitleWrapper,
  Gapper,
} from "../Bnpl/components/CollectionDetail";

export const BargainCollectionDetailSection: React.FC<{
  collection: ICollectionBe;
}> = ({ collection }) => {
  const { chainId } = useWeb3React();
  return (
    <Container>
      <CollectionBannerImage src={collection?.banner} />
      <CollectionInfoContainer direction="column">
        <CollectionInfoTitleContainer>
          <CollectionTitleWrapper alignItems="center" gap="15px">
            <CollectionLogo>
              <CyanLogo width="100%" height="100%" />
            </CollectionLogo>
            <Flex direction="column" gap="5px">
              <SubTitle>{collection.name}</SubTitle>
              <Flex gap="5px" wrap="wrap">
                <CollectionDetailSubText color="gray0">
                  {collection.tokenCount} {`items`}
                </CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">|</CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">{`0% marketplace fees`}</CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">|</CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">{`0% royalties`}</CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">|</CollectionDetailSubText>
                <CollectionDetailSubText color="gray0">
                  {CHAIN_INFO[chainId as typeof CYAN_SUPPORTED_CHAIN_IDS[number]].label || ""}
                </CollectionDetailSubText>
              </Flex>
            </Flex>
          </CollectionTitleWrapper>

          {collection.description && <CollectionDescription description={collection.description} />}
        </CollectionInfoTitleContainer>
        {!collection.description ? <CollectionStatsGapWrapper /> : <Gapper />}
      </CollectionInfoContainer>
    </Container>
  );
};

const Container = styled.div`
  posiion: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CollectionLogo = styled.div`
  border-radius: 50%;
  width: 75px;
  height: 75px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.tablet]: 65 }, { [breakpoints.mobile]: 50 }])};
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 65 }, { [breakpoints.mobile]: 50 }])};
`;
