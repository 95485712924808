import { BigNumber } from "ethers";

export type IUserBalance = {
  apeBalance: BigNumber;
  vaultBalance: BigNumber;
  totalStaked: BigNumber;
  dailyEarnApeCoin: BigNumber;
  helpers: {
    tokenPrice: BigNumber;
  };
};
export type IPoolInfo = {
  rewardsPerDay: number;
  apy: number;
  totalStakedAmountOnCyan: number;
  totalStakedAmount: number;
};
export type IPools = {
  MAYC: IPoolInfo;
  BAYC: IPoolInfo;
  BAKC: IPoolInfo;
  COIN: IPoolInfo;
};
export type IApeVault = {
  totalStaked: number;
  totalStakedOnCyan: number;
  cap: number;
  // horizen apr
  apr: {
    MAYC: number;
    BAYC: number;
    BAKC: number;
    COIN: number;
  };
};
export const poolInfoInitialState = {
  rewardsPerDay: 0,
  apy: 0,
  totalStakedAmountOnCyan: 0,
  totalStakedAmount: 0,
};
export const apeVaultInitialState = {
  totalStaked: 0,
  totalStakedOnCyan: 0,
  cap: 0,
  apr: {
    MAYC: 0,
    BAYC: 0,
    BAKC: 0,
    COIN: 0,
  },
};

export interface IApeCoinContext {
  userBalance: IUserBalance;
  poolsWithBorrow: IPools;
  poolsWithoutBorrow: IPools;
  apeVault: IApeVault;
  updateUserBalance: () => Promise<void>;
}
