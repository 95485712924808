import styled from "styled-components";

import { Button, Flex, TitleContainer, TitleDescription } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { CYAN_POINTS_DOC_LINK } from "@/config";
import { jumpToLink } from "@/utils";

import { ButtonText, HomeTitleWebkit } from ".";

export const MainTitle = ({ toLeaderboard }: { toLeaderboard: () => void }) => {
  return (
    <TopPageContainer direction="column" alignItems="center">
      <TitleContainer alignItems="center" style={{ paddingBottom: 0 }}>
        <HomeTitleWebkit>
          Cyan Points <br /> Season 1
        </HomeTitleWebkit>
      </TitleContainer>
      <TitleDescription style={{ color: "#9C9C9C" }}>Started March 1st, 2024</TitleDescription>
      <ButtonWrapper gap="20px">
        <Flex w="100%">
          <Button title={`Get started`} style={{ padding: "12px", borderRadius: "30px" }} onClick={toLeaderboard}>
            <ButtonText>{`Leaderboard`}</ButtonText>
          </Button>
        </Flex>
        <Flex w="100%">
          <Button
            title={`Read Docs`}
            style={{ padding: "12px", borderRadius: "30px", background: "#00C2FF", border: "none" }}
            onClick={() => {
              jumpToLink(CYAN_POINTS_DOC_LINK);
            }}
          >
            <ButtonText>{`Read Docs`}</ButtonText>
          </Button>
        </Flex>
      </ButtonWrapper>
    </TopPageContainer>
  );
};

const TopPageContainer = styled(Flex)`
  gap: 2rem;
  ${getStyleWithMediaQuery("gap", "", [{ [breakpoints.tablet]: "1rem" }])};
  text-align: center;
`;

const ButtonWrapper = styled(Flex)`
  width: 620px;
  padding-top: 0.5rem;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.tablet]: 300 }])};
`;
