import { useAsyncCallback } from "react-async-hook";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled from "styled-components";

import { Button, Flex, Loader, Text, useModal } from "@cyanco/components/theme";
import { CyanLogo } from "@cyanco/components/theme/v3/icons";

import { jumpToLink, shortenAddress } from "@/utils";

import { useAuthContext } from "./AuthContextProvider";

export const AcceptModal = ({ wallet, onClose }: { wallet: string; onClose: () => void }) => {
  const { signInWallet } = useAuthContext();
  const { unsetModal } = useModal();
  const { execute, loading } = useAsyncCallback(async () => {
    await signInWallet();
    unsetModal();
  });

  return (
    <Flex direction="column" w="100%" alignItems="center" gap="2rem">
      <Flex p="2rem" alignItems="center" direction="column" gap="2rem">
        <CyanLogo width={125} height={125} />
        <Text color="secondary" size="xxl" weight="700">
          Welcome to Cyan
        </Text>
        <Flex alignItems="center" gap="10px">
          <Jazzicon seed={jsNumberForAddress(wallet)} diameter={30} />
          <Text color="secondary" weight="500" size="xl">
            {shortenAddress(wallet)}
          </Text>
        </Flex>
      </Flex>
      <Text color="secondary" textAlign="center" size="md">
        By connecting your wallet and using Cyan, you agree to our{" "}
        <span
          style={{
            color: "cyan",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => jumpToLink("https://docs.usecyan.com/docs/terms-of-service")}
        >
          Terms of Service
        </span>
        .
      </Text>
      <Flex w="100%" gap="1rem">
        <CancelButton
          onClick={() => {
            onClose();
            unsetModal();
          }}
        >
          <Text size="md" weight="600" color="black">
            Cancel
          </Text>
        </CancelButton>
        <AccepButton size="md" onClick={execute}>
          {loading ? (
            <Loader size="16px" />
          ) : (
            <Text size="md" weight="600" color="black">
              Accept and Sign
            </Text>
          )}
        </AccepButton>
      </Flex>
    </Flex>
  );
};

const CancelButton = styled(Button)`
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.gray20};
  border-color: ${({ theme }) => theme.colors.gray20};
  height: 50px;
  :hover {
    background: ${({ theme }) => theme.colors.gray30};
    border-color: ${({ theme }) => theme.colors.gray30};
  }
`;

const AccepButton = styled(Button)`
  border-radius: 10px;
  height: 50px;
`;
