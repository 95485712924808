import { BigNumber } from "ethers";

import { axios } from "./axios";

export interface ICyanStats {
  userCount: number;
  numOfBorrowersWithActiveLoan: number;
  loanedAmountBnplsUsd: BigNumber;
  loanedAmountPawnsUsd: BigNumber;
  totalStakedNftAmount: BigNumber;
  loanCount24h: number;
  loanAmount24h: BigNumber;
}
export const getCyanStats = async (): Promise<ICyanStats> => {
  const { data } = await axios.get("/v2/stats");
  return {
    ...data,
    loanedAmountBnplsUsd: BigNumber.from(data.loanedAmountBnplsUsd),
    loanedAmountPawnsUsd: BigNumber.from(data.loanedAmountPawnsUsd),
    totalStakedNftAmount: BigNumber.from(data.totalStakedNftAmount),
    loanAmount24h: BigNumber.from(data.loanAmount24h),
  };
};
