import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex, Hidden, useModal } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { getSignedUser, sendUserLog } from "@/apis/user";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { DAPP_URL, isDev, isProd } from "@/config";
import { SupportedChainId } from "@/constants/chains";
import { IsInTestDrive } from "@/utils";

import { useAppContext } from "../AppContextProvider";
import { AcceptModal } from "../AuthContext/AcceptSignModal";
import { useAuthContext } from "../AuthContext/AuthContextProvider";
import { CollectionSearch } from "../Bnpl/components/AvailableCollections/CollectionSelectors";
import { PageNavigation } from "./Navigation";
import { NetworkInfo } from "./NetworkInfo";
import NetworkSelector from "./NetworkSelector";
import { UserPoint } from "./UserPoint";
import Web3Status from "./Web3StatusInner";

export enum NetworkStatus {
  CORRECT = "correct-chain",
  SWITCH_MAINNET = "switch-mainnet",
  SWITCH_SEPOLIA = "switch-sepolia",
  SWITCH_BLAST = "switch-blast",
  SWITCH_BLAST_SEPOLIA = "switch-blast-sepolia",
  SWITCH_CURTIS = "switch-curtis",
  NOT_SUPPORTED = "not-supported",
}
export const Header = () => {
  const navigate = useNavigate();
  const { user, setUser } = useAuthContext();
  const { setModalContent } = useModal();
  const { chainId, account } = useWeb3React();
  const [showWrongNetwork, setShowWrongNetwork] = useState(true);
  const { experiment } = useAppContext();

  const networkStatus = useMemo(() => {
    setShowWrongNetwork(true);
    switch (chainId) {
      case SupportedChainId.MAINNET:
        return isProd ? NetworkStatus.CORRECT : NetworkStatus.SWITCH_MAINNET;

      case SupportedChainId.POLYGON:
        return isProd ? NetworkStatus.CORRECT : NetworkStatus.NOT_SUPPORTED;

      case SupportedChainId.SEPOLIA:
        return !isProd || isDev ? NetworkStatus.CORRECT : NetworkStatus.SWITCH_SEPOLIA;

      case SupportedChainId.BLAST:
        return isProd ? NetworkStatus.CORRECT : NetworkStatus.SWITCH_BLAST;

      case SupportedChainId.BLAST_SEPOLIA:
        return !isProd || isDev ? NetworkStatus.CORRECT : NetworkStatus.SWITCH_BLAST_SEPOLIA;

      case SupportedChainId.CURTIS:
        return !isProd || isDev ? NetworkStatus.CORRECT : NetworkStatus.SWITCH_CURTIS;

      default:
        return NetworkStatus.NOT_SUPPORTED;
    }
  }, [chainId, experiment]);

  useEffect(() => {
    if (!account) return;
    const getUser = async () => {
      const currentDate = dayjs();
      let lastVisited = user?.lastVisited;
      if (
        !user?.lastVisited ||
        currentDate.diff(user.lastVisited, "day") > 0 ||
        !currentDate.isSame(user.lastVisited, "day")
      ) {
        sendUserLog({ wallet: account });
        setUser({ ...user, lastVisited: currentDate });
        lastVisited = currentDate;
      }
      if (!user || !user?.isSigned) {
        const { isSigned } = await getSignedUser({ wallet: account });
        if (isSigned) {
          setUser({
            ...user,
            isSigned,
            lastVisited,
          });
          return;
        }
        setModalContent({
          title: "",
          content: (
            <AcceptModal
              wallet={account}
              onClose={() => {
                setUser({
                  ...user,
                  isSigned: false,
                  lastVisited,
                });
              }}
            />
          ),
        });
      }
    };
    getUser();
  }, [account]);

  return (
    <div
      style={{
        marginBottom: networkStatus !== NetworkStatus.CORRECT && showWrongNetwork ? "120.5px" : "85px",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 71,
        }}
      >
        {networkStatus !== NetworkStatus.CORRECT && showWrongNetwork && (
          <NetworkInfo setShowWrongNetwork={setShowWrongNetwork} networkStatus={networkStatus} />
        )}
        <Container isInHomePage={true}>
          <StyledNavLinkLogo
            id={"home-nav-link-logo"}
            $active={false}
            onClick={() => {
              if (IsInTestDrive) {
                window.location.href = DAPP_URL;
              } else {
                navigate("/");
              }
            }}
          >
            <Logo />
          </StyledNavLinkLogo>
          <Flex justifyContent="flex-start">
            <PageNavigation />
          </Flex>
          <Hidden laptopSDown style={{ paddingLeft: "1rem" }}>
            <CollectionSearch isSmall minWidth="400px" />
          </Hidden>
          <ActionContainer>
            <UserPoint />
            <NetworkSelector />
            <Web3Status />
          </ActionContainer>
        </Container>
      </div>
    </div>
  );
};

const Container = styled.div<{ isInHomePage: boolean }>`
  display: grid;
  grid-template-columns: ${({ isInHomePage }) => (isInHomePage ? "0.2fr 1.2fr 1fr 1.5fr;" : "1.5fr 3fr 1.5fr")};
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 75px;
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 0 18px;
  width: calc(100% - 36px);
  ${getStyleWithMediaQuery("height", "px", [
    {
      [breakpoints.tablet]: 65,
    },
  ])}

  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - 36px)" },
    {
      [breakpoints.tablet]: "calc(100% - 1rem - 10px)",
    },
  ])}
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0 calc(0.5rem + 5px)" },
  ])}
  @media only screen and (max-width: 1240px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  top: 0;
  margin: 0;
  padding: 0.4em;

  @media only screen and (max-width: 1080px) {
    padding: 0rem;
  }
`;

const Logo = styled.p`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: flex-start;

  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  transition: 0.4s;
  font-family: Syne;
  font-weight: 800;
  font-size: 28px;
  color: ${({ theme }) => theme.colors.logo};
  :after {
    content: "cyan";
    text-transform: uppercase;
    ${getStyleWithMediaQuery("content", "", [
      {
        [breakpoints.tablet]: "'c'",
      },
    ])}
  }

  &:hover {
    opacity: 0.6;
  }
`;

export const MenuButtonContainer = styled.div<{ show: boolean }>`
  justify-self: center;
  width: fit-content;
  padding: 4px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  ${getStyleWithMediaQuery("overflow", "", [{ [breakpoints.tablet]: "visible" }])}
  @media only screen and (max-width: 1050px) {
    display: ${({ show }) => (show ? "grid" : "none")};
    flex-direction: row;
    justify-content: space-around;
    justify-self: center;
    position: fixed;
    bottom: 0;
    width: calc(100% - 8px);
    margin: 0 auto;
    left: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.gray20};
    outline: none;
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledNavLinkLogo = styled.a<{ $active: boolean }>`
  align-items: left;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, $active }) => ($active ? theme.colors.secondary : theme.colors.gray0)};
  padding: 9px 15px;
  transition: 0.2s;
`;
