import { Twitter } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, Text } from "@cyanco/components/theme/v3";
import { Discord, Github, Youtube } from "@cyanco/components/theme/v3/icons";

import { CYAN_GITHUB_LINK, CYAN_TWITTER_LINK, CYAN_YOUTUBE_LINK, DISCORD_SERVER_INVITE_LINK } from "@/config";
import { jumpToLink } from "@/utils";

import { useSelectedItems } from "../Account/SelectedItemsContext";
import { companyLinks, learnLinks, marketLinks, resourceLinks } from "../HomeTopPage/consts";
import { DiscordWidget } from "./DiscordWidget";
import { LatestArticles } from "./LatestArticles";
import { SignUp } from "./SignUp";
import { Stats } from "./Stats";

const MenuList = ({
  title,
  items,
}: {
  title: string;
  items: {
    value: string;
    link: string;
  }[];
}) => {
  const navigate = useNavigate();
  return (
    <Flex direction="column" gap="11px">
      <Text size="sm" color="secondary" weight="700">
        {title}
      </Text>
      {items.map(item => {
        return (
          <div key={item.value}>
            <TextLink
              color="secondary"
              size="sm"
              onClick={() => {
                if (item.link.startsWith("http")) {
                  jumpToLink(item.link);
                } else {
                  navigate(item.link);
                }
              }}
            >
              {item.value}
            </TextLink>
          </div>
        );
      })}
    </Flex>
  );
};

export const Footer = ({ showArticlesSection = false }: { showArticlesSection?: boolean }) => {
  const theme = useTheme();
  const { items } = useSelectedItems();
  return (
    <Container>
      <BottomContainer>
        {showArticlesSection && (
          <>
            <LatestArticles />
            <Divider />
          </>
        )}
        <StayUpdatedContainer>
          <Flex gap="2.5rem" direction="column">
            <Text color="secondary" size="xl" weight="700">
              STAY UPDATED
            </Text>
            <Text color="secondary" size="md">
              Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and tricks
              for navigating Cyan.
            </Text>
            <SignUp />
          </Flex>
          <Flex gap="2.5rem" direction="column">
            <Text color="secondary" size="xl" weight="700">
              FOLLOW
            </Text>
            <Flex gap="3rem" wrap="wrap">
              <StyledIconLink target="_blank" aria-label="Cyan Discord" href={DISCORD_SERVER_INVITE_LINK}>
                <Discord color={theme.colors.secondary} width={50} height={35} />
              </StyledIconLink>
              <StyledIconLink target="_blank" aria-label="Cyan Twitter" href={CYAN_TWITTER_LINK}>
                <Twitter color={theme.colors.secondary} fill={theme.colors.secondary} width={50} height={35} />
              </StyledIconLink>
              <StyledIconLink target="_blank" aria-label="Cyan Github" href={CYAN_GITHUB_LINK}>
                <Github color={theme.colors.secondary} width={50} height={35} />
              </StyledIconLink>
              <StyledIconLink target="_blank" aria-label="Cyan Youtube" href={CYAN_YOUTUBE_LINK}>
                <Youtube color={theme.colors.secondary} width={50} height={35} fill={theme.colors.primary} />
              </StyledIconLink>
            </Flex>
          </Flex>
        </StayUpdatedContainer>
        <Divider />
        <AdditionalInfo>
          <Flex gap="1.5rem" direction="column">
            <CyanLogo>CYAN</CyanLogo>
            <Text color="secondary" size="md">
              The world’s first Buy Now, Pay Later digital marketplace for crypto collectibles and non-fungible tokens
              (NFTs). Pay later, stake, and collateralize your digital items.
            </Text>
          </Flex>
          <MenuList title="Marketplace" items={marketLinks} />
          <Hidden tabletUp>
            <MenuList title="Resources" items={resourceLinks} />
          </Hidden>
          <Flex direction="column" gap="3rem">
            <Hidden tabletDown>
              <MenuList title="Resources" items={resourceLinks} />
            </Hidden>
            <MenuList title="Learn" items={learnLinks} />
          </Flex>
          <MenuList title="Company" items={companyLinks} />
        </AdditionalInfo>
        <TermsWrapper>
          <Text size="xs" color="gray0">
            © 2021 - 2024 | Cyan
          </Text>
          <TermsAndServiceText
            size="xs"
            color="gray0"
            onClick={() => jumpToLink("https://docs.usecyan.com/docs/terms-of-service")}
          >
            Terms of Service
          </TermsAndServiceText>
        </TermsWrapper>
      </BottomContainer>

      {items.length == 0 && (
        <>
          <Stats />
          <DiscordWidget />
        </>
      )}
    </Container>
  );
};

const StyledLink = styled.a`
  width: fit-content;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: max(1.3vw, 14px);
  letter-spacing: -0.05em;
  color: #000000;
  text-decoration: none;
  margin-bottom: 1.5rem;
  white-space: nowrap;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
const StyledIconLink = styled(StyledLink)`
  transition: 0.2s ease-in-out;
  :hover {
    opacity: 0.7;
  }
  margin-bottom: 0;
`;

const StayUpdatedContainer = styled.div`
  display: grid;
  gap: 8rem;
  grid-template-columns: 1fr 0.7fr;
  padding-bottom: 4.5rem;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 860px) {
    flex-direction: column;
    display: flex;
    padding: 0 1rem 1rem;
    gap: 2rem;
    border-top: none;
  }
`;

const TextLink = styled(Text)`
  transition: 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const Container = styled.div`
  padding: 30px calc((100% - 1240px) / 2);
  margin-top: 0px;
  gap: 100px;
  background: ${({ theme }) => (theme.theme === "dark" ? "#11141c" : "#E8E8E8")};
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.laptopM]: 5 }, { [breakpoints.tablet]: 2 }])}
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptopM]: "30px 50px" },
    { [breakpoints.tablet]: "0px 5px" },
  ])}
  padding-bottom: 70px;
`;

const BottomContainer = styled.div`
  padding: 2rem 0rem 0rem;
  justify-content: space-between;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    display: flex;
    padding: 5rem 0 0;
  }
`;

const CyanLogo = styled.p`
  color: ${({ theme }) => (theme.theme === "light" ? theme.colors.black : theme.colors.cyan)};
  font-family: Syne;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.5px;
  margin: 0;
`;

const AdditionalInfo = styled.div`
  display: grid;
  grid-template-columns: 2.2fr 1.2fr 1.4fr 0.5fr;
  gap: 1rem;
  padding-bottom: 3rem;
  justify-content: space-between;
  @media only screen and (max-width: 860px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 1rem 3rem;
    row-gap: 3rem;
    column-gap: 4rem;
    border-top: none;
  }
`;

const TermsWrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
  padding-top: 1rem;
  justify-content: space-between;
  @media only screen and (max-width: 1050px) {
    padding-bottom: 58px;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
  padding-bottom: 4.5rem;
`;

const TermsAndServiceText = styled(Text)`
  cursor: pointer;
  &:hover {
    color: white;
  }
`;
