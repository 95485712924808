import dayjs from "dayjs";
import { BigNumber, ContractTransaction } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { IOffer } from "@/apis/early-unwind/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { isProd } from "@/config";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { ICurrency } from "@/types";
import { getChainExplorerTextForTxn, getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { IPawn } from "../../Account/pawn.types";
import { IBNPL } from "../../Bnpl/bnpl.types";
import { useTransactionContext } from "../../TransactionContextProvider";
import { PlanMetadata } from "../PlanMetadata";
import { PlanRepayment } from "../PlanRepayment";

export const EARLY_UNWIND_MINUTE_THRESHOLD = isProd ? 30 : 10;

export type IPayerWallet = {
  address: string;
  nativeTokenAmount: BigNumber;
  offerTokenAmount: BigNumber;
};
type IPlan = {
  totalAmount: BigNumber;
  leftAmount: BigNumber;
  purchasedPrice?: string;
  collectionName: string;
  address: string;
  tokenId: string;
  imageUrl?: string;
  isBnpl: boolean;
  planId: number;
  currentNumOfPayments: number;
};
type IPlanRepaymentProgressProps = {
  plan: IPlan;
  pawn?: IPawn;
  bnpl?: IBNPL;
  offer: IOffer;
  currency: ICurrency;
  payment: {
    amount: BigNumber;
    dueDate: Date;
  };
  payerWallet?: IPayerWallet;
  onClose: () => void;
};

export const PlanEarlyUnwindProgress: React.FC<IPlanRepaymentProgressProps> = ({
  plan,
  currency,
  bnpl,
  pawn,
  offer,
  onClose,
  payment,
  payerWallet,
}) => {
  const { setModalContent } = useModal();
  const { chainId, signer, account } = useWeb3React();
  const { cyanWallet } = useCyanWalletContext();
  const { transactions, addTransaction } = useTransactionContext();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [selectedStep, setSelectedStep] = useState<PlanRepaymentSteps>(PlanRepaymentSteps.Transfer);
  const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
  const offerPrice = offer.price.amount;
  const offerCurrencyAddress = offer.price.currency.address;
  const isUserPaying = payment.amount.gt(offerPrice);
  const isTransferRequired =
    !!payerWallet && isUserPaying && payerWallet.address.toLowerCase() === (account ?? "").toLowerCase();

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(PlanRepaymentSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const handleCyanBidPayment = async () => {
    if (!signer || !chainId || !cyanWallet) return;
    const { isBnpl } = plan;
    let tx: ContractTransaction;
    if (isUserPaying && payerWallet && payerWallet?.nativeTokenAmount.gt(0)) {
      const depositData = getWrappedTokenDepositData(payerWallet.nativeTokenAmount);
      const cyanOfferFnDataFormatted = [paymentPlanV2, 0, offer.data];
      const encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
        [depositData, cyanOfferFnDataFormatted],
      ]);
      tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        value: payerWallet.nativeTokenAmount.toString(),
        data: encodedCyanExecuteFnData,
      });
    } else {
      tx = await signer.sendTransaction({
        to: paymentPlanV2,
        value: 0,
        data: offer.data,
      });
    }
    addTransaction({
      hash: tx.hash,
      type: isBnpl ? "bnpl-pay" : "pawn-pay",
      data: {
        planId: plan.planId,
        currentNumOfPayments: plan.currentNumOfPayments,
        tokenId: plan.tokenId,
        contractAddress: plan.address,
      },
    });
    setActiveTx(tx.hash);
  };

  const getWrappedTokenDepositData = (depositAmount: BigNumber) => {
    const iWrappedTokenFactory = f.SampleERC20WrappedTokenFactory.createInterface();
    const encodedDepositFnData = iWrappedTokenFactory.encodeFunctionData("deposit");
    const encodedDepositFnDataFormatted = [offerCurrencyAddress, depositAmount.toString(), encodedDepositFnData];
    return encodedDepositFnDataFormatted;
  };

  const transferFromMainWallet = async () => {
    if (!cyanWallet || !signer || !payerWallet) return;
    if (isTransferRequired) {
      if (payerWallet.nativeTokenAmount.gt(0)) {
        const tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          value: payerWallet.nativeTokenAmount,
        });
        await tx.wait();
      }
      if (payerWallet.offerTokenAmount.gt(0)) {
        const contractWriter = f.SampleERC20TokenFactory.connect(offerCurrencyAddress, signer);
        const tx = await contractWriter.transfer(cyanWallet.walletAddress, payerWallet.offerTokenAmount);
        await tx.wait();
      }
    }
  };

  useEffect(() => {
    if (!signer) return;
    const now = new Date();
    if (
      dayjs(payment.dueDate).isAfter(now) &&
      dayjs(payment.dueDate).diff(now, "minutes") <= EARLY_UNWIND_MINUTE_THRESHOLD
    ) {
      openRepaymentModal({
        title: `Notice`,
        msg: `The Sell NFT option is automatically turned off 30 minutes prior to the loan expiring to prevent any issues with execution.`,
      });
      return;
    }
    const onStepChange = async (step: PlanRepaymentSteps) => {
      try {
        switch (step) {
          case PlanRepaymentSteps.Transfer: {
            if (isTransferRequired) {
              await transferFromMainWallet();
            }
            setSelectedStep(PlanRepaymentSteps.Selling);
            return;
          }
          case PlanRepaymentSteps.Selling: {
            await handleCyanBidPayment();
          }
        }
      } catch (err: any) {
        openRepaymentModal(err);
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    setModalContent({
      title: `Loan Details`,
      content: (
        <PlanRepayment
          pawn={pawn}
          bnpl={bnpl}
          planType={plan.isBnpl ? "bnpl" : "pawn"}
          onClose={onClose}
          error={mappedError}
        />
      ),
    });
  };

  const stepMarks = useMemo(() => {
    return getPlanRepaymentStepMarks(chainId, isTransferRequired, offer.price.currency.symbol);
  }, [chainId, isTransferRequired]);

  return (
    <Flex gap="5x" direction="column">
      <PlanMetadata
        plans={[
          {
            ...plan,
            currency,
          },
        ]}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
    </Flex>
  );
};

enum PlanRepaymentSteps {
  Transfer = 1,
  Selling = 2,
  Done = 3,
}

const getPlanRepaymentStepMarks = (chainId: number, isUserPaying: boolean, symbol: string) => {
  const marks = [
    {
      value: PlanRepaymentSteps.Transfer,
      title: `Transferring ${symbol} to Cyan Wallet`,
      description: `Transfer required amount of tokens to the Cyan Wallet`,
    },
    {
      value: PlanRepaymentSteps.Selling,
      title: `Processing early unwind`,
      description: getChainExplorerTextForTxn(chainId),
    },
  ];
  return isUserPaying ? marks : marks.filter(i => i.value !== PlanRepaymentSteps.Transfer);
};
