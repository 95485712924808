import { RefreshCcw } from "react-feather";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Text, useModal } from "@cyanco/components/theme/v3";

import { IPrivateSale } from "@/apis/private-sale";

import { ISelectedNft } from "../Account/components/WalletView/NftCard";
import { PrivateSaleModal } from "./PrivateSaleModal";

export const PrivateSaleButtonOnNft = ({
  nft,
  privateSale,
}: {
  nft: ISelectedNft;
  privateSale?: IPrivateSale | null;
}) => {
  const { setModalContent } = useModal();

  const openPrivateSaleModal = () => {
    setModalContent({
      title: (
        <Text color="secondary" weight="700">
          {"Private Sale"}
        </Text>
      ),
      content: <PrivateSaleModal nft={nft} privateSale={privateSale} />,
    });
  };
  return (
    <PrivateSaleButton onClick={openPrivateSaleModal}>
      <Flex alignItems="center" gap="0.5rem">
        <RefreshCcw width={13} height={13} strokeWidth="3px" />
        <Text color="black" size="sm" weight="600">
          {privateSale ? `Update Private Sale` : `Private Sale`}
        </Text>
      </Flex>
    </PrivateSaleButton>
  );
};

const PrivateSaleButton = styled(Button)`
  padding: 1rem 0;
`;
