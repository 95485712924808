import { BigNumber } from "ethers";
import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { NftCardLoading, NotFound, useModal } from "@cyanco/components/theme/v3";

import { IPeerPlan } from "@/apis/p2p/types";
import { isUserNft } from "@/apis/user";
import { IUserNft } from "@/apis/user/types";
import { CYAN_TESTDRIVE_WALLET } from "@/config";
import { usePlanCreation } from "@/hooks";
import { ITEM_AMOUNT_BY_NFT_TYPE } from "@/types";
import { numberWithCommas } from "@/utils";

import { ContainerBox, ListViewBox } from "../Account";
import { AccountDataContext, filterAssets } from "../Account/AccountDataContext";
import { ISelectedNft } from "../Account/components/WalletView/NftCard";
import { UserNftRowLoading, UserNftsListHeader } from "../Account/components/WalletView/NftRow";
import { UserNftDetails } from "../Account/components/WalletView/UserNftModals/UserNftDetails";
import { UserNftTransfer } from "../Account/components/WalletView/UserNftModals/UserNftTransfer";
import { ListContainer, PawnNFTWrapper, UserAssets } from "../Account/components/WalletView/UserNfts";
import { IPawn } from "../Account/pawn.types";
import { IBNPL } from "../Bnpl/bnpl.types";
import { INFtRarity } from "../NftStatus";
import { InfiniteScroller } from "../Pagination/InfiniteScroller";
import { useWeb3React } from "../Web3ReactProvider";
import { useLoanPageSelectors, useUserAssets } from "./LoanPageProvider";

const UserNftWrapper: React.FC<{
  showByGrid: boolean;
  loading: boolean;
  totalItem: number;
  sortedAssets: IUserNft[];
  updateSortedAssets: (values: (IUserNft | IPawn | IBNPL | IPeerPlan)[]) => void;
}> = ({ children, showByGrid, loading, totalItem, sortedAssets, updateSortedAssets }) => {
  if (showByGrid) {
    return (
      <Flex direction="column" gap="10px">
        {loading ? (
          <PawnNFTWrapper>
            <InfiniteScroller hasMore={false} isGrid>
              {Array.from(Array(8).keys()).map(loader => (
                <NftCardLoading key={loader} actionText={`Loan`} />
              ))}
            </InfiniteScroller>
          </PawnNFTWrapper>
        ) : (
          <PawnNFTWrapper>{children}</PawnNFTWrapper>
        )}
      </Flex>
    );
  } else {
    return (
      <ListContainer>
        <ListViewBox>
          <Header>
            <UserNftsListHeader
              totalItem={totalItem}
              sortedAssets={sortedAssets}
              updateSortedAssets={updateSortedAssets}
            />
          </Header>
          <ContainerBox>
            {loading ? Array.from(Array(6).keys()).map(loader => <UserNftRowLoading key={loader} />) : children}
          </ContainerBox>
        </ListViewBox>
      </ListContainer>
    );
  }
};

export const NftView = () => {
  const { chainId, account } = useWeb3React();
  const { pawnPositions, bnplPositions, peerPlans } = useContext(AccountDataContext);
  const { userAssetsLoading, userAssets, hasMore, loadMoreUserAssets, demoAssets, userAssetsFiltered } =
    useUserAssets();
  const { walletViewStyle } = useLoanPageSelectors();
  const showByGrid = useMemo(() => walletViewStyle === "grid", [walletViewStyle]);
  const { showNewPlanModal } = usePlanCreation("pawn");
  const { setModalContent, unsetModal } = useModal();
  const openUserNftTransferModal = (asset: IUserNft & { rarity: INFtRarity | null }) => {
    setModalContent({
      title: `Transfer NFT`,
      content: <UserNftTransfer nfts={[asset]} onClose={unsetModal} />,
    });
  };
  const [sortedAssets, setSortedAssets] = useState<IUserNft[]>([...userAssetsFiltered]);

  useEffect(() => {
    setSortedAssets([
      ...filterAssets(userAssetsFiltered, [
        ...pawnPositions,
        ...bnplPositions,
        ...peerPlans.filter(plan => plan.borrowerAddress.toLowerCase() === account?.toLowerCase()),
      ]),
    ]);
  }, [bnplPositions, pawnPositions, peerPlans, userAssetsFiltered]);

  const updateSortedAssets = (values: (IUserNft | IPawn | IBNPL | IPeerPlan)[]) => {
    const nfts: IUserNft[] = [];
    values.forEach(e => {
      if (isUserNft(e)) nfts.push(e);
    });
    setSortedAssets([...nfts]);
  };

  const createPawn = (asset: ISelectedNft) => {
    if (!asset.appraisalValue || !asset.currency || BigNumber.from(asset.appraisalValue).isZero() || !asset.tokenType)
      return;

    showNewPlanModal({
      currency: asset.currency,
      items: [
        {
          ...asset,
          imageUrl: asset.imageUrl ?? null,
          price: BigNumber.from(asset.appraisalValue),
          currency: asset.currency,
          amount: ITEM_AMOUNT_BY_NFT_TYPE[asset.tokenType],
          itemType: asset.tokenType,
          supportedCurrencies: asset.supportedCurrencies,
          rarityStat: asset.rarity
            ? `${numberWithCommas(asset.rarity.rank)}/${numberWithCommas(asset.rarity.total)}`
            : "N/A",
          isCyanWalletAsset: asset.isCyanWallet,
        },
      ],
    }); // TODO - fix this
  };

  const openUserNftDetailsModal = (asset: ISelectedNft) => {
    setModalContent({
      title: `NFT Details`,
      content: (
        <UserNftDetails
          nft={asset}
          onNftTransfer={() => {
            openUserNftTransferModal(asset);
          }}
          onPawnNft={() => createPawn(asset)}
          onClose={unsetModal}
        />
      ),
    });
  };

  const openUserNftDetailsModalDemo = (asset: ISelectedNft) => {
    setModalContent({
      title: `NFT Details`,
      content: (
        <UserNftDetails nft={asset} onNftTransfer={() => {}} onPawnNft={() => createPawn(asset)} onClose={unsetModal} />
      ),
    });
  };
  return (
    <UserNftWrapper
      loading={userAssetsLoading}
      showByGrid={showByGrid}
      totalItem={sortedAssets.length}
      updateSortedAssets={updateSortedAssets}
      sortedAssets={sortedAssets}
    >
      {sortedAssets && (
        <>
          {!account && <NotFound msg={`Connect Wallet to see NFTs`} />}
          {sortedAssets.length === 0 && demoAssets && account && (
            <InfiniteScroller
              hasMore={false}
              loadMore={loadMoreUserAssets}
              loader={
                showByGrid ? (
                  <>
                    {Array.from(Array(8).keys()).map(loader => (
                      <NftCardLoading key={`${loader}-grid`} actionText={`Loan`} />
                    ))}
                  </>
                ) : (
                  <>
                    {Array.from(Array(6).keys()).map(loader => (
                      <UserNftRowLoading key={`${loader}-list`} />
                    ))}
                  </>
                )
              }
              isGrid={showByGrid}
            >
              <UserAssets
                assets={demoAssets}
                showByGrid={showByGrid}
                chainId={chainId}
                account={CYAN_TESTDRIVE_WALLET}
                onClick={openUserNftDetailsModalDemo}
                onClickPawn={createPawn}
                onClickTransfer={() => {}}
              />
            </InfiniteScroller>
          )}
          {userAssets.assets.length > 0 && account && (
            <InfiniteScroller
              hasMore={!!hasMore}
              loadMore={loadMoreUserAssets}
              loader={
                showByGrid ? (
                  <>
                    {Array.from(Array(8).keys()).map(loader => (
                      <NftCardLoading key={`${loader}-grid`} actionText={`Loan`} />
                    ))}
                  </>
                ) : (
                  <>
                    {Array.from(Array(6).keys()).map(loader => (
                      <UserNftRowLoading key={`${loader}-list`} />
                    ))}
                  </>
                )
              }
              isGrid={showByGrid}
            >
              <UserAssets
                assets={sortedAssets}
                showByGrid={showByGrid}
                chainId={chainId}
                account={account}
                onClick={openUserNftDetailsModal}
                onClickPawn={createPawn}
                onClickTransfer={openUserNftTransferModal}
              />
            </InfiniteScroller>
          )}
        </>
      )}
    </UserNftWrapper>
  );
};
const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: sticky;
  align-items: end;
  top: 165px;
  transform-style: preserve-3d;
  z-index: 6;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    top: 235px;
  }
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    overflow-anchor: none;
    position: absolute;
    transform: translateZ(-1px);
    height: calc(100% + 15px);
    top: -15px;
    left: -100px;
    @media only screen and (max-width: ${breakpoints.tablet}px) {
      left: -20px;
    }
    z-index: -1;
    width: 100vw;
  }
`;
