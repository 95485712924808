import { useRef, useState } from "react";
import { Filter } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Option, Text } from "@cyanco/components/theme/v3";
import { useOnClickOutside } from "@cyanco/components/theme/v3/hooks/useOnClickOutside";

import { ApeCoinPageTabs, WalletTypes, useApeCoinContext } from "../ApeCoinPageContext";
import { UserBalance } from "./UserBalance";

export const Selectors = () => {
  const { selectedTab } = useApeCoinContext();
  const navigate = useNavigate();
  return (
    <SelectorWrapper>
      <Container justifyContent="space-between">
        <TabButton active={selectedTab == ApeCoinPageTabs.pools} variant="ghost" onClick={() => navigate(`/ape-coin`)}>
          <TabText
            weight="500"
            size="lg"
            color={selectedTab === ApeCoinPageTabs.pools ? "secondary" : "gray0"}
            textWrap={false}
          >{`Pools`}</TabText>
        </TabButton>
        <TabButton
          active={selectedTab == ApeCoinPageTabs.rewards}
          variant="ghost"
          onClick={() => navigate(`/ape-coin/${ApeCoinPageTabs.rewards}`)}
        >
          <TabText
            weight="500"
            size="lg"
            color={selectedTab === ApeCoinPageTabs.rewards ? "secondary" : "gray0"}
            textWrap={false}
          >{`My Rewards`}</TabText>
        </TabButton>
        <TabButton
          active={selectedTab == ApeCoinPageTabs.history}
          variant="ghost"
          onClick={() => navigate(`/ape-coin/${ApeCoinPageTabs.history}`)}
        >
          <TabText
            weight="500"
            size="lg"
            color={selectedTab === ApeCoinPageTabs.history ? "secondary" : "gray0"}
            textWrap={false}
          >{`History`}</TabText>
        </TabButton>
      </Container>
      <UserBalance />
    </SelectorWrapper>
  );
};

export const WalletSelector = ({
  selectedWalletType,
  setSelectedWalletType,
}: {
  selectedWalletType: WalletTypes;
  setSelectedWalletType: (a: WalletTypes) => void;
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setOpen(false));
  return (
    <WalletSelectorBox>
      <WalletSelectedValue alignItems="center" gap="5px" onClick={() => setOpen(!open)}>
        <Filter color={theme.colors.secondary} size={12} />
        <Text size="xs" color="secondary">
          {WalletTypes.allWallets === selectedWalletType && `All Wallets`}
          {WalletTypes.cyanWallet === selectedWalletType && `Cyan Wallet`}
          {WalletTypes.mainWallet === selectedWalletType && `Main Wallet`}
        </Text>
      </WalletSelectedValue>
      <OptionsWrapper open={open} ref={node}>
        <Option
          value={WalletTypes.allWallets}
          isActive={WalletTypes.allWallets === selectedWalletType}
          onSelect={() => {
            setSelectedWalletType(WalletTypes.allWallets);
            setOpen(false);
          }}
        >{`All Wallets`}</Option>
        <Option
          value={WalletTypes.mainWallet}
          isActive={WalletTypes.mainWallet === selectedWalletType}
          onSelect={() => {
            setSelectedWalletType(WalletTypes.mainWallet);
            setOpen(false);
          }}
        >{`Main Wallet`}</Option>
        <Option
          value={WalletTypes.cyanWallet}
          isActive={WalletTypes.cyanWallet === selectedWalletType}
          onSelect={() => {
            setSelectedWalletType(WalletTypes.cyanWallet);
            setOpen(false);
          }}
        >{`Cyan Wallet`}</Option>
      </OptionsWrapper>
    </WalletSelectorBox>
  );
};

const SelectorWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 23px 0;
  margin-bottom: 2rem;
  justify-content: space-between;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
    padding: 10px 0;
    margin-bottom: 1rem;
  }
`;

const Container = styled(Flex)`
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: ${({ theme }) => theme.borderWidth};
  border-color: ${({ theme }) => theme.colors.gray20};
  border-style: solid;
  background-color: ${({ theme }) => theme.colors.primary};
  height: 36px;
  padding: 5px;
  gap: 0px;
  transition: border-color 0.2s ease-in-out;
  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
  }
`;

const TabText = styled(Text)`
  transition: color 0.2s ease-in-out;
  padding: 9px 12px;
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const WalletSelectorBox = styled(Flex)`
  position: relative;
`;
const WalletSelectedValue = styled(Flex)`
  padding: 3px 10px 3px 8px;
  border-radius: 100px;
  background: ${({ theme }) => (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
  transition: 0.2s;
  :hover {
    background: ${({ theme }) => theme.colors.gray20};
  }
`;
const OptionsWrapper = styled.div<{ open: boolean }>`
  position: absolute;
  max-height: 300px;
  overflow: hidden;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  background: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  border-radius: ${({ theme }) => theme.borderRadius};
  z-index: 10;
  left: 0px;
  outline: none;
  max-width: 188px;
  top: calc(100% + 0.5rem);
  width: calc(100% - 2px);
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const TabButton = styled(Button)<{ active?: boolean }>`
  border-radius: 5px;
  background-color: ${({ theme, active }) => active && (theme.theme == "light" ? "#c8c8c8" : "#2c2c2c")};
`;
