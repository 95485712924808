import orderBy from "lodash.orderby";
import { useContext, useMemo } from "react";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound } from "@cyanco/components/theme/v3";

import { ApePlanStatuses } from "@/apis/ape-plans";

import { ApeCoinDataContext } from "../../ApeCoinDataContext";
import { ContainerBox } from "../common";
import { ApePlanHistoryRow } from "./ApePlanHistory";
import { HistoryHeader, HistoryRowLoading } from "./HistoryRow";
import { Header, ListViewBox } from "./components";

const UserNftWrapper: React.FC<{ totalItems: number }> = ({ children, totalItems }) => {
  return (
    <ListContainer>
      <ListViewBox>
        <Header>
          <HistoryHeader totalItems={totalItems} />
        </Header>
        <ContainerBox>{children}</ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};

export const HistoricalPayments = ({ status }: { status?: ApePlanStatuses }) => {
  const { apePlanHistories, apePlanHistoryLoading } = useContext(ApeCoinDataContext);
  const histories = useMemo(() => {
    return orderBy(apePlanHistories, ["createdAt", "tokenId"], ["desc"]);
  }, [apePlanHistories]);

  const historiesFiltered = useMemo(() => {
    return histories
      .filter(history => !status || (status == history.status && !history.rewards))
      .sort((n1, n2) => n2.createdAt && n1.createdAt && n2.createdAt.getTime() - n1.createdAt.getTime());
  }, [status, histories]);

  return (
    <UserNftWrapper totalItems={historiesFiltered.length}>
      {apePlanHistoryLoading ? (
        <>
          {Array.from(Array(3).keys()).map(loader => (
            <HistoryRowLoading key={loader} />
          ))}
        </>
      ) : (
        <>
          {historiesFiltered.length === 0 && <NotFound msg={`No history found`} />}
          <Container role="grid" showByGrid={false}>
            <ApePlanHistoryRow histories={historiesFiltered} />
          </Container>
        </>
      )}
    </UserNftWrapper>
  );
};

const Container = styled.div<{ showByGrid: boolean }>`
  ${({ showByGrid }) => {
    if (showByGrid) {
      return ` display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  ${getStyleWithMediaQuery("grid-column-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-row-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.desktop]: "repeat(auto-fill, minmax(200px, 1fr))" },
    { [breakpoints.tablet]: "repeat(auto-fill, minmax(180px, 1fr))" },
    { [breakpoints.mobile]: "repeat(auto-fill, minmax(140px, 1fr))" },
  ])}; `;
    } else {
      return ` display: flex;
      flex-direction: column;
      width: 100%;
   @media only screen and (max-width: 414px) {
     border: none;
     border-radius: 20px;
     padding: 0;
   }
   > * {
     &:last-child {
       border-bottom-left-radius: 20px;
       border-bottom-right-radius: 20px;
     }
   }`;
    }
  }}
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;
