import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { jsNumberForAddress } from "react-jazzicon";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Button, Card, Flex, Text } from "@cyanco/components/theme";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { ICurrency, IPlanCreatableNft } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, numberWithCommas, shortenAddress } from "@/utils";

import { ItemsMetadata } from "./ItemsMetadata";
import { PlanCreationDecimalFormatMap } from "./types";

export const WalletOptionModal = ({
  items,
  mainWalletBalance,
  cyanWalletBalance,
  currency,
  payAmount,
  onNext,
  planType,
}: {
  items: IPlanCreatableNft[];
  onNext: (a: { selectedWalletAddress: string }) => void;
  mainWalletBalance: BigNumber;
  cyanWalletBalance: BigNumber;
  currency: ICurrency;
  payAmount: BigNumber;
  planType: "pawn" | "bnpl";
}) => {
  const { account } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const [selectedWallet, setSelectedWallet] = useState(account || "");
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(currency.decimal) || 4;
  }, [currency.decimal]);
  return (
    <Flex direction="column" gap="1.3rem">
      <ItemsMetadata items={items} planType={planType} />
      <Flex direction="column" gap="0.6rem">
        <Flex direction="column" gap="0.4rem">
          <Text color="gray0" size="sm" weight="600">{`Select wallet to pay with:`}</Text>
          <WalletBox isSelected={account === selectedWallet} onClick={() => setSelectedWallet(account || "")}>
            <Flex gap="10px">
              <Jazzicon seed={jsNumberForAddress(account || "")} diameter={18} />
              <Text color="secondary" size="sm" weight="500">
                {shortenAddress(account || "")}
              </Text>
            </Flex>
            <Text color="secondary">{`Balance: ${numberWithCommas(
              bigNumToFloat(mainWalletBalance, currency.decimal),
              4,
            )} ${currency.symbol}`}</Text>
          </WalletBox>
        </Flex>
        {cyanWallet && (
          <WalletBox
            isSelected={cyanWallet.walletAddress === selectedWallet}
            onClick={() => setSelectedWallet(cyanWallet.walletAddress)}
          >
            <Flex gap="10px">
              {" "}
              <div style={{ background: "cyan", width: "18px", height: "18px", borderRadius: "50%" }} />
              <Text color="secondary" size="sm" weight="500">
                {shortenAddress(cyanWallet.walletAddress || "")}
              </Text>
            </Flex>
            <Text color="secondary">{`Balance: ${numberWithCommas(
              bigNumToFloat(cyanWalletBalance, currency.decimal),
              4,
            )} ${currency.symbol}`}</Text>
          </WalletBox>
        )}
      </Flex>
      <Card p={"15px"} mt="2.5rem">
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4px" w="100%">
            <Text size="sm" weight="500" color="gray0">
              {planType === "bnpl" ? `Down payment due now:` : `Pay now`}
            </Text>
            <Text size="lg" weight="500" color="secondary">
              {`${numberWithCommas(bigNumToFixedStr(payAmount, formatNumber, currency.decimal), formatNumber)} ${
                currency.symbol
              }`}
            </Text>
          </Flex>
          <Box w="120px">
            <Button onClick={() => onNext({ selectedWalletAddress: selectedWallet })}>{`Start`}</Button>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};

const WalletBox = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.gray10};
  border: 2px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.cyan : theme.colors.gray10)};
`;
