import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke || theme.colors.primary};
  }
`;

export const Loader = ({ size = "16px", stroke, ...rest }: { size?: string; stroke?: string }) => {
  return (
    <StyledSVG viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} stroke={stroke} {...rest}>
      <path
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSVG>
  );
};

export const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonLine = styled.div<{ h?: string; w?: string; borderRadius?: string }>`
  height: ${({ h }) => h && h};
  width: ${({ w }) => w && w};
  min-width: ${({ w }) => w && w};
  min-height: ${({ h }) => h && h};
  animation: ${skeletonKeyframes} 1.5s ease-in-out infinite;
  background-color: ${({ theme }) => (theme.theme === "dark" ? theme.colors.gray10 : "#9c9c9c")};
  background-image: ${({ theme }) =>
    theme.theme === "dark"
      ? "linear-gradient(90deg, #1c1c1c33, #252525, #1c1c1c)"
      : "linear-gradient(90deg, #9c9c9c, #8c8c8c, #9c9c9c)"};
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "5px")};
`;
