import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { notifyApeVaultBalanceError } from "@/apis/notification";
import { IResponseData, createApeCoinAcceptance, priceApeCoin } from "@/apis/pricer/pricer-ape-coin";
import { ApeCoinBalanceLoading, CloseButton } from "@/components/ApeCoinStaking/CommonComponents";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import {
  ADDRESSES_MAPPED_BY_POOL_ID,
  BAKCAddress,
  CAPS_MAPPED_BY_ADDRESS,
  COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS,
  POOL_IDS_MAPPED_BY_ADDRESS,
  PoolId,
  apeCoinContract,
  apePaymentPlanContract,
  apeVaultContract,
} from "@/config";
import { signNewPlanSignature, useApePlanCreation } from "@/hooks/useApePlanCreation";
import { IInitiateBorrowedApeStake } from "@/hooks/useApePlanCreation.types";
import { useApproval } from "@/hooks/useApproval";
import { INftType } from "@/types";
import { bigNumToFloat, getChainExplorerURL } from "@/utils";
import { getVaultBalance } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";

export const StakingWithApePlan = (params: IInitiateBorrowedApeStake) => {
  const { selectedMainNft, selectedPairingNft, mainWalletAmount, cyanWalletAmount, rewardStakeToCyanVault } = params;
  const { setFireConfetti } = useAppContext();
  const { transactions, addTransaction } = useTransactionContext();
  const { cyanWallet, createNewWallet } = useCyanWalletContext();
  const { giveNftPermission } = useApproval();
  const { updateUserBalance } = useApeCoinStatsContext();
  const { showPlanModal } = useApePlanCreation();
  const { chainId, account, provider, signer } = useWeb3React();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [pricedResult, setPricedResult] = useState<IResponseData>();
  const [isWalletCreated, setIsWalletCreated] = useState(false);

  const stakingAmount = useMemo(() => {
    const cap = CAPS_MAPPED_BY_ADDRESS[selectedMainNft.address];
    if (!selectedMainNft.apeStaking.stakedAmount) return cap;
    return cap - bigNumToFloat(selectedMainNft.apeStaking.stakedAmount);
  }, [selectedMainNft]);

  const loaningAmount = useMemo(() => {
    const userOwned = ethers.utils.parseEther(
      (parseFloat(mainWalletAmount || "0") + parseFloat(cyanWalletAmount || "0")).toString(),
    );
    return ethers.utils.parseEther(stakingAmount.toString()).sub(userOwned);
  }, [stakingAmount]);
  const isTransferRequired = mainWalletAmount !== null;
  const [selectedStep, setSelectedStep] = useState<StakingSteps>(
    cyanWallet ? StakingSteps.Sign : StakingSteps.CreatingCyanWallet,
  );
  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        updateUserBalance();
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setFireConfetti(true);
        setSelectedStep(StakingSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const signPlan = async () => {
    if (!signer) throw new Error("Something went wrong");
    let result;
    if (selectedMainNft.address.toLowerCase() === BAKCAddress.toLowerCase()) {
      if (!selectedPairingNft) {
        throw new Error("Must choose NFT to pair stake with BAKC");
      }
      result = await priceApeCoin({
        items: [
          {
            poolId: POOL_IDS_MAPPED_BY_ADDRESS[selectedMainNft.address.toLowerCase()],
            tokenId: selectedMainNft.tokenId,
            amount: loaningAmount,
            mainPoolId: POOL_IDS_MAPPED_BY_ADDRESS[selectedPairingNft.address.toLowerCase()],
            mainTokenId: selectedPairingNft.tokenId,
          },
        ],
        wallet: await signer.getAddress(),
        rewardStakeToCyanVault,
      });
      if (!result[0].mainTokenId) {
        throw new Error("Something went wrong");
      }
    } else {
      result = await priceApeCoin({
        items: [
          {
            poolId: POOL_IDS_MAPPED_BY_ADDRESS[selectedMainNft.address.toLowerCase()],
            tokenId: selectedMainNft.tokenId,
            amount: loaningAmount,
          },
        ],
        wallet: await signer.getAddress(),
        rewardStakeToCyanVault,
      });
    }
    const signature = await signNewPlanSignature(signer, result);
    await createApeCoinAcceptance({
      planIds: result.map(({ planId }) => planId),
      signature,
    });
    setPricedResult(result[0]);
  };

  const createApePlan = async () => {
    if (!signer || !pricedResult) throw new Error("Something went wrong");
    const contract = f.CyanApeCoinPlanV1Factory.connect(apePaymentPlanContract, signer);
    const mainPoolId = POOL_IDS_MAPPED_BY_ADDRESS[selectedMainNft.address.toLowerCase()];
    let tx: ethers.ContractTransaction;
    if (mainPoolId === PoolId.BAKC) {
      if (!pricedResult.mainTokenId || !selectedPairingNft) {
        throw new Error("Something went wrong");
      }
      const pairingPoolId = POOL_IDS_MAPPED_BY_ADDRESS[selectedPairingNft.address.toLowerCase()];
      const createPlan =
        pairingPoolId === PoolId.BAYC ? contract.createBakcPlanWithBAYC : contract.createBakcPlanWithMAYC;
      tx = await createPlan(
        pricedResult.planId,
        pricedResult.mainTokenId,
        pricedResult.tokenId,
        pricedResult.amount,
        pricedResult.rewardStakeToCyanVault,
        pricedResult.signedBlockNumber,
        pricedResult.signature,
      );
    } else {
      const createPlan = mainPoolId === PoolId.BAYC ? contract.createBaycPlan : contract.createMaycPlan;
      tx = await createPlan(
        pricedResult.planId,
        pricedResult.tokenId,
        pricedResult.amount,
        pricedResult.rewardStakeToCyanVault,
        pricedResult.signedBlockNumber,
        pricedResult.signature,
      );
    }
    addTransaction({
      hash: tx.hash,
      type: "ape-plan-create",
      data: {
        planId: pricedResult.planId,
        tokenId: pricedResult.tokenId,
        contractAddress: ADDRESSES_MAPPED_BY_POOL_ID[pricedResult.poolId],
      },
    });
    setActiveTx(tx.hash);
  };

  const transfer = async () => {
    if (!signer || !account || !cyanWallet) return;
    if (mainWalletAmount && parseFloat(mainWalletAmount) > 0) {
      const contractWriter = f.SampleERC20TokenFactory.connect(apeCoinContract, signer);
      const tx = await contractWriter.transfer(cyanWallet.walletAddress, ethers.utils.parseEther(mainWalletAmount));
      await tx.wait();
    }
  };

  useEffect(() => {
    const onStepChange = async (step: StakingSteps) => {
      if (!chainId || !account || !signer) return;
      try {
        switch (step) {
          case StakingSteps.CreatingCyanWallet: {
            // no need to create cyan wallet here if user is not using tokens in main wallet
            const isUserUsingMainWallet = mainWalletAmount && parseFloat(mainWalletAmount) > 0;
            if (!cyanWallet && isUserUsingMainWallet) {
              await createNewWallet(signer);
              setIsWalletCreated(true);
            }
            setSelectedStep(StakingSteps.Sign);
            return;
          }
          case StakingSteps.Sign: {
            await signPlan();
            setSelectedStep(isTransferRequired ? StakingSteps.TranferApe : StakingSteps.TokenApproval);
            return;
          }
          case StakingSteps.TranferApe: {
            await transfer();
            setSelectedStep(StakingSteps.TokenApproval);
            return;
          }
          case StakingSteps.TokenApproval: {
            if (selectedMainNft.address.toLowerCase() === BAKCAddress) {
              if (!selectedPairingNft) {
                throw new Error("Must choose NFT to pair stake with BAKC");
              }
              await giveNftPermission(
                {
                  collectionAddress: selectedMainNft.address,
                  tokenId: selectedMainNft.tokenId,
                  itemType: INftType.ERC721,
                },
                apePaymentPlanContract,
              );
              await giveNftPermission(
                {
                  collectionAddress: selectedPairingNft.address,
                  tokenId: selectedPairingNft.tokenId,
                  itemType: INftType.ERC721,
                },
                apePaymentPlanContract,
              );
            } else {
              await giveNftPermission(
                {
                  collectionAddress: selectedMainNft.address,
                  tokenId: selectedMainNft.tokenId,
                  itemType: INftType.ERC721,
                },
                apePaymentPlanContract,
              );
            }
            setSelectedStep(StakingSteps.Staking);
            return;
          }
          case StakingSteps.Staking: {
            await createApePlan();
            return;
          }
        }
      } catch (err: any) {
        if (err?.error?.message?.match(/Not enough \w+ in the Vault/g)) {
          const _sendVaultError = async () => {
            if (!account || !provider) return;
            const vaultBalance = await getVaultBalance({ vaultAddress: apeVaultContract, provider });

            await notifyApeVaultBalanceError({
              requestedAmount: loaningAmount.toString(),
              vault: apeVaultContract,
              user: account,
              vaultBalance: vaultBalance.toString(),
            });
          };
          _sendVaultError().catch(e => console.error(e));
        }
        openRepaymentModal(err);
        return;
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err, account);
    showPlanModal({
      ...params,
      err: mappedError,
    });
  };
  const stepMarkFiltered = useMemo(() => {
    return StakingStepMarks.filter(item => {
      if (cyanWallet && item.value === StakingSteps.CreatingCyanWallet && !isWalletCreated) {
        return false;
      }
      if (item.value === StakingSteps.TranferApe && !isTransferRequired) {
        return false;
      }
      return true;
    }).map(item => {
      if (item.value === StakingSteps.Staking && txnFinal !== null) {
        return {
          ...item,
          description: `View on Etherscan`,
        };
      }
      return item;
    });
  }, [cyanWallet, isWalletCreated, txnFinal, isTransferRequired]);
  return (
    <Flex gap="1rem" direction="column">
      <ApeCoinBalanceLoading
        tokenId={selectedMainNft.tokenId}
        collectionName={COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS[selectedMainNft.address.toLowerCase()]}
        stakingAmount={stakingAmount.toString() ?? "0"}
        imageUrl={selectedMainNft.imageUrl}
        isLoading={selectedStep != StakingSteps.Done}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep == StakingSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum StakingSteps {
  CreatingCyanWallet = 1,
  Sign = 2,
  TranferApe = 3,
  TokenApproval = 4,
  Staking = 5,
  Done = 6,
}

const StakingStepMarks = [
  {
    value: StakingSteps.CreatingCyanWallet,
    description: `A new wallet is created on the first loan on Cyan`,
    title: `Creating Cyan Wallet`,
  },
  {
    value: StakingSteps.Sign,
    title: `User Accepts Terms & Conditions`,
    description: `Approve this in your wallet, no gas required`,
  },
  {
    value: StakingSteps.TranferApe,
    title: `Transfer $APE to Cyan Wallet`,
    description: `A small amount of gas is required to move $APE`,
  },
  {
    value: StakingSteps.TokenApproval,
    title: `NFT Approval`,
    description: `This provides Cyan with permission to move your NFT`,
  },
  {
    value: StakingSteps.Staking,
    title: `Staking $APE`,
    description: `A small amount of gas is required to stake $APE`,
  },
];
