import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { jsNumberForAddress } from "react-jazzicon";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Button, Card, Flex, Text } from "@cyanco/components/theme";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, numberWithCommas, shortenAddress } from "@/utils";

import { IPawn } from "../Account/pawn.types";
import { IBNPL } from "../Bnpl/bnpl.types";
import { PlanCreationDecimalFormatMap } from "../PlanCreation/types";
import { PlanMetadata } from "./PlanMetadata";

export const WalletOptionModal = ({
  items,
  mainWalletBalance,
  cyanWalletBalance,
  currency,
  paymentAmount,
  onNext,
  purchasedPrice,
  totalAmount,
  leftAmount,
}: {
  items: Array<IPawn | IBNPL>;
  onNext: (a: { selectedWalletAddress: string }) => void;
  mainWalletBalance: BigNumber;
  cyanWalletBalance: BigNumber;
  currency: ICurrency;
  paymentAmount: BigNumber;
  totalAmount: BigNumber;
  leftAmount: BigNumber;
  purchasedPrice?: string;
}) => {
  const { account } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const [selectedWallet, setSelectedWallet] = useState(account || "");
  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(currency.decimal) || 4;
  }, [currency.decimal]);
  return (
    <Flex direction="column" gap="1.3rem">
      <PlanMetadata
        plans={items.map(plan => ({
          tokenId: plan.tokenId,
          collectionName: plan.metadata.collection.name,
          currency: plan.currency,
          imageUrl: plan.metadata.imageUrl,
          address: plan.metadata.collectionAddress,
          isBnpl: plan.planType === "BNPL",
          purchasedPrice,
          totalAmount,
          leftAmount,
        }))}
      />
      <Flex direction="column" gap="0.6rem">
        <Flex direction="column" gap="0.4rem">
          <Text color="gray0" size="sm" weight="600">{`Select wallet to pay with:`}</Text>
          <WalletBox
            isSelected={account === selectedWallet}
            onClick={() => setSelectedWallet(account || "")}
            disabled={mainWalletBalance.lt(paymentAmount)}
          >
            <Flex gap="10px">
              <Jazzicon seed={jsNumberForAddress(account || "")} diameter={18} />
              <Text color={mainWalletBalance.gte(paymentAmount) ? "secondary" : "gray0"} size="sm" weight="500">
                {shortenAddress(account || "")}
              </Text>
            </Flex>
            <Text color={mainWalletBalance.gte(paymentAmount) ? "secondary" : "gray0"}>{`Balance: ${numberWithCommas(
              bigNumToFloat(mainWalletBalance, currency.decimal),
              4,
            )} ${currency.symbol}`}</Text>
          </WalletBox>
        </Flex>
        {cyanWallet && (
          <WalletBox
            isSelected={cyanWallet.walletAddress === selectedWallet}
            onClick={() => setSelectedWallet(cyanWallet.walletAddress)}
            disabled={cyanWalletBalance.lt(paymentAmount)}
          >
            <Flex gap="10px">
              {" "}
              <div style={{ background: "cyan", width: "18px", height: "18px", borderRadius: "50%" }} />
              <Text color={cyanWalletBalance.gte(paymentAmount) ? "secondary" : "gray0"} size="sm" weight="500">
                {shortenAddress(cyanWallet.walletAddress || "")}
              </Text>
            </Flex>
            <Text color={cyanWalletBalance.gte(paymentAmount) ? "secondary" : "gray0"}>{`Balance: ${numberWithCommas(
              bigNumToFloat(cyanWalletBalance, currency.decimal),
              4,
            )} ${currency.symbol}`}</Text>
          </WalletBox>
        )}
      </Flex>
      <Card p={"10px"}>
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4px" w="100%">
            <Text size="sm" weight="500" color="gray0">
              {`Make repayment:`}
            </Text>
            <Text size="lg" weight="500" color="secondary" key={currency.address}>
              {`${numberWithCommas(bigNumToFixedStr(paymentAmount, formatNumber, currency.decimal), formatNumber)}
                    ${currency.symbol}`}
            </Text>
          </Flex>
          <Box w="120px">
            <Button
              onClick={() => {
                onNext({ selectedWalletAddress: selectedWallet });
              }}
            >{`Pay`}</Button>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};

export const WalletBox = styled.button<{ isSelected: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.gray10};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border: 2px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.cyan : theme.colors.gray10)};
`;
