import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";

import { SearchInput } from "./Search";
import { Tabs } from "./Tabs";
import { UserPortfolio } from "./UserPortfolio";

export const Selectors = () => {
  return (
    <MainWrapper justifyContent="space-between" alignItems="center">
      <SelectorWrapper>
        <Tabs />
        <SearchInput />
      </SelectorWrapper>
      <UserPortfolio />
    </MainWrapper>
  );
};

const MainWrapper = styled(Flex)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    flex-direction: column-reverse;
    gap: 1rem;
    align-items: flex-start;
  }
`;

const SelectorWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  padding: 23px 0;
  @media only screen and (max-width: ${breakpoints.laptop}px) {
    grid-template-columns: 1.7fr 1.2fr;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    margin-bottom: 0;
    padding: 0;
  }
`;
