import { constants } from "ethers";
import { useMemo } from "react";
import { Check } from "react-feather";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SkeletonLine, Text } from "@cyanco/components/theme/v3";

import { IP2PLoanOffer } from "@/apis/p2p/types";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { bigNumToFloat, numberWithCommas, shortenAddress } from "@/utils";

export const LoanOfferRowLoading = () => {
  return (
    <Row>
      <Flex alignItems="center" gap="10px">
        <Jazzicon diameter={20} seed={jsNumberForAddress(constants.AddressZero)} />
        <RowLoadingText />
      </Flex>
      <RowLoadingText />
      <RowLoadingText />
      <RowLoadingText />
      <RowLoadingText />
      <RowLoadingText />
      <div></div>
    </Row>
  );
};

export const LoanOfferHeader = () => {
  return (
    <HeaderContainer>
      <HeaderText
        color="secondary"
        style={{
          textAlign: "left",
        }}
      >{`Lender`}</HeaderText>
      <HeaderText color="secondary">{`Loan Amount`}</HeaderText>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Interest`}</HeaderText>
      </Hidden>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`APR`}</HeaderText>
      </Hidden>
      <HeaderText color="secondary">{`Duration`}</HeaderText>
      <Hidden tabletDown>
        <HeaderText color="secondary">{`Auto-roll`}</HeaderText>
      </Hidden>
      <Hidden tabletDown>
        <HeaderText color="secondary">{``}</HeaderText>
      </Hidden>
    </HeaderContainer>
  );
};

type IProps = {
  offer: IP2PLoanOffer;
  onClick: (a: IP2PLoanOffer) => void;
  blockchainTimestamp: number;
};
export const LoanOfferRow: React.FC<IProps> = ({ offer, onClick }) => {
  const theme = useTheme();
  const loanAmount = useMemo(() => {
    return `${numberWithCommas(bigNumToFloat(offer.amount, offer.currency.decimal), 2)} ${offer.currency.symbol}`;
  }, [offer]);
  const apr = useMemo(() => {
    return (365 / (offer.term / 60 / 60 / 24)) * (offer.interestRate / 100);
  }, [offer.interestRate]);
  return (
    <Row>
      <Flex alignItems="center" gap="10px">
        <Jazzicon diameter={20} seed={jsNumberForAddress(offer.lenderAddress)} />
        <RowText
          color="secondary"
          weight="500"
          style={{
            textAlign: "left",
          }}
        >
          {shortenAddress(offer.lenderAddress)}
        </RowText>
      </Flex>

      <RowText color={"secondary"}>{loanAmount}</RowText>

      <Hidden tabletDown>
        <RowText color="secondary" weight="500">
          {offer.interestRate / 100}%
        </RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText color="secondary" weight="500">
          {apr.toFixed(2)}%
        </RowText>
      </Hidden>
      <RowText color="secondary" weight="500">
        {getPaymentInterval(offer.term)}
      </RowText>
      <Hidden tabletDown>
        <RowText color="secondary" weight="500">
          {offer.isExtendable ? <Check color={theme.colors.green} /> : ""}
        </RowText>
      </Hidden>
      <Hidden tabletDown>
        <Flex h="26px" justifyContent="flex-end">
          <Button
            size="xs"
            onClick={() => onClick(offer)}
            style={{
              width: "70px",
            }}
            disabled={!offer.isActive}
          >
            Borrow
          </Button>
        </Flex>
      </Hidden>
    </Row>
  );
};

export const Row = styled.div<{ selected?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  padding: 13px 18px 13px 18px;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.gray10 : "transparent")};
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2fr 1.5fr 1fr" }])};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 10px 14px 40px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  display: block;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 14 },
    { [breakpoints.laptop]: 12 },
    { [breakpoints.tablet]: 12 },
  ])};
`;

export const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  text-align: right;
  display: block;
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

const HeaderContainer = styled(Row)`
  padding: 14px 19px 12px 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "14px 19px 12px 18px" },
    { [breakpoints.laptop]: "14px 10px 12px 40px" },
    { [breakpoints.tablet]: "10px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
`;
const RowLoadingText = styled(SkeletonLine)<{ sub?: boolean }>`
  width: 50%;
  justify-self: flex-end;
  height: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("height", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])};
`;
