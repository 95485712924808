import { ApeCoin, NoImage } from "@cyanco/components/theme/v3/images";

import { PoolId } from "@/config";
import { bigNumToFloat } from "@/utils";

import { IApePlanHistory } from "../../types";
import { HistoryRow } from "./HistoryRow";

export const ApePlanHistoryRow = ({ histories }: { histories: IApePlanHistory[] }) => {
  return (
    <>
      {histories.map((history, index) => {
        return (
          <HistoryRow
            key={`${history.id}:${index}`}
            history={{
              imageUrl: history.poolId == PoolId.COIN ? ApeCoin : history.metadata?.imageUrl || NoImage,
              collectioName: history.metadata?.collectionName || "",
              poolId: history.poolId,
              createdAt: history.createdAt,
              wallet: history.wallet,
              amount: history.amount,
              rewards: history.rewards ? bigNumToFloat(history.rewards) : undefined,
              rewardStakeToCyanVault: history.rewardStakeToCyanVault,
              status: history.status,
              tokenId: history.tokenId || "",
              transactionHash: history.txnHash,
            }}
          />
        );
      })}
    </>
  );
};
