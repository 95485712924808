import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, Text } from "@cyanco/components/theme/v3";

import { VaultTableHeaderBase } from "../common";

export const LatestLoansHeader = () => {
  return (
    <VaultLoanHeaderContainer>
      <Hidden tabletDown></Hidden>
      <VaultLoanHeaderText color="secondary" align="start">
        {`Collection`} ({`Token ID`})
      </VaultLoanHeaderText>
      <Hidden tabletDown>
        <VaultLoanHeaderText color="secondary">{`Appraisal`}</VaultLoanHeaderText>
      </Hidden>
      <VaultLoanHeaderText color="secondary">{`Loan Amount`}</VaultLoanHeaderText>
      <Hidden tabletDown>
        <VaultLoanHeaderText color="secondary">{`Duration`}</VaultLoanHeaderText>
      </Hidden>
      <VaultLoanHeaderText color="secondary">{`APR`}</VaultLoanHeaderText>
    </VaultLoanHeaderContainer>
  );
};

const VaultLoanHeaderContainer = styled(VaultTableHeaderBase)`
  grid-template-columns: 0.2fr minmax(0, 2.2fr) 1.3fr 1.3fr 1.2fr 1.2fr 0.1fr;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2.5fr 1fr 1fr" }])};
`;

export const VaultLoanHeaderText = styled(Text)<{ align?: string }>`
  display: flex;
  justify-content: ${({ align }) => (align ? align : "end")};
  font-weight: 600;
  font-size: 16px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 14 },
    { [breakpoints.laptop]: 12 },
    { [breakpoints.tablet]: 12 },
  ])};
`;
