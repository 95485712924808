import { useMemo } from "react";
import { useAsyncAbortable } from "react-async-hook";
import styled from "styled-components";

import { Card, Flex, TitleContainer } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { ILeaderboardPoint, getLeaderboard } from "@/apis/cyan-points";
import { useWeb3React } from "@/components/Web3ReactProvider";

import { HomeTitleWebkit } from "../";
import { usePointData } from "../PointDataContext";
import { SectionContainer } from "./../UserProgress";
import { LeaderBoardHeader, LeaderBoardLoading, LeaderBoardRow } from "./LeaderBoardData";

export const Leaderboard = () => {
  const { account } = useWeb3React();
  const { userPoint } = usePointData();
  const { result: leaderboard = [], loading: leaderboardLoading } = useAsyncAbortable<ILeaderboardPoint[]>(
    async abortSignal => {
      return await getLeaderboard({ abortSignal });
    },
    [],
  );

  const isUserPartOfLeaderboard = useMemo(() => {
    if (!account) return false;
    return leaderboard.some(item => item.wallet.toLowerCase() === account.toLowerCase());
  }, [leaderboard]);
  return (
    <SectionContainer direction="column" alignItems="center">
      <TitleContainer alignItems="center" style={{ paddingBottom: 0 }}>
        <HomeTitleWebkit>Leaderboard</HomeTitleWebkit>
      </TitleContainer>
      <ListViewContainer>
        <ListViewBox>
          <ListHeaderWrapper>
            <LeaderBoardHeader />
          </ListHeaderWrapper>
          <ContainerBox>
            {leaderboardLoading ? (
              Array.from(Array(4).keys()).map(loader => <LeaderBoardLoading key={`${loader}-grid`} />)
            ) : (
              <>
                {account && !isUserPartOfLeaderboard && userPoint && (
                  <LeaderBoardRow
                    point={{
                      wallet: account,
                      realized: userPoint?.weeklyPoint?.realized ?? 0,
                      unrealized: userPoint?.totalPoint?.unrealized ?? 0,
                      totalRealized: userPoint?.totalPoint?.realized ?? 0,
                      rank: userPoint?.rank ?? null,
                      lastWeekRank: userPoint?.weeklyLog?.rank ?? null,
                      index: -1,
                    }}
                    userWallet={account}
                  />
                )}
                {leaderboard.map((item, index) => (
                  <LeaderBoardRow
                    point={{ ...item, index }}
                    key={`${item.rank}-${item.wallet}`}
                    userWallet={account ?? ""}
                  />
                ))}
              </>
            )}
          </ContainerBox>
        </ListViewBox>
      </ListViewContainer>
    </SectionContainer>
  );
};

const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative", overflowX: "scroll" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

const MainBox = styled(Card)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  margin: 1px;
  border: none;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-radius: 10px;
    box-shadow: none;
    min-width: 650px;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &:first-child {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
`;

const ContainerBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top: none;
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;

const ListViewContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const ListHeaderWrapper = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 170px;
  }
  ${getStyleWithMediaQuery("position", "", [{ [breakpoints.mobile]: "static" }])};
  align-items: end;
  top: 150px;
  background: ${({ theme }) => theme.colors.primary};
  transform-style: preserve-3d;
  z-index: 5;
`;
