import { FC, useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme";
import { Card, SwitchButton, SwitchButtonGroup, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { usePersistedWalletTypeForRelease } from "@/hooks/usePersistedWalletForNFTRelease";
import { shortenAddress } from "@/utils";

import type { IPawn } from "../Account/pawn.types";
import { useApeStakingUserAssets } from "../ApeCoinStaking/new/ApeCoinDataContext";
import type { IBNPL } from "../Bnpl/bnpl.types";

interface WalletSelectorForNFTReleaseProps {
  selectedWallet: string;
  onReleaseWalletChange: (wallet: string) => void;
  plans: (IBNPL | IPawn)[];
  onMainWalletStateChange?: (disabled: boolean) => void;
  isFullPayment?: boolean;
}

export const WalletSelectorForNFTRelease: FC<WalletSelectorForNFTReleaseProps> = ({
  selectedWallet,
  onReleaseWalletChange,
  plans,
  onMainWalletStateChange,
  isFullPayment = false,
}) => {
  const theme = useTheme();
  const { account } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { setWalletTypeForRelease } = usePersistedWalletTypeForRelease();
  const [isMainWalletDisabled, setIsMainWalletDisabled] = useState<boolean>(false);
  const { stakedPositions } = useApeStakingUserAssets();

  useEffect(() => {
    const detectAbleToUseMainWalletForRelease = async () => {
      if (!cyanWallet || !stakedPositions) return false;
      for (const plan of plans) {
        if (
          stakedPositions.find(
            position =>
              position.metadata.collectionAddress === plan.metadata.collectionAddress &&
              position.tokenId === plan.tokenId,
          )
        ) {
          // If the payment is the last one or full payment, call onDisableMainWallet to show the warning message
          if (plan.totalNumOfPayments - plan.currentNumOfPayments === 1 || isFullPayment) {
            onMainWalletStateChange?.(true);
          }
          onReleaseWalletChange(cyanWallet.walletAddress);
          setIsMainWalletDisabled(true);
          return;
        }
      }
      // To clear the warning message
      onMainWalletStateChange?.(false);
    };
    detectAbleToUseMainWalletForRelease();
    return () => {
      if (isMainWalletDisabled) {
        // When the component is out of viewport, clear the warning message
        // This could happen when user change paymentOption
        onMainWalletStateChange?.(false);
      }
    };
  }, [setIsMainWalletDisabled, isMainWalletDisabled, cyanWallet, stakedPositions]);

  if (!account || !cyanWallet) {
    return null;
  }

  return (
    <Card
      hover
      style={{
        padding: "8px",
      }}
    >
      <Flex direction="column" gap="5px">
        <Flex alignItems="center" gap="4px">
          <Text size="sm" weight="600" color="secondary">
            {`Wallet for NFT release`}
          </Text>
          <Tooltip>
            <HelpCircle height={16} width={16} color={theme.colors.secondary} />
            <TooltipText showArrow position="top" top="-80px" right="-50px" style={{ width: "100px" }}>
              <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                <div>{`Choose where you want your NFTs to go after you finish a loan.`}</div>
              </Text>
            </TooltipText>
          </Tooltip>
        </Flex>
        <SwitchButtonGroup<string>
          activeBackground={theme.colors.cyan}
          activeTextColor={theme.colors.black}
          borderColor={theme.colors.gray10}
          value={selectedWallet}
          onChange={v => {
            setWalletTypeForRelease(v === account ? "main" : "cyan");
            onReleaseWalletChange(v);
          }}
          hover
        >
          <SwitchButton disabled={isMainWalletDisabled} height="22px" value={account}>{`Main (${shortenAddress(
            account,
          )})`}</SwitchButton>
          <SwitchButton height="22px" value={cyanWallet.walletAddress}>{`Cyan (${shortenAddress(
            cyanWallet.walletAddress,
          )})`}</SwitchButton>
        </SwitchButtonGroup>
      </Flex>
    </Card>
  );
};
