import { useEffect, useRef, useState } from "react";
import { useAsyncAbortable } from "react-async-hook";
import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme/Box";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Input, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { useOnClickOutside } from "@cyanco/components/theme/v3/hooks/useOnClickOutside";
import { Search } from "@cyanco/components/theme/v3/icons";

import { searchCollections } from "@/apis/collection";
import { ISearchCollectionResult } from "@/apis/collection/types";
import { useDebounce } from "@/hooks/useDebounce";
import { shortenName } from "@/utils";

import { IProject } from "./SupportedCollections";

export const CollectionSearch = ({
  chainId,
  addNewCollection,
}: {
  chainId: number;
  addNewCollection: (collection: IProject) => void;
}) => {
  const theme = useTheme();
  const searchInput = useRef<HTMLInputElement>(null);
  const node = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [collectionInputValue, setCollectionInputValue] = useState("");

  const [selectedCollectionSearch, setSelectedCollectionSearch] = useState("");
  const { result: collections = [], loading } = useAsyncAbortable<ISearchCollectionResult>(
    async abortSignal => {
      if (selectedCollectionSearch === "") return [];
      return await searchCollections(chainId, selectedCollectionSearch, abortSignal);
    },
    [selectedCollectionSearch],
  );

  useDebounce(
    () => {
      setSelectedCollectionSearch(collectionInputValue);
    },
    [collectionInputValue],
    800,
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        searchInput.current?.blur();
        setOpen(false);
      }
      if (e.key == "ArrowUp") {
        setIndex(index > 0 ? index - 1 : collections.length - 1);
      }
      if (e.key == "ArrowDown") {
        setIndex(index + 1 < collections.length ? index + 1 : 0);
      }
      if (e.key == "Enter" && index >= 0 && index < collections.length) {
        addNewCollection({
          name: collections[index].name,
          address: collections[index].contract,
          imageUrl: collections[index].image,
          isNew: true,
          isBnplAllowed: true,
          isPawnAllowed: true,
        });
        searchInput.current?.blur();
      }
    };
    document.addEventListener("keyup", handleKeyDown);
    return () => document.removeEventListener("keyup", handleKeyDown);
  }, [searchInput, collections, index]);
  useOnClickOutside(node, () => setOpen(false));

  return (
    <div ref={node}>
      <StyledBox h={"40px"} minWidth={"300px"}>
        <Input
          placeholder={`Search collections to add`}
          fontSize="md"
          fontWeight="400"
          icon={<Search color={theme.colors.gray0} />}
          value={collectionInputValue}
          onChange={e => setCollectionInputValue(e.target.value)}
          onFocus={() => {
            setIndex(-1);
            setOpen(true);
          }}
          inputRef={searchInput}
          p={"0.25rem 0.35rem"}
          bgColor={theme.backgroundColor}
        ></Input>
        {open && collectionInputValue.length > 0 && (
          <SearchResultBox minWidth={"300px"}>
            <Flex direction="column" gap="2px">
              {!loading && collections.length === 0 && <Text color="secondary">{`No results found`}</Text>}
              {!loading &&
                collections.map((collection, ind) => (
                  <SearchCollectionItem
                    key={collection.contract}
                    variant="ghost"
                    isActive={ind == index}
                    onClick={() => {
                      addNewCollection({
                        name: collection.name,
                        address: collection.contract,
                        imageUrl: collection.image,
                        isNew: true,
                        isBnplAllowed: true,
                        isPawnAllowed: true,
                      });
                      searchInput.current?.blur();
                    }}
                  >
                    <Flex alignItems="center" gap="5px">
                      <StyledImg src={collection.image} />
                      <Text color="secondary">{shortenName(collection.name, 30)}</Text>
                    </Flex>
                  </SearchCollectionItem>
                ))}
              {loading &&
                [1, 2, 3, 4].map(ind => (
                  <SearchCollectionItem disabled key={ind} variant="ghost" isActive={false}>
                    <SkeletonLine h="32px" w="100%" />
                  </SearchCollectionItem>
                ))}
            </Flex>
          </SearchResultBox>
        )}
      </StyledBox>
    </div>
  );
};

const StyledBox = styled(Box)<{ minWidth?: string }>`
  min-width: ${({ minWidth }) => minWidth};
  max-width: 500px;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    min-width: 180px;
  }
`;

const SearchResultBox = styled.div<{ minWidth?: string }>`
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary} !important;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-top: 0.5rem;
  z-index: 200 !important;
  padding: 0.3rem;
  max-height: 250px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const SearchCollectionItem = styled(Button)<{ isActive: boolean }>`
  padding: 4px 4px;
  justify-content: flex-start;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  background: ${({ theme, isActive }) =>
    isActive && (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
  :hover {
    background-color: ${({ theme }) => (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
    border-radius: 5px;
  }
`;
const StyledImg = styled.img`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: ${({ theme }) => theme.borderRadius};
`;
