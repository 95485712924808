import { axios } from "./axios";

export const fetchExperiments: FnFetchExperiments = async (wallet, abortSignal) => {
  return (
    await axios.get(`/experiment`, {
      params: {
        wallet,
        type: "FE",
      },
      signal: abortSignal,
    })
  ).data;
};

export interface IExperiment {
  [key: string]: boolean;
}
type FnFetchExperiments = (a?: string, abortSignal?: AbortSignal) => Promise<IExperiment>;
