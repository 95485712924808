import { useLocalStorage } from "./useLocalStorage";

export const usePersistedWalletTypeForRelease = () => {
  const [walletTypeForRelease, setWalletTypeForRelease] = useLocalStorage<"main" | "cyan">(
    "selectedWalletForRelease",
    "cyan",
  );
  return {
    walletTypeForRelease,
    setWalletTypeForRelease,
  };
};
