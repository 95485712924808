import { BigNumber } from "ethers";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { SkeletonLine, Text, getCurrencyLogoSrc } from "@cyanco/components/theme/v3";
import { CyanC } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { bigNumToFloatFormatted } from "@/utils";

import { useFilteredVaultsNew } from "../../VaultDataProvider";
import { formatPercentage, getSupplyApy } from "../../VaultPageContext";

export const VaultGridCard = ({ vault }: { vault: IVault }) => {
  const navigate = useNavigate();
  const { layer2Chains } = useFilteredVaultsNew();
  const location = useLocation();
  const urlPath = useMemo(() => {
    return `${location.pathname.split("/")[1]}/${CHAIN_IDS_TO_NAMES[vault.chainId as SupportedChainId]}/${
      vault.contractAddress
    }`;
  }, [vault.chainId, vault.contractAddress]);
  const supplyApy = useMemo(() => {
    return formatPercentage(getSupplyApy(vault));
  }, [vault.estimatedYield, vault.utilizationRate]);
  const vaultTags = useMemo(() => {
    // check layer-2
    const tag = [vault.isAutoLiquidated ? "Automatic liquidations" : "No liquidations"];
    if (layer2Chains.includes(vault.chainId)) {
      tag.push("Layer-2");
    }
    if (["CV09", "CV10"].includes(vault.symbol)) {
      tag.push("Keyrock");
    }
    return tag;
  }, [vault, layer2Chains]);
  return (
    <Container
      onClick={() => navigate(`/${urlPath}`)}
      style={{
        cursor: "pointer",
      }}
    >
      <ColoredTopContainer background={vault.colorCode}>
        <ColoredWrapper>
          <TagsContainer>
            {vaultTags.map((tag: string, index) => (
              <Tag background={vault.colorCode} key={`${tag}:${index}`}>
                <TagWrapper>
                  <Text size="xs" weight="600" color="white">
                    {tag}
                  </Text>
                </TagWrapper>
              </Tag>
            ))}
          </TagsContainer>
        </ColoredWrapper>
      </ColoredTopContainer>
      <ContentContainer direction="column" gap="1rem">
        <VaultLogWrapper>
          <VaultImageWrapper style={{ background: vault.colorCode }}>
            <VaultImage src={CyanC} alt={vault.name} />
            <CurrencyLogo src={getCurrencyLogoSrc(vault.currency)}></CurrencyLogo>
          </VaultImageWrapper>
        </VaultLogWrapper>
        <Text size="lg" weight="500" color="secondary" style={{ marginBottom: "1rem" }}>
          {vault.name}
        </Text>
        <Flex direction="column" gap="0.5rem">
          <Text size="sm" weight="500" color="gray0">
            Current Supply Yield (APY)
          </Text>
          <Text size="xxl" weight="500" color="secondary">
            {supplyApy}
          </Text>
        </Flex>
        <Flex gap="1rem">
          <Flex gap="0.5rem" direction="column">
            <Text size="xs" weight="500" color="gray0">
              Current Price
            </Text>
            <Text size="sm" weight="500" color="green">
              {vault.price ? bigNumToFloatFormatted(vault.price, vault.decimals) : "N/A"}
            </Text>
          </Flex>
          <Flex gap="0.5rem" direction="column">
            <Text size="xs" weight="500" color="gray0">
              Utilization Rate
            </Text>
            <Text size="sm" weight="500" color="secondary">
              {formatPercentage(vault.utilizationRate ? vault.utilizationRate * 100 : null)}
            </Text>
          </Flex>
        </Flex>
        <Flex gap="0.5rem" direction="column">
          <Flex alignItems="center" justifyContent="space-between">
            <Text size="xs" weight="500" color="gray0">
              Loaned Out
            </Text>
            <Text size="xs" weight="500" color="secondary">
              {vault.totalLoanedAmount
                ? `${bigNumToFloatFormatted(
                    BigNumber.from(vault.totalLoanedAmount).add(vault.defaultedNftAmount),
                    vault.decimals,
                    2,
                  )} ${vault.currency}`
                : "N/A"}
            </Text>
          </Flex>
          <div style={{ position: "relative" }}>
            <SimpleHorizonaLChartLineNonFilled />
            <SimpleHorizonaLChartLine width={`${(vault.utilizationRate ?? 0) * 100}%`}></SimpleHorizonaLChartLine>
          </div>
          <Flex alignItems="center" justifyContent="space-between">
            <Text size="xs" weight="500" color="gray0">
              Total Value Locked
            </Text>
            <Text size="xs" weight="500" color="secondary">
              {vault.totalValueLocked
                ? `${bigNumToFloatFormatted(vault.totalValueLocked, vault.decimals, 2)} ${vault.currency}`
                : "N/A"}
            </Text>
          </Flex>
        </Flex>
      </ContentContainer>
    </Container>
  );
};

export const VaultGridCardLoading = () => {
  return (
    <Container>
      <ColoredTopContainer background="gray0">
        <SkeletonLine w="100%" h="100%" />
      </ColoredTopContainer>
      <ContentContainer direction="column" gap="1rem">
        <VaultImageWrapper>
          <SkeletonLine w="100%" h="100%" borderRadius="50%" />
        </VaultImageWrapper>
        <Text size="lg" weight="500" color="secondary" style={{ marginBottom: "1rem" }}>
          <SkeletonLine w="100%" h="16px" />
        </Text>
        <Flex gap="1rem" justifyContent="space-between">
          <Flex gap="0.5rem" direction="column">
            <SkeletonLine w="90px" h="13.5px" />
            <SkeletonLine w="90px" h="12px" />
          </Flex>
          <Flex gap="0.5rem" direction="column">
            <SkeletonLine w="80px" h="13.5px" />
            <SkeletonLine w="80px" h="12px" />
          </Flex>
        </Flex>
        <Flex gap="0.5rem" direction="column">
          <SkeletonLine w="100%" h="12px" />
          <SkeletonLine w="100%" h="12px" />
        </Flex>
      </ContentContainer>
    </Container>
  );
};
const SimpleHorizonaLChartLineNonFilled = styled.div`
  width: 100%;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.gray20};
  height: 3px;
`;
const SimpleHorizonaLChartLine = styled.div<{ width: string }>`
  width: ${props => props.width};
  height: 100%;
  border-radius: 3px;
  height: 3px;
  background: ${({ theme }) => theme.colors.gray90};
  position: absolute;
  top: 0;
  left: 0;
`;
const ContentContainer = styled(Flex)`
  padding: 1rem;
  position: relative;
  top: 1.5rem;
`;
const TagWrapper = styled.div`
  background-color: rgba(128, 128, 128, 0.5);
  align-items: center;
  padding: 4px 8px;
  border-radius: 5px;
`;
const Tag = styled(Flex)<{ background: string }>`
  background-color: ${props => props.background};
  border-radius: 5px;
`;
const TagsContainer = styled(Flex)`
  justify-content: flex-start;
  height: 30px;
  margin: 1rem;
  gap: 0.5rem;
`;
const ColoredWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
`;
const ColoredTopContainer = styled(Flex)<{ background: string }>`
  height: 120px;
  width: 100%;
  background-color: ${props => props.background};
`;
const Container = styled(Flex)`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  background-color: ${({ theme }) => (theme.theme === "light" ? "#EEEEEE" : "#121218")};
  border-radius: 13px;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.colors.gray20};
  width: 100%;
  min-height: 440px;
  min-width: 280px;
  transition: 0.2s ease-in-out;
  :hover {
    border-color: ${({ theme }) => theme.colors.cyan};
    border-width: 2px;
    box-shadow: 0px 0px 10px 5px rgba(0, 255, 255, 0.25);
  }
`;
const CurrencyLogo = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  right: -5px;
  top: -5px;
  border: 2px solid #020d15;
  border-radius: 50%;
`;
const VaultLogWrapper = styled(Flex)`
  position: absolute;
  top: -70px;
  ${getStyleWithMediaQuery("top", "px", [{ [breakpoints.tablet]: -60 }])};
`;
const VaultImage = styled.img`
  width: 32px;
  height: 32px;
`;
const VaultImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
