import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Text } from "@cyanco/components/theme/v3";

import { LoanOffers } from "@/components/Loans/LoanOffers";
import { LoanView, useLoanPageSelectors } from "@/components/Loans/LoanPageProvider";
import { NftView } from "@/components/Loans/UserNfts";

import { Selectors } from "./Selectors";

export const AssetLoans = () => {
  const { selectedLoanView } = useLoanPageSelectors();

  return (
    <Container>
      <Flex direction="column">
        <LoanText color="secondary">{`Test Drive Assets`}</LoanText>
        <Selectors />
        <div
          style={{
            display: LoanView.assets === selectedLoanView ? "block" : "none",
          }}
        >
          <NftView />
        </div>
        <div
          style={{
            display: LoanView.loanOffers === selectedLoanView ? "block" : "none",
          }}
        >
          <LoanOffers />
        </div>
      </Flex>
    </Container>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1240px) / 2);
  gap: 4rem;
  padding: 0 0 5rem 0;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 2 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 50px" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "1rem 0.5rem 10rem 0.5rem" }])}
  display: flex;
  flex-direction: column;
`;

const LoanText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 24 }, { [breakpoints.mobile]: 18 }])};
`;
