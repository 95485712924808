import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Card } from "@cyanco/components/theme/v3";
import { SubTitle, TitleContainer } from "@cyanco/components/theme/v3/common";

import { AccountPageTabs, useAccountPageContext } from "./AccountPageContext";
import { MainView } from "./components/MainView";
import { Selectors } from "./components/Selectors";
import { Shortcuts } from "./components/Selectors/Shortcuts";

export const AccountPage = () => {
  const { panel } = useParams();
  const { setSelectedTab } = useAccountPageContext();

  useEffect(() => {
    switch (panel) {
      case AccountPageTabs.vault_tokens:
        setSelectedTab(AccountPageTabs.vault_tokens);
        break;
      case AccountPageTabs.positions:
        setSelectedTab(AccountPageTabs.positions);
        break;
      case AccountPageTabs.lending:
        setSelectedTab(AccountPageTabs.lending);
        break;
      default:
        setSelectedTab(AccountPageTabs.wallets);
    }
  }, [panel]);

  return (
    <Container direction="column">
      <SubContainer direction="column">
        <HorizantallyGap>
          <TitleContainer style={{ alignItems: "flex-start", paddingBottom: "0rem" }}>
            <MobileTitleView alignItems="center" gap="1rem">
              <SubTitle weight="600">{`Account Page`} </SubTitle>
              <Shortcuts />
            </MobileTitleView>
          </TitleContainer>
        </HorizantallyGap>
        <HorizantallyGap>
          <Selectors />
        </HorizantallyGap>
      </SubContainer>
      <MainView />
    </Container>
  );
};

const Container = styled(Flex)`
  margin: 30px 0;
  gap: 1.5rem;
  padding: 0 0 5rem 0;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1.5 }, { [breakpoints.tablet]: 1 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 0" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 0 5rem 0" },
    { [breakpoints.tablet]: "2rem 0.5rem 10rem 0.5rem" },
  ])}
  margin-top: 5rem;
`;
const SubContainer = styled(Flex)`
  gap: 0.5rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 0.5 }, { [breakpoints.tablet]: 1 }])}
`;

const HorizantallyGap = styled.div`
  margin-left: calc((100% - 1240px) / 2);
  margin-right: calc((100% - 1240px) / 2);
  ${getStyleWithMediaQuery("margin-left", "", [{ [breakpoints.laptopM]: "50px" }, { [breakpoints.tablet]: "0px" }])}
  ${getStyleWithMediaQuery("margin-right", "", [{ [breakpoints.laptopM]: "50px" }, { [breakpoints.tablet]: "0px" }])}
`;

export const HorizantallyContainerGap = styled.div<{ isGrid?: boolean }>`
  margin-left: calc((100% - 1240px) / 2);
  margin-right: calc((100% - 1240px) / 2);
  ${({ isGrid }) =>
    getStyleWithMediaQuery("margin-left", "", [
      { [breakpoints.laptopM]: isGrid ? "50px" : "0px" },
      { [breakpoints.tablet]: "0px" },
    ])}
  ${({ isGrid }) =>
    getStyleWithMediaQuery("margin-right", "", [
      { [breakpoints.laptopM]: isGrid ? "50px" : "0px" },
      { [breakpoints.tablet]: "0px" },
    ])}
`;

export const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

const MainBox = styled(Card)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  margin: 1px;
  border: none;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-radius: 10px;
    box-shadow: none;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &:first-child {
      border-bottom: none;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: sticky;
  align-items: end;
  top: 135px;
  transform-style: preserve-3d;
  z-index: 6;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    overflow-anchor: none;
    position: absolute;
    transform: translateZ(-1px);
    height: calc(100% + 15px);
    top: -15px;
    left: -100px;
    @media only screen and (max-width: ${breakpoints.tablet}px) {
      left: -20px;
    }
    z-index: -1;
    width: 100vw;
  }
`;

export const StickySelectorWrapper = styled(Flex)`
  top: 64px;
  position: sticky;
  z-index: 7;
  background: ${({ theme }) => theme.backgroundColor};
  padding-left: calc((100% - 1240px) / 2);
  padding-right: calc((100% - 1240px) / 2);
  padding-top: 1.5rem;
  margin-top: -1.5rem;
  width: calc(100% - calc((100% - 1240px)));
  ${getStyleWithMediaQuery("padding-top", "", [{ [breakpoints.desktop]: "1.5rem" }, { [breakpoints.tablet]: "1rem" }])}
  ${getStyleWithMediaQuery("margin-top", "", [{ [breakpoints.desktop]: "-1.5rem" }, { [breakpoints.tablet]: "-1rem" }])}
  ${getStyleWithMediaQuery("padding-left", "", [{ [breakpoints.laptopM]: "50px" }, { [breakpoints.tablet]: "0px" }])}
  ${getStyleWithMediaQuery("padding-right", "", [{ [breakpoints.laptopM]: "50px" }, { [breakpoints.tablet]: "0px" }])}
${getStyleWithMediaQuery("width", "", [
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
`;

export const ContainerBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top: none;
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;

const MobileTitleView = styled(Flex)`
  flex-direction: row;
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])}
  ${getStyleWithMediaQuery("align-items", "", [{ [breakpoints.tablet]: "flex-start" }])}
`;
