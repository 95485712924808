import { BigNumber } from "ethers";

import { IPosition, IVaultTokenTransaction } from "@/components/Vault/types";

import { axios } from "../axios";

type IVaultPositionBe = Exclude<IPosition, "availableBalance">;
export const fetchVaultPositionsByUser: FnFetchPositionsByUser<IVaultPositionBe> = async address => {
  const { data } = await axios.get(`/users/${address}/vaults-positions`);
  return data.map((position: IVaultPositionBe) => ({
    ...position,
    balance: BigNumber.from(position.balance),
  }));
};

export const fetchVaultTransactionsByUser: FnFetchTransactionsByUser = async address => {
  const { data } = await axios.get(`/users/${address}/vaults-transactions`);
  return data.map((transaction: IVaultTokenTransaction) => ({
    ...transaction,
    createdAt: new Date(transaction.createdAt),
  }));
};

type FnFetchPositionsByUser<T> = (a: string, abortSignal?: AbortSignal) => Promise<Array<T>>;
type FnFetchTransactionsByUser = (a: string) => Promise<Array<IVaultTokenTransaction>>;
