import styled from "styled-components";

import { Flex, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NewTab } from "@cyanco/components/theme/v3/icons";

import { CYAN_POINTS_DOC_LINK } from "@/config";
import { formatCompactNumber, jumpToLink } from "@/utils";

import { usePointData } from "./PointDataContext";
import { formatCyanPoint } from "./utils";

const Points = {
  leadershipMultiplier1: 1.5, // Leadership multiplier of top 10 users
  leadershipMultiplier2: 0.5, // Leadership multiplier of 11-25th users
  leadershipMultiplier3: 0.25, // Leadership multiplier of 26-50th users
};

export const getBooster = (rank: number | null) => {
  const baseBooster = 1;
  if (!rank) return baseBooster;
  if (rank > 25 && rank <= 50) {
    return baseBooster + Points.leadershipMultiplier3;
  }
  if (rank > 10 && rank <= 25) {
    return baseBooster + Points.leadershipMultiplier2;
  }
  if (rank <= 10) {
    return baseBooster + Points.leadershipMultiplier1;
  }
  return baseBooster;
};

export const formatNumberWithSign = (num: number | null) => {
  return num !== null ? `${num >= 0 ? "+" : ""}${formatCompactNumber(num)}` : "-";
};

export const UserProgress = () => {
  const { userPoint } = usePointData();
  return (
    <SectionContainer direction="column">
      <Title color="secondary" textAlign="center">{`Your progress this week`}</Title>
      <Flex direction="column" gap="2rem" alignItems="flex-start" w="100%">
        <Flex
          w="100%"
          style={{
            overflowX: "auto",
          }}
        >
          <UserProgressTable>
            <tr>
              <td>
                <UserPointData name={`Ranking`} value={userPoint?.rank ? `#${userPoint.rank}` : "-"} />
              </td>
              <td>
                <UserPointData name={`Booster`} value={`${getBooster(userPoint?.rank ?? null)}x`} />
              </td>
              <td>
                <UserPointData
                  name={`Unrealised Pts.`}
                  value={userPoint?.totalPoint?.unrealized ? formatCyanPoint(userPoint.totalPoint.unrealized) : "0"}
                />
              </td>
              <td>
                <UserPointData
                  name={`Realised Pts.`}
                  value={userPoint?.weeklyPoint?.realized ? formatCyanPoint(userPoint.weeklyPoint.realized) : "0"}
                />
              </td>
            </tr>
          </UserProgressTable>
        </Flex>
        <DescriptionBox direction="column" alignItems="flex-start" justifyContent="flex-start">
          <Flex direction="column" gap="1rem">
            <DescriptionTitle weight="700" color="black">{`Start earning points today!`}</DescriptionTitle>
            <DescriptionText color="black">{`Season 1 started from March 1st, 2024. Points are rewarded to users who take action on Cyan either as a borrower or lender. Cyan Points are a way for Cyan to return value back to users. Points are not a promise for any monetary value, nor are guaranteed to convert to tokens or some form of cryptocurrency.`}</DescriptionText>
          </Flex>
          <Flex
            alignItems="center"
            gap="2px"
            style={{ cursor: "pointer", alignSelf: "flex-end" }}
            onClick={() => jumpToLink(CYAN_POINTS_DOC_LINK)}
          >
            <DescriptionLink color="black">{`Learn more`}</DescriptionLink>
            <NewTab color="black" height={12} strokeWidth={2} />
          </Flex>
        </DescriptionBox>
      </Flex>
    </SectionContainer>
  );
};

const UserPointData = ({ name, value }: { name: string; value: string }) => {
  return (
    <Flex direction="column" alignItems="center">
      <Flex direction="column" alignItems="center">
        <DescriptionTitle color="green" weight="600" textWrap={false}>
          {name}
        </DescriptionTitle>
        <UserPointText color="secondary" textWrap={false}>
          {value}
        </UserPointText>
        {/* TEMP HIDING
        <Flex justifyContent="flex-end" w="100%">
          <DescriptionText
            color="gray0"
            weight="600"
            textWrap={false}
            style={{ alignSelf: "flex-end", visibility: difference !== null ? "visible" : "hidden" }}
          >
            {typeof difference === "string" ? difference : formatNumberWithSign(difference)}
          </DescriptionText>
        </Flex> */}
      </Flex>
    </Flex>
  );
};

const Title = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 32 },
    { [breakpoints.tablet]: 24 },
    { [breakpoints.mobile]: 18 },
  ])};
`;

export const SectionContainer = styled(Flex)`
  max-width: 932px;
  gap: 3rem;
  width: 100%;
  ${getStyleWithMediaQuery("gap", "", [{ [breakpoints.tablet]: "1rem" }])};
`;

const DescriptionBox = styled(Flex)`
  background: rgba(0, 255, 163, 1);
  border-radius: 25px;
  padding: 1.5rem;
  gap: 0.2rem;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "1rem" }])};
`;

const DescriptionTitle = styled(Text)`
  font-size: 24px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 24 },
    { [breakpoints.tablet]: 18 },
    { [breakpoints.mobile]: 16 },
  ])};
`;

const DescriptionText = styled(Text)`
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 20 },
    { [breakpoints.tablet]: 14 },
    { [breakpoints.mobile]: 12 },
  ])};
`;
const DescriptionLink = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 12 }])};
`;

const UserProgressTable = styled.table`
  width: 100%;
  border-radius: 25px;
  border-spacing: 0;
  border: 5px solid rgba(0, 255, 163, 1);
  td {
    border-right: 5px solid rgba(0, 255, 163, 1);
    &:last-of-type {
      border-right: 0px;
    }

    padding: 1rem;
  }
`;

const UserPointText = styled(Text)`
  font-weight: 600;
  font-size: 48px;
  text-align: left;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 42 }, { [breakpoints.mobile]: 36 }])};
`;
