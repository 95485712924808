import React, { createContext, useContext, useState } from "react";

import { ISelectedNft } from "./types";

export enum ApeCoinPageTabs {
  pools = "pools",
  rewards = "rewards",
  history = "history",
}

export enum PoolsViewStyle {
  list = "list",
  grid = "grid",
}

export enum RewardsViewStyle {
  list = "list",
  grid = "grid",
}

export enum RewardsViewType {
  staked = "staked",
  unstaked = "unstaked",
}

export enum HistoryViewType {
  all = "all",
  staked = "staked",
  unstaked = "unstaked",
}
export enum WalletTypes {
  allWallets = "allWallets",
  mainWallet = "mainWallet",
  cyanWallet = "cyanWallet",
}
interface IApeCoinContext {
  selectedTab: ApeCoinPageTabs;
  setSelectedTab: React.Dispatch<React.SetStateAction<ApeCoinPageTabs>>;
  selectedPoolsView: PoolsViewStyle;
  setSelectedPoolsView: React.Dispatch<React.SetStateAction<PoolsViewStyle>>;
  selectedRewardsView: RewardsViewStyle;
  setSelectedRewardsView: React.Dispatch<React.SetStateAction<RewardsViewStyle>>;
  selectedRewardsViewType: RewardsViewType;
  setSelectedRewardsViewType: React.Dispatch<React.SetStateAction<RewardsViewType>>;
  selectedHistoryViewType: HistoryViewType;
  setSelectedHistoryViewType: React.Dispatch<React.SetStateAction<HistoryViewType>>;
  selectedStakingNfts: ISelectedNft[];
  setSelectedStakingNfts: React.Dispatch<React.SetStateAction<ISelectedNft[]>>;
  selectedUnstakingNfts: ISelectedNft[];
  setSelectedUnstakingNfts: React.Dispatch<React.SetStateAction<ISelectedNft[]>>;
  selectedStakedWalletType: WalletTypes;
  selectedUnstakedWalletType: WalletTypes;
  setSelectedStakedWalletType: (type: WalletTypes) => void;
  setSelectedUnstakedWalletType: (type: WalletTypes) => void;
}

const initialContext: IApeCoinContext = {
  selectedTab: ApeCoinPageTabs.pools,
  setSelectedTab: () => {},
  selectedPoolsView: PoolsViewStyle.list,
  setSelectedPoolsView: () => {},
  selectedRewardsView: RewardsViewStyle.list,
  setSelectedRewardsView: () => {},
  selectedRewardsViewType: RewardsViewType.unstaked,
  setSelectedRewardsViewType: () => {},
  selectedHistoryViewType: HistoryViewType.unstaked,
  setSelectedHistoryViewType: () => {},
  selectedStakingNfts: [],
  setSelectedStakingNfts: () => {},
  selectedUnstakingNfts: [],
  setSelectedUnstakingNfts: () => {},
  selectedStakedWalletType: WalletTypes.mainWallet,
  setSelectedStakedWalletType: () => {},
  selectedUnstakedWalletType: WalletTypes.mainWallet,
  setSelectedUnstakedWalletType: () => {},
};

const ApeCoinContext = createContext<IApeCoinContext>(initialContext);

export const ApeCoinProvider: React.FC = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState<ApeCoinPageTabs>(ApeCoinPageTabs.pools);
  const [selectedPoolsView, setSelectedPoolsView] = useState<PoolsViewStyle>(PoolsViewStyle.grid);
  const [selectedRewardsView, setSelectedRewardsView] = useState<RewardsViewStyle>(RewardsViewStyle.grid);
  const [selectedRewardsViewType, setSelectedRewardsViewType] = useState<RewardsViewType>(RewardsViewType.staked);
  const [selectedHistoryViewType, setSelectedHistoryViewType] = useState<HistoryViewType>(HistoryViewType.all);
  const [selectedStakingNfts, setSelectedStakingNfts] = useState<ISelectedNft[]>([]);
  const [selectedUnstakingNfts, setSelectedUnstakingNfts] = useState<ISelectedNft[]>([]);
  const [selectedUnstakedWalletType, setSelectedUnstakedWalletType] = useState<WalletTypes>(WalletTypes.allWallets);
  const [selectedStakedWalletType, setSelectedStakedWalletType] = useState<WalletTypes>(WalletTypes.allWallets);

  return (
    <ApeCoinContext.Provider
      value={{
        selectedPoolsView,
        selectedRewardsView,
        selectedTab,
        selectedRewardsViewType,
        selectedStakingNfts,
        selectedUnstakingNfts,
        selectedHistoryViewType,
        selectedStakedWalletType,
        selectedUnstakedWalletType,
        setSelectedUnstakingNfts,
        setSelectedRewardsViewType,
        setSelectedTab,
        setSelectedPoolsView,
        setSelectedRewardsView,
        setSelectedStakingNfts,
        setSelectedHistoryViewType,
        setSelectedStakedWalletType,
        setSelectedUnstakedWalletType,
      }}
    >
      {children}
    </ApeCoinContext.Provider>
  );
};

export const useApeCoinContext = () => useContext(ApeCoinContext);

export const useSelectedStakingNftsContext = () => {
  const { selectedStakingNfts, setSelectedStakingNfts } = useContext(ApeCoinContext);
  const addItem = (nft: ISelectedNft) => {
    setSelectedStakingNfts(prev => [...prev, nft]);
  };
  const removeItem = ({
    collectionAddress: _collectionAddress,
    tokenId: _tokenId,
  }: {
    collectionAddress: string;
    tokenId: string;
  }) => {
    setSelectedStakingNfts(prev =>
      prev.filter(({ address, tokenId }) => {
        return !(address.toLowerCase() === _collectionAddress.toLowerCase() && tokenId === _tokenId);
      }),
    );
  };
  const removeAll = () => {
    setSelectedStakingNfts([]);
  };
  const toggleItem = (nft: ISelectedNft) => {
    if (
      selectedStakingNfts.find(
        ({ address, tokenId }) => address.toLowerCase() === nft.address.toLowerCase() && tokenId === nft.tokenId,
      )
    ) {
      removeItem({
        collectionAddress: nft.address,
        tokenId: nft.tokenId,
      });
    } else {
      addItem(nft);
    }
  };
  const addItems = (nfts: Array<ISelectedNft>) => {
    setSelectedStakingNfts(nfts);
  };
  return {
    items: selectedStakingNfts,
    addItem,
    removeItem,
    removeAll,
    toggleItem,
    addItems,
    setItems: setSelectedStakingNfts,
  };
};

export const useSelectedUnstakingNftsContext = () => {
  const { selectedUnstakingNfts, setSelectedUnstakingNfts } = useContext(ApeCoinContext);
  const addItem = (nft: ISelectedNft) => {
    setSelectedUnstakingNfts(prev => [...prev, nft]);
  };
  const removeItem = ({
    collectionAddress: _collectionAddress,
    tokenId: _tokenId,
  }: {
    collectionAddress: string;
    tokenId: string;
  }) => {
    setSelectedUnstakingNfts(prev =>
      prev.filter(({ address, tokenId }) => {
        return !(address.toLowerCase() === _collectionAddress.toLowerCase() && tokenId === _tokenId);
      }),
    );
  };
  const removeAll = () => {
    setSelectedUnstakingNfts([]);
  };
  const toggleItem = (nft: ISelectedNft) => {
    if (
      selectedUnstakingNfts.find(
        ({ address, tokenId }) => address.toLowerCase() === nft.address.toLowerCase() && tokenId === nft.tokenId,
      )
    ) {
      removeItem({
        collectionAddress: nft.address,
        tokenId: nft.tokenId,
      });
    } else {
      addItem(nft);
    }
  };
  const addItems = (nfts: Array<ISelectedNft>) => {
    setSelectedUnstakingNfts(nfts);
  };
  return {
    items: selectedUnstakingNfts,
    addItem,
    removeItem,
    removeAll,
    toggleItem,
    addItems,
    setItems: setSelectedUnstakingNfts,
  };
};
