import { useContext, useMemo } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, Title, TitleContainer, TitleDescription } from "@cyanco/components/theme/v3";

import { bigNumToFloat, formatCompactNumber, numberWithCommas } from "@/utils";

import { useAppContext } from "../AppContextProvider";
import { ThemeContext } from "../ThemeContextProvider";
import { useVaultDataContext, useVaultStats } from "./VaultDataProvider";
import { AvailableVaults } from "./components/AvailableVaults";
import { VaultCards } from "./components/VaultCard";

export const Vaults = () => {
  const { isLightTheme } = useContext(ThemeContext);
  const { cyanStats, usdPrice } = useAppContext();
  const { vaults } = useVaultDataContext();
  const { totalStakers } = useVaultStats();

  const formattedStakers = useMemo(() => {
    return formatCompactNumber(Math.round((totalStakers + cyanStats.numOfBorrowersWithActiveLoan) / 5) * 5);
  }, [totalStakers, cyanStats.numOfBorrowersWithActiveLoan]);

  const earnedAmount = useMemo(() => {
    const earned = vaults.reduce((acc, vault) => {
      const earnedAmount =
        (bigNumToFloat(vault.price ?? 0, vault.decimals) - 1) *
        (usdPrice[vault.currency] ?? 0) *
        bigNumToFloat(vault.totalValueLocked ?? 0, vault.decimals);
      return acc + earnedAmount;
    }, 0);
    return earned > 999_999 ? formatCompactNumber(earned) : numberWithCommas(earned);
  }, [vaults, usdPrice]);

  return (
    <Container direction="column">
      <TopContainer w="100%">
        <Hidden
          tabletDown
          style={{
            height: "100%",
          }}
        >
          <TitleContainer justifyContent="center" gap="1.5rem">
            <Flex direction="column">
              <Title>{`Stake Crypto,`} </Title>
              <Title>{`Earn Yield`} </Title>
            </Flex>
            <Flex pb="1.5rem">
              <div
                style={{
                  width: "70%",
                }}
              >
                <TitleDescription color="gray0">
                  Stake into Vaults to yield on lent crypto to fund NFT loans, and join the {formattedStakers}+ stakers
                  who have{" "}
                  <TitleDescription style={{ color: !isLightTheme ? "cyan" : "#00D5D5" }}>
                    earned ${earnedAmount}
                  </TitleDescription>{" "}
                  in interest so far.
                </TitleDescription>
              </div>
            </Flex>
          </TitleContainer>
        </Hidden>
        <VaultCards />
      </TopContainer>
      <AvailableVaults />
    </Container>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1240px) / 2);
  gap: 4rem;
  padding: 0 0 5rem 0;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 4 }, { [breakpoints.tablet]: 2 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 50px" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 0 5rem 0" },
    { [breakpoints.tablet]: "1rem 0.5rem 6rem 0.5rem" },
  ])}
`;

const TopContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 340 }], "min-width")}
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])}
`;
