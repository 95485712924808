import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme/Box";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SkeletonLine, SortingArrows, Text } from "@cyanco/components/theme/v3";
import { Send } from "@cyanco/components/theme/v3/icons";
import { Etherscan } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { useSortableHeader } from "@/hooks/useSortableHeader";
import {
  bigNumToFixedStr,
  bigNumToFloat,
  displayInUSD,
  getChainExplorerURL,
  jumpToLink,
  numberWithCommas,
  shortenAddress,
  shortenName,
} from "@/utils";
import { isMobile } from "@/utils/userAgent";

import { IPosition } from "../../../Vault/types";
import { useVaultTokensTabContext } from "../../AccountPageContext";
import { HeaderItemWrapper } from "../PositionView/PositionRow";
import { WalletContainer } from "../WalletView/NftRow";
import { WalletSelector } from "../WalletView/Selectors";

export const VaultPositionListHeader = ({
  sortedVaultPositions,
  updateSortedVaultPositions,
}: {
  sortedVaultPositions: { position: IPosition; vault: IVault }[];
  updateSortedVaultPositions: (positions: { position: IPosition; vault: IVault }[]) => void;
}) => {
  const { setSelectedVaultWalletType, selectedVaultWalletType } = useVaultTokensTabContext();
  const getBalance = (c: { position: IPosition; vault: IVault }) => {
    return c.position && c.vault ? bigNumToFloat(c.position.balance, c.vault.decimals) * c.vault.priceUsd : 0;
  };
  const { sort, sortDirectionDesc, sortName } = useSortableHeader<{ position: IPosition; vault: IVault }>(
    [c => getBalance(c)],
    "balance",
  );

  const handleHeaderClick = (value: ((item: { position: IPosition; vault: IVault }) => any)[], sortName: string) => {
    const sortedData = sort(value, sortedVaultPositions, sortName);
    updateSortedVaultPositions(sortedData);
  };
  return (
    <HeaderContainer>
      <HeaderItemWrapper>
        <Flex gap="5px" onClick={() => handleHeaderClick([c => (c.vault ? c.vault.symbol : "")], "token")}>
          <RowText color="secondary" weight="600">{`Token`}</RowText>
          <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="token" />
        </Flex>
      </HeaderItemWrapper>
      <Hidden tabletDown>
        <WalletSelector
          selectedWalletType={selectedVaultWalletType}
          setSelectedWalletType={setSelectedVaultWalletType}
        />
      </Hidden>
      <Flex gap="5px" onClick={() => handleHeaderClick([c => (c.vault ? c.vault.price : 0)], "price")}>
        <RowText weight="600" color="secondary">{`Price`}</RowText>
        <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="price" />
      </Flex>
      <Flex gap="5px" onClick={() => handleHeaderClick([c => getBalance(c)], "balance")}>
        <RowText weight="600" color="secondary">{`Balance`}</RowText>
        <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="balance" />
      </Flex>
      <Hidden tabletDown>
        <RowText weight="600" color="secondary">{`Transfer`}</RowText>
      </Hidden>
      <RowText weight="600" color="secondary">{`Explorer`}</RowText>
      <Hidden laptopDown>
        <RowText weight="600" color="secondary">{`Sell`}</RowText>
      </Hidden>
    </HeaderContainer>
  );
};
export const VaultPositionRow = ({
  vault,
  position,
  account,
  onClick,
  onTransfer,
  onClickSell,
}: {
  vault: IVault;
  position: IPosition & { balanceInUSD: number };
  account: string;
  onClick: () => void;
  onTransfer: () => void;
  onClickSell: () => void;
}) => {
  const theme = useTheme();
  return (
    <Row>
      <Flex alignItems="center" gap="20px" onClick={onClick}>
        <StyledImg style={{ backgroundColor: vault.colorCode }} />
        <Flex direction="column">
          <RowText color={"secondary"} weight="500">
            {vault.symbol}
          </RowText>
          <RowText sub color={"gray0"}>
            {isMobile ? shortenName(vault.name) : shortenName(vault.name, 25, 20)}
          </RowText>
        </Flex>
      </Flex>
      <Hidden tabletDown>
        {position.isCyanWallet ? (
          <WalletContainer>
            <div
              style={{
                background: theme.colors.cyan,
                width: "18px",
                height: "18px",
                minWidth: "18px",
                minHeight: "18px",
                borderRadius: "50%",
              }}
            />
            <RowText color="secondary" weight="500">
              Cyan Wallet
            </RowText>
          </WalletContainer>
        ) : (
          <WalletContainer>
            <Jazzicon seed={jsNumberForAddress(account)} diameter={18} />
            <RowText color="secondary" weight="500">
              {shortenAddress(account)}
            </RowText>
          </WalletContainer>
        )}
      </Hidden>
      <Flex direction="column">
        <RowText color={"secondary"} weight="500">
          {vault.price ? `${numberWithCommas(bigNumToFloat(vault.price, vault.decimals), 2)} ${vault.currency}` : "-"}
        </RowText>
        <RowText sub color={"gray0"}>
          {displayInUSD(vault.priceUsd)}
        </RowText>
      </Flex>
      <Flex direction="column">
        <RowText color={"secondary"} weight="500">
          {numberWithCommas(bigNumToFixedStr(position.balance, 2, vault.decimals), 2)} {vault.symbol}
        </RowText>
        <RowText sub color={"gray0"}>
          {displayInUSD(position.balanceInUSD)}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <ImgButton
          onClick={e => {
            e.stopPropagation();
            onTransfer();
          }}
        >
          <Send color={theme.colors.secondary} height={18} width={18} />
        </ImgButton>
      </Hidden>
      <ImgButton
        onClick={e => {
          e.stopPropagation();
          jumpToLink(`${getChainExplorerURL(vault.chainId)}/token/${vault.contractTokenAddress}`);
        }}
      >
        <img src={Etherscan} height={18} width={18} />
      </ImgButton>
      <Hidden laptopDown>
        <SmallButton
          onClick={e => {
            e.stopPropagation();
            onClickSell();
          }}
        >
          <Box p={"0.1rem 0.3rem"}>
            <SmallButtonText color="black" weight="700">{`Sell`}</SmallButtonText>
          </Box>
        </SmallButton>
      </Hidden>
    </Row>
  );
};

export const VaultPositionRowLoading = () => {
  return (
    <Row>
      <Flex alignItems="center" gap="8px">
        <RowLoadingText />
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" gap="2px">
          <RowLoadingText />
        </Flex>
      </Hidden>
      <Flex direction="column" gap="2px">
        <RowLoadingText />
        <RowLoadingText sub />
      </Flex>
      <Flex direction="column" gap="2px">
        <RowLoadingText />
        <RowLoadingText sub />
      </Flex>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
      <RowLoadingText />
    </Row>
  );
};

const Row = styled.div<{ disabled?: boolean }>`
  display: grid;
  column-gap: 1rem;
  align-items: center;
  background-color: transparent;
  grid-template-columns: 2.5fr 1.5fr 1.5fr 1.5fr 0.8fr 0.8fr 0.6fr;
  padding: 13px 15px 13px 15px;
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.laptop]: "2.5fr 1.5fr 1.5fr 1.5fr 0.8fr 0.8fr" },
    { [breakpoints.tablet]: "2.5fr 1.5fr 1.5fr 0.8fr" },
  ])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "7px 10px" }])};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;

export const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

const SmallButtonText = styled(Text)`
  font-size: 14px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 12 }, { [breakpoints.mobile]: 10 }])};
`;

const HeaderContainer = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${({ theme }) => theme.colors.primary};
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 7px 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const StyledImg = styled.div`
width: 36px;
height: 36px;
${getStyleWithMediaQuery("min-width", "px", [{ [breakpoints.tablet]: 24 }])}
${getStyleWithMediaQuery("min-height", "px", [{ [breakpoints.tablet]: 24 }])}
${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.tablet]: 24 }])}
${getStyleWithMediaQuery("max-height", "px", [{ [breakpoints.tablet]: 24 }])}
border-radius: 50%;
} `;

const RowLoadingText = styled(SkeletonLine)<{ sub?: boolean }>`
  width: 50%;
  height: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("height", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])};
`;

const SmallButton = styled(Button)`
  border-radius: 5px;
  border-width: 2px;
`;

const ImgButton = styled.div`
  cursor: pointer;
`;
