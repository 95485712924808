import { BigNumber } from "ethers";
import { useMemo } from "react";
import { CheckCircle, HelpCircle } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Box, Flex, Tooltip, TooltipText } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Card, SkeletonLine, SystemMessage, Text } from "@cyanco/components/theme/v3";

import { IOffer } from "@/apis/early-unwind/types";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, numberWithCommas } from "@/utils";
import { REQUEST_MORE_INFO } from "@/utils/error/msgs";

import { PlanCreationDecimalFormatMap } from "../../PlanCreation/types";
import { IPaymentsMapped } from "../PlanRepayment";
import { DueCircle, PaymentStep, StepperContainer } from "../RegularPayment";

export type ISellNftDetailProps = {
  plan: {
    buyOffer: IOffer;
    payments: IPaymentsMapped[];
    interestRate: number;
    amountToComplete: BigNumber;
    currency: ICurrency;
    totalNumberOfPayments: number;
    term: number;
    isBnpl: boolean;
  };
  onSell: (a: boolean) => void;
  isProcessing: boolean;
  isPlanDueDateClose: boolean;
};

export const SellNft: React.FC<ISellNftDetailProps> = ({ plan, isProcessing, onSell, isPlanDueDateClose }) => {
  const theme = useTheme();
  const { currency, payments, buyOffer, interestRate, amountToComplete } = plan;
  const receiveAmount = useMemo(() => {
    const sellingAmount = bigNumToFloat(buyOffer.price.amount, buyOffer.price.currency.decimals);
    return sellingAmount - bigNumToFloat(amountToComplete, currency.decimal);
  }, [buyOffer, amountToComplete]);
  const formatNumber = useMemo(() => {
    if (bigNumToFloat(plan.amountToComplete, currency.decimal) < 1) {
      return 4;
    }
    return PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 4;
  }, [plan.currency.decimal]);

  const isUserPaying = receiveAmount < 0;
  return (
    <Flex direction="column" gap="10px">
      {!isPlanDueDateClose && (
        <MsgBox gap="2px" variant={isUserPaying ? "warning" : "success"}>
          <Flex direction="column" gap="2px">
            <Flex alignItems="center" gap="0.2rem">
              <CheckCircle width={13} height={13} color="black" strokeWidth={2.5} />
              <Text size="md" weight="700" color="black">
                {isUserPaying ? "NFT is below what you owe" : "NFT is above what you owe"}
              </Text>
            </Flex>
          </Flex>
          <Text size="xs" weight="500" color="black">
            {isUserPaying
              ? `Pay off your loan and sell the NFT in one transaction. The best bid available on your NFT is lower than what
            you currently owe. What you pay is the difference after deducting the amount you receive from selling the
            NFT from the remaining loan amount.`
              : `Pay off your loan and sell the NFT in one transaction. Available when the highest bid on Blur or OpenSea
            exceeds the amount owed. What you receive is the net amount after deducting marketplace fees and collection
            royalties.`}
          </Text>
        </MsgBox>
      )}
      {isPlanDueDateClose && (
        <SystemMessage
          variant="warning"
          title={`Notice`}
          msg={
            <Text size="xs" weight="500" color="black">
              {`The Sell NFT option is automatically turned off 30 minutes prior to the loan expiring to prevent any issues with execution.`}
            </Text>
          }
          description={REQUEST_MORE_INFO}
        />
      )}

      <Card p={"10px 8px"} hover>
        <Flex direction="column" gap="7px">
          <Text size="sm" weight="600" color="secondary">
            {`Sell NFT and ${isUserPaying ? "pay" : "receive"} ${numberWithCommas(
              Math.abs(receiveAmount),
              formatNumber,
            )} ${buyOffer.price.currency.symbol}`}
          </Text>
          <StepperContainer>
            {payments.map(payment => (
              <PaymentStep
                key={`${payment.txnHash}-${payment.isRevival ? "revival" : "payment"}}`}
                isPaid
                text={payment.isRevival ? `Revival Penalty` : `Already Paid`}
                paymentAmount={payment.amount}
                fixNumber={4}
                currency={currency}
              />
            ))}
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="8px">
                <DueCircle isNextPayment />
                <Flex gap="3px" alignItems="center">
                  <Text size="xs" weight="500" color="secondary">
                    Sell NFT at best bid
                  </Text>
                  <Tooltip>
                    <HelpCircle height={11} width={11} color={theme.colors.secondary} />
                    <TooltipText showArrow position="top" top="-100px" right="-52.2px" style={{ width: "100px" }}>
                      <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                        <div>
                          This is the best bid on Blur or OpenSea. The price shown is net of marketplace fees and
                          royalty fees.
                        </div>
                      </Text>
                    </TooltipText>
                  </Tooltip>
                </Flex>
              </Flex>
              <Text size="xs" weight="500" color="secondary">
                {`+ ${numberWithCommas(
                  bigNumToFixedStr(
                    buyOffer.price.amount,
                    buyOffer.price.currency.decimals,
                    buyOffer.price.currency.decimals,
                  ),
                  formatNumber,
                )} ${currency.symbol}`}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="8px">
                <DueCircle isLast isNextPayment />
                <Text size="xs" weight="500" color="secondary">
                  {`Pay off loan`}
                </Text>
              </Flex>
              <Text size="xs" weight="500" color="secondary">
                {`- ${numberWithCommas(bigNumToFixedStr(amountToComplete, 4, currency.decimal), formatNumber)} ${
                  currency.symbol
                }`}
              </Text>
            </Flex>
          </StepperContainer>
          <Flex justifyContent="space-between">
            <Text size="xs" weight="500" color="gray0">
              {`${(interestRate / 100).toFixed(2)}% interest`}
            </Text>
            <Text size="xs" weight="500" color={isUserPaying ? "red" : "green"}>
              {`${isUserPaying ? "You pay" : "You receive"} ${numberWithCommas(
                Math.abs(receiveAmount),
                formatNumber,
              )} ${plan.buyOffer.price.currency.symbol}`}
            </Text>
          </Flex>
        </Flex>
      </Card>
      <Card p={"15px"} hover>
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4px" w="100%">
            <Text size="sm" weight="500" color="secondary">
              {isUserPaying ? "Pay to close loan:" : "Sell NFT and receive:"}
            </Text>
            <Text size="lg" weight="600" color={isUserPaying ? "red" : "green"}>
              {`${numberWithCommas(Math.abs(receiveAmount), formatNumber)} ${buyOffer.price.currency.symbol}`}
            </Text>
          </Flex>
          <Box w="120px">
            <Button
              loading={isProcessing}
              disabled={isProcessing || isPlanDueDateClose}
              onClick={() => onSell(isUserPaying)}
            >
              {isUserPaying ? `Pay` : `Sell`}
            </Button>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};

export const SellNftDefault: React.FC<{
  plan: {
    payments: IPaymentsMapped[];
    interestRate: number;
    amountToComplete: BigNumber;
    currency: ICurrency;
  };
  isOfferLoading: boolean;
}> = ({ plan, isOfferLoading }) => {
  const theme = useTheme();
  const { currency, payments, interestRate, amountToComplete } = plan;
  const formatNumber = useMemo(() => {
    if (bigNumToFloat(plan.amountToComplete, currency.decimal) < 1) {
      return 4;
    }
    return PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 4;
  }, [plan.currency.decimal]);

  return isOfferLoading ? (
    <Flex direction="column" gap="10px">
      <SkeletonLineMobile w="100%" h={"127px"} borderRadius="10px" />
      <Card p={"15px"} hover>
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4px" w="100%">
            <Text size="sm" weight="500" color="secondary">
              Sell NFT and receive
            </Text>
            <Text size="lg" weight="600" color="green">
              -
            </Text>
          </Flex>
          <Box w="120px">
            <Button disabled={true}>Sell</Button>
          </Box>
        </Flex>
      </Card>
    </Flex>
  ) : (
    <Flex direction="column" gap="10px">
      <SystemMessage
        title="No Bids Available"
        msg="This NFT currently has no available bids. Please check back later when bids become available."
        variant="warning"
      />
      <Card p={"10px 8px"} hover>
        <Flex direction="column" gap="7px">
          <Text size="sm" weight="600" color="gray0">
            {`Sell NFT and receive -`}
          </Text>
          <StepperContainer>
            {payments.map(payment => (
              <PaymentStep
                key={`${payment.txnHash}-${payment.isRevival ? "revival" : "payment"}}`}
                isPaid
                text={payment.isRevival ? `Revival Penalty` : `Already Paid`}
                paymentAmount={payment.amount}
                fixNumber={4}
                currency={currency}
              />
            ))}
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="8px">
                <DueCircle isNextPayment />
                <Flex gap="3px" alignItems="center">
                  <Text size="xs" weight="500" color="gray0">
                    Sell NFT at best bid
                  </Text>
                  <Tooltip>
                    <HelpCircle height={11} width={11} color={theme.colors.gray0} />
                  </Tooltip>
                </Flex>
              </Flex>
              <Text size="xs" weight="500" color="gray0">
                {`+  -${currency.symbol}`}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center" gap="8px">
                <DueCircle isLast isNextPayment />
                <Text size="xs" weight="500" color="gray0">
                  {`Pay off loan`}
                </Text>
              </Flex>
              <Text size="xs" weight="500" color="gray0">
                {`- ${numberWithCommas(bigNumToFixedStr(amountToComplete, 4, currency.decimal), formatNumber)} ${
                  currency.symbol
                }`}
              </Text>
            </Flex>
          </StepperContainer>
          <Flex justifyContent="space-between">
            <Text size="xs" weight="500" color="gray0">
              {`${(interestRate / 100).toFixed(2)}% interest`}
            </Text>
            <Text size="xs" weight="500" color="gray0">
              You receive -
            </Text>
          </Flex>
        </Flex>
      </Card>
      <Card p={"15px"} hover>
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4px" w="100%">
            <Text size="sm" weight="500" color="secondary">
              Sell NFT and receive:
            </Text>
            <Text size="lg" weight="600" color="gray0">
              -
            </Text>
          </Flex>
          <Box w="120px">
            <Button disabled>Sell</Button>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};

const MsgBox = styled(Flex)<{
  variant: "success" | "warning";
}>`
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 1rem;
  background-color: ${({ theme, variant }) => {
    return variant === "success" ? theme.colors.green : theme.colors.red;
  }}};
  flex-direction: column;
`;

const SkeletonLineMobile = styled(SkeletonLine)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`;
