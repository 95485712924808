import styled from "styled-components";

import { Loader, Text } from "@cyanco/components/theme/v3";

import { IWalletConnectors, SUPPORTED_WALLETS } from "../../constants/wallet";
import Option from "./Option";

const PendingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > * {
    width: 100%;
  }
`;

const LoadingMessage = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  margin-bottom: 1rem;
  width: 100%;
  color: ${({ error }) => (error ? "red" : "inherit")};
  border: ${({ error }) => (error ? "1px solid red" : "1px solid #464646")};
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: 100%;
  */ & > * {
    width: 100%;
  }
`;

const ErrorGroup = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0 0.75rem 0;
  width: 100%;
`;

const ErrorButton = styled.div`
  border-radius: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  color: grey;
  background-color: ${({ theme }) => theme.colors.gray10};
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  padding: 0.75rem;
  user-select: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.gray20};
  }
`;

const PendingView = ({
  connector,
  error = false,
  setPendingError,
  tryActivation,
}: {
  connector?: IWalletConnectors;
  error?: boolean;
  setPendingError: (error: boolean) => void;
  tryActivation: (connector: IWalletConnectors) => void;
}) => {
  return (
    <PendingSection>
      <LoadingMessage error={error}>
        <LoadingWrapper>
          {error ? (
            <ErrorGroup>
              <Text weight="700" style={{ color: "red" }}>
                {`Error connecting`}
              </Text>
              <ErrorButton
                onClick={() => {
                  setPendingError(false);
                  connector && tryActivation(connector);
                }}
              >
                <Text color="secondary">Try Again</Text>
              </ErrorButton>
            </ErrorGroup>
          ) : (
            <>
              <></>
              <Text style={{ color: "grey", padding: "1rem 0 1rem 0" }} weight="500">
                <Loader size="12px" stroke="#6d6d6d" />
                &nbsp;{`Initializing`}...
              </Text>
            </>
          )}
        </LoadingWrapper>
      </LoadingMessage>
      {Object.keys(SUPPORTED_WALLETS).map(key => {
        const option = SUPPORTED_WALLETS[key];
        if (option.connector === connector) {
          return (
            <Option
              id={`connect-${key}`}
              key={key}
              clickable={false}
              color={option.color}
              header={option.name}
              subheader={option.description}
              icon={option.iconURL}
              hooks={option.hooks}
            />
          );
        }
        return null;
      })}
    </PendingSection>
  );
};

export default PendingView;
