import { BigNumber } from "ethers";
import { HelpCircle } from "react-feather";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme";
import { Text, Toggler, Tooltip, TooltipText } from "@cyanco/components/theme/v3";

import { bigNumToFloat, formatCompactNumber, numberWithCommas } from "@/utils";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";
import { ApeCoinStakingInput } from "../../common";

export const ApeCoinStaking = ({
  stakingAmount,
  cyanWalletStakingAmount,
  rewardStakeToCyanVault,
  apeCoinBalance,
  onMainInputChange,
  onCyanInputChange,
  setRewardStakeToCyanWallet,
}: {
  stakingAmount?: string;
  cyanWalletStakingAmount?: string;
  rewardStakeToCyanVault: boolean;
  apeCoinBalance: {
    stakedAmount: null | BigNumber;
    earnedAmount: BigNumber | null;
    mainWalletMax: BigNumber | null;
    cyanWalletMax: BigNumber | null;
    allowance: BigNumber | null;
  };
  setRewardStakeToCyanWallet: (a: boolean) => void;
  onMainInputChange: (a: string) => void;
  onCyanInputChange: (a: string) => void;
}) => {
  const theme = useTheme();
  const cyanWallet = useCyanWallet();
  const { poolsWithoutBorrow } = useApeCoinStatsContext();

  const cyanBalance = apeCoinBalance.cyanWalletMax ? bigNumToFloat(apeCoinBalance.cyanWalletMax) : 0;
  const mainBalance = apeCoinBalance.mainWalletMax ? bigNumToFloat(apeCoinBalance.mainWalletMax) : 0;
  const estimatedApy = formatCompactNumber(poolsWithoutBorrow.COIN.apy);

  return (
    <Flex direction="column" gap="1.2rem">
      <Flex direction="column" gap="0.3rem">
        <Flex justifyContent="space-between" p="0 2px">
          <Text color="gray0" size="xs" weight="600">
            {`Main Wallet`}
          </Text>
          <Text color="gray0" size="xs" weight="500">
            {`${`Balance`}: ${numberWithCommas(mainBalance, 2)} APE`}
          </Text>
        </Flex>
        <ApeCoinStakingInput
          disabled={false}
          stakingAmount={stakingAmount}
          onInputChange={onMainInputChange}
          max={mainBalance}
        />
      </Flex>
      {cyanWallet && (
        <Flex direction="column" gap="0.3rem">
          <Flex justifyContent="space-between" p="0 2px">
            <Text color="gray0" size="xs" weight="600">
              {`Cyan Wallet`}
            </Text>
            <Text color="gray0" size="xs" weight="500">
              {`${`Balance`}: ${numberWithCommas(cyanBalance, 2)} APE`}
            </Text>
          </Flex>
          <ApeCoinStakingInput
            disabled={false}
            onInputChange={onCyanInputChange}
            stakingAmount={cyanWalletStakingAmount}
            max={cyanBalance}
          />
        </Flex>
      )}
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap="4px">
          <Text size="md" weight="600" color="secondary">
            {`Reward APE to Cyan Vault`}
          </Text>
          <Tooltip>
            <HelpCircle height={16} width={16} color={theme.colors.secondary} />
            <TooltipText showArrow position="top" top="-155px" right="-70px" style={{ width: "150px" }}>
              <Flex direction="column" gap="7px">
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  <div>{`Rewarded APE will be staked into the Cyan ApeCoin Staking Vault. If you wish to retain the voting rights for the APE you earn, please turn this option off.`}</div>
                </Text>
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  <div>{`By turning this option off, your rewarded APE will be staked into the Horizen Labs contract and you will retain voting rights.`}</div>
                </Text>
              </Flex>
            </TooltipText>
          </Tooltip>
        </Flex>
        <Toggler value={rewardStakeToCyanVault} onChange={setRewardStakeToCyanWallet} size="sm" />
      </Flex>

      <StakedBox>
        <Flex direction="column" w="100%" gap="0.3rem">
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Total Staked`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {bigNumToFloat(apeCoinBalance.stakedAmount || BigNumber.from("0"))} APE
            </Text>
          </Flex>
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Estimated APY`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {estimatedApy}%
            </Text>
          </Flex>
        </Flex>
      </StakedBox>
    </Flex>
  );
};

const StakedBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 1rem 0.8rem;
`;
