import { useEffect, useState } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Card, NotFound } from "@cyanco/components/theme/v3";
import { Text } from "@cyanco/components/theme/v3/core";

import { ICollectionBe } from "@/apis/collection/types";
import { bigNumToFloat } from "@/utils";

import { useAppContext } from "../../../AppContextProvider";
import { useBnplSupportedCollections } from "../../BnplDataContext";
import { useBnplSelectors } from "../../BnplPageContext";
import { CollectionSelectors } from "./CollectionSelectors";
import { CollectionStatsHeader, CollectionStatsRow, CollectionStatsRowLoading } from "./CollectionStats";

// A value over 1 is a positive gain, under 1 is a negative loss. ex: 1.1 means 10% increase, 0.9 means 10% decrease.
export const calculateReservoirChangePercent = (changedBy: number | null): number | null => {
  if (changedBy === null) return null;
  return (changedBy - 1) * 100;
};

export const calculateFloorPriceChangePercent = (oldPrice: string | undefined, currentPrice: string): number => {
  if (oldPrice) {
    return ((bigNumToFloat(oldPrice) - bigNumToFloat(currentPrice)) / bigNumToFloat(oldPrice)) * 100;
  }
  return 0;
};
export const AvailableCollections = () => {
  const { selectedCurrency } = useBnplSelectors();
  const { collectionsFiltered, collectionsLoading } = useBnplSupportedCollections();
  const { currencySymbol, usdPrice } = useAppContext();
  const [collectionsSorted, setCollectionsSorted] = useState<ICollectionBe[]>(collectionsFiltered);
  useEffect(() => {
    setCollectionsSorted(collectionsFiltered);
  }, [collectionsFiltered]);

  const updateCollectionSorted = (collections: ICollectionBe[]) => {
    setCollectionsSorted([...collections]);
  };

  return (
    <Container direction="column">
      <CollectionText color="secondary">{`Collections available on Cyan`}</CollectionText>
      <ListViewContainer>
        <CollectionSelectors />
        <ListViewBox>
          <Header isStickyHeader={true}>
            <CollectionStatsHeader
              setCollectionsSorted={updateCollectionSorted}
              collectionsSorted={collectionsSorted}
              selectedCurrency={selectedCurrency}
              defaultCurrencySymbol={currencySymbol}
              usdPrice={usdPrice}
            />
          </Header>
          <ContainerBox>
            {collectionsLoading ? (
              Array.from(Array(3).keys()).map(loader => <CollectionStatsRowLoading key={loader} />)
            ) : collectionsSorted.length === 0 ? (
              <NotFound msg="No collections found" />
            ) : (
              collectionsSorted.map((collection, index) => {
                return (
                  <CollectionStatsRow
                    collection={{
                      ...collection,
                      index,
                    }}
                    defaultCurrencySymbol={currencySymbol}
                    selectedCurrency={selectedCurrency}
                    usdPrice={usdPrice}
                    key={collection.address}
                  />
                );
              })
            )}
          </ContainerBox>
        </ListViewBox>
      </ListViewContainer>
    </Container>
  );
};

const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

const MainBox = styled(Card)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  margin: 1px;
  border: none;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-radius: 10px;
    box-shadow: none;
  }
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &:first-child {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
`;

const ContainerBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top: none;
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;

const CollectionText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 32 },
    { [breakpoints.tablet]: 24 },
    { [breakpoints.mobile]: 18 },
  ])};
`;

const Container = styled(Flex)`
  gap: 1.5rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])}
`;

const ListViewContainer = styled(Flex)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const Header = styled.div<{ isStickyHeader: boolean }>`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 170px;
  }
  position: ${({ isStickyHeader }) => (isStickyHeader ? "sticky" : "unset")};
  ${getStyleWithMediaQuery("position", "", [{ [breakpoints.mobile]: "static" }])};
  align-items: end;
  top: 150px;
  background: ${({ theme }) => theme.colors.primary};
  transform-style: preserve-3d;
  z-index: 5;
  ${({ isStickyHeader, theme }) => {
    if (isStickyHeader) {
      return `:before {
        background-color:  ${theme.backgroundColor};
        content: "";
        display: block;
        position: absolute;
        transform: translateZ(-1px);
        top: -15px;
        height: calc(100% + 15px);
        left: -100px;
        @media only screen and (max-width: ${breakpoints.tablet}px) {
          left: -20px;
        }
        width: 100vw;
      }`;
    }
  }}
`;
