import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, Text, Title, TitleContainer, TitleDescription } from "@cyanco/components/theme/v3";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { useToggleModal } from "@/state/application/hooks";
import { ApplicationModal } from "@/state/application/reducer";

import { formatCompactNumber, jumpToLink } from "../../utils";
import { useAppContext } from "../AppContextProvider";
import { ThemeContext } from "../ThemeContextProvider";
import { LoanOffers } from "./LoanOffers";
import { LoanView, useLoanPageSelectors } from "./LoanPageProvider";
import { LoanRanges } from "./LoanRange";
import { Selectors } from "./Selectors";
import { NftView } from "./UserNfts";

export const Loans = () => {
  const { account } = useWeb3React();
  const toggleWallet = useToggleModal(ApplicationModal.WALLET);
  const navigate = useNavigate();
  const { selectedLoanView } = useLoanPageSelectors();
  const { isLightTheme } = useContext(ThemeContext);
  const { cyanStats } = useAppContext();

  const totalLoanedAmountFormatted = formatCompactNumber(
    cyanStats.loanedAmountBnplsUsd + cyanStats.loanedAmountPawnsUsd,
  );
  const formattedUsers = useMemo(() => {
    return formatCompactNumber(Math.round(cyanStats.userCount / 10) * 10);
  }, [cyanStats.userCount]);

  return (
    <Container>
      <TopContainer w="100%">
        <Hidden
          tabletDown
          style={{
            height: "100%",
          }}
        >
          <TitleContainer justifyContent="space-between">
            <Title>{`Instant NFT Loans`} </Title>
            <Flex pb="2rem">
              <div
                style={{
                  flexGrow: 1,
                  width: 0,
                }}
              >
                <TitleDescription color="gray0">
                  With the Cyan Wallet, get a loan on your NFT and use it during the loan. Join {formattedUsers}+ users
                  and{" "}
                  <TitleDescription style={{ color: !isLightTheme ? "cyan" : "#00D5D5" }}>
                    ${totalLoanedAmountFormatted}+ in loan volume
                  </TitleDescription>{" "}
                  to date.
                </TitleDescription>
              </div>
            </Flex>
            <Flex gap="0.7rem">
              <FilledButton onClick={() => jumpToLink("https://docs.usecyan.com/docs/staking-apecoin")}>
                <Text color="white" size="sm" weight="600">{`ApeCoin Staking`}</Text>
              </FilledButton>
              {account ? (
                <GhostButton variant="ghost" onClick={() => navigate("/account/positions")}>
                  <HoverText color="secondary" size="sm" weight="600">{`Existing Loans`}</HoverText>
                </GhostButton>
              ) : (
                <ConnectButton onClick={toggleWallet}>{`Connect Wallet`}</ConnectButton>
              )}
            </Flex>
          </TitleContainer>
        </Hidden>
        <LoanRanges />
      </TopContainer>
      <Flex direction="column">
        <LoanText color="secondary">{`Start an NFT Loan`}</LoanText>
        <Selectors />
        <div
          style={{
            display: LoanView.assets === selectedLoanView ? "block" : "none",
          }}
        >
          <NftView />
        </div>
        <div
          style={{
            display: LoanView.loanOffers === selectedLoanView ? "block" : "none",
          }}
        >
          <LoanOffers />
        </div>
      </Flex>
    </Container>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1240px) / 2);
  gap: 4rem;
  padding: 0 0 5rem 0;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 2 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 50px" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "1rem 0.5rem 10rem 0.5rem" }])}
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 340 }], "min-width")}
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])}
`;

const HoverText = styled(Text)`
  transition: all 0.2s;
`;
const GhostButton = styled(Button)`
  border: 1px solid ${props => props.theme.colors.secondary};
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  transition: all 0.2s;
  :hover {
    border: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.secondary};
  }
  :hover ${HoverText} {
    color: ${props => props.theme.colors.primary};
  }
`;

const ConnectButton = styled(Button)`
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.5rem;
`;

const FilledButton = styled(Button)`
  border: 1px solid #024ad7;
  background-color: #024ad7;
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  :hover {
    border: 1px solid #024ad7;
    background-color: #024ad7;
    opacity: 0.8;
  }
`;

const LoanText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 24 }, { [breakpoints.mobile]: 18 }])};
`;
