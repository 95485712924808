import { BigNumber } from "ethers";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Card, Text } from "@cyanco/components/theme/v3";

import { ICurrency } from "@/types";
import { bigNumToFloat } from "@/utils";

import { PaymentStep } from "../PlanPayment/RegularPayment";

type IProps = {
  bendDaoAmount: BigNumber;
  cyanAmount: BigNumber;
  currency: ICurrency;
};

export const BendDaoMigrationDetail: React.FC<IProps> = ({ bendDaoAmount, cyanAmount, currency }) => {
  const difference = cyanAmount.sub(bendDaoAmount);
  return (
    <Flex direction="column">
      <Card p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text size="sm" weight="600" color="secondary">
              {`Migration details`}
            </Text>
          </Flex>
          <StepperContainer>
            <PaymentStep
              text={`Current loan`}
              paymentAmount={bendDaoAmount}
              fixNumber={4}
              currency={currency}
              color="gray0"
            />
            <PaymentStep
              text={`New loan amount`}
              paymentAmount={cyanAmount}
              fixNumber={4}
              currency={currency}
              color="gray0"
              isLast
            />
          </StepperContainer>
          <Flex
            justifyContent="flex-end"
            style={{
              paddingTop: "2px",
            }}
          >
            <Text size="xs" weight="500" color="secondary">
              {`Net difference ${bigNumToFloat(difference, currency.decimal).toFixed(4)} ${currency.symbol}`}
            </Text>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-left: none;
  border-right: none;
  padding: 9px 0;
  margin-top: 3px;
  gap: 8px;
`;
