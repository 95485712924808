export const getFlagsForPlanRequiredSteps = ({
  isPlanCompleting,
  isNativeCurrency,
  selectedPayWallet,
  selectedReceiverWallet,
  mainWalletAddress,
}: {
  isPlanCompleting: boolean;
  isNativeCurrency: boolean;
  selectedPayWallet: string;
  selectedReceiverWallet: string;
  mainWalletAddress: string;
}) => {
  // checking if user selected main wallet as payer
  const isPayingFromMainWallet = selectedPayWallet.toLowerCase() === mainWalletAddress.toLowerCase();

  /*  isNftTransferRequired is true if following conditions are met:
   1. plan is completing
   2. user selected main wallet as NFT receiver */
  const isNftTransferRequired =
    isPlanCompleting && selectedReceiverWallet.toLowerCase() === mainWalletAddress.toLowerCase();

  /*  isErc20TransferRequired is true if following conditions are met:
   1. plan is non-native currency
   2. user selected main wallet as payer
   3. NFT will be transferred to main wallet once plan completed */
  const isErc20TransferRequired = !isNativeCurrency && isNftTransferRequired && isPayingFromMainWallet;

  /*  isErc20ApprovalRequired is true if following conditions are met:
   1. plan is non-native currency
   2. user selected main wallet as payer
   3. NFT will be remained in cyan wallet */
  const isErc20ApprovalRequired = !isNativeCurrency && isPayingFromMainWallet && !isNftTransferRequired;

  return {
    isNftTransferRequired,
    isErc20ApprovalRequired,
    isErc20TransferRequired,
    isPayingFromMainWallet,
  };
};
