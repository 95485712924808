import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { NotFound, useModal } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";

import { IP2PLoanOffer } from "@/apis/p2p/types";
import { IUserNft } from "@/apis/user/types";
import { ContainerBox, ListViewBox } from "@/components/Account";
import { ListContainer } from "@/components/Account/components/WalletView/UserNfts";
import { useSupportedCollections } from "@/components/AppContextProvider";
import { NftContainer } from "@/components/P2P/collection/Assets/ListedNfts";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";

import { useUserAssets } from "../LoanPageProvider";
import { OfferTakerCard } from "../asset/OfferTakerCard";
import { LoanOfferHeader, LoanOfferRow, LoanOfferRowLoading } from "./LoanOfferRow";

export const LoanOffers = () => {
  const navigate = useNavigate();
  const { provider, chainId } = useWeb3React();
  const { setModalContent, unsetModal } = useModal();
  const { userAssetsLoading, userAssets } = useUserAssets();
  const { collections, collectionsLoading } = useSupportedCollections();
  const [blockchainTimestamp, setBlockchainTimestamp] = useState(0);
  useEffect(() => {
    const setTime = async () => {
      if (!provider) return;
      const lastBlockNum = await provider.getBlockNumber();
      const lastBlock = await provider.getBlock(lastBlockNum);
      const lastTimestamp = lastBlock.timestamp * 1000;
      setBlockchainTimestamp(lastTimestamp);
    };
    setTime();
  }, [provider]);
  const assetsWithBid = useMemo(() => {
    return userAssets.assets.filter(asset => asset.loanBids.length > 0);
  }, [userAssets]);

  const openOfferModal = (
    nft: IUserNft,
    loanBid: Omit<IP2PLoanOffer, "metadata" | "collectionAddress" | "tokenId">,
  ) => {
    const collection = collections.find(collection => collection.address === nft.address);
    if (!collection) return;
    setModalContent({
      title: `Take NFT Loan Offer`,
      content: (
        <OfferTakerCard
          nft={nft}
          loandData={{
            ...loanBid,
            collectionAddress: nft.address,
            tokenId: nft.tokenId,
          }}
          collection={collection}
        />
      ),
    });
  };
  return (
    <ListContainer>
      <ListViewBox>
        <Header>
          <LoanOfferHeader />
        </Header>
        <ContainerBox>
          {userAssetsLoading || collectionsLoading ? (
            Array.from(Array(6).keys()).map(loader => <LoanOfferRowLoading key={loader} />)
          ) : (
            <>
              {assetsWithBid.length === 0 ? (
                <NotFound msg={`No availabe NFT found.`} />
              ) : (
                <NftContainer role="grid">
                  {assetsWithBid.map(assetWithBid =>
                    assetWithBid.loanBids.map(loanBid => (
                      <LoanOfferRow
                        onClick={() => {
                          unsetModal();
                          navigate(
                            `/loans/${CHAIN_IDS_TO_NAMES[chainId as SupportedChainId]}/${assetWithBid.address}/${
                              assetWithBid.tokenId
                            }`,
                          );
                        }}
                        loanBid={loanBid}
                        onClickBorrow={() => openOfferModal(assetWithBid, loanBid)}
                        nft={assetWithBid}
                        key={`${assetWithBid.address}:${assetWithBid.tokenId}`}
                        blockchainTimestamp={blockchainTimestamp}
                      />
                    )),
                  )}
                </NftContainer>
              )}
            </>
          )}
        </ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};
const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: sticky;
  align-items: end;
  top: 165px;
  transform-style: preserve-3d;
  z-index: 6;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    top: 235px;
  }
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    display: block;
    overflow-anchor: none;
    position: absolute;
    transform: translateZ(-1px);
    height: calc(100% + 15px);
    top: -15px;
    left: -100px;
    @media only screen and (max-width: ${breakpoints.tablet}px) {
      left: -20px;
    }
    z-index: -1;
    width: 100vw;
  }
`;
