export interface IExpiryDate {
  name: string;
  value: number;
}

export const ExpiryOptions: IExpiryDate[] = [
  {
    name: "7 Days",
    value: 604800,
  },
  {
    name: "14 Days",
    value: 1209600,
  },
  {
    name: "30 Days",
    value: 2592000,
  },
];
