import { useContext } from "react";
import styled from "styled-components";

import { Flex, Text } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import DollarSignURL from "../../assets/images/dollarSign.svg";
import PawnCardImg from "../../assets/images/homepage/pawnCard.svg";
import TerminalURL from "../../assets/images/terminal.svg";
import { ThemeContext } from "../ThemeContextProvider";

export const CardsSection = () => {
  const { isLightTheme } = useContext(ThemeContext);
  return (
    <>
      <PlanCardWrapper>
        <PlanCard
          title="Buy Now, Pay Later NFTs"
          description="Finance the purchase of an NFT with only 25% down today."
          value="Shop"
          type="BNPL"
          isLightTheme={isLightTheme}
          link={"/"}
        />
        <PlanCard
          title="NFT Loans"
          description="Get instant loans against your NFT with up to 75% LTV."
          value="Loans"
          type="Pawn"
          isLightTheme={isLightTheme}
          link={"/loans"}
        />
      </PlanCardWrapper>

      <InfoCardWrapper>
        <InfoCard
          title="ApeStaking"
          description="Stake with your ape or $APE, even during financing."
          value="Staking"
          iconUrl={ApeCoin}
          isLightTheme={isLightTheme}
          link={"/ape-coin"}
        />
        <InfoCard
          title="Earn"
          description="Stake ETH, WETH, USDC or POL Token and earn."
          value="Vaults"
          iconUrl={DollarSignURL}
          isLightTheme={isLightTheme}
          link="/vault"
        />
        <InfoCard
          title="Build with Cyan"
          description="Add BNPL or Instant Loans to your marketplace."
          value="SDK + API"
          iconUrl={TerminalURL}
          isLightTheme={isLightTheme}
          link="https://docs.usecyan.com/reference/getting-started-with-your-api"
        />
      </InfoCardWrapper>
    </>
  );
};

const PlanCard = ({
  title,
  description,
  value,
  type,
  isLightTheme,
  link,
}: {
  title: string;
  description: string;
  value: string;
  type: "BNPL" | "Pawn";
  isLightTheme: boolean;
  link: string;
}) => {
  return (
    <PlanCardContainer justifyContent="space-between">
      <LinkContainer href={link}>
        <Flex direction="column" justifyContent="space-between">
          <CardTitle color="secondary">{title}</CardTitle>
          <Flex direction="column">
            <CardDescription style={{ marginBottom: "26px", maxWidth: "280px" }}>{description}</CardDescription>
            <TextButton style={{ cursor: "pointer" }}>
              <CardDescription style={{ color: isLightTheme ? "#00E7E7" : "#0FF" }}>{value}</CardDescription>
            </TextButton>
          </Flex>
        </Flex>
        {type == "BNPL" && <BNPLCard />}
        {type == "Pawn" && <PawnCard src={PawnCardImg} alt="mocaverse-pawn" />}
      </LinkContainer>
    </PlanCardContainer>
  );
};

const InfoCard = ({
  title,
  description,
  value,
  iconUrl,
  isLightTheme,
  link,
  onClick,
}: {
  title: string;
  description: string;
  value: string;
  iconUrl: string;
  isLightTheme: boolean;
  link: string;
  onClick?: () => void;
}) => {
  return (
    <InfoCardContainer direction="column" isLightTheme={isLightTheme}>
      <LinkContainer
        style={{ flexDirection: "column" }}
        href={link}
        target={link.includes("http") ? "_blank" : "_self"}
        rel="noreferrer"
        onClick={onClick}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <CardTitle color="secondary" style={{ fontWeight: 600 }}>
            {title}
          </CardTitle>
          <InfoCardImg
            src={iconUrl}
            alt={title}
            style={{
              right: title === "ApeStaking" ? "-10%" : 0,
            }}
          />
        </Flex>

        <InfoCardDescription
          color="secondary"
          style={{ marginTop: "31px", marginBottom: "26px", boxSizing: "border-box" }}
        >
          {description}
        </InfoCardDescription>
        <TextButton style={{ cursor: "pointer" }}>
          <CardDescription color="00ffff" style={{ color: isLightTheme ? "#00E7E7" : "#0FF" }}>
            {value}
          </CardDescription>
        </TextButton>
      </LinkContainer>
    </InfoCardContainer>
  );
};

const BNPLCard = () => {
  const images = [
    {
      name: "Bored Ape Yacht Club",
      url: "https://i.seadn.io/gae/j7H4MTb2iTGexkvyUGd0Tkv9pIuI-SAXVQHAbMJZNNCHdIxfXYDoNg-YRk2EIsddBzDx5qhN_GIpOJZpcZN2wz-ZaJ5KQj3g98tL",
    },
    { name: "Azuki", url: "https://i.seadn.io/gcs/files/372217af828be8fa217ab1c0fb89cc41.png" },
    { name: "Pudgy Penguins", url: "https://i.seadn.io/gcs/files/55148103f99ca6ae8ae3ed375dc1ab81.png" },
    { name: "DeGods", url: "https://i.seadn.io/gcs/files/b4d8c3de1e25881de9e93b085c1a5245.png" },
    { name: "Captainz", url: "https://i.seadn.io/gcs/files/32917e04f01924e1272f0a67afc46348.jpg" },
    { name: "HV-MTL", url: "https://i.seadn.io/gcs/files/a90ce9a8356fbaf58244c7db1c9a9616.png" },
  ];

  return (
    <Flex
      direction="column"
      gap="6px"
      style={{ maxWidth: "150px", maxHeight: "200px", marginTop: "-50px", marginBottom: "-50px", zIndex: 0 }}
    >
      {images.map(image => (
        <div key={image.url}>
          <img src={image.url} alt={image.name} width={60} height={60} style={{ borderRadius: "10px" }} />
        </div>
      ))}
    </Flex>
  );
};

const PlanCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: space-between;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column;
  }
`;

const InfoCardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
  justify-content: space-between;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column;
  }
`;

const PlanCardContainer = styled(Flex)`
  justify-content: space-between;
  max-width: 590px;
  padding: 34px;
  border-radius: 30px;
  background-color: ${({ theme }) => (theme.theme === "light" ? "rgb(232, 255, 255)" : "#041c2d")};
  flex-grow: 1;
  z-index: 3;
  overflow: hidden;
  outline: 1px solid transparent;
  :hover {
    cursor: pointer;
    outline: 1px solid #2c2c2c;
    transition: outline 0.2s ease-in-out;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    padding: 24px 24px;
  }
`;

const InfoCardContainer = styled(Flex)<{ isLightTheme: boolean }>`
  position: relative;
  overflow: hidden;
  max-width: 460px;
  max-height: 200px;
  padding: 25px;
  border-radius: 30px;
  background-color: ${({ isLightTheme }) => (isLightTheme ? "#E8FFFF" : "#1c1c1c")};
  border: 1px solid #2c2c2c;
  flex-grow: 1;
  box-sizing: border-box;
  box-sizing: border-box;
  :hover {
    cursor: pointer;
  }
`;

const LinkContainer = styled.a`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  text-decoration: none;
  color: inherit;
`;

const TextButton = styled.button`
  border: none;
  width: fit-content;
  background: none;
  padding: 0;
  margin: 0;
`;

const CardTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 24px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 18 }])};
  font-weight: 600;
`;
const CardDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  margin-top: 100px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.tablet]: 12 }])};
  ${getStyleWithMediaQuery("margin-top", "px", [{ [breakpoints.tablet]: 50 }])};
  font-weight: 400;
`;
const InfoCardDescription = styled(CardDescription)`
  ${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.tablet]: 180 }])};
`;
const InfoCardImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    position: absolute;
    height: 70%;
    width: auto;
    top: 15%;
    right: 0;
  }
`;

const PawnCard = styled.img`
  width: 180px;
  height: auto;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.laptopM]: 150 }, { [breakpoints.tablet]: 100 }])};
`;
