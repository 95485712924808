import { BigNumber } from "ethers";
import { useMemo } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Card, Text } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS } from "@/config";
import { bigNumToFixedStr, bigNumToFloat, formatCompactNumber, numberWithCommas, shortenName } from "@/utils";

import { IApeCoinSource, ISelectedNft } from "../../../types";

export const BulkUnstakingBreakdown = ({
  nfts,
  source,
}: {
  nfts: Array<
    ISelectedNft & {
      stakedAmount: BigNumber;
      pendingRewards: BigNumber;
      pendingRewardsInVault: BigNumber;
    }
  >;
  source: IApeCoinSource;
}) => {
  const totalStaked = useMemo(() => {
    return nfts.reduce((acc, cur) => acc.add(cur.stakedAmount), BigNumber.from(0));
  }, [nfts]);
  const totalRewards = useMemo(() => {
    return nfts.reduce((acc, cur) => {
      if (!cur.apeStaking.earnedAmount) return acc;
      return acc + bigNumToFloat(cur.apeStaking.earnedAmount);
    }, 0);
  }, [nfts]);
  return (
    <StyledCard p={"10px 8px"}>
      <Flex direction="column" gap="7px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="xs" weight="600" color="secondary">
            {`Bulk Unstaking Breakdown`}
          </Text>
          <Text size="xs" weight="500" color="gray0">
            {`${nfts.length} ${source === IApeCoinSource.borrow ? `borrows` : `stakings`}`}
          </Text>
        </Flex>
        <StepperContainer>
          {nfts.map((nft, index) => (
            <Flex key={`${nft.address}:${nft.tokenId}`} direction="column">
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" gap="8px">
                  <DueCircle isPaid isLast={index === nfts.length - 1}>
                    <DueCircleImg src={nft.imageUrl || NoImage} />
                  </DueCircle>
                  <Text size="xs" weight="500" color={"gray0"}>
                    {`${COLLECTION_SHORT_NAMES_MAPPED_BY_ADDRESS[nft.address]} #${shortenName(nft.tokenId)}`}
                  </Text>
                </Flex>
                <Flex alignItems="center" gap="4px">
                  <Text size="xs" weight="500" color={"gray0"}>
                    {`${numberWithCommas(bigNumToFloat(nft.stakedAmount))} APE `}
                  </Text>
                  {nft.pendingRewards && (
                    <Text size="xs" weight="500" color={"gray0"}>
                      {`(+${formatCompactNumber(bigNumToFloat(nft.pendingRewards))} APE)`}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
          ))}
        </StepperContainer>
        <Flex direction="column" gap="0.3rem">
          <Flex justifyContent="space-between">
            <Text size="xs" weight="500" color="secondary">
              {source === IApeCoinSource.borrow ? `Total Borrowed` : `Total Staked `}
            </Text>
            <Flex direction="column">
              <Text size="xs" weight="500" color="secondary" textAlign="right">
                {`${numberWithCommas(bigNumToFixedStr(totalStaked), 2)} APE`}
              </Text>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between">
            <Text size="xs" weight="500" color="secondary">
              {`Rewards `}
            </Text>
            <Flex direction="column">
              <Text size="xs" weight="500" color="secondary" textAlign="right">
                {`${numberWithCommas(totalRewards, 2)} APE`}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </StyledCard>
  );
};

const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-left: none;
  border-right: none;
  padding: 9px 0;
  margin-top: 3px;
  gap: 8px;
`;

const DueCircleImg = styled.img`
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  border-radius: 50%;
`;
const DueCircle = styled.div<{ isLast?: boolean; isNextPayment?: boolean; isPaid?: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  :before {
    position: absolute;
    content: "";
    display: ${({ isLast }) => (isLast ? "none" : "block")};
    width: 1px;
    height: 14px;
    border-left: 1px solid;
    border-color: ${({ theme }) => theme.colors.gray0};
    bottom: -100%;
    left: 6px;
  }
`;

const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.colors.primary};
`;
