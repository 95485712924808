import { useState } from "react";
import styled from "styled-components";

import { Box } from "../../Box";
import { Flex } from "../../Flex";
import { breakpoints, getStyleWithMediaQuery } from "../../config";
import { Button, SkeletonLine, Text } from "../core";
import { NoImage } from "../images";
import { NftLinkHelperMenu } from "./NftLinkHelperMenu";

export const NftMetadataImage: React.FC<{
  imageUrl: string | null;
  alt: string;
  imageBg?: string;
}> = ({ imageUrl, alt, children, imageBg }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  return (
    <div style={{ position: "relative", flex: 1 }}>
      <NftImageWrapper style={{ backgroundColor: imageBg }}>
        {imageUrl && !imageError ? (
          <>
            {imageLoading && <ImageLoader />}
            <NftImageStyled
              src={imageUrl}
              style={{ backgroundColor: imageBg }}
              alt={alt}
              onLoad={() => setImageLoading(false)}
              onError={() => setImageError(true)}
            />
          </>
        ) : (
          <NftImageNone src={NoImage} alt={"no-image"} style={{ backgroundColor: imageBg }} />
        )}
      </NftImageWrapper>
      {children}
    </div>
  );
};

export const NftCardLoading = ({ actionText }: { actionText: string }) => {
  return (
    <NftCard role="gridcell">
      <NftImageWrapper
        style={{
          overflow: "unset",
        }}
      >
        <ImageLoader style={{ top: "0", position: "absolute" }} />
      </NftImageWrapper>
      <NftMetadataInfoWrapper>
        <Flex justifyContent="space-between">
          <NftCardTextLoading w="80px" />
        </Flex>
        <Flex justifyContent="space-between" h="19px">
          <NftCardTextLoading w="40px" sub />
          <NftCardTextLoading w="30px" sub />
        </Flex>
      </NftMetadataInfoWrapper>

      <NftCardActionWrapper
        style={{
          minHeight: "25px",
          position: "relative",
        }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <NftCardText weight="500" textAlign="left" color="gray0">
            {actionText}
          </NftCardText>
          <NftLinkHelperMenu link={""} market={"OpenSea"} />
        </Flex>
      </NftCardActionWrapper>
    </NftCard>
  );
};
export const NftCardActionText = styled(Text)<{ sub?: boolean }>`
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
  transition: 0.1s ease-in-out;
`;

export const NftCardAddButton = styled(Button)<{ isInCart?: boolean }>`
  height: 25px;
  background-color: ${({ theme, isInCart }) => (isInCart ? theme.colors.gray20 : theme.colors.cyan)};
  border-color: ${({ theme, isInCart }) => (isInCart ? theme.colors.gray20 : theme.colors.cyan)};
  border-radius: 6px;
  transition: 0.2s opacity ease-out;
  :hover {
    background-color: ${({ theme, isInCart }) => isInCart && theme.colors.gray20};
  }
`;

export const NftImageStyled = styled.img`
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
  object-fit: scale-down;
  position: absolute;
  top: 0;
  height: 100%;
  transition: 0.2s ease-in-out;

  @media only screen and (max-width: 414px) {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const NftImageNone = styled(NftImageStyled)`
  object-fit: contain;
  filter: ${({ theme }) =>
    theme.theme === "light" && "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};
`;

const NftImageWrapper = styled.div`
  display: flex;
  object-fit: scale-down;
  border-radius: 14px;
  position: relative;
  overflow: hidden;
  :before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(100%);
  }
`;

export const NftCard = styled(Box)<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -khtml-border-radius: 20px;
  -moz-border-radius: 20px;
  background: ${({ theme }) => theme.colors.primary};
  padding: 0.6rem;
  padding-bottom: 10px;
  cursor: pointer;
  transition: 0.2s;
  border-width: ${({ selected }) => (selected ? "5px" : "1px")} !important;
  border-color: ${({ theme, selected }) =>
    selected ? (theme.theme == "dark" ? theme.colors.cyan : "#00E7E7") : theme.colors.gray20};
  :hover {
    border-style: solid;
    border-width: 1px;
    border-color: #00ffff;
  }

  ${NftImageWrapper} {
    border-radius: ${({ selected }) => (selected ? "8px" : "14px")} !important;
  }

  &:hover ${NftCardActionText} {
    color: ${({ theme }) => theme.colors.cyan};
  }
  &:hover ${NftImageStyled} {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
`;

export const NftMetadataInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 0.5rem;
  gap: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const NftCardText = styled(Text)<{ sub?: boolean }>`
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

export const NftLastSaleText = styled(Text)<{ sub?: boolean }>`
  text-decoration: line-through !important;
  text-decoration-thickness: 2px !important;
  text-decoration-color: red !important;
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

const NftCardTextLoading = styled(SkeletonLine)<{ sub?: boolean }>`
  ${({ sub }) =>
    getStyleWithMediaQuery("height", "px", [
      { [breakpoints.desktop]: sub ? 13 : 17.5 },
      { [breakpoints.mobile]: sub ? 9 : 14 },
    ])}
`;

export const NftCardActionWrapper = styled.div`
  border-top-width: ${({ theme }) => theme.borderWidth};
  border-top-color: ${({ theme }) => theme.colors.gray20};
  border-top-style: solid;
  padding-top: 10px;
  ${getStyleWithMediaQuery("margin-top", "px", [{ [breakpoints.desktop]: 10 }, { [breakpoints.mobile]: 10 }])}
`;

const ImageLoader = styled(SkeletonLine)`
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 15px;
  width: 100%;
`;
