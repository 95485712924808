import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Title } from "@cyanco/components/theme/v3";

import { IsInTestDrive, jumpToLink } from "@/utils";

import CyanYoutubeImg from "../../assets/images/testdrive/youtube-how-to-cyan.svg";
import { AssetLoans } from "./components/Assets";
import { TestDriveHome } from "./home";

export const TestDrive = () => {
  const navigate = useNavigate();

  if (!IsInTestDrive) {
    navigate("/");
    return <></>;
  }

  return (
    <Container direction="column">
      <TestDriveContainer gap="80px" direction="column" justifyContent="center" alignItems="center">
        <TestDriveTitle>Test drive the Cyan Wallet</TestDriveTitle>
        <TestDriveHome />
      </TestDriveContainer>
      <AssetLoans />
      <CyanYoutubeWrapper
        onClick={() => {
          jumpToLink("https://www.youtube.com/@usecyan/videos");
        }}
      >
        <CyanYoutube src={CyanYoutubeImg} alt="Cyan Youtube" />
      </CyanYoutubeWrapper>
    </Container>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1440px) / 2);
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 4 }, { [breakpoints.tablet]: 1 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopL]: "30px 50px" }, { [breakpoints.tablet]: "0px" }])}
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 0 5rem 0" },
    { [breakpoints.tablet]: "0rem 0.5rem 6rem 0.5rem" },
  ])}
`;

const TestDriveTitle = styled(Title)`
  background: linear-gradient(180deg, #fff 0%, rgba(0, 255, 255, 0.61) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TestDriveContainer = styled(Flex)`
  margin: 0 calc((100% - 1240px) / 2);
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
`;

const CyanYoutube = styled.img`
  width: 280px;
  height: 177px;
  transition: 0.2s ease-in-out;
`;

const CyanYoutubeWrapper = styled.div`
cursor: pointer;
overflow:hidden;
border: 2px #2c2c2c solid;
border-radius: 20px;
width: fit-content;
background: #020D15;
padding: 4px;
padding-bottom: 0px;
position: fixed;
bottom: 30px;
right: 40px;
z-index: 30;
@media only screen and (max-width: ${breakpoints.laptopS}px) {
  display:none;
}
&:hover {
  ${CyanYoutube} {
    transform: scale(1.05) !important;
  }

`;
