import { BigNumber } from "ethers";
import { useAsyncCallback } from "react-async-hook";
import styled from "styled-components";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { Flex } from "@cyanco/components/theme";
import { Button, Text, getCurrencyLogoSrc } from "@cyanco/components/theme/v3";

import BendDaoLogo from "@/assets/images/benddao.svg";
import { useAppContext } from "@/components/AppContextProvider";
import { ICurrency } from "@/types";
import { bigNumToFloat, displayInUSD, numberWithCommas } from "@/utils";

export const BendDaoMigrateConfirmation = ({
  bendDaoAmount,
  cyanAmount,
  currency,
  onConfirm,
}: {
  bendDaoAmount: BigNumber;
  cyanAmount: BigNumber;
  currency: ICurrency;
  onConfirm: () => void;
}) => {
  const { usdPrice } = useAppContext();
  const { loading } = useAsyncCallback(async () => {
    await onConfirm();
  });

  return (
    <Flex direction="column" gap="1.5rem">
      <Flex direction="column" gap="2.5rem">
        <Flex direction="column" gap="0.5rem">
          <Text weight="600" color="gray0" size="md">
            {`Current Loan`}
          </Text>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Text color="secondary" size="xxl">
              {`${numberWithCommas(bigNumToFloat(bendDaoAmount, currency.decimal), 3, true)} ${currency.symbol}`}
            </Text>
            <StyledImg src={BendDaoLogo} alt={"benddao-logo"} />
          </Flex>
          <Text weight="500" color="gray0" size="sm">
            {displayInUSD(bigNumToFloat(bendDaoAmount, currency.decimal) * usdPrice[currency.symbol])}
          </Text>
        </Flex>
        <Flex direction="column" gap="0.5rem">
          <Text weight="600" color="gray0" size="md">
            {`New Loan`}
          </Text>
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Text color="secondary" size="xxl">
              {`${numberWithCommas(bigNumToFloat(cyanAmount, currency.decimal), 3, true)} ${currency.symbol}`}
            </Text>
            <StyledImg src={getCurrencyLogoSrc(currency.symbol as SupportedCurrenciesType)} alt={currency.symbol} />
          </Flex>
          <Text weight="500" color="gray0" size="sm">
            {displayInUSD(bigNumToFloat(cyanAmount, currency.decimal) * usdPrice[currency.symbol])}
          </Text>
        </Flex>
      </Flex>
      <InfoBox pt="1.5rem" direction="column" gap="0.8rem">
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <Text color="gray0" size="sm" weight="500">
            {`Migrate and receive:`}
          </Text>
          <Text color="secondary" size="sm" weight="500">
            {`${numberWithCommas(bigNumToFloat(cyanAmount.sub(bendDaoAmount), currency.decimal), 5, true)} ${
              currency.symbol
            }`}
          </Text>
        </Flex>
      </InfoBox>
      <Button
        onClick={onConfirm}
        style={{
          height: "50px",
        }}
        loading={loading}
      >{`Confirm Migration`}</Button>
    </Flex>
  );
};

const StyledImg = styled.img`
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
`;

const InfoBox = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray20};
`;
