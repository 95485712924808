import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Card, NotFound, useModal } from "@cyanco/components/theme/v3";

import { ICollectionBe } from "@/apis/collection/types";
import { OnSaleNftCardLoading } from "@/components/Bnpl/components";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";

import { getRarityRank } from "../../../../utils";
import { useAppContext } from "../../../AppContextProvider";
import { useP2PListedNfts } from "../../LendDataContext";
import { LendPageViewStyle, useLendSelectors } from "../../LendPageContext";
import { OfferMakerCard } from "../LoanOffers/OfferMakerCard";
import { ListedNftCard } from "./ListedNftCard";
import { ListedNftHeader, ListedNftRow, ListedNftRowLoading } from "./ListedNftRow";

const OnSaleNftWrapper: React.FC<{ showByGrid: boolean; loading: boolean }> = ({ children, showByGrid, loading }) => {
  if (showByGrid) {
    return (
      <GridContainer direction="column" gap="10px">
        <Flex direction="row" gap="2rem">
          {loading ? <OnSaleNftCardLoading /> : <BnplNFTWrapper>{children}</BnplNFTWrapper>}
        </Flex>
      </GridContainer>
    );
  } else {
    return (
      <ListContainer>
        <StyledListViewBox>
          <Flex direction="row" w="100%" gap="2rem">
            <ListViewBox>
              <Header>
                <HeaderWrapper>
                  <ListedNftHeader />
                </HeaderWrapper>
              </Header>

              {loading ? (
                <div>
                  {Array.from(Array(4).keys()).map(loader => (
                    <ListedNftRowLoading key={loader} />
                  ))}
                </div>
              ) : (
                children
              )}
            </ListViewBox>
          </Flex>
        </StyledListViewBox>
      </ListContainer>
    );
  }
};

export const ListedNfts = ({ collection }: { collection: ICollectionBe }) => {
  const { chainId } = useWeb3React();
  const { setModalContent } = useModal();
  const navigate = useNavigate();
  const { collections } = useAppContext();
  const { selectedLendViewStyle, selectedTokenId } = useLendSelectors();
  const { listedNfts, listedNftsLoading } = useP2PListedNfts();
  const isGrid = useMemo(() => selectedLendViewStyle === LendPageViewStyle.grid, [selectedLendViewStyle]);
  const listedNftFiltered = useMemo(() => {
    return listedNfts.filter(nft => nft.tokenId.startsWith(selectedTokenId));
  }, [listedNfts, selectedTokenId]);

  return (
    <OnSaleNftWrapper showByGrid={isGrid} loading={listedNftsLoading}>
      <div
        style={{
          width: isGrid ? "100%" : "calc(100% - 2px)",
        }}
      >
        {listedNftFiltered.length === 0 ? (
          <NotFound msg={`No availabe NFT found.`} />
        ) : (
          <NftContainer role="grid" $showByGrid={isGrid}>
            {listedNftFiltered.map(nft =>
              isGrid ? (
                <ListedNftCard
                  nft={{
                    ...nft,
                    rarity: getRarityRank(nft.rarityRank, nft.collectionAddress, collections),
                  }}
                  key={`${nft.id}`}
                  onClick={nft => {
                    setModalContent({
                      title: `NFT Loan Offer`,
                      content: <OfferMakerCard nft={nft} collection={collection} />,
                    });
                  }}
                  onClickCard={() =>
                    navigate(
                      `/lend/${CHAIN_IDS_TO_NAMES[chainId as SupportedChainId]}/${collection.address}/${nft.tokenId}`,
                    )
                  }
                />
              ) : (
                <ListedNftRow
                  nft={{
                    ...nft,
                    rarity: getRarityRank(nft.rarityRank, nft.collectionAddress, collections),
                  }}
                  key={`${nft.id}`}
                  onClickCard={() =>
                    navigate(
                      `/lend/${CHAIN_IDS_TO_NAMES[chainId as SupportedChainId]}/${collection.address}/${nft.tokenId}`,
                    )
                  }
                  onClick={nft => {
                    setModalContent({
                      title: `NFT Loan Offer`,
                      content: <OfferMakerCard nft={nft} collection={collection} />,
                    });
                  }}
                />
              ),
            )}
          </NftContainer>
        )}
      </div>
    </OnSaleNftWrapper>
  );
};
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledListViewBox = styled.div`
  gap: 1rem;
  padding: 0rem 18px 0 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0.5rem calc(0.5rem + 5px) 1.5rem" },
  ])}
`;
export const HeaderWrapper = styled.div``;

const GridContainer = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0.5rem calc(0.5rem + 5px) 1.5rem" },
  ])}
`;

const BnplNFTWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 30px;
`;

export const NftContainer = styled.div<{ $showByGrid?: boolean }>`
  ${({ $showByGrid }) => {
    if ($showByGrid) {
      return ` display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  width: 100%;
  ${getStyleWithMediaQuery("grid-column-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-row-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.tablet]: "repeat(auto-fill, minmax(180px, 1fr))" },
    { [breakpoints.mobile]: "repeat(auto-fill, minmax(140px, 1fr))" },
  ])}; `;
    } else {
      return ` display: flex;
      flex-direction: column;
   @media only screen and (max-width: 414px) {
     border: none;
     border-radius: 20px;
     padding: 0;
   }
   > * {
     &:last-child {
       border-bottom-left-radius: 20px;
       border-bottom-right-radius: 20px;
       @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
     }
   }`;
    }
  }}
`;
const MainBox = styled(Card)`
  background: ${({ theme }) => theme.colors.transparent};
  border: none;
  > * {
    &:last-child {
      background-color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.gray20};
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      border-top: none;
      ${getStyleWithMediaQuery("filter", "", [{ [breakpoints.tablet]: "none" }])}
    }
  }
`;

export const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

export const Header = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 65px;
  }
  position: sticky;
  align-items: end;
  top: 170px;
  border-bottom: none;
  background: ${({ theme }) => theme.colors.primary};
  transform-style: preserve-3d;
  z-index: 5;
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    z-index: -1;
    display: block;
    position: absolute;
    transform: translateZ(-1px);
    height: 120px;
    top: -10px;
    height: calc(100% + 10px);
    left: -5px;
    width: calc(100% + 10px);
  }
`;
