import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { HelpCircle, MinusCircle, PlusCircle } from "react-feather";
import { useTheme } from "styled-components";

import { Flex, Tooltip, TooltipText } from "@cyanco/components/theme";
import { Card, Text } from "@cyanco/components/theme/v3";
import { FilledChevronDown, MaskForBreakdownLoan } from "@cyanco/components/theme/v3/icons";

import { IPricerCalculatorResult } from "@/hooks/usePlanCreationForm.types";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, numberWithCommas } from "@/utils";

import { PaymentStep } from "../../PlanPayment/RegularPayment";
import {
  BREAKDOWN_COLOR,
  BreakdownBar,
  BreakdownCircle,
  BreakdownMask,
  DetailContainer,
  DetailContainerBottom,
  ExpandButton,
  INTEREST_RATE_SPACE,
  PurchaseWrapper,
  StepperContainer,
} from "../PaymentsSteps";
import { PlanCreationDecimalFormatMap } from "../types";
import { getInterestRate, getPaymentInterval } from "../utils";

type IProps = {
  pricerMethods: Array<IPricerCalculatorResult | undefined>;
  planType: "pawn" | "bnpl";
  currency: ICurrency;
};

export const PaymentsStepsBulk: React.FC<IProps> = ({ currency, pricerMethods, planType }) => {
  const theme = useTheme();
  const [openDetail, setOpenDetail] = useState(false);
  const isBnpl = planType === "bnpl";

  const totalAmounts = pricerMethods.reduce<{
    downPayment: BigNumber;
    totalInterestFee: BigNumber;
    totalServiceFee: BigNumber;
    loanAmount: BigNumber;
    totalPrice: BigNumber;
    downPaymentWithoutFee: BigNumber;
  }>(
    (acc, cur) => {
      if (!cur) return acc;
      return {
        downPayment: acc.downPayment.add(cur.downpaymentAmount),
        totalInterestFee: acc.totalInterestFee.add(cur.totalInterestFee),
        totalServiceFee: acc.totalServiceFee.add(cur.totalServiceFee),
        loanAmount: acc.loanAmount.add(cur.loaningAmount),
        totalPrice: acc.totalPrice.add(cur.totalPrice),
        downPaymentWithoutFee: acc.downPaymentWithoutFee.add(cur.totalPrice.mul(cur.downpaymentRate).div(100_00)),
      };
    },
    {
      downPayment: BigNumber.from(0),
      totalInterestFee: BigNumber.from(0),
      totalServiceFee: BigNumber.from(0),
      loanAmount: BigNumber.from(0),
      totalPrice: BigNumber.from(0),
      downPaymentWithoutFee: BigNumber.from(0),
    },
  );
  const totalRepayAmount = totalAmounts.loanAmount.add(totalAmounts.totalInterestFee).add(totalAmounts.totalServiceFee);
  const totalCost = totalAmounts.downPaymentWithoutFee.add(totalRepayAmount);
  const borrowingPercentOfTotal = bigNumToFloat(totalAmounts.loanAmount.mul(100).div(totalCost), 0);
  const purchaseAmountPercentOfTotal = bigNumToFloat(totalAmounts.totalPrice.mul(100).div(totalCost), 0);
  const downpaymentRate = bigNumToFloat(totalAmounts.downPaymentWithoutFee.mul(100).div(totalAmounts.totalPrice), 0);
  const _interestRate = getInterestRate({
    principleAmount: totalAmounts.loanAmount,
    payAmount: totalRepayAmount,
    decimals: currency.decimal,
  });
  const formatNumber = useMemo(() => {
    if (bigNumToFloat(totalAmounts.totalPrice, currency.decimal) < 1) {
      return 5;
    }
    return PlanCreationDecimalFormatMap.get(currency.decimal) || 4;
  }, [currency.decimal]);

  const numberOfPaymentsDeductor = planType === "bnpl" ? 1 : 0;
  const paymentAmountsByDate = pricerMethods.reduce<{ [key: string]: BigNumber }>((acc, cur) => {
    if (!cur) return acc;
    let _acc = { ...acc };
    Array.from(Array(cur.totalNumberOfPayments - numberOfPaymentsDeductor)).map((_, paymentNumber) => {
      const paymentDue = cur.term * (paymentNumber + 1);
      const total = acc[paymentDue] ?? BigNumber.from(0);
      _acc = {
        ..._acc,
        [paymentDue.toString()]: total.add(cur.monthlyAmount),
      };
    });
    return _acc;
  }, {});

  return (
    <Flex direction="column">
      <Card p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text size="sm" weight="600" color="secondary">
              {`Payment schedule`}
            </Text>
            <ExpandButton onClick={() => setOpenDetail(!openDetail)}>
              {!openDetail ? (
                <PlusCircle size={14} color={theme.colors.secondary} />
              ) : (
                <MinusCircle size={14} color={theme.colors.secondary} />
              )}
            </ExpandButton>
          </Flex>
          <StepperContainer>
            {isBnpl && (
              <PaymentStep
                isNextPayment
                text={`Due Today`}
                paymentAmount={totalAmounts.downPayment}
                fixNumber={formatNumber}
                currency={currency}
                color="secondary"
              />
            )}
            {Object.keys(paymentAmountsByDate)
              .sort((a, b) => Number(a) - Number(b))
              .map((due, index) => (
                <PaymentStep
                  isLast={Object.keys(paymentAmountsByDate).length === index + 1}
                  key={index}
                  text={`${!isBnpl ? "due " : ""}in ${getPaymentInterval(Number(due))}`}
                  paymentAmount={paymentAmountsByDate[due]}
                  fixNumber={formatNumber}
                  currency={currency}
                  color="gray0"
                />
              ))}
          </StepperContainer>
          <Flex
            justifyContent="space-between"
            style={{
              borderBottom: openDetail ? `1px solid ${theme.colors.gray20}` : "0px",
              paddingTop: "2px",
              paddingBottom: openDetail ? `9px` : "0px",
            }}
          >
            <Text size="xs" weight="500" color="secondary">
              {`${(_interestRate / 100).toFixed(2)}% interest`}
            </Text>

            <Text size="xs" weight="500" color="secondary">
              {`Total: ${numberWithCommas(bigNumToFixedStr(totalCost, formatNumber, currency.decimal), formatNumber)} ${
                currency.symbol
              }`}
            </Text>
          </Flex>
          <DetailContainer direction="column" open={openDetail} gap="9px">
            <Text size="sm" weight="600" color="secondary">
              {`Breakdown`}
            </Text>
            {planType === "bnpl" && (
              <Flex style={{ position: "relative", minHeight: "32px" }} w="100%" justifyContent="flex-end">
                <PurchaseWrapper left={100 - downpaymentRate - INTEREST_RATE_SPACE}>
                  <Flex alignItems="center" direction="column">
                    <Text color="gray0" size="xxs" weight="500" textWrap={false}>
                      Due today
                    </Text>
                    <Text color="gray0" size="xxs" weight="500" textWrap={false}>
                      {`${numberWithCommas(bigNumToFloat(totalAmounts.downPayment, currency.decimal), 4)} ${
                        currency.symbol
                      }`}
                    </Text>
                    <Flex mt="3px">
                      <FilledChevronDown color={theme.colors.gray0} />
                    </Flex>
                  </Flex>
                </PurchaseWrapper>

                <PurchaseWrapper left={100 - purchaseAmountPercentOfTotal}>
                  <Flex alignItems="center" direction="column">
                    <Text color="gray0" size="xxs" weight="500" textWrap={false}>
                      Purchase price
                    </Text>
                    <Text color="gray0" size="xxs" weight="500" textWrap={false}>
                      {`${numberWithCommas(bigNumToFloat(totalAmounts.totalPrice, currency.decimal), formatNumber)} ${
                        currency.symbol
                      }`}
                    </Text>
                    <Flex mt="3px">
                      <FilledChevronDown color={theme.colors.gray0} />
                    </Flex>
                  </Flex>
                </PurchaseWrapper>
              </Flex>
            )}
            <Flex direction="row" w="100%" style={{ position: "relative" }}>
              {planType === "bnpl" && (
                <>
                  <BreakdownBar color={BREAKDOWN_COLOR.dueNow} width={downpaymentRate} zIndex={5} />
                  <BreakdownMask left={downpaymentRate} zIndex={4}>
                    <MaskForBreakdownLoan color={theme.colors.gray10} />
                  </BreakdownMask>
                </>
              )}
              <BreakdownBar
                color={BREAKDOWN_COLOR.borrow}
                width={borrowingPercentOfTotal - INTEREST_RATE_SPACE}
                zIndex={3}
                hasGap={planType === "bnpl"}
              />
              <BreakdownMask
                left={downpaymentRate + borrowingPercentOfTotal - INTEREST_RATE_SPACE - (planType === "bnpl" ? 2 : 0)}
                zIndex={2}
              >
                <MaskForBreakdownLoan color={theme.colors.gray10} />
              </BreakdownMask>
              <BreakdownBar
                color={BREAKDOWN_COLOR.interest}
                width={
                  100 - borrowingPercentOfTotal - downpaymentRate + INTEREST_RATE_SPACE + (planType === "bnpl" ? 4 : 2)
                }
                zIndex={1}
                hasGap
              />
            </Flex>
            <Flex direction="column" gap="7px">
              {planType === "bnpl" && (
                <Flex alignItems="center" justifyContent="space-between" w="100%">
                  <Flex alignItems="center" gap="5px">
                    <BreakdownCircle bg={BREAKDOWN_COLOR.dueNow} />
                    <Flex alignItems="center" gap="5px">
                      <Text size="xs" weight="500" color="secondary">
                        {`Down payment`}
                      </Text>
                      <Tooltip>
                        <HelpCircle height={15} width={15} color={theme.colors.secondary} />
                        <TooltipText position="top" top="-85px" right="-58px" style={{ width: "115px" }} showArrow>
                          <Flex direction="column" gap="7px">
                            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                              {`The down payment differs from today's due amount because of platform fees included in the 'Interest' bar.`}
                            </Text>
                          </Flex>
                        </TooltipText>
                      </Tooltip>
                    </Flex>
                  </Flex>
                  <Text size="xs" weight="500" color="secondary">
                    {`${numberWithCommas(
                      bigNumToFloat(totalAmounts.downPaymentWithoutFee, currency.decimal),
                      formatNumber,
                    )} ${currency.symbol}`}
                  </Text>
                </Flex>
              )}
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Flex alignItems="center" gap="5px">
                  <BreakdownCircle bg={BREAKDOWN_COLOR.borrow} />
                  <Text size="xs" weight="500" color="secondary">
                    {`Borrow amount`}
                  </Text>
                </Flex>
                <Text size="xs" weight="500" color="secondary">
                  {`${numberWithCommas(bigNumToFloat(totalAmounts.loanAmount, currency.decimal), formatNumber)} ${
                    currency.symbol
                  }`}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Flex alignItems="center" gap="5px">
                  <BreakdownCircle bg={BREAKDOWN_COLOR.interest} />
                  <Text size="xs" weight="500" color="secondary">
                    {`Interest`}
                  </Text>
                </Flex>
                <Text size="xs" weight="500" color="secondary">
                  {`${numberWithCommas(
                    bigNumToFloat(totalAmounts.totalInterestFee.add(totalAmounts.totalServiceFee), currency.decimal),
                    formatNumber,
                  )} ${currency.symbol}`}
                </Text>
              </Flex>
            </Flex>
            <DetailContainerBottom>
              <Flex justifyContent="space-between" alignItems="center">
                <Text size="xs" weight="500" color="secondary">
                  {`Total cost`}
                </Text>
                <Text size="xs" weight="500" color="secondary">
                  {` ${numberWithCommas(bigNumToFloat(totalCost, currency.decimal), formatNumber)} ${currency.symbol}`}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text size="xs" weight="500" color="secondary">
                  {`Interest rate`}
                </Text>
                <Text size="xs" weight="500" color="secondary">
                  {(_interestRate / 100).toFixed(2)}%
                </Text>
              </Flex>
            </DetailContainerBottom>
          </DetailContainer>
        </Flex>
      </Card>
    </Flex>
  );
};
