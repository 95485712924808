import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SubTitle, Text } from "@cyanco/components/theme/v3";
import { ArrowLeft } from "@cyanco/components/theme/v3/icons";

import { LoanPricer } from "./components/LoanPricer";

export const BnplLoanPricer = () => {
  const navigate = useNavigate();
  return (
    <Container direction="column">
      <GappedContainer direction="column">
        <div>
          <BackToCollectionsButton variant="ghost" onClick={() => navigate("/")}>
            <Flex alignItems="center" gap="2px">
              <BackToCollectionsIcon />
              <BackToCollectionsText color="gray0" size="sm">
                Shop Page
              </BackToCollectionsText>
            </Flex>
          </BackToCollectionsButton>
        </div>
        <Flex justifyContent="space-between" w="100%" alignItems="center">
          <Hidden tabletDown>
            <SubTitle>NFT loan interest rates by collection</SubTitle>
          </Hidden>
        </Flex>
      </GappedContainer>
      <LoanPricer />
    </Container>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1240px) / 2);
  padding: 0 0 5rem 0;
  gap: 4rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 2 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 50px" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "1rem 0.5rem 10rem 0.5rem" }])}
`;

const GappedContainer = styled(Flex)`
  align-items: start;
  justify-content: start;
  gap: 1rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 0.5 }])}
`;

const BackToCollectionsText = styled(Text)`
  transition: 0.2s ease-in-out;
`;
const BackToCollectionsIcon = styled(ArrowLeft)`
  stroke: ${props => props.theme.colors.gray0};
  transition: 0.08s ease-in-out;
`;
const BackToCollectionsButton = styled(Button)`
  &:hover {
    ${BackToCollectionsIcon} {
      stroke: ${props => props.theme.colors.secondary};
    }
    ${BackToCollectionsText} {
      color: ${props => props.theme.colors.secondary};
    }
  }
`;
