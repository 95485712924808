import { Provider } from "@ethersproject/providers";
import * as Sentry from "@sentry/react";
import { BigNumber, constants, ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HelpCircle } from "react-feather";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box } from "@cyanco/components/theme";
import { Flex } from "@cyanco/components/theme/Flex";
import { Option, Select, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { CyanC } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { IVault } from "@/apis/vault/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { useQueryParams } from "@/hooks/useQueryParams";
import { bigNumToFloat, displayInUSD, getCurrencyAddressByAbi, numberWithCommas, shortenAddress } from "@/utils";

import { useTransactionContext } from "../../../TransactionContextProvider";
import { useVaultPositions } from "../../VaultDataProvider";
import { IPosition, VaultDecimalFormatMap } from "../../types";
import { StakingCard } from "./StakingCard";
import { UnstakingCard } from "./UnstakingCard";
import { StyledLink } from "./common";

export const VaultStake = ({ vault }: { vault: IVault }) => {
  const { account, chainId, provider, signer } = useWeb3React();
  const { vaultActionType, vaultSelectedWallet } = useQueryParams();
  const navigate = useNavigate();
  const cyanWallet = useCyanWallet();
  const [selectedWallet, setSelectedWallet] = useState(vaultSelectedWallet ?? account ?? "");
  const { vaultPositions, vaultPositionsCyan } = useVaultPositions();
  const { transactions } = useTransactionContext();
  const [userPosition, setUserPosition] = useState<IPosition | null>(null);
  const [userPositionCyan, setUserPositionCyan] = useState<IPosition | null>(null);
  const [userBalanceMain, setUserBalanceMain] = useState<number>(0);
  const [userBalanceCyan, setUserBalanceCyan] = useState<number>(0);
  const formatNumber = useMemo(() => {
    return VaultDecimalFormatMap.get(vault.decimals) || 5;
  }, [vault.decimals]);

  const showStakingCard = vaultActionType !== "sell";

  const getUserBalanceInErc20 = useCallback(
    async (walletAddress: string, provider: Provider) => {
      if (!signer) return 0;
      const currencyAddress = await getCurrencyAddressByAbi(vault, provider);
      const isNativeCurrency = currencyAddress === constants.AddressZero;
      if (!isNativeCurrency) {
        const contract = f.SampleERC20TokenFactory.connect(currencyAddress, signer);
        const balance = await contract.balanceOf(walletAddress);
        return (
          Math.trunc(bigNumToFloat(balance, vault.decimals) * Math.pow(10, formatNumber)) / Math.pow(10, formatNumber)
        );
      }
      const balance = await provider.getBalance(walletAddress);
      const balanceInEth = ethers.utils.formatEther(balance);
      return Math.trunc(Number(balanceInEth) * Math.pow(10, formatNumber)) / Math.pow(10, formatNumber);
    },
    [vault],
  );

  useEffect(() => {
    const getVaultPosition = async () => {
      if (!vaultPositions && !vaultPositionsCyan) {
        setUserPosition(null);
        return;
      }
      const position = vaultPositions.find(position => position.vaultId === vault.id);

      if (!position || !vault.contractAddress || !provider || !account) {
        setUserPosition(null);
        return;
      }

      const vaultReader = f.CyanVaultV2Factory.connect(vault.contractAddress, provider);
      let availableBalance = BigNumber.from(0);
      if (chainId === vault.chainId) {
        availableBalance = await vaultReader.getWithdrawableBalance(account);
      }
      if (availableBalance.eq(0)) {
        setUserPosition(null);
        return;
      }
      setUserPosition({
        ...position,
        availableBalance,
      });
    };
    const getVaultPositionCyan = async () => {
      if (!vaultPositionsCyan) {
        setUserPositionCyan(null);
        return;
      }
      const position = vaultPositionsCyan.find(position => position.vaultId === vault.id);
      if (!position || !vault.contractAddress || !provider || !cyanWallet) {
        setUserPositionCyan(null);
        return;
      }
      const vaultReader = f.CyanVaultV2Factory.connect(vault.contractAddress, provider);
      let availableBalance = BigNumber.from(0);
      if (chainId === vault.chainId) {
        availableBalance = await vaultReader.getWithdrawableBalance(cyanWallet.walletAddress);
      }
      if (availableBalance.eq(0)) {
        setUserPositionCyan(null);
        return;
      }
      setUserPositionCyan({
        ...position,
        availableBalance,
      });
    };
    getVaultPosition();
    getVaultPositionCyan();
  }, [vaultPositions, vault, showStakingCard, vaultPositionsCyan]);

  useEffect(() => {
    const getUserBalance = async () => {
      if (!provider || !account) return;
      try {
        const balance = await getUserBalanceInErc20(account, provider);
        setUserBalanceMain(balance);
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    };
    getUserBalance();
  }, [vaultPositions, vault.contractAddress]);

  useEffect(() => {
    const getUserBalance = async () => {
      if (!provider || !cyanWallet) return;
      try {
        const balance = await getUserBalanceInErc20(cyanWallet.walletAddress, provider);
        setUserBalanceCyan(balance);
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    };
    getUserBalance();
  }, [vaultPositionsCyan, vault.contractAddress]);

  const userPositionBalanceConverted = useMemo(() => {
    return userPosition ? bigNumToFloat(userPosition.balance, vault.decimals) : 0;
  }, [userPosition?.balance]);

  const userPositionBalanceConvertedCyan = useMemo(() => {
    return userPositionCyan ? bigNumToFloat(userPositionCyan.balance, vault.decimals) : 0;
  }, [userPositionCyan?.balance]);

  const userPositionBalanceInUsd = useMemo(() => {
    return displayInUSD((userPositionBalanceConvertedCyan + userPositionBalanceConverted) * vault.priceUsd);
  }, [userPositionBalanceConverted, userPositionBalanceConvertedCyan, vault]);

  const userBalance = useMemo(() => {
    if (account && selectedWallet.toLowerCase() === account.toLowerCase()) {
      return userBalanceMain;
    }
    if (cyanWallet && selectedWallet.toLowerCase() === cyanWallet.walletAddress.toLowerCase()) {
      return userBalanceCyan;
    }
    return 0;
  }, [userBalanceCyan, userBalanceMain, selectedWallet, account, cyanWallet]);

  const userAvailableBalanceInOne = useMemo(() => {
    if (account && cyanWallet && userPositionCyan && userPosition) {
      return userPosition.availableBalance.add(userPositionCyan.availableBalance).toString();
    } else if (cyanWallet && userPositionCyan) {
      return userPositionCyan.availableBalance.toString();
    } else if (account && userPosition) {
      return userPosition.availableBalance.toString();
    }
    return "0";
  }, [userPositionCyan, userPosition, account, cyanWallet]);

  const userAvailableBalance = useMemo(() => {
    if (account && selectedWallet.toLowerCase() === account.toLowerCase()) {
      return userPosition?.availableBalance;
    }
    if (cyanWallet && selectedWallet.toLowerCase() === cyanWallet.walletAddress.toLowerCase()) {
      return userPositionCyan?.availableBalance;
    }
  }, [userPositionCyan, userPosition, selectedWallet, account, cyanWallet]);

  const userPositionBalanceCyan = useMemo(() => {
    if (cyanWallet && userPositionCyan) {
      return bigNumToFloat(userPositionCyan.balance, vault.decimals);
    } else return 0;
  }, [userPositionCyan, cyanWallet, vault.decimals]);

  const userPositionBalanceMain = useMemo(() => {
    if (account && userPosition) {
      return bigNumToFloat(userPosition.balance, vault.decimals);
    } else return 0;
  }, [userPosition, account, vault.decimals]);

  const hasActiveTransaction = useMemo(() => {
    return transactions.some(tx => tx.type === "vault-stake" || tx.type === "vault-unstake");
  }, [transactions]);

  return (
    <Container>
      <Flex justifyContent="space-between" alignItems="center" mb="0.3rem">
        {showStakingCard ? (
          <Text color="secondary" weight="700" size="md">
            {`Stake`} {vault.currency}
          </Text>
        ) : (
          <Text color="secondary" weight="700" size="md">
            {`Unstake`} {vault.symbol}
          </Text>
        )}
        {cyanWallet && account && (
          <Box w="130px">
            <Select
              onChange={setSelectedWallet}
              value={selectedWallet}
              textSize="xxs"
              p="0.3rem 0.5rem 0.4rem"
              disabled={hasActiveTransaction}
            >
              <Option
                value={account}
                style={{
                  padding: "0.2rem 0.5rem 0.3rem",
                }}
              >
                <Text size="xxs" color="secondary">
                  {`${`MAIN`} (${shortenAddress(account, 2)})`}
                </Text>
              </Option>
              <Option
                value={cyanWallet.walletAddress}
                style={{
                  padding: "0.2rem 0.5rem 0.3rem",
                }}
              >
                <Text size="xxs" color="secondary">
                  {`${`CYAN`} (${shortenAddress(cyanWallet.walletAddress, 2)})`}
                </Text>
              </Option>
            </Select>
          </Box>
        )}
      </Flex>

      {showStakingCard ? (
        <StakingCard
          vault={vault}
          toggle={() => navigate({ search: "?type=sell" })}
          availableUserBalance={userBalance}
          availableTokenBalance={userAvailableBalance}
          selectedWallet={selectedWallet}
        />
      ) : (
        <UnstakingCard
          vault={vault}
          toggle={() => navigate({ search: "" })}
          availableTokenBalance={userAvailableBalance}
          selectedWallet={selectedWallet}
        />
      )}
      {account && (
        <UserBalanceCard
          vault={{ ...vault, formatNumber }}
          userPositionBalance={userPositionBalanceCyan + userPositionBalanceMain}
          account={account ?? ""}
          userPositionAvailableBalance={bigNumToFloat(userAvailableBalanceInOne ?? 0, vault.decimals)}
          userPositionBalanceInUsd={userPositionBalanceInUsd}
          userPositionBalanceCyan={userPositionBalanceCyan.toString()}
          userPositionBalanceMain={userPositionBalanceMain.toString()}
          hasCyanWallet={!!cyanWallet}
        />
      )}
    </Container>
  );
};

const UserBalanceCard = ({
  userPositionBalance,
  userPositionBalanceInUsd,
  userPositionBalanceMain,
  userPositionBalanceCyan,
  userPositionAvailableBalance,
  vault,
  account,
  hasCyanWallet,
}: {
  userPositionBalance: number;
  userPositionBalanceInUsd: string;
  userPositionBalanceMain: string;
  userPositionBalanceCyan: string;
  userPositionAvailableBalance: number;
  vault: IVault & { formatNumber: number };
  account: string;
  hasCyanWallet: boolean;
}) => {
  const theme = useTheme();
  return (
    <Container mt="0.4rem">
      <Flex direction="column" gap="0.8rem">
        <Flex direction="row" alignItems="flex-start" justifyContent="space-between" mt="0.2rem">
          <Flex direction="row" alignItems="center" gap="0.5rem">
            <VaultImageWrapper style={{ background: vault.colorCode, position: "relative", left: 0 }}>
              <VaultImage src={CyanC} alt={vault.name} />
            </VaultImageWrapper>
            <Text size="lg" color="secondary">
              {vault.symbol}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text size="lg" color="secondary" textAlign="right">
              {numberWithCommas(userPositionBalance.toFixed(vault.formatNumber), vault.formatNumber)}
            </Text>
            <Text color="gray0" size="xs">
              = {userPositionBalanceInUsd}
            </Text>
          </Flex>
        </Flex>
        <Flex direction="row" alignItems="flex-start" justifyContent="space-between">
          <Flex gap="0.5rem">
            <Jazzicon seed={jsNumberForAddress(account)} diameter={13} />
            <Text size="md" color="secondary">
              Main Wallet balance
            </Text>
          </Flex>
          <Text size="md" color="secondary" textAlign="right">
            {numberWithCommas(userPositionBalanceMain, vault.formatNumber)}
          </Text>
        </Flex>
        {hasCyanWallet && (
          <Flex direction="row" alignItems="flex-start" justifyContent="space-between">
            <Flex gap="0.5rem">
              <div
                style={{
                  background: theme.colors.cyan,
                  width: "13px",
                  height: "13px",
                  minWidth: "13px",
                  minHeight: "13px",
                  borderRadius: "50%",
                }}
              />
              <Text size="md" color="secondary">
                Cyan Wallet balance
              </Text>
            </Flex>
            <Text size="md" color="secondary" textAlign="right">
              {numberWithCommas(userPositionBalanceCyan, vault.formatNumber)}
            </Text>
          </Flex>
        )}
        <Flex direction="row" alignItems="flex-start" justifyContent="space-between">
          <Flex alignItems="center" gap="4px">
            <Text size="md" color="secondary">
              Available Balance
            </Text>
            <Tooltip>
              <HelpCircle height={16} width={16} color={theme.colors.secondary} />
              <TooltipText showArrow position="top" top="-70px" right="-50px" style={{ width: "100px" }}>
                <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                  <div>
                    {`Minimum deposit duration for new deposits are 7 days. `}
                    <StyledLink href="https://docs.usecyan.com/docs/cyan-vaults" target="_blank">
                      Learn more.
                    </StyledLink>
                  </div>
                </Text>
              </TooltipText>
            </Tooltip>
          </Flex>
          <Text size="md" color="secondary" textAlign="right">
            {numberWithCommas(userPositionAvailableBalance, vault.formatNumber)}
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
};
const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-style: solid;
  padding: 1rem;
  max-width: 500px;
  height: fit-content;
`;

export const PillWrapper = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#C8C8C8")};
  border-radius: 30px;
  padding: 0.35rem 0.6rem 0.3rem 0.5rem;
  height: fit-content;
  position: relative;
`;

export const StakeInput = styled.input`
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;
  outline: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
`;
export const StyledSelect = styled.select`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: Inter;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  padding: 0 6px 0 calc(0.5rem + 15px);
  border-radius: 30px;
  width: 85px;
  outline: none;
  appearance: none;
  cursor: pointer;
  background-color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#C8C8C8")};
  border: none;
`;

export const StyledChevronDown = styled.div`
  z-index: 4;
  pointer-events: none;
  left: 78px;
  position: absolute;
`;
export const VaultImageWrapper = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0.5rem;
`;

export const VaultImage = styled.img`
  height: 10px;
  width: 10px;
`;

export const ChevronDownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#C8C8C8")};
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  width: 37px;
  height: 37px;
  z-index: 2;
  top: -20px;
  left: calc(50% - 20px);
  cursor: pointer;
`;
