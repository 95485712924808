import dayjs from "dayjs";

import { useLocalStorage } from "@/hooks";

interface IUserConfig {
  showAPR: boolean;
  showLocalTime: boolean;
}

export interface IUserLog {
  lastVisited?: dayjs.Dayjs;
  isSigned?: boolean;
  token?: string;
  wallet?: string;
  cyanWallet?: string;
  config?: IUserConfig;
  banExpirationDate?: Date;
}

export const useUserLog = () => {
  const [userLogs, setUserLogs] = useLocalStorage<{
    [key: string]: IUserLog | undefined;
  }>("userLogs", {});
  return { userLogs, setUserLogs };
};
