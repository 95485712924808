import { useMemo } from "react";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden } from "@cyanco/components/theme/v3";

import { IVaultOffer } from "@/apis/user/types";
import { IVault } from "@/apis/vault/types";
import { getPaymentInterval } from "@/components/PlanCreation/utils";
import { _getPossibilityMap } from "@/hooks/usePlanCreationForm";
import { bigNumToFloat, numberWithCommas } from "@/utils";

import { Row, RowText } from "./LoanOfferRow";

type IProps = {
  blockchainTimestamp: number;
  vaultOffer: IVaultOffer;
  vault: IVault;
  onClick: () => void;
};
export const VaultOfferRow: React.FC<IProps> = ({ vaultOffer, vault, onClick }) => {
  const [_term, _totalNumberOfPayments, , _loanRate, multiplier, divider, adder] = vaultOffer.config[0];
  const interestRate = Math.ceil((vaultOffer.interestRate * multiplier) / divider) + adder;

  const loanAmount = useMemo(() => {
    return `${numberWithCommas(bigNumToFloat(vaultOffer.price, vault.decimals) * (_loanRate / 10000), 2)} ${
      vault.currency
    }`;
  }, [vault, vaultOffer]);

  const apr = useMemo(() => {
    return (365 / (_term / 60 / 60 / 20)) * (interestRate / 100);
  }, [interestRate]);
  return (
    <Row>
      <Flex alignItems="center" gap="10px">
        <StyledImg style={{ backgroundColor: vault.colorCode }} />
        <RowText color="secondary" weight="500">
          {vault.symbol}
        </RowText>
      </Flex>
      <RowText color={"secondary"}>{loanAmount}</RowText>
      <Hidden tabletDown>
        <RowText color="secondary" weight="500">
          {interestRate / 100}%
        </RowText>
      </Hidden>
      <RowText color="secondary" weight="500">
        {apr}%
      </RowText>
      <RowText color="secondary" weight="500">
        {getPaymentInterval(_term)}
      </RowText>
      <RowText color={"secondary"}></RowText>
      <Hidden tabletDown>
        <Flex h="26px" justifyContent="flex-end">
          <Button
            size="xs"
            onClick={onClick}
            style={{
              width: "70px",
            }}
          >
            Borrow
          </Button>
        </Flex>
      </Hidden>
    </Row>
  );
};

const StyledImg = styled.div`
width: 20px;
height: 20px;
${getStyleWithMediaQuery("min-width", "px", [{ [breakpoints.tablet]: 20 }])}
${getStyleWithMediaQuery("min-height", "px", [{ [breakpoints.tablet]: 20 }])}
${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.tablet]: 20 }])}
${getStyleWithMediaQuery("max-height", "px", [{ [breakpoints.tablet]: 20 }])}
border-radius: 50%;
} `;
