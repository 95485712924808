import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { breakpoints } from "@cyanco/components/theme/config";
import { Text } from "@cyanco/components/theme/v3";
import { Cart } from "@cyanco/components/theme/v3/icons";

import { usePlanCreation } from "@/hooks";
import { IPlanCreatableNft } from "@/types";

import { useBnplCart } from "../../BnplCartContext";

type IProps = {
  planType: "bnpl";
  isSmallCart?: boolean;
};
export const PlanCart: React.FC<IProps> = ({ planType, isSmallCart = false }) => {
  const theme = useTheme();
  const { currency, items } = useBnplCart();
  const { showNewPlanModal } = usePlanCreation(planType);
  const [selectedPlans, setSelectedPlans] = useState<IPlanCreatableNft[]>([]);

  useEffect(() => {
    const plans = items.filter(item => item.planType === planType);
    setSelectedPlans(plans);
  }, [items]);

  if (!selectedPlans.length || !currency || planType === "bnpl") return null;

  return (
    <div style={{ position: "relative", marginLeft: "auto" }}>
      <CartButton
        onClick={() =>
          showNewPlanModal({
            currency: {
              symbol: currency,
              address: selectedPlans[0].currency.address,
              decimal: selectedPlans[0].currency.decimal,
            },
            items: selectedPlans,
          })
        }
        isSmall={isSmallCart}
      >
        <CartItemsCount isSmall={isSmallCart}>
          <Text color="primary" weight="500" size="xxs">
            {selectedPlans.length}
          </Text>
        </CartItemsCount>
        <Cart color={theme.colors.secondary} />
      </CartButton>
    </div>
  );
};

const CartButton = styled.button<{ isSmall: boolean }>`
  background-color: ${props => props.theme.colors.primary};
  border-radius: ${({ isSmall }) => (isSmall ? "8px" : "10px")};
  border: 1px solid ${props => props.theme.colors.gray20};
  display: flex;
  cursor: pointer;
  align-items: center;
  height: ${({ isSmall }) => (isSmall ? "27.83px" : "100%")};
  width: ${({ isSmall }) => (isSmall ? "30px" : "48px")};
  padding: ${({ isSmall }) => (isSmall ? "4px 7px 2.5px 6px" : "8px 12px 5px 10px")};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    height: 45px;
    width: 48px;
    position: fixed;
    padding: 8px 12px 5px 10px;
    bottom: 60px;
    right: 7px;
    z-index: 75;
  }
`;

const CartItemsCount = styled.div<{ isSmall: boolean }>`
  background-color: ${props => props.theme.colors.cyan};
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: ${({ isSmall }) => (isSmall ? "2px" : "5px")}};
  z-index: 81;
  width: 13px;
  height: 13px;
  right: -8px;
  top: -8px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
   padding: 5px;
  }
`;
