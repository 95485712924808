import { axios } from "./axios";

export const getCumulativePoints = async ({
  abortSignal,
}: {
  abortSignal?: AbortSignal;
}): Promise<ICumulativePoints> => {
  const { data } = await axios.get<ICumulativePoints>(`/v2/points/cumulative-history`, {
    signal: abortSignal,
  });
  return data;
};

export const getLeaderboard = async ({ abortSignal }: { abortSignal?: AbortSignal }): Promise<ILeaderboardPoint[]> => {
  const { data } = await axios.get<ILeaderboardPoint[]>(`/v2/points/leaderboard`, {
    signal: abortSignal,
  });
  return data;
};

export const getUserPoint = async ({
  abortSignal,
  wallet,
}: {
  abortSignal?: AbortSignal;
  wallet?: string;
}): Promise<IUserPoint> => {
  const { data } = await axios.get<IUserPoint>(`/v2/users/${wallet}/points`, {
    signal: abortSignal,
  });
  return data;
};

export type ILeaderboardPoint = {
  unrealized: number;
  realized: number;
  totalRealized: number;
  rank: number | null;
  lastWeekRank: number | null;
  wallet: string;
};

export type ICumulativePoints = {
  realizedDate: Date;
  realizedPoint: number;
  totalRealisedPoint?: number;
}[];

export type IUserPoint = {
  totalPoint: { realized: number; unrealized: number } | null;
  weeklyLog: {
    unrealized: number;
    rank: number;
  } | null;
  weeklyPoint: { realized: number } | null;
  rank: number | null;
};
