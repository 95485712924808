import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme/Box";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Hidden, Input, Option, Select, SlashButton, SlashText, Text } from "@cyanco/components/theme/v3";
import { Search } from "@cyanco/components/theme/v3/icons";

import { currencyList } from "@/apis/coinbase";
import { IVault } from "@/apis/vault/types";
import { CHAIN_IDS_TO_NAMES, CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS, SupportedChainId } from "@/constants/chains";
import { useDebounce } from "@/hooks/useDebounce";

import { useLendSupportedCollectionsFiltered } from "../LendDataContext";
import { ActivityPeriod, IActivityPeriodType, LendView, useLendSelectors } from "../LendPageContext";

export const CollectionSelectors: React.FC<{ vaults: IVault[] }> = () => {
  const {
    selectedActivityPeriod,
    selectedChain,
    setSelectedActivityPeriod,
    setSelectedChain,
    selectedView,
    selectedCurrency,
    setSelectedCurrency,
    setSelectedView,
  } = useLendSelectors();

  return (
    <LendSelectorWrapper>
      <DropDowns>
        <Hidden tabletDown>
          <Box h="46px">
            <Select onChange={setSelectedChain} value={selectedChain}>
              {CYAN_SUPPORTED_CHAIN_IDS.map(chainId => (
                <Option value={chainId} key={chainId}>
                  <Flex gap="10px" alignItems="center">
                    <ChainLogo src={CHAIN_INFO[chainId].logoUrl} />
                    <Text size="lg" color="secondary" weight="500">
                      {CHAIN_INFO[chainId].label}
                    </Text>
                  </Flex>
                </Option>
              ))}
            </Select>
          </Box>
        </Hidden>
        <Box h="46px">
          <Select onChange={setSelectedCurrency} value={selectedCurrency}>
            <Option value={currencyList.eth} key={currencyList.eth}>
              {currencyList.eth}
            </Option>
            <Option value={currencyList.weth} key={currencyList.weth}>
              {currencyList.weth}
            </Option>
            <Option value={currencyList.pol} key={currencyList.pol}>
              {currencyList.pol}
            </Option>
          </Select>
        </Box>
        <Hidden tabletUp>
          <Box style={{ minWidth: "60px" }} h="46px">
            <Select onChange={setSelectedChain} value={selectedChain}>
              {CYAN_SUPPORTED_CHAIN_IDS.map(chainId => (
                <Option value={chainId} key={chainId}>
                  <Flex gap="10px" alignItems="center">
                    <ChainLogo src={CHAIN_INFO[chainId].logoUrl} />
                  </Flex>
                </Option>
              ))}
            </Select>
          </Box>
        </Hidden>
        <Box h="46px">
          <Select onChange={setSelectedActivityPeriod} value={selectedActivityPeriod}>
            {(Object.keys(ActivityPeriod) as Array<IActivityPeriodType>).map(period => (
              <Option value={period} key={period}>
                {ActivityPeriod[period]}
              </Option>
            ))}
          </Select>
        </Box>
        <CollectionSearch />
      </DropDowns>
      <CartWrapper direction="row" justifyContent="flex-end">
        <ViewSelectorWrapper>
          <ViewType isActive={LendView.p2p === selectedView} onClick={() => setSelectedView(LendView.p2p)}>
            <Text size="sm" color={LendView.p2p === selectedView ? "secondary" : "gray0"} textWrap={false}>
              {`Peer-to-Peer`}
            </Text>
          </ViewType>
          <ViewType isActive={LendView.vault === selectedView} onClick={() => setSelectedView(LendView.vault)}>
            <Text size="sm" color={LendView.vault === selectedView ? "secondary" : "gray0"} textWrap={false}>
              {`Peer-to-Vault`}
            </Text>
          </ViewType>
        </ViewSelectorWrapper>
      </CartWrapper>
    </LendSelectorWrapper>
  );
};

export const CollectionSearch = ({ minWidth }: { minWidth?: string }) => {
  const location = useLocation();
  const { setSelectedCollectionSearch, selectedCollectionSearch } = useLendSelectors();
  const { collections, collectionsLoading } = useLendSupportedCollectionsFiltered();
  const theme = useTheme();
  const [collectionInputValue, setCollectionInputValue] = useState(selectedCollectionSearch);
  const searchInput = useRef<HTMLInputElement>(null);
  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  useDebounce(
    () => {
      setSelectedCollectionSearch(collectionInputValue);
    },
    [collectionInputValue],
    1000,
  );
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "/") {
        searchInput.current?.focus();
      }
      if (e.key === "Escape") {
        searchInput.current?.blur();
      }
      if (e.key == "ArrowUp") {
        setIndex(index > 0 ? index - 1 : collections.length - 1);
      }
      if (e.key == "ArrowDown") {
        setIndex(index + 1 < collections.length ? index + 1 : 0);
      }
      if (e.key == "Enter" && index >= 0 && index < collections.length) {
        setOpen(false);
        navigate(
          `/lend/${CHAIN_IDS_TO_NAMES[collections[index].chainId as SupportedChainId]}/${collections[index].address}`,
        );
      }
    };
    document.addEventListener("keyup", handleKeyDown);
    return () => document.removeEventListener("keyup", handleKeyDown);
  }, [searchInput, collections, index]);

  const navigate = useNavigate();

  return (
    <Box h="46px" style={{ minWidth: minWidth }}>
      <Input
        placeholder={`Search collections`}
        fontSize="lg"
        fontWeight="400"
        icon={<Search color={theme.colors.gray0} />}
        value={collectionInputValue}
        onChange={e => setCollectionInputValue(e.target.value)}
        inputRef={searchInput}
        onFocus={() => location.pathname !== "/lend" && setOpen(true)}
        onBlur={() => location.pathname !== "/lend" && setOpen(false)}
        p="0.45rem"
      >
        <SlashButton>
          <SlashText size="lg" weight="500">
            /
          </SlashText>
        </SlashButton>
      </Input>
      {open && !collectionsLoading && collectionInputValue.length > 0 && (
        <SearchResultBox>
          <Flex direction="column" gap="2px">
            {collections.length === 0 && <Text color="secondary">{`No results found`}</Text>}
            {collections.map((collection, ind) => (
              <SearchCollectionItem
                key={collection.address}
                variant="ghost"
                isActive={ind == index}
                onClick={() => {
                  navigate(`/lend/${CHAIN_IDS_TO_NAMES[collection.chainId as SupportedChainId]}/${collection.address}`);
                }}
              >
                <Text color="secondary">{collection.name}</Text>
              </SearchCollectionItem>
            ))}
          </Flex>
        </SearchResultBox>
      )}
    </Box>
  );
};

const LendSelectorWrapper = styled.div`
  padding-bottom: 20px;
  gap: 1.5rem;
  top: 75px;
  position: sticky;
  z-index: 6;
  background: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    top: 60px;
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    padding: 5px 0px;
  }
`;
const DropDowns = styled.div`
  display: grid;
  grid-template-columns: 11rem 2fr 6.25rem 4.5fr;
  gap: 1rem;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    gap: 0.5rem;
    grid-template-columns: repeat(4, 1fr);
    & div:last-child {
      grid-column: 1/-1;
    }
  }
`;
const ChainLogo = styled.img`
  width: 20px;
  height: 20px;
`;

const SearchResultBox = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary} !important;
  padding: 0.8rem;
  border-radius: 10px;
  z-index: 200 !important;
  max-height: 400px;
  overflow: auto;
`;

const SearchCollectionItem = styled(Button)<{ isActive: boolean }>`
  padding: 4px 4px;
  justify-content: flex-start;
  border-radius: 0;
  background: ${({ theme, isActive }) => isActive && theme.colors.gray10};
`;

const ViewSelectorWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.gray20};
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 3px;
  flex-direction: row;
`;
const ViewType = styled.div<{ isActive: boolean }>`
  align-items: center;
  display: flex;
  padding: 0 1rem;
  justify-content: center;
  background-color: ${props => (props.isActive ? props.theme.colors.gray20 : "transparent")};
  border-radius: 7px;
  height: 100%;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    height: 25px;
  }
  :hover {
    color: ${({ theme, isActive }) => !isActive && theme.colors.secondary};
  }
`;

const CartWrapper = styled(Flex)`
  justify-content: flex-end;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    order: -1;
  }
`;
