import styled from "styled-components";

import { Box, Flex } from "@cyanco/components/theme";
import { Hidden, SkeletonLine, Text } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { ICollectionBe } from "@/apis/collection/types";
import { useAppContext } from "@/components/AppContextProvider";
import { bigNumToFloat, displayInUSD, shortenName } from "@/utils";

export const LoanRangeLoading = () => {
  return (
    <Container>
      <Flex direction="column">
        <StyledBox>
          <Text weight="600" size="xs" color="secondary">{`Quick ranges of instant loans:`}</Text>
        </StyledBox>
      </Flex>
      <Flex direction="column" gap="0.7rem" p="0 0.8rem">
        {Array.from(Array(5).keys()).map(loader => (
          <Flex direction="row" key={loader} gap="10px" alignItems="center">
            <SkeletonLine
              style={{
                minHeight: "36px",
                minWidth: "36px",
              }}
            />
            <Flex direction="column" w="100%" gap="2px">
              <Flex justifyContent="space-between">
                <SkeletonLine w="50px" h="14.5px" />
                <SkeletonLine w="50px" h="14.5px" />
              </Flex>
              <Flex justifyContent="space-between" gap="2px">
                <SkeletonLine w="50px" h="12px" />
                <SkeletonLine w="50px" h="12px" />
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Container>
  );
};

export const LoanRange = ({ collections }: { collections: ICollectionBe[] }) => {
  const { currencySymbol, usdPrice } = useAppContext();
  return (
    <Container w="100%">
      <Flex direction="column">
        <StyledBox>
          <Text weight="600" size="xs" color="secondary">{`Quick ranges of instant loans:`}</Text>
        </StyledBox>
      </Flex>
      <Flex direction="column" gap="0.7rem" p="0 0.8rem">
        {collections.map(collection => {
          const currentCurrencySymbol = collection.floorAsk.price
            ? collection.floorAsk.price.currency.symbol
            : currencySymbol;
          return (
            <Flex direction="row" key={collection.address} gap="10px" alignItems="center">
              <StyledImg src={collection.image || NoImage} alt={collection.name} />
              <Flex direction="column" w="100%" justifyContent="center">
                <Flex justifyContent="space-between">
                  <Text weight="800" size="xs" color="secondary">
                    {shortenName(collection.name)}
                  </Text>
                  <Hidden tabletDown style={{ lineHeight: "12px" }}>
                    <Text weight="500" size="xs" color="secondary">
                      {`${(bigNumToFloat(collection.floorAsk.price?.amount.raw || 0) * 0.25).toFixed(2)} - ${(
                        bigNumToFloat(collection.floorAsk.price?.amount.raw || 0) * 0.66
                      ).toFixed(2)} ${currentCurrencySymbol}`}
                    </Text>
                  </Hidden>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Hidden tabletUp style={{ lineHeight: "12px" }}>
                    <Text weight="500" size="xxs" color="gray80">
                      {`${(bigNumToFloat(collection.floorAsk.price?.amount.raw || 0) * 0.25).toFixed(2)} - ${(
                        bigNumToFloat(collection.floorAsk.price?.amount.raw || 0) * 0.66
                      ).toFixed(2)} ${currentCurrencySymbol}`}
                    </Text>
                  </Hidden>
                  <Hidden tabletDown style={{ lineHeight: "12px" }}>
                    <Text weight="500" size="xxs" color="gray80">
                      {collection.vaults[0]?.name ?? ""}
                    </Text>
                  </Hidden>
                  <Text weight="500" size="xxs" color="gray80">
                    {`${displayInUSD(
                      bigNumToFloat(collection.floorAsk.price?.amount.raw || 0) *
                        0.25 *
                        usdPrice[currentCurrencySymbol],
                    )} - ${displayInUSD(
                      bigNumToFloat(collection.floorAsk.price?.amount.raw || 0) *
                        0.66 *
                        usdPrice[currentCurrencySymbol],
                    )} `}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Container>
  );
};
const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-style: solid;
  padding-bottom: 0.8rem;
  width: 100%;
  transition: border-color 0.2s ease-in-out;
  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
  }
`;

const StyledImg = styled.img`
width: 36px;
height: 36px;
min-width: 36px;
min-height: 36px;
border-radius: 10px;
} `;

const StyledBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray20};
  padding: 0.5rem 0.8rem;
`;
