import { BigNumber } from "ethers";

import { axios } from "../axios";

export const notifyVaultBalanceError = async ({
  vaultAddress,
  loanAmount,
  planType,
  vaultBalance,
  requestedItems,
  requestedWallet,
}: {
  vaultAddress: string;
  vaultBalance: BigNumber;
  loanAmount: BigNumber;
  planType: string;
  requestedItems: string;
  requestedWallet: string;
}) => {
  try {
    await axios.post("/alert/notify-vault-balance", {
      requestedWallet,
      vaultAddress,
      planType,
      loanAmount: loanAmount.toString(),
      vaultBalance: vaultBalance.toString(),
      requestedItems,
    });
  } catch (error) {
    console.error(error);
  }
};

export const notifyApeVaultBalanceError = async ({
  vault,
  user,
  vaultBalance,
  requestedAmount,
}: {
  vault: string;
  vaultBalance: string;
  requestedAmount?: string;
  user: string;
}) => {
  await axios.post("/alert/notify-ape-vault-balance", { vault, vaultBalance, user, requestedAmount });
};

export const notifyApeCoinActivity = async ({
  amount,
  user,
  action,
  token,
}: {
  amount: string;
  user: string;
  action: string;
  token: string;
}) => {
  try {
    await axios.post("/alert/notify-ape-coin", {
      amount,
      user,
      action,
      token,
    });
  } catch (error) {
    console.error(error);
  }
};
