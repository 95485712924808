import isEqual from "lodash.isequal";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { Flex, Text } from "@cyanco/components/theme";

import { updateVaultDetail } from "@/apis/vault/admin";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { useVaults } from "@/components/Vault/VaultDataProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";

import { SaveSettingsButton } from "./PoolManagement";
import { getVaultAdminSignatureExpiry, signVaultDetailsUpdate } from "./utils";

const vault_name_length_limit = 35;
const vault_description_length_limit = 800;

export const PoolDetails = ({ name, description, address }: { name: string; description: string; address: string }) => {
  const { account, signer } = useWeb3React();
  const { signInWallet } = useAuthContext();
  const { setVaults, vaults } = useVaults();
  const [vaultName, setVaultName] = useState(name);
  const [vaultDescription, setVaultDescription] = useState(description);
  useEffect(() => {
    setVaultName(name);
    setVaultDescription(description);
  }, [address]);
  const updateDetails = async () => {
    if (!signer || !account) return;
    const { token } = await signInWallet();
    const expiryDate = getVaultAdminSignatureExpiry();
    const signature = await signVaultDetailsUpdate(vaultName, vaultDescription, address, expiryDate, signer);
    await updateVaultDetail({
      name: vaultName,
      description: vaultDescription,
      signature,
      token,
      vaultAddress: address,
      expiryDate,
    });
    const selectedVault = vaults.find(vault => vault.contractAddress === address);
    if (selectedVault)
      setVaults([
        ...vaults.filter(vault => vault.contractAddress !== selectedVault.contractAddress),
        {
          ...selectedVault,
          description: vaultDescription,
          name: vaultName,
        },
      ]);
  };

  return (
    <Flex direction="column" gap="2.5rem" p="3rem 2rem 2rem 2rem">
      <Text color="secondary" style={{ fontSize: "33px" }} weight="600">{`Pool Details`}</Text>
      <Flex direction="column" gap="1rem">
        <Flex gap="5px" alignItems="center">
          <Text weight="600" size="lg" color="secondary">
            {`Pool Name`}
          </Text>
        </Flex>
        <InputContainer alignItems="center" justifyContent="space-between">
          <Input
            value={vaultName}
            onChange={e => {
              setVaultName(e.target.value.slice(0, vault_name_length_limit));
            }}
          />
          <Text color="gray30" weight="600" size="md">{`${vaultName.length}/${vault_name_length_limit}`}</Text>
        </InputContainer>
      </Flex>

      <Flex direction="column" gap="1rem">
        <Flex gap="5px" alignItems="center">
          <Text weight="600" size="lg" color="secondary">
            {`Pool Description`}
          </Text>
        </Flex>
        <InputContainer direction="column" justifyContent="flex-end" alignItems="flex-end" gap="5px">
          <TextArea
            value={vaultDescription}
            onChange={e => {
              setVaultDescription(e.target.value.slice(0, vault_description_length_limit));
            }}
            placeholder="Pool description..."
          />
          <Text
            color="gray30"
            weight="600"
            size="md"
          >{`${vaultDescription.length}/${vault_description_length_limit}`}</Text>
        </InputContainer>
      </Flex>
      <SaveSettingsButton
        onClick={updateDetails}
        disabled={isEqual(vaultDescription, description) && isEqual(name, vaultName)}
      />
    </Flex>
  );
};

const Input = styled.input`
  border: 0;
  color: ${({ theme }) => `${theme.colors.gray0}`};
  background: ${({ theme }) => ` ${theme.colors.transparent}`};
  font-size: 22px;
  outline: none;
  width: 100%;
  font-weight: 700;
  transition: 0.2s;
  &:focus {
    color: ${({ theme }) => `${theme.colors.secondary}`};
  }
`;

const TextArea = styled.textarea`
  border: 0;
  color: ${({ theme }) => `${theme.colors.gray0}`};
  background: ${({ theme }) => ` ${theme.colors.transparent}`};
  font-size: 20px;
  outline: none;
  width: 100%;
  resize: vertical;
  height: 120px;
  transition: 0.2s;
  font-weight: 600;
  font-family: Inter;
  &:focus {
    color: ${({ theme }) => `${theme.colors.secondary}`};
  }
`;

const InputContainer = styled(Flex)`
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.gray30}`};
  padding-bottom: 10px;
`;
