import dayjs from "dayjs";

import { useModal } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IPeerPlan, PeerPlanPaymentNumber, PeerPlanStatuses } from "@/apis/p2p/types";

import { PaymentRow } from "./PaymentRow";
import { HistoricalPeerPlanPaymentModal } from "./PeerPaymentModal";

export const PeerPlanPaymentRow = ({ position }: { position: IPeerPlan }) => {
  const { setModalContent } = useModal();

  const onClick = (
    position: IPeerPlan,
    payment: {
      txnHash?: string;
      createdAt: Date;
      paymentNumber: number;
      amount: string;
      status: string;
    },
  ) => {
    setModalContent({
      title: `Payment Details`,
      content: (
        <HistoricalPeerPlanPaymentModal
          payment={{
            imageUrl: position.metadata ? position.metadata.imageUrl : NoImage,
            collectionName: position.collection.name,
            paymentNumber: payment.paymentNumber,
            status: payment.status,
            amount: payment.amount,
            tokenId: position.tokenId,
            serviceFeeRate: position.serviceFeeRate,
            interestRate: position.loanBid.interestRate,
            paidDate: payment.createdAt,
            txnHash: payment.txnHash,
            currency: position.loanBid.currency,
            lender: position.borrowerAddress,
          }}
        />
      ),
    });
  };
  return (
    <>
      {(position.status === PeerPlanStatuses.DEFAULTED || position.status === PeerPlanStatuses.LIQUIDATED) && (
        <PaymentRow
          onClick={() =>
            onClick(position, {
              paymentNumber: 1,
              createdAt: position.defaultedAt
                ? position.defaultedAt
                : dayjs(position.createdAt).add(position.loanBid.term, "seconds").toDate(),
              amount: position.monthlyAmount,
              status: "missed",
            })
          }
          key={`${position.planId}:P2P:defaulted`}
          payment={{
            imageUrl: position.metadata ? position.metadata.imageUrl : NoImage,
            collectioName: position.collection.name,
            createdAt: position.defaultedAt
              ? position.defaultedAt
              : dayjs(position.createdAt).add(position.loanBid.term, "seconds").toDate(),
            paymentNumber: 1,
            amount: position.monthlyAmount,
            currency: position.loanBid.currency,
            status: "missed",
            tokenId: position.tokenId,
            totalPaymentNumber: 1,
            positionId: position.planId,
            planType: "P2P",
            isAutoLiquidationEnabled: false,
          }}
        />
      )}
      {position.payments.map((payment, index) => {
        const status = payment.paymentNumber === PeerPlanPaymentNumber.COMPLETED ? "completed" : "extended";
        return (
          <PaymentRow
            onClick={() => onClick(position, { ...payment, status })}
            key={`${index}:${position.planId}:P2P`}
            payment={{
              imageUrl: position.metadata ? position.metadata.imageUrl : NoImage,
              collectioName: position.collection.name,
              createdAt: payment.createdAt,
              paymentNumber: payment.paymentNumber,
              amount: payment.amount,
              currency: position.loanBid.currency,
              status,
              tokenId: position.tokenId,
              totalPaymentNumber: 1,
              transactionHash: payment.txnHash,
              positionId: position.planId,
              planType: "P2P",
              isAutoLiquidationEnabled: false,
            }}
          />
        );
      })}
    </>
  );
};
