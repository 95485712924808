import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { Button, Text } from "@cyanco/components/theme/v3";

import { useAppContext } from "@/components/AppContextProvider";
import { Experiments } from "@/utils/experimentList";

import { AccountPageTabs, useAccountPageContext } from "../../AccountPageContext";

export const Tabs = () => {
  const navigate = useNavigate();
  const { experiment } = useAppContext();
  const { selectedTab } = useAccountPageContext();
  return (
    <Container justifyContent="space-between">
      <TabButton
        active={selectedTab == AccountPageTabs.wallets}
        variant="ghost"
        onClick={() => {
          navigate(`/account`);
        }}
      >
        <TabText
          weight="500"
          size="lg"
          color={selectedTab === AccountPageTabs.wallets ? "secondary" : "gray0"}
          textWrap={false}
        >{`Wallets`}</TabText>
      </TabButton>
      <TabButton
        active={selectedTab == AccountPageTabs.positions}
        variant="ghost"
        onClick={() => navigate(`/account/${AccountPageTabs.positions}`)}
      >
        <TabText
          weight="500"
          size="lg"
          color={selectedTab === AccountPageTabs.positions ? "secondary" : "gray0"}
          textWrap={false}
        >
          {experiment.result && experiment.result[Experiments.P2P] ? `Borrow` : `Loans`}
        </TabText>
      </TabButton>
      {(!experiment.result || !experiment.result[Experiments.P2P]) && (
        <TabButton
          active={selectedTab == AccountPageTabs.vault_tokens}
          variant="ghost"
          onClick={() => {
            navigate(`/account/${AccountPageTabs.vault_tokens}`);
          }}
        >
          <TabText
            weight="500"
            size="lg"
            color={selectedTab === AccountPageTabs.vault_tokens ? "secondary" : "gray0"}
            textWrap={false}
          >{`Vaults`}</TabText>
        </TabButton>
      )}
      {experiment.result && experiment.result[Experiments.P2P] && (
        <TabButton
          active={selectedTab == AccountPageTabs.lending}
          variant="ghost"
          onClick={() => {
            navigate(`/account/${AccountPageTabs.lending}`);
          }}
        >
          <TabText
            weight="500"
            size="lg"
            color={selectedTab === AccountPageTabs.lending ? "secondary" : "gray0"}
            textWrap={false}
          >{`Lending`}</TabText>
        </TabButton>
      )}
    </Container>
  );
};

const Container = styled(Flex)`
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: ${({ theme }) => theme.borderWidth};
  border-color: ${({ theme }) => theme.colors.gray20};
  border-style: solid;
  background-color: ${({ theme }) => theme.colors.primary};
  height: 36px;
  padding: 5px;
  gap: 0px;
  transition: border-color 0.2s ease-in-out;
  :hover {
    border-color: ${({ theme }) => theme.colors.gray30};
  }
`;

const TabText = styled(Text)`
  transition: color 0.2s ease-in-out;
  padding: 9px 12px;
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const TabButton = styled(Button)<{ active?: boolean }>`
  border-radius: 5px;
  background-color: ${({ theme, active }) => active && (theme.theme == "light" ? "#c8c8c8" : "#2c2c2c")};
`;
