import { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme";
import { Option, Select, Text } from "@cyanco/components/theme/v3";

import { IApeCoinSource } from "../../types";

export const ApeCoinSourceSelector = ({
  selectedSource,
  onChange,
  disabled,
  showAll,
}: {
  selectedSource: IApeCoinSource;
  onChange: (a: IApeCoinSource) => void;
  disabled?: boolean;
  showAll?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box w="144px" h="27px">
      <Select
        onChange={onChange}
        value={selectedSource}
        textSize="xs"
        p="4px 7px"
        bg={theme.colors.gray10}
        borderColor={theme.colors.gray0}
        disabled={disabled}
      >
        {showAll && (
          <Option
            value={IApeCoinSource.all}
            style={{
              padding: "4px 7px",
            }}
          >
            <Text size="xs" color="secondary">
              {`-`}
            </Text>
          </Option>
        )}
        <Option
          value={IApeCoinSource.borrow}
          style={{
            padding: "5px 7px 5px",
          }}
        >
          <Text size="xs" color="secondary">
            {`Borrow ApeCoins`}
          </Text>
        </Option>
        <Option
          value={IApeCoinSource.owned}
          style={{
            padding: "1px 7px 5px",
          }}
        >
          <Text size="xs" color="secondary">
            {`Owned ApeCoins`}
          </Text>
        </Option>
      </Select>
    </Box>
  );
};
