import { useState } from "react";
import { Clock } from "react-feather";
import { useTheme } from "styled-components";

import { Button, Flex, Hidden, Text, useModal } from "@cyanco/components/theme";

import { IVaultLiquidatedNft } from "@/apis/vault/admin/types";
import { IVault, IVaultLatestLoan } from "@/apis/vault/types";
import {
  MultiSelectionBox,
  SelectBoxMobileMenu,
  SelectBoxMoreOptions,
  SelectBoxStyledButton,
  SelectBoxTextButton,
  SelectBoxTextInsideButton,
  SelectionBoxMobileMenuWrapper,
} from "@/components/Bnpl/components/SelectItems/MultiSelectionBox";

import { DefaultedLoanModalBulk } from "../ClaimNft/DefaultedLoanModalBulk";
import { VaultRefundBulkModal } from "../VaultRefund/VaultRefundBulkModal";

export const SelectionBox = ({
  selectedDefaultedLoans,
  setSelectedDefaultedLoans,
  selectedLiquidatedNfts,
  setSelectedLiquidatedNfts,
  vault,
}: {
  selectedDefaultedLoans: IVaultLatestLoan[];
  setSelectedDefaultedLoans: React.Dispatch<React.SetStateAction<IVaultLatestLoan[]>>;
  selectedLiquidatedNfts: IVaultLiquidatedNft[];
  setSelectedLiquidatedNfts: React.Dispatch<React.SetStateAction<IVaultLiquidatedNft[]>>;
  vault: IVault;
}) => {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);
  const { setModalContent } = useModal();

  const openPlanModal = () => {
    if (selectedDefaultedLoans.length > 1) {
      setModalContent({
        title: "Claim NFTs",
        content: <DefaultedLoanModalBulk loans={selectedDefaultedLoans} vault={vault} />,
      });
    }
  };

  const openRefundModal = () => {
    if (selectedLiquidatedNfts.length > 1) {
      setModalContent({
        title: "Return Funds",
        content: <VaultRefundBulkModal nfts={selectedLiquidatedNfts} vault={vault} />,
      });
    }
  };
  return (
    <>
      <Hidden laptopSDown>
        <MultiSelectionBox show={selectedDefaultedLoans.length > 1 || selectedLiquidatedNfts.length > 1}>
          <Flex alignItems="center" gap="1rem" style={{ flexGrow: 1 }}>
            <SelectBoxTextInsideButton size="lg" color="secondary" style={{ width: "78px" }}>{`${
              selectedDefaultedLoans.length
            } loan${selectedDefaultedLoans.length > 1 ? "s" : ""}`}</SelectBoxTextInsideButton>
            <Text size="lg" color="gray0">
              |
            </Text>
            <SelectBoxTextInsideButton size="lg" color="secondary" style={{ width: "78px" }}>{`${
              selectedLiquidatedNfts.length
            } NFT${selectedLiquidatedNfts.length > 1 ? "s" : ""}`}</SelectBoxTextInsideButton>
            <Text size="lg" color="gray0">
              |
            </Text>
            <SelectBoxTextButton
              size="lg"
              color="cyan"
              onClick={() => {
                setSelectedDefaultedLoans([]);
                setSelectedLiquidatedNfts([]);
              }}
            >
              Clear
            </SelectBoxTextButton>
          </Flex>
          <Flex gap="1rem">
            <SelectBoxStyledButton onClick={openPlanModal} disabled={selectedDefaultedLoans.length === 0}>
              <Flex gap="7px" alignItems="center">
                <SelectBoxTextInsideButton color="black" size="lg" weight="600" textWrap={false}>
                  {`Claim NFT${selectedDefaultedLoans.length > 1 ? "s" : ""}`}
                </SelectBoxTextInsideButton>
              </Flex>
            </SelectBoxStyledButton>
            <SelectBoxStyledButton onClick={openRefundModal} disabled={selectedLiquidatedNfts.length === 0}>
              <Flex gap="7px" alignItems="center">
                <SelectBoxTextInsideButton color="black" size="lg" weight="600" textWrap={false}>
                  {`Return funds`}{" "}
                </SelectBoxTextInsideButton>
              </Flex>
            </SelectBoxStyledButton>
          </Flex>
        </MultiSelectionBox>
      </Hidden>
      <Hidden laptopSUp>
        <MultiSelectionBox show={selectedDefaultedLoans.length !== 0 || selectedLiquidatedNfts.length !== 0}>
          <SelectionBoxMobileMenuWrapper>
            <SelectBoxMoreOptions
              style={{ padding: "6px 12px", background: showMenu ? "#2C2C2C" : "#6C6C6C" }}
              onClick={() => setShowMenu(!showMenu)}
            >
              <Text color="white" size="xxs" weight="600" textWrap={false}>
                {`More options`}
              </Text>
            </SelectBoxMoreOptions>
            <SelectBoxMobileMenu direction="column" show={showMenu}>
              <Flex
                direction="column"
                gap="10px"
                p="14px 22px"
                style={{
                  borderTop: "1px solid #2C2C2C ",
                }}
              >
                <Text
                  size="xs"
                  color="secondary"
                  weight="600"
                  textWrap={false}
                  onClick={() => {
                    setSelectedDefaultedLoans([]);
                    setSelectedLiquidatedNfts([]);
                  }}
                >
                  Clean
                </Text>
              </Flex>
            </SelectBoxMobileMenu>
          </SelectionBoxMobileMenuWrapper>
          <Flex gap="10px">
            <Button
              onClick={openPlanModal}
              style={{ padding: "6px 12px", width: "100px" }}
              disabled={selectedDefaultedLoans.length === 0}
            >
              <Flex gap="7px" alignItems="center">
                <Clock color={theme.colors.black} size={10} />
                <Text color="black" size="xxs" weight="600" textWrap={false}>
                  {`Claim NFT${selectedDefaultedLoans.length > 1 ? "s" : ""}`}
                </Text>
              </Flex>
            </Button>
            <Button
              onClick={openRefundModal}
              style={{ padding: "6px 12px", width: "100px" }}
              disabled={selectedLiquidatedNfts.length === 0}
            >
              <Flex gap="7px" alignItems="center">
                <Clock color={theme.colors.black} size={10} />
                <Text color="black" size="xxs" weight="600" textWrap={false}>
                  {`Return funds`}
                </Text>
              </Flex>
            </Button>
          </Flex>
        </MultiSelectionBox>
      </Hidden>
    </>
  );
};
