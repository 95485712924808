import { axios } from "../axios";
import { IUser } from "./types";

export const getEmailVerificationStatus = async (token: string): Promise<boolean> => {
  return (
    await axios.get(`/users/email/verify`, {
      params: { token },
    })
  ).data;
};

export const getUser = async (token: string): Promise<IUser> => {
  const { data } = await axios.get<IUser>("/users/me", {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const sendUserLog = async (data: { wallet: string }): Promise<void> => {
  return await axios.post("/users/log", data);
};

export const updateUserProfile = async ({
  token,
  email,
  discordUsername,
  telegramUsername,
  receivePaymentAlerts,
  affiliateCode,
}: {
  token: string;
  email?: string | null;
  discordUsername?: string | null;
  telegramUsername?: string | null;
  affiliateCode?: string | null;
  receivePaymentAlerts: boolean;
}): Promise<void> => {
  return (
    await axios.patch(
      "/users/me",
      { email, discordUsername, telegramUsername, receivePaymentAlerts, affiliateCode },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    )
  ).data;
};

export const subscribeEmail = async (email: string) => {
  try {
    await axios.post("/v2/users/subscribe", {
      email,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
