import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { breakpoints } from "@cyanco/components/theme/config";
import { NotFound } from "@cyanco/components/theme/v3";

import { ICollectionBe } from "@/apis/collection/types";
import { IP2PLoanOffer } from "@/apis/p2p/types";

import {
  HeaderWrapper,
  ListContainer,
  ListViewBox,
  NftContainer,
  StyledListViewBox,
} from "../collection/Assets/ListedNfts";
import { LoanOfferHeader, LoanOfferRow, LoanOfferRowLoading } from "./LoanOfferRow";

export const LoanOffers = ({
  collection,
  offers,
  blockchainTimestamp,
  loading,
}: {
  collection: ICollectionBe;
  offers: IP2PLoanOffer[];
  blockchainTimestamp: number;
  loading: boolean;
}) => {
  return (
    <ListContainer>
      <StyledListViewBox style={{ padding: 0 }}>
        <Flex direction="row" w="100%" gap="2rem">
          <ListViewBox>
            <Header>
              <HeaderWrapper>
                <LoanOfferHeader />
              </HeaderWrapper>
            </Header>
            {loading ? (
              <>
                {Array.from(Array(4).keys()).map(loader => (
                  <LoanOfferRowLoading key={loader} />
                ))}
              </>
            ) : (
              <>
                {offers.length === 0 ? (
                  <NotFound msg={`No loan offers found.`} />
                ) : (
                  <NftContainer role="grid">
                    {offers.map(offer => (
                      <LoanOfferRow
                        offer={offer}
                        key={`${offer.id}`}
                        collection={collection}
                        blockchainTimestamp={blockchainTimestamp}
                      />
                    ))}
                  </NftContainer>
                )}
              </>
            )}
          </ListViewBox>
        </Flex>
      </StyledListViewBox>
    </ListContainer>
  );
};
const Header = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 65px;
  }
  align-items: end;
  border-bottom: none;
  background: ${({ theme }) => theme.colors.primary};
  transform-style: preserve-3d;
  z-index: 5;
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    z-index: -1;
    display: block;
    position: absolute;
    transform: translateZ(-1px);
    height: 120px;
    top: -10px;
    height: calc(100% + 10px);
    left: -5px;
    width: calc(100% + 10px);
  }
`;
