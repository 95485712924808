import { ReactNode, useEffect, useState } from "react";
import { Minus, Plus } from "react-feather";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";

export const ExpandableView = ({
  title,
  children,
  isOpen,
  onToggle,
}: {
  title: string;
  children: ReactNode[] | ReactNode;
  isOpen?: boolean;
  onToggle?: () => void;
}) => {
  const [height, setHeight] = useState("0");
  const toggleDescription = () => {
    if (onToggle) {
      onToggle();
      return;
    }
    if (height === "0") {
      setHeight("700px");
    } else setHeight("0");
  };
  useEffect(() => {
    if (isOpen) {
      setHeight("700px");
    } else setHeight("0");
  }, [isOpen]);
  return (
    <Container>
      <FAQTitle justifyContent="space-between" alignItems="center" onClick={toggleDescription}>
        <FAQTitleText>{title}</FAQTitleText>
        {height === "0" ? <PlusIcon strokeWidth={1.5} /> : <MinusIcon strokeWidth={1.5} />}
      </FAQTitle>

      <FAQDescription
        style={{
          transition: height === "0" ? "0.3s" : "0.5s",
          maxHeight: height,
        }}
      >
        {children}
      </FAQDescription>
    </Container>
  );
};

const PlusIcon = styled(Plus)`
  color: ${({ theme }) => theme.colors.secondary};
  height: 26px;
  width: 26px;
`;
const MinusIcon = styled(Minus)`
  color: ${({ theme }) => theme.colors.secondary};
  height: 26px;
  width: 26px;
`;
const Container = styled(Flex)`
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray80};
  width: 100%;
`;
const FAQTitle = styled(Flex)`
  cursor: pointer;
  opacity: 1;
  padding: 0.5rem 0rem;
  :hover {
    opacity: 0.8;
  }
`;
const FAQTitleText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-size: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
  white-space: break-word;
  width: 100%;
`;

const FAQDescription = styled.div`
  overflow: hidden;
`;
