import React from "react";

import { VaultDataProvider } from "./VaultDataProvider";
import { VaultPageProvider } from "./VaultPageContext";

export const VaultContextWrapperV3: React.FC = ({ children }) => {
  return (
    <VaultPageProvider>
      <VaultDataProvider>{children}</VaultDataProvider>
    </VaultPageProvider>
  );
};
