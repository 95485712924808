import styled, { css } from "styled-components";

import { Box } from "../../Box";

interface ICardProps {
  variant?: "filled" | "outlined";
  hover?: boolean;
}
export const Card = styled(Box)<ICardProps>`
  background-color: ${({ theme, variant }) =>
    variant === "outlined" ? theme.colors.transparent : theme.colors.gray10};
  border-color: ${({ theme }) => theme.colors.gray10};
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: ${({ theme }) => theme.borderWidth};
  border-style: solid;
  outline: none;
  transition: 0.2s;
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        border-color: ${({ theme }) => theme.colors.gray20};
      }
    `}
`;
