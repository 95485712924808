import { UAuthConnector } from "@uauth/web3-react";
import { SessionTypes } from "@walletconnect/types";
import { parseUri } from "@walletconnect/utils";
import { useEffect, useRef, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import styled, { useTheme } from "styled-components";

import { useCyanWalletAuthRequests, useCyanWalletSessions } from "@usecyan/cyan-wallet";
import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Input, Loader, SystemMessage, Text, useModal } from "@cyanco/components/theme/v3";
import {
  ArrowLeft,
  CheckCircle,
  Copy,
  CyanLogo,
  NewTab,
  ShutDown,
  WalletConnectIcon,
} from "@cyanco/components/theme/v3/icons";
import {
  ApeCoin,
  Blur,
  Delegatecash,
  Looksrare,
  NFTX,
  NoImage,
  Opensea,
  Pudgy,
  Reservoir,
  SudoSwap,
  X2Y2,
} from "@cyanco/components/theme/v3/images";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedChainId } from "@/constants/chains";

import AccountLogo from "../../assets/images/account-logo.svg";
import { WalletInfo } from "../../constants/wallet";
import { getActiveConnector, getChainExplorerURL, jumpToLink, shortenAddress, shortenName } from "../../utils";
import { useWalletContext } from "./WalletProvider";

export const WalletModal = ({ hideDisconnect = false }: { hideDisconnect?: boolean }) => {
  const { account, connector, ENSName, chainId, signer, provider } = useWeb3React();
  const { cyanWallet, createNewWallet } = useCyanWalletContext();
  const { sessions, disconnectSession, sessionProposals, rejectSessionProposal, approveSessionProposal } =
    useCyanWalletSessions();
  const { authRequests, approveAuthRequest } = useCyanWalletAuthRequests(chainId);
  const theme = useTheme();
  const { unsetModal } = useModal();
  const { focusOnCyanWalletByDefault } = useWalletContext();
  const [addressPlaceHolder, setAddressPlaceHolder] = useState<string>("");
  const [cyanWalletAddressPlaceHolder, setCyanWalletAddressPlaceHolder] = useState<string>();

  const [walletConnectInput, setWalletConnectInput] = useState<string>("");
  const [walletProvider, setWalletProvider] = useState<WalletInfo>();
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedCyan, setIsCopiedCyan] = useState(false);
  const [showConnectionError, setShowConnectionError] = useState(false);
  const [showInputError, setShowInputError] = useState(false);
  const [isInputChainging, setIsInputChainging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConnectedSites, setShowConnectedSites] = useState(false);
  const [sessionConnected, setSessionConnected] = useState<boolean>(false);
  const walletInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnCyanWalletByDefault && walletInput.current) {
      walletInput.current.focus();
    }
  }, [focusOnCyanWalletByDefault]);

  useEffect(() => {
    const checkAndGetAddressPlaceholder = async () => {
      if (account) {
        if (connector instanceof UAuthConnector) {
          const user = await connector.uauth.user();
          setAddressPlaceHolder(user.sub);
          return;
        }
        ENSName ? setAddressPlaceHolder(ENSName) : setAddressPlaceHolder(account);
      }
    };
    checkAndGetAddressPlaceholder();
    setWalletProvider(getActiveConnector(connector));
  }, [connector, account, ENSName]);

  useEffect(() => {
    const getEnsName = async () => {
      if (cyanWallet && provider) {
        if (
          chainId === SupportedChainId.MAINNET ||
          chainId === SupportedChainId.POLYGON ||
          chainId === SupportedChainId.BLAST
        ) {
          const ensName = await provider.lookupAddress(cyanWallet.walletAddress);
          if (ensName) {
            setCyanWalletAddressPlaceHolder(shortenName(ensName));
            return;
          }
        }
        setCyanWalletAddressPlaceHolder(shortenAddress(cyanWallet.walletAddress));
      }
    };
    if (cyanWallet) getEnsName();
  }, [cyanWallet, chainId]);

  const disconnectWallet = () => {
    if (connector?.deactivate) {
      void connector.deactivate();
    } else {
      void connector.resetState();
    }
    unsetModal();
  };
  const handleCopyMainWalletClick = () => {
    if (!account) return;
    setIsCopied(true);
    navigator.clipboard.writeText(account);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  const handleCopyCyanWalletClick = () => {
    if (!cyanWallet) return;
    setIsCopiedCyan(true);
    navigator.clipboard.writeText(cyanWallet.walletAddress);
    setTimeout(() => {
      setIsCopiedCyan(false);
    }, 1000);
  };

  const handleInputChainging = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setIsInputChainging(false);
    } else {
      setIsInputChainging(true);
    }
    setWalletConnectInput(e.target.value);
  };

  const accountURL = `${getChainExplorerURL(chainId)}/address/${account}`;
  const cyanWalletURL = `${getChainExplorerURL(chainId)}/address/${cyanWallet?.walletAddress || ""}`;
  const isCyanAddressAvailable = cyanWallet && cyanWallet.walletAddress;

  useEffect(() => {
    const { version, topic } = parseUri(walletConnectInput);
    if (version === 2) {
      const uriRequest = sessionProposals.find(request => request.pairingTopic === topic);
      if (uriRequest) {
        approveSessionProposal(uriRequest);
        setIsProcessing(false);
        setSessionConnected(true);
        setShowConnectedSites(true);
      }
    } else if (version === 1) {
      try {
        setIsProcessing(false);
        setSessionConnected(true);
        setShowConnectedSites(true);
      } catch (error) {}
    }
  }, [sessionProposals]);

  useEffect(() => {
    if (!cyanWallet) return;
    authRequests.forEach(async request => {
      try {
        await approveAuthRequest(request);
        setSessionConnected(true);
      } catch (e) {
        setAddressPlaceHolder("");
        setIsInputChainging(false);
        throw e;
      }
    });
  }, [authRequests]);

  const onConnect = async () => {
    if (!cyanWallet) return;
    const { version } = parseUri(walletConnectInput);

    if (Number.isNaN(version)) {
      setShowInputError(true);
      return;
    }

    setIsProcessing(true);
    await cyanWallet.pair(walletConnectInput);
  };

  const { execute: createCyanWallet, loading: creatingCyanWallet } = useAsyncCallback(async () => {
    if (!signer) return;

    await createNewWallet(signer);
  });

  return (
    <Flex direction="column" m="0 -1rem -1rem -1rem">
      <Flex direction="column" p="0 1rem 1.5rem" gap="0.3rem">
        <Flex justifyContent="space-between">
          <Text color="secondary" size="xs">
            {`Connected with`} {walletProvider && walletProvider.name}
          </Text>
          <Flex>
            <Button variant="ghost" onClick={handleCopyMainWalletClick}>
              {isCopied ? (
                <CheckCircle color={theme.colors.secondary} height={15} width={15} />
              ) : (
                <Copy color={theme.colors.secondary} height={15} width={15} />
              )}
            </Button>
            <Button variant="ghost" onClick={() => jumpToLink(accountURL)}>
              <NewTab color={theme.colors.secondary} height={15} width={15} />
            </Button>
            {!hideDisconnect && (
              <Button variant="ghost" onClick={disconnectWallet}>
                <ShutDown color={theme.colors.secondary} height={15} width={15} />
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="center" gap="0.5rem">
          <img src={AccountLogo} height={22} width={22} />
          <Text size="xl" color="secondary">
            {shortenName(addressPlaceHolder || "address", 15, 6, 4)}
          </Text>
        </Flex>
      </Flex>
      <CyanWrapper direction="column" p="1.5rem 1rem 1rem" gap="0.3rem">
        <Flex justifyContent="space-between">
          <Text color="secondary" size="xs">
            {`Cyan Wallet Connection`}
          </Text>
          <Flex>
            <Button variant="ghost" onClick={handleCopyCyanWalletClick} disabled={!isCyanAddressAvailable}>
              {isCopiedCyan ? (
                <CheckCircle color={theme.colors.secondary} height={15} width={15} />
              ) : (
                <Copy color={theme.colors.secondary} height={15} width={15} />
              )}
            </Button>
            <Button variant="ghost" onClick={() => jumpToLink(cyanWalletURL)} disabled={!isCyanAddressAvailable}>
              <NewTab color={theme.colors.secondary} height={15} width={15} />
            </Button>
          </Flex>
        </Flex>
        <CyanAddressWrapper justifyContent="space-between" alignItems="center" mb="0.8rem">
          <Flex alignItems="center" gap="0.5rem">
            <CyanLogo />
            <Text size="xl" color="secondary">
              {cyanWalletAddressPlaceHolder ? cyanWalletAddressPlaceHolder : `Non-existent`}
            </Text>
          </Flex>
          <ConnectedButton
            variant="ghost"
            onClick={() => {
              setSessionConnected(false);
              setWalletConnectInput("");
              setIsInputChainging(false);
              setIsProcessing(false);
              setShowConnectedSites(!showConnectedSites);
            }}
            disabled={!isCyanAddressAvailable}
          >
            {showConnectedSites ? (
              <Flex alignItems="center" gap="4px">
                <ArrowLeft color={theme.colors.secondary} height={12} width={12} />
                <Text color="secondary" size="xs">
                  {`Go back`}
                </Text>
              </Flex>
            ) : (
              <Text color="secondary" size="xs">
                {`Connected sites`}
              </Text>
            )}
          </ConnectedButton>
        </CyanAddressWrapper>
        {!isCyanAddressAvailable && (
          <Button onClick={createCyanWallet} disabled={creatingCyanWallet}>
            <Box p={"0.6rem"}>
              {creatingCyanWallet ? (
                <Loader stroke="black" size="13.5px" />
              ) : (
                <Text size="sm" weight="500" color="black">
                  {`Create Cyan Wallet`}
                </Text>
              )}
            </Box>
          </Button>
        )}
        {!showConnectedSites && sessionProposals.length === 0 && isCyanAddressAvailable && !sessionConnected && (
          <StyledInput
            p="0.4rem 0.4rem 0.4rem 0.7rem"
            placeholder={`Paste       Wallet Connect code`}
            onChange={handleInputChainging}
            value={walletConnectInput}
            disabled={!isCyanAddressAvailable}
            style={{ position: "relative" }}
            inputRef={walletInput}
          >
            {!showConnectionError && !showInputError ? (
              <ConnectButton disabled={!isCyanAddressAvailable || !isInputChainging} onClick={onConnect}>
                {isProcessing ? (
                  <Loader stroke="black" size="13.5px" />
                ) : (
                  <Text color="black" size="xs" weight="600">
                    {`Connect`}
                  </Text>
                )}
              </ConnectButton>
            ) : (
              <ClearButton
                onClick={() => {
                  setSessionConnected(false);
                  setWalletConnectInput("");
                  setIsInputChainging(false);
                  setShowConnectionError(false);
                  setShowInputError(false);
                }}
              >
                <Text color="white" size="xs" weight="600">
                  {`Clear`}
                </Text>
              </ClearButton>
            )}

            <div
              style={{
                position: "absolute",
                left: "51.5px",
                top: "11.5px",
                display: isInputChainging ? "none" : "block",
              }}
            >
              <WalletConnectIcon color={theme.colors.secondary} />
            </div>
          </StyledInput>
        )}
        {!showConnectedSites && showInputError && (
          <Flex direction="column" mt="10px" gap="10px">
            <SystemMessage
              variant="error"
              description={`Please double check the input. Only Wallet Connect v1 or v2 code will be recognized. Please clear and try again.`}
              title={`Input Error`}
              msg={
                <Flex alignItems="center" gap="5px" style={{ cursor: "pointer" }}>
                  <Text size="xs" weight="500" color="black">
                    {`User input is not a recognized connection code.`}
                  </Text>
                </Flex>
              }
            />
          </Flex>
        )}
        {!showConnectedSites && showConnectionError && (
          <Flex direction="column" mt="10px" gap="10px">
            <SystemMessage
              variant="error"
              description={`The entered Wallet Connect code is already in use. Please enter another Wallet Connect code and try again.`}
              title={`Connection Error`}
              msg={
                <Flex alignItems="center" gap="5px" style={{ cursor: "pointer" }}>
                  <Text size="xs" weight="500" color="black">
                    {`This instance is already connected.`}
                  </Text>
                </Flex>
              }
            />
          </Flex>
        )}
        {sessionProposals.length > 0 && !showConnectedSites && (
          <Flex direction="column" gap="0.5rem">
            <Text color="gray0">{`An app is requesting connection:`}</Text>
            {sessionProposals.map(session => (
              <Flex justifyContent="space-between" key={session.id} alignItems="center">
                <Flex alignItems="center" gap="0.5rem">
                  <img
                    src={session.proposer.metadata.icons[0]}
                    height={24}
                    width={24}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                  <Text size="xs" color="secondary">
                    {session.proposer.metadata.name}
                  </Text>
                </Flex>

                <Flex gap="8px">
                  <Approve
                    onClick={() => {
                      approveSessionProposal(session);
                      setShowConnectedSites(true);
                    }}
                  >
                    <Text size="xs">{`Approve`}</Text>
                  </Approve>
                  <Reject
                    onClick={() => {
                      rejectSessionProposal(session);
                      setShowConnectedSites(true);
                    }}
                  >
                    <Text size="xs" color="red">
                      {`Reject`}
                    </Text>
                  </Reject>
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}
        {showConnectedSites && (
          <Flex direction="column" gap="0.5rem">
            {sessions.length === 0 && (
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" gap="0.5rem">
                  <Text color="gray0" size="xs">
                    {`There are no apps connected.`}
                  </Text>
                </Flex>
              </Flex>
            )}
            {sessions.map((session: SessionTypes.Struct) => (
              <ConnectedSession
                key={session.topic}
                session={session}
                disconnectSession={disconnectSession}
                color={theme.colors.secondary}
              ></ConnectedSession>
            ))}
          </Flex>
        )}
      </CyanWrapper>
    </Flex>
  );
};
const CyanWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray10};
`;

const ConnectedButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 20px;
  padding: 0.1rem 0.6rem;
  width: fit-content;
`;

const ConnectButton = styled(Button)`
  width: 80px;
  height: 28px;
  border-radius: 6px;
`;

const ClearButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.gray20};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-radius: 6px;
  width: 80px;
  height: 35px;
`;

const StyledInput = styled(Input)``;
const CyanAddressWrapper = styled(Flex)`
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.mobile]: "column" }])}
  ${getStyleWithMediaQuery("align-items", "", [{ [breakpoints.mobile]: "flex-start" }])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.mobile]: 0.5 }])}
`;

const Approve = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.green};
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.green};
  padding: 0.1rem 0.6rem;
  cursor: pointer;
`;

const Reject = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.red};
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.transparent};
  padding: 0.1rem 0.6rem;
  cursor: pointer;
`;

const iconMap = new Map<string, string>([
  ["https://opensea.io", Opensea],
  ["https://looksrare.org", Looksrare],
  ["https://marketplace.pudgypenguins.com", Pudgy],
  ["https://x2y2.io", X2Y2],
  ["https://sudoswap.xyz", SudoSwap],
  ["https://reservoir.tools", Reservoir],
  ["https://www.apecoinmarketplace.com", ApeCoin],
  ["https://nftx.io", NFTX],
  ["https://blur.io", Blur],
  ["https://delegate.cash/", Delegatecash],
]);

const ConnectedSession = ({
  session,
  disconnectSession,
  color,
}: {
  color: string;
  session: SessionTypes.Struct;
  disconnectSession: (topic: string) => Promise<any>;
}) => {
  const [loadError, setLoadError] = useState(false);
  let defaultUrl = iconMap.get(session.peer.metadata.url.toLowerCase());
  if (!defaultUrl) defaultUrl = NoImage;

  return (
    <Flex justifyContent="space-between" key={session.topic} alignItems="center">
      <Flex alignItems="center" gap="0.5rem">
        {!loadError ? (
          <img
            src={session.peer.metadata.icons[0]}
            height={24}
            width={24}
            style={{
              borderRadius: "50%",
            }}
            onError={() => setLoadError(true)}
          />
        ) : (
          <img
            src={defaultUrl}
            height={24}
            width={24}
            style={{
              borderRadius: "50%",
            }}
          />
        )}
        <Text size="xs" color="secondary">
          {session.peer.metadata.name}
        </Text>
      </Flex>
      <div style={{ cursor: "pointer", height: "min-content" }}>
        <Button variant="ghost" onClick={() => disconnectSession(session.topic)}>
          <ShutDown color={color} height={15} width={15} />
        </Button>
      </div>
    </Flex>
  );
};
