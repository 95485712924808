import * as React from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "../../Flex";
import { ChevronLeft, ChevronRight } from "../icons";
import { Button } from "./Button";

const SCarouselWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;
const SCarouselSlide = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const SCarouselSlides = styled.div`
  white-space: nowrap;
  transition: transform 0.3s;
`;
const CarouselItem: React.FC<{
  width: string;
}> = ({ children, width }) => {
  return <SCarouselSlide style={{ width: width }}>{children}</SCarouselSlide>;
};

export const Carousel: React.FC<{ autoScroll?: boolean }> = ({ children, autoScroll }) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };
  React.useEffect(() => {
    if (!autoScroll) return;
    const interval = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [activeIndex, autoScroll]);
  if (!children || React.Children.count(children) === 0) return null;
  return (
    <Flex alignItems="center" gap="15px">
      <div>
        <Button
          variant="ghost"
          title="arrow left"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          <ChevronLeft color={theme.colors.secondary} width={30} height={30} />
        </Button>
      </div>
      <SCarouselWrapper>
        <SCarouselSlides style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          {React.Children.map(children, (child, index) => {
            return (
              <CarouselItem width="100%" key={index}>
                {React.cloneElement(child)}
              </CarouselItem>
            );
          })}
        </SCarouselSlides>
      </SCarouselWrapper>
      <div>
        <Button
          variant="ghost"
          title="arrow right"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          <ChevronRight width={30} height={30} color={theme.colors.secondary} />
        </Button>
      </div>
    </Flex>
  );
};
