import { formatJsonRpcError, formatJsonRpcResult } from "@json-rpc-tools/utils";
import * as Sentry from "@sentry/react";
import { AuthEngineTypes } from "@walletconnect/auth-client";
import { Core } from "@walletconnect/core";
import { PendingRequestTypes, ProposalTypes, SessionTypes, SignClientTypes } from "@walletconnect/types";
import { buildApprovedNamespaces, getSdkError } from "@walletconnect/utils";
import { IWeb3Wallet, Web3Wallet, Web3WalletTypes } from "@walletconnect/web3wallet";
import { BigNumber, constants as ethConsts, ethers, providers } from "ethers";

import { factories as f } from "@cyanco/contract";

import { EIP155_SIGNING_METHODS } from "./constants";
import { NoWalletError } from "./errors";
import { getSignParamsMessage, getSignTypedDataParamsData } from "./utils";

type IJsonRpcSigner = providers.JsonRpcSigner;

const CYAN_FACTORY_ADDRESS = process.env.REACT_APP_CYAN_FACTORY_ADDRESS;
const CYAN_TEST_DRIVE_ADDRESS = process.env.REACT_APP_CYAN_TEST_DRIVE_ADDRESS;

const factoryABI = [
  "function getOwnerWallet(address) view returns (address)",
  "function getOrDeployWallet(address) external returns (address)",
];
const fallbackABI = [
  "function isLockedERC721(address collection, uint256 tokenId) external view returns (bool)",
  "function isLockedByCyanPlanERC721(address collection, uint256 tokenId) external view returns (bool)",
  "function isLockedByApePlan(address collection, uint256 tokenId) external view returns (bool)",
  "function getLockedERC1155Amount(address collection, uint256 tokenId) external view returns (uint256)",
  "function getApeLockState(address collection, uint256 tokenId) external view returns (uint8)",
];

const METADATA = {
  name: "Cyan Wallet",
  description: "Cyan Wallet",
  url: "https://usecyan.com",
  icons: ["https://www.usecyan.com/favicon-32x32.png"],
};

type ISessionRequestArgs = SignClientTypes.EventArguments["session_request"];

export class CyanWallet {
  private readonly _ownerAddress: string;
  private readonly _walletAddress: string;
  private readonly _signer: providers.JsonRpcSigner;
  private readonly _contract: ReturnType<typeof f.CoreFactory.connect>;

  private _walletConnect: IWeb3Wallet;

  private constructor(signer: IJsonRpcSigner, ownerAddress: string, walletAddress: string, walletConnect: IWeb3Wallet) {
    this._ownerAddress = ownerAddress;
    this._walletAddress = walletAddress;

    this._signer = signer;
    this._contract = f.CoreFactory.connect(this._walletAddress, this._signer);
    this._walletConnect = walletConnect;

    const topics = Object.keys(walletConnect.getActiveSessions());
    topics.forEach(async topic => {
      try {
        const chainId = await signer.getChainId();
        await walletConnect.emitSessionEvent({
          topic,
          event: {
            name: "chainChanged",
            data: [chainId],
          },
          chainId: `eip155:${chainId}`,
        });
      } catch (e: any) {
        console.log(e);
      }
    });
  }

  private static async initializeWalletConnect(): Promise<IWeb3Wallet> {
    const projectId = process.env.REACT_APP_WC_PROJECT_ID;
    if (!projectId) throw new Error('Environment "REACT_APP_WC_PROJECT_ID" is required.');

    const core = new Core({ projectId });
    return await Web3Wallet.init({ core, metadata: METADATA });
  }

  public static async initialize(signer: IJsonRpcSigner, isTestDrive?: boolean) {
    if (!CYAN_FACTORY_ADDRESS) throw new Error('Environment "CYAN_FACTORY_ADDRESS" is required');

    let ownerAddress;
    try {
      ownerAddress = await signer.getAddress();
    } catch (error) {
      console.log("Cannot get signer address.");
      return;
    }

    const cyanFactory = new ethers.Contract(CYAN_FACTORY_ADDRESS, factoryABI, signer);

    let walletAddress: string = ethConsts.AddressZero;
    try {
      walletAddress = isTestDrive ? CYAN_TEST_DRIVE_ADDRESS : await cyanFactory.getOwnerWallet(ownerAddress);
    } catch (e) {
      console.log("Could not fetch user's CyanWallet");
    }

    if (walletAddress === ethConsts.AddressZero) {
      throw new NoWalletError();
    }

    const walletConnect = await CyanWallet.initializeWalletConnect();
    return new CyanWallet(signer, ownerAddress, walletAddress, walletConnect);
  }

  public static async createNewWallet(signer: IJsonRpcSigner) {
    if (!CYAN_FACTORY_ADDRESS) throw new Error('Environment "CYAN_FACTORY_ADDRESS" is required');

    const ownerAddress = await signer.getAddress();
    const cyanFactory = new ethers.Contract(CYAN_FACTORY_ADDRESS, factoryABI, signer);

    const tx = await cyanFactory.getOrDeployWallet(ownerAddress);
    await tx.wait();
  }

  public get interface(): ethers.utils.Interface {
    return this._contract.interface;
  }

  public get ownerAddress(): string {
    return this._ownerAddress;
  }

  public get walletAddress(): string {
    return this._walletAddress;
  }

  public async getBalance(): Promise<BigNumber> {
    return this._signer.provider?.getBalance(this._walletAddress) ?? ethConsts.Zero;
  }

  public async isLockedERC721(collection: string, tokenId: string): Promise<boolean> {
    const walletAsFallback = new ethers.Contract(this.walletAddress, fallbackABI, this._signer);
    return await walletAsFallback.isLockedERC721(collection, tokenId);
  }

  public async isLockedByCyanPlanERC721(collection: string, tokenId: string): Promise<boolean> {
    const walletAsFallback = new ethers.Contract(this.walletAddress, fallbackABI, this._signer);
    return await walletAsFallback.isLockedByCyanPlanERC721(collection, tokenId);
  }

  public async isLockedByApePlan(collection: string, tokenId: string): Promise<boolean> {
    const walletAsFallback = new ethers.Contract(this.walletAddress, fallbackABI, this._signer);
    return await walletAsFallback.isLockedByApePlan(collection, tokenId);
  }

  public async getLockedERC1155Amount(collection: string, tokenId: string): Promise<BigNumber> {
    const walletAsFallback = new ethers.Contract(this.walletAddress, fallbackABI, this._signer);
    return await walletAsFallback.getLockedERC1155Amount(collection, tokenId);
  }

  public async getApeLockState(collection: string, tokenId: string): Promise<BigNumber> {
    const walletAsFallback = new ethers.Contract(this.walletAddress, fallbackABI, this._signer);
    return await walletAsFallback.getApeLockState(collection, tokenId);
  }

  public get sessions(): Record<string, SessionTypes.Struct> {
    return this._walletConnect.getActiveSessions();
  }

  public get authRequests(): Record<number, AuthEngineTypes.PendingRequest> {
    return this._walletConnect.getPendingAuthRequests();
  }

  public get sessionProposals(): Record<number, ProposalTypes.Struct> {
    return this._walletConnect.getPendingSessionProposals();
  }

  public get sessionRequests(): PendingRequestTypes.Struct[] {
    return this._walletConnect.getPendingSessionRequests();
  }

  public get walletConnect(): IWeb3Wallet {
    return this._walletConnect;
  }

  public async execute(to: string, value: BigNumber, data: string) {
    return await this._contract.execute(to, value ?? 0, data);
  }

  public async executeBatch(data: { to: string; value: BigNumber; data: string }[]) {
    return await this._contract.executeBatch(data);
  }

  public async approveAuthRequest(chainId: number, request: AuthEngineTypes.PendingRequest): Promise<void> {
    const iss = `did:pkh:eip155:${chainId}:${this.walletAddress}`;
    const message = this._walletConnect.formatMessage(request.cacaoPayload, iss);
    const signature = await this._signer.signMessage(message);

    await this._walletConnect.respondAuthRequest(
      {
        id: request.id,
        signature: {
          s: signature,
          t: "eip1271",
        },
      },
      iss,
    );
  }

  public async rejectAuthRequest(request: AuthEngineTypes.PendingRequest): Promise<void> {
    const iss = `did:pkh:eip155:1:${this.ownerAddress}`;
    await this._walletConnect.respondAuthRequest(
      {
        id: request.id,
        error: getSdkError("USER_REJECTED"),
      },
      iss,
    );
  }

  public async approveSessionProposal(proposal: ProposalTypes.Struct): Promise<SessionTypes.Struct> {
    // added eip155:89857165 temporary for otherside townhall
    const chains = [
      "eip155:1",
      "eip155:5",
      "eip155:137",
      "eip155:11155111",
      "eip155:89857165",
      "eip155:81457",
      "eip155:168587773",
    ];
    const approvedNamespaces = buildApprovedNamespaces({
      proposal,
      supportedNamespaces: {
        eip155: {
          chains,
          methods: Object.values(EIP155_SIGNING_METHODS),
          events: ["accountsChanged", "chainChanged"],
          accounts: chains.map(prefix => `${prefix}:${this.walletAddress}`),
        },
      },
    });

    return await this._walletConnect.approveSession({ id: proposal.id, namespaces: approvedNamespaces });
  }

  public async rejectSessionProposal(id: number): Promise<void> {
    await this._walletConnect.rejectSession({ id, reason: getSdkError("USER_REJECTED_METHODS") });
  }

  public async disconnectSession(topic: string): Promise<void> {
    try {
      await this.walletConnect.disconnectSession({ topic, reason: getSdkError("USER_DISCONNECTED") });
    } catch (e: any) {
      const sessions = JSON.parse(localStorage.getItem("wc@2:client:0.3//session") || "[]");
      const updatedSessions = sessions.filter(({ topic: _topic }: { topic: string }) => topic !== _topic);
      localStorage.setItem("wc@2:client:0.3//session", JSON.stringify(updatedSessions));
    }
  }

  public async pair(uri: string): Promise<void> {
    await this.walletConnect.core.pairing.pair({ uri });
  }

  public async approveSignRequest(requestEvent: ISessionRequestArgs): Promise<void> {
    const message = getSignParamsMessage(requestEvent.params.request.params);
    Sentry.captureEvent({
      message: "approveSignRequest",
      extra: { message },
      tags: { event: "cyan-wallet-connect" },
    });
    const signedMessage = await this._signer.signMessage(message);
    await this.walletConnect.respondSessionRequest({
      topic: requestEvent.topic,
      response: formatJsonRpcResult(requestEvent.id, signedMessage),
    });
  }

  public async approveSignTypedDataRequest(requestEvent: ISessionRequestArgs): Promise<void> {
    const { domain, types, message: data } = getSignTypedDataParamsData(requestEvent.params.request.params);
    Sentry.captureEvent({
      message: "approveSignTypedDataRequest",
      extra: { domain, types, data },
      tags: { event: "cyan-wallet-connect" },
    });
    delete types.EIP712Domain;
    const signedData = await this._signer._signTypedData(domain, types, data);

    await this.walletConnect.respondSessionRequest({
      topic: requestEvent.topic,
      response: formatJsonRpcResult(requestEvent.id, signedData),
    });
  }

  public async sendTransaction(requestEvent: ISessionRequestArgs): Promise<void> {
    const transaction = requestEvent.params.request.params[0];
    Sentry.captureEvent({
      message: "sendTransaction",
      extra: { transaction },
      tags: { event: "cyan-wallet-connect" },
    });
    try {
      const response = await this.execute(transaction.to, transaction.value, transaction.data);

      await this.walletConnect.respondSessionRequest({
        topic: requestEvent.topic,
        response: formatJsonRpcResult(requestEvent.id, response.hash),
      });
    } catch (error) {
      Sentry.captureException(error, { extra: { transaction } });
    }
  }

  public async signTransaction(requestEvent: ISessionRequestArgs): Promise<string> {
    const transaction = requestEvent.params.request.params[0];
    const signature = await this._signer.signTransaction(transaction);

    await this.walletConnect.respondSessionRequest({
      topic: requestEvent.topic,
      response: formatJsonRpcResult(requestEvent.id, signature),
    });
    return signature;
  }

  public async rejectSessionRequest(requestEvent: ISessionRequestArgs): Promise<void> {
    await this.walletConnect.respondSessionRequest({
      topic: requestEvent.topic,
      response: formatJsonRpcError(requestEvent.id, getSdkError("USER_REJECTED_METHODS")),
    });
  }

  public addEventHandler<E extends Web3WalletTypes.Event>(
    event: E,
    listener: (args: Web3WalletTypes.EventArguments[E]) => void,
  ) {
    this._walletConnect.on(event, listener);
  }

  public removeEventHandler<E extends Web3WalletTypes.Event>(
    event: E,
    listener: (args: Web3WalletTypes.EventArguments[E]) => void,
  ) {
    this._walletConnect.off(event, listener);
  }
}
