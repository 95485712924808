import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useContext, useMemo, useState } from "react";
import { jsNumberForAddress } from "react-jazzicon";
import Jazzicon from "react-jazzicon/dist/Jazzicon";
import styled, { useTheme } from "styled-components";

import StyledCheckbox from "@cyanco/components/theme/Checkbox";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import {
  AssetTag,
  Button,
  Hidden,
  Loader,
  NftImageTooltip,
  SkeletonLine,
  SortingArrows,
  Text,
} from "@cyanco/components/theme/v3";
import { Send } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IPeerPlan } from "@/apis/p2p/types";
import { isUserNft } from "@/apis/user";
import { IUserNft } from "@/apis/user/types";
import BendDaoLogoSrc from "@/assets/images/benddao.svg";
import { ApeCoinStatus } from "@/components/ApeCoinStaking/ApeCoinStatusOnRow";
import { useAppContext } from "@/components/AppContextProvider";
import { IBNPL, isBnplPlan } from "@/components/Bnpl/bnpl.types";
import { useUserAssets } from "@/components/Loans/LoanPageProvider";
import { APE_COIN_STAKABLE_ADDRESSES } from "@/config";
import { useSortableHeader } from "@/hooks/useSortableHeader";
import { ICurrency } from "@/types";
import {
  bigNumToFixedStr,
  displayInUSD,
  getRarityRank,
  isApeCoinStakingPossible,
  numberWithCommas,
  shortenAddress,
  shortenName,
} from "@/utils";

import { INFtRarity } from "../../../NftStatus";
import { AccountDataContext } from "../../AccountDataContext";
import { useWalletTabContext } from "../../AccountPageContext";
import { IPawn, isPawnPlan } from "../../pawn.types";
import { HeaderItemWrapper } from "../PositionView/PositionRow";
import { SelectAllItemsCheckBox } from "../SelectItems/SelectAllCheckbox";
import { ISelectedNft } from "./NftCard";
import { Refresh } from "./Refresh";
import { WalletSelector } from "./Selectors";

export const UserNftRowLoading = () => {
  return (
    <Row>
      <Flex alignItems="center" gap="8px">
        <ImageLoader>
          <SkeletonLine w="100%" h="100%" borderRadius="10px" />
        </ImageLoader>
        <RowLoadingText />
      </Flex>
      <Flex direction="column" gap="2px">
        <RowLoadingText />
      </Flex>

      <Hidden tabletDown>
        <Flex direction="column" gap="2px">
          <RowLoadingText />
          <RowLoadingText sub />
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex direction="column" gap="2px">
          <RowLoadingText />
          <RowLoadingText sub />
        </Flex>
      </Hidden>
      <RowLoadingText />
      <Hidden tabletDown>
        <RowLoadingText />
      </Hidden>
    </Row>
  );
};

export const UserNftsListHeader = ({
  totalItem,
  sortedAssets,
  updateSortedAssets,
}: {
  totalItem: number;
  sortedAssets: (IUserNft | IPawn | IBNPL | IPeerPlan)[];
  updateSortedAssets: (values: (IUserNft | IPawn | IBNPL | IPeerPlan)[]) => void;
}) => {
  const { selectedWalletType, setSelectedWalletType } = useWalletTabContext();
  const { fetchBNPLPositions, fetchCyanAssets, fetchPawnPositions, fetchUserAssets, fetchPeerPlans } =
    useContext(AccountDataContext);
  const refresh = async () => {
    await Promise.all([
      fetchBNPLPositions(),
      fetchCyanAssets(),
      fetchPeerPlans(),
      fetchPawnPositions(),
      fetchUserAssets(),
    ]);
  };
  const { cyanAssets } = useContext(AccountDataContext);
  const { collections } = useAppContext();
  const getAppraisal = (c: IUserNft | IPawn | IBNPL | IPeerPlan) => {
    if (isPawnPlan(c)) {
      return c.appraisalValue;
    } else if (isBnplPlan(c)) {
      const asset = (cyanAssets?.assets ?? []).find(
        asset => c.tokenId === asset.tokenId && c.metadata.collectionAddress === asset.address,
      );
      return asset?.appraisalValue;
    } else if (isUserNft(c)) {
      return c.appraisalValue;
    } else {
      const asset = (cyanAssets?.assets ?? []).find(
        asset => c.tokenId === asset.tokenId && c.collectionAddress === asset.address,
      );
      return asset?.appraisalValue;
    }
  };
  const { sort, sortDirectionDesc, sortName } = useSortableHeader<IUserNft | IPawn | IBNPL | IPeerPlan>(
    [c => c.tokenId],
    "",
  );

  const handleHeaderClick = (value: ((item: IUserNft | IPawn | IBNPL | IPeerPlan) => any)[], sortName: string) => {
    const sortedData = sort(value, sortedAssets, sortName);
    updateSortedAssets(sortedData);
  };
  return (
    <HeaderContainer>
      <HeaderItemWrapper gap="1rem">
        <SelectAllItemsCheckBox />
        <RowText weight="600" color="secondary">{`${totalItem} item${totalItem > 1 ? "s" : ""}`}</RowText>
        <Hidden tabletDown>
          <Refresh onClick={refresh} />
        </Hidden>
      </HeaderItemWrapper>
      <Hidden tabletDown>
        <WalletSelector selectedWalletType={selectedWalletType} setSelectedWalletType={setSelectedWalletType} />
      </Hidden>
      <Flex gap="5px" onClick={() => handleHeaderClick([c => getAppraisal(c)], "appraisal")}>
        <RowText weight="600" color="secondary">{`Appraisal`}</RowText>
        <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="appraisal" />
      </Flex>
      <Hidden tabletDown>
        <Flex
          gap="5px"
          onClick={() =>
            handleHeaderClick(
              [
                c => {
                  if (isPawnPlan(c)) {
                    return getRarityRank(c.metadata.rarityRank, c.metadata.collectionAddress, collections)?.rank;
                  } else if (isBnplPlan(c)) {
                    return getRarityRank(c.metadata.rarityRank, c.metadata.collectionAddress, collections)?.rank;
                  } else if (isUserNft(c)) {
                    return c.rarityRank;
                  } else {
                    return getRarityRank(c.metadata ? c.metadata.rarityRank : null, c.collectionAddress, collections)
                      ?.rank;
                  }
                },
              ],
              "rarity",
            )
          }
        >
          <RowText weight="600" color="secondary">{`Rarity`}</RowText>
          <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="rarity" />
        </Flex>
      </Hidden>
      <RowText weight="600" color="secondary">{`Action`}</RowText>
      <Hidden tabletDown>
        <RowText weight="600" color="secondary">{`Transfer`}</RowText>
      </Hidden>
    </HeaderContainer>
  );
};
export const UserNftRow = ({
  nft,
  account,
  chainId,
  onClick,
  onClickTransfer,
  onClickPawn,
  toggleItem,
  loading,
  isSelected,
  hasMultiSelectionBox,
}: {
  nft: IUserNft & { priceInUSD: number | null; rarity: INFtRarity | null };
  account: string;
  chainId: number;
  onClick: (a: ISelectedNft) => void;
  onClickTransfer: (a: ISelectedNft) => void;
  onClickPawn: (a: ISelectedNft) => void;
  toggleItem: () => void;
  loading: boolean;
  isSelected: boolean;
  hasMultiSelectionBox: boolean;
}) => {
  const getPrice = useMemo(() => {
    return !nft.appraisalValue ? "-" : bigNumToFixedStr(nft.appraisalValue, 2, nft.currency.decimal);
  }, [nft.appraisalValue]);
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(true);
  const enableApeCoinStaking = useMemo(() => {
    return isApeCoinStakingPossible(chainId) && APE_COIN_STAKABLE_ADDRESSES.includes(nft.address);
  }, [chainId, nft.address]);
  const { isDemoAsset } = useUserAssets();
  const isDemo = useMemo(() => {
    return isDemoAsset(nft.tokenId, nft.address);
  }, [nft]);
  const ApeCoinStatusMemo = useMemo(() => {
    return enableApeCoinStaking ? (
      <ApeCoinStatus
        nft={{
          address: nft.address,
          tokenId: nft.tokenId,
        }}
        isCard={false}
      />
    ) : null;
  }, [enableApeCoinStaking, nft.address, nft.tokenId]);

  const isListedAsPrivateSale = useMemo(() => {
    return nft.privateSale && dayjs().isBefore(dayjs(nft.privateSale.expiryDate));
  }, [nft.privateSale]);

  return (
    <Row
      onClick={() => !loading && onClick(nft)}
      selected={isSelected}
      key={`${nft.collectionName}:${nft.tokenId}:${nft.isCyanWallet}`}
    >
      <Flex alignItems="center" gap="1rem">
        <div>
          {/* // wrapped empty div for safari */}
          <RowCartCheckboxWrapper
            onClick={e => {
              e.stopPropagation();
            }}
            alignItems="center"
          >
            <StyledCheckbox
              checked={isSelected}
              onChange={toggleItem}
              disabled={nft.isBendDao}
              id={`${nft.tokenId}-${nft.isCyanWallet ? "cyan" : "main"}-nft`}
            />
          </RowCartCheckboxWrapper>
        </div>
        <Flex alignItems="center" gap="20px">
          {imageLoading && (
            <ImageLoader>
              <SkeletonLine w="100%" h="100%" borderRadius="10px" />
            </ImageLoader>
          )}
          <NftImageTooltip
            hasMultiSelectionBox={hasMultiSelectionBox}
            data1={{
              label: "Appraisal",
              value: `${nft.appraisalValue ? bigNumToFixedStr(nft.appraisalValue, 2, nft.currency.decimal) : "-"} ${
                nft.currency.symbol
              }`,
            }}
            data2={{
              label: "Wallet",
              value: nft.isCyanWallet ? "Cyan" : "Main",
            }}
            imageUrl={nft.imageUrl}
          >
            <StyledImg
              src={nft.imageUrl || NoImage}
              alt={nft.collectionName}
              onLoad={() => setImageLoading(false)}
              style={{
                display: imageLoading ? "none" : "block",
              }}
              hasImage={!!nft.imageUrl}
            />
          </NftImageTooltip>

          <Flex direction="column" gap="2px">
            <Flex gap="5px" alignItems="center">
              <RowText color={"secondary"} weight="500">
                #{shortenName(nft.tokenId, 10, 6)}
              </RowText>
              <Flex
                style={{
                  alignSelf: "flex-end",
                }}
              >
                {enableApeCoinStaking && ApeCoinStatusMemo}
              </Flex>
              {isListedAsPrivateSale && <AssetTag>{`Private Sale`}</AssetTag>}
              {nft.isBendDao && <AssetTag>{`BendDAO`}</AssetTag>}
            </Flex>
            <RowText color={"gray0"} sub>
              {shortenName(nft.collectionName)}
            </RowText>
          </Flex>
        </Flex>
      </Flex>
      <Hidden tabletDown>
        {isDemo ? (
          <WalletContainer>
            <RowText color="cyan" weight="500">
              Demo wallet
            </RowText>
          </WalletContainer>
        ) : nft.isBendDao ? (
          <WalletContainer>
            <BendDAOLogo src={BendDaoLogoSrc} alt="bend-dao-logo" />
            <RowText color="secondary" weight="500">
              BendDAO
            </RowText>
          </WalletContainer>
        ) : nft.isCyanWallet ? (
          <WalletContainer>
            <div
              style={{
                background: theme.colors.cyan,
                width: "18px",
                height: "18px",
                minWidth: "18px",
                minHeight: "18px",
                borderRadius: "50%",
              }}
            />
            <RowText color="secondary" weight="500">
              Cyan Wallet
            </RowText>
          </WalletContainer>
        ) : (
          <WalletContainer>
            <Jazzicon seed={jsNumberForAddress(account)} diameter={18} />
            <RowText color="secondary" weight="500">
              {shortenAddress(account)}
            </RowText>
          </WalletContainer>
        )}
      </Hidden>
      <Flex direction="column" gap="2px">
        <RowText color={"secondary"} weight="500">
          {getPrice} {nft.currency.symbol}
        </RowText>
        <RowText sub color="gray0">
          {nft.priceInUSD ? displayInUSD(nft.priceInUSD) : "-"}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" gap="2px">
          <RowText color={"secondary"} weight="500">
            {nft.rarity ? numberWithCommas(nft.rarity.rank) : "-"}
          </RowText>
          <RowText color="gray0" sub>
            {nft.rarity ? nft.rarity.relativeRank : "-"}
          </RowText>
        </Flex>
      </Hidden>
      <Flex gap="0.8rem">
        {!nft.appraisalValue ? (
          <RowText color="gray0">{nft.rarity ? nft.rarity.relativeRank : "-"}</RowText>
        ) : loading ? (
          <Flex w="fit-content" alignItems="center" gap="5px">
            <SmallButtonText textWrap={false} weight={"700"} color="cyan">{`Processing`}</SmallButtonText>
            <Loader size="12px" stroke={theme.colors.secondary} />
          </Flex>
        ) : (
          <Flex w="fit-content">
            {nft.isBendDao ? (
              <SmallButton
                onClick={e => {
                  e.stopPropagation();
                  nft.appraisalValue ? onClickPawn(nft) : () => {};
                }}
                style={{ background: "#0085FF", borderColor: "#0085FF" }}
              >
                <BorrowBox>
                  <SmallButtonText textWrap={false} weight={"700"} color="black">
                    {`Migrate`}
                  </SmallButtonText>
                </BorrowBox>
              </SmallButton>
            ) : (
              <SmallButton
                onClick={e => {
                  e.stopPropagation();
                  nft.appraisalValue ? onClickPawn(nft) : () => {};
                }}
              >
                <BorrowBox>
                  <SmallButtonText textWrap={false} weight={"700"} color="black">
                    {`Borrow`}
                  </SmallButtonText>
                </BorrowBox>
              </SmallButton>
            )}
          </Flex>
        )}
      </Flex>
      <Hidden tabletDown>
        <Flex gap="0.8rem">
          <TransferButton
            variant="ghost"
            style={{
              width: "fit-content",
            }}
            disabled={nft.isBendDao}
            onClick={e => {
              e.stopPropagation();
              onClickTransfer(nft);
            }}
          >
            <Send color={theme.colors.secondary} height={18} width={18} />
          </TransferButton>
        </Flex>
      </Hidden>
    </Row>
  );
};

export const UserPositionRow = ({
  nft,
  planType,
  chainId,
  onClick,
  onPay,
  toggleItem,
  loading,
  isSelected,
  hasMultiSelectionBox,
}: {
  nft: {
    tokenId: string;
    collectionName: string;
    imageUrl: string | null;
    currency: ICurrency;
    collectionAddress: string;
    priceInUSD: number | null;
    rarity: INFtRarity | null;
    price: BigNumber;
    isCyanWallet?: boolean;
    appraisal: {
      value?: string;
      usdValue?: number;
      currency: ICurrency;
    };
    isAutoLiquidationEnabled: boolean;
  };
  planType: "BNPL" | "Pawn" | "P2P";
  account: string;
  chainId: number;
  onClick: () => void;
  onPay: () => void;
  toggleItem: () => void;
  loading: boolean;
  isSelected: boolean;
  hasMultiSelectionBox: boolean;
}) => {
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(true);
  const getPrice = useMemo(() => {
    return !nft.appraisal.value ? "-" : bigNumToFixedStr(nft.appraisal.value, 2, nft.appraisal.currency.decimal);
  }, [nft.price]);
  const enableApeCoinStaking = useMemo(() => {
    return isApeCoinStakingPossible(chainId) && APE_COIN_STAKABLE_ADDRESSES.includes(nft.collectionAddress);
  }, [chainId, nft.collectionAddress]);
  const ApeCoinStatusMemo = useMemo(() => {
    return enableApeCoinStaking ? (
      <ApeCoinStatus
        nft={{
          address: nft.collectionAddress,
          tokenId: nft.tokenId,
        }}
        isCard={false}
      />
    ) : null;
  }, [enableApeCoinStaking, nft.collectionAddress, nft.tokenId]);
  return (
    <Row onClick={() => !loading && onClick()} selected={isSelected}>
      <Flex alignItems="center" gap="1rem">
        <div>
          {/* // wrapped empty div for safari */}
          <RowCartCheckboxWrapper
            onClick={e => {
              e.stopPropagation();
            }}
            alignItems="center"
          >
            <StyledCheckbox
              checked={isSelected}
              onChange={toggleItem}
              id={`${nft.tokenId}-${nft.isCyanWallet ? "cyan" : "main"}-${planType}`}
            />
          </RowCartCheckboxWrapper>
        </div>
        <Flex alignItems="center" gap="20px">
          {imageLoading && (
            <ImageLoader>
              <SkeletonLine w="100%" h="100%" borderRadius="10px" />
            </ImageLoader>
          )}
          <NftImageTooltip
            hasMultiSelectionBox={hasMultiSelectionBox}
            data1={{
              label: "Appraisal",
              value: getPrice,
            }}
            data2={{
              label: "Plan type",
              value: planType,
            }}
            imageUrl={nft.imageUrl}
          >
            <StyledImg
              src={nft.imageUrl || NoImage}
              alt={nft.collectionName}
              onLoad={() => setImageLoading(false)}
              style={{
                display: imageLoading ? "none" : "block",
              }}
              hasImage={!!nft.imageUrl}
            />
          </NftImageTooltip>
          <Flex direction="column" gap="2px">
            <Flex gap="5px">
              <RowText color={"secondary"} weight="500">
                #{shortenName(nft.tokenId, 10, 6)}
              </RowText>
              <Hidden laptopDown>
                <Flex gap="4px">
                  <Flex gap="4px" alignItems="flex-end">
                    <Flex
                      style={{
                        background: theme.colors.secondary,
                        borderRadius: "4px",
                      }}
                      p={"2px 4px"}
                      h={"fit-content"}
                      w="fit-content"
                    >
                      <Text color="primary" size="xxs" weight="700">
                        {planType === "P2P" ? "P2P" : planType === "BNPL" ? "BNPL" : "Loan"}
                      </Text>
                    </Flex>
                    {enableApeCoinStaking && ApeCoinStatusMemo}
                  </Flex>
                  {nft.isAutoLiquidationEnabled && (
                    <Flex gap="4px" alignItems="flex-end">
                      <Flex
                        style={{
                          background: theme.colors.secondary,
                          borderRadius: "4px",
                        }}
                        p={"2px 4px"}
                        h={"fit-content"}
                        w="fit-content"
                      >
                        <Text color="primary" size="xxs" weight="700">
                          {"Auto-Liquidation"}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              </Hidden>
            </Flex>
            <RowText color={"gray0"} sub>
              {shortenName(nft.collectionName)}
            </RowText>
          </Flex>
        </Flex>
      </Flex>

      <Hidden tabletDown>
        <WalletContainer>
          <div
            style={{
              background: theme.colors.cyan,
              width: "18px",
              height: "18px",
              minWidth: "18px",
              minHeight: "18px",
              borderRadius: "50%",
            }}
          />
          <RowText color="secondary" weight="500">
            Cyan Wallet
          </RowText>
        </WalletContainer>
      </Hidden>
      <Flex direction="column" gap="2px">
        <RowText color={"secondary"} weight="500">
          {getPrice} {nft.appraisal.currency.symbol}
        </RowText>
        <RowText sub color="gray0">
          {nft.appraisal.usdValue ? displayInUSD(nft.appraisal.usdValue) : "-"}
        </RowText>
      </Flex>
      <Hidden tabletDown>
        <Flex direction="column" gap="2px">
          <RowText color={"secondary"} weight="500">
            {nft.rarity ? numberWithCommas(nft.rarity.rank) : "-"}
          </RowText>
          <RowText sub color="gray0">
            {nft.rarity ? nft.rarity.relativeRank : "-"}
          </RowText>
        </Flex>
      </Hidden>
      {loading ? (
        <Flex w="fit-content" alignItems="center" gap="5px">
          <SmallButtonText textWrap={false} weight={"700"} color="cyan">{`Processing`}</SmallButtonText>
          <Loader size="12px" stroke={theme.colors.secondary} />
        </Flex>
      ) : (
        <Flex w="fit-content">
          <PayButton
            onClick={e => {
              e.stopPropagation();
              onPay();
            }}
          >
            <PayBox>
              <PayText textWrap={false} weight={"700"}>{`Pay`}</PayText>
            </PayBox>
          </PayButton>
        </Flex>
      )}
    </Row>
  );
};

const Row = styled.div<{ selected?: boolean }>`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.gray10 : "transparent")};
  grid-template-columns: 2.5fr 1.3fr 1.3fr 1.2fr 1fr 0.7fr;
  padding: 13px 15px 13px 15px;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "4fr 1.5fr 1.3fr" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const RowCartCheckboxWrapper = styled(Flex)`
  margin: -13px 0px;
  padding: 13px 0px;
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.mobile]: "-7px 0px" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 0px" }])};
  height: 100%;
`;

export const RowText = styled(Text)<{ sub?: boolean }>`
  font-size: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("font-size", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])}
`;

const SmallButtonText = styled(Text)`
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 12 }, { [breakpoints.tablet]: 10 }])};
`;

const HeaderContainer = styled(Row)`
  background: ${({ theme }) => theme.colors.primary};
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 7px 10px;
  }
`;

export const StyledImg = styled.img<{ hasImage?: boolean }>`
min-width: 36px;
min-height: 36px;
max-width: 36px;
max-height: 36px;
${getStyleWithMediaQuery("min-width", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("min-height", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("max-width", "px", [{ [breakpoints.mobile]: 24 }])}
${getStyleWithMediaQuery("max-height", "px", [{ [breakpoints.mobile]: 24 }])}
border-radius: ${({ theme }) => theme.borderRadius};
filter: ${({ hasImage, theme }) =>
  !hasImage &&
  theme.theme === "light" &&
  "invert(72%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(88%) contrast(81%)"};

} `;

const RowLoadingText = styled(SkeletonLine)<{ sub?: boolean }>`
  width: 50%;
  height: ${({ sub }) => (sub ? "14px" : "16px")};
  ${({ sub }) =>
    getStyleWithMediaQuery("height", "px", [
      { [breakpoints.desktop]: sub ? 12 : 14 },
      { [breakpoints.laptop]: sub ? 10 : 12 },
      { [breakpoints.mobile]: sub ? 8 : 10 },
    ])};
`;

const SmallButton = styled(Button)`
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: ${({ disabled }) => !disabled && "#79FFFF"};
    border-color: ${({ disabled }) => !disabled && "#79FFFF"};
  }
`;
const PayText = styled(SmallButtonText)`
  color: ${({ theme }) => theme.colors.black};
  transition: 0.2s ease-in-out;
`;
const PayButton = styled(Button)`
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  :hover {
    background-color: ${({ theme }) => theme.colors.cyan};
  }
  &:hover ${PayText} {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const ImageLoader = styled.div`
  width: 36px;
  height: 36px;
  ${getStyleWithMediaQuery("width", "px", [{ [breakpoints.mobile]: 24 }])}
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.mobile]: 24 }])}
`;

const TransferButton = styled(Button)`
  width: fit-content;
  :hover {
    svg,
    path {
      transition: 0.2s ease-in-out;
      stroke: ${({ theme }) => theme.colors.gray0};
    }
  }
`;

const BorrowBox = styled(Flex)`
  padding: 0.2rem;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0.1rem" }])}
`;
const PayBox = styled(Flex)`
  padding: 0.2rem 0.5rem;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0.15rem 0.7rem" }])}
`;

export const WalletContainer = styled(Flex)`
  background: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray20 : "#EEEEEE")};
  border-radius: 100px;
  padding: 6px 12px 6px 8px;
  gap: 5px;
  width: fit-content;
`;

const BendDAOLogo = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;
