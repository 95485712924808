import styled, { useTheme } from "styled-components";

import { Flex, NftMetadataInline, NftMetadataInlineImage, Text } from "@cyanco/components/theme";
import { NewTab } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IPeerPlan } from "@/apis/p2p/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { bigNumToFloat, getChainExplorerURL, jumpToLink, numberWithCommas, shortenAddress, shortenName } from "@/utils";

export const NftMetadata = ({ plan }: { plan: IPeerPlan }) => {
  const theme = useTheme();
  const { chainId } = useWeb3React();
  return (
    <Flex gap="10px" pt="5px">
      <NftMetadataInlineImage imageUrl={plan.metadata ? plan.metadata.imageUrl : NoImage} />
      <Flex justifyContent="space-between" direction="column" w="100%" p="5px 0 15px 0">
        <NftMetadataInline
          name={shortenName(plan.collection.name, 21, 20, 0)}
          value={`#${shortenName(plan.tokenId, 10, 5)}`}
        />
        <Flex gap="5px" direction="column" w="100%">
          <NftMetadataInline
            name={`Loaned Amount`}
            value={`${numberWithCommas(bigNumToFloat(plan.loanBid.amount, plan.loanBid.currency.decimal), 2)} ${
              plan.loanBid.currency.symbol
            }`}
            sub
          />
          <Flex justifyContent="space-between">
            <Text color="gray0" size="sm" weight="500">
              {`Lender`}
            </Text>
            <OwnerAddress
              gap="0.3rem"
              alignItems="center"
              onClick={() => jumpToLink(`${getChainExplorerURL(chainId)}/address/${plan.lenderAddress}`)}
            >
              <Text size="sm" color="gray0">
                {`${shortenAddress(plan.lenderAddress)}`}
              </Text>
              <NewTab color={theme.colors.gray0} height={14} width={14} />
            </OwnerAddress>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const OwnerAddress = styled(Flex)`
  cursor: pointer;
  transition: 0.2s;
  :hover {
    opacity: 0.8;
  }
`;
