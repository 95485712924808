import { useWeb3React } from "@web3-react/core";
import { useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, Text, Title, TitleContainer, TitleDescription } from "@cyanco/components/theme/v3";

import { useToggleModal } from "@/state/application/hooks";
import { ApplicationModal } from "@/state/application/reducer";
import { formatCompactNumber } from "@/utils";

import { useAppContext } from "../AppContextProvider";
import { ThemeContext } from "../ThemeContextProvider";
import { LeaderBoard } from "./LeaderBoard";
import { LendView, useLendSelectors } from "./LendPageContext";
import { AvailableCollections } from "./collections";

export const P2PCollections = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { account } = useWeb3React();
  const toggleWallet = useToggleModal(ApplicationModal.WALLET);
  const navigate = useNavigate();
  const { isLightTheme } = useContext(ThemeContext);
  const { setSelectedView } = useLendSelectors();
  const { cyanStats } = useAppContext();

  const totalLoanedAmountFormatted = formatCompactNumber(
    cyanStats.loanedAmountBnplsUsd + cyanStats.loanedAmountPawnsUsd,
  );
  const formattedUsers = useMemo(() => {
    return formatCompactNumber(Math.round(cyanStats.userCount / 10) * 10);
  }, [cyanStats.userCount]);

  useEffect(() => {
    if (queryParams.get("redirected-from")?.includes("usecyan")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Container direction="column">
        <TopContainer w="100%">
          <Hidden
            tabletDown
            style={{
              height: "100%",
            }}
          >
            <TitleContainer justifyContent="space-between">
              <Title>{`Peer-to-Peer Loans`} </Title>
              <Flex pb="2rem">
                <div
                  style={{
                    flexGrow: 1,
                    width: 0,
                  }}
                >
                  <TitleDescription color="gray0">
                    Cyan is the Buy Now, Pay Later service for the metaverse, and over {formattedUsers}+ users have done{" "}
                    <TitleDescription style={{ color: !isLightTheme ? "cyan" : "#00D5D5" }}>
                      ${totalLoanedAmountFormatted} in loan volume.
                    </TitleDescription>
                  </TitleDescription>
                </div>
              </Flex>
              <Flex gap="0.7rem">
                <FilledButton onClick={() => navigate("/account/positions")}>
                  <Text color="primary" size="sm" weight="600">{`Your Offers`}</Text>
                </FilledButton>
                {account ? (
                  <GhostButton variant="ghost" onClick={() => navigate("/account/lending")}>
                    <HoverText color="secondary" size="sm" weight="600">{`Loans Made`}</HoverText>
                  </GhostButton>
                ) : (
                  <ConnectButton onClick={toggleWallet}>{`Connect Wallet`}</ConnectButton>
                )}
                <VaultButton size="sm" onClick={() => setSelectedView(LendView.vault)}>{`Cyan Vaults`}</VaultButton>
              </Flex>
            </TitleContainer>
          </Hidden>
          <LeaderBoard />
        </TopContainer>
        <AvailableCollections />
      </Container>
    </>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1240px) / 2);
  padding: 0 0 5rem 0;
  gap: 4rem;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 2 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopM]: "30px 50px" }, { [breakpoints.tablet]: "0px 5px" }])}
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "1rem 0.5rem 10rem 0.5rem" }])}
`;

const TopContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${getStyleWithMediaQuery("height", "px", [{ [breakpoints.tablet]: 340 }], "min-width")}
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])}
`;

const HoverText = styled(Text)`
  transition: all 0.2s;
`;
const GhostButton = styled(Button)`
  border: 1px solid ${props => props.theme.colors.secondary};
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.8rem;
  transition: 0.2s ease-in-out;
  :hover {
    border: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.secondary};
  }
  :hover ${HoverText} {
    color: ${props => props.theme.colors.primary};
  }
`;
const FilledButton = styled(Button)`
  border: 1px solid ${props => props.theme.colors.secondary};
  background-color: ${props => props.theme.colors.secondary};
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  :hover {
    background-color: ${props => props.theme.colors.secondary};
    border-color: ${props => props.theme.colors.secondary};
    opacity: 0.8;
  }
`;

const VaultButton = styled(Button)`
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.8rem;
`;

const ConnectButton = styled(Button)`
  width: fit-content;
  border-radius: 14px;
  padding: 1rem 1.5rem;
`;
