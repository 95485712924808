import { useAsyncCallback } from "react-async-hook";
import { RefreshCcw } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Flex, Loader, Text } from "@cyanco/components/theme";

import { HEADER_ROW_MIN_HEIGHT } from "../../consts";

export const Refresh = ({ onClick }: { onClick: () => Promise<void> }) => {
  const theme = useTheme();
  const { execute, loading } = useAsyncCallback(async () => await onClick());
  return (
    <Container gap="5px" alignItems="center" onClick={execute}>
      {loading ? (
        <Loader size="12px" stroke={theme.colors.gray0} />
      ) : (
        <RefreshCcw color={theme.colors.gray0} size={12} />
      )}
      <Text color="gray0" size="xs">{`Refresh items`}</Text>
    </Container>
  );
};

const Container = styled(Flex)`
  transition: 0.2s;
  cursor: pointer;
  height: ${HEADER_ROW_MIN_HEIGHT};
  :hover {
    opacity: 0.8;
  }
`;
