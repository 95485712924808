import { memo } from "react";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Text } from "@cyanco/components/theme/v3";

import usePagination, { DOTS, PaginatonTypes } from "../../hooks/usePagination";

type PaginationProps = PaginatonTypes & {
  onPageChange: (I: number) => void;
};

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 0 5px 0;
`;

const PaginationButton = styled.button<{
  status?: "active" | undefined;
}>`
  background-color: transparent;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  transition: 0.2s;
  opacity: ${({ status }) => (status === "active" && "1") || "0.3"};
  :hover {
    opacity: 1;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 3px;
`;

const Pagination = ({ onPageChange, ...props }: PaginationProps) => {
  const pagination = usePagination(props);
  const theme = useTheme();

  const { current } = props;

  const onNext = () => onPageChange(current + 1);
  const onPrev = () => onPageChange(current - 1);

  const last = pagination && pagination[pagination?.length - 1];

  return pagination && pagination.length > 1 ? (
    <PaginationContainer>
      <PaginationButton onClick={onPrev} disabled={current === 1}>
        <ChevronLeft color={theme.colors.secondary} />
      </PaginationButton>
      {pagination.map(pageNumber => {
        if (typeof pageNumber == "string" && pageNumber === DOTS)
          return (
            <Flex alignItems="center" key={pageNumber}>
              <MoreHorizontal size={16} color={theme.colors.secondary} />
            </Flex>
          );
        return (
          <PaginationButton
            key={pageNumber}
            onClick={() => onPageChange(Number(pageNumber))}
            status={pageNumber === current ? "active" : undefined}
          >
            <Text color="secondary" weight="700">
              {pageNumber}
            </Text>
          </PaginationButton>
        );
      })}
      <PaginationButton onClick={onNext} disabled={current === last}>
        <ChevronRight color={theme.colors.secondary} />
      </PaginationButton>
    </PaginationContainer>
  ) : null;
};

export default memo(Pagination);
