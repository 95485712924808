import { BigNumber } from "ethers";
import { useContext, useMemo } from "react";

import { useModal } from "@cyanco/components/theme/v3";

import { IPeerPlan, PeerPlanStatuses, isPeerPlan } from "@/apis/p2p/types";
import { IBNPL, isBnplPlan } from "@/components/Bnpl/bnpl.types";
import { PeerPlanRepayment } from "@/components/PlanPayment/PeerPlan";
import { PaymentOptions, PlanRepayment } from "@/components/PlanPayment/PlanRepayment";
import { useTransactionContext } from "@/components/TransactionContextProvider";

import { bigNumToFloat, getRarityRank } from "../../../../utils";
import { useAppContext } from "../../../AppContextProvider";
import { AccountDataContext } from "../../AccountDataContext";
import { useSelectedItems } from "../../SelectedItemsContext";
import { IPawn, isPawnPlan } from "../../pawn.types";
import { UserPositionCard } from "./NftCard";
import { UserPositionRow } from "./NftRow";
import { UserPositionDetails } from "./UserNftModals/UserPositionDetails";

export const UserPosition = ({
  position,
  showByGrid,
  account,
  chainId,
}: {
  position: IPawn | IBNPL | IPeerPlan;
  showByGrid: boolean;
  account: string;
  chainId: number;
}) => {
  const { items, toggleItem } = useSelectedItems();
  const { collections, usdPrice } = useAppContext();
  const { transactions } = useTransactionContext();
  const { cyanAssets } = useContext(AccountDataContext);

  const { setModalContent, unsetModal } = useModal();
  const showPawnPositionModal = (pawn: IPawn, paymentOption?: PaymentOptions) => {
    setModalContent({
      title: `Loan Details`,
      content: <PlanRepayment pawn={pawn} planType="pawn" onClose={unsetModal} paymentOption={paymentOption} />,
    });
  };
  const showBnplPositionModal = (bnpl: IBNPL, paymentOption?: PaymentOptions) => {
    setModalContent({
      title: `Loan Details`,
      content: <PlanRepayment bnpl={bnpl} planType="bnpl" onClose={unsetModal} paymentOption={paymentOption} />,
    });
  };

  const showPeerPlanModal = (plan: IPeerPlan) => {
    if (plan.status !== PeerPlanStatuses.COMPLETED) {
      setModalContent({
        title: `Loan Details`,
        content: <PeerPlanRepayment plan={plan} onClose={unsetModal} />,
      });
      return;
    }
  };

  const openPawnNftDetailsModal = (pawn: IPawn) => {
    setModalContent({
      title: `NFT Details`,
      content: (
        <UserPositionDetails
          pawn={pawn}
          onMakePayment={(item: PaymentOptions) => {
            showPawnPositionModal(pawn, item);
          }}
          onClose={unsetModal}
        />
      ),
    });
  };
  const openBnplNftDetailsModal = (bnpl: IBNPL) => {
    setModalContent({
      title: `NFT Details`,
      content: (
        <UserPositionDetails
          bnpl={bnpl}
          onMakePayment={(item: PaymentOptions) => {
            showBnplPositionModal(bnpl, item);
          }}
          onClose={unsetModal}
        />
      ),
    });
  };

  const asset = useMemo(() => {
    if (isPawnPlan(position) || isBnplPlan(position)) {
      return (cyanAssets?.assets ?? []).find(
        asset => position.tokenId === asset.tokenId && position.metadata.collectionAddress === asset.address,
      );
    } else {
      return (cyanAssets?.assets ?? []).find(
        asset => position.tokenId === asset.tokenId && position.collectionAddress === asset.address,
      );
    }
  }, [cyanAssets, position]);

  return (
    <>
      {isPawnPlan(position) ? (
        !showByGrid ? (
          <UserPositionRow
            planType="Pawn"
            nft={{
              priceInUSD: position.appraisalValue
                ? bigNumToFloat(position.appraisalValue, position.currency.decimal) * usdPrice[position.currency.symbol]
                : null,
              rarity: getRarityRank(position.metadata.rarityRank, position.metadata.collectionAddress, collections),
              currency: position.currency,
              collectionName: position.metadata.collection.name,
              imageUrl: position.metadata.imageUrl,
              tokenId: position.tokenId,
              price: BigNumber.from(position.appraisalValue),
              collectionAddress: position.metadata.collectionAddress,
              isCyanWallet: position.isCyanWallet,
              appraisal: {
                value: asset?.appraisalValue,
                currency: asset?.currency ?? position.currency,
                usdValue: asset?.appraisalValue
                  ? bigNumToFloat(asset.appraisalValue, asset.currency.decimal) * usdPrice[asset.currency.symbol]
                  : undefined,
              },
              isAutoLiquidationEnabled: position.isAutoLiquidationEnabled,
            }}
            key={`${position.planId}-${position.planType}`}
            onClick={() => openPawnNftDetailsModal(position)}
            onPay={() => showPawnPositionModal(position)}
            toggleItem={() => toggleItem(position)}
            isSelected={items.some(
              item =>
                isPawnPlan(item) &&
                item.metadata.collectionAddress === position.metadata.collectionAddress &&
                item.tokenId === position.tokenId,
            )}
            account={account}
            chainId={chainId}
            loading={transactions.some(t => t.data && t.data.planId === position.planId && t.type === "pawn-pay")}
            hasMultiSelectionBox={items.length > 0}
          />
        ) : (
          <UserPositionCard
            planType="Pawn"
            nft={{
              priceInUSD: position.appraisalValue
                ? bigNumToFloat(position.appraisalValue, position.currency.decimal) * usdPrice[position.currency.symbol]
                : null,
              rarity: getRarityRank(position.metadata.rarityRank, position.metadata.collectionAddress, collections),
              currency: position.currency,
              collectionName: position.metadata.collection.name,
              imageUrl: position.metadata.imageUrl,
              tokenId: position.tokenId,
              price: BigNumber.from(position.appraisalValue),
              collectionAddress: position.metadata.collectionAddress,
              nextPayment: BigNumber.from(position.monthlyAmount),
              isCyanWallet: position.isCyanWallet,
              isAutoLiquidationEnabled: position.isAutoLiquidationEnabled,
            }}
            key={`${position.planId}-${position.planType}`}
            onClick={() => openPawnNftDetailsModal(position)}
            onPay={() => showPawnPositionModal(position)}
            chainId={chainId}
            loading={transactions.some(t => t.data && t.data.planId === position.planId && t.type === "pawn-pay")}
            toggleItem={() => toggleItem(position)}
            isSelected={items.some(
              item =>
                isPawnPlan(item) &&
                item.metadata.collectionAddress === position.metadata.collectionAddress &&
                item.tokenId === position.tokenId,
            )}
          />
        )
      ) : isBnplPlan(position) ? (
        !showByGrid ? (
          <UserPositionRow
            planType="BNPL"
            nft={{
              priceInUSD: position.price
                ? bigNumToFloat(position.price, position.currency.decimal) * usdPrice[position.currency.symbol]
                : null,
              rarity: getRarityRank(position.metadata.rarityRank, position.metadata.collectionAddress, collections),
              currency: position.currency,
              collectionName: position.metadata.collection.name,
              imageUrl: position.metadata.imageUrl,
              tokenId: position.tokenId,
              price: BigNumber.from(position.price),
              collectionAddress: position.metadata.collectionAddress,
              isCyanWallet: position.isCyanWallet,
              appraisal: {
                value: asset?.appraisalValue,
                currency: asset?.currency ?? position.currency,
                usdValue: asset?.appraisalValue
                  ? bigNumToFloat(asset.appraisalValue, asset.currency.decimal) * usdPrice[asset.currency.symbol]
                  : undefined,
              },
              isAutoLiquidationEnabled: position.isAutoLiquidationEnabled,
            }}
            key={`${position.planId}-${position.planType}`}
            onClick={() => openBnplNftDetailsModal(position)}
            onPay={() => showBnplPositionModal(position)}
            toggleItem={() => toggleItem(position)}
            isSelected={items.some(
              item =>
                isBnplPlan(item) &&
                item.metadata.collectionAddress === position.metadata.collectionAddress &&
                item.tokenId === position.tokenId,
            )}
            account={account}
            chainId={chainId}
            loading={transactions.some(t => t.data && t.data.planId === position.planId && t.type === "bnpl-pay")}
            hasMultiSelectionBox={items.length > 0}
          />
        ) : (
          <UserPositionCard
            planType="BNPL"
            nft={{
              priceInUSD: position.price
                ? bigNumToFloat(position.price, position.currency.decimal) * usdPrice[position.currency.symbol]
                : null,
              rarity: getRarityRank(position.metadata.rarityRank, position.metadata.collectionAddress, collections),
              currency: position.currency,
              collectionName: position.metadata.collection.name,
              imageUrl: position.metadata.imageUrl,
              tokenId: position.tokenId,
              price: BigNumber.from(position.price),
              collectionAddress: position.metadata.collectionAddress,
              nextPayment: BigNumber.from(position.monthlyAmount),
              isCyanWallet: position.isCyanWallet,
              isAutoLiquidationEnabled: position.isAutoLiquidationEnabled,
            }}
            key={`${position.planId}-${position.planType}`}
            onClick={() => openBnplNftDetailsModal(position)}
            onPay={() => showBnplPositionModal(position)}
            chainId={chainId}
            loading={transactions.some(t => t.data && t.data.planId === position.planId && t.type === "bnpl-pay")}
            toggleItem={() => toggleItem(position)}
            isSelected={items.some(
              item =>
                isBnplPlan(item) &&
                item.metadata.collectionAddress === position.metadata.collectionAddress &&
                item.tokenId === position.tokenId,
            )}
          />
        )
      ) : !showByGrid ? (
        <UserPositionRow
          planType="P2P"
          nft={{
            priceInUSD:
              bigNumToFloat(position.loanBid.amount, position.loanBid.currency.decimal) *
              usdPrice[position.loanBid.currency.symbol],
            rarity: getRarityRank(
              position.metadata ? position.metadata.rarityRank : null,
              position.collectionAddress,
              collections,
            ),
            currency: position.loanBid.currency,
            collectionName: position.collection.name,
            imageUrl: position.metadata ? position.metadata.imageUrl : null,
            tokenId: position.tokenId,
            price: BigNumber.from(position.loanBid.amount),
            collectionAddress: position.collectionAddress,
            isCyanWallet: true,
            appraisal: {
              value: asset?.appraisalValue,
              currency: asset?.currency ?? position.loanBid.currency,
              usdValue: asset?.appraisalValue
                ? bigNumToFloat(asset.appraisalValue, asset.currency.decimal) * usdPrice[asset.currency.symbol]
                : undefined,
            },
            isAutoLiquidationEnabled: false,
          }}
          key={`${position.planId}-${position.planType}`}
          onClick={() => {
            setModalContent({
              title: `NFT Details`,
              content: (
                <UserPositionDetails
                  peerPlan={position}
                  onMakePayment={() => {
                    showPeerPlanModal(position);
                  }}
                  onClose={unsetModal}
                />
              ),
            });
          }}
          onPay={() => showPeerPlanModal(position)}
          toggleItem={() => toggleItem(position)}
          isSelected={items.some(
            item =>
              isPeerPlan(item) &&
              item.collectionAddress === position.collectionAddress &&
              item.tokenId === position.tokenId,
          )}
          account={account}
          chainId={chainId}
          loading={transactions.some(t => t.data && t.data.planId === position.planId && t.type === "p2p-pay")}
          hasMultiSelectionBox={items.length > 0}
        />
      ) : (
        <UserPositionCard
          planType="P2P"
          nft={{
            priceInUSD:
              bigNumToFloat(position.loanBid.amount, position.loanBid.currency.decimal) *
              usdPrice[position.loanBid.currency.symbol],
            rarity: getRarityRank(
              position.metadata ? position.metadata.rarityRank : null,
              position.collectionAddress,
              collections,
            ),
            currency: position.loanBid.currency,
            collectionName: position.collection.name,
            imageUrl: position.metadata ? position.metadata.imageUrl : null,
            tokenId: position.tokenId,
            price: BigNumber.from(position.loanBid.amount),
            collectionAddress: position.collectionAddress,
            isCyanWallet: true,
            nextPayment: BigNumber.from(position.monthlyAmount),
            isAutoLiquidationEnabled: false,
          }}
          key={`${position.planId}-${position.planType}`}
          onClick={() => {
            setModalContent({
              title: `NFT Details`,
              content: (
                <UserPositionDetails
                  peerPlan={position}
                  onMakePayment={() => {
                    showPeerPlanModal(position);
                  }}
                  onClose={unsetModal}
                />
              ),
            });
          }}
          onPay={() => showPeerPlanModal(position)}
          chainId={chainId}
          loading={transactions.some(t => t.data && t.data.planId === position.planId && t.type === "p2p-pay")}
          toggleItem={() => toggleItem(position)}
          isSelected={items.some(
            item =>
              isPeerPlan(item) &&
              item.collectionAddress === position.collectionAddress &&
              item.tokenId === position.tokenId,
          )}
        />
      )}
    </>
  );
};
