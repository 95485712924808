import { SupportedChainId } from "@usecyan/shared/constant/chains";

import {
  BlastBanner,
  EtherBanner,
  EtherBannerLight,
  PolygonBanner,
  PolygonBannerLight,
} from "@cyanco/components/theme/v3/images";

export const getDefaultBannerByChain = (chainId: SupportedChainId) => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
    case SupportedChainId.SEPOLIA:
    case SupportedChainId.APECHAIN:
    case SupportedChainId.CURTIS:
      return [EtherBanner, EtherBannerLight];
    case SupportedChainId.POLYGON:
      return [PolygonBanner, PolygonBannerLight];
    case SupportedChainId.BLAST:
    case SupportedChainId.BLAST_SEPOLIA:
      return [BlastBanner, BlastBanner];
    default:
      return [EtherBanner, EtherBannerLight];
  }
};
