import { BigNumber } from "ethers";

import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import { usePlanCreation } from "@/hooks";
import { useBnplQueryParams } from "@/hooks/useBnplQueryParams";
import { INftBe } from "@/types";
import { getRarityRankV3 } from "@/utils";

import { useSupportedCollections } from "../AppContextProvider";
import { OnSaleNftCard } from "../Bnpl/components";
import { OnSaleNftRow } from "../Bnpl/components/OnSaleNftRow";

export const BargainItems = ({
  isGrid,
  items,
  isMultiSelectPossible,
  isInBnplPage,
}: {
  isGrid: boolean;
  items: INftBe[];
  isMultiSelectPossible: boolean;
  isInBnplPage?: boolean;
}) => {
  const { showNewPlanModal } = usePlanCreation("bnpl");
  const { collections } = useSupportedCollections();
  const { setTokenParam } = useBnplQueryParams();

  const openModal = (nft: INftBe) => {
    if (!nft.privateSale) return;
    if (isInBnplPage) {
      setTokenParam(nft.tokenId);
      return;
    }
    showNewPlanModal({
      currency: nft.currency,
      items: [
        {
          ...nft,
          address: nft.privateSale.collectionAddress,
          price: BigNumber.from(nft.privateSale.price),
          itemType: nft.privateSale.tokenType,
          amount: nft.privateSale.tokenAmount,
          marketName: SupportedMarketPlaces.CYANPRIVATESALE,
          privateSaleId: nft.privateSale.id,
        },
      ],
    });
  };
  return (
    <>
      {items.map(nft => {
        let item = nft;
        if (item.privateSale) {
          item = {
            ...item,
            address: item.privateSale.collectionAddress,
            price: BigNumber.from(item.privateSale.price),
            itemType: item.privateSale.tokenType,
            amount: item.privateSale.tokenAmount,
            privateSaleId: item.privateSale.id,
            marketName: SupportedMarketPlaces.CYANPRIVATESALE,
          };
        }
        const nftCollection = collections.find(c => c.address.toLowerCase() === item.address.toLowerCase());
        return isGrid ? (
          <OnSaleNftCard
            nft={{
              ...item,
              rarity: getRarityRankV3(item.rarityRank, Number(nftCollection?.tokenCount ?? 0)) ?? undefined,
            }}
            key={`${item.address}:${item.tokenId}`}
            onClick={openModal}
            bargainListing
            collectionFloorPrice={nftCollection?.floorAsk}
            isSelectToCardPossible={isMultiSelectPossible}
          />
        ) : (
          <OnSaleNftRow
            nft={item}
            key={`${item.address}:${item.tokenId}`}
            onClick={() => openModal(item)}
            isSelectToCardPossible={isMultiSelectPossible}
          />
        );
      })}
    </>
  );
};
