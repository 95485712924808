import styled from "styled-components";

import { Flex, Text } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";

import { usePointData } from "../CyanPoints/PointDataContext";
import { formatCyanPoint } from "../CyanPoints/utils";

export const UserPoint = () => {
  const { userPoint } = usePointData();

  return (
    <Container alignItems="center">
      <GasText size="xs" textWrap={false}>
        {userPoint?.totalPoint
          ? formatCyanPoint((userPoint.totalPoint?.realized ?? 0) + (userPoint.totalPoint?.unrealized ?? 0))
          : "0"}{" "}
        pts.
      </GasText>
      <FlyoutMenu>
        <Text color="secondary" weight="600" size="xs">{`Cyan Points`}</Text>
        <Flex justifyContent="space-between" w="100%" gap="0.5rem" mt="0.5rem">
          <Flex direction="column" alignItems="center" gap="5px">
            <Text color="gray0" weight="500" size="xs">{`Pending pts.`}</Text>
            <Text color="secondary" weight="500" size="xs">
              {userPoint?.totalPoint?.unrealized ? formatCyanPoint(userPoint.totalPoint.unrealized) : "0"}
            </Text>
          </Flex>
          <Flex direction="column" alignItems="center" gap="5px">
            <Text color="gray0" weight="500" size="xs">{`Points`}</Text>
            <Text color="secondary" weight="500" size="xs">
              {userPoint?.totalPoint?.realized ? formatCyanPoint(userPoint.totalPoint.realized) : "0"}
            </Text>
          </Flex>
        </Flex>
      </FlyoutMenu>
    </Container>
  );
};

const GasText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray0};
  transition: 0.2s ease-in-out;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

const FlyoutMenu = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.primary};
  border: 0px solid ${({ theme }) => theme.colors.gray30};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 0;
  position: absolute;
  opacity: 0;
  top: 1.9rem;
  display: flex;
  right: 0px;
  min-width: 150px;
  max-height: 0px;
  z-index: 99;
  transition: opacity 0.5s ease-in-out, max-height 0s 0.2s ease-in-out, padding 0s 0.2s, border-width 0s 0.2s;
  :hover ${GasText} {
    color: ${({ theme }) => theme.colors.secondary};
  }
  :hover {
    opacity: 1;
    max-height: 200px;
    opacity: 1;
    padding: 13px 10px;
    border-width: 1px;
  }
`;
const Container = styled(Flex)`
  cursor: pointer;
  margin-right: 1.2rem;
  position: relative;
  :hover ${GasText} {
    color: ${({ theme }) => theme.colors.secondary};
  }
  :hover ${FlyoutMenu} {
    max-height: 200px;
    opacity: 1;
    padding: 13px 10px;
    border-width: 1px;
  }
`;
