import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Button, Hidden, SubTitle, Text } from "@cyanco/components/theme/v3";
import { ArrowLeft } from "@cyanco/components/theme/v3/icons";

import { apeVaultContract } from "@/config";
import { CHAIN_NAMES_TO_IDS, ISupportedChainNames } from "@/constants/chains";
import { useUserAdmin } from "@/hooks";

import { useAuthContext } from "../AuthContext/AuthContextProvider";
import { useVaults } from "./VaultDataProvider";
import { VaultDetailsSection } from "./components/VaultDetail/VaultDetailsSection";

export const VaultDetails = ({ isPrivate = false }: { isPrivate?: boolean }) => {
  const { vaultAddress, chain } = useParams();
  const { vaults, fetchingVaults } = useVaults();
  const { user } = useAuthContext();
  const { getUserAdminOfVaults } = useUserAdmin();
  const vault = vaults.find(
    vault =>
      vault.contractAddress === vaultAddress &&
      chain &&
      CHAIN_NAMES_TO_IDS[chain as ISupportedChainNames] === vault.chainId,
  );
  const isApeVault = useMemo(() => vaultAddress === apeVaultContract, [apeVaultContract]);
  const navigate = useNavigate();
  const [isAdmin, setAdmin] = useState<boolean>(false);
  useEffect(() => {
    if (vault) {
      const adminVaults = getUserAdminOfVaults(vaults);
      const isAdmin = adminVaults.includes(vault);
      setAdmin(isAdmin);
    }
  }, [vault, user]);

  useEffect(() => {
    if (!fetchingVaults && !vault) {
      navigate(`/404`);
    }
  }, [fetchingVaults, vault]);

  if (!vault) return null;
  return (
    <Container direction="column">
      <GappedContainer direction="column">
        <Flex direction="column" alignItems="flex-start">
          <BackToVaultsButton variant="ghost" onClick={() => navigate("/vault")}>
            <Flex alignItems="center" gap="2px">
              <BackToVaultsIcon />
              <BackToVaultsText color="gray0" size="sm">
                {`Vaults`}
              </BackToVaultsText>
            </Flex>
          </BackToVaultsButton>
          <div
            style={{
              visibility: isApeVault ? "visible" : "hidden",
              opacity: isApeVault ? 1 : 0,
            }}
          >
            <BackToVaultsButton variant="ghost" onClick={() => navigate("/ape-coin")}>
              <Flex alignItems="center" gap="2px">
                <BackToVaultsIcon />
                <BackToVaultsText color="gray0" size="sm">
                  {`ApeCoin Staking`}
                </BackToVaultsText>
              </Flex>
            </BackToVaultsButton>
          </div>
        </Flex>
        <Hidden tabletDown>
          <SubTitle>{`Stake into Vaults`}</SubTitle>
        </Hidden>
      </GappedContainer>
      {vault && <VaultDetailsSection isPrivate={isPrivate && isAdmin} vault={vault} />}
    </Container>
  );
};

const Container = styled(Flex)`
  margin: 30px calc((100% - 1440px) / 2);
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 4 }, { [breakpoints.tablet]: 1 }])}
  ${getStyleWithMediaQuery("margin", "", [{ [breakpoints.laptopL]: "30px 50px" }, { [breakpoints.tablet]: "0px" }])}
${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 0 5rem 0" },
    { [breakpoints.tablet]: "0rem 0.5rem 6rem 0.5rem" },
  ])}
`;

const GappedContainer = styled(Flex)`
  align-items: start;
  justify-content: start;
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 0.5 }])}
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.desktop]: "0 18px" }, { [breakpoints.tablet]: "0" }])}
`;
const BackToVaultsText = styled(Text)`
  transition: 0.2s ease-in-out;
`;
const BackToVaultsIcon = styled(ArrowLeft)`
  stroke: ${props => props.theme.colors.gray0};
  transition: 0.08s ease-in-out;
`;
const BackToVaultsButton = styled(Button)`
  width: fit-content;
  &:hover ${BackToVaultsText} {
    color: ${props => props.theme.colors.secondary};
  }
  &:hover ${BackToVaultsIcon} {
    stroke: ${props => props.theme.colors.secondary};
  }
`;
