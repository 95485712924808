import { JsonRpcSigner } from "@ethersproject/providers";
import dayjs from "dayjs";
import { BigNumber } from "ethers";

import { ILtvController, IPoolSettings } from "@/apis/vault/admin/types";
import { createHashSHA256 } from "@/utils";

const VAULT_ADMIN_SIGNATURE_EXPIRY_DURATION = 5 * 60; // 5 minutes

export const getVaultAdminSignatureExpiry = () => {
  return dayjs().add(VAULT_ADMIN_SIGNATURE_EXPIRY_DURATION, "seconds").valueOf();
};

export const signSupportedProjectUpdate = async (
  collections: {
    address: string;
    isBnplAllowed: boolean;
    isPawnAllowed: boolean;
  }[],
  vaultAddress: string,
  epxiryDate: number,
  signer: JsonRpcSigner,
) => {
  const signString = collections
    .map(({ address, isBnplAllowed, isPawnAllowed }) => [address.toLowerCase(), isBnplAllowed, isPawnAllowed])
    .join("|");
  const nonce = await createHashSHA256(`${signString}||${epxiryDate}`);
  const message = `Update supported projects for ${vaultAddress.toLowerCase()} with ${nonce}`;
  return await signer.signMessage(message);
};

export const signCollectionsAppraisalSettings = async (
  collections: {
    address: string;
    customAppraisalValue: BigNumber;
  }[],
  vaultAddress: string,
  epxiryDate: number,
  signer: JsonRpcSigner,
) => {
  const signString = collections
    .map(({ address, customAppraisalValue }) => [address.toLowerCase(), customAppraisalValue.toString()])
    .join("|");
  const nonce = await createHashSHA256(`${signString}||${epxiryDate}`);
  const message = `Update appraisal settings for ${vaultAddress.toLowerCase()} with ${nonce}`;
  return await signer.signMessage(message);
};

export const signRequestedProjectsUpdate = async (
  collections: {
    address: string;
    isBnplAllowed: boolean;
    isPawnAllowed: boolean;
  }[],
  vaultAddress: string,
  epxiryDate: number,
  signer: JsonRpcSigner,
) => {
  const signString = collections
    .map(({ address, isBnplAllowed, isPawnAllowed }) => [address.toLowerCase(), isBnplAllowed, isPawnAllowed])
    .join("|");
  const nonce = await createHashSHA256(`${signString}||${epxiryDate}`);
  const message = `Request projects for ${vaultAddress.toLowerCase()} with ${nonce}`;
  return await signer.signMessage(message);
};

export const signVaultDetailsUpdate = async (
  name: string,
  description: string,
  vaultAddress: string,
  epxiryDate: number,
  signer: JsonRpcSigner,
) => {
  const nonce = await createHashSHA256(`${name}: ${description}||${epxiryDate}`);
  const message = `Update vault detail for ${vaultAddress.toLowerCase()} with ${nonce}`;
  const signature = await signer.signMessage(message);
  return signature;
};

export const signLtvControllerUpdate = async (
  config: ILtvController[],
  vaultAddress: string,
  epxiryDate: number,
  signer: JsonRpcSigner,
) => {
  const hashString = config.map(item => [item.ltvRatio, item.priceToFloorRatio].join("|")).join("||");
  const nonce = await createHashSHA256(`${hashString}||${epxiryDate}`);
  const message = `Update vault LTV controller for ${vaultAddress.toLowerCase()} with ${nonce}`;
  const signature = await signer.signMessage(message);
  return signature;
};

export const signPoolSettingsUpdate = async (
  configs: IPoolSettings[],
  vaultAddress: string,
  baseInterestRate: number,
  epxiryDate: number,
  signer: JsonRpcSigner,
) => {
  const hashString = configs
    .map(item => [item.id, item.ltvRatio, item.weight, item.isActiveBnpl, item.isActivePawn, item.maturity].join("|"))
    .join("||");
  const nonce = await createHashSHA256(`${hashString}||${baseInterestRate}||${epxiryDate}`);
  const message = `Update vault pools settings for ${vaultAddress.toLowerCase()} with ${nonce}`;
  const signature = await signer.signMessage(message);
  return signature;
};

export const signLiquidatePlans = async (
  plans: { planId: number; estimatedValue: string }[],
  epxiryDate: number,
  signer: JsonRpcSigner,
) => {
  const hashString = plans.map(item => [item.planId, item.estimatedValue].join("|")).join("||");
  const nonce = await createHashSHA256(`${hashString}||${epxiryDate}`);
  const message = `Liquidate plans with ${nonce}`;
  const signature = await signer.signMessage(message);
  return signature;
};

export const signRefundVault = async (
  tokens: { id: number; refundValue: number }[],
  signer: JsonRpcSigner,
  epxiryDate: number,
) => {
  const hashString = tokens.map(item => [item.id, item.refundValue].join("|")).join("||");
  const nonce = await createHashSHA256(`${hashString}||${epxiryDate}`);
  const message = `Refund vault with ${nonce}`;
  const signature = await signer.signMessage(message);
  return signature;
};
