import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { ClickableArea, Flex } from "@cyanco/components/theme/components";
import {
  AssetTag,
  Loader,
  NftCard,
  NftCardActionText,
  NftCardActionWrapper,
  NftCardAddButton,
  NftCardText,
  NftMetadataImage,
  NftMetadataInfoWrapper,
  Text,
  Tooltip,
  TooltipText,
} from "@cyanco/components/theme/v3";

import { IUserNft } from "@/apis/user/types";
import { ApeCoinStatus } from "@/components/ApeCoinStaking/ApeCoinStatusOnRow";
import { useUserAssets } from "@/components/Loans/LoanPageProvider";
import { INFtRarity } from "@/components/NftStatus";
import { APE_COIN_STAKABLE_ADDRESSES } from "@/config";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, isApeCoinStakingPossible, shortenName } from "@/utils";

import { NftRarity } from "../common";

export type ISelectedNft = IUserNft & { rarity: INFtRarity | null };

export const UserNftCard = ({
  nft,
  loading = false,
  onClick,
  onPawn,
  chainId,
  isSelected,
  toggleItem,
}: {
  nft: IUserNft & { rarity: INFtRarity | null };
  loading?: boolean;
  onClick: (a: ISelectedNft) => void;
  onPawn: (a: ISelectedNft) => void;
  chainId: number;
  toggleItem: () => void;
  isSelected: boolean;
}) => {
  const getPrice = useMemo(() => {
    return !nft.appraisalValue ? "-" : bigNumToFixedStr(nft.appraisalValue, 2);
  }, [nft.appraisalValue]);
  const getPawnPrice = useMemo(() => {
    return !nft.appraisalValue ? "-" : (bigNumToFloat(nft.appraisalValue) * 0.66).toFixed(2);
  }, [nft.appraisalValue]);
  const enableApeCoinStaking = isApeCoinStakingPossible(chainId) && APE_COIN_STAKABLE_ADDRESSES.includes(nft.address);
  const isListedAsPrivateSale = useMemo(() => {
    return nft.privateSale && dayjs().isBefore(dayjs(nft.privateSale.expiryDate));
  }, [nft.privateSale]);
  const theme = useTheme();
  const { isDemoAsset } = useUserAssets();
  const isDemo = useMemo(() => {
    return isDemoAsset(nft.tokenId, nft.address);
  }, [nft]);
  return (
    <NftCardStyled key={`${nft.collectionName}:${nft.tokenId}:${nft.isCyanWallet}`} selected={isSelected}>
      <ClickableArea onClick={() => !loading && onClick(nft)}>
        <Flex direction="column">
          <NftMetadataImage imageUrl={nft.imageUrl ?? null} alt={nft.tokenId}>
            {isListedAsPrivateSale && (
              <div
                style={{
                  position: "absolute",
                  top: "8px",
                  left: "8px",
                }}
              >
                <AssetTag size="large">{`Private Sale`}</AssetTag>
              </div>
            )}
            {nft.isBendDao && (
              <div
                style={{
                  position: "absolute",
                  top: "8px",
                  left: "8px",
                }}
              >
                <AssetTag size="large">{`BendDAO`}</AssetTag>
              </div>
            )}
            {enableApeCoinStaking && (
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              >
                <Flex gap="5px">
                  <ApeCoinStatus
                    nft={{
                      tokenId: nft.tokenId,
                      address: nft.address,
                    }}
                  />
                </Flex>
              </div>
            )}
          </NftMetadataImage>
        </Flex>
      </ClickableArea>
      <NftMetadataInfoWrapper>
        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <NftCardText weight="800" color="secondary">
              {shortenName(nft.collectionName)} #{shortenName(nft.tokenId, 10, 6)}
            </NftCardText>
          </div>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Tooltip>
            <NftCardText weight="500" color="secondary" sub>
              {`${getPrice} ${nft.currency.symbol}`}
            </NftCardText>
            <TooltipText right="1" top="-40px">
              <Text size="xs" color="primary" weight="700" textWrap={false}>
                {`Appraisal value`}
              </Text>
            </TooltipText>
          </Tooltip>
          {nft.rarity && <NftRarity rarity={nft.rarity} />}
        </Flex>
      </NftMetadataInfoWrapper>
      <NftCardActionWrapper
        style={{
          minHeight: "25px",
          position: "relative",
        }}
      >
        <NftPawnPriceWrapper justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <StyledButton
              onClick={e => {
                e.stopPropagation();
                onPawn(nft);
              }}
              disabled={loading || !nft.appraisalValue}
            >
              {isDemo ? (
                <>
                  <NftCardText weight="500" color="cyan">
                    {`Demo wallet`}
                  </NftCardText>
                </>
              ) : loading ? (
                <Flex alignItems="center" gap="5px">
                  <NftCardActionText weight="700" textAlign="left" color="cyan">
                    {`Processing`}
                  </NftCardActionText>
                  <Loader size="12px" stroke={theme.colors.secondary} />
                </Flex>
              ) : (
                <>
                  <NftCardText weight="500" color="gray0">
                    {`Instant Loan:`}
                  </NftCardText>
                </>
              )}
            </StyledButton>
          </Flex>
          <NftCardText weight="600" color="secondary">
            {isDemo ? "..." : `${getPawnPrice} ${nft.currency.symbol}`}
          </NftCardText>
        </NftPawnPriceWrapper>
        <StyledNftCardAddButton
          isInCart={isSelected}
          disabled={nft.isBendDao}
          onClick={e => {
            e.stopPropagation();
            toggleItem();
          }}
          id={nft.tokenId}
        >
          {!isSelected ? (
            <NftCardText weight="600" color="black">{`Select`}</NftCardText>
          ) : (
            <NftCardText weight="600" color="secondary">
              {`Unselect`}
            </NftCardText>
          )}
        </StyledNftCardAddButton>
      </NftCardActionWrapper>
    </NftCardStyled>
  );
};
export const UserPositionCard = ({
  nft,
  planType,
  loading = false,
  chainId,
  onClick,
  onPay,
  isSelected,
  toggleItem,
}: {
  nft: {
    tokenId: string;
    collectionName: string;
    collectionAddress: string;
    imageUrl: string | null;
    currency: ICurrency;
    priceInUSD: number | null;
    rarity: INFtRarity | null;
    price: BigNumber;
    nextPayment: BigNumber;
    isCyanWallet?: boolean;
    isAutoLiquidationEnabled: boolean;
  };
  planType: "BNPL" | "Pawn" | "P2P";
  chainId: number;
  loading?: boolean;
  onClick: () => void;
  onPay: () => void;
  toggleItem: () => void;
  isSelected: boolean;
}) => {
  const theme = useTheme();
  const getPrice = useMemo(() => {
    return !nft.price ? "-" : bigNumToFixedStr(nft.price, 2, nft.currency.decimal);
  }, [nft.price]);
  const enableApeCoinStaking =
    isApeCoinStakingPossible(chainId) && APE_COIN_STAKABLE_ADDRESSES.includes(nft.collectionAddress);
  return (
    <NftPositionCardStyled selected={isSelected}>
      <ClickableArea
        onClick={() => {
          !loading && onClick();
        }}
      >
        <Flex direction="column">
          <NftMetadataImage imageUrl={nft.imageUrl ?? null} alt={nft.tokenId}>
            <AssetTag size="large" variant="black">
              {planType === "Pawn" ? `Loan` : planType} {nft.isAutoLiquidationEnabled ? "Auto-Liquidation" : ""}
            </AssetTag>
            {enableApeCoinStaking && (
              <ApeWrapper>
                <ApeCoinStatus
                  nft={{
                    tokenId: nft.tokenId,
                    address: nft.collectionAddress,
                  }}
                />
              </ApeWrapper>
            )}
          </NftMetadataImage>
        </Flex>
      </ClickableArea>
      <NftMetadataInfoWrapper style={{ background: theme.colors.secondary }}>
        <div>
          <NftCardText weight="800" color="primary">
            {shortenName(nft.collectionName)} #{shortenName(nft.tokenId, 10, 6)}
          </NftCardText>
        </div>
        <Flex justifyContent="space-between" alignItems="center">
          <Tooltip>
            <NftCardText weight="500" color="primary" sub>
              {`${getPrice} ${nft.currency.symbol}`}
            </NftCardText>
            <TooltipText right="0" top="-40px">
              <Text size="xs" color="primary" weight="700" textWrap={false}>
                {`Appraisal value`}
              </Text>
            </TooltipText>
          </Tooltip>
          {nft.rarity && <NftRarity rarity={nft.rarity} isPlan />}
        </Flex>
      </NftMetadataInfoWrapper>
      <NftCardActionWrapper
        style={{
          minHeight: "25px",
          position: "relative",
        }}
      >
        <Flex justifyContent="space-between" alignItems="center" h="25px">
          <StyledButton
            onClick={e => {
              e.stopPropagation();
              onPay();
            }}
            disabled={loading}
          >
            {loading ? (
              <Flex alignItems="center" gap="5px">
                <NftCardActionText weight="700" textAlign="left" color="cyan">
                  {`Processing`}
                </NftCardActionText>
                <Loader size="12px" stroke={theme.colors.primary} />
              </Flex>
            ) : (
              <NftCardActionText weight="500" textAlign="left" color="primary">
                {`Next Payment: `}
              </NftCardActionText>
            )}
          </StyledButton>
          <NftCardActionText weight="600" textAlign="left" color="primary">
            {bigNumToFixedStr(nft.nextPayment, 2)} {nft.currency.symbol}
          </NftCardActionText>
        </Flex>
        <StyledNftCardAddButton
          isInCart={isSelected}
          onClick={e => {
            e.stopPropagation();
            toggleItem();
          }}
          id={nft.tokenId}
        >
          {!isSelected ? (
            <NftCardText weight="600" color="black">{`Select`}</NftCardText>
          ) : (
            <NftCardText weight="600" color="secondary">
              {`Unselect`}
            </NftCardText>
          )}
        </StyledNftCardAddButton>
      </NftCardActionWrapper>
    </NftPositionCardStyled>
  );
};
const StyledButton = styled.button<{ disabled?: boolean }>`
  border: none;
  outline: none;
  height: 100%;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.2s;
  padding-left: 0;
`;

const ApeWrapper = styled.div`
  right: 0;
  position: absolute;
  bottom: 0;
`;

const NftPawnPriceWrapper = styled(Flex)`
  height: 25px;
`;
const StyledNftCardAddButton = styled(NftCardAddButton)`
  transition: all 0.2s ease-out;
  position: absolute;
  opacity: 0;
  top: 90%;
  :hover {
    border: none;
  }
`;
const NftCardStyled = styled(NftCard)`
  :hover {
    ${StyledNftCardAddButton} {
      top: 25%;
      opacity: 1 !important;
      height: 25px !important;
    }
    ${NftPawnPriceWrapper} {
      display: none;
    }
  }
`;
const NftPositionCardStyled = styled(NftCard)`
  :hover {
    ${StyledNftCardAddButton} {
      top: 25%;
      opacity: 1 !important;
      height: 25px !important;
    }
    ${NftPawnPriceWrapper} {
      display: none;
    }
  }
  background-color: ${({ theme }) => theme.colors.secondary};
`;
