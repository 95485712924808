import { useEffect, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import styled from "styled-components";

import { Button, Flex, Hidden, NotFound, SkeletonLine, StyledCheckbox, useModal } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { getVaultLiquidatedNfts } from "@/apis/vault/admin";
import { ILiquidatedNftStatus, IVaultLiquidatedNft } from "@/apis/vault/admin/types";
import { PaymentDate } from "@/components/Account/components/PositionView/PositionRow";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import Pagination from "@/components/Pagination";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { bigNumToFloatFormatted, shortenName } from "@/utils";

import { IVaultLiquidatedNftTable } from ".";
import { LatestLoanLoadingRow, StyledImg, VaultRowText } from "../../LatestLoans/LatestLoanBodyRow";
import { ContainerBox, Header, ListViewBox } from "../../LatestLoans/LatestLoans";
import { VaultLoanHeaderText } from "../../LatestLoans/LatestLoansHeader";
import { VaultTableHeaderBase, VaultTableRowBase } from "../../common";
import { PAGINATION_SIZE } from "../VaultLoans";
import { VaultRefundModal } from "../VaultRefund/VaultRefundModal";

export const CurrentVaultLiquidatedNfts = ({
  vault,
  loading,
  selectedNfts,
  setSelectedNfts,
}: IVaultLiquidatedNftTable) => {
  const { user } = useAuthContext();
  const { setModalContent } = useModal();
  const { vaultLiquidatedNfts, paymentLiquidatedNfts } = useTransactionContext();
  const [totalLoans, setTotalLoans] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedDatas, setPaginatedDatas] = useState<IVaultLiquidatedNft[][]>([]);

  const { loading: dataLoading, execute } = useAsyncCallback(
    async (pageNumber: number, currentTotal: number, existingDatas: IVaultLiquidatedNft[][]) => {
      if (!user?.token) return;
      if (!existingDatas[pageNumber - 1]?.length) {
        const data = await getVaultLiquidatedNfts({
          offset: (pageNumber - 1) * PAGINATION_SIZE,
          contractAddress: vault.contractAddress,
          token: user.token,
          statuses: [ILiquidatedNftStatus.marked],
        });
        if (data.totalCount !== currentTotal) {
          setTotalLoans(data.totalCount);
        }
        const newPaginatedDatas = [...existingDatas];
        newPaginatedDatas[pageNumber - 1] = data.liquidatedNfts;
        setPaginatedDatas(newPaginatedDatas);
      }
    },
  );

  useEffect(() => {
    setTotalLoans(0);
    setPaginatedDatas([]);
    setCurrentPage(1);
    execute(1, 0, []);
  }, [vault.contractAddress]);

  const refresh = async () => {
    if (!user?.token) return;
    const data = await getVaultLiquidatedNfts({
      contractAddress: vault.contractAddress,
      offset: 0,
      token: user.token,
      statuses: [ILiquidatedNftStatus.marked],
    });
    if (data.totalCount !== totalLoans) {
      setTotalLoans(data.totalCount);
    }
    setCurrentPage(1);
    const newPaginatedDatas = [];
    newPaginatedDatas[0] = data.liquidatedNfts;
    setPaginatedDatas(newPaginatedDatas);
  };

  useEffect(() => {
    if (paymentLiquidatedNfts.length === 0) return;
    setTimeout(() => {
      refresh();
    }, 2500);
  }, [paymentLiquidatedNfts]);

  const openModal = (nft: IVaultLiquidatedNft) => {
    setModalContent({
      title: "Return Fund",
      content: <VaultRefundModal nft={nft} vault={vault} />,
    });
  };

  const selectAll = () => {
    const datas = paginatedDatas[currentPage - 1] ?? [];
    if (!datas.every(item => selectedNfts.some(nft => nft.id === item.id))) {
      setSelectedNfts(prev => [...prev, ...datas]);
    } else {
      setSelectedNfts(prev => prev.filter(nft => !datas.some(item => item.id === nft.id)));
    }
  };

  const selectNft = (nft: IVaultLiquidatedNft) => {
    if (!selectedNfts.some(item => item.id === nft.id)) {
      setSelectedNfts(prev => [...prev, nft]);
    } else {
      setSelectedNfts(prev => prev.filter(item => item.id !== nft.id));
    }
  };
  const paginatedData = (paginatedDatas[currentPage - 1] ?? []).filter(
    item => !vaultLiquidatedNfts.some(nft => nft.id === item.id),
  );
  return (
    <Flex direction="column" w="100%">
      <ListViewBox>
        <Header>
          <CurrentHeader
            selectAll={selectAll}
            totalCount={paginatedData.length}
            isSelectedAll={paginatedData.some(item => selectedNfts.some(plan => plan.id === item.id))}
          />
        </Header>
        <ContainerBox>
          {dataLoading || loading ? (
            Array.from(Array(10).keys()).map(loader => <LatestLoanLoadingRow key={loader} />)
          ) : !paginatedData.length ? (
            <NotFound msg="No loans found" />
          ) : (
            paginatedData.map(nft => {
              return (
                <CurrentRow
                  decimals={vault.decimals}
                  symbol={vault.currency}
                  nft={nft}
                  key={nft.id}
                  openModal={openModal}
                  selectNft={selectNft}
                  isSelected={selectedNfts.some(item => item.id === nft.id)}
                />
              );
            })
          )}
          <Pagination
            total={totalLoans}
            pageSize={PAGINATION_SIZE}
            current={currentPage}
            onPageChange={n => {
              setCurrentPage(n);
              execute(n, totalLoans, paginatedDatas);
            }}
          />
        </ContainerBox>
      </ListViewBox>
    </Flex>
  );
};

const CurrentHeader = ({
  totalCount,
  selectAll,
  isSelectedAll,
}: {
  totalCount: number;
  selectAll: () => void;
  isSelectedAll: boolean;
}) => {
  return (
    <HeaderContainer>
      <div style={{ marginTop: "-2px" }}>
        <StyledCheckbox checked={isSelectedAll} onChange={selectAll} id={"check-all-liquidated-nfts"} showMinus />
      </div>
      <VaultLoanHeaderText color="secondary" align="start">
        {`${totalCount} defaulted items`}
      </VaultLoanHeaderText>
      <Hidden tabletDown>
        <VaultLoanHeaderText color="secondary" align="start">
          Marked value
        </VaultLoanHeaderText>
      </Hidden>
      <Hidden tabletDown>
        <VaultLoanHeaderText color="secondary" align="start">
          Defaulted date
        </VaultLoanHeaderText>
      </Hidden>
      <VaultLoanHeaderText color="secondary">Action</VaultLoanHeaderText>
    </HeaderContainer>
  );
};

const CurrentRow = ({
  nft,
  decimals,
  symbol,
  isSelected,
  selectNft,
  openModal,
}: {
  nft: IVaultLiquidatedNft;
  decimals: number;
  symbol: string;
  isSelected: boolean;
  selectNft: (a: IVaultLiquidatedNft) => void;
  openModal: (loan: IVaultLiquidatedNft) => void;
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  return (
    <StyledRow>
      <div style={{ marginTop: "-2px" }}>
        <StyledCheckbox checked={isSelected} onChange={() => selectNft(nft)} id={`${nft.id}`} />
      </div>
      <Flex alignItems="center" gap="8px">
        {imageLoading && <SkeletonLine borderRadius="50%" w="24px" h="24px" />}
        <StyledImg
          src={nft.imageUrl ?? NoImage}
          alt={`${nft.collectionAddress}-${nft.tokenId}`}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
          }}
        />
        <VaultRowText color="secondary" textAlign="left">
          {shortenName(nft.collectionName, 40)} #{nft.tokenId}
        </VaultRowText>
      </Flex>
      <Hidden tabletDown>
        <VaultRowText color="secondary" align="start">
          {bigNumToFloatFormatted(nft.markedPrice, decimals, 3)} {symbol}
        </VaultRowText>{" "}
      </Hidden>
      <Hidden tabletDown>{nft.defaultedAt && <PaymentDate date={nft.defaultedAt} />}</Hidden>
      <Flex justifyContent="flex-end">
        <Button
          size="sm"
          style={{
            width: "fit-content",
            padding: "0.3rem 0.6rem",
          }}
          onClick={() => {
            openModal(nft);
          }}
        >
          <VaultRowText color="black" style={{ fontWeight: 700 }}>
            Return Funds
          </VaultRowText>
        </Button>
      </Flex>
    </StyledRow>
  );
};

const StyledRow = styled(VaultTableRowBase)`
  grid-template-columns: 0.2fr minmax(0, 2.2fr) 1.3fr 1.3fr 1.2fr 0.1fr;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "0.2fr 1fr 1fr" }])};
`;

const HeaderContainer = styled(VaultTableHeaderBase)`
  grid-template-columns: 0.2fr minmax(0, 2.2fr) 1.3fr 1.3fr 1.2fr 0.1fr;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "0.2fr 1fr 1fr" }])};
`;
