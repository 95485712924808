import axios from "axios";
import React, { createContext, useContext } from "react";
import { useAsync } from "react-async-hook";

import { DAPP_URL } from "@/config";

export type IWpArticle = {
  id: string;
  title: {
    rendered: string;
  };
  link: string;
  date: string;
  jetpack_featured_media_url: string;
};

const HomePageContext = createContext<{ articles: IWpArticle[] }>({
  articles: [],
});

export const HomePageContextProvider: React.FC = ({ children }) => {
  const { result: articles = [] } = useAsync<IWpArticle[]>(async () => {
    const { data } = await axios.get(`${DAPP_URL}/articles/wp-json/wp/v2/posts?per_page=4`);
    return data;
  }, []);
  return <HomePageContext.Provider value={{ articles }}>{children}</HomePageContext.Provider>;
};

export const useLatestArticles = () => {
  const { articles } = useContext(HomePageContext);
  return { articles };
};
