import { useAppContext } from "@/components/AppContextProvider";
import { AssetTakeLoanOffer } from "@/components/Loans/LendCollectionAsset";
import { Experiments } from "@/utils/experimentList";

const BorrowCollectionAssetPage = () => {
  const { experiment } = useAppContext();
  if (!experiment.result || !experiment.result[Experiments.P2P]) return null;
  return <AssetTakeLoanOffer />;
};
export default BorrowCollectionAssetPage;
