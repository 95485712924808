import { BigNumber, ethers } from "ethers";
import { Logger, formatUnits } from "ethers/lib/utils";
import { useEffect, useMemo, useState } from "react";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Flex, Stepper, useModal } from "@cyanco/components/theme";
import { factories as f } from "@cyanco/contract";

import { usePrivateSales, useUserAssets } from "@/components/Account/AccountDataContext";
import { useAppContext } from "@/components/AppContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getCyanConduitFromChainId, getPrivateSaleFromChainId } from "@/constants/contracts";
import { useApproval } from "@/hooks/useApproval";
import { IInititatePrivateSalePurchase } from "@/hooks/usePrivateSaleCreation";
import { getChainExplorerTextForTxn } from "@/utils";
import { mapAndLogError } from "@/utils/error";
import { IMappedError } from "@/utils/error/msgs";

import { CloseButton, PrivateSaleLoading } from "../CommonComponents";
import { PrivateSalePurchaseModal } from "../PrivateSaleBuyPurchaseModal";

export const PrivateSalePurchaseStepper = ({ privateSale, nft }: IInititatePrivateSalePurchase) => {
  const { chainId, account, signer } = useWeb3React();
  const { giveErc20Approval } = useApproval();
  const { cyanWallet } = useCyanWalletContext();
  const [selectedStep, setSelectedStep] = useState<PrivateSaleSteps>(PrivateSaleSteps.TokenApproval);
  const { setModalContent } = useModal();
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const { fetchUserAssets } = useUserAssets();
  const { fetchPrivateSales } = usePrivateSales();
  const { setFireConfetti } = useAppContext();

  const checkAndApproveNonNativeCurrency = async (currencyAddress: string) => {
    const isNativeCurrency = currencyAddress === ethers.constants.AddressZero;
    if (isNativeCurrency || !account || !chainId) return;
    if (cyanWallet && privateSale.buyerAddress.toLowerCase() == cyanWallet.walletAddress.toLowerCase()) return;

    const cyanConduit = getCyanConduitFromChainId(chainId);
    await giveErc20Approval(
      {
        amount: BigNumber.from(privateSale.price),
        currencyAddress,
      },
      cyanConduit,
    );
  };

  const buy = async () => {
    if (!account || !signer || !chainId) return;
    const privateSaleContract = getPrivateSaleFromChainId(chainId);
    const cyanConduit = getCyanConduitFromChainId(chainId);
    const contract = f.CyanPrivateSaleFactory.connect(privateSaleContract, signer);

    const override: { value?: BigNumber } = {};
    if (privateSale.currencyAddress === ethers.constants.AddressZero) {
      override.value = BigNumber.from(privateSale.price);
    }
    let tx: ethers.ContractTransaction | undefined = undefined;
    const isNativeCurrency = privateSale.currencyAddress === ethers.constants.AddressZero;
    if (cyanWallet && cyanWallet.walletAddress.toLowerCase() == privateSale.buyerAddress.toLowerCase()) {
      const cyanPrivateSaleIFace = f.CyanPrivateSaleFactory.createInterface();
      const encodedBuyFnData = cyanPrivateSaleIFace.encodeFunctionData("buy", [
        {
          sellerAddress: privateSale.sellerAddress,
          buyerAddress: privateSale.buyerAddress,
          signedDate: Math.floor(privateSale.signedDate.getTime() / 1000),
          expiryDate: Math.floor(privateSale.expiryDate.getTime() / 1000),
          price: privateSale.price,
          currencyAddress: privateSale.currencyAddress,
          tokenAmount: privateSale.tokenAmount,
          tokenId: privateSale.tokenId,
          contractAddress: privateSale.collectionAddress,
          tokenType: privateSale.tokenType,
          collectionSignature: privateSale.collectionSignature,
        },
        privateSale.signature,
      ]);
      const encodedBuyFnDataFormatted = [
        privateSaleContract,
        isNativeCurrency ? privateSale.price : 0,
        encodedBuyFnData,
      ];

      let encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("execute", encodedBuyFnDataFormatted);

      if (!isNativeCurrency) {
        const sampleErc20 = f.SampleERC20TokenFactory.connect(privateSale.currencyAddress, signer);
        const userAllowance = await sampleErc20.allowance(cyanWallet.walletAddress, cyanConduit);
        if (userAllowance.lt(privateSale.price)) {
          const encodedAllowanceFnData = sampleErc20.interface.encodeFunctionData("approve", [
            cyanConduit,
            privateSale.price,
          ]);
          const encodedAllowanceFnDataFormatted = [privateSale.currencyAddress, 0, encodedAllowanceFnData];
          encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
            [encodedAllowanceFnDataFormatted, encodedBuyFnDataFormatted],
          ]);
        }
      }
      tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        data: encodedCyanExecuteFnData,
        value: override.value,
      });
    } else {
      tx = await contract.buy(
        {
          sellerAddress: privateSale.sellerAddress,
          buyerAddress: privateSale.buyerAddress,
          signedDate: Math.floor(privateSale.signedDate.getTime() / 1000),
          expiryDate: Math.floor(privateSale.expiryDate.getTime() / 1000),
          price: privateSale.price,
          currencyAddress: privateSale.currencyAddress,
          tokenAmount: privateSale.tokenAmount,
          tokenId: privateSale.tokenId,
          contractAddress: privateSale.collectionAddress,
          tokenType: privateSale.tokenType,
          collectionSignature: privateSale.collectionSignature,
        },
        privateSale.signature,
        {
          ...override,
        },
      );
    }

    await tx.wait();
    setTxnFinal(tx.hash);
    setFireConfetti(true);
    fetchUserAssets();
    fetchPrivateSales();
  };

  const mapRpcPrivateSaleError = (error: any) => {
    if (!error.code) return typeof error === "string" ? error : `Something went wrong`;
    if (error.code === Logger.errors.UNPREDICTABLE_GAS_LIMIT) {
      if (
        error.error.message.includes("caller is not token owner nor approved") ||
        error.error.message.includes("ERC1155: insufficient balance for transfer")
      ) {
        return `The seller no longer has possession of the asset. They may have sold it on another platform or transferred it away. Please contact the seller for more details.`;
      } else {
        return `The seller has either cancelled the listing or has updated the buyer's address to an address other than yours. Please contact the seller for more details.`;
      }
    }
    const { msg } = mapAndLogError(error);
    return msg;
  };

  useEffect(() => {
    const onStepChange = async (step: PrivateSaleSteps) => {
      if (!chainId || !account || !signer) return;
      try {
        switch (step) {
          case PrivateSaleSteps.TokenApproval: {
            await checkAndApproveNonNativeCurrency(nft.currency.address);
            setSelectedStep(PrivateSaleSteps.Buy);
            return;
          }
          case PrivateSaleSteps.Buy: {
            await buy();
            setSelectedStep(PrivateSaleSteps.Done);
            return;
          }
        }
      } catch (err: any) {
        console.error(err);
        openPrivateSalePurchaseModal({
          title: "Private Sale cannot be fulfilled",
          msg: mapRpcPrivateSaleError(err),
        });
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);

  const openPrivateSalePurchaseModal = (err: IMappedError) => {
    setModalContent({
      title: `Private Sale`,
      content: <PrivateSalePurchaseModal nft={nft} privateSale={privateSale} err={err} />,
    });
  };

  const stepMarkFiltered = useMemo(() => {
    return PrivateSaleStepMarks.map(item => {
      if (item.value === PrivateSaleSteps.TokenApproval && txnFinal !== null) {
        return {
          ...item,
          description: getChainExplorerTextForTxn(chainId),
        };
      }
      return item;
    });
  }, [chainId]);

  return (
    <Flex gap="1rem" direction="column">
      <PrivateSaleLoading
        tokenId={privateSale.tokenId}
        collectionName={nft.collectionName.toLowerCase()}
        price={formatUnits(privateSale.price, nft.currency.decimal)}
        currencySymbol={nft.currency.symbol}
        isLoading={false}
        seller={privateSale.sellerAddress}
        imageUrl={nft.imageUrl}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper marks={stepMarkFiltered} selectedStep={selectedStep} txUrl={""}></Stepper>
      </Box>
      {selectedStep == PrivateSaleSteps.Done && <CloseButton />}
    </Flex>
  );
};

enum PrivateSaleSteps {
  TokenApproval = 1,
  Buy = 2,
  Done = 3,
}

const PrivateSaleStepMarks = [
  {
    value: PrivateSaleSteps.TokenApproval,
    title: `Token Approval`,
    description: `This provides Cyan with permission to move your token`,
  },
  {
    value: PrivateSaleSteps.Buy,
    title: `Complete purchasing`,
    description: `Complete your purchase`,
  },
];
