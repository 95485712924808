import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

import { Button, Card, Flex, Input, SystemMessage, Text, useModal } from "@cyanco/components/theme";

import { IVaultLiquidatedNft } from "@/apis/vault/admin/types";
import { IVault } from "@/apis/vault/types";
import { DueCircle, StepperContainer } from "@/components/PlanPayment/RegularPayment";
import { numberWithCommas, shortenName } from "@/utils";

import { VaultRefundNftMetadata } from "./VaultRefundModal";
import { VaultRefundProgressModal } from "./VaultRefundProgressModal";

export const VaultRefundBulkModal = ({
  nfts,
  vault,
  error,
}: {
  nfts: Array<IVaultLiquidatedNft & { refundValue?: number }>;
  error?: { title: string; msg: string };
  vault: IVault;
}) => {
  const { setModalContent } = useModal();
  const [refundValues, setRefundValues] = useState<Array<string>>([]);

  useEffect(() => {
    const prices = nfts.map(loan => (loan?.refundValue ?? "").toString());
    setRefundValues(prices);
  }, [nfts]);

  const setValue = (index: number, value: string) => {
    const prices = [...refundValues];
    prices[index] = value;
    setRefundValues(prices);
  };

  const openModal = () => {
    setModalContent({
      title: "Return Funds",
      content: (
        <VaultRefundProgressModal
          nfts={nfts.map((nft, index) => ({ ...nft, refundValue: parseFloat(refundValues[index] ?? 0) }))}
          vault={vault}
        />
      ),
    });
  };
  const total = refundValues.reduce<number>((acc, cur) => {
    const curParsed = parseFloat(cur);
    return acc + (isNaN(curParsed) ? 0 : curParsed);
  }, 0);
  return (
    <Flex gap="12px" direction="column">
      {nfts.map((nft, index) => (
        <Flex key={nft.id} direction="column" gap="12px">
          <VaultRefundNftMetadata nft={nft} vault={vault} />
          <NumericFormat
            value={refundValues[index]}
            onValueChange={value => setValue(index, value.value)}
            placeholder="Enter the liquidation amount of the NFT"
            customInput={Input}
            valueIsNumericString
            decimalScale={vault.decimals}
          />
        </Flex>
      ))}
      {error && <SystemMessage variant={"error"} title={error.title} msg={error.msg} />}
      <Card p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Text size="sm" weight="600" color="secondary">
            Total funds to return to the pool
          </Text>
          <StepperContainer>
            {nfts.map((nft, index) => (
              <Flex justifyContent="space-between" alignItems="center" key={nft.id}>
                <Flex alignItems="center" gap="8px">
                  <DueCircle isMissed isLast />
                  <Text size="xs" weight="500" color="gray0">
                    {`${shortenName(nft.collectionName, 30)} #${nft.tokenId}`}
                  </Text>
                </Flex>
                <Text size="xs" weight="500" color={"gray0"}>
                  {`${refundValues[index] ?? 0} ${vault.currency}`}
                </Text>
              </Flex>
            ))}
          </StepperContainer>
          <Flex gap="5px" justifyContent="flex-end">
            <Text size="xs" weight="500" color="gray0" textAlign="right">
              Total funds to return:
            </Text>
            <Text size="xs" weight="500" color="secondary" textAlign="right">
              {`${numberWithCommas(total, 6, true)} ${vault.currency}`}
            </Text>
          </Flex>
        </Flex>
      </Card>
      <Button
        style={{ height: "50px" }}
        disabled={
          refundValues.length !== nfts.length ||
          !refundValues.every(item => !isNaN(parseFloat(item)) && parseFloat(item) !== 0)
        }
        onClick={openModal}
      >{`Return ${numberWithCommas(total, 3, true)} ETH`}</Button>
    </Flex>
  );
};
