import { useContext, useMemo } from "react";
import styled from "styled-components";

import { Flex, SkeletonLine, Text } from "@cyanco/components/theme";

import { useAppContext } from "@/components/AppContextProvider";
import { calculateReservoirChangePercent } from "@/components/Bnpl/components";
import { useUserTokenContext } from "@/components/Token/TokenContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { bigNumToFloat, numberWithCommas } from "@/utils";

import { AccountDataContext, useBNPLPositions, usePawnPositions, useUserAssets } from "../../AccountDataContext";

const formatCompactPorfolioNumber = (number: number) => {
  if (number < 10_000_000) {
    return numberWithCommas(number, 2);
  } else if (number >= 10_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1) + "M";
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1) + "B";
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1) + "T";
  }
  return "-";
};

export const UserPortfolio = () => {
  const { account } = useWeb3React();
  const { usdPrice, usdPrice24hr, collections, collectionsLoading } = useAppContext();
  const { bnplPositions, activeBnplPositions, bnplLoading } = useBNPLPositions();
  const { pawnPositions, activePawnPositions, pawnLoading } = usePawnPositions();
  const { cyanAssets } = useContext(AccountDataContext);
  const { userAssets, userAssetsLoading } = useUserAssets();
  const { tokens } = useUserTokenContext();

  const collectionPriceChangeMap = useMemo(() => {
    return new Map(
      collections.map(item => [
        item.address.toLowerCase(),
        calculateReservoirChangePercent(item.floorSaleChange["1day"]),
      ]),
    );
  }, [collections]);

  const calculateUserPortfolioValue = ({ is24hAgo }: { is24hAgo: boolean }) => {
    if (!account) return 0;
    // value of erc20
    const tokenPrice = is24hAgo ? usdPrice24hr : usdPrice;
    const cryptoValue = tokens.reduce((acc, cur) => {
      return acc + bigNumToFloat(cur.tokenBalance, cur.decimal) * (tokenPrice[cur.symbol] ?? cur.tokenInUsd ?? 0);
    }, 0);
    // value of debt
    const borrowedAmount = [...activePawnPositions, ...activeBnplPositions].reduce((acc, cur) => {
      return (
        acc +
        bigNumToFloat(cur.monthlyAmount, cur.currency.decimal) *
          (cur.totalNumOfPayments - cur.currentNumOfPayments) *
          usdPrice[cur.currency.symbol]
      );
    }, 0);
    // value of nfts with active plan
    const nftLoanValue = [...activeBnplPositions, ...activePawnPositions].reduce((acc, cur) => {
      const asset = (cyanAssets?.assets ?? []).find(
        asset => asset.address === cur.metadata.collectionAddress && asset.tokenId === cur.tokenId,
      );
      if (!asset || !asset.appraisalValue) return acc;
      const priceChange = collectionPriceChangeMap.get(asset.address.toLowerCase());
      const curPriceInNativeCurrency = bigNumToFloat(asset.appraisalValue, asset.currency.decimal);
      const curPriceInUsd = curPriceInNativeCurrency * usdPrice[asset.currency.symbol];
      if (priceChange && is24hAgo) {
        return acc + curPriceInNativeCurrency * (priceChange / 100) * usdPrice24hr[asset.currency.symbol];
      }
      return acc + curPriceInUsd;
    }, 0);
    // value of nfts
    const nftValue = userAssets.assets.reduce((acc, cur) => {
      const priceChange = collectionPriceChangeMap.get(cur.address.toLowerCase());
      const curPriceInNativeCurrency = bigNumToFloat(cur.appraisalValue ?? 0, cur.currency.decimal);
      const curPriceInUsd = curPriceInNativeCurrency * usdPrice[cur.currency.symbol];
      if (priceChange && is24hAgo) {
        return acc + curPriceInNativeCurrency * (1 - priceChange / 100) * usdPrice24hr[cur.currency.symbol];
      }
      return acc + curPriceInUsd;
    }, 0);
    return cryptoValue + nftValue + nftLoanValue - borrowedAmount;
  };

  const userPortfolioValue = useMemo(() => {
    return calculateUserPortfolioValue({ is24hAgo: false });
  }, [bnplPositions, pawnPositions, cyanAssets, userAssets, tokens, usdPrice, account]);

  const userPortfolioChange = useMemo(() => {
    const value24hAgo = calculateUserPortfolioValue({ is24hAgo: true });
    const diff = userPortfolioValue - value24hAgo;
    return {
      percent: value24hAgo > 0 ? (diff * 100) / userPortfolioValue : 0,
      diff,
    };
  }, [usdPrice24hr, userPortfolioValue]);
  const isLoading = bnplLoading || pawnLoading || userAssetsLoading || collectionsLoading;
  const userPortfolioChangeSign = userPortfolioChange.diff >= 0 ? "+" : "-";
  return (
    <Flex direction="column" gap="5px">
      <Text color="gray0" size="md" style={{ whiteSpace: "nowrap" }}>
        {`Portfolio value`}
      </Text>
      {isLoading ? (
        <Loader />
      ) : (
        <Text color="secondary" size="xxl" weight="700">
          ${formatCompactPorfolioNumber(userPortfolioValue)}
        </Text>
      )}
      {isLoading ? (
        <SkeletonLine h="20px" w="52px" />
      ) : (
        <Text color={userPortfolioChange.diff >= 0 ? "green" : "red"} size="md" textWrap={false}>
          {`${userPortfolioChangeSign}$${formatCompactPorfolioNumber(
            Math.abs(userPortfolioChange.diff),
          )} (${userPortfolioChangeSign}${Math.abs(userPortfolioChange.percent).toFixed(2)}%)`}
        </Text>
      )}
    </Flex>
  );
};

const Loader = styled(SkeletonLine)`
  height: 44px;
  width: 200px;
`;
