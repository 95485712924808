import { BigNumber } from "ethers";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Box } from "@cyanco/components/theme/Box";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Card, SkeletonLine, Text } from "@cyanco/components/theme/v3";

import { IPawn } from "@/components/Account/pawn.types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";

import { PlanMetadata } from "../PlanMetadata";

type IBNPLwithLoanAmount = IBNPL & {
  totalLoanAmount: BigNumber;
  totalLeftAmount: BigNumber;
  bnplOriginalPrice?: string;
  totalNumOfPaymentsLeft: number;
};
type IPawnWithLoanAmount = IPawn & {
  totalLoanAmount: BigNumber;
  totalLeftAmount: BigNumber;
  bnplOriginalPrice?: string;
  totalNumOfPaymentsLeft: number;
};
export const BulkRevivalPaymentLoading: React.FC<{
  plans: (IPawnWithLoanAmount | IBNPLwithLoanAmount)[];
}> = ({ plans }) => {
  return (
    <Flex gap="12px" direction="column">
      <PlanMetadata
        plans={plans.map(plan => ({
          tokenId: plan.tokenId,
          collectionName: plan.metadata.collection.name,
          currency: plan.currency,
          imageUrl: plan.metadata.imageUrl,
          address: plan.metadata.collectionAddress,
          leftAmount: BigNumber.from(0),
          totalAmount: BigNumber.from(0),
          isBnpl: plan.planType === "BNPL",
        }))}
      />
      <Flex direction="column">
        <SkeletonLineMobile w="100%" h={"60px"} borderRadius="10px" />
      </Flex>
      <Flex direction="column">
        <SkeletonLineMobile w="100%" h={"125px"} borderRadius="10px" />
      </Flex>
      <StyledCard p={"10px"}>
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4.3px" w="100%">
            <Text size="sm" weight="500" color="gray0">
              {`Make bulk revival:`}
            </Text>
            <SkeletonLine w="100px" h="22px" />
          </Flex>
          <Box w="120px">
            <Button disabled>{`Pay`}</Button>
          </Box>
        </Flex>
      </StyledCard>
    </Flex>
  );
};

const StyledCard = styled(Card)`
  :hover {
    border-color: ${({ theme }) => theme.colors.gray20} !important;
  }
`;

const SkeletonLineMobile = styled(SkeletonLine)`
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`;
