import { useSearchParams } from "react-router-dom";

export const useQueryParams = () => {
  const [searchParams] = useSearchParams();
  const userAffiliateCode = searchParams.get("a");
  const collectionTagId = searchParams.get("tag");
  const vaultActionType = searchParams.get("type");
  const vaultSelectedWallet = searchParams.get("wallet");
  return { collectionTagId, userAffiliateCode, vaultActionType, vaultSelectedWallet };
};
