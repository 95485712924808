import { LOCAL_STORAGE_AFFILIATE_CODE_EXPIRING_DAYS, LOCAL_STORAGE_AFFILIATE_DATA_KEY, isProd } from "../config";
import { useLocalStorage } from "./useLocalStorage";

interface IAffiliateData {
  affiliateCode: string;
  localCreatedDate: string;
}

export const useAffiliateData = () => {
  const [affiliateData, setAffliateData] = useLocalStorage<IAffiliateData | null>(
    LOCAL_STORAGE_AFFILIATE_DATA_KEY,
    null,
  );

  const _setAffliateData = (newValue: IAffiliateData) => {
    if (affiliateData === null) {
      setAffliateData({
        affiliateCode: newValue.affiliateCode,
        localCreatedDate: newValue.localCreatedDate,
      });
    } else if (affiliateData.affiliateCode !== newValue.affiliateCode) {
      const now = new Date(newValue.localCreatedDate);
      const createdDate = affiliateData?.localCreatedDate ? new Date(affiliateData?.localCreatedDate) : new Date();
      const expiryDate = isProd
        ? new Date(createdDate.getTime() + LOCAL_STORAGE_AFFILIATE_CODE_EXPIRING_DAYS * 24 * 3600 * 1000)
        : new Date(createdDate.getTime() + 5 * 60 * 1000); // 5 minutes

      if (now > expiryDate) {
        setAffliateData({
          affiliateCode: newValue.affiliateCode,
          localCreatedDate: newValue.localCreatedDate,
        });
      }
    }
  };

  return [affiliateData, _setAffliateData] as const;
};
