import { AxiosError } from "axios";
import React, { FormEventHandler, useContext, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Input, Text } from "@cyanco/components/theme/v3/core";
import { ArrowLeft, CheckCircle, Discord } from "@cyanco/components/theme/v3/icons";

import { updateUserProfile } from "@/apis/user";

import { DISCORD_ALERT_CHANNEL_URL, DISCORD_SERVER_INVITE_LINK } from "../../config";
import { AuthContext } from "../AuthContext/AuthContextProvider";

const UpdateDiscordComponent = ({ onBack }: { onBack: () => void }) => {
  const theme = useTheme();
  const { user, setUser, userProfile, setUserProfile, fetchUserBe } = useContext(AuthContext);
  const [discordUsername, setDiscordUserName] = useState<string>("");

  const [alreadyJoinDiscord, setAlreadyJoinDiscord] = useState(false);
  const [displayDiscordUserName, setDisplayDiscordUserName] = useState<string>("");
  const [showDiscordUserNotFound, setShowDiscordUserNotFound] = useState(false);
  const [alreadySetupDiscord, setAlreadySetupDiscord] = useState(false);

  const [cyanAlertTypes, setCyanAlertTypes] = useState({
    receivePaymentAlerts: false,
  });

  const handleBackToSetupAlert = async () => {
    await fetchUserBe();
    onBack();
  };

  const updateDiscord: FormEventHandler = async e => {
    e.preventDefault();

    if (!discordUsername || !user?.token) return;

    try {
      await updateUserProfile({
        token: user.token,
        discordUsername,
        ...cyanAlertTypes,
      });
      setDisplayDiscordUserName(discordUsername);
      setDiscordUserName("");
      setAlreadyJoinDiscord(true);
    } catch (err: unknown) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.data.message && axiosError.response?.data.message === "Couldn’t find user In Discord") {
        setShowDiscordUserNotFound(true);
        return;
      }
      if (axiosError.response && (axiosError.response.status === 403 || axiosError.response.status === 401)) {
        setUser({ token: "", wallet: "", cyanWallet: "" });
        setUserProfile({
          email: "",
          discordUsername: "",
          telegramUsername: "",
          receivePaymentAlerts: false,
        });
      }
      console.error(err);
    }
  };

  useEffect(() => {
    if (!user?.token) return;
    setDisplayDiscordUserName(userProfile.discordUsername);
    setCyanAlertTypes({
      receivePaymentAlerts: userProfile.receivePaymentAlerts,
    });
  }, [userProfile, user]);

  return (
    <Flex direction="column" gap="1rem">
      <form onClick={updateDiscord}>
        <Flex direction="column" gap="0.7rem">
          <Flex direction="row" alignItems="center" gap="0.3rem">
            <Discord width={14} height={14} color={theme.colors.secondary} />
            <Text size="md" weight="700" color="secondary">
              {`Discord`}
            </Text>
          </Flex>
          {displayDiscordUserName && alreadySetupDiscord ? (
            <Flex direction="row" alignItems="center" gap="0.3rem">
              <Text size="sm" color="cyan">
                {`Connected`}
              </Text>
            </Flex>
          ) : (
            <Flex direction="row" alignItems="center" gap="0.3rem">
              <Text size="sm" color="gray30">
                {`Not connected yet`}
              </Text>
            </Flex>
          )}

          <Flex direction="row" alignItems="center" gap="0.3rem" mt="0.5rem">
            <Text size="md" color="secondary" weight="700" style={{ width: "95%" }}>
              1. {`Join Discord server`}
            </Text>
            <CheckCircle
              width={14}
              height={14}
              color={displayDiscordUserName || alreadyJoinDiscord ? "cyan" : "#808080"}
            />
          </Flex>
          {!alreadyJoinDiscord && (
            <Flex direction="row" gap="1rem">
              <ButtonContainer>
                <Button
                  disabled={alreadyJoinDiscord}
                  onClick={() => {
                    setAlreadyJoinDiscord(true);
                    window.open(DISCORD_SERVER_INVITE_LINK, "_blank");
                  }}
                >
                  <Text size="sm" weight="700" color="black">
                    {`Join`}
                  </Text>
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  variant="outlined"
                  disabled={alreadyJoinDiscord}
                  onClick={() => {
                    setAlreadyJoinDiscord(true);
                  }}
                >
                  <Text size="sm" weight="700" color="cyan">
                    {`Already in!`}
                  </Text>
                </Button>
              </ButtonContainer>
            </Flex>
          )}

          <Flex direction="row" alignItems="center" gap="0.3rem" mt="1rem">
            <Text size="md" color="secondary" weight="700" style={{ width: "95%" }}>
              2. {`Save Discord username`}
            </Text>
            <CheckCircle height={14} width={14} color={displayDiscordUserName ? "cyan" : "#808080"} />
          </Flex>
          {!displayDiscordUserName && (
            <MobileFriendlyContainer>
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDiscordUserName(e.target.value);
                  setShowDiscordUserNotFound(false);
                }}
                placeholder={`your discord handle`}
                type="text"
                value={discordUsername}
                hasError={showDiscordUserNotFound}
                style={{ width: "100%", margin: 0 }}
              />

              <ButtonContainer>
                <Button disabled={discordUsername === ""}>
                  <Text size="sm" weight="700" color="black">
                    {`Update`}
                  </Text>
                </Button>
              </ButtonContainer>
            </MobileFriendlyContainer>
          )}
          <Flex direction="row" alignItems="center" gap="0.3rem" mt="1rem">
            <Text size="md" color="secondary" weight="700" style={{ width: "95%" }}>
              3. {`Setup Cyan Alert bot in server`}
            </Text>
            <CheckCircle height={14} width={14} color={alreadySetupDiscord ? "cyan" : "#808080"} />
          </Flex>

          {!alreadySetupDiscord && (
            <Flex direction="row" alignItems="center">
              <ButtonContainer>
                <Button
                  disabled={alreadyJoinDiscord && displayDiscordUserName ? false : true}
                  onClick={() => {
                    setAlreadySetupDiscord(true);
                    window.open(DISCORD_ALERT_CHANNEL_URL, "_blank");
                  }}
                >
                  <Text size="sm" weight="700" color="black">
                    {`Setup`}
                  </Text>
                </Button>
              </ButtonContainer>
            </Flex>
          )}

          {alreadyJoinDiscord && displayDiscordUserName && alreadySetupDiscord && (
            <Flex direction="row" alignItems="center" justifyContent="center" mt="1rem">
              <ButtonContainer>
                <Button onClick={handleBackToSetupAlert}>
                  <Flex direction="row" gap="0.5rem" alignContent="center" alignItems="center">
                    <ArrowLeft height={12} width={12} color="black" />
                    <Text size="sm" weight="700" color="black">
                      {`Go Back`}
                    </Text>
                  </Flex>
                </Button>
              </ButtonContainer>
            </Flex>
          )}
        </Flex>
      </form>
    </Flex>
  );
};

const ButtonContainer = styled.div`
  height: 50px;
  width: 100%;
`;

const MobileFriendlyContainer = styled(Flex)`
  gap: 0.5rem;
  flex-direction: column;
`;

export default UpdateDiscordComponent;
