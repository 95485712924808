import { IPaymentPlanStatus } from "@/constants/plans";

import { axios } from "../axios";
import { FnCreateVaultAffiliate, FnFetchVaultAdditionalMetrics, FnFetchVaults, IVaultLatestLoan } from "./types";

export const createVaultAffiliate: FnCreateVaultAffiliate = async args => {
  const { data: vaultData } = await axios.post("/cyanvault/vault-affiliate", args);
  return vaultData;
};

export const fetchVaults: FnFetchVaults = async () => {
  const { data: vaultList } = await axios.get("/cyanvault/v2");
  return vaultList;
};

export const fetchVaultAdditionalMetrics: FnFetchVaultAdditionalMetrics = async contractAddress => {
  const { data: vaultMetrics } = await axios.get(`/v2/vault/${contractAddress}/additional-metrics`);
  return vaultMetrics;
};

export const getVaultLatestLoans = async ({
  contractAddress,
  offset,
  statuses,
}: {
  contractAddress: string;
  offset: number;
  statuses: IPaymentPlanStatus[];
}): Promise<{ latestLoans: IVaultLatestLoan[]; totalCount: number }> => {
  const { data } = await axios.get(`/v2/vault/${contractAddress}/latest-loans`, {
    params: { offset, statuses },
  });
  return data;
};
