import { BigNumber } from "ethers";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Card, Text } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { INextPayment } from "@/apis/types";
import { IPawn } from "@/components/Account/pawn.types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, numberWithCommas, shortenName } from "@/utils";

export const BulkRepaymentBreakdown = ({
  plans,
  totalRepaymentAmountByCurrency,
}: {
  plans: Array<(IPawn | IBNPL) & { nextPayment?: INextPayment; hasError?: boolean }>;
  totalRepaymentAmountByCurrency: {
    [key: string]: {
      totalCost: BigNumber;
      currency: ICurrency;
      formatNumber: number;
    };
  };
}) => {
  return (
    <Card p={"10px 8px"}>
      <Flex direction="column" gap="7px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="sm" weight="600" color="secondary">
            {`Bulk Repayment Breakdown`}
          </Text>
          <Text size="xs" weight="500" color="gray0">
            {`${plans.length} ${`Loans`}`}
          </Text>
        </Flex>
        <StepperContainer>
          {plans.map((plan, index) => (
            <Flex justifyContent="space-between" key={plan.planId} alignItems="center">
              <Flex alignItems="center" gap="8px">
                <DueCircle isPaid isLast={index === plans.length - 1}>
                  <DueCircleImg src={plan.metadata.imageUrl || NoImage} />
                </DueCircle>
                <Text size="xs" weight="500" color={plan.hasError ? "red" : "gray0"}>
                  {`${`Loan`} #${shortenName(plan.tokenId)}`}
                </Text>
              </Flex>
              <Text size="xs" weight="500" color={plan.hasError ? "red" : "gray0"}>
                {`${numberWithCommas(
                  bigNumToFloat(plan.nextPayment?.currentPayment || 0, plan.currency.decimal),
                  PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 4,
                )} ${plan.currency.symbol}`}
              </Text>
            </Flex>
          ))}
        </StepperContainer>
        <Flex justifyContent="space-between">
          <Text size="xs" weight="500" color="secondary">
            {`Total Repayment `}
          </Text>
          <Flex direction="column">
            {Object.values(totalRepaymentAmountByCurrency).map(({ totalCost, currency, formatNumber }) => {
              return (
                <Text size="xs" weight="500" color="secondary" key={currency.address} textAlign="right">
                  {`${numberWithCommas(bigNumToFixedStr(totalCost, formatNumber, currency.decimal), formatNumber)} 
                ${currency.symbol}`}
                </Text>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-left: none;
  border-right: none;
  padding: 9px 0;
  margin-top: 3px;
  gap: 8px;
`;

const DueCircleImg = styled.img`
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  border-radius: 50%;
`;
const DueCircle = styled.div<{ isLast?: boolean; isNextPayment?: boolean; isPaid?: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  :before {
    position: absolute;
    content: "";
    display: ${({ isLast }) => (isLast ? "none" : "block")};
    width: 1px;
    height: 14px;
    border-left: 1px solid;
    border-color: ${({ theme }) => theme.colors.gray0};
    bottom: -100%;
    left: 6px;
  }
`;
