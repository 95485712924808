import { useMemo, useState } from "react";
import { ArrowDownRight, ArrowUpRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, SkeletonLine, SortingArrows, Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";
import { CollectioNoImage } from "@cyanco/components/theme/v3/images";

import { currencyList } from "@/apis/coinbase";
import { ICollectionBe } from "@/apis/collection/types";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { useSortableHeader } from "@/hooks/useSortableHeader";
import { bigNumToFloat, numberWithCommas } from "@/utils";

import { calculateFloorPriceChangePercent, calculateReservoirChangePercent } from ".";

export const CollectionStatsHeader = ({
  collectionsSorted,
  setCollectionsSorted,
  selectedCurrency,
  defaultCurrencySymbol,
  usdPrice,
}: {
  collectionsSorted: ICollectionBe[];
  setCollectionsSorted: (collections: ICollectionBe[]) => void;
  selectedCurrency: currencyList;
  defaultCurrencySymbol: string;
  usdPrice: {
    [key: string]: number;
  };
}) => {
  const theme = useTheme();
  const { sort, sortDirectionDesc, sortName } = useSortableHeader<ICollectionBe>(
    [c => (c.floorAsk ? calculateVolume(c) : 0)],
    "24hv",
  );

  const handleHeaderClick = (value: ((item: ICollectionBe) => any)[], sortName: string) => {
    const sortedData = sort(value, collectionsSorted, sortName);
    setCollectionsSorted(sortedData);
  };
  const calculateVolume = (collection: ICollectionBe) => {
    const volume = collection.volume["1day"];
    const currencySymbol = collection.floorAsk.price
      ? collection.floorAsk.price.currency.symbol
      : defaultCurrencySymbol;
    if (volume === null) return null;
    if (selectedCurrency.toLowerCase() === currencySymbol.toLowerCase()) {
      return volume;
    } else {
      const source = usdPrice[currencySymbol.toUpperCase()];
      const target = usdPrice[selectedCurrency.toUpperCase()];
      if (!source || !target) return null;
      return Number(((volume * source) / target).toFixed(2));
    }
  };

  const calculateFloorPrice = (collection: ICollectionBe) => {
    const currencySymbol = collection.floorAsk.price
      ? collection.floorAsk.price.currency.symbol
      : defaultCurrencySymbol;
    if (!collection?.floorAsk?.price) return null;
    const _floorPrice = bigNumToFloat(collection.floorAsk.price.amount.raw);
    if (selectedCurrency.toLowerCase() === currencySymbol.toLowerCase()) {
      return _floorPrice;
    } else {
      const source = usdPrice[currencySymbol.toUpperCase()];
      const target = usdPrice[selectedCurrency.toUpperCase()];
      if (!source || !target) return null;
      return (_floorPrice * source) / target;
    }
  };
  return (
    <CollectionStatsHeaderContainer>
      <Hidden tabletDown></Hidden>
      <Flex onClick={() => handleHeaderClick([c => c.name], "name")} gap="5px">
        <CollectionHeaderText color="secondary" align="start">
          {`NFT Collections`}
        </CollectionHeaderText>
        <SortingArrows sortDirectionDesc={!sortDirectionDesc} sortName={sortName} currentSortName="name" />
      </Flex>
      <Flex
        justifyContent="flex-end"
        gap="5px"
        onClick={() => handleHeaderClick([c => (c.floorAsk ? calculateFloorPrice(c) : 0)], "floorprice")}
      >
        <CollectionHeaderText color="secondary">{`Floor Price`}</CollectionHeaderText>
        <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="floorprice" />
      </Flex>

      <Hidden tabletDown>
        <Flex
          justifyContent="flex-end"
          gap="5px"
          onClick={() =>
            handleHeaderClick(
              [
                c =>
                  c.floorAsk && c.floorAsk.price
                    ? calculateFloorPriceChangePercent(c.floorPrice1dBefore, c.floorAsk.price.amount.raw) * -1
                    : 0,
              ],
              "24h",
            )
          }
        >
          <CollectionHeaderText color="secondary">{`24h`}</CollectionHeaderText>
          <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="24h" />
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex
          justifyContent="flex-end"
          gap="5px"
          onClick={() =>
            handleHeaderClick(
              [
                c =>
                  c.floorAsk && c.floorAsk.price
                    ? calculateFloorPriceChangePercent(c.floorPrice7dBefore, c.floorAsk.price.amount.raw) * -1
                    : 0,
              ],
              "7d",
            )
          }
        >
          <CollectionHeaderText color="secondary">{`7d`}</CollectionHeaderText>
          <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="7d" />
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex
          justifyContent="flex-end"
          gap="5px"
          onClick={() =>
            handleHeaderClick(
              [
                c =>
                  c.floorAsk && c.floorAsk.price
                    ? calculateFloorPriceChangePercent(c.floorPrice30dBefore, c.floorAsk.price.amount.raw) * -1
                    : 0,
              ],
              "30d",
            )
          }
        >
          <CollectionHeaderText color="secondary">{`30d`}</CollectionHeaderText>
          <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="30d" />
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex
          justifyContent="flex-end"
          gap="5px"
          onClick={() => handleHeaderClick([c => (c.floorAsk ? calculateVolume(c) : 0)], "24hv")}
        >
          <CollectionHeaderText color="secondary">{`24h Volume`}</CollectionHeaderText>
          <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="24hv" />
        </Flex>
      </Hidden>
      <Hidden tabletDown>
        <Flex
          justifyContent="flex-end"
          gap="5px"
          onClick={() =>
            handleHeaderClick(
              [c => (c.volumeChange ? calculateReservoirChangePercent(c.volumeChange["1day"]) : 0)],
              "volumechange",
            )
          }
        >
          <CollectionHeaderText color="secondary">{`Volume Change`}</CollectionHeaderText>
          <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="volumechange" />
        </Flex>
      </Hidden>
      <Flex
        gap="5px"
        alignItems="center"
        justifyContent="flex-end"
        onClick={() => handleHeaderClick([c => (c.floorAsk ? (calculateFloorPrice(c) ?? 0) * 0.25 : 0)], "buynow")}
      >
        <CollectionHeaderText color="secondary" textWrap={false}>{`Buy Now`}</CollectionHeaderText>
        <Hidden tabletDown>
          <Tooltip>
            <HelpCircle height={16} width={16} color={theme.colors.secondary} />
            <TooltipText showArrow position="bottom" top="35px" right="-70px" style={{ width: "150px" }}>
              <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                <div>{`Buy Now price is an estimate based on a floor price NFT for a 3-month, 25% down payment loan.`}</div>
              </Text>
            </TooltipText>
          </Tooltip>
        </Hidden>
        <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="buynow" />
      </Flex>
    </CollectionStatsHeaderContainer>
  );
};

export const CollectionStatsRow: React.FC<{
  collection: ICollectionBe & { index: number };
  selectedCurrency: currencyList;
  defaultCurrencySymbol: string;
  usdPrice: {
    [key: string]: number;
  };
}> = ({ collection, selectedCurrency, usdPrice, defaultCurrencySymbol }) => {
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(true);
  const currencySymbol = collection.floorAsk.price ? collection.floorAsk.price.currency.symbol : defaultCurrencySymbol;
  const volume = useMemo(() => {
    if (collection.volume["1day"] === null) return "N/A";
    const volume24h = collection.volume["1day"];
    if (selectedCurrency.toLowerCase() === currencySymbol.toLowerCase()) {
      return `${numberWithCommas(Number(volume24h.toFixed(2)))} ${currencySymbol}`;
    } else {
      const source = usdPrice[currencySymbol.toUpperCase()];
      const target = usdPrice[selectedCurrency.toUpperCase()];
      if (!source || !target) return "N/A";
      return `${numberWithCommas(Number(((volume24h * source) / target).toFixed(2)))} ${selectedCurrency}`;
    }
  }, [collection.volume, currencySymbol, selectedCurrency, usdPrice]);

  const volumeChange = useMemo(() => {
    return calculateReservoirChangePercent(collection.volumeChange["1day"]);
  }, [collection.volume]);

  const floorChange = useMemo(() => {
    return {
      "24h": calculateFloorPriceChangePercent(collection.floorAsk.price?.amount.raw, collection.floorPrice1dBefore),
      "7d": calculateFloorPriceChangePercent(collection.floorAsk.price?.amount.raw, collection.floorPrice7dBefore),
      "30d": calculateFloorPriceChangePercent(collection.floorAsk.price?.amount.raw, collection.floorPrice30dBefore),
    };
  }, [collection.floorSaleChange, collection.floorSaleChange]);

  const floorPrice = useMemo(() => {
    if (!collection?.floorAsk?.price) return null;
    const _floorPrice = bigNumToFloat(collection.floorAsk.price.amount.raw);
    if (selectedCurrency.toLowerCase() === currencySymbol.toLowerCase()) {
      return _floorPrice;
    } else {
      const source = usdPrice[currencySymbol.toUpperCase()];
      const target = usdPrice[selectedCurrency.toUpperCase()];
      if (!source || !target) return null;
      return (_floorPrice * source) / target;
    }
  }, [collection.floorAsk, currencySymbol, selectedCurrency, usdPrice]);

  const buyNowRange = useMemo(() => {
    if (floorPrice === null) return "N/A";
    return `${(floorPrice * 0.25).toFixed(2)} ${selectedCurrency} `;
  }, [floorPrice, selectedCurrency]);

  const localUrl = `/bnpl/${CHAIN_IDS_TO_NAMES[collection.chainId as SupportedChainId]}/${collection.slug}`;
  return (
    <Row
      onClick={() => navigate(localUrl)}
      style={{
        cursor: "pointer",
      }}
    >
      <Hidden tabletDown>
        <CollectionRowText color="gray0" align="center">
          {collection.index + 1}
        </CollectionRowText>
      </Hidden>
      <Flex alignItems="center" gap="8px">
        {imageLoading && <SkeletonLine borderRadius="50%" w="24px" h="24px" />}
        <StyledImg
          src={collection.image ?? CollectioNoImage}
          alt={collection.name}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
          }}
        />
        <CollectionRowText color="secondary" textAlign="left" textWrap={false}>
          {collection.name}
        </CollectionRowText>
      </Flex>

      <CollectionRowText color="secondary">
        {floorPrice !== null ? `${floorPrice.toFixed(2)} ${selectedCurrency}` : "N/A"}
      </CollectionRowText>
      <Hidden tabletDown>
        <ChangePercent change={floorChange["24h"]} />
      </Hidden>
      <Hidden tabletDown>
        <ChangePercent change={floorChange["7d"]} />
      </Hidden>
      <Hidden tabletDown>
        <ChangePercent change={floorChange["30d"]} />
      </Hidden>
      <Hidden tabletDown>
        <CollectionRowText color="secondary">{volume}</CollectionRowText>
      </Hidden>
      <Hidden tabletDown>
        <ChangePercent change={volumeChange} />
      </Hidden>
      <CollectionRowText color="secondary">{buyNowRange}</CollectionRowText>
    </Row>
  );
};

const ChangePercent = ({ change }: { change: number | null }) => {
  return change !== null ? (
    <Flex alignItems="center" justifyContent="flex-end" gap="3px">
      {change >= 0 ? <StyledIncrease size={18} /> : <ArrowDownRight size={18} color="red" />}
      <CollectionRowText color={change >= 0 ? "green" : "red"}>
        {numberWithCommas(Math.abs(change), 2)}%
      </CollectionRowText>
    </Flex>
  ) : (
    <CollectionRowText color="secondary">N/A</CollectionRowText>
  );
};

export const CollectionStatsRowLoading = () => {
  return (
    <Row>
      <Hidden tabletDown>
        <CollectionRowLoadingText />
      </Hidden>
      <Flex alignItems="center" gap="8px">
        <SkeletonLine borderRadius="50%" h="24px" w="24px" />
        <CollectionRowLoadingText />
      </Flex>

      <CollectionRowLoadingText />

      <CollectionRowLoadingText />

      <HiddenFlex tabletDown>
        <CollectionRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <CollectionRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <CollectionRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <CollectionRowLoadingText />
      </HiddenFlex>
      <HiddenFlex tabletDown>
        <CollectionRowLoadingText />
      </HiddenFlex>
    </Row>
  );
};

const Row = styled.div`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: end;
  grid-template-columns: 0.2fr 2fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 1fr 0.8fr;
  padding: 18px 20px 18px 14px;
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.laptop]: "0.2fr 2fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 1fr 0.8fr" },
    { [breakpoints.tablet]: "3.5fr 2fr 2fr" },
  ])};
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 2px 12px 10px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;

export const HiddenFlex = styled(Hidden)`
  display: flex;
  justify-content: end;
`;

const CollectionStatsHeaderContainer = styled(Row)`
  padding: 14px 20px 12px 14px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.laptop]: "14px 2px 12px 10px" },
    { [breakpoints.tablet]: "7px 10px" },
  ])};
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media only screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.transparent};
    }
  }
  position: sticky;
`;

const CollectionHeaderText = styled(Text)<{ align?: string }>`
  display: flex;
  white-space: nowrap;
  justify-content: ${({ align }) => (align ? align : "end")};
  font-weight: 600;
  font-size: 16px;
  ${getStyleWithMediaQuery("font-size", "px", [
    { [breakpoints.desktop]: 14 },
    { [breakpoints.laptop]: 12 },
    { [breakpoints.mobile]: 12 },
  ])};
  cursor: pointer;
`;

const CollectionRowText = styled(Text)<{ align?: string }>`
  display: block;
  text-align: ${({ align }) => (align ? align : "end")};
  font-weight: 500;
  font-size: 14px;
  ${getStyleWithMediaQuery("max-width", "px", [
    { [breakpoints.desktop]: 250 },
    { [breakpoints.laptopS]: 140 },
    { [breakpoints.mobile]: 100 },
  ])};
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 14 }, { [breakpoints.tablet]: 12 }])};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CollectionRowLoadingText = styled(SkeletonLine)`
  justify-self: end;
  width: 70%;
  height: 20px;
  ${getStyleWithMediaQuery("height", "px", [
    { [breakpoints.desktop]: 17 },
    { [breakpoints.laptop]: 15 },
    { [breakpoints.tablet]: 13 },
  ])};
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const StyledIncrease = styled(ArrowUpRight)`
  color: ${({ theme }) => theme.colors.green} !important;
`;
